import currentUserStore from 'stores/CurrentUserStore';
import hasRoleForAtLeastOneProgram from '../../helpers/hasRoleForAtLeastOneProgram.js';
import { isDdaProgram } from './functions.js';

const requiredUserPermissions = [
  'access-manager',
  'cardholder-support',
  'compliance-internal',
  'compliance-processor-only',
  'delivery-internal',
  'marqeta-admin-internal',
  'production-support-internal',
  'program-admin',
  'risk-internal',
  'supplier-payments-manager',
];

export const requirements = {
  flipFlopRequirements: ['dda-beta'],
  requiredUserPermissions,
};

const verifyRequirements = (cb) => {
  const { requiredUserPermissions } = requirements;
  return requiredUserPermissions.some((permission) => cb(permission));
};

export const verifier = () => {
  const hasAccessForAtLeastOneProgram = verifyRequirements(hasRoleForAtLeastOneProgram);
  const { userStore = {} } = currentUserStore;
  return userStore.ddaActive && hasAccessForAtLeastOneProgram && isDdaProgram();
};

export const redirect = () => {
  if (!isDdaProgram()) return 'programCardholders';
  return false;
};
