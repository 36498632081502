import React, { useMemo, useEffect } from 'react';
import { Input, Flex, Text, VSpacer } from '@mqd/volt-base';
import { useAdjustmentActionStateContext } from '../AdjustmentAction/context.js';
import {
  EXCEPTION_TOTAL_SUMMARY_ID,
  UPDATE_SETTLEMENT_AMOUNTS_AND_FEES,
  ERROR_SETTLEMENT_AMOUNTS_AND_FEES,
  CLEAR_ERROR_SETTLEMENT_AMOUNTS_AND_FEES,
  UPDATE_REFERENCE_ID,
} from './constants.js';
import {
  useAdjustTransactionSettlementAndFeesDispatchContext,
  useAdjustTransactionSettlementAndFeesStateContext,
} from './context.js';
import s from './TransactionDetailContent.module.css';
import { validateReferenceId } from './utils.js';

const TransactionDetailContent = ({ settlementAmountAndFees, currencyCode }) => {
  const dispatch = useAdjustTransactionSettlementAndFeesDispatchContext();
  const state = useAdjustTransactionSettlementAndFeesStateContext();
  const { errors, submitAttempted } = state;
  const { selectedAction } = useAdjustmentActionStateContext();

  const errorsById = useMemo(() => {
    return errors.reduce(
      (acc, err) => ({
        ...acc,
        [err]: true,
      }),
      {}
    );
  }, [errors]);

  const handleChange = (id) => (e) => {
    const {
      target: { value },
    } = e;
    dispatch({
      type: UPDATE_SETTLEMENT_AMOUNTS_AND_FEES,
      payload: { key: id, value: value },
    });

    if (submitAttempted) {
      const isValid = amountsAndFeesErrorValidation(value);
      if (isValid) {
        dispatch({ type: CLEAR_ERROR_SETTLEMENT_AMOUNTS_AND_FEES, payload: id });
      } else {
        dispatch({ type: ERROR_SETTLEMENT_AMOUNTS_AND_FEES, payload: id });
      }
    }
  };

  const handleMemoChange = (e) => dispatch({ type: UPDATE_REFERENCE_ID, payload: e.target.value });
  const handleMemoBlur = (e) => {
    const validatedReferenceId = validateReferenceId(state.referenceId, selectedAction);
    if (submitAttempted && !validatedReferenceId.valid) {
      dispatch(validatedReferenceId);
    }
  };

  const amountsAndFeesErrorValidation = (val) => {
    const re = new RegExp(/^[\d.()]+$/);
    return re.test(val);
  };

  const handleAmountAndFeesErrorValidation = (id) => () => {
    const value = state[id];
    const isValid = amountsAndFeesErrorValidation(value);
    if (!submitAttempted) {
      if (isValid) {
        dispatch({ type: CLEAR_ERROR_SETTLEMENT_AMOUNTS_AND_FEES, payload: id });
      } else {
        dispatch({ type: ERROR_SETTLEMENT_AMOUNTS_AND_FEES, payload: id });
      }
    }
  };

  useEffect(() => {
    return () => dispatch({ type: UPDATE_REFERENCE_ID, payload: '' });
  }, [dispatch]);

  return (
    <Flex justifyContent="space-between">
      <article aria-labelledby={EXCEPTION_TOTAL_SUMMARY_ID} className={s.textColumnWrapper}>
        <Text id={EXCEPTION_TOTAL_SUMMARY_ID} type="h4">
          Exception total summary
        </Text>
        {settlementAmountAndFees.map(({ label, id, disabled }) => (
          <div key={id}>
            <VSpacer factor={2} />
            <Input
              disabled={disabled}
              error={errorsById[id] && 'Please enter only numbers, decimals, or parentheses'}
              label={label}
              onBlur={handleAmountAndFeesErrorValidation(id)}
              onChange={handleChange(id)}
              rightAddOn={{ content: currencyCode }}
              value={state[id]}
            />
          </div>
        ))}
        <VSpacer factor={2} />
        <Input
          label="Reference ID"
          value={state.referenceId}
          onChange={handleMemoChange}
          onBlur={handleMemoBlur}
          error={state.referenceIdError}
        />
      </article>
    </Flex>
  );
};

export default TransactionDetailContent;
