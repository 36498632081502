import currentUserStore from 'stores/CurrentUserStore';
import { isDdaProgram } from './functions.js';

export const requirements = {
  flipFlopRequirements: ['dda-beta'],
};

export const verifier = () => {
  const { userStore = {} } = currentUserStore;
  return userStore.ddaActive && isDdaProgram();
};

export const redirect = () => {
  if (!isDdaProgram()) return 'programCardholders';
  return false;
};
