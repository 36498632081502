export const filterOptions = [
  {
    label: 'Record ID',
    placeholder: '<token>',
    filterParam: 'record_id',
  },
  {
    label: 'Start Date',
    placeholder: 'YYYY-MM-DD',
    filterParam: 'start_date',
  },
  {
    label: 'End Date',
    placeholder: 'YYYY-MM-DD',
    filterParam: 'end_date',
  },
  {
    label: 'App Name',
    placeholder: 'e.g. Program Dashboard',
    filterParam: 'app_name',
  },
  {
    label: 'Record Operation',
    placeholder: 'e.g. index',
    filterParam: 'record_operation',
  },
  {
    label: 'Record Type',
    placeholder: 'e.g. USER',
    filterParam: 'record_type',
  },
  {
    label: 'User Name',
    placeholder: 'e.g. username',
    filterParam: 'user_name',
  },
];
