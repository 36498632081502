import React from 'react';
import PropTypes from 'prop-types';

import { ChangeIndicator, HSpacer } from '@mqd/volt-base';
import { changeStatusTypeMap, statusOptions } from '../../../../../../constants';

function getLabelText({ deleted, new: isNew, edited }) {
  switch (true) {
    case deleted:
      return 'Deleted';

    case isNew:
      return 'New';

    case edited:
      return 'Edited';

    default:
      return null;
  }
}

export function Label({ flags, status }) {
  const text = getLabelText(flags);
  const hasChangedType = changeStatusTypeMap[status];

  return (
    text && (
      <>
        <HSpacer factor={0.5} />
        <ChangeIndicator hasChangedType={hasChangedType}>{text}</ChangeIndicator>
      </>
    )
  );
}

Label.propTypes = {
  flags: PropTypes.shape({
    deleted: PropTypes.bool,
    new: PropTypes.bool,
    edited: PropTypes.bool,
  }).isRequired,
  status: PropTypes.oneOf(statusOptions).isRequired,
};

Label.defaultProps = {
  deleted: false,
  new: false,
  edited: false,
};
