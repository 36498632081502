import logger from 'utils/logger';
import {
  SET_INITIAL_STATE,
  UPDATE_SETTLEMENT_AMOUNTS_AND_FEES,
  ERROR_SETTLEMENT_AMOUNTS_AND_FEES,
  SUBMIT_ATTEMPTED,
  CLEAR_ERROR_SETTLEMENT_AMOUNTS_AND_FEES,
  UPDATE_REFERENCE_ID,
  REFERENCE_ID_ERROR,
} from './constants.js';
import { ACCOUNTING_GROUPS } from '../../utilities/dictionary/index.js';

const {
  PURCHASE_PAYABLES,
  PINDEBIT_ASSOC_FEE,
  CROSS_BORDER_ISSUER_FEE,
  INTEGRITY_FEE,
  IRF_BASE_AMOUNT,
} = ACCOUNTING_GROUPS;

export const initialState = {
  [PINDEBIT_ASSOC_FEE]: '0.00',
  [PURCHASE_PAYABLES]: '0.00',
  [CROSS_BORDER_ISSUER_FEE]: '0.00',
  [IRF_BASE_AMOUNT]: '0.00',
  [INTEGRITY_FEE]: '0.00',
  errors: [],
  submitAttempted: false,
  referenceId: '',
  referenceIdError: '',
};

export function reducer(state, action) {
  const { type, payload } = action;
  if (process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development') {
    logger.info(`TransactionDetailContent reducer: ${type}: ${JSON.stringify(payload, null, 4)}`);
  }
  switch (type) {
    case SET_INITIAL_STATE:
      return {
        ...state,
        ...payload,
      };
    case UPDATE_SETTLEMENT_AMOUNTS_AND_FEES:
      return {
        ...state,
        [payload.key]: payload.value,
      };

    case ERROR_SETTLEMENT_AMOUNTS_AND_FEES:
      return {
        ...state,
        errors: [...state.errors, payload],
      };

    case SUBMIT_ATTEMPTED:
      return {
        ...state,
        submitAttempted: true,
      };

    case CLEAR_ERROR_SETTLEMENT_AMOUNTS_AND_FEES:
      return {
        ...state,
        errors: state.errors.filter((err) => err !== payload),
      };

    case UPDATE_REFERENCE_ID:
      return {
        ...state,
        referenceId: payload,
        referenceIdError: '',
      };

    case REFERENCE_ID_ERROR:
      return {
        ...state,
        referenceIdError: payload,
      };

    default:
      throw new Error(
        `Action of type: ${type} is not supported by TransactionDetailContent reducer`
      );
  }
}
