import { mergeCustomPrograms } from './../../utils/program-config/customPrograms.js';

export default function programsWithRoles({
  janusPrograms,
  userOrgName,
  redseaPrograms,
  programMetaData,
}) {
  const allProgramsAccess = janusPrograms && calculateAllProgramAccess(janusPrograms);
  const programsList = allProgramsAccess
    ? calculateProgramsList(janusPrograms, programMetaData, userOrgName)
    : janusPrograms;

  // Merge custom qa programs data with programs for non-prod for development & testing.
  // these programs are not available in pp_table must be manually added (UPS-16614).
  const mergedProgramsList = mergeCustomPrograms(programsList);

  const programsWithRoles = calculateProgramsWithRolesList(
    allProgramsAccess,
    mergedProgramsList,
    programMetaData,
    redseaPrograms
  );
  return programsWithRoles;
}

const calculateAllProgramAccess = (programs) =>
  programs.some((program) => program && program.program === 'All');

const calculateProgramsList = (programs, programMetaData, userOrgName) =>
  programMetaData.reduce((acc, org) => {
    if (org.org_name === userOrgName) {
      return org.programs;
    }
    return acc;
  }, []);

const calculateProgramsWithRolesList = (
  allProgramsAccess,
  programsList,
  programMetaData,
  redseaPrograms = []
) => {
  // Users in `marqeta` program in Redsea should see all programs
  // from the Marqeta org in Janus
  const allMarqetaPrograms = redseaPrograms.includes('marqeta');

  // Users NOT in `marqeta` program in Redsea should only see programs
  // for which they have a Redsea role
  if (!programsList) {
    return [];
  } else if (allMarqetaPrograms) {
    return programsList;
  } else {
    return programsList.filter((program) => redseaPrograms.includes(program.short_name));
  }
};
