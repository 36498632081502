import React from 'react';
import { Text } from '@mqd/volt-base';

function NoAction() {
  return (
    <div data-testid="no-action">
      <Text type="h4">Card actions not available</Text>
      <Text type="body-sm">View associated card details.</Text>
    </div>
  );
}

export default NoAction;
