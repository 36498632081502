import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { getNameObject, getMultiFieldStatus, getAddressObject, getIssues } from './../helpers';
import { detailsOptions, addressArr, nameArr, fullKycArray, optionsToAPI } from './../constants';
import { AMERICAN_STATES, WORLD_COUNTRIES } from './../../shared-utils/constants';
import {
  Button,
  Col,
  Container,
  Flex,
  Form,
  HSpacer,
  Input,
  Modal,
  RadioGroup,
  Row,
  Select,
  Text,
  Textarea,
  ToastAlert,
  VSpacer,
} from '@mqd/volt-base';
import KycStatus from './../../shared-components/kyc-status/KycStatus';
import ReviewActionDropdown from './ReviewActionDropdown';
import s from './../ReviewDetails.module.css';

class KYCDetailsEdit extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      details: {},
      showUpdateModal: false,
      showCloseReviewModal: false,
      error: '',
      note: '',
      selectedKycStatus: '',
      values: {},
      showToast: false,
      toastIcon: 'success',
      toastMessage: '',
    };

    this.handleFormChange = this.handleFormChange.bind(this);
    this.updateStatusForField = this.updateStatusForField.bind(this);
    this.handleUpdateReview = this.handleUpdateReview.bind(this);
  }

  componentDidMount() {
    const { canRevealIdentification, details } = this.props;
    const deconstructedDetailsProp = {};

    if (details) {
      const valueObj = {};
      fullKycArray.forEach((key) => {
        if (details[key]) {
          valueObj[key] = details[key].value;
          deconstructedDetailsProp[key] = {
            value: details[key].value,
            status: details[key].status,
          };
        }
      });

      if (!canRevealIdentification) {
        valueObj.ssn = '';
        deconstructedDetailsProp.ssn.value = '';
      }

      this.setState({ values: valueObj, details: deconstructedDetailsProp });
    }
  }

  updateStatusForField(field, fieldStatus) {
    const adjustedStatus = optionsToAPI[fieldStatus.val];
    const details = { ...this.state.details };

    if (field === 'name') {
      nameArr.forEach((key) => {
        details[key].status = adjustedStatus;
      });
    } else if (field === 'address') {
      addressArr.forEach((key) => {
        details[key].status = adjustedStatus;
      });
    } else {
      details[field].status = adjustedStatus;
    }
    this.setState({ details: details });
  }

  individualFieldStatus(fieldName) {
    const status = this.state.details[fieldName].status;

    if (!status) {
      return 'UNVERIFIED';
    }
    if (status === 'SUCCESS') {
      return 'VERIFIED';
    }
    return status;
  }

  renderIssues() {
    const { reviewStatus, details } = this.props;

    const issues = getIssues(details);
    if (reviewStatus !== 'CLOSED' && issues) {
      return (
        <div className={s.issuesBox} data-testid="kyc-details-edit-issues-box">
          <Text type="body-sm">Current KYC issue(s): {issues}</Text>
        </div>
      );
    }
  }

  renderName() {
    const nameObj = getNameObject(this.state.details);
    const nameStatus = getMultiFieldStatus(nameObj);

    return (
      <Container gutter={12}>
        <Row testId="kyc-details-edit-name">
          <Col span={3}>
            <Text type="h6">Full name</Text>
          </Col>
          <Col span={7}>
            <Input autoFocus={true} placeholder="First name" width="100%" name="first_name" />
            <VSpacer />
            <Input placeholder="Middle name" width="100%" name="middle_name" />
            <VSpacer />
            <Input placeholder="Last name" width="100%" name="last_name" />
          </Col>
          <Col testId="name-status" span={2}>
            {this.renderStatus('name', nameStatus)}
          </Col>
        </Row>
      </Container>
    );
  }

  renderAddress() {
    const addressObj = getAddressObject(this.state.details);
    const addressStatus = getMultiFieldStatus(addressObj);

    return (
      <Container gutter={12}>
        <Row testId="kyc-details-edit-address">
          <Col span={3}>
            <Text type="h6">Address</Text>
          </Col>
          <Col span={7}>
            <Container gutter={8}>
              <Row>
                <Col span={12}>
                  <Input placeholder="Address" width="100%" name="address1" />
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Input placeholder="Address 2" width="100%" name="address2" />
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={5}>
                  <Input placeholder="City" width="100%" name="city" />
                </Col>
                <Col span={3}>
                  <Select placeholder="State" width="100%" name="state" options={AMERICAN_STATES} />
                </Col>
                <Col span={4}>
                  <Input placeholder="Postal Code" width="100%" name="postal_code" />
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Select
                    placeholder="Country"
                    name="country"
                    width="100%"
                    options={WORLD_COUNTRIES}
                  />
                </Col>
              </Row>
            </Container>
          </Col>
          <Col span={2}>{this.renderStatus('address', addressStatus)}</Col>
        </Row>
      </Container>
    );
  }

  renderDOB() {
    const status = this.individualFieldStatus('birth_date');

    return (
      <Container gutter={12}>
        <Row testId="kyc-details-edit-birth_date">
          <Col span={3}>
            <Text type="h6">DOB</Text>
          </Col>
          <Col span={7}>
            <Input
              placeholder="Date of Birth"
              width="100%"
              name="birth_date"
              errorValidators={[
                {
                  validator: (val) => {
                    const currentDate = new Date();
                    return new Date(val) >= currentDate;
                  },
                  message: 'Invalid date.',
                },
              ]}
            />
          </Col>
          <Col span={2}>{this.renderStatus('birth_date', status)}</Col>
        </Row>
      </Container>
    );
  }

  renderSSN() {
    const status = this.individualFieldStatus('ssn');

    return (
      <Container gutter={12}>
        <Row testId="kyc-details-edit-ssn">
          <Col span={3}>
            <Text type="h6">SSN</Text>
          </Col>
          <Col span={7}>
            <Input
              placeholder="SSN"
              width="100%"
              name="ssn"
              errorValidators={[
                {
                  validator: (val) => {
                    return val && val.indexOf('-') >= 0;
                  },
                  message: "SSN can't contain dashes",
                },
                {
                  validator: (val) => {
                    return val && !/^\d+$/.test(val);
                  },
                  message: 'SSN must be only numbers',
                },
                {
                  validator: (val) => {
                    return val && val.length !== 9;
                  },
                  message: 'SSN must be 9 digits',
                },
              ]}
            />
          </Col>
          <Col span={2}>{this.renderStatus('ssn', status)}</Col>
        </Row>
      </Container>
    );
  }

  renderStatus(field, status) {
    const { internalUser } = this.props;

    if (internalUser) {
      return (
        <div className={s.editStatusDropdown} data-testid={`InternalKYCStatus-${field}-${status}`}>
          <ReviewActionDropdown
            field={field}
            value={status}
            options={detailsOptions}
            changeVal={this.updateStatusForField}
            showText={false}
          />
        </div>
      );
    } else {
      return (
        <div className={s.statusDisplay} data-testid={`ExternalKYCStatus-${field}-${status}`}>
          <KycStatus status={status} showText={false} />
        </div>
      );
    }
  }

  renderCloseReviewModal() {
    const { statusOptions } = this.props;

    return (
      <Modal
        type="sm"
        heading="Close review"
        description="It looks like this account is ready to be verified. If you close this review, you will not be able to make any changes after."
        error={this.state.error}
        footerButtons={[
          <Button testId="cancel-review-btn" type="tertiary" onClick={() => this.cancelModal()}>
            Cancel
          </Button>,
          <Button testId="close-review-btn" onClick={this.closeReview}>
            Close review
          </Button>,
        ]}
        testId="close-review-modal"
      >
        <VSpacer />
        <RadioGroup
          value={this.state.selectedKycStatus}
          label="KYC status"
          groupItems={statusOptions}
          onChange={(value) => this.setState({ selectedKycStatus: value })}
        />
        <VSpacer factor={3} />
        <Textarea
          autoFocus={true}
          label="Add note (optional)"
          value={this.state.note}
          onChange={(e) => this.setState({ note: e.target.value })}
          rows={2}
        />
      </Modal>
    );
  }

  closeReview = async () => {
    const { updateKYCDetails, internalUser, exitEditMode } = this.props;
    const { details, note } = this.state;

    const result = await updateKYCDetails(details, note, internalUser, false);

    if (result) {
      const reviewDispositionResult = await this.props.updateReviewDisposition(
        this.state.selectedKycStatus,
        note
      );
      const toastIcon = reviewDispositionResult ? 'success' : 'error';
      const toastMessage = reviewDispositionResult ? 'Review closed' : 'Review not closed';

      this.setState({ showToast: true, toastIcon: toastIcon, toastMessage: toastMessage });
      setTimeout(() => {
        this.setState({ showToast: false });
      }, 6100);
    }
    exitEditMode();
  };

  renderUpdateModal() {
    return (
      <Modal
        type="sm"
        heading="Confirm update"
        description="Making this change will update this information across the entire app."
        error={this.state.error}
        hideModal={() => this.setState({ showUpdateModal: false })}
        hideModalButtonText="Cancel"
        showHideModalButton={true}
        footerButtons={[<Button onClick={() => this.updateKyc()}>Update review</Button>]}
        testId="volt-library-modal"
      >
        <Textarea
          autoFocus={true}
          label="Add note (optional)"
          value={this.state.note}
          onChange={(e) => this.setState({ note: e.target.value })}
          rows={2}
        />
      </Modal>
    );
  }

  handleUpdateReview() {
    const { internalUser } = this.props;
    const issues = getIssues(this.state.details);
    const allVerified = issues.length === 0;
    const allButOfacVerified = issues === 'OFACIssue';

    if (allVerified && internalUser) {
      this.setState({ showCloseReviewModal: true, selectedKycStatus: 'APPROVED' });
    } else if (allButOfacVerified) {
      this.setState({ showCloseReviewModal: true, selectedKycStatus: 'DECLINED' });
    } else if (!internalUser) {
      this.setState({ showUpdateModal: true });
    } else {
      this.updateKyc();
    }
  }

  async updateKyc() {
    const { updateKYCDetails, internalUser, exitEditMode } = this.props;
    const { details, note } = this.state;

    const result = await updateKYCDetails(details, note, internalUser);
    if (result) {
      this.setState({
        showUpdateModal: false,
        showCloseReviewModal: false,
        note: '',
      });
      exitEditMode();
    }
  }

  async cancelModal() {
    this.props.exitEditMode();
  }

  handleFormChange(formData) {
    const { details } = this.state;

    fullKycArray.forEach((key) => {
      details[key].value =
        key === ('country' && formData[key].val) ? formData[key].val : formData[key];
    });
    this.setState({ details: details });
  }

  render() {
    const { exitEditMode } = this.props;
    const { showUpdateModal, showCloseReviewModal, showToast, values, toastIcon, toastMessage } =
      this.state;

    return (
      <div style={{ width: '100%' }} data-testid="RCRM-kyc-details-edit">
        {this.renderIssues()}
        <VSpacer factor={2} />
        {Object.keys(values).length > 0 && (
          <Form initialState={values} onFormChange={this.handleFormChange}>
            {this.renderName()}
            <VSpacer factor={2} />
            {this.renderAddress()}
            <VSpacer factor={2} />
            {this.renderDOB()}
            <VSpacer factor={2} />
            {this.renderSSN()}
            <VSpacer factor={3} />
            <Flex flexDirection="row">
              <Button type="tertiary" onClick={exitEditMode}>
                Cancel
              </Button>
              <HSpacer factor={2} />
              <Button
                testId="update-review-btn"
                submit
                type="secondary"
                onClick={this.handleUpdateReview}
              >
                Update review
              </Button>
            </Flex>
          </Form>
        )}
        {showUpdateModal && this.renderUpdateModal()}
        {showCloseReviewModal && this.renderCloseReviewModal()}
        {showToast && <ToastAlert icon={toastIcon}>{toastMessage}</ToastAlert>}
      </div>
    );
  }
}

KYCDetailsEdit.propTypes = {
  internalUser: PropTypes.bool,
  details: PropTypes.object,
  updateKYCDetails: PropTypes.func,
  updateReviewDisposition: PropTypes.func,
  canRevealIdentification: PropTypes.bool,
  statusOptions: PropTypes.array,
  reviewStatus: PropTypes.string,
  exitEditMode: PropTypes.func,
};

KYCDetailsEdit.defaultProps = {
  internalUser: false,
  details: {},
  status: '',
  updateKYCDetails: () => {},
  updateReviewDisposition: () => {},
  canRevealIdentification: false,
  statusOptions: [],
  reviewStatus: '',
  exitEditMode: () => {},
};

export default observer(KYCDetailsEdit);
