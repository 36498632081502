import React from 'react';
import { Button, Text, Select, Hr, HSpacer, VSpacer } from '@mqd/volt-base';
import { URLS } from '../../utilities/config.js';
import windowEnv from '../../utilities/dom/window-env.js';

const ENDPOINTS = [
  'fds/ping',
  'fds/networks/NETWORK_VISA/approval-summary',
  'fds/networks/NETWORK_VISA/exception-summary',
  'fds/subnetworks/SUBNETWORK_VISANET/approval-queue/FAILED_TO_POST',
  'fds/subnetworks/SUBNETWORK_VISANET/approval-queue/FAILED_TO_POST/2021-08-31',
  'fds/subnetworks/SUBNETWORK_VISANETDEBIT/unmatched-transaction-queue',
  'fds/transaction-hash-exceptions/685096167064166769',
  'fds/invalid-endpoint',
];

const OPTIONS = {
  'fds/subnetworks/SUBNETWORK_VISANET/unmatched-transaction-queue': {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      exception_type: 'OVERPOSTED',
    }),
  },
};

const REDSEA_OPTIONS = ['Valid Redsea token', 'Invalid Redsea token', 'No Redsea token'];

function getFDSEnv(values = {}) {
  return {
    AMC_ENDPOINT: values.AMC_ENDPOINT || window.location.origin,
    DNA_BASE_URL: values.DNA_BASE_URL || windowEnv('DNA_BASE_URL'),
    ENVIRONMENT: values.NODE_ENV || windowEnv('ENVIRONMENT'),
    GRAPHQL_BASE_URL: values.GRAPHQL_BASE_URL || windowEnv('GRAPHQL_BASE_URL'),
    NODE_GATEWAY_URL: values.NODE_GATEWAY_URL || windowEnv('NODE_GATEWAY_URL'),
    SETTLEMENTS_BASE_URL: values.SETTLEMENTS_BASE_URL || windowEnv('SETTLEMENTS_BASE_URL'),
  };
}

function NodeProxy() {
  const [data, setData] = React.useState('');
  const [selectedEndpoint, setSelectedEndpoint] = React.useState(ENDPOINTS[0]);
  const [fullPath, setFullPath] = React.useState('');
  const [env, setEnv] = React.useState(getFDSEnv());
  const [authToken, setAuthToken] = React.useState(REDSEA_OPTIONS[0]);
  const [loading, setLoading] = React.useState(false);

  const redseaToken = React.useMemo(() => {
    if (authToken === REDSEA_OPTIONS[2]) return undefined;
    return authToken === REDSEA_OPTIONS[0]
      ? `Bearer ${sessionStorage.getItem('accessToken')}`
      : 'Bearer abcde12345';
  }, [authToken]);

  const fetchData = (path) => {
    setLoading(true);
    setFullPath(path);
    const options = { ...(OPTIONS[path] || {}) };
    options.headers = options.headers || {};
    options.headers['Authorization'] = redseaToken;
    return fetch(`${URLS.nodeGateway}/${path}`, options)
      .then((response) => response.json())
      .then((result) => setData(JSON.stringify(result, null, 2)))
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    const options = {
      headers: {
        Authorization: redseaToken,
      },
    };

    fetch(`${URLS.nodeGateway}/fds/env`, options)
      .then((response) => response.json())
      .then((result) => setEnv(getFDSEnv(result.data)));
  }, [redseaToken]);

  return (
    <>
      <Text type="h2">Node Proxy POC</Text>
      <VSpacer factor={2} />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button
          type="primary"
          loading={loading}
          disabled={loading}
          onClick={() => fetchData(selectedEndpoint)}
        >
          Fetch new data
        </Button>
        <HSpacer factor={2} />
        <Select
          disabled={loading}
          options={ENDPOINTS}
          placeholder="Choose an endpoint to call"
          width="400px"
          onChange={(value) => {
            setData('');
            setSelectedEndpoint(value);
          }}
          value={selectedEndpoint}
        />
        <HSpacer factor={2} />
        with
        <HSpacer factor={2} />
        <Select
          disabled={loading}
          options={REDSEA_OPTIONS}
          placeholder="Configure authentication"
          width="200px"
          onChange={(value) => {
            setData('');
            setAuthToken(value);
          }}
          value={authToken}
        />
      </div>
      <VSpacer factor={2} />
      {!loading && data ? (
        <div style={{ whiteSpace: 'break-spaces' }}>
          <Text type="h6">
            Data from <code>{fullPath}</code>:
          </Text>
          <Text>{data}</Text>
        </div>
      ) : (
        <>
          <VSpacer factor={2} />
          <div>{loading ? 'Loading...' : 'Submit to fetch new data...'}</div>
        </>
      )}

      <VSpacer factor={4} />
      <Hr />
      <VSpacer factor={4} />

      <Text type="label">FDS environment details</Text>
      <div style={{ whiteSpace: 'break-spaces' }}>
        <Text type="footnote">{JSON.stringify(env, null, 2)}</Text>
      </div>
    </>
  );
}

export default NodeProxy;
