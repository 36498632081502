import React, { useState, useMemo, useEffect, useCallback } from 'react';
import moment from 'moment';
import { Table, TableHeader } from '@mq/voltron-table';
import {
  Alert,
  Button,
  Drawer,
  ErrorModal,
  Flex,
  FormError,
  FormLabel,
  HSpacer,
  Icon,
  LoadingOverlay,
  Select,
  Text,
  Textarea,
  ToastAlert,
  VSpacer,
} from '@mqd/volt-base';
import { containerHeightHOC } from '@mq/voltron-parent';
import { number } from '@mq/volt-amc-container';
import { useMutation } from '@apollo/react-hooks';

import { useProgramSelectorStateContext } from '../../../../components/program-selector/context.js';
import routerStore from '../../../../stores/router/RouterStore.js';
import { useRouterGoContext } from '../../../../components/router-go/context.js';
import useQueryUrlParams from '../../../../utils/use-query-params-url/useQueryParamsToUrl.js';
import { GET_SETTLED_DATE_QUEUE } from '../../queries/getSettledDateQueue/query.js';
import { PAGE_SIZE } from '../../utilities/config.js';
import { dashCaseToConstant } from '../../utilities/string/index.js';
import { DATE_FORMAT_STRING } from 'views/fds/utilities/date.js';
import TableSorter from '../../components/TableSorter/TableSorter.js';
import DatePicker from '../../components/DatePicker/DatePicker.js';
import FlexContainer from '../../components/FlexContainer/FlexContainer.js';
import ApprovalFlowBreadcrumbs from '../../components/ApprovalFlowBreadcrumbs/ApprovalFlowBreadcrumbs.js';
import { urlParamsToObject } from '../../utilities/url-params/index.js';
import addUpScaledDecimalsArray from '../../utilities/number/add-up-scaled-decimals-array.js';
import {
  ACCOUNTING_GROUP_CATEGORIES,
  NETWORKS,
  PROGRAM_TYPES,
  EXCEPTION_TYPES,
  SUBNETWORKS,
} from '../../utilities/dictionary/index.js';
import ErrorLoading from '../../components/ErrorLoading/index.js';
import useRowSelector from '../../hooks/useRowSelector.js';
import useTableColumns from '../../hooks/useTableColumns.js';
import { APPROVE_TRANSACTION_ADJUSTMENT } from '../../queries/approveTransactionHashAdjustment/query.js';
import { BULK_REJECT_ADJUSTMENTS } from '../../queries/bulkRejectAdjustments/query.js';
import { REJECTION_OPTIONS } from '../ApprovalDetails/components/RejectHashAdjustmentModal/constants.js';

import TableFilters from './components/Filters.js';
import Pagination from './components/Pagination.js';
import { createRejectOrApproveFailureMessage } from './utils.js';
import s from './ApprovalSettlementDateQueue.module.css';
import { COLUMNS_LOCAL_STORAGE_KEY } from './constants.js';

const tableSortConfiguration = {
  settlement_date: {
    type: 'date',
  },
  submission_date: {
    type: 'date',
  },
  hash_id: {
    type: 'string',
  },
  masked_pan: {
    type: 'number',
    transform: (pan) => Number(pan.replace('...', '')),
  },
  program: {
    type: 'string',
  },
  program_type: {
    type: 'string',
  },
  settled_total: {
    type: 'number',
  },
  submitted_fees: {
    type: 'number',
  },
  submitted_total: {
    type: 'number',
  },
};

const urlKeys = [
  'end_submitted_date',
  'start_submitted_date',
  'program_short_codes',
  'program_types',
  'page_token',
  'dna_bank_tokens',
  'originator',
];

const onError = () => {};

function filterOutAccountingGroup(amounts, accountingGroup) {
  return amounts.filter(
    ({ accounting_group_category }) => accounting_group_category !== accountingGroup
  );
}

function calculateSettledTotal(settledAmounts) {
  const amountsWithoutInterchange = filterOutAccountingGroup(
    settledAmounts,
    ACCOUNTING_GROUP_CATEGORIES.INTERCHANGE
  );
  return addUpScaledDecimalsArray(amountsWithoutInterchange);
}

function calculateSubmittedTotal(amounts) {
  const amountsWithoutInterchange = filterOutAccountingGroup(
    amounts,
    ACCOUNTING_GROUP_CATEGORIES.INTERCHANGE
  );
  return addUpScaledDecimalsArray(amountsWithoutInterchange);
}

function TableActions({ numSelected, rejectTransactions, approveTransactions, getTotalVariance }) {
  const [drawerActive, setDrawerActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [reason, setReason] = useState();
  const [note, setNote] = useState('');

  const validateForm = useCallback(() => {
    if (!reason) {
      setError('Please select a reason.');
      return false;
    }
    if (note.length > 1000) {
      setError('Maximum note length is 1000 characters.');
      return false;
    }

    setError('');
    return true;
  }, [reason, note]);

  const handleSubmit = useCallback(
    async (params) => {
      setLoading(true);
      if (validateForm() === true) {
        try {
          await rejectTransactions(params);
          setDrawerActive(false);
        } catch {
          // Empty catch block here to prevent unhandled exception.
          // This could occur if there was a GQL server error.
          // We want to keep the drawer active in that case.
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    },
    [rejectTransactions, validateForm]
  );
  return (
    <>
      <Flex flexDirection="row" alignItems="center">
        {numSelected > 0 && (
          <>
            <Text>{numSelected} selected</Text>
            <HSpacer factor={2} />
          </>
        )}
        <Button type="tertiary" onClick={() => setDrawerActive(true)} disabled={numSelected === 0}>
          Reject
        </Button>
        <HSpacer factor={1} />
        <Button
          type="tertiary"
          onClick={() => approveTransactions()}
          disabled={numSelected === 0 || drawerActive}
        >
          Approve
        </Button>
      </Flex>
      {numSelected > 0 && (
        <Drawer
          isOpened={drawerActive}
          orientation="right"
          backdrop={false}
          closeDrawer={() => setDrawerActive(false)}
          header={
            <div className={s.drawerHeader}>
              <Text type="h4">Reject</Text>
              <Icon type="close" onClick={() => setDrawerActive(false)} />
            </div>
          }
          footer={
            <div className={s.drawerFooter}>
              <Button type="tertiary" onClick={() => setDrawerActive(!drawerActive)}>
                Cancel
              </Button>
              <Button
                type="primary"
                loading={loading}
                onClick={() =>
                  handleSubmit({
                    reason,
                    note,
                  })
                }
              >
                {`Reject (${numSelected})`}
              </Button>
            </div>
          }
        >
          <Alert type="info">
            <Text>Hash IDs selected: {numSelected}</Text>
            <Text>Total submitted variance: {getTotalVariance()}</Text>
          </Alert>
          <VSpacer factor={3} />
          <Text>Rejecting hash IDs will send them to the exception queue for further review.</Text>
          <VSpacer factor={3} />
          <FormLabel size="small">Rejection reason</FormLabel>
          <Select
            onChange={(option) => {
              setReason(option.id);
              setError(false);
            }}
            options={REJECTION_OPTIONS.map((option) => ({
              id: option.id,
              val: option.id,
              render: option.label,
            }))}
            value={reason}
            placeholder="Select an option"
          />
          <VSpacer factor={3} />
          <Textarea
            label="Note"
            value={note}
            onChange={(e) => setNote(e.target.value)}
            maxLength={1000}
            noResize
            labelOptional
            rows={3}
            testId="approvals-bulk-action-note"
          />
          {error && <FormError>{error}</FormError>}
        </Drawer>
      )}
    </>
  );
}

const ApprovalSettlementDateQueue = ({ dynamicHeight }) => {
  // Determine inputs.
  const { location } = window;
  const urlParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const subnetwork = urlParams.get('subnetwork');
  const network = urlParams.get('network');
  const originator = urlParams.get('originator');
  const settlementDateParam = urlParams.get('date');
  const settlementDate = moment(settlementDateParam).format(DATE_FORMAT_STRING);

  const { pathParams } = routerStore;
  const go = useRouterGoContext();
  const adjustmentAction = useMemo(() => dashCaseToConstant(pathParams.type), [pathParams.type]);

  // Get toast message.
  const [toastMessage, setToastMessage] = useState(window.history.state?.toastMessage || null);

  useEffect(() => {
    if (window.history.state?.toastMessage) {
      window.history.replaceState(
        {
          ...window.history.state,
          toastMessage: null,
        },
        ''
      );
    }
  }, []);

  // Fetch Graphql data.
  const {
    data,
    loading: loadingQuery,
    error: loadingError,
    refetch: refetchOrig,
    variables: queryVariables,
  } = useQueryUrlParams(
    GET_SETTLED_DATE_QUEUE,
    {
      notifyOnNetworkStatusChange: true, // show loading screen on refresh
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: {
        subnetwork,
        page_size: PAGE_SIZE,
        settlement_date: settlementDate,
        adjustment_action: adjustmentAction,
        originator: originator,
      },
      onError,
    },
    urlKeys
  );
  const refetch = useCallback(
    (variables) => {
      if (!variables) return refetchOrig();

      // Fill missing variables with their current values.
      const fullVariables = {};
      for (const key of urlKeys) {
        if (key in variables) {
          fullVariables[key] = variables[key];
        } else {
          fullVariables[key] = queryVariables[key];
        }
      }
      return refetchOrig(fullVariables);
    },
    [refetchOrig, queryVariables]
  );

  // Inital variables.
  const initialDateFrom = () =>
    queryVariables.start_submitted_date
      ? moment(queryVariables.start_submitted_date).toDate()
      : null;
  const initialDateTo = () =>
    queryVariables.end_submitted_date ? moment(queryVariables.end_submitted_date).toDate() : null;

  // Available programs/banks/currencies/etc. for filter autocomplete.
  const { programs = [] } = useProgramSelectorStateContext();
  const [programShortCodeToProgram, setProgramShortCodeToProgram] = useState(new Map());
  const allPrograms = useMemo(
    () => Array.from(programShortCodeToProgram.values()),
    [programShortCodeToProgram]
  );

  useEffect(() => {
    if (programs.length) {
      const newProgramMap = new Map();

      // This "unknown" program is added to enable filtering by unknown programs.
      // It is added here because our programs list comes from Janus/Diva, which
      // does not contain "unknown".
      //
      // Add UNKNOWN to newProgramMap before everything else to ensure it shows
      // up first in the filter.
      const hasUnknown = programs.find((p) => p.short_name.toLowerCase() === 'unknown');
      if (!hasUnknown) {
        newProgramMap.set('UNKNOWN', {
          favorite: false,
          program: 'Unknown',
          short_name: 'UNKNOWN',
        });
      }

      for (const program of programs) {
        newProgramMap.set(program.short_name, program);
      }

      setProgramShortCodeToProgram(newProgramMap);
    }
  }, [programs]);

  const [allProgramTypes, setAllProgramTypes] = useState([]);
  const [allBanks, setAllBanks] = useState([]);
  useEffect(() => {
    if (data?.programTypes?.length) setAllProgramTypes(data.programTypes);
    if (data?.dnaBanks?.data?.length) setAllBanks(data.dnaBanks.data);
  }, [data]);

  const [approveOrRejectResponse, setApproveOrRejectResponse] = useState(false);

  // Transform exceptions into the queue fit for <Table> display.
  const [queue, setQueue] = useState([]);
  useEffect(() => {
    const exceptions = data?.settledDateQueue?.exceptions ?? [];

    if (exceptions.length) {
      setQueue(
        exceptions.map((item) => {
          const programObj = programShortCodeToProgram.get(item.program_short_code);

          const program_type =
            PROGRAM_TYPES[(programObj && programObj.program_type) || item.program_type];
          const settledTotal = calculateSettledTotal(item.settled_amounts);
          const submittedTotal = calculateSubmittedTotal(item.adjustment.amounts);
          const submittedVariance = number(settledTotal, {
            negativePattern: '!#',
            precision: 2,
          }).subtract(submittedTotal);
          const bank = item.bank;

          return {
            adjustment: item.adjustment,
            bank: bank ? bank.name : '',
            hash_id: item.hash_id,
            latest_jcard_error_response: item.latest_jcard_error_response,
            message_reason_code: item.message_reason_code,
            network_reference_id: item.network_reference_id,
            program: (programObj && programObj.program) || item.program_short_code,
            program_type: program_type,
            reason: item.reason,
            settled_total: settledTotal,
            source:
              item.source === 'MQ'
                ? 'Marqeta'
                : item.source === 'BOTH'
                ? 'Both'
                : `${NETWORKS[network]}`,
            state: item.state,
            submission_date: moment(item.adjustment.created_date).format(DATE_FORMAT_STRING),
            submitted_total: submittedTotal,
            submitted_variance: submittedVariance.format(),
            tx_hash_internal_id: item.tx_hash_internal_id,
            variance: submittedVariance.value,
          };
        })
      );
    } else {
      setQueue([]);
    }
  }, [data, network, programShortCodeToProgram]);

  // Bulk transaction selection.
  const { SelectRow, selectAllRows, selectedRows, clearSelections } = useRowSelector({
    rows: queue,
    idProp: 'tx_hash_internal_id',
  });

  // Table columns.
  const { columns, columnEditor } = useTableColumns({
    network,
    subnetwork,
    fieldSetId: 'settlement_date_queue',
    isBulkEditable: true,
    SelectRow,
    selectAllRows,
    columnsLocalStorageKey: COLUMNS_LOCAL_STORAGE_KEY,
  });

  // Bulk submit
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [numTxsApprovedForToast, setNumTxsApprovedForToast] = useState(0);
  const [numTxsRejectedForToast, setNumTxsRejectedForToast] = useState(0);

  const loading = loadingApprove || loadingQuery;

  const getTotalVariance = () => {
    let sum = 0;
    for (const item of queue) {
      if (selectedRows.ids.has(item.tx_hash_internal_id)) {
        sum += Math.abs(item.variance);
      }
    }
    return number(sum).format();
  };

  const handleRowClick = (row) => {
    const { tx_hash_internal_id } = row;
    const urlParams = urlParamsToObject();

    go(`/settlement/approvals/${routerStore.pathParams.type}/settlement-date/details`, {
      state: {
        type: adjustmentAction,
        ...urlParams,
        tx_hash_internal_id,
      },
      params: {
        ...urlParams,
        tx_hash_internal_id,
      },
    });
  };

  const [bulkRejectAdjustments] = useMutation(BULK_REJECT_ADJUSTMENTS);

  const handleBulkReject = useCallback(
    (params) => {
      const rejectedAdjustmentIds = Array.from(selectedRows.ids);
      const adjustmentIds = queue
        .filter(({ tx_hash_internal_id }) => selectedRows.ids.has(tx_hash_internal_id))
        .map(({ tx_hash_internal_id, adjustment }) => ({
          tx_hash_internal_id,
          adjustment_idx: adjustment.idx,
        }));
      return bulkRejectAdjustments({
        variables: {
          adjustment_ids: adjustmentIds,
          reason: params.reason,
          notes: params.note,
        },
      })
        .then((response) => {
          const { message, unsuccessful_tx_hash_internal_ids } =
            response?.data?.bulkRejectAdjustments ?? {};
          if (unsuccessful_tx_hash_internal_ids && unsuccessful_tx_hash_internal_ids.length) {
            // Indicate to the user that not all of the ids got rejected
            setApproveOrRejectResponse({
              message: message,
              heading: 'Rejection failed',
              error: true,
            });
            refetch();
          } else {
            // Indicate a total success
            setNumTxsRejectedForToast(rejectedAdjustmentIds.length);
            clearSelections();
            refetch();
          }
        })
        .catch((error) => {
          const alertMessage = createRejectOrApproveFailureMessage('rejected', {
            multiple: rejectedAdjustmentIds.length > 1,
          });
          setApproveOrRejectResponse({
            message: alertMessage,
            heading: 'Rejection failed',
            error: true,
          });
          throw error;
        });
    },
    [refetch, clearSelections, queue, selectedRows.ids, bulkRejectAdjustments]
  );

  const [bulkApprove] = useMutation(APPROVE_TRANSACTION_ADJUSTMENT);

  const handleApprove = useCallback(() => {
    setLoadingApprove(true);

    const approvedHashInternalIDs = Array.from(selectedRows.ids);
    const selectedTransactions = queue
      .filter(({ tx_hash_internal_id }) => selectedRows.ids.has(tx_hash_internal_id))
      .map(({ tx_hash_internal_id, adjustment }) => ({
        tx_hash_internal_id,
        adjustment_idx: adjustment.idx,
      }));

    bulkApprove({
      variables: {
        transactionApprovalIds: selectedTransactions,
      },
    })
      .then((response) => {
        const { message, unsuccessful_tx_hash_internal_ids } =
          response?.data?.approveTransactionAdjustment ?? {};
        if (unsuccessful_tx_hash_internal_ids && unsuccessful_tx_hash_internal_ids.length) {
          // Partial approval success
          setApproveOrRejectResponse({
            message,
            heading: 'Approval failed',
            error: true,
          });
          refetch().then(() => setLoadingApprove(false));
        } else {
          // Complete approval success
          setNumTxsApprovedForToast(approvedHashInternalIDs.length);
          clearSelections();
          refetch().then(() => setLoadingApprove(false));
        }
      })
      .catch(() => {
        // GraphQL server error
        const alertMessage = createRejectOrApproveFailureMessage('approved', {
          multiple: approvedHashInternalIDs.length > 1,
        });
        setApproveOrRejectResponse({
          message: alertMessage,
          heading: 'Approval failed',
          error: true,
        });
        setLoadingApprove(false);
      });
  }, [refetch, clearSelections, queue, selectedRows.ids, bulkApprove]);

  return (
    <div>
      <ApprovalFlowBreadcrumbs />
      <VSpacer factor={1} />
      <FlexContainer justifyContent="space-between">
        <FlexContainer direction="column">
          <Text type="label">
            {SUBNETWORKS[subnetwork]} submission total:{' '}
            {EXCEPTION_TYPES[adjustmentAction] || adjustmentAction} QUEUE
          </Text>
          <VSpacer factor={1} />
          <Text type="h3">Settlement date: {moment(settlementDateParam).format('MM-DD-YYYY')}</Text>
        </FlexContainer>
      </FlexContainer>
      <VSpacer factor={3} />
      {!queue?.length && loadingError ? (
        <ErrorLoading />
      ) : (
        <>
          <TableHeader>
            <FlexContainer style={{ width: '100%' }}>
              <TableFilters
                refetch={refetch}
                allBanks={allBanks}
                allPrograms={allPrograms}
                allProgramTypes={allProgramTypes}
                initialValues={queryVariables}
              />
              <HSpacer factor={2} />
              <DatePicker
                dateFromParam="start_submitted_date"
                dateToParam="end_submitted_date"
                initialDateFrom={initialDateFrom}
                initialDateTo={initialDateTo}
                refetch={refetch}
              />
              <HSpacer factor={2} />
              <div style={{ flex: '1' }} />
              <TableActions
                numSelected={selectedRows.ids.size}
                getTotalVariance={getTotalVariance}
                rejectTransactions={handleBulkReject}
                approveTransactions={handleApprove}
              />
              {columnEditor}
            </FlexContainer>
          </TableHeader>
          <VSpacer factor={1} />
          <div>
            <LoadingOverlay active={loading}>
              <TableSorter configuration={tableSortConfiguration}>
                <Table
                  loading={loading}
                  data={queue}
                  columns={columns}
                  fixedColumnCount={1}
                  rightAlignFixedColumns={true}
                  height={dynamicHeight}
                  onRowClick={handleRowClick}
                />
              </TableSorter>
              <VSpacer factor={1} />
              <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                <Pagination
                  refetch={refetch}
                  prevPageToken={data?.settledDateQueue?.prev_page_token ?? ''}
                  nextPageToken={data?.settledDateQueue?.next_page_token ?? ''}
                />
              </div>
            </LoadingOverlay>
          </div>
        </>
      )}
      {numTxsRejectedForToast > 0 && (
        <ToastAlert remove={() => setNumTxsRejectedForToast(0)} dismissTime={3}>
          {`${numTxsRejectedForToast} Hash ID${
            numTxsRejectedForToast > 1 ? 's have' : ' has'
          } been rejected`}
        </ToastAlert>
      )}

      {numTxsApprovedForToast > 0 && (
        <ToastAlert remove={() => setNumTxsApprovedForToast(0)} dismissTime={3}>
          {`${numTxsApprovedForToast} Hash ID${
            numTxsApprovedForToast > 1 ? 's have' : ' has'
          } been approved`}
        </ToastAlert>
      )}
      {toastMessage && (
        <ToastAlert remove={() => setToastMessage(null)} icon="success">
          {toastMessage}
        </ToastAlert>
      )}
      {approveOrRejectResponse.error && (
        <ErrorModal
          heading={approveOrRejectResponse.heading}
          hideModal={() => setApproveOrRejectResponse({ error: false })}
        >
          {approveOrRejectResponse.message}
        </ErrorModal>
      )}
    </div>
  );
};

export default containerHeightHOC(ApprovalSettlementDateQueue);
