import { useCallback, useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useRouterGoContext } from '../../components/router-go/context.js';
import {
  urlParamsToObject,
  pluckKeysFromObject,
  readObjValuesFromJson,
  pruneUrlParams,
  convertObjValuesToJson,
} from 'views/fds/utilities/url-params/index.js';

const onError = () => {};

/**
 * Wraps Apollo's useQuery to add and remove URL parameters
 * @param {Object} query - GraphQL query string
 * @param {Object} options - Apollo useQuery options
 * @param {Array} urlKeys - Array of keys to be tracked in the URL
 */
export default function useQueryUrlParams(query, options, urlKeys) {
  const go = useRouterGoContext();
  const params = urlParamsToObject();

  const pluckedKeys = useMemo(() => pluckKeysFromObject(params, urlKeys), [params, urlKeys]);

  const urlParamsForQuery = useMemo(() => readObjValuesFromJson(pluckedKeys), [pluckedKeys]);

  const queryVariables = useMemo(
    () => ({
      ...urlParamsForQuery,
      ...options.variables,
    }),
    [options.variables, urlParamsForQuery]
  );

  const { data, loading, error, refetch } = useQuery(query, {
    ...options,
    variables: queryVariables,
    onError,
  });

  const refetchAndPersistVariables = useCallback(
    (variables) => {
      if (!variables) {
        // Simple refresh, no variable changes.
        return refetch();
      }

      const prunedUrlParams = pruneUrlParams(urlKeys);
      const pluckedKeys = pluckKeysFromObject(variables, urlKeys);
      const newUrlParams = convertObjValuesToJson(pluckedKeys);

      const urlParams = {
        ...prunedUrlParams,
        ...newUrlParams,
      };

      go(null, urlParams, { replaceState: true });

      return refetch(variables);
    },
    [urlKeys, go, refetch]
  );

  return useMemo(
    () => ({
      data,
      loading,
      error,
      refetch: refetchAndPersistVariables,
      variables: queryVariables,
    }),
    [data, error, loading, queryVariables, refetchAndPersistVariables]
  );
}
