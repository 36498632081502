import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { LoadingOverlay, Text, VSpacer, HSpacer } from '@mqd/volt-base';
import { Table, Pagination, TableHeader } from '@mq/voltron-table';
import moment from 'moment';

import { useProgramSelectorStateContext } from 'components/program-selector/context.js';
import { useRouterGoContext } from 'components/router-go/context.js';
import routerStore from 'stores/router/RouterStore.js';
import useQueryUrlParams from 'utils/use-query-params-url/useQueryParamsToUrl.js';

import ApprovalFlowBreadcrumbs from 'views/fds/components/ApprovalFlowBreadcrumbs/ApprovalFlowBreadcrumbs.js';
import DatePicker from 'views/fds/components/DatePicker/DatePicker.js';
import ErrorLoading from 'views/fds/components/ErrorLoading/index.js';
import FlexContainer from 'views/fds/components/FlexContainer/FlexContainer.js';
import getFields from 'views/fds/network-configs/fields/index.js';
import TableFilters from 'views/fds/pages/ApprovalSettlementDateQueue/components/Filters.js';
import { PAGE_SIZE } from 'views/fds/utilities/config.js';
import { EXCEPTION_TYPES } from 'views/fds/utilities/dictionary/index.js';
import { dashCaseToConstant, constantToDashCase } from 'views/fds/utilities/string/index.js';

import { GET_EXCEPTIONS } from './constants.js';
import css from './styles.module.css';

const urlKeys = [
  'start_settlement_date',
  'end_settlement_date',
  'dna_bank_tokens',
  'originator',
  'program_short_codes',
  'program_types',
];

function ApprovalQueue() {
  // Get initial state from URL params.
  const { location } = window;
  const urlParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const network = urlParams.get('network');
  const subnetwork = urlParams.get('subnetwork');
  const originator = urlParams.get('originator');

  const tempInitialPage = parseInt(urlParams.get('page'));
  const initialPage =
    urlParams.get('page') && !isNaN(tempInitialPage) ? Math.max(tempInitialPage - 1, 0) : undefined;

  const { pathParams } = routerStore;
  const go = useRouterGoContext();
  const exceptionType = useMemo(() => dashCaseToConstant(pathParams.type), [pathParams.type]);

  // Fetch GraphQL data.
  const {
    data,
    loading,
    error,
    refetch: refetchOrig,
    variables: queryVariables,
  } = useQueryUrlParams(
    GET_EXCEPTIONS,
    {
      notifyOnNetworkStatusChange: true, // show loading screen on refresh
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: {
        subnetwork,
        type: exceptionType,
        page: initialPage,
        size: PAGE_SIZE,
        originator,
      },
      onError: () => {},
    },
    urlKeys
  );

  const refetch = useCallback(
    (variables) => {
      if (!variables) return refetchOrig();

      // Fill missing variables with their current values.
      const fullVariables = {};
      for (const key of urlKeys) {
        if (key in variables) {
          fullVariables[key] = variables[key];
        } else {
          fullVariables[key] = queryVariables[key];
        }
      }
      return refetchOrig(fullVariables);
    },
    [refetchOrig, queryVariables]
  );

  // Populate page data.
  const rows = data?.approvalExceptionTypeQueuePage?.settlement_date_lines || [];
  const hasMore = data?.approvalExceptionTypeQueuePage?.has_next_page || false;
  const page = data?.approvalExceptionTypeQueuePage?.page_number || 0;

  // Inital variables.
  const initialDateFrom = () =>
    queryVariables.start_settlement_date
      ? moment(queryVariables.start_settlement_date).toDate()
      : null;
  const initialDateTo = () =>
    queryVariables.end_settlement_date ? moment(queryVariables.end_settlement_date).toDate() : null;

  // Available programs/banks/etc. for filter autocomplete.
  const { programs = [] } = useProgramSelectorStateContext();
  const [programShortCodeToProgram, setProgramShortCodeToProgram] = useState(new Map());
  const allPrograms = useMemo(
    () => Array.from(programShortCodeToProgram.values()),
    [programShortCodeToProgram]
  );

  useEffect(() => {
    if (programs.length) {
      const newProgramMap = new Map();

      // This "unknown" program is added to enable filtering by unknown programs.
      // It is added here because our programs list comes from Janus/Diva, which
      // does not contain "unknown".
      //
      // Add UNKNOWN to newProgramMap before everything else to ensure it shows
      // up first in the filter.
      const hasUnknown = programs.find((p) => p.short_name.toLowerCase() === 'unknown');
      if (!hasUnknown) {
        newProgramMap.set('UNKNOWN', {
          favorite: false,
          program: 'Unknown',
          short_name: 'UNKNOWN',
        });
      }

      for (const program of programs) {
        newProgramMap.set(program.short_name, program);
      }

      setProgramShortCodeToProgram(newProgramMap);
    }
  }, [programs]);

  const [allProgramTypes, setAllProgramTypes] = useState([]);
  const [allBanks, setAllBanks] = useState([]);
  useEffect(() => {
    if (data?.programTypes?.length) setAllProgramTypes(data.programTypes);
    if (data?.dnaBanks?.data?.length) setAllBanks(data.dnaBanks.data);
  }, [data]);

  return (
    <>
      <ApprovalFlowBreadcrumbs />
      <VSpacer factor={3} />
      <Text type="label">Approval Queue</Text>
      <VSpacer factor={1} />
      <Text type="h3">{EXCEPTION_TYPES[exceptionType]}</Text>
      <LoadingOverlay active={loading}>
        {!rows.length && error ? (
          <ErrorLoading />
        ) : (
          <>
            <VSpacer factor={3} />
            <TableHeader>
              <FlexContainer>
                <TableFilters
                  refetch={refetch}
                  allBanks={allBanks}
                  allPrograms={allPrograms}
                  allProgramTypes={allProgramTypes}
                  initialValues={queryVariables}
                  testId={'approval-queue'}
                />
                <HSpacer factor={2} />
                <DatePicker
                  dateFromParam="start_settlement_date"
                  dateToParam="end_settlement_date"
                  initialDateFrom={initialDateFrom}
                  initialDateTo={initialDateTo}
                  refetch={refetch}
                />
              </FlexContainer>
            </TableHeader>
            <VSpacer factor={1} />
            <div data-testid="exception-type-queue-table">
              <Table
                data={rows}
                fixedColumnCount={1}
                onRowClick={({ row }) => {
                  const { settlement_date } = row;
                  const params = {
                    network,
                    subnetwork,
                    date: settlement_date,
                  };
                  // Don't persist the 'start_settlement_date' and 'end_settlement_date' params, the date filter
                  // in the settled date queue is different from the one this queue.
                  const keysToPersist = urlKeys.slice(2);
                  for (const key of keysToPersist) {
                    if (urlParams.has(key)) {
                      params[key] = urlParams.get(key);
                    }
                  }
                  go(`/settlement/approvals/${constantToDashCase(exceptionType)}/settlement-date`, {
                    params,
                    state: {
                      network: network,
                      subnetwork: subnetwork,
                      type: exceptionType,
                      ...urlParams,
                    },
                  });
                }}
                columns={getFields('approval_queue')}
              />
              <VSpacer factor={1} />
              <div className={css.tableFooter}>
                <Pagination
                  startIndex={0}
                  isMore={hasMore}
                  isLess={typeof page === 'number' && page > 0}
                  handleNextClick={() => refetch({ page: page + 1 })}
                  handlePreviousClick={() => refetch({ page: page - 1 })}
                />
              </div>
            </div>
          </>
        )}
      </LoadingOverlay>
    </>
  );
}

export default ApprovalQueue;
