import { decorate, observable, action, computed } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';
import BinStore from './BinStore';
import FulfillmentShippingStore from './FulfillmentShippingStore';

class CardProductFulfillmentStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.customLoad(args);
  }
  // values
  payment_instrument: string = '';
  fulfillment_provider: string = '';
  bin_prefix: string = '';
  package_id: string = '';
  program_id: string = '';
  bin: BinStore = {};
  shipping: FulfillmentShippingStore = {};

  // actions
  customLoad(args) {
    this.load(args);
    if (args.bins) {
      const { data } = args.bins;
      if (data && data.length > 0) {
        this.loadAndConstructItem('bin', data[0], BinStore);
      }
    }
    if (args.package_id) {
      const data = args.package_id.match(/(?<program_id>[0-9]+)::(?<package_id>[0-9]+)/);
      if (data) {
        const {
          groups: { program_id, package_id },
        } = data;
        this.setAttr('program_id', program_id);
        this.setAttr('package_id', package_id);
      }
    }

    if (args.shipping) {
      this.loadAndConstructItem('shipping', args.shipping, FulfillmentShippingStore);
    }
  }

  // computed
}

decorate(CardProductFulfillmentStore, {
  // values
  payment_instrument: observable,
  bin_prefix: observable,
  fulfillment_provider: observable,
  bin: observable,
  product_id: observable,
  package_id: observable,
  shipping: observable,

  // actions
  customLoad: action.bound,

  // computed
});

export default CardProductFulfillmentStore;
