import React, { PureComponent } from 'react';
import { Modal, Button, VSpacer, Textarea } from '@mqd/volt-base';
import PropTypes from 'prop-types';

class ApprovalModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      note: '',
    };
  }

  setModalCopy() {
    const { validatedFieldChanged, allFieldsSuccess } = this.props;
    const modalCopy = {
      header: 'Confirm changes?',
      message:
        'When these changes are confirmed, only a Marqeta Admin will be able to edit or change the status on these data elements moving forward. Would you like to proceed?',
      cta: 'Save',
    };
    if (validatedFieldChanged) {
      modalCopy.message =
        'One of the fields you changed is currently marked as a Success- are you sure you want to override this field value and save your changes?';
      modalCopy.cta = 'Confirm';
    }
    if (allFieldsSuccess) {
      modalCopy.header = 'Confirm activation?';
      modalCopy.message = (
        <>
          <strong>NOTE: This is the final verification to approve the business KYC.</strong> By
          confirming these fields, the business will be KYC approved and the account will
          automatically become active. Would you like to proceed?
        </>
      );
      modalCopy.cta = 'Activate';
    }
    return modalCopy;
  }

  render() {
    const { hideModal, noteChangeHandler } = this.props;
    const setChangeNote = (e) => this.setState({ note: e.target.value });
    const modalCopy = this.setModalCopy();

    return (
      <Modal
        heading={modalCopy.header}
        hideModal={hideModal}
        footerButtons={[
          <Button
            size="md"
            type="primary"
            onClick={(e) => {
              noteChangeHandler(this.state.note);
            }}
          >
            {modalCopy.cta}
          </Button>,
        ]}
      >
        {modalCopy.message}
        <VSpacer factor={2} />
        <Textarea
          label="Add note"
          maxLength={null}
          width="100%"
          value={this.state.note}
          onChange={setChangeNote}
        />
      </Modal>
    );
  }
}

ApprovalModal.propTypes = {
  hideModal: PropTypes.func,
  noteChangeHandler: PropTypes.func,
  validatedFieldChanged: PropTypes.bool,
  allFieldsSuccess: PropTypes.bool,
};

ApprovalModal.defaultProps = {
  hideModal: () => {},
  noteChangeHandler: () => {},
  validatedFieldChanged: false,
  allFieldsSuccess: false,
};

export default ApprovalModal;
