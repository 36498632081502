import React, { useEffect, useState } from 'react';
import {
  VSpacer,
  DetailPane,
  Form,
  Input,
  LoadingOverlay,
  ErrorModal,
  Select,
} from '@mqd/volt-base';
import { ButtonsGroup } from '../form-helpers';
import { useCardholderInfoContext, TOGGLE_EDIT_IDENTIFICATION } from '../context';
import { errorMessage, idFormValues } from '../utils';
import {
  DRIVERS_LICENSE,
  ERROR_MESSAGE_ACCOUNT_HOLDER_UPDATE,
  IDENTIFICATION_REGEX_MAP,
  IDENTIFICATION_TYPE_NAME_MAP,
  PASSPORT_NUMBER,
  PRIMARY_IDENTIFICATION_TYPES,
  SSN,
  IDENTIFICATION_CARD_NUMBER,
} from '../constants';

const EditIdentification = ({
  data = {},
  onSave,
  error,
  submitting,
  canViewPii = false,
  isAccountholderView = false,
}) => {
  /**
   * Accountholder Details fetches the full ssn (for users with pii access) as part of the initial query,
   * and passes it in the data.identifications array.
   *
   * User Details reveals full ssn (or TIN, etc.) by loading asynchronously.
   * That will have to be built into this somehow to display the current value of the pii ID number.
   * */

  const { dispatch } = useCardholderInfoContext();
  const { identifications, token, revealIdentification } = data || {};
  const [showModal, setShowModal] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [fetchedValue, setFetchedValue] = useState(false);

  let idType = SSN;
  let idIndex = 0;
  if (identifications) {
    identifications.forEach((id, i) => {
      if (PRIMARY_IDENTIFICATION_TYPES.includes(id.type)) {
        idType = id.type;
        idIndex = i;
        return;
      }
    });
  }

  const [identificationType, setIdentificationType] = useState(idType);
  const [identificationIndex, setIdentificationIndex] = useState(idIndex);
  const [identificationValue, setIdentificationValue] = useState(
    (identifications && identifications[identificationIndex].value) || ''
  );

  useEffect(() => {
    async function fetchIdentification() {
      if (canViewPii && revealIdentification) {
        await revealIdentification(idType);
        setIdentificationValue(identifications[identificationIndex].value);
      }
      setFetchedValue(true);
    }
    if (!fetchedValue && canViewPii) {
      fetchIdentification();
    }
  }, []);

  useEffect(() => {
    if (submitted && !error && !submitting) {
      dispatch({ type: TOGGLE_EDIT_IDENTIFICATION });
    } else if (submitted && error && !submitting) {
      setShowModal(true);
    }
  }, [submitted, error, submitting]);

  const programConfigData =
    (window.localStorage.getItem('programConfigData') &&
      JSON.parse(window.localStorage.getItem('programConfigData'))) ||
    {};
  const { internationalResponseFormatVersion } = programConfigData;

  const handleCancel = () => {
    dispatch({ type: TOGGLE_EDIT_IDENTIFICATION });
  };

  const saveForm = async (values) => {
    const filterArray = Object.keys(idFormValues(values)).filter(
      (key) => !PRIMARY_IDENTIFICATION_TYPES.includes(key) || key === identificationType
    );
    const idArray = filterArray.map((key) => {
      return { type: key, value: values[key] };
    });
    const params = {
      token,
      identifications: idArray,
    };
    setSubmitted(false);
    await onSave(params);
    setSubmitted(true);
  };

  const idHash = Array.isArray(identifications && identifications.slice()) // array.isarray returns false for observable arrays so we need to slice it into a normal array
    ? identifications.reduce((map, id) => {
        // This ensures that only users with access to view PII can edit PII fields
        if (canViewPii || !PRIMARY_IDENTIFICATION_TYPES.includes(id.type)) {
          map[id.type] = id.value;
        }
        return map;
      }, {})
    : {};

  const initialFormState = {
    ...idHash,
    idType: {
      val: identificationType,
      render: IDENTIFICATION_TYPE_NAME_MAP[identificationType],
    },
  };

  const identificationOptions = PRIMARY_IDENTIFICATION_TYPES.map((idType) => {
    return {
      val: idType,
      render: IDENTIFICATION_TYPE_NAME_MAP[idType],
    };
  });

  const updateForm = (formState) => {
    setIdentificationType(formState.idType ? formState.idType.val : SSN);
    if (formState[identificationType] !== identificationValue) {
      setIdentificationValue(formState[identificationType]);
    }
  };

  const loading = submitting || !fetchedValue;
  const isV2 = internationalResponseFormatVersion === 'V2';

  return (
    <>
      {showModal && (
        <ErrorModal
          heading={errorMessage(error || ERROR_MESSAGE_ACCOUNT_HOLDER_UPDATE)}
          hideModal={() => setShowModal(false)}
        >
          Try again
        </ErrorModal>
      )}
      <Form onFormChange={updateForm} initialState={initialFormState}>
        <LoadingOverlay borderRadius="small" active={loading}>
          <DetailPane.default layout="row">
            {canViewPii && (
              <>
                {!isAccountholderView && isV2 && (
                  <DetailPane.Content
                    label="Primary ID type"
                    tooltip="Selecting a new primary ID type will override the existing primary ID."
                    formElementId="primary-identification"
                  >
                    <Select options={identificationOptions} name="idType" />
                  </DetailPane.Content>
                )}
                <DetailPane.Content
                  label={IDENTIFICATION_TYPE_NAME_MAP[identificationType]}
                  formElementId={identificationType}
                >
                  <Input
                    disabled={submitting}
                    name={identificationType}
                    id={identificationType}
                    required
                    maxLength={11}
                    value={identificationValue}
                    errorValidators={[
                      {
                        validator: (value, formState) => {
                          const validatorPattern = IDENTIFICATION_REGEX_MAP[formState.idType.val];
                          const formattedId = validatorPattern.test(value);
                          return !formattedId;
                        },
                        message: 'Invalid format.',
                      },
                    ]}
                  />
                </DetailPane.Content>
              </>
            )}

            <DetailPane.Content
              label={IDENTIFICATION_TYPE_NAME_MAP[PASSPORT_NUMBER] + ' (optional)'}
              formElementId={PASSPORT_NUMBER}
            >
              <Input
                disabled={submitting}
                name={PASSPORT_NUMBER}
                id={PASSPORT_NUMBER}
                maxLength={40}
              />
            </DetailPane.Content>
            {internationalResponseFormatVersion === 'V2' && (
              <DetailPane.Content
                label={IDENTIFICATION_TYPE_NAME_MAP[DRIVERS_LICENSE] + ' (optional)'}
                formElementId={DRIVERS_LICENSE}
              >
                <Input
                  disabled={submitting}
                  name={DRIVERS_LICENSE}
                  id={DRIVERS_LICENSE}
                  maxLength={40}
                />
              </DetailPane.Content>
            )}
            {internationalResponseFormatVersion === 'V1' && (
              <DetailPane.Content
                label={IDENTIFICATION_TYPE_NAME_MAP[IDENTIFICATION_CARD_NUMBER] + ' (optional)'}
                formElementId={IDENTIFICATION_CARD_NUMBER}
              >
                <Input
                  disabled={submitting}
                  name={IDENTIFICATION_CARD_NUMBER}
                  id={IDENTIFICATION_CARD_NUMBER}
                  maxLength={40}
                />
              </DetailPane.Content>
            )}
          </DetailPane.default>
        </LoadingOverlay>
        <VSpacer factor={2} />
        {ButtonsGroup(submitting, handleCancel, saveForm)}
      </Form>
    </>
  );
};

export default EditIdentification;
