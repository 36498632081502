import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { ParentComponent } from '@mq/voltron-parent';
import { Activity, Col, Container, Row, VSpacer, LoadingOverlay } from '@mqd/volt-base';
import DocumentManager from './components/document-manager/DocumentManager';
import ReviewStore from '../stores/ReviewStore';
import ReviewHeader from './ReviewHeader';
import ReviewActionRow from './ReviewActionRow';
import KYCDetails from './KYCDetails';
import s from './ReviewDetails.module.css';
import { kycStatuses } from '../shared-components/kyc-status/constants';

class ReviewDetails extends ParentComponent {
  constructor(props) {
    super(props);
    this.storeConstructor = ReviewStore;
    this.state = {
      showAll: false,
    };
  }

  getUserInitials() {
    const { userStore } = this.props;
    if (userStore) {
      const { firstName, lastName } = userStore;
      return firstName[0].toUpperCase() + lastName[0].toUpperCase();
    }

    return '';
  }

  toggleShowAllActivities() {
    this.setState({ showAll: true });
  }

  render() {
    const {
      loadingReview,
      updateReviewDisposition,
      kycStatus,
      addNoteToReview,
      activityLog,
      changeReviewTeam,
      dispositions,
      transitionReasonCodes,
      changeReviewStatus,
      reviewDocuments,
      deleteReviewDocument,
      addControlPlaneReviewDocument,
      loadingReviews,
      missingNewDocument,
      key,
    } = this.store;
    const {
      canEditKycExceptions,
      canEditRiskControlKyc,
      canRevealIdentification,
      hasMarqetaEmail,
    } = this.props.userStore;

    const statusOptions = dispositions.map((status) => ({
      value: status,
      label: kycStatuses[status].label,
    }));

    const canEditReview = canRevealIdentification && canEditRiskControlKyc;

    return (
      <div style={{ width: '100%' }} data-testid="review-details">
        <LoadingOverlay active={loadingReview}>
          <ReviewHeader
            addNoteToReview={addNoteToReview}
            buildBreadcrumb={this.props.buildBreadcrumb}
            canEditReview={canEditReview}
            canViewActionButtons={canEditKycExceptions}
            changeReviewStatus={changeReviewStatus}
            changeReviewTeam={changeReviewTeam}
            dispositions={dispositions}
            internalUser={hasMarqetaEmail}
            key={key}
            kycStatus={kycStatus}
            reviewStore={this.store}
            statusOptions={statusOptions}
            transitionReasonCodes={transitionReasonCodes}
            updateReviewDisposition={updateReviewDisposition}
          />
          <VSpacer factor={2} />
          <ReviewActionRow
            reviewStore={this.store}
            internalUser={hasMarqetaEmail}
            canEditReview={canEditReview}
          />
          <VSpacer factor={4.5} />
          <Container gutter={24}>
            <Row>
              <Col span={5}>
                <KYCDetails
                  canEditReview={canEditReview}
                  canRevealIdentification={canRevealIdentification}
                  internalUser={hasMarqetaEmail}
                  key={key}
                  reviewStore={this.store}
                  statusOptions={statusOptions}
                />
              </Col>
              <Col span={7}>
                <DocumentManager
                  canEditReview={canEditReview}
                  deleteFile={deleteReviewDocument}
                  documents={reviewDocuments}
                  key={key}
                  loadingReviews={loadingReviews}
                  missingNewDocument={missingNewDocument}
                  uploadFiles={addControlPlaneReviewDocument}
                />
              </Col>
            </Row>
          </Container>
          <VSpacer factor={4} />
          <div className={s.activityLog}>
            <Activity
              testId="activity-log"
              activities={activityLog}
              addActivityHandler={addNoteToReview}
              fourColumn={false}
              header="Activity log"
              hideInput={!canEditReview}
              key={key}
              showAllActivities={this.state.showAll}
              userInitials={this.getUserInitials()}
              viewAllHandler={() => this.toggleShowAllActivities()}
            />
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

ReviewDetails.propTypes = {
  buildBreadcrumb: PropTypes.func,
  token: PropTypes.string,
  storeInitArgs: PropTypes.object,
  onStoreConstruction: PropTypes.func,
  userStore: PropTypes.any,
  autoHydrate: PropTypes.bool,
};

ReviewDetails.defaultProps = {
  buildBreadcrumb: () => {},
  token: '',
  storeInitArgs: {},
  onStoreConstruction: null,
  userStore: {},
  autoHydrate: true,
};

export default observer(ReviewDetails);
