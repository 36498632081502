import currency from 'currency.js';
import trueType from '../true-type/index.js';

/**
 * Default options passed to currency.js.
 * @type {Object}
 */
const DEFAULTS = {
  symbol: '',
  separator: ',',
  decimal: '.',
  precision: 2,
  pattern: '!#',
  negativePattern: '(!#)',
  fromCents: false,
  errorOnInvalid: false,
  increment: null,
  useVedic: false,
};

/**
 * Create formattable number object to make it easy to work with currency and
 * decimals.
 *
 * @param {String|Number|ScaledDecimal} value  Value to format or convert to currency instance. Can
 *    be a string, number, or ScaledDecimal (with `units` and `scale` properties).
 * @param {Object} ?options={}  Options passed to currency.js.
 * @param {Boolean} ?format=false  Whether to return the formatted value or the currency instance.
 *    NOTE: If no options are desired, you may omit options and pass format as the 2nd argument.
 * @return {String|Currency}  If `format` is true, will return the string formatted value.
 *    Otherwise an instance of currency is returned.
 */
export default function number(value, options = {}, format = false) {
  let result = value;
  const isObject = trueType(value) === 'object';
  const isValidType = isObject || trueType(value) === 'string' || trueType(value) === 'number';

  // If value is not a String|Number|ScaledDecimal, return value.
  if (!isValidType) return value;

  // If options equals true or format is true, we want return the formatted
  // value. Otherwise we return the currency instance. Either way, we don't want
  // to collid with currency's `format` property, so we handle that here.
  if (options === true) {
    format = true;
    options = {};
  }

  // Merge default options.
  options = Object.assign({}, DEFAULTS, options);

  // If value is an object, convert it to a floating number.
  if (isObject) {
    // Ensure value.scale is a number.
    if (typeof value.scale !== 'number' || value.scale < 0) {
      value.scale = 2;
    }
    // If precision and scale are not the same, we use scale for precision, and
    // we use precision later on to fix the number of digits after the decimal.
    if (options.precision !== value.scale) {
      options.numDecimals = options.precision;
    }
    options.precision = value.scale;
    options.fromCents = true;
    result = value.units;
  }

  // Now we create an instance of currency.
  result = currency(result, options);

  // If precision !== scale (i.e., options.numDecimals is a number), we fix the
  // number of digits after the decimal here.
  if (typeof options.numDecimals === 'number') {
    const n = result.value.toFixed(options.numDecimals);
    result = currency(
      n,
      Object.assign({}, options, { fromCents: false, precision: options.numDecimals })
    );
  }

  // If `options.format` is truthy, return a formatted string.
  if (format) result = result.format();

  // ...otherwise return the currency object.
  return result;
}
