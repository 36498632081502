import React from 'react';
import PropTypes from 'prop-types';
import { ContentSnippet, VSpacer, Text } from '@mqd/volt-base';
import { ParentDetailCard, Status } from '@mq/voltron-parent';

import { formatUTCMoment } from '../shared-utils';

class DigitalWalletTokensDetailsCard extends ParentDetailCard {
  renderStatusSnippet(label, statusName, displayText) {
    return (
      <div style={{ width: 360 }}>
        <ContentSnippet
          title={label}
          testId={`volt-content-snippet_title-${label}`}
          horizontalLayout
        >
          <div>
            <Status status={statusName} onlyDisplayChildren={!!displayText}>
              {displayText}
            </Status>
          </div>
        </ContentSnippet>
        <VSpacer factor={2} />
      </div>
    );
  }

  render() {
    const {
      token_reference_id,
      pan_reference_id,
      token_fulfillment_status,
      formattedFulfillmentStatus,
      issuer_eligibility_decision,
      created_time,
      last_modified_time,
      language_code,
    } = this.props;

    const createdAtFormatted = created_time ? formatUTCMoment(created_time) : '';
    const lastModifiedFormatted = last_modified_time ? formatUTCMoment(last_modified_time) : '';

    return (
      <div data-testid="token-details">
        <Text type="h4">Token details</Text>
        <VSpacer factor={2} />

        {this.renderSnippet({
          title: 'Token reference ID',
          content: token_reference_id,
        })}

        {this.renderSnippet({
          title: 'PAN reference ID',
          content: pan_reference_id,
        })}
        {this.renderSnippet({
          title: 'Token fulfillment status',
          content: formattedFulfillmentStatus,
        })}
        {this.renderSnippet({
          title: 'Issuer eligibility decision',
          content: issuer_eligibility_decision,
        })}
        {this.renderSnippet({
          title: 'Time created',
          content: createdAtFormatted,
        })}
        {this.renderSnippet({
          title: 'Last modified',
          content: lastModifiedFormatted,
        })}
        {this.renderSnippet({
          title: 'Language code',
          content: language_code,
        })}
      </div>
    );
  }
}

DigitalWalletTokensDetailsCard.defaultProps = {
  token_reference_id: '',
  pan_reference_id: '',
  token_fulfillment_status: '',
  formattedFulfillmentStatus: '',
  issuer_eligibility_decision: '',
  created_time: '',
  last_modified_time: '',
  language_code: '',
};

DigitalWalletTokensDetailsCard.propTypes = {
  token_reference_id: PropTypes.string,
  pan_reference_id: PropTypes.string,
  token_fulfillment_status: PropTypes.string,
  formattedFulfillmentStatus: PropTypes.string,
  issuer_eligibility_decision: PropTypes.string,
  created_time: PropTypes.string,
  last_modified_time: PropTypes.string,
  language_code: PropTypes.string,
};

export default DigitalWalletTokensDetailsCard;
