import React from 'react';
import { flipFlop } from '@mq/volt-amc-container';
import {
  acceptedCountriesAssociatedPaths,
  adminUsermanagementAssociatedPaths,
  applicationTokensAssociatedPaths,
  approvalQueueAssociatedPaths,
  binAssociatedPaths,
  binDataManagementAssociatedPaths,
  bulkCardProductAssociatedPaths,
  bundlesAssociatedPaths,
  cardManagementInventoryAssociatedPaths,
  cardOrderAndShipmentAssociatedPaths,
  cardProductsAssociatedPaths,
  commandoModeAssociatedPaths,
  creditAccountsAssociatedPaths,
  creditApprovalQueueAssociatedPaths,
  creditOffersAssociatedPaths,
  creditProductsAssociatedPaths,
  creditUsersAssociatedPaths,
  customersAccountholdersAssociatedPaths,
  customersCardholdersAssociatedPaths,
  dataCatalogAssociatedPaths,
  hsmKeysAssociatedPaths,
  mccGroupsAssociatedPaths,
  policyLibraryAssociatedPaths,
  programCardProductAssociatedPaths,
  programControlsAssociatedPaths,
  providersAssociatedPaths,
  riskDisputesAssociatedPaths,
  settlementsApprovalsAssociatedPaths,
  settlementsExceptionsAssociatedPaths,
  settlementsProgramReservePaths,
  templatesAssociatedPaths,
  workQueueAssociatedPaths,
} from './associated-paths.js';

import { isProgramConfigFlagEnabled } from 'utils/route-authorization/routes/functions';
const isRiskControlReviewManager = flipFlop.get('risk-control-review-manager', false);
const canViewDataCatalog = flipFlop.get('data-marketplace', false);
const hasControlPlaneV1 = isProgramConfigFlagEnabled('ENABLE_CONTROLPLANE_DECISION_REPORTS');
const hasControlPlaneV2 = isProgramConfigFlagEnabled('ENABLE_CONTROLPLANE_DASHBOARD');

/**
 * IMPORTANT: DO NOT MUTATE OR INTERACT DIRECTLY WITH CFN_CONFIG. CFN_CONFIG is
 * the source configuration for CFN workspaces and left nav links. It gets deep
 * cloned with CFNLeftNav into a usable format.
 */
const CFN_CONFIG = {
  dashboard: {
    title: 'Dashboard',
    authorized: true,
    programSelector: true,
    leftNav: [
      {
        title: 'Home',
        path: '/home',
      },
      {
        title: 'Digital wallet',
        path: '/program/token-service',
        associatedPaths: ['/program/token-service/token'],
      },
      {
        title: 'Card management',
        id: '/card-management',
        children: [
          {
            title: 'Card products',
            path: '/program/card-products',
            associatedPaths: programCardProductAssociatedPaths,
          },
          {
            title: 'Bulk issuance',
            path: '/program/bulk-issuances',
            associatedPaths: bulkCardProductAssociatedPaths,
          },
          {
            title: 'Providers',
            path: '/program/providers',
            associatedPaths: providersAssociatedPaths,
          },
          {
            title: 'Inventory management',
            path: '/program/card-management-inventories',
            associatedPaths: cardManagementInventoryAssociatedPaths,
          },
          {
            title: 'Card order and shipment report',
            path: '/program/card-order-and-shipment',
            associatedPaths: cardOrderAndShipmentAssociatedPaths,
          },
          {
            title: 'HSM keys',
            path: '/program/hsm-keys',
            associatedPaths: hsmKeysAssociatedPaths,
          },
        ],
      },
      {
        title: 'Customers',
        id: '/customers',
        children: [
          {
            title: 'Account holders',
            path: '/program/accountholders',
            associatedPaths: customersAccountholdersAssociatedPaths,
          },
          {
            title: 'Users',
            path: '/program/users',
            associatedPaths: customersCardholdersAssociatedPaths,
          },
          {
            title: 'Businesses',
            path: '/program/businesses',
          },
        ],
      },
      {
        title: 'RiskControl',
        id: '/control-plane',
        children: [
          {
            title: 'Disputes',
            path: '/risk/disputes',
            associatedPaths: riskDisputesAssociatedPaths,
          },
          {
            path: '/risk/bank-reporting',
            title: 'Disputes bank reporting',
          },
          {
            title: 'Real-time decisioning',
            hidden: !hasControlPlaneV1,
            path: '/risk/transaction-report?real-time-decisioning=v1',
          },
          {
            title: 'Real-time decisioning dashboard',
            hidden: !hasControlPlaneV2,
            path: '/risk/transaction-report?real-time-decisioning=v2',
          },
          {
            title: 'Transaction alerts',
            path: '/risk/alerts',
          },
          {
            title: 'Transaction review report',
            path: '/risk/transaction-report',
          },
        ],
      },
      {
        title: 'Risk management',
        children: [
          {
            title: 'Risk management',
            path: '/risk/fraudstream/home',
          },
          {
            title: 'Fraud transactions',
            path: '/risk/fraudstream/transactions',
          },
          {
            title: 'Fraud history',
            path: '/risk/fraudstream/history',
          },
          {
            title: 'Fraud health',
            path: '/risk/fraudstream/health',
          },
          {
            title: 'Manual review',
            path: '/risk/decision-manager',
          },
          {
            title: 'Case management',
            path: '/risk/case-management',
          },
        ],
      },
      /* {
        title: 'Balance',
        path: '/program/program-balance',
      }, */
      {
        title: 'Credit Programs',
        id: '/credit',
        children: [
          {
            title: 'Welcome',
            path: '/credit/welcome',
            // hidden: true,
          },
          {
            title: 'Policy library',
            path: '/credit/policy-library',
            associatedPaths: policyLibraryAssociatedPaths,
          },
          {
            title: 'Bundles',
            path: '/credit/bundles',
            associatedPaths: bundlesAssociatedPaths,
          },
          {
            title: 'Work queue',
            path: '/credit/work-queue',
            associatedPaths: workQueueAssociatedPaths,
          },
          {
            title: 'Approval queue',
            path: '/credit/approval-queue',
            associatedPaths: creditApprovalQueueAssociatedPaths,
          },
          {
            title: 'Credit products',
            path: '/credit/credit-products',
            associatedPaths: creditProductsAssociatedPaths,
          },
          {
            title: 'Offers',
            path: '/credit/acquisition-offers',
            associatedPaths: creditOffersAssociatedPaths,
          },
          {
            title: 'Account holders',
            hidden: !isRiskControlReviewManager,
            path: '/credit/accounts',
            associatedPaths: creditAccountsAssociatedPaths,
          },
          {
            title: 'Credit users',
            path: '/credit/users',
            associatedPaths: creditUsersAssociatedPaths,
          },
          {
            title: 'Templates',
            path: '/credit/templates',
            associatedPaths: templatesAssociatedPaths,
          },
        ],
      },
      {
        title: 'Settlement',
        id: '/settlement',
        children: [
          {
            title: 'Exceptions',
            path: '/settlement/exceptions',
            nestedAssociatedPaths: settlementsExceptionsAssociatedPaths,
          },
          {
            title: 'Approvals',
            path: '/settlement/approvals',
            nestedAssociatedPaths: settlementsApprovalsAssociatedPaths,
          },
          {
            title: 'Program funding',
            path: '/settlement/program-funding',
            nestedAssociatedPaths: settlementsProgramReservePaths,
          },
          {
            title: 'Export queue',
            path: '/settlement/export-queue',
          },
        ],
      },
    ],
  },
  dataInsights: {
    title: 'Data & Insights',
    authorized: true,
    programSelector: false,
    leftNav: [
      {
        title: 'Reports',
        path: '/reports',
      },
      {
        title: 'Data Marketplace',
        hidden: !canViewDataCatalog,
        path: '/data-catalog',
        associatedPaths: dataCatalogAssociatedPaths,
      },
      {
        title: 'Reports guide',
        path: '/reports/guide',
      },
      {
        title: 'Data dictionary',
        path: '/reports/data-dictionary',
      },
    ],
  },
  programConfig: {
    title: 'Program Controls',
    authorized: true,
    programSelector: true,
    leftNav: [
      {
        title: 'Control center',
        id: '/control-center',
        children: [
          {
            title: 'Commando mode',
            path: '/program/commando-modes',
            associatedPaths: commandoModeAssociatedPaths,
          },
          {
            title: 'BIN Summary',
            path: '/bin-management',
            associatedPaths: binAssociatedPaths,
          },
          {
            title: 'BIN Data Management',
            path: '/bin-data-management',
            associatedPaths: binDataManagementAssociatedPaths,
          },
          {
            title: 'User profiles',
            path: '/admin',
            associatedPaths: adminUsermanagementAssociatedPaths,
          },
          {
            title: 'Sandbox Management',
            path: '/admin/sandbox-management',
          },
          {
            title: 'Audit logs',
            path: '/audit-logs',
          },
          {
            title: 'ACH management',
            path: '/program/ach-management',
          },
          {
            title: 'ACH program funding',
            path: '/program/ach-funding',
            associatedPaths: [
              '/program/ach-funding/transfer',
              '/program/ach-funding/transfer-funds',
            ],
          },
          {
            title: 'PIN reveal',
            path: '/program/pin-reveal',
          },
          {
            title: '3D secure',
            path: '/program/3d-secure',
          },
          {
            title: 'Accepted countries',
            path: '/accepted-countries',
            associatedPaths: acceptedCountriesAssociatedPaths,
          },
          {
            title: 'Application tokens',
            path: '/application-tokens',
            associatedPaths: applicationTokensAssociatedPaths,
          },
          {
            title: 'Approval queue',
            path: '/approval-queue',
            associatedPaths: approvalQueueAssociatedPaths,
          },
          {
            title: 'Card products',
            path: '/card-products',
            associatedPaths: cardProductsAssociatedPaths,
          },
          {
            title: 'MCC groups',
            path: '/mcc-groups',
            associatedPaths: mccGroupsAssociatedPaths,
          },
          {
            title: 'Programs',
            path: '/program-controls',
            associatedPaths: programControlsAssociatedPaths,
          },
        ],
      },
    ],
  },
  privateSandbox: {
    title: () => <h1>Private Sandbox</h1>,
    leftNav: [
      {
        title: 'API keys',
        path: '/development',
      },
      {
        title: 'Transaction timeline',
        path: '/development/transaction-timeline',
      },
      {
        title: 'Webhooks',
        path: '/development/webhooks',
        associatedPaths: ['/development/add-webhook', '/development/webhooks/show'],
      },
    ],
  },
};

export { CFN_CONFIG };
