import React from 'react';
import PropTypes from 'prop-types';
import s from './NavBar.module.css';

function NavLinkUnderline({ underlineReset, underlineStyle }) {
  return (
    <div
      className={`${s.underline} ${underlineReset ? s.underlineReset : ''}`}
      style={underlineStyle}
    />
  );
}

NavLinkUnderline.propTypes = {
  underlineReset: PropTypes.bool,
  underlineStyle: PropTypes.object,
};
NavLinkUnderline.defaultProps = {
  underlineReset: false,
  underlineStyle: {},
};

export default NavLinkUnderline;
