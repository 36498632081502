import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { HSpacer, PageLoader, Select, Text, VSpacer } from '@mqd/volt-base';
import { useQuery } from '@apollo/react-hooks';
import { TableHeader } from '@mq/voltron-table';
import moment from 'moment';

import routerStore from '../../../../stores/router/RouterStore.js';
import { useProgramSelectorStateContext } from '../../../../components/program-selector/context.js';
import { format as formatDate } from '../../utilities/date.js';
import { NETWORKS } from '../../utilities/dictionary/index.js';
import QueueSummaries from '../../components/QueueSummaries/index.js';
import ErrorLoading from '../../components/ErrorLoading/index.js';

import FlexContainer from 'views/fds/components/FlexContainer/FlexContainer.js';
import { parseSummaries } from '../../components/SummaryPage/utilities.js';
import pageConfig from '../../components/SummaryPage/config.js';
import { constantToDashCase } from '../../utilities/string/index.js';
import TableFilters from 'views/fds/pages/ExceptionQueue/components/Filters.js';
import DatePicker from 'views/fds/components/DatePicker/DatePicker.js';
import useFetchUrlParams from 'views/fds/hooks/useFetchUrlParams.js';

import { GET_FILTER_DATA } from './query.js';

const type = 'exceptions';

const urlKeys = [
  'end_settlement_date',
  'start_settlement_date',
  'program_short_codes',
  'program_types',
  'settlement_currencies',
  'dna_bank_tokens',
  'ica_values',
];

function ExceptionSummaryPage() {
  // Determine inputs.
  const urlParams = new URLSearchParams(window.location.search);
  const networkParam = urlParams.get('network');

  // Filter variables.
  const [network, setNetwork] = useState(networkParam || NETWORKS.Visa);
  const { headings } = React.useMemo(() => pageConfig[type], []);

  // Fetch data.
  const {
    data,
    loading: loadingSummaries,
    error: summariesError,
    refetch: refetchOrig,
    body: summariesVariables,
  } = useFetchUrlParams(
    `/fds/networks/${network}/exception-summary`,
    { method: 'POST' },
    { urlKeys, location: 'body' }
  );

  const refetch = useCallback(
    (body) => {
      if (!body) return refetchOrig();

      // Fill missing variables with their current values.
      const fullBody = {};
      for (const key of urlKeys) {
        if (key in body) {
          fullBody[key] = body[key];
        } else {
          fullBody[key] = summariesVariables[key];
        }
      }
      return refetchOrig({ body: fullBody });
    },
    [refetchOrig, summariesVariables]
  );

  const [summaries, setSummaries] = useState([]);
  const [updatedDate, setUpdatedDate] = useState(formatDate(new Date(), true));
  useEffect(() => {
    const hasData = data?.ok;
    const summaries = [];
    if (hasData) {
      Object.keys(pageConfig['exceptions'].summaryProps).forEach((prop) => {
        if (!data.data[prop]) return;
        const summaryGroup = data.data[prop];
        summaries.push({
          label: pageConfig['exceptions'].summaryProps[prop],
          data: parseSummaries(summaryGroup, 'exceptions'),
        });
      });
      setSummaries([...summaries]);
      setUpdatedDate(formatDate(new Date(), true));
    } else {
      setSummaries([]);
    }
  }, [data]);

  // Available programs/banks/currencies/etc. for filter autocomplete.
  const { programs = [] } = useProgramSelectorStateContext();
  const [programShortCodeToProgram, setProgramShortCodeToProgram] = useState(new Map());
  const allPrograms = useMemo(
    () => Array.from(programShortCodeToProgram.values()),
    [programShortCodeToProgram]
  );

  useEffect(() => {
    if (programs.length) {
      const newProgramMap = new Map();

      // This "unknown" program is added to enable filtering by unknown programs.
      // It is added here because our programs list comes from Janus/Diva, which
      // does not contain "unknown".
      //
      // Add UNKNOWN to newProgramMap before everything else to ensure it shows
      // up first in the filter.
      const hasUnknown = programs.find((p) => p.short_name.toLowerCase() === 'unknown');
      if (!hasUnknown) {
        newProgramMap.set('UNKNOWN', {
          favorite: false,
          program: 'Unknown',
          short_name: 'UNKNOWN',
        });
      }

      for (const program of programs) {
        newProgramMap.set(program.short_name, program);
      }

      setProgramShortCodeToProgram(newProgramMap);
    }
  }, [programs]);

  // Initial variables.
  const initialDateFrom = () =>
    summariesVariables?.start_settlement_date
      ? moment(summariesVariables.start_settlement_date).toDate()
      : null;
  const initialDateTo = () =>
    summariesVariables?.end_settlement_date
      ? moment(summariesVariables.end_settlement_date).toDate()
      : null;

  // Fetch programTypes, dnaBanks, currencyCodes.
  const { data: filterData, loading: loadingFilter } = useQuery(GET_FILTER_DATA, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  });

  // Queue filters.
  const [allProgramTypes, setAllProgramTypes] = useState([]);
  const [allBanks, setAllBanks] = useState([]);
  const [allCurrencyCodes, setAllCurrencyCodes] = useState([]);
  useEffect(() => {
    if (filterData?.programTypes?.length) setAllProgramTypes(filterData.programTypes);
    if (filterData?.dnaBanks?.data?.length) setAllBanks(filterData.dnaBanks.data);
    if (filterData?.currencyCodes?.length) setAllCurrencyCodes(filterData.currencyCodes);
  }, [filterData]);

  // Network filter.
  const onSelectNetwork = (newNetwork) => {
    setNetwork(NETWORKS[newNetwork]);
    urlParams.set('network', NETWORKS[newNetwork]);
    routerStore.go('?' + urlParams.toString());
  };

  const loading = loadingSummaries || loadingFilter;

  return (
    <>
      <Text type="h3">Exceptions</Text>
      <VSpacer />
      <Text type="footnote">Last updated {updatedDate}</Text>
      <VSpacer factor={2} />
      <TableHeader>
        <FlexContainer style={{ width: '100% ' }}>
          <Select
            options={[
              NETWORKS.NETWORK_DISCOVER,
              NETWORKS.NETWORK_MASTERCARD,
              NETWORKS.NETWORK_PULSE,
              NETWORKS.NETWORK_VISA,
            ]}
            onChange={onSelectNetwork}
            width="162px"
            value={NETWORKS[network]}
          />
          <HSpacer />
          <TableFilters
            refetch={refetch}
            allBanks={allBanks}
            allCurrencyCodes={allCurrencyCodes}
            allPrograms={allPrograms}
            allProgramTypes={allProgramTypes}
            initialValues={summariesVariables}
            network={network}
            testId="exception-summary"
          />
          <HSpacer />
          <DatePicker
            dateFromParam="start_settlement_date"
            dateToParam="end_settlement_date"
            initialDateFrom={initialDateFrom}
            initialDateTo={initialDateTo}
            refetch={refetch}
          />
        </FlexContainer>
      </TableHeader>
      <VSpacer factor={3} />

      {loading ? (
        <PageLoader active={loading} />
      ) : summariesError && !summaries?.length ? (
        <ErrorLoading />
      ) : (
        summaries.map((summaryGroup) => (
          <QueueSummaries
            key={summaryGroup.label}
            label={summaryGroup.label}
            headings={headings}
            summaries={summaryGroup.data}
            onRowClick={({ summary, row }) => {
              const params = {
                network,
                subnetwork: summary.subnetwork,
                originator: summary.originator,
              };
              for (const key of urlKeys) {
                if (urlParams.has(key)) {
                  params[key] = urlParams.get(key);
                }
              }
              return routerStore.go(`/settlement/exceptions/${constantToDashCase(row.type)}`, {
                params,
              });
            }}
            error={summariesError}
          />
        ))
      )}
    </>
  );
}

export default ExceptionSummaryPage;
