import { useContext } from 'react';
import { ContextTransactionList } from '../reducers';

const useTransactionListStateContext = () => {
  const context = useContext(ContextTransactionList);

  if (context === undefined) {
    throw new Error('useTransactionListStateContext must be used within a ContextTransactionList');
  }

  return context.state;
};

export default useTransactionListStateContext;
