import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Flex, Form, Input, VSpacer, CountrySelect } from '@mqd/volt-base';
import { defaultCountryCode, defaultCountryName } from '../utils/constants';
import PhoneWrapper from './PhoneWrapper.js';
import s from './styles.module.css';
import { findCountryByName } from '../utils';

function AddressEdit({ formState, handleFormChange, children, requiredFields }) {
  const [countryName, setCountryName] = useState(formState.country || defaultCountryName);

  // It is a trick to re-render component on delete event
  if (!formState) {
    handleFormChange({});
    return null;
  }

  const onFormChange = (nextFormState) => {
    handleFormChange({ ...nextFormState, country: countryName });
  };

  return (
    <section data-testid="bulk-issuance-form-address-edit">
      <Form onFormChange={onFormChange} initialState={formState}>
        <Input
          name="firstName"
          label="First name / Company name"
          maxLength={33}
          required={requiredFields['firstName']}
        />
        <VSpacer factor={3} />
        <Input name="middleName" label="Middle name" maxLength={33} labelOptional />
        <VSpacer factor={3} />
        <Input
          name="lastName"
          label="Last name / Company name"
          maxLength={33}
          required={requiredFields['lastName']}
        />
        <VSpacer factor={3} />
        <Input
          name="address1"
          label="Address line 1"
          maxLength={100}
          required={requiredFields['address1']}
        />
        <VSpacer factor={3} />
        <Input name="address2" label="Address line 2" maxLength={100} labelOptional />
        <VSpacer factor={3} />
        <Flex flexDirection="row" className={s.city}>
          <Input name="city" label="City" maxLength={100} required={requiredFields['city']} />
          <Input
            name="state"
            label="State / Province"
            maxLength={20}
            required={requiredFields['state'] && countryName === defaultCountryName}
          />
          <Input
            name="postal"
            label="Postal code"
            maxLength={9}
            required={requiredFields['postal']}
          />
        </Flex>
        <VSpacer factor={3} />
        <CountrySelect
          name="countrySelect"
          label="Country"
          width={'492px'}
          placeholder={formState.country || defaultCountryName}
          initialValue={
            formState.country
              ? findCountryByName(formState.country).countryCode
              : defaultCountryCode
          }
          onChange={(option) => {
            const { name } = option;
            setCountryName(name);
          }}
        />
        <VSpacer factor={3} />
        <PhoneWrapper
          phone={formState.phone}
          onChange={(e) => {
            handleFormChange({ phone: e });
          }}
        />
        {children}
      </Form>
    </section>
  );
}

AddressEdit.defaultProps = {
  requiredFields: {},
};

export default observer(AddressEdit);
