/*eslint-disable no-unused-vars*/
import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
/*eslint-enable no-unused-vars*/
import { getCLS, getFID, getLCP, getTTFB, getFCP } from 'web-vitals';
import sendWebVitalToGoogleAnalytics from './helpers/sendWebVitalToGoogleAnalytics.js';
import routerStore from '../stores/router/RouterStore.js';

function WebVitals({ children }) {
  const { currentPath } = routerStore;
  useEffect(() => {
    getCLS((data) => sendWebVitalToGoogleAnalytics(data, currentPath));
    getFID((data) => sendWebVitalToGoogleAnalytics(data, currentPath));
    getLCP((data) => sendWebVitalToGoogleAnalytics(data, currentPath));
    getFCP((data) => sendWebVitalToGoogleAnalytics(data, currentPath));
    getTTFB((data) => sendWebVitalToGoogleAnalytics(data, currentPath));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath]);

  return children;
}

export default observer(WebVitals);
