import gql from 'graphql-tag';

export const REJECT_TRANSACTION_ADJUSTMENT = gql`
  mutation rejectTransactionAdjustment(
    $tx_hash_internal_id: String!
    $reason: TransactionHashAdjustmentRejectionReasonEnum!
    $adjustment_idx: Int!
  ) {
    rejectTransactionAdjustment(
      tx_hash_internal_id: $tx_hash_internal_id
      reason: $reason
      adjustment_idx: $adjustment_idx
    ) {
      status
      statusText
      message
      ok
    }
  }
`;
