import React from 'react';
import { GlobalAlert } from '@mqd/volt-base';

function GlobalAlertBankInformation({ showModal }) {
  return (
    <>
      <GlobalAlert
        type="info"
        confirmText="click here."
        showTime={null}
        dismiss={showModal}
        mounted={true}
      >
        Marqeta has updated its bank account information. For account details, please
      </GlobalAlert>
    </>
  );
}

export default GlobalAlertBankInformation;
