// @flow
import { decorate, observable, action, computed } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';
import CardPersonalizationStore from './CardPersonalizationStore';
import FulfillmentShippingStore from './FulfillmentShippingStore';

class FulfillmentStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.loadAndConstruct(args);
  }
  // values
  card_fulfillment_reason: string = '';
  // objects
  shipping: FulfillmentShippingStore = {};
  card_personalization: CardPersonalizationStore = {};

  // actions
  loadAndConstruct(args) {
    this.load(args);
    const { shipping, card_personalization } = args;
    this.loadAndConstructItem('shipping', shipping, FulfillmentShippingStore);
    this.loadAndConstructItem(
      'card_personalization',
      card_personalization,
      CardPersonalizationStore
    );
  }

  // computed
  watchedObjects = ['shipping', 'card_personalization'];
  get updateParams(): Object {
    const params = { ...this.watchedUpdateParams };
    this.valuesUpdatedArray.forEach((value) => {
      params[value] = this[value];
    });
    if (Object.keys(params).length === 0) return null;
    return params;
  }
}

decorate(FulfillmentStore, {
  // values
  card_fulfillment_reason: observable,
  // objects
  shipping: observable,
  card_personalization: observable,

  // actions
  loadAndConstruct: action.bound,

  // computed
  updateParams: computed,
});

export default FulfillmentStore;
