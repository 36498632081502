import React, { useState } from 'react';
import { Modal, RadioGroup, Button, Textarea, VSpacer } from '@mqd/volt-base';
import PropTypes from 'prop-types';

function ManualOverrideModal({ handleSubmit, hideModal }) {
  const [manualOverride, setManualOverride] = useState(false);
  const [notes, setNotes] = useState('');

  return (
    <Modal
      hideModal={hideModal}
      footerButtons={[
        <Button key="1" onClick={() => handleSubmit(manualOverride, notes)}>
          Submit
        </Button>,
      ]}
    >
      <RadioGroup
        label="Manual override"
        groupItems={[
          {
            label: 'Perform electronic KYC verification',
            value: false,
          },
          {
            label: 'Override KYC status to “Success”',
            value: true,
          },
        ]}
        value={manualOverride}
        onChange={setManualOverride}
      />

      <VSpacer factor={3} />

      <Textarea
        label="Notes (optional)"
        labelSize="small"
        maxLength={255}
        width={540}
        rows={2}
        noResize
        value={notes}
        onChange={(event) => setNotes(event.target.value)}
      />
    </Modal>
  );
}

ManualOverrideModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default ManualOverrideModal;
