import { FlipFlop } from './../../../utils/index.js';
import hasRoleForSelectedProgram from '../../helpers/hasRoleForSelectedProgram.js';

export const requirements = {
  flipFlopRequirements: ['pin-reveal'],
  requiredUserPermissions: ['program-admin', 'delivery-internal', 'risk-internal'],
};

export const verifier = () => {
  return (
    FlipFlop.get('pin-reveal', false) &&
    (hasRoleForSelectedProgram('program-admin') ||
      hasRoleForSelectedProgram('delivery-internal') ||
      hasRoleForSelectedProgram('risk-internal'))
  );
};
