import React, { useMemo } from 'react';
import { useProgramSelectorStateContext } from '../context.js';

export default function withProgramSelector(Component) {
  return function WrapperComponent(props) {
    const { programsMetadata, activeProgram, programs, activeProgramBootstrapCompleted } =
      useProgramSelectorStateContext();
    const programSelector = useMemo(
      () => ({ programsMetadata, activeProgram, programs, activeProgramBootstrapCompleted }),
      [programsMetadata, activeProgram, programs, activeProgramBootstrapCompleted]
    );
    return <Component {...props} programSelector={programSelector} />;
  };
}
