import { FlipFlop } from '../../index';
import hasRoleForSelectedProgram from '../../helpers/hasRoleForSelectedProgram.js';
import { isAchOriginationEnabled } from '../../ach.js';
import { UAM_GRANULAR_PERMISSIONS } from '@mqd/mqd-constants';

const { ACH_PROGRAM_FUNDING_VIEW, ACH_PROGRAM_FUNDING_VIEW_AND_EDIT } = UAM_GRANULAR_PERMISSIONS;

const requiredGranularPermissions = [ACH_PROGRAM_FUNDING_VIEW, ACH_PROGRAM_FUNDING_VIEW_AND_EDIT];

export const requirements = {
  flipFlopRequirements: ['ach-program-funding-beta'],
  requiredUserPermissions: ['aux-view-acho-transfers'],
  requiredGranularPermissions,
};

export const verifier = () => {
  if (!isAchOriginationEnabled()) {
    return false;
  }

  if (hasRoleForSelectedProgram(UAM_GRANULAR_PERMISSIONS.UAM_GRANULAR_PERMISSIONS)) {
    return requiredGranularPermissions.some(hasRoleForSelectedProgram);
  }

  return (
    // Note: We don't need to check for the 'ach-program-funding-beta' feature flag anymore.
    //       The feature flag was released awhile back. A clean up ticket for removing the
    //       feature flag is https://marqeta.atlassian.net/browse/SPD-1004
    FlipFlop.get('ach-program-funding-beta', false) &&
    hasRoleForSelectedProgram('aux-view-acho-transfers')
  );
};
