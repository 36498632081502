export const ACQUIRER_FEE = 'ACQUIRER_FEE';
export const CROSS_BORDER_ISSUER_FEE = 'CROSS_BORDER_ISSUER_FEE';
export const CUR_CONV_CARDHOLDER_FEE = 'CUR_CONV_CARDHOLDER_FEE';
export const CUR_CONV_ISSUER_FEE = 'CUR_CONV_ISSUER_FEE';
export const INTEGRITY_FEE = 'INTEGRITY_FEE';
export const INTERCHANGE_PAYABLES = 'INTERCHANGE_PAYABLES';
export const IRF_BASE_AMOUNT = 'IRF_BASE_AMOUNT';
export const ISSUER_FEE = 'ISSUER_FEE';
export const PINDEBIT_ASSOC_FEE = 'PINDEBIT_ASSOC_FEE';
export const PURCHASE_PAYABLES = 'PURCHASE_PAYABLES';
export const SWITCH_FEE = 'SWITCH_FEE';

const ACCOUNTING_GROUPS = {
  ACQUIRER_FEE,
  CROSS_BORDER_ISSUER_FEE,
  CUR_CONV_CARDHOLDER_FEE,
  CUR_CONV_ISSUER_FEE,
  INTEGRITY_FEE,
  INTERCHANGE_PAYABLES,
  IRF_BASE_AMOUNT,
  ISSUER_FEE,
  PINDEBIT_ASSOC_FEE,
  PURCHASE_PAYABLES,
  SWITCH_FEE,
};

export default ACCOUNTING_GROUPS;
