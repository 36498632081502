import { SELECTED_ACTIVE_PROGRAM_KEY } from './constants.js';
import logger from 'utils/logger';
import { secureStorage } from '@mq/volt-amc-container';

export default function loadActiveProgramFromLocalStorage(programs) {
  let activeProgram = '';
  try {
    activeProgram = JSON.parse(secureStorage.getItem(SELECTED_ACTIVE_PROGRAM_KEY));
  } catch (e) {
    logger.error('No active program in localStorage');
  }
  const reconciled = reconcileSelectedProgramWithRedseaAccess(activeProgram, programs);
  if (reconciled) {
    return activeProgram;
  }
  secureStorage.removeItem(SELECTED_ACTIVE_PROGRAM_KEY);
  if (!activeProgram || !reconciled) {
    return defaultToFirstProgram(programs);
  }
}

function reconcileSelectedProgramWithRedseaAccess(activeProgram, programs) {
  if (activeProgram) {
    const availablePrograms = programs;
    return Boolean(
      availablePrograms.find(
        (programObject) => programObject.short_name === activeProgram.short_name
      )
    );
  }
  return false;
}

// if no program selected, default to users first program
function defaultToFirstProgram(programs) {
  if (programs.length > 0) {
    secureStorage.setItem(SELECTED_ACTIVE_PROGRAM_KEY, JSON.stringify(programs[0]));
    return programs[0];
  }
  return null;
}
