import React from 'react';
import PropTypes from 'prop-types';
import { Colors, Text } from '@mqd/volt-base';
import { formatMoneyAndNegative } from '@mqd/volt-currency';
import s from './TransactionAmount.module.css';

const TransactionAmount = (props) => {
  const { color } = props;
  const amount = props.amount || 0;
  // Declined DWT transactions return currency_code as null
  // formatMoneyAndNegative is not ready to handle it, e.g.: "0.00 null".
  const currencyCode = props.currencyCode || 'USD';

  const formattedAmount = formatMoneyAndNegative(amount, currencyCode);
  const lineThroughClassName = props.lineThrough ? s.lineThrough : undefined;

  return (
    <div className={s.container} data-testid="transaction-amount">
      <Text type="h6" color={color} className={lineThroughClassName}>
        {formattedAmount}
      </Text>
    </div>
  );
};

TransactionAmount.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currencyCode: PropTypes.string,
  color: PropTypes.string,
  lineThrough: PropTypes.bool,
};

TransactionAmount.defaultProps = {
  color: Colors.blackLighter3,
  currencyCode: undefined,
};

export default TransactionAmount;
