import { FlipFlop } from '../../index.js';
import { isProgramConfigFlagEnabled } from './functions.js';
import currentUserStore from 'stores/CurrentUserStore';
import { KYC_EXCEPTIONS_VIEW_AND_EDIT } from '../../../views/admin/uam-granular-permissions/constants.js';

const UAM_ROLES = [KYC_EXCEPTIONS_VIEW_AND_EDIT];

export const requirements = {
  flipFlopRequirements: ['risk-control-review-manager'],
  programConfigRequirements: ['KYC_MICROSERVICE_ENABLED'],
  requiredGranularPermissions: UAM_ROLES,
};

export const verifier = () => {
  const {
    userStore: { hasRequiredUamPermissions, uamGranularPermissionsActive },
  } = currentUserStore;

  const hasKycExceptionsPermissions = hasRequiredUamPermissions(UAM_ROLES);
  const riskControlReviewManagerFlagActive = FlipFlop.get('risk-control-review-manager', false);
  const programConfigFlagActive = isProgramConfigFlagEnabled('KYC_MICROSERVICE_ENABLED');

  const canViewProgramReviews = riskControlReviewManagerFlagActive && programConfigFlagActive;

  // verifier should return true for the following scenarios:
  // --------------------------------------------------------
  // 1) current user has both uam-granular-permissions and kyc-exceptions-view-and-edit redsea roles
  //    AND both risk-control-review-manager and KYC_MICROSERVICE_ENABLED flags are active
  // ------------------------------------------------------------------------------------------------
  // 2) current user does not have the uam-granular-permissions redsea role
  //    AND both risk-control-review-manager and KYC_MICROSERVICE_ENABLED flags are active
  return uamGranularPermissionsActive
    ? hasKycExceptionsPermissions && canViewProgramReviews
    : canViewProgramReviews;
};
