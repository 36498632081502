import { useState } from 'react';
import { complement, concat, equals, filter } from 'ramda';

export function useLoadingCards() {
  const [loadingCards, setLoadingCards] = useState([]);
  const addLoadingCard = (id) => setLoadingCards(concat([id]));
  const removeLoadingCard = (id) => setLoadingCards(filter(complement(equals(id))));
  const isLoading = (id) => loadingCards.includes(id);

  return {
    addLoadingCard,
    removeLoadingCard,
    isLoading,
  };
}
