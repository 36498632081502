import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Checkbox } from '@mqd/volt-base';

const stopPropagation = (e) => e.stopPropagation();
const alwaysTrue = (row) => true;

/**
 * Implements bulk selection of rows (transactions).
 */
export default function useRowSelector({ rows, idProp, isRowSelectable = alwaysTrue }) {
  // State.
  const [selectedRows, setSelectedRows] = useState(() => ({
    allRows: false,
    ids: new Set(),
  }));

  const numSelectableRows = useMemo(() => {
    return rows.filter(({ _disabled }) => _disabled === false).length;
  }, [rows]);

  const getSelectAllState = (newSelectedCount, numSelectableRows) => {
    if (newSelectedCount === 0) {
      return false;
    } else if (newSelectedCount === numSelectableRows) {
      return true;
    }
    return 'indeterminate';
  };

  useEffect(() => {
    setSelectedRows(({ ids }) => {
      const newSelectedIds = new Set();
      let newAllRows = true;
      for (const row of rows) {
        const id = row[idProp];
        if (ids.has(id)) {
          newSelectedIds.add(id);
        } else {
          newAllRows = false;
        }
      }

      newAllRows = getSelectAllState(newSelectedIds.size, numSelectableRows);
      return { allRows: newAllRows, ids: newSelectedIds };
    });
  }, [rows, idProp, numSelectableRows]);

  // Reducers.
  const toggleRow = useCallback(
    (row) => {
      const id = row[idProp];
      setSelectedRows(({ ids }) => {
        if (ids.has(id)) {
          ids.delete(id);
        } else {
          ids.add(id);
        }
        return {
          allRows: getSelectAllState(ids.size, numSelectableRows),
          ids: ids,
        };
      });
    },
    [idProp, numSelectableRows]
  );
  const toggleSelectAll = useCallback(() => {
    setSelectedRows(({ allRows, ids }) => {
      if (allRows) {
        ids.clear();
        return {
          allRows: false,
          ids: ids,
        };
      } else {
        ids.clear();
        for (const row of rows) {
          if (isRowSelectable(row)) {
            ids.add(row[idProp]);
          }
        }
        return {
          allRows: true,
          ids,
        };
      }
    });
  }, [rows, idProp, isRowSelectable]);
  const clearSelections = useCallback(() => {
    setSelectedRows(({ ids }) => {
      ids.clear();
      return {
        allRows: false,
        ids,
      };
    });
  }, []);

  // Renderable components.
  const SelectRow = useCallback(
    ({ row }) => {
      const selected = selectedRows.ids.has(row[idProp]);
      return (
        <div onClick={stopPropagation}>
          <Checkbox
            disabled={!isRowSelectable(row)}
            active={selected}
            onClick={() => toggleRow(row)}
            testId={`toggle-one-${selected ? 'selected' : 'deselected'}`}
          />
        </div>
      );
    },
    [idProp, isRowSelectable, toggleRow, selectedRows]
  );
  // Subtle: use selectedRows in dependency list rather than selectedRows.ids
  // since we may reuse the same ids set in reducers.

  const selectAllRows = useMemo(
    () => (
      <Checkbox
        onClick={toggleSelectAll}
        active={selectedRows.allRows}
        testId={`toggle-all-${selectedRows.allRows ? 'selected' : 'deselected'}`}
      />
    ),
    [toggleSelectAll, selectedRows.allRows]
  );

  return { selectedRows, SelectRow, selectAllRows, clearSelections };
}
