import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import NotesTableStore from './NotesTableStore';
import { ParentTable } from '@mq/voltron-parent';
import { Table } from '@mq/voltron-table';
import { DetailPane, LoadingOverlay, Modal, VSpacer } from '@mqd/volt-base';
import StyleContext from '../contexts/StyleContext';

class NotesTable extends ParentTable {
  constructor(props) {
    super(props);
    this.storeConstructor = NotesTableStore;
    this.state = this.colEditState(props, this.columns());
    this.hideRowDataModal = this.hideRowDataModal.bind(this);
  }

  columns() {
    const { hasMarqetaEmail } = this.props;

    const cols = [
      {
        header: this.headerRenderer(),
        accessor: 'token',
        key: 'actions',
        renderer: () => <div />,
        noPadding: true,
        noResize: true,
        noRowClick: true,
        noSort: true,
        noEdit: true,
      },
      { header: 'Description', accessor: 'description', key: 'description' },
      { header: 'Created By', accessor: 'created_by', key: 'created_by' },
      {
        header: 'Created By User Role',
        accessor: 'created_by_user_role',
        key: 'created_by_user_role',
      },
      { header: 'Private', accessor: 'private', key: 'private', hide: true },
      { header: 'Token', accessor: 'token', key: 'token', primaryKey: true },
      {
        header: 'Last modified time',
        accessor: 'last_modified_time',
        key: 'last_modified_time',
        primaryKey: true,
      },
      { header: 'Created time', accessor: 'created_time', key: 'created_time', primaryKey: true },
    ];

    // doing this to main the order of the columns
    if (!hasMarqetaEmail) {
      cols.splice(3, 1);
    }

    return cols;
  }

  hideRowDataModal() {
    this.setState({ dataForRowDataModal: null });
  }

  renderRowDataModal() {
    const { columns, dataForRowDataModal } = this.state;
    if (!dataForRowDataModal) return;

    const rowsToRender = [];

    for (let keyName in dataForRowDataModal) {
      const shouldDisplayColumnData = columns.find(({ key, hide }) => !hide && key === keyName);
      if (shouldDisplayColumnData) {
        const value = dataForRowDataModal[keyName];
        const columnData = columns.find(({ key }) => key === keyName);
        rowsToRender.push(
          <DetailPane.Content label={columnData.header} key={columnData.key}>
            {String(value)}
          </DetailPane.Content>
        );
      }
    }

    return (
      <Modal width={600} heading="Row Data" hideModal={this.hideRowDataModal}>
        <DetailPane.Container>{rowsToRender}</DetailPane.Container>
      </Modal>
    );
  }

  render() {
    const { currentList, activeSort, activeRow, setAttr, updateKey, loading } = this.store;
    const { columns, tableKey } = this.state;
    const pageSize = this.store.count;
    const tablePaddingBottom = currentList.length >= pageSize ? 0 : 70;

    return (
      <div style={{ width: '100%' }}>
        {this.renderColEditTableHeader({ tableFilterTestId: 'notes-table-header-buttons' })}
        <VSpacer />
        <StyleContext.Consumer>
          {({ height }) => {
            return (
              <LoadingOverlay active={loading}>
                {this.renderRowDataModal()}
                <Table
                  key={tableKey}
                  loading={loading}
                  height={height + tablePaddingBottom || this.dynamicHeight}
                  data={currentList}
                  columns={columns}
                  fixedColumnCount={1}
                  rightAlignFixedColumns={true}
                  sort={activeSort}
                  activeRow={activeRow}
                  onSort={(newSort) => setAttr('activeSort', newSort)}
                  onRowClick={(rowInfo) => {
                    this.setState({ dataForRowDataModal: rowInfo });
                  }}
                  scrollToTopUpdateKey={updateKey}
                  handleColSizeChange={this.handleColSizeChange.bind(this)}
                />
              </LoadingOverlay>
            );
          }}
        </StyleContext.Consumer>
        {this.renderPagination()}
      </div>
    );
  }
}

NotesTable.propTypes = {
  height: PropTypes.number,
  storeInitArgs: PropTypes.object,
  store: PropTypes.object,
  userStore: PropTypes.object,
  autoHydrate: PropTypes.bool,
  onStoreConstruction: PropTypes.func,
  gqlClient: PropTypes.any,
  localStorageKey: PropTypes.string,
};

NotesTable.defaultProps = {
  height: 800,
  storeInitArgs: { limit: 100 },
  store: null,
  userStore: {},
  autoHydrate: true,
  onStoreConstruction: null,
  gqlClient: null,
  localStorageKey: 'NotesTableConfig',
};

export default observer(NotesTable);
