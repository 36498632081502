import React from 'react';
import { observer } from 'mobx-react';
import { NotificationStack } from 'react-notification';

const Notifications = ({ notificationStore }) => {
  const notifications =
    notificationStore.notifications && notificationStore.notifications.toJS
      ? notificationStore.notifications.toJS()
      : [];
  return (
    <NotificationStack
      notifications={notifications}
      onDismiss={(notification) => notificationStore.removeNotification(notification)}
    />
  );
};

export default observer(Notifications);
