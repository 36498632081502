import React, { useMemo } from 'react';
import withRouterGo from '../../../components/router-go/withRouterGo.js';
import { routeMap } from '@mq/volt-amc-container';

function Link({ children, go, to }) {
  const handleClick = (e) => {
    e.preventDefault();
    go(to);
  };

  const href = useMemo(() => routeMap[to].path, [to]);
  return (
    <a href={href} onClick={handleClick} data-testid="link">
      {children}
    </a>
  );
}

export default withRouterGo(Link);
