export const ACTIVE = 'ACTIVE';
export const CLEARED = 'CLEARED';
export const ENABLED = 'ENABLED';
export const SUCCESS = 'SUCCESS';
export const UNVERIFIED = 'UNVERIFIED';
export const COMPLETION = 'COMPLETION';
export const REQUESTED = 'REQUESTED';
export const REQUEST_DECLINED = 'REQUEST_DECLINED';
export const LIMITED = 'LIMITED';
export const PARTIAL = 'PARTIAL';
export const PENDING = 'PENDING';
export const ERROR = 'ERROR';
export const SUSPENDED = 'SUSPENDED';
export const DECLINED = 'DECLINED';
export const DISABLED = 'DISABLED';
export const UNACTIVATED = 'UNACTIVATED';
export const CLOSED = 'CLOSED';
export const INACTIVE = 'INACTIVE';
export const TERMINATED = 'TERMINATED';
export const DECISION_RED = 'DECISION_RED';
export const DECISION_YELLOW = 'DECISION_YELLOW';
export const DECISION_GREEN = 'DECISION_GREEN';
export const REJECTED = 'REJECTED';
export const PROVISIONED = 'PROVISIONED';

export const FULFILLMENT_STATUSES = [
  DECISION_RED,
  DECISION_YELLOW,
  DECISION_GREEN,
  REJECTED,
  PROVISIONED,
];

export const DIGITAL_WALLET_TOKEN_STATES = [
  REQUESTED,
  REQUEST_DECLINED,
  TERMINATED,
  ACTIVE,
  SUSPENDED,
  TERMINATED,
];

export const ISSUER_ELIGIBILITY_DECISIONS = {
  PROVISIONED: '0000',
  VERIFIED: 'cardaccount.verified',
  SUSPICIOUS: 'card.suspicious',
  VERIFICATION_REQUIRED: 'token.activation.verification.required',
  DECLINED: 'token.activation-request.decline',
  CARD_NOT_ACTIVE: 'card.not.active',
  INVALID_CID: 'invalid.cid',
  CARD_EXPIRED: 'card.expired',
  CARD_SUSPENDED: 'card.suspended',
  CARDHOLDER_NOT_ACTIVE: 'cardholder.not.active',
};

export const TOKEN_REQUESTOR_NAMES = {
  APPLE_PAY: 'APPLE_PAY',
  ANDROID_PAY: 'ANDROID_PAY',
  VISA_CHECKOUT: 'VISA_CHECKOUT',
  NETFLIX: 'NETFLIX',
  FACEBOOK: 'FACEBOOK',
};

export const DEVICE_TYPES = {
  MOBILE_PHONE: 'MOBILE_PHONE',
  WATCH: 'WATCH',
  TABLET: 'TABLET',
  MOBILE_PHONE_OR_TABLET: 'MOBILE_PHONE_OR_TABLET',
  VEHICLE: 'VEHICLE',
  APPLIANCE: 'APPLIANCE',
  LAPTOP: 'LAPTOP',
  GAMING_DEVICE: 'GAMING_DEVICE',
  UNKNOWN: 'UNKNOWN',
};

export const TRANSFER_STATUS_MAP = {
  REVERSAL_PEND: 'Reversal pending',
  REVERSAL_DECL: 'Reversal declined',
  REVERSAL_COMP: 'Reversal completed',
};

export const WALLET_PROVIDER_REASON_CODES = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '0A',
  '0B',
  '0C',
  '0D',
  '0E',
  '0G',
];

export const WALLET_PROVIDER_PAN_SOURCES = {
  KEY_ENTERED: 'KEY_ENTERED',
  ON_FILE: 'ON_FILE',
  MOBILE_BANKING_APP: 'MOBILE_BANKING_APP',
};

export const WALLET_PROVIDER_RISK_ASSESSMENT_SCORES = [
  DECISION_GREEN,
  DECISION_YELLOW,
  DECISION_RED,
];

export const KYC_STATUS_MAP = {
  SUCCESS: { display: 'Success', icon: 'confirmed', color: 'statusSuccessColor' },
  FAILURE: { display: 'Failure', icon: 'clear', color: 'statusErrorColor' },
  PENDING: { display: 'Pending', icon: 'clock', color: 'statusPendingColor' },
  VENDOR_PENDING: { display: 'Vendor pending', icon: 'clock', color: 'statusWarningColor' },
};

export const AMERICAN_STATES = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

export const WORLD_COUNTRIES = [
  { render: 'Afghanistan', val: 'AF' },
  { render: 'Åland Islands', val: 'AX' },
  { render: 'Albania', val: 'AL' },
  { render: 'Algeria', val: 'DZ' },
  { render: 'American Samoa', val: 'AS' },
  { render: 'Andorra', val: 'AD' },
  { render: 'Angola', val: 'AO' },
  { render: 'Anguilla', val: 'AI' },
  { render: 'Antarctica', val: 'AQ' },
  { render: 'Antigua and Barbuda', val: 'AG' },
  { render: 'Argentina', val: 'AR' },
  { render: 'Armenia', val: 'AM' },
  { render: 'Aruba', val: 'AW' },
  { render: 'Australia', val: 'AU' },
  { render: 'Austria', val: 'AT' },
  { render: 'Azerbaijan', val: 'AZ' },
  { render: 'Bahamas', val: 'BS' },
  { render: 'Bahrain', val: 'BH' },
  { render: 'Bangladesh', val: 'BD' },
  { render: 'Barbados', val: 'BB' },
  { render: 'Belarus', val: 'BY' },
  { render: 'Belgium', val: 'BE' },
  { render: 'Belize', val: 'BZ' },
  { render: 'Benin', val: 'BJ' },
  { render: 'Bermuda', val: 'BM' },
  { render: 'Bhutan', val: 'BT' },
  { render: 'Bolivia', val: 'BO' },
  { render: 'Bosnia and Herzegovina', val: 'BA' },
  { render: 'Botswana', val: 'BW' },
  { render: 'Bouvet Island', val: 'BV' },
  { render: 'Brazil', val: 'BR' },
  { render: 'British Indian Ocean Territory', val: 'IO' },
  { render: 'Brunei Darussalam', val: 'BN' },
  { render: 'Bulgaria', val: 'BG' },
  { render: 'Burkina Faso', val: 'BF' },
  { render: 'Burundi', val: 'BI' },
  { render: 'Cambodia', val: 'KH' },
  { render: 'Cameroon', val: 'CM' },
  { render: 'Canada', val: 'CA' },
  { render: 'Cape Verde', val: 'CV' },
  { render: 'Cayman Islands', val: 'KY' },
  { render: 'Central African Republic', val: 'CF' },
  { render: 'Chad', val: 'TD' },
  { render: 'Chile', val: 'CL' },
  { render: 'China', val: 'CN' },
  { render: 'Christmas Island', val: 'CX' },
  { render: 'Cocos (Keeling) Islands', val: 'CC' },
  { render: 'Colombia', val: 'CO' },
  { render: 'Comoros', val: 'KM' },
  { render: 'Congo', val: 'CG' },
  { render: 'Congo, The Democratic Republic of the', val: 'CD' },
  { render: 'Cook Islands', val: 'CK' },
  { render: 'Costa Rica', val: 'CR' },
  { render: "Cote D'Ivoire", val: 'CI' },
  { render: 'Croatia', val: 'HR' },
  { render: 'Cuba', val: 'CU' },
  { render: 'Cyprus', val: 'CY' },
  { render: 'Czech Republic', val: 'CZ' },
  { render: 'Denmark', val: 'DK' },
  { render: 'Djibouti', val: 'DJ' },
  { render: 'Dominica', val: 'DM' },
  { render: 'Dominican Republic', val: 'DO' },
  { render: 'Ecuador', val: 'EC' },
  { render: 'Egypt', val: 'EG' },
  { render: 'El Salvador', val: 'SV' },
  { render: 'Equatorial Guinea', val: 'GQ' },
  { render: 'Eritrea', val: 'ER' },
  { render: 'Estonia', val: 'EE' },
  { render: 'Ethiopia', val: 'ET' },
  { render: 'Falkland Islands (Malvinas)', val: 'FK' },
  { render: 'Faroe Islands', val: 'FO' },
  { render: 'Fiji', val: 'FJ' },
  { render: 'Finland', val: 'FI' },
  { render: 'France', val: 'FR' },
  { render: 'French Guiana', val: 'GF' },
  { render: 'French Polynesia', val: 'PF' },
  { render: 'French Southern Territories', val: 'TF' },
  { render: 'Gabon', val: 'GA' },
  { render: 'Gambia', val: 'GM' },
  { render: 'Georgia', val: 'GE' },
  { render: 'Germany', val: 'DE' },
  { render: 'Ghana', val: 'GH' },
  { render: 'Gibraltar', val: 'GI' },
  { render: 'Greece', val: 'GR' },
  { render: 'Greenland', val: 'GL' },
  { render: 'Grenada', val: 'GD' },
  { render: 'Guadeloupe', val: 'GP' },
  { render: 'Guam', val: 'GU' },
  { render: 'Guatemala', val: 'GT' },
  { render: 'Guernsey', val: 'GG' },
  { render: 'Guinea', val: 'GN' },
  { render: 'Guinea-Bissau', val: 'GW' },
  { render: 'Guyana', val: 'GY' },
  { render: 'Haiti', val: 'HT' },
  { render: 'Heard Island and Mcdonald Islands', val: 'HM' },
  { render: 'Holy See (Vatican City State)', val: 'VA' },
  { render: 'Honduras', val: 'HN' },
  { render: 'Hong Kong', val: 'HK' },
  { render: 'Hungary', val: 'HU' },
  { render: 'Iceland', val: 'IS' },
  { render: 'India', val: 'IN' },
  { render: 'Indonesia', val: 'ID' },
  { render: 'Iran, Islamic Republic Of', val: 'IR' },
  { render: 'Iraq', val: 'IQ' },
  { render: 'Ireland', val: 'IE' },
  { render: 'Isle of Man', val: 'IM' },
  { render: 'Israel', val: 'IL' },
  { render: 'Italy', val: 'IT' },
  { render: 'Jamaica', val: 'JM' },
  { render: 'Japan', val: 'JP' },
  { render: 'Jersey', val: 'JE' },
  { render: 'Jordan', val: 'JO' },
  { render: 'Kazakhstan', val: 'KZ' },
  { render: 'Kenya', val: 'KE' },
  { render: 'Kiribati', val: 'KI' },
  { render: "Korea, Democratic People'S Republic of", val: 'KP' },
  { render: 'Korea, Republic of', val: 'KR' },
  { render: 'Kuwait', val: 'KW' },
  { render: 'Kyrgyzstan', val: 'KG' },
  { render: "Lao People'S Democratic Republic", val: 'LA' },
  { render: 'Latvia', val: 'LV' },
  { render: 'Lebanon', val: 'LB' },
  { render: 'Lesotho', val: 'LS' },
  { render: 'Liberia', val: 'LR' },
  { render: 'Libyan Arab Jamahiriya', val: 'LY' },
  { render: 'Liechtenstein', val: 'LI' },
  { render: 'Lithuania', val: 'LT' },
  { render: 'Luxembourg', val: 'LU' },
  { render: 'Macao', val: 'MO' },
  { render: 'Macedonia, The Former Yugoslav Republic of', val: 'MK' },
  { render: 'Madagascar', val: 'MG' },
  { render: 'Malawi', val: 'MW' },
  { render: 'Malaysia', val: 'MY' },
  { render: 'Maldives', val: 'MV' },
  { render: 'Mali', val: 'ML' },
  { render: 'Malta', val: 'MT' },
  { render: 'Marshall Islands', val: 'MH' },
  { render: 'Martinique', val: 'MQ' },
  { render: 'Mauritania', val: 'MR' },
  { render: 'Mauritius', val: 'MU' },
  { render: 'Mayotte', val: 'YT' },
  { render: 'Mexico', val: 'MX' },
  { render: 'Micronesia, Federated States of', val: 'FM' },
  { render: 'Moldova, Republic of', val: 'MD' },
  { render: 'Monaco', val: 'MC' },
  { render: 'Mongolia', val: 'MN' },
  { render: 'Montserrat', val: 'MS' },
  { render: 'Morocco', val: 'MA' },
  { render: 'Mozambique', val: 'MZ' },
  { render: 'Myanmar', val: 'MM' },
  { render: 'Namibia', val: 'NA' },
  { render: 'Nauru', val: 'NR' },
  { render: 'Nepal', val: 'NP' },
  { render: 'Netherlands', val: 'NL' },
  { render: 'Netherlands Antilles', val: 'AN' },
  { render: 'New Caledonia', val: 'NC' },
  { render: 'New Zealand', val: 'NZ' },
  { render: 'Nicaragua', val: 'NI' },
  { render: 'Niger', val: 'NE' },
  { render: 'Nigeria', val: 'NG' },
  { render: 'Niue', val: 'NU' },
  { render: 'Norfolk Island', val: 'NF' },
  { render: 'Northern Mariana Islands', val: 'MP' },
  { render: 'Norway', val: 'NO' },
  { render: 'Oman', val: 'OM' },
  { render: 'Pakistan', val: 'PK' },
  { render: 'Palau', val: 'PW' },
  { render: 'Palestinian Territory, Occupied', val: 'PS' },
  { render: 'Panama', val: 'PA' },
  { render: 'Papua New Guinea', val: 'PG' },
  { render: 'Paraguay', val: 'PY' },
  { render: 'Peru', val: 'PE' },
  { render: 'Philippines', val: 'PH' },
  { render: 'Pitcairn', val: 'PN' },
  { render: 'Poland', val: 'PL' },
  { render: 'Portugal', val: 'PT' },
  { render: 'Puerto Rico', val: 'PR' },
  { render: 'Qatar', val: 'QA' },
  { render: 'Reunion', val: 'RE' },
  { render: 'Romania', val: 'RO' },
  { render: 'Russian Federation', val: 'RU' },
  { render: 'RWANDA', val: 'RW' },
  { render: 'Saint Helena', val: 'SH' },
  { render: 'Saint Kitts and Nevis', val: 'KN' },
  { render: 'Saint Lucia', val: 'LC' },
  { render: 'Saint Pierre and Miquelon', val: 'PM' },
  { render: 'Saint Vincent and the Grenadines', val: 'VC' },
  { render: 'Samoa', val: 'WS' },
  { render: 'San Marino', val: 'SM' },
  { render: 'Sao Tome and Principe', val: 'ST' },
  { render: 'Saudi Arabia', val: 'SA' },
  { render: 'Senegal', val: 'SN' },
  { render: 'Serbia and Montenegro', val: 'CS' },
  { render: 'Seychelles', val: 'SC' },
  { render: 'Sierra Leone', val: 'SL' },
  { render: 'Singapore', val: 'SG' },
  { render: 'Slovakia', val: 'SK' },
  { render: 'Slovenia', val: 'SI' },
  { render: 'Solomon Islands', val: 'SB' },
  { render: 'Somalia', val: 'SO' },
  { render: 'South Africa', val: 'ZA' },
  { render: 'South Georgia and the South Sandwich Islands', val: 'GS' },
  { render: 'Spain', val: 'ES' },
  { render: 'Sri Lanka', val: 'LK' },
  { render: 'Sudan', val: 'SD' },
  { render: 'Surirender', val: 'SR' },
  { render: 'Svalbard and Jan Mayen', val: 'SJ' },
  { render: 'Swaziland', val: 'SZ' },
  { render: 'Sweden', val: 'SE' },
  { render: 'Switzerland', val: 'CH' },
  { render: 'Syrian Arab Republic', val: 'SY' },
  { render: 'Taiwan, Province of China', val: 'TW' },
  { render: 'Tajikistan', val: 'TJ' },
  { render: 'Tanzania, United Republic of', val: 'TZ' },
  { render: 'Thailand', val: 'TH' },
  { render: 'Timor-Leste', val: 'TL' },
  { render: 'Togo', val: 'TG' },
  { render: 'Tokelau', val: 'TK' },
  { render: 'Tonga', val: 'TO' },
  { render: 'Trinidad and Tobago', val: 'TT' },
  { render: 'Tunisia', val: 'TN' },
  { render: 'Turkey', val: 'TR' },
  { render: 'Turkmenistan', val: 'TM' },
  { render: 'Turks and Caicos Islands', val: 'TC' },
  { render: 'Tuvalu', val: 'TV' },
  { render: 'Uganda', val: 'UG' },
  { render: 'Ukraine', val: 'UA' },
  { render: 'United Arab Emirates', val: 'AE' },
  { render: 'United Kingdom', val: 'GB' },
  { render: 'United States', val: 'US' },
  { render: 'United States Minor Outlying Islands', val: 'UM' },
  { render: 'Uruguay', val: 'UY' },
  { render: 'Uzbekistan', val: 'UZ' },
  { render: 'Vanuatu', val: 'VU' },
  { render: 'Venezuela', val: 'VE' },
  { render: 'Viet Nam', val: 'VN' },
  { render: 'Virgin Islands, British', val: 'VG' },
  { render: 'Virgin Islands, U.S.', val: 'VI' },
  { render: 'Wallis and Futuna', val: 'WF' },
  { render: 'Western Sahara', val: 'EH' },
  { render: 'Yemen', val: 'YE' },
  { render: 'Zambia', val: 'ZM' },
  { render: 'Zimbabwe', val: 'ZW' },
];
