export const currencyToIconCountryNameMap = {
  AED: 'united arab emirates',
  AFN: 'afghanistan',
  ALL: 'albania',
  AMD: 'armenia',
  AOA: 'angola',
  ARS: 'argentina',
  AUD: 'australia',
  AWG: 'aruba',
  AZN: 'azerbaijan',
  BAM: 'bosnia herzegovina',
  BBD: 'barbados',
  BDT: 'bangladesh',
  BGN: 'bulgaria',
  BHD: 'bahrain',
  BIF: 'burundi',
  BMD: 'bermuda',
  BND: 'brunei',
  BOB: 'bolivia',
  BOV: 'bolivia',
  BRL: 'brazil',
  BSD: 'bahamas',
  BTN: 'bhutan',
  BWP: 'botswana',
  BYN: 'belarus',
  BZD: 'belize',
  CAD: 'canada',
  CDF: 'congo',
  CHE: 'switzerland',
  CHF: 'liechtenstein',
  CHW: 'switzerland',
  CLF: 'chile',
  CLP: 'chile',
  CNY: 'china',
  COP: 'colombia',
  COU: 'colombia',
  CRC: 'costa rica',
  CUC: 'cuba',
  CUP: 'cuba',
  CVE: 'cape verde islands',
  CZK: 'czech republic',
  DJF: 'djibouti',
  DKK: 'denmark',
  DOP: 'dominican republic',
  DZD: 'algeria',
  EGP: 'egypt',
  ERN: 'eritrea',
  ETB: 'ethiopia',
  EUR: 'european union.svg',
  FJD: 'fiji',
  FKP: 'falkland islands',
  GBP: 'uk',
  GEL: 'georgia',
  GHS: 'ghana',
  GIP: 'gibraltar',
  GMD: 'gambia',
  GNF: 'guinea',
  GTQ: 'guatemala',
  GYD: 'guyana',
  HKD: 'hong kong',
  HNL: 'honduras',
  HRK: 'croatia',
  HTG: 'haiti',
  HUF: 'hungary',
  IDR: 'indonesia',
  ILS: 'israel',
  INR: 'bhutan',
  IQD: 'iraq',
  IRR: 'iran',
  ISK: 'iceland',
  JMD: 'jamaica',
  JOD: 'jordan',
  JPY: 'japan',
  KES: 'kenya',
  KGS: 'kyrgyzstan',
  KHR: 'cambodia',
  KMF: 'comoros',
  KPW: 'korea - north',
  KRW: 'korea - south',
  KWD: 'kuwait',
  KYD: 'cayman islands',
  KZT: 'kazakhstan',
  LAK: 'laos',
  LBP: 'lebanon',
  LKR: 'sri lanka',
  LRD: 'liberia',
  LSL: 'lesotho',
  LYD: 'libya',
  MAD: 'morocco',
  MDL: 'moldova',
  MGA: 'madagascar',
  MKD: 'macedonia',
  MMK: 'myanmar',
  MNT: 'mongolia',
  MOP: 'macao',
  MRU: 'mauritania',
  MVR: 'maldives',
  MWK: 'malawi',
  MXN: 'mexico',
  MXV: 'mexico',
  MYR: 'malaysia',
  MZN: 'mozambique',
  NAD: 'namibia',
  NGN: 'nigeria',
  NIO: 'nicaragua',
  NOK: 'norway',
  NPR: 'nepal',
  NZD: 'new zealand',
  OMR: 'oman',
  PAB: 'panama',
  PEN: 'peru',
  PGK: 'papua new guinea',
  PHP: 'philippines',
  PKR: 'pakistan',
  PLN: 'poland',
  PYG: 'paraguay',
  QAR: 'qatar',
  RON: 'romania',
  RSD: 'serbia',
  RUB: 'russia',
  RWF: 'rwanda',
  SAR: 'saudi arabia',
  SBD: 'solomon islands',
  SCR: 'seychelles',
  SDG: 'sudan',
  SEK: 'sweden',
  SGD: 'singapore',
  SHP: 'st. helena',
  SLL: 'sierra leone',
  SOS: 'somalia',
  SRD: 'suriname',
  STN: 'sao tome & principe',
  SVC: 'el salvador',
  SYP: 'syria',
  SZL: 'swaziland',
  THB: 'thailand',
  TJS: 'tajikistan',
  TMT: 'turkmenistan',
  TND: 'tunisia',
  TOP: 'tonga',
  TRY: 'turkey',
  TTD: 'trinidad & tobago',
  TWD: 'taiwan',
  UAH: 'ukraine',
  UGX: 'uganda',
  USD: 'united states',
  USN: 'united states',
  UYI: 'uruguay',
  UYU: 'uruguay',
  UYW: 'uruguay',
  UZS: 'uzbekistan',
  VES: 'venezuela',
  VND: 'vietnam',
  VUV: 'vanuatu',
  XAF: 'cameroon',
  XCD: 'anguilla',
  XOF: 'benin',
  XPF: 'french polynesia',
  XSU: 'venezuela',
  YER: 'yemen - north',
  ZAR: 'lesotho',
  ZMW: 'zambia',
  ZWL: 'zimbabwe',
};

// list of missing flags for currencies
// in case we want to add more flags in future
// eslint-disable-next-line no-unused-vars
const missing = [
  {
    code: 'ANG',
    number: '532',
  },
  {
    code: 'MUR',
    number: '480',
  },
  {
    code: 'SSP',
    number: '728',
  },
  {
    code: 'TZS',
    number: '834',
  },
  {
    code: 'WST',
    number: '882',
  },
  {
    code: 'XAG',
    number: '961',
  },
  {
    code: 'XAU',
    number: '959',
  },
  {
    code: 'XBA',
    number: '955',
  },
  {
    code: 'XBB',
    number: '956',
  },
  {
    code: 'XBC',
    number: '957',
  },
  {
    code: 'XBD',
    number: '958',
  },
  {
    code: 'XDR',
    number: '960',
  },
  {
    code: 'XPD',
    number: '964',
  },
  {
    code: 'XPT',
    number: '962',
  },
  {
    code: 'XTS',
    number: '963',
  },
  {
    code: 'XUA',
    number: '965',
  },
  {
    code: 'XXX',
    number: '999',
  },
];
