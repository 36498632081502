import { type, pathOr } from 'ramda';

// constants
import { PAGE_SIZE } from '../constants/index.js';

// utils
import capitalizeUtil from '../../../../utils/helpers/capitalize.js';
import { formatMoneyAndNegative } from '@mqd/volt-currency';

export const isFunc = (item) => type(item) === 'Function';
export const isObject = (item) => type(item) === 'Object';
export const isString = (item) => type(item) === 'String';

export const capitalize = (value = '', defaultValue = '') => {
  const val = value || defaultValue;
  const str = isString(val) ? val : val.toString();
  return capitalizeUtil(str.toLocaleLowerCase());
};

export const getPagination = (path, data, pageSize = PAGE_SIZE) => {
  const startIndex = pathOr(0, [...(path || []), 'start_index'], data);
  const isMore = pathOr(false, [...(path || []), 'is_more'], data);
  return {
    startIndex,
    pageSize,
    isMore,
    isLess: startIndex > 0,
  };
};

export const formatAmount = (amount = '', currencyCode = 'USD') => {
  const toFloat = parseFloat(amount);
  if (isNaN(toFloat)) return null;

  return formatMoneyAndNegative(toFloat, currencyCode);
};

export const concatStringsWithSpace = (...strings) => strings.join(' ').trim();

export const getFullLegalName = (data = {}) => {
  const { first_name, last_name } = data.cardholder || data;
  return concatStringsWithSpace(first_name, last_name);
};

export const errorMessage = (error = {}, msg) => {
  const errors = error?.graphQLErrors || [];
  return errors[0]?.message || msg;
};

// https://marqeta.atlassian.net/browse/PS-22337
export const convertStatus = (status = '') => {
  if (!status) return '';
  return status === 'CLOSED' ? 'TERMINATED' : status;
};

export const getTxnSignedAmount = (item = {}) => {
  const { amount, gpa = {} } = item;
  const impactedAmount = parseFloat(gpa?.impacted_amount);
  return !isNaN(impactedAmount) && impactedAmount < 0 ? `-${amount}` : amount;
};

/**
 * Format balance and currency code using formatMoneyAndNegative from volt-currency
 * @param balance
 * @param currencyCode
 * @returns [] - need to return empty array for the further usage
 * @usage const [balance, currencyCode] = formatBalance(available_balance, currency_code);
 */
export const formatBalance = (balance = '', currencyCode = 'USD') => {
  const formattedBalance = formatAmount(balance, currencyCode);
  return formattedBalance ? formattedBalance.split(' ') : [];
};
