import React from 'react';
import { ActionCard, Flex, OnPageError } from '@mqd/volt-base';

import s from './CardErrorHandler.module.css';

const { Card, MODE, TemplateWrapper } = ActionCard;

const ErrorTemplate = () => (
  <TemplateWrapper mode={MODE.PREVIEW} dynamicWidth>
    <Flex alignItems="center" justifyContent="center" className={s.fixedHeight}>
      <OnPageError>Action was not executed due to an unspecified error.</OnPageError>
    </Flex>
  </TemplateWrapper>
);

export class CardErrorHandler extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(`Report card id: ${this.props.id}`, error);
  }

  render() {
    if (this.state.hasError) {
      return <Card previewTemplate={ErrorTemplate} defaultMode={MODE.PREVIEW} />;
    }

    return this.props.children;
  }
}
