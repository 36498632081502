import React from 'react';
import PropTypes from 'prop-types';
import { VSpacer, FormLabel, Text } from '@mqd/volt-base';
import { formatAmount } from './../shared-utils';

export default function ConfirmFundsForm({ formState }) {
  const { funding_source_token, amount, currency_code } = formState;

  return (
    <>
      {funding_source_token && (
        <>
          <FormLabel id="funding-source-token">Funding source token</FormLabel>
          <Text labelledBy="funding-source-token">{funding_source_token}</Text>
        </>
      )}
      <VSpacer factor={3} />
      {amount && (
        <>
          <FormLabel id="amount" isHorizontalLabel>
            Amount
          </FormLabel>
          <VSpacer factor={0.5} />
          <Text labelledBy="amount">
            {formatAmount(amount)} {currency_code}
          </Text>
        </>
      )}
    </>
  );
}

ConfirmFundsForm.propTypes = {
  formState: PropTypes.object,
};

ConfirmFundsForm.defaultProps = {
  formState: {},
};
