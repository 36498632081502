import React, { useMemo, useState } from 'react';
import { withRedseaUser } from '@mq/volt-amc-container';
import { Activity, ToastAlert } from '@mqd/volt-base';
import { useMutation } from '@apollo/react-hooks';

import logger from 'utils/logger';
import { CREATE_EXCEPTION_NOTE } from '../../../queries/createExceptionNote/query.js';
import generateUserInitials from '../../../utilities/string/generate-user-initials.js';
import { generateNote } from '../../../utilities/use-hash-exception-details/generate-notes.js';
import { useHashExceptionDetailsDispatch } from '../../../utilities/use-hash-exception-details/context.js';
import { UPDATE_NOTES } from '../../../utilities/use-hash-exception-details/constants.js';

const ActivityWrapper = ({
  notes,
  handleViewAllActivity,
  redseaUser,
  tabView,
  tx_hash_internal_id,
  hideInput = false,
}) => {
  const dispatch = useHashExceptionDetailsDispatch();
  const [error, setError] = useState('');
  const { data: { redsea_first_name, redsea_last_name, redsea_email } = {} } = redseaUser;
  const userInitials = useMemo(
    () => generateUserInitials(`${redsea_first_name} ${redsea_last_name}`),
    [redsea_first_name, redsea_last_name]
  );

  const [createExceptionNote, { loading }] = useMutation(CREATE_EXCEPTION_NOTE, {
    onError: (e) => logger.log(e),
  });

  const [toastAlertData, setToastAlertData] = useState({
    message: '',
    error: false,
  });

  function optimisticallyAddNewNote(note) {
    const oldNotes = notes;
    const newNotes = notes.slice();
    const noteData = {
      note,
      email: redsea_email,
      user_name: `${redsea_first_name} ${redsea_last_name}`,
      createdTime: new Date(),
    };
    const newNote = generateNote(noteData, -1);
    newNotes.unshift(newNote);
    dispatch({ type: UPDATE_NOTES, payload: newNotes });

    return oldNotes;
  }

  const handleAddNote = async (note) => {
    if (note.length > 1000) {
      setError('Note cannot exceed 1,000 characters.');
      return;
    }

    if (loading) {
      setError('Wait for the previous request to finish.');
      return;
    }

    setError('');

    const oldNotes = optimisticallyAddNewNote(note);
    const { data } = await createExceptionNote({
      variables: {
        note,
        tx_hash_internal_id,
      },
    });

    if (!data?.createExceptionNote?.ok) {
      dispatch({ type: UPDATE_NOTES, payload: oldNotes });
      setToastAlertData({
        message: data?.createExceptionNote?.message,
        error: true,
      });
    } else {
      setToastAlertData({
        message: 'Successfully added note',
        error: false,
      });
    }
  };

  return (
    <>
      <Activity
        activities={notes}
        addActivityHandler={handleAddNote}
        error={error}
        hideInput={hideInput}
        fourColumns={!tabView}
        header={!tabView && 'Activity'}
        id="exception-detail-activity"
        showAllActivities={tabView}
        userInitials={userInitials}
        viewAllHandler={handleViewAllActivity}
      />
      {toastAlertData.message !== '' && (
        <ToastAlert
          icon={toastAlertData.error ? 'error' : 'success'}
          remove={() => setToastAlertData({ message: '', error: false })}
        >
          {toastAlertData.message}
        </ToastAlert>
      )}
    </>
  );
};

export default withRedseaUser(ActivityWrapper);
