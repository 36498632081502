import React, { useContext, useMemo, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import { Flex, Select } from '@mqd/volt-base';
import { ProgramReserveContext } from '../../../../../../../../context/ProgramReserveContext.js';
import { ProgramTransactionHistoryStoreContext } from '../../../../../../../../context/ProgramTransactionHistoryStoreContext.js';
import { prepareDataForCSVExport } from '../../../../utils.js';
import { fetchTransactionHistoryForExport } from '../../fetch-utils.js';
import CSVExportButton from '../../../CSVExportButton/index.js';

function Filters() {
  const { programsOptions } = useContext(ProgramReserveContext);
  const { filterProgram, history, error, setFilterProgram, resetPageIndex, visibleColumns } =
    useContext(ProgramTransactionHistoryStoreContext);

  const programValue = useMemo(
    () => filterProgram && programsOptions.find((option) => filterProgram === option.val),
    [filterProgram, programsOptions]
  );

  const fileName = `Program-Funding_Program_${programValue?.render.trim().replace(/\W+/g, '_')}`;
  const exportNotAvailable = !filterProgram || error || history.length === 0;
  const memoizedDataLoader = useCallback(() => {
    return fetchTransactionHistoryForExport(prepareDataForCSVExport, visibleColumns, filterProgram);
  }, [filterProgram, visibleColumns]);

  // when the user comes to the Program specific tab, automatically select the first program
  useEffect(() => {
    if (programsOptions.length > 0 && !filterProgram) {
      setFilterProgram(programsOptions[0].val);
    }
  }, [filterProgram, programsOptions, programsOptions.length, setFilterProgram]);

  return (
    <Flex justifyContent="space-between">
      <Select
        width="248px"
        showSearch
        placeholder="Select program"
        testId="program-reserve-filter-program"
        value={programValue}
        options={programsOptions}
        onChange={(program) => {
          setFilterProgram(program.val);
          resetPageIndex();
        }}
      />

      <CSVExportButton
        filename={fileName}
        disabled={exportNotAvailable}
        dataLoader={memoizedDataLoader}
      />
    </Flex>
  );
}

export default observer(Filters);
