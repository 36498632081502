import { FlipFlop } from '../index';
import currentUserStore from '../../stores/CurrentUserStore.js';
import { CASES_PROGRAM_DATA } from './constants';

export const OLD_CASES_PROGRAM_DATA = { program: 'Cases', short_name: 'cases', favorite: false };
export const DDAQA_PROGRAM_DATA = {
  program: 'DDA Test Program',
  short_name: 'ddaqa',
  favorite: false,
};

// Temporary hack until mastercard demo program is added to snowflake
export const MASTERCARD_DEMO_PROGRAM_DATA = {
  program: 'MasterCard Demo',
  short_name: 'mqdm',
  favorite: false,
};

export const MASTERCARD_DEMO_EU_PROGRAM_DATA = {
  program: 'MasterCard Demo EU Local',
  short_name: 'mqdmeu',
  favorite: false,
};

export const RISK_DEMO_PROGRAM_DATA = {
  program: 'Risk Demo',
  short_name: 'riskdemo',
  favorite: false,
};

export const RISK_DEMO_ACH_PROGRAM_DATA = {
  program: 'Risk Demo DDA',
  short_name: 'rsdm',
  favorite: false,
};

export const UPGRADE_PROGRAM_DATA = {
  program: 'Upgrade',
  short_name: 'upgd',
  favorite: false,
};

export const WATERMARQ_PROGRAM_DATA = {
  program: 'Watermarq Demo',
  short_name: 'watr2',
  favorite: false,
};

export const CONSUMER_CREDIT_OP_TOOLING_PROGRAM_DATA = {
  program: 'Consumer Credit Operational Tooling QA Environment',
  short_name: 'cncrdopt',
  favorite: false,
};

export const GREENLIGHT_CONSUMER_CREDIT_QA_PROGRAM_DATA = {
  program: 'Greenlight Consumer Credit QA',
  short_name: 'grnltcc',
  favorite: false,
};

export const CUI_PROGRAM_DATA = {
  program: 'CUI Program QA',
  short_name: 'cui01',
  favorite: false,
};

export const KLEU_QA_PROGRAM_DATA = {
  program: 'Klarna EU Program QA',
  short_name: 'kleu-qa',
  favorite: false,
};

const includeCustomDisputeProgram = () => {
  const canAccessDisputes = currentUserStore.userStore.canAccessDisputes;
  if (!canAccessDisputes) return [];
  return CASES_PROGRAM_DATA;
};

const includeCustomDdaProgram = () => {
  const hasDdaFlag = FlipFlop.get('dda-beta', false);
  if (!hasDdaFlag) return false;
  return DDAQA_PROGRAM_DATA;
};

const includeCustomUpgradeProgram = () => {
  const hasUpgradeFlag = FlipFlop.get('upgrade-permissions', false);
  if (!hasUpgradeFlag) return false;
  return UPGRADE_PROGRAM_DATA;
};

/*
  This is for non-production use only!
  Janus does not support QA programs that do not also exist in Prod,
  but some teams need custom programs for development and testing.
  This util file is intended to support integration with such custom programs.
*/
export const mergeCustomPrograms = (programs = []) => {
  if (window.env.ENVIRONMENT === 'production') return programs;
  try {
    const programsArray = Array.isArray(programs) ? programs : programs.toJS();
    return [
      ...includeCustomDisputeProgram(),
      OLD_CASES_PROGRAM_DATA,
      window.env.ENVIRONMENT && MASTERCARD_DEMO_PROGRAM_DATA,
      window.env.ENVIRONMENT && MASTERCARD_DEMO_EU_PROGRAM_DATA,
      includeCustomDdaProgram(),
      includeCustomUpgradeProgram(),
      RISK_DEMO_PROGRAM_DATA,
      RISK_DEMO_ACH_PROGRAM_DATA,
      WATERMARQ_PROGRAM_DATA,
      CONSUMER_CREDIT_OP_TOOLING_PROGRAM_DATA,
      GREENLIGHT_CONSUMER_CREDIT_QA_PROGRAM_DATA,
      CUI_PROGRAM_DATA,
      KLEU_QA_PROGRAM_DATA,
      ...programsArray,
    ].filter((program) => Boolean(program));
  } catch (err) {
    return programs;
  }
};
