import React from 'react';
import { Button, Text, VSpacer, LoadingOverlay, Colors, Flex } from '@mqd/volt-base';
import s from './DownloadFileLoadingOverlay.module.css';
import PropTypes from 'prop-types';

const DownloadFileLoadingOverlay = (props) => {
  const { cancelRequestFn, entity, active } = props;
  if (!active) return null;

  return (
    <div className={s.container} style={{ backgroundColor: Colors.white }}>
      <LoadingOverlay active={true} />
      <VSpacer factor={3} />
      <Flex
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        className={s.loadingContent}
      >
        <Text type="h5">{`Generating ${entity}...`}</Text>
        <VSpacer factor={3} />

        <Text type="body-lg">
          {`Large ${entity} can take a minute or two to generate. Thank you for your patience! If this message persists for more than a few minutes, contact your Marqeta representative.`}
        </Text>
        <VSpacer factor={3} />
        <Button onClick={cancelRequestFn}>Cancel request</Button>
      </Flex>
    </div>
  );
};

DownloadFileLoadingOverlay.propTypes = {
  entity: PropTypes.string,
  cancelRequestFn: PropTypes.func,
  active: PropTypes.bool,
  contactLink: PropTypes.string,
};

export default DownloadFileLoadingOverlay;
