import React from 'react';
import PropTypes from 'prop-types';
import { ActionCard } from '@mqd/volt-base';

import { CardTemplateContext } from '../../context/CardTemplateContext';
import { useIsFirstRender } from '../../hooks/use-is-first-render';

import { CardTemplate } from './components/card-template';

const { Card, MODE } = ActionCard;

export function CardFulfillmentActionCards({
  data,
  previewTemplate,
  editTemplate,
  compareState,
  status,
  cardType,
  actions,
}) {
  const isFirstRenderOfGroup = useIsFirstRender();

  const templateContextValue = {
    previewTemplate,
    editTemplate,
    compareState,
    status,
    cardType,
    actions,
    isFirstRenderOfGroup,
  };

  return (
    <CardTemplateContext.Provider value={templateContextValue}>
      {data.map((datum) => (
        <Card
          // we have to include datum.temp into the key to reset any react state down in the tree when we add a new card
          // so any state that was made from datum will not have { temp: true } in it
          key={[datum.id, Boolean(datum.temp)]}
          datum={datum}
          previewTemplate={CardTemplate}
          editTemplate={CardTemplate}
          defaultMode={datum.temp ? MODE.EDIT : MODE.PREVIEW}
        />
      ))}
    </CardTemplateContext.Provider>
  );
}

CardFulfillmentActionCards.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  previewTemplate: Card.propTypes.previewTemplate,
  editTemplate: Card.propTypes.editTemplate,
  cardType: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    onCreateNewCard: PropTypes.func.isRequired,
    onUpdateCard: PropTypes.func.isRequired,
    onResetCard: PropTypes.func.isRequired,
    onDeleteCard: PropTypes.func.isRequired,
  }),
};
