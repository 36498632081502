import React, { useContext, useState } from 'react';
import { StatusIndicator } from '@mqd/volt-base';
import { WebhookContext } from '../contexts/index.js';
import { translateFormValuesToGraphQLWebhookType } from '../functions/webhooks.js';
import { DisableConfirmationModal } from '../components/index.js';

const WebhookStatusIndicatorToggle = ({
  saving,
  active,
  onActivate,
  onDisable,
  dropdownDisabled = false,
}) => {
  const { defaultWebhookValues, onSave } = useContext(WebhookContext);
  const [showDisableConfirmationModal, setShowDisableConfirmationModal] = useState(false);

  const setWebhookActive = (_active) => {
    defaultWebhookValues.active = _active;
    onSave(translateFormValuesToGraphQLWebhookType(defaultWebhookValues));
  };

  const _onActivateClick = () => {
    if (onActivate) {
      const useCustomActivateHandler = onActivate();
      if (!useCustomActivateHandler) setWebhookActive(true);
      return;
    }
    setWebhookActive(true);
  };

  const _onDisableClick = () => {
    setShowDisableConfirmationModal(true);
  };

  const disableWebhook = () => {
    setShowDisableConfirmationModal(false);
    setWebhookActive(false);
    onDisable();
  };

  return (
    <>
      <StatusIndicator
        testId={active ? 'overview-status-active' : 'overview-status-inactive'}
        status={active ? 'ACTIVE' : 'INACTIVE'}
        dropdownDisabled={dropdownDisabled || saving}
      >
        {active ? (
          <StatusIndicator.Action onClick={_onDisableClick}>Disable</StatusIndicator.Action>
        ) : (
          <StatusIndicator.Action onClick={_onActivateClick}>Activate</StatusIndicator.Action>
        )}
      </StatusIndicator>
      {showDisableConfirmationModal ? (
        <DisableConfirmationModal
          disableWebhook={disableWebhook}
          hideModalCallback={() => {
            setShowDisableConfirmationModal(false);
          }}
        />
      ) : null}
    </>
  );
};

export default WebhookStatusIndicatorToggle;
