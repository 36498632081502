const UserStoreTableConfigKeys = {
  auditLogTableConfig: 'auditLogTableConfig',
  businessDetailCardholdersTableConfig: 'businessDetailCardholdersTableConfig',
  businessDetailHistoryTableConfig: 'businessDetailHistoryTableConfig',
  businessDetailNotesTableConfig: 'businessDetailNotesTableConfig',
  businessDetailTransactionsTableConfig: 'businessDetailTransactionsTableConfig',
  businessDetailTransitionsTableConfig: 'businessDetailTransitionsTableConfig',
  businessesTableConfig: 'businessesTableConfig',
  cardDetailHistoryTableConfig: 'cardDetailHistoryTableConfig',
  cardDetailTransactionsTableConfig: 'cardDetailTransactionsTableConfig',
  cardDetailTransitionsTableConfig: 'cardDetailTransitionsTableConfig',
  cardholderDetailCardsTableConfig: 'cardholderDetailCardsTableConfig',
  cardholderDetailHistoryTableConfig: 'cardholderDetailHistoryTableConfig',
  cardholderDetailNotesTableConfig: 'cardholderDetailNotesTableConfig',
  cardholderDetailTransactionsTableConfig: 'cardholderDetailTransactionsTableConfig',
  cardholderDetailTransitionsTableConfig: 'cardholderDetailTransitionsTableConfig',
  cardholdersTableConfig: 'cardholdersTableConfig',
  cardPackagesTableConfig: 'cardPackagesTableConfig',
  commandoModeDetailTransitionsTableConfig: 'commandoModeDetailTransitionsTableConfig',
  orderHistoryTableConfig: 'orderHistoryTableConfig',
  providersTableConfig: 'providersTableConfig',
  reviewsTableConfig: 'reviewsTableConfig',
  skusTableConfig: 'skusTableConfig',
};

export default UserStoreTableConfigKeys;
