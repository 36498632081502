import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Icon, VSpacer, Text, Hr } from '@mqd/volt-base';
import { containerWidthHOC } from '@mq/voltron-parent';
import CommandoModeAuthCard from './CommandoModeAuthCard';
import CommandoModeVelocityCard from './CommandoModeVelocityCard';
import s from './CommandoModeControlsTab.module.css';

class CommandoModeControlsTab extends Component {
  render() {
    const { commandoModeStore = { velocity_controls: [], auth_controls: [] } } = this.props;
    const { velocity_controls, auth_controls } = commandoModeStore;
    const colSpan = 6;
    const iterationCount = Math.max(velocity_controls.length, auth_controls.length);
    return (
      <div>
        <Container>
          {iterationCount ? (
            Array(iterationCount)
              .fill()
              .map((val, key, arr) => [
                <Row>
                  {velocity_controls[key] && (
                    <Col span={colSpan}>
                      <CommandoModeVelocityCard {...velocity_controls[key]} />
                    </Col>
                  )}
                  {auth_controls[key] && (
                    <Col span={colSpan}>
                      <CommandoModeAuthCard {...auth_controls[key]} />
                    </Col>
                  )}
                </Row>,
                arr[key + 1] ? (
                  <div className={`${s.hr}`}>
                    <Hr />
                  </div>
                ) : null,
              ])
          ) : (
            <div className={`${s.noControls}`}>
              <div className={`${s.iconParent}`}>
                <div className={`${s.balancer}`}>
                  <Icon type="side-bar-settings-inactive" noHoverEffects={true} factor={7} />
                </div>
              </div>
              <VSpacer factor={2} />
              <Text type="h3">No controls configured</Text>
            </div>
          )}
        </Container>
      </div>
    );
  }
}

CommandoModeControlsTab.propTypes = {
  commandoModeStore: PropTypes.any,
};

CommandoModeControlsTab.defaultProps = {
  commandoModeStore: { velocity_controls: [], auth_controls: [] },
};

export default containerWidthHOC(CommandoModeControlsTab);
