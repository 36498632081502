import React, { useCallback, useMemo, useState } from 'react';
import { TableFilters } from '@mq/voltron-table';

import { NETWORKS, PROGRAM_TYPES } from '../../../utilities/dictionary/index.js';
import {
  makeToggleClick,
  makeBulkToggleClick,
} from '../../../utilities/make-filter-option-click.js';

const PROGRAM_TYPES_READABLE = new Map();
for (const [api, readable] of Object.entries(PROGRAM_TYPES)) {
  PROGRAM_TYPES_READABLE.set(readable, api);
}
const programTypeToReadable = (programType) => PROGRAM_TYPES[programType] || programType;
const programTypeReadableToType = (readable) => PROGRAM_TYPES_READABLE.get(readable) || readable;

function Filters({
  network,
  allBanks,
  allCurrencyCodes,
  allPrograms,
  allProgramTypes,
  initialValues = {},
  refetch,
  testId,
}) {
  // Compute ID <-> readable option mappings for banks and programs.
  const [bankTokenToName, bankNameToToken, allBankNames] = useMemo(() => {
    const tokenToName = new Map();
    const nameToToken = new Map();
    for (const bank of allBanks) {
      tokenToName.set(bank.token, bank.name);
      nameToToken.set(bank.name, bank.token);
    }
    return [
      (token) => tokenToName.get(token) || token,
      (name) => nameToToken.get(name) || name,
      Array.from(nameToToken.keys()),
    ];
  }, [allBanks]);
  const [programShortCodeToName, programNameToShortCode, allProgramNames] = useMemo(() => {
    const shortCodeToProgram = new Map();
    const programToShortCode = new Map();
    for (const program of allPrograms) {
      shortCodeToProgram.set(program.short_name, program.program);
      programToShortCode.set(program.program, program.short_name);
    }
    return [
      (shortCode) => shortCodeToProgram.get(shortCode) || shortCode,
      (program) => programToShortCode.get(program) || program,
      Array.from(programToShortCode.keys()),
    ];
  }, [allPrograms]);
  const allProgramTypesReadable = useMemo(
    () => allProgramTypes.map((t) => PROGRAM_TYPES[t] || t),
    [allProgramTypes]
  );

  // States.
  const [bankTokens, setBankTokens] = useState(() => new Set(initialValues.dna_bank_tokens || []));
  const [currencyCodes, setCurrencyCodes] = useState(
    () => new Set(initialValues.settlement_currencies || [])
  );
  const [programShortCodes, setProgramShortCodes] = useState(
    () => new Set(initialValues.program_short_codes || [])
  );
  const [programTypes, setProgramTypes] = useState(
    () => new Set(initialValues.program_types || [])
  );
  const [icaValues, setICAValues] = useState(() =>
    initialValues.ica_values ? initialValues.ica_values.join(', ') : ''
  );

  // Actions.
  const handleClear = useCallback(() => {
    setBankTokens(new Set());
    setCurrencyCodes(new Set());
    setProgramShortCodes(new Set());
    setProgramTypes(new Set());
    setICAValues('');
    refetch({
      dna_bank_tokens: undefined,
      ica_values: undefined,
      program_short_codes: undefined,
      program_types: undefined,
      settlement_currencies: undefined,
    });
  }, [refetch]);

  const handleSearch = () => {
    refetch({
      dna_bank_tokens: bankTokens.size ? Array.from(bankTokens) : undefined,
      ica_values: icaValues ? icaValues.split(',').map((v) => v.trim()) : undefined,
      program_short_codes: programShortCodes.size ? Array.from(programShortCodes) : undefined,
      program_types: programTypes.size ? Array.from(programTypes) : undefined,
      settlement_currencies: currencyCodes.size ? Array.from(currencyCodes) : undefined,
    });
  };

  // Filter options.
  const programTypeFilterOption = useMemo(
    () => ({
      filterParam: 'programTypes',
      label: 'Program type',
      placeholder: 'Select',
      type: 'multiselect',
      onOptionClick: makeToggleClick(setProgramTypes, programTypeReadableToType),
      onBulkToggleClick: makeBulkToggleClick(
        programTypes,
        setProgramTypes,
        allProgramTypesReadable,
        programTypeReadableToType
      ),
      selected: Array.from(programTypes, programTypeToReadable),
      options: allProgramTypesReadable,
    }),
    [programTypes, allProgramTypesReadable]
  );

  const programFilterOption = useMemo(
    () => ({
      filterParam: 'programs',
      label: 'Program',
      placeholder: 'Select',
      type: 'multiselect',
      onOptionClick: makeToggleClick(setProgramShortCodes, programNameToShortCode),
      onBulkToggleClick: makeBulkToggleClick(
        programShortCodes,
        setProgramShortCodes,
        allProgramNames,
        programNameToShortCode
      ),
      selected: Array.from(programShortCodes, programShortCodeToName),
      options: allProgramNames,
    }),
    [programShortCodes, allProgramNames, programNameToShortCode, programShortCodeToName]
  );

  const bankFilterOption = useMemo(
    () => ({
      filterParam: 'banks',
      label: 'Bank',
      placeholder: 'Select',
      type: 'multiselect',
      onOptionClick: makeToggleClick(setBankTokens, bankNameToToken),
      onBulkToggleClick: makeBulkToggleClick(
        bankTokens,
        setBankTokens,
        allBankNames,
        bankNameToToken
      ),
      selected: Array.from(bankTokens, bankTokenToName),
      options: allBankNames,
    }),
    [bankTokens, allBankNames, bankNameToToken, bankTokenToName]
  );

  const currencyCodeFilterOption = useMemo(
    () => ({
      filterParam: 'currencyCodes',
      label: 'Settlement currency',
      placeholder: 'Select',
      type: 'multiselect',
      onOptionClick: makeToggleClick(setCurrencyCodes),
      onBulkToggleClick: makeBulkToggleClick(currencyCodes, setCurrencyCodes, allCurrencyCodes),
      selected: Array.from(currencyCodes),
      options: allCurrencyCodes,
    }),
    [currencyCodes, allCurrencyCodes]
  );

  const icaFilterOption = useMemo(
    () => ({
      filterParam: 'icaValues',
      label: 'ICA',
      placeholder: 'Enter ICA values separated by a comma',
      onChange: (e) => {
        setICAValues(e.target.value);
      },
      value: icaValues,
    }),
    [icaValues]
  );

  const filters = useMemo(() => {
    const out = [
      programTypeFilterOption,
      programFilterOption,
      bankFilterOption,
      currencyCodeFilterOption,
    ];
    if (network === NETWORKS.Mastercard) {
      out.push(icaFilterOption);
    }
    return out;
  }, [
    programTypeFilterOption,
    programFilterOption,
    bankFilterOption,
    currencyCodeFilterOption,
    network,
    icaFilterOption,
  ]);

  return (
    <TableFilters
      handleClear={handleClear}
      handleSearch={handleSearch}
      filterOptions={filters}
      testId={testId}
      type="large"
      showActiveFilterCountOnMount
      fetchOnClear={false}
    />
  );
}

export default Filters;
