const getHref = function (routerStore, { to, params, active }) {
  if (!active || !routerStore) return null;

  let path;
  if (to) {
    const routeObject = routerStore.routeMap[to];
    path = routeObject ? routeObject.path : '_';
  } else {
    path = routerStore.currentPath;
  }
  const href = `${path}${routerStore.objectToSearchString(params)}`;

  return href;
};

const handleClick = async function (
  event,
  routerStore,
  { to, params = {}, active = true, before, skipGaPageview }
) {
  event.preventDefault();
  try {
    if (before) {
      return await before();
    }
  } catch (error) {
    return {
      error: true,
      message: 'Error running action',
    };
  }

  if (!active || !routerStore) {
    return false;
  }

  const noTo = !to || to === '';
  if (noTo && params) {
    routerStore.replaceParams(params, skipGaPageview);
  } else {
    routerStore.go(to, params, skipGaPageview);
  }
};

export { getHref, handleClick };
