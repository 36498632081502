// @flow
import { decorate, observable } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';

class NoteStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
  }
  // values
  token: string = '';
  description: string = '';
  created_by: string = '';
  created_by_user_role: string = '';
  private: boolean = false;
}

decorate(NoteStore, {
  // values
  token: observable,
  description: observable,
  created_by: observable,
  created_by_user_role: observable,
  private: observable,

  // actions

  // computed
});

export default NoteStore;
