import React from 'react';
import PropTypes from 'prop-types';
import { VSpacer, Text, Address } from '@mqd/volt-base';
import { StoreContext } from '@mqd/volt-contexts';

function ShippingInformation({ address, onChange, cardProductIsPhysical, testId }) {
  if (!cardProductIsPhysical) return null;

  return (
    <div data-testid={testId}>
      <StoreContext.Consumer>
        {({ ProgramConfigStore = {} }) => {
          return (
            <>
              <VSpacer factor={2} />
              <Text type="h6">Shipping address</Text>
              <Address
                {...address}
                onChange={onChange}
                isEdit={true}
                isV2={ProgramConfigStore.isV2}
              />
            </>
          );
        }}
      </StoreContext.Consumer>
    </div>
  );
}

ShippingInformation.propTypes = {
  address: PropTypes.object,
  onChange: PropTypes.func,
  cardProductIsPhysical: PropTypes.bool,
  testId: PropTypes.string,
};

ShippingInformation.defaultProps = {
  address: {},
  testId: 'card-shipping-information',
};

export default ShippingInformation;
