import React from 'react';
import { FormLabel, Text, VSpacer } from '@mqd/volt-base';
import PropTypes from 'prop-types';
import { formatAmount } from './../shared-utils';

export default function ConfirmRemoveFundsForm({ formState }) {
  const { amount, currency_code } = formState;
  return (
    <>
      {amount && (
        <>
          <FormLabel id="amount" isHorizontalLabel>
            Amount to unload
          </FormLabel>
          <VSpacer factor={0.5} />
          <Text labelledBy="amount">
            {formatAmount(amount)} {currency_code}
          </Text>
        </>
      )}
    </>
  );
}

ConfirmRemoveFundsForm.propTypes = {
  formState: PropTypes.object,
};

ConfirmRemoveFundsForm.defaultProps = {
  formState: {},
};
