import React from 'react';
import PropTypes from 'prop-types';
import { EmptyState, VSpacer } from '@mqd/volt-base';

function Placeholder({ hasError }) {
  const message = hasError
    ? 'An error occurred while fetching program funding data'
    : 'No program funding data available';

  return (
    <>
      <VSpacer factor={8} />
      <EmptyState text={message} type="large" />
      <VSpacer factor={28} />
    </>
  );
}

Placeholder.propTypes = {
  hasError: PropTypes.bool.isRequired,
};

export default Placeholder;
