import { secureStorage } from '@mq/volt-amc-container';

const stringToBoolean = (str = '') => str.toLocaleLowerCase() === 'true';

export const isProgramConfigFlagEnabled = (featureFlagName) => {
  try {
    const activeProgramShortcode = JSON.parse(
      secureStorage.getItem('selectedActiveProgram')
    )?.short_name;
    const programConfigs = JSON.parse(secureStorage.getItem('programConfigs'));
    const programConfigFlag =
      programConfigs?.[activeProgramShortcode]?.featureFlags?.[featureFlagName];

    if (typeof programConfigFlag === 'string') {
      return stringToBoolean(programConfigFlag);
    }
    return Boolean(programConfigFlag);
  } catch (e) {
    console.error(e);
    return false;
  }
};
