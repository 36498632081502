import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { statusToPillDetail } from './constants';
import { Flex, HSpacer, Link, Pill, Text } from '@mqd/volt-base';
import KYCDetailsView from './components/KYCDetailsView';
import KYCDetailsEdit from './components/KYCDetailsEdit';
import s from './ReviewDetails.module.css';

class KYCDetails extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
    };
    this.exitEditMode = this.exitEditMode.bind(this);
  }

  exitEditMode() {
    this.setState({ editMode: false });
  }

  render() {
    const { editMode } = this.state;
    const { statusOptions, canEditReview, canRevealIdentification, internalUser, reviewStore } =
      this.props;
    const {
      kycDetails,
      kycStatus = 'PENDING',
      status = 'CLOSED',
      team = 'CUSTOMER',
      updateKYCDetails,
      updateReviewDisposition,
    } = reviewStore;
    const pillDetail = statusToPillDetail[kycStatus];
    const canEdit =
      status !== 'CLOSED' &&
      ((team === 'MARQETA' && internalUser) || (team !== 'MARQETA' && !internalUser)) &&
      canEditReview;

    return (
      <div style={{ width: '100%' }} data-testid="RCRM-kyc-details">
        <div className={s.header}>
          <Flex display="flex" flexDirection="row" alignItems="flex-end">
            <Text type="h4" inline={true}>
              KYC details
            </Text>
            <HSpacer />
            {!editMode && canEdit && (
              <Link
                external={false}
                newTab={false}
                onClick={() => this.setState({ editMode: true })}
                testId="RCRM-kyc-edit-link"
              >
                Edit
              </Link>
            )}
          </Flex>
          <Pill type={pillDetail.pillType}>{pillDetail.label}</Pill>
        </div>
        {editMode && canEdit ? (
          <KYCDetailsEdit
            internalUser={internalUser}
            details={kycDetails}
            updateKYCDetails={updateKYCDetails}
            updateReviewDisposition={updateReviewDisposition}
            canRevealIdentification={canRevealIdentification}
            statusOptions={statusOptions}
            reviewStatus={status}
            exitEditMode={this.exitEditMode}
          />
        ) : (
          <KYCDetailsView
            details={kycDetails}
            canRevealIdentification={canRevealIdentification}
            reviewStatus={kycStatus}
          />
        )}
      </div>
    );
  }
}

KYCDetails.propTypes = {
  canEditReview: PropTypes.bool,
  canRevealIdentification: PropTypes.bool,
  internalUser: PropTypes.bool,
  reviewStore: PropTypes.any,
  statusOptions: PropTypes.array,
};

KYCDetails.defaultProps = {
  canEditReview: false,
  canRevealIdentification: false,
  internalUser: false,
  reviewStore: {},
  statusOptions: [],
};

export default observer(KYCDetails);
