import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button, ErrorModal } from '@mqd/volt-base';
import routerStore from 'stores/router/RouterStore.js';
import { DownloadStore } from '../../../../../../../../stores/program-reserve/download-store.js';

function CSVExportButton({ filename, disabled, dataLoader }) {
  const { isInProgress, isError, setIsError, setIsDownloadAvailable, download } = useMemo(
    () => new DownloadStore(filename, dataLoader),
    [dataLoader, filename]
  );

  useEffect(() => {
    if (isInProgress) {
      routerStore.registerOnPageLeave({
        headerText: 'Are you sure you want to cancel?',
        bodyText: 'The CSV file is not ready yet.',
      });

      return () => routerStore.unregisterOnPageLeave(false);
    }
  }, [isInProgress]);

  useEffect(() => {
    return () => setIsDownloadAvailable(false);
  }, [setIsDownloadAvailable]);

  return (
    <>
      {isError && (
        <ErrorModal heading="Exporting failed" hideModal={() => setIsError(false)}>
          An error occurred while exporting the CSV file
        </ErrorModal>
      )}

      <Button
        type="tertiary"
        testId="program-reserve-export-button"
        loading={isInProgress}
        disabled={disabled}
        onClick={download}
      >
        Export CSV
      </Button>
    </>
  );
}

CSVExportButton.propTypes = {
  filename: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  dataLoader: PropTypes.func.isRequired,
};

export default observer(CSVExportButton);
