import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from '@mq/voltron-table';
import { Button, DetailPane, Flex, HSpacer, LoadingOverlay, VSpacer } from '@mqd/volt-base';
import { format } from '../../../../utilities/date.js';
import { ProgramReserveContext } from '../../context/ProgramReserveContext.js';
import { ToastStoreContext } from '../../context/ToastStoreContext.js';
import { withToastStore } from '../../hoc/withToastStore.js';
import { loseFocus } from '../../utils/dom.js';
import { goToProgramReserve } from '../../utils/navigation.js';
import PageHeader from '../PageHeader/index.js';
import { columns } from './columns.js';
import { submitTransactions } from './submit-transactions.js';

import s from './Summary.module.css';

const HEADER_ROW_HEIGHT = 40;
const BODY_ROW_HEIGHT = 41;

function Summary({ breadcrumbs, bankFileName, toastMessages }) {
  const { transactions, enableEditMode, getProgramFullName } = useContext(ProgramReserveContext);
  const TABLE_HEIGHT = transactions.length * BODY_ROW_HEIGHT + HEADER_ROW_HEIGHT;
  const { showToast } = useContext(ToastStoreContext);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const dataWithFullProgramNames = useMemo(
    () =>
      transactions.toJS().map((transaction) => ({
        ...transaction,
        program: getProgramFullName(transaction.program),
      })),
    [getProgramFullName, transactions]
  );

  const handleSubmitClick = async () => {
    try {
      // blur submit button because chrome doesn't do it automatically
      // focused button shadow overflows loading overlay and looks ugly
      loseFocus();

      setIsSubmitting(true);
      await submitTransactions(transactions, bankFileName);
      showToast(toastMessages.success, 'success');
    } catch (error) {
      console.error(error);
      showToast(toastMessages.error, 'error');
    } finally {
      setIsSubmitting(false);
      goToProgramReserve();
    }
  };

  return (
    <>
      <PageHeader
        breadcrumbs={[...breadcrumbs, { children: 'Review entry' }]}
        title="Review entry"
        description={
          <>
            Review each program funding entry below to make sure it is accurate. Entries made after
            midnight UTC will be
            <br /> posted the following day.
          </>
        }
      />

      <LoadingOverlay active={isSubmitting}>
        <Table
          loading // when loading is enabled, we get rid of unnecessary loading indicator
          columns={columns}
          data={dataWithFullProgramNames}
          height={TABLE_HEIGHT}
          bodyRowHeight={BODY_ROW_HEIGHT}
          inlineStyle={{
            borderTop: 'none',
            borderLeft: 'none',
            marginTop: -8,
            overflow: 'hidden',
          }}
        />

        <VSpacer factor={3} />

        <Flex flexDirection="row" className={s.summaryInfo} testId="program-reserve-footer-summary">
          <DetailPane.Container>
            <DetailPane.Content label="Total entries" />
            <DetailPane.Section header={String(transactions.length)} />
          </DetailPane.Container>
          <HSpacer factor={7.4} />
          <DetailPane.Container>
            <DetailPane.Content label="Post date" />
            <DetailPane.Section header={format(new Date())} />
          </DetailPane.Container>
          <HSpacer factor={6.1} />
          {bankFileName ? (
            <DetailPane.Container>
              <DetailPane.Content label="Bank file" />
              <DetailPane.Section header={bankFileName} />
            </DetailPane.Container>
          ) : null}
        </Flex>

        <VSpacer factor={3} />

        <Flex flexDirection="row">
          <Button type="tertiary" onClick={enableEditMode}>
            Back
          </Button>
          <HSpacer factor={2} />
          <Button type="primary" onClick={handleSubmitClick}>
            Submit
          </Button>
        </Flex>
      </LoadingOverlay>
    </>
  );
}

Summary.propTypes = {
  bankFileName: PropTypes.string,
  toastMessages: PropTypes.shape({
    success: PropTypes.string,
    error: PropTypes.string,
  }),
};

Summary.defaultProps = {
  toastMessages: {
    success: 'Operation successful',
    error: 'Operation unsuccessful',
  },
};

export default withToastStore(Summary);
