import React, { useCallback, useMemo } from 'react';
import useHydrateProgramConfig from '../useHydrateProgramConfig.js';
import { secureStorage } from '@mq/volt-amc-container';
import { PROGRAM_CONFIG_KEY } from '../constants.js';

export default function withProgramConfigActions(Component) {
  return function WrapperComponent(props) {
    const hydrateProgramConfig = useHydrateProgramConfig();
    const clearLocalStorage = useCallback(() => secureStorage.removeItem(PROGRAM_CONFIG_KEY), []);
    const programConfigActions = useMemo(
      () => ({ hydrate: hydrateProgramConfig, clearLocalStorage }),
      [hydrateProgramConfig, clearLocalStorage]
    );
    return <Component {...props} programConfigActions={programConfigActions} />;
  };
}
