import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  secureStorage,
  withApplicationDataActions,
  withJanusUser,
  withRedseaUser,
  useUnifyActive,
  ProgramSelector,
} from '@mq/volt-amc-container';
import routerStore from 'stores/router/RouterStore';
import {
  SELECTED_ACTIVE_PROGRAM_KEY,
  PROGRAMS,
  PROGRAMS_META_DATA,
  SELECT_ACTIVE_PROGRAM,
} from './constants.js';
import { useProgramSelectorDispatchContext } from './context.js';
import useLoadProgramsMetadata from './useLoadProgramsMetadata.js';
import loadFavoritePrograms from './loadFavoritePrograms.js';
import withProgramConfigActions from '../../utils/program-config/components/withProgramConfigActions.js';
import loadProgramMetaDataFromLocalStorage from './loadProgramMetaDataFromLocalStorage.js';
import filterProgramsByRedseaRole from './filterProgramsByRedseaRole.js';
import orderProgramsByFavoritesAlphabetically from './orderProgramsByFavoritesAlphabetically.js';
import { stripFavoriteKeyFromProgram } from './utils.js';
import loadActiveProgramFromLocalStorage from './loadActiveProgramFromLocalStorage.js';
import useToggleFavoriteProgram from './useToggleFavoriteProgram.js';
import { useProgramSelectorStateContext } from './context.js';
import applicationPropertiesStore from 'stores/ApplicationPropertiesStore';
import useLoadActiveProgramInfo from './useLoadActiveProgramInfo.js';

const ProgramSelectorWrapper = ({
  applicationDataActions,
  programConfigActions,
  getBreadcrumbRootRoute,
  gqlApiActions,
  janusUser,
  redseaUser,
  hideFavoriteProgramsIcons,
  marginLeft,
  width,
}) => {
  const [reorder, setReorder] = useState(false);
  const { data: { programs: janusPrograms, userOrgName } = {} } = janusUser;
  const { data: { redseaPrograms } = {} } = redseaUser;
  const { currentProgramShortCodeInUrl } = routerStore;
  const dispatch = useProgramSelectorDispatchContext();
  const loadProgramsMetadata = useLoadProgramsMetadata();
  const toggleFavoriteProgram = useToggleFavoriteProgram();
  const { programs, activeProgram } = useProgramSelectorStateContext();

  const unifyActive = useUnifyActive();
  const { setApplicationPropertiesCountryCode } = applicationPropertiesStore;

  useLoadActiveProgramInfo();

  // initialize program selector
  useEffect(() => {
    try {
      const handleLoadingProgramsMetaDataAndActiveProgram = async () => {
        let programMetaData = loadProgramMetaDataFromLocalStorage();
        if (!programMetaData) {
          programMetaData = await loadProgramsMetadata();
        }
        dispatch({ type: PROGRAMS_META_DATA, payload: programMetaData });
        const programsFilteredByRedseaRole = filterProgramsByRedseaRole({
          programMetaData,
          userOrgName,
          janusPrograms,
          redseaPrograms,
        });
        const programsWithFavorites = loadFavoritePrograms(programsFilteredByRedseaRole);
        const orderedPrograms = orderProgramsByFavoritesAlphabetically(programsWithFavorites);
        const activeUrlProgram = programsFilteredByRedseaRole.find(
          ({ short_name }) => short_name === currentProgramShortCodeInUrl
        );
        const activeProgram =
          activeUrlProgram || loadActiveProgramFromLocalStorage(orderedPrograms);
        dispatch({ type: SELECT_ACTIVE_PROGRAM, payload: activeProgram });
        dispatch({ type: PROGRAMS, payload: orderedPrograms });

        setApplicationPropertiesCountryCode(activeProgram);
      };
      handleLoadingProgramsMetaDataAndActiveProgram();
    } catch (e) {
      // do nothing
    } finally {
      // allow programselector to reorder the program after it was expanded or closed
      setReorder(false);
    }
  }, [
    loadProgramsMetadata,
    janusPrograms,
    userOrgName,
    redseaPrograms,
    dispatch,
    reorder,
    currentProgramShortCodeInUrl,
    setApplicationPropertiesCountryCode,
  ]);

  const selectActiveProgram = (programObject) => {
    const { updateParams } = routerStore;
    // clear local storage to force call to backend when page reloads (a few lines below)
    applicationDataActions.clearLocalStorage();
    programConfigActions.clearLocalStorage();

    const shortCode = JSON.stringify(stripFavoriteKeyFromProgram(programObject));

    secureStorage.setItem(SELECTED_ACTIVE_PROGRAM_KEY, shortCode);

    setApplicationPropertiesCountryCode(shortCode);

    if (updateParams) {
      updateParams({ program: programObject.short_name }, true);
    }

    const breadcrumbRootRoute = getBreadcrumbRootRoute();
    if (breadcrumbRootRoute) {
      window.location.href = breadcrumbRootRoute;
    } else if (window.location.href.includes('token=')) {
      window.location.href = '/';
    } else {
      window.location.reload();
    }
  };

  // wait for useEffect to finish reordering the program before rendering the list
  const isLoading = reorder;

  if (!unifyActive) return null;

  return (
    <ProgramSelector
      width={width}
      marginLeft={marginLeft}
      selectActiveProgram={selectActiveProgram}
      toggleFavoriteProgram={toggleFavoriteProgram}
      programs={programs}
      activeProgram={activeProgram}
      reorderProgram={setReorder}
      loading={isLoading}
      hideFavoriteProgramsIcons={hideFavoriteProgramsIcons}
    />
  );
};

ProgramSelectorWrapper.propTypes = {
  marginLeft: PropTypes.string,
  width: PropTypes.string,
};
ProgramSelectorWrapper.defaultProps = {
  marginLeft: '-8px',
  width: '256px',
};

export default withJanusUser(
  withRedseaUser(withProgramConfigActions(withApplicationDataActions(ProgramSelectorWrapper)))
);
