import React, { useMemo } from 'react';
import { Flex, Select, HSpacer } from '@mqd/volt-base';
import { useAdjustmentActionStateContext, useAdjustmentActionDispatchContext } from './context.js';
import { SELECT_ACTION, SELECT_REASON } from './constants.js';

const AdjustmentAction = ({ width, loadingCreateAdjustmentAction }) => {
  const {
    actions,
    reasons,
    selectedAction,
    selectedReason,
    selectedReasonError,
    selectedActionError,
  } = useAdjustmentActionStateContext();
  const dispatch = useAdjustmentActionDispatchContext();

  const mappedActions = useMemo(() => actions.map(({ label }) => label), [actions]);
  const mappedReasons = useMemo(() => reasons.map(({ label }) => label), [reasons]);

  const handleActionSelection = (val) =>
    dispatch({ type: SELECT_ACTION, payload: actions.find(({ label }) => label === val).type });

  const handleReasonSelection = (val) =>
    dispatch({ type: SELECT_REASON, payload: reasons.find(({ label }) => label === val).type });

  const selectedActionValue = useMemo(
    () =>
      selectedAction && actions ? actions.find(({ type }) => type === selectedAction)?.label : '',
    [selectedAction, actions]
  );
  const selectedReasonValue = useMemo(
    () => (selectedReason ? reasons.find(({ type }) => type === selectedReason)?.label : ''),
    [selectedReason, reasons]
  );

  return (
    <div style={{ width }}>
      <Flex flexDirection="row" justifyContent="flex-end">
        <Select
          disabled={loadingCreateAdjustmentAction}
          error={selectedActionError}
          onChange={handleActionSelection}
          options={mappedActions}
          placeholder="Select action"
          testId="adjustment-action"
          value={selectedActionValue}
        />
        <HSpacer factor={2} />
        <Select
          disabled={loadingCreateAdjustmentAction}
          error={selectedReasonError}
          onChange={handleReasonSelection}
          options={mappedReasons}
          width="230px"
          dropDownWrap={{ width: 'auto', minWidth: '100%' }}
          placeholder="Select reason"
          testId="adjustment-reason"
          value={selectedReasonValue}
        />
      </Flex>
    </div>
  );
};

export default AdjustmentAction;
