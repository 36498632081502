// @flow
import { decorate, observable, computed } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';

class FundingSourceStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
  }
  // values
  token: string = '';
  account_suffix: string = '';
  account_number: string = '';
  account_number_confirmation: string = '';
  account_type: string = '';
  routing_number: string = '';
  active: boolean = false;
  is_default_account: boolean = false;
  exp_date: string = '';
  verification_status: string = '';
  date_verified: string = '';
  name_on_account: string = '';
  date_sent_for_verification: string = '';
  verification_override: string = '';
  verification_notes: string = '';

  // actions

  // computed
  get achFormIsValid() {
    return Object.keys(this.achFormValidation).length === 0;
  }

  get achFormValidation() {
    const errors = {};
    if (!this.name_on_account) {
      errors.name_on_account = 'Account holder name is required';
    }

    if (!this.account_type) {
      errors.account_type = 'Account type is required';
    }

    if (!this.routing_number) {
      errors.routing_number = 'Routing number is required';
    } else if (!/^\d{9}$/.test(this.routing_number)) {
      errors.routing_number = 'Routing number must be 9 digits';
    }

    if (!this.account_number) {
      errors.account_number = 'Account number is required';
    }

    if (!this.account_number_confirmation) {
      errors.account_number_confirmation = 'Account number confirmation is required';
    } else if (this.account_number !== this.account_number_confirmation) {
      errors.account_number_confirmation = 'Account numbers do not match';
    }

    return errors;
  }
}

decorate(FundingSourceStore, {
  // values
  token: observable,
  account_suffix: observable,
  account_number: observable,
  account_number_confirmation: observable,
  account_type: observable,
  routing_number: observable,
  active: observable,
  is_default_account: observable,
  exp_date: observable,
  verification_status: observable,
  date_verified: observable,
  name_on_account: observable,
  date_sent_for_verification: observable,
  verification_override: observable,
  verification_notes: observable,

  // computed
  achFormValidation: computed,
  achFormIsValid: computed,
});

export default FundingSourceStore;
