export const userCancelReasons = [
  'Duplicative Transaction',
  'End User changed his/her Mind',
  'Erroneous Transaction made by End User',
  'Erroneous Transaction due to System Issue',
  'Insufficient Funds',
  'Incorrect Destination Account (funds to be sent to)',
  'Incorrect Source Account (funds to be pulled from)',
  'Unauthorized Transaction reported by End User',
  'Unauthorized Transaction reported by MQ Customer',
  'Unauthorized Transaction reported by MQ Partner Bank',
  'Unauthorized Transaction reported by Third Party',
  'Unusual Activity reported by MQ Internal Risk Monitoring',
  'Unusual Activity reported by End User',
  'Unusual Activity reported by MQ Customer',
  'Unusual Activity reported by MQ Partner Bank',
  'Unusual Activity reported by Third Party',
];

export const programCancelReasons = [
  'Duplicative Transaction',
  'Program determined transaction is no longer required',
  'Erroneous Transaction made by Program',
  'Erroneous Transaction due to System Issue',
  'Insufficient Funds',
  'Incorrect Amount (amount of funds to be sent)',
  'Incorrect Destination Account (funds to be sent to)',
  'Unauthorized Transaction reported by Program',
  'Unauthorized Transaction reported by MQ Customer',
  'Unauthorized Transaction reported by MQ Partner Bank',
  'Unauthorized Transaction reported by Third Party',
  'Unusual Activity reported by MQ',
  'Unusual Activity reported by End User',
  'Unusual Activity reported by MQ Customer',
  'Unusual Activity reported by MQ Partner Bank',
  'Unusual Activity reported by Third Party',
];

export const bankTransferTypeMapping = {
  user: {
    cancelReasons: userCancelReasons,
    descriptionProps: {
      label: 'Description (optional)',
    },
  },
  program: {
    cancelReasons: programCancelReasons,
    descriptionProps: {
      required: true,
      label: 'Description',
      placeholder: 'Enter an explanation or additional information for canceling this transfer.',
    },
  },
};
