import { useState, useEffect } from 'react';
import { path } from 'ramda';
import { useMutation, useQuery } from '@apollo/react-hooks';
import moment from 'moment';

import {
  getNotifications,
  deleteNotificationMutation,
  readNotificationsMutation,
} from './queries.js';

const NOTIFICATION_LOCALSTORAGE_LABEL = 'closedNotificationPanelTimeStamp';
// const DEFAULT_POLL_INTERVAL = 30000;

const checkIsNotificationRead = (notification) => {
  const closedNotificationPanelTimeStamp = localStorage.getItem(NOTIFICATION_LOCALSTORAGE_LABEL);

  return (
    closedNotificationPanelTimeStamp > moment.utc(notification.createdTime).valueOf() ||
    !closedNotificationPanelTimeStamp
  );
};

const updateLastReadNotificationsTimestamp = (timestamp) => {
  localStorage.setItem(NOTIFICATION_LOCALSTORAGE_LABEL, timestamp);
};

export const mapNotification = (notification) => {
  const isRead = checkIsNotificationRead(notification);
  const { token, createdTime, title, message, link } = notification;
  return {
    token,
    createdDate: createdTime,
    headLine: title,
    description: message,
    isRead,
    link,
  };
};

const useNotifications = () => {
  const [notifications, saveNotifications] = useState([]);
  const [unsuccessfulPollingCounter, setUnsuccessfulPollingCounter] = useState(0);

  useEffect(() => {
    if (!localStorage.getItem(NOTIFICATION_LOCALSTORAGE_LABEL)) {
      localStorage.setItem(NOTIFICATION_LOCALSTORAGE_LABEL, '');
    }
  }, []);

  const { data, stopPolling } = useQuery(getNotifications, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    // pollInterval: DEFAULT_POLL_INTERVAL,
    skip: true,
    onError: (error) => {
      console.error(error);
      if (unsuccessfulPollingCounter) {
        stopPolling();
      }

      setUnsuccessfulPollingCounter(unsuccessfulPollingCounter + 1);
      // startPolling(DEFAULT_POLL_INTERVAL);
    },
  });

  useEffect(() => {
    if (!data) return;

    const newNotifications = path(['notifications', 'data'], data);
    const notificationsToSave = newNotifications
      .map(mapNotification)
      .sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
    saveNotifications(notificationsToSave);
  }, [data]);

  const [removeNotification] = useMutation(deleteNotificationMutation);
  const [readAllNotifications] = useMutation(readNotificationsMutation);

  const removeNotificationHandler = async (notificationToken) => {
    if (notificationToken) {
      await removeNotification({
        variables: { notificationToken },
      });
    }
  };

  const removeNotificationSectionHandler = async (notifications) => {
    if (notifications && notifications.length) {
      await Promise.all(notifications.map(({ token }) => removeNotificationHandler(token)));
    }
  };

  const markAllNotificationsAsRead = async () => {
    if (!notifications.length) return;

    const readAllNotificationsData = await readAllNotifications();

    const readNotifications = notifications.map((sn) => ({ ...sn, isRead: true }));
    saveNotifications(readNotifications);

    const timestamp = path(['data', 'readNotifications', 'timestamp'], readAllNotificationsData);
    const timestampToUpdate = Number(timestamp) || moment.utc().valueOf();
    updateLastReadNotificationsTimestamp(timestampToUpdate);
  };

  return {
    notifications,
    saveNotifications,
    removeNotificationHandler,
    removeNotificationSectionHandler,
    markAllNotificationsAsRead,
  };
};

export default useNotifications;
