import { length, not, trim } from 'ramda';

const WEBHOOKS_FORM_VALIDATION_MSG = {
  NAME: 'Provide a valid webhook name. Maximum of 36 characters, spaces and special characters permitted.',
  URL: 'Provide a valid URL. The URL must use HTTPS.',
  USERNAME: 'Provide a valid authentication username. Maximum of 50 characters.',
  PASSWORD: 'Provide a strong password.',
  HEADERS: 'Provide valid custom HTTP headers. A maximum of three headers is permitted.',
};

const webhooksFormValidators = {
  name: [
    {
      validator: (val) => {
        return val.length > 64;
      },
      message: WEBHOOKS_FORM_VALIDATION_MSG.NAME,
    },
  ],
  url: [
    {
      validator: (val) => {
        if (length(val) > 255) return true;
        try {
          var url = new URL(val);
          return url.protocol !== 'https:';
        } catch (e) {
          return true;
        }
      },
      message: WEBHOOKS_FORM_VALIDATION_MSG.URL,
    },
  ],
  username: [
    {
      validator: (val) => {
        return val.length > 50;
      },
      message: WEBHOOKS_FORM_VALIDATION_MSG.USERNAME,
    },
  ],
  password: [
    {
      validator: (val) => {
        return passwordValid(val);
      },
      message: WEBHOOKS_FORM_VALIDATION_MSG.PASSWORD,
    },
  ],
  headers: [
    {
      validator: (val) => {
        if (not(trim(val))) return false;
        try {
          JSON.parse(val);
          return false;
        } catch (e) {
          return true;
        }
      },
      message: WEBHOOKS_FORM_VALIDATION_MSG.HEADERS,
    },
  ],
};

const lowerCaseCheck = (password) => /[a-z]/.test(password);
const upperCaseCheck = (password) => /[A-Z]/.test(password);
const specialCharacterCheck = (password) => /[~`!@#$%^&*+=()\-[\]\\';,/{}|\\":<>?_]/.test(password);
const numberCheck = (password) => /\d/.test(password);
const twentyCharactersCheck = (password) => password.length >= 20;
const twentyToFiftyCharactersCheck = (password) => password.length >= 20 && password.length <= 50;

const passwordValid = (_password = '') => {
  const lowerCase = lowerCaseCheck(_password);
  const upperCase = upperCaseCheck(_password);
  const specialCharacter = specialCharacterCheck(_password);
  const number = numberCheck(_password);
  const twentyToFiftyCharacters = twentyToFiftyCharactersCheck(_password);
  return lowerCase && upperCase && specialCharacter && number && twentyToFiftyCharacters;
};

export {
  WEBHOOKS_FORM_VALIDATION_MSG,
  webhooksFormValidators,
  passwordValid,
  lowerCaseCheck,
  upperCaseCheck,
  specialCharacterCheck,
  numberCheck,
  twentyCharactersCheck,
  twentyToFiftyCharactersCheck,
};
