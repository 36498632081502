// '2021-01-27T13:33:45.128Z' => '2021-01-27 13:33'
export const parseDateTimeString = (
  dateTime = '',
  searchValue = /^(.{10})T(.{5}):(.*)/,
  replaceValue = '$1, $2'
) => dateTime.replace(searchValue, replaceValue);

// '2021-01-27T13:33:45.128Z' => '2021-01-27'
export const parseDateString = (dateTime = '') => parseDateTimeString(dateTime).split(' ')[0];

export const dateToMMDDYYYY = (date) => {
  if (Date.parse(date)) {
    const formattedDate = new Date(date);
    const yyyymmdd = formattedDate
      .toISOString() // 2022-08-25T09:51:26.317Z
      .replace('T', ', ') // 2022-08-25, 09:51:26.317Z
      .split(',')[0]; // 2022-08-25
    return `${yyyymmdd.slice(5)}-${yyyymmdd.slice(0, 4)}`;
  }

  return date;
};
