import React from 'react';
import { EmptyState, OnPageError, Flex, VSpacer, Link } from '@mqd/volt-base';

const ErrorLoading = () => {
  return (
    <Flex alignItems="center" display="flex" flexDirection="column" type="div">
      <OnPageError type="sm">
        Unable to load data.{' '}
        <Link inline inheritParentFont onClick={() => window.location.reload()}>
          Refresh
        </Link>
      </OnPageError>
      <VSpacer factor={3} transition={false} />
      <EmptyState type="large" text="No data available" iconType="empty-state-folder" />
    </Flex>
  );
};

export default ErrorLoading;
