import React from 'react';
import { useCallback } from 'react';
import { RedseaContext } from '@mq/volt-amc-container';
import { useProgramSelectorStateContext } from '../../components/program-selector/context.js';

export default function useHasRoleInArrayForActiveProgram() {
  const { user: { redseaRolesToProgramsMap } = {} } = RedseaContext.useRedseaStateContext();
  const { activeProgram } = useProgramSelectorStateContext();

  return useCallback(
    (rolesArray) => {
      const hasRoleForActiveProgram = (role) => {
        const activeProgramShortcode = activeProgram && activeProgram.short_name;
        if (redseaRolesToProgramsMap && redseaRolesToProgramsMap[role]) {
          return redseaRolesToProgramsMap[role].find((shortCode) => {
            const allMarqetaPermissions = shortCode === 'marqeta';
            const matchingActiveProgram = activeProgramShortcode === shortCode;
            return allMarqetaPermissions || matchingActiveProgram;
          });
        }
        return false;
      };
      return rolesArray.find((role) => hasRoleForActiveProgram(role));
    },
    [redseaRolesToProgramsMap, activeProgram]
  );
}

export function withHasRoleInArrayForActiveProgram(Component) {
  return function WrapperComponent(props) {
    const hasRoleInArrayForActiveProgram = useHasRoleInArrayForActiveProgram();

    return <Component {...props} hasRoleInArrayForActiveProgram={hasRoleInArrayForActiveProgram} />;
  };
}
