import React from 'react';

const FormButton = ({ key, className, testId, label, onClick, style }) => (
  <div key={key} className={className} onClick={onClick} data-testid={testId} style={style}>
    {label}
  </div>
);

FormButton.defaultProps = {
  kind: 'button',
};

export default FormButton;
