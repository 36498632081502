import React from 'react';
import PropTypes from 'prop-types';
import { Status } from '@mq/voltron-parent';
import { Text, VSpacer } from '@mqd/volt-base';

function CardStatus({ status, lastFour }) {
  return (
    <div>
      <Status textType="label" uppercase={true} status={status}>
        &nbsp;CARD
      </Status>
      <VSpacer />
      <Text type="h3" testId="card-pan-last-four" inline>
        Card<div style={{ marginLeft: '6px' }}>{lastFour}</div>
      </Text>
    </div>
  );
}

CardStatus.propTypes = {
  status: PropTypes.string,
  lastFour: PropTypes.string,
};

CardStatus.defaultProps = {
  status: '',
  lastFour: '',
};

export default CardStatus;
