import currentUserStore from 'stores/CurrentUserStore';

export const requirements = {
  flipFlopRequirements: ['bin-data-management-active'],
  requiredUserPermissions: ['manage-bin-data-internal'],
};

export const verifier = () => {
  const { userStore = {} } = currentUserStore;
  // check if user has feature flag turned on
  return userStore.canManageBinData;
};
