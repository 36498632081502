import React from 'react';
import { StatusIndicator, Tooltip, Flex } from '@mqd/volt-base';
import { formatMoney } from '../../../../utils/money.js';
import { formatDate } from '../../../../utils/date.js';
import {
  ENTRY_TYPE,
  ENTRY_TYPE_LABEL,
  TRANSACTION_STATUS,
  TRANSACTION_STATUS_LABEL,
} from '../../../../constants.js';

function plainRenderer({ cellValue }) {
  return cellValue;
}

export const status = {
  header: 'Status',
  accessor: 'status',
  width: 120,
  renderer: ({ row, cellValue }) => {
    const STATUS_TO_COLOR_MAP = {
      [TRANSACTION_STATUS.FAILED]: '#ff304a',
      [TRANSACTION_STATUS.PENDING]: '#ffc13b',
      [TRANSACTION_STATUS.SUCCEEDED]: '#3db65c',
    };

    const status = TRANSACTION_STATUS_LABEL[cellValue];
    const color = STATUS_TO_COLOR_MAP[cellValue];

    const statusIndicator = <StatusIndicator status={status} colorOverride={color} />;

    if (row.failure_reason) {
      return (
        <Tooltip content={row.failure_reason} direction="bottom" fixOverflow>
          {statusIndicator}
        </Tooltip>
      );
    }

    return statusIndicator;
  },
};

export const createdAt = {
  header: 'Date processed',
  accessor: 'created_at',
  width: 162,
  renderer: ({ cellValue }) => formatDate(Number(cellValue)),
};

export const createdTime = {
  header: 'Date processed',
  accessor: 'created_time',
  width: 162,
  renderer: ({ cellValue }) => formatDate(cellValue),
};

export const program = {
  header: 'Program',
  accessor: 'program',
  width: 220,
  renderer: ({ row }) => row.programFullName,
};

export const programFullName = {
  header: 'Program',
  accessor: 'programFullName',
  width: 220,
  noSort: true,
};

export const type = {
  header: 'Type',
  accessor: 'type',
  width: 150,
  renderer: ({ cellValue }) => ENTRY_TYPE_LABEL[cellValue],
};

export const amount = {
  header: 'Deposit/repayment amount',
  accessor: 'amount',
  width: 210,
  headerStyle: {
    justifyContent: 'flex-end',
  },
  columnStyle: {
    justifyContent: 'flex-end',
  },
  renderer: ({ cellValue, row }) => {
    const sign = row.type === ENTRY_TYPE.CREDIT ? 1 : -1;
    const amountWithSign = sign * Math.abs(cellValue);

    return (
      <Flex flexDirection="row" justifyContent="flex-end">
        {formatMoney(amountWithSign)}
      </Flex>
    );
  },
};

export const collateral = {
  header: 'Collateral amount',
  accessor: 'collateral_amount',
  width: 140,
  headerStyle: {
    justifyContent: 'flex-end',
  },
  columnStyle: {
    justifyContent: 'flex-end',
  },
  renderer: ({ cellValue, row }) => {
    const sign = row.type === ENTRY_TYPE.CREDIT ? 1 : -1;
    const amountWithSign = sign * Math.abs(cellValue);
    return (
      <Flex flexDirection="row" justifyContent="flex-end">
        {formatMoney(amountWithSign)}
      </Flex>
    );
  },
};

export const currency = {
  header: 'Currency',
  accessor: 'currency_code',
  width: 110,
};

export const token = {
  header: 'Transaction token',
  accessor: 'token',
  width: 310,
};

export const transactionToken = {
  header: 'Transaction token',
  accessor: 'transaction_token',
  width: 310,
};

export const memo = {
  header: 'Memo',
  accessor: 'memo',
  width: 220,
  renderer: plainRenderer,
};

export const bankFile = {
  header: 'Bank file',
  accessor: 'bank_file',
  width: 200,
  renderer: plainRenderer,
};

export const failureReason = {
  hide: true,
  header: 'Reason failed',
  accessor: 'failure_reason',
  width: 600,
  renderer: plainRenderer,
};
