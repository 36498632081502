import React from 'react';

import { ActionCard } from '@mqd/volt-base';
import { useCardTemplateContext } from '../../../../context/CardTemplateContext';

const { TemplateWrapper } = ActionCard;

export function PreviewTemplate({ datum, mode, switchToEdit }) {
  const { previewTemplate: PreviewTemplate } = useCardTemplateContext();

  return (
    <TemplateWrapper mode={mode} onClick={switchToEdit}>
      <PreviewTemplate datum={datum} />
    </TemplateWrapper>
  );
}
