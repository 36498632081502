import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Text } from '@mqd/volt-base';

const formatDate = (date) => {
  const dateInstance = new Date(date);
  const [day, time] = dateInstance.toISOString().split('T');
  return `${day}, ${time.substring(0, 5)}`;
};

const Timestamp = ({ createdTime, lastModifiedTime, testId }) => (
  <Flex display="inline-flex" testId={testId}>
    <Text type="footnote">
      Created {formatDate(createdTime)} • Last Modified {formatDate(lastModifiedTime)}
    </Text>
  </Flex>
);

Timestamp.propTypes = {
  createdTime: PropTypes.string,
  lastModifiedTime: PropTypes.string,
  testId: PropTypes.string,
};

Timestamp.defaultProps = {
  testId: 'timestamp',
};

export default Timestamp;
