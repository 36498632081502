import currentUserStore from 'stores/CurrentUserStore';

export const requirements = {
  requiredPermissions: ['Admin', 'SuperAdmin'],
  marqetaOnly: true,
};

export const verifier = () => {
  const allowed = ['Admin', 'SuperAdmin'];
  const isMarqetan =
    currentUserStore.userStore.userOrgName && currentUserStore.userStore.userOrgName === 'Marqeta';
  return !!isMarqetan && allowed.includes(currentUserStore.userStore.permissionLevel);
};
