import { SET_ENVIRONMENT } from './constants';

export const defaultState = {
  environment: '',
};

export default function reducer(state, action) {
  switch (action.type) {
    case SET_ENVIRONMENT:
      return {
        ...state,
        environment: action.payload,
      };
    default:
      return state;
  }
}
