import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { getNameObject, getMultiFieldStatus, getAddressObject, getIssues } from '../helpers';
import { Col, Container, Row, Text, ToggleVisibility, VSpacer } from '@mqd/volt-base';
import KycStatus from './../../shared-components/kyc-status/KycStatus';
import s from '../ReviewDetails.module.css';

class KYCDetailsView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderName() {
    const nameObj = getNameObject(this.props.details);
    const nameStatus = getMultiFieldStatus(nameObj);

    return (
      <Container gutter={12}>
        <Row testId={'kyc-details-name'}>
          <Col span={3}>
            <Text type="h6">Full name</Text>
          </Col>
          <Col span={4}>
            <Text type="body-sm" className={s.breakWords}>
              {nameObj.first_name.value} {nameObj.middle_name.value} {nameObj.last_name.value}
            </Text>
          </Col>
          <Col span={2}>
            <KycStatus status={nameStatus} showText={false} />
          </Col>
        </Row>
      </Container>
    );
  }

  renderAddress() {
    const addressObj = getAddressObject(this.props.details);
    const addressStatus = getMultiFieldStatus(addressObj);

    return (
      <Container gutter={12}>
        <Row testId={'kyc-details-address'}>
          <Col span={3}>
            <Text type="h6">Address</Text>
          </Col>
          <Col span={4}>
            <Text type="body-sm" className={s.breakWords}>
              {addressObj.address1.value}
            </Text>
            {addressObj.address2 && addressObj.address2.value && (
              <Text type="body-sm" className={s.breakWords}>
                {addressObj.address2.value}
              </Text>
            )}
            <Text type="body-sm" className={s.breakWords}>
              {addressObj.city.value}, {addressObj.state.value} {addressObj.postal_code.value}
            </Text>
            <Text type="body-sm" className={s.breakWords}>
              {addressObj.country.value}
            </Text>
          </Col>
          <Col span={2}>
            <KycStatus status={addressStatus} showText={false} />
          </Col>
        </Row>
      </Container>
    );
  }

  renderDOB() {
    const { birth_date = { value: '', status: '' } } = this.props.details;
    const status = birth_date.status
      ? birth_date.status === 'SUCCESS'
        ? 'VERIFIED'
        : birth_date.status
      : 'UNVERIFIED';

    return (
      <Container gutter={12}>
        <Row testId={'kyc-details-birth_date'}>
          <Col span={3}>
            <Text type="h6">DOB</Text>
          </Col>
          <Col span={4}>
            <Text type="body-sm" className={s.breakWords}>
              {birth_date.value}
            </Text>
          </Col>
          <Col span={2}>
            <KycStatus status={status} showText={false} />
          </Col>
        </Row>
      </Container>
    );
  }

  renderSSN() {
    const { canRevealIdentification } = this.props;
    const { ssn = { value: '', status: '' } } = this.props.details;
    const status = ssn.status ? (ssn.status === 'SUCCESS' ? 'VERIFIED' : ssn.status) : 'UNVERIFIED';

    return (
      <Container gutter={12}>
        <Row testId={'kyc-details-ssn'}>
          <Col span={3}>
            <Text type="h6">SSN</Text>
          </Col>
          <Col span={4}>
            <Text type="body-sm" className={s.breakWords}>
              <ToggleVisibility
                abbreviate={ssn.value ? 'lastFour' : null}
                formatting="ssn"
                value={ssn.value}
                readOnly={!canRevealIdentification}
              />
            </Text>
          </Col>
          <Col span={2}>
            <KycStatus status={status} showText={false} />
          </Col>
        </Row>
      </Container>
    );
  }

  renderIssues() {
    const { reviewStatus } = this.props;
    const issues = getIssues(this.props.details);
    if (reviewStatus !== 'CLOSED' && issues) {
      return (
        <div className={s.issuesBox} data-testid="kyc-details-view-issues-box">
          <Text type="body-sm">Current KYC issue(s): {issues}</Text>
        </div>
      );
    }
  }

  render() {
    return (
      <div style={{ width: '100%' }} data-testid="RCRM-kyc-details-view">
        <VSpacer factor={2} />
        {this.renderIssues()}
        <VSpacer factor={3} />
        {this.renderName()}
        <VSpacer factor={2} />
        {this.renderAddress()}
        <VSpacer factor={2} />
        {this.renderDOB()}
        <VSpacer factor={2} />
        {this.renderSSN()}
      </div>
    );
  }
}

KYCDetailsView.propTypes = {
  details: PropTypes.object,
  canRevealIdentification: PropTypes.bool,
  reviewStatus: PropTypes.string,
};

KYCDetailsView.defaultProps = {
  details: {},
  canRevealIdentification: false,
  reviewStatus: '',
};

export default observer(KYCDetailsView);
