import React from 'react';
import TimelineNode from './TimelineNode';
import { Colors, Icon, Text, VSpacer } from '@mqd/volt-base';
import s from './Timeline.module.css';
import PropTypes from 'prop-types';
import moment from 'moment';

// helper function
function Title({ accordionNodeLength }) {
  return <Text type="h6">{accordionNodeLength} items updated</Text>;
}

// helper function
function CollapsedContent({ userEmail, loggedTime }) {
  return (
    <div data-testid="KYB-accordion-collapsed-content">
      <VSpacer factor={1 / 2} />
      <Text type="footnote" color={Colors.blackLighter4}>{`by ${userEmail} at ${moment(
        loggedTime
      ).format('MM-DD-YYYY HH:mm z')}`}</Text>
    </div>
  );
}

// helper function
function ExpandedContent({ eventsArray }) {
  return (
    <div data-testid="KYB-accordion-expanded-content">
      <VSpacer />
      {eventsArray.map((event, i) => (
        <React.Fragment key={`history-event-${i}`}>
          <TimelineNode event={event} />
          <VSpacer factor={2.5} />
        </React.Fragment>
      ))}
    </div>
  );
}

class KYBAccordionNode extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
  }

  render() {
    const { eventsArray } = this.props;
    const { active } = this.state;
    const { user_email, timestamp } = eventsArray[0] || {};
    const toggleActive = () =>
      this.setState({
        active: !active,
      });

    return (
      <div
        className={s.accordionNode}
        onClick={toggleActive}
        data-testid="KYB-accordion-node-container"
      >
        <div className={`${s.titleRow} ${active ? s.active : ''}`}>
          <Title accordionNodeLength={eventsArray.length} />
          <Icon type="chevron-down" />
        </div>
        {active ? (
          <ExpandedContent eventsArray={eventsArray} />
        ) : (
          <CollapsedContent userEmail={user_email} loggedTime={timestamp} />
        )}
      </div>
    );
  }
}

KYBAccordionNode.propTypes = {
  eventsArray: PropTypes.array,
};
KYBAccordionNode.defaultProps = {
  eventsArray: [],
};

export default KYBAccordionNode;
