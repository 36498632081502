import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { containerWidthHOC } from '@mq/voltron-parent';
import { Container, Row, Col, Card, Text, VSpacer } from '@mqd/volt-base';
import { flipFlop } from '@mq/volt-amc-container';
import CardholderInformationCard from './CardholderInformationCard';
import CardholderIdentificationsCard from './CardholderIdentificationsCard';
import CardholderAuthAndVelocityControlsCard from '../spend-controls-tab/CardholderAuthAndVelocityControlsCard';
import CardholderBalanceCard from '../shared-components/balance-card/BalanceCard';
import KycDetailsCard from '../shared-components/kyc-details-card/KycDetailsCard';

class CardholderInfoTab extends Component {
  render() {
    const isKycDetailsActive = flipFlop.get('kyc-kyb-details', false);
    const {
      canRevealIdentification,
      cardholderStore,
      containerWidth,
      goToReview,
      hasUamRoleToken,
      hasUserDetailsEdit,
      userStore,
    } = this.props;

    if (!cardholderStore) {
      cardholderStore = {};
    }
    let colSpan = 4;
    if (containerWidth <= 1200) {
      colSpan = 6;
    }
    if (containerWidth <= 800) {
      colSpan = 12;
    }
    const {
      createKycReview,
      createReviewRoles,
      createTransitionAllowedRoles,
      kycData,
      kycDetails,
      manualOverrideRoles,
      performManualOverride,
      updateCardholderAllowedRoles,
      reviewToken,
    } = cardholderStore;
    const { hasRoleInArrayForActiveProgram = () => {} } = userStore;
    const updateEditable = hasRoleInArrayForActiveProgram(updateCardholderAllowedRoles);
    const canMakeTransition = hasRoleInArrayForActiveProgram(createTransitionAllowedRoles);

    const canEditIdentifications = !(hasUamRoleToken && !hasUserDetailsEdit);

    const canManualOverride = hasRoleInArrayForActiveProgram(manualOverrideRoles);
    const canCreateReview = hasRoleInArrayForActiveProgram(createReviewRoles);
    return (
      <div>
        <Container>
          <Row>
            <Col span={colSpan}>
              <CardholderInformationCard
                cardholderStore={cardholderStore}
                editable={hasUamRoleToken ? hasUserDetailsEdit : updateEditable}
                canMakeTransition={canMakeTransition}
                hasUamRoleToken={hasUamRoleToken}
                hasUserDetailsEdit={hasUserDetailsEdit}
              />
              <CardholderBalanceCard store={cardholderStore} />
            </Col>
            <Col span={colSpan}>
              <CardholderIdentificationsCard
                hasUamRoleToken={hasUamRoleToken}
                canRevealIdentification={canRevealIdentification}
                cardholderStore={cardholderStore}
                editable={hasUamRoleToken ? hasUserDetailsEdit : updateEditable}
                canEditIdentifications={canEditIdentifications}
              />
              {isKycDetailsActive ? (
                <Card contentPadding="16px 24px 24px 24px">
                  <Text type="h5">KYC details</Text>
                  <VSpacer factor={2} />
                  <KycDetailsCard
                    canManualOverride={canManualOverride}
                    createKycReview={createKycReview}
                    goToReview={goToReview}
                    kycData={kycData}
                    canCreateReview={canCreateReview}
                    latestKycResult={kycDetails}
                    performManualOverride={performManualOverride}
                    reviewToken={reviewToken}
                    type="KYC"
                  />
                </Card>
              ) : null}
            </Col>
            <Col span={colSpan}>
              <CardholderAuthAndVelocityControlsCard cardholderStore={cardholderStore} />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

CardholderInfoTab.propTypes = {
  canRevealIdentification: PropTypes.bool,
  cardholderStore: PropTypes.any,
  goToReview: PropTypes.func,
  userStore: PropTypes.any,
};

CardholderInfoTab.defaultProps = {
  canRevealIdentification: false,
  cardholderStore: {},
  goToReview: () => {},
  userStore: {},
};

export default containerWidthHOC(observer(CardholderInfoTab));
