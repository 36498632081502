import currentUserStore from 'stores/CurrentUserStore';
import {
  USER_DETAILS_VIEW,
  USER_DETAILS_VIEW_AND_EDIT,
} from '../../../views/admin/uam-granular-permissions/constants.js';
import { isDdaProgram } from './functions.js';
import { janusToRedseaRoles } from './constants/redseaRoles.js';

export const requirements = {
  requiredGranularPermissions: [USER_DETAILS_VIEW_AND_EDIT, USER_DETAILS_VIEW],
};

export const verifier = () => {
  const { userStore = {} } = currentUserStore;
  const { hasRoleInArrayForActiveEnvironment, privateSandboxActive, uamGranularPermissionsActive } =
    userStore;
  const hasUnify = userStore.unifyActive && !isDdaProgram();
  const userDetailsRoles = [USER_DETAILS_VIEW, USER_DETAILS_VIEW_AND_EDIT];
  const canViewUserDetails = uamGranularPermissionsActive
    ? userDetailsRoles.some((role_token) => userStore.redseaRoles?.includes(role_token))
    : hasUnify;
  const canViewUserDetailsPrivateSandbox = uamGranularPermissionsActive
    ? hasRoleInArrayForActiveEnvironment(userDetailsRoles)
    : hasRoleInArrayForActiveEnvironment(janusToRedseaRoles);

  return privateSandboxActive ? canViewUserDetailsPrivateSandbox : canViewUserDetails;
};

export const redirect = () => {
  if (isDdaProgram()) return 'programAccountholders';
  return false;
};
