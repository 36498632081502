import React from 'react';
import ProgramTransactionHistoryStoreProvider from '../components/ProgramTransactionHistoryStoreProvider/index.js';

export function withProgramTransactionHistoryStore(Component) {
  return function WithProgramTransactionHistoryStoreComponent(props) {
    return (
      <ProgramTransactionHistoryStoreProvider>
        <Component {...props} />
      </ProgramTransactionHistoryStoreProvider>
    );
  };
}
