import React from 'react';
import ProgramReserveStoreProvider from '../components/ProgramReserveStoreProvider/index.js';

export function withProgramReserveStore(Component) {
  return function WithProgramReserveStoreComponent(props) {
    return (
      <ProgramReserveStoreProvider>
        <Component {...props} />
      </ProgramReserveStoreProvider>
    );
  };
}
