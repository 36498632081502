import React, { useState, useMemo } from 'react';
import sortData from './sortData.js';

const TableSort = ({ children, configuration }) => {
  const [sort, setSort] = useState();

  const handleSort = (data) => (newSort) => {
    setSort(newSort);
  };

  let tableChild;
  const sortedData = useMemo(() => {
    const data = children.props.data || [];
    return sortData({ data, sort, configuration });
  }, [children.props.data, sort, configuration]);

  try {
    tableChild = React.Children.only(children);
  } catch (e) {
    if (process.env.NODE_ENV === 'development') {
      throw e;
    } else {
      console.error(e);
      return;
    }
  }

  const clonedTableWithProps = React.cloneElement(
    tableChild,
    {
      ...tableChild.props,
      data: sortedData,
      onSort: handleSort(tableChild.props.data),
      sort,
    },
    tableChild.children
  );

  return clonedTableWithProps;
};

export default TableSort;
