import moment from 'moment';

import { DATE_LABELS } from './constants';

moment.updateLocale('en', {
  calendar: {
    lastDay: '[Yesterday]',
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    lastWeek: '[This week]',
    sameElse: '[Earlier]',
  },
});

export const formatDate = (date) => {
  return date && moment.utc(date).calendar();
};

export const splitDateSections = (notificationsToSplit) => {
  return notificationsToSplit.reduce(
    (acc, curr) => {
      const formattedDate = formatDate(curr.createdDate);

      switch (formattedDate) {
        case DATE_LABELS.TODAY:
          acc[0].push(curr);
          break;

        case DATE_LABELS.YESTERDAY:
          acc[1].push(curr);
          break;

        case DATE_LABELS.THIS_WEEK:
          acc[2].push(curr);
          break;

        case DATE_LABELS.EARLIER:
          acc[3].push(curr);
          break;

        default:
          acc[3].push(curr);
          break;
      }

      return acc;
    },
    [[], [], [], []]
  );
};
