import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Flex, HSpacer, Text, LoadingOverlay } from '@mqd/volt-base';
import { SegmentTrackHOC } from '@mq/voltron-parent';
import { StoreContext } from '@mqd/volt-contexts';
import { dig, formatAmount } from './../shared-utils';
import AddFundsForm from './AddFundsForm';
import ConfirmFundsForm from './ConfirmFundsForm';

function AddFundsModal({ hideModal, cardholderStore, showSuccessToast }) {
  const [programFundingSources, setProgramFundingSources] = useState([]);
  const [formState, setFormState] = useState({});
  const [confirmMode, setConfirmMode] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const { addFunds, fullName, getProgramFundingSources } = cardholderStore;
  const gpa = dig(cardholderStore, 'gpa_balance', 'gpa');

  const programFundingSourceNames = useMemo(
    () => programFundingSources.map(({ name }) => name),
    [programFundingSources]
  );

  useEffect(() => {
    (async () => {
      if (getProgramFundingSources) {
        try {
          setLoading(true);
          const programFundingSources = await getProgramFundingSources();
          programFundingSources && setProgramFundingSources(programFundingSources);
        } catch (e) {
          setError(e);
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [getProgramFundingSources]);

  const handleFormChange = ({ program_funding_source_name, amount, currency_code }) => {
    const programFundingSourceObject = programFundingSources.find(
      (programFundingSource) => programFundingSource.name === program_funding_source_name
    );
    setFormState({
      funding_source_token: programFundingSourceObject && programFundingSourceObject.token,
      amount,
      currency_code,
      program_funding_source_name,
    });
  };

  const handleSave = async ({ funding_source_token, amount, currency_code }) => {
    try {
      setLoading(true);
      await addFunds({ funding_source_token, amount, currency_code });
      hideModal();
      showSuccessToast();
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  const editModeButtons = [
    <Button key="continue" onClick={() => setConfirmMode(true)}>
      Continue
    </Button>,
  ];

  const confirmModeButtons = [
    <Button key="back-to-edit" type="tertiary" onClick={() => setConfirmMode(false)}>
      Cancel
    </Button>,
    <SegmentTrackHOC eventName="Funds added">
      <Button key="add-funds" onClick={() => handleSave(formState)}>
        Add funds
      </Button>
    </SegmentTrackHOC>,
  ];

  return (
    <StoreContext.Consumer>
      {({ ProgramConfigStore = {} } = {}) => {
        const { defaultCurrency } = ProgramConfigStore;
        return (
          <>
            <Modal
              error={error}
              heading={confirmMode ? 'Confirm adding funds' : 'Add funds to user ledger'}
              description={
                <Flex flexDirection="row">
                  <Text>Ledger balance for {fullName}:</Text>
                  <HSpacer factor={0.5} />
                  <Text type="h6">
                    {gpa && gpa.currency_code} {gpa && formatAmount(gpa.ledger_balance)}
                  </Text>
                </Flex>
              }
              disableOverFlow
              hideModal={hideModal}
              showHideModalButton={!confirmMode}
              footerButtons={confirmMode ? confirmModeButtons : editModeButtons}
            >
              <LoadingOverlay active={loading}>
                {confirmMode ? (
                  <ConfirmFundsForm formState={formState} />
                ) : (
                  <AddFundsForm
                    defaultCurrency={defaultCurrency}
                    formState={formState}
                    handleFormChange={handleFormChange}
                    programFundingSourceNames={programFundingSourceNames}
                  />
                )}
              </LoadingOverlay>
            </Modal>
          </>
        );
      }}
    </StoreContext.Consumer>
  );
}

AddFundsModal.propTypes = {
  hideModal: PropTypes.func,
  cardholderStore: PropTypes.object,
  showSuccessToast: PropTypes.func,
};

AddFundsModal.defaultProps = {
  hideModal: () => {},
  cardholderStore: {},
  showSuccessToast: () => {},
};

export default AddFundsModal;
