import React from 'react';
import PropTypes from 'prop-types';
import { VSpacer, Text } from '@mqd/volt-base';
import s from './ErrorView.module.css';

export default function ErrorView({ children, title, description }) {
  return (
    <div className={s.viewWrap}>
      <div className={s.textWrap}>
        {title}
        <VSpacer factor={3} />
        <Text color="#434355" type="h3">
          {description}
        </Text>
        <VSpacer factor={1} />
        {children}
      </div>
    </div>
  );
}

ErrorView.propTypes = {
  children: PropTypes.any,
  title: PropTypes.element,
  description: PropTypes.string,
};

ErrorView.defaultProps = {
  children: null,
  title: <span>Oops!</span>,
  description:
    'Something went wrong! This information has been logged, and our engineers are on it!',
};
