import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import s from '../CardCreationDetail.module.css';
import { Input, Text, HSpacer, VSpacer } from '@mqd/volt-base';

function CardholderInfo({ first_name, last_name, goBack, setCardholderAttr, testId }) {
  return (
    <div data-testid={testId}>
      <Text type="h6">Card user name</Text>
      <VSpacer factor={1} />
      <div className={s.flexDiv}>
        <Input
          testId="first-name-input"
          label={
            <span>
              <b>First name</b> <i>(Optional)</i>
            </span>
          }
          placeholder="First name"
          value={first_name}
          onChange={(e) => setCardholderAttr('first_name', e.target.value)}
          width={358}
        />
        <HSpacer factor={2} />
        <Input
          testId="last-name-input"
          label={
            <span>
              <b>Last name</b> <i>(Optional)</i>
            </span>
          }
          placeholder="Last name"
          value={last_name}
          onChange={(e) => setCardholderAttr('last_name', e.target.value)}
          width={358}
        />
      </div>
    </div>
  );
}

CardholderInfo.propTypes = {
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  goBack: PropTypes.func,
  setCardholderAttr: PropTypes.func,
  testId: PropTypes.string,
};

CardholderInfo.defaultProps = {
  first_name: '',
  last_name: '',
  goBack: () => {},
  setCardholderAttr: () => {},
  testId: 'cardholder-info',
};

export default observer(CardholderInfo);
