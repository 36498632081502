// @flow
import { decorate, observable, action, computed, runInAction } from 'mobx';
import gqlUtils from '@mqd/graphql-utils';
import { ParentTableStore } from '@mq/voltron-parent';
import CardProductStore from './CardProductStore';

const { fragments, templates } = gqlUtils;

class CardProductsStore extends ParentTableStore {
  // values
  count: number = 100;
  items: Array<CardProductStore> = [];
  // query params
  name: string = '';
  binPrefix: string = '';
  token: string = '';
  paymentInstrument: string = 'all';
  // "-created_time": newest created first; "created_time": newest created last
  sortBy: string = '';

  // actions
  async hydrate() {
    try {
      this.loading = true;
      const query = `
        query cardProducts${this.outerQueryParams}{
          cardProducts${this.innerQueryParams}{
            count
            start_index
            end_index
            is_more
            data {
              ${templates.cardProductExpandedBaseInfo}
            }
          }
        }

        ${fragments.binBaseInfo}
        ${fragments.cardProductFulfillmentBaseInfo}
        ${fragments.cardProductJitFundingBaseInfo}
        ${fragments.cardProductCardLifeCycleBaseInfo}
      `;

      const result = await this.gqlQuery(
        query,
        {
          count: this.count,
          start_index: this.start_index,
        },
        undefined,
        undefined,
        'all'
      );

      runInAction(() => {
        const cardProducts = this.dig(result, 'data', 'cardProducts');
        this.processPaginationResponse(cardProducts, CardProductStore);
        this.currentFilteredItems = this.items;
      });
    } catch (e) {
      console.error('Failed to load Card products: ', e);
    } finally {
      this.loading = false;
    }
  }

  handleClear() {
    this.name = '';
    this.binPrefix = '';
    this.token = '';
    this.paymentInstrument = 'all';
  }

  // computed
  get cardProductsTableList() {
    return this.sortedItems.map((cardProduct) => {
      const {
        name,
        token,
        last_modified_time,
        config: {
          fulfillment: { payment_instrument, bin_prefix },
        },
      } = cardProduct;
      return {
        name,
        last_modified_time,
        payment_instrument: cardProduct.isPhysical ? 'PHYSICAL' : 'VIRTUAL',
        bin_prefix,
        token,
      };
    });
  }

  get filteredItems() {
    if (this.disableClear) return this.items;

    return this.items.filter((item) => {
      const fulfillment = this.dig(item, 'config', 'fulfillment');
      const { bin_prefix: itemBinPrefix, payment_instrument: itemPaymentInstrument } =
        fulfillment || {};

      if (this.name && !item.formattedName.toLowerCase().includes(this.name.toLowerCase())) {
        return false;
      }
      if (this.binPrefix && !itemBinPrefix.toLowerCase().includes(this.binPrefix.toLowerCase())) {
        return false;
      }
      if (this.token && !item.token.toLowerCase().includes(this.token.toLowerCase())) {
        return false;
      }
      // skip if both are selected
      if (this.paymentInstrument === 'virtual' && item.isPhysical) {
        return false;
      }
      if (this.paymentInstrument === 'physical' && !item.isPhysical) {
        return false;
      }

      return true;
    });
  }

  get sortedItems() {
    if (!this.sortBy) {
      return this.filteredItems;
    } else {
      return [...this.filteredItems.sort(this.sortMethod)];
    }
  }

  get sortMethod() {
    const sortDescending = Boolean(this.sortBy && this.sortBy.charAt(0) === '-');
    const attr = sortDescending ? this.sortBy.slice(1) : this.sortBy;

    return sortDescending
      ? (a, b) => new Date(b[attr]) - new Date(a[attr])
      : (a, b) => new Date(a[attr]) - new Date(b[attr]);
  }

  get disableClear() {
    return Boolean(
      !this.name && !this.binPrefix && !this.token && this.paymentInstrument === 'all'
    );
  }
}

decorate(CardProductsStore, {
  // observable
  items: observable,
  // query params
  name: observable,
  binPrefix: observable,
  token: observable,
  sortBy: observable,
  paymentInstrument: observable,

  // actions
  hydrate: action.bound,
  handleClear: action.bound,

  // computed
  cardProductsTableList: computed,
  filteredItems: computed,
  disableClear: computed,
  sortedItems: computed,
  sortMethod: computed,
});

export default CardProductsStore;
