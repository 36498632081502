import React from 'react';

import { Icon, Text, VSpacer } from '@mqd/volt-base';

import s from './NotificationList.module.css';

const EmptyState = () => {
  return (
    <div className={s.emptyState__wrapper}>
      <div className={s.emptyState__content}>
        <div className={s.emptyState__iconContainer}>
          <Icon type="inbox-inactive" factor={4} />
        </div>

        <VSpacer />
        <Text type="h4" color="#666679">
          No notifications
        </Text>
        <VSpacer factor={0.5} />

        <Text>
          You’re all caught up! When an item requires your attention, it will appear here.
        </Text>
      </div>
    </div>
  );
};

export default EmptyState;
