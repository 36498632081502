import { useEffect } from 'react';

export function useScrollToTopIf(trigger) {
  useEffect(() => {
    if (trigger) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [trigger]);
}
