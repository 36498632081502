// @flow
import { action, decorate, computed, observable, runInAction, toJS } from 'mobx';
import loadingStore from 'stores/LoadingStore';
import currentUserStore from 'stores/CurrentUserStore';
import ParentStore from './ParentStore.js';
import ManagedUserStore from './ManagedUserStore.js';
import { secureStorage } from '@mq/volt-amc-container';
import { googleAnalytics } from '@mq/volt-amc-container';
import { RedseaAddOnDepartments, RedseaAddOnSupplements } from 'stores/constants/RedseaRoleAddOns';
import { dateOrNumberSorter } from './../utils/table-helpers/index.js';
import notificationStore from 'stores/NotificationStore';
import janusApi from 'apis/JanusApi';
import { redseaRoleMapping } from '@mq/volt-amc-container';
import { FlipFlop } from '../utils/index.js';
import {
  UAM_GRANULAR_PERMISSIONS,
  NO_REPORTS,
  PRIVATE_SANDBOX,
  permissionsRoleTokenLabelAndRedseaHash,
} from '../views/admin/uam-granular-permissions/constants';

const { upgradeOnlySupplements, swissquoteOnlySupplements, supplementsToRedseaRoles } =
  redseaRoleMapping;

class AdminUsersStore extends ParentStore {
  constructor() {
    super();
    try {
      let existingDepartments = secureStorage.getItem('userMetadata-departments');
      this.departmentsOptions = existingDepartments
        ? JSON.parse(existingDepartments).map((el) => el.department_name)
        : [];

      let existingSupplements = secureStorage.getItem('userMetadata-supplements');
      this.supplementsOptions = existingSupplements ? JSON.parse(existingSupplements) : [];

      this.orgsOptions = [];

      let existingPrograms = secureStorage.getItem('userMetadata-programs');
      this.programsOptions = existingPrograms ? JSON.parse(existingPrograms) : [];
    } catch (error) {
      this.departmentsOptions = [];
      this.supplementsOptions = [];
      this.orgsOptions = [];
      this.programsOptions = [];
    }
  }

  //values
  allRedseaRoles: Array<Object> = [];
  departmentsOptions: Array<string>;
  supplementsOptions: Array<string>;
  orgsOptions: Array<string>;
  programsOptions: Array<Object>;
  userDataLoaded: boolean = false;
  activeNewUser: ?any = null;
  sort: string = '';

  activeRow: ?Object = null;
  activeEdit: boolean = false;
  filters: Object = {};
  loading: boolean = false;

  //actions
  async init(): void {
    const { userStore } = currentUserStore;
    this.getUserManagementMetadata();
    this.getOrgsMetadata();
    this.getProgramsMetadata();
    await userStore.getManagedUsers(this);
    runInAction(() => {
      this.userDataLoaded = true;
    });
  }

  async getProgramsMetadata(): void {
    const needNewPrograms =
      !secureStorage.getItem('userMetadata-programs') || toJS(this.programsOptions).length === 0;

    if (needNewPrograms) {
      try {
        const programs = await janusApi.getData('/diva/security/orgs/programs', {});
        if (programs) {
          runInAction(() => {
            this.programsOptions = programs;
          });
          secureStorage.setItem('userMetadata-programs', JSON.stringify(programs));
        }
      } catch (e) {
        console.error(e);
        this.programsOptions = [];
        secureStorage.setItem('userMetadata-programs', []);
      }
    }
  }

  async getUserManagementMetadata(): void {
    const needNewDepartments =
      !secureStorage.getItem('userMetadata-departments') ||
      toJS(this.departmentsOptions).length === 0;
    const needNewSupplements =
      !secureStorage.getItem('userMetadata-supplements') ||
      toJS(this.supplementsOptions).length === 0;

    if (needNewDepartments || needNewSupplements) {
      try {
        const metadata = await janusApi.getData('/diva/security/metadata', {});
        if (metadata) {
          if (metadata.departments) {
            runInAction(() => {
              this.departmentsOptions = metadata.departments.map((el) => el.department_name);
            });
            secureStorage.setItem('userMetadata-departments', JSON.stringify(metadata.departments));
          }
          if (metadata.supplements) {
            runInAction(() => {
              this.supplementsOptions = metadata.supplements;
            });
            secureStorage.setItem('userMetadata-supplements', JSON.stringify(metadata.supplements));
          }
        }
      } catch (e) {
        console.error(e);
        this.departmentsOptions = [];
        this.supplementsOptions = [];
        secureStorage.setItem('userMetadata-departments', []);
        secureStorage.setItem('userMetadata-supplements', []);
      }
    }
  }

  async getOrgsMetadata(): void {
    try {
      const orgs = await janusApi.getData('/diva/security/orgs', {});
      if (orgs) {
        runInAction(() => {
          this.orgsOptions = orgs;
        });
      }
    } catch (e) {
      console.warn("Couldn't get Orgs data", e);
    }
  }

  async inviteUser(): void {
    const { userStore } = currentUserStore;
    try {
      const { createUser, email, role, orgName } = this.activeManagedUserStore;
      loadingStore.startLoading('Please allow up to a minute for the user to be created.');
      const { error, redseaResult } = await createUser();
      if (!error) {
        if (googleAnalytics) {
          googleAnalytics.event({
            category: 'Key User Events',
            action: 'Invited New User',
            label: 'Invited New User',
          });
        }
        await userStore.getManagedUsers(this);
        runInAction(() => {
          this.activeNewUser = null;
        });
        loadingStore.stopLoading();
        if (redseaResult?.errors?.includes('User was created but invite email could not be sent')) {
          notificationStore.notify(
            'success',
            `The user account has been created but the email could not be sent. Use the Forgot Password? link on the Sign In page to create a new password and access your account.`
          );
        } else {
          notificationStore.notify('success', `${email} - ${role} at ${orgName} Invited`);
        }
      }
    } catch (error) {
      notificationStore.notify('error', `Could not invite the user - ${error.message}`);
    } finally {
      loadingStore.stopLoading();
    }
  }

  async editUser(): void {
    loadingStore.startLoading();
    try {
      const success = await this.activeManagedUserStore.saveEdits();
      loadingStore.stopLoading();
      if (success) {
        notificationStore.notify('success', `${this.activeManagedUserStore.email} Edited`);
        currentUserStore.userStore.getManagedUsers(this);
        runInAction(() => {
          this.activeRow = false;
          this.activeEdit = false;
        });
      }
    } catch (error) {
      notificationStore.notify('error', `${error.message}`);
    } finally {
      loadingStore.stopLoading();
    }
  }

  //computed
  get users(): Array<Object> {
    return currentUserStore.userStore.managedUsers || [];
  }

  handleTableFilter(accessor, val, type): void {
    const newFilters = JSON.parse(JSON.stringify(this.filters));
    if (val && val.length) {
      newFilters[accessor] = { val, type };
    } else {
      delete newFilters[accessor];
    }
    this.filters = newFilters;
  }

  get filteredUsers(): Array<*> {
    if (this.users && this.users.length) {
      let filtered = this.users;
      Object.keys(this.filters).forEach((filterKey) => {
        filtered = filtered.filter((user) => {
          const userVal = user && user[filterKey];
          const filterVal = this.filters[filterKey] && this.filters[filterKey].val;
          if (userVal && userVal.toLowerCase && filterVal && filterVal.toLowerCase) {
            // to account for backend returning disabled instead of inactive
            if (filterKey === 'status' && user[filterKey] === 'Disabled') {
              return 'inactive'.startsWith(filterVal.toLowerCase());
            } else if (filterKey === 'status' && user[filterKey] === 'Active') {
              return 'active'.startsWith(filterVal.toLowerCase());
            }
            return userVal.toLowerCase().includes(filterVal.toLowerCase());
          } else {
            return false;
          }
        });
      });
      return [...filtered];
    }
    return [];
  }

  get activeManagedUserStore(): ManagedUserStore {
    if (this.activeRow && this.activeRow.managedUserStore) {
      return this.activeRow.managedUserStore;
    } else if (this.activeNewUser) {
      return this.activeNewUser;
    }
    return null;
  }

  get janusDepartmentsOptionsJsArray(): Array<*> {
    if (this.departmentsOptions && this.departmentsOptions.toJS) {
      return this.departmentsOptions.toJS();
    }
    return [];
  }

  get janusSupplementsOptionsJsArray(): Array<*> {
    if (this.supplementsOptions && this.supplementsOptions.toJS) {
      return this.supplementsOptions.toJS();
    }
    return [];
  }

  get extendedDepartmentsOptions(): Array<string> {
    let extendedDepartments = [];
    const janusDepartments = this.janusDepartmentsOptionsJsArray;
    extendedDepartments = extendedDepartments.concat(janusDepartments, RedseaAddOnDepartments);
    if (!this.userIsMarqetaInternalAdmin) {
      extendedDepartments = extendedDepartments.filter((dept) => !dept.includes('(Internal)'));
    }
    return extendedDepartments;
  }

  get orgsOptionsJsArray(): Array<string> {
    const orgs = this.orgsOptions && this.orgsOptions.toJS ? this.orgsOptions.toJS() : [];
    // prevent empty string names from showing
    return orgs.map((org) => org.org_name).filter((name) => name && name !== ' ');
  }

  // https://marqeta.atlassian.net/browse/DIVA-1540
  get janusFilteredSupplementsOptionsJsArray(): Array<string> {
    if (this.supplementsOptions && this.supplementsOptions.toJS) {
      // $FlowFixMe
      const originalSupplmentsOptions = this.supplementsOptions.toJS();

      // Allows super admins to grant PII/FraudStream access
      // Superadmins are only allowed to add no_reports if editing/adding a swissquote user
      if (this.userIsSuperAdmin) {
        return originalSupplmentsOptions.filter((sup) => sup !== NO_REPORTS);
      } else {
        // Granting of PII access needs to be controlled by Compliance (DIVA-1540)
        // Granting of FraudStream requires payment for the service
        // Only users who have interchange and are admin should be able to grant others interchange.
        const controlledSupplements = new Set(['FRAUDSTREAM', 'PII']);
        return originalSupplmentsOptions.filter((option) => {
          const upperCaseOption = option.toUpperCase && option.toUpperCase();
          const isControlled = controlledSupplements.has(upperCaseOption);
          if (isControlled) {
            return false;
          }
          // Only swissquote admin users should have the no_report supplement
          if (option === NO_REPORTS) {
            return this.currentUserIsAdmin && this.currentUserIsSwissquoteAdmin;
          }
          return this.currentUserIsAdmin && this.currentUserHasSupplement(option);
        });
      }
    } else {
      return [];
    }
  }

  get isUpgradePermissions() {
    return FlipFlop.get('upgrade-permissions', false);
  }

  get isSwissquoteFeatureFlagActive() {
    return FlipFlop.get('swissquote-cp-level-restriction', false);
  }

  get currentUserIsAdmin() {
    return currentUserStore?.userStore?.userRole === 'Admin';
  }

  get currentUserIsSwissquoteAdmin() {
    return currentUserStore?.userStore?.userOrgName === 'Swissquote Bank';
  }

  get userHasUamGranularPermissionsRedseaRole() {
    return currentUserStore.userStore.redseaRoles?.includes(UAM_GRANULAR_PERMISSIONS);
  }

  get hasPrivateSandbox() {
    return currentUserStore.userStore.redseaRoles?.includes(PRIVATE_SANDBOX);
  }

  get extendedSupplements() {
    let extendedSupplements = [];
    const janusSupplements = this.janusFilteredSupplementsOptionsJsArray;

    extendedSupplements = extendedSupplements.concat(janusSupplements, RedseaAddOnSupplements);
    if (!this.userIsMarqetaInternalAdmin) {
      extendedSupplements = extendedSupplements.filter((sup) => !sup.includes('(Internal)'));
    }

    //Future ticket to remove: https://marqeta.atlassian.net/browse/PS-32323.
    //Add additional supplements for upgrade program users
    if (this.isUpgradePermissions) {
      const isUpgradeUser = currentUserStore.userStore.userOrgName === 'Upgrade';
      const { orgName: selectedOrgName } = this.activeManagedUserStore;
      upgradeOnlySupplements.forEach((supplement) => {
        const redseaRole = supplementsToRedseaRoles[supplement && supplement.toUpperCase()];
        const showUpgradeSupplements =
          (isUpgradeUser && this.currentUserHasRedseaRole(redseaRole)) ||
          (this.userIsSuperAdmin && selectedOrgName === 'Upgrade');
        if (showUpgradeSupplements) {
          extendedSupplements.push(supplement);
        }
      });
    }

    // Add additional supplements for Swissquote program users
    // For no reports, a Swissquote admin or SuperAdmin editing a swissquote user can assign it regardless if they have it or not
    if (this.isSwissquoteFeatureFlagActive) {
      const { orgName: selectedOrgName } = this.activeManagedUserStore;

      swissquoteOnlySupplements.forEach((supplement) => {
        const redseaRole = supplementsToRedseaRoles[supplement && supplement.toUpperCase()];
        const hasSupplement = this.currentUserHasRedseaRole(redseaRole);
        const currentUserIsSuperAdminEditingSwiss =
          this.userIsSuperAdmin && selectedOrgName === 'Swissquote Bank';

        const showSwissquoteSupplement =
          supplement === NO_REPORTS
            ? currentUserIsSuperAdminEditingSwiss || this.currentUserIsSwissquoteAdmin
            : currentUserIsSuperAdminEditingSwiss ||
              (this.currentUserIsSwissquoteAdmin && hasSupplement);

        if (showSwissquoteSupplement) {
          extendedSupplements.push(supplement);
        }
      });
    }

    if (!this.userIsSuperAdmin || !this.userHasUamGranularPermissionsRedseaRole) {
      extendedSupplements = extendedSupplements.filter(
        (sup) => sup !== permissionsRoleTokenLabelAndRedseaHash[UAM_GRANULAR_PERMISSIONS].label
      );
    }

    if (!this.userIsMarqetaInternalAdmin || !this.hasPrivateSandbox) {
      extendedSupplements = extendedSupplements.filter((sup) => sup !== 'Private sandbox');
    }

    return extendedSupplements;
  }

  get userIsSuperAdmin() {
    return (
      currentUserStore &&
      currentUserStore.userStore &&
      currentUserStore.userStore.userRole === 'SuperAdmin'
    );
  }

  get userIsMarqetaInternalAdmin() {
    return (
      currentUserStore &&
      currentUserStore.userStore &&
      currentUserStore.userStore.redseaRoles &&
      currentUserStore.userStore.redseaRoles.includes('marqeta-admin-internal')
    );
  }

  get userIsExternalAdmin() {
    return (
      currentUserStore &&
      currentUserStore.userStore &&
      currentUserStore.userStore.redseaRoles &&
      currentUserStore.userStore.redseaRoles.includes('program-admin')
    );
  }

  get activeOrgPrograms(): Array<string> {
    if (
      this.activeManagedUserStore &&
      this.activeManagedUserStore.orgName &&
      this.activeManagedUserStore.orgName !== ''
    ) {
      let activeOrg = {};

      try {
        this.programsOptions.forEach((org) => {
          if (org.org_name === this.activeManagedUserStore.orgName) {
            activeOrg = org;
            return [];
          }
        });
      } catch (e) {
        console.warn('Failed to parse programsOptions');
      }

      if (activeOrg && activeOrg.programs && activeOrg.programs.toJS) {
        const userAvailablePrograms = currentUserStore.userStore.redseaPrograms;
        const programList = activeOrg.programs.toJS();

        const hasMarqetaOrg = userAvailablePrograms.includes('marqeta');
        const isAvailableProgram = (program) => userAvailablePrograms.includes(program.short_name);

        return hasMarqetaOrg ? programList : programList.filter(isAvailableProgram);
      }
    }
    return [];
  }

  get liveProgramShortCodes(): Set<string> {
    const shortCodeArray = this.programsOptions.map(({ short_name }) => short_name);
    return new Set(shortCodeArray).add('marqeta');
  }

  get availableProgramNames(): Array<string> {
    return this.activeOrgPrograms.map((program) => program.program);
  }

  get allProgramsOptionsSelected(): Boolean {
    const availablePrograms = this.activeOrgPrograms;
    const selectedPrograms =
      this.activeManagedUserStore && this.activeManagedUserStore.programsJSArray;
    if (!selectedPrograms) return false;
    const selectedProgramsNames = new Set(
      selectedPrograms.map((selectedProgram) => selectedProgram.program)
    );
    for (let i = 0; i < availablePrograms.length; i++) {
      const availableProgram = availablePrograms[i];
      const availableProgramName = availableProgram.program;
      if (!selectedProgramsNames.has(availableProgramName)) {
        return false;
      }
    }
    return true;
  }

  get sortedData(): Array<*> {
    if (this.filteredUsers && this.filteredUsers.length) {
      const sortDirection = this.sort[this.sort.length - 1] === '-' ? 'DESC' : 'ASC';
      const sortColumnAccessor = this.sort.slice(0, this.sort.length - 1);
      return [...this.filteredUsers].sort(dateOrNumberSorter(sortDirection, sortColumnAccessor));
    }
    return [];
  }

  currentUserHasRedseaRole(redseaRole: string): Boolean {
    const currentUserRedseaRoles = currentUserStore.userStore.redseaRoles;
    const upperCaseRedseaRole = redseaRole.toUpperCase && redseaRole.toUpperCase();
    return currentUserRedseaRoles.some(
      (redseaRole) => redseaRole.toUpperCase && redseaRole.toUpperCase() === upperCaseRedseaRole
    );
  }

  currentUserHasSupplement(supplement: string): Boolean {
    const currentUserSupplements = currentUserStore.userStore.supplements;
    const upperCaseSupplement = supplement.toUpperCase && supplement.toUpperCase();
    return currentUserSupplements.some(
      (supplement) => supplement.toUpperCase && supplement.toUpperCase() === upperCaseSupplement
    );
  }

  getOrgTypeByName(orgName: string): string {
    const foundOrg = this.orgsOptions.find((org) => org.org_name === orgName);
    if (foundOrg && foundOrg.org_type) {
      return foundOrg.org_type;
    }
    return '';
  }
}

decorate(AdminUsersStore, {
  //values
  activeRow: observable,
  activeEdit: observable,
  filters: observable,
  loading: observable,
  userDataLoaded: observable,
  departmentsOptions: observable,
  supplementsOptions: observable,
  orgsOptions: observable,
  programsOptions: observable,
  activeNewUser: observable,
  sort: observable,

  //actions
  handleTableFilter: action.bound,
  init: action.bound,
  getProgramsMetadata: action.bound,
  getUserManagementMetadata: action.bound,
  getOrgsMetadata: action.bound,
  inviteUser: action.bound,
  editUser: action.bound,
  getOrgTypeByName: action.bound,

  //computed
  users: computed,
  activeManagedUserStore: computed,
  filteredUsers: computed,
  janusFilteredSupplementsOptionsJsArray: computed,
  janusDepartmentsOptionsJsArray: computed,
  janusSupplementsOptionsJsArray: computed,
  orgsOptionsJsArray: computed,
  availableProgramNames: computed,
  allProgramsOptionsSelected: computed,
  sortedData: computed,
  extendedDepartmentsOptions: computed,
  extendedSupplements: computed,
  currentUserIsAdmin: computed,
  currentUserIsSwissquoteAdmin: computed,
  userIsSuperAdmin: computed,
  userIsMarqetaInternalAdmin: computed,
  userIsExternalAdmin: computed,
});

const adminUsersStore = new AdminUsersStore();
export default adminUsersStore;
