import React, { useContext } from 'react';
import { ProgramReserveContext } from '../../../../context/ProgramReserveContext.js';
import Summary from '../../../Summary/Summary.js';

function ManualEntryView() {
  const { enableEditMode } = useContext(ProgramReserveContext);

  return (
    <Summary
      breadcrumbs={[{ children: 'Manual entry', onClick: enableEditMode }]}
      toastMessages={{
        success: 'Manual entry successful',
        error: 'Manual entry unsuccessful',
      }}
    />
  );
}

export default ManualEntryView;
