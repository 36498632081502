import React from 'react';
import { List, Text, VSpacer, Colors, Icon, Tooltip } from '@mqd/volt-base';
import {
  EXCEPTION_TYPES,
  SUMMARY_STATUSES,
  SUBNETWORKS,
  TOOLTIP_DESCRIPTIONS,
} from '../../utilities/dictionary/index.js';
import ErrorLoading from '../ErrorLoading/index.js';
import css from './QueueSummaries.module.css';

const COLORS_MAP = {
  waiting: 'statusWarningColor',
  ready: 'statusSuccessColor',
  in_progress: 'statusSuccessColor',
  complete: 'statusSuccessColor',
  partially_reconciled: 'statusPendingColor',
};

function QueueSummaries({
  summaries = [],
  headings = [],
  label = 'exceptions',
  onRowClick,
  error,
}) {
  return !summaries?.length && error ? (
    <ErrorLoading />
  ) : (
    summaries.map((summary) => {
      const id = `${label.replace(/ /g, '-')}-summary-${summary.subnetwork}`;
      const status = summary.status?.toLowerCase();

      return (
        <div data-testid={id} key={id}>
          <div data-testid={`${id}-heading`} className={css.heading}>
            <Text type="h4">{`${SUBNETWORKS[summary.subnetwork]} ${label} total`}</Text>
            {summary.status ? (
              <span className={css.status}>
                <span
                  className={`${css.dot}`}
                  style={{ backgroundColor: Colors[COLORS_MAP[status]] }}
                />
                {SUMMARY_STATUSES[summary.status]}
                {summary.status !== 'COMPLETE' && (
                  <Tooltip
                    content={TOOLTIP_DESCRIPTIONS[summary.status]}
                    direction="topRight"
                    textWrap={true}
                  >
                    <Icon type="info" hasTooltip={true} style={{ marginLeft: '4px' }} />
                  </Tooltip>
                )}
              </span>
            ) : (
              ''
            )}
          </div>
          <VSpacer factor={1} />
          <List
            columns={headings}
            rows={(summary.summary_lines || summary.lines).map((line) => ({
              ...line,
              type: EXCEPTION_TYPES[line.type],
            }))}
            onRowClick={({ rowIndex }) => {
              if (typeof onRowClick === 'function') {
                const row = (summary.summary_lines || summary.lines)[rowIndex];
                return onRowClick({ row, rowIndex, summary, summaries });
              }
            }}
          />
          <VSpacer factor={3} />
        </div>
      );
    })
  );
}

export default QueueSummaries;
