import React from 'react';
import Pan from '../../components/Pan/Pan.js';
import { notAvailable, textRenderer } from '../helpers/renderers.js';
import s from '../helpers/styles.css';
import getSharedFields from './shared.js';

const EXCEPTION_QUEUE_FIELDS = Object.assign(
  getSharedFields([
    'hash_id',
    'state',
    'source',
    'network_ref_id',
    'program',
    'program_type',
    'bank',
    'tx_hash_internal_id',
  ]),
  {
    ica: {
      header: 'ICA',
      accessor: 'ica',
      key: 'ica',
      width: 160,
      renderer: textRenderer,
    },

    local_transaction_date_time: {
      header: 'Local transaction date time',
      accessor: 'local_transaction_date_time',
      key: 'local_transaction_date_time',
      width: 220,
      renderer: textRenderer,
    },

    masked_pan: {
      header: 'PAN',
      accessor: 'masked_pan',
      key: 'masked_pan',
      width: 160,
      renderer: function ({ cellValue, row }) {
        if (cellValue) {
          const { tx_hash_internal_id } = row;
          return <Pan value={cellValue} tx_hash_internal_id={tx_hash_internal_id} readOnly />;
        }
        return notAvailable;
      },
    },

    message_reason_code: {
      header: 'Message reason code',
      accessor: 'message_reason_code',
      key: 'message_reason_code',
      width: 160,
      renderer: textRenderer,
    },

    settlement_date: {
      header: 'Settlement date',
      accessor: 'settlement_date',
      key: 'settlement_date',
      width: 160,
      renderer: textRenderer,
    },

    variance_amount: {
      header: 'Variance',
      accessor: 'variance_amount',
      key: 'variance_amount',
      width: 168,
      renderer: ({ cellValue }) =>
        cellValue ? (
          <div className={s.varianceCell}>
            <span>{cellValue}</span>
          </div>
        ) : (
          notAvailable
        ),
    },
  }
);

export default EXCEPTION_QUEUE_FIELDS;
