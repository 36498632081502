import { omit, type } from 'ramda';

/**
 * Returns a copy of the given object, omitting each of the entries that correspond
 * to any of the given keys.
 *
 * @param {array} keys - keys that should be omitted from the given object
 * @param {object} object - object to be modified
 * @returns {object} - value or copied object without specified keys
 */
export default function omitDeep(keys, object) {
  if (type(object) === 'Object') {
    return Object.entries(omit(keys, object)).reduce(
      (obj, [key, value]) => ({ ...obj, [key]: omitDeep(keys, value) }),
      {}
    );
  }

  return object;
}
