import React from 'react';
import { Text } from '@mqd/volt-base';

function VelocityControlUsageLimitsWithTypes({ children = [] }) {
  const childrenIsArray = Array.isArray(children);
  const invalidVelocityControlTypeValue =
    !children || (childrenIsArray && children.length === 0) || !childrenIsArray;
  if (invalidVelocityControlTypeValue) return <Text type="body-sm">--</Text>;

  return (
    children &&
    children.map(({ formattedUsageLimit, includes }, i) => {
      return (
        <div data-testid="velocity-control-usage-limit-types" key={i}>
          <Text>{formattedUsageLimit}</Text>
          <Text type="footnote">{includes}</Text>
        </div>
      );
    })
  );
}

export default VelocityControlUsageLimitsWithTypes;
