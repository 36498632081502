import routerStore from '../../../../stores/router/RouterStore.js';
import { constantToDashCase } from '../../utilities/string/index.js';
import { format as formatDate } from '../../utilities/date.js';
import fetchFds from '../../utilities/dom/fetch-fds.js';

const config = {
  exceptions: {
    title: 'Exceptions',
    fetch: (network, options) => fetchFds(`/networks/${network}/exception-summary`, options),
    linesProp: 'summary_lines',
    amountProps: ['debit_amount', 'credit_amount', 'variance_amount'],
    integerProps: ['exception_count'],
    headings: [
      {
        id: 'type',
        label: 'Type',
        renderer: 'value',
      },
      {
        id: 'exception_count',
        label: 'Count',
        renderer: 'value',
      },
      {
        id: 'debit_amount',
        label: 'Debits',
        renderer: 'amount',
      },
      {
        id: 'credit_amount',
        label: 'Credits',
        renderer: 'amount',
      },
      {
        id: 'variance_amount',
        label: 'Variance',
        renderer: 'amount',
      },
    ],
    handleRowClick({ network, summary, row, dateFilters = {} } = {}) {
      const params = {
        network,
        subnetwork: summary.subnetwork,
        originator: summary.originator,
      };
      if (dateFilters.from) {
        params.start_settlement_date = formatDate(dateFilters.from);
      }
      if (dateFilters.to) {
        params.end_settlement_date = formatDate(dateFilters.to);
      }
      return routerStore.go(`/settlement/exceptions/${constantToDashCase(row.type)}`, { params });
    },
    summaryProps: {
      subnetwork_summaries: 'exceptions',
      subnetwork_adjustment_summaries: 'adjustment exceptions',
    },
  },

  approvals: {
    title: 'Approvals',
    fetch: (network) => fetchFds(`/networks/${network}/approval-summary`),
    linesProp: 'lines',
    getCountsAndAmounts: (line) => line.counts_and_amounts,
    amountProps: ['debit_amount', 'debit_variance', 'credit_amount', 'credit_variance'],
    integerProps: ['exception_count'],
    headings: [
      {
        id: 'type',
        label: 'Type',
        renderer: 'value',
      },
      {
        id: 'exception_count',
        label: 'Count',
        renderer: 'value',
      },
      {
        id: 'debit_amount',
        label: 'Submitted debits',
        renderer: 'amount',
      },
      {
        id: 'debit_variance',
        label: 'Debit variance',
        renderer: 'amount',
      },
      {
        id: 'credit_amount',
        label: 'Submitted credits',
        renderer: 'amount',
      },
      {
        id: 'credit_variance',
        label: 'Credit variance',
        renderer: 'amount',
      },
    ],
    handleRowClick({ network, summary, row }) {
      return routerStore.go(
        `/settlement/approvals/${constantToDashCase(row.type)}?network=${network}&originator=${
          summary.originator
        }&subnetwork=${summary.subnetwork}`,
        {
          state: {
            type: row.type,
            subnetwork: summary.subnetwork,
          },
        }
      );
    },
    summaryProps: {
      subnetworks: 'approvals',
      subnetwork_adjustment_summaries: 'adjustment approvals',
    },
  },
};

export default config;
