import React from 'react';
import { useRouterGoContext } from './context.js';

export default function withRouterGo(Component) {
  return function WrapperComponent(props) {
    const go = useRouterGoContext();

    return <Component {...props} go={go} />;
  };
}
