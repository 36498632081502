import { TRANSACTIONS_FILTER_OPTIONS } from '../constants';

export * from './transformTransactionFields';

/**
 * Loops through all elements of filterOptions,
 * compares the default value (from defaultFilters) to the applied value (from appliedFilters),
 * and returns the count where applied value is not equal to default value
 * @param {Object[]} filterOptions - array of filters used in the filter form
 * @param {Object} appliedFilters
 * @param {Object} defaultFilters
 * @returns
 */
export const countActiveFilters = (filterOptions, appliedFilters = {}, defaultFilters = {}) => {
  if (!Array.isArray(filterOptions)) return 0;

  const activeFilters = filterOptions.filter((fo = {}) => {
    const appliedValue = appliedFilters[fo.filterParam];
    if (fo.type === 'multiselect') {
      const defaults = defaultFilters[fo.filterParam] || [];
      const applied = appliedValue || [];

      if (defaults.length !== applied.length) return true;
      return !defaults.every((d) => applied.findIndex((a) => a === d) !== -1);
    }

    return !!appliedValue;
  });
  return activeFilters.length;
};

const returnableTransactionTypes = [
  'directdeposit.credit',
  'directdeposit.credit.pending',
  'directdeposit.debit',
  'directdeposit.debit.pending',
];

const returnableTransactionStates = ['COMPLETION', 'PENDING'];

export const txnIsReturnableType = (type) => returnableTransactionTypes.includes(type);
export const displayReturnableTooltip = (state) => !returnableTransactionStates.includes(state);

const disputableTransactionTypes = [
  'account.funding.authorization.clearing',
  'authorization.clearing',
  'pindebit.authorization.clearing',
  'pindebit.atm.withdrawal',
  'pindebit.cashback',
  'original.credit.auth_plus_capture',
];

const disputableTransactionStates = ['COMPLETION'];

export const txnIsDisputableType = (type) => disputableTransactionTypes.includes(type);
export const displayDisputableTooltip = (state) => !disputableTransactionStates.includes(state);

const toMidnight = (date) => {
  const midnight = date ? new Date(date) : new Date();
  midnight.setHours(0);
  midnight.setMinutes(0);
  midnight.setSeconds(0);
  midnight.setMilliseconds(0);
  return midnight;
};

/**
 * Compares two filter objects (check actual filters against defaults).
 * Since we are not interested in time, date filters are adjusted to midnight for comparison.
 * @param a
 * @param b
 * @returns boolean
 */
export const filtersIsEqual = (a = {}, b = {}) => {
  const adjustedA = {};
  const adjustedB = {};

  Object.keys(a).forEach(
    (key) => (adjustedA[key] = a[key] instanceof Date ? toMidnight(a[key]) : a[key])
  );
  Object.keys(b).forEach(
    (key) => (adjustedB[key] = b[key] instanceof Date ? toMidnight(b[key]) : b[key])
  );

  return Object.keys(adjustedA).every((key) => adjustedA[key] === adjustedB[key]);
};

export const getFilterOptions = (filters, setFilters) =>
  TRANSACTIONS_FILTER_OPTIONS.map((filterOption = {}) => {
    const filterValue = filters[filterOption.filterParam];

    if (filterOption.type === 'multiselect') {
      return {
        ...filterOption,
        value: filterValue,
        selected: filterValue,
        onOptionClick: (option) => {
          const newSelection = filterValue.includes(option)
            ? filterValue.filter((s) => s !== option)
            : [...filterValue, option];

          setFilters({ ...filters, [filterOption.filterParam]: newSelection });
        },
      };
    }

    return {
      ...filterOption,
      value: filterValue,
      onChange: ({ target: { value } }) =>
        setFilters({ ...filters, [filterOption.filterParam]: value }),
    };
  });
