import { datadogRum } from '@datadog/browser-rum';
import rootPackageJson from '../../package.json';

export default function initializeDataDog() {
  datadogRum.init({
    applicationId: window.env.DATA_DOG_APPLICATION_ID,
    clientToken: window.env.DATA_DOG_CLIENT_KEY,
    site: 'datadoghq.com',
    service: 'amc',
    env: window.env.DATA_DOG_ENVIRONMENT,
    version: rootPackageJson.version,
    sessionSampleRate: 100,
    trackInteractions: true,
  });
}
