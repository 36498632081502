import React from 'react';
import PropTypes from 'prop-types';
import s from './../cardproducts-grid/CardProductGrid.module.css';
import { DetailSnippetRow } from '@mq/voltron-parent';
import { CopyToClipboard, VSpacer, Text, Hr } from '@mqd/volt-base';
const emptyVal = <Text type="body-sm">--</Text>;

function CardProductInfoCard({ token, start_date, config, active }) {
  const {
    fulfillment: {
      bin_prefix,
      fulfillment_provider,
      bin: { network, type, classification } = {},
      package_id,
      program_id,
    } = {},
    jit_funding: { fundingType } = {},
  } = config;

  return (
    <>
      <Text type="h4">Card product information</Text>
      <VSpacer factor={2} />
      <Hr />
      <VSpacer factor={2} />
      <div style={{ cursor: 'pointer' }} data-testid="card-product-details_info">
        <DetailSnippetRow label="Status">
          <span className={s.capitalizeFirst}>{active ? 'Active' : 'Inactive'}</span>
        </DetailSnippetRow>

        <DetailSnippetRow label="Start date">
          <span className={s.capitalizeFirst}>{start_date || emptyVal}</span>
        </DetailSnippetRow>

        <DetailSnippetRow label="Network">
          <span className={s.capitalizeFirst}>{network || emptyVal}</span>
        </DetailSnippetRow>

        <DetailSnippetRow label="Classification">
          <span className={s.capitalizeFirst}>{classification || emptyVal}</span>
        </DetailSnippetRow>

        <DetailSnippetRow label="BIN prefix">
          {bin_prefix ? (
            <CopyToClipboard tooltipDirection="topRight" tooltipUncopiedText="Copy BIN">
              {bin_prefix}
            </CopyToClipboard>
          ) : (
            emptyVal
          )}
        </DetailSnippetRow>

        <DetailSnippetRow label="Token">
          {token ? (
            <CopyToClipboard tooltipDirection="topRight" tooltipUncopiedText="Copy token">
              {token}
            </CopyToClipboard>
          ) : (
            emptyVal
          )}
        </DetailSnippetRow>

        <DetailSnippetRow label="Type">
          <span className={s.capitalizeFirst}>{type || emptyVal}</span>
        </DetailSnippetRow>

        <DetailSnippetRow label="Fulfillment provider">
          {fulfillment_provider || emptyVal}
        </DetailSnippetRow>

        <DetailSnippetRow label="Funding">{fundingType || emptyVal}</DetailSnippetRow>
        <DetailSnippetRow label="Package ID">
          {package_id ? (
            <CopyToClipboard tooltipDirection="topRight" tooltipUncopiedText="Copy Package ID">
              {package_id}
            </CopyToClipboard>
          ) : (
            emptyVal
          )}
        </DetailSnippetRow>
        <DetailSnippetRow label="Program ID">
          {program_id ? (
            <CopyToClipboard tooltipDirection="topRight" tooltipUncopiedText="Copy Program ID">
              {program_id}
            </CopyToClipboard>
          ) : (
            emptyVal
          )}
        </DetailSnippetRow>
      </div>
    </>
  );
}

CardProductInfoCard.propTypes = {
  token: PropTypes.string,
  start_date: PropTypes.string,
  config: PropTypes.object,
  active: PropTypes.bool,
};

CardProductInfoCard.defaultProps = {
  token: '',
  start_date: '',
  config: {},
  active: true,
};

export default CardProductInfoCard;
