import rightAlignCell from '../../components/RightAlignCell/RightAlignCell.js';

const APPROVAL_QUEUE_FIELDS = {
  settlement_date: {
    accessor: 'settlement_date',
    header: 'Settlement date',
  },
  exception_count: {
    accessor: 'exception_count',
    header: 'Exception count',
    renderer: rightAlignCell,
  },
  settled_total: {
    accessor: 'settled_total',
    header: 'Settled total',
    minWidth: 120,
    renderer: rightAlignCell,
  },
  adjustment_fees: {
    accessor: 'adjustment_fees',
    header: 'Submitted fees',
    minWidth: 120,
    renderer: rightAlignCell,
  },
  adjustment_total: {
    accessor: 'adjustment_total',
    header: 'Submitted total',
    minWidth: 120,
    renderer: rightAlignCell,
  },
};

export default APPROVAL_QUEUE_FIELDS;
