import number from './index.js';
import getDefaultFormattingOptions from './get-default-formatting-options.js';

export default function addUpScaledDecimalsArray(scaledUnitsArray = [], currencyCode = '') {
  const totaledValue = scaledUnitsArray.reduce((total, { amount }) => {
    const formattedAmount = number(amount, true);
    return number(total).add(formattedAmount).format();
  }, 0);

  if (currencyCode) {
    return number(totaledValue, getDefaultFormattingOptions({ symbol: currencyCode })).format();
  }
  return number(totaledValue, getDefaultFormattingOptions()).format();
}
