import {
  verifier as defaultVerifier,
  requirements as defaultRequirements,
} from './permissions/default.js';

const defaultImporter = () => import(/* webpackChunkName: "Settlement" */ './index.js');

/**
 * Convert FDS Routes to MQD routes.
 *
 * @param {Object[]} [routes=[] ] Array of FDS Route objects to convert to MQD
 *          routes.
 * @return {Object} Result object with mapByName (for use in MQD's routeMap),
 *          mapById (routeMap by name), importers (for use in MQD's
 *          RouteFactoryMap), and requirements and verifiers (both used in MQD's
 *          route-authorization).
 */
function convertFdsRoutesToAmcRoutes(routes = []) {
  const mapByPath = {};
  const mapById = {};
  const mapByName = {};
  const verifiers = {};
  const requirements = {};

  routes.forEach((route) => {
    // Skip route if path|paths is not configured.
    if (!route.paths && !route.path?.length) {
      console.warn(`[!!] Route not configured: ${JSON.stringify(route, null, 2)}`);
      return;
    }

    // Create a route for each path.
    const paths = route.paths || [route.path];
    paths.forEach((path, i) => {
      const newRoute = { ...route };
      const name = getProp(newRoute, 'name', i);
      let id = newRoute.id || newRoute.ids ? getProp(newRoute, 'id', i) : camelCase(name);
      if (!id) id = camelCase(name);
      newRoute.path = path;
      newRoute.name = name;
      newRoute.id = id;
      if (!newRoute.importer) newRoute.importer = defaultImporter;
      if (!newRoute.verifier) newRoute.verifier = defaultVerifier;
      if (!newRoute.requirements) newRoute.requirements = defaultRequirements;
      mapByPath[path] = newRoute;
      mapById[id] = mapByPath[path];
      mapByName[name] = mapByPath[path];
      verifiers[id] = mapByPath[path].verifier;
      requirements[id] = mapByPath[path].requirements;
    });
  });

  return {
    mapByPath,
    mapByName,
    mapById,
    requirements,
    verifiers,
  };
}

/**
 * Utility to camelCase a string of words.
 *
 * @param {String} words Words to camelCase.
 * @return {String} camelCased words.
 */
function camelCase(words) {
  words = words.split(' ').reduce((result, word, i) => {
    if (i === 0) result += word.toLowerCase();
    else {
      result += word[0].toUpperCase();
      result += word.slice(1).toLowerCase();
    }
    return result;
  }, '');
  return words;
}

/**
 * Utility to find and return the correct value for a given property and index.
 * This is what determines where a value comes from, whether a plural or
 * singular version of a property.
 *
 * @param {Object} [route={}] FDS Route object.
 * @param {string} [prop=''] Property to use.
 * @param {number} index Index of the current iteration.
 * @return {any} Value for the given property and index.
 */
function getProp(route = {}, prop = '', index) {
  const pluralProp = route[prop + 's'];
  const value = pluralProp ? pluralProp[index] : route[prop];
  if (typeof value === 'function') {
    const path = route.paths && route.paths[index] ? route.paths[index] : route.path;
    return value({ route, path, index });
  }
  return value;
}

export { convertFdsRoutesToAmcRoutes };
