/* eslint-disable camelcase */
import React, { useState } from 'react';
import { toJS } from 'mobx';
import {
  Colors,
  DetailPane,
  Flex,
  Text,
  HSpacer,
  VSpacer,
  LoadingOverlay,
  CopyToClipboard,
  Link,
  ToastAlert,
} from '@mqd/volt-base';
import KycStatusIndicator from '../kyc-status-indicator/KycStatusIndicator';
import PropTypes from 'prop-types';
import ManualOverrideModal from './components/manual-override-modal/ManualOverrideModal.js';
import FullTokenHistory from './components/full-token-history/FullTokenHistory.js';
import { dig } from '../../shared-utils';
import { toastMessages } from './constants.js';

import s from './KycDetailsCard.module.css';

const DetailPaneContentWithEmptyChildren = ({ label, children }) => {
  const renderedContent = children ? (
    children
  ) : (
    <Text color={Colors.blackLighter5} type="body-sm">
      None
    </Text>
  );
  return <DetailPane.Content label={label}>{renderedContent}</DetailPane.Content>;
};

const KycDetailsCard = (props) => {
  const {
    canManualOverride,
    createKycReview,
    kycData,
    canCreateReview,
    latestKycResult,
    performManualOverride,
    reviewToken,
    type,
    goToReview,
  } = props;

  const {
    created_time,
    last_modified_time,
    notes,
    result: { status, codes } = {},
  } = latestKycResult || {};

  const commaSeparatedFaulureCodes = Array.isArray(toJS(codes))
    ? codes.map((failureCodes) => failureCodes.code).join(', ')
    : null;

  const kycToken = dig(latestKycResult, 'token');
  const [loading, setLoading] = useState(false);
  const [loadingReview, setLoadingReview] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [toastVisible, setToastVisible] = useState(false);
  const [fullTokenHistoryVisible, setFullTokenHistoryVisible] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('success');

  const programConfigData =
    (window.localStorage.getItem('programConfigData') &&
      JSON.parse(window.localStorage.getItem('programConfigData'))) ||
    {};
  const kycMicroserviceEnabled =
    programConfigData.kycMicroserviceEnabled &&
    programConfigData.kycMicroserviceEnabled.toLowerCase() === 'true';

  const handleSubmit = async (manualOverride, notes) => {
    try {
      setLoading(true);
      setModalVisible(false);

      await performManualOverride({ manualOverride, notes });
      setToastType('success');
      setToastMessage(toastMessages.manualOverrideSuccess);
    } catch (error) {
      setToastType('error');
      setToastMessage(toastMessages.manualOverrideError);
    } finally {
      setLoading(false);
      setToastVisible(true);
    }
  };

  const handleCreateReview = async () => {
    try {
      setLoadingReview(true);
      await createKycReview();
    } catch (error) {
      setToastType('error');
      setToastMessage(toastMessages.createReviewError);
      setToastVisible(true);
    } finally {
      setLoadingReview(false);
    }
  };

  const handleShowModal = () => {
    setModalVisible(true);
  };

  const handleHideModal = () => {
    setModalVisible(false);
  };

  const handleHideToast = () => {
    setToastVisible(false);
  };

  const handleShowFullTokenHistory = () => {
    setFullTokenHistoryVisible(true);
  };

  const handleHideFullTokenHistory = () => {
    setFullTokenHistoryVisible(false);
  };

  const renderToken = () => (
    <>
      <CopyToClipboard overrideValue={kycToken}>
        <span className={s.token}>{kycToken}</span>
      </CopyToClipboard>
      {kycData.length > 0 && (
        <>
          <Link inheritParentFont onClick={handleShowFullTokenHistory}>
            Full token history
          </Link>
          <VSpacer factor={0.5} />
        </>
      )}
    </>
  );

  const renderReviewToken = () => {
    const hideCreateReview = status && status.toLowerCase() === 'success';
    return reviewToken ? (
      <Link
        testId="kyc-review-token"
        onClick={() => {
          goToReview(reviewToken);
        }}
        className={s.token}
      >
        {reviewToken}
      </Link>
    ) : (
      <LoadingOverlay active={loadingReview} size={16}>
        <Flex flexDirection="row" alignItems="center" testId="create-kyc-review">
          <Text color={Colors.blackLighter5}>None </Text>
          <HSpacer />
          {!hideCreateReview && (
            <Link
              testId="create-kyc-review-link"
              onClick={() => {
                handleCreateReview();
              }}
            >
              Create review
            </Link>
          )}
        </Flex>
      </LoadingOverlay>
    );
  };

  return (
    <LoadingOverlay active={loading}>
      <div data-testid="kyc-details-card">
        <DetailPane.default layout="row">
          <DetailPane.Content label={`${type} status`}>
            <KycStatusIndicator kycResultStatus={status} />
            {canManualOverride && (
              <>
                <Link inheritParentFont onClick={handleShowModal}>
                  Manual override
                </Link>
                <VSpacer factor={0.5} />
              </>
            )}
          </DetailPane.Content>
          <DetailPaneContentWithEmptyChildren label={`${type} token`}>
            {kycToken && renderToken()}
          </DetailPaneContentWithEmptyChildren>
          <DetailPaneContentWithEmptyChildren label={`${type} review`}>
            {canCreateReview && kycMicroserviceEnabled && renderReviewToken()}
          </DetailPaneContentWithEmptyChildren>
          <DetailPaneContentWithEmptyChildren label={`${type} failure code`}>
            {commaSeparatedFaulureCodes}
          </DetailPaneContentWithEmptyChildren>
          <DetailPaneContentWithEmptyChildren label="Notes">
            {notes}
          </DetailPaneContentWithEmptyChildren>
          <DetailPaneContentWithEmptyChildren label="Created">
            {created_time}
          </DetailPaneContentWithEmptyChildren>
          <DetailPaneContentWithEmptyChildren label="Last modified">
            {last_modified_time}
          </DetailPaneContentWithEmptyChildren>
        </DetailPane.default>
      </div>
      {modalVisible && (
        <ManualOverrideModal handleSubmit={handleSubmit} hideModal={handleHideModal} />
      )}
      {toastVisible && (
        <ToastAlert icon={toastType} dismissTime={3} remove={handleHideToast}>
          {toastMessage}
        </ToastAlert>
      )}
      <FullTokenHistory
        type={type}
        data={kycData}
        isOpen={fullTokenHistoryVisible}
        onCloseClick={handleHideFullTokenHistory}
      />
    </LoadingOverlay>
  );
};

KycDetailsCard.propTypes = {
  type: PropTypes.oneOf(['KYC', 'KYB']),
  canManualOverride: PropTypes.bool,
  performManualOverride: PropTypes.func,
  /**
   * latest kyc result sorted by last_modified_time field
   */
  latestKycResult: PropTypes.shape({
    created_time: PropTypes.string,
    last_modified_time: PropTypes.string,
    notes: PropTypes.string,
    result: PropTypes.object,
    token: PropTypes.string,
  }),
  /**
   * List of kyc results
   */
  kycData: PropTypes.array,
  /**
   * Whether or not reviews are allowed to be created
   */
  canCreateReview: PropTypes.bool,
  /**
   * The token of the attached review
   */
  reviewToken: PropTypes.string,
  /**
   * A function that creates a review for the user
   */
  createKycReview: PropTypes.func,
  goToReview: PropTypes.func,
};

KycDetailsCard.defaultProps = {
  type: 'KYC',
  canManualOverride: false,
  latestKycResult: {},
  kycData: [],
  canCreateReview: false,
  reviewToken: '',
  createKycReview: () => {},
  goToReview: () => {},
};

export default KycDetailsCard;
