import {
  REFERENCE_ID_ERROR,
  REFERENCE_ID_LENGTH_ERROR,
  REFERENCE_ID_MAX_LENGTH,
} from './constants.js';

export const validateReferenceId = (referenceId) => {
  if (referenceId.length > REFERENCE_ID_MAX_LENGTH) {
    return { type: REFERENCE_ID_ERROR, payload: REFERENCE_ID_LENGTH_ERROR };
  }
  return { valid: true };
};
