import currentUserStore from '../../stores/CurrentUserStore.js';
import { UAM_GRANULAR_PERMISSIONS } from '@mqd/mqd-constants';

// Routes
const applicationTokensRoutes = [
  {
    path: '/application-tokens',
    name: 'Application Tokens',
    importer: () =>
      import(/*webpackChunkName: "Application Tokens" */ './pages/ApplicationTokens.js'),
  },
  {
    path: '/application-tokens/standard-token',
    name: 'Provision Standard Token',
    importer: () =>
      import(/*webpackChunkName: "Provision Standard Token" */ './pages/ProvisionStandardToken.js'),
  },
  {
    path: '/application-tokens/application',
    name: 'Application Token',
    importer: () =>
      import(/*webpackChunkName: "Application Token" */ './pages/ApplicationTokenFormRouter.js'),
  },
];

// Authorization
const defaultRequirements = {
  flipFlopRequirements: ['app-tokens'],
  requiredGranularPermissions: [UAM_GRANULAR_PERMISSIONS.PROGRAM_CONFIGURATION_VIEW_AND_EDIT],
};

const defaultVerifier = () => {
  return currentUserStore.userStore.applicationTokensActive;
};

const requirements = {
  applicationTokens: defaultRequirements,
  applicationToken: defaultRequirements,
  createAppicationToken: defaultRequirements,
};

const verifiers = {
  applicationTokens: defaultVerifier,
  applicationToken: defaultVerifier,
  createAppicationToken: defaultVerifier,
};

export { requirements, verifiers };
export default applicationTokensRoutes;
