import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import ReportCardLost from '../card-info-tab/card-actions-card/actions/ReportCardLost';
import CardsTableStore from './CardsTableStore';
import CardStore from '../stores/CardStore';
import { ParentTable, Status } from '@mq/voltron-parent';
import { Table, ActionsCell, ActionPlaceholder } from '@mq/voltron-table';
import { Button, LoadingOverlay, Text, ToastAlert, VSpacer, Address } from '@mqd/volt-base';
import ChangeStatusModal from '../change-status-modal/ChangeStatusModal';
import DisableCardModal from '../disable-card-modal/DisableCardModal';
import StyleContext from '../contexts/StyleContext';

class CardsTable extends ParentTable {
  constructor(props) {
    super(props);
    this.storeConstructor = CardsTableStore;
    this.actionsRenderer = this.actionsRenderer.bind(this);
    this.state = {
      toastAlert: null,
      showChangeStatusModal: false,
      showDisableCardModal: false,
      activeStore: null,
      tableKey: 'unset',
      ...this.colEditState(props, this.columns()),
    };
  }

  get canMakeTransition() {
    // Need card store to reference the array of roles we need
    const { createTransitionAllowedRoles } = new CardStore();
    const { hasRoleInArrayForActiveProgram } = this.props;
    return hasRoleInArrayForActiveProgram(createTransitionAllowedRoles);
  }

  renderToastAlert() {
    const { toastAlert } = this.state;
    if (!toastAlert || !toastAlert.message) return null;

    return (
      <ToastAlert
        testId="issue-card-alert"
        remove={() => this.setState({ toastAlert: null })}
        {...toastAlert}
      >
        {toastAlert.message}
      </ToastAlert>
    );
  }

  renderSuccessAlert() {
    this.setState({ toastAlert: { message: 'New card issued', icon: 'success' } });
  }

  renderErrorAlert() {
    this.setState({ toastAlert: { message: 'New card failed to issue', icon: 'error' } });
  }

  changeStatus(store) {
    this.setState({ showChangeStatusModal: true, activeStore: store });
  }

  disableCard(store) {
    this.setState({ showDisableCardModal: true, activeStore: store });
  }

  statusRenderer() {
    return ({ cellValue }) => {
      return (
        <div>
          <Status status={cellValue} />
        </div>
      );
    };
  }

  actionsRenderer({ row }) {
    const acceptedCardStates = ['ACTIVE', 'SUSPENDED', 'LIMITED'];
    const { reportCardLostStolenActive, canViewCardDetails = () => {} } = this.props;

    const noOptions = [
      {
        text: 'No options available',
        disabled: true,
      },
    ];

    const options = [
      ...(reportCardLostStolenActive && acceptedCardStates.indexOf(row.state) >= 0
        ? [
            {
              text: 'Report Card Lost/Stolen',
              onClick: () => this.disableCard(row),
            },
          ]
        : []),
      {
        text: 'Change Status',
        onClick: () => this.changeStatus(row),
      },
    ];

    const actions = canViewCardDetails(this.canMakeTransition) ? options : noOptions;

    return <ActionsCell actions={actions} />;
  }

  columns() {
    return [
      // main info
      {
        header: this.headerRenderer(),
        accessor: 'token',
        key: 'actions',
        renderer: this.actionsRenderer,
        noPadding: true,
        noResize: true,
        noRowClick: true,
        noSort: true,
        noEdit: true,
        primaryKey: true,
        deferRenderPlaceholder: () => <ActionPlaceholder width={32} />,
      },
      {
        header: 'Status',
        accessor: 'state',
        key: 'state',
        renderer: this.statusRenderer(),
        filterType: 'starts-with',
      },
      { header: 'Last four', accessor: 'last_four', key: 'last_four' },
      { header: 'Expiration', accessor: 'expiration', key: 'expiration' },
      { header: 'Instrument type', accessor: 'instrument_type', key: 'instrument_type' },
      { header: 'Fulfillment status', accessor: 'fulfillment_status', key: 'fulfillment_status' },

      { header: 'PIN is set', accessor: 'pin_is_set', key: 'pin_is_set' },
      { header: 'Created time', accessor: 'created_time', key: 'created_time' },
      { header: 'Last modified time', accessor: 'last_modified_time', key: 'last_modified_time' },

      // extended info
      { header: 'State Reason', accessor: 'state_reason', key: 'state_reason', hide: true },
      { header: 'Expedite', accessor: 'expedite', key: 'expedite', hide: true },
      { header: 'Token', accessor: 'token', key: 'token', hide: true },
      {
        header: 'User Token',
        accessor: 'cardholder_token',
        key: 'cardholder_token',
        hide: true,
      },
      {
        header: 'Card product token',
        accessor: 'card_product_token',
        key: 'card_product_token',
        hide: true,
      },
      {
        header: 'Expiration Time',
        accessor: 'expiration_time',
        key: 'expiration_time',
        hide: true,
      },
      {
        header: 'Reissue Pan From Card Token',
        accessor: 'reissue_pan_from_card_token',
        key: 'reissue_pan_from_card_token',
        hide: true,
      },
      {
        header: 'Bulk Issuance Token',
        accessor: 'bulk_issuance_token',
        key: 'bulk_issuance_token',
        hide: true,
      },
      {
        header: 'Translate Pin From Card Token',
        accessor: 'translate_pin_from_card_token',
        key: 'translate_pin_from_card_token',
        hide: true,
      },
      // not shown
      { header: 'PAN', accessor: 'pan', key: 'pan', hide: true },
      { header: 'Metadata', accessor: 'metadata', key: 'metadata', hide: true },
      { header: 'CVV', accessor: 'cvv_number', key: 'cvv_number', hide: true },
      { header: 'Chip CVV', accessor: 'chip_cvv_number', key: 'chip_cvv_number', hide: true },
      { header: 'Barcode', accessor: 'barcode', key: 'barcode', hide: true },
    ];
  }

  renderChangeStatusModal() {
    const { showChangeStatusModal, activeStore } = this.state;
    if (!showChangeStatusModal) return null;

    return (
      <ChangeStatusModal
        hideModal={() => this.setState({ showChangeStatusModal: false })}
        heading={`Change Status - ${activeStore.last_four}`}
        store={activeStore}
        segmentEventName="Card Status Changed"
      />
    );
  }

  renderDisableCardModal() {
    const { uamGranularPermissionsActive } = this.props;
    const { showDisableCardModal, activeStore } = this.state;
    if (!showDisableCardModal || !activeStore) return null;
    const { reportCardLostStolen, reportCardLostStolenUam, final_fulfillment } = activeStore;

    const {
      shipping: {
        recipient_address: {
          address1 = '',
          address2 = '',
          city = '',
          state = '',
          postal_code = '',
          country = '',
          zip = '',
        } = {},
      } = {},
    } = final_fulfillment || {};

    const recipientAddress = (
      <Address
        address1={address1}
        address2={address2}
        city={city}
        state={state}
        zip={zip}
        postal_code={postal_code}
        country={country}
      />
    );

    const delayedGoToCard = async (token) => {
      // give it some time to display success message before routing to new card
      this.renderSuccessAlert();
      setTimeout(() => {
        this.props.onRowClick({ token });
      }, 1500);
    };

    return (
      <ReportCardLost
        cardStore={activeStore}
        recipientAddress={recipientAddress}
        onClick={async (params) => {
          try {
            await (uamGranularPermissionsActive
              ? reportCardLostStolenUam(params, delayedGoToCard)
              : reportCardLostStolen(params, delayedGoToCard));
          } catch (e) {
            console.error(e);
            this.renderErrorAlert();
          }
        }}
        triggerModalNeeded={false}
        hideModal={() => this.setState({ showDisableCardModal: false })}
      />
    );
  }

  render() {
    const { onRowClick, canViewCardDetails = () => {} } = this.props;
    const canClickOnRow = canViewCardDetails(true) ? onRowClick : null;
    const { currentList, activeSort, activeRow, setAttr, loading, updateKey } = this.store;
    const { columns, tableKey } = this.state;
    const pageSize = this.store.count;
    const tablePaddingBottom = currentList.length >= pageSize ? 0 : 70;
    return (
      <div style={{ width: '100%' }} data-testid="card-table-wrapper">
        {this.renderToastAlert()}
        {this.renderChangeStatusModal()}
        {this.renderDisableCardModal()}
        {this.renderColEditTableHeader({ tableFilterTestId: 'cards-table-header-buttons' })}
        <VSpacer />
        <StyleContext.Consumer>
          {({ height }) => {
            return (
              <LoadingOverlay active={loading}>
                <Table
                  key={tableKey}
                  loading={loading}
                  height={height + tablePaddingBottom || this.dynamicHeight}
                  data={currentList}
                  columns={columns}
                  sort={activeSort}
                  fixedColumnCount={1}
                  rightAlignFixedColumns={true}
                  activeRow={activeRow}
                  onRowClick={canClickOnRow}
                  onSort={(newSort) => setAttr('activeSort', newSort)}
                  scrollToTopUpdateKey={updateKey}
                  handleColSizeChange={this.handleColSizeChange.bind(this)}
                />
              </LoadingOverlay>
            );
          }}
        </StyleContext.Consumer>
        {this.renderPagination()}
      </div>
    );
  }
}

CardsTable.propTypes = {
  uamGranularPermissionsActive: PropTypes.bool,
  height: PropTypes.number,
  storeInitArgs: PropTypes.object,
  store: PropTypes.object,
  userStore: PropTypes.object,
  autoHydrate: PropTypes.bool,
  onStoreConstruction: PropTypes.func,
  gqlClient: PropTypes.any,
  onViewClick: PropTypes.func,
  onRowClick: PropTypes.func,
  localStorageKey: PropTypes.string,
  reportCardLostStolenActive: PropTypes.bool,
  hasRoleInArrayForActiveProgram: PropTypes.func,
};

CardsTable.defaultProps = {
  uamGranularPermissionsActive: false,
  height: 800,
  storeInitArgs: { limit: 100 },
  store: null,
  userStore: {},
  autoHydrate: true,
  onStoreConstruction: null,
  gqlClient: null,
  onViewClick: () => {},
  onRowClick: () => {},
  hasRoleInArrayForActiveProgram: () => {},
  localStorageKey: 'CardsTableConfig',
  reportCardLostStolenActive: null,
};

export default observer(CardsTable);
