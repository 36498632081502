import { useContext, useMemo } from 'react';
import { ProgramReserveContext } from '../../../../../../../context/ProgramReserveContext.js';

export function useHistoryDataWithProgramFullNames(history) {
  const { programs } = useContext(ProgramReserveContext);

  const programNamesMap = useMemo(
    () => new Map(programs.map(({ short_name, program }) => [short_name, program])),
    [programs]
  );

  const historyDataWithProgramFullNames = history.toJS().map((transaction) => {
    const { program, amount, is_collateral } = transaction;

    return {
      ...transaction,
      amount: is_collateral ? null : amount,
      collateral_amount: is_collateral ? amount : null,
      programFullName: programNamesMap.get(program) || program,
    };
  });

  return { historyDataWithProgramFullNames };
}
