import { createContext } from 'react';

export const ContextTransactionList = createContext();

export const initialState = {};

export function reducer(state, action) {
  const { type } = action;
  switch (type) {
    default:
      return state;
  }
}
