import { includes, not } from 'ramda';
import { isJanusUser } from 'utils/route-authorization/routes/functions';

const _routeNamesWithoutProgramSelector = ['Development', 'TransactionTimeline'];

const hideProgramSelectorForRouteNamed = (routeName) =>
  includes(routeName, _routeNamesWithoutProgramSelector);

const hideProgramSelectorForUser = (userStore) => not(isJanusUser(userStore));

export { hideProgramSelectorForRouteNamed, hideProgramSelectorForUser };
