import { useMemo } from 'react';
import { diff } from 'deep-object-diff';
import { removeEmptyObjectsAndStringsFromObject } from '../index.js';

function countFormChanges(object) {
  if (object.new || object.updated) {
    return 1;
  }

  return Object.keys(object).reduce((acc, key) => {
    const value = object[key];
    const changes = typeof value === 'object' ? countFormChanges(value) : 1;

    return acc + changes;
  }, 0);
}

export function useFormChangesCount(initialState, currentFormState) {
  return useMemo(() => {
    const diffObject = diff(initialState, currentFormState);
    const diffObjectWithoutEmptyFields = removeEmptyObjectsAndStringsFromObject(diffObject);

    return countFormChanges(diffObjectWithoutEmptyFields);
  }, [initialState, currentFormState]);
}
