import { FlipFlop } from './../../../utils/index.js';
import currentUserStore from 'stores/CurrentUserStore';
import { hasDeveloperRole, hasNoRoles, isJanusUser } from './functions.js';

export const requirements = {
  flipFlopRequirements: ['transaction-trainer'],
  requiredRoles: ['developer'],
};

export const verifier = () => {
  const { userStore = {} } = currentUserStore;
  return (
    FlipFlop.get('transaction-trainer', false) &&
    !isJanusUser(userStore) &&
    (hasNoRoles(userStore) || hasDeveloperRole(userStore))
  );
};
