import React, { useEffect } from 'react';
import { EnvironmentSelector } from '@mq/volt-amc-container';
import routerStore from 'stores/router/RouterStore';
import {
  SET_ENVIRONMENT,
  ENVIRONMENT_LOCAL_STORAGE_KEY,
  PRODUCTION,
  VALID_ENVIRONMENTS,
} from './constants.js';
import {
  useEnvironmentSelectorDispatchContext,
  useEnvironmentSelectorStateContext,
} from './context.js';

const EnvironmentSelectorWrapper = ({ getBreadcrumbRootRoute }) => {
  const { environment } = useEnvironmentSelectorStateContext();
  const dispatch = useEnvironmentSelectorDispatchContext();

  const { currentEnvironmentInUrl } = routerStore || {};

  useEffect(() => {
    const localStorageEnvironment = localStorage.getItem(ENVIRONMENT_LOCAL_STORAGE_KEY);
    const isValidLocalStorageEnv = VALID_ENVIRONMENTS.includes(localStorageEnvironment);
    const isValidUrlEnv = VALID_ENVIRONMENTS.includes(currentEnvironmentInUrl);

    if (currentEnvironmentInUrl !== localStorageEnvironment && isValidUrlEnv) {
      localStorage.setItem(ENVIRONMENT_LOCAL_STORAGE_KEY, currentEnvironmentInUrl);
      dispatch({ type: SET_ENVIRONMENT, payload: currentEnvironmentInUrl });
    } else if (isValidLocalStorageEnv) {
      dispatch({ type: SET_ENVIRONMENT, payload: localStorageEnvironment });
    } else {
      localStorage.setItem(ENVIRONMENT_LOCAL_STORAGE_KEY, PRODUCTION);
      dispatch({ type: SET_ENVIRONMENT, payload: PRODUCTION });
    }
  }, [dispatch, currentEnvironmentInUrl]);

  const handleChange = (option) => {
    const { updateParams } = routerStore;
    const { val: selectedEnv } = option;
    localStorage.setItem(ENVIRONMENT_LOCAL_STORAGE_KEY, selectedEnv);

    if (updateParams) {
      updateParams && updateParams({ env: selectedEnv }, true);
    }

    const breadcrumbRootRoute = getBreadcrumbRootRoute();
    if (breadcrumbRootRoute) {
      window.location.href = breadcrumbRootRoute;
    } else if (
      window.location.href.includes('token=') ||
      window.location.href.includes('cardProductToken=')
    ) {
      window.location.href = '/';
    } else {
      window.location.reload();
    }
  };

  return <EnvironmentSelector environment={environment} onChange={handleChange} />;
};

export default EnvironmentSelectorWrapper;
