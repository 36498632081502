export const TRANSACTION_TYPE_RENAME_MAP = {
  gpa: 'GPA',
  pindebit: 'PIN debit',
  directdeposit: 'direct deposit',
  auth_plus_capture: 'auth plus capture',
  quasicash: 'quasi cash',
  issuerexpiration: 'issuer expiration',
  issueroperator: 'issuer-operator',
  balanceinquiry: 'balance inquiry',
  networkload: 'network load',
  programreserve: 'program reserve',
  'activation-request': 'activation request',
  pushtocard: 'push to card',
  standin: 'stand in',
  writeoff: 'write off',
  pgfs: 'PGFS',
};

export const LIST_HEIGHT_BOTTOM_MARGIN = 24;

export const TRANSACTIONS_LIST_COLUMNS = [
  { id: 'created_time', label: 'Date', csv_label: 'date', sort: true, width: 170 },
  { id: 'state', label: 'Status', csv_label: 'status', sort: true, width: 150 },
  {
    id: 'description',
    label: 'Description',
    width: 300,
    sort: true,
    tooltip: {
      fixOverflow: true,
      content:
        'For card transactions, this column refers to the Merchant. For ACH transactions, it refers to the Originator.',
    },
  },
  { id: 'location', label: 'Location', sort: true },
  {
    id: 'code',
    label: 'Code',
    sort: true,
    tooltip: {
      fixOverflow: true,
      content:
        'For card transactions, this column refers to the Merchant Category Code (MCC). For ACH transactions, it refers to the Standard Entry Class (SEC) code.',
    },
  },
  { id: 'type', label: 'Type', sort: true, width: 300 },
  { id: 'amount', label: 'Amount', renderer: 'amount', sort: true, width: 150 },
];

export const TRANSACTIONS_FILTER_OPTIONS = [
  {
    type: 'multiselect',
    label: 'Type',
    filterParam: 'type',
    placeholder: 'Select options',
    options: [
      'account.credit',
      'account.debit',
      'account.funding.auth_plus_capture.reversal',
      'account.funding.auth_plus_capture',
      'account.funding.authorization.clearing',
      'account.funding.authorization.reversal',
      'account.funding.authorization',
      'accountfunding.pull.chargeback.rev',
      'accountfunding.pull.chargeback',
      'accountfunding.pull',
      'ach.cancel',
      'ach.early.funds.reversed',
      'ach.early.funds',
      'ach.jit.cancel',
      'ach.jit.pull.pending',
      'ach.jit.pull.returned',
      'ach.jit.pull',
      'ach.jit.push.pending',
      'ach.jit.push.returned',
      'ach.jit.push',
      'ach.provisional.credit.reversed',
      'ach.provisional.credit',
      'ach.pull.pending',
      'ach.pull.returned',
      'ach.pull',
      'ach.push.pending',
      'ach.push.returned',
      'ach.push.reversal.completed',
      'ach.push.reversal.declined',
      'ach.push',
      'authorization.advice',
      'authorization.atm.withdrawal',
      'authorization.cashback',
      'authorization.clearing.atm.withdrawal',
      'authorization.clearing.cashback',
      'authorization.clearing.chargeback.completed',
      'authorization.clearing.chargeback.provisional.credit',
      'authorization.clearing.chargeback.provisional.debit',
      'authorization.clearing.chargeback.reversal',
      'authorization.clearing.chargeback.writeoff',
      'authorization.clearing.chargeback',
      'authorization.clearing.quasi.cash',
      'authorization.clearing.representment',
      'authorization.clearing',
      'authorization.incremental',
      'authorization.quasi.cash',
      'authorization.reversal.issuerexpiration',
      'authorization.reversal',
      'authorization.standin',
      'authorization',
      'balanceinquiry',
      'check.return.debit',
      'directdeposit.credit.pending.reversal',
      'directdeposit.credit.pending',
      'directdeposit.credit.reject',
      'directdeposit.credit.reversal',
      'directdeposit.credit',
      'directdeposit.debit.pending.reversal',
      'directdeposit.debit.pending',
      'directdeposit.debit.reject',
      'directdeposit.debit.reversal',
      'directdeposit.debit',
      'dispute.credit',
      'dispute.debit',
      'fee.charge.pending.refund',
      'fee.charge.pending',
      'fee.charge.reversal',
      'fee.charge',
      'funds.expire',
      'gpa.credit.authorization.billpayment.reversal',
      'gpa.credit.authorization.billpayment',
      'gpa.credit.authorization.reversal',
      'gpa.credit.authorization',
      'gpa.credit.billpayment',
      'gpa.credit.chargeback.reversal',
      'gpa.credit.chargeback',
      'gpa.credit.issueroperator',
      'gpa.credit.networkload.reversal',
      'gpa.credit.networkload',
      'gpa.credit.pending.reversal',
      'gpa.credit.pending',
      'gpa.credit.reversal',
      'gpa.credit',
      'gpa.debit.issueroperator',
      'gpa.debit.networkload',
      'gpa.debit.pending.reversal',
      'gpa.debit.pending',
      'gpa.debit.reversal',
      'gpa.debit',
      'gpa.grant',
      'msa.credit.chargeback.reversal',
      'msa.credit.chargeback',
      'msa.credit.pending.reversal',
      'msa.credit.pending',
      'msa.credit.reversal',
      'msa.credit',
      'msa.debit.pending.reversal',
      'msa.debit.pending',
      'msa.debit',
      'original.credit.auth_plus_capture.reversal',
      'original.credit.auth_plus_capture',
      'original.credit.authorization.clearing',
      'original.credit.authorization.reversal',
      'original.credit.authorization',
      'pgfs.balanceinquiry',
      'pindebit.atm.withdrawal',
      'pindebit.authorization.clearing',
      'pindebit.authorization.reversal.issuerexpiration',
      'pindebit.authorization.reversal',
      'pindebit.authorization',
      'pindebit.balanceinquiry',
      'pindebit.cashback',
      'pindebit.chargeback.completed',
      'pindebit.chargeback.provisional.credit',
      'pindebit.chargeback.provisional.debit',
      'pindebit.chargeback.reversal',
      'pindebit.chargeback.writeoff',
      'pindebit.chargeback',
      'pindebit.credit.adjustment',
      'pindebit.quasicash',
      'pindebit.refund.reversal',
      'pindebit.refund',
      'pindebit.reversal',
      'pindebit.transfer',
      'pindebit',
      'programreserve.credit',
      'programreserve.debit',
      'pullfromcard.pull.chargeback.rev',
      'pullfromcard.pull.chargeback',
      'pullfromcard.pull.reversal',
      'pullfromcard.pull',
      'pushtocard.debit',
      'pushtocard.reversal',
      'refund.authorization.advice',
      'refund.authorization.clearing',
      'refund.authorization.reversal',
      'refund.authorization',
      'refund',
      'reward.earn',
      'token.activation-request',
      'token.advice',
      'transaction.unknown',
      'transfer.fee',
      'transfer.peer',
      'transfer.program',
      'unknown',
    ],
    // selects need to render options in portal to secure proper styling
    // as sometimes options dropdown is cut by boundaries of parent Filters dropdown
    props: {
      fixOverflow: true,
      optionsWrap: { zIndex: 102 },
      dropDownWrap: { width: '430px', minWidth: '430px' },
      onBulkToggleClick: null,
    },
  },
  {
    type: 'multiselect',
    label: 'Status',
    filterParam: 'state',
    placeholder: 'Select options',
    options: ['PENDING', 'CLEARED', 'COMPLETION', 'DECLINED', 'ERROR', 'ALL'],
    props: {
      fixOverflow: true,
      optionsWrap: { zIndex: 102 },
      dropDownWrap: { width: '430px', minWidth: '430px' },
      onBulkToggleClick: null,
    },
  },
];

export const PAGE_SIZE = 25;

export const ICON_TYPE_MAPPING_BY_NETWORK = {
  VISA: 'visa',
  DISCOVER: 'discover',
  PULSE: 'pulse',
  MASTERCARD: 'master-card',
  MARQETA: 'marqeta-network',
};
