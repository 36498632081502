import { useProgramConfigStateContext } from './context.js';
import { useMemo } from 'react';

export default function useProgramConfig() {
  const {
    authDefault,
    isV2,
    internationalResponseFormatVersion,
    enablePinReveal,
    loaded,
    loading,
    maxActiveCardsPerUser,
    terminateActiveCardsOnNewCardActivation,
    transactionModelVersion,
    kycMicroserviceEnabled,
    controlPlaneDecisionReportsEnabled,
    controlPlaneDashboardEnabled,
    defaultCurrency,
    txnAcctMonitoringEnabled,
  } = useProgramConfigStateContext() || {};

  return useMemo(() => {
    return {
      authDefault,
      internationalResponseFormatVersion,
      isV2,
      enablePinReveal,
      loaded,
      loading,
      isWhiteList: authDefault === 'DENY',
      maxActiveCardsPerUser,
      transactionModelVersion,
      terminateActiveCardsOnNewCardActivation,
      kycMicroserviceEnabled,
      controlPlaneDecisionReportsEnabled,
      controlPlaneDashboardEnabled,
      defaultCurrency,
      txnAcctMonitoringEnabled,
    };
  }, [
    authDefault,
    internationalResponseFormatVersion,
    isV2,
    enablePinReveal,
    loaded,
    loading,
    maxActiveCardsPerUser,
    transactionModelVersion,
    terminateActiveCardsOnNewCardActivation,
    kycMicroserviceEnabled,
    controlPlaneDecisionReportsEnabled,
    controlPlaneDashboardEnabled,
    defaultCurrency,
    txnAcctMonitoringEnabled,
  ]);
}
