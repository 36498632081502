import React, { useEffect, useState, useContext, useRef, createRef } from 'react';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Container,
  Flex,
  HSpacer,
  Icon,
  Link,
  Row,
  Text,
  VSpacer,
} from '@mqd/volt-base';
import { WebhookContext } from '../../contexts';
import {
  getSelectedCount,
  isParentEvent,
  recursivelyCheckChildren,
  recursivelyUpdateEventsArray,
  shouldParentBeChecked,
} from './utils/webhookHelpers.js';
import events from './utils/events.json';
import s from './WebhookEvents.module.css';
import { find, propEq } from 'ramda';
import { CHECKBOX_STATES } from './constants';

const WebhookEvents = ({ onEventsChange, oldWebhookData }) => {
  const initialSelectAllState = {
    id: '*',
    title: 'Select all events',
    isSelected: false,
    children: events,
  };

  // Search input
  const searchInput = createRef();
  const [search, setSearch] = useState('');

  // Events
  const [webhookEvents, setWebhookEvents] = useState(initialSelectAllState);
  const [expandedSections, setExpandedSections] = useState([]);
  const [numActiveEvents, setNumActiveEvents] = useState(0);
  const countEventsRef = useRef(0);
  const { defaultWebhookValues, onSave } = useContext(WebhookContext);

  // Initialization
  useEffect(() => {
    const defaultWebhookEvents = [...defaultWebhookValues.events];
    const isSelectAll = defaultWebhookEvents.length === 1 && defaultWebhookEvents[0] === '*';
    let count = 0;
    let countSelected = 0;

    webhookEvents.children.forEach((parentEvent) => {
      // If the event is in the defaultWebhooks array
      // then we want to check the checkbox of parent & all child beneath it
      if (defaultWebhookEvents.indexOf(parentEvent.id + '.*') !== -1) {
        parentEvent.isSelected = CHECKBOX_STATES.TRUE;
        parentEvent.children.forEach((child) => {
          child.isSelected = CHECKBOX_STATES.TRUE;
          count += 1;
          countSelected += 1;
        });
      } else {
        parentEvent.children.forEach((child) => {
          if (isSelectAll || defaultWebhookEvents.indexOf(child.id.toLowerCase()) !== -1) {
            child.isSelected = CHECKBOX_STATES.TRUE;
            countSelected += 1;
          }
          count += 1;
        });
        parentEvent.isSelected = shouldParentBeChecked(parentEvent.children);
      }
    });
    webhookEvents.isSelected = shouldParentBeChecked(webhookEvents.children);
    countEventsRef.current = count;

    setNumActiveEvents(isSelectAll ? count : countSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleSectionExpansion = (sectionKey, sectionTitle) => {
    const updatedExpandedSections = expandedSections.slice();
    const sectionIdentifier = sectionKey || sectionTitle;
    const indexOfId = updatedExpandedSections.indexOf(sectionIdentifier);
    if (indexOfId !== -1) updatedExpandedSections.splice(indexOfId, 1);
    else updatedExpandedSections.push(sectionIdentifier);

    setExpandedSections(updatedExpandedSections);
  };

  // API Calls
  const updateWebhookEvents = () => {
    const eventsArray = [];
    eventsArray.concat(recursivelyUpdateEventsArray(webhookEvents, eventsArray));
    oldWebhookData.events = eventsArray;
    onSave(oldWebhookData);
  };

  // Helpers
  const setCheckboxState = (section, checkboxState) => {
    let events = { ...webhookEvents };
    const sectionId = section.id;
    if (sectionId === '*') {
      // If select all was selected
      recursivelyCheckChildren(events, checkboxState);
    } else if (isParentEvent(section)) {
      // If parent section was selected
      recursivelyCheckChildren(section, checkboxState);
      events.isSelected = shouldParentBeChecked(events.children);
    } else {
      // If child was selected
      let parentEventId = sectionId.split(`.${section.title}`)[0];
      const parentEvent = find(propEq('id', parentEventId), events.children);
      recursivelyCheckChildren(section, checkboxState);
      parentEvent.isSelected = shouldParentBeChecked(parentEvent.children);
      events.isSelected = shouldParentBeChecked(events.children);
    }
    setWebhookEvents(events);
    setNumActiveEvents(getSelectedCount(webhookEvents));
    onEventsChange(events);
  };

  // Components
  const WebhookSection = ({ section, idx }) => {
    const { children = [], key, title = '' } = section;
    const sectionIdentifier = key || title;
    const isExpanded = expandedSections.includes(sectionIdentifier) ? s.isExpanded : null;
    const containsString =
      search === '' || section.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
    return (
      <div
        className={`${s.section} ${!containsString ? s.hideSection : ''}`}
        key={key || idx}
        style={{ marginBottom: '8px' }}
      >
        <SectionTitle section={section} isExpanded={isExpanded} />
        {children.map((child, childIndex) => (
          <SectionChildren childIndex={childIndex} child={child} isExpanded={isExpanded} />
        ))}
      </div>
    );
  };

  const SectionTitle = ({ section, isExpanded }) => {
    return (
      <div
        className={`${s.sectionTitle} ${isExpanded}`}
        onClick={() => toggleSectionExpansion(section.key, section.title)}
      >
        <div className={`${s.caret} ${isExpanded}`}>
          <Icon type="caret-right" factor={0.8333} />
        </div>
        <HSpacer factor={0.5} />
        <div className={s.text}>
          <Flex flexDirection="row" alignItems="center">
            <CreateCheckbox section={section} />
          </Flex>
        </div>
      </div>
    );
  };

  const SectionChildren = ({ childIndex, child, isExpanded }) => {
    if (!isExpanded) return null;
    return (
      <div
        key={childIndex}
        className={`${s.sectionContent} ${isExpanded}`}
        data-testId={isExpanded ? 'expanded-content' : 'hidden-content'}
      >
        <CreateChildContentCheckbox section={child} />
      </div>
    );
  };

  const CreateChildContentCheckbox = ({ section }) => {
    const _onClick = (e) => {
      e.stopPropagation();
      setCheckboxState(section, !section.isSelected);
    };

    return (
      <Checkbox
        id={section.id}
        label={section.title}
        active={section.isSelected}
        onClick={_onClick}
      >
        <Text type="footnote">{section.content}</Text>
      </Checkbox>
    );
  };

  const CreateCheckbox = ({ section }) => {
    const _onClick = (e) => {
      e.stopPropagation();
      // We have 3 checkbox states. We want to toggle between on/off only when user clicks box
      // if indeterminate or true then toggle to off.
      var updateCheckboxStateTo =
        section.isSelected !== CHECKBOX_STATES.FALSE ? CHECKBOX_STATES.FALSE : CHECKBOX_STATES.TRUE;
      setCheckboxState(section, updateCheckboxStateTo);
    };

    return (
      <Checkbox
        id={section.id}
        label={section.title}
        active={section.isSelected}
        onClick={_onClick}
      >
        {section.content}
      </Checkbox>
    );
  };

  const RenderSearch = () => {
    return (
      <div className={s.searchContainer}>
        <div className={s.searchIcon}>
          <Icon type="search" factor={0} noHoverEffects />
        </div>
        <input
          className={s.searchInput}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search"
          ref={searchInput}
          data-testid="webhook-events-search-input"
          value={search}
          spellCheck={false}
          tabIndex={0}
        />
        {search && (
          <div className={s.cancelSearchIcon} onClick={(e) => setSearch('')}>
            <Icon type="clear" factor={0} noHoverEffects />
          </div>
        )}
      </div>
    );
  };

  return (
    <Container gutter={24} padding={0}>
      <Row>
        <Col span={8}>
          <div data-testId="webhook-event-listeners-tab">
            <Flex display="flex" justifyContent="space-between" type="div">
              <Text type="h4" role="heading">
                Event listeners
              </Text>
              <Flex display="inline-flex" type="div">
                <Button
                  type="primary"
                  disabled={numActiveEvents === 0}
                  onClick={updateWebhookEvents}
                >
                  Save selected events
                </Button>
              </Flex>
            </Flex>
            <div className={s.eventsContainer}>
              {RenderSearch()}
              <div className={s.checkboxContainer}>
                <div data-testId="webhook-sections" className={`${s.container} ${s.sections}`}>
                  <CreateCheckbox section={webhookEvents} />
                  <VSpacer factor={1} />
                  {webhookEvents.children.map((section, idx) => (
                    <WebhookSection section={section} idx={idx} key={idx} />
                  ))}
                </div>
              </div>
            </div>
            <Text type="h6">
              <span data-testId="webhook-num-events">{numActiveEvents}</span>/
              <span data-testId="webhook-num-total-events">{countEventsRef.current}</span>
              &nbsp; available events selected
            </Text>
          </div>
        </Col>
        <Col span={4}>
          <Alert>
            The Marqeta platform handles webhook notification messages by retrying failed
            notifications and batching multiple notifications of the same event type in a single
            message.
            <Link
              href="https://www.marqeta.com/docs/developer-guides/webhooks-overview#_runtime_characteristics"
              external={true}
              newTab={true}
              inline={true}
            >
              &nbsp;Read more
            </Link>
          </Alert>
        </Col>
      </Row>
    </Container>
  );
};

export default WebhookEvents;
