import React from 'react';
import { observer } from 'mobx-react';
import { formatMoney } from '@mqd/volt-currency';
import { Card, HSpacer, Text, VSpacer } from '@mqd/volt-base';
import { ParentDetailCard } from '@mq/voltron-parent';

class BalanceCard extends ParentDetailCard {
  constructor(props) {
    super(props);
  }

  get store() {
    const { store = {} } = this.props;
    return store;
  }

  formatBalance(amount, currency) {
    return (
      <>
        <Text type="body-lg" inline>
          {'$' + formatMoney(Number(amount))}
        </Text>
        <HSpacer factor={1} inline />
        <Text inline>{currency}</Text>
      </>
    );
  }

  render() {
    const store = this.store;

    if (!store.gpa_balance || !store.gpa_balance.gpa) return <></>;
    const gpa = store.gpa_balance.gpa;
    return (
      <Card title="Account balance">
        <VSpacer factor={2} />
        {this.renderSnippet({
          title: 'Available balance',
          content: this.formatBalance(gpa.available_balance, gpa.currency_code),
          tooltipContent: `The ledger balance minus any authorized transactions that have not yet cleared. Also known as the cardholder’s purchasing power. If you are using JIT Funding, this balance is usually 0.00.`,
        })}
        {this.renderSnippet({
          title: 'Credit balance',
          content: this.formatBalance(gpa.credit_balance, gpa.currency_code),
          tooltipContent: `Not currently used.`,
        })}
        {this.renderSnippet({
          title: 'Pending credits',
          content: this.formatBalance(gpa.pending_credits, gpa.currency_code),
          tooltipContent: `ACH loads that have been accepted, but for which the funding time has not yet elapsed.`,
        })}
        {this.renderSnippet({
          title: 'Ledger balance',
          content: this.formatBalance(gpa.ledger_balance, gpa.currency_code),
          tooltipContent: `When using standard funding, the funds that are available to spend immediately, including any authorized transactions that have not yet cleared. When using Just-in-Time (JIT) Funding, authorized funds that are currently on hold, but not yet cleared.`,
        })}
      </Card>
    );
  }
}

export default observer(BalanceCard);
