import { useMemo } from 'react';
import { TOOLTIP_DESCRIPTIONS } from '../../utilities/dictionary/index.js';
import { useHashExceptionDetailsState } from '../../utilities/use-hash-exception-details/context.js';
import number from '../../utilities/number/index.js';
import getDefaultFormattingOptions from '../../utilities/number/get-default-formatting-options.js';

const defaultAmount = () => '-';

export default function useDetailsMetricsRow({
  showSettledTotal,
  source,
  showSubmittedTotal,
  varianceType,
}) {
  const { data, settledTotal, submittedTotal } = useHashExceptionDetailsState();

  const { common_data: { currency_code } = {}, settled_details } = data;

  const variance = useMemo(() => {
    if (settled_details?.variance) {
      return number(settled_details.variance, getDefaultFormattingOptions()).format();
    }
    return defaultAmount();
  }, [settled_details]);

  const daysOutstanding = useMemo(() => {
    if (settled_details?.days) {
      return settled_details.days;
    }

    return '-';
  }, [settled_details]);

  const topCard = [
    source && {
      label: 'source',
      content: source,
    },
    showSettledTotal && {
      label: 'settled total',
      content: settledTotal,
      secondaryContent: currency_code,
    },
    showSubmittedTotal && {
      label: 'submitted total',
      content: submittedTotal,
      secondaryContent: currency_code,
    },
    {
      label: varianceType === 'submitted' ? 'submitted variance' : 'variance',
      content: variance,
      secondaryContent: currency_code,
      ...(varianceType === 'submitted' && {
        tooltip: TOOLTIP_DESCRIPTIONS.SUBMITTED_VARIANCE,
      }),
    },
    {
      label: 'days outstanding',
      content: daysOutstanding,
      secondaryContent: 'Days',
    },
  ].filter((content) => content);

  return topCard;
}
