import { NETWORKS, SUBNETWORKS } from '../../utilities/dictionary/index.js';
import { useHashExceptionDetailsState } from '../../utilities/use-hash-exception-details/context.js';

export default function useTransactionInformation() {
  const {
    data,
    error: { hasError },
    loading,
  } = useHashExceptionDetailsState();

  if (loading || hasError) {
    return {
      pan: {},
    };
  }

  const {
    common_data: { masked_pan, network, subnetwork },
    tx_hash_internal_id,
    transactions,
  } = data;

  const source = createSource({ network, subnetwork, transactions });

  return {
    source,
    pan: {
      value: masked_pan,
      tx_hash_internal_id,
    },
  };
}

function createSource({ transactions, network, subnetwork }) {
  const mq = transactions.find(({ source }) => source === 'MQ');
  const networkSource = transactions.find(({ source }) => source === 'NETWORK');

  if (mq && networkSource) {
    return 'Both';
  } else if (mq) {
    return 'Marqeta';
  }

  return `${NETWORKS[network]} - ${SUBNETWORKS[subnetwork]}`;
}
