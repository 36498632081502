/**
 * Does exactly what ruby dig() does.
 * Attempts to find a nested value, if it's not found returns undefined
 */
export default function dig(result, ...keys) {
  let digged = result;
  for (const key of keys) {
    if (typeof digged === 'undefined' || digged === null) {
      return undefined;
    }
    if (typeof key === 'function') {
      digged = key(digged);
    } else {
      digged = digged[key];
    }
  }
  return digged;
}
