import logger from 'utils/logger';
import {
  PROGRAMS_META_DATA,
  TOGGLE_FAVORITE_PROGRAM,
  SELECT_ACTIVE_PROGRAM,
  PROGRAMS,
  FILTER,
  SELECT_ACTIVE_PROGRAM_DNA,
  SELECT_ACTIVE_PROGRAM_CONFIGS,
} from './constants.js';

export const initialState = {
  programsMetadata: undefined,
  filter: '',
  activeProgramInfo: {},
  activeProgramBootstrapCompleted: false,
};

const activeProgramBootstrapCompleted = (state) => {
  const { activeProgram, activeProgramInfo } = state;

  try {
    const shortCode = activeProgram.short_name;
    const { dna, programConfigs } = activeProgramInfo;
    return { activeProgramBootstrapCompleted: dna.short_code === shortCode && !!programConfigs };
  } catch {
    return { activeProgramBootstrapCompleted: false };
  }
};

const activeProgramInfoStateUpdate = (state, key, value) => {
  const newState = {
    ...state,
    activeProgramInfo: {
      ...state.activeProgramInfo,
      [key]: value,
    },
  };

  return {
    ...newState,
    ...activeProgramBootstrapCompleted(newState),
  };
};

export function reducer(state, action) {
  const { type, payload } = action;
  if (process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development') {
    logger.groupCollapsed('ProgramSelector reducer');
    logger.info(`ProgramSelector reducer: ${type}: ${JSON.stringify(payload, null, 4)}`);
    logger.groupEnd();
  }
  switch (type) {
    case PROGRAMS_META_DATA:
      return {
        ...state,
        programsMetadata: payload,
      };
    case PROGRAMS:
      return {
        ...state,
        programs: payload,
      };

    case TOGGLE_FAVORITE_PROGRAM:
      return {
        ...state,
        favoritePrograms: payload,
      };

    case SELECT_ACTIVE_PROGRAM:
      return {
        ...state,
        activeProgram: payload,
      };

    case FILTER:
      return {
        ...state,
        filter: payload,
      };

    case SELECT_ACTIVE_PROGRAM_DNA:
      return activeProgramInfoStateUpdate(state, 'dna', payload);

    case SELECT_ACTIVE_PROGRAM_CONFIGS:
      return activeProgramInfoStateUpdate(state, 'programConfigs', payload);

    default:
      throw new Error(`Action of type: ${type} is not supported by ProgramSelector reducer`);
  }
}
