export const inProgress = 'in-progress';
export const changedReview = 'changed-review';
export const approvalReview = 'approval-review';
export const approvedReview = 'approved-review';

export const statusOptions = [inProgress, changedReview, approvalReview, approvedReview];

export const changeStatusTypeMap = {
  [changedReview]: 'changed',
  [approvalReview]: 'changed',
  [approvedReview]: 'completed',
};

export const disabledStatuses = new Set([approvalReview, approvedReview]);
export const hideWhenNoChangeStatuses = new Set([approvalReview, approvedReview]);
export const formerValueStatuses = new Set([changedReview, approvalReview, approvedReview]);
