import React, { Component } from 'react';
import { Modal, Button, VSpacer, ModalAlert, LoadingOverlay } from '@mqd/volt-base';
import { SegmentTrackHOC } from '@mq/voltron-parent';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { convertNumberToCurrency, formatDateAsUTCString, toBase64 } from '../utils';
import DisputeTransactionForm from './DisputeTransactionForm';
import TransactionDetails from './TransactionDetails';

class DisputeTransactionModal extends Component {
  constructor(props) {
    super(props);
    this.loadCardAndCardholerData();
    this.handleDispute = this.handleDispute.bind(this);
  }

  state = {
    disputeAmount: 0,
    reason: '',
    cardCompromised: '',
    details: '',
    files: [],
    filesProcessing: false,
    formErrors: {},
    successMessage: '',
    errorMessage: '',
  };

  loadCardAndCardholerData() {
    const { store } = this.props;
    if (!store.card) {
      try {
        store.fetchCardInformation();
      } catch (e) {
        console.error(e);
      }
    }
    if (!store.cardholder) {
      try {
        store.fetchCardholderInformation();
      } catch (e) {
        console.error(e);
      }
    }
  }

  async handleFileUpload(file) {
    this.setState({ filesProcessing: true });

    const { name, type } = file;
    const base64File = await toBase64(file);
    const uploadFile = {
      filename: name,
      mimetype: type,
      file: base64File,
    };

    this.setState({ files: uploadFile, filesProcessing: false });
  }

  async handleDispute() {
    const { store, activeUserInfo } = this.props;
    const { files } = this.state;
    const { activeProgram, ...submitterInfo } = activeUserInfo;

    if (!this.isFormValid()) return;
    const params = {
      submitter: submitterInfo,
      subject: this.formatDisputeSubject(),
      comment: {
        body: this.formatDisputeBody(),
      },
      priority: 'urgent',
      attachments: files,
    };

    try {
      const result = await store.disputeTransaction(params);
      this.setState({
        successMessage: result,
        errorMessage: '',
      });
    } catch (e) {
      this.setState({
        errorMessage: e,
        successMessage: '',
      });
    }
  }

  isFormValid() {
    const { disputeAmount, reason, cardCompromised, formErrors } = this.state;

    if (!disputeAmount || !reason || !cardCompromised) {
      const requiredErrors = {};
      if (!disputeAmount) requiredErrors.disputeAmount = true;
      if (!reason) requiredErrors.reason = true;
      if (!cardCompromised) requiredErrors.cardCompromised = true;
      this.setState({ formErrors: { ...formErrors, ...requiredErrors } });
      return false;
    } else if (Object.keys(formErrors).filter((key) => formErrors[key]).length > 0) {
      return false;
    }
    return true;
  }

  formatDisputeSubject() {
    const { store } = this.props;
    const { request_amount, currency_code, token } = store;
    const { reason } = this.state;
    return `Service Dispute ${reason} - ${token} (${convertNumberToCurrency(
      request_amount,
      currency_code
    )})`;
  }

  formatDisputeBody() {
    const { disputeAmount, reason, cardCompromised, details } = this.state;
    const {
      activeUserInfo,
      activeProgram: { program, short_name },
      store,
    } = this.props;
    const {
      request_amount,
      currency_code,
      card,
      cardAcceptor,
      cardholder,
      cardholder_token,
      cardholder_transaction_time,
      token,
    } = store;
    const disputeDate = formatDateAsUTCString(new Date());
    return `${program} would like to file a Service Dispute for ${reason}. 
      \n Program: ${program}
      Submitter Name: ${activeUserInfo.firstName} ${activeUserInfo.lastName}
      Submitter Email: ${activeUserInfo.email}
      Submission Date: ${disputeDate}
      Customer Notification Date: ${disputeDate}
      \n Name on Card: ${cardholder ? cardholder.fullName : ''}
      Card Number: **${card.last_four}
      Card Expiration: ${card.formattedExpiration}
      Card Status: ${cardCompromised}
      Card State: ${card.state}
      \n Merchant: ${cardAcceptor}
      Transaction Amount: ${convertNumberToCurrency(request_amount, currency_code)}
      Transaction Date: ${formatDateAsUTCString(cardholder_transaction_time)}
      Processing Type: ${this.formatProcessingType()}
      Transaction ID: ${token}
      ${
        window.env ? window.env.MONTEREY_PROGRAMS_URL : ''
      }/${short_name}/users/${cardholder_token}/transactions/${token}
      \n Dispute Amount: ${convertNumberToCurrency(disputeAmount, currency_code)}
      Dispute Reason: ${reason}
      Dispute Details: 
      ${details}
    `;
  }

  formatProcessingType() {
    const {
      store: { card_acceptor },
    } = this.props;

    if (!card_acceptor) return '';

    const { poi } = card_acceptor;
    if (!poi) return '';

    switch (poi.processing_type) {
      case 'MAGSTRIPE':
        return 'Stripe';
      case 'CHIP':
        return 'Chip';
      case 'CHIP FALLBACK':
        return 'Chip Fallback';
      case 'UNKNOWN':
        return 'Other';
      default:
        return '';
    }
  }

  renderFailure() {
    const { errorMessage } = this.state;
    if (!errorMessage) return;
    return (
      <ModalAlert
        type="danger"
        hideModal={() => {
          return this.setState({ errorMessage: '' });
        }}
      >
        {errorMessage}
      </ModalAlert>
    );
  }

  renderSuccess() {
    const { successMessage } = this.state;
    const { hideModal } = this.props;
    if (!successMessage) return;
    return (
      <ModalAlert
        type="success"
        hideModal={() => {
          this.setState({ successMessage: '' });
          return hideModal();
        }}
      >
        {successMessage}
      </ModalAlert>
    );
  }

  toggleError(field, isError) {
    const { formErrors } = this.state;
    this.setState({
      formErrors: { ...formErrors, [field]: isError },
    });
  }

  render() {
    const { disputeAmount, reason, cardCompromised, details, files, filesProcessing, formErrors } =
      this.state;
    const { hideModal, store, segmentEventName, segmentProperty } = this.props;
    const {
      loading,
      loadingCard,
      loadingCardholder,
      saving,
      request_amount,
      currency_code,
      card,
      cardAcceptor,
      cardholder_transaction_time,
      token,
      cardholder,
    } = store;
    return (
      <Modal
        heading="Dispute a transaction"
        hideModal={hideModal}
        width="520px"
        footerButtons={[
          <SegmentTrackHOC eventName={segmentEventName} eventProperties={segmentProperty}>
            <Button
              disabled={loading || saving || filesProcessing}
              onClick={() => this.handleDispute()}
            >
              File Dispute
            </Button>
          </SegmentTrackHOC>,
        ]}
      >
        <LoadingOverlay active={loading || loadingCard || loadingCardholder || saving}>
          {this.renderSuccess()}
          {this.renderFailure()}
          <TransactionDetails
            request_amount={request_amount}
            card={card}
            cardAcceptor={cardAcceptor}
            cardholderName={cardholder ? cardholder.fullName : null}
            currencyCode={currency_code}
            token={token}
            transactionTime={cardholder_transaction_time}
          ></TransactionDetails>
          <VSpacer factor={3} />
          <DisputeTransactionForm
            transactionAmount={request_amount}
            currencyCode={currency_code}
            disputeAmount={disputeAmount}
            onDisputeAmountChange={(val) => this.setState({ disputeAmount: val })}
            reason={reason}
            onReasonChange={(val) => this.setState({ reason: val })}
            cardCompromised={cardCompromised}
            onCardCompromisedChange={(val) => this.setState({ cardCompromised: val })}
            details={details}
            onDetailsChange={(val) => this.setState({ details: val })}
            files={files}
            onFilesChange={(files) => this.handleFileUpload(files)}
            formErrors={formErrors}
            onErrorChanges={(field, isError) => this.toggleError(field, isError)}
          ></DisputeTransactionForm>
        </LoadingOverlay>
      </Modal>
    );
  }
}

DisputeTransactionModal.propTypes = {
  hideModal: PropTypes.func,
  store: PropTypes.object,
  activeUserInfo: PropTypes.object,
  activeProgram: PropTypes.object,
};

DisputeTransactionModal.defaultProps = {
  hideModal: () => {},
  store: {},
  activeUserInfo: {},
  activeProgram: {},
};

export default observer(DisputeTransactionModal);
