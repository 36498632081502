import { UAM_GRANULAR_PERMISSIONS } from '@mqd/mqd-constants';
import { FlipFlop } from '../../index.js';
import currentUserStore from 'stores/CurrentUserStore';
import { isProgramConfigFlagEnabled } from './functions.js';

const UAM_ROLES = [UAM_GRANULAR_PERMISSIONS.MANAGE_TRANSACTION_ALERTS_VIEW_AND_EDIT];

export const requirements = {
  flipFlopRequirements: ['transaction-review-alerts-manager', 'new-uam-permission-rollout'],
  programConfigRequirements: ['TXN_ACCT_MONITORING_ENABLED'],
  requiredGranularPermissions: UAM_ROLES,
};

export const verifier = () => {
  const { userStore = {} } = currentUserStore;
  const { hasRequiredUamPermissions, newUamPermissionRolloutActive, uamGranularPermissionsActive } =
    userStore;

  const hasUamPermissions = hasRequiredUamPermissions(UAM_ROLES);
  const hasFeatureFlag = FlipFlop.get('transaction-review-alerts-manager', false);
  const isRiskAlertsEnabled = isProgramConfigFlagEnabled('TXN_ACCT_MONITORING_ENABLED');
  return uamGranularPermissionsActive && newUamPermissionRolloutActive
    ? hasUamPermissions && hasFeatureFlag && isRiskAlertsEnabled
    : hasFeatureFlag && isRiskAlertsEnabled;
};
