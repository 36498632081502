// React lazy returns a promise, so if it fails to load an asset, lets
import logger from 'utils/logger';
// retry that loading every <default=500ms> <default=3> times.
export default function retry(fn, retriesLeft = 3, interval = 500) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        logger.error(error);
        if (retriesLeft <= 1) {
          reject(error);
          return;
        }
        setTimeout(() => {
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}
