import React from 'react';
import { Breadcrumbs } from '@mqd/volt-base';

import routerStore from '../../../../stores/router/RouterStore.js';
import { EXCEPTION_TYPES, SUBNETWORKS } from '../../utilities/dictionary/index.js';
import { capitalize } from '../../utilities/string/index.js';
import { appendUrlParamsToUrl } from '../../utilities/url-params/index.js';
import { dashCaseToConstant } from '../../utilities/string/index.js';

const baseUrl = '/settlement';

const exceptionSummaryFilterParams = [
  'start_settlement_date',
  'end_settlement_date',
  'program_short_codes',
  'program_types',
  'settlement_currencies',
  'dna_bank_tokens',
];

const exceptionQueueFilterParams = [...exceptionSummaryFilterParams, 'originator'];

const ExceptionFlowBreadcrumbs = ({ hashId }) => {
  const urlParams = new URLSearchParams(window.location.search);

  const typeParam = window.history.state?.type || dashCaseToConstant(routerStore.pathParams.type);
  const type = typeParam && EXCEPTION_TYPES[typeParam];

  const network = urlParams.get('network');
  const subnetworkParam = urlParams.get('subnetwork');
  const subnetwork = subnetworkParam && (SUBNETWORKS[subnetworkParam] || 'Unknown');

  const txHashInternalId = urlParams.get('tx_hash_internal_id');
  const originator = urlParams.get('originator');

  const filterParams = {
    type,
    subnetwork,
    network,
    txHashInternalId,
    originator,
  };

  const crumbs = [
    {
      children: 'Exceptions',
      href: appendUrlParamsToUrl(
        `${baseUrl}/exceptions?network=${network}`,
        exceptionSummaryFilterParams
      ),
      filter: ['network'],
    },
    {
      children: subnetwork + ' exceptions: ' + (type && capitalize(type)),
      href: appendUrlParamsToUrl(
        `${baseUrl}/exceptions/${routerStore.pathParams.type}?network=${network}&subnetwork=${subnetworkParam}`,
        exceptionQueueFilterParams
      ),
      filter: ['network', 'subnetwork', 'type'],
    },
    {
      children: hashId ? `Hash ID ••${hashId.substr(hashId.length - 4, hashId.length - 1)}` : '',
      href: '',
      filter: ['network', 'subnetwork', 'type', 'txHashInternalId'],
    },
  ]
    .filter(({ filter }) => filter.every((filterParam) => filterParams[filterParam]))
    .map(({ children, href }) => ({ children, href }));

  return (
    <div>
      <Breadcrumbs crumbs={crumbs} />
    </div>
  );
};

export default ExceptionFlowBreadcrumbs;
