import exceptionQueue from './exception-queue.js';
import approvalQueue from './approval-queue.js';
import settlementDateQueue from './settlement-date-queue.js';
import hashGroup from './hash-group.js';
import transactionInfo from './transaction-info.js';
import relatedTransactions from './related-transactions.js';

const fieldsetMap = {
  exception_queue: exceptionQueue,
  approval_queue: approvalQueue,
  settlement_date_queue: settlementDateQueue,
  hash_group: hashGroup,
  transaction_info: transactionInfo,
  related_transactions: relatedTransactions,
};

export default fieldsetMap;
