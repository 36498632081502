import React, { useCallback, useState } from 'react';
import moment from 'moment';
import { DatePicker } from '@mq/voltron-date-picker';
import { Button } from '@mqd/volt-base';

import { DATE_FORMAT_STRING } from 'views/fds/utilities/date.js';
import { DEFAULT_FILTER_LABEL } from '../SummaryPage/constants.js';

export default function DatePickerWrapper({
  refetch,
  dateFromParam,
  dateToParam,
  initialDateFrom,
  initialDateTo,
  singleDate = false,
  disableBeforeDays = 180,
}) {
  const [dateFrom, setDateFrom] = useState(initialDateFrom);
  const [dateTo, setDateTo] = useState(initialDateTo);

  const onChange = useCallback(
    (dateFrom, dateTo) => {
      setDateFrom(dateFrom);
      if (!singleDate) {
        setDateTo(dateTo);
      }
    },
    [singleDate]
  );
  const onClear = () => {
    setDateFrom(null);
    setDateTo(null);
    refetch({ [dateFromParam]: null, [dateToParam]: null });
  };

  const onSave = () => {
    refetch({
      ...{
        [dateFromParam]: dateFrom && dateTo ? moment(dateFrom).format(DATE_FORMAT_STRING) : null,
      },
      ...{ [dateToParam]: dateFrom && dateTo ? moment(dateTo).format(DATE_FORMAT_STRING) : null },
    });
  };

  return (
    <DatePicker
      height="32px"
      onChange={onChange}
      disableAfterDay={new Date()}
      disableBeforeDay={moment().subtract(disableBeforeDays, 'd').toDate()}
      from={dateFrom || moment().subtract(90, 'd').toDate()}
      to={dateTo}
      label={dateFrom && dateTo ? '' : DEFAULT_FILTER_LABEL}
      disableDateField
      noHoverEffects={true}
      includeTime={false}
      monthMode={false}
      weekMode={false}
      singleDate={singleDate}
      closeAfterButtonClick
      footerButtons={[
        <Button key="clear" type="outline" onClick={onClear}>
          Clear
        </Button>,
        <Button key="filter" size="md" onClick={onSave}>
          Save
        </Button>,
      ]}
    />
  );
}
