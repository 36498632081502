// @flow
import { decorate, observable, action, computed } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';
import AddressStore from './AddressStore';

class FulfillmentShippingStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.loadAndConstruct(args);
  }
  // values
  care_of_line: string = '';
  method: string = '';
  // objects
  recipient_address: AddressStore = {};
  return_address: AddressStore = {};

  // actions
  loadAndConstruct(args) {
    this.load(args);
    const { recipient_address, return_address } = args;
    this.loadAndConstructItem('recipient_address', recipient_address, AddressStore);
    this.loadAndConstructItem('return_address', return_address, AddressStore);
  }

  // computed
  watchedObjects = ['recipient_address', 'return_address'];
  get updateParams(): Object {
    const params = { ...this.watchedUpdateParams };
    // The API has some inconsistent behavior with updates
    // here if there is any object sent it will act as a
    // replace not update for method and care_of_line
    if (
      Object.keys(params).length ||
      typeof this.valuesUpdated.method !== 'undefined' ||
      typeof this.valuesUpdated.care_of_line !== 'undefined'
    ) {
      params.method = this.method;
      params.care_of_line = this.care_of_line;
    }
    this.valuesUpdatedArray.forEach((value) => {
      params[value] = this[value];
    });
    if (Object.keys(params).length === 0) return null;
    return params;
  }
}

decorate(FulfillmentShippingStore, {
  // values
  care_of_line: observable,
  method: observable,
  // objects
  recipient_address: observable,
  return_address: observable,

  // actions
  loadAndConstruct: action.bound,

  // computed
  updateParams: computed,
});

export default FulfillmentShippingStore;
