import { decorate, observable, action } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';
import S3MetadataResponseStore from './S3MetadataResponseStore';

class S3MetadataStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.loadAndConstruct(args);
  }

  // values
  program: string = '';
  uploadedBy: string = '';
  description: string = '';
  response: string = '';

  loadAndConstruct(args = {}) {
    const { response } = args;
    this.load(args);
    this.loadAndConstructItem('response', response, S3MetadataResponseStore);
  }

  // computed
}

decorate(S3MetadataStore, {
  skipAutoLoad: observable,
  // values
  program: observable,
  uploadedBy: observable,
  description: observable,
  response: observable,

  // actions
  loadAndConstruct: action.bound,

  // computed
});

export default S3MetadataStore;
