import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { ErrorBannerAlert, ParentDetail, containerWidthHOC } from '@mq/voltron-parent';
import CardProductStore from '../stores/CardProductStore';
import CardProductDetailControlsCard from './CardProductDetailControlsCard';
import CardProductInfoCard from './CardProductInfoCard';
import {
  Text,
  VSpacer,
  LoadingOverlay,
  Container,
  Row,
  Col,
  Button,
  ActionDropdown,
  Icon,
} from '@mqd/volt-base';
import { StoreContext } from '@mqd/volt-contexts';

class CardProductDetail extends ParentDetail {
  constructor(props) {
    super(props);
    this.storeConstructor = CardProductStore;
  }

  get trendownIcon() {
    return <Icon type="caret-down" mod="reversed" factor={1} noHoverEffects={false} />;
  }

  renderErrors() {
    return <ErrorBannerAlert store={this.store} />;
  }

  render() {
    const {
      token,
      loading,
      cardProductType,
      hasControls,
      authControlStartTimes,
      velocityControlUsageLimitsWithTypes,
      velocityControlAmountLimits,
      velocityControlUsageLimits,
      authFormattedMccs,
      hasSingleUseVelocityControl,
      formattedName,
      infoCardProps,
      error,
    } = this.store;
    const { onCardCreationClick, containerWidth, userStore } = this.props;
    const { cardCreationActive } = userStore;

    const dropdownContent = [
      ...[
        {
          element: 'Bulk create cards',
          onClick: () => onCardCreationClick({ token }),
        },
        {
          element: 'Manual create card',
          onClick: () => onCardCreationClick({ token, type: 'manual' }),
        },
      ],
    ];

    let colSpan = 6;
    if (containerWidth <= 800) {
      colSpan = 12;
    }

    return (
      <StoreContext.Consumer>
        {({ ProgramConfigStore = {} } = {}) => {
          const { authDefault, isWhiteList } = ProgramConfigStore;
          const showControlsCard = hasControls || authDefault;

          return (
            <LoadingOverlay active={loading}>
              <div data-testid="card-product-details_container">
                {this.renderErrors()}
                <Container gutter={0}>
                  <Row>
                    <Col>
                      <Text type="label">{loading ? null : cardProductType}</Text>
                      <VSpacer />
                      <Text type="h3" testId="card-product-title">
                        {formattedName}
                      </Text>
                    </Col>
                    {cardCreationActive && (
                      <ActionDropdown
                        control={
                          <Button
                            testId="create-card-button"
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            Create card &nbsp; {this.trendownIcon}
                          </Button>
                        }
                        tooltipWidth={143}
                        content={dropdownContent}
                      />
                    )}
                  </Row>
                </Container>
                <VSpacer factor={3} />
                <Container>
                  <Row>
                    <Col span={colSpan}>
                      <CardProductInfoCard {...infoCardProps} />
                    </Col>
                    {showControlsCard && (
                      <Col span={colSpan}>
                        <CardProductDetailControlsCard
                          velocityControlUsageLimitsWithTypes={velocityControlUsageLimitsWithTypes}
                          authControlStartTimes={authControlStartTimes}
                          velocityControlAmountLimits={velocityControlAmountLimits}
                          velocityControlUsageLimits={velocityControlUsageLimits}
                          authFormattedMccs={authFormattedMccs}
                          isWhiteList={isWhiteList}
                          authDefault={authDefault}
                          hasSingleUseVelocityControl={hasSingleUseVelocityControl}
                          error={error}
                        />
                      </Col>
                    )}
                  </Row>
                </Container>
              </div>
            </LoadingOverlay>
          );
        }}
      </StoreContext.Consumer>
    );
  }
}

CardProductDetail.propTypes = {
  storeInitArgs: PropTypes.object,
  store: PropTypes.object,
  userStore: PropTypes.object,
  authDefault: PropTypes.string,
  autoHydrate: PropTypes.bool,
  onStoreConstruction: PropTypes.func,
  token: PropTypes.string,
};

CardProductDetail.defaultProps = {
  storeInitArgs: {},
  store: null,
  userStore: {},
  authDefault: '',
  autoHydrate: true,
  onStoreConstruction: null,
  token: '',
};

export default containerWidthHOC(observer(CardProductDetail));
