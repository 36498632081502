import React from 'react';
import { Text, HSpacer, VSpacer, Input } from '@mqd/volt-base';
import { transformEntityString } from '../../helpers';
import s from './KYBCard.module.css';
import PropTypes from 'prop-types';

class BusinessRow extends React.PureComponent {
  render() {
    const { editActive, field, onChange, name, userStore } = this.props;
    const displayName = transformEntityString(name);
    const disabled = (name === 'ssn' || name === 'ein') && !userStore.canRevealIdentification;

    return (
      <div data-testid="KYB-business-row">
        <Text type="h6">{displayName}</Text>
        <VSpacer />
        {editActive ? (
          <div className={s.editActive} data-testid="KYB-business-row-edit-active">
            <Input
              testId={`KYB-business-row-input-${name}`}
              value={field}
              disabled={disabled}
              onChange={onChange}
              width="100%"
            />
          </div>
        ) : (
          <div className={s.notEditActive} data-testid="KYB-business-row-edit-not-active">
            <Text testId={`KYB-business-row-${name}`}>{field}</Text>
            <HSpacer />
          </div>
        )}
      </div>
    );
  }
}

BusinessRow.propTypes = {
  editActive: PropTypes.bool,
  field: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
};

BusinessRow.defaultProps = {
  editActive: false,
  field: '',
  name: '',
  onChange: () => {},
};

export default BusinessRow;
