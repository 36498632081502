import React from 'react';
import {
  Form,
  Select,
  VSpacer,
  FormLabel,
  Text,
  InputSelectGroup,
  CurrencySelect,
} from '@mqd/volt-base';
import { MaskedInput } from '@mqd/volt-currency';
import PropTypes from 'prop-types';

export default function AddFundsForm({
  defaultCurrency,
  formState,
  handleFormChange,
  programFundingSourceNames,
}) {
  return (
    <Form onFormChange={handleFormChange} initialState={formState}>
      <Select
        required
        label="Program funding source"
        name="program_funding_source_name"
        options={programFundingSourceNames}
      />
      {formState.funding_source_token && (
        <>
          <VSpacer factor={3} />
          <FormLabel id="funding_source_token" isHorizontalLabel>
            Funding source token
          </FormLabel>
          <VSpacer factor={0.5} />
          <Text labelledBy="funding_source_token">{formState.funding_source_token}</Text>
        </>
      )}
      <VSpacer factor={3} />
      <InputSelectGroup label="Amount" testId="amount-currency">
        <MaskedInput name="amount" />
        <CurrencySelect
          withFlags
          primaryCodes={['USD', 'CAD']}
          name="currency_code"
          placeholder="Select"
          onChange={({ code }) => code}
          value={defaultCurrency}
        />
      </InputSelectGroup>
      <VSpacer factor={3} />
    </Form>
  );
}

AddFundsForm.propTypes = {
  defaultCurrency: PropTypes.string,
  formState: PropTypes.object,
  handleFormChange: PropTypes.func,
  programFundingSourceNames: PropTypes.array,
};

AddFundsForm.defaultProps = {
  defaultCurrency: '',
  formState: {},
  handleFormChange: () => {},
  programFundingSourceNames: [],
};
