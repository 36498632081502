import React from 'react';
import { observer } from 'mobx-react';
import { LoadingOverlay } from '@mqd/volt-base';

const LoadingOverlayWrapper = ({ loadingStore }) => {
  if (!loadingStore.isLoading) return null;
  const style = { position: 'fixed', zIndex: 9999999 };
  const fullHeightAndWidth = { width: '100vw', height: '100vh' };
  const { message } = loadingStore;
  return (
    <div style={style}>
      <LoadingOverlay active={true} message={message}>
        <div style={fullHeightAndWidth} />
      </LoadingOverlay>
    </div>
  );
};

export default observer(LoadingOverlayWrapper);
