import currentUserStore from '../../stores/CurrentUserStore.js';
import { UAM_GRANULAR_PERMISSIONS } from '@mqd/mqd-constants';
// Routes
const cardProductRoutes = [
  {
    path: '/card-products',
    name: 'Card Products',
    importer: () => import(/*webpackChunkName: "Card Products" */ './pages/CardProducts.js'),
  },
  {
    path: '/card-products/new',
    name: 'Create Card Product',
    importer: () =>
      import(/*webpackChunkName: "Create Card Product" */ './pages/CreateCardProduct.js'),
  },
  {
    path: '/card-products/:token',
    name: 'Card Product',
    importer: () =>
      import(/*webpackChunkName: "Card Product" */ './pages/CardProductDetailWrapper.js'),
  },
];

// Authorization
const defaultRequirements = {
  flipFlopRequirements: ['card-products'],
  requiredGranularPermissions: [UAM_GRANULAR_PERMISSIONS.PROGRAM_CONFIGURATION_VIEW_AND_EDIT],
};

const defaultVerifier = () => {
  return currentUserStore.userStore.cardProductsActive;
};

const requirements = {
  cardProducts: defaultRequirements,
  createCardProduct: defaultRequirements,
  cardProduct: defaultRequirements,
};

const verifiers = {
  cardProducts: defaultVerifier,
  createCardProduct: defaultVerifier,
  cardProduct: defaultVerifier,
};

export { requirements, verifiers };
export default cardProductRoutes;
