// @flow
import { decorate, observable, action, runInAction } from 'mobx';
import gqlUtils from '@mqd/graphql-utils';
import BusinessStore from './../stores/BusinessStore';
import { ParentTableStore } from '@mq/voltron-parent';

const { fragments } = gqlUtils;

export default class BusinessesTableStore extends ParentTableStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
  }
  // values
  items: Array<BusinessStore> = [];
  count: number = 50;
  business_name_dba: string = '';
  business_name_legal: string = '';
  cardHoldersBackendError: string = '';

  // actions
  handleSearch() {
    const { hydrate, setAttr } = this;
    setAttr && setAttr('start_index', 0);
    setAttr('count', 50);
    hydrate && hydrate();
  }

  resetError(result) {
    const error_message = this.dig(result, 'errors', '0', 'message');
    if (
      error_message &&
      error_message.includes("You don't have access to VIEW this part of the app")
    ) {
      this.cardHoldersBackendError = "You don't have permission to view this page";
    } else {
      this.cardHoldersBackendError = '';
    }
  }

  async hydrate() {
    const paramsInfo = {
      business_name_dba: {
        type: 'String',
        val: this.business_name_dba,
      },
      business_name_legal: {
        type: 'String',
        val: this.business_name_legal,
      },
    };
    this.loading = true;
    if (!this.enableNoParamQuery && !this.validParams) return;
    const defaultOuterParams = `(${this.configureOuterQueryParams(
      paramsInfo
    )} $count: Int $start_index: Int)`;
    const defaultInnerParams = `(${this.configureInnerQueryParams(
      paramsInfo
    )} count: $count start_index: $start_index)`;
    const queryParams = this.configureQueryParams(paramsInfo);
    const queryAndPaginationParams = Object.assign({}, this.paginationParams, queryParams);
    const result = await this.gqlQuery(
      `
        query businesses${defaultOuterParams} {
          businesses${defaultInnerParams} {
            data {
              ...businessBaseInfo
              primary_contact {
                ...primaryContactBaseInfo
              }
              office_location {
                ...addressBaseInfo
              }
            }
            ${this.paginationAttributes}
          }
        }

        ${fragments.businessBaseInfo}
        ${fragments.primaryContactBaseInfo}
        ${fragments.addressBaseInfo}
      `,
      queryAndPaginationParams,
      [],
      'no-cache',
      'all'
    );

    runInAction(() => {
      this.resetError(result);
      const businesses = this.extract(result, 'businesses');
      this.processPaginationResponse(businesses, BusinessStore);
      this.loading = false;
    });
  }
}

decorate(BusinessesTableStore, {
  // values
  items: observable,
  business_name_dba: observable,
  business_name_legal: observable,
  cardHoldersBackendError: observable,

  // actions
  handleSearch: action.bound,
  hydrate: action.bound,

  // computed
});
