import { decorate, observable, action, computed } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';
import { formatMoney } from '@mqd/volt-currency';
import AssociationStore from './AssociationStore';
import MerchantScopeStore from './MerchantScopeStore';

class VelocityControlStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.loadAndConstruct(args);
  }
  // store conifg
  skipAutoLoad: boolean = true;

  // values
  token: string = '';
  name: string = '';
  usage_limit: number = '';
  approvals_only: boolean = true;
  include_purchases: boolean = true;
  include_withdrawals: boolean = true;
  include_transfers: boolean = true;
  include_cashback: boolean = true;
  include_credits: boolean = false;
  currency_code: string = '';
  amount_limit: number = '';
  velocity_window: string = '';
  number_of_days: number = '';
  active: boolean = false;
  maxAmountLimit: string;
  maxUsageLimit: string;
  hasError: boolean = false;
  // objects
  association: AssociationStore = {};
  merchant_scope: MerchantScopeStore = {};

  // actions
  loadAndConstruct(velocityControlData = {}) {
    const { association = {}, merchant_scope = {}, ...velocityControlAttr } = velocityControlData;
    this.load(velocityControlAttr);
    this.loadAndConstructItem('association', association, AssociationStore);
    this.loadAndConstructItem('merchant_scope', merchant_scope, MerchantScopeStore);
  }

  // computed
  get velocityWindowOptions() {
    const options = ['TRANSACTION', 'DAY', 'WEEK', 'MONTH', 'LIFETIME'];
    return options.filter((option) => option !== this.velocity_window);
  }

  get windowLabel() {
    if (!this.velocity_window && this.number_of_days) {
      return `${this.number_of_days} days`;
    }

    switch (this.velocity_window) {
      case 'DAY':
        return 'Daily';
      case 'WEEK':
        return 'Weekly';
      case 'MONTH':
        return 'Monthly';
      case 'LIFETIME':
        return 'Lifetime';
      case 'TRANSACTION':
        return 'Transaction';
      default:
        return this.velocity_window;
    }
  }

  get formattedAmountLimit() {
    if (this.amount_limit) {
      return ['$' + formatMoney(this.amount_limit), this.currency_code].join(' ').trim();
    }
  }

  get formattedVelocityControl() {
    return [this.formattedAmountLimit, this.formattedVelocityWindow].join(' ').trim();
  }

  get formattedUsageLimit() {
    const amount = `$${formatMoney(this.amount_limit)} ${this.currency_code}`;
    const velocity =
      this.usage_limit !== -1 && this.usage_limit !== null
        ? `${amount} and ${this.usage_limit} txns`
        : amount;

    return [velocity, this.formattedVelocityWindow].join(' ').trim();
  }

  get formattedVelocityWindow() {
    if (this.velocity_window) {
      return `per ${this.velocity_window.toLowerCase()}`;
    } else if (this.number_of_days) {
      return `per ${this.number_of_days} days`;
    }
  }

  get isSingleUse() {
    return Boolean(this.usage_limit === 1 && this.velocity_window === 'LIFETIME');
  }

  get isValid() {
    return Object.keys(this.validation).length === 0;
  }

  get validation() {
    const error = {};
    if (!this.amount_limit) {
      error['amount_limit'] = 'Amount limit is required';
    } else if (!/^\d*(.\d{0,2})?$/.test(this.amount_limit)) {
      error['amount_limit'] = 'Invalid amount format (ex: 1000.00)';
    } else if (this.maxAmountLimit && this.maxAmountLimit < this.amount_limit) {
      error['amount_limit'] = `Amount cannot exceed limit of ${this.maxAmountLimit}`;
    }
    if (!this.velocity_window && !this.number_of_days) {
      error['velocity_window'] = 'Velocity time window is required';
    }
    if (!this.currency_code) {
      error['currency_code'] = 'Currency code is required';
    }
    return error;
  }

  get isValidWithRequiredUsage() {
    return Object.keys(this.validationWithRequiredUsage).length === 0;
  }

  get validationWithRequiredUsage() {
    const updateValidation = (errorMessage) => ({
      ...this.validation,
      usage_limit: errorMessage,
    });
    if (!this.usage_limit) {
      return updateValidation('Usage limit is required');
    } else if (!/^\d*$/.test(this.usage_limit) && this.usage_limit !== -1) {
      return updateValidation('Usage limit needs to be a whole number');
    } else if (
      this.maxUsageLimit &&
      this.maxUsageLimit > 0 &&
      this.maxUsageLimit < this.usage_limit
    ) {
      return updateValidation(`Usage cannot exceed limit of ${this.maxUsageLimit}`);
    } else {
      return this.validation;
    }
  }

  get types() {
    return {
      'Approvals only': {
        value: this.approvals_only,
        key: 'approvals_only',
      },
      Purchases: {
        value: this.include_purchases,
        key: 'include_purchases',
      },
      Withdrawals: {
        value: this.include_withdrawals,
        key: 'include_withdrawals',
      },
      Transfers: {
        value: this.include_transfers,
        key: 'include_transfers',
      },
      Cashback: {
        value: this.include_cashback,
        key: 'include_cashback',
      },
      Credits: {
        value: this.include_credits,
        key: 'include_credits',
      },
    };
  }

  get includeOnlyTypes() {
    const types = this.types;
    delete types['Approvals only'];
    return types;
  }

  get controlTypeStringsArray() {
    let typeStringsArray = [];

    for (let type in this.types) {
      const info = this.types[type];
      const value = info.value;
      value && typeStringsArray.push(type);
    }

    return typeStringsArray;
  }

  get selectedIncludeOptions() {
    const selectedTypes = [];

    for (let type in this.includeOnlyTypes) {
      const info = this.includeOnlyTypes[type];
      const value = info.value;
      value && selectedTypes.push(type);
    }

    return selectedTypes;
  }

  get invalidIncludeTypeSelection() {
    return false;
    const error = 'This field is required';
    return !Object.entries(this.includeOnlyTypes).some(([key, value]) => value.value) && error;
  }
}

decorate(VelocityControlStore, {
  skipAutoLoad: observable,
  // values
  token: observable,
  name: observable,
  usage_limit: observable,
  approvals_only: observable,
  include_purchases: observable,
  include_withdrawals: observable,
  include_transfers: observable,
  include_cashback: observable,
  include_credits: observable,
  currency_code: observable,
  amount_limit: observable,
  velocity_window: observable,
  active: observable,
  number_of_days: observable,
  maxAmountLimit: observable,
  maxUsageLimit: observable,
  hasError: observable,
  // objects
  association: observable,
  merchant_scope: observable,

  // actions
  loadAndConstruct: action.bound,

  // computed
  velocityWindowOptions: computed,
  windowLabel: computed,
  formattedAmountLimit: computed,
  formattedVelocityControl: computed,
  formattedUsageLimit: computed,
  formattedVelocityWindow: computed,
  isSingleUse: computed,
  isValid: computed,
  validation: computed,
  isValidWithRequiredUsage: computed,
  validationWithRequiredUsage: computed,
  controlTypeStringsArray: computed,
  selectedIncludeOptions: computed,
  types: computed,
  invalidIncludeTypeSelection: computed,
  includeOnlyTypes: computed,
});

export default VelocityControlStore;
