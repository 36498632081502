import React, { useEffect, useState } from 'react';
import { Table, TableHeader } from '@mq/voltron-table';
import moment from 'moment';

import useTableColumns from 'views/fds/hooks/useTableColumns.js';
import { DATE_TIME_FORMAT_STRING } from 'views/fds/utilities/date.js';
import calculateAndFormatScaledDecimal from 'views/fds/utilities/number/calculate-and-format-scaled-decimal.js';
import { NETWORKS, COUNTRY_BY_CURRENCY_CODE } from 'views/fds/utilities/dictionary';
import { formatApiEnum } from 'views/fds/utilities/string';
import { COLUMNS_LOCAL_STORAGE_KEY } from './constants';

const DEFAULT_FIELDS = [
  'settlement_date',
  'network_reference_id',
  'source',
  'settled_total',
  'transaction_total',
  'fee_total',
  'switch_fee',
  'cross_border_fee',
  'interchange_fee',
  'surcharge_atm_pin_debit_fee',
  'pulse_surcharge_atm_pin_debit_fee',
];

const RelatedTransactions = ({
  commonData,
  loading,
  netRefId,
  network,
  subnetwork,
  settlementDate,
  transactions,
}) => {
  // Table columns.
  const { columns, columnEditor } = useTableColumns({
    network,
    subnetwork,
    fieldSetId: 'related_transactions',
    isBulkEditable: false,
    columnsLocalStorageKey: COLUMNS_LOCAL_STORAGE_KEY,
    defaultColumnsBaseFilter: DEFAULT_FIELDS,
  });

  const [data, setData] = useState();

  useEffect(() => {
    if (!loading) {
      const { currency_code, masked_pan } = commonData;

      const processedTxs = transactions.map((tx) => {
        const { source, transaction_summary, settlement_currency } = tx;

        const sourceSpecificVals = {};

        if (source === 'MQ') {
          sourceSpecificVals.source = 'Marqeta';
          sourceSpecificVals.created_date =
            tx.created_date && moment(tx.created_date).format(DATE_TIME_FORMAT_STRING);
          sourceSpecificVals.itc = tx.itc;
        } else {
          sourceSpecificVals.source = `${NETWORKS[network]}`;
          sourceSpecificVals.fee_program_indicator = tx.fee_program_indicator;
          sourceSpecificVals.message_reason_code = tx.message_reason_code;
        }

        return {
          settlement_date: settlementDate,
          network_reference_id: netRefId,

          settled_total: calculateAndFormatScaledDecimal(
            transaction_summary.settled_total,
            settlement_currency
          ),
          transaction_total: calculateAndFormatScaledDecimal(
            transaction_summary.transactions,
            settlement_currency
          ),
          fee_total: calculateAndFormatScaledDecimal(transaction_summary.fees, settlement_currency),
          switch_fee: calculateAndFormatScaledDecimal(
            transaction_summary.switch_fee,
            settlement_currency
          ),
          cross_border_fee: calculateAndFormatScaledDecimal(
            transaction_summary.cross_border_fee,
            settlement_currency
          ),
          interchange_fee: calculateAndFormatScaledDecimal(
            transaction_summary.interchange_fee,
            settlement_currency
          ),
          surcharge_atm_pin_debit_fee: calculateAndFormatScaledDecimal(
            transaction_summary.surcharge_atm_pin_debit_fee,
            settlement_currency
          ),

          masked_pan: masked_pan,
          transaction_token: tx.transaction_token,
          original_id: tx.original_id,
          retrieval_reference_number: tx.retrieval_reference_number,
          system_trace_audit_number: tx.system_trace_audit_number,
          transaction_type: formatApiEnum(tx.transaction_type),
          transaction_code: formatApiEnum(tx.transaction_code),
          local_transaction_date_time: tx.local_transaction_date_time,
          batch_number: tx.batch_number,
          reason_code: tx.reason_code,
          merchant_name: tx.merchant_name,
          merchant_id: tx.merchant_id,
          merchant_country: COUNTRY_BY_CURRENCY_CODE[tx.merchant_country],
          merchant_category_code: tx.merchant_category_code,
          conversion_fee: calculateAndFormatScaledDecimal(
            tx.conversion_fee_amount,
            tx.conversion_fee_currency
          ),
          message_type_indicator: tx.message_type_indicator,
          transaction_function: tx.transaction_function,
          processing_code: tx.processing_code,
          function_code: tx.function_code,
          billing_amount: calculateAndFormatScaledDecimal(tx.billing_amount, currency_code),
          business_activity: tx.business_activity,
          cycle: tx.cycle,
          originator: tx.originator,
          additional_information: tx.additional_information,
          ...sourceSpecificVals,
        };
      });

      setData(processedTxs);
    }
  }, [loading, commonData, network, netRefId, settlementDate, transactions, setData]);

  return (
    <>
      <TableHeader>{columnEditor}</TableHeader>
      <Table
        loading={loading}
        data={data}
        columns={columns}
        fixedColumnCount={1}
        rightAlignFixedColumns={true}
        height={500}
      />
    </>
  );
};

export default RelatedTransactions;
