import gql from 'graphql-tag';

export const PROGRAM_CONFIG_KEY = 'programConfigData';

export const UPDATE_PROGRAM_CONFIG = 'UPDATE_PROGRAM_CONFIG';
export const LOADING_PROGRAM_CONFIG = 'LOADING_PROGRAM_CONFIG';

export const CASES_PROGRAM_DATA = [
  {
    program: 'qa01',
    short_name: 'qa01',
    favorite: false,
  },
  {
    program: 'qa02',
    short_name: 'qa02',
    favorite: false,
  },
  {
    program: 'qa03',
    short_name: 'qa03',
    favorite: false,
  },
  {
    program: 'qa04',
    short_name: 'qa04',
    favorite: false,
  },
  {
    program: 'qa05',
    short_name: 'qa05',
    favorite: false,
  },
  {
    program: 'qa06',
    short_name: 'qa06',
    favorite: false,
  },
  {
    program: 'qa07',
    short_name: 'qa07',
    favorite: false,
  },
  {
    program: '2node',
    short_name: '2node',
    favorite: false,
  },
];

export const PROGRAM_CONFIGS = gql`
  query programConfigs {
    programConfigs {
      AUTH_CONTROL_DEFAULT
      INTERNATIONAL_RESPONSE_FORMAT_VERSION
      ENABLE_PIN_REVEAL
      MAX_ACTIVE_CARDS_PER_USER
      TRANSACTION_MODEL_VERSION
      TERMINATE_ACTIVE_CARDS_ON_NEW_CARD_ACTIVATION
      DEFAULT_CURRENCY
      featureFlags {
        KYC_MICROSERVICE_ENABLED
        ENABLE_CONTROLPLANE_DASHBOARD
        ENABLE_CONTROLPLANE_DECISION_REPORTS
        TXN_ACCT_MONITORING_ENABLED
      }
    }
  }
`;
