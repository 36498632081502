import { FlipFlop } from '../../index.js';
import CreditCapabilities from '../../../views/credit/CreditCapabilities/index.js';

export const requirements = {
  flipFlopRequirements: ['unify-beta'],
};

export const verifier = () => {
  const rolesRestrictions = CreditCapabilities.canEnterProductsPage();
  return FlipFlop.get('unify-beta', false) && rolesRestrictions;
};
