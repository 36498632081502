import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Icon, Text, Colors, HSpacer, Link, Tooltip } from '@mqd/volt-base';
import {
  txnIsReturnableType,
  displayReturnableTooltip,
  txnIsDisputableType,
  displayDisputableTooltip,
} from '../utils';
import { useHasAuxToReturnTransaction, useHasAuxToDisputeTransaction } from '../hooks';

const CodeAndMemo = ({ response = {}, state }) => {
  if (!response.code && !response.memo) return null;
  return (
    <>
      {(state === 'DECLINED' || state === 'ERROR') && (
        <>
          <Icon overrideColor={Colors.dangerColor} noHoverEffects type="danger" />
          <HSpacer factor={0.8} />
        </>
      )}
      <Text type="footnote">{`[${response.code}] ${response.memo}`}</Text>
    </>
  );
};

const InitiateReturn = ({ canReturn, type, onReturnClick, state = '' }) => {
  if (!canReturn || !txnIsReturnableType(type)) return null;
  const displayTooltip = displayReturnableTooltip(state);
  return (
    <>
      <HSpacer />
      <Flex display="flex" testId="action-buttons-initiate-return">
        <Text type="footnote">
          <Link
            type="secondary"
            onClick={onReturnClick}
            inheritParentFont
            disabled={displayTooltip}
          >
            Initiate return
          </Link>
        </Text>
        {displayTooltip && (
          <Tooltip
            content={`Cannot return a ${state.toLocaleLowerCase()} transaction`}
            direction="top"
          >
            <Icon type="info" noHoverEffects />
          </Tooltip>
        )}
      </Flex>
    </>
  );
};

const Dispute = ({ canDispute, type, onDisputeClick, state = '' }) => {
  if (!canDispute || !txnIsDisputableType(type)) return null;
  const displayTooltip = displayDisputableTooltip(state);
  return (
    <>
      <HSpacer />
      <Flex display="flex" testId="action-buttons-dispute">
        <Text type="footnote">
          <Link
            type="secondary"
            onClick={onDisputeClick}
            inheritParentFont
            disabled={displayTooltip}
          >
            Dispute transaction
          </Link>
        </Text>
        {displayTooltip && (
          <Tooltip
            content={`Cannot dispute a ${state.toLocaleLowerCase()} transaction`}
            direction="top"
          >
            <Icon type="info" noHoverEffects />
          </Tooltip>
        )}
      </Flex>
    </>
  );
};

const ActionButtons = ({ transaction, onViewMoreClick, onReturnClick, onDisputeClick }) => {
  const { token, state, type, response = {} } = transaction;
  const hasAuxToReturnTransaction = useHasAuxToReturnTransaction();
  const hasAuxToDisputeTransaction = useHasAuxToDisputeTransaction();

  return (
    <Flex display="flex" justifyContent="space-between">
      <Flex display="flex" alignItems="baseline">
        <CodeAndMemo state={state} response={response} />
        <InitiateReturn
          type={type}
          state={state}
          canReturn={hasAuxToReturnTransaction}
          onReturnClick={() => onReturnClick(transaction)}
        />
        <Dispute
          type={type}
          state={state}
          canDispute={hasAuxToDisputeTransaction}
          onDisputeClick={() => onDisputeClick(token)}
        />
      </Flex>
      <Flex display="flex">
        <Text type="footnote">
          <Link
            testId="action-buttons__view-more-details"
            iconType="arrow-right"
            onClick={() => onViewMoreClick(token)}
            inheritParentFont
          >
            View more details
          </Link>
        </Text>
        <HSpacer factor={5} />
      </Flex>
    </Flex>
  );
};

ActionButtons.propTypes = {
  transaction: PropTypes.object,
  onViewMoreClick: PropTypes.func,
  onReturnClick: PropTypes.func,
  onDisputeClick: PropTypes.func,
};

ActionButtons.defaultProps = {
  transaction: {},
};

export default ActionButtons;
