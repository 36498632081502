import React from 'react';

import StatusIndicator from '../../components/StatusIndicator/StatusIndicator.js';
import { notAvailable, textRenderer, copyRenderer } from '../helpers/renderers.js';

const SHARED_FIELDS = {
  bank: {
    header: 'Bank',
    accessor: 'bank',
    key: 'bank',
    width: 160,
    renderer: textRenderer,
  },

  hash_id: {
    header: 'Hash ID',
    accessor: 'hash_id',
    key: 'hash_id',
    width: 120,
    renderer: (opts) => copyRenderer({ ...opts, shouldMask: true }),
  },

  network_ref_id: {
    header: 'Network ref. ID',
    accessor: 'network_reference_id',
    key: 'network_reference_id',
    width: 200,
    renderer: copyRenderer,
  },

  program: {
    header: 'Program',
    accessor: 'program',
    key: 'program',
    width: 160,
    renderer: textRenderer,
  },

  program_type: {
    header: 'Program type',
    accessor: 'program_type',
    key: 'program_type',
    width: 160,
    renderer: textRenderer,
  },

  source: {
    header: 'Source',
    accessor: 'source',
    key: 'source',
    width: 160,
    renderer: textRenderer,
  },

  state: {
    header: 'Status',
    accessor: 'state',
    key: 'state',
    width: 160,
    renderer: ({ cellValue }) =>
      cellValue ? <StatusIndicator status={cellValue} /> : notAvailable,
  },

  tx_hash_internal_id: {
    header: 'FDS tx hash internal ID',
    key: 'tx_hash_internal_id',
    accessor: 'tx_hash_internal_id',
    width: 200,
    renderer: copyRenderer,
  },
};

function getSharedFields(fieldIds = []) {
  return fieldIds.reduce((result, id) => {
    if (SHARED_FIELDS[id]) result[id] = SHARED_FIELDS[id];
    return result;
  }, {});
}

export default getSharedFields;
