import React from 'react';

import { MarqetaCard } from '@mqd/volt-base';

import Logo from './Logo.svg';

export default function CreditCard() {
  return (
    <MarqetaCard
      testId="credit-marqeta-card"
      pan={'************1234'}
      cvv={'123'}
      exp={'01/23'}
      locked={false}
      displayLogo
      firstName="Example"
      lastName="card"
      style={{ backgroundImage: `url(${Logo})` }}
    />
  );
}
