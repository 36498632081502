import currentUserStore from 'stores/CurrentUserStore';
export const requirements = {
  marqetaOnly: true,
};

export const verifier = () => {
  const isMarqetan =
    currentUserStore.userStore.userOrgName && currentUserStore.userStore.userOrgName === 'Marqeta';
  return !!isMarqetan;
};
