import { useQuery } from '@apollo/react-hooks';
import { CURRENT_USER_SANDBOX_QUERY, getSandboxDataFromCurrentUser } from '../sandboxFunctions.js';

const useSandboxCredentials = () => {
  const { data, error, loading } = useQuery(CURRENT_USER_SANDBOX_QUERY);
  const { accessToken, applicationToken, shortCode } = getSandboxDataFromCurrentUser(data);

  const sandboxCredentialsVariables = {
    accessToken,
    applicationToken,
    'x-marqeta-program-short-code': shortCode,
  };

  return {
    data: {
      applicationToken,
      accessToken,
      shortCode,
    },
    error,
    loading,
    sandboxCredentialsVariables,
  };
};

export { useSandboxCredentials };
