/* eslint-disable camelcase */
import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import CardholderInfo from './InputCards/CardholderInfo';
import AddFunds from './InputCards/AddFunds';
import OptionalCardControls from './InputCards/OptionalCardControls';
import ShippingInformation from './InputCards/ShippingInformation';
import s from './CardCreationDetail.module.css';
import {
  Text,
  VSpacer,
  HSpacer,
  Button,
  LoadingOverlay,
  Modal,
  Hr,
  Link,
  ErrorModal,
} from '@mqd/volt-base';
import ManualCardCreateStore from '../stores/ManualCardCreateStore';
import BusinessStore from '../stores/BusinessStore';
import { ParentComponent, SegmentTrackHOC } from '@mq/voltron-parent';
import CardPersonalization from './InputCards/CardPersonalization';
import CardCarrier from './../shared-components/CardCarrier';
import CardCarrierEditTemplate from './InputCards/components/card-carrier-templates/EditTemplate';
import CardCarrierViewTemplate from './InputCards/components/card-carrier-templates/ViewTemplate';
import Address from '../shared-components/address';
import { UAM_GRANULAR_PERMISSIONS } from '../constants/permissions';

class ManualCreateFlow extends ParentComponent {
  constructor(props) {
    super(props);
    const { queryParams: { business_token: { val: businessToken } = {} } = {} } =
      this.props.storeInitArgs || {};
    this.storeConstructor = ManualCardCreateStore;
    this.instantiateAdditionalStore('businessStore', BusinessStore, { token: businessToken });
    this.state = {
      showConfirmationModal: false,
      returnAddress: null,
    };

    this.openConfirmationModal = this.openConfirmationModal.bind(this);
    this.closeConfirmationModal = this.closeConfirmationModal.bind(this);
    this.createCardHandler = this.createCardHandler.bind(this);
  }

  openConfirmationModal() {
    this.setState({ showConfirmationModal: true });
  }

  closeConfirmationModal() {
    this.setState({ showConfirmationModal: false });
  }

  get title() {
    const { business_name_legal } = this.businessStore;
    const title = business_name_legal
      ? `Create card for ${business_name_legal} users`
      : 'Manual create card';
    return title;
  }

  createCardHandler() {
    const {
      createCardResources,
      createCardResourcesUam,
      shippingAddressHasChanged,
      cardProduct: { isPhysical },
    } = this.store;
    const { returnAddress } = this.state;
    const { userStore: { redseaRoles = [] } = {} } = this.props;
    const hasUamRole = redseaRoles.includes(UAM_GRANULAR_PERMISSIONS);

    this.store.setAttr('returnAddress', returnAddress);

    if (!isPhysical) {
      return hasUamRole ? createCardResourcesUam : createCardResources;
    } else {
      return shippingAddressHasChanged
        ? this.openConfirmationModal
        : hasUamRole
        ? createCardResourcesUam
        : createCardResources;
    }
  }

  render() {
    const {
      formIsValid,
      createCardResources,
      cardholder: {
        first_name,
        last_name,
        setAttr: setCardholderAttr,
        authFormattedMccs: cardholderAuthFormattedMccs,
      } = {},
      cardProduct: {
        isPreFunded,
        name: cardProductName,
        authFormattedMccs: cardProductAuthFormattedMccs,
        hasMinOffSet,
        isPhysical,
      },
      card: { token: cardToken },
      cardStartDate,
      cardExpirationDate,
      cardExpirationDateError,
      setThreeYearTimeFrame,
      authControls,
      addNewAuthControl,
      removeAuthControl,
      duplicateAuthControl,
      setAttr: setManualCardCreateAttr,
      setAttrWithLimit: setManualCardCreateAttrWithLimit,
      loading: manualCardCreateLoading,
      activeFundingSource,
      fundingSourceSelectOptions,
      addFundingSource,
      gpaOrder,
      velocityControlsFormStore,
      requestSuccess,
      responseModalProps,
      cardCardholderCardproductFullfillmentShippingAddress,
      shippingAddressHasChanged,
      cardPersonalizationData,
      shippingMethodOptions,
      cardPersonalizationValidators,
    } = this.store;
    const { loading: businessStoreLoading } = this.businessStore;
    const { goBack, goToCard, testId } = this.props;
    const { returnAddress } = this.state;
    const conditionalModalProps = responseModalProps[requestSuccess];
    const ModalComponent =
      requestSuccess === true ? Modal : requestSuccess === false ? ErrorModal : null;
    const closeModal = () => setManualCardCreateAttr('requestSuccess', null);
    const { showConfirmationModal } = this.state;
    const shouldAskForConfirmation = shippingAddressHasChanged && showConfirmationModal;

    const returnAddressActions = {
      createAction: (returnAddress) => this.setState({ returnAddress }),
      updateAction: (returnAddress) => this.setState({ returnAddress }),
      deleteAction: () => this.setState({ returnAddress: {} }),
      cancelAction: () => this.setState({ returnAddress: {} }),
    };

    return (
      <div data-testid={testId}>
        <LoadingOverlay active={manualCardCreateLoading || businessStoreLoading}>
          {conditionalModalProps && (
            <ModalComponent
              type="sm"
              {...responseModalProps}
              {...conditionalModalProps}
              showHideModalButton={false}
              hideModal={closeModal}
              footerButtons={[
                <Button
                  key="continue"
                  onClick={requestSuccess ? () => goToCard(cardToken) : closeModal}
                  testId="card-creation-modal-button"
                >
                  {conditionalModalProps.actionText}
                </Button>,
              ]}
            >
              {conditionalModalProps.text}
            </ModalComponent>
          )}
          {shouldAskForConfirmation && (
            <Modal
              type="sm"
              testId="edit-address-confirmation"
              heading="Shipping address confirmation"
              hideModal={this.closeConfirmationModal}
              footerButtons={[
                <Button
                  key="confirmation"
                  onClick={() => {
                    createCardResources();
                    this.closeConfirmationModal();
                  }}
                  testId="card-creation-modal-button"
                >
                  Confirm
                </Button>,
              ]}
            >
              <Text>
                By editing the shipping address, you are updating this address for where this card
                will be shipped.
              </Text>
            </Modal>
          )}
          <Text type="h3" testId="card-creation-header">
            {this.title}
          </Text>
          <VSpacer factor={2} />
          <Text type="h5">Manual create</Text>
          <div>
            Note that this new card will use the previously selected card product, &nbsp;
            <Link inline onClick={goBack}>
              {cardProductName}
            </Link>
          </div>
          <VSpacer factor={3} />
          {isPhysical && (
            <>
              <CardPersonalization
                onChange={setManualCardCreateAttr}
                data={cardPersonalizationData}
                shippingMethodOptions={shippingMethodOptions}
                validators={cardPersonalizationValidators}
                cardProductIsPhysical={isPhysical}
              />
              <VSpacer factor={8} />
              <CardCarrier
                EditTemplate={CardCarrierEditTemplate}
                ViewTemplate={CardCarrierViewTemplate}
                actions={{
                  createAction: (card) => {
                    this.store.setAttr('cardCarrier', card);
                  },
                  updateAction: (card) => {
                    this.store.setAttr('cardCarrier', card);
                  },
                  deleteAction: () => {
                    this.store.setAttr('cardCarrier', null);
                  },
                }}
                cardCarrier={this.store.cardCarrier}
              />
              <VSpacer factor={6} />
              <Text type="h5">Return address</Text>
              <VSpacer factor={2} />
              <Address actions={returnAddressActions} address={returnAddress} />
              <VSpacer factor={3} />
            </>
          )}
          <ShippingInformation
            address={cardCardholderCardproductFullfillmentShippingAddress}
            onChange={(key, value) => setManualCardCreateAttr(key, value)}
            cardProductIsPhysical={isPhysical}
          />
          <VSpacer factor={2} />
          <CardholderInfo
            first_name={first_name}
            last_name={last_name}
            goBack={goBack}
            setCardholderAttr={setCardholderAttr}
          />
          {isPreFunded && (
            <>
              <VSpacer factor={2} />
              <AddFunds
                setAttr={setManualCardCreateAttr}
                activeFundingSource={activeFundingSource}
                fundingSourceSelectOptions={fundingSourceSelectOptions}
                addFundingSource={addFundingSource}
                gpaOrder={gpaOrder}
              />
            </>
          )}
          <VSpacer factor={2} />
          <OptionalCardControls
            store={{
              authControls,
              cardStartDate,
              cardExpirationDate,
              setManualCardCreateAttrWithLimit,
              cardExpirationDateError,
              addNewAuthControl,
              removeAuthControl,
              setThreeYearTimeFrame,
              duplicateAuthControl,
              hasMinOffSet,
              authFormattedMccs: [...cardholderAuthFormattedMccs, ...cardProductAuthFormattedMccs],
              velocityControlsFormStore,
            }}
          />
          <VSpacer factor={2} />
          <Hr />
          <VSpacer factor={2} />
          <div className={s.flexStart}>
            <Button type="outline" onClick={goBack}>
              Back
            </Button>
            <HSpacer factor={2} />
            <SegmentTrackHOC eventName="Manual Cards Created">
              <span onClick={this.createCardHandler()} data-testid="submit-card-create-button">
                <Button disabled={!formIsValid}>Create card</Button>
              </span>
            </SegmentTrackHOC>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

ManualCreateFlow.propTypes = {
  storeInitArgs: PropTypes.object,
  autoHydrate: PropTypes.bool,
  store: PropTypes.object,
  onStoreConstruction: PropTypes.func,
  testId: PropTypes.string,
};

ManualCreateFlow.defaultProps = {
  autoHydrate: true,
  onStoreConstruction: () => {},
  testId: 'manual-create-tile',
};

export default observer(ManualCreateFlow);
