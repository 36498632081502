import gql from 'graphql-tag';

export const FETCH_CURRENT_DNA_PROGRAM = gql`
  query currentDNAProgram {
    currentDNAProgram {
      short_code
      bank {
        name
      }
      ach {
        origination_enabled
      }
      bill_pay {
        service_enabled
      }
    }
  }
`;

export const FETCH_PROGRAM_CONFIGS = gql`
  query programConfigs {
    programConfigs {
      AUTH_CONTROL_DEFAULT
      INTERNATIONAL_RESPONSE_FORMAT_VERSION
      ENABLE_PIN_REVEAL
      MAX_ACTIVE_CARDS_PER_USER
      PROGRAM_MT_FUND_SOURCE_SWITCH
      TRANSACTION_MODEL_VERSION
      TERMINATE_ACTIVE_CARDS_ON_NEW_CARD_ACTIVATION
      DEFAULT_CURRENCY
      SAME_DAY_AMOUNT_LIMIT
      STANDARD_AMOUNT_LIMIT
      featureFlags {
        KYC_MICROSERVICE_ENABLED
        ENABLE_CONTROLPLANE_DECISION_REPORTS
        ENABLE_CONTROLPLANE_DASHBOARD
        TXN_ACCT_MONITORING_ENABLED
      }
    }
  }
`;
