import React from 'react';
import { GlobalAlert } from '@mqd/volt-base';
import routerStore from 'stores/router/RouterStore';
import handlePdRedirect from './handlePdRedirect.js';

export default function PDHeader() {
  const pdRedirect = window.location.pathname === '/reports' && routerStore.pdRedirect;
  if (!pdRedirect) return null;

  return (
    <GlobalAlert
      type="info"
      testId="pd-header"
      mounted={pdRedirect}
      confirmText="Click here to return to Program dashboard"
      dismiss={handlePdRedirect(pdRedirect)}
    >
      You have been redirected to Reports in AMC.
    </GlobalAlert>
  );
}
