import capitalize from './capitalize.js';
import hasRoleForAtLeastOneProgram from './hasRoleForAtLeastOneProgram.js';
import hasRoleForSelectedProgram from './hasRoleForSelectedProgram.js';
import toggleElInArray from './toggleElInArray.js';
import titleize from './titleize.js';
import getEventParents from './getEventParents.js';
import roundNumber from './roundNumber.js';
import retry from './retry.js';
import findParamChangeInObjects from './findParamChangeInObjects.js';
import arrayUnique from './arrayUnique.js';
import { parseDateString, parseDateTimeString } from './parseDateString.js';

const Helpers = {
  capitalize,
  findParamChangeInObjects,
  hasRoleForAtLeastOneProgram,
  hasRoleForSelectedProgram,
  toggleElInArray,
  titleize,
  getEventParents,
  roundNumber,
  retry,
  arrayUnique,
  parseDateString,
  parseDateTimeString,
};

export default Helpers;
