import { decorate, observable, action, runInAction, computed } from 'mobx';
import { ParentTableStore } from '@mq/voltron-parent';
import AccountStore from '../stores/AccountStore';
import { accountTransitionEnabledRoles, revealAccountNumberEnabledRoles } from './constants';

export default class AccountsTableStore extends ParentTableStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
  }

  // values
  items: Array<AccountStore> = [];
  count: number = 50;
  sort_by: string = 'state';

  // actions
  async hydrate() {
    this.loading = true;
    const result = await this.gqlQuery(
      `query directDepositAccounts(
        $user_token: ID, 
        $sort_by: String, 
        ${this.paginationParamsOuter}
      ){
        directDepositAccounts(
          user_token: $user_token, 
          sort_by: $sort_by, 
          ${this.paginationParamsInner}
        ) {
          count
          data {
            token
            account_number
            routing_number
            user_token
            created_time
            state
            allow_immediate_credit
          }
        }
      }`,
      { ...this.hydrateParams, user_token: this.cardholder_token, sort_by: this.sort_by }
    );

    runInAction(() => {
      try {
        const accounts = this.extract(result, 'directDepositAccounts');
        this.processPaginationResponse(accounts, AccountStore);
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    });
  }

  // computed
  get canRevealAccountNumber() {
    if (typeof this.userHasRoleInArrayForActiveProgram !== 'function') return false;
    return Boolean(this.userHasRoleInArrayForActiveProgram(revealAccountNumberEnabledRoles));
  }

  get canTransitionAccount() {
    if (!this.userHasRoleInArrayForActiveProgram) return false;
    return Boolean(this.userHasRoleInArrayForActiveProgram(accountTransitionEnabledRoles));
  }
}

decorate(AccountsTableStore, {
  // values
  items: observable,
  count: observable,
  sort_by: observable,

  // actions
  hydrate: action.bound,

  // computed
  canRevealAccountNumber: computed,
  canTransitionAccount: computed,
});
