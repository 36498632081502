import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import AddButton from '../commons/AddButton';
import AuthControl from './AuthControl';
import { Text, VSpacer, ContentSnippet } from '@mqd/volt-base';

function AuthControls({
  title,
  store: {
    authControls,
    addNewAuthControl,
    removeAuthControl,
    duplicateAuthControl,
    authFormattedMccs,
  },
}) {
  const tooltipProps = {
    direction: 'bottomRight',
  };

  const mccNames = authFormattedMccs && authFormattedMccs.join(', ');

  return (
    <>
      <ContentSnippet
        title={<Text type="h6">{title}</Text>}
        tooltipContent={`
            Add MCCs to this new user. You cannot create an
            MCC. You can only add existing ones.
          `}
        horizontalLayout={true}
        tooltipProps={tooltipProps}
      />
      {mccNames && <Text>Pre-configured: {mccNames}</Text>}
      {authControls &&
        authControls.map((authControl, idx) => {
          return (
            <AuthControl
              key={idx}
              authControl={authControl}
              duplicateAuthControl={() => duplicateAuthControl(idx)}
              removeAuthControl={() => removeAuthControl(idx)}
            />
          );
        })}
      <VSpacer factor={1} />
      <AddButton onClick={addNewAuthControl}>More MCCs</AddButton>
      <VSpacer factor={2} />
    </>
  );
}

AuthControls.propTypes = {
  title: PropTypes.string,
  store: PropTypes.object,
};

AuthControls.defaultProps = {
  title: 'MCCs',
  store: {},
};

export default observer(AuthControls);
