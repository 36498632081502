import calculateAndFormatScaledDecimal from '../../../utilities/number/calculate-and-format-scaled-decimal.js';

import { ACCOUNTING_GROUPS_ORDER, API_KEY_TO_ACCOUNT_GROUP_HASH } from '../constants.js';
import { generateAmountsAndFeesMap } from '../utils.js';

export function calculateSettlementAmountAndFees(settledDetails, network) {
  const accountingGroupsOrder = ACCOUNTING_GROUPS_ORDER[network] || ACCOUNTING_GROUPS_ORDER.default;
  const amountsAndFeesMap = generateAmountsAndFeesMap(network);
  return accountingGroupsOrder.map((accountingGroup) => {
    const apiKey = API_KEY_TO_ACCOUNT_GROUP_HASH[accountingGroup];
    const amount = settledDetails[apiKey] || {
      scale: 2,
      units: '0',
    };

    return {
      ...amountsAndFeesMap[accountingGroup],
      content: calculateAndFormatScaledDecimal(amount),
    };
  });
}
