function convertSearchToObject(search: string) {
  return search
    .substring(1)
    .split('&')
    .reduce(function (result, value) {
      var parts = value.split('=');
      if (parts[0]) result[parts[0]] = decodeURIComponent(parts[1]);
      return result;
    }, {});
}

function convertObjectToQuery(obj: Object) {
  const keys = Object.keys(obj);
  return keys.map((key) => `${key}=${encodeURIComponent(obj[key])}`).join('&');
}

function convertObjectToSearch(obj: Object) {
  const keyCount = Object.keys(obj).length;
  if (keyCount > 0) {
    return '?' + convertObjectToQuery(obj);
  } else {
    return '';
  }
}

function windowPathnameAndSearchString() {
  return decodeURIComponent(`${window.location.pathname}${window.location.search}`);
}

/**
   Input: Program Summary
   Output: program-summary
   meant to be used as <a id='program-summary' />
 */
function convertStringToTag(string) {
  return string && string.toLowerCase().split(' ').join('-');
}

const UrlHelpers = {
  convertSearchToObject,
  convertObjectToQuery,
  convertObjectToSearch,
  windowPathnameAndSearchString,
  convertStringToTag,
};

export default UrlHelpers;
