import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { pageIsProtected } from './utils/index.js';
import { ProtectedView, UnprotectedView } from './components/index.js';
import { IsLoading, BootstrapSession } from '@mq/volt-amc-container';
import AppLoadingOverlayWrapper from '../AppLoadingOverlayWrapper.js';
import ProgramConfigSetup from '../../utils/program-config/ProgramConfigSetup.js';
import ProgramSelectorSetup from '../program-selector/ProgramSelectorSetup.js';

const Page = ({ gqlApi }) => {
  const protectedPage = useMemo(() => pageIsProtected(), []);

  return (
    <IsLoading>
      <AppLoadingOverlayWrapper />
      <ProgramConfigSetup>
        <ProgramSelectorSetup>
          {protectedPage ? (
            <BootstrapSession>
              <ProtectedView />
            </BootstrapSession>
          ) : (
            <UnprotectedView />
          )}
        </ProgramSelectorSetup>
      </ProgramConfigSetup>
    </IsLoading>
  );
};

export default observer(Page);
