import React from 'react';

import { Button } from '../button/Button';

import s from './EditButton.module.css';

export function EditButton(props) {
  // TODO: replace local Button with VOLT Button when the ticket below is done
  //  https://marqeta.atlassian.net/browse/VOLTENG-45
  return (
    <Button {...props} link mod="primary">
      <span className={s.text}>Edit</span>
    </Button>
  );
}
