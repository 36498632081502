import React from 'react';

import { EditTemplate } from '../../../../../../components/default-card-template/components/edit-template';
import { withAdditionalProps } from '../../../../../../hoc/with-additional-props';

import { renderControls } from './components/render-controls';
import {
  CardTemplateContext,
  useCardTemplateContext,
} from '../../../../../../context/CardTemplateContext';

// Pass "status" and "cardCompareState" down to the template
function EditTemplateWithStatusAndCompareState({ cardCompareState, ...props }) {
  const { editTemplate, ...cardTemplateContext } = useCardTemplateContext();

  const extendedCardTemplateContext = {
    ...cardTemplateContext,
    editTemplate: withAdditionalProps(editTemplate, {
      status: cardTemplateContext.status,
      compareState: cardCompareState,
    }),
  };

  return (
    <CardTemplateContext.Provider value={extendedCardTemplateContext}>
      <EditTemplate {...props} />
    </CardTemplateContext.Provider>
  );
}

const EditTemplateWithControls = withAdditionalProps(EditTemplateWithStatusAndCompareState, {
  renderControls,
});

export { EditTemplateWithControls as EditTemplate };
