import React from 'react';
import { ContentSnippet, Icon, VSpacer, Text } from '@mqd/volt-base';
import { ParentDetailCard, Status } from '@mq/voltron-parent';
import s from './digitalwallettoken.module.css';

import { walletProviderReasonScoreCodes } from './constants';

class DigitalWalletTokensSummaryCard extends ParentDetailCard {
  renderStatusSnippet(label, statusName, displayText) {
    return (
      <div style={{ width: 360 }}>
        <ContentSnippet
          title={label}
          testId={`volt-content-snippet_title-${label}`}
          horizontalLayout
        >
          <div>
            <Status status={statusName} onlyDisplayChildren={!!displayText}>
              {displayText}
            </Status>
          </div>
        </ContentSnippet>
        <VSpacer factor={2} />
      </div>
    );
  }

  renderTokenPanBody() {
    const { token_pan, handleShowFullPan, canShowTokenPan } = this.props;
    if (!token_pan) {
      return '';
    }

    if (token_pan.indexOf('_') >= 0) {
      const maskedTokenPan = token_pan.slice(-4);

      return (
        <div className={s.flexDiv}>
          {canShowTokenPan && <Icon type="show" factor={1} onClick={handleShowFullPan} />}
          •••• •••• •••• {maskedTokenPan}
        </div>
      );
    } else {
      return token_pan;
    }
  }

  static splitToArrayOfScores(string) {
    if (!string.length) {
      return [];
    }
    if (string.includes(',')) {
      return string.split(',');
    } else {
      return string.match(/.{1,2}/g);
    }
  }

  render() {
    const {
      token_expiration_date,
      wallet_name,
      wallet_provider_pan_source,
      wallet_provider_reason_score,
      wallet_provider_risk_score,
      formattedWalletProviderRiskScore,
    } = this.props;

    return (
      <div data-testid="token-summary">
        <Text type="h4">Token summary</Text>
        <VSpacer factor={2} />
        {this.renderSnippet({
          title: 'Wallet name',
          content: wallet_name,
        })}

        {this.renderSnippet({
          title: 'Token PAN',
          content: this.renderTokenPanBody(),
        })}

        {this.renderSnippet({
          title: 'Token expiration',
          content:
            token_expiration_date &&
            token_expiration_date.substring(0, 2) + '/' + token_expiration_date.substring(2, 4),
        })}

        {this.renderStatusSnippet(
          'Wallet provider risk score',
          wallet_provider_risk_score,
          formattedWalletProviderRiskScore
        )}

        {this.renderSnippet({
          title: 'Wallet provider - provisioning method',
          content: wallet_provider_pan_source,
        })}

        {this.renderSnippet({
          title: 'Wallet provider reason score',
          content:
            wallet_provider_reason_score &&
            DigitalWalletTokensSummaryCard.splitToArrayOfScores(wallet_provider_reason_score)
              .map((reason) => walletProviderReasonScoreCodes[reason])
              .reduce((acc, curr) => {
                acc.push(curr);
                acc.push(<br />);
                return acc;
              }, []),
        })}
      </div>
    );
  }
}

export default DigitalWalletTokensSummaryCard;
