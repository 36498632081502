import React, { useCallback, useMemo } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import NavBar from './nav-bar/NavBar.js';
import TitleBar from './title-bar/TitleBar.js';
import s from './Header.module.css';
import { getBreadcrumbRootRoute } from '../../../utils/Breadcrumb.js';
import { isActive, handleSearchView } from './utils.js';
import { verifier } from 'utils/route-authorization';
import { withJanusUser, useFlipFlop } from '@mq/volt-amc-container';
import useHasRoleInArrayForActiveProgram from '../../../utils/helpers/useHasRoleInArrayForActiveProgram.js';
import withRouterGo from '../../../components/router-go/withRouterGo.js';
import PDHeader from '../../../views/reports/PDHeader.js';
import { UNIFY_BETA } from 'utils/route-authorization/routes/constants/featureFlags';
import currentUserStore from '../../../stores/CurrentUserStore.js';

function Header({ janusUser, go, routerStore }) {
  const flipFlop = useFlipFlop();
  const gqlClient = useApolloClient();

  const isGlobalIssueActive = flipFlop.get('active-issue-link', false);
  const newFeatureLink = flipFlop.get('new-feature-link', false);
  const isUnifyActive = flipFlop.get(UNIFY_BETA, false);
  const janusUserEmail = useMemo(() => {
    const { data: { email } = {} } = janusUser;
    return email;
  }, [janusUser]);
  const hasRoleInArrayForActiveProgram = useHasRoleInArrayForActiveProgram();
  const reskinLiteHeader = isUnifyActive ? s.reskinLite : '';

  const handleSearchViewWithGo = useCallback(() => handleSearchView({ go }), [go]);

  return (
    <header className={[s.header, reskinLiteHeader].join(' ')} data-testid="layout-header">
      {!isUnifyActive && <PDHeader />}
      <TitleBar
        getBreadcrumbRootRoute={getBreadcrumbRootRoute}
        gqlClient={gqlClient}
        handleSearchView={handleSearchViewWithGo()}
        isFeatureUnifyBetaActive={isUnifyActive}
        isGlobalIssueActive={isGlobalIssueActive}
        newFeatureLink={newFeatureLink}
        janusUserEmail={janusUserEmail}
        routerStore={routerStore}
        hasRoleInArrayForActiveProgram={hasRoleInArrayForActiveProgram}
        userStore={currentUserStore?.userStore}
      />
      {!isUnifyActive && <NavBar isActiveLink={isActive} verifier={verifier} />}
    </header>
  );
}

Header.propTypes = {};
Header.defaultProps = {};

export default withRouterGo(withJanusUser(Header));
