import { decorate, observable, runInAction, action } from 'mobx';
import gqlUtils from '@mqd/graphql-utils';
import { ParentStore } from '@mq/voltron-parent';
import ProgramGatewayFundingSourceStore from './ProgramGatewayFundingSourceStore';
import ProgramFundingSourceStore from './ProgramFundingSourceStore';
import VelocityControlStore from './VelocityControlStore';
import AuthControlStore from './AuthControlStore';

const { fragments } = gqlUtils;

class CommandoModeStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
  }
  // values
  token: string = '';
  enabled: boolean = false;
  enabled_reason: string = '';
  enabled_channel: string = '';
  enabled_username: string = '';
  ignore_card_suspended_state: boolean = false;
  real_time_standin_enabled: boolean = false;
  real_time_standin_include_connection_errors: boolean = false;
  real_time_standin_inclue_response_timeouts: boolean = false;
  real_time_standin_include_application_errors: boolean = false;
  created_time: string = '';
  last_modified_time: string = '';
  // objects
  program_gateway_funding_source: ProgramGatewayFundingSourceStore = {};
  program_funding_source: ProgramFundingSourceStore = {};
  velocity_controls: [VelocityControlStore] = [];
  auth_controls: [AuthControlStore] = [];

  // actions
  async hydrate() {
    this.loading = true;
    const result = await this.gqlQuery(
      ` query commandoMode${this.outerQueryParams || '($token: ID!)'} {
          commandoMode${this.innerQueryParams || '(token: $token)'} {
            ...commandoModeBaseInfo
            program_gateway_funding_source {
              ...programGatewayFundingSourceBaseInfo
            }
            program_funding_source {
              ...programFundingSourceBaseInfo
            }
            velocity_controls {
              ...velocityControlBaseInfo
              association {
                ...associationBaseInfo
              }
              merchant_scope {
                ...merchantScopeBaseInfo
              }
            }
            auth_controls {
              ...authControlBaseInfo
              association {
                ...associationBaseInfo
              }
              merchant_scope {
                ...merchantScopeBaseInfo
              }
            }
          }
        }
        ${fragments.commandoModeBaseInfo}
        ${fragments.programGatewayFundingSourceBaseInfo}
        ${fragments.programFundingSourceBaseInfo}
        ${fragments.velocityControlBaseInfo}
        ${fragments.authControlBaseInfo}
        ${fragments.associationBaseInfo}
        ${fragments.merchantScopeBaseInfo}
      `,
      this.hydrateParams
    );
    runInAction(() => {
      this.loading = false;
      const commandoModeData = this.extract(result, 'commandoMode');
      this.load(commandoModeData);
      const {
        program_gateway_funding_source,
        program_funding_source,
        velocity_controls,
        auth_controls,
      } = commandoModeData;
      this.loadAndConstructItem(
        'program_gateway_funding_source',
        program_gateway_funding_source,
        ProgramGatewayFundingSourceStore
      );
      this.loadAndConstructItem(
        'program_funding_source',
        program_funding_source,
        ProgramFundingSourceStore
      );
      this.loadAndConstructList('velocity_controls', velocity_controls, VelocityControlStore);
      this.loadAndConstructList('auth_controls', auth_controls, AuthControlStore);
    });
  }

  async transition(args) {
    this.loading = true;
    const result = await this.gqlMutation(
      `
        mutation transitionCommandoMode(
          $token: ID!
          $commando_enabled: Boolean
          $reason: String
          $username: String
        ) {
          transitionCommandoMode(
            token: $token
            commando_enabled: $commando_enabled
            reason: $reason
            username: $username
          ) {
            enabled
            enabled_reason
            enabled_channel
            enabled_username
          }
        }
      `,
      { ...args, token: this.token }
    );
    runInAction(() => {
      this.loading = false;
      const commandoModeData = this.extract(result, 'transitionCommandoMode');
      this.load(commandoModeData);
    });
  }
}

decorate(CommandoModeStore, {
  // values
  token: observable,
  enabled: observable,
  enabled_reason: observable,
  enabled_channel: observable,
  enabled_username: observable,
  ignore_card_suspended_state: observable,
  real_time_standin_enabled: observable,
  real_time_standin_include_connection_errors: observable,
  real_time_standin_inclue_response_timeouts: observable,
  real_time_standin_include_application_errors: observable,
  created_time: observable,
  last_modified_time: observable,
  // objects
  program_gateway_funding_source: observable,
  program_funding_source: observable,
  velocity_controls: observable,
  auth_controls: observable,

  // actions
  transition: action.bound,
});

export default CommandoModeStore;
