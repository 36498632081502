import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import AuditLogsTableStore from './AuditLogsTableStore';
import { ParentTable } from '@mq/voltron-parent';
import { Table, clearColumnConfig } from '@mq/voltron-table';
import { LoadingOverlay, Tooltip, VSpacer } from '@mqd/volt-base';
import { filterOptions } from './constants';
import StyleContext from '../contexts/StyleContext';
import { downloadBlob } from 'utils';
import currentUserStore from 'stores/CurrentUserStore';

class AuditLogsTable extends ParentTable {
  constructor(props) {
    super(props);
    this.storeConstructor = AuditLogsTableStore;
    this.state = this.colEditState(props, this.columns());
  }

  columns() {
    return [
      {
        header: 'id',
        accessor: 'id',
        key: 'id',
        primaryKey: true,
      },
      {
        header: 'User Name',
        accessor: 'user_name',
        key: 'user_name',
      },
      {
        header: 'App Name',
        accessor: 'app_name',
        key: 'app_name',
      },
      {
        header: 'Record Id',
        accessor: 'record_id',
        key: 'record_id',
      },
      {
        header: 'Record Operation',
        accessor: 'record_operation',
        key: 'record_operation',
      },
      {
        header: 'Record Type',
        accessor: 'record_type',
        key: 'record_type',
      },
      {
        header: 'Program',
        accessor: 'program',
        key: 'program',
      },
      {
        header: 'Created Time',
        accessor: 'created_time',
        key: 'created_time',
      },
      {
        header: 'Remote Ip Address',
        accessor: 'remote_ip_address',
        key: 'remote_ip_address',
      },
      {
        header: 'User Role',
        accessor: 'user_role',
        key: 'user_role',
      },
      // // extended
      {
        header: 'Http Status Code',
        accessor: 'http_status_code',
        key: 'http_status_code',
        hide: true,
      },
      {
        header: 'Http Params',
        accessor: 'http_params',
        key: 'http_params',
        hide: true,
      },
      {
        header: 'Event Type',
        accessor: 'event_type',
        key: 'event_type',
        hide: true,
      },
      {
        header: 'App Server Name',
        accessor: 'app_server_name',
        key: 'app_server_name',
        hide: true,
      },
      {
        header: 'Http Path',
        accessor: 'http_path',
        key: 'http_path',
        hide: true,
      },
      {
        header: 'Http Method',
        accessor: 'http_method',
        key: 'http_method',
        hide: true,
      },
      {
        header: 'Old Value',
        accessor: 'old_value',
        key: 'old_value',
        hide: true,
      },
      {
        header: 'New Value',
        accessor: 'new_value',
        key: 'new_value',
        hide: true,
      },
      {
        header: 'Notes',
        accessor: 'notes',
        key: 'notes',
        hide: true,
      },
      {
        header: 'Extra Info',
        accessor: 'extra_info',
        key: 'extra_info',
        hide: true,
      },
    ];
  }

  convertArrayOfObjectsToCSV = () => {
    const { currentList } = this.store;
    const { columns } = this.state;

    const headerRow = columns.map((header) => header.header);
    const extractData = (row) =>
      columns.map((header) => {
        let value = row[header.accessor];
        if (typeof value === 'string' && (value.indexOf(',') !== -1 || value.indexOf('"') !== -1)) {
          value = value.replace(/"/g, '""');
          value = `"${value}"`;
        }
        return value;
      });

    const csvRows = [headerRow, ...currentList.map((row) => extractData(row))];

    const csvContent = csvRows.map((row) => row.join(',')).join('\n');
    return csvContent;
  };

  getheaderActions = () => {
    const { localStorageKey } = this.props;
    const programShortCode = currentUserStore.userStore.activeProgramShortCode;
    return [
      {
        text: 'Modify columns',
        onClick: () => this.setState({ showColumnEditor: true }),
      },
      {
        text: 'Reset table to default',
        onClick: () => {
          clearColumnConfig(localStorageKey);
          window.location.reload();
        },
      },
      {
        text: (
          <Tooltip
            content={'Download will only include results from the current page'}
            direction="bottomLeft"
          >
            Download as csv
          </Tooltip>
        ),
        onClick: () => {
          downloadBlob(
            this.convertArrayOfObjectsToCSV(),
            `Auditlogs-${programShortCode}-${Date.now()}`,
            'text/csv'
          );
        },
      },
    ];
  };
  render() {
    const { onRowClick, loading: detailLoading, calculateTableHeight } = this.props;
    const { currentList, activeSort, activeRow, setAttr, loading, updateKey } = this.store;
    const { columns, tableKey } = this.state;
    const tablePaddingBottom = calculateTableHeight(this.store);

    return (
      <div style={{ width: '100%' }} data-testid="audit-log-table-wrapper">
        {this.renderColEditTableHeader({
          filterOptions,
          tableFilterTestId: 'audit-logs-table-header-buttons',
        })}
        <VSpacer />
        <StyleContext.Consumer>
          {({ height }) => {
            return (
              <LoadingOverlay active={loading || detailLoading}>
                <Table
                  key={tableKey}
                  loading={loading}
                  height={height + tablePaddingBottom || this.dynamicHeight}
                  data={currentList}
                  columns={columns}
                  fixedColumnCount={1}
                  rightAlignFixedColumns={true}
                  sort={activeSort}
                  activeRow={activeRow}
                  onRowClick={onRowClick}
                  onSort={(newSort) => setAttr('activeSort', newSort)}
                  scrollToTopUpdateKey={updateKey}
                  handleColSizeChange={this.handleColSizeChange.bind(this)}
                  handleResetTable={this.clearFilterFunc}
                  headerActions={this.getheaderActions()}
                />
              </LoadingOverlay>
            );
          }}
        </StyleContext.Consumer>
        {this.renderPagination()}
      </div>
    );
  }
}

AuditLogsTable.propTypes = {
  height: PropTypes.number,
  storeInitArgs: PropTypes.object,
  store: PropTypes.object,
  userStore: PropTypes.object,
  autoHydrate: PropTypes.bool,
  onStoreConstruction: PropTypes.func,
  gqlClient: PropTypes.any,
  title: PropTypes.any,
  onRowClick: PropTypes.func,
  localStorageKey: PropTypes.string,
  calculateTableHeight: PropTypes.func,
};

AuditLogsTable.defaultProps = {
  height: 800,
  storeInitArgs: { limit: 100 },
  store: null,
  userStore: {},
  autoHydrate: true,
  onStoreConstruction: null,
  gqlClient: null,
  title: 'Audit logs',
  onRowClick: null,
  localStorageKey: 'AuditLogsTableConfig',
  calculateTableHeight: () => 0,
};

export default observer(AuditLogsTable);
