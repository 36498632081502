import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { ParentTable, Status } from '@mq/voltron-parent';
import { Table, ActionsCell, ActionsHeader, ActionPlaceholder } from '@mq/voltron-table';
import { LoadingOverlay, ToastAlert } from '@mqd/volt-base';
import StyleContext from '../contexts/StyleContext';
import AccountsTableStore from './AccountsTableStore';
import ChangeAccountStatusModal from './ChangeAccountStatusModal';
import capitalize from '../../../../utils/helpers/capitalize';

class AccountsTable extends ParentTable {
  constructor(props) {
    super(props);
    this.storeConstructor = AccountsTableStore;
    this.actionsRenderer = this.actionsRenderer.bind(this);
    this.state = {
      showChangeStatusModal: false,
      changeStatusModalDefault: null,
      activeStore: null,
      toast: {
        toastMessage: '',
        icon: '',
      },
      ...this.colEditState(props, this.columns()),
    };
  }

  columns() {
    const { canTransitionAccount } = this.store;

    const columns = [
      { header: 'Status', accessor: 'state', key: 'status', renderer: this.statusRenderer() },
      {
        header: 'Account Number',
        accessor: 'account_number',
        key: 'account_number',
        renderer: this.accountNumberRenderer(),
      },
      { header: 'Routing Number', accessor: 'routing_number', key: 'routing_number' },
      { header: 'Creation Date', accessor: 'created_time', key: 'created_time' },
      { header: 'Account Token', accessor: 'token', key: 'token', primaryKey: true },
    ];

    if (canTransitionAccount) {
      columns.unshift({
        header: null,
        key: 'actions',
        renderer: this.actionsRenderer,
        noPadding: true,
        noResize: true,
        noRowClick: true,
        noSort: true,
        noEdit: true,
        deferRenderPlaceholder: () => <ActionPlaceholder width={32} />,
      });
    }
    return columns;
  }

  accountNumberRenderer() {
    return (cell) => {
      cell.style.display = 'grid';
      return '**' + cell.cellValue.slice(cell.cellValue.length - 4);
    };
  }

  statusRenderer() {
    return ({ cellValue }) => {
      return (
        <div>
          <Status status={cellValue} />
        </div>
      );
    };
  }

  actionsRenderer({ row }) {
    const actions = [
      ...(!row.statusTransitionActions || row.statusTransitionActions.length < 1
        ? [
            {
              text: 'No options available',
              disabled: true,
            },
          ]
        : row.statusTransitionActions.map((action) => {
            return {
              text: action.text,
              onClick: () => {
                this.setState({
                  activeStore: row,
                  showChangeStatusModal: true,
                  changeStatusModalDefault: action.defaultStatus,
                });
              },
            };
          })),
    ];
    return <ActionsCell actions={actions} />;
  }

  headerRenderer() {
    return <ActionsHeader />;
  }

  renderChangeStatusModal() {
    const { showChangeStatusModal, activeStore, changeStatusModalDefault } = this.state;

    if (!showChangeStatusModal) return null;
    return (
      <ChangeAccountStatusModal
        hideModal={() => this.setState({ showChangeStatusModal: false })}
        store={activeStore}
        onError={() => {
          this.setState({
            ...this.state,
            toast: {
              toastMessage: `Account transition failed!`,
              icon: 'error',
            }
          })
        }}
        onComplete={(status) => {
          this.setState({
            ...this.state,
            toast: {
              toastMessage: `Successfully updated account status to ${capitalize(
                status.toLocaleLowerCase()
              )}.`,
              icon: 'success',
            }
          });
        }}
        defaultStatus={changeStatusModalDefault}
      />
    );
  }

  render() {
    const {
      currentList,
      activeSort,
      activeRow,
      setAttr,
      updateKey,
      loading,
      canTransitionAccount,
    } = this.store;
    const { onRowClick } = this.props;

    const { columns, tableKey, toast } = this.state;
    const pageSize = this.store.count;
    const tablePaddingBottom = currentList.length >= pageSize ? 0 : 78;
    return (
      <div style={{ width: '100%' }}>
        {this.renderChangeStatusModal()}
        <StyleContext.Consumer>
          {({ height }) => {
            return (
              <LoadingOverlay active={loading}>
                <Table
                  key={tableKey}
                  loading={loading}
                  height={height + tablePaddingBottom || this.dynamicHeight}
                  data={currentList}
                  columns={columns}
                  fixedColumnCount={canTransitionAccount ? 1 : 0}
                  rightAlignFixedColumns={true}
                  sort={activeSort}
                  activeRow={activeRow}
                  onSort={(newSort) => {
                    setAttr('activeSort', newSort);
                  }}
                  scrollToTopUpdateKey={updateKey}
                  handleColSizeChange={this.handleColSizeChange.bind(this)}
                  onRowClick={onRowClick}
                />
              </LoadingOverlay>
            );
          }}
        </StyleContext.Consumer>
        {toast.toastMessage && (
          <ToastAlert
            icon={toast.icon}
            dismissTime="3"
            action=""
            remove={()=>{
              this.setState({
                ...this.state,
                toast: {
                  toastMessage: '',
                  icon: '',
                }
              });
            }}
          >
            {toast.toastMessage}
          </ToastAlert>
        )}
      </div>
    );
  }
}

AccountsTable.propTypes = {
  height: PropTypes.number,
  storeInitArgs: PropTypes.object,
  store: PropTypes.object,
  userStore: PropTypes.object,
  autoHydrate: PropTypes.bool,
  onStoreConstruction: PropTypes.func,
  gqlClient: PropTypes.any,
  localStorageKey: PropTypes.string,
  onRowClick: PropTypes.func,
};

AccountsTable.defaultProps = {
  height: 800,
  storeInitArgs: {
    limit: 100,
  },
  store: null,
  userStore: {},
  autoHydrate: true,
  onStoreConstruction: null,
  gqlClient: null,
  localStorageKey: 'AccountsTableConfig',
};

export default observer(AccountsTable);
