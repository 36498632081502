import { v4 as uuidv4 } from 'uuid';

export const VIOLATION_LEVEL = {
  WARN: 0,
  ERROR: 1,
};

export const VALIDATION_ERROR = {
  PROGRAM_REQUIRED: 0,
  PROGRAM_NOT_FOUND: 1,
  AMOUNT_REQUIRED: 2,
  AMOUNT_INVALID: 3,
  MISSING_CURRENCY: 4,
  PROGRAM_DUPLICATE: 5,
};

export class ValidationError {
  id = uuidv4();

  constructor(level, message) {
    this.level = level;
    this.code = message;
  }
}
