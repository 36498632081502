import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Colors, HSpacer, Icon, Text, Flex } from '@mqd/volt-base';
import { kycStatuses } from './constants';
import s from './kycstatus.module.css';

class KycStatus extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { status, showText } = this.props;
    const fillColor =
      Colors[kycStatuses[status] && kycStatuses[status].color] || 'statusPendingColor';
    const icon = (kycStatuses[status] && kycStatuses[status].icon) || 'clock';
    const statusText = (kycStatuses[status] && kycStatuses[status].label) || 'Pending';
    return (
      <Flex testId="review-status-row" display="flex" flexDirection="row" alignItems="center">
        <Icon type={icon} overrideColor={fillColor} noHoverEffects={true} />
        <HSpacer factor={0.5} />
        {showText && <Text type="body-sm">{statusText}</Text>}
      </Flex>
    );
  }
}

KycStatus.propTypes = {
  status: PropTypes.string,
  showText: PropTypes.bool,
};
KycStatus.defaultProps = {
  status: '',
  showText: true,
};

export default observer(KycStatus);
