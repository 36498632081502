import { useCallback, useMemo } from 'react';
import { useRouterGoContext } from '../../../components/router-go/context.js';
import {
  urlParamsToObject,
  pluckKeysFromObject,
  readObjValuesFromJson,
  pruneUrlParams,
  convertObjValuesToJson,
} from '../utilities/url-params/index.js';
import useFetch from './useFetch.js';

/**
 * Wraps useFetch hook to add and remove URL parameters
 * @param {String} endpoint - Endpoint to fetch, exluding query variables
 * @param {Object} options - Options passed to useFetch
 * @param {String} options.method - HTTP method used for the fetch
 * @param {Object} options.body - Body sent during the fetch
 * @param {Array}  options.query - Query variables used to form query string
 * @param {Array}  urlKeys - Array of keys to be tracked in the URL
 * @param {String} location - Where to merge the url keys: either 'body' or 'query'
 */
export default function useFetchUrlParams(endpoint, options, { urlKeys = [], location = 'query' }) {
  const go = useRouterGoContext();
  const params = urlParamsToObject();

  const pluckedKeys = useMemo(() => pluckKeysFromObject(params, urlKeys), [params, urlKeys]);

  const urlParamsForQuery = useMemo(() => readObjValuesFromJson(pluckedKeys), [pluckedKeys]);

  const fetchQuery = useMemo(() => {
    let vars = { ...options.query };
    if (location === 'query') {
      vars = { ...urlParamsForQuery, ...vars };
    }
    return vars;
  }, [options.query, urlParamsForQuery, location]);

  const fetchBody = useMemo(() => {
    let body = options.body ? { ...options.body } : undefined;
    if (location === 'body') {
      body = { ...urlParamsForQuery, ...body };
    }
    return body;
  }, [options.body, urlParamsForQuery, location]);

  const { data, loading, error, refetch, body, query } = useFetch(endpoint, {
    ...options,
    query: fetchQuery,
    body: fetchBody,
  });

  const refetchAndPersistVariables = useCallback(
    ({ query, body }) => {
      if (!query && !body) {
        // Simple refresh, no variable changes.
        return refetch();
      }

      const prunedUrlParams = pruneUrlParams(urlKeys);
      const pluckedKeys = {
        ...pluckKeysFromObject(query || {}, urlKeys),
        ...pluckKeysFromObject(body || {}, urlKeys),
      };
      const newUrlParams = convertObjValuesToJson(pluckedKeys);

      const urlParams = {
        ...prunedUrlParams,
        ...newUrlParams,
      };

      go(null, urlParams, { replaceState: true });

      return refetch({ query: query, body });
    },
    [urlKeys, go, refetch]
  );

  return useMemo(
    () => ({
      data,
      loading,
      error,
      refetch: refetchAndPersistVariables,
      body,
      query,
    }),
    [data, error, loading, refetchAndPersistVariables, body, query]
  );
}
