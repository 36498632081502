import currentUserStore from 'stores/CurrentUserStore';
import { FlipFlop } from 'utils';

const requiredUserPermissions = ['risk-internal'];

export const requirements = {
  flipFlopRequirements: ['bank-reporting'],
  requiredUserPermissions,
};

export const verifier = () => {
  const {
    userStore: { canAccessDisputes },
  } = currentUserStore;
  const hasFeatureFlag = FlipFlop.get('bank-reporting', false);
  return canAccessDisputes && hasFeatureFlag;
};
