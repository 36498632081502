import currentUserStore from '../../stores/CurrentUserStore.js';
import { UAM_GRANULAR_PERMISSIONS } from '@mqd/mqd-constants';

// Routes
const mccGroupsRoutes = [
  {
    path: '/mcc-groups',
    name: 'MCC Groups',
    importer: () => import(/*webpackChunkName: "MCC Groups" */ './MCCGroups.js'),
  },
  {
    path: '/mcc-groups/new',
    name: 'New MCC Group',
    importer: () => import(/*webpackChunkName: "New MCC Group" */ './NewMCCGroup.js'),
  },
  {
    path: '/mcc-groups/:token',
    name: 'MCC Group',
    importer: () => import(/*webpackChunkName: "MCC Group" */ './MCCGroup.js'),
  },
];

// Authorization
const defaultRequirements = {
  flipFlopRequirements: ['mcc-groups'],
  requiredGranularPermissions: [UAM_GRANULAR_PERMISSIONS.PROGRAM_CONFIGURATION_VIEW_AND_EDIT],
};

const defaultVerifier = () => {
  return currentUserStore.userStore.mccGroupsActive;
};

const requirements = {
  mccGroup: defaultRequirements,
  mccGroups: defaultRequirements,
  newMccGroup: defaultRequirements,
};

const verifiers = {
  mccGroup: defaultVerifier,
  mccGroups: defaultVerifier,
  newMccGroup: defaultVerifier,
};

export { requirements, verifiers };
export default mccGroupsRoutes;
