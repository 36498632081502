// @flow
import { decorate, action, observable } from 'mobx';

class LoadingStore {
  isLoading: Boolean = false;
  message: string = '';

  startLoading(message = ''): void {
    this.isLoading = true;
    this.message = message;
  }

  stopLoading(): void {
    this.isLoading = false;
    this.message = '';
  }
}

decorate(LoadingStore, {
  isLoading: observable,
  message: observable,
  startLoading: action.bound,
  stopLoading: action.bound,
});

const loadingStore = new LoadingStore();
export default loadingStore;
