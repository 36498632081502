import currentUserStore from 'stores/CurrentUserStore';
import { UAM_ADD_USER_PERMISSIONS } from './constants';

const adminUserDetailsRoutes = [
  {
    path: '/admin/user-details/new',
    name: 'Admin Create User',
    importer: () =>
      import(/*webpackChunkName: "Program Config Request Review" */ './AdminCreateNewUser.js'),
  },
];

export const defaultRequirements = {
  requiredGranularPermissions: UAM_ADD_USER_PERMISSIONS,
};

const defaultVerifier = () => {
  const {
    userStore: { uamGranularPermissionsActive, hasRequiredUamPermissions },
  } = currentUserStore;
  const hasAccessMgmtEdit = hasRequiredUamPermissions(UAM_ADD_USER_PERMISSIONS);
  return uamGranularPermissionsActive && hasAccessMgmtEdit;
};

const verifiers = {
  adminCreateUser: defaultVerifier,
};

const requirements = {
  adminCreateUser: defaultRequirements,
};

export { verifiers, requirements };
export default adminUserDetailsRoutes;
