import React, { useState } from 'react';
import { BannerAlert, Icon, Link, Text, VSpacer } from '@mqd/volt-base';

const DismissableBanner = ({
  LOCAL_STORAGE_KEY,
  icon,
  heading,
  description,
  linkURL,
  linkText,
}) => {
  const [showBanner, setShowBanner] = useState(!hasDismissedBanner());

  function hasDismissedBanner() {
    return localStorage.getItem(LOCAL_STORAGE_KEY);
  }

  function storeDismissedBannerRecord() {
    localStorage.setItem(LOCAL_STORAGE_KEY, true);
  }

  const dismissBanner = () => {
    storeDismissedBannerRecord();
    setTimeout(() => setShowBanner(false), 500);
  };

  return (
    <>
      {showBanner && (
        <>
          <BannerAlert
            size="lg"
            type="cta"
            heading={heading}
            disposable={true}
            onHidden={dismissBanner}
            linkComponent={
              <Link href={linkURL} external newTab inline>
                {linkText}
              </Link>
            }
            iconComponent={
              <div style={{ margin: 12 }}>
                <Icon type={icon} factor={4} />
              </div>
            }
          >
            <div style={{ maxWidth: 640 }}>
              <Text type="body-sm">{description}</Text>
            </div>
          </BannerAlert>
          <VSpacer factor={3} inline={true} testId="volt-library-hspacer" />
        </>
      )}
    </>
  );
};

export default DismissableBanner;
