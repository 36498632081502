const exceptionStatuses = {
  ADJUSTMENT_FAILED_RECONCILIATION: 'Failed',
  ADJUSTMENT_PENDING_APPROVAL: 'Pending approval',
  ADJUSTMENT_REJECTED: 'Rejected',
  FAILED_BATCH_SUBMISSION: 'Retry',
  FAILED_BATCH_APPROVAL: 'Failed submission',
  AWAITING_ACTION: 'Ready',
  COMPLETE: 'Complete',
  IN_PROGRESS: 'In progress',
  MATCHING: 'Matching',
  PARTIALLY_RECONCILED: 'Partially reconciled',
  READY: 'Ready',
  WAITING: 'Waiting',
};

export default exceptionStatuses;
