import { decorate, observable, action, computed } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';
import S3MetadataStore from './S3MetadataStore';
import moment from 'moment';

class S3BatchObjectStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.loadAndConstruct(args);
  }
  // store conifg
  skipAutoLoad: boolean = true;

  // values
  signedUrl: string = '';
  Bucket: string = '';
  Key: string = '';
  LastModified: string = '';
  ContentType: string = '';
  Metadata: S3MetadataStore = {};

  loadAndConstruct(args = {}) {
    const { Metadata } = args;
    this.load(args);
    this.loadAndConstructItem('Metadata', Metadata, S3MetadataStore);
  }

  // computed
  get responseStatus() {
    const status = this.dig(this.Metadata, 'response', 'description');
    if (['PARTIAL', 'SUCCESS'].includes(status.toUpperCase())) {
      return status;
    }
    return 'Error';
  }

  get responseDescription() {
    return this.dig(this.Metadata, 'response', 'description');
  }

  get csvEntries() {
    return this.dig(this.Metadata, 'response', 'formattedProcessedResources');
  }

  // eslint-disable-next-line getter-return
  get formattedLastModified() {
    try {
      const milliseconds = parseInt(this.LastModified);
      return new moment(milliseconds).utc().format('YYYY-MM-DD');
    } catch (err) {
      console.error(`Error formatting S3 timestamp: ${err}`);
    }
  }
}

decorate(S3BatchObjectStore, {
  skipAutoLoad: observable,
  // values
  signedUrl: observable,
  Bucket: observable,
  Key: observable,
  LastModified: observable,
  ContentType: observable,
  Metadata: observable,

  // actions
  loadAndConstruct: action.bound,

  // computed
  responseStatus: computed,
  responseDescription: computed,
  csvEntries: computed,
  formattedLastModified: computed,
});

export default S3BatchObjectStore;
