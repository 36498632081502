import React, { useEffect, useState } from 'react';
import {
  VSpacer,
  Form,
  Input,
  Select,
  ErrorModal,
  DetailPane,
  Text,
  PhoneInput,
} from '@mqd/volt-base';
import { ButtonsGroup } from '../form-helpers';
import { stateOptions, ERROR_MESSAGE_ACCOUNT_HOLDER_UPDATE } from '../constants';
import { errorMessage, detailsFormValues } from '../utils';
import { useCardholderInfoContext, TOGGLE_EDIT_DETAILS } from '../context';

const EditDetails = ({ data = {}, onSave, error, submitting }) => {
  const { dispatch } = useCardholderInfoContext();
  const [showModal, setShowModal] = useState(false);
  const [submitted, setSubmitted] = useState(false); // Boolean of whether onSave call was attempted + complete

  useEffect(() => {
    if (submitted && !error && !submitting) {
      dispatch({ type: TOGGLE_EDIT_DETAILS });
    } else if (submitted && error && !submitting) {
      setShowModal(true);
    }
  }, [submitted, error, submitting]);

  const token = data && data.token;
  const metadata = (data && data.metadataObject) || {};

  const handleCancel = () => {
    dispatch({ type: TOGGLE_EDIT_DETAILS });
  };

  const saveForm = async (values) => {
    setSubmitted(false);

    const metadataObj = {};
    Object.keys(metadata).forEach((key) => {
      metadataObj[key] = values[key];
    });

    values.metadata = JSON.stringify(metadataObj);

    await onSave({
      token,
      ...detailsFormValues(values),
    });

    setSubmitted(true);
  };

  const initialFormState = {
    ...detailsFormValues(data),
    ...metadata,
  };

  const metadataArr = [];
  for (const key in metadata) {
    const value = metadata[key];
    metadataArr.push(
      <DetailPane.Content label={key} formElementId={key}>
        <Input name={key} id={key} maxLength={40} />
      </DetailPane.Content>
    );
  }

  return (
    <>
      {showModal && (
        <ErrorModal
          heading={errorMessage(error || ERROR_MESSAGE_ACCOUNT_HOLDER_UPDATE)}
          hideModal={() => setShowModal(false)}
        >
          Try again
        </ErrorModal>
      )}
      <Form initialState={initialFormState}>
        <DetailPane.default layout="row">
          <DetailPane.Content label="First name" formElementId="first_name">
            <Input name="first_name" id="first_name" required maxLength={40} />
          </DetailPane.Content>
          <DetailPane.Content label="Middle name (optional)" formElementId="middle_name">
            <Input name="middle_name" id="middle_name" maxLength={40} />
          </DetailPane.Content>
          <DetailPane.Content label="Last name" formElementId="last_name">
            <Input name="last_name" id="last_name" required maxLength={40} />
          </DetailPane.Content>
          <DetailPane.Content label="Address 1" formElementId="address1">
            <Input name="address1" id="address1" required maxLength={255} />
          </DetailPane.Content>
          <DetailPane.Content label="Address 2 (optional)" formElementId="address2">
            <Input name="address2" id="address2" maxLength={255} />
          </DetailPane.Content>
          <DetailPane.Content label="City" formElementId="city">
            <Input name="city" id="city" required maxLength={40} />
          </DetailPane.Content>
          <DetailPane.Content label="State" formElementId="state">
            <Select
              required
              name="state"
              id="state"
              sortOptions
              showSearch
              options={stateOptions}
            />
          </DetailPane.Content>
          <DetailPane.Content label="Postal code" formElementId="postal_code">
            <Input name="postal_code" id="postal_code" required maxLength={10} />
          </DetailPane.Content>
          <DetailPane.Content label="Country" formElementId="country">
            <Input name="country" id="country" required maxLength={10} />
          </DetailPane.Content>
          <DetailPane.Content label="Email" formElementId="email">
            <Input
              name="email"
              id="email"
              required
              maxLength={255}
              errorValidators={[
                {
                  validator: (val) => {
                    const email = /^.+@.+\..+$/.test(val);
                    return !email;
                  },
                  message: 'Invalid email.',
                },
              ]}
            />
          </DetailPane.Content>
          <DetailPane.Content label="Phone" formElementId="phone">
            <PhoneInput
              width="100%"
              name="phone"
              id="phone"
              required
              maxLength={20}
              countryCodeTestId="cardholder-info-phone-code-select"
            />
          </DetailPane.Content>
          <DetailPane.Content label="Birth date (optional)" formElementId="birth_date">
            <Input
              type="date"
              name="birth_date"
              id="birth_date"
              errorValidators={[
                {
                  validator: (val) => {
                    const currentDate = new Date();
                    return new Date(val) >= currentDate;
                  },
                  message: 'Invalid date.',
                },
              ]}
            />
          </DetailPane.Content>
          <DetailPane.Content label="Nationality (optional)" formElementId="nationality">
            <Input name="nationality" id="nationality" maxLength={255} />
          </DetailPane.Content>
          <DetailPane.Content label="Company (optional)" formElementId="company">
            <Input name="company" id="company" maxLength={255} />
          </DetailPane.Content>
          <DetailPane.Content label="Honorific (optional)" formElementId="honorific">
            <Input name="honorific" id="honorific" maxLength={255} />
          </DetailPane.Content>
        </DetailPane.default>
        <VSpacer factor={2} />
        {metadataArr.length > 0 && (
          <>
            <Text type="h5">Metadata</Text>
            <VSpacer factor={2} />
            <DetailPane.default layout="row">{metadataArr}</DetailPane.default>
          </>
        )}
        <VSpacer factor={2} />
        {ButtonsGroup(submitting, handleCancel, saveForm)}
      </Form>
    </>
  );
};

export default EditDetails;
