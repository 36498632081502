import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import { Button, Flex, Form, HSpacer, Input, Modal, ToastAlert, VSpacer } from '@mqd/volt-base';
import { confirmPinValidators, pinValidators } from './validators';

const SetPinModal = memo(({ hideModal, onSave, show }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const shouldDisable = (formState) =>
    !formState.allFieldsAreValid ||
    confirmPinValidators.match.validator(formState.confirmPin, formState) ||
    !formState.pin ||
    !formState.confirmPin;

  const handleFormChange = (formState) => {
    setDisabled(shouldDisable(formState));
    setSubmitted(formState.submitted);
  };

  const handleSave = async ({ pin }) => {
    try {
      setShowToast(false);
      setError(null);
      setLoading(true);
      await onSave({ pin });
      setShowToast(true);
      hideModal();
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    !show && setDisabled(true);
  }, [show]);

  return (
    <>
      {show && (
        <Modal
          heading="Set PIN"
          error={error}
          hideModal={hideModal}
          showHideModalButton={false}
          disableOverFlow
        >
          <Form initialState={{ allFieldsAreValid: false }} onFormChange={handleFormChange}>
            <Input
              name="pin"
              label="4-digit PIN"
              sublabel="PIN must be a 4-digit number."
              type="password"
              maxLength={4}
              errorValidators={Object.values(pinValidators)}
              required
            />
            <VSpacer factor={3} />
            <Input
              name="confirmPin"
              label="Confirm PIN"
              sublabel="PIN must be a 4-digit number."
              type="password"
              maxLength={4}
              errorValidators={Object.values(confirmPinValidators)}
              required
            />
            <VSpacer factor={3} />
            <Flex alignItems="center" justifyContent="flex-end">
              <Button onClick={hideModal} type="tertiary">
                Cancel
              </Button>
              <HSpacer factor={2} />
              <Button disabled={disabled} loading={loading} onClick={handleSave} submit>
                {submitted && error ? 'Try again' : 'Save'}
              </Button>
            </Flex>
          </Form>
        </Modal>
      )}
      {showToast && (
        <ToastAlert action={undefined} remove={() => setShowToast(false)}>
          PIN successfully set.
        </ToastAlert>
      )}
    </>
  );
});

SetPinModal.propTypes = {
  hideModal: PropTypes.func,
  onSave: PropTypes.func,
  show: PropTypes.bool,
};

SetPinModal.defaultProps = {
  hideModal: () => {},
  onSave: () => {},
  show: false,
};

export default SetPinModal;
