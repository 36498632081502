import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import s from '../CardCreationDetail.module.css';
import AddButton from '../commons/AddButton';
import VelocityControl from './VelocityControl';
import { Text, HSpacer, VSpacer, Radio, Hr } from '@mqd/volt-base';

function VelocityControls({ store }) {
  const {
    setAttr,
    singleUseSelected,
    presetSingleUse,
    formattedAmountAndUsageLimit,
    validateVelocityControlMaximums,
    addNewVelocityControl,
    removeVelocityControl,
    duplicateVelocityControl,
    targetValocityControls,
  } = store;

  const isSingleUse = presetSingleUse || singleUseSelected;
  const toggleUsageRadio = (isSingleUse) => {
    presetSingleUse ? null : setAttr('singleUseSelected', isSingleUse); // eslint-disable-line no-unused-expressions
  };

  return (
    <>
      <Text type="h6">Usage type</Text>
      <VSpacer factor={1} />
      <div className={s.flexDiv}>
        <Radio
          active={!isSingleUse}
          onClick={() => toggleUsageRadio(false)}
          type="default"
          disabled={presetSingleUse}
          testId="velocity-controls-form_multi-use"
          label="Multi use"
        />
        <HSpacer factor={3} />
        <Radio
          active={isSingleUse}
          onClick={() => toggleUsageRadio(true)}
          type="default"
          disabled={presetSingleUse}
          testId="velocity-controls-form_single-use"
          label="Single use"
        />
      </div>

      <VSpacer factor={2} />

      <Text type="h5">Velocity controls</Text>
      <Text type="footnote">{formattedAmountAndUsageLimit}</Text>
      <div>
        {targetValocityControls &&
          targetValocityControls.map((velocityControl, index) => (
            <Fragment key={index}>
              <VelocityControl
                validateVelocityControlMaximums={validateVelocityControlMaximums}
                velocityControl={velocityControl}
                isSingleUse={isSingleUse}
                removeVelocityControl={() => removeVelocityControl(index)}
                duplicateVelocityControl={() => duplicateVelocityControl(velocityControl)}
              />
              <Hr />
            </Fragment>
          ))}
      </div>
      <VSpacer factor={1} />
      <AddButton
        onClick={() => addNewVelocityControl(singleUseSelected)}
        testId="add-button_velocity-control"
      >
        Add velocity control
      </AddButton>
      <VSpacer factor={2} />
    </>
  );
}

VelocityControls.propTypes = {
  store: PropTypes.object,
};

VelocityControls.defaultProps = {
  store: {},
};

export default observer(VelocityControls);
