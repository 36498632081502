import ApplicationDataStore from 'stores/application-data/ApplicationDataStore';
import hasRoleForAtLeastOneProgram from '../../helpers/hasRoleForAtLeastOneProgram.js';
import hasRoleForSelectedProgram from '../../helpers/hasRoleForSelectedProgram.js';
import currentUserStore from 'stores/CurrentUserStore';
import { hasDeveloperRole, hasNoRoles, isJanusUser } from './functions.js';

export const requirements = {
  requiredProgramPermissions: ['ProgramBalance'],
  requiredUserPermissions: [
    'access-manager',
    'aux-access-pii',
    'aux-approver',
    'aux-approver-internal',
    'aux-invoke-commando-mode',
    'aux-invoke-commando-mode-internal',
    'aux-show-pan',
    'cardholder-support',
    'compliance-internal',
    'compliance-processor-only',
    'compliance-program-managed',
    'delivery-internal',
    'developer',
    'fulfillment-internal',
    'marqeta-admin-internal',
    'production-support-internal',
    'program-admin',
    'read-only',
    'risk-internal',
    'settlement-internal',
    'supplier-payments-manager',
  ],
};

const verifyRequirementsAgainstCallback = (cb) => {
  const { requiredUserPermissions } = requirements;
  return requiredUserPermissions.some((permission) => cb(permission));
};

export const verifier = () => {
  const hasAccessForAtLeastOneProgram = verifyRequirementsAgainstCallback(
    hasRoleForAtLeastOneProgram
  );
  const { userStore = {} } = currentUserStore;
  return (
    ((!hasNoRoles(userStore) && !hasDeveloperRole(userStore)) || isJanusUser(userStore)) &&
    hasAccessForAtLeastOneProgram
  );
};

export const programVerifier = () => {
  const { applicationData } = ApplicationDataStore;
  const programHasApplicationDataAccess = Boolean(applicationData['ProgramBalance']);
  const programHasPermissionsAccess = verifyRequirementsAgainstCallback(hasRoleForSelectedProgram);
  return programHasPermissionsAccess && programHasApplicationDataAccess;
};
