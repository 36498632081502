import React from 'react';
import PropTypes from 'prop-types';
import { is, isEmpty } from 'ramda';
import { isFunction } from '@utils/index.js';
import { Link, HoverCard, ProfileHoverCard } from '@mqd/volt-base';
import routerStore from '../../../stores/router/RouterStore.js';

const AccountholderHoverCard = ({
  accountholder,
  className,
  bottomLabel,
  bottomLabelHandler,
  displayTokenAsText,
} = {}) => {
  if (!accountholder || !is(Object, accountholder) || isEmpty(accountholder)) return null;

  const { first_name, last_name, phone, city, state, country, token } = accountholder;

  const name = first_name || last_name ? `${first_name || ''} ${last_name || ''}` : 'None';
  const location = [city, state, (country || '').includes('US') ? 'United States' : country]
    .filter((x) => x && is(String, x))
    .join(', ');

  // Default to program/accountholder if no bottomLabelHandler is passed
  const _bottomLabelHandler = () => routerStore.go('/program/accountholder', { params: { token } });
  const labelHandler = isFunction(bottomLabelHandler) ? bottomLabelHandler : _bottomLabelHandler;

  const HoverCardBody = () => (
    <ProfileHoverCard
      className={className}
      cardholder={name}
      phone={phone}
      location={location}
      bottomLabel={bottomLabel}
      bottomLabelHandler={labelHandler}
    />
  );

  return (
    <HoverCard body={<HoverCardBody />}>
      <Link inheritParentFont onClick={labelHandler}>
        {displayTokenAsText ? token : name}
      </Link>
    </HoverCard>
  );
};

AccountholderHoverCard.propTypes = {
  className: PropTypes.string,
  accountholder: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    phone: PropTypes.string,
    token: PropTypes.string,
  }),
  bottomLabel: PropTypes.string,
  bottomLabelHandler: PropTypes.func,
};

export default AccountholderHoverCard;
