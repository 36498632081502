import { UAM_GRANULAR_PERMISSIONS } from '@mqd/mqd-constants';
import currentUserStore from 'stores/CurrentUserStore';
import { FlipFlop } from '../../index.js';
import { isProgramConfigFlagEnabled } from './functions.js';

const UAMRoles = [UAM_GRANULAR_PERMISSIONS.REAL_TIME_DECISIONING_VIEW_AND_EDIT];

export const requirements = {
  flipFlopRequirements: ['rtd-iframe-beta'],
  programConfigRequirements: ['ENABLE_CONTROLPLANE_DECISION_REPORTS'],
  requiredGranularPermissions: UAMRoles,
};

export const verifier = () => {
  const { userStore = {} } = currentUserStore;
  const { uamGranularPermissionsActive, hasRequiredUamPermissions } = userStore;

  const hasUAMAccess = uamGranularPermissionsActive && hasRequiredUamPermissions(UAMRoles);

  return (
    (hasUAMAccess || FlipFlop.get('rtd-iframe-beta', false)) &&
    isProgramConfigFlagEnabled('ENABLE_CONTROLPLANE_DECISION_REPORTS')
  );
};
