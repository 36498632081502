import React from 'react';

import { Card, Text, VSpacer, HSpacer, Button, Icon } from '@mqd/volt-base';
import CreditCapabilities from 'views/credit/CreditCapabilities/index.js';
import { EMPTY_DASHBOARD, CREDIT_TYPES } from 'views/credit/constants.js';

import s from './EmptyDashboard.module.css';

export default function EmptyDashboard({
  productType,
  loading,
  btnClickHandler,
  showTitle = true,
  containsActiveProduct,
  children,
}) {
  const styles = {
    height: '515px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  if (loading) {
    styles.background = 'transparent';
  }

  const SubHeading = () => {
    let subHeading = '';

    if (CreditCapabilities.canApproveProducts())
      subHeading = EMPTY_DASHBOARD.CP_APPROVER_SUBHEADING;

    if (CreditCapabilities.canViewExtendedPII()) subHeading = EMPTY_DASHBOARD.CP_SUPPORT_SUBHEADING;

    if (CreditCapabilities.canCreateProducts())
      subHeading = (
        <div>
          <VSpacer />
          <Button onClick={btnClickHandler}>
            <Icon
              type="plus"
              mod="reversed"
              factor={1}
              noHoverEffects
              style={{ paddingTop: '2px', marginRight: '8px' }}
            />
            {EMPTY_DASHBOARD.CP_BTN_TEXT}
          </Button>
        </div>
      );

    return subHeading;
  };

  const renderForCreditProductsPage = () => {
    return (
      <>
        <div className={s.iconContainer}>
          <Icon type="credit-empty" noHoverEffects />
        </div>
        <VSpacer factor={2} />
        <Text type="h3">{EMPTY_DASHBOARD.CP_HEADING}</Text>
        <div className={s.subHeading}>
          <VSpacer />
          <Text type="body-sm">
            <SubHeading />
          </Text>
        </div>
      </>
    );
  };

  const renderForOffersPageNonACMEUsers = () => {
    if (CreditCapabilities.canCreateOffers()) {
      if (containsActiveProduct) {
        return (
          <>
            <Text type="h3">{EMPTY_DASHBOARD.AO_HEADING}</Text>
            <div className={s.subHeading}>
              <VSpacer />
              <Text type="body-lg">{EMPTY_DASHBOARD.AO_SUBHEADING}</Text>
            </div>
          </>
        );
      }
      return (
        <>
          <Text type="h3">{EMPTY_DASHBOARD.AO_HEADING}</Text>
          <div className={s.subHeading}>
            <VSpacer />
            <Text type="body-lg">{EMPTY_DASHBOARD.AO_SUBHEADING}</Text>
          </div>

          <VSpacer factor={2} />
          <div style={{ display: 'flex', alignContent: 'center' }}>
            <Button onClick={() => btnClickHandler(EMPTY_DASHBOARD.AO_BTN_1_TEXT)}>
              {EMPTY_DASHBOARD.AO_BTN_1_TEXT}
            </Button>
          </div>
        </>
      );
    }

    if (CreditCapabilities.canApproveOffers()) {
      return (
        <>
          <Text type="h3">{EMPTY_DASHBOARD.AO_APPROVE_HEADING}</Text>
          <div className={s.subHeading}>
            <VSpacer />
            <Text type="body-lg">{EMPTY_DASHBOARD.AO_APPROVE_SUBHEADING}</Text>
          </div>
        </>
      );
    }

    return (
      <>
        <Text type="h3">{EMPTY_DASHBOARD.AO_SUPPORT_HEADING}</Text>
        <div className={s.subHeading}>
          <VSpacer />
          <Text type="body-lg">{EMPTY_DASHBOARD.AO_SUPPORT_SUBHEADING}</Text>
        </div>
      </>
    );
  };

  const renderForOffersPageACMEUsers = () => (
    <>
      <Text type="h3">{EMPTY_DASHBOARD.AO_ACME_USER_HEADING}</Text>
      <div className={s.subHeading}>
        <VSpacer factor={2} />
        <Text type="body-sm">{EMPTY_DASHBOARD.AO_ACME_USER_SUBHEADING}</Text>
      </div>

      <VSpacer factor={2} />
      <div style={{ display: 'flex', alignContent: 'center' }}>
        <Button
          type="tertiary"
          onClick={() => btnClickHandler(EMPTY_DASHBOARD.AO_ACME_USER_BTN_1_TEXT)}
        >
          {EMPTY_DASHBOARD.AO_ACME_USER_BTN_1_TEXT}
        </Button>
        <HSpacer factor={2} />
        <Button onClick={() => btnClickHandler(EMPTY_DASHBOARD.AO_ACME_USER_BTN_2_TEXT)}>
          {EMPTY_DASHBOARD.AO_ACME_USER_BTN_2_TEXT}
        </Button>
      </div>
    </>
  );

  const renderForOffersPage = () => (
    <>
      <div className={s.iconContainer}>
        <Icon type="credit-empty" noHoverEffects />
      </div>
      <VSpacer factor={2} />
      {CreditCapabilities.canEnterOffersPage()
        ? renderForOffersPageNonACMEUsers()
        : renderForOffersPageACMEUsers()}
    </>
  );

  const EmptyDashboardTitle = productType === CREDIT_TYPES.CREDIT ? 'Credit products' : 'Offers';

  return (
    <>
      {!loading && showTitle && <Text type="h3">{EmptyDashboardTitle}</Text>}
      {children}
      <VSpacer factor={2} />
      <Card noBoxShadow style={styles}>
        {!loading && (
          <div className={s.content}>
            {productType === CREDIT_TYPES.CREDIT
              ? renderForCreditProductsPage()
              : renderForOffersPage()}
          </div>
        )}
      </Card>
    </>
  );
}
