// @flow
import { decorate, observable, computed } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';

class AuditLogStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
  }
  // values
  id: ?number = null;
  app_name: ?string = '';
  app_server_name: ?string = '';
  record_type: ?string = '';
  record_id: ?string = '';
  record_operation: ?string = '';
  remote_ip_address: ?string = '';
  user_name: ?string = '';
  event_type: ?string = '';
  program: ?string = '';
  http_path: ?string = '';
  http_method: ?string = '';
  http_params: ?string = '';
  http_status_code: ?string = '';
  user_role: ?string = '';
  created_time: ?string = '';
  last_modified_time: ?string = '';
  old_value: ?string = '';
  new_value: ?string = '';
  notes: ?string = '';
  extra_info: ?string = '';

  get createdDate(): string {
    if (this.createdTime) {
      return new Date(this.createdTime).toLocaleDateString();
    } else {
      return '';
    }
  }
}

decorate(AuditLogStore, {
  // values
  id: observable,
  app_name: observable,
  app_server_name: observable,
  record_type: observable,
  record_id: observable,
  record_operation: observable,
  remote_ip_address: observable,
  user_name: observable,
  event_type: observable,
  program: observable,
  http_path: observable,
  http_method: observable,
  http_params: observable,
  http_status_code: observable,
  user_role: observable,
  created_time: observable,
  last_modified_time: observable,
  old_value: observable,
  new_value: observable,
  notes: observable,
  extra_info: observable,

  // actions

  // computed
  createdDate: computed,
});

export default AuditLogStore;
