import currentUserStore from 'stores/CurrentUserStore';
import { hasDeveloperRole, hasNoRoles, isJanusUser, isDdaProgram } from './functions.js';
import {
  ACCOUNT_HOLDER_DETAILS_VIEW,
  ACCOUNT_HOLDER_DETAILS_VIEW_AND_EDIT,
} from '../../../views/admin/uam-granular-permissions/constants';

const UAM_ROLES = [ACCOUNT_HOLDER_DETAILS_VIEW, ACCOUNT_HOLDER_DETAILS_VIEW_AND_EDIT];

export const requirements = {
  flipFlopRequirements: ['dda-beta', 'new-uam-permission-rollout'],
  requiredGranularPermissions: UAM_ROLES,
};

export const verifier = () => {
  const { userStore = {} } = currentUserStore;
  const { uamGranularPermissionsActive, newUamPermissionRolloutActive, hasRequiredUamPermissions } =
    userStore;
  const hasNonDevelopmentRole = !hasNoRoles(userStore) && !hasDeveloperRole(userStore);
  const hasDda =
    isDdaProgram() && userStore.ddaActive && (hasNonDevelopmentRole || isJanusUser(userStore));

  return uamGranularPermissionsActive && newUamPermissionRolloutActive
    ? hasRequiredUamPermissions(UAM_ROLES) && hasDda
    : hasDda;
};

export const redirect = () => {
  if (!isDdaProgram()) return 'programCardholders';
  return false;
};
