import { path } from 'ramda';
import { formatMoneyAndNegative } from '@mqd/volt-currency';
import currentUserStore from 'stores/CurrentUserStore';
import capitalize from '../../utils/helpers/capitalize.js';

const transactionTypeRenameMap = {
  'accountfunding.pull': 'instant funding',
  'original.credit.auth_plus_capture': 'instant funding',
  gpa: 'GPA',
  pindebit: 'PIN debit',
  directdeposit: 'direct deposit',
  auth_plus_capture: 'auth plus capture',
  quasicash: 'quasi cash',
  issuerexpiration: 'issuer expiration',
  issueroperator: 'issuer-operator',
  balanceinquiry: 'balance inquiry',
  networkload: 'network load',
  programreserve: 'program reserve',
  'activation-request': 'activation request',
  pushtocard: 'push to card',
  standin: 'stand in',
  writeoff: 'write off',
  pgfs: 'PGFS',
};

const verificationStatusMap = {
  VERIFICATION_PENDING: 'Pending',
  ACH_VERIFIED: 'Verified',
  ACH_FAILED: 'Failed',
};

export const getTxnDisplayType = (transaction = {}, sentenceCase = false) => {
  const { type = '' } = transaction;
  const safeType = type || '';

  if (transactionTypeRenameMap[safeType]) {
    return transactionTypeRenameMap[safeType];
  }

  if (isAchTransaction(transaction)) {
    const [ach, ...rest] = type.split('.');
    return `${ach.toUpperCase()} ${rest.join(' ')}`;
  }

  const displayType = safeType
    .split('.')
    .map((word) => transactionTypeRenameMap[word] || word)
    .join(' ');

  return sentenceCase ? capitalize(displayType.toLocaleLowerCase()) : displayType;
};

export const getTxnDescription = (item = {}) => {
  if (item.direct_deposit) {
    const { company_name, company_entry_description } = item.direct_deposit;

    return company_name && company_entry_description
      ? `${company_name} ${company_entry_description}`
      : company_name || company_entry_description;
  }

  return item.card_acceptor?.name;
};

export const isAchTransaction = (transaction = {}) =>
  transaction.type && transaction.type.includes('ach.');

export const getTransitionDate = (transitions = [], status) => {
  const { created_time } = transitions.find((transition) => transition.status === status) || {};
  return created_time;
};

export const getTransitionReason = (transitions = [], status) => {
  const { reason } = transitions.find((transition) => transition.status === status) || {};
  return reason || '';
};

export const getProgramName = () => {
  const { userStore = {} } = currentUserStore;
  const { activeProgram } = userStore;
  if (typeof activeProgram === 'string') {
    return activeProgram;
  } else if (activeProgram && activeProgram.program) {
    return activeProgram.program;
  }
};

export const getVerificationStatus = (bankTransfer = {}) => {
  const verificationStatus = path(['achFundingSource', 'verification_status'], bankTransfer);
  const verificationOverride = path(['achFundingSource', 'verification_override'], bankTransfer);

  if (Boolean(verificationOverride) && verificationStatus === 'ACH_VERIFIED')
    return `Verified by ${getProgramName()}`;
  if (!Boolean(verificationOverride)) return verificationStatusMap[verificationStatus];
};

export const getAmount = (transaction) => {
  const { amount, gpa } = transaction;

  const impactedAmount = parseFloat(gpa?.impacted_amount);
  const signedAmount = !isNaN(impactedAmount) && impactedAmount < 0 ? `-${amount}` : amount;
  const amountWithCurrencyCode = formatMoneyAndNegative(signedAmount);
  const [parsedAmount] = amountWithCurrencyCode.split(' ');

  return parsedAmount;
};

export const getMethod = (transaction) => {
  const isDirectDeposit = transaction.type && transaction.type.includes('directdeposit');
  const achTransaction = isAchTransaction(transaction);
  return isDirectDeposit ? 'Direct deposit' : achTransaction ? 'ACH' : 'Card';
};
