import NETWORK_CONFIGS from '../networks/index.js';
import fieldsetMap from './fieldsets.js';

function getFields(fieldsetId, data = {}, restrictTo) {
  // Log error if fieldset is not found.
  const fieldset = fieldsetMap[fieldsetId];
  const network = data.network;
  if (!fieldset) {
    console.error(`Error: No fieldset found for [${fieldsetId}:${network}].`);
    return [];
  }

  // Get network configured fields.
  const config = NETWORK_CONFIGS[network] || NETWORK_CONFIGS.default;
  const configuredFields =
    typeof config[fieldsetId] === 'function'
      ? config[fieldsetId](data, fieldset)
      : config[fieldsetId];

  // Return configured fields.
  return configuredFields
    .map((configuredField) => {
      // Ensure field is truthy.
      if (!configuredField) return null;
      // Check if field is an object to be merged with default field properties later on.
      const shouldMergeField = typeof configuredField === 'object';
      // If configuredField is string, use it as id. Otherwise use id prop.
      const id = shouldMergeField ? configuredField.id : configuredField;
      // If restrictTo exists, only return fields that are listed in restrictTo.
      if (Array.isArray(restrictTo) && !restrictTo.includes(id)) return null;
      // Get default field values.
      const defaultField = fieldset[id];
      if (!defaultField) return null;
      // Run the default field callback, if it exists.
      let field = typeof defaultField === 'function' ? defaultField(data) : defaultField;
      // If needed, shallow merge with configuredField.
      return shouldMergeField ? Object.assign({}, field, configuredField) : field;
    })
    .filter(Boolean);
}

export default getFields;
