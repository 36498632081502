import React, { useMemo, useState, useReducer } from 'react';
import {
  Button,
  ErrorModal,
  Flex,
  Text,
  PageLoader,
  HSpacer,
  VSpacer,
  Card,
  Tabs,
} from '@mqd/volt-base';

import withProgramSelector from '../../../../components/program-selector/components/withProgramSelector.js';
import ApprovalFlowBreadcrumbs from '../../components/ApprovalFlowBreadcrumbs/ApprovalFlowBreadcrumbs.js';
import ApproveButton from './components/ApproveButton/ApproveButton.js';
import RelatedTransactionsTable from '../../components/RelatedTransactionsTable/RelatedTransactionsTable.js';
import {
  HashExceptionDetailsDispatch,
  HashExceptionDetailsState,
  useHashExceptionDetailsState,
} from '../../utilities/use-hash-exception-details/context.js';
import useHashExceptionDetails from '../../utilities/use-hash-exception-details/index.js';
import * as hashExceptionDetailsReducer from '../../utilities/use-hash-exception-details/reducer.js';
import DetailsMetricsRow from '../../components/DetailsMetricsRow/DetailsMetricsRow.js';
import FlexContainer from '../../components/FlexContainer/FlexContainer.js';
import { maskHashId } from '../../utilities/string/index.js';
import ErrorLoading from '../../components/ErrorLoading/index.js';
import Activity from '../ExceptionDetail/components/Activity.js';

import useSettledDetails from './useSettledDetails.js';
import useSubmittedDetails from './useSubmittedDetails.js';
import useTransactionInformation from './useTransactionInformation.js';
import RejectHashAdjustmentModal from './components/RejectHashAdjustmentModal/RejectHashAdjustmentModal.js';
import SubmissionDetails from './components/SubmissionDetails.js';

const ID_PREFIX = 'approvalDetails';

const tabs = {
  submissionDetails: 'Submission details',
  relatedTransactions: 'Related transactions',
  activity: 'Activity',
};

const ApprovalDetailsPage = ({ programSelector }) => {
  const [loadingState, setLoadingState] = useState(null);
  const [activeTab, setActiveTab] = useState(tabs.submissionDetails);
  const {
    adjustmentIdx,
    data,
    error: { hasError },
    hashId,
    netRefId,
    notes,
    settlementDate,
    submissionDate,
    submissionType,
  } = useHashExceptionDetailsState();
  const { network, subnetwork } = data?.common_data || {};
  const hasData = useMemo(() => Boolean(data?.transactions?.length), [data]);

  const urlParams = new URLSearchParams(window.location.search);
  const txHashInternalId = urlParams.get('tx_hash_internal_id');

  const { programs } = programSelector;

  const { loading } = useHashExceptionDetails({
    tx_hash_internal_id: txHashInternalId,
    programs,
  });

  const [showModal, setShowModal] = useState(false);
  const [approveOrRejectResponse, setApproveOrRejectResponse] = useState({ error: false });

  const settledDetails = useSettledDetails();
  const submittedDetails = useSubmittedDetails();

  const transactionInformation = useTransactionInformation();

  const toggleShowModal = () => setShowModal(!showModal);
  const handleTabChange = (tab) => setActiveTab(tab);
  const handleViewAllActivity = () => setActiveTab(tabs.activity);
  const handleHideModal = () => setApproveOrRejectResponse({ error: false });

  return (
    <FlexContainer direction="column">
      {approveOrRejectResponse.error && (
        <ErrorModal heading={approveOrRejectResponse.heading} hideModal={handleHideModal}>
          {approveOrRejectResponse.message}
        </ErrorModal>
      )}
      <div>
        <Card>
          <FlexContainer justifyContent="space-between">
            <ApprovalFlowBreadcrumbs hashId={hashId} />
            {hasData && (
              <FlexContainer>
                <Button
                  disabled={showModal || loadingState === 'approving' || loading}
                  type="tertiary"
                  onClick={toggleShowModal}
                >
                  Reject
                </Button>
                <HSpacer factor={2} />
                <ApproveButton
                  disabled={showModal || loadingState === 'rejecting' || loading}
                  onLoading={() => setLoadingState('approving')}
                  onResponse={() => setLoadingState(null)}
                  txHashInternalId={txHashInternalId}
                  adjustmentIdx={adjustmentIdx}
                  netRefId={netRefId}
                  setApproveOrRejectResponse={setApproveOrRejectResponse}
                />
              </FlexContainer>
            )}
          </FlexContainer>

          {loading ? (
            <PageLoader active={loading} />
          ) : !hasData && hasError ? (
            <>
              <VSpacer factor={2} />
              <ErrorLoading />
            </>
          ) : (
            <>
              <Flex direction="column">
                <Text type="h3" id="exceptionDetailPageHashId">
                  Hash ID {maskHashId(hashId)}
                </Text>
              </Flex>
              <VSpacer factor={4} />
              <DetailsMetricsRow showSettledTotal showSubmittedTotal varianceType="submitted" />

              <div>
                <VSpacer factor={4} />
                <Tabs
                  activeTab={activeTab}
                  tabs={[tabs.submissionDetails, tabs.relatedTransactions, tabs.activity]}
                  onTabChange={handleTabChange}
                >
                  <VSpacer factor={3} />
                  {activeTab === tabs.submissionDetails && (
                    <SubmissionDetails
                      data={data}
                      handleViewAllActivity={handleViewAllActivity}
                      submissionType={submissionType}
                      submissionDate={submissionDate}
                      idPrefix={ID_PREFIX}
                      settledDetails={settledDetails}
                      submittedDetails={submittedDetails}
                      notes={notes}
                      transactionInformation={transactionInformation}
                    />
                  )}
                  {activeTab === tabs.relatedTransactions && (
                    <RelatedTransactionsTable
                      commonData={data.common_data}
                      loading={loading}
                      netRefId={netRefId}
                      network={network}
                      subnetwork={subnetwork}
                      settlementDate={settlementDate}
                      transactions={data.transactions}
                    />
                  )}
                  {activeTab === tabs.activity && (
                    <Activity notes={notes} tabView hideInput={true} />
                  )}
                </Tabs>
              </div>
            </>
          )}
        </Card>
      </div>
      {showModal && (
        <RejectHashAdjustmentModal
          adjustmentIdx={adjustmentIdx}
          netRefId={netRefId}
          setApproveOrRejectResponse={setApproveOrRejectResponse}
          toggleShowModal={toggleShowModal}
          txHashInternalId={txHashInternalId}
        />
      )}
    </FlexContainer>
  );
};

const ApprovalDetailsPageWrapper = ({ programSelector }) => {
  const [state, dispatch] = useReducer(
    hashExceptionDetailsReducer.reducer,
    hashExceptionDetailsReducer.initialState
  );

  return (
    <HashExceptionDetailsState.Provider value={state}>
      <HashExceptionDetailsDispatch.Provider value={dispatch}>
        <ApprovalDetailsPage programSelector={programSelector} />
      </HashExceptionDetailsDispatch.Provider>
    </HashExceptionDetailsState.Provider>
  );
};

export default withProgramSelector(ApprovalDetailsPageWrapper);
