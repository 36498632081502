import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import ReviewsTableStore from './ReviewsTableStore';
import { ParentTable } from '@mq/voltron-parent';
import {
  Table,
  ActionPlaceholder,
  ActionsHeader,
  TableFilters,
  ColumnEditor,
  saveColumnConfig,
  mergeColumnsWithStoredSettings,
  clearColumnConfig,
} from '@mq/voltron-table';
import { Checkbox, LoadingOverlay, VSpacer, Flex, Select } from '@mqd/volt-base';
import { AGENT_TYPES, COLUMNS_LOCAL_STORAGE_KEY, STATUSES } from './constants';
import { getInitialColumns } from './utils';
import s from './ReviewsTable.module.css';
import { toJS } from 'mobx';

class ReviewsTable extends ParentTable {
  constructor(props) {
    super(props);
    this.storeConstructor = ReviewsTableStore;
    const { hasMarqetaEmail } = this.props.userStore;
    this.state = {
      activeStore: null,
      tableKey: 'unset',
      showColumnEditor: false,
      columns: [
        ...mergeColumnsWithStoredSettings(
          getInitialColumns(hasMarqetaEmail),
          COLUMNS_LOCAL_STORAGE_KEY
        ),
      ],
      checkedRows: [],
      allChecked: false,
    };
  }

  getFilterOptions() {
    const { programsListOptions, agentsListOptions, agentsListSearchableOptions } = this.store;
    const { hasMarqetaEmail } = this.props.userStore;

    return [
      {
        type: 'multiselect',
        label: 'Review status',
        filterParam: 'status',
        placeholder: 'Select an option',
        options: [STATUSES.OPEN, STATUSES.IN_PROGRESS, STATUSES.CLOSED],
      },
      {
        type: 'date',
        label: 'Last updated',
        placeholder: 'Select date range',
        filterParam: 'lastUpdated',
      },
      {
        type: 'multiselect',
        label: 'Reviewing party',
        filterParam: 'caseReviewer',
        placeholder: 'Select an option',
        numOptions: '4',
        selected: [hasMarqetaEmail ? AGENT_TYPES.MARQETA : AGENT_TYPES.CUSTOMER],
        options: [AGENT_TYPES.MARQETA, AGENT_TYPES.CUSTOMER],
      },
      {
        label: 'Review ID',
        filterParam: 'reviewId',
        placeholder: 'e.g. 332523',
      },
      {
        label: 'User token',
        filterParam: 'userToken',
      },
      {
        type: 'date',
        label: 'Date created',
        placeholder: 'Select date range',
        filterParam: 'dateCreated',
      },
      {
        type: 'multiselect',
        label: 'Assignee',
        filterParam: 'agent',
        placeholder: 'Select an option',
        numOptions: 6,
        selected: [],
        searchableOptions: agentsListSearchableOptions,
        options: [...agentsListOptions],
      },
      {
        type: 'multiselect',
        label: 'Program',
        filterParam: 'selectedPrograms',
        placeholder: 'Select an option',
        options: programsListOptions,
      },
    ];
  }

  customTableFilter() {
    const { excludedSearchFilters } = this.props;
    return (
      <Flex justifyContent="space-between" display="flex" className={s.reviewHeaderWrapper}>
        <TableFilters
          store={this.store}
          filterOptions={this.getFilterOptions()}
          excludedFilters={excludedSearchFilters}
          testId="reviews-table-header-buttons"
          receiveClearFilterFunc={(clearFilterFunc) => (this.clearFilterFunc = clearFilterFunc)}
          type="large"
        />
      </Flex>
    );
  }

  actionColumn() {
    return {
      accessor: 'action',
      deferRenderPlaceholder: () => <ActionPlaceholder width={32} />,
      header: (
        <ActionsHeader
          testId="tokens-table-actions-header"
          actions={[
            {
              text: 'Customize table columns',
              onClick: () => this.setState({ showColumnEditor: true }),
            },
            {
              text: 'Reset table to default',
              onClick: () => {
                clearColumnConfig(COLUMNS_LOCAL_STORAGE_KEY);
                window.location.reload();
              },
            },
          ]}
        />
      ),
      key: 'action',
      width: '32px',
      noEdit: true,
      noPadding: true,
      noResize: true,
      noRowClick: true,
      noSort: true,
      renderer: () => {
        return <></>;
      },
    };
  }

  checkColumn() {
    return {
      accessor: 'check',
      deferRenderPlaceholder: () => <ActionPlaceholder width={32} />,
      header: (
        <div className={s.reviewTableCheckbox}>
          <Checkbox
            id="AllChecks"
            active={this.state.allChecked}
            onClick={() => this.toggleAllChecks()}
          ></Checkbox>
        </div>
      ),
      key: 'check',
      width: '40px',
      noEdit: true,
      noPadding: true,
      noResize: true,
      noRowClick: true,
      noSort: true,
      renderer: (rowInfo) => {
        const token = rowInfo && rowInfo.row && rowInfo.row.token;
        const isActive = this.state.checkedRows.includes(token);

        return (
          <div className={s.reviewTableCheckbox}>
            <Checkbox
              testId="row-checkbox"
              id={`row-${token}-check`}
              active={isActive}
              onClick={() => this.toggleRowCheck(isActive, token)}
            ></Checkbox>
          </div>
        );
      },
    };
  }

  toggleAllChecks() {
    const { tokens } = this.store;
    if (this.state.allChecked) {
      this.setState({ checkedRows: [], allChecked: !this.state.allChecked });
    } else {
      this.setState({ checkedRows: tokens, allChecked: !this.state.allChecked });
    }
  }

  toggleRowCheck(checkIsActive, token) {
    const { checkedRows } = this.state;
    if (checkIsActive) {
      checkedRows.splice(checkedRows.indexOf(token), 1);
    } else {
      checkedRows.push(token);
    }

    this.setState({ checkedRows: checkedRows });
  }

  async assignReviews(email) {
    const { assignSelectedReview } = this.store;
    await assignSelectedReview(email, this.state.checkedRows);
  }

  render() {
    const { onRowClick } = this.props;
    const {
      activeSort,
      activeRow,
      currentList,
      agentsListSearchableOptions,
      setAttr,
      loading,
      updateKey,
      agentsListOptions,
    } = this.store;
    const { checkedRows, tableKey, showColumnEditor } = this.state;

    return (
      <div
        style={{ width: '100%' }}
        data-testid="business-table-wrapper"
        className={s.filterOptions}
      >
        <Flex alignItems="center" justifyContent="space-between">
          {this.renderColEditTableHeader({
            customTableFilter: this.customTableFilter(),
          })}
          <Select
            testId="asignee-select"
            disabled={checkedRows.length === 0}
            options={agentsListOptions}
            placeholder="Assign"
            showSearch={true}
            onChange={(email) => this.assignReviews(email)}
            searchableOptions={agentsListSearchableOptions}
          />
        </Flex>

        <VSpacer />
        <LoadingOverlay active={loading}>
          <Table
            testId="reviews-table"
            loading={loading}
            key={tableKey}
            height={this.dynamicHeight}
            data={currentList}
            columns={[this.checkColumn(), ...this.state.columns, this.actionColumn()]}
            sort={activeSort}
            activeRow={activeRow}
            onRowClick={onRowClick}
            onSort={(newSort) => setAttr('activeSort', newSort)}
            scrollToTopUpdateKey={updateKey}
            deferRenderTimeout={500}
            handleColSizeChange={this.handleColSizeChange.bind(this)}
            handleResetTable={this.clearFilterFunc}
          />
          {showColumnEditor && (
            <ColumnEditor
              columns={this.state.columns}
              handleSave={(columns) => {
                this.setState({ columns });
                saveColumnConfig(columns, COLUMNS_LOCAL_STORAGE_KEY);
                this.setState({ showColumnEditor: false });
              }}
              title="Customize table columns"
              hideModal={() => this.setState({ showColumnEditor: false })}
            />
          )}
        </LoadingOverlay>
        {this.renderPagination()}
      </div>
    );
  }
}

ReviewsTable.propTypes = {
  autoHydrate: PropTypes.bool,
  height: PropTypes.number,
  onRowClick: PropTypes.func,
  onViewClick: PropTypes.func,
  storeInitArgs: PropTypes.object,
  userStore: PropTypes.object,
  localStorageKey: PropTypes.string,
  assignSelectedReview: PropTypes.func,
};

ReviewsTable.defaultProps = {
  autoHydrate: true,
  height: 800,
  onRowClick: () => {},
  onViewClick: PropTypes.func,
  userStore: {},
  localStorageKey: 'ReviewsTableConfig',
  assignSelectedReview: () => {},
};

export default observer(ReviewsTable);
