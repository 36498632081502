import gql from 'graphql-tag';

export const GET_TRANSACTION_EXCEPTION_DETAIL = gql`
  query getTransactionHashExceptionDetail($tx_hash_internal_id: String!) {
    transactionHashExceptionDetail(tx_hash_internal_id: $tx_hash_internal_id) {
      adjustment {
        adjustment_type
        amounts {
          accounting_group
          amount {
            units
            scale
          }
        }
        created_date
        cross_border_fee {
          units
          scale
        }
        idx
        fees_total {
          units
          scale
        }
        integrity_fee {
          units
          scale
        }
        interchange_payable {
          units
          scale
        }
        irf_base_fee {
          units
          scale
        }
        submitted_total {
          units
          scale
        }
        surcharge_atm_pin_debit_fee {
          units
          scale
        }
        transactions_total {
          units
          scale
        }
      }
      common_data {
        bank {
          name
        }
        currency_code
        ica
        masked_pan
        merchant {
          name
          mid
          country
        }
        net_ref_id
        network
        reference_id
        program_short_code
        program_type
        settlement_service_number
        source
        state
        subnetwork
        acquirer_reference_id
      }
      exception_type
      exception_variance_amounts {
        accounting_group
        amount {
          units
          scale
        }
      }
      hash_id
      settled_amounts {
        accounting_group
        amount {
          units
          scale
        }
      }
      settled_details {
        cross_border_fee {
          units
          scale
        }
        days
        fees {
          units
          scale
        }
        integrity_fee {
          units
          scale
        }
        interchange_fee {
          units
          scale
        }
        irf_base_fee {
          units
          scale
        }
        settled_total {
          units
          scale
        }
        submitted_total {
          units
          scale
        }
        surcharge_atm_pin_debit_fee {
          units
          scale
        }
        switch_fee {
          units
          scale
        }
        transactions {
          units
          scale
        }
        variance {
          units
          scale
        }
      }
      settlement_date
      transactions {
        accounting_group_and_amounts {
          accounting_group
          amount {
            units
            scale
          }
        }
        acquirer_reference_id
        batch_number
        created_date
        fee_program_indicator
        ft_sre
        itc
        masked_pan
        merchant_category_code
        merchant_country
        merchant_id
        merchant_name
        merchant_category_code
        message_reason_code
        original_id
        reason_code
        retrieval_reference_number
        settlement_currency
        source
        sre
        system_trace_audit_number
        transaction_summary {
          cross_border_fee {
            units
            scale
          }
          fees {
            units
            scale
          }
          interchange_fee {
            units
            scale
          }
          settled_total {
            units
            scale
          }
          surcharge_atm_pin_debit_fee {
            units
            scale
          }
          transactions {
            units
            scale
          }
          switch_fee {
            units
            scale
          }
        }
        transaction_token
        transaction_code
        transaction_type
        local_transaction_date_time
        message_type_indicator
        conversion_fee_amount {
          units
          scale
        }
        conversion_fee_currency
        transaction_function
        processing_code
        function_code
        billing_amount {
          units
          scale
        }
        business_activity
        cycle
        originator
        additional_information
      }
      tx_hash_internal_id
      user_name
      activity {
        createdTime
        email
        note
        user_name
      }
    }
  }
`;
