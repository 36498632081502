import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { Colors, Flex, HSpacer, Icon, Text, VSpacer } from '@mqd/volt-base';
import {
  VALIDATION_ERROR,
  VIOLATION_LEVEL,
} from '../../../../../../../../stores/program-reserve/validation-error.js';

const ERROR_MESSAGE_MAP = {
  [VALIDATION_ERROR.PROGRAM_REQUIRED]: 'Program required',
  [VALIDATION_ERROR.PROGRAM_NOT_FOUND]: 'Program not found',
  [VALIDATION_ERROR.AMOUNT_REQUIRED]: 'Amount required',
  [VALIDATION_ERROR.AMOUNT_INVALID]: 'Invalid amount',
  [VALIDATION_ERROR.MISSING_CURRENCY]: 'Selected program is missing default currency',
  [VALIDATION_ERROR.PROGRAM_DUPLICATE]: 'Duplicate entry',
};

const ERROR_ICON_MAP = {
  [VIOLATION_LEVEL.ERROR]: (
    <Icon
      type="danger"
      mod="default"
      factor={1}
      noHoverEffects
      overrideColor={Colors.statusErrorColor}
    />
  ),
  [VIOLATION_LEVEL.WARN]: (
    <Icon
      type="infoUpsideDown"
      mod="default"
      factor={1}
      noHoverEffects
      overrideColor={Colors.statusWarningColor}
    />
  ),
};

const ValidationStatus = ({ transaction }) => {
  if (!transaction.hasErrors && !transaction.hasWarnings) {
    return (
      <Icon
        type="check"
        mod={transaction.isValidated ? 'default' : 'disabled'}
        overrideColor={
          transaction.isValidated ? Colors.statusSuccessColor : Colors.statusDraftColor
        }
        factor={1}
        noHoverEffects
      />
    );
  }

  return (
    <Flex flexDirection="column">
      {transaction.validationErrorsAndWarnings.map((error, index, array) => (
        <Fragment key={error.id}>
          <Flex flexDirection="row" testId="validation-error">
            <Flex display="flex">{ERROR_ICON_MAP[error.level]}</Flex>
            <HSpacer factor={1} />
            <Text type="footnote" mod="active">
              {ERROR_MESSAGE_MAP[error.code]}
            </Text>
          </Flex>
          {index !== array.length - 1 && <VSpacer factor={1} />}
        </Fragment>
      ))}
    </Flex>
  );
};

export default observer(ValidationStatus);
