import React from 'react';
import { Icon, HSpacer, Text, Tooltip, VSpacer, Colors } from '@mqd/volt-base';
import moment from 'moment';
import s from './Timeline.module.css';
import PropTypes from 'prop-types';
import { transformEntityString } from './../helpers';

// helper function
function NoteIcon({ note }) {
  return (
    <>
      <HSpacer />
      <Tooltip direction="bottomLeft" content={note}>
        <Icon type="note" factor={1 / 2} noHoverEffects />
      </Tooltip>
    </>
  );
}

function TimelineNode({ event }) {
  const { timestamp, message_title, user_email, note } = event;
  const byUserStr = user_email ? `by ${user_email} ` : '';

  return (
    <div className={s.timelineNode} data-testid="KYB-timeline-node-container">
      <div className={s.message_titleRow}>
        <Text type="h6">{transformEntityString(message_title)}</Text>
        {note && <NoteIcon note={note} />}
      </div>
      <VSpacer factor={1 / 2} />
      <Text type="footnote" color={Colors.blackLighter4}>{`${byUserStr}${moment(timestamp).format(
        'MM-DD-YYYY HH:mm z'
      )}`}</Text>
    </div>
  );
}

TimelineNode.propTypes = {
  event: PropTypes.shape({
    timestamp: PropTypes.string,
    message: PropTypes.string,
    message_title: PropTypes.string,
    user_email: PropTypes.string,
    note: PropTypes.string,
  }),
};
TimelineNode.defaultProps = {
  event: {
    timestamp: '',
    message: '',
    message_title: '',
    user_email: '',
    note: '',
  },
};

export default TimelineNode;
