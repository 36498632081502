import currentUserStore from 'stores/CurrentUserStore';

export const requirements = {
  flipFlopRequirements: ['bin-management-active'],
  marqetaOnly: true,
};

export const verifier = () => {
  const { userStore = {} } = currentUserStore;

  // check if user is in allowed departments
  const userDepartments = new Set([...userStore.extendedDepartments]);
  const allowedDepartments = ['Customer Success', 'Program Management', 'Delivery (Internal)'];
  const isMarqetan =
    currentUserStore.userStore.userOrgName && currentUserStore.userStore.userOrgName === 'Marqeta';

  if (allowedDepartments.some((department) => userDepartments.has(department)) && !!isMarqetan) {
    return true;
  }

  // check if user has feature flag turned on
  if (userStore.binManagementActive) {
    return true;
  }

  return false;
};
