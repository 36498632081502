import number from './index.js';

// It looks like `currency.js` mutates the options, so
// each version of the options needs to be distinct
// in memory, which is why it is a function.
export default function getDefaultFormattingOptions(override = {}) {
  return {
    format: (currency) =>
      number(currency.value, { pattern: '# !', negativePattern: '(#) !', ...override })
        .format()
        .trim(),
  };
}
