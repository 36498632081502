import React, { useContext } from 'react';
import { ProgramReserveContext } from '../../../../context/ProgramReserveContext.js';
import Summary from '../../../Summary/Summary.js';
import { BulkEntryContext } from '../../BulkEntryContext.js';

function BulkEntryView() {
  const { enableEditMode } = useContext(ProgramReserveContext);
  const { file } = useContext(BulkEntryContext);

  return (
    <Summary
      breadcrumbs={[{ children: 'Bulk entry', onClick: enableEditMode }]}
      bankFileName={file.name}
      toastMessages={{
        success: 'Bulk entry successful',
        error: 'Bulk entry unsuccessful',
      }}
    />
  );
}

export default BulkEntryView;
