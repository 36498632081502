import currentUserStore from 'stores/CurrentUserStore';
import { FlipFlop } from 'utils/index.js';

// Routes
const dataCatalogRoutes = [
  {
    path: '/data-catalog/product',
    name: 'Data Product',
    importer: () => import(/*webpackChunkName: "Data Product" */ './ProductCatalogView.js'),
  },
  {
    path: '/data-catalog/product/manage',
    name: 'Manage Asset',
    importer: () => import(/*webpackChunkName: "Manage Asset" */ './ProductCatalogView.js'),
  },
];

// Authorization
const requirements = {
  validJanusAccount: true,
  flipFlopRequirements: ['data-marketplace'],
};

const verifier = () => {
  const hasProductCatalogFlag = FlipFlop.get('data-marketplace', false);

  const userHasNoReportsSupplement = currentUserStore.userStore.hasNoReportsSupplement;

  return (
    hasProductCatalogFlag &&
    currentUserStore.userStore.loggedInToJanus &&
    !userHasNoReportsSupplement
  );
};

export { requirements, verifier };
export default dataCatalogRoutes;
