import { UAM_GRANULAR_PERMISSIONS } from '@mqd/mqd-constants';
import currentUserStore from 'stores/CurrentUserStore';
import hasRoleForSelectedProgram from '../../helpers/hasRoleForSelectedProgram.js';
import { isDdaProgram } from './functions.js';

const { ACCOUNT_HOLDER_DETAILS_VIEW, ACCOUNT_HOLDER_DETAILS_VIEW_AND_EDIT } =
  UAM_GRANULAR_PERMISSIONS;

const requiredGranularPermissions = [
  ACCOUNT_HOLDER_DETAILS_VIEW,
  ACCOUNT_HOLDER_DETAILS_VIEW_AND_EDIT,
];

export const requirements = {
  flipFlopRequirements: ['unify-beta', 'dda-beta'],
  requiredGranularPermissions,
};

export const verifier = () => {
  const { userStore = {} } = currentUserStore;
  const hasDda = userStore.ddaActive && isDdaProgram();
  // if user has granular permissions, check if they have req. role; if not, this check is not necessary
  const hasRequiredPermissions = userStore.uamGranularPermissionsActive
    ? requiredGranularPermissions.some(hasRoleForSelectedProgram)
    : true;

  return hasDda && hasRequiredPermissions;
};

export const redirect = () => {
  if (!isDdaProgram()) return 'programCardholders';
  return false;
};
