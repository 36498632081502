import { action, autorun, computed, decorate, observable } from 'mobx';
import { Transaction } from './transaction.js';
import { flipFlop } from '@mq/volt-amc-container';

class ProgramReserveStore {
  loading = true;
  areChangesMade = false;
  isEditMode = true;
  transactions = [new Transaction()];
  programs = [];
  currencyCodes = [];
  currenciesMap = new Map();

  constructor() {
    autorun(() => this.autocorrectCurrencies());
  }

  setBulkTransactions(items) {
    this.areChangesMade = true;

    this.transactions = items.map((item) => {
      const transaction = new Transaction();
      return Object.assign(transaction, item);
    });

    this.validateTransactions();
  }

  resetTransactions() {
    this.transactions = [];
    this.areChangesMade = false;
  }

  setPrograms(programs) {
    this.programs = programs;
  }

  setCurrencyCodes(currencyCodes) {
    this.currencyCodes = currencyCodes;
  }

  setCurrencies(currencies = []) {
    const values = currencies.map(({ program, currency_code }) => [program, currency_code]);
    this.currenciesMap = new Map(values);
  }

  enableEditMode() {
    this.isEditMode = true;
  }

  disableEditMode() {
    this.isEditMode = false;
  }

  setIsLoading(loading) {
    this.loading = loading;
  }

  validateTransactions() {
    this.transactions.forEach((transaction) => {
      transaction.validate(this.programShortNames);
    });

    this.validateTransactionsProgramDuplicates();
  }

  validateAndDisableEditMode() {
    this.validateTransactions();

    if (!this.hasTransactionsWithErrors) {
      this.disableEditMode();
    }
  }

  validateTransactionsProgramDuplicates() {
    this.transactions.forEach((transaction) => {
      transaction.validateProgramDuplicates(this.duplicatedPrograms);
    });
  }

  addOneMoreTransaction() {
    this.transactions.push(new Transaction());
  }

  updateTransaction(id, payload) {
    this.areChangesMade = true;

    const transaction = this.transactions.find((transaction) => transaction.id === id);
    Object.assign(transaction, payload);

    if (transaction.isValidated) {
      transaction.validate(this.programShortNames);
    }

    this.validateTransactionsProgramDuplicates();
  }

  removeTransaction(id) {
    this.transactions = this.transactions.filter((transaction) => transaction.id !== id);

    this.validateTransactionsProgramDuplicates();
  }

  getProgramFullName(shortName) {
    return this.programs.find((program) => shortName === program.short_name)?.program ?? null;
  }

  autocorrectCurrencies() {
    const { currenciesMap } = this;

    this.transactions.forEach((transaction) => {
      if (transaction.program) {
        transaction.autocorrectCurrency(currenciesMap);
      }
    });
  }

  get hasTransactionsWithErrors() {
    return this.transactions.some(
      (transaction) => transaction.isValidated && transaction.hasErrors
    );
  }

  get hasTransactionsWithWarnings() {
    return this.transactions.some(
      (transaction) => transaction.isValidated && transaction.hasWarnings
    );
  }

  get programShortNames() {
    return this.programs.map((program) => program.short_name);
  }

  get programsOptions() {
    return this.programs.map(({ program, short_name }) => ({
      val: short_name,
      render: program,
      searchString: `${program} ${short_name}`,
    }));
  }

  get duplicatedPrograms() {
    const programsCount = new Map();
    for (const transaction of this.transactions) {
      if (transaction.program) {
        const count = programsCount.get(transaction.program) ?? 0;
        programsCount.set(transaction.program, count + 1);
      }
    }

    const duplicatedPrograms = [];
    for (const [program, count] of programsCount.entries()) {
      if (count > 1) {
        duplicatedPrograms.push(program);
      }
    }

    return duplicatedPrograms;
  }

  get commercialCreditActive() {
    return flipFlop.get('commercial-credit', false);
  }
}

decorate(ProgramReserveStore, {
  loading: observable,
  areChangesMade: observable,
  isEditMode: observable,
  transactions: observable,
  programs: observable,
  currencyCodes: observable,
  currenciesMap: observable,

  setBulkTransactions: action.bound,
  resetTransactions: action.bound,
  setPrograms: action.bound,
  setCurrencyCodes: action.bound,
  setCurrencies: action.bound,
  enableEditMode: action.bound,
  disableEditMode: action.bound,
  setIsLoading: action.bound,
  validateTransactions: action.bound,
  validateAndDisableEditMode: action.bound,
  addOneMoreTransaction: action.bound,
  updateTransaction: action.bound,
  removeTransaction: action.bound,
  getProgramFullName: action.bound,

  hasTransactionsWithErrors: computed,
  hasTransactionsWithWarnings: computed,
  programShortNames: computed,
  programsOptions: computed,
  duplicatedPrograms: computed,
});

export { ProgramReserveStore };
