import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { ToastAlert } from '@mqd/volt-base';
import { withToastStore } from '../../../../hoc/withToastStore.js';
import { ToastStoreContext } from '../../../../context/ToastStoreContext.js';

function ToastContainer() {
  const { toastMessage, toastType, isToastVisible, hideToast } = useContext(ToastStoreContext);

  if (!isToastVisible) {
    return null;
  }

  return (
    <ToastAlert icon={toastType} dismissTime={3} remove={hideToast}>
      {toastMessage}
    </ToastAlert>
  );
}

export default withToastStore(observer(ToastContainer));
