import userStore from '../../../../stores/UserStore.js';

const requirements = {
  requiredUserPermissions: ['settlement-internal'],
};

const verifier = () => {
  return Boolean(userStore?.hasRole('settlement-internal'));
};

export { requirements, verifier };
