import React from 'react';
import PropTypes from 'prop-types';
import { FormLabel, Flex, Input, HSpacer, Text } from '@mqd/volt-base';

// This component will be moved to @mqd/volt-base for use in the future
// Jira: https://marqeta.atlassian.net/browse/PS-25314
function NumberInput({
  testId,
  id,
  label,
  sublabel,
  quantifierLabel,
  hasChangedType,
  ...inputProps
}) {
  return (
    <div data-testid={testId}>
      <FormLabel labelFor={id} subLabel={sublabel} hasChangedType={hasChangedType}>
        {label}
      </FormLabel>
      <Flex flexDirection="row" alignItems="center">
        <Input
          {...inputProps}
          hasChangedType={hasChangedType}
          id={id}
          testId={`${testId}-input`}
          width={64}
        />
        <HSpacer factor={1} />
        <Text>{quantifierLabel}</Text>
      </Flex>
    </div>
  );
}

NumberInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  hasChangedType: PropTypes.oneOf(['changed', 'completed']),
  quantifierLabel: PropTypes.string,
  testId: PropTypes.string,
};

NumberInput.defaultProps = {
  hasChangedType: null,
  id: null,
  kind: 'input',
  label: 'Amount',
  quantifierLabel: 'per',
  testId: 'number-input',
};

export default NumberInput;
