import React, { Component } from 'react';
import { Button, Modal, LoadingOverlay } from '@mqd/volt-base';
import PropTypes from 'prop-types';

class DisableCardModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.handleReport = this.handleReport.bind(this);
  }

  async handleReport() {
    const { hideModal, renderFailedAlert, renderSuccessAlert, reportAction } = this.props;
    this.setState({ loading: true });

    try {
      const success = reportAction && (await reportAction());
      hideModal && hideModal();
      renderSuccessAlert && renderSuccessAlert();
    } catch (error) {
      hideModal && hideModal();
      renderFailedAlert && renderFailedAlert();
    } finally {
      this.setState({ loading: false });
    }
  }

  renderContent() {
    const { last_four } = this.props;
    return (
      <div data-testid="disable-card-modal-content">
        Report the card ending in{' '}
        <span style={{ fontWeight: 'bold' }} data-testid="disable-card-modal-content-last-four">
          {last_four}
        </span>{' '}
        as <span style={{ fontWeight: 'bold' }}>Lost or Stolen</span>.<br />
        <br />
        This process will <span style={{ fontWeight: 'bold' }}>terminate</span> the card.
      </div>
    );
  }

  render() {
    const { heading, hideModal } = this.props;
    const { loading } = this.state;
    return (
      <Modal
        disableOverFlow
        testId={'disable-card-modal'}
        heading={heading}
        hideModal={hideModal}
        footerButtons={[
          <Button testId="disable-card-modal-submit" onClick={this.handleReport}>
            Report Lost/Stolen
          </Button>,
        ]}
      >
        <LoadingOverlay active={loading}>{this.renderContent()}</LoadingOverlay>
      </Modal>
    );
  }
}

DisableCardModal.propTypes = {
  hideModal: PropTypes.func,
  heading: PropTypes.string,
  renderFailedAlert: PropTypes.func,
  renderSuccessAlert: PropTypes.func,
  reportAction: PropTypes.func,
  last_four: PropTypes.string,
};

DisableCardModal.defaultProps = {
  hideModal: () => {},
  heading: 'Add Note',
  renderFailedAlert: () => {},
  renderSuccessAlert: () => {},
  reportAction: () => {},
  last_four: '',
};

export default DisableCardModal;
