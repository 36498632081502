import React from 'react';
import PropTypes from 'prop-types';

import { Card, Text, VSpacer, ContentSnippet } from '@mqd/volt-base';
import Status from '../Status/index.js';

import { titleCase } from '../../utils.js';

import s from './CreditProductItem.module.css';

export default function CreditProductItem({
  status,
  name,
  classification,
  productType,
  productSubType,
  clickHandler,
}) {
  const classNames = `${s.pointer} ${s.creditProductItemBorder}`;

  return (
    <div onClick={clickHandler} className={classNames} data-testid={name}>
      <Card noBoxShadow contentPadding={'0 0 24px 0'}>
        <Text type="h5" testId="creditProductName">
          {name}
        </Text>
        <VSpacer />

        <div className={s.fieldsContainer}>
          <div className={s.fieldContainer}>
            <ContentSnippet spacingFactor={0.5} title="Credit product status">
              <Status status={status} capitalize />
            </ContentSnippet>
          </div>
          <div className={s.fieldContainer}>
            <ContentSnippet spacingFactor={0.5} title="Credit product classification">
              {titleCase(classification)}
            </ContentSnippet>
          </div>
          <div className={s.fieldContainer}>
            <ContentSnippet spacingFactor={0.5} title="Credit product type">
              {titleCase(productType)}
            </ContentSnippet>
          </div>
          <div className={s.fieldContainer}>
            <ContentSnippet spacingFactor={0.5} title="Credit product subtype">
              {titleCase(productSubType)}
            </ContentSnippet>
          </div>
        </div>
      </Card>
      <VSpacer factor={3} />
    </div>
  );
}

CreditProductItem.propTypes = {
  status: PropTypes.string,
  name: PropTypes.string,
  classification: PropTypes.string,
  productType: PropTypes.string,
  productSubType: PropTypes.string,
  clickHandler: PropTypes.func,
};
