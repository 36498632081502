import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumbs, Text, VSpacer } from '@mqd/volt-base';
import { goToProgramReserve } from '../../utils/navigation.js';

const PageHeader = ({ breadcrumbs, title, description }) => {
  return (
    <>
      {breadcrumbs.length > 0 && (
        <>
          <Breadcrumbs
            crumbs={[
              {
                children: 'Program funding',
                onClick: goToProgramReserve,
              },
              ...breadcrumbs,
            ]}
            testId="program-reserve-breadcrumbs"
          />
          <VSpacer factor={2} />
        </>
      )}

      {title && (
        <>
          <Text type="h3">{title}</Text>
          <VSpacer factor={1} />
        </>
      )}

      {description && (
        <>
          <Text type="p">{description}</Text>
          <VSpacer factor={4} />
        </>
      )}
    </>
  );
};

PageHeader.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  description: PropTypes.node,
};

export default PageHeader;
