import React from 'react';
import { CopyToClipboard, HSpacer } from '@mqd/volt-base';
import { maskHashId } from '../../utilities/string';

const notAvailable = <span style={{ color: '#9a9aac' }}>N/A</span>;

// Similar to <Table>'s text renderer, except displays "N/A" rather than "None"
// if the field is unavailable.
const textRenderer = ({ cellValue }) => (
  <span>{cellValue || typeof cellValue === 'number' ? cellValue : notAvailable}</span>
);

const copyRenderer = ({ cellValue, shouldMask = false }) =>
  cellValue ? (
    <CopyToClipboard
      overrideValue={String(cellValue)}
      tooltipDirection="top"
      tooltipUncopiedText="Copy to the clipboard"
      tooltipCopiedText="Copied!"
      copyCallback={(e) => {
        e.stopPropagation();
      }}
      fixOverflow
    >
      {shouldMask ? maskHashId(cellValue) : cellValue}
      <HSpacer factor={0.5} />
    </CopyToClipboard>
  ) : (
    notAvailable
  );

export { notAvailable, textRenderer, copyRenderer };
