import { FlipFlop } from './../../../utils/index.js';
import currentUserStore from 'stores/CurrentUserStore';
import hasRoleForAtLeastOneProgram from '../../helpers/hasRoleForAtLeastOneProgram.js';
import hasRoleForSelectedProgram from '../../helpers/hasRoleForSelectedProgram.js';
import { UAM_GRANULAR_PERMISSIONS } from '@mqd/mqd-constants';

const { DIGITAL_WALLET_TOKEN_DETAILS_VIEW, DIGITAL_WALLET_TOKEN_DETAILS_VIEW_AND_EDIT } =
  UAM_GRANULAR_PERMISSIONS;

export const requirements = {
  flipFlopRequirements: ['unify-beta', 'digital-wallet-token-beta'],
};

const requiredUserPermissions = [
  // internal
  'marqeta-admin-internal',
  'delivery-internal',
  'production-support-internal',
  'cardholder-support',
  // external
  'program-admin',
  'supplier-payments-manager',
  'read-only',
];

export const verifier = () => {
  const {
    userStore: { hasRole, redseaRoles, uamGranularPermissionsActive },
  } = currentUserStore;
  const hasSufficientRoles = requiredUserPermissions.some((role) =>
    hasRoleForAtLeastOneProgram(hasRole(role))
  );

  const canViewRoute = redseaRoles.some((r) =>
    [DIGITAL_WALLET_TOKEN_DETAILS_VIEW, DIGITAL_WALLET_TOKEN_DETAILS_VIEW_AND_EDIT].includes(r)
  );

  return uamGranularPermissionsActive
    ? canViewRoute
    : FlipFlop.get('digital-wallet-token-beta', false) && hasSufficientRoles;
};

export const programVerifier = () => {
  const {
    userStore: { hasRole },
  } = currentUserStore;
  const hasSufficientRolesForProgram = requiredUserPermissions.some((role) =>
    hasRoleForSelectedProgram(hasRole(role))
  );

  return FlipFlop.get('digital-wallet-token-beta', false) && hasSufficientRolesForProgram;
};
