import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import FundingSourcesTableStore from './FundingSourcesTableStore';
import { ParentTable } from '@mq/voltron-parent';
import { Table } from '@mq/voltron-table';
import { LoadingOverlay, Text, VSpacer } from '@mqd/volt-base';

class FundingSourcesTable extends ParentTable {
  constructor(props) {
    super(props);
    this.storeConstructor = FundingSourcesTableStore;
    this.state = this.colEditState(props, this.columns());
  }

  columns() {
    return [
      { header: 'Token', accessor: 'token', key: 'token', primaryKey: true },
      {
        header: 'Account Suffix',
        accessor: 'account_suffix',
        key: 'account_suffix',
      },
      { header: 'Account Type', accessor: 'account_type', key: 'account_type' },
      {
        header: 'Active',
        accessor: 'active',
        key: 'active',
        renderer: ({ cellValue }) => <div>{cellValue ? 'True' : 'False'}</div>,
      },
      {
        header: 'Is Default Account',
        accessor: 'is_default_account',
        key: 'is_default_account',
        renderer: ({ cellValue }) => <div>{cellValue ? 'True' : 'False'}</div>,
      },
      { header: 'Exp Date', accessor: 'exp_date', key: 'exp_date' },
      {
        header: 'Verification Status',
        accessor: 'verification_status',
        key: 'verification_status',
      },
      {
        header: 'Date Verified',
        accessor: 'date_verified',
        key: 'date_verified',
      },
      {
        header: 'Name On Account',
        accessor: 'name_on_account',
        key: 'name_on_account',
      },
      {
        header: 'Date Sent For Verification',
        accessor: 'date_sent_for_verification',
        key: 'date_sent_for_verification',
      },
      {
        header: 'Verification Override',
        accessor: 'verification_override',
        key: 'verification_override',
        renderer: ({ cellValue }) => <div>{cellValue ? 'True' : 'False'}</div>,
      },
      {
        header: 'Verification Notes',
        accessor: 'verification_notes',
        key: 'verification_notes',
      },
    ];
  }

  render() {
    const { currentList, activeSort, setAttr, loading, updateKey } = this.store;
    const { columns, tableKey } = this.state;
    return (
      <div style={{ width: '100%' }}>
        <LoadingOverlay active={loading}>
          <Text type="h3">Funding Sources</Text>
          <VSpacer factor={2} />
          <Table
            key={tableKey}
            height={this.dynamicHeight}
            data={currentList}
            columns={columns}
            sort={activeSort}
            onSort={(newSort) => setAttr('activeSort', newSort)}
            scrollToTopUpdateKey={updateKey}
            headerContent={this.renderColEditTableHeader({
              tableFilterTestId: 'funding-sources-table-header-buttons',
            })}
            handleColSizeChange={this.handleColSizeChange.bind(this)}
          />
        </LoadingOverlay>
      </div>
    );
  }
}

FundingSourcesTable.propTypes = {
  height: PropTypes.number,
  storeInitArgs: PropTypes.object,
  store: PropTypes.object,
  userStore: PropTypes.object,
  autoHydrate: PropTypes.bool,
  onStoreConstruction: PropTypes.func,
  gqlClient: PropTypes.any,
  localStorageKey: PropTypes.string,
};

FundingSourcesTable.defaultProps = {
  height: 800,
  storeInitArgs: { limit: 100 },
  store: null,
  userStore: {},
  autoHydrate: true,
  onStoreConstruction: null,
  gqlClient: null,
  localStorageKey: 'FundingSourcesTableConfig',
};

export default observer(FundingSourcesTable);
