import React from 'react';
import { Text, VSpacer, Button, ErrorModal, Modal } from '@mqd/volt-base';
import errorModalStore from './ErrorModalStore.js';
import s from './ErrorModal.module.css';

const closeModalDecorator = (callback) => () => {
  errorModalStore.clearError();

  callback && callback();
};

export default function ErrorModalWrapper({
  showError,
  showConfirmation,
  heading,
  description,
  btnText,
  btnClickCallback,
  closeCallback,
  ...props
}) {
  if (showError) {
    return (
      <ErrorModal hideModal={closeModalDecorator(btnClickCallback)} {...props} heading={heading}>
        <VSpacer />
        <Text type="body-sm">{description}</Text>
      </ErrorModal>
    );
  }

  if (showConfirmation) {
    return (
      <Modal
        hideModal={closeModalDecorator()}
        {...props}
        footerButtons={[
          <div className={s.btnContainer}>
            <Button
              key={btnText}
              onClick={closeModalDecorator(btnClickCallback)}
              testId={`${btnText}-button`}
            >
              {btnText}
            </Button>
          </div>,
        ]}
      >
        <Text type="h5" inline>
          {heading}
        </Text>
        <VSpacer />
        <Text type="body-sm">{description}</Text>
      </Modal>
    );
  }

  return null;
}
