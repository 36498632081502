import hasRoleForAtLeastOneProgram from './../../helpers/hasRoleForAtLeastOneProgram.js';

export const requirements = {
  requiredUserPermissions: ['risk-internal'],
};

export const verifier = () => {
  const hasAccessForAtLeastOneProgram = hasRoleForAtLeastOneProgram('risk-internal');
  return hasAccessForAtLeastOneProgram;
};
