// @flow
import AbstractCardStore from './AbstractCardStore';

import { runInAction } from 'mobx';

class CreditCardStore extends AbstractCardStore {
  constructor(args) {
    super(args);

    const { accountToken, isSupportAgent } = args;
    this.accountToken = accountToken;
    this.isSupportAgent = isSupportAgent;
  }

  async hydrate(token) {
    try {
      this.loading = true;
      const result = await this.gqlQuery(
        `query card($token: ID!) {
          card(token: $token) {
              ${this.fullDataQuery}
              ${this.isSupportAgent ? 'support_agent_cvv' : ''}
            }
          }
          ${this.fullDataFragments}
        `,
        {
          token,
          ...this.hydrateParams,
        }
      );
      runInAction(() => {
        if (result) {
          const cardInfo = this.extract(result, 'card');
          if (this.isSupportAgent) {
            cardInfo.cvv_number = cardInfo.support_agent_cvv;
          }
          this.extractAndLoadContructItem(cardInfo);
          this.valuesUpdated = {};
          return true;
        } else {
          return false;
        }
      });
    } catch (e) {
      console.error('CardStore: Unable to hydrate card');
    } finally {
      this.loading = false;
    }
  }

  /**
   * @param samePan if true, reissue card with the same pan
   * @returns a card token
   */
  async reissueCard(samePan) {
    const payload = {
      accountToken: this.accountToken,
      userToken: this.cardholder_token,
      ...(samePan && { reissuePanFromCardToken: this.token }),
    };

    const result = await this.gqlMutation(
      `
        mutation createCreditCard (
          $accountToken: ID!
          $userToken: ID!
          $expirationOffset: ExpirationOffsetInput
          ${samePan ? '$reissuePanFromCardToken: String' : ''}
        ) {
          createCreditCard(
            accountToken: $accountToken
            userToken: $userToken
            expirationOffset: $expirationOffset
            ${samePan ? 'reissuePanFromCardToken: $reissuePanFromCardToken' : ''}
          ){
            token
          }
        }
      `,
      payload
    );

    return this.dig(result, 'data', 'createCreditCard', 'token');
  }
}

export default CreditCardStore;
