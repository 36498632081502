import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { ProgramReserveContext } from '../../context/ProgramReserveContext.js';
import { withProgramReserveStore } from '../../hoc/withProgramReserveStore.js';
import { withFetchCurrencies } from '../../hoc/withFetchCurrencies.js';
import ManualEntryEdit from './components/ManualEntryEdit/ManualEntryEdit.js';
import ManualEntryView from './components/ManualEntryView/ManualEntryView.js';

export function ManualEntry() {
  const { isEditMode } = useContext(ProgramReserveContext);

  return isEditMode ? <ManualEntryEdit /> : <ManualEntryView />;
}

export default withProgramReserveStore(withFetchCurrencies(observer(ManualEntry)));
