import currentUserStore from 'stores/CurrentUserStore';
import { hasTokenizationManagerPermissions } from './functions.js';
import { UAM_ACCESS_MGMT_PERMISSIONS } from '../../../views/admin/uam-granular-permissions/constants.js';

export const requirements = {
  requiredGranularPermissions: UAM_ACCESS_MGMT_PERMISSIONS,
  requiredPermissions: ['Support', 'Admin', 'SuperAdmin'],
};

export const verifier = () => {
  // don't show user profiles admin page during impersonation, otherwise, user can infinitely impersonate
  // by hiding user profile during impersonation, it will also remind user they are impersonating
  if (currentUserStore.actingAsUserStore) return false;

  const {
    userStore: { uamGranularPermissionsActive, hasRequiredUamPermissions },
  } = currentUserStore;
  const canViewAccessMgmtMqd = hasRequiredUamPermissions(UAM_ACCESS_MGMT_PERMISSIONS);

  const allowed = ['Support', 'Admin', 'SuperAdmin'];

  return uamGranularPermissionsActive
    ? canViewAccessMgmtMqd
    : allowed.includes(currentUserStore.userStore.permissionLevel) ||
        hasTokenizationManagerPermissions(currentUserStore?.userStore, 'admin');
};
