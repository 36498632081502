import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { Drawer, VSpacer, Text, Icon, Flex } from '@mqd/volt-base';
import { Table, Pagination } from '@mq/voltron-table';

import { sentenceCase } from '../../../../shared-utils';
import { kycStatuses } from '../../constants.js';

import s from './FullTokenHistory.module.css';

const ITEMS_PER_PAGE = 5;
const HEADER_ROW_HEIGHT = 40;
const BODY_ROW_HEIGHT = 68;
const EMPTY_TABLE_HEIGHT = 240;

const withLineBreak = (text) => (
  <div className={s.lineBreak} style={{ maxHeight: BODY_ROW_HEIGHT }}>
    {text}
  </div>
);

export const getFailureCodes = ({ cellValue }) => {
  const { codes } = cellValue || {};
  if (!codes || !codes.length) return '';

  const codesString = (codes || [])
    .map((codeItem) => {
      const { code } = codeItem || {};
      return sentenceCase(code);
    })
    .join(', ');
  return withLineBreak(codesString);
};

export const getColumns = (type) =>
  [
    {
      header: `${type} token`,
      accessor: 'token',
      renderer: ({ cellValue }) => withLineBreak(cellValue),
      noSort: true,
      width: 295,
    },
    {
      header: `${type} status`,
      accessor: 'result',
      renderer: ({ cellValue }) => kycStatuses[cellValue.status.toUpperCase()],
      noSort: true,
      width: 90,
    },
    {
      header: `${type} failure code`,
      accessor: 'result',
      renderer: getFailureCodes,
      noSort: true,
      KYC: 140,
    },
    {
      header: 'Manual override',
      accessor: 'manual_override',
      noSort: true,
      width: 130,
    },
    {
      header: 'Notes',
      accessor: 'notes',
      renderer: ({ cellValue }) => withLineBreak(cellValue),
      noSort: true,
      width: 130,
    },
    {
      header: 'Created',
      accessor: 'created_time',
      noSort: true,
      width: 170,
    },
  ].filter(Boolean);

const FullTokenHistory = ({ type, data, isOpen, onCloseClick }) => {
  const [page, setPage] = useState(0);
  const columns = useMemo(() => getColumns(type), [type]);

  const count = data.length;
  const startIndex = page * ITEMS_PER_PAGE;
  const endIndex = Math.min(startIndex + ITEMS_PER_PAGE, count);
  const isMore = endIndex < count - 1;
  const isLess = page > 0;
  const dataPage = data.slice(startIndex, endIndex);

  return (
    <Drawer backdrop dynamicWidth isOpened={isOpen} closeDrawer={onCloseClick}>
      <div className={s.container}>
        <div className={s.close}>
          <Icon type="close" onClick={onCloseClick} />
        </div>

        <VSpacer />

        <Text type="h4">{type} token history</Text>

        <VSpacer factor={3} />

        <Table
          loading={false}
          columns={columns}
          headerRowHeight={HEADER_ROW_HEIGHT}
          bodyRowHeight={BODY_ROW_HEIGHT}
          height={
            count ? HEADER_ROW_HEIGHT + dataPage.length * BODY_ROW_HEIGHT : EMPTY_TABLE_HEIGHT
          }
          borderLessWrapper
          data={dataPage}
        />

        <VSpacer factor={3} />

        <Flex justifyContent="flex-end">
          <Pagination
            startIndex={startIndex}
            endIndex={endIndex}
            handleNextClick={() => setPage((page) => page + 1)}
            handlePreviousClick={() => setPage((page) => page - 1)}
            isMore={isMore}
            isLess={isLess}
          />
        </Flex>
      </div>
    </Drawer>
  );
};

FullTokenHistory.propTypes = {
  type: PropTypes.oneOf(['KYC', 'KYB']).isRequired,
  isOpen: PropTypes.bool,
  onCloseClick: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.object),
};

FullTokenHistory.defaultProps = {
  isOpen: false,
  onCloseClick: () => {},
  data: [],
};

export default observer(FullTokenHistory);
