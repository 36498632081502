import { toJS } from 'mobx';
import { reviewStatusColors, STATUSES, AGENT_TYPES } from './constants';
import moment from 'moment';
import KycStatus from '../shared-components/kyc-status/KycStatus';
import s from './ReviewsTable.module.css';
import React from 'react';
import { Colors, Text } from '@mqd/volt-base';
import { sentenceCase } from '../review-details/helpers';

export const getMultiSelectParams = (params) => {
  const options = toJS(params);
  const allConsts = { ...STATUSES, ...AGENT_TYPES };

  return options
    .map((value) => Object.keys(allConsts).find((key) => allConsts[key] === value))
    .join(',');
};

export const getProgramShortCodes = (allPrograms, selectedPrograms) => {
  return allPrograms
    .filter((program) => selectedPrograms.includes(program.program))
    .map((item) => item.short_name)
    .join(',');
};

export const formatDateRange = (field) => {
  const formattedFrom = moment(field.from).format('YYYY-MM-DD');
  const formattedTo = moment(field.to).format('YYYY-MM-DD');
  return `${formattedFrom}..${formattedTo}`;
};

export const prepareForTable = (reviews: {}) => {
  return {
    data: reviews && reviews.reviews,
    count: reviews && reviews.count,
    start_index: reviews && reviews.start_index,
    end_index: reviews && reviews.end_index,
    is_more: reviews && reviews.is_more,
  };
};

export const compareByEmail = (a, b) => {
  if (a.email < b.email) {
    return -1;
  }
  if (a.email > b.email) {
    return 1;
  }
  return 0;
};

export const formatActivityLog = (history) => {
  const array = [];
  history.forEach((item, i) => {
    if (item.user_channel === 'SYSTEM') {
      array.push({
        createdTime: moment(item.modify_time).format('YYYY-MM-DD, HH:mm'),
        token: `${item.modify_time}${i}`,
        description: item.header,
        note: item.body || '',
      });
    } else if (item.user_channel === 'USER') {
      const first = item.modify_user && item.modify_user.first_name.charAt(0);
      const last = item.modify_user && item.modify_user.last_name.charAt(0);
      const initials = `${first}${last}`;
      const email = item.modify_user && item.modify_user.email_address;
      array.push({
        createdTime: moment(item.modify_time).format('YYYY-MM-DD, HH:mm'),
        userInitials: initials,
        email: email,
        token: `${item.modify_time}${i}`,
        description: item.header,
        note: item.body || '',
      });
    }
  });
  return array;
};

export const reviewDateCreatedRenderer = ({ cellValue }) => {
  return (
    (cellValue && moment(cellValue).format('YYYY-MM-DD HH:mm')) || (
      <span className={s.unnassignedRender}> -- </span>
    )
  );
};

export const reviewDateModifiedRenderer = ({ cellValue }) => {
  return (
    (cellValue && moment(cellValue).format('YYYY-MM-DD HH:mm')) || (
      <span className={s.unnassignedRender}> -- </span>
    )
  );
};

export const reviewKycStatusRenderer = ({ cellValue }) => {
  const res = (cellValue && cellValue.kyc_status) || 'FAILURE';
  return <KycStatus status={res} showText={true} />;
};

export const reviewIDRenderer = ({ cellValue }) => {
  return (
    (cellValue && `${cellValue.substr(0, 12)}...`) || (
      <span className={s.unnassignedRender}>None</span>
    )
  );
};

export const userTokenRenderer = ({ cellValue }) => {
  return (cellValue && cellValue.user_token) || <span className={s.unnassignedRender}>None</span>;
};

export const reviewStatusRenderer = ({ cellValue }) => {
  const backgroundColor = reviewStatusColors[cellValue].backgroundColor || Colors.blackLighter7;
  return (
    <div className={s.reviewStatus}>
      <div
        style={{
          minWidth: '8px',
          height: '8px',
          borderRadius: '8px',
          marginRight: '8px',
          backgroundColor: backgroundColor || '#999',
        }}
      />
      <Text type="body-sm">{sentenceCase(cellValue)}</Text>
    </div>
  );
};

export const marqetaAgentRenderer = ({ cellValue }) => {
  return (
    (cellValue && cellValue.marqeta_agent && cellValue.marqeta_agent.email) || (
      <span className={s.unnassignedRender}>Unassigned</span>
    )
  );
};

export const agentRenderer = ({ cellValue }) => {
  return (
    (cellValue && cellValue.customer_agent && cellValue.customer_agent.email) || (
      <span className={s.unnassignedRender}>Unassigned</span>
    )
  );
};

export const teamRenderer = ({ cellValue }) => {
  return (
    (cellValue && cellValue.team && cellValue.team && sentenceCase(cellValue.team)) || (
      <span className={s.unnassignedRender}>Unassigned</span>
    )
  );
};

export const programRenderer = ({ cellValue }) => {
  return (
    (cellValue && cellValue[0] && cellValue[0].name) || (
      <span className={s.unnassignedRender}>Unassigned</span>
    )
  );
};

export const getInitialColumns = (isEmailMarqeta) => {
  return [
    {
      header: 'Review Status',
      accessor: 'status',
      key: 'status',
      renderer: reviewStatusRenderer,
    },
    {
      header: 'Review ID',
      accessor: 'token',
      key: 'token',
      renderer: reviewIDRenderer,
    },
    {
      header: 'User token',
      accessor: 'alert',
      key: 'userToken',
      renderer: userTokenRenderer,
    },
    {
      header: 'KYC status',
      accessor: 'alert',
      key: 'kycStatus',
      renderer: reviewKycStatusRenderer,
    },
    {
      header: 'Date created',
      accessor: 'created_time',
      key: 'created_time',
      renderer: reviewDateCreatedRenderer,
    },
    {
      header: 'Last updated',
      accessor: 'last_modified_time',
      key: 'last_modified_time',
      renderer: reviewDateModifiedRenderer,
    },
    {
      header: `${isEmailMarqeta ? 'Customer assignee' : 'Assignee'}`,
      accessor: 'assignment',
      key: 'agent',
      renderer: agentRenderer,
    },
    {
      header: `${isEmailMarqeta ? 'Assignee' : 'Marqeta assignee'}`,
      accessor: 'assignment',
      key: 'marqetaAgent',
      renderer: marqetaAgentRenderer,
    },
    {
      header: 'Reviewing party',
      accessor: 'assignment',
      key: 'caseReviewer',
      renderer: teamRenderer,
    },
    {
      header: 'Program',
      accessor: 'programs',
      key: 'program',
      renderer: programRenderer,
    },
  ];
};
