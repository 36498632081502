import WebhooksNew from './WebhooksNew.js';
import WebhooksOverview from './WebhooksOverview.js';
import WebhooksShow from './WebhooksShow.js';

const webhooksRoutes = [
  {
    id: 'developmentWebhooksOverview',
    path: '/development/webhooks',
    name: 'Webhooks overview',
    view: WebhooksOverview,
  },
  {
    id: 'developmentWebhooksNew',
    path: '/development/add-webhook',
    name: 'Create a webhook',
    view: WebhooksNew,
  },
  {
    id: 'developmentWebhooksShow',
    path: '/development/webhooks/show',
    name: 'Webhook settings',
    view: WebhooksShow,
  },
];

export { webhooksRoutes };
