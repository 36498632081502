import {
  applySpec,
  descend,
  filter,
  isNil,
  path,
  pick,
  pipe,
  prop,
  propOr,
  reject,
  sortWith,
} from 'ramda';

const _requiredAttributes = ['active', 'token', 'config', 'events', 'name'];
const formAttributes = [
  'name',
  'active',
  'url',
  'token',
  'events',
  'username',
  'password',
  'headers',
];

const detailFormAttributes = ['name', 'url', 'token', 'username', 'password', 'headers'];

const countActiveWebhooks = pipe(filter(prop('active')), propOr(0, 'length'));

const renderName = (renderFn) => pipe(path(['rowData', 'row']), pick(['name', 'token']), renderFn);

const renderState = (renderFn) =>
  pipe(path(['rowData', 'row']), pick(_requiredAttributes), renderFn);

const renderUrl = (renderFn) => pipe(path(['rowData', 'row', 'config']), pick(['url']), renderFn);

const sortActiveWebhooksFirst = pipe(
  reject(isNil),
  sortWith([descend(pipe(prop('active'), String))])
);

const translateGraphQLWebhookTypeToFormValues = applySpec({
  name: prop('name'),
  active: prop('active'),
  token: prop('token'),
  url: path(['config', 'url']),
  username: path(['config', 'basic_auth_username']),
  password: path(['config', 'basic_auth_password']),
  events: propOr("['*']", 'events'),
  headers: path(['config', 'custom_header']),
});

const translateFormValuesToGraphQLWebhookType = applySpec({
  name: prop('name'),
  active: prop('active'),
  token: prop('token'),
  url: prop('url'),
  username: prop('username'),
  password: prop('password'),
  events: propOr("['*']", 'events'),
  headers: propOr('{}', 'headers'),
});

const translateDetailFormValuesToGraphQLWebhookType = applySpec({
  name: prop('name'),
  token: prop('token'),
  url: prop('url'),
  username: prop('username'),
  password: prop('password'),
  headers: propOr('{}', 'headers'),
});

const arraysEqual = (a, b) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

export {
  arraysEqual,
  countActiveWebhooks,
  detailFormAttributes,
  formAttributes,
  renderName,
  renderState,
  renderUrl,
  sortActiveWebhooksFirst,
  translateGraphQLWebhookTypeToFormValues,
  translateDetailFormValuesToGraphQLWebhookType,
  translateFormValuesToGraphQLWebhookType,
};
