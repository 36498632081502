import currentUserStore from 'stores/CurrentUserStore';
import {
  INVOKE_COMMANDO_MODE_VIEW,
  INVOKE_COMMANDO_MODE_VIEW_AND_EDIT,
} from '../../../views/admin/uam-granular-permissions/constants.js';

export const requirements = {
  requiredGranularPermissions: [INVOKE_COMMANDO_MODE_VIEW, INVOKE_COMMANDO_MODE_VIEW_AND_EDIT],
};

export const verifier = () => {
  const { userStore = {} } = currentUserStore;
  const { unifyActive, canViewCommandoMode, uamGranularPermissionsActive } = userStore;
  return uamGranularPermissionsActive ? canViewCommandoMode : unifyActive;
};
