import { ACCOUNTING_GROUPS, NETWORKS } from '../../utilities/dictionary/index.js';

const {
  CROSS_BORDER_ISSUER_FEE,
  INTEGRITY_FEE,
  INTERCHANGE_PAYABLES,
  IRF_BASE_AMOUNT,
  PINDEBIT_ASSOC_FEE,
  PURCHASE_PAYABLES,
  SWITCH_FEE,
} = ACCOUNTING_GROUPS;

export const generateAmountsAndFeesMap = (network) => ({
  [PURCHASE_PAYABLES]: {
    id: PURCHASE_PAYABLES,
    label: 'Settlement amount',
    disabled: false,
  },
  [PINDEBIT_ASSOC_FEE]: {
    id: PINDEBIT_ASSOC_FEE,
    label:
      network === NETWORKS.Pulse ? 'ATM fee/Pin debit association fee' : 'Surcharge/ATM debit fee',
    disabled: false,
  },
  [CROSS_BORDER_ISSUER_FEE]: {
    id: CROSS_BORDER_ISSUER_FEE,
    label: 'Cross border fee',
    disabled: false,
  },
  [INTERCHANGE_PAYABLES]: {
    id: INTERCHANGE_PAYABLES,
    label: 'Interchange fee',
    disabled: false,
  },
  [IRF_BASE_AMOUNT]: {
    label: 'IRF base amount',
    disabled: true,
  },
  [INTEGRITY_FEE]: {
    label: 'Integrity fee',
    disabled: true,
  },
  [SWITCH_FEE]: {
    id: SWITCH_FEE,
    label: 'Switch fee',
    disabled: false,
  },
});
