import PropTypes from 'prop-types';
import React from 'react';
import { Text, Icon, Colors, HSpacer, Flex } from '@mqd/volt-base';
import { KYC_STATUS_MAP } from '../../shared-utils/constants';

export const getLatestKycResult = (kycResults) => {
  if (!kycResults || !Array.isArray(kycResults.data)) return null;
  kycResults.data.sort((a, b) => {
    if (new Date(a.last_modified_time) < new Date(b.last_modified_time)) {
      return 1;
    }
    if (new Date(a.last_modified_time) > new Date(b.last_modified_time)) {
      return -1;
    }
    return 0;
  });
  return kycResults.data[0];
};

const KycStatusIndicator = ({ kycResultStatus }) => {
  if (!kycResultStatus) {
    return <Text testId="cardholderinfo-kyc-status">Not performed</Text>;
  }

  const status = KYC_STATUS_MAP[kycResultStatus.toString().toUpperCase()];

  if (!status) {
    return <Text testId="cardholderinfo-kyc-status">Not performed</Text>;
  }

  return (
    <Flex display="flex">
      <Icon type={status.icon} overrideColor={Colors[status.color]} noHoverEffects />
      <HSpacer factor={0.5} />
      {status.display}
    </Flex>
  );
};

KycStatusIndicator.propTypes = {
  kycResultStatus: PropTypes.string,
};

KycStatusIndicator.defaultProps = {
  kycResultStatus: '',
};

export default KycStatusIndicator;
