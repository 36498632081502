import React, { useState, useRef, useEffect } from 'react';
import { Button, Flex, HSpacer } from '@mqd/volt-base';

import { Button as TempButton } from '../../../../../../../../components/button/Button';
import { ConfirmDeletionModal } from '../../../../../../../../components/default-card-template/components/edit-template/components/confirm-deletion-modal/ConfirmDeletionModal';

/**
 * An object that contains actions we can apply to a card
 * @typedef {Object} Actions
 * @property {Function} onCreateNewCard - The callback to be called when we want to create a new card
 * @property {Function} onUpdateCard - The callback to be called when we want to update a card
 * @property {Function} onDeleteCard - The callback to be called when we want to remove a card
 * @property {Function} onResetCard - The callback to be called when we want to reset (undo any changes) a card
 */

/**
 * Returns rendered react elements to be placed inside the Form component
 * @param {Object} formState
 * @param {Actions} actions
 * @param {string} cardType
 * @param {Function} switchToPreview
 * @returns {JSX.Element}
 */
export function renderControls({ formState, actions, cardType, switchToPreview }) {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const closeModal = () => setIsDeleteModalVisible(false);

  const cancelButtonRef = useRef();
  useEffect(() => {
    if (formState.submitted && !formState.allFieldsAreValid) {
      cancelButtonRef.current.focus();
    }
  }, [cancelButtonRef, formState.submitted, formState.allFieldsAreValid]);

  const handleAction = (callback) => {
    callback(formState);
    switchToPreview();
  };

  const handleSet = () =>
    handleAction(formState.temp ? actions.onCreateNewCard : actions.onUpdateCard);

  const handleDelete = () => {
    closeModal();
    handleAction(actions.onDeleteCard);
  };

  const handleCancel = () => handleAction(actions.onResetCard);

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        <Flex display="flex">
          <Button
            type="primary"
            submit
            onClick={handleSet}
            disabled={formState.submitted && !formState.allFieldsAreValid}
          >
            {`Set ${cardType}`}
          </Button>

          <HSpacer factor={1.5} />

          <Button
            type="danger"
            onClick={() => setIsDeleteModalVisible(true)}
            disabled={formState.temp}
          >
            Delete
          </Button>
        </Flex>

        {/* TODO: replace after this ticket is done https://marqeta.atlassian.net/browse/VOLTENG-45 */}
        <TempButton ref={cancelButtonRef} mod="secondary" link onClick={handleCancel}>
          Cancel
        </TempButton>
      </Flex>

      {isDeleteModalVisible && (
        <ConfirmDeletionModal
          cardType={cardType}
          closeModal={closeModal}
          handleDelete={handleDelete}
          name={formState.name}
        />
      )}
    </>
  );
}
