import React, { useMemo, useReducer, useState } from 'react';
import { ErrorModal, HSpacer, Flex, PageLoader, Tabs, Text, VSpacer } from '@mqd/volt-base';

import withProgramSelector from '../../../../components/program-selector/components/withProgramSelector.js';
import * as adjustmentActionReducer from '../../components/AdjustmentAction/reducer.js';
import SubmitAdjustmentAction from '../../components/SubmitAdjustmentAction/SubmitAdjustmentAction.js';
import {
  HashExceptionDetailsDispatch,
  HashExceptionDetailsState,
  useHashExceptionDetailsState,
} from '../../utilities/use-hash-exception-details/context.js';
import * as hashExceptionDetailsReducer from '../../utilities/use-hash-exception-details/reducer.js';
import useHashExceptionDetails from '../../utilities/use-hash-exception-details/index.js';
import RelatedTransactionsTable from '../../components/RelatedTransactionsTable/RelatedTransactionsTable.js';
import DetailsMetricsRow from '../../components/DetailsMetricsRow/DetailsMetricsRow.js';
import AdjustmentAction from '../../components/AdjustmentAction/AdjustmentAction.js';
import { maskHashId } from '../../utilities/string/index.js';
import getSource from '../../utilities/get-transaction-source.js';
import ExceptionFlowBreadcrumbs from '../../components/ExceptionFlowBreadcrumbs/ExceptionFlowBreadcrumbs.js';
import ErrorLoading from '../../components/ErrorLoading/index.js';
import {
  AdjustmentActionStateContext,
  AdjustmentActionDispatchContext,
} from '../../components/AdjustmentAction/context.js';
import {
  AdjustTransactionSettlementAndFeesDispatchContext,
  AdjustTransactionSettlementAndFeesStateContext,
} from '../../components/TransactionDetailContent/context.js';
import * as transactionSettlementAndFeesReducer from '../../components/TransactionDetailContent/reducer.js';

import ExceptionDetails from './components/ExceptionDetails.js';
import Activity from './components/Activity.js';

const tabs = {
  exceptionDetails: 'Exception details',
  relatedTransactions: 'Related transactions',
  activity: 'Activity',
};

const ExceptionDetailPage = ({ programSelector }) => {
  const [activeTab, setActiveTab] = useState(tabs.exceptionDetails);

  const [errorModalData, setErrorModalData] = useState({
    error: false,
    message: '',
  });
  const { programs } = programSelector;

  const urlParams = new URLSearchParams(window.location.search);
  const subnetwork = urlParams.get('subnetwork');
  const txHashInternalId = urlParams.get('tx_hash_internal_id');
  const [loadingCreateAdjustmentAction, setLoadingCreateAdjustmentAction] = useState(false);

  useHashExceptionDetails({
    tx_hash_internal_id: txHashInternalId,
    programs,
  });

  const {
    data,
    error: { hasError },
    hashId,
    loading,
    netRefId,
    notes,
    program,
    settlementDate,
  } = useHashExceptionDetailsState();
  const { network, source } = data?.common_data || {};
  const humanSource = useMemo(() => {
    return getSource({ source, network, subnetwork });
  }, [source, network, subnetwork]);
  const hasData = useMemo(() => Boolean(data?.transactions?.length), [data]);

  const handleTabChange = (tab) => setActiveTab(tab);
  const handleViewAllActivity = () => setActiveTab(tabs.activity);

  return (
    <div>
      <Flex>
        <Flex flexDirection="row" justifyContent="space-between">
          <ExceptionFlowBreadcrumbs hashId={hashId} />
          {loading || (!hasData && hasError) ? null : (
            <Flex flexDirection="row">
              <AdjustmentAction
                width="100%"
                loadingCreateAdjustmentAction={loadingCreateAdjustmentAction}
              />
              <HSpacer factor={2} />
              <SubmitAdjustmentAction
                detailPageLoading={loading}
                internalHashId={txHashInternalId}
                setErrorModalData={setErrorModalData}
                netRefId={netRefId}
                setLoadingCreateAdjustmentAction={setLoadingCreateAdjustmentAction}
              />
            </Flex>
          )}
        </Flex>
      </Flex>
      {loading ? (
        <PageLoader active={loading} />
      ) : !hasData && hasError ? (
        <>
          <VSpacer factor={2} />
          <ErrorLoading />
        </>
      ) : (
        <>
          <VSpacer factor={3} />
          <Flex direction="column">
            <Text type="h3" id="exceptionDetailPageHashId">
              Hash ID {maskHashId(hashId)}
            </Text>
          </Flex>
          <VSpacer factor={3} />
          <DetailsMetricsRow source={humanSource} showSettledTotal />
          <VSpacer factor={3} />
          <Tabs
            activeTab={activeTab}
            tabs={[tabs.exceptionDetails, tabs.relatedTransactions, tabs.activity]}
            onTabChange={handleTabChange}
          >
            <VSpacer factor={3} />
            {activeTab === tabs.exceptionDetails && (
              <ExceptionDetails
                commonData={data.common_data}
                exceptionType={data.exception_type}
                handleViewAllActivity={handleViewAllActivity}
                hashId={hashId}
                loading={loading}
                notes={notes}
                program={program}
                settledDetails={data.settled_details}
                settlementDate={settlementDate}
                tx_hash_internal_id={txHashInternalId}
              />
            )}
            {activeTab === tabs.relatedTransactions && (
              <RelatedTransactionsTable
                commonData={data.common_data}
                loading={loading}
                netRefId={netRefId}
                network={network}
                subnetwork={subnetwork}
                settlementDate={settlementDate}
                transactions={data.transactions}
              />
            )}
            {activeTab === tabs.activity && (
              <Activity notes={notes} tabView tx_hash_internal_id={txHashInternalId} />
            )}
          </Tabs>
        </>
      )}
      {errorModalData.error && (
        <ErrorModal
          heading="Submission failed"
          buttonText="Dismiss"
          hideModal={() => setErrorModalData({ error: false, message: '' })}
        >
          {errorModalData.message || 'Hash ID could not be submitted. Try again.'}
        </ErrorModal>
      )}
    </div>
  );
};

const ExceptionDetailPageWrapper = (props) => {
  const [state, dispatch] = useReducer(
    hashExceptionDetailsReducer.reducer,
    hashExceptionDetailsReducer.initialState
  );
  const [adjustmentActionState, adjustmentActionDispatch] = useReducer(
    adjustmentActionReducer.reducer,
    adjustmentActionReducer.initialState
  );
  const [transactionSettlementAndFeesState, transactionSettlementAndFeesDispatch] = useReducer(
    transactionSettlementAndFeesReducer.reducer,
    transactionSettlementAndFeesReducer.initialState
  );
  return (
    <AdjustmentActionStateContext.Provider value={adjustmentActionState}>
      <AdjustmentActionDispatchContext.Provider value={adjustmentActionDispatch}>
        <AdjustTransactionSettlementAndFeesDispatchContext.Provider
          value={transactionSettlementAndFeesDispatch}
        >
          <AdjustTransactionSettlementAndFeesStateContext.Provider
            value={transactionSettlementAndFeesState}
          >
            <HashExceptionDetailsDispatch.Provider value={dispatch}>
              <HashExceptionDetailsState.Provider value={state}>
                <ExceptionDetailPage {...props} />
              </HashExceptionDetailsState.Provider>
            </HashExceptionDetailsDispatch.Provider>
          </AdjustTransactionSettlementAndFeesStateContext.Provider>
        </AdjustTransactionSettlementAndFeesDispatchContext.Provider>
      </AdjustmentActionDispatchContext.Provider>
    </AdjustmentActionStateContext.Provider>
  );
};

export default withProgramSelector(ExceptionDetailPageWrapper);
