import { decorate, observable, computed } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';
import BusinessIdentityIndividualStore from './BusinessIdentityIndividualStore';
import BusinessIdentityBusinessStore from './BusinessIdentityBusinessStore';

class BusinessIdentityCheckStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
    this.loadFullInfoResult(args);
  }
  // values
  status: string = '';
  cardholder_token: string = '';
  kyb_token: string = '';
  status: string = '';
  attester_name: string = '';
  attester_title: string = '';
  attestation_date: string = '';
  attestation_consent: string = '';
  attestation_date: string = '';
  proprietor_is_beneficial_owner: string = '';
  business: BusinessIdentityBusinessStore = {};
  proprietor: BusinessIdentityIndividualStore = {};
  beneficial_owner1: BusinessIdentityIndividualStore = {};
  beneficial_owner2: BusinessIdentityIndividualStore = {};
  beneficial_owner3: BusinessIdentityIndividualStore = {};
  beneficial_owner4: BusinessIdentityIndividualStore = {};

  loadFullInfoResult(data) {
    this.load(data);
    const business = this.dig(data, 'business');
    if (business) {
      this.loadAndConstructItem('business', business, BusinessIdentityBusinessStore);
    }
    const proprietor = this.dig(data, 'proprietor');
    if (proprietor) {
      this.loadAndConstructItem('proprietor', proprietor, BusinessIdentityIndividualStore);
    }
    const beneficial_owner1 = this.dig(data, 'beneficial_owner1');
    if (beneficial_owner1) {
      this.loadAndConstructItem(
        'beneficial_owner1',
        beneficial_owner1,
        BusinessIdentityIndividualStore
      );
    }
    const beneficial_owner2 = this.dig(data, 'beneficial_owner2');
    if (beneficial_owner2) {
      this.loadAndConstructItem(
        'beneficial_owner2',
        beneficial_owner2,
        BusinessIdentityIndividualStore
      );
    }
    const beneficial_owner3 = this.dig(data, 'beneficial_owner3');
    if (beneficial_owner3) {
      this.loadAndConstructItem(
        'beneficial_owner3',
        beneficial_owner3,
        BusinessIdentityIndividualStore
      );
    }
    const beneficial_owner4 = this.dig(data, 'beneficial_owner4');
    if (beneficial_owner4) {
      this.loadAndConstructItem(
        'beneficial_owner4',
        beneficial_owner4,
        BusinessIdentityIndividualStore
      );
    }
  }
}

decorate(BusinessIdentityCheckStore, {
  // values
  status: observable,
  cardholder_token: observable,
  kyb_token: observable,
  status: observable,
  attester_name: observable,
  attester_title: observable,
  attestation_date: observable,
  attestation_consent: observable,
  proprietor_is_beneficial_owner: observable,
  business: observable,
  proprietor: observable,
  beneficial_owner1: observable,
  beneficial_owner2: observable,
  beneficial_owner3: observable,
  beneficial_owner4: observable,
});

export default BusinessIdentityCheckStore;
