// @flow
import { decorate, observable, computed } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';

class CardPersonalizationStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.load({
      ...args,
      name_line_1: this.dig(args, 'text', 'name_line_1', 'value'),
      name_line_2: this.dig(args, 'text', 'name_line_2', 'value'),
      name_line_3: this.dig(args, 'text', 'name_line_3', 'value'),
    });
  }
  // values
  // just using normal objects here instead of stores
  // becuase the objects are so small and there is
  // so many of them
  text: Object = {
    name_line_1: { value: '' },
    name_line_2: { value: '' },
  };
  images: Object = {
    card: { name: '', thermal_color: '' },
    carrier: { name: '', message_1: '' },
    signature: { name: '' },
    carrier_return_window: { name: '' },
  };
  carrier: Object = {
    template_id: '',
    logo_file: '',
    logo_thumbnail_file: '',
    message_file: '',
    message_line: '',
  };
  perso_type: string = '';
  // flat values
  name_line_1: string = '';
  name_line_2: string = '';
  name_line_3: string = '';
  imagesCardName: string = '';
  imagesCardThermalColor: string = '';
  imagesCarrierName: string = '';
  imagesCarrierMessageOne: string = '';
  imagesSignatureName: string = '';
  imagesCarrierReturnWindow: string = '';
  carrierTemplateId: string = '';
  carrierLogoFile: string = '';
  carrierLogoThumbnailFile: string = '';
  carrierMessageFile: string = '';
  carrierMessageLine: string = '';

  // actions

  // computed
  get updateParams(): string {
    if (!this.valuesAreUpdated) return null;
    return {
      text: {
        name_line_1: { value: this.currentValue('name_line_1') },
        name_line_2: { value: this.currentValue('name_line_2') },
      },
      images: {
        card: {
          name: this.currentValue('imagesCardName'),
          thermal_color: this.currentValue('imagesCardThermalColor'),
        },
        carrier: {
          name: this.currentValue('imagesCarrierName'),
          message_1: this.currentValue('imagesCarrierMessageOne'),
        },
        signature: {
          name: this.currentValue('imagesSignatureName'),
        },
        carrier_return_window: {
          name: this.currentValue('imagesCarrierReturnWindow'),
        },
      },
      carrier: {
        template_id: this.currentValue('carrierTemplateId'),
        logo_file: this.currentValue('carrierLogoFile'),
        logo_thumbnail_file: this.currentValue('carrierLogoThumbnailFile'),
        message_file: this.currentValue('carrierMessageFile'),
        message_line: this.currentValue('carrierMessageLine'),
      },
      perso_type: this.perso_type,
    };
  }

  get flatObject(): Object {
    const object = {
      ...this.flatText,
      ...this.flatImages,
      ...this.flatCarrier,
    };
    this.valuesUpdatedArray.forEach((attr) => {
      object[attr] = this[attr];
    });
    return object;
  }

  get initialFlatObject(): Object {
    return {
      ...this.flatText,
      ...this.flatImages,
      ...this.flatCarrier,
    };
  }

  get flatText() {
    const name_line_1 = this.dig(this.text, 'name_line_1', 'value') || '';
    const name_line_2 = this.dig(this.text, 'name_line_2', 'value') || '';
    return {
      name_line_1,
      name_line_2,
    };
  }

  get flatImages() {
    const imagesCardName = this.dig(this.images, 'card', 'name') || '';
    const imagesCardThermalColor = this.dig(this.images, 'card', 'thermal_color') || '';
    const imagesCarrierName = this.dig(this.images, 'carrier', 'name') || '';
    const imagesCarrierMessageOne = this.dig(this.images, 'carrier', 'message_1') || '';
    const imagesSignatureName = this.dig(this.images, 'signature', 'name') || '';
    const imagesCarrierReturnWindow = this.dig(this.images, 'carrier_return_window', 'name') || '';
    return {
      imagesCardName,
      imagesCardThermalColor,
      imagesCarrierName,
      imagesCarrierMessageOne,
      imagesSignatureName,
      imagesCarrierReturnWindow,
    };
  }

  get flatCarrier() {
    const carrierTemplateId = this.dig(this.carrier, 'template_id') || '';
    const carrierLogoFile = this.dig(this.carrier, 'logo_file') || '';
    const carrierLogoThumbnailFile = this.dig(this.carrier, 'logo_thumbnail_file') || '';
    const carrierMessageFile = this.dig(this.carrier, 'message_file') || '';
    const carrierMessageLine = this.dig(this.carrier, 'message_line') || '';
    return {
      carrierTemplateId,
      carrierLogoFile,
      carrierLogoThumbnailFile,
      carrierMessageFile,
      carrierMessageLine,
    };
  }

  // helpers
  currentValue(flatAttribute) {
    if (this.valuesUpdatedArray.includes(flatAttribute)) {
      return this[flatAttribute];
    } else {
      return this.initialFlatObject[flatAttribute];
    }
  }
}

decorate(CardPersonalizationStore, {
  // values
  text: observable,
  images: observable,
  carrier: observable,
  perso_type: observable,
  // flat values
  name_line_1: observable,
  name_line_2: observable,
  name_line_3: observable,
  imagesCardName: observable,
  imagesCardThermalColor: observable,
  imagesCarrierName: observable,
  imagesCarrierMessageOne: observable,
  imagesSignatureName: observable,
  imagesCarrierReturnWindow: observable,
  carrierTemplateId: observable,
  carrierLogoFile: observable,
  carrierLogoThumbnailFile: observable,
  carrierMessageFile: observable,
  carrierMessageLine: observable,

  // actions

  // computed
  flatObject: computed,
  initialFlatObject: computed,
  flatText: computed,
  flatImages: computed,
  flatCarrier: computed,
  updateParams: computed,
});

export default CardPersonalizationStore;
