export function createButtonKeyDownHandler(callback) {
  return (event) => {
    const isEnterOrSpaceKey = ['Space', 'Enter'].includes(event.nativeEvent.code);
    const doesTargetMatch = event.target === event.currentTarget;

    if (isEnterOrSpaceKey && doesTargetMatch) {
      event.preventDefault();
      callback(event);
    }
  };
}
