import { decorate, observable, computed } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';

class IdentificationStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
  }
  // values
  type: string = '';
  value: string = '';
  expiration_date: string = '';
  loading: boolean = false;
  error: string = null;

  get valueWithStars() {
    try {
      return this.value.replace(/_/g, '*');
    } catch (e) {
      ('');
    }
  }
}

decorate(IdentificationStore, {
  type: observable,
  value: observable,
  expiration_date: observable,
  loading: observable,
  error: observable,
  valueWithStars: computed,
});

export default IdentificationStore;
