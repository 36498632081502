/* eslint-disable camelcase */
import React from 'react';
import { observer } from 'mobx-react';
import { Card, Button, HSpacer, LoadingOverlay } from '@mqd/volt-base';
import PropTypes from 'prop-types';
import { ParentDetailCard } from '@mq/voltron-parent';

class BusinessPrimaryContactCard extends ParentDetailCard {
  constructor(props) {
    super(props);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.state = {
      loading: false,
      editActive: false,
    };
  }

  get primaryContactStore() {
    const { businessStore = {} } = this.props;
    const { primary_contact = {} } = businessStore;
    return primary_contact || {};
  }

  get businessStore() {
    const { businessStore } = this.props;
    return businessStore || {};
  }

  async handleSave() {
    const { updateBusiness } = this.businessStore;
    this.setState({ loading: true });
    const success = await updateBusiness();
    if (success) {
      this.setState({ loading: false, editActive: false });
    } else {
      this.setState({ loading: false });
    }
  }

  renderHeaderButtons() {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button type="outline" onClick={this.handleCancel}>
          Cancel
        </Button>
        <HSpacer />
        <Button onClick={this.handleSave}>Save</Button>
      </div>
    );
  }

  handleCancel() {
    const { revertUpdates } = this.businessStore;
    revertUpdates && revertUpdates();
    this.setState({ editActive: false });
  }

  renderInfo() {
    const { editable } = this.props;
    const { editActive } = this.state;
    const { full_name, title, department, phone, extension, fax, mobile, email, updateForSave } =
      this.primaryContactStore;
    return (
      <Card
        title="Primary Contact"
        testId="business-primary-contact-card"
        controls={editable && (editActive ? this.renderHeaderButtons() : this.renderControl())}
      >
        {this.renderSnippet({
          title: 'Name',
          content: full_name,
          editActive: editActive,
          onChange: (e) => updateForSave('full_name', e.target.value),
          tooltipContent: 'Name of primary contact.',
        })}
        {this.renderSnippet({
          title: 'Title',
          content: title,
          editActive: editActive,
          onChange: (e) => updateForSave('title', e.target.value),
          tooltipContent: 'Title of primary contact.',
        })}
        {this.renderSnippet({
          title: 'Department',
          content: department,
          editActive: editActive,
          onChange: (e) => updateForSave('department', e.target.value),
          tooltipContent: 'Department of primary contact.',
        })}
        {this.renderSnippet({
          title: 'Phone',
          content: phone,
          editActive: editActive,
          onChange: (e) => updateForSave('phone', e.target.value),
          tooltipContent: '10 digit telephone number of primary contact.',
        })}
        {this.renderSnippet({
          title: 'Extension',
          content: extension,
          editActive: editActive,
          onChange: (e) => updateForSave('extension', e.target.value),
          tooltipContent: 'Telephone extension of primary contact.',
        })}
        {this.renderSnippet({
          title: 'Mobile Phone',
          content: mobile,
          editActive: editActive,
          onChange: (e) => updateForSave('mobile', e.target.value),
          tooltipContent: '10 digit mobile number of primary contact.',
        })}
        {this.renderSnippet({
          title: 'Fax Number',
          content: fax,
          editActive: editActive,
          onChange: (e) => updateForSave('fax', e.target.value),
          tooltipContent: '10 digit fax telephone number of primary contact.',
        })}
        {this.renderSnippet({
          title: 'Email',
          content: email,
          editActive: editActive,
          onChange: (e) => updateForSave('email', e.target.value),
          tooltipContent: 'Email address of primary contact.',
        })}
      </Card>
    );
  }

  render() {
    const { loading } = this.state;
    return <LoadingOverlay active={loading}>{this.renderInfo()}</LoadingOverlay>;
  }
}

BusinessPrimaryContactCard.propTypes = {
  businessStore: PropTypes.any,
  editable: PropTypes.bool,
};

BusinessPrimaryContactCard.defaultProps = {
  businessStore: {},
  editable: false,
};

export default observer(BusinessPrimaryContactCard);
