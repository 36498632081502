import { action, decorate, observable } from 'mobx';

class ToastStore {
  toastMessage = '';
  toastType = 'success';
  isToastVisible = false;

  showToast(message, type) {
    this.toastMessage = message;
    this.toastType = type;
    this.isToastVisible = true;
  }

  hideToast() {
    this.toastMessage = '';
    this.isToastVisible = false;
  }
}

decorate(ToastStore, {
  // values
  toastMessage: observable,
  toastType: observable,
  isToastVisible: observable,

  // actions
  showToast: action.bound,
  hideToast: action.bound,
});

const toastStore = new ToastStore();

export { toastStore };
