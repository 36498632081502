import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { ParentTable, containerHeightHOC } from '@mq/voltron-parent';
import { Table } from '@mq/voltron-table';
import { Text, LoadingOverlay, VSpacer, Card } from '@mqd/volt-base';
import CardProductsStore from './../stores/CardProductsStore';
import BusinessStore from './../stores/BusinessStore';
import CardholderStore from './../stores/CardholderStore';

// total of navbar and all spacing above the table
const heightOffset = 310;

class CardProductsTable extends ParentTable {
  constructor(props) {
    super(props);
    const { businessToken, userToken } = props;
    this.storeConstructor = CardProductsStore;
    this.instantiateAdditionalStore('businessStore', BusinessStore, { token: businessToken });
    this.instantiateAdditionalStore('cardholderStore', CardholderStore, { token: userToken });
    this.state = {
      ...this.colEditState(props, this.columns),
    };
  }

  get columns() {
    return [
      {
        accessor: 'last_modified_time',
        header: 'Last Modified',
        key: 'last_modified_time',
      },
      {
        accessor: 'name',
        header: 'Name',
        key: 'name',
      },
      {
        accessor: 'payment_instrument',
        header: 'Payment instrument',
        key: 'payment_instrument',
      },
      {
        accessor: 'bin_prefix',
        header: 'BIN prefix',
        key: 'bin_prefix',
      },
      {
        accessor: 'token',
        header: 'Token',
        key: 'token',
      },
    ];
  }

  get isCardholder() {
    return this.props.userToken;
  }

  get renderTitle() {
    const { business_name_legal } = this.businessStore;
    const { first_name, last_name, token } = this.cardholderStore;
    const { businessToken, userToken } = this.props;

    const cardholderTitle = () => {
      if (first_name && last_name) {
        return `Create a card for ${first_name} ${last_name}`;
      } else {
        return `Create a card for user '${userToken}'`;
      }
    };

    const businessTitle = () => {
      if (business_name_legal) {
        return `Create a card for ${business_name_legal} users`;
      } else {
        return `Create a card for business '${businessToken}' users`;
      }
    };

    const text = this.isCardholder ? cardholderTitle() : businessTitle();

    return <Text type="h3">{text}</Text>;
  }

  render() {
    const { cardProductsTableList, loading } = this.store;
    const { loading: businessStoreLoading } = this.businessStore;
    const { loading: cardholderStoreLoading } = this.cardholderStore;
    const { onRowClick, setRowLink, dynamicHeight } = this.props;
    const { columns } = this.state;

    return (
      <LoadingOverlay active={loading || businessStoreLoading || cardholderStoreLoading}>
        <div>
          {this.renderTitle}
          <VSpacer factor={2} />
          <Text type="body-sm">
            To get starting creating a new card for this user, select a card product below.
          </Text>
          <VSpacer factor={2} />
          <Table
            testId="card-products-table"
            data={cardProductsTableList}
            columns={columns}
            height={dynamicHeight}
            onRowClick={onRowClick}
            setRowLink={setRowLink}
          />
          {this.renderPagination()}
        </div>
      </LoadingOverlay>
    );
  }
}

CardProductsTable.propTypes = {
  activeUserInfo: PropTypes.object,
  autoHydrate: PropTypes.bool,
  handleRedirect: PropTypes.func,
  height: PropTypes.number,
  store: PropTypes.object,
  storeInitArgs: PropTypes.object,
  onRowClick: PropTypes.func,
  setRowLink: PropTypes.func,
  onStoreConstruction: PropTypes.func,
  userStore: PropTypes.object,
  localStorageKey: PropTypes.string,
};

CardProductsTable.defaultProps = {
  activeUserInfo: {},
  handleRedirect: (store, tab) => {},
  height: 800,
  onRowClick: () => {},
  setRowLink: null,
  onStoreConstruction: null,
  store: null,
  storeInitArgs: { limit: 100 },
  userStore: {},
  localStorageKey: 'CardProductsTable',
};

export default containerHeightHOC(observer(CardProductsTable), heightOffset);
