import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import CardsTable from './../cards-table/CardsTable';

class CardsTab extends Component {
  render() {
    const {
      uamGranularPermissionsActive,
      storeInitArgs,
      onRowClick,
      onStoreConstruction,
      localStorageKey,
      hasRoleInArrayForActiveProgram,
      reportCardLostStolenActive,
      canViewCardDetails,
    } = this.props;
    return (
      <CardsTable
        uamGranularPermissionsActive={uamGranularPermissionsActive}
        storeInitArgs={storeInitArgs}
        canViewCardDetails={canViewCardDetails}
        onRowClick={onRowClick}
        onStoreConstruction={onStoreConstruction}
        localStorageKey={localStorageKey}
        hasRoleInArrayForActiveProgram={hasRoleInArrayForActiveProgram}
        reportCardLostStolenActive={reportCardLostStolenActive}
      />
    );
  }
}

CardsTab.propTypes = {
  uamGranularPermissionsActive: PropTypes.bool,
  storeInitArgs: PropTypes.object,
  onRowClick: PropTypes.func,
  onStoreConstruction: PropTypes.func,
  hasRoleInArrayForActiveProgram: PropTypes.func,
  localStorageKey: PropTypes.string,
  reportCardLostStolenActive: PropTypes.bool,
};

CardsTab.defaultProps = {
  uamGranularPermissionsActive: false,
  storeInitArgs: {},
  onRowClick: () => {},
  onStoreConstruction: () => {},
  hasRoleInArrayForActiveProgram: () => {},
  localStorageKey: '',
  reportCardLostStolenActive: undefined,
};

export default observer(CardsTab);
