// @flow
import { decorate, observable, action, runInAction, computed } from 'mobx';
import gqlUtils from '@mqd/graphql-utils';
import DigitalWalletTokenStore from '../stores/DigitalWalletTokenStore';
import { ParentTableStore } from '@mq/voltron-parent';
import {
  TERMINATE,
  ACTIVE,
  REQUESTED,
  REQUEST_DECLINED,
  SUSPENDED,
  TERMINATED,
} from '../constants';

const { fragments } = gqlUtils;

export default class DigitalWalletTokensTableStore extends ParentTableStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
  }
  // values
  items: Array<DigitalWalletTokenStore> = [];
  count: number = 2000;
  start_date: string = '';
  end_date: string = '';
  showTerminateModal: Boolean = false;
  activeDWTTermination: DigitalWalletTokenStore = {};

  get activeItems(): Array<DigitalWalletTokenStore> {
    return this.items.filter((item) => item.state === ACTIVE);
  }

  get inactiveItems(): Array<DigitalWalletTokenStore> {
    return this.items.filter((item) => item.state !== ACTIVE);
  }

  // actions
  async hydrate() {
    if (!this.validHydrate) {
      return;
    }

    this.loading = true;
    const result = await this.gqlQuery(
      `query cardDigitalWalletTokens${this.hydrateOuterParams} {
          cardDigitalWalletTokens${this.hydrateInnerParams} {
            data {
              ...digitalWalletTokenBaseInfo

            }
            ${this.paginationAttributes}
          }
        }
        ${fragments.digitalWalletTokenBaseInfo}
      `,
      this.hydrateParams
    );
    runInAction(() => {
      const dwts = this.extract(result, 'cardDigitalWalletTokens');
      this.processPaginationResponse(dwts, DigitalWalletTokenStore);
      this.loading = false;
    });
  }

  async handleRowActionClick(actionCallback) {
    if (actionCallback instanceof Function) {
      this.loading = true;
      const result = await actionCallback();
      if (result) {
        this.loading = false;
      }
    }
  }

  activateTerminationModal(dwt) {
    if (dwt instanceof DigitalWalletTokenStore) {
      this.showTerminateModal = true;
      this.activeDWTTermination = dwt;
    }
  }

  hideTerminateModal() {
    this.showTerminateModal = false;
    this.activeDWTTermination = null;
  }

  async terminateActiveDWT() {
    if (this.activeDWTTermination instanceof DigitalWalletTokenStore) {
      const { terminateToken } = this.activeDWTTermination;
      this.loading = true;
      await terminateToken();
      this.hideTerminateModal();
      this.loading = false;
    }
  }

  // helpers
  configureAllowedActions(dwtRow = {}) {
    const { allowedActions } = dwtRow;
    if (!Array.isArray(allowedActions)) return [];

    return allowedActions.map(({ text, onClick }) => ({
      text,
      onClick:
        text === TERMINATE
          ? () => this.activateTerminationModal(dwtRow)
          : () => this.handleRowActionClick(onClick),
    }));
  }

  // computed
  get sortedByState() {
    if (this.activeSort) return this.currentList;

    const order = [ACTIVE, REQUESTED, REQUEST_DECLINED, SUSPENDED, TERMINATED];
    const idx = (i) => order.indexOf(i);

    return this.currentList.sort((a, b) => {
      const first = a.state;
      const second = b.state;
      return idx(first) > idx(second) ? 1 : -1;
    });
  }

  get validHydrate() {
    return this.dig(this.queryParams, 'card_token', 'val');
  }

  get hydrateOuterParams() {
    return `(${this.configureOuterQueryParams(this.queryParams)} $count: Int $start_index: Int)`;
  }

  get hydrateInnerParams() {
    return `(${this.configureInnerQueryParams(
      this.queryParams
    )} count: $count start_index: $start_index)`;
  }
}

decorate(DigitalWalletTokensTableStore, {
  // values
  items: observable,
  count: observable,
  sort_by: observable,
  cardholder_token: observable,
  start_date: observable,
  end_date: observable,
  state: observable,
  type: observable,
  showTerminateModal: observable,
  activeDWTTermination: observable,

  // actions
  hydrate: action.bound,
  handleRowActionClick: action.bound,
  activateTerminationModal: action.bound,
  terminateActiveDWT: action.bound,
  hideTerminateModal: action.bound,

  // helpers
  configureAllowedActions: action.bound,

  // computed
  sortedByState: computed,
  validHydrate: computed,
  hydrateOuterParams: computed,
  hydrateInnerParams: computed,
});
