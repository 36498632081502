import currentUserStore from 'stores/CurrentUserStore';

export const requirements = {
  flipFlopRequirements: ['tokenization-beta'],
};

export const verifier = () => {
  const { userStore = {} } = currentUserStore;

  return userStore.tokenizationActive;
};
