import { PROGRAMS_META_DATA_KEY } from './constants.js';
import logger from 'utils/logger';
import { secureStorage } from '@mq/volt-amc-container';

export default function loadProgramMetaDataFromLocalStorage() {
  try {
    return JSON.parse(secureStorage.getItem(PROGRAMS_META_DATA_KEY));
  } catch (e) {
    logger.error('No active program in localStorage');
  }
}
