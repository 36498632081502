import number from './index.js';
import getDefaultFormattingOptions from './get-default-formatting-options.js';

export default function calculateAndFormatScaledDecimal(scaledDecimal, currencyCode) {
  const override = currencyCode ? { symbol: currencyCode } : {};
  const defaultFormattingOptions = getDefaultFormattingOptions(override);
  const result = number(scaledDecimal, defaultFormattingOptions, true);

  return typeof result === 'string' ? result.trim() : result;
}
