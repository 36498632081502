import React from 'react';
import PropTypes from 'prop-types';
import { Button, Text, VSpacer, LoadingOverlay, Colors, Flex } from '@mqd/volt-base';
import s from './ExportTransactionsOverlay.module.css';

const ExportTransactionsOverlay = ({ loadingExport, cancelExportRequest }) => {
  if (!loadingExport) return null;
  return (
    <div className={s.container} style={{ backgroundColor: Colors.white }}>
      <LoadingOverlay active={true} />
      <VSpacer factor={3} />
      <Flex
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        className={s.loadingContent}
      >
        <Text type="h5">Generating transactions...</Text>
        <VSpacer factor={3} />

        <Text type="body-lg">
          {`Large transactions can take a minute or two to generate. Thank you for your patience! If this message persists for more than a few minutes, contact your Marqeta representative.`}
        </Text>
        <VSpacer factor={3} />
        <Button onClick={cancelExportRequest}>Cancel request</Button>
      </Flex>
    </div>
  );
};

ExportTransactionsOverlay.propTypes = {
  loadingExport: PropTypes.bool,
  cancelExportRequest: PropTypes.func,
};

export default ExportTransactionsOverlay;
