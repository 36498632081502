import { decorate, observable } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';

class ProgramGatewayFundingSourceStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
  }
  // values
  name: string = '';
  url: string = '';
  version: string = '';
  active: boolean = '';
  token: string = '';
  created_time: string = '';
  last_modified_time: string = '';
  account: string = '';
  timeout_millis: number = '';
}

decorate(ProgramGatewayFundingSourceStore, {
  // values
  name: observable,
  url: observable,
  version: observable,
  active: observable,
  token: observable,
  created_time: observable,
  last_modified_time: observable,
  account: observable,
  timeout_millis: observable,
});

export default ProgramGatewayFundingSourceStore;
