import React, { createContext } from 'react';

const WebhookContext = createContext({
  defaultWebhookValues: {},
  error: null,
  onSave: () => {},
  saving: false,
  webhook: {},
  onUpdateWebhook: () => {},
});

const WebhookProvider = ({
  children,
  defaultWebhookValues = {},
  error = null,
  onSave = () => {},
  saving = false,
  onUpdateWebhook = () => {},
}) => {
  return (
    <WebhookContext.Provider
      value={{
        defaultWebhookValues,
        onSave,
        saving,
        error,
        onUpdateWebhook,
      }}
    >
      {children}
    </WebhookContext.Provider>
  );
};

export { WebhookContext, WebhookProvider };
