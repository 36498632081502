import currentUserStore from 'stores/CurrentUserStore';
import hasRoleForAtLeastOneProgram from '../../helpers/hasRoleForAtLeastOneProgram';
import hasRoleForSelectedProgram from '../../helpers/hasRoleForSelectedProgram';
import { FlipFlop } from './../../../utils';
import { UNIFY_BETA } from './constants/featureFlags';
import {
  CARDHOLDER_SUPPORT,
  DELIVERY_INTERNAL,
  MARQETA_ADMIN_INTERNAL,
  PRODUCTION_SUPPORT_INTERNAL,
  PROGRAM_ADMIN,
} from './constants/redseaRoles';
import { CARD_PRODUCTS_VIEW } from '../../../views/admin/uam-granular-permissions/constants.js';

const requiredUserPermissions = [
  MARQETA_ADMIN_INTERNAL,
  PROGRAM_ADMIN,
  DELIVERY_INTERNAL,
  PRODUCTION_SUPPORT_INTERNAL,
  CARDHOLDER_SUPPORT,
  'compliance-internal',
  'compliance-program-managed',
  'compliance-processor-only',
  'risk-internal',
  'fulfillment-internal',
  'settlement-internal',
  'supplier-payments-manager',
  'read-only',
  'access-manager',
];

const requiredGranularPermissions = [CARD_PRODUCTS_VIEW];

export const requirements = {
  flipFlopRequirements: [UNIFY_BETA],
  requiredUserPermissions,
  requiredGranularPermissions,
};

export const verifier = () => {
  const {
    userStore: {
      hasRole,
      cardProductViewActive,
      uamGranularPermissionsActive,
    },
  } = currentUserStore;

  const hasSufficientRoles = requiredUserPermissions.some((role) =>
    hasRoleForAtLeastOneProgram(hasRole(role))
  );

  const canViewCardProduct = uamGranularPermissionsActive
    ? cardProductViewActive
    : hasSufficientRoles;

  return FlipFlop.get(UNIFY_BETA, false) && canViewCardProduct;
};

export const programVerifier = () => {
  const {
    userStore: {
      hasRole,
      cardProductViewActive,
      uamGranularPermissionsActive,
    },
  } = currentUserStore;

  const hasSufficientRolesForProgram = requiredUserPermissions.some((role) =>
    hasRoleForSelectedProgram(hasRole(role))
  );

  const canViewCardProduct = uamGranularPermissionsActive
    ? cardProductViewActive
    : hasSufficientRolesForProgram;

  return FlipFlop.get(UNIFY_BETA, false) && canViewCardProduct;
};
