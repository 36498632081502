import React from 'react';

const defaultValue = {
  height: null,
  width: null,
};

const StyleContext = React.createContext(defaultValue);

export default StyleContext;
