// Takes an element, and an array, and toggles the element's inclusion in the array
// Returns an array that contains the element if it did not previously
// Otherwise, returns an array without the element if previously contained

const toggleElInArray = (el, arr: Array) => {
  if (!arr) {
    return null;
  }

  // Avoid side-effects by clone the arr (not a deep clone!)
  const clonedArr = arr.slice(0);

  // Check if the el already exists in the arr
  const elIndexInArray = clonedArr.indexOf(el);
  const elExistsInArray = elIndexInArray !== -1;

  if (elExistsInArray) {
    // Remove the element
    clonedArr.splice(elIndexInArray, 1);
  } else {
    // Add the element
    clonedArr.push(el);
  }

  return clonedArr;
};

export default toggleElInArray;
