// @flow
import { decorate, observable, action } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';

class CardTransitionStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    // this.loadAndConstruct(args);
  }
  // values
  token: string = '';
  card_token: string = '';
  cardholder_token: string = '';
  state: string = '';
  reason: string = '';
  reason_code: string = '';
  channel: string = '';
  fulfillment_status: string = '';
  type: string = '';
  created_time: string = '';
  last_modified_time: string = '';
  card_product_token: string = '';
  last_four: string = '';
  pan: string = '';
  expiration: string = '';
  expiration_time: string = '';
  barcode: string = '';
  pin_is_set: boolean = false;
  bulk_issuance_token: string = '';
  reissue_pan_from_card_token: string = '';
  expedite: boolean = false;

  // Objects
  // card: string = '';
  // cardholder: string = '';
  // fulfillment: string = '';
  // validations: string = '';
}

decorate(CardTransitionStore, {
  // values
  token: observable,
  card_token: observable,
  cardholder_token: observable,
  state: observable,
  reason: observable,
  reason_code: observable,
  channel: observable,
  fulfillment_status: observable,
  type: observable,
  created_time: observable,
  last_modified_time: observable,
  card_product_token: observable,
  last_four: observable,
  pan: observable,
  expiration: observable,
  expiration_time: observable,
  barcode: observable,
  pin_is_set: observable,
  bulk_issuance_token: observable,
  reissue_pan_from_card_token: observable,
  card: observable,
  expedite: observable,

  // Objects
  // validations
  // fulfillment
  // cardholder

  // actions
  loadAndConstruct: action.bound,

  // computed
});

export default CardTransitionStore;
