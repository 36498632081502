import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { googleAnalytics, PageProgressBar } from '@mq/volt-amc-container';
import routerStore from 'stores/router/RouterStore';
import applicationDataStore from 'stores/application-data/ApplicationDataStore';
import currentUserStore from 'stores/CurrentUserStore';
import notificationStore from 'stores/NotificationStore';
import loadingStore from 'stores/LoadingStore';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ErrorBoundary from '../../../views/error-views/ErrorBoundary.js';
import {
  ActingAs,
  ActiveDownloads,
  CurrentRoute,
  LoadingOverlayWrapper,
  Notifications,
} from './index.js';
import {
  addActiveProgramToUrl,
  addActiveEnvironmentToUrl,
  ensureValidFeatureFlagsIsSet,
} from './../utils/index.js';
import { StoreContext } from '@mqd/volt-contexts';
import { RouterGoContext } from '../../../components/router-go/context.js';
import { RouterParamsContext } from '../../../components/router-params/context.js';
import { RouterUrlContext } from '../../../components/router-url/context.js';
import withProgramConfig from '../../../utils/program-config/components/withProgramConfig.js';
import withProgramConfigActions from '../../../utils/program-config/components/withProgramConfigActions.js';
import { useProgramConfigStateContext } from '../../../utils/program-config/context.js';

import { LeavingModal } from '../../../components/index.js';

const LoggedIn = ({ programConfig, programConfigActions }) => {
  const {
    featureFlagDataLoaded,
    programsList = [],
    privateSandboxActive,
  } = currentUserStore.userStore;
  const { currentPathWithSearch, currentParamsAsObject = {}, currentView } = routerStore;
  const { loaded } = useProgramConfigStateContext();

  useEffect(() => {
    if (privateSandboxActive && currentView) {
      addActiveEnvironmentToUrl();
    }
  }, [currentPathWithSearch, currentView, privateSandboxActive]);

  useEffect(
    () => {
      if (programsList.length > 0 && currentView) {
        addActiveProgramToUrl();
      }
    },
    [currentPathWithSearch, currentView, programsList.length] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    ensureValidFeatureFlagsIsSet();
  }, [currentPathWithSearch]); // eslint-disable-line

  useEffect(() => {
    if (!loaded) {
      programConfigActions.hydrate();
    }
  }, [loaded, programConfigActions]);

  const percent = routerStore && routerStore.loadingView ? 25 : 100;

  // construct a key that will pick up on changes that should cause an application-wide view refresh
  const applicationDataKey = `application-data:${applicationDataStore.applicationDataHash}`;
  const featureFlagKey = `feature-flag-loaded:${featureFlagDataLoaded ? '1' : '0'}`;
  const refreshKey = [applicationDataKey, featureFlagKey].join('|');
  const ProgramConfigStore = useMemo(
    () => ({ ProgramConfigStore: programConfig }),
    [programConfig]
  );

  const routerStoreParams = useMemo(() => ({ currentParamsAsObject }), [currentParamsAsObject]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>Marqeta - {routerStore.currentRouteName}</title>
      </Helmet>
      <ErrorBoundary googleAnalytics={googleAnalytics}>
        <StoreContext.Provider value={ProgramConfigStore}>
          <div key={refreshKey}>
            <PageProgressBar percent={percent} autoIncrement />
            <LoadingOverlayWrapper loadingStore={loadingStore} />
            <ActingAs />
            <Notifications notificationStore={notificationStore} />
            <ActiveDownloads />
            <RouterGoContext.Provider value={routerStore.go}>
              <RouterParamsContext.Provider value={routerStoreParams}>
                <RouterUrlContext.Provider value={routerStore.url}>
                  <CurrentRoute />
                </RouterUrlContext.Provider>
              </RouterParamsContext.Provider>
            </RouterGoContext.Provider>
            <LeavingModal />
          </div>
        </StoreContext.Provider>
      </ErrorBoundary>
    </HelmetProvider>
  );
};

export default withProgramConfigActions(withProgramConfig(observer(LoggedIn)));
