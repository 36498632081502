/* eslint-disable camelcase */
// the unused vars need to be there otherwise the component doesn't rerender on those property change
/* eslint-disable no-unused-vars */
import React from 'react';
import { observer } from 'mobx-react';
import s from './BusinessInfoTab.module.css';
import {
  Card,
  Text,
  HSpacer,
  Tooltip,
  Icon,
  ContentSnippet,
  VSpacer,
  Button,
  LoadingOverlay,
} from '@mqd/volt-base';
import PropTypes from 'prop-types';
import { AddIdentificationModal } from '../shared-components';
import { ParentDetailCard } from '@mq/voltron-parent';
import { StoreContext } from '@mqd/volt-contexts';

class BusinessProprietorOrOfficerCard extends ParentDetailCard {
  constructor(props) {
    super(props);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.state = {
      editActive: false,
      showAddIdentificationModal: false,
      loading: false,
    };
  }

  get businessStore() {
    const { businessStore } = this.props;
    return businessStore || {};
  }

  get poe() {
    const { businessStore = {} } = this.props;
    const { proprietor_or_officer = {} } = businessStore;
    return proprietor_or_officer || {};
  }

  async handleSave() {
    const { updateBusiness } = this.businessStore;
    this.setState({ loading: true });
    const success = await updateBusiness();
    if (success) {
      this.setState({ loading: false, editActive: false });
    } else {
      this.setState({ loading: false });
    }
  }

  handleCancel() {
    const { revertUpdates } = this.businessStore;
    revertUpdates && revertUpdates();
    this.setState({ editActive: false });
  }

  renderHeaderButtons() {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button type="outline" onClick={this.handleCancel}>
          Cancel
        </Button>
        <HSpacer />
        <Button onClick={this.handleSave}>Save</Button>
      </div>
    );
  }

  renderTitle() {
    return (
      <div className={s.flexDiv}>
        <Text type="h5">Proprietor Or Officer</Text>
        <HSpacer />
        <div style={{ height: 24 }}>
          <Tooltip
            zIndex={9999}
            content={`
                If a businesses incoporation type is
                SOLE_PROPRIETORSHIP information about
                the proprietor or officer will be used
                for verification (KYC) checks.
              `}
          >
            <Icon type="info" mod="disabled" hasTooltip />
          </Tooltip>
        </div>
      </div>
    );
  }

  renderHome() {
    const { editActive } = this.state;
    const { home = {} } = this.poe;
    const addressDisplay = home && home.displayUS ? home.displayUS : '';
    const { address1, address2, city, state, postal_code, country, updateForSave } = home;
    if (editActive) {
      return (
        <>
          <Text type="h5">Home</Text>
          <VSpacer factor={2} />
          {this.renderSnippet({
            title: 'Address 1',
            content: address1,
            editActive: true,
            onChange: (e) => updateForSave('address1', e.target.value),
            tooltipContent: 'Street address of business proprietor or officer.',
          })}
          {this.renderSnippet({
            title: 'Address 2',
            content: address2,
            editActive: true,
            onChange: (e) => updateForSave('address2', e.target.value),
            tooltipContent: 'Additional address information.',
          })}
          {this.renderSnippet({
            title: 'City',
            content: city,
            editActive: true,
            onChange: (e) => updateForSave('city', e.target.value),
            tooltipContent: 'City of business proprietor or officer.',
          })}
          {this.renderSnippet({
            title: 'State',
            content: state,
            editActive: true,
            onChange: (e) => updateForSave('state', e.target.value),
            tooltipContent: 'State in which business proprietor or officer resides.',
          })}
          {this.renderSnippet({
            title: 'Postal Code',
            content: postal_code,
            editActive: true,
            onChange: (e) => updateForSave('postal_code', e.target.value),
            tooltipContent: "Business proprietor or officer's postal code.",
          })}
          {this.renderSnippet({
            title: 'Country',
            content: country,
            editActive: true,
            onChange: (e) => updateForSave('country', e.target.value),
            tooltipContent: 'Country in which business proprietor or officer resides.',
          })}
        </>
      );
    } else {
      return (
        <ContentSnippet
          title="Home"
          testId="volt-content-snippet_title-Home"
          horizontalLayout
          tooltipProps={{ zIndex: 9999 }}
          tooltipContent={`
              Home of business proprietor or officer.
              Required for verification (KYC) checks.
            `}
        >
          <div className={s.address}>{addressDisplay}</div>
        </ContentSnippet>
      );
    }
  }

  renderIdentifications() {
    const { canRevealIdentification, uamGranularPermissionsActive } = this.props;
    const { editActive } = this.state;
    const { revealIdentification, revealIdentificationGranular, hideIdentification } =
      this.businessStore;
    let { identifications, isEdit } = this.poe;
    if (!identifications) {
      identifications = [];
    }
    const snippets = identifications.map((identification, i) => {
      const { type, value, expiration_date, loading, valueWithStars } = identification;
      return (
        <LoadingOverlay active={loading} key={i}>
          <ContentSnippet title={type} key={i} horizontalLayout>
            <div className={s.spaceAround}>
              <Text>{valueWithStars}</Text>
              {this.renderSsnRevealButton({
                editActive,
                canReveal: canRevealIdentification,
                value: valueWithStars,
                reveal: () =>
                  uamGranularPermissionsActive
                    ? revealIdentificationGranular(type)
                    : revealIdentification(type),
                hide: () => hideIdentification(type),
              })}
            </div>
            {expiration_date ? (
              <div className={s.idExpires}>
                <Text mod="light">Expires: {expiration_date}</Text>
              </div>
            ) : null}
          </ContentSnippet>
          <VSpacer factor={2} />
        </LoadingOverlay>
      );
    });
    return (
      <div data-testid="v2-identifications">
        <VSpacer factor={2} />
        <div className={s.flexDiv}>
          <Text type="h5">Identifications</Text>
          <HSpacer factor={0.5} />
          <div style={{ height: 22 }}>
            <Tooltip
              zIndex={9999}
              content={
                <div>
                  Forms of identification used to validate the proprietor or officer.
                  <br />
                  <span style={{ fontWeight: 'bold' }}>
                    Required for verification (KYC) checks.
                  </span>
                  <br />
                  <br />
                  <span style={{ fontWeight: 'bold' }}>Note:</span> Only one of the following
                  identification types can be associated with a business:
                  <ul>
                    <li>SSN – Social Security Number</li>
                    <li>SIN – Social Insurance Number</li>
                    <li>TIN – Taxpayer Identification Number</li>
                    <li>NIN – National Insurance Number</li>
                  </ul>
                  <br />
                  <span style={{ fontWeight: 'bold' }}>Also Note:</span> SSN is required for KYC
                  verification checks.
                </div>
              }
            >
              <Icon type="info" mod="disabled" hasTooltip />
            </Tooltip>
          </div>
          <HSpacer factor={2} />
          <Button
            size="xs"
            type="outline"
            onClick={() => this.setState({ showAddIdentificationModal: true })}
            testId={`${isEdit ? 'Edit' : 'Add New'}_button`}
          >
            {isEdit ? 'Edit' : 'Add New'}
          </Button>
        </div>
        <VSpacer factor={2} />
        {snippets}
      </div>
    );
  }

  renderAddIdentificationModal() {
    const { showAddIdentificationModal, loading } = this.state;
    const { addPoeIdentification } = this.businessStore;
    const { getIdentification, isEdit } = this.poe;
    if (!showAddIdentificationModal) return null;
    return (
      <LoadingOverlay active={loading}>
        <AddIdentificationModal
          isEdit={isEdit}
          values={getIdentification}
          options={['SSN', 'SIN', 'TIN', 'NIN']}
          disableExpirationDateFor={['SSN', 'SIN', 'TIN', 'NIN']}
          handleSave={async ({ type, value, expirationDate }) => {
            this.setState({ loading: true, showAddIdentificationModal: false });
            addPoeIdentification && (await addPoeIdentification({ type, value, expirationDate }));
            this.setState({ loading: false });
          }}
          hideModal={() => {
            this.setState({ showAddIdentificationModal: false });
          }}
        />
      </LoadingOverlay>
    );
  }

  renderV1Identification() {
    const { editActive } = this.state;
    const { ssn, updateForSave, loading, ssnWithStars } = this.poe;
    const { canRevealIdentification, uamGranularPermissionsActive } = this.props;
    const { revealV1Identification, revealV1IdentificationGranular, hideV1Identification } =
      this.businessStore;
    return (
      <div data-testid="v1-identifications">
        <VSpacer factor={2} />
        <div className={s.spaceBetween}>
          <div className={s.flexDiv}>
            <Text type="h5">Identifications</Text>
            <HSpacer factor={0.5} />
            <div style={{ height: 22 }}>
              <Tooltip
                zIndex={9999}
                content={`
                    Forms of identification used to validate the proprietor or officer.
                    Required for verification (KYC) checks. SSN needs to be 9 digits
                    Note: SSN is required for KYC
                    verification checks.
                  `}
              >
                <Icon type="info" mod="disabled" hasTooltip />
              </Tooltip>
            </div>
          </div>
          {this.renderSsnRevealButton({
            editActive,
            canReveal: canRevealIdentification,
            value: ssnWithStars,
            reveal: () =>
              uamGranularPermissionsActive
                ? revealV1IdentificationGranular()
                : revealV1Identification(),
            hide: () => hideV1Identification(),
          })}
        </div>
        <VSpacer factor={2} />
        <LoadingOverlay active={loading}>
          {this.renderSnippet({
            title: 'SSN',
            content: ssnWithStars,
            editActive: editActive,
            onChange: (e) => updateForSave('ssn', e.target.value),
            tooltipContent: 'SSN of the proprieter SSN',
          })}
        </LoadingOverlay>
      </div>
    );
  }

  renderInfo() {
    const { editable } = this.props;
    const { editActive } = this.state;
    const {
      first_name,
      middle_name,
      last_name,
      alternative_names,
      title,
      dob,
      phone,
      ssn,
      email,
      updateForSave,
      identifications,
      loading,
    } = this.poe;
    // if observables are not declared at where the context is called, a re-render doesn't happen
    const {
      loading: identificationLoading,
      value,
      type,
    } = (identifications && identifications[0]) || {};
    return (
      <Card
        title={this.renderTitle()}
        testId="business-propritor-or-office"
        controls={editable && (editActive ? this.renderHeaderButtons() : this.renderControl())}
      >
        {this.renderSnippet({
          title: 'First Name',
          content: first_name,
          editActive: editActive,
          onChange: (e) => updateForSave('first_name', e.target.value),
          tooltipContent: 'First name of business proprietor or officer.',
        })}
        {this.renderSnippet({
          title: 'Middle Name',
          content: middle_name,
          editActive: editActive,
          onChange: (e) => updateForSave('middle_name', e.target.value),
          tooltipContent: 'Middle name of business proprietor or officer.',
        })}
        {this.renderSnippet({
          title: 'Last Name',
          content: last_name,
          editActive: editActive,
          onChange: (e) => updateForSave('last_name', e.target.value),
          tooltipContent: 'Last name of business proprietor or officer.',
        })}
        {this.renderSnippet({
          title: 'Date Of Birth',
          content: dob,
          editActive: editActive,
          onChange: (e) => updateForSave('dob', e.target.value),
          tooltipContent: "Business proprietor or officer's date of birth",
        })}
        {this.renderSnippet({
          title: 'Phone',
          content: phone,
          editActive: editActive,
          onChange: (e) => updateForSave('phone', e.target.value),
          tooltipContent: 'Telephone number of business proprietor or officer.',
        })}
        {this.renderSnippet({
          title: 'Email',
          content: email,
          editActive: editActive,
          onChange: (e) => updateForSave('email', e.target.value),
          tooltipContent: 'Email address of business proprietor or officer.',
        })}
        {this.renderSnippet({
          title: 'Alternative Names',
          content: alternative_names,
          editActive: editActive,
          onChange: (e) => updateForSave('alternative_names', e.target.value),
          tooltipContent: 'Alternate names of business proprietor or officer.',
        })}
        {this.renderSnippet({
          title: 'Title',
          content: title,
          editActive: editActive,
          onChange: (e) => updateForSave('title', e.target.value),
          tooltipContent: 'Title of business proprietor or officer.',
        })}
        {this.renderHome()}
        <StoreContext.Consumer>
          {({ ProgramConfigStore = {} }) => {
            return ProgramConfigStore.isV2
              ? this.renderIdentifications()
              : this.renderV1Identification();
          }}
        </StoreContext.Consumer>
      </Card>
    );
  }

  render() {
    const { loading } = this.state;
    const { canRevealIdentification } = this.props;
    return (
      <LoadingOverlay active={loading}>
        {this.renderAddIdentificationModal()}
        {this.renderInfo()}
      </LoadingOverlay>
    );
  }
}

BusinessProprietorOrOfficerCard.propTypes = {
  businessStore: PropTypes.any,
  canRevealIdentification: PropTypes.boolean,
  editable: PropTypes.bool,
};

BusinessProprietorOrOfficerCard.defaultProps = {
  businessStore: {},
  canRevealIdentification: false,
  editable: false,
};

export default observer(BusinessProprietorOrOfficerCard);
