import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Text } from '@mqd/volt-base';
import { convertNumberToCurrency, formatDateAsUTCString } from '../utils';

class TransactionDetails extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { amount, cardholder_transaction_time, currency_code } = this.props;
    return (
      <Container gutter={16}>
        <Row>
          <Col span={4}>
            <Text type="h6">Transaction amount</Text>
          </Col>
          <Col>
            <Text type="body-sm">{convertNumberToCurrency(amount, currency_code)}</Text>
          </Col>
        </Row>
        <Row>
          <Col span={4}>
            <Text type="h6">Transaction date</Text>
          </Col>
          <Col>
            <Text type="body-sm">{formatDateAsUTCString(cardholder_transaction_time)}</Text>
          </Col>
        </Row>
      </Container>
    );
  }
}

TransactionDetails.propTypes = {
  amount: PropTypes.string,
  cardholder_transaction_time: PropTypes.string,
  currency_code: PropTypes.string,
};

TransactionDetails.defaultProps = {
  amount: null,
  cardholder_transaction_time: null,
  currency_code: null,
};

export default TransactionDetails;
