import React from 'react';
import logger from 'utils/logger';
import 'whatwg-fetch';
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'url-search-params-polyfill';
import ReactDOM from 'react-dom';
import '@mqd/volt-base/dist/normalize.css';
import '@mqd/volt-base/dist/style.css';
import '@mqd/bootstrap/dist/style.css';
import '@mqd/volt-codeblock/dist/style.css';
import '@mqd/volt-date-picker/dist/style.css';
import '@mqd/store-helpers/dist/style.css';
import '@mqd/volt-table/dist/style.css';
import '@mqd/card-catalog/dist/style.css';
import '@mqd/disputes/dist/style.css';
import './dayjs';

import App from './App.js';
// import * as Sentry from '@sentry/browser';

// try {
//   const sentryEnv = window.env.SENTRY_ENV;
//   const isQAorProd = sentryEnv !== 'development';
//   if (isQAorProd) {
//     Sentry.init({
//       dsn: window.env.SENTRY_DSN,
//       environment: sentryEnv,
//       release: process.env.REACT_APP_VERSION,
//     });
//   }
// } catch (error) {
//   logger.error('Could not initialize Sentry', error);
// }

try {
  ReactDOM.render(<App />, document.getElementById('root'));
  window.reactMounted = true;
} catch (error) {
  logger.error('REACT ERROR IN GLOBAL RENDER, LOCAL STORAGE HAS BEEN CLEARED');
  window.localStorage.clear();
  if (window && window.location) {
    window.location.href = '/';
  } else {
    window.alert("We're sorry! There was an error. Please refresh the page");
  }
}
