import PropTypes from 'prop-types';
import { is, isEmpty } from 'ramda';
import React from 'react';
import { CardHoverCard as CardHoverCardTemplate, HoverCard } from '@mqd/volt-base';
import routerStore from '../../../stores/router/RouterStore.js';
import { parseDateString } from '../../../utils/helpers/parseDateString.js';
import CardLink from '../../card-link/index.js';

const CardHoverCard = ({ card, bottomLabelHandler, mask, network }) => {
  if (!is(Object, card) || isEmpty(card)) return null;

  const { last_four, expiration_time, state, token, cvv_number } = card;
  const expiration = parseDateString(expiration_time);

  // Defaults to /program/accountholder/card
  const _bottomLabelHandler = () =>
    routerStore.go('/program/accountholder/card', { params: { token } });
  const labelHandler =
    typeof bottomLabelHandler === 'function' ? bottomLabelHandler : _bottomLabelHandler;

  return (
    <HoverCard
      body={
        <CardHoverCardTemplate
          last_four={last_four}
          status={state}
          expiration_date={expiration}
          bottomLabelHandler={labelHandler}
          cvv={cvv_number}
        />
      }
    >
      <CardLink isInline mask={mask} onClick={labelHandler} text={last_four} network={network} />
    </HoverCard>
  );
};

CardHoverCard.propTypes = {
  card: PropTypes.shape({
    token: PropTypes.string,
    last_four: PropTypes.string,
    expiration_time: PropTypes.string,
    state: PropTypes.string,
    cvv_number: PropTypes.string,
  }).isRequired,
  bottomLabelHandler: PropTypes.func,
  mask: PropTypes.string,
  network: PropTypes.string,
};

CardHoverCard.defaultProps = {
  mask: '\u2022\u2022',
};

export default CardHoverCard;
