export const DUPLICATE_ACTION_SUBMITTED = 'DUPLICATE_ACTION_SUBMITTED';
export const INCORRECT_ACTION_SUBMITTED = 'INCORRECT_ACTION_SUBMITTED';
export const INCORRECT_AMOUNT_SUBMITTED = 'INCORRECT_AMOUNT_SUBMITTED';
export const INCORRECT_CATEGORY_SUBMITTED = 'INCORRECT_CATEGORY_SUBMITTED';
export const PAST_SLA = 'PAST_SLA';
export const POTENTIAL_PROCESSING_ERROR = 'POTENTIAL_PROCESSING_ERROR';

export const REJECTION_OPTIONS = [
  {
    label: 'Action already submitted',
    id: DUPLICATE_ACTION_SUBMITTED,
  },
  {
    label: 'Incorrect action submitted',
    id: INCORRECT_ACTION_SUBMITTED,
  },
  {
    label: 'Incorrect amount submitted',
    id: INCORRECT_AMOUNT_SUBMITTED,
  },
  {
    label: 'Incorrect category submitted',
    id: INCORRECT_CATEGORY_SUBMITTED,
  },
  {
    label: 'Past SLA',
    id: PAST_SLA,
  },
  {
    label: 'Potential processing error',
    id: POTENTIAL_PROCESSING_ERROR,
  },
];
