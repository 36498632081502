import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Link, Text } from '@mqd/volt-base';
import s from './CardLink.module.css';
import { ICON_TYPE_MAPPING_BY_NETWORK } from './constants.js';

const CardLink = ({
  type,
  isEmpty,
  text,
  onClick,
  mask,
  className,
  isInline,
  emptyStateMessage,
  network,
  ...rest
}) => {
  if (isEmpty) return emptyStateMessage;
  if (!text || typeof text !== 'string') return null;

  const classes = `${s.cardLinkContainer} ${className || ''}`;
  const linkProps = { className: classes, onClick, ...rest };

  return (
    <Text>
      <Link {...linkProps} inheritParentFont={isInline}>
        <div className={network ? s.networkIcon : s.cardIcon}>
          <Icon
            type={network ? ICON_TYPE_MAPPING_BY_NETWORK[network.toUpperCase()] : 'credit-card'}
            factor={1}
            noHoverEffects
            testId={`card-link-${network?.toLowerCase()}-icon`}
          />
        </div>
        {mask ? mask + text : text}
      </Link>
    </Text>
  );
};

CardLink.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  className: PropTypes.string,
  mask: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isEmpty: PropTypes.bool,
  emptyStateMessage: PropTypes.string,
};

CardLink.defaultProps = {
  mask: '\u2022\u2022',
  className: '',
  isInline: false,
  emptyStateMessage: 'No cards for this account yet.',
};

export default CardLink;
