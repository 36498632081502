import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Card, VSpacer, Text, Modal, Textarea, LoadingOverlay, Button } from '@mqd/volt-base';
import s from './TokenCard.module.css';
import moment from 'moment';

class TokenCard extends Component {
  constructor(props) {
    super(props);
    this.state = { editNoteActive: false };
    this.startEditNote = this.startEditNote.bind(this);
    this.handleSaveNoteEdit = this.handleSaveNoteEdit.bind(this);
    this.cancelEditNote = this.cancelEditNote.bind(this);
  }

  // handlers
  startEditNote() {
    const { store } = this.props;
    store.setAttr('noteEdit', store.note);
    this.setState({ editNoteActive: true });
  }

  cancelEditNote() {
    const { store } = this.props;
    store.setAttr('noteEdit', store.note);
    this.setState({ editNoteActive: false });
  }

  handleSaveNoteEdit() {
    const { store } = this.props;
    store.saveNoteEdit();
    this.setState({ editNoteActive: false });
  }

  // helpers
  getStatus() {
    const { store } = this.props;
    let mod;
    if (store.status === 'Active') {
      mod = 'success';
    } else if (store.status === 'Deleted') {
      mod = 'error';
    } else if (store.status === 'Disabled') {
      mod = 'disabled';
    }
    return <Text mod={mod}>{store.status}</Text>;
  }

  getDisableOrEnableAction() {
    const { store } = this.props;
    const active = store.status === 'Active';
    return {
      element: active ? 'Disable Token' : 'Enable Token',
      onClick: () => {
        active ? store.disableToken() : store.enableToken();
        // Fix for forcing hide dropdown
        // todo: make dropdown elements optional triggers inside RawDropdown
        document.dispatchEvent(new Event('mousedown', { bubbles: true }));
      },
    };
  }

  getDeleteAction() {
    const { store } = this.props;
    const deleted = store.status === 'Deleted';
    return {
      element: deleted ? 'Token Deleted' : 'Delete Token',
      onClick: () => {
        if (!deleted) {
          store.deleteToken();
        }
      },
    };
  }

  getCardActions() {
    const { store } = this.props;
    const deleted = store.status === 'Deleted';

    return deleted
      ? null
      : [
          {
            element: 'Edit Note',
            onClick: this.startEditNote,
          },
          this.getDisableOrEnableAction(),
          this.getDeleteAction(),
        ];
  }

  // renderers
  renderEditNote() {
    const { store } = this.props;
    const { editNoteActive } = this.state;
    if (!editNoteActive) return null;
    return (
      <Modal
        heading="Edit Note"
        hideModal={() => {
          this.setState({ editNoteActive: false });
          this.cancelEditNote();
        }}
        width={512}
        footerButtons={[
          <Button key="edit-note" onClick={this.handleSaveNoteEdit} style={{ width: 88 }}>
            Save
          </Button>,
        ]}
      >
        <Textarea
          label="Token Note (Optional)"
          placeholder="Enter note here..."
          value={store.noteEdit}
          onChange={(e) => store.setAttr('noteEdit', e.target.value)}
          maxLength={128}
        />
      </Modal>
    );
  }

  renderInfo(title, content, last = false) {
    const { store } = this.props;
    const disabled = store.status === 'Disabled';
    return (
      <>
        <div className={s.info}>
          <div className={s.infoTitle}>
            <Text mod={disabled ? 'disabled' : 'h6'}>{title}</Text>
          </div>
          {typeof content === 'object' ? (
            content
          ) : (
            <Text mod={disabled ? 'disabled' : ''}>{content}</Text>
          )}
        </div>
        {last ? null : <VSpacer factor={2} />}
      </>
    );
  }

  render() {
    const { store } = this.props;
    const {
      email,
      note,
      durableAccessTokenId,
      durableAccessTokenType,
      dateCreated,
      dateLastUsed,
      dateUpdated,
      loading,
    } = store;
    return (
      <>
        {this.renderEditNote()}
        <LoadingOverlay active={loading}>
          <Card actions={this.getCardActions()}>
            {this.renderInfo('Status', this.getStatus())}
            {this.renderInfo('Email', email)}
            {this.renderInfo('Note', note || <Text mod="disabled">None</Text>)}
            {this.renderInfo('ID', durableAccessTokenId)}
            {this.renderInfo('Type', durableAccessTokenType)}
            {this.renderInfo('Date Created', moment(dateCreated).format('M/D/YY HH:MM A'))}
            {this.renderInfo(
              'Date Last Used',
              dateLastUsed ? (
                moment(dateLastUsed).format('M/D/YY HH:MM A')
              ) : (
                <Text mod="disabled">--</Text>
              )
            )}
            {this.renderInfo(
              'Date Updated',
              dateUpdated ? (
                moment(dateUpdated).format('M/D/YY HH:MM A')
              ) : (
                <Text mod="disabled">--</Text>
              ),
              true
            )}
          </Card>
        </LoadingOverlay>
      </>
    );
  }
}

TokenCard.propTypes = {
  store: PropTypes.any,
};

TokenCard.defaultProps = {
  store: null,
};

export default observer(TokenCard);
