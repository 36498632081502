/* eslint-disable camelcase */
// the unused vars need to be there otherwise the component doesn't rerender on those property change
/* eslint-disable no-unused-vars */
import React from 'react';
import { observer } from 'mobx-react';
import {
  ContentSnippet,
  VSpacer,
  Button,
  HSpacer,
  Icon,
  Card,
  Text,
  Tooltip,
  LoadingOverlay,
} from '@mqd/volt-base';
import PropTypes from 'prop-types';
import { AddIdentificationModal, AddMetadataModal } from '../shared-components';
import { ParentDetailCard, Status } from '@mq/voltron-parent';
import ChangeStatusModal from '../change-status-modal/ChangeStatusModal';
import s from './BusinessInfoTab.module.css';
import { StoreContext } from '@mqd/volt-contexts';
import moment from 'moment';

class BusinessInformationCard extends ParentDetailCard {
  constructor(props) {
    super(props);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.state = {
      loading: false,
      editActive: false,
      expand: false,
      showChangeStatusModal: false,
      showAddIdentificationModal: false,
      showAddMetadataModal: false,
    };
  }

  get businessStore() {
    const { businessStore } = this.props;
    return businessStore || {};
  }

  async handleSave() {
    const { updateBusiness } = this.businessStore;
    this.setState({ loading: true });
    const success = await updateBusiness();
    if (success) {
      this.setState({ loading: false, editActive: false });
    } else {
      this.setState({ loading: false });
    }
  }

  handleCancel() {
    const { revertUpdates } = this.businessStore;
    revertUpdates && revertUpdates();
    this.setState({ editActive: false });
  }

  renderHeaderButtons() {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button type="outline" onClick={this.handleCancel}>
          Cancel
        </Button>
        <HSpacer />
        <Button onClick={this.handleSave}>Save</Button>
      </div>
    );
  }

  getFooterButtons() {
    const { expand } = this.state;
    return [
      <Button
        key="view"
        type="outline"
        testId="expand-button"
        onClick={() => this.setState({ expand: !expand })}
      >
        <div className={s.flexDiv}>
          <div>{expand ? 'Minimize' : 'View All'}</div>
          <HSpacer factor={0.5} />
          <Icon type={expand ? 'arrow-up' : 'arrow-down'} factor={0.8333} />
        </div>
      </Button>,
    ];
  }

  renderStatus() {
    const { status } = this.businessStore;
    const { editActive } = this.state;

    return (
      <>
        <ContentSnippet
          title="Status"
          testId="volt-content-snippet_title-Status"
          horizontalLayout
          tooltipProps={{ zIndex: 9999 }}
          tooltipContent={`
              Status controls the business's capabilities and the
              setting of the business.active field.
            `}
          titleInline={!editActive ? { width: 180 } : null}
          contentInline={!editActive ? { flexGrow: 1 } : null}
        >
          <div className={s.flexDiv}>
            <Status status={status} />
            {this.renderChangeStatusButton()}
          </div>
        </ContentSnippet>
        <VSpacer factor={2} />
      </>
    );
  }

  renderOfficeLocation() {
    const { editActive } = this.state;
    let { office_location } = this.businessStore;
    if (!office_location) {
      office_location = {};
    }
    const addressDisplay =
      office_location && office_location.displayUS ? office_location.displayUS : '';
    const { address1, address2, city, state, postal_code, country, updateForSave } =
      office_location;
    if (editActive) {
      return (
        <>
          <Text type="h5">Office Location</Text>
          <VSpacer factor={2} />
          {this.renderSnippet({
            title: 'Address 1',
            content: address1,
            editActive: true,
            onChange: (e) => updateForSave('address1', e.target.value),
            tooltipContent: `
                Business office's street address. 
                Required for verification (KYB) checks.
                Cannot perform KYC if set to a PO Box.
              `,
          })}
          {this.renderSnippet({
            title: 'Address 2',
            content: address2,
            editActive: true,
            onChange: (e) => updateForSave('address2', e.target.value),
            tooltipContent: `
                Additional address information. 
                Cannot perform KYB if set to a PO Box.
              `,
          })}
          {this.renderSnippet({
            title: 'City',
            content: city,
            editActive: true,
            onChange: (e) => updateForSave('city', e.target.value),
            tooltipContent: `
                Business office's city.
                Required for verification (KYB) checks.
              `,
          })}
          {this.renderSnippet({
            title: 'State',
            content: state,
            editActive: true,
            onChange: (e) => updateForSave('state', e.target.value),
            tooltipContent: `
                Business office's state.
                Required for verification (KYB) checks.
              `,
          })}
          {this.renderSnippet({
            title: 'Postal Code',
            content: postal_code,
            editActive: true,
            onChange: (e) => updateForSave('postal_code', e.target.value),
            tooltipContent: `
                Business office's postal code.
                Required for verification (KYB) checks.
              `,
          })}
          {this.renderSnippet({
            title: 'Country',
            content: country,
            editActive: true,
            onChange: (e) => updateForSave('country', e.target.value),
            tooltipContent: `
                Business office's country.
                Required for verification (KYB) checks.
              `,
          })}
        </>
      );
    } else {
      return (
        <ContentSnippet
          title="Office Location"
          horizontalLayout
          tooltipProps={{ zIndex: 9999 }}
          tooltipContent={`
              Address of business office.
              Required for verification (KYB) checks.
            `}
          titleInline={!editActive ? { width: 180 } : null}
          contentInline={!editActive ? { flexGrow: 1 } : null}
        >
          <div className={s.address}>{addressDisplay}</div>
        </ContentSnippet>
      );
    }
  }

  renderExpansion() {
    const { editActive } = this.state;
    const {
      website,
      in_current_location_since,
      general_business_description,
      history,
      international_office_locations,
      notes_string,
      account_holder_group_token,
      ip_address,
      created_time,
      last_modified_time,
      business_type,
      duns_number,
      updateForSave,
    } = this.businessStore;
    return (
      <>
        {this.renderSnippet({
          title: 'Business Type',
          content: business_type,
          editActive,
          onChange: (e) => updateForSave('business_type', e.target.value),
          tooltipContent: `
              Indicates the type of business, for
              example B2B (business-to-business)
              or B2C (business-to-consumer).
            `,
        })}
        {this.renderSnippet({
          title: 'DUNS Number',
          content: duns_number,
          editActive,
          onChange: (e) => updateForSave('duns_number', e.target.value),
          tooltipContent: `
              Data Universal Numbering System 
              (DUNS) number of business.
            `,
        })}
        {this.renderSnippet({
          title: 'Business Website',
          content: website,
          editActive,
          onChange: (e) => updateForSave('website', e.target.value),
          tooltipContent: "URL of the business's website.",
        })}
        {this.renderSnippet({
          title: 'On Location Since',
          content: in_current_location_since,
          editActive,
          onChange: (e) => updateForSave('in_current_location_since', e.target.value),
          tooltipContent: `
              The date on which the business office
              opened in its current location.
            `,
        })}
        {this.renderSnippet({
          title: 'Business Description',
          content: general_business_description,
          editActive,
          onChange: (e) => updateForSave('general_business_description', e.target.value),
          tooltipContent: 'General description of the business.',
        })}
        {this.renderSnippet({
          title: 'History',
          content: history,
          editActive,
          onChange: (e) => updateForSave('history', e.target.value),
          tooltipContent: 'History of the business.',
        })}
        {this.renderSnippet({
          title: 'International Offices',
          content: international_office_locations,
          editActive,
          onChange: (e) => updateForSave('international_office_locations', e.target.value),
          tooltipContent: "The locations of the business's offices outside the US.",
        })}
        {this.renderSnippet({
          title: 'Api Note',
          content: notes_string,
          editActive,
          onChange: (e) => updateForSave('notes_string', e.target.value),
          tooltipContent: `
              A note string defined on the business object.
              Note: This is different than the notes in
              the note tab.
            `,
        })}
        <div id={s.accHoldGroupToken}>
          {this.renderSnippet({
            title: 'Account Holder Group Token',
            content: account_holder_group_token,
            editActive,
            onChange: (e) => updateForSave('account_holder_group_token', e.target.value),
            tooltipContent: `
              Associates the specified account
              holder group with the business.
            `,
          })}
        </div>
        {this.renderSnippet({
          title: 'IP Address',
          content: ip_address,
          editActive,
          onChange: (e) => updateForSave('ip_address', e.target.value),
          tooltipContent: 'The IP address of the business.',
        })}
        {this.renderTextRow({
          title: 'Created',
          content: created_time,
          tooltipContent: `
              A timestamp of when the business was
              created in the Marqeta API
            `,
        })}
        {this.renderTextRow({
          title: 'Last Modified',
          content: last_modified_time,
          tooltipContent: `
              A timestamp of when the business was
              last updated in the Marqea API
            `,
        })}
        {this.renderMetadata()}
      </>
    );
  }

  renderTextRow({ content, title, ...props }) {
    const { editActive } = this.state;

    return (
      <>
        <ContentSnippet
          horizontalLayout
          title={title}
          titleInline={!editActive ? { width: 180 } : null}
          contentInline={!editActive ? { flexGrow: 1 } : null}
          testId={`volt-content-snippet_title-${title}`}
          {...props}
        >
          <Text>{content}</Text>
        </ContentSnippet>
        <VSpacer factor={2} />
      </>
    );
  }

  renderIdentifications() {
    let { identifications, identificationIsEdit } = this.businessStore;
    const { editActive } = this.state;

    if (!identifications) {
      identifications = [];
    }

    const snippets = identifications.map((identification, i) => {
      const { type, value, expiration_date } = identification;
      return (
        <>
          <ContentSnippet
            title={type}
            key={i}
            horizontalLayout
            titleInline={!editActive ? { width: 180 } : null}
            contentInline={!editActive ? { flexGrow: 1 } : null}
          >
            <Text>{value}</Text>
            {expiration_date ? (
              <div className={s.idExpires}>
                <Text mod="light">Expires: {expiration_date}</Text>
              </div>
            ) : null}
          </ContentSnippet>
          <VSpacer factor={2} />
        </>
      );
    });
    return (
      <div data-testid="v2-identifications">
        <VSpacer factor={2} />
        <div className={s.flexDiv}>
          <Text type="h5">Identifications</Text>
          <HSpacer />
          <div style={{ height: 16 }}>
            <Tooltip
              zIndex={9999}
              content={
                <div>
                  Forms of identification used to validate the business.
                  <br />
                  Required for verification (KYB) checks.
                  <br />
                  <br />
                  Note: Only one of the following identification types can be associated with a
                  business:
                  <ul>
                    <li>Business Tax ID</li>
                    <li>Business Number</li>
                    <li>Taxpayer Reference</li>
                  </ul>
                </div>
              }
            >
              <Icon type="info" mod="default" hasTooltip />
            </Tooltip>
          </div>
          <HSpacer factor={2} />
          <Button
            size="xs"
            type="outline"
            onClick={() => this.setState({ showAddIdentificationModal: true })}
            testId={`${identificationIsEdit ? 'Edit' : 'Add New'}_button`}
          >
            {identificationIsEdit ? 'Edit' : 'Add New'}
          </Button>
        </div>
        <VSpacer factor={2} />
        {snippets}
      </div>
    );
  }

  renderV1Identification() {
    const { taxpayer_id, updateForSave } = this.businessStore;
    const { editActive } = this.state;

    return (
      <div data-testid="v1-identifications">
        <VSpacer factor={2} />
        <Text type="h5">Identifications</Text>
        <VSpacer factor={2} />
        {this.renderSnippet({
          title: 'Tax Payer ID',
          content: taxpayer_id,
          editActive,
          onChange: (e) => updateForSave('taxpayer_id', e.target.value),
          tooltipContent: 'Tax Payer ID',
        })}
        <VSpacer factor={2} />
      </div>
    );
  }

  renderMetadata() {
    const { editActive } = this.state;
    const { metadataObject = {}, updateMetadata } = this.businessStore;
    const metadata = [];
    for (const key in metadataObject) {
      const value = metadataObject[key];
      metadata.push(
        <React.Fragment key={key}>
          {this.renderSnippet({
            title: key,
            content: value,
            editActive,
            tooltipContent: `
                To delete a metadata key pair
                delete the value and save
              `,
            onChange: (e) => updateMetadata(key, e.target.value),
          })}
        </React.Fragment>
      );
    }
    return (
      <div>
        <VSpacer />
        <div className={s.flexDiv}>
          <Text type="h5">Metadata</Text>
          <HSpacer factor={2} />
          <Button
            size="xs"
            type="outline"
            testId="add-metadata-button"
            onClick={() => this.setState({ showAddMetadataModal: true })}
          >
            Add New
          </Button>
        </div>
        <VSpacer factor={2} />
        {metadata}
      </div>
    );
  }

  renderChangeStatusModal() {
    const { showChangeStatusModal } = this.state;
    const { businessStore } = this.props;
    const { business_name_legal = '' } = businessStore;
    if (!showChangeStatusModal) return null;
    return (
      <ChangeStatusModal
        store={businessStore}
        heading={`Change Status - ${business_name_legal}`}
        hideModal={() => this.setState({ showChangeStatusModal: false })}
      />
    );
  }

  renderAddIdentificationModal() {
    const { showAddIdentificationModal } = this.state;
    const { addIdentification } = this.businessStore;
    if (!showAddIdentificationModal) return null;
    return (
      <AddIdentificationModal
        options={['BUSINESS_TAX_ID', 'BUSINESS_NUMBER', 'TAXPAYER_REFERENCE']}
        handleSave={async ({ type, value, expirationDate }) => {
          this.setState({ loading: true, showAddIdentificationModal: false });
          addIdentification && (await addIdentification({ type, value, expirationDate }));
          this.setState({ loading: false });
        }}
        hideModal={() => {
          this.setState({ showAddIdentificationModal: false });
        }}
      />
    );
  }

  renderAddMetadataModal() {
    const { showAddMetadataModal } = this.state;
    const { addMetadata } = this.businessStore;
    if (!showAddMetadataModal) return null;
    return (
      <AddMetadataModal
        handleCancel={() => {
          this.setState({ showAddMetadataModal: false });
        }}
        handleSave={async ({ key, value }) => {
          this.setState({ showAddMetadataModal: false, loading: true });
          addMetadata && (await addMetadata({ key, value }));
          this.setState({ loading: false });
        }}
        hideModal={() => {
          this.setState({ showAddMetadataModal: false });
        }}
      />
    );
  }

  renderKybDate() {
    const { kybActive } = this.props;
    const { kybDate } = this.businessStore;
    if (!kybActive || !kybDate) return null;
    return this.renderSnippet({
      title: 'KYB',
      content: `${moment(kybDate).format('MM-DD-YYYY')}`,
      tooltipContent: 'Date kyb was last run.',
    });
  }

  renderInfo() {
    const { editable } = this.props;
    const { editActive, expand } = this.state;
    const {
      token,
      active,
      business_name_legal,
      business_name_dba,
      phone,
      date_established,
      taxpayer_id,
      updateForSave,
    } = this.businessStore;
    return (
      <Card
        title="Business Information"
        testId="business-information-card"
        controls={editable && (editActive ? this.renderHeaderButtons() : this.renderControl())}
        footerButtons={this.getFooterButtons()}
      >
        <div style={{ width: '90%' }}>
          {this.renderTextRow({
            title: 'Active',
            content: <Status status={active ? 'ACTIVE' : 'INACTIVE'} />,
            tooltipContent: (
              <div>
                Active is a boolean determined by current status
                <br />
                <VSpacer />
                <table className={s.table}>
                  <thead>
                    <tr>
                      <th className={s.th}>Status</th>
                      <th className={s.th}>Active</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className={s.td}>Active, Limited</td>
                      <td className={s.td}>True</td>
                    </tr>
                    <tr>
                      <td className={s.td}>Unverified, Suspended, Closed</td>
                      <td className={s.td}>False</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ),
          })}
          {this.renderStatus()}
          {this.renderTextRow({
            title: 'Token',
            content: token,
            tooltipContent: `
                The unique identifier of the business.
                This value cannot be updated.
              `,
          })}
          {this.renderSnippet({
            title: 'Name (Legal)',
            content: business_name_legal,
            onChange: (e) => updateForSave('business_name_legal', e.target.value),
            editActive,
            tooltipContent: `
                Legal name of business.
                Required for verification (KYB) checks.
              `,
          })}
          {this.renderSnippet({
            title: 'Name (DBA)',
            content: business_name_dba,
            onChange: (e) => updateForSave('business_name_dba', e.target.value),
            editActive,
            tooltipContent: `
                Fictitious business name.
                Required for verification (KYB) checks.
              `,
          })}
          {this.renderSnippet({
            title: 'Business Phone',
            content: phone,
            onChange: (e) => updateForSave('phone', e.target.value),
            editActive,
            tooltipContent: `
                10-digit telephone number of business.
                Required for verification (KYB) checks.
              `,
          })}
          {this.renderSnippet({
            title: 'Established',
            content: date_established,
            onChange: (e) => updateForSave('date_established', e.target.value),
            editActive,
            tooltipContent: `
                Date the business was established. 
                Required for verification (KYB) checks.
              `,
          })}
          {this.renderOfficeLocation()}
          {this.renderKybDate()}
          <StoreContext.Consumer>
            {({ ProgramConfigStore = {} }) => {
              return ProgramConfigStore.isV2
                ? this.renderIdentifications()
                : this.renderV1Identification();
            }}
          </StoreContext.Consumer>
          {expand && this.renderExpansion()}
        </div>
      </Card>
    );
  }

  render() {
    const { loading } = this.state;
    return (
      <LoadingOverlay active={loading}>
        {this.renderChangeStatusModal()}
        {this.renderAddIdentificationModal()}
        {this.renderAddMetadataModal()}
        {this.renderInfo()}
      </LoadingOverlay>
    );
  }
}

BusinessInformationCard.propTypes = {
  businessStore: PropTypes.any,
  editable: PropTypes.bool,
};

BusinessInformationCard.defaultProps = {
  businessStore: {},
  editable: false,
};

export default observer(BusinessInformationCard);
