export const detailsOptions = ['VERIFIED', 'UNVERIFIED'];
export const addressArr = ['address1', 'address2', 'city', 'state', 'country', 'postal_code'];
export const nameArr = ['first_name', 'middle_name', 'last_name'];
export const fullKycArray = [
  'first_name',
  'middle_name',
  'last_name',
  'address1',
  'address2',
  'city',
  'state',
  'country',
  'postal_code',
  'birth_date',
  'ssn',
  'ofac',
];
export const optionsToAPI = {
  VERIFIED: 'SUCCESS',
  UNVERIFIED: null,
};
export const statusToPillDetail = {
  SUCCESS: {
    pillType: 'success',
    label: 'Success',
  },
  FAILURE: {
    pillType: 'error',
    label: 'Failure',
  },
  PENDING: {
    pillType: 'pending',
    label: 'Pending',
  },
  NOT_PERFORMED: {
    pillType: 'draft',
    label: 'Not performed',
  },
  '': {
    pillType: 'pending',
    label: 'Pending',
  },
  UNVERIFIED: {
    pillType: 'draft',
    label: 'Unverified',
  },
  VERIFIED: {
    pillType: 'success',
    label: 'Verified',
  },
  APPROVED: {
    pillType: 'success',
    label: 'Verified',
  },
  DECLINED: {
    pillType: 'error',
    label: 'Declined',
  },
  OPEN: {
    pillType: 'draft',
  },
  CLOSED: {
    pillType: 'success',
  },
  IN_PROGRESS: {
    pillType: 'pending',
  },
};

export const TRANSITION_REASON_CODES = {
  MISSING_DOCUMENT: {
    code: 'MISSING_DOCUMENT',
    label: 'Missing document',
  },
  DOCUMENT_DOES_NOT_MATCH_RECORDS: {
    code: 'DOCUMENT_DOES_NOT_MATCH_RECORDS',
    label: 'Document does not match records',
  },
  POOR_QUALITY_DOCUMENT: {
    code: 'POOR_QUALITY_DOCUMENT',
    label: 'Poor quality document',
  },
  WRONG_DOCUMENT: {
    code: 'WRONG_DOCUMENT',
    label: 'Wrong document',
  },
  DOCUMENT_EXPIRED_OR_STALE: {
    code: 'DOCUMENT_EXPIRED_OR_STALE',
    label: 'Document expired or stale',
  },
  MORE_INFORMATION_REQUIRED: {
    code: 'MORE_INFORMATION_REQUIRED',
    label: 'More information required',
  },
  OTHER: {
    code: 'OTHER',
    label: 'Other',
  },
};
