import { decorate, observable } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';

class BalanceStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
  }
  // values
  currency_code: string = '';
  ledger_balance: string = '';
  available_balance: string = '';
  credit_balance: string = '';
  pending_credits: string = '';
}

decorate(BalanceStore, {
  currency_code: observable,
  ledger_balance: observable,
  available_balance: observable,
  credit_balance: observable,
  pending_credits: observable,
});

export default BalanceStore;
