const subnetworks = {
  SUBNETWORK_VISANET: 'Net',
  SUBNETWORK_VISANETDEBIT: 'Net Debit',
  SUBNETWORK_VISAINTERLINK: 'Interlink',
  SUBNETWORK_VISAPLUS: 'Plus',
  SUBNETWORK_MAESTRO: 'Maestro',
  SUBNETWORK_CIRRUS: 'Cirrus',
  SUBNETWORK_MASTERCARD_SIG: 'Mastercard Sig',
  SUBNETWORK_GATEWAY_JIT: 'Gateway JIT',
  SUBNETWORK_MANAGED_JIT: 'Managed JIT',
  SUBNETWORK_PULSE: 'Pulse',
  SUBNETWORK_DISCOVER: 'Discover',
  UNRECOGNIZED: 'Unrecognized',
};

export default subnetworks;
