import React from 'react';
import { Modal, Button } from '@mqd/volt-base';

const MaximumActiveEventsModal = ({ hideModalCallback }) => {
  return (
    <div style={{ whiteSpace: 'break-spaces' }}>
      <Modal
        type="sm"
        heading="Maximum active webhooks reached"
        description="Environments support a maximum of 5 active  webhooks. To add a new webhook, you need to disable or delete a current active webhook."
        hideModalButtonText="Cancel"
        showHideModalButton={false}
        hideModal={hideModalCallback}
        maxHeightVh={80}
        disableOverFlow={true}
        footerButtons={[<Button onClick={hideModalCallback}>Close</Button>]}
        testId="maximum-active-events-modal"
      ></Modal>
    </div>
  );
};

export default MaximumActiveEventsModal;
