import React from 'react';
import { Flex, VSpacer, Button } from '@mqd/volt-base';

import { Button as TempButton } from '../../../../../../components/button/Button';

export function renderControls({ formState, switchToPreview, actions, cardType }) {
  const createActionHandler = (callback) => () => {
    callback(formState);

    switchToPreview();
  };

  return (
    <>
      <VSpacer factor={3} />
      <Flex justifyContent="space-between" alignItems="center">
        {formState.temp ? (
          <Button submit onClick={createActionHandler(actions.onCreateNewCard)}>
            Add {cardType}
          </Button>
        ) : (
          <Button submit onClick={createActionHandler(actions.onUpdateCard)}>
            Update {cardType}
          </Button>
        )}
        {/* TODO: replace after this ticket is done https://marqeta.atlassian.net/browse/VOLTENG-45 */}
        <TempButton link mod="secondary" onClick={createActionHandler(actions.onResetCard)}>
          Cancel
        </TempButton>
      </Flex>
    </>
  );
}
