import { decorate, observable } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';

class CardAcceptorStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
  }
  // values
  name: string = '';
  poi: Object = {};
  mcc: string = '';
  address1: string = '';
  address2: string = '';
  city: string = '';
  country: string = '';
  state: string = '';
  postal_code: string = '';
  zip: string = '';
}

decorate(CardAcceptorStore, {
  name: observable,
  poi: observable,
  mcc: observable,
  address1: observable,
  address2: observable,
  city: observable,
  country: observable,
  state: observable,
  postal_code: observable,
  zip: observable,
});

export default CardAcceptorStore;
