import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Button, VSpacer, Modal, ContentSnippet, SegmentedButtons, Text } from '@mqd/volt-base';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import './DivaApiDownloadControls.css';
import { downloadBlob, convertJsonToCsv } from './../../utils/index.js';
import { googleAnalytics } from '@mq/volt-amc-container';

class DivaApiDownloadControls extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visibleData: false,
    };
    this.downloadVisibleData = this.downloadVisibleData.bind(this);
  }

  handleClickOutside(evt) {
    this.setState({ active: false });
  }

  getType(check, active) {
    return check === active ? 'default' : 'default-white';
  }

  filenamePreview() {
    const { controllerStore } = this.props;
    const { visibleData } = this.state;
    return (
      <ContentSnippet title="File Preview">
        {visibleData
          ? `${controllerStore.downloadName}.csv`
          : controllerStore.downloadNameWithOptions}
      </ContentSnippet>
    );
  }

  downloadVisibleData() {
    const { dashboardStore, controllerStore } = this.props;
    const json = dashboardStore.activeMutableDataStore.data;
    const content = convertJsonToCsv(json);
    const fileName = `${controllerStore.downloadName}.csv`;
    downloadBlob(content, fileName);
    googleAnalytics &&
      googleAnalytics.event({
        category: 'Key User Events',
        action: 'Downloaded Report',
        label: `Downloaded Filtered Report - ${fileName}`,
      });
  }

  getFullDataControls() {
    // const { controllerStore } = this.props;
    // const format = controllerStore.downloadFormat;
    // const compression = controllerStore.downloadCompression;
    // const formatOptions = [
    //   {
    //     active: format === 'CSV',
    //     text: 'CSV',
    //     onClick: () => {
    //       controllerStore.setAttr('downloadFormat', 'CSV');
    //     },
    //   },
    //   {
    //     active: format === 'Excel',
    //     text: 'Excel',
    //     onClick: () => {
    //       controllerStore.setAttr('downloadFormat', 'Excel');
    //     },
    //   },
    // ];
    // const compressionOptions = [
    //   {
    //     active: compression === '.gz',
    //     text: '.gz',
    //     onClick: () => {
    //       controllerStore.setAttr('downloadCompression', '.gz');
    //     },
    //   },
    //   {
    //     active: compression === '.zip',
    //     text: '.zip',
    //     onClick: () => {
    //       controllerStore.setAttr('downloadCompression', '.zip');
    //     },
    //   },
    // ];

    return (
      <div>
        <div>
          {/* <ContentSnippet title="File Format">
            <SegmentedButtons type="outline" options={formatOptions} />
          </ContentSnippet> */}
        </div>
        {/* <VSpacer factor={4} /> */}
        <div>
          {/* <ContentSnippet title="Compression Type">
            <SegmentedButtons type="outline" options={compressionOptions} />
          </ContentSnippet> */}
        </div>
      </div>
    );
  }

  renderLoadingComponent(cancelRequestFn) {
    return (
      <div className="report-loading-container" data-testid="generating-reports-message">
        <div className="report-loading-content">
          <Text type="h5">Generating report...</Text>
          <VSpacer factor={3} />
          <Text type="body-lg">
            Large reports can take a minute or two to generate. Thank you for your patience! If this
            message persists for more than a few minutes, contact your Marqeta representative.
          </Text>
          <VSpacer factor={3} />
          <Button onClick={cancelRequestFn} testId="cancel-download-report-button">
            Cancel request
          </Button>
        </div>
      </div>
    );
  }

  renderFooterButtons() {
    const { controllerStore, handleClose } = this.props;
    const { visibleData } = this.state;
    const downloadAction = visibleData
      ? this.downloadVisibleData
      : controllerStore.startDownload.bind(this, this.renderLoadingComponent);
    const handleOnClick = () => {
      handleClose && handleClose();
      downloadAction();
    };
    return [
      <Button key="download" onClick={handleOnClick} testId="confirm-report-download-button">
        Download
      </Button>,
    ];
  }

  renderDatasetControl() {
    const { visibleData } = this.state;
    const datasetOptions = [
      {
        active: visibleData === false,
        text: 'Full',
        onClick: () => {
          this.setState({ visibleData: false });
        },
      },
      {
        active: visibleData === true,
        text: 'Exposed',
        onClick: () => {
          this.setState({ visibleData: true });
        },
      },
    ];
    return <SegmentedButtons options={datasetOptions} label="Dataset" />;
  }

  render() {
    const { visibleData } = this.state;
    return (
      <Modal
        heading="Download Report"
        width={460}
        disableOverFlow
        hideModalText="Close"
        hideModal={() => {
          this.props.handleClose && this.props.handleClose();
        }}
        footerButtons={this.renderFooterButtons()}
      >
        <div>
          {this.renderDatasetControl()}
          {!visibleData ? this.getFullDataControls() : null}
          <VSpacer factor={3} />
          {this.filenamePreview()}
        </div>
      </Modal>
    );
  }
}

DivaApiDownloadControls.propTypes = {
  controllerStore: PropTypes.any,
};

DivaApiDownloadControls.defaultProps = {
  controllerStore: {},
};

export default onClickOutside(observer(DivaApiDownloadControls));
