export const UAM_USER_CREATED = 'UAM_USER_CREATED';
export const UAM_USER_EDITED = 'UAM_USER_EDITED';

export const USER_STATUS_CHANGED = 'USER_STATUS_CHANGED';
export const USER_NOTE_ADDED = 'USER_NOTE_ADDED';
export const USER_CARD_CREATED = 'USER_CARD_CREATED';

export const CARD_STATUS_CHANGED = 'CARD_STATUS_CHANGED';

export const DIGITAL_WALLET_TOKEN_SUSPENDED = 'DIGITAL_WALLET_TOKEN_SUSPENDED';

export const ACHO_CANCELLED = 'ACHO_CANCELLED';
export const DIRECT_DEPOSIT_RETURNED = 'DIRECT_DEPOSIT_RETURNED';
export const ACHO_STATUS_CHANGED = 'ACHO_STATUS_CHANGED';

export const POLICY_CREATED = 'POLICY_CREATED';
