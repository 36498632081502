import React from 'react';
import PropTypes from 'prop-types';
import { LoadingOverlay, OnPageError, Link, Flex } from '@mqd/volt-base';

import { EditButton } from '../../../edit-button/EditButton';
import { useCardTemplateContext } from '../../../../context/CardTemplateContext';

import s from './PreviewTemplate.module.css';

export function PreviewTemplate({ datum, switchToEdit, editable }) {
  const { previewTemplate: Template, actions } = useCardTemplateContext();

  return (
    <div className={s.container}>
      {datum.loading ? (
        <LoadingOverlay active={datum.loading}>
          <div className={s.fixedHeight} />
        </LoadingOverlay>
      ) : datum.error ? (
        <Flex alignItems="center" justifyContent="center" className={s.fixedHeight}>
          <OnPageError>
            <span>Could not execute action.</span>{' '}
            {/* TODO: replace after this ticket is done https://marqeta.atlassian.net/browse/VOLTENG-45 */}
            <Link inline inheritParentFont onClick={() => actions.retryLastAction(datum)}>
              Retry
            </Link>
          </OnPageError>
        </Flex>
      ) : (
        <>
          <Template datum={datum} />
          {editable && (
            <div className={s.edit}>
              <EditButton onClick={switchToEdit} />
            </div>
          )}
        </>
      )}
    </div>
  );
}

PreviewTemplate.propTypes = {
  datum: PropTypes.any.isRequired,
  switchToEdit: PropTypes.func.isRequired,
  editable: PropTypes.bool,
};

PreviewTemplate.defaultProps = {
  editable: true,
};
