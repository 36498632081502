import React from 'react';
import { observer } from 'mobx-react';
import {
  Container,
  VSpacer,
  Modal,
  Button,
  Textarea,
  LoadingOverlay,
  Text,
  List,
  BannerAlert,
  Icon,
  Link,
  CopyToClipboard,
} from '@mqd/volt-base';
import PropTypes from 'prop-types';
import { ErrorBannerAlert, ParentComponent } from '@mq/voltron-parent';
import CommandoModeGroupStore from './CommandoModeCardGroupStore';
import s from './CommandoModeCardGroup.module.css';
import { secureStorage } from '@mq/volt-amc-container';

class CommandoModeCardGroup extends ParentComponent {
  constructor(props) {
    super(props);
    this.storeConstructor = CommandoModeGroupStore;
    this.runTransition = this.runTransition.bind(this);
  }

  state = {
    transitionModalActive: false,
    transitionReason: '',
    activeStore: {},
  };

  async runTransition() {
    const { transitionReason, transitionsTableStore, activeStore } = this.state;
    const { transition, enabled } = activeStore;
    const { userStore = {} } = this.props;
    const { email = 'unset' } = userStore;
    this.setState({ transitionModalActive: false, transitionReason: '' });
    await transition({
      reason: transitionReason,
      commando_enabled: !enabled,
      username: email,
    });
    transitionsTableStore && transitionsTableStore.hydrate && transitionsTableStore.hydrate();
  }

  renderTransitionModal() {
    const { transitionModalActive, transitionReason, activeStore } = this.state;
    const { enabled } = activeStore;
    if (!transitionModalActive) return null;
    return (
      <Modal
        type="lg"
        description="Enabling commando mode changes how your card program processes Gateway JIT funding
        requests. In commando mode, when your system cannot respond, Marqeta will process Gateway
        JIT funding requests according to business rules you define in a commando mode control
        set."
        heading={`${enabled ? 'Disable' : 'Enable'} commando mode`}
        footerButtons={[
          <Button onClick={this.runTransition}>{enabled ? 'Disable' : 'Enable'}</Button>,
        ]}
        hideModal={() => this.setState({ transitionModalActive: false })}
      >
        <Textarea
          value={transitionReason}
          onChange={(e) => this.setState({ transitionReason: e.target.value })}
          label="Reason for enablement (optional)"
          noResize={true}
        />
      </Modal>
    );
  }

  renderErrors() {
    const { items } = this.store;
    return items.map((commandoModeStore, i) => (
      <ErrorBannerAlert store={commandoModeStore} key={i} />
    ));
  }

  renderNoCommandoModes() {
    return (
      <div className={s.noItems}>
        <Text mod="light" type="lg">
          No commando modes configured
        </Text>
      </div>
    );
  }

  renderList(items) {
    const { handleViewClick, canEditCommandoMode } = this.props;

    let activeColumns = [
      {
        id: 'program_gateway_funding_source',
        label: 'Funding source',
        renderer: 'link',
        onClick: ({ rowIndex }) => handleViewClick && handleViewClick(items[rowIndex].token),
      },
      {
        id: 'token',
        label: 'Token',
        renderer: ({ val }) => {
          return (
            <div onClick={(e) => e.stopPropagation()}>
              <CopyToClipboard overrideValue={val}>
                <div className={s.tokenItem}>{val}</div>
              </CopyToClipboard>
            </div>
          );
        },
      },
      { id: 'enabled_username', label: 'Enabled by', renderer: 'value' },
      { id: 'enabled_channel', label: 'Enabled channel', renderer: 'value' },
      { id: 'enabled_reason', label: 'Enabled reason', renderer: 'value' },
    ];

    if (canEditCommandoMode) {
      activeColumns = [
        {
          id: 'enabled',
          label: 'Status',
          renderer: 'toggle',
          onClick: ({ rowIndex }) => {
            this.setState({ activeStore: items[rowIndex], transitionModalActive: true });
          },
        },
        ...activeColumns,
      ];
    }

    return (
      <div className={s.listWrapper}>
        <List
          columns={activeColumns}
          rows={items.map(
            ({
              token,
              program_gateway_funding_source,
              enabled,
              enabled_username,
              enabled_reason,
              enabled_channel,
              index,
            }) => ({
              enabled: { val: ' ', props: { active: enabled, testId: `volt-toggle-${index}` } },
              program_gateway_funding_source:
                (program_gateway_funding_source && program_gateway_funding_source.name) || '',
              token: token || '',
              enabled_username: enabled_username || '',
              enabled_channel: enabled_channel || '',
              enabled_reason: enabled_reason || '',
            })
          )}
          onRowClick={({ rowIndex, ...remainder }) =>
            handleViewClick && handleViewClick(items[rowIndex].token)
          }
        />
      </div>
    );
  }

  renderCommandoModes() {
    const { items } = this.store;
    if (!items || items.length === 0) {
      return this.renderNoCommandoModes();
    }

    const allItems = items.map((item, index) => {
      item.index = index;
      return item;
    });
    const activeItems = allItems.filter((item) => item.enabled);
    const inactiveItems = allItems.filter((item) => !item.enabled);
    const loading = items.some((item) => item.loading);
    const hasActiveItems = activeItems.length > 0;
    const hasInActiveItems = inactiveItems.length > 0;
    const shouldRenderCommandoModeDoc = !secureStorage.getItem('commando-mode-docs-closed');
    const closeCommandoModeDocs = () =>
      secureStorage.setItem('commando-mode-docs-closed', 'closed');

    return (
      <>
        <Text type="h3" testId="volt-text-commando-title" children="Commando mode" />
        <VSpacer factor={2} />
        {shouldRenderCommandoModeDoc && (
          <BannerAlert
            type="cta"
            heading="What is commando mode?"
            size="lg"
            iconComponent={
              <Container padding={19} gutter={6}>
                <Icon type="guided-sb-setup-active" factor={4} noHoverEffects />
              </Container>
            }
            onHidden={closeCommandoModeDocs}
            disposable
            linkComponent={
              <Link
                href="https://www.marqeta.com/docs/core-api/commando-mode"
                newTab={true}
                iconType="new-tab"
                children="Learn more"
              />
            }
          >
            Commando mode is a fallback measure that ensures Gateway JIT-funded cards continue to
            function in the event your system fails. If your system cannot respond to the JIT
            Funding request, the Marqeta platform makes a decision in your place based on defined
            business rules.
          </BannerAlert>
        )}

        {hasActiveItems && (
          <>
            <VSpacer factor={3} />
            <Text type="h4" children="Active" />
            <VSpacer factor={3} />
            <LoadingOverlay active={loading}>{this.renderList(activeItems)}</LoadingOverlay>
          </>
        )}

        {hasInActiveItems && (
          <>
            <VSpacer factor={3} />
            <Text type="h4" children="Inactive" />
            <VSpacer factor={3} />
            <LoadingOverlay active={loading}>{this.renderList(inactiveItems)}</LoadingOverlay>
          </>
        )}
      </>
    );
  }

  renderLoading() {
    return (
      <LoadingOverlay active={true}>
        <div className={s.loadingBlock} />
      </LoadingOverlay>
    );
  }

  render() {
    const { loading } = this.store;
    return (
      <>
        {this.renderTransitionModal()}
        {this.renderErrors()}
        {loading ? this.renderLoading() : this.renderCommandoModes()}
      </>
    );
  }
}

CommandoModeCardGroup.propTypes = {
  autoHydrate: PropTypes.bool,
  storeInitArgs: PropTypes.object,
  onStoreConstruction: PropTypes.func,
  userStore: PropTypes.object,
  handleViewClick: PropTypes.func,
};

CommandoModeCardGroup.defaultProps = {
  autoHydrate: true,
  storeInitArgs: {},
  onStoreConstruction: null,
  userStore: {},
  handleViewClick: () => {},
};

export default observer(CommandoModeCardGroup);
