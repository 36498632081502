import { SUBNETWORKS } from '../../utilities/dictionary';

const exception_queue = [
  'hash_id',
  'settlement_date',
  'state',
  'variance_amount',
  'source',
  'masked_pan',
  'network_ref_id',
  'program',
  'program_type',
  'bank',
  'ica',
  'message_reason_code',
  'tx_hash_internal_id',
];

const approval_queue = [
  'settlement_date',
  'exception_count',
  'settled_total',
  'adjustment_fees',
  'adjustment_total',
];

const settlement_date_queue = [
  'hash_id',
  'submission_date',
  'state',
  'network_ref_id',
  'settled_total',
  'submitted_total',
  'submitted_variance',
  'program',
  'reason',
  'latest_jcard_error_response',
  'program_type',
  'bank',
  'tx_hash_internal_id',
  'message_reason_code',
];

const hash_group = ({ subnetwork }) => {
  return [
    'hashId',
    'tx_hash_internal_id',
    SUBNETWORKS[subnetwork] === SUBNETWORKS.SUBNETWORK_MAESTRO ? 'net_ref_id' : null,
    'acquirer_reference_id',
    'subnetwork',
    'bank',
    'ica',
    SUBNETWORKS[subnetwork] === SUBNETWORKS.SUBNETWORK_MAESTRO ? 'settlement_service_number' : null,
    'settlementDate',
    'state',
    'exceptionType',
    'submissionDate',
    'submissionType',
    'program_header',
    'program_type',
    'program',
    'reference_id',
  ];
};

const transaction_info = ({ subnetwork }) => {
  const mastercard_sig_fields =
    SUBNETWORKS[subnetwork] === SUBNETWORKS.SUBNETWORK_MASTERCARD_SIG
      ? [
          'message_type_indicator',
          'transaction_function',
          'processing_code',
          'function_code',
          'billing_amount',
          'business_activity',
          'cycle',
        ]
      : [];
  return [
    'source',
    'masked_pan',
    'transaction_token',
    'original_id',
    'retrieval_reference_number',
    'transaction_type',
    'transaction_code',
    'created_date',
    'itc',
    'fee_program_indicator',
    'batch_number',
    'reason_code',
    'message_reason_code',
    'merchant_name',
    'merchant_id',
    'merchant_country',
    'merchant_category_code',
    'acquirer_reference_id',
    'conversion_fee_amount',
    ...mastercard_sig_fields,
  ];
};

const related_transactions = ({ subnetwork }) => {
  const mastercard_sig_fields =
    SUBNETWORKS[subnetwork] === SUBNETWORKS.SUBNETWORK_MASTERCARD_SIG
      ? [
          'message_type_indicator',
          'transaction_function',
          'processing_code',
          'function_code',
          'billing_amount',
          'business_activity',
          'cycle',
        ]
      : [];
  return [
    'settlement_date',
    'network_reference_id',
    'source',
    'settled_total',
    'transaction_total',
    'fee_total',
    'cross_border_fee',
    'interchange_fee',
    'surcharge_atm_pin_debit_fee',
    'masked_pan',
    'transaction_token',
    'original_id',
    'retrieval_reference_number',
    'transaction_type',
    'transaction_code',
    'created_date',
    'itc',
    'fee_program_indicator',
    'batch_number',
    'reason_code',
    'message_reason_code',
    'merchant_name',
    'merchant_id',
    'merchant_country',
    'merchant_category_code',
    'conversion_fee',
    ...mastercard_sig_fields,
  ];
};

export {
  exception_queue,
  approval_queue,
  settlement_date_queue,
  hash_group,
  transaction_info,
  related_transactions,
};
