import React, { useEffect, useState } from 'react';
import { Button } from '@mqd/volt-base';
import PropTypes from 'prop-types';
import { DatePicker } from '@mq/voltron-date-picker';
import { getDateThirtyDaysBeforeDate } from './utils';

const TransactionsTableDatePicker = ({ tableStore, tableParentType }) => {
  const today = new Date();
  const isBusinessTransactions = tableParentType === 'business';
  const defaultStartDate = isBusinessTransactions ? getDateThirtyDaysBeforeDate(today) : '';
  const defaultEndDate = isBusinessTransactions ? today : '';

  //business transactions is only limited to 30 days
  const [filterStartDate, setFilterStartDate] = isBusinessTransactions
    ? useState(tableStore?.start_date || defaultStartDate)
    : useState('');
  const [filterEndDate, setFilterEndDate] = isBusinessTransactions
    ? useState(tableStore?.end_date || defaultEndDate)
    : useState('');
  const [disableAfterDate, setDisableAfterDate] = useState(today);

  useEffect(() => {
    const maxEndDateObject = new Date(filterStartDate);
    maxEndDateObject.setDate(maxEndDateObject.getDate() + 30);
    if (isBusinessTransactions) {
      setDisableAfterDate(maxEndDateObject);
    }
  }, [filterStartDate]);

  const updateFilterDates = (from, to) => {
    tableStore.start_date = from;
    setFilterStartDate(from);
    tableStore.end_date = to;
    setFilterEndDate(to);
  };

  const onClickApply = () => {
    if (!filterEndDate) {
      tableStore.end_date = disableAfterDate;
      setFilterEndDate(disableAfterDate);
    }
    tableStore.hydrate();
  };

  const onClickCancel = () => {
    tableStore.start_date = defaultStartDate;
    setFilterStartDate(defaultStartDate);
    tableStore.end_date = defaultEndDate;
    setFilterEndDate(defaultEndDate);
    tableStore.hydrate();
  };

  return (
    <DatePicker
      closeAfterButtonClick={true}
      disableAfterDay={disableAfterDate}
      disableDateRange={true}
      footerButtons={[
        <Button type="tertiary" onClick={() => onClickCancel()}>
          Cancel
        </Button>,
        <Button onClick={() => onClickApply()}>Apply</Button>,
      ]}
      from={filterStartDate}
      onChange={(from, to) => updateFilterDates(from, to)}
      to={filterEndDate}
    />
  );
};

TransactionsTableDatePicker.propTypes = {
  tableStore: PropTypes.object,
  tableParentType: PropTypes.string,
};

TransactionsTableDatePicker.defaultProps = {
  tableStore: null,
  tableParentType: '',
};

export default TransactionsTableDatePicker;
