import React from 'react';
import PropTypes from 'prop-types';
import { ToastAlert } from '@mqd/volt-base';
import { WEBHOOKS_TOAST_MESSAGE } from '../../constants';

const WebhookUpdatedToast = ({
  dismissToast,
  isSuccessful,
  successMessage = WEBHOOKS_TOAST_MESSAGE.DEFAULT.SUCCESS,
  failedMessage = WEBHOOKS_TOAST_MESSAGE.DEFAULT.FAILED,
}) => {
  return (
    <ToastAlert icon={isSuccessful ? 'success' : 'error'} dismissTime={2} remove={dismissToast}>
      {isSuccessful ? successMessage : failedMessage}
    </ToastAlert>
  );
};

WebhookUpdatedToast.propTypes = {
  dismissToast: PropTypes.func,
  isSuccessful: PropTypes.bool,
};

WebhookUpdatedToast.defaultProps = {
  isSuccessful: true,
  dismissToast: null,
};

export default WebhookUpdatedToast;
