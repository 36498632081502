/* eslint-disable camelcase */
// the unused vars need to be there otherwise the component doesn't rerender on those property change
/* eslint-disable no-unused-vars */
import React from 'react';
import { observer } from 'mobx-react';
import {
  Card,
  Text,
  ContentSnippet,
  HSpacer,
  Button,
  VSpacer,
  Icon,
  LoadingOverlay,
  Tooltip,
} from '@mqd/volt-base';
import { AddIdentificationModal } from './../shared-components';
import { ParentDetailCard } from '@mq/voltron-parent';
import s from './CardholderInfoTab.module.css';
import { StoreContext } from '@mqd/volt-contexts';

class CardholderIdentificationsCard extends ParentDetailCard {
  constructor(props) {
    super(props);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.state = {
      loading: false,
      editActive: false,
      showAddIdentificationModal: false,
    };
  }

  get cardholderStore() {
    const { cardholderStore = {} } = this.props;
    return cardholderStore;
  }

  async handleSave() {
    const { updateCardholder, updateCardholderIdentificationsUam } = this.cardholderStore;
    const { hasUamRoleToken } = this.props;
    this.setState({ loading: true });
    const success = await (hasUamRoleToken
      ? updateCardholderIdentificationsUam()
      : updateCardholder());
    if (success) {
      this.setState({ loading: false, editActive: false });
    } else {
      this.setState({ loading: false });
    }
  }

  handleCancel() {
    const { revertUpdates } = this.cardholderStore;
    revertUpdates && revertUpdates();
    this.setState({ editActive: false });
  }

  renderHeaderButtons() {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button type="outline" onClick={this.handleCancel}>
          Cancel
        </Button>
        <HSpacer />
        <Button onClick={this.handleSave}>Save</Button>
      </div>
    );
  }

  renderIdentifications() {
    const { canRevealIdentification, editable } = this.props;
    const { editActive } = this.state;
    let { identifications, identificationIsEdit, revealIdentification, hideIdentification } =
      this.cardholderStore;
    if (!identifications) {
      identifications = [];
    }
    const snippets = identifications.map((identification, i) => {
      const { type, value, expiration_date, valueWithStars, loading } = identification;
      const isPii = ['SSN', 'SIN', 'TIN', 'NIN'].includes(type);
      return (
        <LoadingOverlay active={loading} key={i}>
          <ContentSnippet title={type} key={i} horizontalLayout>
            <div className={`${s.flexDiv} ${s.spaceBetween}`}>
              <Text>{valueWithStars}</Text>
              {isPii &&
                this.renderSsnRevealButton({
                  editActive,
                  canReveal: canRevealIdentification,
                  value: valueWithStars,
                  reveal: () => revealIdentification(type),
                  hide: () => hideIdentification(type),
                })}
            </div>
            {expiration_date ? (
              <div className={s.idExpires}>
                <Text mod="light">Expires: {expiration_date}</Text>
              </div>
            ) : null}
          </ContentSnippet>
          <VSpacer factor={2} />
        </LoadingOverlay>
      );
    });

    return (
      <div>
        <div className={s.flexDiv}>
          <Text type="h5">Identifications Array</Text>
          <HSpacer />
          <div style={{ height: 16 }}>
            <Tooltip
              zIndex={9999}
              width={340}
              content={
                <div>
                  Forms of identification used to validate the user. Required for verification (KYC)
                  checks.
                  <br />
                  <br />
                  <span style={{ fontWeight: 'bold' }}>Note:</span> Only one of the following
                  identification types can be associated with a user:
                  <ul>
                    <li>SSN – Social Security Number</li>
                    <li>SIN – Social Insurance Number</li>
                    <li>TIN – Taxpayer Identification Number</li>
                    <li>NIN – National Insurance Number</li>
                  </ul>
                  You can also include the following identification types:
                  <ul>
                    <li>PASSPORT_NUMBER</li>
                    <li>DRIVERS_LICENSE</li>
                  </ul>
                </div>
              }
            >
              <Icon type="info" mod="default" hasTooltip />
            </Tooltip>
          </div>
          <HSpacer factor={2} />
          {editable ? (
            <Button
              size="xs"
              type="outline"
              onClick={() => this.setState({ showAddIdentificationModal: true })}
              testId={`${identificationIsEdit ? 'Edit' : 'Add New'}_button`}
            >
              {identificationIsEdit ? 'Edit' : 'Add New'}
            </Button>
          ) : (
            ''
          )}
        </div>
        <VSpacer factor={2} />
        {snippets}
      </div>
    );
  }

  renderAddIdentificationModal() {
    const { showAddIdentificationModal } = this.state;
    const { addIdentification, addIdentificationUam } = this.cardholderStore;
    const { hasUamRoleToken } = this.props;

    if (!showAddIdentificationModal) return null;
    return (
      <AddIdentificationModal
        options={['SSN', 'SIN', 'TIN', 'NIN', 'PASSPORT_NUMBER', 'DRIVERS_LICENSE']}
        disableExpirationDateFor={['SSN', 'SIN', 'TIN', 'NIN']}
        handleSave={async ({ type, value, expirationDate }) => {
          this.setState({ loading: true, showAddIdentificationModal: false });
          if (hasUamRoleToken) {
            addIdentificationUam && (await addIdentificationUam({ type, value, expirationDate }));
          } else {
            addIdentification && (await addIdentification({ type, value, expirationDate }));
          }

          this.setState({ loading: false });
        }}
        hideModal={() => {
          this.setState({ showAddIdentificationModal: false });
        }}
      />
    );
  }

  renderV1Identification() {
    const { editActive } = this.state;
    const { canRevealIdentification } = this.props;
    const {
      ssn,
      passport_number,
      passport_expiration_date,
      id_card_number,
      id_card_expiration_date,
      updateForSave,
      revealV1Identification,
      hideV1Identification,
      ssnWithStars,
      loading,
    } = this.cardholderStore;

    return (
      <div>
        <div className={`${s.flexDiv} ${s.spaceBetween}`}>
          <div className={s.flexDiv}>
            <Text type="h5">Identification Attributes</Text>
            <HSpacer />
            <div style={{ height: 16 }}>
              <Tooltip
                zIndex={9999}
                width={340}
                content={`
                    Forms of identification used to validate the user.
                    Required for verification (KYC) checks.
                  `}
              >
                <Icon type="info" mod="deafult" hasTooltip />
              </Tooltip>
            </div>
          </div>
          {this.renderSsnRevealButton({
            editActive,
            canReveal: canRevealIdentification,
            value: ssnWithStars,
            reveal: () => revealV1Identification(),
            hide: () => hideV1Identification(),
          })}
        </div>
        <VSpacer factor={2} />
        <LoadingOverlay active={loading}>
          {this.renderSnippet({
            title: 'SSN',
            content: ssnWithStars,
            onChange: (e) => updateForSave('ssn', e.target.value),
            editActive,
            tooltipContent: `
                User Social Securty Number V1
              `,
          })}
        </LoadingOverlay>
        {this.renderSnippet({
          title: 'Passport Number',
          content: passport_number,
          onChange: (e) => updateForSave('passport_number', e.target.value),
          editActive,
          tooltipContent: `
              User Passport Number V1
            `,
        })}
        {this.renderSnippet({
          title: 'Passport Expiration Date',
          content: passport_expiration_date,
          onChange: (e) => updateForSave('passport_expiration_date', e.target.value),
          editActive,
          tooltipContent: `
              User Passport Expiration V1
            `,
        })}
        {this.renderSnippet({
          title: 'ID Card Number',
          content: id_card_number,
          onChange: (e) => updateForSave('id_card_number', e.target.value),
          editActive,
          tooltipContent: `
              User ID Card Number V1
            `,
        })}
        {this.renderSnippet({
          title: 'ID Card Expiration Date',
          content: id_card_expiration_date,
          onChange: (e) => updateForSave('id_card_expiration_date', e.target.value),
          editActive,
          tooltipContent: `
              User ID Card Expiration Date V1
            `,
        })}
      </div>
    );
  }

  renderInfo() {
    const { canEditIdentifications } = this.props;
    const { editActive } = this.state;
    const {
      ssn,
      passport_number,
      passport_expiration_date,
      id_card_number,
      id_card_expiration_date,
      updateForSave,
      identifications,
      loading,
    } = this.cardholderStore;
    // if observables are not declared at where the context is called, a re-render doesn't happen
    const {
      loading: identificationLoading,
      value,
      type,
    } = (identifications && identifications[0]) || {};
    return (
      <StoreContext.Consumer>
        {({ ProgramConfigStore = {} }) => {
          return ProgramConfigStore.isV2 ? (
            <Card title="Identifications" testId="v2-identifications">
              {this.renderIdentifications()}
            </Card>
          ) : (
            <Card
              title="Identifications"
              testId="v1-identifications"
              controls={
                canEditIdentifications &&
                (editActive ? this.renderHeaderButtons() : this.renderControl())
              }
            >
              {this.renderV1Identification()}
            </Card>
          );
        }}
      </StoreContext.Consumer>
    );
  }

  render() {
    const { loading } = this.state;
    return (
      <LoadingOverlay active={loading}>
        {this.renderAddIdentificationModal()}
        {this.renderInfo()}
      </LoadingOverlay>
    );
  }
}

export default observer(CardholderIdentificationsCard);
