import React, { PureComponent } from 'react';
import { Modal, Button, Select, Input, VSpacer, ModalAlert, Text } from '@mqd/volt-base';
import PropTypes from 'prop-types';

class AddIdentificationModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      type: '',
      value: '',
      error: '',
    };
  }

  componentDidMount() {
    const { values, isEdit } = this.props;
    isEdit &&
      this.setState({
        ...values,
      });
  }

  resetState() {
    this.setState({
      type: '',
      value: '',
    });
  }

  renderErrorModal() {
    const { error } = this.state;
    if (!error) return null;
    return (
      <ModalAlert type="danger" title="Error" hideModal={() => this.setState({ error: '' })}>
        <Text>{error}</Text>
      </ModalAlert>
    );
  }

  render() {
    const { options, handleSave, hideModal, disableExpirationDateFor } = this.props;
    const { type, value, expirationDate } = this.state;
    const disableExpirationDate = disableExpirationDateFor.includes(type);
    const handleHideModal = () => {
      hideModal && hideModal();
      this.resetState();
    };
    const dropDownWrap = {
      width: '99.9%',
      height: '140px',
    };

    return (
      <Modal
        heading="Add Identification"
        hideModal={handleHideModal}
        disableOverFlow
        footerButtons={[
          <Button
            key="save"
            onClick={() => {
              if (!value) return this.setState({ error: 'Identification number cannot be empty' });
              if (value.match(/[^\w]|_/g)) {
                return this.setState({
                  error: 'Please enter a valid identification number without special characters',
                });
              }
              if (handleSave) {
                const data = disableExpirationDate
                  ? { type, value }
                  : { type, value, expirationDate };
                handleSave(data);
              }
              this.resetState();
            }}
          >
            Save
          </Button>,
        ]}
      >
        {this.renderErrorModal()}
        <Select
          label="Type"
          placeholder="Select Type"
          dropDownWrap={dropDownWrap}
          value={type}
          options={options}
          onChange={(type) => this.setState({ type })}
        />
        <VSpacer factor={4} />
        <Input
          label="Value"
          testId="identification-value"
          placeholder="Enter Value"
          value={value && value.replace(/_/g, '*')}
          onChange={(e) => this.setState({ value: e.target.value })}
        />
      </Modal>
    );
  }
}

AddIdentificationModal.propTypes = {
  options: PropTypes.array,
  handleCancel: PropTypes.func,
  handleSave: PropTypes.func,
  hideModal: PropTypes.func,
  isEdit: PropTypes.bool,
  values: PropTypes.object,
  disableExpirationDateFor: PropTypes.array,
};

AddIdentificationModal.defaultProps = {
  options: [],
  handleCancel: () => {},
  handleSave: () => {},
  hideModal: () => {},
  values: {
    type: '',
    value: '',
    expirationDate: '',
  },
  isEdit: false,
  disableExpirationDateFor: [],
};

export default AddIdentificationModal;
