import { observer } from 'mobx-react';
import React from 'react';
import { Flex, Text, Colors, VSpacer } from '@mqd/volt-base';
import s from './styles.module.css';
import { defaultCountryName } from '../utils/constants';

function AddressView({ formState, children, editButton }) {
  const {
    city,
    state,
    postal,
    firstName,
    address1,
    address2,
    lastName,
    country = defaultCountryName,
  } = formState || {};
  const addresses = [city, state, postal].filter((el) => el);

  return (
    <section data-testid="bulk-issuance-address-view">
      <Flex justifyContent="space-between" alignItems="start">
        <Text
          type="h5"
          color={Colors.blackLighter1}
          className={`${s.truncate} ${s.headerWidth}`}
          testId="address_view_firstName_lastName"
        >
          {firstName} {lastName}
        </Text>
        {editButton}
      </Flex>
      <VSpacer factor={0.5} />
      <Text
        type="body-sm"
        color={Colors.blackLighter3}
        className={`${s.truncate} ${s.textWidth}`}
        testId="address_view_address1"
      >
        {address1}
      </Text>
      <Text
        type="body-sm"
        color={Colors.blackLighter3}
        className={`${s.truncate} ${s.textWidth}`}
        testId="address_view_address2"
      >
        {address2}
      </Text>
      <Text
        type="body-sm"
        color={Colors.blackLighter3}
        className={`${s.truncate} ${s.textWidth}`}
        testId="address_view_addresses"
      >
        {addresses.join(', ')}
      </Text>
      <Text
        type="body-sm"
        color={Colors.blackLighter3}
        className={`${s.truncate} ${s.textWidth}`}
        testId="address_view_country"
      >
        {country}
      </Text>
      {children}
    </section>
  );
}

export default observer(AddressView);
