import React, { useState } from 'react';
import WebhookStatusIndicatorToggle from './WebhookStatusIndicatorToggle.js';
import { WebhookProvider } from '../contexts/index.js';
import { findIndex, propEq } from 'ramda';
import { translateGraphQLWebhookTypeToFormValues } from '../functions/webhooks.js';
import { useUpdateWebhook } from '../hooks/index.js';
import { renderIf } from 'utils';
import { WebhookUpdatedToast } from '../webhooks/components/index.js';
import { MaximumActiveEventsModal } from '../components/index.js';
import { countActiveWebhooks } from '../functions/webhooks';
import { WEBHOOKS_TOAST_MESSAGE } from '../constants.js';

const WebhooksTableStateColumn = ({ webhook, webhooks, setWebhooksData }) => {
  const [showMaxActiveEventsModal, setShowMaxActiveEventsModal] = useState(false);

  const shouldShowMaxActiveEventsModal = () => {
    const newActiveWebhooksCount = countActiveWebhooks(webhooks);
    if (newActiveWebhooksCount >= 5) {
      setShowMaxActiveEventsModal(true);
      return true;
    }
    return false;
  };

  const hideModalCallback = () => {
    setShowMaxActiveEventsModal(false);
  };

  const onCompleted = ({ publicSandboxUpdateWebhook }, _error) => {
    setUpdateSuccessful(_error ? false : true);
    setShowUpdateToast(true);
    setIsActive(publicSandboxUpdateWebhook.active);
    // SET WEBHOOKS
    const webhookIndex = findIndex(propEq('token', publicSandboxUpdateWebhook.token))(webhooks);
    const newWebhooks = [...webhooks];
    const newWebhook = webhooks[webhookIndex];
    newWebhook.active = publicSandboxUpdateWebhook.active;
    newWebhooks[webhookIndex] = newWebhook;
    setWebhooksData(newWebhooks);
  };

  const dismissToast = () => {
    setShowUpdateToast(false);
  };

  const { token, active } = webhook;
  const [isActive, setIsActive] = useState(active);
  const { updateWebhook, error, loading: updating } = useUpdateWebhook(onCompleted);
  const [updateSuccessful, setUpdateSuccessful] = useState(true);
  const [showUpdateToast, setShowUpdateToast] = useState(false);

  return (
    <>
      {renderIf(
        webhook,
        <WebhookProvider
          defaultWebhookValues={translateGraphQLWebhookTypeToFormValues(webhook)}
          error={error}
          onSave={updateWebhook}
          saving={updating}
        >
          <WebhookStatusIndicatorToggle
            saving={updating}
            active={isActive}
            onDisable={() => console.log(`Disable webhook ${token}`)}
            onActivate={() => shouldShowMaxActiveEventsModal()}
          />
        </WebhookProvider>
      )}
      {renderIf(
        showUpdateToast,
        <WebhookUpdatedToast
          dismissToast={dismissToast}
          isSuccessful={updateSuccessful}
          successMessage={WEBHOOKS_TOAST_MESSAGE.EVENTS_SAVED.SUCCESS}
          failedMessage={WEBHOOKS_TOAST_MESSAGE.EVENTS_SAVED.FAILED}
        />
      )}
      {renderIf(
        showMaxActiveEventsModal,
        <MaximumActiveEventsModal hideModalCallback={hideModalCallback} />
      )}
    </>
  );
};

export { WebhooksTableStateColumn };
