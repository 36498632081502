export const WELCOME_STEPS = {
  CREATOR_VIEW: {
    steps: {
      1: {
        direction: 'left',
        heading: 'Create your policies',
        message: 'Create a new credit policy for credit products, APRs, fees, rewards, or offers.',
        selector: '[data-testid="volt-raw-dropdown"]',
      },
      2: {
        direction: 'topRight',
        heading: 'View your policies',
        message: 'Select a policy type to view existing policies of that type.',
        selector: '[data-testid="policyDashboardItem"]:nth-child(2)',
      },
      3: {
        direction: 'right',
        heading: 'Go to Bundles',
        message:
          'Combine your policies together into a Bundle, which enables you to design credit cards. Once approved and activated, Bundles can be use to onboard new customers.',
        selector: '[data-testid="left-nav-sublink_/credit/bundles"]',
      },
      4: {
        direction: 'right',
        heading: 'View bundles in work queue',
        message:
          'Use the Work queue to view Bundles pending approval and collaborate with the approver.',
        selector: '[data-testid="left-nav-sublink_/credit/work-queue"]',
      },
    },
    listHeader: 'To launch a credit program...',
    listTexts: [
      'Create policies for credit products, APRs, fees, rewards, and offers.',
      'Create a bundle from existing policies that align with your strategic goals.',
      'Submit your bundle for approval.',
      'Once approved, you can activate your bundle and onboard new customer accounts.',
    ],
    links: [
      {
        header: 'What is a policy?',
        content:
          'Policies are the components that make up a bundle and contain configurations for credit products, APRs, fees, rewards, and offers.',
      },
      {
        header: 'What is a bundle?',
        content:
          'A bundle is comprised of several policies and enables you to design credit cards.',
      },
      {
        header: 'What are templates',
        content: 'Templates allow you to customize the terms of use and disclosures in a bundle.',
      },
    ],
  },
  APPROVER_VIEW: {
    steps: {
      1: {
        direction: 'right',
        heading: 'Policy library',
        message:
          'Go to the Policy library to view pending and active policies. Policies are the components that make up a Bundle and contain configurations for credit products, APRs, fees, rewards, and offers.',
        selector: '[data-testid="left-nav-sublink_/credit/policy-library"]',
      },
      2: {
        direction: 'right',
        heading: 'Bundles',
        message:
          'Go to Bundles to view active bundles. Policies are combined together into a Bundle, which is a credit card being used by cardholders',
        selector: '[data-testid="left-nav-sublink_/credit/bundles"]',
      },
      3: {
        direction: 'right',
        heading: 'Work queue',
        message:
          'Use the Work queue to view Bundles submitted for your review and collaborate with the creator. You can approve, reject, or send back bundles that need revision to the creator.',
        selector: '[data-testid="left-nav-sublink_/credit/work-queue"]',
      },
    },
    listHeader: 'To review a credit bundle...',
    listTexts: [
      'View credit bundles submitted for review.',
      'Approve, reject, or request revisions for pending credit bundles ',
      'View active bundles being used by cardholders.',
    ],
    links: [
      {
        header: 'What is a bundle?',
        content:
          'A bundle is comprised of several policies and enables you to design credit cards.',
      },
      {
        header: 'What is a policy?',
        content:
          'Policies are the components that make up a bundle and contain configurations for credit products, APRs, fees, rewards, and offers.',
      },
      {
        header: 'What are templates',
        content: 'Templates allow you to customize the terms of use and disclosures in a bundle.',
      },
    ],
  },
  BRAND_CONTRIBUTOR_VIEW: {
    steps: {
      1: {
        direction: ['bottomRight', 'right'],
        heading: 'Activate bundles',
        message:
          'Review and activate credit bundles awaiting activation. Active credit bundles are to be used by cardholders.',
        selector: [
          '[data-testid="bundles-list"] tbody tr:first-child td[class*="cell-status"] p',
          '[data-testid="volt-icon__empty-state-folder-default"]',
        ],
      },
    },
    listHeader: 'To activate a credit bundle...',
    listTexts: [
      'View credit bundles awaiting activation.',
      'Activate credit bundles to be used by cardholders.',
    ],
    links: [
      {
        header: 'What is a bundle?',
        content:
          'A bundle is comprised of several policies and enables you to design credit cards.',
      },
    ],
  },
};
