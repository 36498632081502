import React from 'react';
import PropTypes from 'prop-types';
import { Select } from '@mqd/volt-base';
import KycStatus from './../../shared-components/kyc-status/KycStatus';
import s from './../ReviewDetails.module.css';

function selectedValue(value, showText, field) {
  const element = (
    <div data-testid={`rad-handle-${field}`}>
      <KycStatus status={value} showText={showText} />
    </div>
  );
  return {
    val: value,
    render: element,
    renderSelected: element,
  };
}

function generateOptions(options, field) {
  return options.map((option) => {
    const element = (
      <div className={s.dropdownOption} data-testid={`rad-option-${field}-${option}`}>
        <KycStatus status={option} showText={true} />
      </div>
    );

    return {
      val: option,
      render: element,
      renderSelected: element,
    };
  });
}

function ReviewActionDropdown({ field, value, options, changeVal, showText }) {
  const dropDownWrap = {
    // this is to allow options to be wider than selectedValue
    // need to either compute it or set in accordance with longest status
    width: 150,
  };

  return (
    <div className={s.actionDropdownContainer}>
      <Select
        testId={`review-action-dropdown-${field}`}
        value={selectedValue(value, showText, field)}
        options={generateOptions(
          options.filter((o) => o !== value),
          field
        )}
        onChange={(option) => changeVal(field, option)}
        dropDownWrap={dropDownWrap}
      />
    </div>
  );
}

ReviewActionDropdown.propTypes = {
  value: PropTypes.string,
  options: PropTypes.array,
  changeVal: PropTypes.func,
  field: PropTypes.string,
  showText: PropTypes.bool,
  showBorder: PropTypes.bool,
};
ReviewActionDropdown.defaultProps = {
  field: '',
  value: '',
  options: [],
  changeVal: () => {},
  showText: false,
  showBorder: false,
};

export default ReviewActionDropdown;
