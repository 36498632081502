import { useEffect, useState } from 'react';
import fetchGraphql from '../../../../../utilities/graphql/fetch-graphql.js';

const query = `
  query {
    currencyCodes
  }
`;

const fetchCurrencyCodes = () => fetchGraphql({ query });

const PRIORITIZED_CURRENCY_CODES = ['USD', 'CAD'];
const sortCurrencyCodesByPriority = (currencyCodes) =>
  currencyCodes.slice().sort((a, b) => {
    if (PRIORITIZED_CURRENCY_CODES.includes(a)) {
      return -1;
    } else if (PRIORITIZED_CURRENCY_CODES.includes(b)) {
      return 1;
    }
    return 0;
  });

export const useSortedCurrencyCodes = () => {
  const [currencyCodes, setCurrencyCodes] = useState([]);

  useEffect(() => {
    (async () => {
      const {
        data: { currencyCodes },
      } = await fetchCurrencyCodes();

      const sortedCurrencyCodes = sortCurrencyCodesByPriority(currencyCodes);

      setCurrencyCodes(sortedCurrencyCodes);
    })();
  }, []);

  return currencyCodes;
};
