import React from 'react';
import { HSpacer, Button } from '@mqd/volt-base';
import s from './KYBCard.module.css';
import PropTypes from 'prop-types';

class FooterButtons extends React.PureComponent {
  render() {
    const { closeEditState, onSave } = this.props;
    return (
      <div className={s.footerButtons}>
        <Button testId="KYB-edit-button" type="tertiary" onClick={closeEditState}>
          Cancel
        </Button>
        <HSpacer factor={2} />
        <Button testId="KYB-save-button" onClick={onSave}>
          Save
        </Button>
      </div>
    );
  }
}

FooterButtons.propTypes = {
  closeEditState: PropTypes.func,
  onSave: PropTypes.func,
};
FooterButtons.defaultProps = {
  closeEditState: () => {},
  onSave: () => {},
};

export default FooterButtons;
