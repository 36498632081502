import fetchGraphql from '../../../../utilities/graphql/fetch-graphql.js';
import { format } from '../../../../utilities/date.js';
import { ENTRY_TYPE } from '../../constants.js';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment/moment';

export const query = `
mutation createProgramReserveTransactionsMutation(
  $date: String!
  $transactions: [TransactionInput!]!
) {
  createProgramReserveTransactions(
    date: $date
    transactions: $transactions
  )
}
`;

export const creditProgramsFundingQuery = `
mutation createCreditProgramsFunding(
$programsFunding: CreditProgramsFundingInput!) {
        createCreditProgramsFunding(programsFunding: $programsFunding) {
            token
        }
}
`;

const formatTransaction = (transaction, bankFileName) => {
  return {
    program: transaction.program,
    token: transaction.id,
    amount: transaction.amount,
    is_collateral: transaction.is_collateral,
    currency_code: transaction.currency,
    memo: transaction.memo,
    bank_file: bankFileName,
    type: transaction.amount > 0 ? ENTRY_TYPE.CREDIT : ENTRY_TYPE.DEBIT,
  };
};

const sendCreditProgramsFundingRequest = async (query, variables) => {
  // pass the variable to the creditProgramsFundingQuery as query and variable

  const response = await fetchGraphql({ query, variables });

  if (response?.errors?.length) {
    const message = response.errors.map((error) => error.message).join('\n');
    throw new Error(message);
  }

  return response;
};

const separateCollateralEntries = (transactions, bankFileName) => {
  let separatedTransactions = [];
  transactions.toJS().forEach((transaction) => {
    // if the transaction has credit amount, send a creditProgramsFunding request
    // and delete the creditAmount from the transaction
    if (transaction.creditAmount) {
      let variables = {
        programsFunding: {
          shortCode: transaction.program,
          amount: transaction.creditAmount,
          currencyCode: transaction.currency,
          postTime: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss[Z]'),
          memo: transaction.memo,
        },
      };
      sendCreditProgramsFundingRequest(creditProgramsFundingQuery, variables);
    }
    delete transaction.creditAmount;

    // if transaction has deposit amount and collateral amount,
    // need to separate the the transaction into two:
    // one for amount with collateral set to false, and one where amount
    // is set to collateral amount and collateral is set to true
    if (transaction.collateralAmount) {
      if (Math.abs(transaction.amount) !== 0) {
        const amountsTransaction = {
          ...transaction,
          is_collateral: false,
        };

        separatedTransactions.push(formatTransaction(amountsTransaction, bankFileName));
      }

      if (Math.abs(transaction.collateralAmount) !== 0) {
        const collateralTransaction = {
          ...transaction,
          //needs a different token from the normal amount transaction
          id: transaction.amount ? uuidv4() : transaction.id,
          is_collateral: true,
          amount: transaction.collateralAmount,
        };

        separatedTransactions.push(formatTransaction(collateralTransaction, bankFileName));
      }
    } else {
      if (Math.abs(transaction.amount) !== 0) {
        separatedTransactions.push(formatTransaction(transaction, bankFileName));
      }
    }
  });
  return separatedTransactions;
};

export const submitTransactions = async (transactions, bankFileName) => {
  const variables = {
    date: format(new Date()),
    transactions: separateCollateralEntries(transactions, bankFileName),
  };

  const response = await fetchGraphql({ query, variables });

  if (response?.errors?.length) {
    const message = response.errors.map((error) => error.message).join('\n');
    throw new Error(message);
  }

  return response;
};
