import React from 'react';
import { Flex, VSpacer, Colors, Text } from '@mqd/volt-base';
import s from './styles.module.css';

const ViewTemplate = ({ children, editButton, formState }) => {
  if (!formState) {
    return null;
  }

  return (
    <section>
      <Flex justifyContent="space-between" alignItems="start">
        <Text
          type="h5"
          color={Colors.blackLighter1}
          className={`${s.truncate} ${s.headerWidth}`}
          testId={'card_carrier_logo_file'}
        >
          {formState['logo_file']}
        </Text>
        {editButton}
      </Flex>
      <VSpacer factor={0.5} />
      <Text
        type="body-sm"
        color={Colors.blackLighter3}
        className={`${s.truncate} ${s.textWidth}`}
        testId={'message_line'}
      >
        {formState['message_line']}
      </Text>
      <Text
        type="body-sm"
        color={Colors.blackLighter3}
        className={`${s.truncate} ${s.textWidth}`}
        testId={'carrier_return_window'}
      >
        {formState['carrier_return_window']}
      </Text>
      {children}
    </section>
  );
};

export default ViewTemplate;
