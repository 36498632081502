import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import StyleContext from '../contexts/StyleContext';
import CommandoModeTransitionsTableStore from './CommandoModeTransitionsTableStore';
import { containerHeightHOC, ParentTable, Status } from '@mq/voltron-parent';
import { Table } from '@mq/voltron-table';
import { LoadingOverlay, VSpacer } from '@mqd/volt-base';

const topNavHeight = 119;
const viewHeaderHeight = 82;
const aboveTableHeight = 107;
const tableHeaderAndPadding = 70;
const padding = 28;
const tableOffset =
  topNavHeight + viewHeaderHeight + aboveTableHeight + tableHeaderAndPadding + padding;

class CommandoModeTransitionsTable extends ParentTable {
  constructor(props) {
    super(props);
    this.storeConstructor = CommandoModeTransitionsTableStore;
    this.state = this.colEditState(props, this.columns());
  }

  statusRenderer() {
    return ({ cellValue }) => {
      return (
        <div>
          <Status status={cellValue} />
        </div>
      );
    };
  }

  columns() {
    return [
      {
        header: 'Enabled',
        accessor: 'enabledString',
        key: 'enabledString',
        renderer: this.statusRenderer(),
      },
      { header: 'Reason', accessor: 'enabled_reason', key: 'enabled_reason' },
      { header: 'Channel', accessor: 'enabled_channel', key: 'enabled_channel' },
      { header: 'Userame', accessor: 'enabled_username', key: 'enabled_username' },
      { header: 'Token', accessor: 'token', key: 'token', primaryKey: true },
      { header: 'Type', accessor: 'type', key: 'type' },
      {
        header: 'Commando Mode Token',
        accessor: 'commando_mode_token',
        key: 'commando_mode_token',
      },
      { header: 'Created', accessor: 'created_time', key: 'created_time' },
      { header: 'Name', accessor: 'name', key: 'name' },
    ];
  }

  render() {
    const { currentList, activeSort, setAttr, updateKey, loading } = this.store;
    const { columns, tableKey } = this.state;
    const { dynamicHeight } = this.props;

    return (
      <div style={{ width: '100%' }}>
        {this.renderColEditTableHeader({
          tableFilterTestId: 'commando-mode-transitions-table-header-buttons',
        })}
        <VSpacer />
        <LoadingOverlay active={loading}>
          <Table
            loading={loading}
            key={tableKey}
            height={dynamicHeight || this.dynamicHeight}
            data={currentList}
            columns={columns}
            sort={activeSort}
            onSort={(newSort) => setAttr('activeSort', newSort)}
            scrollToTopUpdateKey={updateKey}
            handleColSizeChange={this.handleColSizeChange.bind(this)}
          />
        </LoadingOverlay>
        {this.renderPagination()}
      </div>
    );
  }
}

CommandoModeTransitionsTable.propTypes = {
  dynamicHeight: PropTypes.number,
  storeInitArgs: PropTypes.object,
  store: PropTypes.object,
  userStore: PropTypes.object,
  autoHydrate: PropTypes.bool,
  onStoreConstruction: PropTypes.func,
  gqlClient: PropTypes.any,
  localStorageKey: PropTypes.string,
};

CommandoModeTransitionsTable.defaultProps = {
  dynamicHeight: 800,
  storeInitArgs: { limit: 100 },
  store: null,
  userStore: {},
  autoHydrate: true,
  onStoreConstruction: null,
  gqlClient: null,
  localStorageKey: 'CommandoModeTransitionsTableConfig',
};

export default containerHeightHOC(observer(CommandoModeTransitionsTable), tableOffset);
