import React from 'react';
import { observer } from 'mobx-react';
import { Card, ContentSnippet, Text, VSpacer } from '@mqd/volt-base';
import { ParentDetailCard } from '@mq/voltron-parent';
import DetailCard from '../detail-card/DetailCard';

class CommandoModeProtectedFundingSourceCard extends ParentDetailCard {
  state = {
    editActive: false,
  };

  get commandoModeStore() {
    const { commandoModeStore = {} } = this.props;
    return commandoModeStore;
  }

  renderInfo() {
    const { program_gateway_funding_source = {} } = this.commandoModeStore;
    const { name, url, active, token, timeout_millis, account, last_modified_time, created_time } =
      program_gateway_funding_source;
    return (
      <DetailCard title="Protected funding sources">
        {this.renderSnippet({
          title: 'Name',
          content: name,
          tooltipContent: 'The name of the funding program.',
        })}
        {this.renderSnippet({
          title: 'Url',
          content: url,
          tooltipContent: `
              The URL of the gateway endpoint 
              hosted in your environment, to 
              which POST messages are submitted
              by Marqeta.
            `,
        })}
        {this.renderSnippet({
          title: 'Token',
          content: token,
          tooltipContent: `
              The unique identifier of 
              the funding source.
            `,
        })}
        {this.renderSnippet({
          title: 'Timeout',
          content: timeout_millis,
          tooltipContent: `
              Total timeout in milliseconds 
              for gateway processing.
            `,
        })}
        {this.renderSnippet({
          title: 'Account',
          content: account,
          tooltipContent: 'Funding source account',
        })}
        {this.renderSnippet({
          title: 'Created',
          content: created_time,
          tooltipContent: `
              A timestamp of when this 
              commando mode was
              created in the Marqeta API
            `,
        })}
        {this.renderSnippet({
          title: 'Last Modified',
          content: last_modified_time,
          tooltipContent: `
              A timestamp of when this
              commando mode was
              last updated in the Marqea API
            `,
        })}
      </DetailCard>
    );
  }

  render() {
    return <div>{this.renderInfo()}</div>;
  }
}

export default observer(CommandoModeProtectedFundingSourceCard);
