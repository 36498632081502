import React from 'react';
import PropTypes from 'prop-types';
import { Flex, HSpacer, Button, Link, Text } from '@mqd/volt-base';
import { TableFilters, Pagination } from '@mq/voltron-table';
import { DatePicker } from '@mq/voltron-date-picker';
import { ExportTransactionsOverlay } from './index';

const Export = ({ disabled, onClick }) => (
  <Text>
    <Link
      type="secondary"
      onClick={onClick}
      disabled={disabled}
      iconType="download"
      iconDirection="left"
      inheritParentFont
      pillHover
      testId="transaction-list-export-button"
    >
      Download
    </Link>
  </Text>
);

const Picker = ({ from, to, onChange, onClear, onSubmit }) => (
  <DatePicker
    from={from}
    to={to}
    onChange={onChange}
    footerButtons={[
      <Button type="outline" onClick={onClear}>
        Clear
      </Button>,
      <Button onClick={onSubmit}>Filter</Button>,
    ]}
  />
);

const Header = ({
  disableExport,
  handleExport,
  cancelExportRequest,
  loadingExport,
  transactionsQueryFn,
  pagination = {},
  filterCount,
  filterOptions = [],
  filters = {},
  handleSearch,
  handleClear,
  handleDateRangeChange,
  handleDateRangeClear,
}) => {
  // this is to make sure TableFilters dropdown stays open
  // while user works with dropdowns created by multiselect controls
  const multiSelectFilter = (filterOptions || []).some((fo) => fo.type === 'multiselect');

  const handleNextClick = () => {
    transactionsQueryFn({
      variables: {
        start_index: pagination.startIndex + pagination.pageSize,
        count: pagination.pageSize,
        ...filters,
      },
    });
  };

  const handlePreviousClick = () => {
    transactionsQueryFn({
      variables: {
        start_index: pagination.startIndex - pagination.pageSize,
        count: pagination.pageSize,
        ...filters,
      },
    });
  };

  return (
    <>
      <ExportTransactionsOverlay
        loadingExport={loadingExport}
        cancelExportRequest={cancelExportRequest}
      />
      <Flex display="flex" alignItems="center">
        <TableFilters
          type="small"
          controlText="Filter table"
          activeFilterCount={filterCount}
          filterOptions={filterOptions}
          handleClear={handleClear}
          handleSearch={handleSearch}
          preventCloseClassList={multiSelectFilter ? ['volt-options-wrap-hook'] : []}
          fetchOnClear={false}
        />
        <HSpacer />
        <Picker
          from={filters.start_date}
          to={filters.end_date}
          onChange={handleDateRangeChange}
          onClear={handleDateRangeClear}
          onSubmit={handleSearch}
        />
        <Flex display="flex" style={{ marginLeft: 'auto' }}>
          <Export disabled={disableExport} onClick={() => handleExport(filters)} />
          {(pagination.isMore || pagination.isLess) && (
            <>
              <HSpacer factor={2} />
              <Pagination
                handleNextClick={handleNextClick}
                handlePreviousClick={handlePreviousClick}
                {...pagination}
              />
            </>
          )}
        </Flex>
      </Flex>
    </>
  );
};

Header.propTypes = {
  disableExport: PropTypes.bool,
  handleExport: PropTypes.func,
  cancelExportRequest: PropTypes.func,
  loadingExport: PropTypes.bool,
  transactionsQueryFn: PropTypes.func,
  pagination: PropTypes.object,
  filterCount: PropTypes.number,
  filterOptions: PropTypes.arrayOf(PropTypes.object),
  filters: PropTypes.object,
  handleSearch: PropTypes.func,
  handleClear: PropTypes.func,
  handleDateRangeChange: PropTypes.func,
  handleDateRangeClear: PropTypes.func,
};

Header.defaultProps = {
  pagination: {},
  filterOptions: [],
  filters: {},
};

export default Header;
