export const inProgress = 'in-progress';
export const changedReview = 'changed-review';
export const approvalReview = 'approval-review';
export const approvedReview = 'approved-review';
export const disabledStatuses = new Set([approvalReview, approvedReview]);

export const changeStatusTypeMap = {
  [changedReview]: 'changed',
  [approvalReview]: 'changed',
  [approvedReview]: 'completed',
};
