import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Colors, Flex, HSpacer, Icon, Link, Text, FlatCard } from '@mqd/volt-base';
import AddressEdit from './components/AddressEdit';
import AddressView from './components/AddressView';
import s from './styles.module.css';
import WithRequired from './components/WithRequired';

function Address({ address, actions, overrideIsFlat, name, requiredFields = {}, canEdit = true }) {
  const [showFlatCard, setShowFlatCard] = useState(false);
  const [isFlat, setIsFlat] = useState(overrideIsFlat || false);

  const TemplateEdit = WithRequired(AddressEdit, requiredFields);

  const addId = `bulk-issuance-add-${
    name && name === 'returnAddress'
      ? 'return'
      : name && name === 'recipientAddress'
      ? 'recipient'
      : ''
  }-address-button`;

  const addBtnTxt = `Add ${
    name && name === 'returnAddress'
      ? 'return'
      : name && name === 'recipientAddress'
      ? 'recipient'
      : ''
  } address`;

  if (showFlatCard) {
    return (
      <FlatCard
        editTemplate={TemplateEdit}
        viewTemplate={AddressView}
        cardType="address"
        data={address}
        onAddCard={(card) => actions.createAction(card)}
        onUpdateCard={(card) => actions.updateAction(card)}
        onCancelCard={() => {
          setShowFlatCard(false);
          actions.cancelAction();
        }}
        onDeleteCard={() => {
          setShowFlatCard(false);
          actions.deleteAction();
        }}
        overrideIsFlat={isFlat}
        canEdit={canEdit}
        onEdit={() => {
          setIsFlat(false);
        }}
      />
    );
  }

  return (
    <Flex justifyContent="flex-start">
      <HSpacer factor={1.25} />
      <Link onClick={() => setShowFlatCard(true)} testId={addId}>
        <Icon overrideColor={Colors.actionColor} type="plus" noHoverEffects />
        <HSpacer factor={0.75} />
        <Text type="footnote" className={s.bold}>
          {addBtnTxt}
        </Text>
      </Link>
    </Flex>
  );
}
export default observer(Address);
