import React, { useMemo, useState } from 'react';
import {
  mergeColumnsWithStoredSettings,
  clearColumnConfig,
  saveColumnConfig,
  ActionsHeader,
  ColumnEditor,
} from '@mq/voltron-table';

import getFields from 'views/fds/network-configs/fields/index.js';

// Columns that are generated and cannot be customized with the column editor.
const SELECTOR_COLUMN_KEY = 'selected';
const ACTIONS_COLUMN_KEY = 'actions';
const GENERATED_COLUMNS = new Set([SELECTOR_COLUMN_KEY, ACTIONS_COLUMN_KEY]);

/**
 * Computes the table columns based the network and user preferences stored in localStorage.
 */
export default function useTableColumns({
  network,
  subnetwork,
  fieldSetId,
  isBulkEditable,
  SelectRow,
  selectAllRows,
  columnsLocalStorageKey,
  defaultColumnsBaseFilter,
}) {
  const [showColumnEditor, setShowColumnEditor] = useState(false);

  // Default columns for network.
  const defaultColumnsBase = useMemo(
    () => getFields(fieldSetId, { network, subnetwork }),
    [fieldSetId, network, subnetwork]
  );

  // Hide any columns that shouldn't be shown by default.
  if (defaultColumnsBaseFilter && defaultColumnsBaseFilter.length) {
    for (const col of defaultColumnsBase) {
      if (!defaultColumnsBaseFilter.includes(col.key)) {
        col.hide = true;
      }
    }
  }

  // User-customized columns.
  const [columnsBase, setColumnsBase] = useState(() =>
    mergeColumnsWithStoredSettings(defaultColumnsBase, columnsLocalStorageKey)
  );

  const columns = useMemo(() => {
    const out = [];

    out.push({
      header: (
        <ActionsHeader
          actions={[
            {
              text: 'Modify columns',
              onClick: () => setShowColumnEditor(true),
            },
            {
              text: 'Reset table to default',
              onClick: () => {
                clearColumnConfig(columnsLocalStorageKey);
                setColumnsBase(defaultColumnsBase);
              },
            },
          ]}
        />
      ),
      key: ACTIONS_COLUMN_KEY,
      renderer: () => null,
      noPadding: true,
      noResize: true,
      noRowClick: true,
      noSort: true,
      noEdit: true,
    });

    if (isBulkEditable) {
      out.push({
        header: selectAllRows,
        key: SELECTOR_COLUMN_KEY,
        width: '36px',
        renderer: SelectRow,
        noResize: true,
        noRowClick: true,
        noSort: true,
        noEdit: true,
      });
    }

    // Rest of the columns corresponding to user preference.
    for (const col of columnsBase) {
      // Make a copy of every column since the ColumnEditor changes the column
      // object in place.
      out.push({ ...col });
    }

    return out;
  }, [
    isBulkEditable,
    defaultColumnsBase,
    selectAllRows,
    SelectRow,
    columnsBase,
    columnsLocalStorageKey,
  ]);

  const columnEditor = showColumnEditor ? (
    <ColumnEditor
      columns={columns}
      handleSave={(newColumns) => {
        const newColumnsBase = newColumns.filter((col) => !GENERATED_COLUMNS.has(col.key));
        setColumnsBase(newColumnsBase);
        saveColumnConfig(newColumnsBase, columnsLocalStorageKey);
        setShowColumnEditor(false);
      }}
      hideModal={() => setShowColumnEditor(false)}
    />
  ) : null;

  return { columns, columnEditor };
}
