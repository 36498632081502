import React, { useState, useMemo } from 'react';
import { Modal, Select, Button, Text, VSpacer } from '@mqd/volt-base';
import { useMutation } from '@apollo/react-hooks';

import logger from 'utils/logger';
import routerStore from '../../../../../../stores/router/RouterStore.js';
import FlexContainer from '../../../../components/FlexContainer/FlexContainer.js';
import { REJECT_TRANSACTION_ADJUSTMENT } from '../../../../queries/rejectTransactionHashAdjustment/query.js';
import { createNetRefIdForToast } from '../../../../utilities/string/index.js';
import { createRejectOrApproveFailureMessage } from '../../../../pages/ApprovalSettlementDateQueue/utils.js';

import { REJECTION_OPTIONS } from './constants.js';

const errorMessage = 'A reason is required to reject this exception';
const alertMessage = createRejectOrApproveFailureMessage('rejected');

const RejectHashAdjustmentModal = ({
  adjustmentIdx,
  netRefId,
  setApproveOrRejectResponse,
  toggleShowModal,
  txHashInternalId,
}) => {
  const options = REJECTION_OPTIONS.map(({ label }) => label);

  const [reason, setReason] = useState();
  const [error, setError] = useState(false);

  const [rejectTransactionAdjustment, { loading }] = useMutation(REJECT_TRANSACTION_ADJUSTMENT, {
    onError: (e) => logger.log(e),
  });

  const handleReject = async () => {
    if (reason) {
      setError(false);
      setApproveOrRejectResponse({ error: false });
      const { data, error: apiError } = await rejectTransactionAdjustment({
        variables: {
          tx_hash_internal_id: txHashInternalId,
          adjustment_idx: adjustmentIdx,
          reason,
        },
      });

      if (!data?.rejectTransactionAdjustment?.ok || apiError) {
        toggleShowModal();
        setApproveOrRejectResponse({
          error: true,
          message: data?.rejectTransactionAdjustment?.message || alertMessage,
          heading: 'Rejection failed',
        });
      } else {
        redirectToApprovalQueue();
      }
    } else {
      setError(true);
    }
  };

  const handleSelectReason = (option) => {
    const reason = REJECTION_OPTIONS.find(({ label }) => label === option);
    setReason(reason.id);
    setError(false);
  };

  const redirectToApprovalQueue = () => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete('tx_hash_internal_id');

    const toastMessage = `${createNetRefIdForToast(netRefId)} rejected`;
    toggleShowModal();
    routerStore.go(`/settlement/approvals/${routerStore.pathParams.type}/settlement-date`, {
      params: urlParams,
      state: {
        toastMessage,
      },
    });
  };

  const reasonHumanReadable = useMemo(
    () => (reason ? REJECTION_OPTIONS.find(({ id }) => id === reason).label : ''),
    [reason]
  );

  return (
    <Modal
      heading="Reject hash ID"
      hideModal={toggleShowModal}
      showHideModalButton={false}
      footerButtons={[
        <Button type="tertiary" disabled={loading} onClick={toggleShowModal}>
          Cancel
        </Button>,
        <Button type="primary" loading={loading} onClick={handleReject}>
          Reject
        </Button>,
      ]}
      testId="reject-hash-adjustment-modal"
      width="640px"
      disableOverFlow
    >
      <FlexContainer direction="column">
        <Text>
          Rejecting this hash ID will send it back to the exception queue for further review.
        </Text>
        <VSpacer factor={3} />
        <Select
          error={error && errorMessage}
          label="Reject reason"
          onChange={handleSelectReason}
          options={options}
          placeholder="Select an option"
          sublabel="Please select the reason you are rejecting this hash ID"
          value={reasonHumanReadable}
        />
      </FlexContainer>
    </Modal>
  );
};

export default RejectHashAdjustmentModal;
