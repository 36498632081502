import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { HSpacer, Text, Flex, Link, ChangeIndicator } from '@mqd/volt-base';
import s from './Grouper.module.css';

function Grouper({
  addButtonLabel,
  data,
  hasChangedType,
  formattedGroupListItem,
  leftGroupHeaderLabel,
  onChange,
  options,
  removeButtonLabel,
  rightGroupHeaderLabel,
  showItemsSelectedCount,
  showReset,
  testId,
}) {
  const [initialData] = useState(data);
  const [selected, setSelected] = useState([]);
  const [leftGroup] = useState(Object.keys(options) || []);
  const [rightGroup, setRightGroup] = useState(data || []);

  const not = (a, b) => a.filter((value) => b.indexOf(value) === -1);
  const intersection = (a, b) => a.filter((item) => b.indexOf(item) !== -1);

  const leftGroupSelectedItems = intersection(selected, not(leftGroup, rightGroup));
  const rightGroupSelectedItems = intersection(selected, rightGroup);

  const handleSelect = (value) => {
    const currentIndex = selected.indexOf(value);
    let newSelected = [...selected];

    // prevent multiple group item selection
    if (rightGroup.includes(value)) {
      if (leftGroupSelectedItems.length > 0)
        newSelected = newSelected.filter((item) => !leftGroupSelectedItems.includes(item));
    } else if (rightGroupSelectedItems.length > 0)
      newSelected = newSelected.filter((item) => !rightGroupSelectedItems.includes(item));

    if (currentIndex === -1) {
      newSelected.push(value);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    setSelected(newSelected);
  };

  const handleAdd = () => {
    const itemsToAdd = leftGroupSelectedItems;
    const newRightGroup = [...rightGroup];

    newRightGroup.push(...itemsToAdd);

    setRightGroup(newRightGroup);
    setSelected([]);

    onChange({
      data: newRightGroup,
    });
  };

  const handleRemove = () => {
    const itemsToRemove = rightGroupSelectedItems;

    let newRightGroup = [...rightGroup];

    newRightGroup = newRightGroup.filter((item) => !itemsToRemove.includes(item));

    setRightGroup(newRightGroup);
    setSelected([]);

    onChange({
      data: newRightGroup,
    });
  };

  const handleReset = () => {
    setRightGroup(data);
    setSelected([]);

    setRightGroup(initialData);

    onChange({
      data: initialData,
    });
  };

  const addButtonDisabled = leftGroupSelectedItems.length === 0;
  const removeButtonDisabled = rightGroupSelectedItems.length === 0;

  return (
    <div className={s.grouper} data-testid={testId}>
      <Group
        buttonLabel={addButtonLabel}
        buttonLabelIconDirection={'right'}
        data={leftGroup}
        disabled={addButtonDisabled}
        formattedGroupListItem={formattedGroupListItem}
        hasChangedType={hasChangedType}
        headerLabel={leftGroupHeaderLabel}
        isOptionsList={true}
        onClick={handleAdd}
        onSelect={handleSelect}
        options={options}
        rightGroup={rightGroup}
        selected={selected}
        testId="grouper-group-left"
      />

      <Group
        buttonLabel={removeButtonLabel}
        buttonLabelIconDirection={'left'}
        data={rightGroup}
        disabled={removeButtonDisabled}
        formattedGroupListItem={formattedGroupListItem}
        hasChangedType={hasChangedType}
        headerLabel={rightGroupHeaderLabel}
        onClick={handleRemove}
        onReset={handleReset}
        onSelect={handleSelect}
        options={options}
        rightGroup={rightGroup}
        selected={selected}
        showItemsSelectedCount={showItemsSelectedCount}
        showReset={showReset}
        testId="grouper-group-right"
      />
    </div>
  );
}

const Group = React.memo(
  ({
    buttonLabel,
    buttonLabelIconDirection,
    data,
    disabled,
    formattedGroupListItem,
    hasChangedType,
    headerLabel,
    isOptionsList,
    onClick,
    onReset,
    onSelect,
    options,
    rightGroup,
    selected,
    showItemsSelectedCount,
    showReset,
    testId,
  }) => {
    const isGroupListItemDisabled = (item) => isOptionsList && rightGroup.includes(item);
    const isGroupListItemSelected = (item) => `${selected.includes(item) ? s.selected : ''}`;
    const shouldHighlightGroupListItem = (item) =>
      !isGroupListItemDisabled(item) && isGroupListItemSelected(item);

    return (
      <div className={s.group} data-testid={testId}>
        <Flex flexDirection="column" alignSelf="stretch" className={s.groupHeaderContainer}>
          <Flex
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            className={s.groupHeader}
          >
            <Flex flexDirection="row">
              {!isOptionsList ? (
                <ChangeIndicator hasChangedType={hasChangedType} noPadding>
                  <Text type="h6">{headerLabel}</Text>
                  {showItemsSelectedCount && (
                    <>
                      <HSpacer factor={0.5} />
                      <Text type="body-sm">{`(${rightGroup.length})`}</Text>
                    </>
                  )}
                </ChangeIndicator>
              ) : (
                <Text type="h6">{headerLabel}</Text>
              )}
            </Flex>
            {showReset && onReset && (
              <Link
                type="secondary"
                iconType="revert"
                iconDirection="left"
                className={s.groupResetButton}
                onClick={onReset}
              >
                Reset
              </Link>
            )}
          </Flex>
          <Flex flexDirection="row" alignItems="center" className={s.groupSubheader}>
            <Link
              type="primary"
              iconType={`arrow-${buttonLabelIconDirection}`}
              iconDirection={buttonLabelIconDirection}
              className={disabled ? s.groupSubheaderIconDisabled : ''}
              noHoverEffects
              disabled={disabled}
              onClick={onClick}
            >
              {buttonLabel}
            </Link>
          </Flex>
        </Flex>

        <Flex flexDirection="column" alignItems="flex-start" className={s.groupList}>
          {data.map((datum, index) => {
            return (
              <Link
                key={`${datum}-${index}`}
                type="secondary"
                className={`${s.groupListItem} ${shouldHighlightGroupListItem(datum)}`}
                disabled={isGroupListItemDisabled(datum)}
                onClick={() => onSelect(datum)}
                testId={`${testId}-${datum}`}
              >
                {formattedGroupListItem(datum, options)}
              </Link>
            );
          })}
        </Flex>
      </div>
    );
  }
);

Grouper.propTypes = {
  data: PropTypes.array.isRequired,
  options: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  leftGroupHeaderLabel: PropTypes.string,
  rightGroupHeaderLabel: PropTypes.string,
  addButtonLabel: PropTypes.string,
  removeButtonLabel: PropTypes.string,
  formattedGroupListItem: PropTypes.func,
  showItemsSelectedCount: PropTypes.bool,
  showReset: PropTypes.bool,
};

Grouper.defaultProps = {
  data: [],
  options: {},
  onChange: () => {},
  formattedGroupListItem: () => {},
  kind: 'grouper',
};

export default Grouper;
