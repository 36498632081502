import React from 'react';
import { PhoneInput } from '@mqd/volt-base';

const PhoneWrapper = ({ phone, onChange }) => {
  return (
    <PhoneInput
      onChange={onChange}
      width="100%"
      name="phone"
      label="Phone"
      id="bulk-issuance-phone-input"
      value={phone}
      countryCodeTestId="bulk-issuance-country-code-select"
    />
  );
};

export default PhoneWrapper;
