import React from 'react';
import PropTypes from 'prop-types';
import { Text, VSpacer, Input, Select, Checkbox } from '@mqd/volt-base';

const CardPersonalization = ({
  onChange,
  data,
  shippingMethodOptions,
  validators,
  cardProductIsPhysical,
}) => {
  const { name_line_1, name_line_2, cardholderSignature, method, expedite } = data || {};
  const { nameLine1Validator, cardholderSignatureValidator } = validators || {};

  return (
    <div style={{ width: '540px' }}>
      <Text type="h5">Card personalization</Text>
      <VSpacer factor={2} />
      <Input
        name={'name_line_1'}
        label={'Name line 1*'}
        maxLength={28}
        onChange={(e) => onChange('name_line_1', e.target.value)}
        placeholder="This field is required"
        value={name_line_1}
        testId={'name_line_1'}
        error={nameLine1Validator}
      />
      <VSpacer factor={2} />
      <Input
        name={'name_line_2'}
        label={'Name line 2'}
        placeholder="optional"
        maxLength={28}
        onChange={(e) => onChange('name_line_2', e.target.value)}
        value={name_line_2}
        testId={'name_line_2'}
      />
      <VSpacer factor={2} />
      <Input
        name={'cardholderSignature'}
        label={'Cardholder signature'}
        maxLength={28}
        onChange={(e) => onChange('cardholderSignature', e.target.value)}
        value={cardholderSignature}
        testId={'cardholder-signature'}
        labelTooltip={'Specifies a PNG image of the cardholder’s signature.'}
        error={cardholderSignatureValidator}
      />
      {cardProductIsPhysical && (
        <>
          <VSpacer factor={2} />
          <Checkbox
            label="active"
            headerLabel="Expedite"
            headerLabelTooltip={
              'Expedited processing time will result in expediated manufacturing at the provider site. Shipping will not be expedited.'
            }
            active={expedite}
            onClick={() => onChange('expedite', !expedite)}
          />
          <VSpacer factor={2} />
          <Select
            label="Shipping method"
            onChange={(selectedOption) => {
              const { val } = selectedOption;
              onChange('method', val);
            }}
            options={shippingMethodOptions}
            value={method}
          />
        </>
      )}
    </div>
  );
};

CardPersonalization.propTypes = {
  onChange: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  shippingMethodOptions: PropTypes.array.isRequired,
  validators: PropTypes.object.isRequired,
  cardProductIsPhysical: PropTypes.bool.isRequired,
};

export default CardPersonalization;
