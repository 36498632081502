import React from 'react';
import { DetailPane, Text, Colors } from '@mqd/volt-base';
import PropTypes from 'prop-types';
import { prepareJsonAsText } from '../../views/program/TransactionDetails/utils';

export default function DetailPaneContentWithNoneValue({ label, jsonAsPlainText, children }) {
  let content = (
    <Text color={Colors.blackLighter5} type="body-sm">
      None
    </Text>
  );

  if (children) {
    content = <Text>{children}</Text>;
  } else if (jsonAsPlainText) {
    content = <Text type="pre">{prepareJsonAsText(jsonAsPlainText)}</Text>;
  }

  return <DetailPane.Content label={label}>{content}</DetailPane.Content>;
}

DetailPaneContentWithNoneValue.defaultProps = {
  kind: 'content',
  jsonAsPlainText: false,
};

DetailPaneContentWithNoneValue.propTypes = {
  label: PropTypes.string,
  jsonAsPlainText: PropTypes.object,
};
