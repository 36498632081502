import { action, decorate, observable } from 'mobx';
import moment from 'moment';
import { BaseHistoryStore } from './base-history-store.js';
import { flipFlop } from '@mq/volt-amc-container';

class TransactionHistoryStore extends BaseHistoryStore {
  filterProgram = undefined;
  filterType = undefined;
  filterAmount = undefined;
  filterCollateralAmount = undefined;
  filterToken = undefined;
  filterStatus = undefined;
  filterCurrencyCode = undefined;
  filterDateRange = [
    moment().subtract(this.RECORDS_TTL_DAYS, 'days').startOf('day').toDate(),
    moment().endOf('day').toDate(),
  ];
  exportNotAvailable = true;
  initialFetchWasMade = false;

  get RECORDS_TTL_DAYS() {
    return 7;
  }

  get DEFAULT_SORT_BY() {
    return 'created_at-';
  }

  get commercialCreditActive() {
    return flipFlop.get('commercial-credit', false);
  }

  setFilterProgram(value) {
    this.filterProgram = value;
  }

  setFilterType(value) {
    this.filterType = value;
  }

  setFilterAmount(value) {
    this.filterAmount = value;
  }

  setFilterCollateralAmount(value) {
    this.filterCollateralAmount = value;
  }

  setFilterToken(value) {
    this.filterToken = value;
  }

  setFilterStatus(value) {
    this.filterStatus = value;
  }

  setFilterCurrencyCode(value) {
    this.filterCurrencyCode = value;
  }

  setFilterDateRange(value) {
    this.filterDateRange = value;
  }

  setExportNotAvailable(value) {
    this.exportNotAvailable = value;
  }

  getInitialFetchWasMade() {
    return this.initialFetchWasMade;
  }

  setInitialFetchWasMade(value) {
    this.initialFetchWasMade = value;
  }
}

decorate(TransactionHistoryStore, {
  filterProgram: observable,
  filterType: observable,
  filterAmount: observable,
  filterCollateralAmount: observable,
  filterToken: observable,
  filterStatus: observable,
  filterCurrencyCode: observable,
  filterDateRange: observable,
  exportNotAvailable: observable,

  setFilterProgram: action.bound,
  setFilterType: action.bound,
  setFilterAmount: action.bound,
  setFilterCollateralAmount: action.bound,
  setFilterToken: action.bound,
  setFilterStatus: action.bound,
  setFilterCurrencyCode: action.bound,
  setFilterDateRange: action.bound,
  setExportNotAvailable: action.bound,
  getInitialFetchWasMade: action.bound,
  setInitialFetchWasMade: action.bound,
});

export { TransactionHistoryStore };
