import gql from 'graphql-tag';

export const GET_SETTLED_DATE_QUEUE = gql`
  query getSettledDateQueue(
    $adjustment_action: ExceptionTypeEnum!
    $dna_bank_tokens: [ID]
    $end_submitted_date: String
    $page_size: Int!
    $page_token: String
    $program_short_codes: [String]
    $program_types: [String]
    $settlement_date: String!
    $start_submitted_date: String
    $subnetwork: Subnetwork!
    $originator: String
  ) {
    settledDateQueue(
      adjustment_action: $adjustment_action
      dna_bank_tokens: $dna_bank_tokens
      end_submitted_date: $end_submitted_date
      page_size: $page_size
      page_token: $page_token
      program_short_codes: $program_short_codes
      program_types: $program_types
      settlement_date: $settlement_date
      start_submitted_date: $start_submitted_date
      subnetwork: $subnetwork
      originator: $originator
    ) {
      prev_page_token
      next_page_token
      exceptions {
        adjustment {
          idx
          created_date
          amounts {
            accounting_group_category
            amount {
              scale
              units
            }
          }
        }
        bank {
          name
          token
        }
        hash_id
        latest_jcard_error_response
        masked_pan
        network_reference_id
        program_short_code
        program_type
        message_reason_code
        settled_amounts {
          accounting_group_category
          amount {
            scale
            units
          }
        }
        settlement_date
        source
        tx_hash_internal_id
        variance_amount {
          scale
          units
        }
        reason
        state
      }
    }
    programTypes
    dnaBanks(count: 500) {
      data {
        name
        token
      }
    }
  }
`;
