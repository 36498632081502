import { useMemo } from 'react';
import { pickAll, equals } from 'ramda';
import { useTemplateProperties } from '../../../hooks/use-template-properties';

export function useIsCardEdited(properties, datum, cardCompareState) {
  const templateProperties = useTemplateProperties(properties);

  return useMemo(() => {
    const pickNeededPropertiesSafe = (object) => pickAll(templateProperties, object ?? {});

    const datumOnlyNeededProps = pickNeededPropertiesSafe(datum);
    const cardCompareStateOnlyNeededProps = pickNeededPropertiesSafe(cardCompareState);

    return equals(datumOnlyNeededProps, cardCompareStateOnlyNeededProps) === false;
  }, [templateProperties, datum, cardCompareState]);
}
