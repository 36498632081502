import { path, isNil } from 'ramda';

/**
 * Returns either the value in an object or array, based on its path, or, if the value is null or undefined,
 * returns prescribed default value.
 *
 * @param {any} defaultValue - value to default too
 * @param {array} pathToValue  - path to the value within an object or array
 * @param {object|array} objectOrArray - object or array containing the paths and value
 * @returns {any} - value or default value
 */
export default function nullPathOr(defaultValue, pathToValue, objectOrArray) {
  const value = path(pathToValue, objectOrArray);

  if (isNil(value)) {
    return defaultValue;
  }

  return value;
}
