import React from 'react';
import { Colors, StatusIndicator } from '@mqd/volt-base';
import { EXPORT_STATUSES } from '../../utilities/dictionary/index.js';

const colorMap = {
  COMPLETED: Colors.statusSuccessColor,
  IN_PROGRESS: Colors.statusWarningColor,
  ERROR: Colors.statusErrorColor,
};

export default function ExportStatusIndicator({ status }) {
  return <StatusIndicator status={EXPORT_STATUSES[status]} colorOverride={colorMap[status]} />;
}
