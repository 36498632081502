import { readableCodes } from './reasonCodes.js';
import { readableCardCodes } from './cardReasonCodes.js';

export const ACCOUNTHOLDER_TRANSITION_QUERY_PARAM = 'accountholder';
export const ACCOUNT_TRANSITION_QUERY_PARAM = 'account';
export const TRANSITION_INPUT_WIDTH = 540;

export const ACTIVATE = 'activate';
export const SUSPEND = 'suspend';
export const TERMINATE = 'terminate';

export const ACTIVE = 'ACTIVE';
export const SUSPENDED = 'SUSPENDED';
export const TERMINATED = 'TERMINATED';
export const CLOSED = 'CLOSED';
export const UNACTIVATED = 'UNACTIVATED';

export { readableCodes, readableCardCodes };
