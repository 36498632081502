import React, { useLayoutEffect, useState } from 'react';

import { Popover } from '@mqd/volt-base';

import CreditCapabilities from '../../CreditCapabilities';

import { CREDIT_TYPES } from '../../constants';
import { WELCOME_STEPS } from '../../pages/welcome/constants';

const CreditPopover = ({ closePopover, noData = false }) => {
  const viewType = CreditCapabilities.getViewType(CREDIT_TYPES.BUNDLE);
  const [step, setStep] = useState(1);

  const [selector, setSelector] = useState('');

  const steps = WELCOME_STEPS[viewType]?.steps;
  const stepsCount = Object.keys(steps).length;

  useLayoutEffect(() => {
    if (noData && Array.isArray(steps[step].selector)) {
      setSelector(steps[step].selector[1]);
    } else {
      setSelector(steps[step].selector || steps[step].selector[0]);
    }
  }, [steps, step, noData]);

  const onSubmit = () => (step === stepsCount ? closePopover() : setStep(step + 1));

  const { heading, message } = steps[step];

  const getDirecion = () => {
    if (noData && Array.isArray(steps[step].direction)) {
      return steps[step].direction[1];
    }

    if (!noData && !Array.isArray(steps[step].direction)) {
      return steps[step].direction;
    }

    if (!noData && Array.isArray(steps[step].direction)) {
      return steps[step].direction[0];
    }
  };

  if (!selector) return null;

  return (
    <Popover
      type="education"
      heading={heading}
      message={message}
      step={step}
      stepCount={stepsCount}
      onClose={closePopover}
      onSubmit={onSubmit}
      content=""
      direction={getDirecion()}
      textWrap={true}
      defaultActive={true}
      selector={selector}
      testId="welcome-popover"
    />
  );
};

export default CreditPopover;
