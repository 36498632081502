import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import TransitionsTable from '../transitions-table/TransitionsTable';

class TransitionTab extends Component {
  render() {
    const { storeInitArgs, onStoreConstruction, localStorageKey, loading, calculateTableHeight } =
      this.props;
    return (
      <TransitionsTable
        loading={loading}
        storeInitArgs={storeInitArgs}
        onStoreConstruction={onStoreConstruction}
        localStorageKey={localStorageKey}
        calculateTableHeight={calculateTableHeight}
      />
    );
  }
}

TransitionTab.propTypes = {
  storeInitArgs: PropTypes.object,
  onStoreConstruction: PropTypes.func,
  localStorageKey: PropTypes.string,
  calculateTableHeight: PropTypes.func,
};

TransitionTab.defaultProps = {
  storeInitArgs: {},
  onStoreConstruction: () => {},
  localStorageKey: '',
  calculateTableHeight: () => 0,
};

export default observer(TransitionTab);
