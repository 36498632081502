import React from 'react';
import { Pill } from '@mqd/volt-base';
import { capitalize } from '../../../../views/program/Accountholders/utils';
import { formatDate } from '../../../../views/credit/utils';
import { capitalizeFirstLetter } from '../shared-utils';

export const PAGE_SIZE = 25;

const STATUS_MAP = {
  PENDING: "warning",
  COMPLETED: "success",
  CANCELLED: "light",
  RETURNED: "pending",
  FAILED: "error",
};

export const PAYMENTS_TABLE_COLUMNS = [
  {
    header: 'Actions',
    accessor: 'actions',
    key: 'actions',
    hide: true,
  },
  {
    header: 'Biller',
    accessor: 'biller',
    key: 'biller',
    noSort: true,
    noEdit: true,
    width: 255,
    renderer: ({ cellValue }) => cellValue?.name,
  },
  {
    header: 'Type',
    accessor: 'payment_type',
    key: 'payment_type',
    noSort: true,
    width: 148,
    renderer: ({ cellValue }) => capitalizeFirstLetter(cellValue.replace('_', ' ')),
  },
  {
    header: 'Amount',
    accessor: 'amount',
    key: 'amount',
    noSort: true,
    width: 130,
    renderer: ({ cellValue }) => cellValue.toFixed(2),
  },
  {
    header: 'Status',
    accessor: 'status',
    key: 'status',
    noSort: true,
    filterType: 'starts-with',
    width: 155,
    renderer: ({ cellValue }) => <Pill type={STATUS_MAP[cellValue]} size="body-sm">{ capitalize(cellValue) }</Pill> ,
  },
  {
    header: 'Date initiated',
    accessor: 'created_time',
    key: 'created_time',
    noSort: true,
    width: 158,
    renderer: ({ cellValue }) =>  formatDate(cellValue, 'YYYY-MM-DD'),
  },
  {
    header: 'Last updated',
    accessor: 'last_modified_time',
    key: 'last_modified_time',
    noSort: true,
    width: 220,
  },
];

export const ERROR_MESSAGE_PAYMENTS_FETCH = 'Unable to load payments.';

