// @flow
import { decorate, observable } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';

class PrimaryContactStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
  }
  // values
  full_name: string = '';
  title: string = '';
  department: string = '';
  phone: string = '';
  extension: string = '';
  fax: string = '';
  mobile: string = '';
  email: string = '';
}

decorate(PrimaryContactStore, {
  // values
  full_name: observable,
  title: observable,
  department: observable,
  phone: observable,
  extension: observable,
  fax: observable,
  mobile: observable,
  email: observable,
});

export default PrimaryContactStore;
