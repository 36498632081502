import React from 'react';
import moment from 'moment';
import { Breadcrumbs } from '@mqd/volt-base';

import routerStore from '../../../../stores/router/RouterStore.js';
import { DATE_FORMAT_STRING } from 'views/fds/utilities/date.js';
import { EXCEPTION_TYPES, NETWORKS, SUBNETWORKS } from '../../utilities/dictionary/index.js';
import { dashCaseToConstant } from '../../utilities/string/index.js';
import { capitalize } from '../../utilities/string/index.js';
import { appendUrlParamsToUrl } from '../../utilities/url-params/index.js';

const approvalSummaryParams = [
  'start_settlement_date',
  'end_settlement_date',
  'dna_bank_tokens',
  'program_types',
  'program_short_codes',
];

const exceptionTypeQueueParams = [...approvalSummaryParams, 'originator'];

const settledDateQueueParams = [
  ...exceptionTypeQueueParams,
  'start_submitted_date',
  'end_submitted_date',
  'program_short_codes',
  'program_types',
];

const ApprovalFlowBreadcrumbs = ({ hashId }) => {
  const urlParams = new URLSearchParams(window.location.search);

  const typeParam = window.history.state?.type || dashCaseToConstant(routerStore.pathParams.type);
  const type = typeParam && EXCEPTION_TYPES[typeParam];

  const subnetworkParam = urlParams.get('subnetwork');
  const subnetwork = (subnetworkParam && SUBNETWORKS[subnetworkParam]) || 'Unknown';

  const networkParam = urlParams.get('network');
  const network = (networkParam && NETWORKS[networkParam]) || 'Unrecognized';

  const dateParam = urlParams.get('date');
  const date = dateParam && moment(dateParam).format(DATE_FORMAT_STRING);

  const txHashInternalId = urlParams.get('tx_hash_internal_id');
  const originator = urlParams.get('originator');

  const filterParams = {
    type,
    subnetwork,
    network,
    date,
    txHashInternalId,
    originator,
  };

  const crumbs = [
    {
      children: 'Approvals',
      href: appendUrlParamsToUrl(
        `/settlement/approvals?network=${networkParam}`,
        approvalSummaryParams
      ),
      filter: ['network'],
    },
    {
      children: subnetwork + ' submission total' + (type ? ': ' + capitalize(type) : ''),
      href: appendUrlParamsToUrl(
        `/settlement/approvals/${routerStore.pathParams.type}?network=${networkParam}&subnetwork=${subnetworkParam}`,
        exceptionTypeQueueParams
      ),
      filter: ['subnetwork', 'type'],
    },
    {
      children: 'Settlement date: ' + moment(date).format('MM-DD-YYYY'),
      href: appendUrlParamsToUrl(
        `/settlement/approvals/${routerStore.pathParams.type}/settlement-date?network=${networkParam}&subnetwork=${subnetworkParam}&date=${date}`,
        settledDateQueueParams
      ),
      filter: ['date', 'subnetwork', 'type'],
    },
    {
      children: hashId ? `Hash ID ••${hashId.substr(hashId.length - 4, hashId.length - 1)}` : '',
      href: `/settlement/approvals/${routerStore.pathParams.type}/settlement-date/details?network=${networkParam}subnetwork=${subnetworkParam}&date=${date}&tx_hash_internal_id=${txHashInternalId}`,
      filter: ['date', 'subnetwork', 'type', 'txHashInternalId'],
    },
  ]
    .filter(({ filter }) => filter.every((filterParam) => filterParams[filterParam] !== null))
    .map(({ children, href }) => ({ children, href }));

  return (
    <div>
      <Breadcrumbs crumbs={crumbs} />
    </div>
  );
};

export default ApprovalFlowBreadcrumbs;
