// @flow
import { decorate, observable, action, runInAction } from 'mobx';
import gqlUtils from '@mqd/graphql-utils';
import AuditLogStore from '../stores/AuditLogStore';
import { ParentTableStore } from '@mq/voltron-parent';

const { fragments } = gqlUtils;

export default class AuditLogsTableStore extends ParentTableStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
  }
  // values
  items: Array<AuditLogStore> = [];
  count: number = 50;
  app_name: string = '';
  end_date: string = '';
  program: string = '';
  record_id: string = '';
  record_operation: string = '';
  record_type: string = '';
  start_date: string = '';
  user_name: string = '';

  // actions
  handleSearch() {
    const { hydrate, setAttr } = this;
    setAttr && setAttr('start_index', 0);
    setAttr('count', 50);
    hydrate && hydrate();
  }

  async hydrate() {
    if (!this.enableNoParamQuery && !this.validParams) return;
    this.loading = true;
    try {
      Object.keys(this.queryParams).forEach((param) => (this[param] = this.queryParams[param].val));
      const paramsInfo = {
        app_name: {
          type: 'String',
          val: this.app_name,
        },
        end_date: {
          type: 'String',
          val: this.end_date,
        },
        program: {
          type: 'String',
          val: this.program,
        },
        record_id: {
          type: 'String',
          val: this.record_id,
        },
        record_operation: {
          type: 'String',
          val: this.record_operation,
        },
        record_type: {
          type: 'String',
          val: this.record_type,
        },
        start_date: {
          type: 'String',
          val: this.start_date,
        },
        user_name: {
          type: 'String',
          val: this.user_name,
        },
      };

      const defaultOuterParams = `(${this.configureOuterQueryParams(
        paramsInfo
      )} $count: Int $start_index: Int)`;
      const defaultInnerParams = `(${this.configureInnerQueryParams(
        paramsInfo
      )} count: $count start_index: $start_index)`;
      const queryParams = this.configureQueryParams(paramsInfo);
      const queryAndPaginationParams = Object.assign({}, this.paginationParams, queryParams);

      const result = await this.gqlQuery(
        `
          query auditLogs${defaultOuterParams} {
            auditLogs${defaultInnerParams} {
              data {
                ...auditLogBaseInfo
              }
              ${this.paginationAttributes}
            }
          }
          ${fragments.auditLogBaseInfo}
        `,
        queryAndPaginationParams
      );

      runInAction(() => {
        const auditLogs = this.extract(result, 'auditLogs');
        this.processPaginationResponse(auditLogs, AuditLogStore);
      });
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }
}

decorate(AuditLogsTableStore, {
  // values
  items: observable,
  app_name: observable,
  end_date: observable,
  program: observable,
  record_id: observable,
  record_operation: observable,
  record_type: observable,
  start_date: observable,
  user_name: observable,

  // actions
  handleSearch: action.bound,
  hydrate: action.bound,
  loadParams: action.bound,

  // computed
});
