import React from 'react';
import PropTypes from 'prop-types';
import { ToastAlert } from '@mqd/volt-base';

const TestEventToast = ({ dismissToast, isSuccessful }) => {
  return (
    <ToastAlert
      icon={isSuccessful ? 'success' : 'error'}
      dismissTime={2}
      remove={dismissToast}
      testId="test-event"
    >
      {isSuccessful ? 'Test event received' : 'Test event not received'}
    </ToastAlert>
  );
};

TestEventToast.propTypes = {
  dismissToast: PropTypes.func,
  isSuccessful: PropTypes.bool,
};

TestEventToast.defaultProps = {
  isSuccessful: true,
  dismissToast: null,
};

export default TestEventToast;
