import React from 'react';
import PropTypes from 'prop-types';

import { Accordion } from '@mqd/volt-base';
import { statusOptions } from '../../../../constants';
import { Title } from './components/Title';
import { Label } from './components/Label';

export function AccordionWrapper({
  datum,
  edited,
  status,
  onToggleExpansion,
  expand,
  defaultExpanded,
  children,
}) {
  const label = (
    <Label
      status={status}
      flags={{
        deleted: datum.deleted,
        new: datum.new,
        edited,
      }}
    />
  );

  const title = (
    <Title
      name={datum.name}
      inactive={datum.enabled === false || datum.active === false}
      collapsed={!expand}
      label={label}
    />
  );

  const section = {
    key: datum.id,
    expand,
    defaultExpanded,
    onToggleExpansion,
    content: children,
    title,
  };

  return <Accordion sections={[section]} contentInset="0px" isColumn />;
}

AccordionWrapper.propTypes = {
  datum: PropTypes.object.isRequired,
  edited: PropTypes.bool.isRequired,
  status: PropTypes.oneOf(statusOptions).isRequired,
  onToggleExpansion: PropTypes.func.isRequired,
  expand: PropTypes.bool,
  defaultExpanded: PropTypes.bool,
  children: PropTypes.node,
};

AccordionWrapper.defaultProps = {
  expand: false,
  defaultExpanded: false,
  children: null,
};
