import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Colors, Text, Select, StatusIndicator } from '@mqd/volt-base';
import s from './ReviewDetails.module.css';
import { toJS } from 'mobx';
import { canEdit, sentenceCase } from './helpers';
import { kycStatuses } from '../shared-components/kyc-status/constants';
import moment from 'moment';

class ReviewActionRow extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  renderString(value) {
    return <Text type="body-sm">{value ? sentenceCase(value) : <span>Unassigned</span>}</Text>;
  }

  renderDate(value) {
    return (
      <Text type="body-sm">
        {value ? moment(value).format('YYYY-MM-DD, HH:mm') : <span>--</span>}
      </Text>
    );
  }

  getFilterOptions = () => {
    const {
      internalUser,
      reviewStore: { agentsList },
    } = this.props;
    const agentsFiltered = internalUser
      ? agentsList.filter((agent) => agent.email.includes('marqeta'))
      : agentsList.filter((agent) => !agent.email.includes('marqeta'));

    return agentsFiltered.slice(0, 50).map((item) => item.email);
  };

  renderOptions(statuses = [], val) {
    const { changeReviewStatus } = this.props.reviewStore;
    return (
      statuses &&
      statuses
        .filter((s) => s !== val)
        .map((status) => (
          <StatusIndicator.Action onClick={() => changeReviewStatus(status)}>
            {sentenceCase(status)}
          </StatusIndicator.Action>
        ))
    );
  }

  render() {
    const {
      status,
      agent,
      agentsList,
      marqetaCaseAgent,
      lastUpdated,
      dateCreated,
      team,
      updateAgentForReview,
      statuses,
    } = this.props.reviewStore || {};
    const { internalUser, canEditReview } = this.props;
    const agentListEmails = toJS(agentsList).map((item) => item.email);
    const canEditStatus = canEdit(internalUser, team) && canEditReview;
    const canEditCustomerAgent =
      !internalUser && (team === 'CUSTOMER' || team === '') && canEditReview;
    const canEditMarqetaAgent =
      internalUser && (team === 'MARQETA' || team === '') && canEditReview;
    const fillColor =
      Colors[kycStatuses[status] && kycStatuses[status].color] || 'statusPendingColor';

    return (
      <div className={s.header}>
        {canEditStatus ? (
          <div data-testid="review-status-field-edit">
            <Text type="h6" className={s.actionRowHeader}>
              Review status
            </Text>
            <StatusIndicator
              testId="status-indicator-edit"
              status={status}
              colorOverride={fillColor}
            >
              {this.renderOptions(statuses, status)}
            </StatusIndicator>
          </div>
        ) : (
          <div data-testid="review-status-field-view" className={s.actionRowHeader}>
            <Text type="h6">Review status</Text>
            {sentenceCase(status)}
          </div>
        )}

        {canEditCustomerAgent ? (
          <div data-testid="review-customer-agent-field-edit">
            <Text type="h6" className={s.actionRowHeader}>
              Assignee
            </Text>
            <Select
              testId="asignee-select"
              fixOverflow={false}
              onChange={(val) => updateAgentForReview('agent', val)}
              options={this.getFilterOptions()}
              showSearch={true}
              value={agent}
              searchableOptions={agentListEmails}
            />
          </div>
        ) : (
          <div data-testid="review-customer-agent-field-view">
            <Text type="h6" className={s.actionRowHeader}>
              {internalUser ? <>Customer assignee</> : <>Assignee</>}
            </Text>
            {this.renderString(agent)}
          </div>
        )}

        {canEditMarqetaAgent ? (
          <div data-testid="review-agent-field-edit">
            <Text type="h6" className={s.actionRowHeader}>
              Assignee
            </Text>
            <Select
              testId="asignee-select"
              fixOverflow={false}
              onChange={(val) => updateAgentForReview('marqetaCaseAgent', val)}
              options={this.getFilterOptions()}
              showSearch={true}
              value={marqetaCaseAgent}
              searchableOptions={agentListEmails}
            />
          </div>
        ) : (
          <div data-testid="review-agent-field-view">
            <Text type="h6" className={s.actionRowHeader}>
              Marqeta Assignee
            </Text>
            {this.renderString(marqetaCaseAgent)}
          </div>
        )}

        <div data-testid="last-updated-field">
          <Text type="h6" className={s.actionRowHeader}>
            Last updated
          </Text>
          {this.renderDate(lastUpdated)}
        </div>

        <div data-testid="date-created-field">
          <Text type="h6" className={s.actionRowHeader}>
            Date created
          </Text>
          {this.renderDate(dateCreated)}
        </div>

        <div data-testid="reviewing-party-field">
          <Text type="h6" className={s.actionRowHeader}>
            Reviewing party
          </Text>
          {this.renderString(team)}
        </div>
      </div>
    );
  }
}

ReviewActionRow.propTypes = {
  canEditReview: PropTypes.bool,
  internalUser: PropTypes.bool,
  reviewStore: PropTypes.any,
};

ReviewActionRow.defaultProps = {
  canEditReview: false,
  internalUser: false,
  reviewStore: {},
};

export default observer(ReviewActionRow);
