import { action, decorate, observable } from 'mobx';

export class LeavingModalStore {
  showLeavingModal: boolean = false;
  leavingModalTexts: Object = {};
  routeParamsToLeave: Object = null;

  setShowLeavingModal(showModal) {
    this.showLeavingModal = !!showModal;
  }

  setRouteParamsToLeave(params) {
    this.routeParamsToLeave = params;
  }

  setLeavingModalTexts(texts) {
    this.leavingModalTexts = texts;
  }
}

decorate(LeavingModalStore, {
  showLeavingModal: observable,
  leavingModalTexts: observable,
  routeParamsToLeave: observable,

  setShowLeavingModal: action.bound,
  setRouteParamsToLeave: action.bound,
  setLeavingModalTexts: action.bound,
});

const leavingModalStore = new LeavingModalStore();

export default leavingModalStore;
