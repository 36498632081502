import { secureStorage } from '@mq/volt-amc-container';
import logger from 'utils/logger';
import { FAVORITE_PROGRAMS_KEY } from './constants.js';

export const programsWithRolesList = (programsList, redseaPrograms) =>
  programsList.filter((program) => redseaPrograms.includes(program.short_name));

export const getProgramObjectFromName = (programsList, name) => {
  for (let i = 0; i < programsList.length; i++) {
    const programObject = programsList[i];
    if (programObject.program === name) return programObject;
  }
  return {};
};

export const stripFavoriteKeyFromProgram = ({ program, short_name }) => ({ program, short_name });

export function loadFavoriteProgramsFromLocalStorage() {
  let favoritePrograms = [];
  try {
    const favoriteProgramsFromLocalStorage = JSON.parse(
      secureStorage.getItem(FAVORITE_PROGRAMS_KEY)
    );
    if (favoriteProgramsFromLocalStorage) {
      favoritePrograms = favoriteProgramsFromLocalStorage;
    }
  } catch (e) {
    logger.error('No favorite programs set in localStorage');
  }
  return favoritePrograms;
}

export const saveActiveProgramInfo = (shortCode, key, value) => {
  try {
    secureStorage.setItem(key, JSON.stringify({ [shortCode]: value }));
  } catch (e) {
    console.error(e);
    return e;
  }
};
