const RedseaAddOnDepartments = [
  'Compliance - Program Managed',
  'Compliance - Processor Only',
  'Compliance (Internal)',
  'Risk (Internal)',
  'Delivery (Internal)',
  'Fulfillment (Internal)',
  'Settlement (Internal)',
  'Production Support (Internal)',
  'Token Manager',
];

const RedseaAddOnSupplements = [
  'ACH Origination',
  'Approver',
  'Approver (Internal)',
  'Cancel ACH transfer',
  'Compliance (Internal)',
  'Create ACH transfer',
  'Create Card Manually',
  'Credit Brand Contributor',
  'Credit Bank Program Manager Approver',
  'Credit Bank Program Manager Creator',
  'Credit Offer Creator',
  'Credit Offer Approver',
  'Credit Product Creator',
  'Credit Product Approver',
  'Credit Product Viewer',
  'Credit Support Agent',
  'Inventory Admin',
  'Inventory Management',
  'Invoke Commando Mode (Internal)',
  'Invoke Commando Mode',
  'Granular permissions',
  'HSM key manager',
  'Private sandbox',
  'Remove funds',
  'Replace Card',
  'Report Card Stolen',
  'Risk (External)',
  'Risk (Internal)',
  'Show PAN',
  'Suspend Card',
  'Token Manager (Edit)',
  'Transaction Review Manager',
  'View ACH transfers',
  'Review BIN Data (Internal)',
  'Manage BIN Data (Internal)',
  'View BIN Data (Internal)',
  'Issuer Write Off Access',
  'Disputes Manual Transitions',
];

export { RedseaAddOnDepartments, RedseaAddOnSupplements };
