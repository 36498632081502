import React from 'react';
import { observer } from 'mobx-react';
import backgroundJobStore from 'stores/BackgroundJobStore';
import { Card, HSpacer, Icon, Text } from '@mqd/volt-base';

const ActiveDownloads = () => {
  if (backgroundJobStore.activeDownloads && backgroundJobStore.activeDownloads.length) {
    const downloads = backgroundJobStore.activeDownloads.map((download, i) => {
      return (
        <div className="active-download" key={i}>
          <Icon type="loading" mod="active" />
          <HSpacer factor={2} />
          <Text>{download.name}</Text>
        </div>
      );
    });

    return (
      <div className="active-downloads-wrap" data-testid="active-downloads-banner">
        <Card title="Active Downloads">{downloads}</Card>
      </div>
    );
  }
  return null;
};

export default observer(ActiveDownloads);
