/**
 * @NOTE:
 *
 * This is duplicated from @mq/volt-amc-container's associatedPaths.js
 * file. When cfn-beta feature flag is removed and CFN is the default,
 * the old associatedPaths.js file in @mq/volt-amc-container should be
 * removed.
 */
export const binAssociatedPaths = [
  '/bin-management/bin',
  '/bin-management/range',
  '/bin-management/program',
];

export const binDataManagementAssociatedPaths = [
  '/bin-data-management/range/create',
  '/bin-data-management/range/edit',
  '/bin-data-management/bin/create',
  '/bin-data-management/bin/edit',
  '/bin-data-management/bin',
  '/bin-data-management/range',
];

export const programCardProductAssociatedPaths = [
  '/program/card-product',
  '/program/cards/new',
  '/program/card-products/bulk',
];

export const bulkCardProductAssociatedPaths = [
  '/program/bulk-issuances',
  '/program/bulk-issuance/new',
  '/program/bulk-issuance',
];

export const cardOrderAndShipmentAssociatedPaths = ['/program/card-order-and-shipment'];

export const providersAssociatedPaths = ['/program/providers'];

export const cardManagementInventoryAssociatedPaths = ['/program/card-management-inventories'];

export const hsmKeysAssociatedPaths = ['/program/hsm-keys', '/program/hsm-keys/assign'];

export const cardholderAssociatedPaths = ['/program/user'];

export const businessAssociatedPaths = ['/program/business'];

export const programControlRoutes = [
  '/bin-management',
  '/admin',
  '/admin/roles',
  '/admin/departments',
  '/program/ach-management',
  '/program/pin-reveal',
  '/program/3d-secure',
];

export const customersCardholdersAssociatedPaths = [
  '/program/user',
  '/program/card',
  '/program/digital-wallet-token',
  '/program/account',
];

export const riskDisputesAssociatedPaths = ['/risk/dispute', '/risk/dispute/new'];

export const customersAccountholdersAssociatedPaths = [
  '/program/accountholder',
  '/program/accountholder/account',
  '/program/accountholder/card',
  '/program/accountholder/suspend',
  '/program/accountholder/terminate',
  '/program/accountholder/transaction',
  '/program/accountholder/activity',
  '/program/accountholder/account/activity',
  '/program/accountholder/card/activity',
];

export const settlementsApprovalsAssociatedPaths = ['/settlement/approvals'];

export const settlementsExceptionsAssociatedPaths = ['/settlement/exceptions'];

export const settlementsProgramReservePaths = [
  '/settlement/program-funding',
  '/settlement/program-funding/manual-entry',
  '/settlement/program-funding/bulk-entry',
];

export const commandoModeAssociatedPaths = ['/program/commando-mode'];

// Credit products
export const creditProductsAssociatedPaths = [
  '/credit/credit-products',
  '/credit/credit-product',
  '/credit/credit-products/create',
  '/credit/credit-products/rejected',
  '/credit/credit-products/history',
  '/credit/credit-products/activity',
];

// Policy library
export const policyLibraryAssociatedPaths = [
  '/credit',
  '/credit/policy-library',
  '/credit/policy-library/create',
  '/credit/policy-library/credit-products',
  '/credit/policy-library/credit-product',
  '/credit/policy-library/aprs',
  '/credit/policy-library/apr',
  '/credit/policy-library/fees',
  '/credit/policy-library/fee',
  '/credit/policy-library/rewards',
  '/credit/policy-library/offers',
];

// Bundles
export const bundlesAssociatedPaths = ['/credit/bundles', '/credit/bundles/create'];

// Work queue
export const workQueueAssociatedPaths = ['/credit/work-queue'];

// Credit approval queue
export const creditApprovalQueueAssociatedPaths = ['/credit/approval-queue'];

// Credit offers
export const creditOffersAssociatedPaths = [
  '/credit/acquisition-offer',
  '/credit/acquisition-offers',
  '/credit/acquisition-offers/create',
  '/credit/acquisition-offers/history',
];

// Credit accounts
export const creditAccountsAssociatedPaths = [
  '/credit/account',
  '/credit/accounts',
  '/credit/account/card',
];

// Credit account users
export const creditUsersAssociatedPaths = ['/credit/users'];

// Templates
export const templatesAssociatedPaths = ['/credit/templates'];

// Admin user management
export const adminUsermanagementAssociatedPaths = [
  '/admin',
  '/admin/roles',
  '/admin/departments',
  '/admin/supplements',
];

// Accepted Countries
export const acceptedCountriesAssociatedPaths = [
  '/accepted-countries',
  '/accepted-countries/new',
  '/accepted-countries/:token',
];

// Application Tokens
export const applicationTokensAssociatedPaths = [
  '/application-tokens',
  '/application-tokens/application',
  '/application-tokens/standard-token',
];

// Approval Queue
export const approvalQueueAssociatedPaths = ['/approval-queue', '/approval-queue/:token'];

// Card Products
export const cardProductsAssociatedPaths = [
  '/card-products',
  '/card-products/new',
  '/card-products/:token',
];

// MCC Groups
export const mccGroupsAssociatedPaths = ['/mcc-groups', '/mcc-groups/new', '/mcc-groups/:token'];

// Program Controls
export const programControlsAssociatedPaths = ['/program-controls', '/program-controls/:shortcode'];

// Data Marketplace
export const dataCatalogAssociatedPaths = [
  '/data-catalog',
  '/data-catalog/product',
  '/data-catalog/product/manage',
];
