import React from 'react';

import { Text } from '@mqd/volt-base';

import {
  PRODUCT_STATUS,
  CARD_STATUSES,
  ACCOUNT_STATUSES,
  PAYMENT_STATUSES,
  PAYMENT_SOURCE_STATUS,
  PAYMENT_SOURCE_TYPE,
} from '../../constants.js';
import { capitalizeFirstLetter } from '../../utils.js';

import s from './Status.module.css';

export default function Status({
  status = '',
  className = '',
  capitalize = false,
  showStatus = true,
}) {
  let pointColorClass;
  switch (status) {
    case PRODUCT_STATUS.ACTIVE:
    case CARD_STATUSES.ACTIVE:
    case ACCOUNT_STATUSES.ACTIVE:
    case PAYMENT_STATUSES.COMPLETED:
      pointColorClass = s.active;
      break;
    case PRODUCT_STATUS.SENT_FOR_REVISION:
    case CARD_STATUSES.SUSPENDED:
    case ACCOUNT_STATUSES.SUSPENDED:
    case PAYMENT_STATUSES.PENDING:
    case PAYMENT_STATUSES.PROCESSING:
    case PAYMENT_STATUSES.SUBMITTED:
      pointColorClass = s.sentForRevision;
      break;
    case PRODUCT_STATUS.PENDING_APPROVAL:
      pointColorClass = s.pendingApproval;
      break;
    case PRODUCT_STATUS.REJECTED:
    case CARD_STATUSES.TERMINATED:
    case ACCOUNT_STATUSES.TERMINATED:
    case PAYMENT_STATUSES.REJECTED:
    case PAYMENT_STATUSES.RETURNED:
    case PAYMENT_STATUSES.CANCELLED:
      pointColorClass = s.rejected;
      break;
    case PRODUCT_STATUS.DRAFT:
    case ACCOUNT_STATUSES.DRAFT:
      pointColorClass = s.draft;
      break;
    case PAYMENT_SOURCE_STATUS.ACTIVE:
    case PAYMENT_SOURCE_STATUS.PENDING:
    case PAYMENT_SOURCE_STATUS.INACTIVE:
    case PAYMENT_SOURCE_TYPE.SAVINGS:
    case PAYMENT_SOURCE_TYPE.CHECKING:
    case PAYMENT_SOURCE_TYPE.OTHER:
    default:
      pointColorClass = s.draft;
  }

  const statusPointClasses = [s.status, pointColorClass].join(' ');
  const statusName = status.split('_').join(' ');

  return (
    <div className={className}>
      <div className={`${showStatus ? statusPointClasses : ''}`} />
      {capitalize ? (
        <Text type="body-sm" inline={true} testId="statusName">
          {capitalizeFirstLetter(statusName)}
        </Text>
      ) : (
        <Text type="label" inline={true} testId="statusName">
          {statusName}
        </Text>
      )}
    </div>
  );
}
