import React, { useState } from 'react';
import { VSpacer, Select, Flex } from '@mqd/volt-base';
import s from '../../../../../../../../../credit/components/AccountsTable/AccountsTable.module.css';
import Pagination from '../../../../../../../../../program/Accountholders/components/Pagination';
import shared from '../../../../../../../../../credit/SharedStyles.module.css';
import { calculateTableHeight, handleError } from '../../../../../../../../../credit/utils';
import { useQuery } from '@apollo/react-hooks';
import { LoadingOverlay } from '@mqd/volt-core/pre';
import { Table } from '@mq/voltron-table';
import gql from 'graphql-tag';
import CSVExportButton from '../CSVExportButton';
import { prepareDataForCSVExport } from '../../utils';
import Placeholder from '../Placeholder';
import { formatDate } from '../../../../../../utils/date';
import { creditColumns } from './columns';

const fetchCreditProgramsQuery = gql`
  query creditProgram($start_index: Int) {
    creditProgram(start_index: $start_index) {
      shortCode
      name
      partner
      createdTime
      updatedTime
    }
  }
`;

const fetchAllProgramsFundingQuery = gql`
  query fetchAllProgramsFunding($start_index: Int) {
    fetchAllProgramsFunding(start_index: $start_index) {
      data {
        token
        shortCode
        currencyCode
        amount
        postTime
        createdTime
        updatedTime
        memo
      }
      is_more
      start_index
    }
  }
`;

const fetchCreditProgramsFundingQuery = gql`
  query creditProgramsFunding($start_index: Int, $short_code: String) {
    creditProgramsFunding(start_index: $start_index, short_code: $short_code) {
      data {
        token
        shortCode
        currencyCode
        amount
        postTime
        createdTime
        updatedTime
        memo
      }
      is_more
      start_index
    }
  }
`;

const getProgramFundingQuery = (program) => {
  return program.length === 0 ? fetchAllProgramsFundingQuery : fetchCreditProgramsFundingQuery;
};

export const CreditProgramSpecificTab = () => {
  const [program, setProgram] = useState('');

  const {
    data: creditProgramData,
    refetch: refetchCreditProgramData,
    loading: programLoading,
  } = useQuery(fetchCreditProgramsQuery, {
    onError: (error) => {
      handleError(refetchCreditProgramData(), error, 'credit programs');
    },
    fetchPolicy: 'no-cache',
  });

  const programFundingQuery = getProgramFundingQuery(program);

  const {
    data: creditProgramsFundingData,
    refetch: refetchCreditProgramsFundingData,
    nextPage,
    prevPage,
    loading,
    isMore,
    isLess,
    error,
  } = useQuery(programFundingQuery, {
    variables: {
      start_index: 0,
      short_code: program,
    },
    onError: (error) => {
      handleError(refetchCreditProgramsFundingData, error, 'credit programs funding');
    },
    fetchPolicy: 'no-cache',
  });

  if (loading || programLoading) {
    return (
      <>
        <LoadingOverlay active={loading} transparentBackground>
          <div className={shared.loaderContainer} />
        </LoadingOverlay>
      </>
    );
  }
  return DisplayCreditProgramSpecificTab(
    program,
    creditProgramsFundingData,
    creditProgramData,
    error,
    isMore,
    isLess,
    nextPage,
    prevPage,
    setProgram
  );
};

export function DisplayCreditProgramSpecificTab(
  program,
  creditProgramsFundingData,
  creditProgramData,
  error,
  isMore,
  isLess,
  nextPage,
  prevPage,
  setProgram
) {
  // Not sure why but the test sends the whole parameter
  // as additional fields in the first argument
  if (program.hasOwnProperty('program')) {
    creditProgramsFundingData = program.creditProgramsFundingData;
    creditProgramData = program.creditProgramData;
    error = program.error;
    isMore = program.isMore;
    isLess = program.isLess;
    nextPage = program.nextPage;
    prevPage = program.prevPage;
    setProgram = program.setProgram;
    program = program.program;
  }

  let creditProgramsFunding;
  let fileName = 'Credit_Program_Funding_All';
  if (program.length === 0) {
    creditProgramsFunding = creditProgramsFundingData?.fetchAllProgramsFunding?.data || [];
  } else {
    fileName = `Credit_Program_Funding_${program}`;
    creditProgramsFunding = creditProgramsFundingData?.creditProgramsFunding?.data || [];
  }

  creditProgramsFunding.sort((a, b) => new Date(b.createdTime) - new Date(a.createdTime));
  const creditPrograms = creditProgramData?.creditProgram || [];
  const programs = creditPrograms
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .map((program) => {
      return {
        val: program.shortCode,
        id: program.name,
        render: program.name,
      };
    });
  programs.unshift({ val: '', id: 'All', render: 'All' });

  creditProgramsFunding.forEach((item) => {
    item.type = item.amount >= 0 ? 'Deposit' : 'Withdrawal';
    item.postTime = formatDate(item.postTime);
    item.amount = parseFloat(item.amount).toFixed(2);
  });

  const noDataAvailable = creditProgramsFunding.length === 0;

  function displayFunding(val) {
    setProgram(val.val);
  }

  function memoizedDataLoader() {
    return prepareDataForCSVExport(creditProgramsFunding, creditColumns);
  }

  function header() {
    return (
      <>
        <VSpacer factor={2} />
        <Flex justifyContent="space-between">
          <Select
            testId={'credit-program-select'}
            showSearch={true}
            width={200}
            value={program}
            onChange={(value) => displayFunding(value)}
            options={programs}
          />
          <CSVExportButton
            filename={fileName}
            disabled={noDataAvailable}
            dataLoader={memoizedDataLoader}
          />
        </Flex>
      </>
    );
  }

  if (noDataAvailable || error) {
    return (
      <>
        {header()}
        <Placeholder hasError={program.length !== 0 && Boolean(error)} />
      </>
    );
  }

  const renderPagination = (
    <Pagination
      startIndex={0}
      isMore={isMore}
      isLess={isLess}
      handleNextClick={() => nextPage()}
      handlePreviousClick={() => prevPage()}
      className={s.pagination}
    />
  );

  return (
    <>
      {header()}
      <VSpacer factor={1} />
      <div>
        <VSpacer factor={2} />
        <Table
          testId="credit-programs-funding-table"
          height={calculateTableHeight(creditProgramsFunding)}
          data={creditProgramsFunding}
          columns={creditColumns}
          inlineStyle={{
            borderTop: 'none',
            borderLeft: 'none',
          }}
        />
      </div>
      {renderPagination}
    </>
  );
}
