import React from 'react';
import PropTypes from 'prop-types';
import { Text, VSpacer, Button } from '@mqd/volt-base';
import { SegmentTrackHOC } from '@mq/voltron-parent';
import ChangeStatusModal from '../../../change-status-modal/ChangeStatusModal';

import s from './actions.module.css';

function LockCard({ active, cardStore, lockCard, unlockCard }) {
  const [showConfirmationmodal, setShowConfirmationmodal] = React.useState(false);

  const { last_four } = cardStore;
  const toggleCardLock = active ? unlockCard : () => setShowConfirmationmodal(true);

  return (
    <div
      className={s.container}
      data-testid="lock-card"
      style={{ margin: '0px 44px 0 46px', paddingLeft: 7 }}
    >
      {showConfirmationmodal && (
        <ChangeStatusModal
          testId="suspend-card-modal"
          heading={`Suspend card**${last_four}`}
          showStatusSelection={false}
          hideModal={() => setShowConfirmationmodal(false)}
          store={cardStore}
          overrideNativeOnSubmit={(params) => {
            setShowConfirmationmodal(false);
            return lockCard(params);
          }}
          footerButtons={[<Button testId="suspend-card-modal-button">Suspend</Button>]}
        >
          <Text>Locking a card temporarily suspends card activity.</Text>
        </ChangeStatusModal>
      )}
      <Text type="h6">Lock card</Text>
      <VSpacer factor={1} />
      <Text type="body-sm">Temporarily suspend card activity. </Text>
      <VSpacer factor={3} />
      <SegmentTrackHOC eventName={active ? 'Card Unlocked' : 'Card Locked'}>
        <Button testId="lock-card-button" type="secondary" onClick={toggleCardLock}>
          {active ? 'Unlock card' : 'Lock card'}
        </Button>
      </SegmentTrackHOC>
    </div>
  );
}

LockCard.propTypes = {
  active: PropTypes.bool,
  cardStore: PropTypes.any,
  lockCard: PropTypes.func,
  unlockCard: PropTypes.func,
};

LockCard.defaultProps = {
  cardStore: {},
};

export default LockCard;
