import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Select } from '@mqd/volt-base';
import { countryCodeList } from './constants.js';

// This component will be moved to @mqd/volt-base for use in the future
// Jira: https://marqeta.atlassian.net/browse/PS-25232
/**
  Props:
    initialValue: 
      - Expects a country code or country alpha2 code. Ex. initialValue={'US'} || initialValue={'840'}
      - Acceptable code values in ./constants
    onChange: 
      - will receive the full country object. 
        Ex. {
          name: 'United States of America',
          alpha2: 'US',
          alpha3: 'USA',
          countryCode: 840,
          iso3166Dash2: 'ISO 3166-2:US',
          region: 'Americas',
          subRegion: 'Northern America',
          intermediateRegion: '',
          regionCode: '019',
          subregionCode: '021',
          intermediateregionCode: '',
        }
 */
function CountrySelect({
  label,
  width,
  initialValue,
  value,
  onChange,
  testId,
  name,
  disabled,
  hasChangedType,
  placeholder,
}) {
  const convertToCodeIfAlpha2 = (codeOrAlpha2) => {
    const shouldConvertToCountryCode = isNaN(Number(codeOrAlpha2));
    if (shouldConvertToCountryCode) {
      const currencyObject = countryCodeList.find(({ alpha2 }) => String(alpha2) === codeOrAlpha2);
      return currencyObject && String(currencyObject.countryCode);
    }

    return String(codeOrAlpha2);
  };

  const [selected, setSelected] = useState(convertToCodeIfAlpha2(initialValue));
  const options = countryCodeList.map(({ countryCode, name }) => {
    const countryCodeString = String(countryCode);

    return {
      id: countryCode,
      val: countryCodeString,
      render: name,
      renderSelected: name,
      searchString: name,
    };
  });

  const onSelect = ({ val }) => {
    setSelected(val);

    const countryObject = countryCodeList.find(
      ({ countryCode }) => String(countryCode) === String(val)
    );
    onChange(countryObject);
  };
  const selectedObject = useMemo(
    () =>
      options.find(({ val }) => {
        return val === selected;
      }),
    [options, selected]
  );

  return (
    <Select
      label={label}
      testId={testId}
      width={width}
      onChange={onSelect}
      value={selectedObject}
      options={options}
      showSearch={true}
      name={name}
      disabled={disabled}
      hasChangedType={hasChangedType}
      placeholder={placeholder}
    />
  );
}

CountrySelect.propTypes = {
  label: PropTypes.string,
  width: PropTypes.number,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  testId: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  hasChangedType: PropTypes.string,
  kind: PropTypes.string,
};

CountrySelect.defaultProps = {
  label: 'Select a country',
  width: 100,
  initialValue: null,
  onChange: () => {},
  testId: 'country-select',
  name: '',
  disabled: false,
  kind: 'select',
};

export default CountrySelect;
