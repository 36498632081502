import React, { PureComponent } from 'react';
import { Icon, Text, VSpacer } from '@mqd/volt-base';
import { transformEntityString, getRelevantFields } from '../../helpers';
import CardProgressBar from './CardProgressBar';
import Rows from './Rows';
import s from './KYBCard.module.css';
import PropTypes from 'prop-types';

class KYBCard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
    this.getCounts = this.getCounts.bind(this);
  }

  getCounts() {
    const { entity } = this.props;
    const relevantFields = getRelevantFields(entity);
    const totalCount = relevantFields.length;
    const currentCount = relevantFields.reduce((acc, el) => {
      if (entity && entity[el] && entity[el].status && entity[el].status === 'SUCCESS') {
        return acc + 1;
      }
      return acc;
    }, 0);
    return { currentCount, totalCount };
  }

  render() {
    const { entity, title, userStore, updateBusinessAllowedRoles, kybStore, hydrateBusinessStore } =
      this.props;
    const { currentCount, totalCount } = this.getCounts();
    const { active } = this.state;
    const upperCasedTitle = transformEntityString(title);
    const allSuccess = currentCount === totalCount;
    return (
      <>
        <div data-testid={`KYB-card-${title}`} className={s.cardContainer}>
          <div className={s.header}>
            <div
              data-testid={'KYB-change-card-edit-state'}
              className={`${s.cardTitle} ${active ? s.active : ''}`}
              onClick={() => this.setState({ active: !active })}
            >
              <Text type="h6">{upperCasedTitle}</Text>
              <Icon type="chevron-down" />
            </div>
            <VSpacer factor={1.25} />
            <CardProgressBar currentCount={currentCount} totalCount={totalCount} />
          </div>
        </div>
        {active && (
          <Rows
            updateBusinessAllowedRoles={updateBusinessAllowedRoles}
            userStore={userStore}
            kybStore={kybStore}
            entityType={title}
            fields={entity}
            allSuccess={allSuccess}
            hydrateBusinessStore={hydrateBusinessStore}
          />
        )}
      </>
    );
  }
}

KYBCard.propTypes = {
  entity: PropTypes.object,
  kybStore: PropTypes.any,
  title: PropTypes.string,
  userStore: PropTypes.any,
  updateBusinessAllowedRoles: PropTypes.array,
  hydrateBusinessStore: PropTypes.func,
};
KYBCard.defaultProps = {
  entity: { fields: [] },
  kybStore: {},
  title: '',
  userStore: {},
  updateBusinessAllowedRoles: [],
  hydrateBusinessStore: () => {},
};
export default KYBCard;
