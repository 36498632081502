import React, { useState } from 'react';
import { observer } from 'mobx-react';
import s from '../CardCreationDetail.module.css';
import FundingSourceStore from './../../stores/FundingSourceStore';
import { Modal, Text, Radio, Input, Button, VSpacer, HSpacer } from '@mqd/volt-base';

function AddAchFundingSourceModal({ hideModal, submitFundingSource }) {
  const [newFundingSource] = useState(() => new FundingSourceStore());
  const {
    setAttr,
    name_on_account,
    account_number,
    account_number_confirmation,
    routing_number,
    account_type,
    achFormIsValid,
    achFormValidation,
  } = newFundingSource;

  return (
    <Modal
      heading="Add new bank"
      hideModal={hideModal}
      width={552}
      maxHeightVh={90}
      testId="funding-source-form"
      footerButtons={[
        <Button
          disabled={!achFormIsValid}
          onClick={() => (achFormIsValid ? submitFundingSource(newFundingSource) : null)}
        >
          Submit
        </Button>,
      ]}
    >
      <Input
        label="Account holder"
        placeholder="Full name"
        value={name_on_account}
        onChange={(e) => setAttr('name_on_account', e.target.value)}
        isError={() => achFormValidation['name_on_account']}
        testId="funding-source-form_name-on-account"
      />
      <VSpacer factor={3} />
      <div>
        <Text mod="semi-bold">Account type</Text>
        <div className={s.flexDiv}>
          <Radio
            active={account_type === 'checking'}
            onClick={() => setAttr('account_type', 'checking')}
            testId="funding-source-form_account-type-checking"
            label="Checking"
          />
          <HSpacer factor={3} />
          <Radio
            active={account_type === 'savings'}
            onClick={() => setAttr('account_type', 'savings')}
            testId="funding-source-form_account-type-savings"
            label="Savings"
          />
        </div>
      </div>
      <VSpacer factor={3} />
      <Input
        label="Routing number"
        value={routing_number}
        onChange={(e) => setAttr('routing_number', e.target.value)}
        isError={() => achFormValidation['routing_number']}
        testId="funding-source-form_routing-number"
      />
      <VSpacer factor={3} />
      <Input
        label="Account number"
        value={account_number}
        onChange={(e) => setAttr('account_number', e.target.value)}
        isError={() => achFormValidation['account_number']}
        testId="funding-source-form_account-number"
      />
      <Text>Your bank account must be able to accept ACH transfers.</Text>
      <VSpacer factor={3} />
      <Input
        label="Confirm account number"
        value={account_number_confirmation}
        onChange={(e) => setAttr('account_number_confirmation', e.target.value)}
        isError={() => achFormValidation['account_number_confirmation']}
        testId="funding-source-form_account-number-confirmation"
      />
    </Modal>
  );
}

export default observer(AddAchFundingSourceModal);
