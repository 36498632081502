import currentUserStore from 'stores/CurrentUserStore';
import { UAM_GRANULAR_PERMISSIONS } from '@mqd/mqd-constants';

const requiredUserPermissions = [
  'aux-risk-external',
  'risk-internal',
  UAM_GRANULAR_PERMISSIONS.DISPUTES_VIEW_AND_EDIT,
];

export const requirements = {
  flipFlopRequirements: [],
  requiredUserPermissions,
};

export const verifier = () => {
  const {
    userStore: { canAccessDisputes },
  } = currentUserStore;

  return canAccessDisputes;
};
