import currentUserStore from 'stores/CurrentUserStore';
export const requirements = {
  requiredSupplements: ['Decision Manager'],
};

export const verifier = () => {
  const hasDecManSupplement =
    currentUserStore.userStore.supplements &&
    currentUserStore.userStore.supplements.includes('Decision Manager');
  return hasDecManSupplement;
};
