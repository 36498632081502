import React from 'react';
import Pan from 'views/fds/components/Pan/Pan.js';
import { copyRenderer, textRenderer, notAvailable } from '../helpers/renderers.js';

const RELATED_TRANSACTIONS_FIELDS = {
  /** Hash group fields */
  settlement_date: {
    header: 'Settlement date',
    accessor: 'settlement_date',
    key: 'settlement_date',
    renderer: textRenderer,
    width: 130,
  },

  network_reference_id: {
    header: 'Network reference ID',
    accessor: 'network_reference_id',
    key: 'network_reference_id',
    renderer: textRenderer,
    width: 170,
  },
  /** End hash group fields */

  source: {
    header: 'Source',
    accessor: 'source',
    key: 'source',
    renderer: textRenderer,
    width: 100,
  },

  /** Transaction summary fields */
  settled_total: {
    header: 'Settled total',
    accessor: 'settled_total',
    key: 'settled_total',
    renderer: textRenderer,
    width: 120,
  },

  transaction_total: {
    header: 'Transaction total',
    accessor: 'transaction_total',
    key: 'transaction_total',
    renderer: textRenderer,
    width: 140,
  },

  fee_total: {
    header: 'Fee total',
    accessor: 'fee_total',
    key: 'fee_total',
    renderer: textRenderer,
    width: 100,
  },

  switch_fee: {
    header: 'Switch fee',
    accessor: 'switch_fee',
    key: 'switch_fee',
    renderer: textRenderer,
    width: 120,
  },

  cross_border_fee: {
    header: 'Cross border fee',
    accessor: 'cross_border_fee',
    key: 'cross_border_fee',
    renderer: textRenderer,
    width: 130,
  },

  interchange_fee: {
    header: 'Interchange fee',
    accessor: 'interchange_fee',
    key: 'interchange_fee',
    renderer: textRenderer,
    width: 130,
  },

  surcharge_atm_pin_debit_fee: {
    header: 'Surcharge/ATM debit fee',
    accessor: 'surcharge_atm_pin_debit_fee',
    key: 'surcharge_atm_pin_debit_fee',
    renderer: textRenderer,
    width: 185,
  },

  pulse_surcharge_atm_pin_debit_fee: {
    header: 'ATM fee/Pin debit association fee',
    accessor: 'surcharge_atm_pin_debit_fee',
    key: 'surcharge_atm_pin_debit_fee',
    renderer: textRenderer,
    width: 250,
  },
  /** End transaction summary fields */

  /** Transaction info fields */
  masked_pan: {
    header: 'PAN',
    accessor: 'masked_pan',
    key: 'masked_pan',
    renderer: ({ cellValue, row }) => {
      if (cellValue) {
        const { tx_hash_internal_id } = row;
        return <Pan value={cellValue} tx_hash_internal_id={tx_hash_internal_id} readOnly />;
      }
      return notAvailable;
    },
    width: 150,
  },

  transaction_token: {
    header: 'Token',
    accessor: 'transaction_token',
    key: 'transaction_token',
    renderer: copyRenderer,
    width: 320,
  },

  original_id: {
    header: 'Original token',
    accessor: 'original_id',
    key: 'original_id',
    renderer: textRenderer,
    width: 140,
  },

  retrieval_reference_number: {
    header: 'RRN',
    accessor: 'retrieval_reference_number',
    key: 'retrieval_reference_number',
    renderer: textRenderer,
    width: 140,
  },

  system_trace_audit_number: {
    header: 'STAN',
    accessor: 'system_trace_audit_number',
    key: 'system_trace_audit_number',
    renderer: textRenderer,
    width: 140,
  },

  transaction_type: {
    header: 'Transaction type',
    accessor: 'transaction_type',
    key: 'transaction_type',
    renderer: textRenderer,
    width: 140,
  },

  transaction_code: {
    header: 'Transaction code',
    accessor: 'transaction_code',
    key: 'transaction_code',
    renderer: textRenderer,
    width: 140,
  },

  created_date: {
    header: 'Created date',
    accessor: 'created_date',
    key: 'created_date',
    renderer: textRenderer,
    width: 140,
  },

  local_transaction_date_time: {
    header: 'Local transaction date time',
    accessor: 'local_transaction_date_time',
    key: 'local_transaction_date_time',
    renderer: textRenderer,
    width: 180,
  },

  itc: {
    header: 'Internal transaction code',
    accessor: 'itc',
    key: 'itc',
    renderer: textRenderer,
    width: 190,
  },

  fee_program_indicator: {
    header: 'Fee program indicator',
    accessor: 'fee_program_indicator',
    key: 'fee_program_indicator',
    renderer: textRenderer,
    width: 170,
  },

  batch_number: {
    header: 'Batch number',
    accessor: 'batch_number',
    key: 'batch_number',
    renderer: textRenderer,
    width: 140,
  },

  reason_code: {
    header: 'Reason code',
    accessor: 'reason_code',
    key: 'reason_code',
    renderer: textRenderer,
    width: 110,
  },

  message_reason_code: {
    header: 'Message reason code',
    accessor: 'message_reason_code',
    key: 'message_reason_code',
    renderer: textRenderer,
    width: 160,
  },

  merchant_name: {
    header: 'Merchant name',
    accessor: 'merchant_name',
    key: 'merchant_name',
    renderer: textRenderer,
    width: 200,
  },

  merchant_id: {
    header: 'Merchant ID',
    accessor: 'merchant_id',
    key: 'merchant_id',
    renderer: textRenderer,
    width: 200,
  },

  merchant_country: {
    header: 'Merchant country',
    accessor: 'merchant_country',
    key: 'merchant_country',
    renderer: textRenderer,
    width: 200,
  },

  merchant_category_code: {
    header: 'Merchant category code',
    accessor: 'merchant_category_code',
    key: 'merchant_category_code',
    renderer: textRenderer,
    width: 140,
  },

  conversion_fee: {
    header: 'CCA and currency',
    accessor: 'conversion_fee',
    key: 'conversion_fee',
    renderer: textRenderer,
    width: 140,
  },

  message_type_indicator: {
    header: 'Message type indicator',
    accessor: 'message_type_indicator',
    key: 'message_type_indicator',
    renderer: textRenderer,
    width: 140,
  },

  transaction_function: {
    header: 'Transaction function',
    accessor: 'transaction_function',
    key: 'transaction_function',
    renderer: textRenderer,
    width: 140,
  },

  processing_code: {
    header: 'Processing code',
    accessor: 'processing_code',
    key: 'processing_code',
    renderer: textRenderer,
    width: 140,
  },

  function_code: {
    header: 'Function code',
    accessor: 'function_code',
    key: 'function_code',
    renderer: textRenderer,
    width: 140,
  },

  billing_amount: {
    header: 'Billing amount',
    accessor: 'billing_amount',
    key: 'billing_amount',
    renderer: textRenderer,
    width: 140,
  },

  business_activity: {
    header: 'Business activity',
    accessor: 'business_activity',
    key: 'business_activity',
    renderer: textRenderer,
    width: 140,
  },

  cycle: {
    header: 'Cycle',
    accessor: 'cycle',
    key: 'cycle',
    renderer: textRenderer,
    width: 140,
  },

  originator: {
    header: 'Originator',
    accessor: 'originator',
    key: 'originator',
    renderer: textRenderer,
    width: 120,
  },

  additional_information: {
    header: 'Additional information',
    accessor: 'additional_information',
    key: 'additional_information',
    renderer: textRenderer,
    width: 240,
  },
  /** End transaction info fields */
};

export default RELATED_TRANSACTIONS_FIELDS;
