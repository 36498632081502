export const FEE = 'FEE';
export const INTERCHANGE = 'INTERCHANGE';
export const PURCHASE = 'PURCHASE';
export const DRAWDOWN = 'DRAWDOWN';
export const UNKNOWN = 'UNKNOWN';

const accountingGroupCategories = {
  FEE,
  INTERCHANGE,
  PURCHASE,
  DRAWDOWN,
  UNKNOWN,
};

export default accountingGroupCategories;
