import React from 'react';
import { propOr } from 'ramda';
import { HasNoWebhooks, WebhooksTable } from '../webhooks-overview/index.js';

const WebhooksMain = ({ webhooks, setWebhooksData }) =>
  propOr(0, 'length', webhooks) === 0 ? (
    <HasNoWebhooks />
  ) : (
    <WebhooksTable setWebhooksData={setWebhooksData} webhooks={webhooks} />
  );

export { WebhooksMain };
