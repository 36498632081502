import currentUserStore from '../../stores/CurrentUserStore.js';
import { UAM_GRANULAR_PERMISSIONS } from '@mqd/mqd-constants';
// Routes
const programControlsRoutes = [
  {
    path: '/program-controls',
    name: 'Program Controls',
    importer: () => import(/*webpackChunkName: "Program Controls" */ './ProgramControls.js'),
  },
  {
    path: '/program-controls/:shortcode',
    name: 'Program Control Detail',
    importer: () =>
      import(
        /*webpackChunkName: "Program Controls Details" */ './program-detail/ProgramDetailWrapper.js'
      ),
  },
];

// Authorization
const defaultRequirements = {
  flipFlopRequirements: ['program-controls'],
  requiredGranularPermissions: [UAM_GRANULAR_PERMISSIONS.PROGRAM_CONFIGURATION_VIEW_AND_EDIT],
};

const defaultVerifier = () => {
  return currentUserStore.userStore.programControlsActive;
};

const requirements = {
  programControls: defaultRequirements,
  programControlDetail: defaultRequirements,
};

const verifiers = {
  programControls: defaultVerifier,
  programControlDetail: defaultVerifier,
};

export { requirements, verifiers };
export default programControlsRoutes;
