import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import TransactionsTableStore from './TransactionsTableStore';
import { ParentTable } from '@mq/voltron-parent';
import { Table } from '@mq/voltron-table';
import { Flex, LoadingOverlay, VSpacer, ToastAlert } from '@mqd/volt-base';
import { filterOptions } from './constants';
import StyleContext from '../contexts/StyleContext';
import DisputeTransactionModal from './dispute-transaction-modal/DisputeTransactionModal';
import ReturnTransactionModal from './return-transaction-modal/ReturnTransactionModal';
import { clearStoredFiltersOnUrlChange } from './utils';
import TransactionsTableActionsCell from './transactions-tabel-actions-cell/TransactionsTableActionsCell.js';
import CancelTransferModal from './cancel-transfer-modal/CancelTransferModal';
import routerStore from '../../../../stores/router/RouterStore.js';
import { transactionsTableColumns } from './TransactionsTableColumns.js';
import TransactionsTableDatePicker from './TransactionsTableDatePicker';

class TransactionsTable extends ParentTable {
  constructor(props) {
    super(props);
    this.storeConstructor = TransactionsTableStore;
    this.actionsRenderer = this.actionsRenderer.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.state = {
      showDisputeTransactionModal: false,
      showReturnTransactionModal: false,
      showCancelBankTransferModal: false,
      activeStore: null,
      ...this.colEditState(
        props,
        transactionsTableColumns({
          actionsRenderer: this.actionsRenderer,
          goToDigitalWalletToken: this.props.goToDigitalWalletToken,
          headerRenderer: this.headerRenderer(),
          parentIsBusiness: this.tableParentType === 'business',
        })
      ),
      showReturnSuccessToast: false,
      showToast: false,
      toastMessage: '',
    };
  }

  componentDidMount() {
    window.addEventListener('popstate', () => {
      clearStoredFiltersOnUrlChange(window.location.pathname);
    });

    if (routerStore && typeof routerStore.on === 'function') {
      routerStore.on('route', (rs) => {
        clearStoredFiltersOnUrlChange(rs.route.path);
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', () => {
      clearStoredFiltersOnUrlChange(window.location.pathname);
    });

    if (routerStore && typeof routerStore.off === 'function') {
      routerStore.off('route', (rs) => {
        clearStoredFiltersOnUrlChange(rs.route.path);
      });
    }
  }

  get displayActionsColumn() {
    const { canDisputeTransaction, canReturnTransaction } = this.store;
    return canDisputeTransaction || canReturnTransaction;
  }

  actionsRenderer({ row }) {
    const { canDisputeTransaction, canCancelBankTransfer, canReturnTransaction } = this.store;
    const { isBankTransferCancellable, isDisputable, isReturnable, token } = row || {};
    const { canAccessDisputes, goToNewDispute, hasInternalRole, hasProgramAdminRole } =
      this.props || {};
    // TODO - DE-3762 Remove Below When Feature Flag Turned ON
    const canCreateLegacyDispute = !hasProgramAdminRole && hasInternalRole;
    const showZendeskModal = () =>
      this.setState({ showDisputeTransactionModal: true, activeStore: row });
    const returnAction = () =>
      this.setState({ showReturnTransactionModal: true, activeStore: row });
    const cancelBankTransfer = () =>
      this.setState({ showCancelBankTransferModal: true, activeStore: row });
    return (
      <TransactionsTableActionsCell
        canDisputeTransaction={canDisputeTransaction}
        isDisputable={isDisputable}
        canReturnTransaction={canReturnTransaction}
        isReturnable={isReturnable}
        canAccessDisputes={canAccessDisputes}
        goToNewDispute={() => goToNewDispute(token)}
        showZendeskModal={showZendeskModal}
        returnAction={returnAction}
        isBankTransferCancellable={isBankTransferCancellable}
        canCancelBankTransfer={canCancelBankTransfer}
        cancelBankTransfer={cancelBankTransfer}
        // TODO - DE-3762 Remove Below When Feature Flag Turned ON
        canCreateLegacyDispute={canCreateLegacyDispute}
      />
    );
  }

  renderDisputeTransactionModal() {
    const { showDisputeTransactionModal, activeStore } = this.state;
    const { userStore } = this.store;
    if (!showDisputeTransactionModal) return null;
    return (
      <DisputeTransactionModal
        hideModal={() => this.setState({ showDisputeTransactionModal: false })}
        store={activeStore}
        activeUserInfo={{
          firstName: userStore.firstName,
          lastName: userStore.lastName,
          email: userStore.email,
        }}
        activeProgram={userStore.activeProgram}
        segmentEventName="Transaction Disputed"
        segmentProperty={{ Flow: 'Legacy' }}
      />
    );
  }

  renderReturnTransactionModal() {
    const { showToast } = this.props;
    const { showReturnTransactionModal, activeStore } = this.state;
    const { hydrate } = this.store;

    if (!showReturnTransactionModal) return null;
    return (
      <ReturnTransactionModal
        hideModal={() => this.setState({ showReturnTransactionModal: false })}
        refreshTable={hydrate}
        store={activeStore}
        showToast={showToast}
        onSuccess={() => {
          this.setState({ showToast: true, toastMessage: 'Direct deposit return initiated' });
        }}
      />
    );
  }

  renderCancelBankTransferModal() {
    const { showCancelBankTransferModal, activeStore } = this.state;
    const { hydrate } = this.store;

    if (!showCancelBankTransferModal) return null;

    return (
      <CancelTransferModal
        hideModal={() => this.setState({ showCancelBankTransferModal: false })}
        refreshTable={hydrate}
        store={activeStore}
        onSuccess={() => {
          this.setState({ showToast: true, toastMessage: 'Bank transfer has been cancelled' });
        }}
      />
    );
  }

  renderToastAlert = () => {
    const { showToast, toastMessage } = this.state;
    if (!showToast) return null;
    return (
      <ToastAlert
        icon="success"
        dismissTime={2}
        remove={() => {
          this.setState({ showToast: false });
        }}
      >
        {toastMessage}
      </ToastAlert>
    );
  };

  handleRowClick = ({ row }) => {
    this.props.goToTransaction(row.token);
  };

  render() {
    const {
      currentList,
      activeSort,
      activeRow,
      setAttr,
      loading,
      updateKey,
      userStore,
      activeFilterCount,
    } = this.store;
    const { columns, tableKey } = this.state;
    const { loading: detailLoading } = this.props;
    const pageSize = this.store.count;
    const tablePaddingBottom = currentList.length >= pageSize ? 0 : 31;

    const mainSqrShardFilterOptions = filterOptions.filter(
      (fil) => fil.label !== 'State' && fil.label !== 'Type'
    );

    const finalFilterOptions =
      userStore.selectedActiveProgram.short_name === 'sqr'
        ? mainSqrShardFilterOptions
        : filterOptions;

    return (
      <div style={{ width: '100%' }} data-testid="transaction-table-wrapper">
        {this.renderDisputeTransactionModal()}
        {this.renderReturnTransactionModal()}
        {this.renderCancelBankTransferModal()}
        {this.renderToastAlert()}
        <Flex flexDirection="row" style={{ gap: '8px' }}>
          {this.renderColEditTableHeader({
            filterOptions: finalFilterOptions,
            tableFilterTestId: 'transactions-table-header-buttons',
            filterProps: {
              activeFilterCount,
              showActiveFilterCountOnMount: true,
            },
          })}
          <TransactionsTableDatePicker
            tableStore={this.store}
            tableParentType={this.tableParentType}
          />
        </Flex>
        <VSpacer />
        <StyleContext.Consumer>
          {({ height }) => {
            return (
              <LoadingOverlay active={loading || detailLoading}>
                <Table
                  key={tableKey}
                  customEmptyState={this.customEmptyState('No transactions yet')}
                  loading={loading}
                  height={height + tablePaddingBottom || this.dynamicHeight}
                  data={currentList}
                  columns={columns}
                  sort={activeSort}
                  activeRow={activeRow}
                  fixedColumnCount={1}
                  rightAlignFixedColumns={true}
                  onSort={(newSort) => setAttr('activeSort', newSort)}
                  scrollToTopUpdateKey={updateKey}
                  handleColSizeChange={this.handleColSizeChange.bind(this)}
                  handleResetTable={this.clearFilterFunc}
                  onRowClick={this.handleRowClick}
                />
              </LoadingOverlay>
            );
          }}
        </StyleContext.Consumer>
        {this.renderPagination()}
      </div>
    );
  }
}

TransactionsTable.propTypes = {
  height: PropTypes.number,
  storeInitArgs: PropTypes.object,
  store: PropTypes.object,
  autoHydrate: PropTypes.bool,
  onStoreConstruction: PropTypes.func,
  gqlClient: PropTypes.any,
  localStorageKey: PropTypes.string,
  canAccessDisputes: PropTypes.bool,
  hasInternalRole: PropTypes.bool,
  hasProgramAdminRole: PropTypes.bool,
};

TransactionsTable.defaultProps = {
  height: 800,
  storeInitArgs: { limit: 100 },
  store: null,
  autoHydrate: true,
  onStoreConstruction: null,
  gqlClient: null,
  localStorageKey: 'TransactionsTableConfig',
  canAccessDisputes: false,
  hasInternalRole: false,
  hasProgramAdminRole: false,
};

export default observer(TransactionsTable);
