const JOB_STATES = {
  WAITING: 'waiting',
  PENDING: 'pending',
  COMPLETED: 'completed',
  FAILED: 'failed',
};

const SANDBOX_PROVISIONING_STEPS = {
  QUEUED: 0,
  SPINUP: 1,
  CONFIGURE: 2,
  PROVISION: 3,
  COMPLETED: 4,
  ERROR: 5,
  RETRY: 6,
};

const EMPTY_STATES_TEXT = {
  HasNoCards:
    'Before viewing a transaction timeline, you must create a card and simulated transaction.',
  HasNoSelectedCards: 'Select a card to view its timeline.',
  HasNoCardTransactions:
    'Before viewing a transaction timeline, you must create a card and simulate a transaction.',
};

const ERROR_STATES = {
  Default: 'Something went wrong',
  EnvironmentError: 'There was an error with your environment',
  NoTransactions: 'No transactions available for this card',
};

const UNNAMED_USER = 'Unnamed user';

const WEBHOOKS_TOAST_MESSAGE = {
  DEFAULT: {
    SUCCESS: 'Webhook saved',
    FAILED: 'Webhook not saved',
  },
  DISABLED: {
    SUCCESS: 'Webhook disabled',
    FAILED: 'Webhook not disabled',
  },
  ACTIVATED: {
    SUCCESS: 'Webhook activated',
    FAILED: 'Webhook not activated',
  },
  EVENTS_SAVED: {
    SUCCESS: 'Events saved to webhook',
    FAILED: 'Events not saved',
  },
};

export {
  JOB_STATES,
  SANDBOX_PROVISIONING_STEPS,
  EMPTY_STATES_TEXT,
  ERROR_STATES,
  UNNAMED_USER,
  WEBHOOKS_TOAST_MESSAGE,
};
