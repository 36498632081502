import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import s from './CardProductGrid.module.css';
import { Card, Text, VSpacer } from '@mqd/volt-base';

function TileText({ label, children, capitalize = true, testId = 'card-product__tile-text' }) {
  if (!children) return null;
  return (
    <Text type="body-sm" inline testId={testId}>
      <strong>{label}:</strong>
      &nbsp;
      <span className={capitalize ? s.capitalizeFirst : null}>{children}</span>
    </Text>
  );
}

function CardProductTile({
  store: {
    token,
    formattedName,
    cardProductType,
    velocity_controls = [],
    config: { fulfillment: { bin: { network, classification, type } = {}, bin_prefix } = {} } = {},
  },
  handleClick,
}) {
  const renderVelocityControls = () => {
    return velocity_controls.map(({ formattedAmountLimit, windowLabel, token }) => {
      return (
        <Fragment key={token}>
          <TileText
            label={<span className={s.capitalizeFirst}>{windowLabel}</span>}
            capitalize={false}
            testId={`velocity-control-text__${token}`}
          >
            {formattedAmountLimit}
          </TileText>
        </Fragment>
      );
    });
  };

  const renderLeftSide = () => {
    if (!network && !(classification || type)) return null;

    return (
      <div className={s.flexColumn}>
        <TileText label="Network">{network}</TileText>
        <TileText label="Classification type">{`${classification} ${type}`.trim()}</TileText>
      </div>
    );
  };

  return (
    <div
      className={s.cardProductTile}
      onClick={handleClick}
      data-testid={`card-product-tile__${token}`}
    >
      <Text type="label" testId="card-products-grid_product-type">
        {cardProductType}
      </Text>
      <Text type="h4">{formattedName}</Text>
      <VSpacer factor={2} />
      <div className={s.flexDiv}>
        {renderLeftSide()}
        <div className={s.flexColumn}>
          <TileText label="BIN prefix">{bin_prefix}</TileText>
          {renderVelocityControls()}
        </div>
      </div>
    </div>
  );
}

CardProductTile.propTypes = {
  store: PropTypes.object,
};

CardProductTile.defaultProps = {
  store: {},
};

export default CardProductTile;
