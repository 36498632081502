import {
  MARQETA_ADMIN_INTERNAL,
  PROGRAM_ADMIN,
  SUPPLIER_PAYMENTS_MANAGER,
  DELIVERY_INTERNAL,
  PRODUCTION_SUPPORT_INTERNAL,
  CARDHOLDER_SUPPORT,
} from './constants/redseaRoles.js';
import { CREATE_CARD_MANUALLY_VIEW_AND_EDIT } from '../../../views/admin/uam-granular-permissions/constants.js';
import currentUserStore from 'stores/CurrentUserStore';

const requiredUserPermissions = [
  MARQETA_ADMIN_INTERNAL,
  PROGRAM_ADMIN,
  SUPPLIER_PAYMENTS_MANAGER,
  DELIVERY_INTERNAL,
  PRODUCTION_SUPPORT_INTERNAL,
  CARDHOLDER_SUPPORT,
  'aux-manual-card-create',
];

export const requirements = {
  requiredUserPermissions,
  requiredGranularPermissions: [CREATE_CARD_MANUALLY_VIEW_AND_EDIT],
};

export const verifier = () => {
  const {
    userStore: {
      cardCreationActive,
      privateSandboxActive,
      uamGranularPermissionsActive,
      hasRoleInArrayForActiveEnvironment,
    },
  } = currentUserStore;

  const canCreateCardPsp = uamGranularPermissionsActive
    ? hasRoleInArrayForActiveEnvironment([CREATE_CARD_MANUALLY_VIEW_AND_EDIT])
    : hasRoleInArrayForActiveEnvironment(requiredUserPermissions);

  if (privateSandboxActive) {
    return canCreateCardPsp;
  } else {
    return cardCreationActive;
  }
};
