import gql from 'graphql-tag';
export const GET_EXCEPTIONS = gql`
  query getApprovalExceptionTypeQueuePage(
    $subnetwork: Subnetwork!
    $type: ExceptionTypeEnum!
    $page: Int
    $size: Int
    $start_settlement_date: String
    $end_settlement_date: String
    $dna_bank_tokens: [ID]
    $program_short_codes: [String]
    $program_types: [String]
    $originator: String
  ) {
    approvalExceptionTypeQueuePage(
      subnetwork: $subnetwork
      type: $type
      page: $page
      size: $size
      start_settlement_date: $start_settlement_date
      end_settlement_date: $end_settlement_date
      dna_bank_tokens: $dna_bank_tokens
      program_short_codes: $program_short_codes
      program_types: $program_types
      originator: $originator
    ) {
      page_number
      page_size
      has_next_page
      settlement_date_lines {
        settlement_date
        exception_count
        settled_total
        adjustment_fees
        adjustment_total
        counts_and_amounts {
          exception_count
          settled_amounts {
            accounting_group_category
            amount {
              units
              scale
            }
          }
          adjustment_amounts {
            accounting_group_category
            amount {
              units
              scale
            }
          }
        }
      }
    }
    programTypes
    dnaBanks(count: 500) {
      data {
        name
        token
      }
    }
  }
`;
