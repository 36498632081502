import React from 'react';
import { VSpacer } from '@mqd/volt-base';
import { withProgramReserveStore } from '../../hoc/withProgramReserveStore.js';
import Header from './components/Header/index.js';
import TransactionHistory from './components/TransactionHistory/index.js';
import ToastContainer from './components/ToastContainer/index.js';

function ProgramReserve() {
  return (
    <>
      <Header />
      <VSpacer factor={3} />
      <TransactionHistory />
      <ToastContainer />
    </>
  );
}

export default withProgramReserveStore(ProgramReserve);
