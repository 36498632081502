import React, { useState } from 'react';
import { Flex, HSpacer, Icon, Link, Text, VSpacer, Colors, FlatCard } from '@mqd/volt-base';
import s from './CardCarrier.module.css';
import { observer } from 'mobx-react';

const CardCarrier = ({
  cardCarrier,
  actions,
  overrideIsFlat,
  EditTemplate,
  ViewTemplate,
  canEdit = true,
}) => {
  const cardType = 'card carrier';

  const [showFlatCard, setShowFlatCard] = useState(false);

  return (
    <>
      <Text type={'h5'}>Card carrier</Text>
      <VSpacer factor={2} />
      {showFlatCard || cardCarrier ? (
        <FlatCard
          editTemplate={EditTemplate}
          viewTemplate={ViewTemplate}
          cardType={cardType}
          data={cardCarrier}
          onAddCard={(card) => actions.createAction(card)}
          onUpdateCard={(card) => actions.updateAction(card)}
          onCancelCard={() => {
            setShowFlatCard(false);
            actions.cancelAction();
          }}
          onDeleteCard={() => {
            setShowFlatCard(false);
            actions.deleteAction();
          }}
          overrideIsFlat={overrideIsFlat}
          canEdit={canEdit}
        />
      ) : canEdit ? (
        <Flex justifyContent="flex-start">
          <HSpacer factor={1.25} />
          <Link onClick={() => setShowFlatCard(true)}>
            <Icon overrideColor={`${Colors.actionColor}`} type="plus" noHoverEffects />
            <HSpacer factor={0.75} />
            <Text type="footnote" className={s.bold}>
              {`Add ${cardType}`}
            </Text>
          </Link>
        </Flex>
      ) : null}
    </>
  );
};

export default observer(CardCarrier);
