import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import s from '../CardCreationDetail.module.css';
import { ActionDropdown, HSpacer, VSpacer, Input, Icon } from '@mqd/volt-base';

function AuthControl({
  authControl: {
    merchant_scope: { setAttrWithLimit, mcc },
  },
  removeAuthControl,
  duplicateAuthControl,
}) {
  return (
    <>
      <VSpacer factor={1} />
      <div className={s.flexDiv}>
        <Input
          testId="mcc-input"
          placeholder="Add code: ####"
          onChange={(e) => setAttrWithLimit('mcc', e.target.value, 4)}
          value={mcc}
          width={456}
        />
        <HSpacer factor={1} />
        <ActionDropdown
          control={<Icon factor={0.5} type="view-more-vertical" />}
          content={[
            {
              element: 'Remove',
              onClick: removeAuthControl,
            },
            {
              element: 'Duplicate',
              onClick: duplicateAuthControl,
            },
          ]}
        />
      </div>
    </>
  );
}

AuthControl.propTypes = {
  authControl: PropTypes.object,
  removeAuthControl: PropTypes.func,
  duplicateAuthControl: PropTypes.func,
};

AuthControl.defaultProps = {
  authControl: {
    merchant_scope: {
      setAttrWithLimit: () => {},
      mcc: '',
    },
  },
  removeAuthControl: () => {},
  duplicateAuthControl: () => {},
};

export default observer(AuthControl);
