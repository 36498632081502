import React from 'react';
import PropTypes from 'prop-types';
import s from './commons.module.css';
import { Icon, HSpacer } from '@mqd/volt-base';

function AddButton({ children, onClick, testId }) {
  return (
    <div className={s.addButton} onClick={onClick} data-testid={testId}>
      <Icon factor={0.5} type="plus" />
      <HSpacer factor={1} />
      {children}
    </div>
  );
}

AddButton.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  testId: PropTypes.string,
};

AddButton.defaultProps = {
  children: null,
  onClick: () => {},
  testId: 'add-button',
};

export default AddButton;
