export const weakPins = [
  '0000',
  '1111',
  '2222',
  '3333',
  '4444',
  '5555',
  '6666',
  '7777',
  '8888',
  '9999',
  '1234',
  '2345',
  '3456',
  '4567',
  '5678',
  '6789',
  '7890',
  '0123',
  '0987',
  '9876',
  '8765',
  '7654',
  '6543',
  '5432',
  '4321',
  '3210',
  '1212',
  '1004',
  '6969',
  '2000',
  '2015',
];
