import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import css from './Button.module.css';

function classes(...classList) {
  try {
    const classes = classList.map((className) => {
      const isNotPresent = [null, undefined, ''].includes(className);
      if (isNotPresent) {
        return null;
      }
      if (className && typeof className === 'string') return className;
      if (typeof className === 'object' && !(className instanceof Array)) {
        return Object.keys(className)
          .filter((classKey) => className[classKey])
          .join(' ');
      }
      return null;
    });
    return classes.filter((c) => c).join(' ');
  } catch (err) {
    console.error(`ERROR: classes error: ${err}`);
  }
}

// TODO: remove this file when the ticket below is done
//  https://marqeta.atlassian.net/browse/VOLTENG-45
const ButtonWithRef = forwardRef(
  (
    {
      children,
      link,
      fullWidth,
      loading,
      onClick,
      testId,
      type, // @deprecated
      mod = 'primary',
      ...buttonProps
    },
    ref
  ) => {
    mod = mod || type;

    function handleClick(event) {
      onClick(event);
    }

    return (
      <button
        ref={ref}
        className={classes(
          css.button,
          css[`button--${mod}`],
          link && css[`button--link`],
          fullWidth && css[`button--full-width`],
          loading && css[`button--loading`]
        )}
        data-testid={testId}
        onClick={handleClick}
        {...buttonProps}
      >
        <span className={css.content}>{children}</span>
      </button>
    );
  }
);

ButtonWithRef.propTypes = {
  link: PropTypes.bool,
  fullWidth: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  testId: PropTypes.string,
  type: PropTypes.string,
  mod: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'danger', 'link']),
};

ButtonWithRef.defaultProps = {
  link: false,
  fullWidth: false,
  loading: false,
  testId: null,
  mod: 'primary',
};

ButtonWithRef.displayName = 'Button';

export { ButtonWithRef as Button };
