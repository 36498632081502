import { useState, useCallback } from 'react';
import { reject, isNil, pickAll, propEq } from 'ramda';

import uniqueId from '../../shared-utils/unique-id';
import { useTemplateProperties } from './use-template-properties';

export function useActionCards({ properties, onCreateNewCard, onUpdateCard, onDeleteCard }) {
  const [newCards, setNewCards] = useState([]);
  const templateProperties = useTemplateProperties(properties);

  const handleAddNewCard = useCallback(() => {
    // { temp: true } means that the card is stored only in memory
    setNewCards((cards) => cards.concat({ id: uniqueId(), new: true, temp: true }));
  }, [setNewCards]);

  const removeFromNewCards = (id) => setNewCards(reject(propEq('id', id)));

  const callFormCallback = (callback, datum) => {
    const datumWithOnlyNeededProps = reject(isNil, pickAll(templateProperties, datum));
    callback({ cardData: datumWithOnlyNeededProps, id: datum.id });
  };

  const actions = {
    onCreateNewCard(datum) {
      removeFromNewCards(datum.id);
      callFormCallback(onCreateNewCard, datum);
    },
    onUpdateCard(datum) {
      callFormCallback(onUpdateCard, datum);
    },
    onResetCard(datum) {
      removeFromNewCards(datum.id);
    },
    onDeleteCard(datum) {
      callFormCallback(onDeleteCard, datum);
    },
  };

  return { newCards, handleAddNewCard, actions };
}
