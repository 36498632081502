import { dropLast, last } from 'ramda';
import { secureStorage } from '@mq/volt-amc-container';
import moment from 'moment';

const PREFIX = 'BILL_';
const BILL_FILTERS = `${PREFIX}Filters`;

export const sorter = (activeSort) => (a, b) => {
  const direction = last(activeSort);
  const key = dropLast(1, activeSort);

  return  direction === '+' ? (a[key] > b[key] ? 1 : -1) : a[key] > b[key] ? -1 : 1;
};

export const getBillPaymentFilters = () => {
  return secureStorage.getItem(BILL_FILTERS);
};

export const setBillPaymentFilters = (value) => {
  secureStorage.setItem(BILL_FILTERS, value);
};

export const dateToString = (date) => moment(date).format("YYYY-MM-DD");

export const getDefaultStartDate = (days = 60) => {
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - days);
  return startDate;
};

export const getDefaultDateRangeFilter = () => {
  const startDate = getDefaultStartDate();

  return {
    start_date: dateToString(startDate),
    end_date: dateToString(new Date())
  };
}

export const getDefaultDates = () => {
  const rawFilters = getBillPaymentFilters();
  if (rawFilters) {
    const filters = JSON.parse(rawFilters);
    const { start_date, end_date } = filters;

    return [new Date(start_date), new Date(end_date)];
  }

  const startDate = getDefaultStartDate();
  return [startDate, new Date()];
};

export const onChangeDateRange = ({startDate, endDate, setQueryFilters, setLocalDateFilter}) => {
  if (startDate && endDate) {
    setLocalDateFilter([startDate, endDate]);
  } else if (endDate) {
    setLocalDateFilter(['', endDate]);
  } else {
    setLocalDateFilter([startDate, '']);
  }
  const filters = {
    ...(startDate && { start_date: dateToString(startDate) }),
    ...(endDate && { end_date: dateToString(endDate) })
  };
  setQueryFilters(filters);
  setBillPaymentFilters(JSON.stringify(filters));
};

