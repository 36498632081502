import React from 'react';
import { ParentDetailCard } from '@mq/voltron-parent';
import { Text, VSpacer } from '@mqd/volt-base';
import { sentenceCase, formatPhone } from '../shared-utils';

class DigitalWalletTokenDeviceInfo extends ParentDetailCard {
  render() {
    const { device_type, device_id, phone_number, device_name, location, ip_address } =
      this.props || {};

    return (
      <div data-testid="token-device-info">
        <Text type="h4">Device</Text>
        <VSpacer factor={2} />
        {this.renderSnippet({
          title: 'Device name',
          content: device_name,
        })}
        {this.renderSnippet({
          title: 'Phone number',
          content: formatPhone(phone_number),
        })}
        {this.renderSnippet({
          title: 'Device ID',
          content: device_id,
        })}
        {this.renderSnippet({
          title: 'Type',
          content: sentenceCase(device_type),
        })}

        {this.renderSnippet({
          title: 'Location',
          content: location,
        })}

        {this.renderSnippet({
          title: 'IP address',
          content: ip_address,
        })}
      </div>
    );
  }
}

export default DigitalWalletTokenDeviceInfo;
