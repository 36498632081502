import React from 'react';
import { Pagination } from '@mq/voltron-table';

export default function PaginationWrapper({ className, prevPageToken, nextPageToken, refetch }) {
  const handleNextClick = () => refetch({ page_token: nextPageToken });
  const handlePreviousClick = () => refetch({ page_token: prevPageToken });
  return (
    <Pagination
      className={className}
      startIndex={0}
      isMore={Boolean(nextPageToken)}
      isLess={Boolean(prevPageToken)}
      handleNextClick={handleNextClick}
      handlePreviousClick={handlePreviousClick}
    />
  );
}
