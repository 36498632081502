// https://www.qualtrics.com/support/website-app-feedback/intercepts-tab/edit-intercept-section/action-set-options/adding-event-tracking-triggering/#AdvancedEventTracking

export function track(event) {
  try {
    if (window._qsie) {
      window._qsie.push(event);
    }

    if (window.QSI) {
      window.QSI.API.unload();
      window.QSI.API.load().then(window.QSI.API.run);
    }
  } catch (error) {
    console.error('Qualtrics event tracking error', error);
  }
}
