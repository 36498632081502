import React from 'react';
import { ProgramTransactionHistoryStore } from '../../../../stores/program-reserve/program-transaction-history-store.js';
import { ProgramTransactionHistoryStoreContext } from '../../context/ProgramTransactionHistoryStoreContext.js';
import { columns } from '../ProgramReserve/components/TransactionHistory/components/ProgramSpecificTab/columns.js';
import { filterColumns } from '../../utils/filterColumns.js';

const ProgramTransactionHistoryStoreProvider = ({ children }) => {
  const initialColumns = filterColumns(columns);
  const programTransactionHistoryStore = new ProgramTransactionHistoryStore(initialColumns);

  return (
    <ProgramTransactionHistoryStoreContext.Provider value={programTransactionHistoryStore}>
      {children}
    </ProgramTransactionHistoryStoreContext.Provider>
  );
};

export default ProgramTransactionHistoryStoreProvider;
