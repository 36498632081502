import { decorate, observable, action, computed } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';

class S3MetadataResponseStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
  }

  // values
  code: string = '';
  description: string = '';
  total: string = '';
  success: string = '';
  failure: string = '';
  users_processed: string = '';
  cards_processed: string = '';
  funds_processed: string = '';

  // helpers
  formatResource(res, count) {
    if (!count || !res) return;
    const label = count === 1 ? res : res + 's';
    return `${count} ${label}`;
  }

  // computed
  get formattedProcessedResources() {
    return [
      this.formatResource('User', this.users_processed),
      this.formatResource('Card', this.cards_processed),
      this.formatResource('Fund', this.funds_processed),
    ]
      .filter((e) => e)
      .join(', ');
  }
}

decorate(S3MetadataResponseStore, {
  skipAutoLoad: observable,
  // values
  code: observable,
  description: observable,
  total: observable,
  success: observable,
  failure: observable,
  users_processed: observable,
  cards_processed: observable,
  funds_processed: observable,

  // actions

  // helpers
  formatResource: action.bound,

  // computed
  formattedProcessedResources: computed,
});

export default S3MetadataResponseStore;
