import React from 'react';
import {
  Colors,
  DetailPane,
  Link,
  StatusIndicator,
  CopyToClipboard,
  Text,
  VSpacer,
} from '@mqd/volt-base';
import { formatMoney } from '@mqd/volt-currency';
import AddressStore from '../../../stores/AddressStore';
import { useCardholderInfoContext } from '../context';
import s from '../CardholderInfo.module.css';

const ViewDetails = ({ data = {}, statusComponent, onBusinessClick }) => {
  const {
    state: { isAccountholderView },
  } = useCardholderInfoContext();
  const {
    token,
    email,
    phone,
    birth_date,
    nationality,
    company,
    honorific,
    status,
    parent_token,
    business_token,
    business_name_dba,
    business_name_legal,
    uses_parent_account,
    gpa_balance,
  } = data;

  const { address1, address2, city, state, postal_code, country } = data;
  const addressStore = new AddressStore({
    address1,
    address2,
    city,
    state,
    postal_code,
    country,
  });
  let addressDisplay = (
    <Text type="body-sm" color={Colors.blackLighter5}>
      Address required
    </Text>
  );

  if (addressStore.addressCorrect) {
    addressDisplay = addressStore && addressStore.displayUS ? addressStore.displayUS : '';
  }

  const { metadataObject = {} } = data;
  const businessName = business_name_dba || business_name_legal || business_token || '';
  const metadataArr = [];
  for (const key in metadataObject) {
    const value = metadataObject[key];
    metadataArr.push(<DetailPane.Content label={key}>{value}</DetailPane.Content>);
  }

  const { gpa } = gpa_balance || {};

  return (
    <DetailPane.default layout="row">
      <DetailPane.Content label="Status">
        {statusComponent ? (
          statusComponent
        ) : (
          <StatusIndicator testId="cardholderinfo-status-indicator" status={status} />
        )}
      </DetailPane.Content>
      <DetailPane.Content label="Phone">
        {phone ? (
          phone
        ) : (
          <Text type="body-sm" color={Colors.blackLighter5}>
            Phone number required
          </Text>
        )}
      </DetailPane.Content>
      <DetailPane.Content label="Birth date">
        {birth_date ? (
          birth_date
        ) : (
          <Text type="body-sm" color={Colors.blackLighter5}>
            Birth date required
          </Text>
        )}
      </DetailPane.Content>
      <DetailPane.Content label="Email">
        {email ? (
          email
        ) : (
          <Text type="body-sm" color={Colors.blackLighter5}>
            Email required
          </Text>
        )}
      </DetailPane.Content>
      <DetailPane.Content label="Address">
        <Text className={s.wrappedAddress}>{addressDisplay}</Text>
      </DetailPane.Content>
      {nationality && <DetailPane.Content label="Nationality">{nationality}</DetailPane.Content>}
      {company && <DetailPane.Content label="Company">{company}</DetailPane.Content>}
      {honorific && <DetailPane.Content label="Honorific">{honorific}</DetailPane.Content>}

      <DetailPane.Content label="Token">
        <CopyToClipboard testId="cardholder-token">{token}</CopyToClipboard>
      </DetailPane.Content>
      {business_token && (
        <DetailPane.Content label="Business">
          <Link newTab={false} onClick={() => onBusinessClick(business_token)}>
            {businessName}
          </Link>
        </DetailPane.Content>
      )}
      {parent_token && uses_parent_account && (
        <DetailPane.Content label="Parent token">{parent_token}</DetailPane.Content>
      )}
      {metadataArr.length > 0 && (
        <>
          <VSpacer factor={2} />
          <Text type="h5" labelledBy="Metadata">
            Metadata
          </Text>
          <VSpacer factor={2} />
          <DetailPane.default layout="row">{metadataArr}</DetailPane.default>
        </>
      )}
      {gpa && !isAccountholderView && (
        <>
          <VSpacer factor={4} />
          <Text type="h5" labelledBy="Account balance">
            Account balance
          </Text>
          <VSpacer factor={1} />
          <DetailPane.Content
            label="Available balance"
            tooltip="The ledger balance minus any authorized transactions that have not yet cleared. Also known as the cardholder’s purchasing power. If you are using JIT Funding, this balance is usually 0.00."
          >
            <Text inline={true}>
              ${formatMoney(Number(gpa.available_balance))} {gpa.currency_code}
            </Text>
          </DetailPane.Content>
          <DetailPane.Content label="Credit balance" tooltip="Not currently used.">
            <Text inline={true}>
              ${formatMoney(Number(gpa.credit_balance))} {gpa.currency_code}
            </Text>
          </DetailPane.Content>
          <DetailPane.Content
            label="Pending credits"
            tooltip="ACH loads that have been accepted, but for which the funding time has not yet elapsed."
          >
            <Text inline={true}>
              ${formatMoney(Number(gpa.pending_credits))} {gpa.currency_code}
            </Text>
          </DetailPane.Content>
          <DetailPane.Content
            label="Ledger balance"
            tooltip="When using standard funding, the funds that are available to spend immediately, including any authorized transactions that have not yet cleared. When using Just-in-Time (JIT) Funding, authorized funds that are currently on hold, but not yet cleared.ACH loads that have been accepted, but for which the funding time has not yet elapsed."
          >
            <Text inline={true}>
              ${formatMoney(Number(gpa.ledger_balance))} {gpa.currency_code}
            </Text>
          </DetailPane.Content>
        </>
      )}
    </DetailPane.default>
  );
};

export default ViewDetails;
