import moment from 'moment';
import { formatMoney } from '@mqd/volt-currency';
import { transactionFiltersLocalStorageKey } from './constants.js';

export const convertNumberToCurrency = (value, unit) => {
  if (!value) return null;
  let returnValue = `$${formatMoney(Number.parseFloat(value))}`;
  if (unit) returnValue = returnValue + ` ${unit}`;
  return returnValue;
};

export const formatDateAsUTCString = (date) => {
  return date ? moment.utc(date).format('M/D/Y, H:mm UTC') : '';
};

export const getDateThirtyDaysBeforeDate = (date) => {
  return new Date(new Date().setDate(date.getDate() - 30));
};

export const toBase64 = (file) =>
  new Promise(async (resolve, reject) => {
    const reader = new FileReader();

    reader.onload = async () => {
      try {
        const base64Data = reader.result.split('base64,')[1];
        resolve(base64Data);
      } catch (e) {
        reject(e);
      }
    };
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });

export const clearStoredFiltersOnUrlChange = (path = '') => {
  const [program, entity] = path.slice(1).split('/');
  const firstTwoSegments = `/${program}/${entity}`;

  if (!['/program/user', '/program/business'].includes(firstTwoSegments)) {
    window.sessionStorage.removeItem(transactionFiltersLocalStorageKey);
  }
};
