import React from 'react';
import ApprovalStatus from './ApprovalStatus';
import { Text } from '@mqd/volt-base';
import s from './Header.module.css';

function Header(props) {
  return (
    <div className={s.header}>
      <Text type="h3">{props.businessName}</Text>
      <ApprovalStatus
        businessEntities={props.businessEntities}
        userStore={props.userStore}
        businessName={props.businessName}
      />
    </div>
  );
}

export default Header;
