import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Text, Button, Modal, VSpacer } from '@mqd/volt-base';

import s from './actions.module.css';

function ReportCardLost({ cardStore, recipientAddress, onClick, triggerModalNeeded, hideModal }) {
  const [showConfirmationmodal, setShowConfirmationmodal] = useState(!triggerModalNeeded);
  const { isPhysical, last_four } = cardStore;

  const physicalContent = (
    <>
      <Text>
        Terminate a lost or stolen card and immeditately reissue a new card with a different card
        number. Terminated cards cannot be used or reactivated. The physical card will be sent to
        the recipient address on file.
      </Text>
      <VSpacer factor={3} />
      <Text type="h6">Recipient address</Text>
      <VSpacer factor={1} />
      <Text>{recipientAddress}</Text>
    </>
  );

  const virtualContent = (
    <Text>
      Terminate a stolen card and immeditately reissue a new card with a different card number.
      Terminated cards cannot be used or reactivated.
    </Text>
  );

  const modalContent = isPhysical ? physicalContent : virtualContent;

  return (
    <div
      className={triggerModalNeeded && s.container}
      data-testid="report-card"
      style={{ paddingLeft: 14 }}
    >
      {showConfirmationmodal && (
        <Modal
          heading={`Report card **${last_four}`}
          hideModal={() => (triggerModalNeeded ? setShowConfirmationmodal(false) : hideModal())}
          store={cardStore}
          footerButtons={[
            <Button type="danger" testId="report-card-modal-button" onClick={onClick}>
              Terminate and reissue
            </Button>,
          ]}
        >
          {modalContent}
        </Modal>
      )}
      {triggerModalNeeded && (
        <>
          <Text type="h6">Report card lost or stolen</Text>
          <VSpacer factor={1} />
          <Text type="body-sm">Terminate and issue a new card with a new card number.</Text>
          <VSpacer factor={3} />
          <Button
            testId="report-card-button"
            type="secondary"
            onClick={() => setShowConfirmationmodal(true)}
          >
            Report card
          </Button>
        </>
      )}
    </div>
  );
}

ReportCardLost.propTypes = {
  cardStore: PropTypes.any,
  recipientAddress: PropTypes.string,
  triggerModalNeeded: PropTypes.bool,
  hideModal: PropTypes.func,
  onClick: PropTypes.func,
};

ReportCardLost.defaultProps = {
  cardStore: {},
  triggerModalNeeded: true,
  hideModal: () => {},
  onClick: () => {},
};

export default ReportCardLost;
