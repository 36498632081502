import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Text, VSpacer } from '@mqd/volt-base';

import NotificationSection from './NotificationSection';
import EmptyState from './EmptyState';

import { splitDateSections } from './utils';
import { DATE_LABELS } from './constants';

import s from './NotificationList.module.css';

const NotificationList = ({
  notifications,
  saveNotifications,
  removeNotificationHandler,
  removeNotificationSectionHandler,
}) => {
  const [notificationSections, setNotificationSections] = useState([]);

  useEffect(() => {
    if (notifications && notifications.length > 0) {
      setNotificationSections(splitDateSections(notifications));
    }
  }, [notifications]);

  const mapNotifications = (notificationsToMap) => {
    const [today, yesterday, thisWeek, earlier] = splitDateSections(notificationsToMap);

    return (
      <>
        <NotificationSection
          id="notification_section-1"
          notifications={today}
          label={DATE_LABELS.TODAY}
          removeNotification={removeNotification}
          removeNotificationSection={removeNotificationSection}
          markNotificationAsRead={markNotificationAsRead}
        />
        <NotificationSection
          id="notification_section-2"
          notifications={yesterday}
          label={DATE_LABELS.YESTERDAY}
          removeNotification={removeNotification}
          removeNotificationSection={removeNotificationSection}
          markNotificationAsRead={markNotificationAsRead}
        />
        <NotificationSection
          id="notification_section-3"
          notifications={thisWeek}
          label={DATE_LABELS.THIS_WEEK}
          removeNotification={removeNotification}
          removeNotificationSection={removeNotificationSection}
          markNotificationAsRead={markNotificationAsRead}
        />
        <NotificationSection
          id="notification_section-4"
          notifications={earlier}
          label={DATE_LABELS.EARLIER}
          removeNotification={removeNotification}
          removeNotificationSection={removeNotificationSection}
          markNotificationAsRead={markNotificationAsRead}
        />
      </>
    );
  };

  const endOfNotificationListBlock = () => {
    const endBlockHeight = 48;
    const list = document.getElementById('notification-list');

    const sectionHeightSum = notificationSections.reduce((acc, cur, i) => {
      if (cur.length) {
        const elem = document.getElementById(`notification_section-${i + 1}`);

        if (elem) {
          return acc + elem.clientHeight;
        }
      }

      return acc;
    }, 0);

    if (list && list.clientHeight >= sectionHeightSum + endBlockHeight) {
      return (
        <div style={{ height: endBlockHeight }}>
          <VSpacer factor={2} />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className={s.notifications__endOfList}>
              <Text>You’re all caught up!</Text>
            </div>
          </div>
        </div>
      );
    }
  };

  const markNotificationAsRead = (e, token) => {
    e.stopPropagation();

    if (token && notifications.length > 0) {
      const mappedNotifications = notifications.map((n) => {
        if (n.token === token) {
          return {
            ...n,
            isRead: true,
          };
        }

        return n;
      });

      saveNotifications(mappedNotifications);
    }
  };

  const removeNotification = (e, token) => {
    e.stopPropagation();
    if (token && notifications.length > 0) {
      const filteredNotifications = notifications.filter((n) => n.token !== token);

      removeNotificationHandler(token);
      saveNotifications(filteredNotifications);
    }
  };

  const filterAndChangeNotificationsSection = (notifications, sectionNotifications) => {
    const filteredNotifications = notifications.filter(
      (notification) =>
        !sectionNotifications.some(
          (sectionNotification) => sectionNotification.token === notification.token
        )
    );

    removeNotificationSectionHandler(sectionNotifications);
    saveNotifications(filteredNotifications);
    setNotificationSections(splitDateSections(filteredNotifications));
  };

  const removeNotificationSection = (label) => {
    const [today, yesterday, thisWeek, earlier] = notificationSections;

    switch (label) {
      case 'Today':
        filterAndChangeNotificationsSection(notifications, today);
        break;
      case 'Yesterday':
        filterAndChangeNotificationsSection(notifications, yesterday);
        break;
      case 'This week':
        filterAndChangeNotificationsSection(notifications, thisWeek);
        break;
      case 'Earlier':
        filterAndChangeNotificationsSection(notifications, earlier);
        break;
      default:
        break;
    }
  };

  if (!notifications || notifications.length === 0) return <EmptyState />;

  const getElemAndHeight = (id) => {
    const elem = document.getElementById(id);

    if (!elem) return null;

    return {
      elem,
      top: elem.offsetTop,
    };
  };

  const removeAdditionStyles = (existingSections) => {
    if (existingSections && existingSections.length) {
      existingSections.forEach((ex) => {
        const sectionHeaderStyle = ex.elem.firstElementChild.style;
        const sectionHRStyle = ex.elem.firstElementChild.nextElementSibling.style;

        if (sectionHeaderStyle.boxShadow) {
          sectionHeaderStyle.boxShadow = 'none';
          sectionHRStyle.height = '1px';
        }
      });
    }
  };

  const onScrollHandler = (e) => {
    const scrollPosition = e.target.scrollTop;

    const [section1, section2, section3, section4] = new Array(4)
      .fill('notification_section-')
      .map((cur, i) => getElemAndHeight(cur + (i + 1)));

    const existingSections = [section1, section2, section3, section4]
      .filter((s) => s)
      .sort((a, b) => a.top > b.top);

    const elementToMark = existingSections.find((section, i, arr) => {
      const nextSection = arr[i + 1];

      return scrollPosition > section.top && (nextSection ? scrollPosition < arr[i + 1].top : true);
    });

    if (elementToMark) {
      removeAdditionStyles(existingSections);

      elementToMark.elem.firstElementChild.style.boxShadow =
        '0px 0px 1px rgba(25, 25, 25, 0.2), 0px 0px 3px rgba(25, 25, 25, 0.12), 0px 6px 8px rgba(25, 25, 25, 0.08)';
      elementToMark.elem.firstElementChild.nextElementSibling.style.height = 0;
    } else {
      removeAdditionStyles(existingSections);
    }
  };

  return (
    <div id="notification-list" className={s.notification__list} onScroll={onScrollHandler}>
      {mapNotifications(notifications)}
      {endOfNotificationListBlock()}
    </div>
  );
};

NotificationList.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      content: PropTypes.string,
    })
  ),
  saveNotifications: PropTypes.func,
  removeNotificationHandler: PropTypes.func,
  removeNotificationSectionHandler: PropTypes.func,
};

NotificationList.defaultProps = {
  notifications: [],
  saveNotifications: () => {},
  removeNotificationHandler: () => {},
  removeNotificationSectionHandler: () => {},
};

export default NotificationList;
