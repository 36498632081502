import React from 'react';
import { Colors, Flex, HSpacer, Text } from '@mqd/volt-base';
import { EXCEPTION_STATUSES } from '../../utilities/dictionary/index.js';

const colorMap = {
  ADJUSTMENT_FAILED_RECONCILIATION: Colors.statusErrorColor,
  FAILED_BATCH_SUBMISSION: Colors.statusErrorColor,
  FAILED_BATCH_APPROVAL: Colors.statusErrorColor,
  ADJUSTMENT_PENDING_APPROVAL: Colors.statusWarningColor,
  AWAITING_ACTION: Colors.statusSuccessColor,
  ADJUSTMENT_REJECTED: Colors.statusErrorColor,
};

const StatusIndicator = ({ status, labelledBy }) => {
  const text = EXCEPTION_STATUSES[status] || '';
  return (
    <Flex alignItems="center">
      <div
        style={{
          height: 8,
          width: 8,
          borderRadius: 12,
          backgroundColor: colorMap[status],
        }}
      />
      <HSpacer />
      <Text labelledBy={labelledBy}>{text}</Text>
    </Flex>
  );
};

export default StatusIndicator;
