// @flow
import { observable, decorate } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';
import WalletProviderRiskAssessmentStore from './WalletProviderRiskAssessmentStore';

class WalletProviderProfileStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
    this.loadAndConstructItem(
      'risk_assessment',
      args.risk_assessment,
      WalletProviderRiskAssessmentStore
    );
  }
  reason_code: string = '';
  pan_source: string = '';
  risk_assessment: WalletProviderRiskAssessmentStore = {};
}

decorate(WalletProviderRiskAssessmentStore, {
  reason_code: observable,
  pan_source: observable,
  risk_assessment: observable,
});

export default WalletProviderProfileStore;
