// @flow
import { decorate, observable, action, runInAction, computed } from 'mobx';
import axios from 'axios';
import { ParentStore } from '@mq/voltron-parent';

class BulkUploadStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
  }

  // values
  bfpInstructions: string =
    'https://amc-static-assets-prod.s3.amazonaws.com/MarqetaBulkUploadTemplateInstructions.pdf';
  bfpTemplate: string =
    'https://amc-static-assets-prod.s3.amazonaws.com/MarqetaBulkUploadTemplate.csv';
  file: object = {};
  loading: Boolean = false;

  async hydrate() {
    // nothing to hydrate
  }

  async getUploadBFPSignedUrl(key) {
    const result = await this.gqlQuery(
      `
        query getUploadBFPSignedUrl(
          $key: String!
          $description: String
        ) {
          uploadBFPSignedUrl(
            Key: $key
            description: $description
          )
        }        
      `,
      {
        key,
        // TODO: description is avaliable but there is no design for this
        // https://marqeta.atlassian.net/browse/PS-10459
        // description: 'test description'
      }
    );

    return this.dig(result, 'data', 'uploadBFPSignedUrl');
  }

  async uploadBFPFile(successCb) {
    try {
      this.loading = true;
      const signedUrl = await this.getUploadBFPSignedUrl(this.file.name.split('.')[0]);
      const options = {
        headers: {
          'Content-Type': 'binary/octet-stream',
        },
      };
      await axios.put(signedUrl, this.file, options);
      successCb();
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }
  }
}

decorate(BulkUploadStore, {
  // observables
  bfpInstructions: observable,
  bfpTemplate: observable,
  file: observable,
  loading: observable,

  // actions
  getUploadBFPSignedUrl: action.bound,
  uploadBFPFile: action.bound,
});

export default BulkUploadStore;
