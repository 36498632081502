import React from 'react';
import { Text, HSpacer, VSpacer, Input, Select } from '@mqd/volt-base';
import { getMultiFieldStatus } from '../../helpers';
import s from './KYBCard.module.css';
import IconInput from './IconInput';
import StatusIcon from './StatusIcon';
import PropTypes from 'prop-types';
import { WORLD_COUNTRIES } from './../../../shared-utils/constants';

class AddressRow extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      status: 'PENDING',
    };
    this.changeStatus = this.changeStatus.bind(this);
  }

  componentDidUpdate() {
    const status = getMultiFieldStatus(this.props.addressObject);
    this.setState({ status: status });
  }

  changeStatus(field, statusType) {
    const { addressObject, onChangeStatus } = this.props;
    Object.keys(addressObject).forEach((key) => {
      onChangeStatus(key, statusType);
    });
  }

  render() {
    const { addressObject, editActive, onChange, userStore = {}, kybCustomerCanWrite } = this.props;
    const hasStatusEditRights = userStore.kybCanWrite;
    const showInput = editActive && (hasStatusEditRights || kybCustomerCanWrite);
    return (
      <div data-testid="KYB-address-row">
        <Text type="h6">Headquarters Address</Text>
        <VSpacer />
        {showInput ? (
          <div className={s.editActive} data-testid="KYB-address-row-edit-active">
            <Input
              placeholder="Street"
              value={addressObject.address.value}
              onChange={(e) => onChange('address', e.target.value)}
              width="100%"
              testId="KYB-address-row-input"
            />
            {hasStatusEditRights && (
              <IconInput
                onChangeStatus={this.changeStatus}
                field={'address'}
                status={this.state.status}
              />
            )}
          </div>
        ) : (
          <div className={s.notEditActive} data-testid="KYB-address-row-edit-not-active">
            <Text testId="KYB-address-row-one">{addressObject.address.value}</Text>
          </div>
        )}
        {showInput ? (
          <>
            <VSpacer />
            <div className={s.editSubActive}>
              <Input
                placeholder="Address 2"
                value={addressObject.address2.value}
                onChange={(e) => onChange('address2', e.target.value)}
                width="100%"
              />
            </div>
            <VSpacer />
            <div className={s.editSubActive}>
              <Input
                placeholder="City"
                value={addressObject.city.value}
                onChange={(e) => onChange('city', e.target.value)}
                width="100%"
              />
            </div>

            <VSpacer />
            <div className={s.editSubActive}>
              <Input
                placeholder="State"
                value={addressObject.state.value}
                onChange={(e) => onChange('state', e.target.value)}
                maxLength={35}
                width="100%"
              />
            </div>

            <VSpacer />
            <div className={s.editSubActive}>
              <Input
                placeholder="Zip"
                value={addressObject.zip.value}
                onChange={(e) => onChange('zip', e.target.value)}
                width="100%"
              />
            </div>
            <VSpacer />
            <div className={s.editSubActive}>
              <Select
                placeholder="Country"
                value={addressObject.country.value}
                onChange={(val) => onChange('country', val.val)}
                width="100%"
                options={WORLD_COUNTRIES}
              />
            </div>
          </>
        ) : (
          <>
            <div className={s.notEditActive}>
              <Text testId="KYB-address-row-two" inline={false}>
                {addressObject.address2.value}
              </Text>
            </div>
            <div className={s.notEditActive}>
              <Text testId="KYB-address-row-csz" inline={false}>
                {addressObject.city.value}, {addressObject.state.value} {addressObject.zip.value}
              </Text>
            </div>
            <div className={s.notEditActive}>
              <Text testId="KYB-address-row-country">{addressObject.country.value}</Text>
              <HSpacer />
              <StatusIcon status={this.state.status} />
            </div>
          </>
        )}
      </div>
    );
  }
}

AddressRow.propTypes = {
  addressObject: PropTypes.object,
  editActive: PropTypes.bool,
  onChange: PropTypes.func,
  onChangeStatus: PropTypes.func,
  userStore: PropTypes.any,
  kybCustomerCanWrite: PropTypes.bool,
};

AddressRow.defaultProps = {
  addressObject: {},
  editActive: false,
  onChange: () => {},
  onChangeStatus: () => {},
  userStore: {},
  kybCustomerCanWrite: false,
};

export default AddressRow;
