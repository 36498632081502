/**
  This function helps convert an array into a map for better lookup 
  Params: 
    array: 
      an array of items 
    key: 
      a string value that uses a key within each item of the array to become the key of the object in the converted object
 */
const convertArrayToObject = (array, key) => {
  const map = {};
  array &&
    array.forEach((obj) => {
      const targetKey = obj[key];
      map[targetKey] = obj;
    });

  return map;
};

/**
  This function will try to find a matching object in an array of objects and update it's values with a new entry
  It will return a new array with updated values
  You can also add optional new key/values to that item in the array
  Params:
    array: 
      an array of items
    updatedObject:
      an object to update the array entry with
    matchFn:
      a function that determines if it's a match for update. It accepts 2 params, original object and update object.
      This function should return a boolean
    optionalObject:
      this param is optional, you can add 
 */
const updateObjectsArrayByfunction = (array, updatedObject, matchFn, optionalObject = {}) => {
  return array.map((item) => {
    if (matchFn(item, updatedObject)) {
      return {
        ...item,
        ...updatedObject,
        ...optionalObject,
      };
    }

    return item;
  });
};

export { convertArrayToObject, updateObjectsArrayByfunction };
