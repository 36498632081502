import currentUserStore from 'stores/CurrentUserStore';
import {
  INVOKE_COMMANDO_MODE_VIEW,
  INVOKE_COMMANDO_MODE_VIEW_AND_EDIT,
} from '../../../views/admin/uam-granular-permissions/constants.js';

import {
  hasTokenizationManagerPermissions,
  hasDeveloperRole,
  hasNoRoles,
  isJanusUser,
} from './functions.js';

export const requirements = {
  requiredGranularPermissions: [INVOKE_COMMANDO_MODE_VIEW, INVOKE_COMMANDO_MODE_VIEW_AND_EDIT],
};

export const verifier = () => {
  const { userStore = {} } = currentUserStore;
  const { unifyActive, canViewCommandoMode, uamGranularPermissionsActive } = userStore;

  return uamGranularPermissionsActive
    ? canViewCommandoMode
    : hasTokenizationManagerPermissions(userStore, 'program/commando-modes') ||
        (unifyActive &&
          ((!hasNoRoles(userStore) && !hasDeveloperRole(userStore)) || isJanusUser(userStore)));
};
