import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Colors,
  Flex,
  FileUpload,
  HSpacer,
  Icon,
  LoadingOverlay,
  Modal,
  RawDropdown,
  Text,
  VSpacer,
} from '@mqd/volt-base';
import s from './DocumentManager.module.css';
class DocumentManager extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      files: [],
      selectedFile: {},
      widthAdj: 0,
      hoverState: false,
      showDeleteModal: false,
      toDelete: '',
    };

    this.handleFileDrop = this.handleFileDrop.bind(this);
    this.renderScreen = this.renderScreen.bind(this);
    this.individualFiles = this.individualFiles.bind(this);
    this.handleIndividualFile = this.handleIndividualFile.bind(this);
  }

  renderDefaultScreen() {
    const { loadingReviews } = this.props;
    return (
      <LoadingOverlay active={loadingReviews} size={26}>
        <div className={s.dashedBox} data-testid="docman-default-screen">
          <div className={s.emptyImage}>
            <Icon type="thumbnail" noHoverEffects={true} />
          </div>
          <VSpacer factor={2} />
          <Text type="body-lg">Add related files</Text>
          <VSpacer factor={0.5} />
          <Text type="body-sm">
            Maximum file size is 10MB
            <br />
            Maximum number of files is 100
            <br />
            Accepted file types: PNG, JPG, PDF
          </Text>
          <VSpacer factor={2} />
          <Flex alignItems="center" flexDirection="row" justifyContent="center">
            {this.renderFileUpload()}
          </Flex>
        </div>
      </LoadingOverlay>
    );
  }

  handleFileDrop(_ev) {
    if (!this.props.canEditReview) return;

    _ev.preventDefault();
    this.setState({ loading: true, hoverState: false });
    const newFiles = [];
    const acceptedTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
    const { uploadFiles } = this.props;
    const items = _ev.dataTransfer.items;

    if (items) {
      // https://vikky.dev/dev-tools-drop-event-bug
      for (let i = 0; i < _ev.dataTransfer.items.length; i++) {
        const file = _ev.dataTransfer.items[i];
        if (file.kind === 'file' && acceptedTypes.includes(file.type)) {
          newFiles.push(file.getAsFile());
        } else {
          newFiles.push(_ev.dataTransfer.files[i].name);
        }
      }
    }

    uploadFiles(newFiles);
    this.setState({ loading: false });
  }

  handleIndividualFile(file) {
    const { uploadFiles } = this.props;
    uploadFiles(file);
  }

  renderSelectedFile() {
    const file =
      Object.keys(this.state.selectedFile).length > 0
        ? this.state.selectedFile
        : this.props.documents[0];
    const fileURL = file.link;
    const currWidthAdj = this.state.widthAdj;
    const imgWidth = 100 + currWidthAdj * 5 + '%';

    if (file.type === 'application/pdf') {
      return (
        <div className={s.fileBox}>
          <iframe
            id="viewer"
            frameborder="0"
            scrolling="no"
            width="100%"
            height="424"
            src={fileURL}
            data-testid={`docman-selected-pdf-${file.name}`}
          ></iframe>
        </div>
      );
    } else {
      return (
        <div className={s.fileBox}>
          <div className={s.imageHolder}>
            <img
              className={s.uploadedImage}
              src={fileURL}
              width={imgWidth}
              onDragStart={() => {
                return false;
              }}
              draggable={false}
              data-testid={`docman-selected-img-${file.name}`}
            />
          </div>
          <div className={s.imageModifiers}>
            <Button
              className={s.modifierSquare}
              onClick={(e) => this.setState({ widthAdj: currWidthAdj + 1 })}
              type="tertiary"
            >
              <Icon type="plus24" noHoverEffects={true} />
            </Button>
            <HSpacer factor={2} />
            <Button
              className={s.modifierSquare}
              onClick={(e) => this.setState({ widthAdj: currWidthAdj - 1 })}
              type="tertiary"
            >
              <Icon type="checkmark-mixed" noHoverEffects={true} factor={2} />
            </Button>
          </div>
        </div>
      );
    }
  }

  renderScreen() {
    const { loadingReviews, documents } = this.props;
    if (this.state.hoverState) {
      return (
        <div className={s.hoverBox}>
          <Icon type="plus" noHoverEffects={true} />
        </div>
      );
    }
    return documents.length > 0 ? this.renderSelectedFile() : this.renderDefaultScreen();
  }

  renderFiles() {
    const { missingNewDocument, loadingReviews } = this.props;
    return (
      <LoadingOverlay active={loadingReviews}>
        {missingNewDocument ? (
          <div className={s.filesPending}>
            <Text type="body-sm">Uploaded file is still processing.</Text>
            <Text type="body-sm">Please check again later.</Text>
          </div>
        ) : (
          <>
            <div className={s.fileManager}>
              <div className={s.fileName}>
                <Text type="h6">File name</Text>
              </div>
              <div className={s.fileSize}>
                <Text type="h6">File size</Text>
              </div>
              <div className={s.fileDate}>
                <Text type="h6">Date uploaded</Text>
              </div>
            </div>
            {this.individualFiles()}
            <VSpacer factor={3} />
            {this.renderFileUpload()}
          </>
        )}
      </LoadingOverlay>
    );
  }

  downloadDocument(fileLink, fileName = 'Review Document') {
    var tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = fileLink;
    tempLink.setAttribute('download', fileName);

    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
      tempLink.setAttribute('rel', 'noopener noreferrer');
    }
    if (document && document.body) {
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
    }
  }

  individualFiles() {
    const { documents = [], canEditReview } = this.props;
    const selectedFileToken =
      Object.keys(this.state.selectedFile).length > 0
        ? this.state.selectedFile.token
        : this.props.documents[0].token;

    return documents.map((file, i) => {
      const bgColor = selectedFileToken === file.token ? Colors.actionColorLighter2 : Colors.white;
      const created = new Date(file.created_time);
      const documentToken = file.token;

      return (
        <div
          key={i}
          className={s.fileManager}
          style={{ cursor: 'pointer', backgroundColor: bgColor }}
          onClick={(e) => this.setState({ selectedFile: file, widthAdj: 0 })}
          data-testid={`docman-file-${documentToken}`}
        >
          <div className={s.fileName}>
            <Text type="body-sm" className={s.ellipsisOverflow}>
              {file.name}
            </Text>
          </div>
          <div className={s.fileSize}>
            <Text type="body-sm">{this.fancyFileSize(file.size)}</Text>
          </div>
          {!this.state.loading && (
            <>
              <div className={s.fileDate}>
                <Text className={s.ellipsisOverflow} type="body-sm">
                  {created.toDateString()} {created.toLocaleTimeString('en-US')}
                </Text>
              </div>
              <div className={s.fileControl}>
                <RawDropdown
                  anchor="right"
                  control={<Icon type="view-more-vertical-big" noHoverEffects={true} />}
                  contentContainerStyle={{
                    maxWidth: '134px',
                    padding: '0',
                  }}
                >
                  <div
                    style={{ padding: '8px' }}
                    onClick={() => this.downloadDocument(file.link, file.name)}
                  >
                    <Text>Download</Text>
                  </div>
                  {canEditReview && (
                    <div
                      style={{ padding: '8px' }}
                      onClick={() => {
                        this.setState({ toDelete: documentToken, showDeleteModal: true });
                      }}
                      data-testid={`docman-file-${documentToken}-delete`}
                    >
                      <Text>Delete</Text>
                    </div>
                  )}
                </RawDropdown>
              </div>
            </>
          )}
        </div>
      );
    });
  }

  fancyFileSize(fileSize) {
    if (fileSize === 0) return '0 b';
    const labels = [' b', ' kb', ' mb'];
    const i = Math.floor(Math.log(fileSize) / Math.log(1024));

    return parseFloat((fileSize / Math.pow(1024, i)).toFixed(2)) + labels[i];
  }

  renderFileUpload() {
    const { canEditReview } = this.props;

    return (
      <FileUpload
        acceptedFileTypes=".png,.PNG,.jpg,.JPG,.jpeg,.pdf,.PDF"
        handleFiles={this.handleIndividualFile}
      >
        <Button
          disabled={!canEditReview}
          type="tertiary"
          style={{ marginLeft: 'auto', marginRight: 'auto' }}
          testId="document-manager-upload-button"
        >
          Upload files
        </Button>
      </FileUpload>
    );
  }

  renderDeleteModal() {
    return (
      <Modal
        type="sm"
        heading="Delete file"
        description="Would you like to delete this file? This action cannot be undone."
        hideModal={() => this.setState({ showDeleteModal: false })}
        hideModalButtonText="Cancel"
        showHideModalButton={true}
        footerButtons={[
          <Button
            type="danger"
            onClick={() => {
              this.deleteFile();
            }}
            testId="docman-delete-modal-delete-button"
          >
            Delete file
          </Button>,
        ]}
      />
    );
  }

  async deleteFile() {
    const { deleteFile } = this.props;
    await deleteFile(this.state.toDelete);
    this.setState({ toDelete: '', showDeleteModal: false });
  }

  render() {
    const { width, documents, canEditReview } = this.props;
    return (
      <div
        className={s.documentManager}
        style={{ width: width }}
        onDragOver={(e) => {
          e.stopPropagation();
          e.preventDefault();
          if (!canEditReview) {
            this.setState({ hoverState: true });
          }
        }}
        onDragLeave={(e) => {
          e.stopPropagation();
          e.preventDefault();
          this.setState({ hoverState: false });
        }}
        onDrop={(file) => this.handleFileDrop(file)}
      >
        {this.renderScreen()}
        {documents.length > 0 && this.renderFiles()}
        {this.state.showDeleteModal && this.renderDeleteModal()}
      </div>
    );
  }
}

DocumentManager.propTypes = {
  canEditReview: PropTypes.bool,
  deleteFile: PropTypes.func,
  documents: PropTypes.array,
  loadingReviews: PropTypes.bool,
  missingNewDocument: PropTypes.bool,
  uploadFiles: PropTypes.func,
  width: PropTypes.string,
};

DocumentManager.defaultProps = {
  canEditReview: false,
  deleteFile: () => {},
  documents: [],
  loadingReviews: false,
  missingNewDocument: false,
  uploadFiles: () => {},
  width: '100%',
};

export default DocumentManager;
