// @flow
import { decorate, observable, computed, action, runInAction } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';
import gqlUtils from '@mqd/graphql-utils';
import IdentificationStore from './IdentificationStore';
import FundingSourceStore from './FundingSourceStore';
import VelocityControlStore from './VelocityControlStore';
import AuthControlStore from './AuthControlStore';
import GpaBalanceStore from './GpaBalanceStore';
import { reasonCodes } from '../constants';
import { findNewestKyc, getCardOrCardholderQuery } from '../shared-utils/index';
import * as qualtrics from 'utils/qualtrics';

const { fragments } = gqlUtils;

class CardholderStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
  }
  // values
  // customer info
  account_holder_group_token: string = '';
  active: Boolean = false;
  address1: string = '';
  address2: string = '';
  birth_date: string = '';
  business_token: string = '';
  city: string = '';
  company: string = '';
  corporate_card_holder: Boolean = false;
  country: string = '';
  created_time: string = '';
  email: string = '';
  first_name: string = '';
  fullKycResults: object = null;
  gender: string = '';
  honorific: string = '';
  id_card_expiration_date: string = '';
  id_card_number: string = '';
  ip_address: string = '';
  kycMicroserviceEnabled: boolean = false;
  kycResults: object = null;
  last_modified_time: string = '';
  last_name: string = '';
  metadata: string = '';
  middle_name: string = '';
  nationality: string = '';
  notes_string: string = '';
  parent_token: string = '';
  passport_expiration_date: string = '';
  passport_number: string = '';
  phone: string = '';
  postal_code: string = '';
  reviewToken: string = '';
  ssn: string = '';
  state: string = '';
  status: string = '';
  token: string = '';
  uses_parent_account: Boolean = false;
  // objects
  auth_controls: Array<AuthControlStore> = [];
  funding_sources: Array<FundingSourceStore> = [];
  gpa_balance: GpaBalanceStore = {};
  identifications: Array<IdentificationStore> = [];
  velocity_controls: Array<VelocityControlStore> = [];

  loading: boolean = false;

  reasonCodes = reasonCodes;

  get fundingSourcesNames() {
    return this.funding_sources && Array.isArray(this.funding_sources)
      ? this.funding_sources.map(({ name_on_account }) => name_on_account)
      : [];
  }

  get identificationIsEdit() {
    return this.identifications && this.identifications.length > 0;
  }

  // identification functions
  hideIdentification(type) {
    const targetIdentification = this.identifications.find(
      (identification) => identification.type === type
    );
    targetIdentification.value = '********';
  }

  async revealIdentification(type) {
    const payload = {
      token: this.token,
      full_ssn: true,
      type,
    };
    const targetIdentification = this.identifications.find(
      (identification) => identification.type === type
    );
    try {
      targetIdentification.loading = true;
      targetIdentification.error = null;
      const result = await this.getIdentification(payload);
      const lowerCaseType = type.toLowerCase();
      const fullIdentificationValue = result.data.fullCardholderIdentificationNumber[lowerCaseType];
      targetIdentification.value = fullIdentificationValue;
    } catch (e) {
      console.error(e);
      targetIdentification.error = e;
    } finally {
      targetIdentification.loading = false;
    }
  }

  get ssnWithStars() {
    try {
      return this.ssn.replace(/_/g, '*');
    } catch (e) {
      ('');
    }
  }

  hideV1Identification() {
    this.ssn = '********';
  }

  get kycDetails() {
    return this.latestKycResult;
  }

  get latestKycResult() {
    return findNewestKyc(this.fullKycResults);
  }

  async revealV1Identification() {
    const payload = {
      token: this.token,
      full_ssn: true,
      type: 'ssn',
    };

    try {
      this.loading = true;
      const result = await this.getIdentification(payload);
      this.ssn = result.data.fullCardholderIdentificationNumber.ssn;
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  async getIdentification(payload) {
    const result = await this.gqlQuery(
      `
        query fullCardholderIdentificationNumber (
          $token: ID!
          $type: String!
          $full_ssn: Boolean!
        ) {
          fullCardholderIdentificationNumber (
            token: $token
            type: $type
            full_ssn: $full_ssn
          ) {
            ssn
            tin
            nin
            sin
          }
        }
      `,
      payload
    );
    return result;
  }

  createTransitionAllowedRoles = [
    'access-manager',
    'cardholder-support',
    'compliance-internal',
    'compliance-processor-only',
    'delivery-internal',
    'marqeta-admin-internal',
    'production-support-internal',
    'program-admin',
    'risk-internal',
    'supplier-payments-manager',
  ];

  manualOverrideRoles = [
    'compliance-internal',
    'risk-internal',
    'aux-compliance-internal',
    'aux-risk-internal',
  ];

  createReviewRoles = [
    'cardholder-support',
    'compliance-internal',
    'compliance-processor-only',
    'compliance-program-managed',
    'risk-internal',
    'aux-compliance-internal',
    'aux-risk-internal',
    'production-support-internal',
  ];

  get availableStatuses() {
    const possibleTransitions = {
      LIMITED: ['Active', 'Suspended', 'Closed'],
      ACTIVE: ['Suspended', 'Closed'],
      UNVERIFIED: ['Active', 'Closed'],
      SUSPENDED: ['Active', 'Limited', 'Unverified', 'Closed'],
      CLOSED: ['Active', 'Limited', 'Unverified', 'Suspended'],
    };

    return possibleTransitions[this.status] || [];
  }

  async changeStatus(params) {
    const payload = {
      cardholder_token: this.token,
      channel: 'ADMIN',
      ...params,
    };

    const result = await this.gqlMutation(
      `mutation createCardholderTransition (
          $token: ID
          $cardholder_token: ID!
          $status: String!
          $reason_code: String!
          $reason: String
          $channel: String!
        ) {
          createCardholderTransition(
            token: $token
            cardholder_token: $cardholder_token
            status: $status
            reason_code: $reason_code
            reason: $reason
            channel: $channel
          ){
            ...cardholderTransitionInfo
          }
        }

        ${fragments.cardholderTransitionInfo}
      `,
      payload
    );

    if (!result) throw 'User transition Failed!';
    return runInAction(async () => {
      await this.hydrate(this.token);
      const newStatus = this.dig(result, 'data', 'createCardholderTransition', 'status');
      qualtrics.track(qualtrics.EVENTS.USER_STATUS_CHANGED);
      return `Successfully updated user status to ${newStatus}`;
    });
  }

  // actions
  async addNote(cardholderToken, description, isPrivate, activeUserInfo) {
    const { email, userRole } = activeUserInfo;
    if (!email || !userRole)
      throw new Error(
        'Error: user must be logged in to create a note (undefined user email and role).'
      );
    const paramsInfo = {
      token: {
        type: 'String!',
        val: cardholderToken,
      },
      description: {
        type: 'String',
        val: description,
      },
      private: {
        type: 'Boolean',
        val: isPrivate,
      },
      created_by: {
        type: 'String',
        val: email,
      },
      created_by_user_role: {
        type: 'String',
        val: userRole.toUpperCase(),
      },
    };

    const result = await this.gqlMutation(
      `
        mutation createCardholderNote(${this.configureOuterQueryParams(paramsInfo)}) {
          createCardholderNote(${this.configureInnerQueryParams(paramsInfo)}) {
            ...noteBaseInfo
          }
        }
        ${fragments.noteBaseInfo}
      `,
      this.configureQueryParams(paramsInfo)
    );

    if (result?.data) {
      qualtrics.track(qualtrics.EVENTS.USER_NOTE_ADDED);
    }

    return result;
  }

  async performManualOverride({ manualOverride, notes }) {
    const paramsInfo = {
      manual_override: { type: 'Boolean!' },
      notes: { type: 'String' },
      user_token: { type: 'String!' },
    };
    const result = await this.gqlMutation(
      `
      mutation performKYC(${this.configureOuterQueryParams(paramsInfo)}) {
        performKYC(${this.configureInnerQueryParams(paramsInfo)}) {
          token
        }
      }
      `,
      {
        manual_override: manualOverride,
        notes: notes,
        user_token: this.token,
      }
    );

    if (!result) {
      throw new Error('Manual override failed!');
    }

    return runInAction(async () => {
      await this.hydrate(this.token);
    });
  }

  async createKycReview() {
    try {
      const paramsInfo = {
        user_token: { type: 'String!' },
      };
      const result = await this.gqlMutation(
        `
        mutation createKycReview(${this.configureOuterQueryParams(paramsInfo)}) {
          createKycReview(${this.configureInnerQueryParams(paramsInfo)}) {
            token
          }
        }
        `,
        { user_token: this.token }
      );

      if (!result) {
        throw new Error('Creating review failed');
      }

      return runInAction(async () => {
        this.reviewToken = this.dig(result, 'data', 'createKycReview', 'token');
      });
    } catch (e) {
      console.error(e);
    }
  }

  async hydrate(token) {
    if (this.detailHydrated) return;
    try {
      this.loading = true;
      const tokenParam = token || this.token;
      const result = await this.gqlQuery(
        `
          query ${this.cardholderQuery}${this.outerQueryParams || '($token: ID!)'} {
            ${this.cardholderQuery}${this.innerQueryParams || '(token: $token)'} {
              fullKycResults: kycResults(count: 500) {
                hasKycMicroservice
                data {
                  token
                  user_token
                  created_time
                  last_modified_time
                  manual_override
                  notes
                  result {
                    status
                    codes {
                      code
                    }
                  }
                }
              }
              ${this.fullDataQuery}
            }
          }
          ${this.fullDataFragments}
        `,
        {
          token: tokenParam,
          ...this.hydrateParams,
        }
      );
      // TODO handle errors
      runInAction(() => {
        if (result) {
          this.loadFullInfoResult(this.extract(result, this.cardholderQuery));
          this.kycMicroserviceEnabled = this.dig(
            result,
            'data',
            this.cardholderQuery,
            'fullKycResults',
            'hasKycMicroservice'
          );
          this.valuesUpdated = {};
          return true;
        } else {
          return false;
        }
      });
    } catch (e) {
      console.error('Error fetching cardholder: ', e);
    } finally {
      this.loading = false;
    }
  }

  updateCardholderAllowedRoles = [
    'compliance-internal',
    'compliance-processor-only',
    'risk-internal',
    'delivery-internal',
    'fulfillment-internal',
    'production-support-internal',
    'cardholder-support',
    'supplier-payments-manager',
    'program-admin',
    'access-manager',
    'marqeta-admin-internal',
  ];

  async updateCardholder(payload) {
    this.loading = true;
    const paramsInfo = {
      token: { type: 'ID!' },
      honorific: { type: 'String' },
      gender: { type: 'String' },
      email: { type: 'String' },
      address1: { type: 'String' },
      address2: { type: 'String' },
      city: { type: 'String' },
      state: { type: 'String' },
      postal_code: { type: 'String' },
      country: { type: 'String' },
      nationality: { type: 'String' },
      notes: { type: 'String' },
      phone: { type: 'String' },
      middle_name: { type: 'String' },
      first_name: { type: 'String' },
      last_name: { type: 'String' },
      birth_date: { type: 'String' },
      parent_token: { type: 'String' },
      ip_address: { type: 'String' },
      corporate_card_holder: { type: 'Boolean' },
      company: { type: 'String' },
      account_holder_group_token: { type: 'String' },
      passport_number: { type: 'String' },
      passport_expiration_date: { type: 'String' },
      id_card_number: { type: 'String' },
      id_card_expiration_date: { type: 'String' },
      metadata: { type: 'String' },
      ssn: { type: 'String' },
    };
    const paramsToUpdate = payload ? payload : this.updateParams;
    const result = await this.gqlMutation(
      `
      mutation updateCardholder(${this.configureOuterQueryParams(paramsInfo)}) {
        updateCardholder(${this.configureInnerQueryParams(paramsInfo)}) {
          ${this.fullDataQuery}
        }
      }
      ${this.fullDataFragments}
      `,
      {
        zip: this.updateParams.postal_code,
        ...paramsToUpdate,
      }
    );
    return runInAction(() => {
      if (result) {
        this.loadFullInfoResult(this.extract(result, 'updateCardholder'));
        this.valuesUpdated = {};
        this.loading = false;
        return true;
      } else {
        this.loading = false;
        return false;
      }
    });
  }

  async updateCardholderIdentificationsUam(payload) {
    //V1
    this.loading = true;
    const paramsInfo = {
      token: { type: 'ID!' },
      passport_number: { type: 'String' },
      passport_expiration_date: { type: 'String' },
      id_card_number: { type: 'String' },
      id_card_expiration_date: { type: 'String' },
      ssn: { type: 'String' },
    };
    const paramsToUpdate = payload ? payload : this.updateParams;
    const result = await this.gqlMutation(
      `
      mutation updateCardholderIdentifications(${this.configureOuterQueryParams(paramsInfo)}) {
        updateCardholderIdentifications(${this.configureInnerQueryParams(paramsInfo)}) {
          ${this.fullDataQuery}
        }
      }
      ${this.fullDataFragments}
      `,
      paramsToUpdate
    );
    return runInAction(() => {
      if (result) {
        this.loadFullInfoResult(this.extract(result, 'updateCardholderIdentifications'));
        this.valuesUpdated = {};
        this.loading = false;
        return true;
      } else {
        this.loading = false;
        return false;
      }
    });
  }

  loadFullInfoResult(cardholderData) {
    this.load(cardholderData);
    const { identifications, funding_sources, velocity_controls, auth_controls, gpa_balance } =
      cardholderData;
    this.loadAndConstructList('identifications', identifications, IdentificationStore);
    if (funding_sources && funding_sources.data) {
      this.loadAndConstructList('funding_sources', funding_sources.data, FundingSourceStore);
    }
    if (velocity_controls && velocity_controls.data) {
      this.loadAndConstructList('velocity_controls', velocity_controls.data, VelocityControlStore);
    }
    if (auth_controls && auth_controls.data) {
      this.loadAndConstructList('auth_controls', auth_controls.data, AuthControlStore);
    }
    this.loadAndConstructItem('gpa_balance', gpa_balance, GpaBalanceStore);
  }

  async addIdentification({ type, value, expirationDate }) {
    const paramsInfo = {
      token: { type: 'ID!' },
      identifications: { type: '[IdentificationInput]' },
    };
    const result = await this.gqlMutation(
      `
      mutation updateCardholder(${this.configureOuterQueryParams(paramsInfo)}) {
        updateCardholder(${this.configureInnerQueryParams(paramsInfo)}) {
          identifications {
            ...identificationBaseInfo
          }
        }
      }
      ${fragments.identificationBaseInfo}
      `,
      {
        token: this.token,
        identifications: [{ type, value, expiration_date: expirationDate }],
      }
    );
    return runInAction(() => {
      if (result) {
        const cardholderData = this.extract(result, 'updateCardholder');
        const { identifications } = cardholderData;
        this.loadAndConstructList('identifications', identifications, IdentificationStore);
        this.valuesUpdated = {};
        return true;
      } else {
        return false;
      }
    });
  }

  async addIdentificationUam({ type, value, expirationDate }) {
    //V2
    const paramsInfo = {
      token: { type: 'ID!' },
      identifications: { type: '[IdentificationInput]' },
    };
    const result = await this.gqlMutation(
      `
      mutation updateCardholderIdentifications(${this.configureOuterQueryParams(paramsInfo)}) {
        updateCardholderIdentifications(${this.configureInnerQueryParams(paramsInfo)}) {
          identifications {
            ...identificationBaseInfo
          }
        }
      }
      ${fragments.identificationBaseInfo}
      `,
      {
        token: this.token,
        identifications: [{ type, value, expiration_date: expirationDate }],
      }
    );
    return runInAction(() => {
      if (result) {
        const cardholderData = this.extract(result, 'updateCardholderIdentifications');
        const { identifications } = cardholderData;
        this.loadAndConstructList('identifications', identifications, IdentificationStore);
        this.valuesUpdated = {};
        return true;
      } else {
        return false;
      }
    });
  }

  async addMetadata({ key, value }) {
    const paramsInfo = {
      token: { type: 'ID!' },
      metadata: { type: 'String' },
    };
    const result = await this.gqlMutation(
      `
      mutation updateCardholder(${this.configureOuterQueryParams(paramsInfo)}) {
        updateCardholder(${this.configureInnerQueryParams(paramsInfo)}) {
          metadata
        }
      }
      `,
      {
        token: this.token,
        metadata: JSON.stringify({ [key]: value }),
      }
    );
    return runInAction(() => {
      if (result) {
        const cardholderData = this.extract(result, 'updateCardholder');
        const { metadata } = cardholderData;
        if (metadata) {
          this.metadata = metadata;
        }
        this.valuesUpdated = {};
        return true;
      } else {
        return false;
      }
    });
  }

  async getProgramFundingSources() {
    try {
      const result = await this.gqlQuery(
        `query programFundingSources {
          programFundingSources {
            data {
              name
              token
            }
          }
        }`
      );

      return this.dig(result, 'data', 'programFundingSources', 'data');
    } catch (e) {
      console.error(`CardholderStore: Unable to fetch program funding sources. Error: ${e}`);
      throw error;
    }
  }

  async addFunds({ amount, currency_code, funding_source_token }) {
    try {
      const result = await this.gqlMutation(
        `
        mutation createGpaOrder(
          $amount: Float!,
          $cardholder_token: String,
          $currency_code: String!,
          $funding_source_token: String!
        ) {
          createGpaOrder(
            amount: $amount,
            cardholder_token: $cardholder_token,
            currency_code: $currency_code,
            funding_source_token: $funding_source_token
          ) {
            cardholder {
              gpa_balance {
                gpa {
                  ...balanceBaseInfo
                }
              }
            }
          }
        }
        ${fragments.balanceBaseInfo}

        `,
        {
          amount,
          cardholder_token: this.token,
          currency_code,
          funding_source_token,
        }
      );
      if (!result) throw 'Failed to add funds';

      runInAction(() => {
        this.loadFullInfoResult({
          gpa_balance: this.dig(result, 'data', 'createGpaOrder', 'cardholder', 'gpa_balance'),
        });
        this.valuesUpdated = {};
      });
    } catch (error) {
      console.error(`CardholderStore: Unable to add funds. ${error}`);
      throw error;
    }
  }

  async getGpaOrders() {
    try {
      const result = await this.gqlQuery(
        `query transactions($type: String, $user_token: String) {
          transactions(type: $type, user_token: $user_token) {
            data {
              token
              type
              amount
              request_amount
              gpa_order {
                token
              }
            }
            count
          }
        }`,
        {
          type: 'gpa.credit',
          user_token: this.token,
        }
      );

      return this.dig(result, 'data', 'transactions', 'data');
    } catch (e) {
      console.error('CardholderStore: Unable to fetch gpa orders');
    }
  }

  async removeFunds(params) {
    const { amount, original_order_token, ...restOfParams } = params;

    try {
      const result = await this.gqlMutation(
        `
          mutation createGpaUnload (
            $amount: Float!
            $funding_source_address_token: String
            $memo: String
            $original_order_token: String!
            $tags: String
            $token: ID
          ) {
            createGpaUnload(
              amount: $amount
              funding_source_address_token: $funding_source_address_token
              memo: $memo
              original_order_token: $original_order_token
              tags: $tags
              token: $token
            ){
              token
              amount
            }
          }
        `,
        {
          amount,
          original_order_token,
          ...restOfParams,
        }
      );

      if (!result || this.error) throw this.error;
      await this.hydrate(this.token);
    } catch (error) {
      throw error;
    }
  }

  updateMetadata(key, value) {
    const metadataCopy = Object.assign({}, this.metadataObject);
    metadataCopy[key] = value ? value : null;
    this.updateForSave('metadata', JSON.stringify(metadataCopy));
  }

  // computed
  get updateParams(): Object {
    const params = { token: this.token };
    this.valuesUpdatedArray.forEach((value) => {
      // to handle the notes => notes_string rename
      if (value === 'notes_string') {
        params.notes = this.notes_string;
      } else {
        params[value] = this[value];
      }
    });
    return params;
  }

  get fullName(): string {
    if (!this.first_name && !this.middle_name && !this.last_name) return '';
    return `${this.first_name || ''} ${this.middle_name ? `${this.middle_name} ` : ''}${
      this.last_name || ''
    }`;
  }

  get metadataObject(): Object {
    if (this.metadata) {
      return JSON.parse(this.metadata);
    } else {
      return {};
    }
  }

  get hasSingleUseVelocityControl() {
    return this.velocity_controls.some((velocity) => velocity.isSingleUse);
  }

  get authFormattedMccs() {
    const authControlMccNames = this.mapMccNames(this.auth_controls);
    return this.uniqueArray(authControlMccNames);
  }

  // helpers
  get fullDataQuery() {
    return `
      ...cardholderBaseInfo
      identifications {
        ...identificationBaseInfo
      }
      velocity_controls {
        data {
          ...velocityControlBaseInfo
          association {
            ...associationBaseInfo
          }
          merchant_scope {
            ...merchantScopeBaseInfo
          }
        }
      }
      auth_controls {
        data {
          ...authControlBaseInfo
          association {
            ...associationBaseInfo
          }
          merchant_scope {
            ...merchantScopeBaseInfo
          }
        }
      }
      funding_sources {
        data {
          ...fundingSourceBaseInfo
        }
      }
      gpa_balance {
        gpa {
          ...balanceBaseInfo
        }
      }
    `;
  }

  get fullDataFragments() {
    return `
      ${fragments.cardholderBaseInfo}
      ${fragments.balanceBaseInfo}
      ${fragments.identificationBaseInfo}
      ${fragments.velocityControlBaseInfo}
      ${fragments.authControlBaseInfo}
      ${fragments.associationBaseInfo}
      ${fragments.merchantScopeBaseInfo}
      ${fragments.fundingSourceBaseInfo}
    `;
  }

  mapMccNames(controls) {
    let mccs = [];
    controls.forEach(({ merchant_scope }) => {
      const { mcc_name, mcc_group_data } = merchant_scope;
      const { mcc_names } = mcc_group_data || {};
      if (mcc_name) mccs.push(mcc_name);
      if (mcc_names) mccs = [...mccs, ...mcc_names];
    });

    return this.uniqueArray(mccs);
  }

  get shippingAddress() {
    const {
      first_name,
      middle_name,
      last_name,
      address1,
      address2,
      city,
      state,
      postal_code,
      zip,
      country,
    } = this || {};

    if (!address1) return null;

    return {
      first_name,
      middle_name,
      last_name,
      address1,
      address2,
      city,
      state,
      postal_code,
      zip,
      country,
    };
  }

  get kycData() {
    return this.dig(this.fullKycResults, 'data');
  }

  get cardholderQuery() {
    return getCardOrCardholderQuery(
      'cardholder',
      'cardholderByCardProduct',
      localStorage.getItem('userOrgName'),
      JSON.parse(localStorage.getItem('redseaRoles'))
    );
  }
}

decorate(CardholderStore, {
  // values
  // customer info
  account_holder_group_token: observable,
  active: observable,
  address1: observable,
  address2: observable,
  birth_date: observable,
  business_token: observable,
  city: observable,
  company: observable,
  corporate_card_holder: observable,
  country: observable,
  created_time: observable,
  email: observable,
  first_name: observable,
  fullKycResults: observable,
  gender: observable,
  gpa_balance: observable,
  honorific: observable,
  id_card_expiration_date: observable,
  id_card_number: observable,
  ip_address: observable,
  kycResults: observable,
  last_modified_time: observable,
  last_name: observable,
  loading: observable,
  metadata: observable,
  middle_name: observable,
  nationality: observable,
  notes_string: observable,
  parent_token: observable,
  passport_expiration_date: observable,
  passport_number: observable,
  phone: observable,
  postal_code: observable,
  reviewToken: observable,
  ssn: observable,
  state: observable,
  status: observable,
  token: observable,
  uses_parent_account: observable,

  // objects
  identifications: observable,
  funding_sources: observable,
  velocity_controls: observable,
  auth_controls: observable,

  // actions
  getProgramFundingSources: action.bound,
  addFunds: action.bound,
  createKycReview: action.bound,
  getGpaOrders: action.bound,
  removeFunds: action.bound,
  addNote: action.bound,
  hydrate: action.bound,
  loadFullInfoResult: action.bound,
  updateCardholder: action.bound,
  updateCardholderIdentificationsUam: action.bound,
  addIdentification: action.bound,
  addIdentificationUam: action.bound,
  addMetadata: action.bound,
  updateMetadata: action.bound,
  revealIdentification: action.bound,
  hideIdentification: action.bound,
  hideV1Identification: action.bound,
  revealV1Identification: action.bound,
  getIdentification: action.bound,
  mapMccNames: action.bound,
  performManualOverride: action.bound,

  // computed
  fullName: computed,
  metadataObject: computed,
  updateParams: computed,
  ssnWithStars: computed,
  identificationIsEdit: computed,
  authFormattedMccs: computed,
  fundingSourcesNames: computed,
  shippingAddress: computed,
  latestKycResult: computed,
  kycDetails: computed,
  kycData: computed,
  cardholderQuery: computed,
});

export default CardholderStore;
