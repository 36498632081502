import { decorate, observable, computed } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';

class CommandoModeTransitionStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
  }
  // values
  token: string = '';
  type: string = '';
  commando_mode_token: string = '';
  enabled: Boolean = false;
  enabled_reason: string = '';
  enabled_channel: string = '';
  enabled_username: string = '';
  created_time: string = '';
  name: string = '';

  // computed
  get enabledString(): string {
    return this.enabled ? 'Enabled' : 'Disabled';
  }
}

decorate(CommandoModeTransitionStore, {
  // values
  token: observable,
  type: observable,
  commando_mode_token: observable,
  enabled: observable,
  enabled_reason: observable,
  enabled_channel: observable,
  enabled_username: observable,
  created_time: observable,
  name: observable,

  // computed
  enabledString: computed,
});

export default CommandoModeTransitionStore;
