import { useState, useEffect } from 'react';
import { getFilterOptions, filtersIsEqual } from '../utils';
import { PAGE_SIZE } from '../constants';

export default function useFilters(props = {}) {
  const { defaultFilters = {}, pagination = {}, transactionsQueryFn } = props;
  const [filters, setFilters] = useState(defaultFilters);
  const [appliedFilters, setAppliedFilters] = useState(defaultFilters);

  // had to stringify date values as javascript Date objects are not comparable
  const defaultDateFilters = [];
  Object.keys(defaultFilters).forEach((key) => {
    if (defaultFilters[key] instanceof Date) {
      defaultDateFilters.push(defaultFilters[key].toString());
    }
  });

  // defaultFilters are updated based on data fetch
  // e.g. defaults include filtering transactions by accountholder.created_time
  useEffect(() => {
    if (!filtersIsEqual(filters, defaultFilters)) {
      setFilters(defaultFilters);
      setAppliedFilters(defaultFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...defaultDateFilters]);

  const applyFilters = (filters) => {
    const { startIndex = 0, pageSize = PAGE_SIZE } = pagination;
    transactionsQueryFn({
      variables: {
        start_index: startIndex,
        count: pageSize,
        ...filters,
      },
    });
    setAppliedFilters(filters);
  };

  const handleSearch = () => {
    const filtersToApply = {};
    Object.keys(filters).forEach((key) => {
      if (filters[key]) filtersToApply[key] = filters[key];
    });
    applyFilters(filtersToApply);
  };

  const handleClear = () => {
    setFilters(defaultFilters);
    applyFilters(defaultFilters);
  };

  const handleDateRangeChange = (from, to) =>
    setFilters({ ...filters, start_date: from, end_date: to });

  const handleDateRangeClear = () => {
    const newFilters = {
      ...filters,
      start_date: defaultFilters.start_date,
      end_date: defaultFilters.end_date,
    };

    setFilters(newFilters);
    applyFilters(newFilters);
  };

  return {
    filters,
    filterOptions: getFilterOptions(filters, setFilters),
    handleSearch,
    handleClear,
    handleDateRangeChange,
    handleDateRangeClear,
    appliedFilters,
  };
}
