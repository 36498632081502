import React from 'react';
import { observer, PropTypes } from 'mobx-react';
import { VSpacer, Container, Row, Col } from '@mqd/volt-base';
import KYBCard from './card/KYBCard';
import s from './Body.module.css';
import KYBBanner from '../../shared-components/kyb-banner/KYBBanner';

class Body extends React.Component {
  mapOverEntities(side, entities) {
    const { userStore, updateBusinessAllowedRoles, kybStore, hydrateBusinessStore } = this.props;
    return entities.map((entity, i) => {
      const [title, entityFields] = entity;
      return (
        <React.Fragment key={`${side}-card-${i}`}>
          <KYBCard
            updateBusinessAllowedRoles={updateBusinessAllowedRoles}
            userStore={userStore}
            kybStore={kybStore}
            entity={entityFields}
            title={title}
            hydrateBusinessStore={hydrateBusinessStore}
          />
          {i !== entities.length - 1 ? <VSpacer factor={3} /> : null}
        </React.Fragment>
      );
    });
  }

  showBanner() {
    const { businessEntities = {}, kycManualOverride, vendorPending } = this.props;
    if (!kycManualOverride) {
      return <KYBBanner type={businessEntities.status} vendorPending={vendorPending} />;
    }
    return null;
  }

  render() {
    const businessEntities = this.props.businessEntities || {};
    const isSoleProp = this.props.isSoleProp || false;
    const business_name_dba = this.props.business_name_dba || false;
    const KYBperformed = businessEntities.status && businessEntities.status !== '';
    const leftColumnCards = [];
    const rightColumnCards = [];
    const skipFields = [
      'cardholder_token',
      'status',
      'canVerify',
      'attestation_consent',
      'attestation_date',
      'attester_name',
      'attester_title',
      'proprietor_is_beneficial_owner',
    ];
    var isLeft = true;
    Object.keys(businessEntities).forEach((entityType) => {
      if (
        skipFields.includes(entityType) ||
        businessEntities[entityType] === null ||
        businessEntities[entityType] === undefined
      ) {
        isLeft = !isLeft;
        return false;
      }
      if (isSoleProp && entityType === 'business') {
        return false;
      }
      if (entityType === 'business') {
        businessEntities[entityType].business_name_dba = {
          value: business_name_dba,
          status: 'SUCCESS',
        };
      }
      const entityFields = businessEntities[entityType];
      if (isLeft) {
        leftColumnCards.push([entityType, entityFields]);
      } else {
        rightColumnCards.push([entityType, entityFields]);
      }
      isLeft = !isLeft;
    });
    return (
      <Container className={s.body}>
        {KYBperformed ? (
          <Row testId={`KYB-performed`}>
            <Col span={6}>{this.mapOverEntities('left', leftColumnCards)}</Col>
            <Col span={6}>{this.mapOverEntities('right', rightColumnCards)}</Col>
          </Row>
        ) : (
          <div className={s.bannerContainer} data-testid={`KYB-not-performed`}>
            <VSpacer factor={2} />
            {this.showBanner()}
          </div>
        )}
      </Container>
    );
  }
}

Body.propTypes = {
  businessEntities: PropTypes.any,
  updateBusinessAllowedRoles: PropTypes.array,
  kybStore: PropTypes.any,
  userStore: PropTypes.any,
  isSoleProp: PropTypes.bool,
  business_name_dba: PropTypes.string,
  hydrateBusinessStore: PropTypes.func,
  loadingBusinessEntity: PropTypes.bool,
  kycManualOverride: PropTypes.bool,
  vendorPending: PropTypes.bool,
};

Body.defaultProps = {
  loadingBusinessEntity: false,
  businessEntities: {},
  updateBusinessAllowedRoles: [],
  kybStore: {},
  userStore: {},
  isSoleProp: false,
  business_name_dba: '',
  hydrateBusinessStore: () => {},
  kycManualOverride: false,
  vendorPending: false,
};

export default observer(Body);
