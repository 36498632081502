import React from 'react';
import ErrorView from './ErrorView.js';
import { Button, ImageAsset, VSpacer, Text } from '@mqd/volt-base';
import s from './ErrorView.module.css';

export default function NotFoundView() {
  return (
    <ErrorView title={<ImageAsset width={290} type="emptyError" />} description="Page not found">
      <Text type="body-sm">We couldn't find the page you were looking for.</Text>
      <VSpacer factor={3} />
      <div className={s.buttonWrap}>
        <a href="/">
          <Button type="primary">Back to home</Button>
        </a>
      </div>
    </ErrorView>
  );
}
