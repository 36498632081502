import React from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  CheckboxGroup,
  Checkbox,
  RadioGroup,
  VSpacer,
  Form,
  FormLabel,
  FormChangeSummary,
} from '@mqd/volt-base';
import { MaskedInput } from '@mqd/volt-currency';
import { SplitChildrenComponentByComponent } from 'utils';
import {
  CASH_ACCESS_ENABLED,
  INTERNATIONAL_ENABLED,
  IS_RELOADABLE_PRE_KYC,
  ENABLE_NON_PROGRAM_LOADS,
  IS_RELOADABLE,
  REAL_TIME_FEE_GROUP_TOKEN,
  BALANCE_MAX,
} from './constants.js';

function AccountHolderGroupTemplate({
  formState = {},
  children,
  handleFormChange,
  compareState,
  status,
}) {
  const isNewCard = formState.new;

  return (
    <Form initialState={formState} onFormChange={handleFormChange}>
      <FormChangeSummary compareState={compareState} status={status} formType={'actionCard'}>
        <SplitChildrenComponentByComponent component={<VSpacer factor={3} />}>
          <Input name="name" label="Name" />
          <Input name="token" label="Token" disabled={!isNewCard} />
          <Input name={REAL_TIME_FEE_GROUP_TOKEN} label="Real time fee group token (optional)" />
          <CheckboxGroup version="v2" name="accountHolderGroupEnableFeatures">
            <FormLabel>Enable features</FormLabel>
            <Checkbox label="Reloadable" name={IS_RELOADABLE} />
            <VSpacer />
          </CheckboxGroup>
          <RadioGroup
            label="KYC"
            testId="account-holder-group-kyc"
            name="kyc_required"
            groupItems={[
              {
                label: 'Always required',
                value: 'ALWAYS',
              },
              {
                label: 'Conditionally required',
                value: 'CONDITIONAL',
              },
              {
                label: 'Never required',
                value: 'NEVER',
              },
            ]}
          />
          <CheckboxGroup version="v2" name="accountHolderGroupPreKycControls">
            <FormLabel>Pre KYC controls</FormLabel>
            <Checkbox label="Cash access" name={CASH_ACCESS_ENABLED} />
            <VSpacer />
            <Checkbox label="International" name={INTERNATIONAL_ENABLED} />
            <VSpacer />
            <Checkbox label="Reloadable" name={IS_RELOADABLE_PRE_KYC} />
            <VSpacer />
            <Checkbox label="Non-program loads" name={ENABLE_NON_PROGRAM_LOADS} />
            <VSpacer />
          </CheckboxGroup>
          <MaskedInput
            label="Pre KYC max balance"
            name={BALANCE_MAX}
            width={240}
            type="currency"
            rightAddOn={'USD'}
          />
        </SplitChildrenComponentByComponent>
        {children}
      </FormChangeSummary>
    </Form>
  );
}

AccountHolderGroupTemplate.properties = [
  'id',
  'name',
  'token',
  'real_time_fee_group_token',
  'is_reloadable',
  'kyc_required',
  'cash_access_enabled',
  'international_enabled',
  'is_reloadable_pre_kyc',
  'enable_non_program_loads',
  'balance_max',
];

AccountHolderGroupTemplate.propTypes = {
  formState: PropTypes.object.isRequired,
  children: PropTypes.array.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  isNewCard: PropTypes.bool,
  compareState: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
};

AccountHolderGroupTemplate.defaultProps = {
  isNewCard: false,
  formState: {},
  compareState: {},
  handleFormChange: () => {},
};

export default AccountHolderGroupTemplate;
