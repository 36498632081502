import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { ActionCard, LoadingOverlay } from '@mqd/volt-base';
import { disabledStatuses } from '../../constants';
import { useCardTemplateContext } from '../../context/CardTemplateContext';
import { AccordionWrapper } from './components/accordion-wrapper/AccordionWrapper';
import { createButtonKeyDownHandler } from '../../helpers';
import { T } from 'ramda';

const { TemplateWrapper, MODE } = ActionCard;

export function AccordionCardTemplate({
  datum,
  mode,
  switchToPreview,
  switchToEdit,
  isCardEdited,
  cardCompareState,
  renderControls,
}) {
  const {
    template: Template,
    actions,
    status,
    cardType,
    isFirstRenderOfGroup,
  } = useCardTemplateContext();

  const templateRef = useRef();

  const handleSwitchToPreview = () => {
    switchToPreview();
    templateRef.current?.focus();
  };

  useEffect(() => {
    if (mode === MODE.PREVIEW && !isFirstRenderOfGroup) {
      templateRef.current?.focus();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [formState, setFormState] = useState(datum);
  const actionsWithReset = {
    ...actions,
    onResetCard: () => {
      formState.temp ? actions.onResetCard(formState) : setFormState(datum);
    },
  };

  // this has to be a renderControls() function, not <Controls /> component
  // so the Form component can find buttons iterating by its direct children
  const controls = renderControls({
    formState,
    cardType,
    actions: actionsWithReset,
    switchToPreview: handleSwitchToPreview,
  });

  if (mode === MODE.PREVIEW) {
    const switchToEditMaybe = datum.error || datum.loading ? T : switchToEdit;

    return (
      <TemplateWrapper
        ref={templateRef}
        mode={mode}
        onClick={switchToEditMaybe}
        onKeyDown={createButtonKeyDownHandler(switchToEditMaybe)}
        testId={`action-card-${mode}`}
      >
        {datum.loading ? (
          <LoadingOverlay active size={24} />
        ) : (
          <AccordionWrapper
            datum={datum}
            status={status}
            onToggleExpansion={switchToEdit}
            edited={isCardEdited}
          />
        )}
      </TemplateWrapper>
    );
  }

  if (mode === MODE.EDIT) {
    const shouldShowControls = !disabledStatuses.has(status);
    const templateWithControls = (
      <Template
        status={status}
        compareState={cardCompareState}
        formState={formState}
        handleFormChange={setFormState}
      >
        {shouldShowControls && controls}
      </Template>
    );

    return (
      <TemplateWrapper
        ref={templateRef}
        mode={mode}
        onKeyDown={createButtonKeyDownHandler(switchToPreview)}
        testId={`action-card-${mode}`}
      >
        {formState.temp ? (
          templateWithControls
        ) : (
          <AccordionWrapper
            datum={datum}
            status={status}
            onToggleExpansion={switchToPreview}
            edited={isCardEdited}
            expand
            defaultExpanded
          >
            {templateWithControls}
          </AccordionWrapper>
        )}
      </TemplateWrapper>
    );
  }

  return null;
}

AccordionCardTemplate.propTypes = {
  datum: PropTypes.object.isRequired,
  mode: PropTypes.oneOf(Object.values(MODE)).isRequired,
  isCardEdited: PropTypes.bool.isRequired,
  cardCompareState: PropTypes.object.isRequired,
  renderControls: PropTypes.func.isRequired,
  switchToPreview: PropTypes.func, // is passed when mode === MODE.EDIT
  switchToEdit: PropTypes.func, // is passed when mode === MODE.PREVIEW
};

const warning = (mode) => {
  console.warn(`Tried switching to "${mode}" mode when it's already set.`);
};

AccordionCardTemplate.defaultProps = {
  switchToPreview: () => {
    warning(MODE.PREVIEW);
  },
  switchToEdit: () => {
    warning(MODE.EDIT);
  },
};
