import React, { useState, useContext, useEffect } from 'react';
import { Tabs, VSpacer } from '@mqd/volt-base';
import { WebhookDetailsForm } from './webhookDetailsForm/WebhookDetailsForm.js';
import WebhookEvents from './webhookEvents/WebhookEvents.js';
import { WebhookContext } from '../contexts/WebhookContext.js';
import { recursivelyUpdateEventsArray } from './webhookEvents/utils/webhookHelpers.js';
import { translateFormValuesToGraphQLWebhookType } from '../functions/webhooks.js';

const WEBHOOK_DETAILS_TABS = {
  DETAILS: 'Details',
  EVENTS: 'Event listeners',
};

const WebhookDetailsMain = () => {
  const [activeTab, setActiveTab] = useState(WEBHOOK_DETAILS_TABS.DETAILS);
  const [formData, setFormData] = useState(null);
  const [eventsData, setEventsData] = useState(null);

  const { onUpdateWebhook, defaultWebhookValues } = useContext(WebhookContext);

  // Hold a reference of the webhook data
  const [oldWebhookData, setOldWebhookData] = useState(null);

  useEffect(() => {
    setOldWebhookData(defaultWebhookValues);
  }, [defaultWebhookValues]);

  const onFormChange = (data) => {
    setFormData(data);
  };

  const onEventsChange = (eventsData) => {
    setEventsData(eventsData);
  };

  const getEventsArray = () => {
    const eventsArray = [];
    eventsArray.concat(recursivelyUpdateEventsArray(eventsData, eventsArray));
    return eventsArray;
  };

  const handleTabChange = (tab) => {
    if (onUpdateWebhook && formData) {
      // If switching to events tab
      if (tab === WEBHOOK_DETAILS_TABS.EVENTS) {
        onUpdateWebhook(translateFormValuesToGraphQLWebhookType(formData));
      } else {
        const eventsArr = getEventsArray();
        formData.events = eventsArr;
        onUpdateWebhook(translateFormValuesToGraphQLWebhookType(formData));
      }
    }
    setActiveTab(tab);
  };

  return (
    <Tabs
      activeTab={activeTab}
      tabs={[WEBHOOK_DETAILS_TABS.DETAILS, WEBHOOK_DETAILS_TABS.EVENTS]}
      onTabChange={(tab) => handleTabChange(tab)}
      testId="tabs-screen"
    >
      <VSpacer factor={3} />
      {activeTab === WEBHOOK_DETAILS_TABS.EVENTS ? (
        <WebhookEvents onEventsChange={onEventsChange} oldWebhookData={oldWebhookData} />
      ) : (
        <WebhookDetailsForm onFormChange={onFormChange} oldWebhookData={oldWebhookData} />
      )}
    </Tabs>
  );
};

export { WebhookDetailsMain };
