import currentUserStore from '../../stores/CurrentUserStore.js';
import { UAM_GRANULAR_PERMISSIONS } from '@mqd/mqd-constants';

// Routes
const acceptedCountriesRoutes = [
  {
    path: '/accepted-countries',
    name: 'Accepted Countries',
    importer: () =>
      import(/*webpackChunkName: "Accepted Countries" */ './pages/AcceptedCountries.js'),
  },
  {
    path: '/accepted-countries/new',
    name: 'Create Accepted Countries',
    importer: () =>
      import(/*webpackChunkName: "Accepted Countries New" */ './pages/CreateAcceptedCountries.js'),
  },
  {
    path: '/accepted-countries/:token',
    name: 'Accepted Countries Detail',
    importer: () =>
      import(
        /*webpackChunkName: "Accepted Countries Detail" */ './pages/AcceptedCountriesDetail.js'
      ),
  },
];

// Authorization
const defaultRequirements = {
  flipFlopRequirements: ['accepted-countries'],
  requiredGranularPermissions: [UAM_GRANULAR_PERMISSIONS.PROGRAM_CONFIGURATION_VIEW_AND_EDIT],
};

const defaultVerifier = () => {
  return currentUserStore.userStore.acceptedCountriesActive;
};

const requirements = {
  acceptedCountries: defaultRequirements,
  acceptedCountriesDetail: defaultRequirements,
  acceptedCountriesNew: defaultRequirements,
};

const verifiers = {
  acceptedCountries: defaultVerifier,
  acceptedCountriesDetail: defaultVerifier,
  acceptedCountriesNew: defaultVerifier,
};

export { requirements, verifiers };
export default acceptedCountriesRoutes;
