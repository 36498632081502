import { useContext, useEffect } from 'react';
import { ProgramReserveContext } from '../context/ProgramReserveContext.js';
import routerStore from 'stores/router/RouterStore';

export const usePageLeaveConfirmationModal = () => {
  const { areChangesMade } = useContext(ProgramReserveContext);

  useEffect(() => {
    if (areChangesMade) {
      routerStore.registerOnPageLeave({
        headerText: 'Are you sure you want to cancel?',
        bodyText: 'You will lose any unsaved changes.',
      });

      return () => routerStore.unregisterOnPageLeave();
    }
  }, [areChangesMade]);
};
