import currentUserStore from 'stores/CurrentUserStore';
import hasRoleForAtLeastOneProgram from '../../helpers/hasRoleForAtLeastOneProgram.js';
import hasRoleForSelectedProgram from '../../helpers/hasRoleForSelectedProgram.js';
import {
  THREE_DS_SETTINGS_VIEW,
  THREE_DS_SETTINGS_VIEW_AND_EDIT,
} from '../../../views/admin/uam-granular-permissions/constants.js';

export const requirements = {
  requiredUserPermissions: ['delivery-internal'],
  requiredGranularPermissions: [THREE_DS_SETTINGS_VIEW, THREE_DS_SETTINGS_VIEW_AND_EDIT],
};

export const verifier = () => {
  const {
    userStore: { uamGranularPermissionsActive },
  } = currentUserStore;
  const canView3DS = [THREE_DS_SETTINGS_VIEW, THREE_DS_SETTINGS_VIEW_AND_EDIT].some((role_token) =>
    hasRoleForAtLeastOneProgram(role_token)
  );

  const hasAccessForAtLeastOneProgram = hasRoleForAtLeastOneProgram('delivery-internal');
  return uamGranularPermissionsActive ? canView3DS : hasAccessForAtLeastOneProgram;
};

export const programVerifier = () => {
  const {
    userStore: { uamGranularPermissionsActive },
  } = currentUserStore;
  const canView3DS = [THREE_DS_SETTINGS_VIEW, THREE_DS_SETTINGS_VIEW_AND_EDIT].some((role_token) =>
    hasRoleForSelectedProgram(role_token)
  );

  const programHasPermissionsAccess = hasRoleForSelectedProgram('delivery-internal');
  return uamGranularPermissionsActive ? canView3DS : programHasPermissionsAccess;
};
