import { pathOr, takeLast } from 'ramda';

// constants
import { DEFAULT_MASK, LAST_FOUR } from '../constants/index.js';

// utils
import { isString } from './general.js';
import { getDda } from './account.js';

/**
 * getMaskedNumber - masks input string (presumably representing a number)
 * @param number - a number in string format that should be masked
 * @returns {string}
 */
export const getMaskedNumber = (number = '') => {
  if (!number || !isString(number)) return '';

  return DEFAULT_MASK + takeLast(LAST_FOUR, number);
};

/**
 * getMaskedAccountNumber - takes first CHECKING account from directDepositAccounts object
 * and returns its masked version or null, if there is no such account.
 * @param data
 * @returns {*}
 */
export const getMaskedAccountNumber = (data = {}) => {
  const { account_number } = getDda(data) || {};
  const maskedAccountNumber = getMaskedNumber(account_number);

  return maskedAccountNumber ? `Account ${maskedAccountNumber}` : null;
};

export const getMaskedCardNumber = (data = {}, prefix = '') => {
  const cardNumber = pathOr('', ['card', 'pan'], data);
  const maskedCardNumber = getMaskedNumber(cardNumber);
  return maskedCardNumber ? `${prefix || 'Card'} ${maskedCardNumber}` : null;
};
