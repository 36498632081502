import React, { useContext, useState } from 'react';
import { prop } from 'ramda';
import { Breadcrumbs, Button, Flex, HSpacer, Text, VSpacer } from '@mqd/volt-base';
import routerStore from 'stores/router/RouterStore';
import { renderIf } from 'utils';
import { WebhookContext } from '../contexts/index.js';
import { translateFormValuesToGraphQLWebhookType } from '../functions/webhooks.js';
import WebhookStatusIndicatorToggle from '../webhooks-overview/WebhookStatusIndicatorToggle.js';
import { DisableConfirmationModal } from '../components/index.js';
import { SendTestEventModal, TestEventToast } from './components/index.js';
import { WEBHOOK_PING } from '../operations/webhooks.js';
import { useLazyQuery } from '@apollo/react-hooks';
import { useSandboxCredentials } from '../hooks/useSandboxCredentials.js';

const WebhookDetailsHeader = ({ active }) => {
  const { defaultWebhookValues, onSave } = useContext(WebhookContext);
  const { sandboxCredentialsVariables } = useSandboxCredentials();

  const [showDisableConfirmationModal, setShowDisableConfirmationModal] = useState(false);
  const [showTestWebhookConfirmationModal, setShowTestWebhookConfirmationModal] = useState(false);
  const [showTestEventToast, setShowTestEventToast] = useState(false);

  const [testEventSuccessful, setTestEventSuccessful] = useState(false);
  const name = prop('name', defaultWebhookValues);

  // Webhook Activate & Deactivate Functions
  const setWebhookActive = (_active) => {
    defaultWebhookValues.active = _active;
    onSave(translateFormValuesToGraphQLWebhookType(defaultWebhookValues));
  };

  const isWebhooksNewView = () => {
    return routerStore.currentView && routerStore.currentView.name === 'WebhooksNew';
  };

  const disableWebhook = () => {
    setShowDisableConfirmationModal(false);
    setWebhookActive(false);
  };

  const webhookStatusButtonClick = () => {
    active ? setShowDisableConfirmationModal(true) : setWebhookActive(true);
  };

  // Webhook test event functions
  const [webhookPing, { loading: testEventLoading }] = useLazyQuery(WEBHOOK_PING, {
    onError: () => {
      setTestEventToastSuccessful(false);
    },
    onCompleted: () => {
      setTestEventToastSuccessful(true);
    },
    fetchPolicy: 'no-cache',
  });

  const sendTestEvent = () => {
    setShowTestWebhookConfirmationModal(false);
    const { token } = defaultWebhookValues;
    webhookPing({
      variables: {
        token,
        ...sandboxCredentialsVariables,
      },
    });
  };

  const showSendTestEventModal = () => {
    setShowTestWebhookConfirmationModal(true);
  };

  // Webhook Toasts
  const setTestEventToastSuccessful = (isSuccessful) => {
    setShowTestEventToast(true);
    setTestEventSuccessful(isSuccessful);
  };

  // Webhook View
  return (
    <>
      <Flex flexDirection="row" justifyContent="space-between">
        <Flex>
          <Breadcrumbs
            crumbs={[
              {
                children: 'Webhooks',
                href: '/development/webhooks',
                onClick: () => routerStore.go('developmentWebhooksOverview'),
              },
              {
                children: name,
              },
            ]}
          />
          <VSpacer factor={2} />
          <WebhookStatusIndicatorToggle
            active={active}
            onDisable={() => console.log(`Disable webhook ${defaultWebhookValues}`)}
            onActivate={() => console.log(`Activate webhook ${defaultWebhookValues}`)}
            dropdownDisabled={true}
          />
        </Flex>
        <Flex flexDirection="row" alignItems="flex-start">
          <Button
            type="tertiary"
            testId="update-webhook-status-button"
            onClick={webhookStatusButtonClick}
            disabled={isWebhooksNewView()}
          >
            {active ? 'Disable' : 'Activate'} webhook
          </Button>
          <HSpacer factor={2} />
          <Button
            type="tertiary"
            onClick={showSendTestEventModal}
            loading={testEventLoading}
            testId="send-test-event-button"
            disabled={!active}
          >
            Send test event
          </Button>
        </Flex>
      </Flex>
      <VSpacer factor={1} />
      <Text type="h3" role="heading" ariaLevel={1}>
        {name}
      </Text>
      <VSpacer factor={2} />
      {renderIf(
        showDisableConfirmationModal,
        <DisableConfirmationModal
          disableWebhook={disableWebhook}
          hideModalCallback={() => {
            setShowDisableConfirmationModal(false);
          }}
        />
      )}
      {renderIf(
        showTestWebhookConfirmationModal,
        <SendTestEventModal
          sendTestEvent={sendTestEvent}
          hideModalCallback={() => {
            setShowTestWebhookConfirmationModal(false);
          }}
        />
      )}
      {renderIf(
        showTestEventToast,
        <TestEventToast
          dismissToast={() => setShowTestEventToast(false)}
          isSuccessful={testEventSuccessful}
        />
      )}
    </>
  );
};

export { WebhookDetailsHeader };
