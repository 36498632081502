import gql from 'graphql-tag';

export const APPROVE_TRANSACTION_ADJUSTMENT = gql`
  mutation createApproveTransactionAdjustment(
    $transactionApprovalIds: [TransactionAdjustmentInfoInput!]
  ) {
    approveTransactionAdjustment(transactionApprovalIds: $transactionApprovalIds) {
      status
      statusText
      message
      unsuccessful_tx_hash_internal_ids
    }
  }
`;
