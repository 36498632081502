import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Text, Icon, HSpacer, RawDropdown } from '@mqd/volt-base';
import { TableFilters } from '@mq/voltron-table';
import s from './CardProductGrid.module.css';
import { filterOptions, sortOptions } from './constants';

function SortByOption({ label, sortByAttr, sortBy, setAttr }) {
  const active = sortBy === sortByAttr;
  return (
    <div
      onClick={() => setAttr('sortBy', sortByAttr)}
      className={`${s.sortOption} ${active ? s.active : ''}`}
    >
      <Text type={active ? 'h6' : 'body'} mod={active ? 'semi-bold' : ''}>
        {label}
      </Text>
    </div>
  );
}

function CardProductGridFilter({ store, excludedSearchFilters }) {
  const { handleClear, disableClear, sortBy, setAttr } = store;

  return (
    <div className={s.flexDiv} data-testid="card-product-grid__filter" style={{ width: '330px' }}>
      <TableFilters
        store={store}
        filterOptions={filterOptions}
        excludedFilters={excludedSearchFilters}
        testId="card-product-grid-filters"
        type="small"
        footerButtons={[
          <div className="clear-button" key="clear-button">
            <Button
              onClick={handleClear}
              disabled={disableClear}
              type="tertiary"
              testId="card-product-grid-filters__clear-container"
            >
              Clear
            </Button>
          </div>,
        ]}
      />
      <HSpacer />
      <RawDropdown control="Sort by last modified" contentPadding={0}>
        {sortOptions.map((option, i) => (
          <Fragment key={i}>
            <SortByOption {...option} sortBy={sortBy} setAttr={setAttr} />
          </Fragment>
        ))}
      </RawDropdown>
    </div>
  );
}

CardProductGridFilter.propTypes = {
  excludedSearchFilters: PropTypes.array,
  store: PropTypes.object,
};

CardProductGridFilter.defaultProps = {
  excludedSearchFilters: [],
  store: {},
};

export default CardProductGridFilter;
