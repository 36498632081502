import gql from 'graphql-tag';

export const GET_UNMATCHED_QUEUE = gql`
  query getExceptionQueueBySubnetwork(
    $subnetwork: Subnetwork!
    $exception_type: ExceptionTypeEnum!
    $ica_values: [String]
    $page_token: String
    $originator: String
    $page_size: Int!
    $program_types: [ProgramType]
    $program_short_codes: [String]
    $dna_bank_tokens: [ID]
    $settlement_currencies: [CurrencyCode]
    $start_settlement_date: String
    $end_settlement_date: String
  ) {
    exceptionQueueBySubnetwork(
      page_size: $page_size
      subnetwork: $subnetwork
      exception_type: $exception_type
      ica_values: $ica_values
      page_token: $page_token
      program_types: $program_types
      program_short_codes: $program_short_codes
      dna_bank_tokens: $dna_bank_tokens
      originator: $originator
      settlement_currencies: $settlement_currencies
      start_settlement_date: $start_settlement_date
      end_settlement_date: $end_settlement_date
    ) {
      prev_page_token
      next_page_token
      exceptions {
        bank {
          name
          token
        }
        hash_id
        ica
        local_transaction_date_time
        masked_pan
        message_reason_code
        network_reference_id
        program_short_code
        program_type
        settlement_date
        settlement_currency
        source
        state
        tx_hash_internal_id
        variance_amount {
          scale
          units
        }
      }
    }
    programTypes
    dnaBanks(count: 500) {
      data {
        name
        token
      }
    }
    currencyCodes
  }
`;
