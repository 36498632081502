import sortDateStrings from './sortDateStrings.js';
import sortNumberStrings from './sortNumberStrings.js';

const dateOrNumberSorter = (direction, accessor) => {
  function sortFunction(a, b) {
    let aVal = a[accessor] ? a[accessor].toString().toLowerCase().trim() : '';
    let bVal = b[accessor] ? b[accessor].toString().toLowerCase().trim() : '';
    if (accessor.startsWith('date')) {
      [aVal, bVal] = sortDateStrings(aVal, bVal);
    } else if (!isNaN(aVal) && !isNaN(bVal)) {
      [aVal, bVal] = sortNumberStrings(aVal, bVal);
    }
    if (direction === 'DESC') {
      return aVal > bVal ? -1 : 1;
    } else {
      return aVal > bVal ? 1 : -1;
    }
  }
  return sortFunction;
};

export default dateOrNumberSorter;
