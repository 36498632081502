import currentUserStore from 'stores/CurrentUserStore';
import { hasTokenizationManagerPermissions } from './functions.js';

export const requirements = {
  flipFlopRequirements: ['tokenization-beta'],
};

export const verifier = () => {
  const { userStore = {} } = currentUserStore;

  return (
    userStore.tokenizationActive &&
    hasTokenizationManagerPermissions(userStore, 'program/token-service')
  );
};
