import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import s from './TableActions.module.css';
import { Icon, ActionDropdown, HSpacer } from '@mqd/volt-base';

class TableActions extends PureComponent {
  renderActions() {
    const { actions, row } = this.props;
    const actionsContent = actions.map((action) => {
      const { element, icon, text, onClick, children } = action;
      if (element) return element;
      return {
        element:
          text === 'View' ? (
            text
          ) : (
            <div className={s.action} data-testid={`table-action-${text}`}>
              {this.renderIcon(icon)}
              {text}
            </div>
          ),
        onClick: children ? null : () => onClick && onClick(row),
        children: children ? children : null,
      };
    });
    return (
      <ActionDropdown
        control={
          <div className={s.actionsControl}>
            <Icon type="chevron-down" mod="disabled" overrideColor="#5F5F7A" factor={0.5} />
          </div>
        }
        anchor="left"
        content={actionsContent}
        fixOverflow={true}
        portalZIndex={198}
      />
    );
  }

  renderIcon(icon) {
    if (!icon) return null;
    return (
      <>
        <div className={s.iconHighlight}>
          <Icon factor={0.2} mod="reversed" type={icon} />
        </div>
        <HSpacer factor={1.5} />
      </>
    );
  }

  render() {
    const { onViewClick, row, actions } = this.props;
    const hasActions = !actions || actions.length > 0;
    const borderRadiusStyle = {
      borderRadius: hasActions ? '2px 0 0 2px' : '2px',
    };

    if (Object.keys(row).length === 0) return null;
    return (
      <div data-testid="table-actions">
        <div className={s.actions}>
          <div
            className={s.view}
            style={borderRadiusStyle}
            onClick={() => onViewClick && onViewClick(row)}
            data-testid="table-action-view"
          >
            View
          </div>
          {hasActions && <div className={s.dropdown}>{this.renderActions()}</div>}
        </div>
      </div>
    );
  }
}

TableActions.propTypes = {
  onViewClick: PropTypes.func,
  actions: PropTypes.array,
  row: PropTypes.object,
};

TableActions.defaultProps = {
  onViewClick: () => {},
  actions: [],
  row: {},
  /*
   // example action
   {
    icon,
    text,
    onClick,
    element // pass through to ActionsDropdown
   }
  */
};
export default TableActions;
