import React, { useReducer } from 'react';
import { ProgramSelectorDispatchContext, ProgramSelectorStateContext } from './context.js';
import { reducer, initialState } from './reducer.js';

export default function ProgramSelector({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <ProgramSelectorStateContext.Provider value={state}>
      <ProgramSelectorDispatchContext.Provider value={dispatch}>
        {children}
      </ProgramSelectorDispatchContext.Provider>
    </ProgramSelectorStateContext.Provider>
  );
}
