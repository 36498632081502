import { FlipFlop } from './../../../utils/index.js';
import currentUserStore from 'stores/CurrentUserStore';
import { hasDeveloperRole, hasNoRoles, isJanusUser } from './functions.js';

export const developerRole = 'developer';

export const requirements = {
  flipFlopRequirements: ['developer-dashboard'],
  requiredRoles: [developerRole],
};

export const verifier = () => {
  const { userStore = {} } = currentUserStore;
  const devDashFlag = FlipFlop.get('developer-dashboard', true);
  return (
    !isJanusUser(userStore) && devDashFlag && (hasNoRoles(userStore) || hasDeveloperRole(userStore))
  );
};
