import currentUserStore from 'stores/CurrentUserStore';
import { FlipFlop } from '../..';
import hasRoleForAtLeastOneProgram from '../../helpers/hasRoleForAtLeastOneProgram';
import { UNIFY_BETA } from './constants/featureFlags';
import {
  CARDHOLDER_SUPPORT,
  DELIVERY_INTERNAL,
  MARQETA_ADMIN_INTERNAL,
  PRODUCTION_SUPPORT_INTERNAL,
  PROGRAM_ADMIN,
} from './constants/redseaRoles';

const requiredUserPermissions = [
  MARQETA_ADMIN_INTERNAL,
  PROGRAM_ADMIN,
  DELIVERY_INTERNAL,
  PRODUCTION_SUPPORT_INTERNAL,
  CARDHOLDER_SUPPORT,
  'compliance-internal',
  'compliance-program-managed',
  'compliance-processor-only',
  'risk-internal',
  'fulfillment-internal',
  'settlement-internal',
  'supplier-payments-manager',
  'read-only',
  'access-manager',
];

export const requirements = {
  flipFlopRequirements: [UNIFY_BETA],
  requiredUserPermissions,
};

export const verifier = () => {
  const {
    userStore: { hasRole },
  } = currentUserStore;
  const hasSufficientRoles = requiredUserPermissions.some((role) =>
    hasRoleForAtLeastOneProgram(hasRole(role))
  );

  return FlipFlop.get('bulk-issuance-beta', false) && hasSufficientRoles;
};
