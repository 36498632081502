import { SUBNETWORKS, NETWORKS } from './index.js';

const subnetworkToFullName = {
  SUBNETWORK_VISANET: NETWORKS['NETWORK_VISA'] + ' ' + SUBNETWORKS['SUBNETWORK_VISANET'],
  SUBNETWORK_VISANETDEBIT: NETWORKS['NETWORK_VISA'] + ' ' + SUBNETWORKS['SUBNETWORK_VISANETDEBIT'],
  SUBNETWORK_VISAINTERLINK:
    NETWORKS['NETWORK_VISA'] + ' ' + SUBNETWORKS['SUBNETWORK_VISAINTERLINK'],
  SUBNETWORK_VISAPLUS: NETWORKS['NETWORK_VISA'] + ' ' + SUBNETWORKS['SUBNETWORK_VISAPLUS'],

  SUBNETWORK_MAESTRO: NETWORKS['NETWORK_MASTERCARD'] + ' ' + SUBNETWORKS['SUBNETWORK_MAESTRO'],
  SUBNETWORK_CIRRUS: NETWORKS['NETWORK_MASTERCARD'] + ' ' + SUBNETWORKS['SUBNETWORK_CIRRUS'],
  SUBNETWORK_MASTERCARD_SIG: SUBNETWORKS['SUBNETWORK_MASTERCARD_SIG'],

  SUBNETWORK_GATEWAY_JIT: NETWORKS['NETWORK_MARQETA'] + ' ' + SUBNETWORKS['SUBNETWORK_GATEWAY_JIT'],
  SUBNETWORK_MANAGED_JIT: NETWORKS['NETWORK_MARQETA'] + ' ' + SUBNETWORKS['SUBNETWORK_MANAGED_JIT'],

  SUBNETWORK_PULSE: SUBNETWORKS['SUBNETWORK_PULSE'],
  SUBNETWORK_DISCOVER: SUBNETWORKS['SUBNETWORK_DISCOVER'],
  UNRECOGNIZED: SUBNETWORKS['UNRECOGNIZED'],
};

export default subnetworkToFullName;
