import React from 'react';
import FooterButtons from './FooterButtons';
import Row from './Row';
import AddressRow from './AddressRow';
import BusinessRow from './BusinessRow';
import NameRow from './NameRow';
import NoValRow from './NoValRow';
import { HSpacer, Icon, Text, VSpacer } from '@mqd/volt-base';
import s from './KYBCard.module.css';
import ApprovalModal from '../../ApprovalModal';
import {
  checkForChanges,
  getAddressObject,
  getNameObject,
  getMultiFieldStatus,
  transformEntityString,
} from './../../helpers';
import { stringToStatus } from './../../constants';
import { PropTypes } from 'mobx-react';
import StatusIcon from './StatusIcon';

class Rows extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editActive: false,
      showModal: false,
      validatedFieldChanged: false,
    };
    this.changeValueHandler = this.changeValueHandler.bind(this);
    this.changeStatusHandler = this.changeStatusHandler.bind(this);
    this.createFieldsState = this.createFieldsState.bind(this);
    this.onSave = this.onSave.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.checkForSave = this.checkForSave.bind(this);
    this.showApprovalModal = this.showApprovalModal.bind(this);
  }

  componentDidMount() {
    this.createFieldsState();
  }

  createFieldsState() {
    const { fields } = this.props;

    const stateObject = Object.keys(fields).reduce((acc, fieldName) => {
      if (fields[fieldName]) {
        acc[fieldName] = { value: fields[fieldName].value, status: fields[fieldName].status };
      } else {
        acc[fieldName] = { value: '', status: 'PENDING' };
      }

      return acc;
    }, {});
    this.setState({ editActive: false, ...stateObject });
  }

  changeValueHandler(fieldName, newVal) {
    this.setState({ [fieldName]: { value: newVal, status: this.state[fieldName].status } });
  }

  changeStatusHandler(fieldName, newVal) {
    const newStatus = stringToStatus[newVal] || 'PENDING';
    this.setState({ [fieldName]: { value: this.state[fieldName].value, status: newStatus } });
  }

  async onSave(note) {
    const { entityType, kybStore, userStore = {}, hydrateBusinessStore } = this.props;
    if (userStore.kybCanWrite) {
      const result = await kybStore.saveChanges(this.state, entityType, note, userStore.email);
      if (result) {
        kybStore.getKybLog();
        hydrateBusinessStore();
      }
    } else if (userStore.kybCustomerWrite) {
      const result = await kybStore.saveBusinessChanges(this.state, entityType);
      if (result) {
        hydrateBusinessStore();
      }
    }
    if (this.state.showModal) {
      this.toggleModal(false, false);
    }
  }

  checkForSave() {
    const { fields } = this.props;
    const changes = checkForChanges(fields, this.state);
    if (changes.popModal) {
      this.toggleModal(changes.validatedFieldChanged, changes.allFieldsSuccess);
    } else if (changes.valueChanged) {
      this.onSave('');
      this.createFieldsState();
    } else {
      this.createFieldsState();
    }
  }

  toggleModal(validatedFieldChanged, allFieldsSuccess) {
    this.setState({
      showModal: !this.state.showModal,
      validatedFieldChanged: validatedFieldChanged,
      allFieldsSuccess: allFieldsSuccess,
    });
  }

  renderAddressRow({ editActive, userStore, fieldName, kybCustomerCanWrite, i }) {
    const addressObject = getAddressObject(this.state);

    return (
      <React.Fragment key={`${fieldName}-field-${i}`}>
        <AddressRow
          addressObject={addressObject}
          onChange={this.changeValueHandler}
          onChangeStatus={this.changeStatusHandler}
          editActive={editActive}
          userStore={userStore}
          kybCustomerCanWrite={kybCustomerCanWrite}
        />
        <VSpacer factor={2} />
      </React.Fragment>
    );
  }

  renderBusinessRow({ editActive, userStore, fieldName, status, i }) {
    return (
      <React.Fragment key={`${fieldName}-field-${i}`}>
        <BusinessRow
          name={fieldName}
          field={this.state[fieldName] ? this.state[fieldName].value : ''}
          status={status}
          onChange={(e) => this.changeValueHandler(fieldName, e.target.value)}
          onChangeStatus={this.changeStatusHandler}
          editActive={editActive}
          userStore={userStore}
        />
        <VSpacer factor={2} />
      </React.Fragment>
    );
  }

  renderNameRow({ editActive, userStore, fieldName, kybCustomerCanWrite, i }) {
    const nameObject = getNameObject(this.state);

    return (
      <React.Fragment key={`${fieldName}-field-${i}`}>
        <NameRow
          nameObject={nameObject}
          onChange={this.changeValueHandler}
          onChangeStatus={this.changeStatusHandler}
          editActive={editActive}
          userStore={userStore}
          kybCustomerCanWrite={kybCustomerCanWrite}
        />
        <VSpacer factor={2} />
      </React.Fragment>
    );
  }

  renderNoValRow({ editActive, userStore, fieldName, kybCustomerCanWrite, status, i }) {
    return (
      <React.Fragment key={`${fieldName}-field-${i}`}>
        <NoValRow
          name={fieldName}
          status={status}
          onChangeStatus={this.changeStatusHandler}
          editActive={editActive}
          userStore={userStore}
          kybCustomerCanWrite={kybCustomerCanWrite}
        />
        <VSpacer factor={2} />
      </React.Fragment>
    );
  }

  renderBoolRow({ fieldName, status, i }) {
    const displayName = transformEntityString(fieldName);
    return (
      <React.Fragment key={`${fieldName}-field-${i}`}>
        <div data-testid={`KYB-bool-row-${status}`} className={s.boolRow}>
          <Text type="h6">{displayName}</Text>
          <HSpacer />
          <StatusIcon status={status} />
        </div>
        <VSpacer factor={2} />
      </React.Fragment>
    );
  }

  renderRow({ editActive, userStore, fieldName, kybCustomerCanWrite, status, i }) {
    return (
      <React.Fragment key={`${fieldName}-field-${i}`}>
        <Row
          name={fieldName}
          field={this.state[fieldName] ? this.state[fieldName].value : ''}
          status={status}
          editActive={editActive}
          onChange={(e) => this.changeValueHandler(fieldName, e.target.value)}
          onChangeStatus={this.changeStatusHandler}
          userStore={userStore}
          kybCustomerCanWrite={kybCustomerCanWrite}
        />
        <VSpacer factor={2} />
      </React.Fragment>
    );
  }

  renderFields() {
    const { editActive } = this.state;
    const { fields, userStore } = this.props;
    const relevantFields = [
      'name',
      'first_name',
      'business_name_dba',
      'address',
      'dob',
      'ein',
      'ssn',
      'ofac',
      'sanctions',
      'attester_name',
      'attester_title',
      'attestation_date',
      'proprietor_is_beneficial_owner',
    ];
    const businessFields = [
      'attestation_date',
      'attester_name',
      'attester_title',
      'business_name_dba',
    ];
    const noValFields = ['sanctions', 'ofac'];
    return relevantFields.map((fieldName, i) => {
      if (!Object.keys(fields).includes(fieldName)) return;

      var status = this.state[fieldName] ? this.state[fieldName].status : 'PENDING';
      if (fieldName === 'address') {
        status = getMultiFieldStatus(getAddressObject(this.state));
      }
      if (fieldName === 'first_name') {
        status = getMultiFieldStatus(getNameObject(this.state));
      }

      const kybCustomerCanWrite = userStore.kybCustomerWrite && status !== 'SUCCESS';
      if (fieldName === 'address') {
        return this.renderAddressRow({ editActive, userStore, fieldName, kybCustomerCanWrite, i });
      }
      if (fieldName === 'first_name') {
        return this.renderNameRow({ editActive, userStore, fieldName, kybCustomerCanWrite, i });
      }
      if (noValFields.includes(fieldName)) {
        return this.renderNoValRow({
          editActive,
          userStore,
          fieldName,
          kybCustomerCanWrite,
          status,
          i,
        });
      }
      if (businessFields.includes(fieldName)) {
        return this.renderBusinessRow({ editActive, userStore, fieldName, status, i });
      }
      if (fieldName === 'proprietor_is_beneficial_owner') {
        return this.renderBoolRow({ fieldName, status, i });
      }
      return this.renderRow({ editActive, userStore, fieldName, kybCustomerCanWrite, status, i });
    });
  }

  showApprovalModal() {
    if (this.state.showModal) {
      return (
        <ApprovalModal
          hideModal={this.toggleModal}
          noteChangeHandler={this.onSave}
          validatedFieldChanged={this.state.validatedFieldChanged}
          allFieldsSuccess={this.state.allFieldsSuccess}
        />
      );
    }
  }

  render() {
    const { editActive } = this.state;
    const { updateBusinessAllowedRoles, userStore = {}, kybStore, allSuccess } = this.props;
    const toggleEdit = () => this.setState({ editActive: !editActive });
    const { hasRoleInArrayForActiveProgram = () => {} } = userStore;
    const customerCanWrite = userStore.kybCustomerWrite && !allSuccess;
    const userCanWrite = userStore.kybCanWrite;
    const updateEditable =
      hasRoleInArrayForActiveProgram(updateBusinessAllowedRoles) &&
      kybStore.status !== 'SUCCESS' &&
      !editActive &&
      (customerCanWrite || userCanWrite);
    return (
      <div className={s.rows} data-testid="KYB-rows">
        {updateEditable && (
          <div data-testid="KYB-rows-edit-button" className={s.editIcon} onClick={toggleEdit}>
            <Icon type="edit" />
          </div>
        )}
        <div className={s.content}>{this.renderFields()}</div>
        {editActive && (
          <FooterButtons closeEditState={this.createFieldsState} onSave={this.checkForSave} />
        )}

        {this.showApprovalModal()}
      </div>
    );
  }
}

Row.propTypes = {
  updateBusinessAllowedRoles: PropTypes.array,
  userStore: PropTypes.object,
  kybStore: PropTypes.object,
  fields: PropTypes.object,
  allSuccess: PropTypes.boolean,
  hydrateBusinessStore: PropTypes.func,
};

Row.defaultProps = {
  updateBusinessAllowedRoles: [],
  userStore: {},
  kybStore: {},
  fields: {},
  allSuccess: false,
  hydrateBusinessStore: () => {},
};

export default Rows;
