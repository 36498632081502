import { action, decorate, observable } from 'mobx';
import gqlApi from './../apis/GqlApi.js';
import ParentStore from './ParentStore.js';
import { secureStorage } from '@mq/volt-amc-container';
import logger from 'utils/logger.js';
import gqlUtils from '@mqd/graphql-utils';
import { APPLICATION_PROPERTIES_COUNTRY_CODE } from '@mq/dispute-management';

const { templates } = gqlUtils;

export class ApplicationPropertiesStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
  }

  // values
  applicationPropertiesCountryCode = null;
  prevShortCode = null;

  async setApplicationPropertiesCountryCode(shortCode) {
    if (this.prevShortCode !== shortCode) {
      try {
        const {
          data: {
            applicationPropertiesProgramCountryCode: { application_properties_country_code },
          },
        } = await gqlApi.gqlQuery(templates.applicationPropertiesQuery, {});

        secureStorage.setItem(
          APPLICATION_PROPERTIES_COUNTRY_CODE,
          application_properties_country_code
        );
        this.applicationPropertiesCountryCode = application_properties_country_code;
      } catch (e) {
        logger.error(`Error fetching getApplicationProperties: ${e}`);
      }
    }
  }
}

decorate(ApplicationPropertiesStore, {
  // values
  applicationPropertiesCountryCode: observable,
  prevShortCode: observable,

  // actions
  setApplicationPropertiesCountryCode: action.bound,
});

const applicationPropertiesStore = new ApplicationPropertiesStore();

export default applicationPropertiesStore;
