import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import KYBStore from '../stores/KYBStore';
import { containerWidthHOC, ParentComponent } from '@mq/voltron-parent';
import { LoadingOverlay } from '@mqd/volt-base';
import Header from './header/Header';
import Body from './body/Body';
import KYBTimeline from './timeline/KYBTimeline';
import { VSpacer } from '@mqd/volt-base';
import s from './KYB.module.css';

class KYBTab extends ParentComponent {
  constructor(params) {
    super(params);
    this.storeConstructor = KYBStore;
    this.state = {
      status: '',
    };
    this.hydrateBusinessStore = this.hydrateBusinessStore.bind(this);
  }

  hydrateBusinessStore() {
    this.props.businessStore.hydrate();
    this.store.hydrate();
  }

  render() {
    const kybLog = this.store && this.store.kybLog ? this.store.kybLog : {};
    const loadingBusinessEntity =
      this.store && this.store.loadingBusinessEntity ? this.store.loadingBusinessEntity : false;
    const businessEntities = this.store.businessEntities || {};
    const businessStore = this.props.businessStore || {};
    const userStore = this.props.userStore || {};
    const business_name_legal = businessStore.business_name_legal || '';
    const business_name_dba = businessStore.business_name_dba || '';
    const updateBusinessAllowedRoles = businessStore.updateBusinessAllowedRoles || [];
    const isSoleProp = businessStore.isSoleProp || false;
    const kycManualOverride = businessStore.kycManualOverride || false;
    const vendorPending = businessStore.vendorPending || false;
    const kybPerformed = businessEntities && businessEntities.status !== '';

    return (
      <div className={s.kybTab}>
        <Header
          userStore={userStore}
          businessName={business_name_legal}
          businessEntities={businessEntities}
        />
        <VSpacer factor={3} />
        <LoadingOverlay active={loadingBusinessEntity}>
          {!loadingBusinessEntity && (
            <div className={s.kybContent} data-testid="kyb-tab">
              <Body
                businessEntities={businessEntities}
                updateBusinessAllowedRoles={updateBusinessAllowedRoles}
                kybStore={this.store}
                userStore={userStore}
                isSoleProp={isSoleProp}
                business_name_dba={business_name_dba}
                hydrateBusinessStore={this.hydrateBusinessStore}
                kycManualOverride={kycManualOverride}
                vendorPending={vendorPending}
              />
              {kybPerformed && <KYBTimeline kybLog={kybLog} />}
            </div>
          )}
        </LoadingOverlay>
      </div>
    );
  }
}

KYBTab.propTypes = {
  autoHydrate: PropTypes.bool,
  businessStore: PropTypes.any,
  userStore: PropTypes.any,
};

KYBTab.defaultProps = {
  autoHydrate: true,
  businessStore: {},
  userStore: {},
};

export default containerWidthHOC(observer(KYBTab));
