import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { PageLoader } from '@mqd/volt-base';
import { ProgramReserveStore } from '../../../../stores/program-reserve/program-reserve-store.js';
import { ProgramReserveContext } from '../../context/ProgramReserveContext.js';
import useProgramsList from '../../../../../../components/program-selector/useProgramsList.js';
import { useSortedCurrencyCodes } from './hooks/useSortedCurrencyCodes.js';

const ProgramReserveStoreProvider = ({ children }) => {
  const programReserveStore = useMemo(() => new ProgramReserveStore(), []);

  const programs = useProgramsList();
  const currencyCodes = useSortedCurrencyCodes();

  useEffect(() => {
    programReserveStore.setPrograms(programs);
  }, [programReserveStore, programs]);

  useEffect(() => {
    programReserveStore.setCurrencyCodes(currencyCodes);
  }, [programReserveStore, currencyCodes]);

  useEffect(() => {
    if (programReserveStore.programs.length > 0 && programReserveStore.currencyCodes.length > 0) {
      programReserveStore.setIsLoading(false);
    }
  }, [programReserveStore, programReserveStore.programs, programReserveStore.currencyCodes]);

  return (
    <ProgramReserveContext.Provider value={programReserveStore}>
      {programReserveStore.loading ? <PageLoader active /> : children}
    </ProgramReserveContext.Provider>
  );
};

export default observer(ProgramReserveStoreProvider);
