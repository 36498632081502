import React from 'react';
import PropTypes from 'prop-types';
import { progressBarColors } from './../../constants';
import { Colors, HSpacer, ProgressBar, Text } from '@mqd/volt-base';
import s from './KYBCard.module.css';

function CardProgressBar({ currentCount, totalCount }) {
  const { complete, incomplete } = progressBarColors;
  const foregroundColor = currentCount === totalCount ? Colors[complete] : Colors[incomplete];
  return (
    <div
      className={s.progressBarContainer}
      data-testid={`KYB-progress-bar-${currentCount}/${totalCount}`}
    >
      <ProgressBar
        height="8px"
        foregroundColor={foregroundColor}
        currentCount={currentCount}
        totalCount={totalCount}
      />
      <HSpacer factor={1.5} />
      <Text type="label">{`${currentCount}/${totalCount}`}</Text>
    </div>
  );
}

CardProgressBar.propTypes = {
  currentCount: PropTypes.number,
  totalCount: PropTypes.number,
};
CardProgressBar.defaultProps = {
  currentCount: 0,
  totalCount: 1,
};

export default CardProgressBar;
