import { decorate, observable } from 'mobx';
import ExpirationOffSetStore from './ExpirationOffSetStore';
import { ParentStore } from '@mq/voltron-parent';

class CardLifeCycleStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
    this.loadAndConstructItem('expiration_offset', args.expiration_offset, ExpirationOffSetStore);
  }
  // values
  activate_upon_issue: boolean = null;
  expiration_offset: ExpirationOffSetStore = {};
}

decorate(CardLifeCycleStore, {
  // values
  activate_upon_issue: observable,
  expiration_offset: observable,
});

export default CardLifeCycleStore;
