export const ENTRY_TYPE = {
  CREDIT: 'CREDIT',
  DEBIT: 'DEBIT',
};

export const ENTRY_TYPE_LABEL = {
  [ENTRY_TYPE.CREDIT]: 'Deposit',
  [ENTRY_TYPE.DEBIT]: 'Withdrawal',
};

export const TRANSACTION_STATUS = {
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
  PENDING: 'PENDING',
};

export const TRANSACTION_STATUS_LABEL = {
  [TRANSACTION_STATUS.FAILED]: 'Failed',
  [TRANSACTION_STATUS.PENDING]: 'Pending',
  [TRANSACTION_STATUS.SUCCEEDED]: 'Completed',
};
