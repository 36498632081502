import { PropTypes } from 'prop-types';
import React from 'react';
import { Flex, HSpacer, Text, Tooltip, Icon } from '@mqd/volt-base';
import { camelCase } from '../../utilities/string/index.js';
import { generateId } from '../TextColumn/utilities.js';
import s from './DetailsMetricsRow.module.css';
import useDetailsMetricsRow from './useDetailsMetricsRow.js';

const idPrefix = 'metrics-row';

const DetailsMetricsRow = ({ source, showSettledTotal, showSubmittedTotal, varianceType }) => {
  const metrics = useDetailsMetricsRow({
    showSettledTotal,
    source,
    showSubmittedTotal,
    varianceType,
  });
  return (
    <Flex justifyContent="space-between">
      {metrics.map(({ label, content, secondaryContent, tooltip }, i) => (
        <Flex display="flex" key={`topCard-${camelCase(label)}`} flex="0 0 25%">
          {i !== 0 && <div className={s.verticalRule} />}
          {i !== 0 && <HSpacer factor={4} />}
          <Flex flexDirection="column">
            <Flex flexDirection="row">
              <Text type="label" id={generateId(label, idPrefix)}>
                {label}
              </Text>
              {tooltip && (
                <div className={s.tooltipWrapper}>
                  <Tooltip content={tooltip} direction="topRight" textWrap>
                    <Icon type="info" hasTooltip />
                  </Tooltip>
                </div>
              )}
            </Flex>
            <Flex flexDirection="row">
              <Text type="h2" labelledBy={generateId(label, idPrefix)}>
                {content}
              </Text>
              <HSpacer factor={1} />
              {secondaryContent && (
                <Flex alignSelf="flex-end">
                  <Text type="footnote">{secondaryContent}</Text>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
};

DetailsMetricsRow.propTypes = {
  source: PropTypes.string,
  showSettledTotal: PropTypes.bool,
};

DetailsMetricsRow.defaultProps = {
  source: '',
  showSettledTotal: false,
};

export default DetailsMetricsRow;
