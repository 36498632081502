import type from './true-type/index.js';

const date = { format, parse };

const DATE_FORMAT_STRING = 'YYYY-MM-DD';
const DATE_TIME_FORMAT_STRING = 'YYYY-MM-DD HH:mm';

function format(date, includeTime) {
  // Create a date object from date.
  if (type(date) !== 'date') date = new Date(date);
  let time = '';
  if (includeTime) {
    time = [date.getHours(), ('0' + date.getMinutes()).slice(-2)].join(':');
  }

  // Return formatted date.
  return (
    [date.getFullYear(), twoDigit(date.getMonth() + 1), twoDigit(date.getDate())].join('-') +
    (includeTime ? ' ' + time : '')
  );
}

function parse(string, debug) {
  if (!string || string === 'null') {
    console.warn('No string to parse.');
    return;
  }
  string = string.split('-');
  return new Date(parseInt(string[0]), parseInt(string[1]) - 1, parseInt(string[2].slice(0, 2)));
}

function twoDigit(value) {
  return ('0' + value).slice(-2);
}

export default date;
export { format, parse, DATE_FORMAT_STRING, DATE_TIME_FORMAT_STRING };
