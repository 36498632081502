import { pathOr } from 'ramda';

/// utils
import { handleClick } from '../../../../utils/linkHelpers.js';
import {
  getMaskedAccountNumber,
  getMaskedCardNumber,
  getTxnSignedAmount,
  formatAmount,
  getFullLegalName,
  getDda,
} from '../utils/index.js';

// stores
import routerStore from 'stores/router/RouterStore';

/**
 * getBreadcrumbs - constructs crumbs prop for <Breadcrumbs /> component within DDA
 * @param path - array of strings, representing breadcrumbs sequence. e.g.
 *    Account holders / Karen McPeak (accountholder) / Account ••1234
 * @param token - cardholder token used in redirects when user clicks the crumb
 * @param data - GraphQL query result with cardholder object
 * @returns {[]}
 */
export const getBreadcrumbs = (path = ['accountholders'], token = null, data = {}, cardToken) => {
  const cardholderPath = ['cardholder'];
  if (data?.card) cardholderPath.unshift('card');
  if (data?.transaction) cardholderPath.unshift('transaction');

  const ddaPath =
    cardholderPath.length > 1
      ? [...cardholderPath, 'directDepositAccounts']
      : ['directDepositAccounts'];

  const invalidNode = {
    href: null,
    onClick: null,
  };

  // Account holder node
  const getAccountholderCrumb = () => {
    const accountholder = pathOr({}, cardholderPath, data);
    const fullLegalName = getFullLegalName(accountholder);

    return {
      children: fullLegalName,
      forQueryParams: `Program account holder:${fullLegalName}:${token}`,
      href: null,
      onClick: (e) =>
        handleClick(e, routerStore, { to: '/program/accountholder', params: { token } }),
    };
  };

  // Account node
  const getAccountCrumb = () => {
    const directDepositAccounts = pathOr({}, ddaPath, data);
    const display = getMaskedAccountNumber({ directDepositAccounts });
    if (!display) return invalidNode;

    const { token: accountToken } = getDda({ directDepositAccounts }) || {};
    return {
      children: display,
      forQueryParams: `Program account holder's account:${display}:${accountToken}`,
      href: null,
      onClick: !accountToken
        ? null
        : (e) =>
            handleClick(e, routerStore, {
              to: '/program/accountholder/account',
              params: { token: accountToken },
            }),
    };
  };

  // Card node
  const getCardCrumb = () => {
    const display = getMaskedCardNumber(data);
    if (!display) return invalidNode;
    return {
      children: display,
      forQueryParams: `Program account holder's card:${display}:${data?.card?.token}`,
      href: null,
      onClick: !cardToken
        ? null
        : (e) =>
            handleClick(e, routerStore, {
              to: '/program/accountholder/card',
              params: { token: cardToken },
            }),
    };
  };

  // Transaction node
  const getTransactionCrumb = () => {
    const txnSignedAmount = getTxnSignedAmount(data?.transaction);
    const txnCurrencyCode = data?.transaction?.currency_code;
    if (!data?.transaction || (!txnSignedAmount && !txnCurrencyCode)) return invalidNode;
    return {
      children: formatAmount(txnSignedAmount, txnCurrencyCode),
    };
  };

  const BREADCRUMBS_MAP = {
    accountholders: {
      children: 'Account holders',
      href: null,
      forQueryParams: `Program account holders:Account holders`,
      onClick: (e) => handleClick(e, routerStore, { to: '/program/accountholders' }),
    },
    accountholder: getAccountholderCrumb(),
    account: getAccountCrumb(),
    activate: { children: 'Activate' },
    suspend: { children: 'Suspend' },
    terminate: { children: 'Terminate' },
    card: getCardCrumb(),
    transaction: getTransactionCrumb(),
    activity: { children: 'Activity' },
  };

  const breadcrumbs = [];
  path.forEach((item) => {
    const crumb = BREADCRUMBS_MAP[item];
    if (crumb) {
      breadcrumbs.push(crumb);
    }
  });

  return breadcrumbs;
};
