import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Toggle, Tabs } from '@mqd/volt-base';
import { ErrorBannerAlert, ParentDetail, TabSelector } from '@mq/voltron-parent';
import CommandoModeStore from './../stores/CommandoModeStore';
import {
  VSpacer,
  HSpacer,
  ContentSnippet,
  Button,
  Container,
  Row,
  Col,
  Link,
  Modal,
  Textarea,
  Icon,
  Text,
} from '@mqd/volt-base';
import s from './CommandoModeDetail.module.css';
import CommandoModeInfoTab from './../commando-mode-info-tab/CommandoModeInfoTab';
import CommandoModeControlsTab from './../commando-mode-controls-tab/CommandoModeControlsTab';
import CommandoModeTransitionsTab from './../commando-mode-transitions-tab/CommandoModeTransitionsTab';
import { Status } from '@mq/voltron-parent';

class CommandoModeDetail extends ParentDetail {
  constructor(props) {
    super(props);
    this.storeConstructor = CommandoModeStore;
    this.state = {
      activeTab: this.props.currentTab,
      transitionReason: '',
      transitionsTableStore: {},
      loadedStores: {},
    };
    this.runTransition = this.runTransition.bind(this);
  }

  tabs = ['Details', 'Controls', 'Transition history'];

  renderBreadcrumb(name) {
    const { buildBreadcrumb } = this.props;
    if (!(buildBreadcrumb instanceof Function)) return null;
    const currentCrumb = {
      children: name || '· · ·',
    };
    return buildBreadcrumb(currentCrumb);
  }

  renderContent() {
    const { activeTab } = this.state;
    const { transitionsTableLocalStorageKey } = this.props;
    switch (activeTab) {
      case 'Details':
        return <CommandoModeInfoTab commandoModeStore={this.store} />;

      case 'Controls':
        return <CommandoModeControlsTab commandoModeStore={this.store} />;

      case 'Transition history':
        return (
          <CommandoModeTransitionsTab
            commandoModeStore={this.store}
            onStoreConstruction={(store) => {
              // const currentLoadedStores = this.state.loadedStores;
              const newLoadedStores = Object.assign({}, this.state.loadedStores, {
                Transitions: store,
              });
              this.setState({ loadedStores: newLoadedStores });
            }}
            localStorageKey={transitionsTableLocalStorageKey}
          />
        );

      default:
        return <CommandoModeInfoTab commandoModeStore={this.store} />;
    }
  }

  renderActiveController() {
    const { enabled_username, enabled_reason, enabled_channel } = this.store;
    return (
      <div className={`${s.block}`}>
        <Container>
          <Row>
            <div>
              <Text type="h6">Enabled by</Text>
              <Text type="p">{enabled_username}</Text>
            </div>
            <HSpacer factor="4" />
            <div>
              <Text type="h6">Enable channel</Text>
              <Text type="p">{enabled_channel}</Text>
            </div>
            <HSpacer factor="4" />
            <div>
              <Text type="h6">Enabled reason</Text>
              <Text type="p">{enabled_reason}</Text>
            </div>
          </Row>
        </Container>
      </div>
    );
  }

  async runTransition() {
    const { transitionReason, transitionsTableStore } = this.state;
    const { transition, enabled } = this.store;
    const { userStore = {} } = this.props;
    const { email = 'unset' } = userStore;

    this.setState({ transitionModalActive: false, transitionReason: '' });
    await transition({
      reason: transitionReason,
      commando_enabled: !enabled,
      username: email,
    });

    transitionsTableStore && transitionsTableStore.hydrate && transitionsTableStore.hydrate();
    this.props.onTransition();
  }

  renderTransitionModal() {
    const { transitionModalActive, transitionReason } = this.state;
    const { enabled, token } = this.store;

    if (!transitionModalActive) return null;
    return (
      <Modal
        type="lg"
        heading={`${enabled ? 'Disable' : 'Enable'} commando mode`}
        description="Enabling commando mode changes how your card program processes Gateway JIT funding
        requests. In commando mode, when your system cannot respond, Marqeta will process Gateway
        JIT funding requests according to business rules you define in a commando mode control
        set."
        footerButtons={[
          <Button onClick={this.runTransition}>{enabled ? 'Disable' : 'Enable'}</Button>,
        ]}
        hideModal={() => this.setState({ transitionModalActive: false })}
      >
        <Textarea
          label="Reason for enablement (optional)"
          value={transitionReason}
          onChange={(e) => this.setState({ transitionReason: e.target.value })}
          placeholder=""
          noResize={false}
        />
      </Modal>
    );
  }

  renderErrors() {
    const { loadedStores, activeTab } = this.state;
    if (activeTab === 'Details') {
      return <ErrorBannerAlert store={this.store} />;
    } else {
      return <ErrorBannerAlert store={loadedStores[activeTab]} />;
    }
  }

  render() {
    const { activeTab } = this.state;
    const { enabled, program_gateway_funding_source } = this.store;
    const { handleTabClick, canEditCommandoMode } = this.props;
    const name = program_gateway_funding_source && program_gateway_funding_source.name;
    return (
      <div style={{ width: '100%' }}>
        {this.renderErrors()}
        {this.renderBreadcrumb(name)}
        {this.renderTransitionModal()}
        <VSpacer factor={2} />
        <Text type="h3">{name}</Text>
        <VSpacer factor={1} />
        <Status textType="label" uppercase={true} status={enabled ? 'ENABLED' : 'DISABLED'} />
        {canEditCommandoMode && (
          <div className={s.toogle}>
            <Toggle
              type="default"
              active={enabled}
              disabled={false}
              onClick={() => this.setState({ transitionModalActive: true })}
            />
          </div>
        )}
        <VSpacer factor={4} />
        {this.renderActiveController()}
        <VSpacer factor={3} />
        <Tabs
          testId="commando-mode-detail-tab-selector"
          tabs={this.tabs}
          activeTab={activeTab}
          onTabChange={(activeTab) => {
            handleTabClick({ tab: activeTab });
            this.setState({ activeTab });
          }}
        />
        <VSpacer factor={2.75} />
        {this.renderContent()}
      </div>
    );
  }
}

CommandoModeDetail.propTypes = {
  storeInitArgs: PropTypes.object,
  store: PropTypes.object,
  userStore: PropTypes.object,
  autoHydrate: PropTypes.bool,
  onStoreConstruction: PropTypes.func,
  onTransition: PropTypes.func,
  buildBreadcrumb: PropTypes.array,
  currentTab: PropTypes.string,
  handleTabClick: PropTypes.func,
  transitionsTableLocalStorageKey: PropTypes.string,
};

CommandoModeDetail.defaultProps = {
  storeInitArgs: {},
  store: null,
  userStore: {},
  autoHydrate: true,
  onStoreConstruction: null,
  buildBreadcrumb: [],
  currentTab: 'Details',
  handleTabClick: () => {},
  onTransition: () => {},
  transitionsTableLocalStorageKey: 'commandoModeDetailTransitionsTableConfig',
};

export default observer(CommandoModeDetail);
