import CreditCapabilities from 'views/credit/CreditCapabilities/index.js';

export const requirements = {
  flipFlopRequirements: [],
};

export const verifier = () => {
  const key = 'hideCreditWelcomePage';
  const localStorageValue = localStorage.getItem(key);
  const hideCreditWelcomePage =
    localStorageValue === undefined ? localStorageValue : JSON.parse(localStorage.getItem(key));

  return !hideCreditWelcomePage && CreditCapabilities.canEnterBundlePage();
};
