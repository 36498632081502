import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { containerWidthHOC } from '@mq/voltron-parent';
import { VSpacer, Container, Row, Col, Card, Text } from '@mqd/volt-base';
import { flipFlop } from '@mq/volt-amc-container';
import { UAM_GRANULAR_PERMISSIONS } from '@mqd/mqd-constants';

import KycDetailsCard from '../shared-components/kyc-details-card/KycDetailsCard';
import KYBBanner from './../shared-components/kyb-banner/KYBBanner';
import BusinessBalanceCard from './../shared-components/balance-card/BalanceCard';
import BusinessInformationCard from './BusinessInformationCard';
import BusinessProprietorOrOfficerCard from './BusinessProprietorOrOfficerCard';
import BusinessIncorporationStatusCard from './BusinessIncorporationStatusCard';
import BusinessPrimaryContactCard from './BusinessPrimaryContactCard';

import { SUCCESS, CLOSED } from './../constants';

const { BUSINESS_DETAILS_VIEW_AND_EDIT } = UAM_GRANULAR_PERMISSIONS;

class BusinessInfoTab extends Component {
  renderKYBBanner(businessStore) {
    const {
      businessIdentityStatus,
      loading,
      status,
      kycManualOverride,
      vendorPending,
      kyb_active_for_business,
    } = businessStore;
    const showBanner =
      kyb_active_for_business &&
      businessIdentityStatus !== SUCCESS &&
      !loading &&
      status != CLOSED &&
      !kycManualOverride &&
      !vendorPending;
    if (showBanner) {
      return <KYBBanner type={businessIdentityStatus} />;
    } else {
      return null;
    }
  }

  render() {
    const isKybDetailsActive = flipFlop.get('kyc-kyb-details', false);
    let { businessStore, containerWidth, canRevealIdentification, userStore } = this.props;
    if (!businessStore) {
      businessStore = {};
    }
    let colSpan = 4;
    if (containerWidth <= 1200) {
      colSpan = 6;
    }
    if (containerWidth <= 800) {
      colSpan = 12;
    }

    const {
      updateBusinessAllowedRoles,
      createTransitionAllowedRoles,
      manualOverrideRoles,
      businessIdentityStatus,
      performManualOverride,
      kycDetails,
      kycData,
    } = businessStore;

    const {
      hasRoleInArrayForActiveProgram = () => {},
      redseaRoles,
      uamGranularPermissionsActive,
    } = userStore;
    let updateEditable =
      hasRoleInArrayForActiveProgram(updateBusinessAllowedRoles) &&
      (businessIdentityStatus === SUCCESS || businessIdentityStatus === '');
    let canMakeTransition = hasRoleInArrayForActiveProgram(createTransitionAllowedRoles);
    const canManualOverride = hasRoleInArrayForActiveProgram(manualOverrideRoles);

    if (uamGranularPermissionsActive) {
      const hasViewAndEditPermissions = redseaRoles.includes(BUSINESS_DETAILS_VIEW_AND_EDIT);

      canMakeTransition = hasViewAndEditPermissions;
      updateEditable = hasViewAndEditPermissions;
    }

    return (
      <div>
        {this.renderKYBBanner(businessStore)}
        <Container>
          <Row>
            <Col span={colSpan}>
              <BusinessInformationCard
                businessStore={businessStore}
                editable={updateEditable}
                canMakeTransition={canMakeTransition}
                kybActive={businessStore.kyb_active_for_business}
              />
              <BusinessBalanceCard store={businessStore} />
            </Col>
            <Col span={colSpan}>
              <BusinessPrimaryContactCard businessStore={businessStore} editable={updateEditable} />
              <VSpacer factor={2} />
              <BusinessProprietorOrOfficerCard
                canRevealIdentification={canRevealIdentification}
                businessStore={businessStore}
                editable={updateEditable}
                uamGranularPermissionsActive={uamGranularPermissionsActive}
              />
              {colSpan > 4 && [
                <VSpacer factor={2} />,
                <BusinessIncorporationStatusCard
                  businessStore={businessStore}
                  editable={updateEditable}
                />,
                isKybDetailsActive ? (
                  <Card contentPadding="16px 24px 24px 24px">
                    <Text type="h5">KYB details</Text>
                    <VSpacer factor={2} />
                    <KycDetailsCard
                      type="KYB"
                      canManualOverride={canManualOverride}
                      performManualOverride={performManualOverride}
                      latestKycResult={kycDetails}
                      kycData={kycData}
                    />
                  </Card>
                ) : null,
              ]}
            </Col>
            {colSpan === 4 && (
              <Col span={colSpan}>
                <BusinessIncorporationStatusCard
                  businessStore={businessStore}
                  editable={updateEditable}
                />
                {isKybDetailsActive ? (
                  <Card contentPadding="16px 24px 24px 24px">
                    <Text type="h5">KYB details</Text>
                    <VSpacer factor={2} />
                    <KycDetailsCard
                      type="KYB"
                      canManualOverride={canManualOverride}
                      performManualOverride={performManualOverride}
                      latestKycResult={kycDetails}
                      kycData={kycData}
                    />
                  </Card>
                ) : null}
              </Col>
            )}
          </Row>
        </Container>
      </div>
    );
  }
}

BusinessInfoTab.propTypes = {
  businessStore: PropTypes.any,
  canRevealIdentification: PropTypes.bool,
  userStore: PropTypes.any,
};

BusinessInfoTab.defaultProps = {
  businessStore: null,
  canRevealIdentification: false,
  userStore: {},
};

export default containerWidthHOC(observer(BusinessInfoTab));
