import { JOB_STATES, SANDBOX_PROVISIONING_STEPS } from './constants.js';
import { googleAnalytics } from '@mq/volt-amc-container';
import gql from 'graphql-tag';
import { append, applySpec, concat, pathOr } from 'ramda';

/* * * D E P R E C A T E D begin * * */

import gqlApi from 'apis/GqlApi';

/** @deprecated */
const getUserSandboxData = async () => {
  const queryResponse = await effectfullyFetchUserSandboxes();
  const sandboxData = extractSandboxData(queryResponse);
  return compileLatestStatus(sandboxData);
};

const effectfullyFetchUserSandboxes = () =>
  gqlApi.gqlQuery(`query {
    currentUser {
      first_name
      email
      scopes
      roles {
        domain_type
        domain_id
        role_token
      }
      sandboxes {
        data {
          token
          display_name
          short_code
          jobs {
            data {
              token
              state
              estimated_completion_time
              created_time
              retry_delay_timeout_in_minutes
              remaining_retry_attempts
            }
          }
          credentials {
            data {
              access_token
              application_token
            }
          }
        }
      }
    }
  }`);

/* * * end D E P R E C A T E D * * */

const { QUEUED, SPINUP, CONFIGURE, PROVISION, COMPLETED, ERROR, RETRY } =
  SANDBOX_PROVISIONING_STEPS;

const CURRENT_USER_SANDBOX_QUERY = gql`
  query {
    currentUser {
      first_name
      email
      scopes
      roles {
        domain_type
        domain_id
        role_token
      }
      sandboxes {
        data {
          token
          display_name
          short_code
          jobs {
            data {
              token
              state
              estimated_completion_time
              created_time
              retry_delay_timeout_in_minutes
              remaining_retry_attempts
            }
          }
          credentials {
            data {
              access_token
              application_token
            }
          }
        }
      }
    }
  }
`;

const CREATE_SANDBOX_MUTATION = gql`
  mutation {
    createSandbox {
      token
      display_name
      jobs {
        data {
          state
          estimated_completion_time
          created_time
        }
      }
    }
  }
`;

const CREATE_CUSTOM_SANDBOX_MUTATION = gql`
  mutation createCustomSandbox(
    $issuingRegion: String!
    $transactionRegions: [String]!
    $transactionApprover: String!
    $endUser: String!
    $cardUsages: [String]!
  ) {
    createCustomSandbox(
      issuing_region: $issuingRegion
      transaction_regions: $transactionRegions
      transaction_approver: $transactionApprover
      end_user: $endUser
      card_usages: $cardUsages
    ) {
      token
      display_name
      jobs {
        data {
          state
          estimated_completion_time
          created_time
        }
      }
    }
  }
`;

const RESTART_SANDBOX_JOB_MUTATION = gql`
  mutation restartSandboxJob($jobToken: String!) {
    restartSandboxJob(token: $jobToken) {
      state
    }
  }
`;

const emptyCredentials = () => ({});
const emptyJob = () => ({});

const getCredentials = (sandboxData) => {
  const creds = extractCredentials(sandboxData);
  return transformCredentials(creds);
};

const getJob = (sandboxData) => {
  const jobData = extractJob(sandboxData);
  return transformJob(jobData);
};

const _pathToSandboxData = ['currentUser', 'sandboxes', 'data', '0'];
const _pathToCredentials = concat(_pathToSandboxData, ['credentials', 'data', '0']);
const getSandboxDataFromCurrentUser = applySpec({
  accessToken: pathOr(null, append('access_token', _pathToCredentials)),
  applicationToken: pathOr(null, append('application_token', _pathToCredentials)),
  shortCode: pathOr(null, append('short_code', _pathToSandboxData)),
});

const getSandboxShortCode = (userSandboxData) => {
  return pathOr(null, ['currentUser', 'sandboxes', 'data', '0', 'short_code'], userSandboxData);
};

const extractCredentials = (sandboxData) => {
  try {
    return sandboxData.credentials.data[0] || emptyCredentials();
  } catch (e) {
    return emptyCredentials();
  }
};

const transformCredentials = (creds) => {
  const { application_token: appToken, access_token: accessToken } = creds;
  const transformed = {};
  if (appToken) transformed.appToken = appToken;
  if (accessToken) transformed.accessToken = accessToken;
  return transformed;
};

const extractJob = (sandboxData) => {
  try {
    return sandboxData.jobs.data[0] || emptyJob();
  } catch (e) {
    return emptyJob();
  }
};

const transformJob = (jobData) => {
  const {
    token,
    state,
    estimated_completion_time: eta,
    created_time: createdTime,
    remaining_retry_attempts: remainingRetryAttempts,
    retry_delay_timeout_in_minutes: retryDelayTimeoutInMinutes,
  } = jobData;
  const transformed = {};
  if (token) transformed.token = token;
  if (state) transformed.state = state;
  if (remainingRetryAttempts) {
    transformed.remainingRetryAttempts = remainingRetryAttempts;
  }
  if (retryDelayTimeoutInMinutes) {
    transformed.retryDelayTimeoutInMinutes = retryDelayTimeoutInMinutes;
  }
  if (eta) transformed.eta = new Date(eta);
  if (createdTime) transformed.createdTime = new Date(createdTime);
  return transformed;
};

const credentialsExist = (credentials) =>
  credentials &&
  credentials.hasOwnProperty('appToken') &&
  credentials.hasOwnProperty('accessToken');

const userHasSandbox = ({ job, credentials }) =>
  jobIsComplete(job) && credentialsExist(credentials);

const jobIsFailed = (job) => job && job.state === JOB_STATES.FAILED;
const jobIsComplete = (job) => job && job.state === JOB_STATES.COMPLETED;
const jobIsPending = (job) => job && job.state === JOB_STATES.PENDING;
const jobIsRunning = (job) =>
  job && [JOB_STATES.WAITING, JOB_STATES.PENDING, JOB_STATES.FAILED].includes(job.state);

const getErrors = (sandboxData) => {
  if (!sandboxData) {
    return ['Error: no data returned.'];
  } else if (sandboxData.errors) {
    return sandboxData.errors;
  } else {
    return [];
  }
};

const extractSandboxData = (responseData) => {
  try {
    const isUndefined = typeof responseData === 'undefined';
    if (isUndefined) return { errors: 'No data received from API.' };
    const { data } = responseData;
    const isCurrentUser = data.hasOwnProperty('currentUser');
    const isCreateSandbox = data.hasOwnProperty('createSandbox');
    if (isCurrentUser) {
      return data.currentUser.sandboxes.data[0] || {};
    } else if (isCreateSandbox) {
      return data.createSandbox || {};
    }
  } catch (e) {
    return {};
  }
};

const compileLatestStatus = (sandboxData) => {
  const credentials = getCredentials(sandboxData);
  const job = getJob(sandboxData);
  const errors = getErrors(sandboxData);
  return { credentials, job, errors };
};

const defaultPosition = () => new Date().toString();
const progressFraction = (start, end, position = defaultPosition()) => {
  const date = (d) => new Date(d);
  const totalTime = date(end) - date(start);
  const timeElapsed = date(position) - date(start);
  return timeElapsed / totalTime;
};

const stepFromJob = ({ state, createdTime, eta, remainingRetryAttempts }) => {
  if (state === JOB_STATES.FAILED) return ERROR;
  if (state === JOB_STATES.WAITING) return remainingRetryAttempts ? RETRY : QUEUED;
  if (state === JOB_STATES.COMPLETED) return COMPLETED;

  const progress = progressFraction(createdTime, eta);
  const steps = [SPINUP, CONFIGURE, PROVISION];
  const stepIndex = Math.floor(progress * steps.length);

  return steps[stepIndex] || SPINUP;
};

const laterJobStep = (a, b) => {
  const steps = [QUEUED, SPINUP, CONFIGURE, PROVISION];
  return steps.indexOf(a) < steps.indexOf(b) ? b : a;
};

const mockWaitingJob = () => {
  return { state: JOB_STATES.WAITING };
};

const obscureText = (text) => '•'.repeat(text.length) || '';

const setSandboxGA = ({ action, label, isError = false }) => {
  if (googleAnalytics) {
    const category = isError ? 'Development Errors' : 'Development';
    googleAnalytics.event({
      category: category,
      label: label,
      action: action,
    });
  }
};

const setSegmentTracking = (event) => {
  window.analytics && window.analytics.track(event);
};

const setSegmentExternalId = (redsea_token, appToken) => {
  window.analytics &&
    window.analytics.identify(
      redsea_token,
      {},
      {
        externalIds: [
          {
            id: appToken,
            type: 'application_token',
            collection: 'users',
            encoding: 'none',
          },
        ],
      }
    );
};

/** @deprecated USER_SANDBOX_QUERY -- renamed to CURRENT_USER_SANDBOX_QUERY */
const USER_SANDBOX_QUERY = CURRENT_USER_SANDBOX_QUERY;

export {
  CREATE_CUSTOM_SANDBOX_MUTATION,
  CREATE_SANDBOX_MUTATION,
  CURRENT_USER_SANDBOX_QUERY,
  RESTART_SANDBOX_JOB_MUTATION,
  USER_SANDBOX_QUERY, // D E P R E C A T E D
  compileLatestStatus, // new, no tests
  credentialsExist,
  emptyCredentials,
  emptyJob,
  extractSandboxData, // new, no tests
  getCredentials,
  getUserSandboxData, // D E P R E C A T E D
  getSandboxDataFromCurrentUser,
  getSandboxShortCode,
  jobIsComplete,
  jobIsFailed,
  jobIsPending,
  jobIsRunning,
  laterJobStep,
  mockWaitingJob,
  obscureText,
  stepFromJob,
  setSandboxGA,
  setSegmentTracking,
  setSegmentExternalId,
  userHasSandbox,
};
