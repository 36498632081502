const exceptionTypes = {
  OVERPOSTED: 'Overpost',
  FAILED_TO_POST: 'Fail to post',
  OTHER: 'Other',
  WRITE_OFF: 'Write-off',
  MANUALLY_RECONCILE: 'Manually reconciled',
  INVALID_EXCEPTION: 'Invalid',
  EXCLUDE_EXCEPTION: 'Excluded',
};

export default exceptionTypes;

export const SORTED_TYPES = [
  'FAILED_TO_POST',
  'OVERPOSTED',
  'WRITE_OFF',
  'MANUALLY_RECONCILE',
  'INVALID_EXCEPTION',
  'EXCLUDE_EXCEPTION',
  'OTHER',
];
