const transactionDetailsRoutes = [
  {
    path: '/program/user/transaction',
    name: 'Cardholder transaction details',
    importer: () =>
      import(/*webpackChunkName: "User transaction details" */ '../ProgramView/ProgramView'),
  },
];

export default transactionDetailsRoutes;
