import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import s from './CardCreationDetail.module.css';
import {
  Text,
  Button,
  Icon,
  HSpacer,
  VSpacer,
  FileUpload,
  Modal,
  Hr,
  LoadingOverlay,
} from '@mqd/volt-base';
import { ParentComponent, SegmentTrackHOC } from '@mq/voltron-parent';
import BulkUploadStore from '../stores/BulkUploadStore';

class BulkCreate extends ParentComponent {
  constructor(props) {
    super(props);
    this.storeConstructor = BulkUploadStore;
    this.state = {
      renderSuccess: false,
    };

    this.triggerSuccess = this.triggerSuccess.bind(this);
  }

  triggerSuccess() {
    this.setState({ renderSuccess: true });
  }

  renderFooterButtons() {
    const { goToHome } = this.props;
    return [<Button onClick={goToHome}>Take me home</Button>];
  }

  render() {
    const { bfpInstructions, bfpTemplate, uploadBFPFile, setAttr, file, loading } = this.store;
    const { renderSuccess } = this.state;
    const { goBack, goToBulkResponse, testId } = this.props;
    const fileName = file && file.name;

    return (
      <LoadingOverlay active={loading}>
        <div data-testid={testId}>
          {renderSuccess && (
            <Modal
              type="sm"
              hide={!renderSuccess}
              maxHeightVh={220}
              heading="Bulk creation processing"
              footerButtons={[<Button onClick={goToBulkResponse}>View bulk response</Button>]}
              handleClose={() => this.setState({ renderSuccess: false })}
            >
              <Text type="body-sm">
                The status of your CSV upload will be ready in <br />
                20-30 minutes. Find the status under the bulk <br />
                creation response page.
              </Text>
            </Modal>
          )}
          <div className={s.flexDivSpaceBetween}>
            <Text type="h3" testId="card-creation-header">
              Bulk create card
            </Text>
            <div className={s.flexDiv}>
              <Button
                testId={`download-csv-template-button_${bfpTemplate ? 'active' : 'inactive'}`}
                type="outline"
                disabled={!bfpTemplate}
              >
                <Icon type="arrow-down" />
                <a target="_blank" href={bfpTemplate} download rel="noreferrer">
                  Download CSV template
                </a>
              </Button>
              <HSpacer factor={2} />
              <Button
                testId={`download-csv-instruction-button_${bfpTemplate ? 'active' : 'inactive'}`}
                type="outline"
                disabled={!bfpInstructions}
              >
                <Icon type="arrow-down" />
                <a target="_blank" href={bfpInstructions} download rel="noreferrer">
                  Download CSV instructions
                </a>
              </Button>
            </div>
          </div>
          <Text>Note that bulk creation can be done with more than one card product. </Text>
          <VSpacer factor={2} />
          <FileUpload
            placeholder="Select file to upload"
            acceptedFileTypes=".csv"
            width={741}
            displayValue={fileName}
            handleFiles={(file) => file && setAttr('file', file)}
          />
          <VSpacer factor={2} />
          <Hr />
          <VSpacer factor={2} />
          <div className={s.flexStart}>
            <Button type="outline" onClick={goBack}>
              Back
            </Button>
            <HSpacer factor={2} />
            <SegmentTrackHOC eventName="Bulk Cards Created">
              <Button
                testId={`create-card-button_${fileName ? 'active' : 'inactive'}`}
                disabled={!fileName}
                onClick={() => uploadBFPFile(this.triggerSuccess)}
              >
                Create cards
              </Button>
            </SegmentTrackHOC>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

BulkCreate.propTypes = {
  autoHydrate: PropTypes.bool,
  testId: PropTypes.string,
  goToHome: PropTypes.func,
};

BulkCreate.defaultProps = {
  autoHydrate: true,
  testId: 'card-bulk-create-tile',
  goToHome: () => {},
};

export default observer(BulkCreate);
