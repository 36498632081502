import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import s from './ReviewDetails.module.css';
import {
  Modal,
  VSpacer,
  RadioGroup,
  Textarea,
  Button,
  HSpacer,
  Text,
  Multiselect,
  CopyToClipboard,
  ToastAlert,
} from '@mqd/volt-base';
import { TRANSITION_REASON_CODES } from './constants';
import { canEdit } from './helpers';

class ReviewHeader extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showCloseModal: false,
      showOpenReviewModal: false,
      showTransitionModal: false,
      selectedKycStatus: '',
      closeNote: '',
      transitionNote: '',
      selectedReasonCodes: [],
      showToast: false,
      toastIcon: 'success',
      toastMessage: '',
    };

    this.openReview = this.openReview.bind(this);
    this.closeReview = this.closeReview.bind(this);
    this.closeTransitionModal = this.closeTransitionModal.bind(this);
    this.renderTransitionModal = this.renderTransitionModal.bind(this);
    this.renderOpenReviewModal = this.renderOpenReviewModal.bind(this);
    this.renderCloseModal = this.renderCloseModal.bind(this);
  }

  renderBreadcrumb() {
    const { buildBreadcrumb } = this.props;

    if (!(buildBreadcrumb instanceof Function)) return null;
    const { token } = this.props.reviewStore;
    const currentCrumb = {
      children: `${token && token.substr(0, 12)}...`,
    };
    return buildBreadcrumb(currentCrumb);
  }

  async closeReview() {
    const { internalUser } = this.props;
    const reviewStatus = internalUser ? this.state.selectedKycStatus : 'DECLINED';
    const reviewDispositionResult = this.props.updateReviewDisposition(
      reviewStatus,
      this.state.closeNote
    );

    const toastIcon = reviewDispositionResult ? 'success' : 'error';
    const toastMessage = reviewDispositionResult ? 'Review closed' : 'Review not closed';

    this.setState({
      showCloseModal: false,
      closeNote: '',
      showToast: true,
      toastIcon: toastIcon,
      toastMessage: toastMessage,
    });
    setTimeout(() => {
      this.setState({ showToast: false });
    }, 6100);
  }

  openReview() {
    this.props.changeReviewStatus('IN_PROGRESS', this.state.transitionNote);
    this.setState({ showOpenReviewModal: false, transitionNote: '' });
  }

  async closeTransitionModal() {
    const transitionCodes = this.state.selectedReasonCodes.map((reason) =>
      Object.keys(TRANSITION_REASON_CODES).find(
        (key) => TRANSITION_REASON_CODES[key].label === reason
      )
    );
    const transitionResult = await this.props.changeReviewTeam(
      transitionCodes,
      this.state.transitionNote
    );
    const toastIcon = transitionResult ? 'success' : 'error';
    const toastMessage = transitionResult ? 'Review transitioned' : 'Review not transitioned';

    this.setState({
      showTransitionModal: false,
      transitionNote: '',
      showToast: true,
      toastIcon: toastIcon,
      toastMessage: toastMessage,
    });
    setTimeout(() => {
      this.setState({ showToast: false });
    }, 6100);
  }

  openModal(status) {
    status === 'CLOSED'
      ? this.setState({ showOpenReviewModal: true })
      : this.setState({ showCloseModal: true });
  }

  renderTransitionModal() {
    const { internalUser, transitionReasonCodes } = this.props;
    const { selectedReasonCodes, transitionNote } = this.state;
    const transitionTo = !internalUser ? 'Marqeta' : 'customer';
    const transitionDisabled = internalUser && selectedReasonCodes.length === 0;
    const transitionMessage = internalUser
      ? 'This will transition the review to the customer. After transitioning, no changes can be made to this review.'
      : 'You can no longer make changes after transitioning this review to Marqeta.';
    const availableTransitionCodes = transitionReasonCodes.map(
      (code) => TRANSITION_REASON_CODES[code].label
    );

    return (
      <Modal
        type="sm"
        disableOverFlow={true}
        heading={`Transition to ${transitionTo}`}
        description={transitionMessage}
        hideModal={() => this.setState({ showTransitionModal: false })}
        hideModalButtonText="Cancel"
        footerButtons={[
          <Button
            testId="transition-modal-button"
            onClick={this.closeTransitionModal}
            disabled={transitionDisabled}
          >{`Transition to ${transitionTo}`}</Button>,
        ]}
      >
        {internalUser && (
          <>
            <VSpacer />
            <Multiselect
              testId="transition-reason-code"
              collapseSelected={true}
              disabled={false}
              dropDownWrap={{
                width: '300px',
              }}
              fixOverflow={false}
              label="Select reason"
              labelSize={'small'}
              labelOptional={false}
              onOptionClick={(option) => {
                let selected = selectedReasonCodes.slice();
                if (selected.includes(option)) {
                  selected = selected.filter((s) => s !== option);
                } else {
                  selected.push(option);
                }
                this.setState({ selectedReasonCodes: selected });
              }}
              options={availableTransitionCodes}
              placeholder="Select from options"
              selected={selectedReasonCodes}
              showSearch={false}
              sublabel=""
            />
          </>
        )}
        <VSpacer factor={2} />
        <Textarea
          label="Add note"
          testId="transition-note"
          value={transitionNote}
          onChange={(e) => this.setState({ transitionNote: e.target.value })}
        />
      </Modal>
    );
  }

  renderOpenReviewModal() {
    return (
      <Modal
        type="sm"
        heading="Open review"
        description="Taking this action will reopen the review and may change the associated account and KYC status."
        hideModal={() => this.setState({ showOpenReviewModal: false })}
        hideModalButtonText="Cancel"
        footerButtons={[<Button onClick={this.openReview}>Open review</Button>]}
      >
        <Textarea
          label="Add note (optional)"
          value={this.state.transitionNote}
          onChange={(e) => this.setState({ transitionNote: e.target.value })}
        />
      </Modal>
    );
  }

  renderCloseModal() {
    const { statusOptions, kycStatus, internalUser } = this.props;
    const { selectedKycStatus, closeNote } = this.state;
    const reviewStatusOnClose = kycStatus === 'SUCCESS' ? 'Success' : 'Failure';

    return (
      <Modal
        width={400}
        heading="Close review"
        description="You can no longer make changes after closing this review."
        hideModal={() => this.setState({ showCloseModal: false })}
        hideModalButtonText="Cancel"
        footerButtons={[
          <Button
            testId="close-review-btn"
            disabled={selectedKycStatus === ''}
            onClick={this.closeReview}
          >
            Close review
          </Button>,
        ]}
      >
        {internalUser ? (
          <>
            <VSpacer />
            <RadioGroup
              value={selectedKycStatus}
              label="KYC status"
              groupItems={statusOptions}
              onChange={(value) => this.setState({ selectedKycStatus: value })}
            />
          </>
        ) : (
          <>
            <Text type="h6">KYC status</Text>
            <VSpacer />
            <Text type="body-sm">{reviewStatusOnClose}</Text>
          </>
        )}

        <VSpacer factor={2} />
        <Textarea
          label="Add note"
          value={closeNote}
          onChange={(e) => this.setState({ closeNote: e.target.value })}
        />
      </Modal>
    );
  }

  render() {
    const { token, status, team } = this.props.reviewStore || {};
    const { internalUser, dispositions, canEditReview, canViewActionButtons } = this.props;
    const {
      showOpenReviewModal,
      showCloseModal,
      showTransitionModal,
      showToast,
      toastMessage,
      toastIcon,
    } = this.state;

    const transitionTo = !internalUser ? 'Marqeta' : 'customer';
    const disableTransitionButtton =
      status === 'CLOSED' || !canEdit(internalUser, team) || !canEditReview;
    const disableCloseButton =
      !canEdit(internalUser, team) ||
      !dispositions.length ||
      (status === 'CLOSED' && !internalUser) ||
      !canEditReview;

    return (
      <div className={s.header}>
        <div className={s.headerText}>
          {this.renderBreadcrumb() || <span />}
          <Text type="h3" inline={true}>
            {`${token && token.substr(0, 12)}...`}
            <HSpacer factor={2} />
            <span className="copyToClipboardIcon">
              <CopyToClipboard overrideValue={token} largeSize={true} verticalAlignment="unset" />
            </span>
          </Text>
        </div>
        <div className={internalUser ? s.controlsContainer : s.controlsContainerCustomer}>
          {canViewActionButtons && (
            <div data-testid="transition-to-other-team-btn">
              <Button
                type={internalUser ? 'tertiary' : 'primary'}
                onClick={() => this.setState({ showTransitionModal: true })}
                disabled={disableTransitionButtton}
              >
                {`Transition to ${transitionTo}`}
              </Button>
            </div>
          )}

          <HSpacer factor={2} />
          {canViewActionButtons && (
            <div data-testid="open-close-review-btn">
              <Button
                type={internalUser && status !== 'CLOSED' ? 'primary' : 'tertiary'}
                disabled={disableCloseButton}
                onClick={() => this.openModal(status)}
              >
                {status === 'CLOSED' ? 'Open review' : 'Close review'}
              </Button>
            </div>
          )}
        </div>
        {showCloseModal && this.renderCloseModal()}
        {showOpenReviewModal && this.renderOpenReviewModal()}
        {showTransitionModal && this.renderTransitionModal()}
        {showToast && <ToastAlert icon={toastIcon}>{toastMessage}</ToastAlert>}
      </div>
    );
  }
}

ReviewHeader.propTypes = {
  buildBreadcrumb: PropTypes.func,
  canEditReview: PropTypes.bool,
  canViewActionButtons: PropTypes.bool,
  changeReviewStatus: PropTypes.func,
  changeReviewTeam: PropTypes.func,
  dispositions: PropTypes.array,
  internalUser: PropTypes.bool,
  kycStatus: PropTypes.string,
  statusOptions: PropTypes.array,
  transitionReasonCodes: PropTypes.array,
  updateReviewDisposition: PropTypes.func,
};

ReviewHeader.defaultProps = {
  buildBreadcrumb: () => {},
  canEditReview: false,
  canViewActionButtons: false,
  changeReviewStatus: () => {},
  dispositions: [],
  internalUser: false,
  kycStatus: 'PENDING',
  statusOptions: [],
  transitionReasonCodes: [],
  updateReviewDisposition: () => {},
};

export default observer(ReviewHeader);
