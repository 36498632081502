import currentUserStore from 'stores/CurrentUserStore';
export const requirements = {
  requiredSupplements: ['Stories'],
};
export const verifier = () => {
  const hasStoriesSupplement =
    currentUserStore.userStore.supplements &&
    currentUserStore.userStore.supplements.includes('Stories');
  return hasStoriesSupplement;
};
