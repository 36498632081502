import { computed, decorate, observable, action, runInAction, toJS } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';
import gqlUtils from '@mqd/graphql-utils';
import KYCStore from './KYCStore';
import { compareByEmail, formatActivityLog } from '../reviews-table/utils';

const { fragments } = gqlUtils;
const FormData = require('form-data');

class ReviewStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
  }

  // values
  token: String = '';
  status: String = '';
  customerName: String = '';
  program_short_code: String = '';
  user_token: String = '';
  kycStatus: String = 'PENDING';
  dateCreated: String = '';
  lastUpdated: String = '';
  agent: String = 'Unassigned';
  marqetaCaseAgent: String = 'Unassigned';
  loading: Boolean = true;
  loadingReview: Boolean = true;
  loadingReviews: Boolean = false;
  agentsList: Array<Object> = [];
  initialLoad: Boolean = true;
  marqetaAgentOptions: Array<String> = [];
  kycDetails: KYCStore = {};
  kycDetailsOriginal: Object = {};
  team: String = '';
  key: String = '';
  activityLog: Array<Object> = [];
  transitionReasonCodes: Array<String> = [];
  dispositionReasonCodes: Array<String> = [];
  dispositions: Array<String> = [];
  statuses: Array<String> = [];
  reviewDocuments: Array<Object> = [];
  missingNewDocument: Boolean = false;

  async hydrate(token) {
    try {
      const tokenParam = token || this.queryParams.token.val;
      this.token = tokenParam;
      this.loading = true;
      await Promise.all([
        this.initialLoad && this.getAgents(),
        this.initialLoad && this.getReviewDocuments(),
        this.getReview(tokenParam),
      ]);
    } catch (e) {
      console.error('Error fetching review: ', e);
    } finally {
      this.loading = false;
    }
  }

  async getReview(tokenParam) {
    const payload = { reviewToken: tokenParam };
    try {
      const result = await this.gqlQuery(
        `
          query kycReviewDetails ($reviewToken: ID!) {
            kycReviewDetails (reviewToken: $reviewToken) {
              ...kycReviewDetailsInfo
            }
          }
          ${fragments.kycReviewDetailsInfo}
        `,
        payload
      );

      runInAction(() => {
        const data = this.dig(result, 'data', 'kycReviewDetails');
        if (data) {
          const alert = this.dig(data, 'alert');
          const assignment = this.dig(data, 'assignment');
          const history = this.dig(data, 'history');
          const availableValues = this.dig(data, 'available_values');

          this.status = (data && data.status) || '';
          this.lastUpdated = data && data.last_modified_time;
          this.dateCreated = data && data.created_time;
          this.kycStatus = (alert && alert.kyc_status) || 'NOT_PERFORMED';
          this.program_short_code = alert && alert.program_short_code;
          this.user_token = alert && alert.user_token;
          this.kycDetailsOriginal = this.cleanKYCDetails(alert.kyc_identity);
          this.loadAndConstructItem(
            'kycDetails',
            JSON.parse(JSON.stringify(this.kycDetailsOriginal)),
            KYCStore
          );
          this.team = (assignment && assignment.team) || '';
          this.marqetaCaseAgent =
            assignment && assignment.marqeta_agent && assignment.marqeta_agent.email;
          this.agent = assignment && assignment.customer_agent && assignment.customer_agent.email;
          this.activityLog =
            history && [...formatActivityLog(history, toJS(this.agentsList))].reverse();
          this.transitionReasonCodes =
            availableValues &&
            availableValues.transition_reason_codes &&
            availableValues.transition_reason_codes.slice();
          this.dispositionReasonCodes =
            (availableValues && availableValues.disposition_reason_codes) || [];
          this.dispositions =
            availableValues && availableValues.dispositions && availableValues.dispositions.slice();
          this.statuses =
            availableValues && availableValues.statuses && availableValues.statuses.slice();
        }
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.key = Date.now();
      this.loadingReview = false;
    }
  }

  async updateKYCDetails(entityFieldsObject: Object, note, isUserInternal, getReview = true) {
    this.loadingReview = true;
    const changedParams = this.prepareEntityToSave(entityFieldsObject, isUserInternal);
    const graphQLCall = !isUserInternal
      ? 'updateControlPlaneReviewCustomer'
      : 'updateControlPlaneReviewInternal';
    const graphQLExpectedType = !isUserInternal
      ? 'IdentityCheckPayloadCustomer'
      : 'IdentityCheckPayloadInternal';
    const updatedEntities = !isUserInternal
      ? { kyc_user: changedParams, note: note }
      : { kyc_identity: changedParams, note: note };

    try {
      const payload = {
        reviewToken: this.token,
        updatedEntities: updatedEntities,
      };

      const result = await this.gqlMutation(
        `
          mutation ${graphQLCall}(
            $reviewToken:ID!
            $updatedEntities:${graphQLExpectedType}
          ){
            ${graphQLCall}(
              reviewToken:$reviewToken
              updatedEntities:$updatedEntities
            )
          }
          `,
        payload
      );
      return runInAction(() => {
        if (result && getReview) {
          //if we are closing the review at the same time we don't want to get the review twice
          this.getReview(this.token);
        }

        return !!result;
      });
    } catch (error) {
      return false;
    } finally {
      this.loadingReview = false;
    }
  }

  prepareEntityToSave(entityFieldsObject, isUserInternal) {
    const paramsObj = {};
    const entityFields = [
      'first_name',
      'middle_name',
      'last_name',
      'address1',
      'address2',
      'city',
      'state',
      'postal_code',
      'country',
      'ssn',
      'birth_date',
    ];

    entityFields.forEach((key) => {
      if (
        entityFieldsObject[key].status !== this.kycDetailsOriginal[key].status ||
        entityFieldsObject[key].value !== this.kycDetailsOriginal[key].value
      ) {
        paramsObj[key] = isUserInternal
          ? {
              status: entityFieldsObject[key].status,
              value: entityFieldsObject[key].value,
            }
          : entityFieldsObject[key].value;
      }
    });

    return paramsObj;
  }

  async updateReviewDisposition(disposition, note) {
    const payload = {
      reviewToken: this.token,
      disposition_value: disposition,
      disposition_note: note,
      disposition_reason_codes: this.dispositionReasonCodes,
    };
    try {
      this.loadingReview = true;
      const outerParams = `($reviewToken: ID!, $disposition_value: String, $disposition_note: String, $disposition_reason_codes: [String])`;
      const innerParams = `(reviewToken: $reviewToken, disposition_value: $disposition_value, disposition_note: $disposition_note, disposition_reason_codes: $disposition_reason_codes)`;
      const result = await this.gqlMutation(
        `
          mutation updateReviewDisposition${outerParams} {
            updateReviewDisposition${innerParams} {
              disposition_value
              review_token
            }
          }
        `,
        payload
      );
      if (!result) throw 'Update failed';
      this.getReview(this.token);
    } catch (error) {
      console.error(error);
    } finally {
      this.loadingReview = false;
      return true;
    }
  }

  updateAgentForReview = async (agentType, option) => {
    this[agentType] = option;
    const agent = this.agentsList.find((agent) => agent.email === option);
    await this.assignReviewToAgent(agent.token);
  };

  async assignReviewToAgent(agent_token, transition_note = '') {
    const payload = { agent_token, transition_note, reviewTokens: [this.token] };
    try {
      this.loading = true;
      const result = await this.gqlMutation(
        `
          mutation assignReviewToAgent ($agent_token: String, $transition_note: String, $reviewTokens: [ID]) {
            assignReviewToAgent (agent_token: $agent_token, transition_note: $transition_note, reviewTokens: $reviewTokens) {
              transition_note
            }
          }
        `,
        payload
      );
      if (!result) throw 'Update failed';
      await this.getReview(this.token);
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  cleanKYCDetails(kycDetails) {
    try {
      if (!kycDetails) return {};
      delete kycDetails.__typename;
      Object.keys(kycDetails).forEach((key) => {
        if (kycDetails[key]) {
          delete kycDetails[key].__typename;
        } else {
          //if it comes through as null, we mark it success because it wasn't flagged as an issue and is an optional field
          kycDetails[key] = {
            status: 'SUCCESS',
            value: '',
          };
        }
      });
      return kycDetails;
    } catch (error) {
      console.error(error);
      return {};
    }
  }

  get kycDetailsObj(): Object {
    const emptyObj = { status: 'PENDING', value: '' };
    return {
      status: this.dig(this.kycDetails, 'status', 'status') || 'PENDING',
      firstName: this.dig(this.kycDetails, 'firstName') || emptyObj,
      middleName: this.dig(this.kycDetails, 'middleName') || emptyObj,
      lastName: this.dig(this.kycDetails, 'lastName') || emptyObj,
      address1: this.dig(this.kycDetails, 'address1') || emptyObj,
      address2: this.dig(this.kycDetails, 'address2') || emptyObj,
      city: this.dig(this.kycDetails, 'city') || emptyObj,
      state: this.dig(this.kycDetails, 'state') || emptyObj,
      postal_code: this.dig(this.kycDetails, 'postal_code') || emptyObj,
      country: this.dig(this.kycDetails, 'country') || emptyObj,
      birth_date: this.dig(this.kycDetails, 'birth_date') || emptyObj,
      ssn: this.dig(this.kycDetails, 'ssn') || emptyObj,
      ofac: this.dig(this.kycDetails, 'ofac') || emptyObj,
    };
  }

  async getAgents() {
    const payload = { reviewToken: this.token };
    const result = await this.gqlQuery(
      `
      query agentsQuery($reviewToken: ID!) {
        agents(reviewToken: $reviewToken) {
          agents {
            token
            email
          }
        }
      }
      `,
      payload
    );
    const agentsResult = this.dig(result, 'data', 'agents', 'agents') || [];
    this.initialLoad = false;
    runInAction(() => {
      this.agentsList = agentsResult.sort(compareByEmail);
    });
  }

  addNoteToReview = async (text = '') => {
    const payload = { reviewToken: this.token, text };
    try {
      this.loading = true;
      const result = await this.gqlMutation(
        `
          mutation addNoteToReview ($reviewToken: ID!, $text: String) {
            addNoteToReview (reviewToken: $reviewToken, text: $text) {
              token
              text
            }
          }
        `,
        payload
      );
      if (!result) throw 'Adding note failed';
      await this.hydrate(this.token);
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
      this.key = Date.now();
    }
  };

  getReviewDocuments = async (expectedNewDoc = []) => {
    const payload = { reviewToken: this.token };
    try {
      this.loadingReviews = true;
      const result = await this.gqlQuery(
        `
          query getReviewDocuments ($reviewToken: ID!) {
            getReviewDocuments (reviewToken: $reviewToken) {
              token
              name
              created_time
              deleted_time
            }
          }
        `,
        payload
      );
      if (!result) throw 'Getting documents failed';
      runInAction(async () => {
        const reviewDocuments = (this.extract(result, 'getReviewDocuments') || [])
          .slice() // turn into an array
          .filter((document) => !document.deleted_time); // dont show deleted documents

        //expectednewdoc is an array of files that were just uploaded passed from the addControlPlaneReviewDocument function
        //missingnewdocument is used by the document manager to tell if the files they uploaded were successfully uploaded or not
        //if the array exists we look through it and compare it against the documents we got from the call to check if they exist in it or not
        this.missingNewDocument =
          expectedNewDoc &&
          expectedNewDoc.some(
            (expectedDoc) => !reviewDocuments.some((newDoc) => newDoc.name === expectedDoc.name)
          );

        const requestOptions = {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
            'X-Marqeta-Access-Token-Type': 'USER',
          },
          redirect: 'follow',
        };

        const newReviewDocs = [];
        await Promise.all(
          reviewDocuments.map(async (document) => {
            //if the document exists, we dont need to fetch
            const found = this.reviewDocuments.find(
              (existingDoc) => existingDoc.token === document.token
            );
            if (found) {
              newReviewDocs.push(found);
            } else {
              //shallow copy the document and update it with details from the download
              const newDocument = { ...document };
              const documentFetch = await fetch(
                `/reviewmanager/api/v1/kyc/reviews/${this.token}/documents/${document.token}/download`,
                requestOptions
              );
              const blob = await documentFetch.blob();
              newDocument.size = blob.size;
              const extension = document.name.slice(document.name.lastIndexOf('.') + 1);
              newDocument.type = extension === 'pdf' ? 'application/pdf' : `image/${extension}`;
              const newBlob = new Blob([blob], { type: newDocument.type });
              newDocument.link = window.URL.createObjectURL(newBlob);
              newReviewDocs.push(newDocument);
            }
          })
        );

        //display the documents from most recent to least recent
        this.reviewDocuments = newReviewDocs.reverse();
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.loadingReviews = false;
      this.key = Date.now();
    }
  };

  deleteReviewDocument = async (documentToken) => {
    const payload = { reviewToken: this.token, documentToken: documentToken };
    try {
      this.loadingReviews = true;
      const result = await this.gqlQuery(
        `
          mutation deleteReviewDocument ($reviewToken: ID!, $documentToken: String) {
            deleteReviewDocument (reviewToken: $reviewToken, documentToken: $documentToken)
          }
        `,
        payload
      );
      if (!result) throw 'Deleting document failed';
      return result;
    } catch (error) {
      console.error(error);
    } finally {
      this.getReviewDocuments();
    }
  };

  addControlPlaneReviewDocument = async (rawFile) => {
    //The graphql layer cannot handle transferring files, so we have to do a fetch here instead of an upload
    //The upload api endpoint passes the file through several layers and doesn't wait until it is complete to return a success

    this.loadingReviews = true;
    const filesAsArray = rawFile.constructor !== Array ? [rawFile] : rawFile;
    try {
      filesAsArray.forEach(async (newDocument) => {
        var formData = new FormData();
        formData.append('file', newDocument);

        const requestOptions = {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
            'X-Marqeta-Access-Token-Type': 'USER',
          },
          body: formData,
          redirect: 'follow',
        };

        const result = await fetch(
          `/reviewmanager/api/v1/kyc/reviews/${this.token}/documents/upload`,
          requestOptions
        );
        return result;
      });
    } catch (error) {
      console.error(error);
    } finally {
      //Due to the immediate response of the api on a successful upload, we wait 10 seconds and pass the files we're looking for
      //to the getreview to check if they were uploaded yet
      setTimeout(() => this.getReviewDocuments(filesAsArray), 10000);
    }
  };

  changeReviewStatus = async (status = '', note = '') => {
    status = status === 'IN PROGRESS' ? 'IN_PROGRESS' : status;
    const payload = { reviewToken: this.token, status, note };
    try {
      this.loading = true;
      const result = await this.gqlMutation(
        `
          mutation changeReviewStatus ($reviewToken: ID!, $status: String, $note: String) {
            changeReviewStatus (reviewToken: $reviewToken, status: $status, note: $note) {
              token
              status
            }
          }
        `,
        payload
      );
      if (!result) throw 'Changing status failed';
      await this.hydrate(this.token);
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  };

  changeReviewTeam = async (transition_reason_codes = [], transition_note = '') => {
    const payload = {
      reviewToken: this.token,
      transition_note,
      transition_reason_codes,
    };
    try {
      this.loading = true;
      const result = await this.gqlMutation(
        `
          mutation changeReviewTeam ($reviewToken: ID!, $transition_note: String, $transition_reason_codes: [String]) {
            changeReviewTeam (reviewToken: $reviewToken, transition_note: $transition_note, transition_reason_codes: $transition_reason_codes) {
              review_token
            }
          }
        `,
        payload
      );
      if (!result) {
        return false;
      }
      await this.hydrate(this.token);
      return true;
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  };
}

decorate(ReviewStore, {
  // values
  activityLog: observable,
  agent: observable,
  agentOptions: observable,
  caseReviewer: observable,
  customerName: observable,
  dateCreated: observable,
  kycStatus: observable,
  lastUpdated: observable,
  loading: observable,
  marqetaAgentOptions: observable,
  marqetaCaseAgent: observable,
  status: observable,
  token: observable,
  program_short_code: observable,
  user_token: observable,
  missingNewDocument: observable,

  // actions
  hydrate: action.bound,
  updateKYCDetails: action.bound,
  updateReviewDisposition: action.bound,

  // computed
  kycDetailsObj: computed,
  loadingReview: observable,
  loadingReviews: observable,
  agentsList: observable,
  reviewDocuments: observable,
  team: observable,
  transitionReasonCodes: observable,
  dispositions: observable,
});

export default ReviewStore;
