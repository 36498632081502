import { secureStorage } from '@mq/volt-amc-container';

const readProgramConfigByActiveProgram = () => {
  try {
    const activeProgram = secureStorage.getItem('selectedActiveProgram');
    const { short_name } = JSON.parse(activeProgram);
    const programConfigs = JSON.parse(secureStorage.getItem('programConfigs'));

    return programConfigs[short_name];
  } catch (error) {
    console.warn(`Unable to read active program from localStorage ${error}`);
    return {};
  }
};

export default readProgramConfigByActiveProgram;
