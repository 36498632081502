import React from 'react';
import { observer } from 'mobx-react';
import { Text, Timeline, VSpacer } from '@mqd/volt-base';
import TimelineNode from './TimelineNode';
import KYBAccordionNode from './KYBAccordionNode';
import s from './Timeline.module.css';

class KYBTimeline extends React.Component {
  render() {
    const { kybLog } = this.props;
    return (
      <div className={s.timelineContainer}>
        <Text type="h6">History</Text>
        <VSpacer factor={2} />
        <Timeline>
          {kybLog.map((event, i) => {
            return event.length > 1 ? (
              <KYBAccordionNode eventsArray={[...event]} key={`KYB-timeline-node-${i}`} />
            ) : (
              <TimelineNode event={event[0]} key={`KYB-timeline-node-${i}`} />
            );
          })}
        </Timeline>
      </div>
    );
  }
}

KYBTimeline.propTypes = {};
KYBTimeline.defaultProps = {};

export default observer(KYBTimeline);
