import { decorate, observable, action, computed } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';
import AddressStore from './AddressStore';

class IncorporationStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.loadAndConstruct(args);
  }
  // values
  is_public: string = '';
  incorporation_type: string = '';
  stock_symbol: string = '';
  state_of_incorporation: string = '';
  name_registered_under: string = '';
  address_registered_under: AddressStore = {};

  // actions
  loadAndConstruct(args) {
    this.load(args);
    if (args.address_registered_under) {
      this.address_registered_under = new AddressStore(args.address_registered_under);
    } else {
      this.address_registered_under = new AddressStore();
    }
  }

  // computed
  watchedObjects = ['address_registered_under'];
  get updateParams(): Object {
    const params = { ...this.watchedUpdateParams };
    this.valuesUpdatedArray.forEach((value) => {
      params[value] = this[value];
    });
    if (Object.keys(params).length === 0) return null;
    return params;
  }
}

decorate(IncorporationStore, {
  // values
  is_public: observable,
  incorporation_type: observable,
  stock_symbol: observable,
  state_of_incorporation: observable,
  name_registered_under: observable,
  address_registered_under: observable,

  // actions
  loadAndConstruct: action.bound,

  // computed
  updateParams: computed,
});

export default IncorporationStore;
