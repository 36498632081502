import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import s from './DetailCard.module.css';
import { ActionDropdown, HSpacer, Text } from '@mqd/volt-base';

class DetailCard extends PureComponent {
  showHeader() {
    const { title, controls } = this.props;
    return title || controls;
  }

  renderTitle() {
    const { title, testId } = this.props;
    return (
      <Text type="h4" data-testid={`${testId}_title`}>
        {title}
      </Text>
    );
  }

  renderControls() {
    const { controls } = this.props;
    return <div className={s.title}>{controls}</div>;
  }

  renderHeader() {
    if (!this.showHeader()) return null;
    return (
      <div className={s.header}>
        {this.renderTitle()}
        {this.renderControls()}
      </div>
    );
  }

  renderContent() {
    const { children } = this.props;
    return (
      <div className={s.content}>
        {this.renderActionDropdown()}
        {children}
      </div>
    );
  }

  renderActionDropdown() {
    const { actions, actionDropdownProps } = this.props;
    if (actions) {
      return (
        <div className={s.actionDropdown}>
          <ActionDropdown content={actions} {...actionDropdownProps} />
        </div>
      );
    }
  }

  renderFooterButtons() {
    const { footerButtons } = this.props;
    if (!footerButtons || footerButtons.length === 0) {
      return null;
    }
    return (
      <div className={s.footer}>
        {footerButtons.map((button, i) => {
          return (
            <React.Fragment key={i}>
              <HSpacer factor={2} />
              {button}
            </React.Fragment>
          );
        })}
      </div>
    );
  }

  render() {
    const { noBoxShadow, style, testId } = this.props;
    const styleAddOn = {
      boxShadow: noBoxShadow
        ? null
        : `0 0 2px 0 rgba(22, 22, 26, 0.1),
    0 4px 8px 0 rgba(22, 22, 26, 0.08)`,
    };
    const allStyles = Object.assign(styleAddOn, style);
    const showHeader = this.showHeader();
    return (
      <div
        className={`${s.cardWrap} ${showHeader ? s.withHeader : ''}`}
        style={allStyles}
        data-testid={testId}
      >
        <div className={s.card}>
          {this.renderHeader()}
          {this.renderContent()}
          {this.renderFooterButtons()}
        </div>
      </div>
    );
  }
}

DetailCard.propTypes = {
  actions: PropTypes.array,
  actionDropdownProps: PropTypes.object,
  contentPadding: PropTypes.string,
  controls: PropTypes.any,
  noBoxShadow: PropTypes.bool,
  footerButtons: PropTypes.array,
  style: PropTypes.object,
  testId: PropTypes.string,
  title: PropTypes.any,
};

DetailCard.defaultProps = {
  actions: null,
  actionDropdownProps: {},
  contentPadding: '24px',
  noBoxShadow: true,
  controls: null,
  footerButtons: [],
  style: {},
  testId: 'volt-card',
  title: null,
};

export default DetailCard;
