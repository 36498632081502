import React, { useState } from 'react';
import { VSpacer, Input, Icon, HSpacer } from '@mqd/volt-base';
import { PropTypes } from 'prop-types';
import s from './WebhookFormPasswordInput.module.css';
import {
  passwordValid,
  lowerCaseCheck,
  upperCaseCheck,
  specialCharacterCheck,
  numberCheck,
  twentyToFiftyCharactersCheck,
} from './webhooksFormValidators';

const WebhookFormPasswordInput = ({ password, onChangePassword }) => {
  const [passwordFocused, setPasswordFocused] = useState(false);

  const PasswordStrengthInfo = () => {
    const lowerCase = lowerCaseCheck(password);
    const upperCase = upperCaseCheck(password);
    const specialCharacter = specialCharacterCheck(password);
    const number = numberCheck(password);
    const twentyToFiftyCharacters = twentyToFiftyCharactersCheck(password);
    return (
      <div className={s.passwordStrengthInputWrap}>
        <div className={s.passwordStrengthTitle}>Password strength:</div>
        <VSpacer height={10} />
        {getPasswordCheck('character-check', twentyToFiftyCharacters, '20-50 characters')}
        <VSpacer height={10} />
        {getPasswordCheck('number-check', number, 'Must contain at least one numeral')}
        <VSpacer height={10} />
        {getPasswordCheck(
          'lowercase-check',
          lowerCase,
          'Must contain at least one lowercase letter'
        )}
        <VSpacer height={10} />
        {getPasswordCheck(
          'uppercase-check',
          upperCase,
          'Must contain at least one uppercase letter'
        )}
        <VSpacer height={10} />
        {getPasswordCheck(
          'special-character-check',
          specialCharacter,
          'Must contain at least one of these symbols: @ # $ % ! ^ & * ( ) \\ _ + ~ ` - = [ ] { } , ; : \' " , . / < > ?'
        )}
      </div>
    );
  };

  const getPasswordCheck = (testId, currentValue, message) => {
    return (
      <div
        data-testId={testId}
        className={`${s.passwordStrengthCheck} ${currentValue ? '' : s.vtError}`}
      >
        <div className={`${s.passwordStrengthIcon} ${currentValue ? s.iconGreen : ''}`}>
          {currentValue ? (
            <Icon
              type="check"
              mod="active"
              factor={1} //seems to only work for left nav and illustrative icons
              hasTooltip={false} //use in conjunction with Tooltip component
              noHoverEffects={false}
            />
          ) : (
            <Icon
              type="close"
              mod="error"
              factor={1} //seems to only work for left nav and illustrative icons
              hasTooltip={false} //use in conjunction with Tooltip component
              noHoverEffects={false}
            />
          )}
        </div>
        <HSpacer width={5} />
        <div className={s.passwordStrengthText}>{message}</div>
      </div>
    );
  };

  const GetPasswordStrength = () => {
    const valid = passwordValid(password);
    return (
      password && (
        <div data-testId="password-input-strength-text" style={{ fontSize: 11 }}>
          Password strength:{' '}
          <span style={{ fontWeight: 'bold', color: valid ? '#65b409' : '#d0021b' }}>
            {valid ? 'strong' : 'weak'}
          </span>
        </div>
      )
    );
  };

  const GetPasswordInput = () => {
    return (
      <div>
        <Input
          testId="volt-input-password"
          name="password"
          label="Authentication password"
          type="password"
          width={540}
          required
          value={password}
          onKeyDown={() => setPasswordFocused(true)}
          onBlur={() => setPasswordFocused(true)}
          onChange={(e) => {
            if (onChangePassword) {
              onChangePassword(e.target.value);
            }
          }}
        />
        <div
          data-testId="password-input-tooltip"
          className={`${s.passwordInputTooltip} ${passwordFocused ? s.vtFocused : ''}`}
        >
          <div className={s.passwordInputSquare} />
          <div className={s.passwordInputSquareCover} />
          <PasswordStrengthInfo />
        </div>
      </div>
    );
  };

  return (
    <>
      {GetPasswordInput()}
      <VSpacer height={5} />
      {GetPasswordStrength()}
    </>
  );
};

WebhookFormPasswordInput.propTypes = {
  password: PropTypes.any,
  onChangePassword: PropTypes.func,
};

WebhookFormPasswordInput.defaultProps = {
  password: '',
  onChangePassword: () => {},
};

export default WebhookFormPasswordInput;
