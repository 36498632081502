import React from 'react';
import { Text, HSpacer, VSpacer, Select } from '@mqd/volt-base';
import { transformEntityString } from '../../helpers';
import s from './KYBCard.module.css';
import IconInput from './IconInput';
import StatusIcon from './StatusIcon';
import PropTypes from 'prop-types';

class Row extends React.PureComponent {
  render() {
    const {
      editActive,
      name,
      onChangeStatus,
      status,
      userStore = {},
      kybCustomerCanWrite,
    } = this.props;
    const displayName = transformEntityString(name);
    const hasStatusEditRights = userStore.kybCanWrite;
    const showInput = editActive && hasStatusEditRights;
    var value = 'Not Performed';
    var selectOptions = [
      { val: 'Verified', render: 'Verified' },
      { val: 'Rejected', render: 'Failed' },
    ];
    if (status === 'SUCCESS') {
      value = 'Passed';
      selectOptions = [
        { val: 'Pending', render: 'Not Performed' },
        { val: 'Rejected', render: 'Failed' },
      ];
    } else if (status === 'FAILURE') {
      value = 'Failed';
      selectOptions = [
        { val: 'Verified', render: 'Verified' },
        { val: 'Pending', render: 'Not Performed' },
      ];
    }
    return (
      <div data-testid="KYB-noval-row">
        <Text type="h6">{displayName}</Text>
        <VSpacer />
        {showInput ? (
          <div className={s.editActive} data-testid="KYB-noval-row-edit-active">
            <Select
              value={value}
              options={selectOptions}
              onChange={(value) => {
                onChangeStatus(name, value.val);
              }}
              numOptions={2}
              width="100%"
              testId="KYB-noval-input"
            />
            {hasStatusEditRights ? (
              <IconInput onChangeStatus={onChangeStatus} field={name} status={status} />
            ) : (
              <StatusIcon status={status} />
            )}
          </div>
        ) : (
          <div className={s.notEditActive} data-testid="KYB-noval-row-edit-not-active">
            <Text testId={`KYB-noval-row-${name}`}>{value}</Text>
            <HSpacer />
            <StatusIcon status={status} />
          </div>
        )}
      </div>
    );
  }
}

Row.propTypes = {
  editActive: PropTypes.bool,
  field: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  status: PropTypes.string,
  kybCustomerCanWrite: PropTypes.bool,
};

Row.defaultProps = {
  editActive: false,
  field: '',
  name: '',
  onChange: () => {},
  status: '',
  kybCustomerCanWrite: false,
};

export default Row;
