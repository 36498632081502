import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Text } from '@mqd/volt-base';
import s from './GlobalSearch.module.css';

class GlobalSearchNoResult extends Component {
  render() {
    return (
      <div className={s.result} data-testid="global-search-result">
        <div className={s.resultBody} data-testid={`global_search_result_type-no_result`}>
          <div className={`${s.noResults} ${s.noHover}`}>
            <Text>No matching token or account number found.</Text>
          </div>
        </div>
      </div>
    );
  }
}

export default observer(GlobalSearchNoResult);
