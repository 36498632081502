import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { ActionDropdown, Button, Tabs, Text, ToastAlert, EmptyState } from '@mqd/volt-base';
import { ErrorBannerAlert, ParentDetail } from '@mq/voltron-parent';
import CardholderStore from './../stores/CardholderStore';
import { Icon, VSpacer, ModalAlert } from '@mqd/volt-base';
import CardholderInfoTabOld from '../cardholder-info-tab-old/CardholderInfoTab';
import CardholderInfoTab from '../cardholder-info-tab/CardholderInfoTab';
import TransactionsTab from './../transactions-tab/TransactionsTab';
import TransitionsTab from './../transitions-tab/TransitionsTab';
import AuditLogsTab from './../audit-logs-tab/AuditLogsTab';
import NotesTab from './../notes-tab/NotesTab';
import CardsTab from './../cards-tab/CardsTab';
import AccountsTab from './../accounts-tab/AccountsTab';
import SpendControlsTab from './../spend-controls-tab/SpendControlsTab';
import AddFundsModal from '../add-funds-modal/AddFundsModal';
import RemoveFundsModal from '../remove-funds-modal/RemoveFundsModal';
import AddNoteModal from '../add-note-modal/AddNoteModal';
import ChangeStatusModal from '../change-status-modal/ChangeStatusModal';
import StyleContext from '../contexts/StyleContext';
import s from './CardholderDetail.module.css';
import routerStore from '../../../../stores/router/RouterStore.js';
import { FlipFlop } from '../../../../utils/index.js';
import { isBillPaymentEnabled } from '../../../../utils/bill-pay';
import PaymentsTab from './../payments-tab/PaymentsTab';
import { UAM_GRANULAR_PERMISSIONS } from '@mqd/mqd-constants';

const { USER_DETAILS_VIEW_AND_EDIT, CARD_DETAILS_VIEW, CARD_DETAILS_VIEW_AND_EDIT } =
  UAM_GRANULAR_PERMISSIONS;

class CardholderDetail extends ParentDetail {
  constructor(props) {
    super(props);
    this.storeConstructor = CardholderStore;
    this.state = {
      activeTab: this.props.currentTab || 'User Details',
      showAddFundsModal: false,
      showAddNoteModal: false,
      showRemoveFundsModal: false,
      showChangeStatusModal: false,
      showNoteSuccessfullyAddedAlert: false,
      showNoteNotAddedAlert: false,
      noteTabRefreshToken: 'initial',
      historyTabRefreshToken: 'initial',
      loadedStores: {},
      toastProps: null,
    };
  }

  get activeTabs() {
    const { userStore = {}, isUserDetailsV2Active, isBillPaymentsActive } = this.props;
    const config = {
      'User Details': true,
      'User Details (New)': isUserDetailsV2Active,
      Cards: true,
      Accounts: true,
      Transactions: true,
      'Bill payments': isBillPaymentEnabled() && isBillPaymentsActive,
      'Spend controls (New)': isUserDetailsV2Active,
      History: userStore.auditLogTableActive,
      Notes: true,
      Transitions: true,
    };
    return Object.keys(config).filter((key) => config[key]);
  }

  get hasUamRoleToken() {
    const {
      userStore: { uamGranularPermissionsActive },
    } = this.props;
    return uamGranularPermissionsActive;
  }

  get hasUserDetailsEdit() {
    const { userStore } = this.props;
    return (
      userStore &&
      userStore.redseaRoles &&
      userStore.redseaRoles.includes(USER_DETAILS_VIEW_AND_EDIT)
    );
  }

  get hasCardDetailsView() {
    const { userStore } = this.props;
    const cardDetailsPermissions = [CARD_DETAILS_VIEW, CARD_DETAILS_VIEW_AND_EDIT];
    return (
      userStore &&
      userStore.redseaRoles &&
      userStore.redseaRoles.some((role) => cardDetailsPermissions.includes(role))
    );
  }

  renderBreadcrumb() {
    const { buildBreadcrumb } = this.props;
    if (!(buildBreadcrumb instanceof Function)) return null;
    const { fullName } = this.store;
    const currentCrumb = {
      children: fullName || '· · ·',
    };
    return buildBreadcrumb(currentCrumb);
  }

  renderContent() {
    const {
      canAccessDisputes,
      cardsTableLocalStorageKey,
      goToDigitalWalletToken,
      goToNewDispute,
      goToReview,
      goToTransaction,
      historyTableLocalStorageKey,
      isDirectDepositsAlphaActive,
      notesTableLocalStorageKey,
      onCardViewClick,
      token,
      transactionsTableLocalStorageKey,
      transitionsTableLocalStorageKey,
      userHasRoleInArrayForActiveProgram,
      userStore,
    } = this.props;
    const { activeTab, noteTabRefreshToken, historyTabRefreshToken, loadedStores } = this.state;
    const {
      userStore: { canRevealIdentification },
    } = this.props;
    const { hasMarqetaEmail, hasRoleInArrayForActiveProgram, reportCardLostStolenActive } =
      userStore;
    const canViewCardDetails = (arg) => {
      return this.hasUamRoleToken ? this.hasCardDetailsView : arg;
    };

    const { gqlClient, fullName } = this.store;

    const defaultComponent = (
      <CardholderInfoTabOld
        cardholderStore={this.store}
        userStore={userStore}
        goToReview={goToReview}
        hasUamRoleToken={this.hasUamRoleToken}
        hasUserDetailsEdit={this.hasUserDetailsEdit}
      />
    );
    if (!this.activeTabs.includes(activeTab)) return defaultComponent;

    const goToDWT = (dwtToken) => {
      goToDigitalWalletToken(dwtToken, fullName, token);
    };

    const hasAccountsParityFlag = FlipFlop.get('accounts-parity', false);
    const goToAccount = hasAccountsParityFlag
      ? ({ token }) => routerStore.go('/program/account', { token })
      : undefined;

    switch (activeTab) {
      case 'User Details':
        return (
          <CardholderInfoTabOld
            canRevealIdentification={canRevealIdentification}
            cardholderStore={this.store}
            userStore={userStore}
            goToReview={goToReview}
            hasUamRoleToken={this.hasUamRoleToken}
            hasUserDetailsEdit={this.hasUserDetailsEdit}
          />
        );

      case 'User Details (New)':
        return <CardholderInfoTab />;

      case 'Cards':
        return (
          <CardsTab
            uamGranularPermissionsActive={this.hasUamRoleToken}
            onStoreConstruction={(store) => {
              const newLoadedStores = Object.assign({}, loadedStores, {
                Cards: store,
              });
              this.setState({ loadedStores: newLoadedStores });
            }}
            storeInitArgs={{
              gqlClient: gqlClient,
              cardholder_token: token,
            }}
            reportCardLostStolenActive={reportCardLostStolenActive}
            hasRoleInArrayForActiveProgram={hasRoleInArrayForActiveProgram}
            canViewCardDetails={canViewCardDetails}
            onRowClick={onCardViewClick}
            localStorageKey={cardsTableLocalStorageKey}
          />
        );

      case 'Spend controls (New)':
        return <SpendControlsTab cardholderStore={this.store} />;

      case 'Accounts':
        return (
          <AccountsTab
            onRowClick={goToAccount}
            storeInitArgs={{
              gqlClient: gqlClient,
              cardholder_token: token,
              userHasRoleInArrayForActiveProgram: userHasRoleInArrayForActiveProgram,
              isDirectDepositsAlphaActive: isDirectDepositsAlphaActive,
            }}
          />
        );

      case 'Transactions':
        return (
          <TransactionsTab
            onStoreConstruction={(store) => {
              const newLoadedStores = Object.assign({}, loadedStores, {
                Transactions: store,
              });
              this.setState({ loadedStores: newLoadedStores });
            }}
            storeInitArgs={{
              gqlClient,
              enableNoParamQuery: false,
              queryParams: {
                acting_cardholder_token: {
                  type: 'ID!',
                  val: token,
                },
              },
              isDirectDepositsAlphaActive,
              userStore,
              cardholderOrBusinessToken: token,
            }}
            goToDigitalWalletToken={goToDWT}
            goToNewDispute={goToNewDispute}
            localStorageKey={transactionsTableLocalStorageKey}
            canAccessDisputes={canAccessDisputes}
            goToTransaction={goToTransaction(fullName)}
          />
        );

      case 'Bill payments':
        return <PaymentsTab />;

      case 'History':
        return (
          <AuditLogsTab
            key={historyTabRefreshToken}
            onStoreConstruction={(store) => {
              const newLoadedStores = Object.assign({}, loadedStores, {
                History: store,
              });
              this.setState({ loadedStores: newLoadedStores });
            }}
            storeInitArgs={{
              gqlClient,
              enableNoParamQuery: false,
              queryParams: {
                record_id: {
                  val: token,
                  type: 'String',
                },
              },
            }}
            excludedSearchFilters={['record_id']}
            localStorageKey={historyTableLocalStorageKey}
          />
        );

      case 'Notes':
        return (
          <NotesTab
            key={noteTabRefreshToken}
            hasMarqetaEmail={hasMarqetaEmail}
            onStoreConstruction={(store) => {
              const newLoadedStores = Object.assign({}, loadedStores, {
                Notes: store,
              });
              this.setState({ loadedStores: newLoadedStores });
            }}
            storeInitArgs={{
              gqlClient,
              token,
              type: 'cardholder',
            }}
            localStorageKey={notesTableLocalStorageKey}
          />
        );

      case 'Transitions':
        return (
          <TransitionsTab
            onStoreConstruction={(store) => {
              const newLoadedStores = Object.assign({}, loadedStores, {
                Transitions: store,
              });
              this.setState({ loadedStores: newLoadedStores });
            }}
            storeInitArgs={{
              gqlClient,
              queryParams: {
                cardholder_token: {
                  type: 'ID!',
                  val: token,
                },
              },
            }}
            localStorageKey={transitionsTableLocalStorageKey}
            calculateTableHeight={(store) => (store.currentList.length >= store.pageSize ? 0 : 70)}
          />
        );
      default:
        return defaultComponent;
    }
  }

  renderAddNoteModal() {
    const { showAddNoteModal } = this.state;
    const { userStore } = this.props;
    if (!showAddNoteModal) return null;

    //TODO: Pull out userRole from userStore (current userRole is from Janus).
    //Map redseaUserRole to USER | ADMIN | BANK_USER | BANK_ADMIN | MARQETA_PD | MARQETA_ADMIN.
    //Pass in the correct created_by_user_role.
    //Temporarily, all notes will have created_by_user_role='ADMIN'.
    const { email } = userStore;
    const activeUserInfo = email
      ? {
          email,
          userRole: 'ADMIN',
        }
      : {};
    return (
      <AddNoteModal
        hideModal={() => this.setState({ showAddNoteModal: false })}
        heading={`Add Note - ${this.store.first_name} ${this.store.last_name}`}
        store={this.store}
        activeUserInfo={activeUserInfo}
        renderSuccessAlert={() =>
          this.setState({
            showNoteSuccessfullyAddedAlert: true,
            noteTabRefreshToken: Math.random(),
            historyTabRefreshToken: Math.random(),
          })
        }
        renderFailedAlert={() => this.setState({ showNoteNotAddedAlert: true })}
        segmentEventName="Cardholder Note Added"
      />
    );
  }

  renderNoteSuccessfullyAddedAlert() {
    const { showNoteSuccessfullyAddedAlert } = this.state;
    if (!showNoteSuccessfullyAddedAlert) return null;
    return (
      <ModalAlert
        type="success"
        title="Note successfully added"
        hideModal={() => this.setState({ showNoteSuccessfullyAddedAlert: false })}
      />
    );
  }

  renderNoteNotAddedAlert() {
    const { showNoteNotAddedAlert } = this.state;
    if (!showNoteNotAddedAlert) return null;
    return (
      <ModalAlert
        type="danger"
        title="Note could not be added. Please try again."
        hideModal={() => this.setState({ showNoteNotAddedAlert: false })}
      />
    );
  }

  renderChangeStatusModal() {
    const { showChangeStatusModal } = this.state;
    if (!showChangeStatusModal) return null;
    return (
      <ChangeStatusModal
        hideModal={() =>
          this.setState({
            showChangeStatusModal: false,
            historyTabRefreshToken: Math.random(),
          })
        }
        heading={`Change Status - ${this.store.fullName}`}
        store={this.store}
      />
    );
  }

  get canAddFunds() {
    const { userStore } = this.props;
    const allowedRoles = ['production-support-internal', 'delivery-internal'];
    const { hasRoleInArrayForActiveProgram = () => {} } = userStore;
    return hasRoleInArrayForActiveProgram(allowedRoles);
  }

  get canRemoveFunds() {
    const { userStore } = this.props;
    return !!userStore?.canRemoveFunds;
  }

  renderAddFundsModal() {
    const { showAddFundsModal } = this.state;
    if (!showAddFundsModal) return null;

    return (
      <AddFundsModal
        onCardHolderPage
        cardholderStore={this.store}
        hideModal={() => this.setState({ showAddFundsModal: false })}
        showSuccessToast={() =>
          this.setState({ toastProps: { message: 'Funds added successfully.', icon: 'success' } })
        }
      />
    );
  }

  renderRemoveFundsModal() {
    const { showRemoveFundsModal } = this.state;
    if (!showRemoveFundsModal) return null;

    return (
      <RemoveFundsModal
        fundingSourceType={'cardholder'}
        fundingSourceStore={this.store}
        hideModal={() => this.setState({ showRemoveFundsModal: false })}
        showSuccessToast={() =>
          this.setState({
            toastProps: { message: 'Unload fund request submitted', icon: 'success' },
          })
        }
        showErrorToast={() =>
          this.setState({
            toastProps: {
              message: 'Error occurred when submitting unload fund request',
              icon: 'error',
            },
          })
        }
      />
    );
  }

  renderToastAlert() {
    const { toastProps } = this.state;
    if (!toastProps || typeof toastProps !== 'object' || !toastProps.message) return null;

    return (
      <ToastAlert remove={() => this.setState({ toastProps: null })} {...toastProps}>
        {toastProps.message}
      </ToastAlert>
    );
  }

  renderErrors() {
    const { loadedStores, activeTab } = this.state;
    if (activeTab === 'User%20Details' || activeTab === 'User Details') {
      return <ErrorBannerAlert store={this.store} />;
    } else {
      return <ErrorBannerAlert store={loadedStores[activeTab]} />;
    }
  }

  render() {
    const { activeTab } = this.state;
    const { handleTabClick, onCardCreationClick } = this.props;
    const { token, fullName, loading } = this.store;
    const {
      userStore: { cardCreationActive = {} },
    } = this.props;

    const showDropdown = cardCreationActive || this.canAddFunds || this.canRemoveFunds;
    const canEditDetails = this.hasUamRoleToken ? this.hasUserDetailsEdit : true;

    const dropdownContent = [
      ...[
        // if user is not a UAM user or is a UAM user w/create card manually view/edit role,
        // they can see manual create card option if card creation is active
        cardCreationActive && {
          element: 'Manual create card',
          onClick: () => onCardCreationClick({ token }),
        },
        this.canAddFunds && {
          element: 'Add funds to user',
          onClick: () => this.setState({ showAddFundsModal: true }),
        },
        this.canRemoveFunds && {
          element: 'Remove funds from user',
          onClick: () => this.setState({ showRemoveFundsModal: true }),
        },
      ],
    ].filter((content) => typeof content === 'object');

    return (
      <div
        style={{ width: '100%', position: 'relative' }}
        data-testid={`cardholder-detail-loading-${loading}`}
      >
        {this.renderErrors()}
        <div className={s.flexAndSpaceBetween}>
          {this.renderBreadcrumb() || <span />}
          <div className={s.flexAndPositionTopRight}>
            {canEditDetails && (
              <>
                <Button
                  testId="cardholder-detail-tab-selector_action-Change Status"
                  onClick={() => this.setState({ showChangeStatusModal: true })}
                  type="outline"
                  className={s.actionButton}
                >
                  Change status
                </Button>
                <Button
                  testId="cardholder-detail-tab-selector_action-Add Note"
                  onClick={() => this.setState({ showAddNoteModal: true })}
                  type="outline"
                  style={{ marginLeft: '16px' }}
                  className={s.actionButton}
                >
                  Add note
                </Button>
              </>
            )}
            {showDropdown && (
              <ActionDropdown
                anchor="right"
                control={
                  <Button
                    testId="cardHolderActionButton"
                    type="outline"
                    style={{
                      marginLeft: '16px',
                    }}
                    className={s.actionButton}
                  >
                    More actions
                    <Icon type="caret-down" mod="default" factor={1} noHoverEffects></Icon>
                  </Button>
                }
                content={dropdownContent}
              />
            )}
          </div>
        </div>
        {this.renderAddFundsModal()}
        {this.renderRemoveFundsModal()}
        {this.renderAddNoteModal()}
        {this.renderNoteSuccessfullyAddedAlert()}
        {this.renderNoteNotAddedAlert()}
        {this.renderChangeStatusModal()}
        {this.renderToastAlert()}
        <VSpacer factor={2} />
        <Text type="h3">{loading ? '...' : fullName || 'Unnamed User'}</Text>
        <VSpacer factor={3} />
        <Tabs
          testId="cardholder-detail-tab-selector"
          activeTab={activeTab}
          tabs={this.activeTabs}
          onTabChange={(activeTab) => {
            handleTabClick({ tab: activeTab });
            this.setState({ activeTab });
          }}
        />
        <VSpacer factor={2} />
        <StyleContext.Provider value={{ height: this.dynamicHeight }}>
          {this.renderContent()}
        </StyleContext.Provider>
      </div>
    );
  }
}

CardholderDetail.propTypes = {
  autoHydrate: PropTypes.bool,
  buildBreadcrumb: PropTypes.array,
  canAccessDisputes: PropTypes.bool,
  cardsTableLocalStorageKey: PropTypes.string,
  currentTab: PropTypes.string,
  goToDigitalWalletToken: PropTypes.func,
  goToNewDispute: PropTypes.func,
  goToReview: PropTypes.func,
  goToTransaction: PropTypes.func,
  handleTabClick: PropTypes.func,
  historyTableLocalStorageKey: PropTypes.string,
  isDirectDepositsAlphaActive: PropTypes.bool,
  notesTableLocalStorageKey: PropTypes.string,
  onCardViewClick: PropTypes.func,
  onStoreConstruction: PropTypes.func,
  store: PropTypes.object,
  storeInitArgs: PropTypes.object,
  token: PropTypes.string,
  transactionsTableLocalStorageKey: PropTypes.string,
  transitionsTableLocalStorageKey: PropTypes.string,
  userHasRoleInArrayForActiveProgram: PropTypes.func,
  userStore: PropTypes.object,
};

CardholderDetail.defaultProps = {
  autoHydrate: true,
  buildBreadcrumb: [],
  canAccessDisputes: false,
  cardsTableLocalStorageKey: 'cardholderDetailCardsTableConfig',
  currentTab: 'User Details',
  goToDigitalWalletToken: () => {},
  goToNewDispute: () => {},
  goToReview: () => {},
  goToTransaction: () => {},
  handleTabClick: () => {},
  historyTableLocalStorageKey: 'cardholderDetailHistoryTableConfig',
  isDirectDepositsAlphaActive: false,
  notesTableLocalStorageKey: 'cardholderDetailNotesTableConfig',
  onCardViewClick: () => {},
  onStoreConstruction: null,
  store: null,
  storeInitArgs: {},
  token: '',
  transactionsTableLocalStorageKey: 'cardholderDetailTransactionsTableConfig',
  transitionsTableLocalStorageKey: 'cardholderDetailTransitionsTableConfig',
  userHasRoleInArrayForActiveProgram: null,
  userStore: {},
};

export default observer(CardholderDetail);
