import React from 'react';
import PropTypes from 'prop-types';

function SplitChildrenComponentByComponent({ children, component }) {
  const childrenArray = [];
  children.forEach((child, idx) => {
    const elementWithKey = React.cloneElement(
      child,
      {
        ...child.props,
        // key has to be cloned with the same key everytime, otherwise react will rerender that element incorrectly
        key: idx + child.key,
      },
      child.props.children
    );
    const fillInElementWithKey = React.cloneElement(component, {
      ...component.props,
      key: idx,
    });
    childrenArray.push(elementWithKey);
    if (children.length - 1 !== idx) childrenArray.push(fillInElementWithKey);
  });
  return childrenArray;
}

SplitChildrenComponentByComponent.propTypes = {
  children: PropTypes.array,
  component: PropTypes.element,
};

SplitChildrenComponentByComponent.defaultProps = {
  children: [],
  component: null,
};

export default SplitChildrenComponentByComponent;
