import React from 'react';
import { Colors, Icon } from '@mqd/volt-base';
import PropTypes from 'prop-types';
import { SUCCESS, FAILURE, PENDING } from './../../constants';

function StatusIcon({ status }) {
  if (status === PENDING || status === '') return <></>;
  return (
    <Icon
      type={status === SUCCESS ? 'check' : 'close'}
      mod={status === FAILURE ? 'error' : ''}
      overrideColor={status === SUCCESS ? Colors.statusSuccessColor : ''}
      noHoverEffects
    />
  );
}

StatusIcon.propTypes = {
  status: PropTypes.oneOf([SUCCESS, FAILURE, PENDING, '']),
};
StatusIcon.defaultProps = {
  status: PENDING,
};

export default StatusIcon;
