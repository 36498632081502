// copied from lodash/uniqueId
let idCounter = 0;

export default function uniqueId(prefix = '') {
  if (typeof prefix !== 'string') {
    throw new Error(`uniqueId takes an argument of type String. It was passed ${typeof prefix}`);
  }
  const id = ++idCounter;
  return prefix + id;
}
