// @flow
import { action, computed, decorate, observable } from 'mobx';
import gqlUtils from '@mqd/graphql-utils';
import shortid from 'shortid';
import logger from 'utils/logger';
import { verifier } from 'utils/route-authorization';
import {
  flipFlop,
  googleAnalytics,
  redseaRoleMapping,
  secureStorage,
} from '@mq/volt-amc-container';
import { ENVIRONMENT_LOCAL_STORAGE_KEY, PRODUCTION } from '@mqd/mqd-constants';
import logOut from '../utils/logOut.js';
import gqlApi from './../apis/GqlApi.js';
import janusApi from './../apis/JanusApi.js';
import persistApi from './../apis/persist-api/PersistApi.js';
import applicationDataStore from './application-data/ApplicationDataStore.js';
import UserStoreLoadAttributes from './constants/UserStoreLoadAttributes.js';
import UserStoreTableConfigKeys from './constants/UserStoreTableConfigKeys.js';
import ManagedUserStore from './ManagedUserStore.js';
import notificationStore from './NotificationStore.js';
import ParentStore from './ParentStore.js';
import TokenStore from './TokenStore.js';

import {
  AUDIT_LOGS_VIEW,
  ACCOUNT_HOLDER_DETAILS_VIEW_AND_EDIT,
  ACCOUNT_HOLDER_DETAILS_SENSITIVE_VIEW,
  ACCOUNT_HOLDER_DETAILS_SENSITIVE_VIEW_AND_EDIT,
  CARD_PRODUCTS_VIEW,
  CREATE_CARD_MANUALLY_VIEW_AND_EDIT,
  FULL_PAN_VIEW,
  UAM_GRANULAR_PERMISSIONS,
  REPLACE_CARD_VIEW_AND_EDIT,
  REPORT_STOLEN_VIEW_AND_EDIT,
  USER_DETAILS_VIEW_AND_EDIT,
  USER_DETAILS_VIEW,
  USER_DETAILS_SENSITIVE_VIEW,
  USER_DETAILS_SENSITIVE_VIEW_AND_EDIT,
  SET_PIN_VIEW_AND_EDIT,
  SUSPEND_CARD_VIEW_AND_EDIT,
  CARD_DETAILS_VIEW_AND_EDIT,
  DIGITAL_WALLET_TOKEN_DETAILS_VIEW,
  DIGITAL_WALLET_TOKEN_DETAILS_VIEW_AND_EDIT,
  INVENTORY_MGMT_VIEW,
  INVOKE_COMMANDO_MODE_VIEW,
  INVOKE_COMMANDO_MODE_VIEW_AND_EDIT,
  DISPUTES_VIEW_AND_EDIT,
  KYC_EXCEPTIONS_VIEW_AND_EDIT,
  PROGRAM_CONFIGURATION_VIEW_AND_EDIT,
  REMOVE_CARDHOLDER_FUNDS_VIEW_AND_EDIT,
} from '../views/admin/uam-granular-permissions/constants.js';

const { fragments } = gqlUtils;
const { redseaDepartmentsDisplayMapping, redseaSupplementsDisplayMapping } = redseaRoleMapping;

class UserStore extends ParentStore {
  constructor(localStoragePrefix: string = '', flipFlopInstance) {
    super();
    this.flipFlop = flipFlopInstance;
    this.localStoragePrefix = localStoragePrefix;
    UserStoreLoadAttributes.forEach(({ localStorageKey, attr, defaultVal, parse }) => {
      if (!attr) {
        attr = localStorageKey;
      }
      try {
        const existing = this.getFromLocalStorage(localStorageKey);
        if (parse) {
          this[attr] = existing ? JSON.parse(existing) : defaultVal;
        } else {
          this[attr] = existing;
        }
      } catch (error) {
        logger.error(`Error loading ${attr}`, error);
        this[attr] = defaultVal;
      }
    });

    this.managedUsers = [];

    try {
      let existingApiTokens = this.getFromLocalStorage('apiTokens');
      this.apiTokens = existingApiTokens ? this.loadApiTokens(existingApiTokens) : null;
    } catch (error) {
      this.apiTokens = [];
    }
    if (this.persistApiUserId) {
      this.userPersistDataLoaded = true;
    }
    this.setGoogleAnalyticsUid();
  }
  userId: ?number;
  firstName: ?string;
  lastName: ?string;
  email: ?string;
  webToken: ?string;
  webTokenCreatedTime: ?string;
  apiTokens: ?Array<string>;
  apiTokensErrored: Boolean = false;
  userOrgName: ?string;
  userRole: ?string;
  departments: Array<string>;
  programs: Array<Object>;
  supplements: Array<string>;
  managedUsers: ?Array<string>;
  userPersistDataLoaded: Boolean = false;
  localStoragePrefix: string;
  bootstrapSessionRun: boolean = false;
  featureFlagDataLoaded: Boolean = false;
  isLoggingOut: Boolean = false;
  // added for Unify
  totp: Boolean = false;
  phone: string = '';
  favoritePrograms: Array<Object> = [];
  selectedActiveProgram: ?Object = null;
  persistInLocalStorage: Array<Object> = [
    { key: 'favoritePrograms', parse: true },
    { key: 'selectedActiveProgram', parse: true },
    { key: 'validFeatureFlags', parse: true },
    { key: 'codeVerifier', parse: false },
    { key: 'render-cardproducts-hero', parse: true },
    { key: 'hideCreditProductWelcomeCard', parse: false, skipEncrypt: true },
    { key: 'hideOfferWelcomeCard', parse: false, skipEncrypt: true },
  ];

  // reports
  savedReports: Array<Object>;
  currentPrograms: Array<*> = [];
  currentRegime: ?string;
  dataJournals: Array<Object>;
  defaultReport: Object;

  // redsea variables
  redsea_first_name: ?string;
  redsea_last_name: ?string;
  redsea_phone: ?string;
  redsea_email: ?string;
  redsea_mfa_enabled: ?string;
  redsea_mfa_totp_enabled: Boolean = false;
  redsea_token: ?string;
  redseaPrograms: Array<string> = [];
  redseaRoles: Array<string> = [];
  redseaRolesToProgramsMap: Object = {};
  redseaRolesToProgramsEnvironmentsMap: Object = {};

  // actions
  // ********** //
  // LOGIN FLOW //
  // ********** //

  async bootstrapSession({ janusUser, redseaUser, applicationData, flipFlop }) {
    const janusSucceeded = !Boolean(janusUser.error);
    const redseaSucceeded = !Boolean(redseaUser.error);
    const applicationDataSucceeded = !Boolean(applicationData.error);
    const flipFlopSucceeded = !flipFlop.loading && !flipFlop.error && Boolean(flipFlop.data);

    if (redseaSucceeded) {
      this.loadRedseaData(redseaUser.data);

      const userInfo = await this.fetchCurrentRedseaUserData();
      if (userInfo) {
        const { has_additional_auth_factor } = userInfo;
        // set data to be used by progressive mfa check
        secureStorage.setItem('has_additional_auth_factor', has_additional_auth_factor);
        secureStorage.setItem('progressiveMfaActive', this.progressiveMfaActive);
      }
    }

    if (janusSucceeded) {
      this.loadJanusData(janusUser.data);
    }

    if (flipFlopSucceeded) {
      this.featureFlagDataLoaded = true;
    }

    if (applicationDataSucceeded) {
      applicationDataStore.setApplicationData(applicationData);
    }

    this.bootstrapSessionRun = true;

    if (
      (!redseaUser.loadedFromLocalStorage || !janusSucceeded.loadedFromLocalStorage) &&
      !this.isLoggingOut
    ) {
      // For items not directly related to login flow that need to to happen on login, do them in loginHook
      this.loginHook();
    }
  }

  isRedseaAccessTokenSet() {
    const accessToken = sessionStorage.getItem('accessToken');
    if (accessToken) {
      return true;
    }
  }

  initPersistUser = async (userEmail: string) => {
    try {
      const userResult = await persistApi.findOrCreateUser({
        email: userEmail,
      });
      this.setupPersistUser(userResult);
    } catch (error) {
      // Temprory disable, since the fix didn't work
      // notificationStore.notify('error', `Persist API Error: ${error.message}`);
      console.warn(`Persist API Error: ${error.message}`);
    }
  };

  loginHook() {
    if (this.email) {
      // Persist currently requires valid Janus token on all calls, only init if Janus succeeded
      this.initPersistUser(this.email);
    }
    this.recordLogin();
  }
  // ************** //
  // END LOGIN FLOW //
  // ************** //

  async fetchSelectedRedseaUserData(payload) {
    const webToken = secureStorage.getItem('webToken');
    try {
      const result = await gqlApi.gqlQuery(
        `query userLookup(
          $email: String
          $first_name: String
          $last_name: String
          $phone: String
          $webToken: String!
        ) {
          userLookup(
            email: $email
            first_name: $first_name
            last_name: $last_name
            phone: $phone
            webToken: $webToken
          ) {
            ...userInfo
          }
        }
          ${fragments.userInfo}
        `,
        {
          email: payload.email,
          first_name: payload.firstName,
          last_name: payload.lastName,
          phone: payload.phone,
          webToken: webToken,
        }
      );

      const userData = result.data.userLookup;
      return userData;
    } catch (e) {
      logger.error(e);
    }
  }

  async fetchCurrentRedseaUserData() {
    try {
      const result = await gqlApi.gqlQuery(`
        query currentUser {
          currentUser {
            ...userInfo
          }
        }
        ${fragments.userInfo}
      `);

      const userData = result.data.currentUser;
      return userData;
    } catch (e) {
      logger.error(e);
    }
  }

  async updateRedseaUser(payload) {
    // remove __typename from request - will be there if role is just regurgitated
    if (payload.roles && Array.isArray(payload.roles)) {
      payload.roles = payload.roles.map((role) => {
        const roleClone = Object.assign({}, role);
        delete roleClone.__typename;
        return roleClone;
      });
    }

    try {
      const result = await gqlApi.gqlMutation(
        `mutation updateUser(
          $token: String
          $first_name: String
          $last_name: String
          $active: Boolean
          $phone: String
          $mfa_enabled: Boolean
          $mfa_totp_enabled: Boolean
          $mfa_totp_revealed: Boolean
          $roles: [UserRoleInput]
          $webToken: String!
        ) {
          updateUser(
            token: $token
            first_name: $first_name
            last_name: $last_name
            active: $active
            phone: $phone
            mfa_enabled: $mfa_enabled
            mfa_totp_enabled: $mfa_totp_enabled
            mfa_totp_revealed: $mfa_totp_revealed
            roles: $roles
            webToken: $webToken
          ) {
            ...userInfo
          }
        }
          ${fragments.userInfo}
        `,
        {
          ...payload,
          webToken: this.webToken,
        },
        undefined,
        undefined,
        'all'
      );
      if (!result || !result.data || !result.data.updateUser) {
        const errorMessage = this.dig(result, 'errors', '0', 'message');
        throw new Error(errorMessage);
      }
      const userData = result.data.updateUser;
      return userData;
    } catch (e) {
      logger.error(e);
      throw new Error(e);
    }
  }

  demoLogin = async () => {
    try {
      const janusAuthResult = await janusApi.postData('/diva/security/salesdemo', {}, this);

      const loadJanusDataSuccess = this.loadJanusData(janusAuthResult);
      if (janusAuthResult && janusAuthResult.email) {
        this.initPersistUser(janusAuthResult.email);
      }
      this.recordLogin();
      return loadJanusDataSuccess;
    } catch (error) {
      return false;
    }
  };

  saveReport = async (name: string = 'saved-report', reportJson: Object = {}) => {
    if (!this.persistApiUserId) {
      return false;
    }
    const uid = shortid.generate();
    const gqlArgs = {
      uid: uid,
      created_by_user_id: this.persistApiUserId,
      name: name,
      hydrator: JSON.stringify(reportJson),
    };
    try {
      await persistApi.saveReport(gqlArgs);
      this.refreshUserData();
      notificationStore.notify('success', `${name} Successfully Saved`);

      googleAnalytics &&
        googleAnalytics.event({
          category: 'Key User Events',
          action: 'New Report Saved',
          label: `UID(${uid}) - Name(${name})`,
        });
    } catch (error) {
      notificationStore.notify('error', `${name} Could not be Saved: ${error.message}`);
    }
  };

  setDefaultReport = async (reportJson: Object = {}, name: ?String = 'Default Report') => {
    if (!this.persistApiUserId) {
      return false;
    }
    const stringifiedReport = JSON.stringify({
      name: name,
      config: reportJson,
    });
    const gqlArgs = {
      user_id: this.persistApiUserId,
      default_report: stringifiedReport,
    };
    try {
      await persistApi.updateDefaultReport(gqlArgs);
      googleAnalytics.event({
        category: 'Key User Events',
        action: 'User Set Default Report',
        label: stringifiedReport,
      });
      this.refreshUserData();
      notificationStore.notify('success', `Default Report Successfully Set`);
    } catch (error) {
      notificationStore.notify('error', `Default Report Could not be Saved: ${error.message}`);
    }
  };

  get defaultReportConfig(): ?Object {
    if (this.defaultReport) {
      if (typeof this.defaultReport === 'string') {
        return JSON.parse(this.defaultReport).config;
      }
      return this.defaultReport.config;
    } else {
      return null;
    }
  }

  get defaultReportName(): ?string {
    if (this.defaultReport) {
      if (typeof this.defaultReport === 'string') {
        return JSON.parse(this.defaultReport).name;
      }
      return this.defaultReport.name;
    } else {
      return null;
    }
  }

  deleteReport = async (uid: string) => {
    if (!uid) {
      return false;
    }
    const gqlArgs = {
      uid: uid,
    };
    try {
      await persistApi.deleteReport(gqlArgs);
      this.refreshUserData();
      notificationStore.notify('success', `Report Successfully Deleted`);
    } catch (error) {
      notificationStore.notify('error', `Report could not be Deleted: ${error.message}`);
    }
  };

  editReport = async (args: Object) => {
    try {
      const reportData = await persistApi.editReport(args);
      this.refreshUserData();
      notificationStore.notify('success', `${reportData.name} Successfully Edited`);
      googleAnalytics &&
        googleAnalytics.event({
          category: 'Key User Events',
          action: 'Saved Report Updated',
          label: `UID(${args.uid}) - Name(${reportData.name})`,
        });
    } catch (error) {
      notificationStore.notify('error', `Report could not be Edited: ${error.message}`);
    }
  };

  refreshUserData = async () => {
    if (this.persistApiUserId) {
      const userData = await persistApi.getUser({ id: this.persistApiUserId });
      this.setupPersistUser(userData);
    }
  };

  getApiTokens = async function (viewStore: ?any = null) {
    try {
      const apiTokens = await janusApi.getData(
        `/diva/security/users/tokens/${encodeURIComponent(this.email)}`,
        null,
        viewStore
      );
      const stringifiedTokens = JSON.stringify(apiTokens);
      this.apiTokens = this.loadApiTokens(stringifiedTokens);
      this.setInLocalStorage('apiTokens', stringifiedTokens);
    } catch (err) {
      if (viewStore && viewStore.notify) {
        this.apiTokensErrored = true;
      }
    }
  };

  getManagedUsers = async function (viewStore: ?any = null) {
    if (!verifier('admin')) return;
    let params = { org_name: this.userOrgName };
    if (this.userRole === 'SuperAdmin' && this.userOrgName === 'Marqeta') {
      params = {};
    }
    try {
      const managedUsersData = await janusApi.getData(
        '/diva/security/org/users',
        params,
        viewStore
      );
      this.managedUsers = this.loadManagedUsers(managedUsersData);
    } catch (err) {
      if (viewStore && viewStore.notify) {
        viewStore.notify('error', 'Error: Could not get managed users list');
      }
    }
  };

  setAndSaveAttr(attribute: string, value: any) {
    this.setInLocalStorage(attribute, value);
    this[attribute] = value;
  }

  setupPersistUser(data: Object) {
    if (!data || !data.id) {
      this.userPersistDataLoaded = true;
      return false;
    }
    this.setAndSaveAttr('persistApiUserId', data.id);
    // special treatment for stringified data
    secureStorage.setItem(
      this.localStoragePrefix + 'savedReports',
      JSON.stringify(data.saved_reports)
    );
    this.savedReports = data.saved_reports;
    secureStorage.setItem(this.localStoragePrefix + 'defaultReport', data.default_report);
    try {
      this.defaultReport = JSON.parse(data.default_report);
    } catch (e) {
      this.defaultReport = {};
    }
    this.userPersistDataLoaded = true;
  }

  clearPersistUserData() {
    this.removeFromLocalStorage('persistApiUserId');
    this.removeFromLocalStorage('savedReports');
    this.removeFromLocalStorage('defaultReport');
  }

  loadRedseaData(data: Object) {
    this.setAttr('redsea_first_name', data.redsea_first_name);
    this.setAttr('redsea_last_name', data.redsea_last_name);
    this.setAttr('redsea_phone', data.redsea_phone);
    this.setAttr('redsea_email', data.redsea_email);
    this.setAttr('redsea_mfa_enabled', data.redsea_mfa_enabled);
    this.setAttr('redsea_mfa_totp_enabled', data.redsea_mfa_totp_enabled);
    this.setAttr('redsea_token', data.redsea_token);
    if (data.redseaRolesToProgramsMap)
      this.redseaRolesToProgramsMap = data.redseaRolesToProgramsMap;
    if (data.redseaRolesToProgramsEnvironmentsMap)
      this.redseaRolesToProgramsEnvironmentsMap = data.redseaRolesToProgramsEnvironmentsMap;
    if (data.redseaRoles) this.redseaRoles = data.redseaRoles;
    if (data.redseaPrograms) this.redseaPrograms = data.redseaPrograms;
  }

  loadJanusData(data: Object, actingAs) {
    if (!data || !data.email) return false;
    const save = actingAs ? 'setAndSaveAttr' : 'setAttr';
    this[save]('userId', data.userId);
    this[save]('firstName', data.firstName);
    this[save]('lastName', data.lastName);
    this[save]('email', data.email);
    this[save]('webToken', data.webToken);
    this[save]('webTokenCreatedTime', data.webTokenCreatedTime); // store timestamp of when created
    this[save]('userOrgName', data.userOrgName);
    this[save]('userRole', data.userRole);
    this.departments = data.departments;
    this.programs = data.programs || [];
    this.supplements = data.supplements;

    if (actingAs) {
      secureStorage.setItem(
        this.localStoragePrefix + 'departments',
        JSON.stringify(data.departments)
      );
      this.departments = data.departments;
      secureStorage.setItem(this.localStoragePrefix + 'programs', JSON.stringify(data.programs));
      this.programs = data.programs || [];
      secureStorage.setItem(
        this.localStoragePrefix + 'supplements',
        JSON.stringify(data.supplements)
      );
    }

    return true;
  }

  clearJanusData() {
    this.removeFromLocalStorage('firstName');
    this.removeFromLocalStorage('lastName');
    this.removeFromLocalStorage('email');
    this.removeFromLocalStorage('webToken');
    this.removeFromLocalStorage('webTokenCreatedTime');
    this.removeFromLocalStorage('userOrgName');
    this.removeFromLocalStorage('userRole');
    this.removeFromLocalStorage('departments');
    this.removeFromLocalStorage('programs');
    this.removeFromLocalStorage('supplements');
    // added in course of user events
    this.removeFromLocalStorage('managedUsers');
  }

  updateWebToken(newWebToken: string) {
    this.setAndSaveAttr('webToken', newWebToken);
    this.setAndSaveAttr('webTokenCreatedTime', new Date().toString()); // store timestamp of when created
  }

  loadProgramsMetadata = async function () {
    if (!this.programsMetadata || this.programsMetadata.length === 0) {
      const programs = await janusApi.getData('/diva/security/orgs/programs', {});
      if (programs) {
        this.programsMetadata = programs;
        secureStorage.setItem('userMetadata-programs', JSON.stringify(programs));
        return programs;
      }
    } else {
      return this.programsMetadata;
    }
  };

  toggleFavoriteProgram(programName) {
    const newFavoritePrograms = this.favoritePrograms ? this.favoritePrograms.slice() : [];
    const index = newFavoritePrograms.findIndex((programObj) => programObj.program === programName);
    if (index > -1) {
      newFavoritePrograms.splice(index, 1);
    } else {
      const programObject = this.getProgramObjectFromName(programName);
      newFavoritePrograms.push(programObject);
    }
    this.setInLocalStorage('favoritePrograms', JSON.stringify(newFavoritePrograms));
    this.setAttr('favoritePrograms', newFavoritePrograms);
  }

  setActiveProgram(programObject) {
    if (programObject) {
      this.setAttr('selectedActiveProgram', programObject);
      this.setInLocalStorage('selectedActiveProgram', JSON.stringify(programObject));
    }
  }

  clearActiveProgram() {
    this.setAttr('selectedActiveProgram', null);
    this.removeFromLocalStorage('selectedActiveProgram');
  }

  hasRoleForActiveProgram(role: string) {
    const activeProgramShortcode = this.activeProgram && this.activeProgram.short_name;
    if (this.redseaRolesToProgramsMap && this.redseaRolesToProgramsMap[role]) {
      return this.redseaRolesToProgramsMap[role].find((shortCode) => {
        const allMarqetaPermissions = shortCode === 'marqeta';
        const matchingActiveProgram = activeProgramShortcode === shortCode;
        return allMarqetaPermissions || matchingActiveProgram;
      });
    }
    return false;
  }

  hasAnyRoleForProgram(programShortCode) {
    return this.redseaRoles.some(
      (role) =>
        this.redseaRolesToProgramsMap[role]?.includes(programShortCode) ||
        this.redseaRolesToProgramsMap[role]?.includes('marqeta')
    );
  }

  hasRoleForActiveEnvironment(role: string) {
    const activeProgramShortcode = this.activeProgram && this.activeProgram.short_name;
    const activeEnvironment = this.privateSandboxActive
      ? localStorage.getItem(ENVIRONMENT_LOCAL_STORAGE_KEY)
      : PRODUCTION;
    if (
      this.redseaRolesToProgramsEnvironmentsMap &&
      this.redseaRolesToProgramsEnvironmentsMap[role]
    ) {
      return this.redseaRolesToProgramsEnvironmentsMap[role].find((roleObject) => {
        const allMarqetaPermissions = roleObject.domain_id === 'marqeta';
        const matchingActiveProgram = activeProgramShortcode === roleObject.domain_id;
        const matchingActiveEnvironment = activeEnvironment === roleObject.environment;
        return (
          (allMarqetaPermissions && matchingActiveEnvironment) ||
          (matchingActiveProgram && matchingActiveEnvironment)
        );
      });
    }
    return false;
  }

  hasRoleInArrayForActiveProgram(rolesArray: Array[] = []) {
    return rolesArray.some((role) => this.hasRoleForActiveProgram(role));
  }

  hasRoleInArrayForActiveEnvironment(rolesArray: Array[] = []) {
    return rolesArray.some((role) => this.hasRoleForActiveEnvironment(role));
  }

  hasRole(role: string) {
    const rolesSet = new Set(this.redseaRoles || []);
    if (rolesSet.has(role)) {
      return role;
    }
  }

  hasRequiredUamPermissions(requiredRoles: Array[] = []) {
    return requiredRoles.some((role_token) => this.redseaRoles.includes(role_token));
  }

  //computed

  /**
   * Searches programsMetadata from Janus for an org_name that matches the user's org
   * Returns all programs for the matching org
   * @returns {{program: string, short_name: string}[]}
   */
  get allJanusProgramsFromUserOrg() {
    const programsMetadata = this.programsMetadata;
    for (let i = 0; i < programsMetadata.length; i++) {
      const org = programsMetadata[i];
      if (org.org_name === this.userOrgName) {
        return org.programs;
      }
    }
    return [];
  }

  /**
   * Returns an array of all Janus programs for user
   * [ { program: "Program Display Name", short_name: "program short code" }...]
   */
  get programsList(): Array<*> {
    const programs = this.programs;
    if (this.allProgramAccess) {
      return this.allJanusProgramsFromUserOrg;
    } else {
      return programs;
    }
  }

  /**
   * Returns an array of programs for which the user has access via Redsea
   */
  get programsWithRolesList() {
    // Users in `marqeta` program in Redsea should see all programs
    // from the Marqeta org in Janus
    const allMarqetaPrograms = this.redseaPrograms.includes('marqeta');
    if (allMarqetaPrograms) return this.allJanusProgramsFromUserOrg;

    // Users NOT in `marqeta` program in Redsea should only see programs
    // for which they have a Redsea role
    if (!this.programsList) return [];
    const programsWithRoles = this.programsList.filter((program) =>
      this.redseaPrograms.includes(program.short_name)
    );
    return programsWithRoles;
  }

  get orderedProgramsWithRolesList(): Array<*> {
    if (!this.programsWithRolesList) return [];

    return this.programsWithRolesList
      .map((programInfo) => programInfo.program)
      .sort((a, b) => {
        if (typeof a === 'string' && typeof b === 'string') {
          if (a.toUpperCase() > b.toUpperCase()) {
            return 1;
          } else {
            return -1;
          }
        }
        return 0;
      });
  }

  get numberOfProgramsAvailable(): number {
    return this.programsList.length;
  }

  get allProgramAccess(): boolean {
    for (let i = 0; i < this.programs.length; i++) {
      const program = this.programs[i];
      if (program && program.program === 'All') {
        return true;
      }
    }
    return false;
  }

  get activeProgram() {
    if (this.selectedActiveProgram) {
      return this.selectedActiveProgram;
    }
    // if no program selected, default to users first program
    if (this.orderedProgramsWithRolesList && this.orderedProgramsWithRolesList.length) {
      return this.getProgramObjectFromName(this.orderedProgramsWithRolesList[0]);
    }
    return null;
  }

  get activeProgramShortCode() {
    // this is done for activeProgram and favorite programs
    // that are saved with short_name in localStorage so
    // user doesn't have to wait for programs to load
    if (this.activeProgram && typeof this.activeProgram.short_name === 'string') {
      return this.activeProgram.short_name;
    }
    const programObject = this.getProgramObjectFromName(this.activeProgram);
    const { short_name = '' } = programObject;
    return short_name;
  }

  get permissionLevel(): string {
    return this.userRole ? this.userRole : 'undefined';
  }

  get initials(): string {
    let initials = [];
    if (this.firstName) {
      initials.push(this.firstName.slice(0, 1));
    }
    if (this.lastName) {
      initials.push(this.lastName.slice(0, 1));
    }
    return initials.join('');
  }

  get fullName(): string {
    return `${this.firstName ? this.firstName : ''} ${this.lastName ? this.lastName : ''}`;
  }

  get redseaOrJanusFirstName(): string {
    return this.redsea_first_name || this.firstName;
  }

  get departmentsString(): string {
    try {
      if (this.departments && this.departments.toJS) {
        // $FlowFixMe
        return this.departments.toJS().join(', ');
      } else {
        return 'none';
      }
    } catch (error) {
      return 'none';
    }
  }

  get extendedDepartments(): Array<string> {
    let departmentsJs = [];
    if (this.departments && this.departments.toJS) {
      departmentsJs = this.departments.toJS();
    }
    return [].concat(departmentsJs, this.mappedDepartmentsToRedseaRoles).filter(Boolean);
  }

  get mappedDepartmentsToRedseaRoles(): Array<string> {
    return this.redseaRoles
      .map((role) => {
        return redseaDepartmentsDisplayMapping[role && role.toUpperCase()];
      })
      .filter(Boolean);
  }

  get extendedSupplements(): Array<string> {
    let supplementsJs = [];
    if (this.supplements && this.supplements.toJS) {
      supplementsJs = this.supplements.toJS();
    }
    return [].concat(supplementsJs, this.mappedSupplementsToRedseaRoles).filter(Boolean);
  }

  get mappedSupplementsToRedseaRoles(): Array<string> {
    return this.redseaRoles
      .map((role) => {
        return redseaSupplementsDisplayMapping[role && role.toUpperCase()];
      })
      .filter(Boolean);
  }

  get tokensAreProvisioned(): any {
    try {
      return (
        this.apiTokens &&
        // $FlowFixMe
        Array.isArray(this.apiTokens.toJS()) &&
        // $FlowFixMe
        this.apiTokens.toJS().length > 0
      );
    } catch (error) {
      return false;
    }
  }
  get programListForMarqetaApi(): string {
    let programNames = [];
    for (let i = 0; i < this.programs.length; i++) {
      const program = this.programs[i];
      const programShortName = program.short_name;
      programNames.push(programShortName);
    }
    return programNames.join(',');
  }

  // helpers
  getProgramObjectFromName(name): Object {
    for (let i = 0; i < this.programsList.length; i++) {
      const programObject = this.programsList[i];
      if (programObject.program === name) return programObject;
    }
    return {};
  }

  loggedIn(): boolean {
    return !!this.redsea_email || this.loggedInToJanus;
  }

  get loggedInComputed(): boolean {
    return !!this.redsea_email || this.loggedInToJanus;
  }

  get loggedInToJanus(): boolean {
    return Boolean(this.webToken);
  }

  recordLogin() {
    if (googleAnalytics) {
      this.setGoogleAnalyticsUid();
      googleAnalytics.event({
        category: 'Key User Events',
        action: 'User Logged In',
        label: 'User Logged In',
      });
    }
  }

  logOut = async () => {
    this.isLoggingOut = true;
    this.persistToLocalStorage();
    logOut();
  };

  persistToLocalStorage() {
    const persistWithTableConfig = this.persistInLocalStorage.slice();
    for (const val in UserStoreTableConfigKeys) {
      persistWithTableConfig.push({ key: val, parse: true, skipEncrypt: true });
    }
    const persisted = persistWithTableConfig
      .map((element) => {
        const { skipEncrypt, parse, key } = element;
        try {
          let val;
          if (skipEncrypt) {
            val = parse ? JSON.parse(localStorage.getItem(key)) : localStorage.getItem(key);
          } else {
            val = parse ? JSON.parse(this.getFromLocalStorage(key)) : this.getFromLocalStorage(key);
          }
          return {
            key,
            val,
            skipEncrypt,
            stringify: parse,
          };
        } catch (error) {
          return null;
        }
      })
      .filter(Boolean);
    persisted.forEach((element) => {
      const { stringify, val, key, skipEncrypt } = element;
      const valToSet = stringify ? JSON.stringify(val) : val;
      skipEncrypt ? localStorage.setItem(key, valToSet) : this.setInLocalStorage(key, valToSet);
    });
  }

  setInLocalStorage(key: string, value: string) {
    secureStorage.setItem(this.localStoragePrefix + key, value);
  }

  removeFromLocalStorage(key: string) {
    secureStorage.removeItem(this.localStoragePrefix + key);
  }

  getFromLocalStorage(key: string) {
    return secureStorage.getItem(this.localStoragePrefix + key);
  }

  loadApiTokens(existingApiTokens: string) {
    const existingTokensArray = JSON.parse(existingApiTokens);
    return existingTokensArray.map((token) => {
      const newTokenStore = new TokenStore();
      newTokenStore.load({
        dateCreated: token.date_created,
        dateLastUsed: token.date_last_used,
        dateUpdated: token.date_updated,
        durableAccessTokenId: token.durable_access_token_id,
        durableAccessTokenType: token.durable_access_token_type,
        email: token.email,
        expireDays: token.expireDays,
        id: token.id,
        note: token.note,
        status: token.status,
      });
      return newTokenStore;
    });
  }

  loadManagedUsers(managedUsers: []) {
    return managedUsers.map((managedUser) => {
      const managedUserStore = new ManagedUserStore();
      managedUserStore.load({
        dateCreated: managedUser.date_created,
        dateUpdated: managedUser.date_updated,
        departments: managedUser.departments,
        email: managedUser.email,
        slackHandle: managedUser.slack_handle,
        originalEmail: managedUser.email,
        firstName: managedUser.first_name,
        lastName: managedUser.last_name,
        id: managedUser.id,
        orgName: managedUser.org_name,
        orgType: managedUser.org_type,
        programs: managedUser.programs,
        role: managedUser.role,
        status: managedUser.status,
        supplements: managedUser.supplements,
      });
      return managedUserStore;
    });
  }

  permissionsGreaterThan(permissionLevel: string) {
    const permissionsLevels = ['Viewer', 'Support', 'Admin', 'SuperAdmin'];
    const managedUserPermissionLevel = permissionsLevels.indexOf(this.userRole);
    const checkPermissionLevel = permissionsLevels.indexOf(permissionLevel);
    if (managedUserPermissionLevel === -1 || checkPermissionLevel === -1) {
      // if not found refturn false to be safe
      return false;
    }
    return managedUserPermissionLevel > checkPermissionLevel;
  }

  permissionsGreaterThanOrEqualTo(permissionLevel: string) {
    const permissionsLevels = ['Viewer', 'Support', 'Admin', 'SuperAdmin'];
    const managedUserPermissionLevel = permissionsLevels.indexOf(this.userRole);
    const checkPermissionLevel = permissionsLevels.indexOf(permissionLevel);
    if (managedUserPermissionLevel === -1 || checkPermissionLevel === -1) {
      // if not found refturn false to be safe
      return false;
    }
    return managedUserPermissionLevel >= checkPermissionLevel;
  }

  setGoogleAnalyticsUid(): void {
    if (this.userId && googleAnalytics) {
      googleAnalytics.set({ userId: this.userId });
      googleAnalytics.set({ dimension1: this.userId }); // User ID (Janus)
    }
    if (this.redsea_token && googleAnalytics) {
      googleAnalytics.set({ dimension2: this.redsea_token }); // User ID (Redsea)
    }
  }

  getTableConfigKey(key) {
    return UserStoreTableConfigKeys && UserStoreTableConfigKeys[key];
  }

  get unifyActive() {
    return this.flipFlop.get('unify-beta', false) || this.flipFlop.get('unify-beta-org', false);
  }

  get ddaActive() {
    return this.flipFlop.get('dda-beta', false);
  }

  get tokenizationActive() {
    return this.flipFlop.get('tokenization-beta', false);
  }

  get canRemoveFunds() {
    const hasUamGranularPermissions = this.uamGranularPermissionsActive;
    if (!hasUamGranularPermissions) {
      const allowedRedseaRoles = [
        'production-support-internal',
        'delivery-internal',
        'aux-remove-cardholder-funds',
      ];

      return this.hasRoleInArrayForActiveProgram(allowedRedseaRoles);
    }

    const hasRequiredUAMRole = this.redseaRoles.some(
      (role) => role === REMOVE_CARDHOLDER_FUNDS_VIEW_AND_EDIT
    );

    return !!hasRequiredUAMRole;
  }

  get digitalWalletTokenActive() {
    const hasUamGranularPermissions = this.uamGranularPermissionsActive;
    const hasRequiredRedseaRole = this.redseaRoles.some((role) =>
      [DIGITAL_WALLET_TOKEN_DETAILS_VIEW, DIGITAL_WALLET_TOKEN_DETAILS_VIEW_AND_EDIT].includes(role)
    );
    const hasRequiredFlipFlopFlag = this.flipFlop.get('digital-wallet-token-beta', false);

    return hasUamGranularPermissions ? hasRequiredRedseaRole : hasRequiredFlipFlopFlag;
  }

  get cardProductViewActive() {
    const hasUamGranularPermissions = this.uamGranularPermissionsActive;
    const hasRequiredRedseaRole = this.redseaRoles.includes(CARD_PRODUCTS_VIEW);

    return !(hasUamGranularPermissions && !hasRequiredRedseaRole);
  }

  get userDetailsViewAndEditActive() {
    const hasUamGranularPermissions = this.uamGranularPermissionsActive;
    const hasRequiredRedseaRole = this.redseaRoles.includes(USER_DETAILS_VIEW_AND_EDIT);

    return !(hasUamGranularPermissions && !hasRequiredRedseaRole);
  }

  get userDetailsViewActive() {
    const hasUamGranularPermissions = this.uamGranularPermissionsActive;
    const hasRequiredRedseaRole = this.redseaRoles.includes(USER_DETAILS_VIEW);

    return !(hasUamGranularPermissions && !hasRequiredRedseaRole);
  }

  get binManagementActive() {
    return this.flipFlop.get('bin-management-active', false);
  }

  get binDataManagementActive() {
    return (
      this.hasRoleInArrayForActiveProgram(['manage-bin-data-internal', 'view-bin-data-internal']) ||
      this.flipFlop.get('bin-data-management-active', false)
    );
  }

  get canManageBinData() {
    return (
      this.hasRoleInArrayForActiveProgram(['manage-bin-data-internal']) ||
      this.flipFlop.get('bin-data-management-active', false)
    );
  }

  get progressiveMfaActive() {
    return this.flipFlop.get('progressive-mfa', false);
  }

  get canRevealIdentification() {
    const rolesWithAccess = ['aux-access-pii'];
    const userDetailsSensitive = [
      USER_DETAILS_SENSITIVE_VIEW,
      USER_DETAILS_SENSITIVE_VIEW_AND_EDIT,
    ];

    const hasUserDetailsSensitive = this.redseaRoles.some((role) =>
      userDetailsSensitive.includes(role)
    );

    return this.uamGranularPermissionsActive
      ? hasUserDetailsSensitive
      : this.hasRoleInArrayForActiveProgram(rolesWithAccess);
  }

  get acceptedCountriesActive() {
    if (this.uamGranularPermissionsActive) {
      return this.hasRoleInArrayForActiveProgram([PROGRAM_CONFIGURATION_VIEW_AND_EDIT]);
    } else {
      const rolesWithAccess = ['delivery-internal', 'production-support-internal'];
      return (
        this.hasRoleInArrayForActiveProgram(rolesWithAccess) &&
        this.flipFlop.get('accepted-countries', false)
      );
    }
  }

  get applicationTokensActive() {
    if (this.uamGranularPermissionsActive) {
      return this.hasRoleInArrayForActiveProgram([PROGRAM_CONFIGURATION_VIEW_AND_EDIT]);
    } else {
      const rolesWithAccess = ['delivery-internal', 'production-support-internal'];
      return (
        this.hasRoleInArrayForActiveProgram(rolesWithAccess) &&
        this.flipFlop.get('app-tokens', false)
      );
    }
  }

  get approvalQueueActive() {
    let rolesWithAccess;
    if (this.uamGranularPermissionsActive) {
      rolesWithAccess = [PROGRAM_CONFIGURATION_VIEW_AND_EDIT];
    } else {
      rolesWithAccess = [
        'delivery-internal',
        'production-support-internal',
        'manage-bin-data-internal',
      ];
    }

    return this.hasRoleInArrayForActiveProgram(rolesWithAccess);
  }

  get cardProductsActive() {
    if (this.uamGranularPermissionsActive) {
      return this.hasRoleInArrayForActiveProgram([PROGRAM_CONFIGURATION_VIEW_AND_EDIT]);
    } else {
      const rolesWithAccess = ['delivery-internal', 'production-support-internal'];
      return (
        this.hasRoleInArrayForActiveProgram(rolesWithAccess) &&
        this.flipFlop.get('card-products', false)
      );
    }
  }

  get mccGroupsActive() {
    if (this.uamGranularPermissionsActive) {
      return this.hasRoleInArrayForActiveProgram([PROGRAM_CONFIGURATION_VIEW_AND_EDIT]);
    } else {
      return (
        this.hasRoleInArrayForActiveProgram(['delivery-internal', 'production-support-internal']) &&
        this.flipFlop.get('mcc-groups', false)
      );
    }
  }

  get programControlsActive() {
    if (this.uamGranularPermissionsActive) {
      return this.hasRoleInArrayForActiveProgram([PROGRAM_CONFIGURATION_VIEW_AND_EDIT]);
    } else {
      const rolesWithAccess = ['delivery-internal', 'production-support-internal'];
      return (
        this.hasRoleInArrayForActiveProgram(rolesWithAccess) &&
        this.flipFlop.get('program-controls', false)
      );
    }
  }

  get auxShowPanActive() {
    const rolesWithAccess = ['aux-show-pan'];
    return this.hasRoleInArrayForActiveProgram(rolesWithAccess);
  }

  get auxShowDWTPanActive() {
    const rolesWithAccess = ['aux-show-pan'];
    return this.hasRoleInArrayForActiveProgram(rolesWithAccess) || this.isCreditSupportAgent;
  }

  get isCreditSupportAgent() {
    const roles = ['aux-credit-support-agent-external'];

    return this.hasRoleInArrayForActiveProgram(roles);
  }

  get kybCustomerWrite() {
    const rolesWithAccess = [
      'compliance-program-managed',
      'compliance-processor-only',
      'program-admin',
    ];

    return this.hasRoleInArrayForActiveProgram(rolesWithAccess);
  }

  get kybCanWrite() {
    const rolesWithAccess = [
      'compliance-internal',
      'risk-internal',
      'delivery-internal',
      'production-support-internal',
      'marqeta-admin-internal',
    ];

    return this.hasRoleInArrayForActiveProgram(rolesWithAccess);
  }

  get canEditRiskControlKyc() {
    const rolesWithAccess = [
      'cardholder-support',
      'compliance-program-managed',
      'compliance-internal',
      'production-support-internal',
      'risk-internal',
    ];

    return this.hasRoleInArrayForActiveEnvironment(rolesWithAccess);
  }

  get uamGranularPermissionsActive() {
    return this.redseaRoles?.includes(UAM_GRANULAR_PERMISSIONS);
  }

  get privateSandboxActive() {
    return this.redseaRoles?.includes('private-sandbox');
  }

  get auditLogTableActive() {
    const rolesWithAccess = [
      'production-support-internal',
      'program-admin',
      'developer',
      'marqeta-admin-internal',
    ];

    const isUamActive = this.uamGranularPermissionsActive;
    const uamAuditLogRoles = [AUDIT_LOGS_VIEW];

    return isUamActive
      ? this.hasRoleInArrayForActiveProgram(uamAuditLogRoles)
      : this.hasRoleInArrayForActiveProgram(rolesWithAccess);
  }

  get hasMarqetaEmail() {
    return this.email && this.email.includes('@marqeta.com');
  }

  get cardCreationActive() {
    const rolesWithAccess = [
      'marqeta-admin-internal',
      'program-admin',
      'supplier-payments-manager',
      'delivery-internal',
      'production-support-internal',
      'cardholder-support',
      'aux-manual-card-create',
    ];

    return this.uamGranularPermissionsActive
      ? this.hasRoleInArrayForActiveProgram([CREATE_CARD_MANUALLY_VIEW_AND_EDIT])
      : this.hasRoleInArrayForActiveProgram(rolesWithAccess);
  }

  get reportCardLostStolenActive() {
    const rolesWithAccess = [
      'access-manager',
      'cardholder-support',
      'compliance-internal',
      'compliance-processor-only',
      'delivery-internal',
      'fulfillment-internal',
      'marqeta-admin-internal',
      'production-support-internal',
      'program-admin',
      'risk-internal',
      'supplier-payments-manager',
      'aux-credit-support-agent-external',
      'aux-report-card-lost-stolen',
    ];

    return this.uamGranularPermissionsActive
      ? this.redseaRoles?.includes(REPORT_STOLEN_VIEW_AND_EDIT)
      : this.hasRoleInArrayForActiveProgram(rolesWithAccess);
  }

  get cardSuspendActive() {
    const rolesWithAccess = [
      'access-manager',
      'cardholder-support',
      'compliance-internal',
      'compliance-processor-only',
      'delivery-internal',
      'fulfillment-internal',
      'marqeta-admin-internal',
      'production-support-internal',
      'program-admin',
      'risk-internal',
      'supplier-payments-manager',
      'aux-credit-support-agent-external',
      'aux-card-suspend',
    ];

    const hasUamGranularPermissions = this.uamGranularPermissionsActive;
    const hasSuspendCardViewAndEdit = this.redseaRoles?.includes(SUSPEND_CARD_VIEW_AND_EDIT);

    return hasUamGranularPermissions
      ? hasSuspendCardViewAndEdit
      : this.hasRoleInArrayForActiveProgram(rolesWithAccess);
  }

  get cardReplaceActive() {
    const rolesWithAccess = [
      'access-manager',
      'cardholder-support',
      'compliance-internal',
      'compliance-processor-only',
      'delivery-internal',
      'fulfillment-internal',
      'marqeta-admin-internal',
      'production-support-internal',
      'program-admin',
      'risk-internal',
      'supplier-payments-manager',
      'aux-credit-support-agent-external',
      'aux-card-replace',
    ];

    return this.uamGranularPermissionsActive
      ? this.redseaRoles?.includes(REPLACE_CARD_VIEW_AND_EDIT)
      : this.hasRoleInArrayForActiveProgram(rolesWithAccess);
  }

  get canViewCommandoMode() {
    const hasUamGranularPermissions = this.uamGranularPermissionsActive;
    const hasCommandModeViewAndEdit = this.redseaRoles?.some((role) =>
      [INVOKE_COMMANDO_MODE_VIEW, INVOKE_COMMANDO_MODE_VIEW_AND_EDIT].includes(role)
    );

    return !(hasUamGranularPermissions && !hasCommandModeViewAndEdit);
  }

  get canEditCommandoMode() {
    const hasUamGranularPermissions = this.uamGranularPermissionsActive;
    const hasCommandModeEdit = this.redseaRoles?.some((role) =>
      [INVOKE_COMMANDO_MODE_VIEW_AND_EDIT].includes(role)
    );

    return !(hasUamGranularPermissions && !hasCommandModeEdit);
  }

  get hasCardDetailsViewAndEdit() {
    const hasUamGranularPermissions = this.uamGranularPermissionsActive;
    const hasCardDetailsViewAndEdit = this.redseaRoles?.includes(CARD_DETAILS_VIEW_AND_EDIT);
    return hasUamGranularPermissions ? hasCardDetailsViewAndEdit : true;
  }

  get showTerminateCard() {
    return this.hasCardDetailsViewAndEdit;
  }

  get showActivateCard() {
    return this.hasCardDetailsViewAndEdit;
  }

  get canAccessDisputes() {
    const roles = ['risk-internal', 'aux-risk-external'];

    return this.uamGranularPermissionsActive
      ? this.hasRoleForActiveProgram(DISPUTES_VIEW_AND_EDIT)
      : this.hasRoleInArrayForActiveProgram(roles);
  }

  get canViewPan() {
    return !(this.uamGranularPermissionsActive && !this.redseaRoles?.includes(FULL_PAN_VIEW));
  }

  get canSetPin() {
    const hasUamGranularPermissions = this.uamGranularPermissionsActive;
    const hasSetPinViewAndEdit = this.redseaRoles?.some((role) =>
      [SET_PIN_VIEW_AND_EDIT].includes(role)
    );

    return !(hasUamGranularPermissions && !hasSetPinViewAndEdit);
  }

  get newUamPermissionRolloutActive() {
    return this.flipFlop.get('new-uam-permission-rollout', false);
  }

  get hasAccountHolderDetailsViewAndEdit() {
    const hasAchDetailsViewAndEdit = this.redseaRoles?.some((role) =>
      [ACCOUNT_HOLDER_DETAILS_VIEW_AND_EDIT].includes(role)
    );
    return !(this.uamGranularPermissionsActive && !hasAchDetailsViewAndEdit);
  }

  get canViewAchDetailsSensitiveInfo() {
    const hasAchDetailsSensitiveView = this.redseaRoles?.some((role) =>
      [
        ACCOUNT_HOLDER_DETAILS_SENSITIVE_VIEW,
        ACCOUNT_HOLDER_DETAILS_SENSITIVE_VIEW_AND_EDIT,
      ].includes(role)
    );
    return !(this.uamGranularPermissionsActive && !hasAchDetailsSensitiveView);
  }

  get hasAchDetailsSensitiveViewAndEdit() {
    const hasAchSensitiveViewAndEdit = this.redseaRoles?.some((role) =>
      [ACCOUNT_HOLDER_DETAILS_SENSITIVE_VIEW_AND_EDIT].includes(role)
    );
    return !(this.uamGranularPermissionsActive && !hasAchSensitiveViewAndEdit);
  }

  get canEditKycExceptions() {
    const hasKycExceptionsViewAndEdit = this.redseaRoles?.some((role) =>
      [KYC_EXCEPTIONS_VIEW_AND_EDIT].includes(role)
    );
    return this.uamGranularPermissionsActive ? hasKycExceptionsViewAndEdit : true;
  }

  canEditAccountHolderDetails(nonUamEditPermission: boolean) {
    return this.uamGranularPermissionsActive
      ? this.hasAccountHolderDetailsViewAndEdit
      : nonUamEditPermission;
  }

  canEditAccountHolderSensitiveDetails(nonUamEditPermission: boolean) {
    return this.uamGranularPermissionsActive
      ? this.hasAchDetailsSensitiveViewAndEdit
      : nonUamEditPermission;
  }

  get canViewInventoryMgmt() {
    const rolesWithAccess = ['aux-inventory-management'];

    return this.uamGranularPermissionsActive
      ? this.redseaRoles?.includes(INVENTORY_MGMT_VIEW)
      : this.hasRoleInArrayForActiveProgram(rolesWithAccess);
  }

  get hasNoReportsSupplement() {
    return this.redseaRoles?.includes('no-reports') || this.supplements?.includes('No Reports');
  }
}

decorate(UserStore, {
  // values
  localStoragePrefix: observable,
  userId: observable,
  firstName: observable,
  lastName: observable,
  email: observable,
  webToken: observable,
  webTokenCreatedTime: observable,
  apiTokens: observable,
  apiTokensErrored: observable,
  userOrgName: observable,
  userRole: observable,
  redseaPrograms: observable,
  redseaRoles: observable,
  redseaRolesToProgramsMap: observable,
  redseaRolesToProgramsEnvironmentsMap: observable,
  persistApiUserId: observable,
  departments: observable,
  managedUsers: observable,
  programs: observable,
  programsMetadata: observable,
  savedReports: observable,
  defaultReport: observable,
  dataJournals: observable,
  supplements: observable,
  selectedActiveProgram: observable,
  userPersistDataLoaded: observable,
  totp: observable,
  phone: observable,
  favoritePrograms: observable,
  redsea_first_name: observable,
  redsea_last_name: observable,
  redsea_phone: observable,
  redsea_email: observable,
  redsea_mfa_totp_enabled: observable,
  redsea_mfa_enabled: observable,
  redsea_token: observable,
  loading: observable,
  bootstrapSessionRun: observable,
  featureFlagDataLoaded: observable,

  // actions
  bootstrapSession: action.bound,
  demoLogin: action.bound,
  initPersistUser: action.bound,
  saveReport: action.bound,
  setDefaultReport: action.bound,
  deleteReport: action.bound,
  editReport: action.bound,
  refreshUserData: action.bound,
  getApiTokens: action.bound,
  getManagedUsers: action.bound,
  setAndSaveAttr: action.bound,
  setupPersistUser: action.bound,
  addSavedReport: action.bound,
  loadJanusData: action.bound,
  loadRedseaData: action.bound,
  clearJanusData: action.bound,
  updateWebToken: action.bound,
  loadProgramsMetadata: action.bound,
  setInLocalStorage: action.bound,
  hydrateWithRedsea: action.bound,
  updateRedseaUser: action.bound,
  setActiveProgram: action.bound,
  clearActiveProgram: action.bound,
  toggleFavoriteProgram: action.bound,
  hasRoleForActiveProgram: action.bound,
  hasRoleForActiveEnvironment: action.bound,
  hasRoleInArrayForActiveProgram: action.bound,
  hasRoleInArrayForActiveEnvironment: action.bound,
  hasRequiredUamPermissions: action.bound,
  hasRole: action.bound,

  // computed
  auditLogTableActive: computed,
  cardCreationActive: computed,
  reportCardLostStolenActive: computed,
  cardSuspendActive: computed,
  cardReplaceActive: computed,
  defaultReportName: computed,
  defaultReportConfig: computed,
  programsList: computed,
  programsWithRolesList: computed,
  orderedProgramsWithRolesList: computed,
  allProgramAccess: computed,
  permissionLevel: computed,
  initials: computed,
  fullName: computed,
  redseaOrJanusFirstName: computed,
  tokensAreProvisioned: computed,
  departmentsString: computed,
  programListForMarqetaApi: computed,
  hasMarqetaEmail: computed,
  activeProgram: computed,
  activeProgramShortCode: computed,
  numberOfProgramsAvailable: computed,
  loggedInComputed: computed,
  loggedInToJanus: computed,
  extendedDepartments: computed,
  mappedDepartmentsToRedseaRoles: computed,
  extendedSupplements: computed,
  mappedSupplementsToRedseaRoles: computed,
  canRevealIdentification: computed,
  digitalWalletTokenActive: computed,
  allJanusProgramsFromUserOrg: computed,
  auxShowPanActive: computed,
  kybCanWrite: computed,
  isCreditSupportAgent: computed,
  auxShowDWTPanActive: computed,
  binManagementActive: computed,
  binDataManagementActive: computed,
  canManageBinData: computed,
  canAccessDisputes: computed,
  acceptedCountriesActive: computed,
  applicationTokensActive: computed,
  approvalQueueActive: computed,
  cardProductsActive: computed,
  mccGroupsActive: computed,
  programControlsActive: computed,
  progressiveMfaActive: computed,
  canSetPin: computed,
  hasNoReportsSupplement: computed,

  // helpers
  getProgramObjectFromName: action.bound,
});

const userStore = new UserStore(undefined, flipFlop);
export { UserStore as UserStoreClass };
export default userStore;
