import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Colors, Flex, Icon, Link, LoadingOverlay, Text, HSpacer } from '@mqd/volt-base';
import { DATAVISOR_URL_PROD, DATAVISOR_URL_SANDBOX, FEATURESPACE_URL } from './constants';
import s from './RealTimeDecisioningModal.module.css';

const RealTimeDecisioningModal = ({ hideModal, version }) => {
  const [loading, setLoading] = useState(true);
  const datavisorURL =
    localStorage.getItem('selectedEnvironment') === 'sbx'
      ? DATAVISOR_URL_SANDBOX
      : DATAVISOR_URL_PROD;
  const iframeUrl = version === 'v1' ? FEATURESPACE_URL : datavisorURL;

  return (
    <div className={s.realTimeDecisioningModal} data-testId="real-time-decisioning-modal">
      <Flex alignItems="center" justifyContent="space-between" className={s.header}>
        <Text type="label" color={Colors.blackLighter2}>
          Controls
        </Text>
        <Link
          tabIndex="1"
          type="body-sm"
          className={s.flexText}
          onClick={hideModal}
          testId="exit-controls"
        >
          <Icon type="clear" noHoverEffects={true} /> <HSpacer factor={0.5} />
          Exit controls
        </Link>
      </Flex>
      <LoadingOverlay active={loading} size={64}>
        <div className={s.iFrame}>
          <iframe
            title="Marqeta RTD"
            src={iframeUrl}
            target="_parent"
            width="100%"
            height="100%"
            onLoad={() => setLoading(false)}
          />
        </div>
      </LoadingOverlay>
    </div>
  );
};

RealTimeDecisioningModal.propTypes = {
  hideModal: PropTypes.func,
};

RealTimeDecisioningModal.defaultProps = {
  hideModal: () => {},
};

export default RealTimeDecisioningModal;
