import retry from './../../utils/helpers/retry.js';
const lazyLoader = async (factory) => {
  if (factory) {
    const res = await retry(factory);
    return res.default;
  }
  throw new Error(
    `Factory is undefined. Please check that your route has been added to RouteFactoryMap.js`
  );
};

export default lazyLoader;
