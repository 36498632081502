export const makeToggleClick =
  (setState, optionToID = (id) => id) =>
  (option) => {
    const id = optionToID(option);
    setState((prev) => {
      const out = new Set(prev);
      if (out.has(id)) {
        out.delete(id);
      } else {
        out.add(id);
      }
      return out;
    });
  };

export const makeBulkToggleClick =
  (state, setState, all, optionToID = (id) => id) =>
  () => {
    if (state.size !== all.length) {
      setState(new Set(all.map((option) => optionToID(option))));
    } else {
      setState(new Set());
    }
  };
