import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Text, Button, VSpacer, Toggle, Link } from '@mqd/volt-base';
import s from './digitalwallettoken.module.css';
import TerminateTokenModal from './../digital-wallet-tokens-table/TerminateTokenModal';

function DigitalWalletTokensActions({
  state,
  terminateToken,
  lockToken,
  activateToken,
  displayTerminateModal,
  showTerminateModal,
  hideTerminateModal,
  setToastProps,
}) {
  const isInactive = state !== 'ACTIVE';
  const lockCardText = isInactive ? 'Unlock token' : 'Lock token';
  const lockCardDescription = isInactive
    ? 'This token will become functional upon unlocking.'
    : 'This token will become non-functional upon locking.';
  const lockCardAction = isInactive ? activateToken : lockToken;

  const lockCardActionHandler = async () => {
    const response = await lockCardAction();
    if (response) {
      setToastProps({
        text:
          lockCardText === 'Unlock token'
            ? 'Token unlocked successfully'
            : 'Token locked successfully',
      });
    }
  };

  return (
    <div className={s.flexStart} data-testid="digital-wallet-token-actions">
      <div className={s.actionContainer}>
        <Text type="h6">Terminate token</Text>
        <VSpacer factor={0.5} />
        <div>
          <Text>This token will be permanently non-functional upon terminating.</Text>
          <VSpacer factor="2" />
          <Button
            testId="dwt-actions-terminate-token-button"
            type="secondary"
            onClick={displayTerminateModal}
          >
            Terminate token
          </Button>
        </div>
      </div>

      <div className={s.actionContainer}>
        <Text type="h6">{lockCardText}</Text>
        <VSpacer factor={0.5} />
        <div>
          <Text>{lockCardDescription}</Text>
          <VSpacer factor="2" />
          <Button testId="dwt-actions-lock" type="secondary" onClick={lockCardActionHandler}>
            {lockCardText}
          </Button>
        </div>
      </div>

      <TerminateTokenModal
        showTerminateModal={showTerminateModal}
        hideTerminateModal={hideTerminateModal}
        handleTermination={terminateToken}
        setToastProps={setToastProps}
      />
    </div>
  );
}

DigitalWalletTokensActions.propTypes = {
  state: PropTypes.string,
  terminateToken: PropTypes.func,
  lockToken: PropTypes.func,
  activateToken: PropTypes.func,
  displayTerminateModal: PropTypes.bool,
  showTerminateModal: PropTypes.bool,
  hideTerminateModal: PropTypes.func,
  setToastProps: PropTypes.func,
};

DigitalWalletTokensActions.defaultProps = {
  state: null,
  terminateToken: () => {},
  lockToken: () => {},
  activateToken: () => {},
  displayTerminateModal: () => {},
  showTerminateModal: false,
  hideTerminateModal: () => {},
  setToastProps: () => {},
};

export default observer(DigitalWalletTokensActions);
