/* eslint-disable camelcase */
import React, { Component, createRef } from 'react';
import { observer } from 'mobx-react';
import { Input, ModalAlert, Icon, Tooltip, Text, HSpacer, Button, Colors } from '@mqd/volt-base';
import { segmentTrackEvent } from '@mq/voltron-parent';
import PropTypes from 'prop-types';
import { AddNoteModal, ChangeStatusModal, DisableCardModal } from '@mq/parity-plus';
import currentUserStore from 'stores/CurrentUserStore';
import routerStore from 'stores/router/RouterStore';
import GlobalSearchStore, { MAX_NUM_PROGRAMS_TO_SEARCH_ACROSS } from './GlobalSearchStore.js';
import s from './GlobalSearch.module.css';
import GlobalSearchNoResult from './GlobalSearchNoResult.js';
import GlobalSearchResults from './GlobalSearchResults.js';

class GlobalSearch extends Component {
  constructor(props) {
    super(props);
    // doing this here instead of pullin in parent component
    // because I ran into a really weird bug where the first
    // event wasn't causing rerender. This fixes it for
    // whatever reason. Still looking for cause.
    this.store = new GlobalSearchStore(props.storeInitArgs);
    if (typeof props.onStoreConstruction === 'function') {
      props.onStoreConstruction(this.store);
    }
    this.runSearch = this.debounce(this.runSearch.bind(this), 200);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.addNote = this.addNote.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.disableCard = this.disableCard.bind(this);
    this.handleViewClick = this.handleViewClick.bind(this);
    this.clearInputAndResult = this.clearInputAndResult.bind(this);
    this.findProgramName = this.findProgramName.bind(this);
    this.handleKeyEnter = this.handleKeyEnter.bind(this);
    this.state = {
      activeStore: null,
      showAddNoteModal: false,
      showChangeStatusModal: false,
      showNoteNotAddedAlert: false,
      showNoteSuccessfullyAddedAlert: false,
      showDisableCardModal: false,
      hovered: false,
      focused: false,
    };
    this.searchWrapRef = createRef();
  }

  get hoveredFocused() {
    const { hovered, focused } = this.state;

    return hovered || focused;
  }

  addNote(store) {
    this.setState({ showAddNoteModal: true, activeStore: store });
  }

  changeStatus(store) {
    this.setState({ showChangeStatusModal: true, activeStore: store });
  }

  disableCard(store) {
    this.setState({ showDisableCardModal: true, activeStore: store });
  }

  handleViewClick(resourceType, resourceStore) {
    const { onClickResult } = this.props;
    onClickResult && onClickResult({ resourceType, resourceStore });
  }

  handleInputChange(e) {
    const { setAttr } = this.store;
    setAttr('searchVal', e.target.value);

    // if user clears the input manually we want to make sure we clear the no result state
    if (!e.target.value) {
      this.clearInputAndResult();
    }
  }

  runSearch() {
    const searchVal = this.store.searchVal;
    const valueIsAccountType = !isNaN(+searchVal) && searchVal.length === 13;

    segmentTrackEvent('Global Search Initiated', {
      type: valueIsAccountType ? 'Account' : 'Token',
    });

    const { search } = this.store;
    const { resourceType } = this.props;
    search(resourceType);
  }

  handleKeyEnter(e) {
    const { key } = e;

    if (key === 'Enter') {
      this.runSearch();
    }
  }

  debounce(func, time) {
    let timeout;
    return function () {
      clearTimeout(timeout);
      timeout = setTimeout(func, time);
    };
  }

  clearInputAndResult() {
    const { clearResults, setAttr } = this.store || {};

    setAttr && setAttr('searchVal', '');
    clearResults && clearResults();
  }

  findProgramName(programs, programShortCode) {
    if (programs?.length > 0 && programShortCode) {
      const programObj = programs.find((program) => program.short_name === programShortCode);
      return programObj?.program || programShortCode;
    }
    return '';
  }

  renderResults() {
    const { results, showNoResult } = this.store;
    const { programsList = [] } = currentUserStore.userStore;

    const processedResults = results.reduce((acc, { type, store }) => {
      let mainText = '';

      switch (type) {
        case 'Tokens':
          return [
            ...acc,
            {
              type,
              programName: this.findProgramName(programsList, store.programShortCode),
              programShortCode: store.programShortCode,
              store,
              mainText: store.token,
              onViewClick: () =>
                routerStore.go('/program/token-service/token', { params: { token: store.token } }),
            },
          ];
        case 'Business':
          mainText = store.business_name_legal || store.token;
          break;
        case 'User':
          mainText = store.fullName || store.token;
          break;
        case 'Card':
          mainText = `*****${store.last_four}`;
          break;
        case 'Dispute':
          mainText = store.token;
          break;
        case 'Transaction':
          const { currencyCode, cardAcceptorName, amount } = store;
          mainText = `(${amount}) ${currencyCode} - ${cardAcceptorName}`;
          break;
        default:
          break;
      }

      const resultType = type === 'User' ? 'cardholder' : type.toLowerCase();

      return [
        ...acc,
        {
          type,
          programName: this.findProgramName(programsList, store.programShortCode),
          programShortCode: store.programShortCode,
          store,
          mainText,
          onViewClick: () => this.handleViewClick(resultType, store),
        },
      ];
    }, []);

    if (processedResults.length !== 0) {
      return <GlobalSearchResults allSearchItemProps={processedResults} />;
    }

    if (showNoResult) {
      return <GlobalSearchNoResult />;
    }
    return null;
  }

  renderLeftIcon() {
    return (
      <Tooltip
        zIndex={9999}
        textWrap
        direction="bottomRight"
        content={
          <Text type="h6" color="#FFFFFF">
            Search users by account number or token. Search businesses, cards, and cases by token.
          </Text>
        }
        width={341}
      >
        {/* done to center with input */}
        <div style={{ marginTop: 2 }}>
          <Icon type="info" hasTooltip />
        </div>
      </Tooltip>
    );
  }

  renderInputRightAddOn() {
    const { actionColor, blackLighter6 } = Colors;

    const buttonStyle = {
      borderColor: this.hoveredFocused ? actionColor : blackLighter6,
      borderLeftWidth: '0px',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      boxShadow: 'none',
    };

    return (
      <Button
        type="tertiary"
        testId="global-search-button"
        style={buttonStyle}
        onClick={this.runSearch}
      >
        <Icon type="search" noHoverEffects />
        <HSpacer factor={0.5} />
        <Text>Search</Text>
      </Button>
    );
  }

  clearInputIfBlurred(e) {
    // Use timeout to delay examination of activeElement until after blur/focus
    // events have been processed.
    setTimeout(() => {
      const target = document.activeElement;
      if (!this?.searchWrapRef?.current?.contains(target)) {
        const { clearResults } = this.store || {};
        if (clearResults) {
          clearResults();
        }
      }
    }, 1);
  }

  turnOffFocus() {
    this.setState({ focused: false });
  }

  renderInput() {
    const { inputProps, currentProgramName } = this.props;
    const { searchVal, loading } = this.store;
    const turnOnFocus = () => this.setState({ focused: true });
    const turnOnHover = () => this.setState({ hovered: true });
    const turnOffHover = () => this.setState({ hovered: false });
    const programsArray = currentUserStore.userStore.redseaPrograms;

    const singleSearchPlaceholder = currentProgramName
      ? `Search ${currentProgramName} program`
      : '';
    const placeholder =
      programsArray.includes('marqeta') || programsArray.length > MAX_NUM_PROGRAMS_TO_SEARCH_ACROSS
        ? singleSearchPlaceholder
        : `Search all your programs`;

    return (
      <div
        className={s.globalSearchContainer}
        onMouseEnter={turnOnHover}
        onMouseLeave={turnOffHover}
        onFocus={turnOnFocus}
        onKeyDown={this.handleKeyEnter}
        tabIndex="0"
      >
        <Input
          testId="global_search_input"
          placeholder={placeholder}
          value={searchVal}
          onChange={this.handleInputChange}
          leftIcon={this.renderLeftIcon()}
          rightIcon={searchVal && !loading ? 'close' : null}
          onRightIconClick={this.clearInputAndResult}
          loading={loading}
          inputStyle={{
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            border: this.hoveredFocused ? '1px solid #3B2CE3' : '1px solid #DEDFE6',
          }}
          {...inputProps}
        />
        {this.renderInputRightAddOn()}
      </div>
    );
  }

  renderAddNoteModal() {
    const { showAddNoteModal, activeStore } = this.state;
    const { janusUserEmail } = this.props;

    // TODO: Pull out userRole from userStore (current userRole is from Janus).
    // Map redseaUserRole to USER | ADMIN | BANK_USER | BANK_ADMIN | MARQETA_PD | MARQETA_ADMIN.
    // Pass in the correct created_by_user_role.
    // Temporarily, all notes will have created_by_user_role='ADMIN'.
    const activeUserInfo = janusUserEmail
      ? {
          email: janusUserEmail,
          userRole: 'ADMIN',
        }
      : {};
    if (!showAddNoteModal) return null;
    return (
      <AddNoteModal
        hideModal={() => this.setState({ showAddNoteModal: false })}
        heading="Add Note"
        store={activeStore}
        activeUserInfo={activeUserInfo}
        renderSuccessAlert={() => this.setState({ showNoteSuccessfullyAddedAlert: true })}
        renderFailedAlert={() => this.setState({ showNoteNotAddedAlert: true })}
      />
    );
  }

  renderChangeStatusModal() {
    const { showChangeStatusModal, activeStore } = this.state;
    if (!showChangeStatusModal) return null;

    const headerContent =
      activeStore.fullName || activeStore.last_four || activeStore.business_name_legal;
    const header = headerContent ? ` - ${headerContent}` : '';
    return (
      <ChangeStatusModal
        hideModal={() => this.setState({ showChangeStatusModal: false })}
        heading={`Change Status${header}`}
        store={activeStore}
      />
    );
  }

  renderNoteSuccessfullyAddedAlert() {
    const { showNoteSuccessfullyAddedAlert } = this.state;
    if (!showNoteSuccessfullyAddedAlert) return null;
    return (
      <ModalAlert
        type="success"
        title="Note successfully added"
        hideModal={() => this.setState({ showNoteSuccessfullyAddedAlert: false })}
      />
    );
  }

  renderNoteNotAddedAlert() {
    const { showNoteNotAddedAlert } = this.state;
    if (!showNoteNotAddedAlert) return null;
    return (
      <ModalAlert
        type="danger"
        title="Note could not be added. Please try again."
        hideModal={() => this.setState({ showNoteNotAddedAlert: false })}
      />
    );
  }

  renderDisableCardModal() {
    const { showDisableCardModal, activeStore } = this.state;
    if (!showDisableCardModal || !activeStore) return null;
    const { last_four, changeStatus } = activeStore;
    return (
      <DisableCardModal
        hideModal={() => this.setState({ showDisableCardModal: false })}
        heading="Report Lost/Stolen"
        renderFailedAlert={() =>
          this.setState({
            errorMessage: (
              <p data-testid="global-search-success-modal">
                Failed to report the card ending in{' '}
                <b data-testid="global-search-result-modal-last-four">{last_four}</b> as{' '}
                <b>Lost or Stolen</b>.
              </p>
            ),
          })
        }
        renderSuccessAlert={() =>
          this.setState({
            successMessage: (
              <p data-testid="global-search-failure-modal">
                Successfully reported the card ending in{' '}
                <b data-testid="global-search-result-modal-last-four">{last_four}</b> as{' '}
                <b>Lost or Stolen</b>.
              </p>
            ),
          })
        }
        reportAction={() => changeStatus({ status: 'TERMINATED', reason_code: '10' })}
        last_four={last_four}
      />
    );
  }

  renderSuccess() {
    const { successMessage } = this.state;
    const { testId } = this.props;
    if (!successMessage) return;
    return (
      <ModalAlert
        type="success"
        hideModal={() => {
          this.setState({ successMessage: '' });
        }}
        testId={`${testId}__success-modal-alert`}
      >
        {successMessage}
      </ModalAlert>
    );
  }

  renderFailure() {
    const { testId } = this.props;
    const { errorMessage } = this.state;
    if (!errorMessage) return;
    return (
      <ModalAlert
        type="danger"
        hideModal={() => {
          return this.setState({ errorMessage: '' });
        }}
        testId={`${testId}__failed-modal-alert`}
      >
        {errorMessage}
      </ModalAlert>
    );
  }

  render() {
    return (
      <div
        className={s.wrap}
        onBlur={() => {
          this.turnOffFocus();
          this.clearInputIfBlurred();
        }}
        ref={this.searchWrapRef}
      >
        {this.renderSuccess()}
        {this.renderFailure()}
        {this.renderAddNoteModal()}
        {this.renderChangeStatusModal()}
        {this.renderNoteSuccessfullyAddedAlert()}
        {this.renderDisableCardModal()}
        {this.renderNoteNotAddedAlert()}
        {this.renderInput()}
        {this.renderResults()}
      </div>
    );
  }
}

GlobalSearch.propTypes = {
  storeInitArgs: PropTypes.object,
  onStoreConstruction: PropTypes.func,
  inputProps: PropTypes.object,
  resourceType: PropTypes.string,
  userStore: PropTypes.object,
  testId: PropTypes.string,
};

GlobalSearch.defaultProps = {
  storeInitArgs: {},
  onStoreConstruction: null,
  inputProps: {},
  resourceType: 'all',
  userStore: {},
  testId: 'global-search',
};

export default observer(GlobalSearch);
