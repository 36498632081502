import { useCallback, useEffect, useState } from 'react';
import { LIST_HEIGHT_BOTTOM_MARGIN } from '../constants';

export default function useDynamicHeight(transactions, setHeight) {
  const [windowHeight, setWindowHeight] = useState(undefined);

  useEffect(() => {
    function handleResize() {
      setWindowHeight(window.innerHeight);
    }

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return useCallback(
    (node) => {
      if (node) {
        const { top, bottom } = node.getBoundingClientRect();

        if (bottom - top > windowHeight) {
          setHeight(windowHeight - top - LIST_HEIGHT_BOTTOM_MARGIN);
        }
      }
    },
    [windowHeight, transactions] // eslint-disable-line react-hooks/exhaustive-deps
  );
}
