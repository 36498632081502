import React from 'react';
import { observer } from 'mobx-react';
import { Button } from '@mqd/volt-base';
import currentUserStore from 'stores/CurrentUserStore';
import './ActingAs.css';

const ActingAs = () => {
  if (currentUserStore.actingAsUserStore) {
    const clearActingAsUser = () => {
      currentUserStore.clearActingAsUser();
    };
    const style = { width: 10 };
    return (
      <div className="acting-as-wrap">
        <div className="acting-as-container">
          {currentUserStore.userStore.email} - {currentUserStore.userStore.userRole}
          <div style={style} />
          <Button size="sm" onClick={clearActingAsUser} testId="end-impersonation-button">
            End
          </Button>
        </div>
      </div>
    );
  }
  return null;
};

export default observer(ActingAs);
