import React from 'react';
import { LoadingOverlay } from '@mqd/volt-base';
import { IsLoadingContext } from '@mq/volt-amc-container';

import routerStore from 'stores/router/RouterStore';

const AppLoadingOverlayWrapper = () => {
  const { isLoading, message } = IsLoadingContext.useIsLoadingStateContext() || {};

  if (!isLoading) return null;
  const style = { position: 'fixed', zIndex: 9999999 };
  const fullHeightAndWidth = { width: '100vw', height: '100vh' };
  routerStore.setIsSigningOut(message === 'Signing Out');

  return (
    <div style={style}>
      <LoadingOverlay active={true} message={message}>
        <div style={fullHeightAndWidth} />
      </LoadingOverlay>
    </div>
  );
};

export default AppLoadingOverlayWrapper;
