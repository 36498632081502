import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ExternalLink extends Component {
  render() {
    const { href, color, children } = this.props;
    return (
      <a style={{ color: color }} href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  }
}

ExternalLink.propTypes = {
  href: PropTypes.string,
  color: PropTypes.string,
};

ExternalLink.defaultProps = {
  href: '#',
  color: '#4b0e8d',
};

export default ExternalLink;
