import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FileUpload } from '@mqd/volt-base';

import s from './FileInput.module.css';

const FileInput = forwardRef((props, ref) => (
  <div className={s.fileInputContainer}>
    <FileUpload
      ref={ref}
      label="File upload"
      labelSize="large"
      acceptedFileTypes=".csv"
      {...props}
    />
  </div>
));

FileInput.propTypes = {
  placeholder: PropTypes.string,
};

FileInput.defaultProps = {
  placeholder: 'Select CSV file',
};

export default FileInput;
