import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import CardholderAuthAndVelocityControlsCard from './CardholderAuthAndVelocityControlsCard';

class SpendControlsTab extends Component {
  render() {
    const { cardholderStore } = this.props;
    return (
      <div data-testid="spend-controls-tab-container">
        <CardholderAuthAndVelocityControlsCard cardholderStore={cardholderStore} />
      </div>
    );
  }
}

SpendControlsTab.propTypes = {
  cardholderStore: PropTypes.object,
};

SpendControlsTab.defaultProps = {
  cardholderStore: {},
};

export default observer(SpendControlsTab);
