import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, VSpacer, Text } from '@mqd/volt-base';
import {
  CardholderInfoProvider,
  reducer,
  initialState,
  TOGGLE_EDIT_DETAILS,
  TOGGLE_EDIT_IDENTIFICATION,
} from './context';
import { EditableTitle } from './form-helpers';
import { EditDetails, ViewDetails } from './details';
import { EditIdentification, ViewIdentification } from './identification';
import './CardholderInfo.module.css';
import KycDetailsCard from '../kyc-details-card/KycDetailsCard';
import { findNewestKyc, dig } from '../../shared-utils/index';
import Timestamp from './Timestamp';

const CardholderInfo = ({
  canEdit = false,
  canCreateReview = false,
  canViewPii = false,
  canManualOverrideKyc = false,
  containerWidth,
  data = {},
  isAccountholderView = false,
  showAchSensitiveInfo = false,
  canEditAchSensitiveInfo = false,
  onCardholderSave,
  onPerformManualOverride,
  revealId,
  statusComponent,
  submissionError,
  submitting = false,
  onBusinessClick,
  createKycReview,
  goToReview,
  loadingReview = false,
  reviewToken = '',
}) => {
  const [state, dispatch] = useReducer(reducer, { ...initialState, isAccountholderView });
  const { isEditIdentificationMode, isEditDetailsMode } = state;

  const latestKycResult = findNewestKyc(data.kycResults);
  const kycData = dig(data.kycResults, 'data');

  let colSpan = 6;
  if (containerWidth <= 800) {
    colSpan = 12;
  }

  const handleEditDetails = () => {
    dispatch({
      type: TOGGLE_EDIT_DETAILS,
      payload: true,
    });
  };

  const handleEditIdentification = () => {
    dispatch({
      type: TOGGLE_EDIT_IDENTIFICATION,
      payload: true,
    });
  };

  //new uam permissions rollout
  const showIdentificationSection = !(isAccountholderView && !showAchSensitiveInfo);
  const canEditIdentificationSection =
    (isAccountholderView ? canEditAchSensitiveInfo : canEdit) && !isEditDetailsMode;

  return (
    <CardholderInfoProvider value={{ dispatch, state }}>
      <Container gutter={24}>
        <Row>
          <Col span={colSpan} testId="cardholderinfo-details-section">
            <EditableTitle
              title={isAccountholderView ? 'Account holder details' : 'User details'}
              canEdit={canEdit && !isEditIdentificationMode}
              isEditMode={isEditDetailsMode}
              onClick={handleEditDetails}
              testId="cardholderinfo-details-edit-link"
            />
            {/* ToDo: Create a separate Timestamp component */}
            {data.created_time && data.last_modified_time && (
              <Timestamp
                createdTime={data.created_time}
                lastModifiedTime={data.last_modified_time}
              />
            )}
            <VSpacer factor={2} />
            {isEditDetailsMode ? (
              <EditDetails
                data={data}
                onSave={onCardholderSave}
                submitting={submitting}
                error={submissionError}
              />
            ) : (
              <ViewDetails
                data={data}
                statusComponent={statusComponent}
                onBusinessClick={onBusinessClick}
              />
            )}
          </Col>
          <Col span={colSpan} testId="cardholderinfo-identification-section">
            {showIdentificationSection && (
              <>
                <EditableTitle
                  title="Identification"
                  canEdit={canEditIdentificationSection}
                  isEditMode={isEditIdentificationMode}
                  onClick={handleEditIdentification}
                  testId="cardholderinfo-identification-edit-link"
                />
                <VSpacer factor={2} />
                {isEditIdentificationMode ? (
                  <EditIdentification
                    data={data}
                    canViewPii={canViewPii}
                    onSave={onCardholderSave}
                    submitting={submitting}
                    error={submissionError}
                    isAccountholderView={isAccountholderView}
                  />
                ) : (
                  <ViewIdentification revealId={revealId} data={data} canViewPii={canViewPii} />
                )}
                <VSpacer factor={4} />
              </>
            )}
            <Text type="h5">KYC details</Text>
            <VSpacer factor={2} />
            <KycDetailsCard
              canCreateReview={canCreateReview}
              canManualOverride={canManualOverrideKyc}
              createKycReview={createKycReview}
              goToReview={goToReview}
              kycData={kycData}
              latestKycResult={latestKycResult}
              loadingReview={loadingReview}
              performManualOverride={onPerformManualOverride}
              reviewToken={reviewToken}
              type="KYC"
            />
          </Col>
        </Row>
      </Container>
    </CardholderInfoProvider>
  );
};

CardholderInfo.propTypes = {
  canEdit: PropTypes.bool,
  canViewPii: PropTypes.bool,
  canManualOverrideKyc: PropTypes.bool,

  /**
   * StatusIndicator component to display for the Cardholder Status.
   * A read-only StatusIndicator will be displayed if this is not provided.
   * */
  statusComponent: PropTypes.element,

  /**
   * onCardholderSave should be an async function that accepts a partial cardholder object as an argument,
   * and performs an update mutation on the cardholder based on the properties in the passed argument.
   * It should return a Promise.
   *
   * When a form in this component is saved, the onCardholderSave function will be executed
   * with a single object as the argument. The argument will include the cardholder token plus
   * any properties that the form updates.
   *    Example:
   *        onCardholderSave({token: 'exampleToken', identifications: [{...}, {...}]})
   */
  onCardholderSave: PropTypes.func,

  /**
   * Set to true if a mutation is in progress as part of onCardholderSave
   * */
  submitting: PropTypes.bool,

  /**
   * If a business is attached we can use this to link to the business
   * */
  onBusinessClick: PropTypes.bool,

  /**
   * Mutation error for onCardholderSave
   */
  submissionError: PropTypes.string,

  /**
   * data should be a cardholderholder object,
   * including kycResults object (with a data array) and identifications array
   */
  data: PropTypes.shape({
    token: PropTypes.string,
    status: PropTypes.string,
    first_name: PropTypes.string,
    middle_name: PropTypes.string,
    last_name: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    postal_code: PropTypes.string,
    birth_date: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    nationality: PropTypes.string,
    company: PropTypes.string,
    kycResults: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          token: PropTypes.string,
          user_token: PropTypes.string,
          last_modified_time: PropTypes.string.isRequired,
          created_time: PropTypes.string,
          notes: PropTypes.string,
          result: PropTypes.shape({ status: PropTypes.string.isRequired }).isRequired,
        })
      ),
    }),
    identifications: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        /**
         * use loading and error if fetching the full ID number asynchronously
         * via revealId prop.
         */
        loading: PropTypes.bool,
        error: PropTypes.bool,
      })
    ),
  }).isRequired,

  /**
   * Function to asynchronously load the full value of an ID number
   * for the passed `type` argument.
   *
   * This function should update the value, loading, and error properties of the corresponding
   * ID type in the data.identifications prop.
   */
  revealId: PropTypes.func,
  containerWidth: PropTypes.number,
  /**
   * props for the kycdetails
   */
  canCreateReview: PropTypes.bool,
  createKycReview: PropTypes.func,
  goToReview: PropTypes.func,
  reviewToken: PropTypes.string,
};

export default CardholderInfo;
