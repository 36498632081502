import gql from 'graphql-tag';

export const CREATE_EXCEPTION_NOTE = gql`
  mutation createExceptionNote($note: String!, $tx_hash_internal_id: String!) {
    createExceptionNote(note: $note, tx_hash_internal_id: $tx_hash_internal_id) {
      status
      statusText
      message
      ok
    }
  }
`;
