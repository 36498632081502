//* check if tokenization-token-manager and tokenization-token-manager-edit users can see the functionality

const TOKEN_MANAGER = 'tokenization-token-manager';
const TOKEN_MANAGER_EDIT = 'tokenization-token-manager-edit';

//! key consist of role-possibility_to_view_some_functionality-possibility_to_edit_some_functionality
//! value is the array of only possible urls for current set of permissions
const STANDARD_USER = [`viewer-true-true`, ['home', 'reports', 'program/token-service']];
const ADMIN_USER = [`admin-true-true`, ['home', 'reports', 'program/token-service', 'admin']];
const VIEW_ONLY_USER = [`viewer-true-false`, ['home', 'reports', 'program/token-service']];

const PATH_MAP = new Map([STANDARD_USER, ADMIN_USER, VIEW_ONLY_USER]);

export const hasTokenizationManagerPermissions = (userStore = {}, pathToCheck) => {
  const { userRole, redseaRoles } = userStore;

  const onlyView = redseaRoles.includes(TOKEN_MANAGER);
  const canEdit = redseaRoles.includes(TOKEN_MANAGER_EDIT);

  const admin = userRole === 'Admin' && 'admin';
  const viewer = userRole === 'Viewer' && 'viewer';

  const viewerKeyPattern = `${viewer}-${onlyView}-${canEdit}`;
  const adminKeyPattern = `${admin}-${onlyView}-${canEdit}`;

  if (PATH_MAP.has(viewerKeyPattern)) {
    return PATH_MAP.get(viewerKeyPattern).includes(pathToCheck);
  }

  if (PATH_MAP.has(adminKeyPattern)) {
    return PATH_MAP.get(adminKeyPattern).includes(pathToCheck);
  }

  return false;
};
