export const ERROR_MESSAGE_ACCOUNT_HOLDER_UPDATE = 'Unable to update account holder details.';
export const stateOptions = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];
export const SSN = 'SSN';
export const SIN = 'SIN';
export const TIN = 'TIN';
export const NIN = 'NIN';
export const PASSPORT_NUMBER = 'PASSPORT_NUMBER';
export const DRIVERS_LICENSE = 'DRIVERS_LICENSE';
export const IDENTIFICATION_CARD_NUMBER = 'IDENTIFICATION_CARD_NUMBER';

export const IDENTIFICATION_TYPE_NAME_MAP = {
  [SSN]: 'Social security number',
  [SIN]: 'Social insurance number',
  [TIN]: 'Taxpayer identification number',
  [NIN]: 'National insurance number',
  [PASSPORT_NUMBER]: 'Passport number',
  [DRIVERS_LICENSE]: "Driver's license number",
  [IDENTIFICATION_CARD_NUMBER]: 'ID card number',
};

export const IDENTIFICATION_REGEX_MAP = {
  [SSN]: /^[0-9]{3}-?[0-9]{2}-?[0-9]{4}$/,
  [SIN]: /^[0-9]{3}-?[0-9]{3}-?[0-9]{3}$/,
  [TIN]: /^9[0-9]{2}-?[0-9]{2}-?[0-9]{4}$/,
  [NIN]:
    /^[ABCEGHJKLMNOPRSTWXYZ]{1}[ABCEGHJKLMNPRSTWXYZ]{1} ?[0-9]{2} ?[0-9]{2} ?[0-9]{2} ?[ABCDFMP]{1}$/,
};

export const PRIMARY_IDENTIFICATION_TYPES = [SSN, SIN, TIN, NIN];
