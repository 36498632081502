import PropTypes from 'prop-types';
import React from 'react';
import { pipe, prop, find } from 'ramda';
import Link from '../Link.js';
import { Icon, HSpacer } from '@mqd/volt-base';
import s from './NavBar.module.css';

const generateLinkTextTestId = (txt) => {
  if (txt) {
    return `${txt.toLowerCase()}-link-text`;
  }
  return '';
};

/**
 * Check if a user has access to a given destination
 *
 * @param {function} verifier
 *  A function defined to check if a user has access to a route.
 *  Defined in the route-authorization utility.
 *
 * @param {object} destination
 *  A destination object contains two fields: routeName, to.
 *  See standard-layout/header/constants.js > NAV_LINKS > destinations array.
 *
 * @returns {boolean}
 */
const verifyRouteName = (verifier) => pipe(prop('routeName'), verifier);

function NavLink({
  assignActiveLinkRef,
  destinations,
  handleMouseEnter,
  handleMouseOut,
  hovered,
  icon,
  isActiveLink,
  setHovered,
  text,
  verifier,
  width,
}) {
  const renderLink = find(verifyRouteName(verifier), destinations);
  if (!renderLink) return null;
  const { to } = renderLink;
  const active = isActiveLink(to);
  const isHovered = hovered === text;
  // set explicit width to deal with issue where on initial visit
  // underline was measured before fonts loaded, putting underline
  // in the wrong location
  const wrapStyle = width ? { width } : {};
  return (
    <>
      <div
        data-testid="navbar-nav-link"
        className={s.noShrink}
        onMouseOver={(e) => {
          setHovered(text);
          handleMouseEnter(e);
        }}
        onMouseLeave={(e) => {
          setHovered('');
          !active && handleMouseOut(e);
        }}
        data-link-wrap={true}
        ref={(link) => {
          if (active) {
            assignActiveLinkRef(link);
          }
        }}
        style={wrapStyle}
      >
        <Link to={to}>
          <div className={`${s.link} ${active ? s.active : ''} ${s[text] ? s[text] : ''}`}>
            <Icon
              type={icon}
              mod="disabled"
              overrideColor={active || isHovered ? '#3C33B2' : '#8E8E99'}
            />
            <HSpacer />
            <div data-testid={generateLinkTextTestId(text)} className={s.linkText}>
              {text}
            </div>
          </div>
        </Link>
      </div>
      <div className={s.noShrink}>
        <HSpacer factor={4} />
      </div>
    </>
  );
}

NavLink.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  destinations: PropTypes.array,
  hovered: PropTypes.string,
};
NavLink.defaultProps = {
  text: '',
  icon: '',
  destinations: [],
  hovered: '',
};

export default NavLink;
