import React from 'react';
import PropTypes from 'prop-types';

import { Icon, Text, Tooltip } from '@mqd/volt-base';

import s from './HeadingWithTooltip.module.css';

export default function HeadingWithTooltip({
  headingType,
  headingText,
  tooltipText,
  tooltipDirection = 'topRight',
}) {
  return (
    <div className={s.container}>
      <Text type={headingType} inline={true}>
        {headingText}
      </Text>
      <div className={s.tooltipWrapper}>
        <Tooltip
          content={tooltipText}
          direction={tooltipDirection}
          pointerEvents={true}
          textWrap={true}
        >
          <Icon type="info" mod="disabled" factor={0.3} noHoverEffects />
        </Tooltip>
      </div>
    </div>
  );
}

HeadingWithTooltip.propTypes = {
  headingType: PropTypes.string,
  headingText: PropTypes.string,
  tooltipText: PropTypes.string,
  tooltipDirection: PropTypes.string,
};
