import currentUserStore from '../../stores/CurrentUserStore';

const approvalQueueRoutes = [
  {
    path: '/approval-queue',
    name: 'Program Config Approval Queue',
    importer: () =>
      import(/*webpackChunkName: "Program Config Approval Queue" */ './ApprovalQueue'),
  },
  {
    path: '/approval-queue/:token',
    name: 'Program Config Request Review',
    importer: () =>
      import(/*webpackChunkName: "Program Config Request Review" */ './pages/ReviewAQRequest.js'),
  },
];

const defaultVerifier = () => {
  return currentUserStore.userStore.approvalQueueActive;
};

const verifiers = {
  programConfigApprovalQueue: defaultVerifier,
  programConfigRequestReview: defaultVerifier,
};

export { verifiers };
export default approvalQueueRoutes;
