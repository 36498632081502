// @flow
import { decorate, observable, action, runInAction } from 'mobx';
import gqlUtils from '@mqd/graphql-utils';
import NoteStore from '../stores/NoteStore';
import { ParentTableStore } from '@mq/voltron-parent';

const { fragments } = gqlUtils;

export default class NotesTableStore extends ParentTableStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
  }
  // values
  items: Array<NoteStore> = [];
  count: number = 50;

  // actions
  async hydrate() {
    switch (this.type) {
      case 'business':
        this.hydrateBusinesses();
        break;
      case 'cardholder':
        this.hydrateCardholders();
        break;
      default:
        runInAction(() => {
          const notes = {
            count: this.count,
            start_index: this.start_index,
            end_index: 0,
            is_more: false,
            data: [],
          };
          this.processPaginationResponse(notes, NoteStore);
          this.loading = false;
        });
        console.error("Type can only be 'business' or 'cardholder'.");
        break;
    }
  }

  async hydrateBusinesses() {
    this.loading = true;
    const result = await this.gqlQuery(
      `
        query notes($count: Int, $start_index: Int, $token: ID!) {
          business(token: $token) {
            notes(count: $count, start_index: $start_index) {
              data {
                ...noteBaseInfo
              }
              ${this.paginationAttributes}
            }
          }
        }
        ${fragments.noteBaseInfo}
      `,
      {
        count: this.count,
        start_index: this.start_index,
        token: this.token,
      }
    );
    runInAction(() => {
      if (!result) return null;
      const notes = result.data.business.notes;
      this.processPaginationResponse(notes, NoteStore);
      this.loading = false;
    });
  }

  async hydrateCardholders() {
    this.loading = true;
    const result = await this.gqlQuery(
      `
        query notes($count: Int, $start_index: Int, $token: ID!) {
          cardholder(token: $token) {
            notes(count: $count, start_index: $start_index) {
              data {
                ...noteBaseInfo
              }
              ${this.paginationAttributes}
            }
          }
        }
        ${fragments.noteBaseInfo}
      `,
      {
        count: this.count,
        start_index: this.start_index,
        token: this.token,
      }
    );
    runInAction(() => {
      if (!result) return null;
      const notes = result.data.cardholder.notes;
      this.processPaginationResponse(notes, NoteStore);
      this.loading = false;
    });
  }
}

decorate(NotesTableStore, {
  // values
  items: observable,

  // actions
  hydrate: action.bound,

  // computed
});
