import { useMemo, useLayoutEffect } from 'react';

export function useBodyNode() {
  const node = useMemo(() => document.createElement('div'), []);

  useLayoutEffect(() => {
    document.body.appendChild(node);

    return () => {
      document.body.removeChild(node);
    };
  }, []);

  return node;
}
