import logger from 'utils/logger';
import { useCallback } from 'react';
import { useJanusApi, JanusContext, useJanusActions } from '@mq/volt-amc-container';

export default function useJanusRefreshToken() {
  const { stopWebtokenRefresh } = JanusContext.useJanusStateContext();
  const { loadJanusUser } = useJanusActions();
  const fetchJanusApi = useJanusApi();

  return useCallback(() => {
    const refreshToken = async () => {
      let response;
      try {
        response = await fetchJanusApi({
          url: '/diva/security/webtoken/refresh',
          options: { method: 'POST' },
        });
      } catch (e) {
        logger.error(`Janus token was not refreshed ${e}`);
      }
      if (stopWebtokenRefresh) {
        return;
      }

      if (response && response.data && response.data.json_web_token) {
        const payload = {
          webToken: response.data.json_web_token,
          webTokenCreatedTime: new Date().toString(),
        };

        loadJanusUser(payload);
      }
    };

    // Always run token refresh if session if active.
    // Session inactivity timeout is handled by Redsea now
    setTimeout(refreshToken, 1000); // run on page reload (after 1 second)
    setInterval(refreshToken, 1000 * 60); // run every minute
  }, [stopWebtokenRefresh, fetchJanusApi, loadJanusUser]);
}
