import React from 'react';
import { Text, HSpacer, VSpacer, Input } from '@mqd/volt-base';
import { getMultiFieldStatus } from '../../helpers';
import s from './KYBCard.module.css';
import IconInput from './IconInput';
import StatusIcon from './StatusIcon';
import PropTypes from 'prop-types';

class NameRow extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      status: 'PENDING',
    };
    this.changeStatus = this.changeStatus.bind(this);
  }

  componentDidUpdate() {
    this.getStatus();
  }

  getStatus() {
    const status = getMultiFieldStatus(this.props.nameObject);
    this.setState({ status: status });
  }

  changeStatus(field, statusType) {
    const { nameObject, onChangeStatus } = this.props;
    Object.keys(nameObject).forEach((key) => {
      onChangeStatus(key, statusType);
    });
  }

  render() {
    const { nameObject, editActive, onChange, userStore = {}, kybCustomerCanWrite } = this.props;
    const hasStatusEditRights = userStore.kybCanWrite;
    const showInput = editActive && (hasStatusEditRights || kybCustomerCanWrite);
    return (
      <div data-testid="KYB-name-row">
        <Text type="h6">
          <span>Name</span>
        </Text>
        <VSpacer />
        {showInput ? (
          <>
            <div className={s.editActive} data-testid="KYB-name-row-edit-active">
              <Input
                placeholder="First Name"
                value={nameObject.first_name.value}
                onChange={(e) => onChange('first_name', e.target.value)}
                width="100%"
                testId="KYB-name-row-input"
              />
              {hasStatusEditRights && (
                <IconInput
                  onChangeStatus={this.changeStatus}
                  field={'first_name'}
                  status={this.state.status}
                />
              )}
            </div>
            <VSpacer />
            <div className={s.editSubActive}>
              <Input
                placeholder="Middle Name"
                value={nameObject.middle_name.value}
                onChange={(e) => onChange('middle_name', e.target.value)}
                width="100%"
              />
            </div>
            <VSpacer />
            <div className={s.editSubActive}>
              <Input
                placeholder="Last Name"
                value={nameObject.last_name.value}
                onChange={(e) => onChange('last_name', e.target.value)}
                width="100%"
              />
            </div>
          </>
        ) : (
          <div className={s.notEditActive} data-testid="KYB-name-row-edit-not-active">
            <Text inline={true}>
              {nameObject.first_name.value} {nameObject.middle_name.value}{' '}
              {nameObject.last_name.value} <HSpacer /> <StatusIcon status={this.state.status} />
            </Text>
          </div>
        )}
      </div>
    );
  }
}

NameRow.propTypes = {
  editActive: PropTypes.bool,
  onChange: PropTypes.func,
  nameObject: PropTypes.object,
  kybCustomerCanWrite: PropTypes.bool,
};

NameRow.defaultProps = {
  editActive: false,
  onChange: () => {},
  nameObject: {},
  kybCustomerCanWrite: false,
};

export default NameRow;
