// Returns the string received with the first char capitalized
// If something other than a string is received, returns an empty string
const capitalize = (string) => {
  if (string && typeof string === 'string') {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } else {
    return '';
  }
};

export default capitalize;
