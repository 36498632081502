// Once this util list grows, we should break functions into categorized files
// Those files can then be imported and collectively exported here
import moment from 'moment';
import { TRANSFER_STATUS_MAP } from './constants';
import { flipFlop } from '@mq/volt-amc-container';

const sentenceCase = (str = '', split = '_') => {
  try {
    const splitStr = str.split(split).join(' ');
    const sentence = splitStr.charAt(0).toUpperCase() + splitStr.substr(1).toLowerCase();
    return sentence;
  } catch (e) {
    return str;
  }
};

const titleCase = (str = '', split = '_') => {
  try {
    const splitStr = str.split(split).join(' ');
    return splitStr.replace(
      /\w\S*/g,
      (word) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
    );
  } catch (e) {
    return str;
  }
};

const formatUTCMoment = (timestamp, options = {}) => {
  try {
    const { showDate = true, showTime = true, dateFormat = 'YYYY-MM-DD' } = options;

    const utcMoment = moment.utc(timestamp);
    let formattedTime = '';

    if (!utcMoment.isValid()) return timestamp;

    if (showDate) {
      formattedTime += utcMoment.format(dateFormat);
      if (showTime) {
        formattedTime += ' ' + utcMoment.format('HH:mm') + ' UTC';
      }
    } else if (showTime) {
      formattedTime += utcMoment.format('HH:mm') + ' UTC';
    }

    return formattedTime;
  } catch (err) {
    console.error(`Error (formatUTCMoment util): ${err}`);
    return timestamp;
  }
};

function randomFromArray(array) {
  return array[Math.floor(Math.random() * array.length)];
}

/**
 * Gets a random value from an object. This works best with objects with no nested objects, hence the name Dictionary
 * @param dictionary A javascript object.  Preferrably a POJO of some sort.
 * @return random value supplied dictionary
 */
function randomFromDictionary(dictionary) {
  try {
    const randomKey = randomFromArray(Object.keys(dictionary));

    return dictionary[randomKey];
  } catch (e) {
    return null;
  }
}

/*
 * Taken from ParentStore.js
 * Does exactly what ruby dig() does.
 * Attempts to find a nested value, if it's not found returns undefined
 */
const dig = (result: Object, ...keys) => {
  let digged = result;
  for (const key of keys) {
    if (typeof digged === 'undefined' || digged === null) {
      return undefined;
    }
    if (typeof key === 'function') {
      digged = key(digged);
    } else {
      digged = digged[key];
    }
  }
  return digged;
};

const formatPhone = (phone) => {
  if (!phone) return phone;

  const onlyDigitsPhone = phone.replace(/\D+/g, '');

  if (onlyDigitsPhone.length === 10 || onlyDigitsPhone.length === 11) {
    const isPhoneWithCountry = onlyDigitsPhone.length === 11;

    const operator = isPhoneWithCountry ? onlyDigitsPhone.slice(1, 4) : onlyDigitsPhone.slice(0, 3);
    const firstPart = onlyDigitsPhone.slice(-7).slice(0, 3);
    const secondPart = onlyDigitsPhone.slice(-4);
    const country = isPhoneWithCountry ? phone.slice(0, 1) : '1';

    return `+${country} (${operator}) ${firstPart}-${secondPart}`;
  }

  return phone;
};

const isNotPresent = (val) => {
  return [null, undefined, ''].includes(val);
};

const formatAmount = (amount) => {
  try {
    if (isNotPresent(amount)) return null;
    return Number.parseFloat(amount).toFixed(2);
  } catch (err) {
    return amount;
  }
};

const formatAmountAndNegative = (amount) => {
  try {
    if (isNotPresent(amount)) return null;
    const isNegative = amount < 0;
    const formattedAmount = isNegative ? amount * -1 : amount;
    const money = Number.parseFloat(formattedAmount).toFixed(2);
    return isNegative ? `(${money})` : money;
  } catch (err) {
    return amount;
  }
};

const findNewestKyc = (kyc) => {
  if (!kyc || !kyc.data) {
    return null;
  }

  kyc.data.sort((a, b) => {
    if (new Date(a.last_modified_time) < new Date(b.last_modified_time)) {
      return 1;
    }
    if (new Date(a.last_modified_time) > new Date(b.last_modified_time)) {
      return -1;
    }
    return 0;
  });

  return dig(kyc, 'data', '0');
};

const mapTransferStatus = (status = '') => {
  const mappedStatus = TRANSFER_STATUS_MAP[status];

  return mappedStatus || capitalizeFirstLetter(status);
};

const capitalizeFirstLetter = (str) => {
  if (str) {
    const newStr = str.toLowerCase();
    return newStr.charAt(0).toUpperCase() + newStr.slice(1);
  }
};

const swissquoteCardProductLevelRestrictionActive = (orgName = '', redseaRoles = []) => {
  const userIsSwissquoteOrgUser = orgName === 'Swissquote Bank';
  const swissquoteFeatureFlagActive = flipFlop.get('swissquote-cp-level-restriction', false);
  const userIsExternalAdmin = redseaRoles?.includes('program-admin');

  const userHasSwissquoteCustomerSupportRole = redseaRoles?.some((role_token) =>
    ['customer-support-swissquote', 'customer-support-yuh'].includes(role_token)
  );

  const userHasSwissquoteAdminPermissions =
    (redseaRoles?.includes('customer-support-swissquote') &&
      redseaRoles?.includes('customer-support-yuh')) ||
    (!redseaRoles?.includes('customer-support-swissquote') &&
      !redseaRoles?.includes('customer-support-yuh'));

  if (userIsSwissquoteOrgUser && swissquoteFeatureFlagActive) {
    if (userIsExternalAdmin && userHasSwissquoteAdminPermissions) return false;
    else return userHasSwissquoteCustomerSupportRole;
  }

  return false;
};

/**
 * Determines whether to use the original card or cardholder related query or the new
 * Swissquote card or cardholder query that filters data by card product
 * more details -> [SPD-725]
 * @param originalQuery the name of the original card or cardholder query
 * @param newQuery the name of the new Swissquote card or cardholder query
 * @param orgName the name of the current user's organization
 * @param redseaRoles the array of the current user's redsea roles
 * @return the string name of the card or cardholder query to use
 */
const getCardOrCardholderQuery = (originalQuery, newQuery, orgName, redseaRoles) => {
  const shouldFilterByCardProduct = swissquoteCardProductLevelRestrictionActive(
    orgName,
    redseaRoles
  );
  return shouldFilterByCardProduct ? newQuery : originalQuery;
};

export {
  sentenceCase,
  titleCase,
  formatUTCMoment,
  randomFromArray,
  randomFromDictionary,
  dig,
  formatPhone,
  isNotPresent,
  findNewestKyc,
  formatAmount,
  formatAmountAndNegative,
  mapTransferStatus,
  capitalizeFirstLetter,
  getCardOrCardholderQuery,
};
