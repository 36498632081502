import currentUserStore from 'stores/CurrentUserStore';

const hasRoleForSelectedProgram = (roleToken) => {
  const { activeProgram, redseaRolesToProgramsMap } = currentUserStore
    ? currentUserStore.userStore || {}
    : {};
  const validPrograms = redseaRolesToProgramsMap && redseaRolesToProgramsMap[roleToken];
  const { short_name } = activeProgram || {};

  return validPrograms
    ? validPrograms.includes('marqeta') || validPrograms.includes(short_name)
    : false;
};

export default hasRoleForSelectedProgram;
