import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  ActivityMonitorController,
  withBootstrapSession,
  withIsLoadingActions,
} from '@mq/volt-amc-container';
import { datadogRum } from '@datadog/browser-rum';
import currentUserStore from '../../../stores/CurrentUserStore.js';
import LoggedIn from './LoggedIn.js';
import useJanusRefreshToken from '../../../utils/janus-refresh-token/useJanusRefreshToken.js';

const ProtectedView = ({
  janusUser,
  redseaUser,
  applicationData,
  flipFlop,
  sessionIsBootstrapped,
  isLoadingActions,
}) => {
  const refreshJanusToken = useJanusRefreshToken();
  useEffect(() => {
    if (sessionIsBootstrapped && !currentUserStore.actingAsUserStore) {
      datadogRum.addRumGlobalContext('user', {
        token: redseaUser?.data?.redsea_token,
      });
      currentUserStore.userStore.bootstrapSession({
        janusUser,
        redseaUser,
        applicationData,
        flipFlop,
      });
    }
  }, [janusUser, redseaUser, applicationData, flipFlop, sessionIsBootstrapped]);

  useEffect(() => {
    if (sessionIsBootstrapped) {
      refreshJanusToken();
    }
  }, [refreshJanusToken, sessionIsBootstrapped]);

  useEffect(() => {
    if (!currentUserStore.actingAsUserStore && !currentUserStore.userStore.loggedInComputed) {
      isLoadingActions.setLoading(
        'Please refresh your browser if you are not automatically redirected after five seconds.'
      );
    } else {
      isLoadingActions.stopLoading();
    } // eslint-disable-next-line
  }, [
    isLoadingActions,
    currentUserStore.actingAsUserStore,
    currentUserStore.userStore.loggedInComputed,
  ]);

  return currentUserStore.userStore.loggedInComputed || currentUserStore.actingAsUserStore ? (
    <>
      <LoggedIn />
      <ActivityMonitorController />
    </>
  ) : null;
};

export default withIsLoadingActions(withBootstrapSession(observer(ProtectedView)));
