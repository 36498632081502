// @flow
import AbstractCardStore from './AbstractCardStore';

class CardStore extends AbstractCardStore {
  /**
   * @param samePan if true, reissue card with the same pan
   * @returns a card token
   */
  async reissueCard(samePan) {
    const payload = {
      cardholder_token: this.cardholder_token,
      card_product_token: this.card_product_token,
      ...(samePan
        ? { reissue_pan_from_card_token: this.token }
        : { new_pan_from_card_token: this.token }),
    };

    const result = await this.gqlMutation(
      `
        mutation createCard (
          $cardholder_token: ID!
          $card_product_token: ID!
          $expiration_offset: ExpirationOffsetInput
          ${samePan ? '$reissue_pan_from_card_token: String' : '$new_pan_from_card_token: String'}
        ) {
          createCard(
            cardholder_token: $cardholder_token
            card_product_token: $card_product_token
            expiration_offset: $expiration_offset
            ${
              samePan
                ? 'reissue_pan_from_card_token: $reissue_pan_from_card_token'
                : 'new_pan_from_card_token: $new_pan_from_card_token'
            }
          ){
            token
          }
        }
      `,
      payload
    );

    return this.dig(result, 'data', 'createCard', 'token');
  }
}

export default CardStore;
