import { decorate, observable, computed } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';

class KycBusinessResultStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
  }

  // values
  status: string = '';
}

decorate(KycBusinessResultStore, {
  // values
  status: observable,
});

export default KycBusinessResultStore;
