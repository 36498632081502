import React from 'react';
import s from './Footer.module.css';

function Footer({ postfix }) {
  return (
    <>
      <ul className={s.footerLinksContainer}>
        <li>
          <a
            href="https://www.marqeta.com/terms"
            target="_blank"
            rel="noopener noreferrer"
            className={s.footerLink}
          >
            Terms
          </a>
        </li>
        <li>
          <a
            href="https://www.marqeta.com/api-terms"
            target="_blank"
            rel="noopener noreferrer"
            className={s.footerLink}
          >
            API terms
          </a>
        </li>
        <li>
          <a
            href="https://www.marqeta.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
            className={s.footerLink}
          >
            Privacy
          </a>
        </li>
        <li>
          <a
            href="https://www.marqeta.com/cookie-policy"
            target="_blank"
            rel="noopener noreferrer"
            className={s.footerLink}
          >
            Cookies
          </a>
        </li>
        <li>
          <a
            href="https://www.marqeta.com/responsible-disclosure"
            target="_blank"
            rel="noopener noreferrer"
            className={s.footerLink}
          >
            Responsible disclosure
          </a>
        </li>
      </ul>
      {postfix}
    </>
  );
}

export default Footer;
