import React from 'react';

import { getDisplayName } from '../helpers';

export function withAdditionalProps(WrappedComponent, additionalProps) {
  function WithAdditionalProps(props) {
    return <WrappedComponent {...props} {...additionalProps} />;
  }

  WithAdditionalProps.displayName = `WithAdditionalProps(${getDisplayName(WrappedComponent)})`;

  return WithAdditionalProps;
}
