import React from 'react';
import { Flex, LoadingOverlay } from '@mqd/volt-base';
import { Layout } from '../../layouts/index.js';
import { useWebhooks } from './hooks/index.js';
import { WebhooksBanner, WebhooksHeader, WebhooksMain } from './webhooks-overview/index.js';

const WebhooksOverview = () => {
  const { webhooks, loading, setWebhooksData } = useWebhooks();

  return (
    <Layout>
      <LoadingOverlay active={loading}>
        <Flex flexDirection="column" style={{ minHeight: 'calc(100vh - 180px)' }}>
          <WebhooksBanner />
          <WebhooksHeader webhooks={webhooks} />
          <WebhooksMain setWebhooksData={setWebhooksData} webhooks={webhooks} />
        </Flex>
      </LoadingOverlay>
    </Layout>
  );
};

export default WebhooksOverview;
