import React from 'react';
import { Modal, Button } from '@mqd/volt-base';
import { CodeBlock } from '@mqd/volt-codeblock';

const SendTestEventModal = ({ sendTestEvent, hideModalCallback }) => {
  function codeString() {
    return `
{
  "pings": [
    {
      "token": "marqeta",
      "payload": "healthcheck"
    }   
  ]
}`;
  }

  return (
    <div style={{ whiteSpace: 'break-spaces' }}>
      <Modal
        type="sm"
        heading="Send test event"
        description="This will send a test payload to the current webhook."
        hideModal={hideModalCallback}
        hideModalButtonText="Cancel"
        showHideModalButton={true}
        maxHeightVh={80}
        disableOverFlow={true}
        footerButtons={[<Button onClick={sendTestEvent}>Send test event</Button>]}
        testId="send-test-event-modal"
      >
        <CodeBlock
          content={[
            {
              language: 'json',
              copyValue: codeString(),
              content: codeString(),
            },
          ]}
          onCopy={() => console.log('code copied')}
        />
        <p>Dashboard will let you know whether or not the test ping is received by your webhook.</p>
      </Modal>
    </div>
  );
};

export default SendTestEventModal;
