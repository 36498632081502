import React from 'react';
import PropTypes from 'prop-types';
import { ActionCard } from '@mqd/volt-base';
import { CardTemplateContext } from '../../context/CardTemplateContext';

import { PreviewTemplate } from './components/preview-template';
import { EditTemplate } from './components/edit-template';

const { Card, MODE } = ActionCard;

export function UserActionCards({ data, previewTemplate, editTemplate, actions, cardType }) {
  const contextValue = {
    previewTemplate,
    editTemplate,
    actions,
    cardType,
  };

  return (
    <CardTemplateContext.Provider value={contextValue}>
      {data.map((datum) => (
        <Card
          key={datum.id}
          datum={datum}
          previewTemplate={PreviewTemplate}
          editTemplate={EditTemplate}
          defaultMode={datum.temp ? MODE.EDIT : MODE.PREVIEW}
        />
      ))}
    </CardTemplateContext.Provider>
  );
}

UserActionCards.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  previewTemplate: Card.propTypes.previewTemplate,
  editTemplate: Card.propTypes.editTemplate,
  actions: PropTypes.shape({
    onCreateNewCard: PropTypes.func.isRequired,
    onUpdateCard: PropTypes.func.isRequired,
    onResetCard: PropTypes.func.isRequired,
    onDeleteCard: PropTypes.func.isRequired,
  }),
};
