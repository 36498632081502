const titleize = (string) => {
  if (string && string.replace) {
    return string.replace(/_/g, ' ').replace(/\w\S*/g, function (word) {
      return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
    });
  } else {
    return '';
  }
};

export default titleize;
