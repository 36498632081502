import React from 'react';
import routerStore from '../../../stores/router/RouterStore.js';
import Layout from '../../../layouts/standard-layout/layout/Layout.js';
import FDSEnv from '../components/FDSEnv/index.js';
import { fdsRoutesByName } from './config.js';
import { ROUTES } from './constants.js';

function FdsRouter() {
  const { currentRouteName } = routerStore;
  const content = React.useMemo(
    () => fdsRoutesByName[currentRouteName]?.content,
    [currentRouteName]
  );

  return (
    <Layout key={currentRouteName} footerPostfix={<FDSEnv />}>
      {content}
    </Layout>
  );
}

export default FdsRouter;

export { ROUTES };
