import { decorate, observable, computed } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';

class CardProductJitFundingStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
  }
  // values
  paymentcard_funding_source: object = {};
  programgateway_funding_source: object = {};
  program_funding_source: object = {};

  // computed
  get fundingType() {
    if (Object.keys(this.paymentcard_funding_source).length) {
      return 'Pre-Funded';
    }

    if (Object.keys(this.program_funding_source).length) {
      return 'Managed JIT';
    }

    if (Object.keys(this.programgateway_funding_source).length) {
      return 'Gateway JIT';
    }
  }
}

decorate(CardProductJitFundingStore, {
  // values
  paymentcard_funding_source: observable,
  programgateway_funding_source: observable,
  program_funding_source: observable,

  // computed
  fundingType: computed,
});

export default CardProductJitFundingStore;
