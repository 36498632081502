import { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_TRANSACTION_EXCEPTION_DETAIL } from '../../queries/getTransactionExceptionDetail/query.js';
import {
  HASH_EXCEPTION_DETAILS_ERROR,
  HASH_EXCEPTION_DETAILS,
  LOADING_HASH_EXCEPTION_DETAILS,
  HASH_EXCEPTION_DETAILS_PROGRAM,
} from './constants.js';
import { useHashExceptionDetailsDispatch } from './context.js';

export default function useHashExceptionDetails({ tx_hash_internal_id, programs }) {
  const dispatch = useHashExceptionDetailsDispatch();
  const { data, loading, error } = useQuery(GET_TRANSACTION_EXCEPTION_DETAIL, {
    errorPolicy: 'all',
    variables: {
      tx_hash_internal_id,
    },
    fetchPolicy: 'no-cache',
    onError: () => {},
  });

  useEffect(() => {
    if (data?.transactionHashExceptionDetail) {
      dispatch({
        type: HASH_EXCEPTION_DETAILS,
        payload: data.transactionHashExceptionDetail,
      });
    }
  }, [data, dispatch]);

  useEffect(() => {
    const programShortCode = data?.transactionHashExceptionDetail?.common_data?.program_short_code;
    if (programShortCode && programs?.length) {
      const program = programs.find(({ short_name }) => short_name === programShortCode);
      dispatch({
        type: HASH_EXCEPTION_DETAILS_PROGRAM,
        payload: program ? program.program : programShortCode,
      });
    }
  }, [data, dispatch, programs]);

  useEffect(() => {
    dispatch({ type: LOADING_HASH_EXCEPTION_DETAILS, payload: loading });
  }, [loading, dispatch]);

  useEffect(() => {
    if (error) {
      dispatch({ type: HASH_EXCEPTION_DETAILS_ERROR, payload: error });
    }
  }, [error, dispatch]);

  return {
    loading,
  };
}
