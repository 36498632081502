import React from 'react';
import { Flex, Text, VSpacer } from '@mqd/volt-base';
import emptyStateFolder from '../assets/empty-state-folder.svg';

const HasNoWebhooks = () => (
  <Flex
    display="flex"
    flexGrow="1"
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
    type="div"
  >
    <div>
      <img src={emptyStateFolder} alt="empty folder graphic" />
    </div>
    <VSpacer factor={2} />
    <Text type="h5" role="heading" ariaLevel={2}>
      No webhooks added yet
    </Text>
    <VSpacer factor={0.5} />
    <Text>Webhooks will show up here once you add them.</Text>
  </Flex>
);

export { HasNoWebhooks };
