// @flow
import { decorate, observable, action } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';

class CardholderTransitionStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    // this.loadAndConstruct(args);
  }
  // values
  token: string = '';
  status: string = '';
  reason_code: string = '';
  reason: string = '';
  channel: string = '';
  cardholder_token: string = '';
  created_time: string = '';
  last_modified_time: string = '';
}

decorate(CardholderTransitionStore, {
  // values
  token: observable,
  status: observable,
  reason_code: observable,
  reason: observable,
  channel: observable,
  cardholder_token: observable,
  created_time: observable,
  last_modified_time: observable,

  // actions
  loadAndConstruct: action.bound,

  // computed
});

export default CardholderTransitionStore;
