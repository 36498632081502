import React, { useCallback, useContext, useMemo, useState } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import { Flex, HSpacer } from '@mqd/volt-base';
import { DatePicker } from '@mq/voltron-date-picker';
import { TableFilters } from '@mq/voltron-table';
import { ProgramReserveContext } from '../../../../../../../../context/ProgramReserveContext.js';
import { TransactionHistoryStoreContext } from '../../../../../../../../context/TransactionHistoryStoreContext.js';
import { ENTRY_TYPE_LABEL, TRANSACTION_STATUS_LABEL } from '../../../../../../../../constants.js';
import { prepareDataForCSVExport } from '../../../../utils.js';
import { fetchTransactionHistoryForExport } from '../../fetch-utils.js';
import CSVExportButton from '../../../CSVExportButton/index.js';
import { parseAmount } from '../../../../../../../../utils/money.js';

import s from './Filters.module.css';

function Filters() {
  const { programsOptions, currencyCodes } = useContext(ProgramReserveContext);
  const {
    RECORDS_TTL_DAYS,
    filterDateRange,
    setFilterDateRange,
    setFilterProgram,
    setFilterType,
    setFilterAmount,
    setFilterCollateralAmount,
    setFilterToken,
    setFilterStatus,
    setFilterCurrencyCode,
    resetPageIndex,
    exportNotAvailable,
    visibleColumns,
    commercialCreditActive,
  } = useContext(TransactionHistoryStoreContext);

  const [localFilterProgram, setLocalFilterProgram] = useState();
  const [localFilterEntryType, setLocalFilterEntryType] = useState();
  const [localFilterAmount, setLocalFilterAmount] = useState();
  const [localFilterCollateralAmount, setLocalFilterCollateralAmount] = useState();
  const [localFilterToken, setLocalFilterToken] = useState();
  const [localFilterStatus, setLocalFilterStatus] = useState();
  const [localFilterCurrencyCode, setLocalFilterCurrencyCode] = useState();

  const entryTypeOptions = Object.entries(ENTRY_TYPE_LABEL).map(([key, value]) => ({
    val: key,
    render: value,
  }));

  const statusOptions = Object.entries(TRANSACTION_STATUS_LABEL).map(([key, value]) => ({
    val: key,
    render: value,
  }));

  const currencyCodeOptions = currencyCodes.map((code) => ({
    val: code,
    render: code,
  }));

  const handleClearClick = () => {
    setLocalFilterProgram();
    setLocalFilterEntryType();
    setLocalFilterAmount();
    setLocalFilterCollateralAmount();
    setLocalFilterToken();
    setLocalFilterStatus();
    setLocalFilterCurrencyCode();
  };

  const handleFilterClick = () => {
    resetPageIndex();
    setFilterProgram(localFilterProgram);
    setFilterType(localFilterEntryType);
    setFilterToken(localFilterToken);
    setFilterStatus(localFilterStatus);
    setFilterCurrencyCode(localFilterCurrencyCode);
    setFilterAmount(validateAmount(localFilterAmount));
    setFilterCollateralAmount(validateAmount(localFilterCollateralAmount));
  };

  const validateAmount = (amount) => {
    const amountFloat = parseAmount(String(amount));
    const isValidAmount = typeof amountFloat === 'number' && isFinite(amountFloat);

    return isValidAmount ? amountFloat : undefined;
  };

  const programValue = useMemo(
    () => localFilterProgram && programsOptions.find((option) => localFilterProgram === option.val),
    [programsOptions, localFilterProgram]
  );

  const entryTypeValue = useMemo(
    () =>
      localFilterEntryType &&
      entryTypeOptions.find((option) => localFilterEntryType === option.val),
    [entryTypeOptions, localFilterEntryType]
  );

  const statusValue = useMemo(
    () => localFilterStatus && statusOptions.find((option) => localFilterStatus === option.val),
    [statusOptions, localFilterStatus]
  );

  const currencyCodeValue = useMemo(
    () =>
      localFilterCurrencyCode &&
      currencyCodeOptions.find((option) => localFilterCurrencyCode === option.val),
    [currencyCodeOptions, localFilterCurrencyCode]
  );

  const activeFilterCount = [
    programValue,
    localFilterAmount,
    localFilterCollateralAmount,
    entryTypeValue,
    localFilterToken,
    localFilterStatus,
    localFilterCurrencyCode,
  ].filter(Boolean).length;

  const memoizedDataLoader = useCallback(() => {
    return fetchTransactionHistoryForExport(prepareDataForCSVExport, visibleColumns);
  }, [visibleColumns]);

  const rawFilterOptions = [
    {
      type: 'select',
      label: 'Program',
      placeholder: 'Select program',
      value: programValue || {},
      options: programsOptions,
      props: { width: '312px', showSearch: true, testId: 'program-reserve-filter-program' },
      onChange: (program) => setLocalFilterProgram(program.val),
    },
    {
      label: 'Amount',
      value: localFilterAmount,
      props: { width: 312, testId: 'program-reserve-filter-amount' },
      onChange: (event) => setLocalFilterAmount(event.target.value),
    },
    {
      label: 'Collateral amount',
      value: localFilterCollateralAmount,
      props: { width: 312, testId: 'program-reserve-filter-collateral-amount' },
      onChange: (event) => setLocalFilterCollateralAmount(event.target.value),
    },
    {
      type: 'select',
      label: 'Status',
      placeholder: 'Select an option',
      value: statusValue || {},
      options: statusOptions,
      props: { width: '312px', testId: 'program-reserve-filter-status' },
      onChange: (status) => setLocalFilterStatus(status.val),
    },
    {
      label: 'Transaction token',
      value: localFilterToken,
      props: { width: 312, testId: 'program-reserve-filter-token' },
      onChange: (event) => setLocalFilterToken(event.target.value),
    },
    {
      type: 'select',
      label: 'Type',
      placeholder: 'Select an option',
      value: entryTypeValue || {},
      options: entryTypeOptions,
      props: { width: '312px', showSearch: true, testId: 'program-reserve-filter-type' },
      onChange: (entryType) => setLocalFilterEntryType(entryType.val),
    },
    {
      type: 'select',
      label: 'Currency code',
      placeholder: 'Select an option',
      value: currencyCodeValue || {},
      options: currencyCodeOptions,
      props: {
        width: '312px',
        showSearch: true,
        testId: 'program-reserve-filter-currency-code',
      },
      onChange: (currencyCode) => setLocalFilterCurrencyCode(currencyCode.val),
    },
  ];

  const filterOptions = commercialCreditActive
    ? rawFilterOptions
    : rawFilterOptions.filter((option) => option.label !== 'Collateral amount');

  return (
    <Flex justifyContent="space-between" className={s.filters}>
      <Flex flexDirection="row">
        <TableFilters
          fetchOnClear={false}
          testId="program-reserve-filter-table"
          activeFilterCount={activeFilterCount}
          disableFiltersScroll
          handleSearch={handleFilterClick}
          handleClear={handleClearClick}
          filterOptions={filterOptions}
        />

        <HSpacer factor={1} />

        <DatePicker
          from={filterDateRange[0]}
          to={filterDateRange[1]}
          disableDateRange
          disableAfterDay={moment().endOf('day').toDate()}
          disableBeforeDay={moment().subtract(RECORDS_TTL_DAYS, 'days').startOf('day').toDate()}
          onChange={(from, to) => setFilterDateRange([from, to])}
        />
      </Flex>

      <CSVExportButton
        filename="Program-Funding_Overview"
        disabled={exportNotAvailable}
        dataLoader={memoizedDataLoader}
      />
    </Flex>
  );
}

export default observer(Filters);
