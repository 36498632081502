// @flow
import { decorate, observable, action, computed } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';
import AddressStore from './AddressStore';
import IdentificationStore from './IdentificationStore';

class ProprietorOrOfficerStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.loadAndConstruct(args);
  }
  // values
  first_name: string = '';
  middle_name: string = '';
  last_name: string = '';
  alternative_names: string = '';
  title: string = '';
  dob: string = '';
  phone: string = '';
  email: string = '';
  ssn: string = '';
  // objects
  home: AddressStore = {};
  identifications: [IdentificationStore] = [];
  loading: boolean = false;

  // actions
  loadAndConstruct(args) {
    this.load(args);
    this.loadAndConstructItem('home', args.home, AddressStore);
    this.loadAndConstructList('identifications', args.identifications, IdentificationStore);
  }

  get isEdit() {
    return !(this.identifications && !this.identifications[0]);
  }

  // according to the api docs, there can only be one identification in the array
  get getIdentification() {
    return this.identifications ? this.identifications[0] : {};
  }

  // computed
  watchedObjects = ['home'];
  get updateParams(): Object {
    const params = { ...this.watchedUpdateParams };
    this.valuesUpdatedArray.forEach((value) => {
      params[value] = this[value];
    });
    if (Object.keys(params).length === 0) return null;
    return params;
  }

  get ssnWithStars() {
    try {
      return this.ssn.replace(/_/g, '*');
    } catch (e) {
      ('');
    }
  }
}

decorate(ProprietorOrOfficerStore, {
  // values
  first_name: observable,
  middle_name: observable,
  last_name: observable,
  alternative_names: observable,
  title: observable,
  dob: observable,
  phone: observable,
  email: observable,
  ssn: observable,
  loading: observable,
  // objects
  home: observable,
  identifications: observable,

  // actions
  loadAndConstruct: action.bound,

  // computed
  updateParams: computed,
  ssnWithStars: computed,
});

export default ProprietorOrOfficerStore;
