import RightAlignCell from '../../components/RightAlignCell/RightAlignCell.js';
import { EXCEPTION_TYPES } from '../../utilities/dictionary/index.js';
import { notAvailable, textRenderer } from '../helpers/renderers.js';
import getSharedFields from '../fields/shared.js';

const SETTLEMENT_DATE_QUEUE_FIELDS = Object.assign(
  getSharedFields([
    'hash_id',
    'state',
    'source',
    'network_ref_id',
    'program',
    'program_type',
    'bank',
    'tx_hash_internal_id',
  ]),
  {
    reason: {
      header: 'Reason',
      accessor: 'reason',
      key: 'reason',
      width: 139,
      renderer: ({ cellValue }) =>
        cellValue ? EXCEPTION_TYPES[cellValue] || cellValue : notAvailable,
    },

    latest_jcard_error_response: {
      header: 'Error code',
      accessor: 'latest_jcard_error_response',
      key: 'latest_jcard_error_response',
      width: 140,
      renderer: textRenderer,
    },

    settled_total: {
      header: 'Settled total',
      accessor: 'settled_total',
      key: 'settled_total',
      width: 147,
      renderer: (cell) => (cell.cellValue ? RightAlignCell(cell) : notAvailable),
    },

    submission_date: {
      header: 'Submission date',
      accessor: 'submission_date',
      key: 'submission_date',
      width: 130,
      renderer: textRenderer,
    },

    submitted_total: {
      header: 'Submitted total',
      accessor: 'submitted_total',
      key: 'submitted_total',
      width: 147,
      renderer: (cell) => (cell.cellValue ? RightAlignCell(cell) : notAvailable),
    },

    submitted_variance: {
      header: 'Submitted variance',
      accessor: 'submitted_variance',
      key: 'submitted_variance',
      width: 147,
      renderer: (cell) => (cell.cellValue ? RightAlignCell(cell) : notAvailable),
    },

    message_reason_code: {
      header: 'Message reason code',
      accessor: 'message_reason_code',
      key: 'message_reason_code',
      width: 160,
      renderer: textRenderer,
    },
  }
);

export default SETTLEMENT_DATE_QUEUE_FIELDS;
