import moment from 'moment';
import { DATE_FORMAT_STRING } from 'views/fds/utilities/date.js';
import { EXCEPTION_TYPES } from '../dictionary/index.js';
import number from '../number/index.js';
import getDefaultFormattingOptions from '../number/get-default-formatting-options.js';
import generateNotes from './generate-notes.js';
import {
  HASH_EXCEPTION_DETAILS,
  HASH_EXCEPTION_DETAILS_ERROR,
  HASH_EXCEPTION_DETAILS_PROGRAM,
  LOADING_HASH_EXCEPTION_DETAILS,
  UPDATE_NOTES,
} from './constants.js';

export const initialState = {
  adjustmentType: null,
  data: {},
  error: {
    hasError: false,
    message: '',
  },
  hashId: '',
  loading: true,
  netRefId: null,
  notes: [],
  program: null,
  settledTotal: '-',
  settlementDate: null,
  submissionDate: null,
  submissionType: null,
  submittedTotal: '-',
};

export function reducer(state, action) {
  const { payload, type } = action;
  switch (type) {
    case LOADING_HASH_EXCEPTION_DETAILS:
      return {
        ...state,
        loading: payload,
        error: {
          hasError: false,
          message: '',
        },
      };

    case HASH_EXCEPTION_DETAILS:
      return {
        ...state,
        adjustmentIdx: payload.adjustment?.idx,
        data: payload,
        hashId: payload.hash_id || '',
        netRefId: payload.common_data?.net_ref_id,
        notes: payload.activity ? generateNotes(payload.activity) : [],
        settledTotal: payload.settled_details?.settled_total
          ? number(payload.settled_details.settled_total, getDefaultFormattingOptions()).format()
          : '',
        settlementDate: moment(payload.settlement_date).format(DATE_FORMAT_STRING),
        submissionDate: payload.adjustment?.created_date
          ? moment(payload.adjustment.created_date).format(DATE_FORMAT_STRING)
          : null,
        submissionType: payload.adjustment?.adjustment_type
          ? EXCEPTION_TYPES[payload.adjustment.adjustment_type]
          : null,
        submittedTotal: payload.settled_details?.submitted_total
          ? number(payload.settled_details.submitted_total, getDefaultFormattingOptions()).format()
          : '',
      };

    case HASH_EXCEPTION_DETAILS_PROGRAM:
      return {
        ...state,
        program: payload,
      };

    case HASH_EXCEPTION_DETAILS_ERROR:
      return {
        ...state,
        error: {
          hasError: true,
          message: payload,
        },
      };

    case UPDATE_NOTES:
      return {
        ...state,
        notes: payload,
      };

    default:
      return state;
  }
}
