import { decorate, observable, computed } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';
import { KycBusinessStore } from './KycBusinessStore';

class KycsBusinessStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
  }

  // values
  data: Array<KycBusinessStore> = [];
  count: number;
  start_index: number;
  end_index: number;
  is_more: Boolean = false;
}

decorate(KycsBusinessStore, {
  // values
  data: observable,
  count: observable,
  start_index: observable,
  end_index: observable,
  is_more: observable,
});

export default KycsBusinessStore;
