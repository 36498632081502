import React from 'react';
import { observer } from 'mobx-react';
import { ContentSnippet, Text, VSpacer } from '@mqd/volt-base';
import { ParentDetailCard } from '@mq/voltron-parent';
import DetailCard from '../detail-card/DetailCard';

class CommandoModeRealTimeStandInInfoCard extends ParentDetailCard {
  state = {
    editActive: false,
  };

  get commandoModeStore() {
    const { commandoModeStore = {} } = this.props;
    return commandoModeStore;
  }

  renderInfo() {
    const {
      real_time_standin_enabled,
      real_time_standin_include_connection_errors,
      real_time_standin_inclue_response_timeouts,
      real_time_standin_include_application_errors,
    } = this.commandoModeStore;
    return (
      <DetailCard title="Real time stand in info">
        {this.renderSnippet({
          title: 'Enabled',
          content: real_time_standin_enabled ? 'True' : 'False',
          tooltipProps: { width: 275 },
          tooltipContent: `
              If true, Commando Mode is automatically
              enabled by events defined in the 
              real_time_standin_criteria object; if 
              false, Auto Commando Mode is not enabled.
            `,
        })}
        {this.renderSnippet({
          title: 'Connection errors',
          content: real_time_standin_include_connection_errors ? 'True' : 'False',
          tooltipProps: { width: 285 },
          tooltipContent: `
              If true, a non-timeout connection error
              automatically enables Commando Mode when
              real_time_standin_criteria.enabled is
              also true.
            `,
        })}
        {this.renderSnippet({
          title: 'Response errors',
          content: real_time_standin_inclue_response_timeouts ? 'True' : 'False',
          tooltipProps: { width: 300 },
          tooltipContent: `
              If true, a gateway response slower than 3000ms
              automatically enables Commando Mode when
              real_time_standin_criteria.enabled 
              is also true.
            `,
        })}
        {this.renderSnippet({
          title: 'Application errors',
          content: real_time_standin_include_application_errors ? 'True' : 'False',
          tooltipProps: { width: 260 },
          tooltipContent: `
              If true, an application error (any 
              non-connection, non-timeout error) 
              automatically enables Commando Mode 
              when real_time_standin_criteria.enabled 
              is also true.
            `,
        })}
      </DetailCard>
    );
  }

  render() {
    return <div>{this.renderInfo()}</div>;
  }
}

export default observer(CommandoModeRealTimeStandInInfoCard);
