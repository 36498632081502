import React from 'react';
import { DetailPane, Flex, HSpacer, Text, VSpacer } from '@mqd/volt-base';

import TextColumn from '../../../components/TextColumn/index.js';
import { TOOLTIP_DESCRIPTIONS } from '../../../utilities/dictionary/index.js';
import getFields from '../../../network-configs/fields/index.js';

import s from './SubmissionDetails.module.css';
import withProgramSelector from '../../../../../components/program-selector/components/withProgramSelector.js';
import getProgramCodeToNameMap from '../../../utilities/get-program-code-to-name-map.js';
import Activity from '../../ExceptionDetail/components/Activity.js';

const SubmissionDetails = ({
  programSelector,
  handleViewAllActivity,
  data,
  notes,
  submissionType,
  submissionDate,
  settledDetails,
  submittedDetails,
  idPrefix,
}) => {
  const {
    hash_id: hashId,
    exception_type: exceptionType,
    settlement_date: settlementDate,
    tx_hash_internal_id,
  } = data;
  const {
    acquirer_reference_id,
    bank,
    ica,
    masked_pan,
    network,
    net_ref_id,
    program_short_code,
    program_type,
    reference_id,
    settlement_service_number,
    source,
    state,
    subnetwork,
  } = data?.common_data || {};

  const shortCodeToNameMap = getProgramCodeToNameMap(programSelector);
  const program = shortCodeToNameMap.get(program_short_code) || null;
  const hashIdInformation = React.useMemo(
    () =>
      getFields('hash_group', {
        acquirer_reference_id,
        bank,
        exceptionType,
        hashId,
        ica,
        net_ref_id,
        network,
        program,
        program_type,
        reference_id,
        settlement_service_number,
        showSubmissionFields: true,
        showReportingEntities: true,
        settlementDate,
        source,
        state,
        subnetwork,
        submissionDate,
        submissionType,
        tx_hash_internal_id,
      }),
    [
      acquirer_reference_id,
      bank,
      exceptionType,
      hashId,
      ica,
      net_ref_id,
      network,
      program,
      program_type,
      reference_id,
      settlementDate,
      settlement_service_number,
      source,
      state,
      subnetwork,
      submissionDate,
      submissionType,
      tx_hash_internal_id,
    ]
  );

  const transactionInformation = React.useMemo(
    () =>
      getFields(
        'transaction_info',
        {
          source,
          masked_pan,
          network,
        },
        ['source', 'masked_pan']
      ),
    [source, masked_pan, network]
  );

  return (
    <>
      <div className={s.fullWidth}>
        <Flex flexDirection="row">
          <div className={s.halfWidth}>
            <TextColumn
              data={hashIdInformation}
              header="Hash ID group information"
              idPrefix="exceptionDetailsHashIdGroupInformation"
            />
            <VSpacer factor={3} />
            <TextColumn
              data={transactionInformation}
              header="Transaction information"
              idPrefix="exceptionDetailsHashIdGroupInformation"
            />
          </div>
          <HSpacer factor={3} inline={true} />
          <Activity notes={notes} hideInput={true} handleViewAllActivity={handleViewAllActivity} />
        </Flex>

        <VSpacer factor={3} />

        <DetailPane.Container header="Transaction comparison" layout="row">
          <Flex flexDirection="row">
            <div className={s.fullWidth}>
              <DetailPane.Section header="Settled transaction summary">
                <DetailPane.Content
                  label="Settled total"
                  tooltip={TOOLTIP_DESCRIPTIONS.SETTLED_TOTAL}
                >
                  <Text>{settledDetails.total || ''}</Text>
                </DetailPane.Content>
                <DetailPane.Content
                  label="Transaction total"
                  tooltip={TOOLTIP_DESCRIPTIONS.TRANSACTION_TOTAL}
                >
                  <Text>{settledDetails.transactionTotal || ''}</Text>
                </DetailPane.Content>
                <DetailPane.Content label="Fee total" tooltip={TOOLTIP_DESCRIPTIONS.FEES_TOTAL}>
                  <Text>{settledDetails.feesTotal || ''}</Text>
                </DetailPane.Content>
              </DetailPane.Section>

              <DetailPane.Section header="Settled fee summary">
                <DetailPane.Content label="Cross border fee">
                  <Text>{settledDetails.crossBorderFee || ''}</Text>
                </DetailPane.Content>
                <DetailPane.Content label="Interchange fee">
                  <Text>{settledDetails.interchangeFee || ''}</Text>
                </DetailPane.Content>
                <DetailPane.Content label="Surcharge/ATM debit fee">
                  <Text>{settledDetails.surchargeAtmPinDebit || ''}</Text>
                </DetailPane.Content>
              </DetailPane.Section>
            </div>

            <div className={s.fullWidth}>
              <DetailPane.Section header="Submitted transaction summary">
                <DetailPane.Content
                  label="Settled total"
                  tooltip={TOOLTIP_DESCRIPTIONS.SETTLED_TOTAL}
                >
                  <Text>{submittedDetails.total || ''}</Text>
                </DetailPane.Content>
                <DetailPane.Content
                  label="Transaction total"
                  tooltip={TOOLTIP_DESCRIPTIONS.TRANSACTION_TOTAL}
                >
                  <Text>{submittedDetails.transactionTotal || ''}</Text>
                </DetailPane.Content>
                <DetailPane.Content label="Fee total" tooltip={TOOLTIP_DESCRIPTIONS.FEES_TOTAL}>
                  <Text>{submittedDetails.feesTotal || ''}</Text>
                </DetailPane.Content>
              </DetailPane.Section>

              <DetailPane.Section header="Submitted fee summary">
                <DetailPane.Content label="Cross border fee">
                  <Text>{submittedDetails.crossBorderFee || ''}</Text>
                </DetailPane.Content>

                <DetailPane.Content label="Interchange fee">
                  <Text>{submittedDetails.interchangeFee || ''}</Text>
                </DetailPane.Content>

                <DetailPane.Content label="Surcharge/ATM debit fee">
                  <Text>{submittedDetails.surchargeAtmPinDebit || ''}</Text>
                </DetailPane.Content>
              </DetailPane.Section>
            </div>
          </Flex>
        </DetailPane.Container>
      </div>
    </>
  );
};

export default withProgramSelector(SubmissionDetails);
