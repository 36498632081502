export const progressBarColors = {
  incomplete: 'statusWarningColor',
  complete: 'statusSuccessColor',
};

export const approvalStatusColors = {
  Pending: 'statusWarningColor',
  'Not Performed': 'blackLighter4',
  Verified: 'statusSuccessColor',
  Rejected: 'statusErrorColor',
};

export const iconInputOptions = {
  SUCCESS: {
    dropdownOptions: ['Rejected', 'Not Performed'],
    icon: 'check',
  },
  FAILURE: {
    dropdownOptions: ['Verified', 'Not Performed'],
    icon: 'close',
  },
  PENDING: {
    dropdownOptions: ['Verified', 'Rejected'],
    icon: 'pending',
  },
};

export const stringToStatus = {
  Verified: 'SUCCESS',
  Rejected: 'FAILURE',
  Pending: 'PENDING',
};

export const statusToString = {
  SUCCESS: 'Verified',
  FAILURE: 'Rejected',
  PENDING: 'Pending',
};

export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';
export const PENDING = 'PENDING';
