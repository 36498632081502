import currentUserStore from 'stores/CurrentUserStore';
import hasRoleForAtLeastOneProgram from '../../helpers/hasRoleForAtLeastOneProgram';
import { INVENTORY_MGMT_VIEW } from '../../../views/admin/uam-granular-permissions/constants.js';

export const requirements = {
  requiredUserPermissions: ['aux-inventory-management'],
  requiredGranularPermissions: [INVENTORY_MGMT_VIEW],
};

export const verifier = () => {
  const {
    userStore: { uamGranularPermissionsActive, hasRequiredUamPermissions },
  } = currentUserStore;

  return uamGranularPermissionsActive
    ? hasRequiredUamPermissions([INVENTORY_MGMT_VIEW])
    : hasRoleForAtLeastOneProgram('aux-inventory-management');
};
