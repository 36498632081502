import { decorate, observable } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';
import BusinessIdentityResultsStore from './BusinessIdentityResultsStore';

class BusinessIdentityBusinessStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
  }
  // values
  name: BusinessIdentityResultsStore = {};
  address: BusinessIdentityResultsStore = {};
  city: BusinessIdentityResultsStore = {};
  state: BusinessIdentityResultsStore = {};
  zip: BusinessIdentityResultsStore = {};
  country: BusinessIdentityResultsStore = {};
  ein: BusinessIdentityResultsStore = {};
  ofac: BusinessIdentityResultsStore = {};
  sanctions: BusinessIdentityResultsStore = {};
}

decorate(BusinessIdentityBusinessStore, {
  // values
  name: observable,
  address: observable,
  city: observable,
  state: observable,
  zip: observable,
  country: observable,
  ein: observable,
  ofac: observable,
  sanctions: observable,
});

export default BusinessIdentityBusinessStore;
