// @flow
import { decorate, action, runInAction, observable, computed } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';
import moment from 'moment';
import BusinessIdentityCheckStore from './BusinessIdentityCheckStore';
import { dateToMMDDYYYY } from './../../../../utils/helpers/parseDateString';

class KYBStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
    this.getBusinessEntities = this.getBusinessEntities.bind(this);
  }

  // OBSERVABLES
  loadingBusinessEntity: Boolean;
  loadingKybLog: Boolean;
  cardholder_token = '';
  kybLog: Array = [];
  //objects
  businessIdentityCheck: BusinessIdentityCheckStore = {};

  // ACTIONS
  async hydrate() {
    await Promise.all([this.getBusinessEntities(), this.getKybLog()]);
  }

  async getBusinessEntities() {
    const payload = { cardholder_token: this.cardholder_token };
    try {
      this.loadingBusinessEntity = true;
      const result = await this.gqlQuery(
        `
          query businessIdentityCheck ($cardholder_token: ID!) {
            businessIdentityCheck (cardholder_token: $cardholder_token) {
              cardholder_token
              status
              attester_name
              attester_title
              attestation_date
              attestation_consent
              proprietor_is_beneficial_owner
              business {
                ${this.businessDetails}
              }
              proprietor {
                ${this.identityDetails}
              }
              beneficial_owner1 {
                ${this.identityDetails}
              }
              beneficial_owner2 {
                ${this.identityDetails}
              }
              beneficial_owner3 {
                ${this.identityDetails}
              }
              beneficial_owner4 {
                ${this.identityDetails}
              }
            }
          }
        `,
        payload
      );
      runInAction(() => {
        const newBusinessEntities = this.cleanBusinessEntities(
          this.dig(result, 'data', 'businessIdentityCheck')
        );
        this.loadAndConstructItem(
          'businessIdentityCheck',
          newBusinessEntities,
          BusinessIdentityCheckStore
        );
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.loadingBusinessEntity = false;
    }
  }

  async getKybLog() {
    const payload = { cardholder_token: this.cardholder_token };
    try {
      this.loadingKybLog = true;
      const result = await this.gqlQuery(
        `
          query kybLog ($cardholder_token: ID!) {
            kybLog (cardholder_token: $cardholder_token) {
              data {
                cardholder_token
                user_email
                timestamp
                type
                message_title
                note
              }
            }
          }
        `,
        payload
      );

      runInAction(() => {
        this.kybLog = this.dig(result, 'data', 'kybLog', 'data')
          ? this.orderKybLog(result.data.kybLog.data)
          : [];
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.loadingKybLog = false;
    }
  }

  // HELPERS

  get businessDetails() {
    return `
      name {
        ${this.identityVals}
      }
      address {
        ${this.identityVals}
      }
      address2 {
        ${this.identityVals}
      }
      city {
        ${this.identityVals}
      }
      state {
        ${this.identityVals}
      }
      zip {
        ${this.identityVals}
      }
      country {
        ${this.identityVals}
      }
      ein {
        ${this.identityVals}
      }
      ofac {
        ${this.identityVals}
      }
      sanctions {
        ${this.identityVals}
      }
    `;
  }

  get identityDetails() {
    return `
      first_name {
        ${this.identityVals}
      }
      middle_name {
        ${this.identityVals}
      }
      last_name {
        ${this.identityVals}
      }
      address {
        ${this.identityVals}
      }
      address2 {
        ${this.identityVals}
      }
      city {
        ${this.identityVals}
      }
      state {
        ${this.identityVals}
      }
      zip {
        ${this.identityVals}
      }
      country {
        ${this.identityVals}
      }
      ssn {
        ${this.identityVals}
      }
      dob {
        ${this.identityVals}
      }
      ofac {
        ${this.identityVals}
      }
    `;
  }

  get identityVals() {
    return `
      value
      status
      note
    `;
  }

  get status(): String {
    return this.dig(this.businessIdentityCheck, 'status');
  }

  get businessEntities(): Object {
    return {
      status: this.dig(this.businessIdentityCheck, 'status') || '',
      cardholder_token: this.dig(this.businessIdentityCheck, 'cardholder_token') || '',
      business: this.dig(this.businessIdentityCheck, 'business'),
      proprietor: this.dig(this.businessIdentityCheck, 'proprietor'),
      beneficial_owner1: this.dig(this.businessIdentityCheck, 'beneficial_owner1'),
      beneficial_owner2: this.dig(this.businessIdentityCheck, 'beneficial_owner2'),
      beneficial_owner3: this.dig(this.businessIdentityCheck, 'beneficial_owner3'),
      beneficial_owner4: this.dig(this.businessIdentityCheck, 'beneficial_owner4'),
      attester: this.dig(this.businessIdentityCheck, 'attester'),
    };
  }

  cleanBusinessEntities(businessEntities) {
    try {
      if (!businessEntities) return {};
      delete businessEntities.__typename;
      var canVerify = true;
      Object.keys(businessEntities).forEach((key) => {
        if (businessEntities && businessEntities[key]) {
          if (businessEntities && businessEntities[key].__typename) {
            delete businessEntities[key].__typename;
          }

          if (businessEntities && businessEntities[key].dob) {
            businessEntities[key].dob.value = dateToMMDDYYYY(businessEntities[key].dob.value);
          }

          businessEntities.attester = {
            attester_name: {
              value: businessEntities.attester_name || '',
              status: this.getStatusForAttestationData(businessEntities.attester_name),
            },
            attester_title: {
              value: businessEntities.attester_title || '',
              status: this.getStatusForAttestationData(businessEntities.attester_title),
            },
            attestation_date: {
              value: businessEntities.attestation_date
                ? dateToMMDDYYYY(businessEntities.attestation_date)
                : '',
              status: this.getStatusForAttestationData(businessEntities.attestation_date),
            },
          };

          if (typeof businessEntities[key] === 'object') {
            Object.keys(businessEntities[key]).forEach((subKey) => {
              if (
                businessEntities[key][subKey] &&
                businessEntities[key][subKey].status &&
                businessEntities[key][subKey].status !== 'SUCCESS'
              ) {
                canVerify = false;
              }

              if (businessEntities[key][subKey] && !businessEntities[key][subKey].status) {
                businessEntities[key][subKey].status = 'PENDING';
              }

              if (businessEntities[key][subKey] && !businessEntities[key][subKey].value) {
                businessEntities[key][subKey].value = '';
              }
            });
          }
        }
      });
      if (businessEntities && businessEntities.proprietor) {
        businessEntities.proprietor.proprietor_is_beneficial_owner = {
          status:
            businessEntities.proprietor_is_beneficial_owner &&
            businessEntities.proprietor_is_beneficial_owner === 'true'
              ? 'SUCCESS'
              : 'FAILURE',
        };
      }
      businessEntities.canVerify = canVerify;
      return businessEntities;
    } catch (error) {
      console.error(error);
      return {};
    }
  }

  getStatusForAttestationData(value) {
    if (value && value != '') {
      return 'SUCCESS';
    } else {
      return 'FAILED';
    }
  }

  orderKybLog(kybLogData) {
    try {
      if (!kybLogData) return [];
      const collapsedHistoryObject = kybLogData.reduce((acc, result) => {
        if (acc[result.timestamp]) {
          acc[result.timestamp].push(result);
        } else {
          acc[result.timestamp] = [result];
        }
        return acc;
      }, {});
      const sortedTimestamps = Object.keys(collapsedHistoryObject).sort();
      const collapsedEvents = sortedTimestamps.map((timestamp) => {
        return collapsedHistoryObject[timestamp];
      });
      return collapsedEvents;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async saveChanges(entityFieldsObject: Object, entityType, note, userEmail) {
    this.loadingBusinessEntity = true;
    const changedParams = this.prepareEntityToSave(entityFieldsObject, entityType, note, userEmail);
    try {
      const payload = {
        cardholder_token: this.cardholder_token,
        updatedEntities: changedParams.filter((entity) => entity),
      };
      const result = await this.gqlMutation(
        `
        mutation updateBusinessIdentityCheck(
          $cardholder_token:ID!
          $updatedEntities:[UpdatedEntity]
        ){
          updateBusinessIdentityCheck(
            cardholder_token:$cardholder_token
            updatedEntities:$updatedEntities
          )
        }
        `,
        payload
      );
      return runInAction(() => {
        if (result) {
          this.getBusinessEntities();
          this.getKybLog();
          return true;
        } else {
          return false;
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  async saveBusinessItems(businessObject: Object) {
    this.loadingBusinessEntity = true;
    try {
      const paramsInfo = {
        token: { type: 'ID!' },
        attester_name: { type: 'String' },
        attester_title: { type: 'String' },
        attestation_date: { type: 'String' },
        business_name_dba: { type: 'String' },
      };
      const safariSafeDate = businessObject.attestation_date
        ? businessObject.attestation_date.replace(/-/gi, '/')
        : this.businessEntities.attester.attestation_date.value;
      const result = await this.gqlMutation(
        `
        mutation updateBusiness(${this.configureOuterQueryParams(paramsInfo)}) {
          updateBusiness(${this.configureInnerQueryParams(paramsInfo)}) {
            attester_name
            attester_title
            attestation_date
            business_name_dba
          }
        }
        `,
        {
          token: this.cardholder_token,
          attester_name:
            businessObject.attester_name || this.businessEntities.attester.attester_name.value,
          attester_title:
            businessObject.attester_title || this.businessEntities.attester.attester_title.value,
          attestation_date: moment(safariSafeDate).format('YYYY-MM-DDTHH:mm:ssZ'),
          business_name_dba:
            businessObject.business_name_dba ||
            this.businessEntities.business.business_name_dba.value,
        }
      );
      return runInAction(() => {
        if (result) {
          return true;
        } else {
          return false;
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  async saveBusinessChanges(entityFieldsObject: Object, entityType) {
    this.loadingBusinessEntity = true;
    const paramsInfo = {
      token: { type: 'ID!' },
      business_name_legal: { type: 'String' },
      business_name_dba: { type: 'String' },
      office_location: { type: 'AddressInput' },
      proprietor_or_officer: { type: 'ProprietorOrOfficerInput' },
      beneficial_owner1: { type: 'ProprietorOrOfficerInput' },
      beneficial_owner2: { type: 'ProprietorOrOfficerInput' },
      beneficial_owner3: { type: 'ProprietorOrOfficerInput' },
      beneficial_owner4: { type: 'ProprietorOrOfficerInput' },
    };
    const params = this.prepareBusinessToSave(entityFieldsObject, entityType);
    try {
      const result = await this.gqlMutation(
        `
        mutation updateBusiness(${this.configureOuterQueryParams(paramsInfo)}) {
          updateBusiness(${this.configureInnerQueryParams(paramsInfo)}) {
            business_name_legal
            business_name_dba
            office_location {
              address1
              city
              postal_code
              state
              country
            }
            proprietor_or_officer {
              dob
              ssn 
              first_name
              middle_name
              last_name
              home {
                address1
                city
                state
                postal_code
                country
              }
            }
            beneficial_owner1 {
              first_name
              middle_name
              last_name
              ssn
              dob
              home {
                address1
                city
                state
                postal_code
                country
              }
            }
            beneficial_owner2 {
              first_name
              middle_name
              last_name
              ssn
              dob
              home {
                address1
                city
                state
                postal_code
                country
              }
            }
            beneficial_owner3 {
              first_name
              middle_name
              last_name
              ssn
              dob
              home {
                address1
                city
                state
                postal_code
                country
              }
            }
            beneficial_owner4 {
              first_name
              middle_name
              last_name
              ssn
              dob
              home {
                address1
                city
                state
                postal_code
                country
              }
            }
          }
        }
        `,
        params
      );
      return runInAction(() => {
        if (result) {
          return true;
        } else {
          return false;
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  prepareEntityToSave(entityFieldsObject, entityType, note, userEmail) {
    const paramNames = Object.keys({
      ...this.businessEntities[entityType],
    });
    const entity = this.businessEntities[entityType];
    var newBusinessEntities = this.businessEntities;
    var businessObject = {};
    try {
      return paramNames.map((key) => {
        if (
          entityFieldsObject[key].value !== entity[key].value ||
          entityFieldsObject[key].status !== entity[key].status
        ) {
          newBusinessEntities[entityType][key].value = entityFieldsObject[key].value;
          newBusinessEntities[entityType][key].status = entityFieldsObject[key].status;
          if (
            (key.includes('attest') || key.includes('business_name_dba')) &&
            entityFieldsObject[key].value.length >= 0
          ) {
            businessObject[key] = entityFieldsObject[key].value;
          } else if (entityFieldsObject[key].value === '*********') {
            return {
              check_type: entityType.toUpperCase(),
              check_name: key,
              check_status: entityFieldsObject[key].status,
              note: note,
              user_email: userEmail,
            };
          } else if (key === 'dob') {
            const safariSafeDate = entityFieldsObject[key].value.replace(/-/gi, '/');
            return {
              check_type: entityType.toUpperCase(),
              check_name: key,
              check_value: moment(safariSafeDate).format('ddd MMM DD hh:mm:ss zz YYYY'),
              check_status: entityFieldsObject[key].status,
              note: note,
              user_email: userEmail,
            };
          } else {
            return {
              check_type: entityType.toUpperCase(),
              check_name: key,
              check_value: entityFieldsObject[key].value,
              check_status: entityFieldsObject[key].status,
              note: note,
              user_email: userEmail,
            };
          }
          if (Object.keys(businessObject).length > 0) {
            this.saveBusinessItems(businessObject);
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  prepareBusinessToSave(entityFieldsObject, entityType) {
    const relevantFields = [
      'name',
      'ssn',
      'ein',
      'dob',
      'business_name_dba',
      'attester_name',
      'attester_title',
      'attestation_date',
      'first_name',
      'middle_name',
      'last_name',
    ];
    const addressFields = ['address', 'address2', 'city', 'state', 'zip', 'country'];
    entityType = entityType === 'proprietor' ? 'proprietor_or_officer' : entityType;
    const paramsInfo = {
      token: this.cardholder_token,
      [entityType]: {},
    };
    const paramsEntity = paramsInfo[entityType];
    Object.keys(entityFieldsObject).forEach((key) => {
      if (relevantFields.includes(key)) {
        if (entityFieldsObject[key].value === '*********') {
          return;
        }
        if (entityType === 'business') {
          if (key === 'name') {
            paramsInfo.business_name_legal = this.dig(entityFieldsObject, key, 'value');
          }
          if (key === 'business_name_dba') {
            paramsInfo.business_name_dba = this.dig(entityFieldsObject, key, 'value');
          }
          if (key === 'ein') {
            paramsInfo.identifications = [];
            paramsInfo.identifications.push({
              type: 'BUSINESS_TAX_ID',
              value: this.dig(entityFieldsObject, key, 'value'),
            });
          }
        } else {
          if (key === 'ssn') {
            paramsEntity.identifications = [];
            paramsEntity.identifications.push({
              type: 'SSN',
              value: this.dig(entityFieldsObject, key, 'value'),
            });
          }
          paramsEntity[key] =
            key === 'dob'
              ? moment(this.dig(entityFieldsObject, key, 'value')).format('YYYY-MM-DD')
              : this.dig(entityFieldsObject, key, 'value');
        }
      }

      if (addressFields.includes(key)) {
        if (entityType === 'business') {
          if (!paramsInfo['office_location']) {
            paramsInfo['office_location'] = {};
          }

          if (key === 'address') {
            paramsInfo['office_location'].address1 = this.dig(entityFieldsObject, key, 'value');
          } else if (key === 'zip') {
            paramsInfo['office_location'].postal_code = this.dig(entityFieldsObject, key, 'value');
          } else {
            paramsInfo['office_location'][key] = this.dig(entityFieldsObject, key, 'value');
          }
        } else {
          if (!paramsEntity['home']) {
            paramsEntity['home'] = {};
          }

          if (key === 'address') {
            paramsEntity['home'].address1 = this.dig(entityFieldsObject, key, 'value');
          } else if (key === 'zip') {
            paramsEntity['home'].postal_code = this.dig(entityFieldsObject, key, 'value');
          } else {
            paramsEntity['home'][key] = this.dig(entityFieldsObject, key, 'value');
          }
        }
      }
    });
    return paramsInfo;
  }
}

decorate(KYBStore, {
  // OBSERVABLES
  businessEntities: observable,
  cardholder_token: observable,
  loadingBusinessEntity: observable,
  loadingKybLog: observable,
  kybLog: observable,
  businessIdentityCheck: observable,

  // ACTIONS
  hydrate: action.bound,
  getBusinessEntities: action.bound,
  getKybLog: action.bound,
  saveChanges: action.bound,

  // COMPUTED
  status: computed,
  businessEntities: computed,
});

export default KYBStore;
