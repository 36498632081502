import React, { createContext, useContext } from 'react';

export const TOGGLE_EDIT_DETAILS = 'TOGGLE_EDIT_DETAILS';
export const TOGGLE_EDIT_IDENTIFICATION = 'TOGGLE_EDIT_IDENTIFICATION';

const initialState = { isEditDetailsMode: false, isEditIdentificationMode: false };

function reducer(state, action) {
  const { type, payload } = action;
  console.info(type, payload);
  switch (type) {
    case TOGGLE_EDIT_DETAILS:
      return { ...state, isEditDetailsMode: Boolean(payload), isEditIdentificationMode: false };
    case TOGGLE_EDIT_IDENTIFICATION:
      return { ...state, isEditIdentificationMode: Boolean(payload), isEditDetailsMode: false };
    default:
      return state;
  }
}

const CardholderInfoContext = createContext();
const CardholderInfoProvider = ({ children, ...rest }) => {
  return <CardholderInfoContext.Provider {...rest}>{children}</CardholderInfoContext.Provider>;
};

const useCardholderInfoContext = () => {
  const context = useContext(CardholderInfoContext);
  if (context === undefined) {
    throw new Error('CardholderInfoContext must be used within a CardholderInfoProvider');
  }
  return context;
};

export { initialState, reducer, CardholderInfoProvider, useCardholderInfoContext };
