import React, { useState } from 'react';

import { ActionCard } from '@mqd/volt-base';

import { useCardTemplateContext } from '../../../../context/CardTemplateContext';
import { renderControls } from './render-controls';

const { TemplateWrapper } = ActionCard;

export function EditTemplate({ datum, mode, switchToPreview }) {
  const { editTemplate: EditTemplate, actions, cardType } = useCardTemplateContext();
  const [formState, setFormState] = useState(datum);

  return (
    <TemplateWrapper mode={mode}>
      <EditTemplate datum={datum} formState={formState} handleFormChange={setFormState}>
        {renderControls({ formState, switchToPreview, actions, cardType })}
      </EditTemplate>
    </TemplateWrapper>
  );
}
