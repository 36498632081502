import {
  ADJUSTMENT_REASONS,
  ADJUSTMENT_ACTIONS,
  EXCEPTION_TYPES,
} from '../../utilities/dictionary/index.js';

const {
  CUSTOMER_APPEASEMENT,
  FAILED_TO_POST,
  NON_MARQETA_CARDHOLDER,
  OTHER,
  OVERPOSTED,
  PAST_SLA,
  POTENTIAL_FRAUD,
  PROGRAM_ENABLED_LATE,
  PROD_SUPPORT_ACTION,
  CHARGEBACK_PRESENT,
  LATE_JCARD_TRANSACTIONS,
  ROUNDING_ERROR,
  NRID_IN_DIFFERENT_SUBNETWORKS,
  MISSING_SURCHARGE_AMOUNT_IN_FDS,
  MISSING_TRANSACTION_IN_JCARD,
  SETTLEMENT_DATE_MISMATCH,
  OPPOSITE_SURCHARGE_AMOUNT_SIGNAGE_IN_FDS,
  TX_NOT_REQUIRED_FOR_RECON,
  REPRESENTMENT_PRESENT,
  CHARGEBACK_IN_JCARD,
  CHARGEBACK_IN_TXM,
  CHARGEBACK_IN_TXM_AND_JCARD,
  ATM_ALREADY_PRESENT,
} = ADJUSTMENT_REASONS;

const {
  ADJUST_TRANSACTION,
  WRITE_OFF_TRANSACTION,
  MANUAL_TRANSACTION,
  INVALID_TRANSACTION,
  EXCLUDE_TRANSACTION,
} = ADJUSTMENT_ACTIONS;

// types
export const SELECT_ACTION = 'SELECT_ACTION';
export const SELECT_REASON = 'SELECT_REASON';
export const SELECT_ACTION_ERROR = 'SELECT_ACTION_ERROR';
export const SELECT_REASON_ERROR = 'SELECT_REASON_ERROR';

const other = {
  type: OTHER,
  label: 'Other',
};

export const ADJUSTMENT_ACTIONS_LABELS = [
  {
    type: ADJUST_TRANSACTION,
    label: 'Adjust',
  },
  {
    type: WRITE_OFF_TRANSACTION,
    label: 'Write-off',
  },
  {
    type: MANUAL_TRANSACTION,
    label: 'Manually reconcile',
  },
  {
    type: INVALID_TRANSACTION,
    label: 'Invalid exception',
  },
  {
    type: EXCLUDE_TRANSACTION,
    label: 'Exclude',
  },
];

export const ADJUSTMENT_REASONS_LABELS = {
  ADJUST_TRANSACTION: [
    {
      type: FAILED_TO_POST,
      label: EXCEPTION_TYPES.FAILED_TO_POST,
    },
    {
      type: OVERPOSTED,
      label: EXCEPTION_TYPES.OVERPOSTED,
    },
  ],
  WRITE_OFF_TRANSACTION: [
    {
      type: CUSTOMER_APPEASEMENT,
      label: 'Customer appeasement',
    },
    {
      type: NON_MARQETA_CARDHOLDER,
      label: 'Non-Marqeta cardholder',
    },
    {
      type: PAST_SLA,
      label: 'Past SLA',
    },
    {
      type: POTENTIAL_FRAUD,
      label: 'Potential fraud',
    },
    other,
  ],
  MANUAL_TRANSACTION: [
    {
      type: PROGRAM_ENABLED_LATE,
      label: 'Program enabled late',
    },
    {
      type: PROD_SUPPORT_ACTION,
      label: 'Prod support action/ticket',
    },
    {
      type: CHARGEBACK_PRESENT,
      label: 'Chargeback is present',
    },
    {
      type: LATE_JCARD_TRANSACTIONS,
      label: 'Late JCard transactions',
    },
    {
      type: ROUNDING_ERROR,
      label: 'Rounding off error',
    },
    {
      type: NRID_IN_DIFFERENT_SUBNETWORKS,
      label: 'NRID in different subnetworks',
    },
    {
      type: MISSING_SURCHARGE_AMOUNT_IN_FDS,
      label: 'Missing Surcharge Amount in FDS',
    },
    {
      type: MISSING_TRANSACTION_IN_JCARD,
      label: 'Missing Transaction in JCard',
    },
    {
      type: SETTLEMENT_DATE_MISMATCH,
      label: 'Date mismatch',
    },
    {
      type: OPPOSITE_SURCHARGE_AMOUNT_SIGNAGE_IN_FDS,
      label: 'Opposite Surcharge Amount Signage in FDS',
    },
    other,
  ],
  INVALID_EXCEPTION: [
    {
      type: TX_NOT_REQUIRED_FOR_RECON,
      label: 'Tx not required for reconciliation',
    },
    {
      type: REPRESENTMENT_PRESENT,
      label: 'Representment present',
    },
    other,
  ],
  EXCLUDE_TRANSACTION: [
    {
      type: CHARGEBACK_IN_JCARD,
      label: 'Chargeback in JCard',
    },
    {
      type: CHARGEBACK_IN_TXM,
      label: 'Chargeback in TXM',
    },
    {
      type: CHARGEBACK_IN_TXM_AND_JCARD,
      label: 'Chargeback in TXM and JCard',
    },
    {
      type: ATM_ALREADY_PRESENT,
      label: 'ATM is already present',
    },
    other,
  ],
};
