import { dig } from '../../shared-utils';
import {
  SSN,
  TIN,
  SIN,
  NIN,
  PASSPORT_NUMBER,
  DRIVERS_LICENSE,
  IDENTIFICATION_CARD_NUMBER,
} from './constants';

export const detailsFormValues = (values) => {
  const fields = [
    'first_name',
    'middle_name',
    'last_name',
    'address1',
    'address2',
    'birth_date',
    'city',
    'state',
    'postal_code',
    'country',
    'email',
    'phone',
    'nationality',
    'honorific',
    'company',
    'metadata',
  ];
  const newObj = {};
  fields.forEach((key) => {
    if (key in values) newObj[key] = values[key];
  });
  return newObj;
};

export const idFormValues = (values) => {
  const fields = [SSN, SIN, TIN, NIN, PASSPORT_NUMBER, DRIVERS_LICENSE, IDENTIFICATION_CARD_NUMBER];
  const newObj = {};
  fields.forEach((key) => {
    if (key in values) newObj[key] = values[key];
  });
  return newObj;
};

export const errorMessage = (error, msg) => {
  if (!error) return msg;
  const errorText = dig(error, 'graphQLErrors', 0, 'message') || error;
  return errorText.toString() || msg.toString();
};
