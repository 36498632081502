import { decorate, observable, runInAction, action, computed } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';

class AccountStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
  }

  /** Configuration info for each account status */
  statuses = {
    ACTIVE: {
      actionText: 'Activate Account',
      possibleTransitions: ['SUSPENDED', 'TERMINATED'],
    },
    SUSPENDED: {
      actionText: 'Suspend Account',
      possibleTransitions: ['ACTIVE', 'TERMINATED'],
    },
    TERMINATED: {
      actionText: 'Terminate Account',
      possibleTransitions: [],
    },
  };

  // values
  token: string = '';
  account_number: string = '';
  routing_number: string = '';
  user_token: string = '';
  state: string = '';
  allow_immediate_credit: boolean = false;
  created_time: string = '';
  last_modified_time: string = '';

  // actions
  async hydrate(token) {
    const result = await this.gqlQuery(
      `query directDepositAccount($token: ID!) {
          directDepositAccount(token: $token) {
            token
            user_token
            business_token
            account_number
            routing_number
            state
            allow_immediate_credit
            created_time
            last_modified_time
          }
        }
        `,
      {
        token,
        ...this.hydrateParams,
      }
    );

    runInAction(() => {
      if (result) {
        const accountInfo = this.extract(result, 'directDepositAccount');
        this.load(accountInfo);
        return true;
      } else {
        return false;
      }
    });
  }

  async changeStatus(params) {
    this.loading = true;

    const payload = {
      account_token: this.token,
      ...params,
    };

    const result = await this.gqlMutation(
      `mutation createDirectDepositAccountTransition (
          $account_token: ID!
          $state: String!
          $reason: String
        ) {
          createDirectDepositAccountTransition(
            account_token: $account_token
            state: $state
            reason: $reason
          ) {
            token
            state
            channel
          }
        }
        `,
      payload
    );

    return runInAction(async () => {
      try {
        if (!result) {
          throw this.error || 'This form could not be submitted because of an API error.';
        }
        await this.hydrate(this.token);
      } catch (e) {
        throw this.error || e;
      } finally {
        this.loading = false;
      }
    });
  }

  // computed
  get lastFour() {
    if (!this.account_number) return null;
    return this.account_number.slice(this.account_number.length - 4);
  }
  get statusTransitionOptions() {
    return this.dig(this.statuses, this.state, 'possibleTransitions') || [];
  }

  get statusTransitionActions() {
    const options = this.statusTransitionOptions;
    return options.map((transitionOption) => {
      return {
        text: this.statuses[transitionOption].actionText,
        defaultStatus: transitionOption,
      };
    });
  }
}

decorate(AccountStore, {
  // values
  token: observable,
  account_number: observable,
  routing_number: observable,
  user_token: observable,
  state: observable,
  allow_immediate_credit: observable,
  created_time: observable,
  last_modified_time: observable,
  loading: observable,

  // actions
  hydrate: action.bound,
  changeStatus: action.bound,

  // computed
  lastFour: computed,
  statusTransitionOptions: computed,
  statusTransitionActions: computed,
});

export default AccountStore;
