import { FlipFlop } from '../../index';
import hasRoleForSelectedProgram from '../../helpers/hasRoleForSelectedProgram.js';
import { isAchProgramBankTransfersEnabled } from '../../ach.js';
import { UAM_GRANULAR_PERMISSIONS } from '@mqd/mqd-constants';

export const requirements = {
  flipFlopRequirements: ['ach-program-funding-beta'],
  requiredUserPermissions: ['aux-create-acho-transfer'],
  requiredGranularPermissions: [UAM_GRANULAR_PERMISSIONS.ACH_PROGRAM_FUNDING_VIEW_AND_EDIT],
};

export const verifier = () => {
  if (!isAchProgramBankTransfersEnabled()) {
    return false;
  }

  if (hasRoleForSelectedProgram(UAM_GRANULAR_PERMISSIONS.UAM_GRANULAR_PERMISSIONS)) {
    return hasRoleForSelectedProgram(UAM_GRANULAR_PERMISSIONS.ACH_PROGRAM_FUNDING_VIEW_AND_EDIT);
  }

  return (
    // Note: We don't need to check for the 'ach-program-funding-beta' feature flag anymore.
    //       The feature flag was released awhile back. A clean up ticket for removing the
    //       feature flag is https://marqeta.atlassian.net/browse/SPD-1004
    FlipFlop.get('ach-program-funding-beta', false) &&
    hasRoleForSelectedProgram('aux-create-acho-transfer')
  );
};
