export function camelCase(string = '', optionsOverride) {
  const options = {
    fromSnake: false,
    fromWord: true,
    ...optionsOverride,
  };
  let splitString;
  if (options.fromSnake) {
    splitString = string.split('_');
  } else {
    splitString = string.split(' ');
  }
  return splitString.reduce((acc, word, i) => `${acc}${i === 0 ? word : capitalize(word)}`);
}

export function capitalize(string = '') {
  return `${string.substr(0, 1).toUpperCase()}${string.substr(1).toLowerCase()}`;
}

export const createNetRefIdForToast = (netRefId) => {
  if (netRefId) {
    return `Network Ref. ID ${netRefId.substr(0, 6)} ${String.fromCharCode(
      9679
    )}${String.fromCharCode(9679)} ${netRefId.substr(netRefId.length - 4, netRefId.length - 1)}`;
  }

  return '';
};

export const maskHashId = (hashId) => `••${hashId.substr(hashId.length - 4, hashId.length - 1)}`;

export function constantToDashCase(string) {
  return string.toLowerCase().replace(/_/g, '-');
}

export function dashCaseToConstant(string) {
  return string.toUpperCase().replace(/-/g, '_');
}

export const formatApiEnum = (val) => {
  if (!val) return null;

  return val.split('_').join(' ');
};
