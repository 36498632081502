import md5 from 'blueimp-md5';
import ParentApi from '../ParentApi.js';
import gqlApi from '../GqlApi';
import {
  DELETE_REPORT,
  EDIT_REPORT,
  FIND_OR_CREATE_USER,
  GET_REPORT,
  GET_USER,
  SAVE_REPORT,
  UPDATE_DEFAULT_REPORT,
} from './constants';

class PersistApi extends ParentApi {
  // mutations
  findOrCreateUser = async (vars) => {
    // hash email for storage in DB
    if (vars && vars.email) {
      vars.email = md5(vars.email);
    }

    const result = await gqlApi.gqlMutation(FIND_OR_CREATE_USER, vars, undefined, undefined, false);
    return result.data.findOrCreatePersistUser;
  };

  updateDefaultReport = async (vars) => {
    const result = await gqlApi.gqlMutation(
      UPDATE_DEFAULT_REPORT,
      vars,
      undefined,
      undefined,
      false
    );
    return result.data.updateDefaultDivaReport;
  };

  saveReport = async (vars) => {
    const result = await gqlApi.gqlMutation(SAVE_REPORT, vars, undefined, undefined, false);
    return result.data.addDivaReport;
  };

  editReport = async (vars) => {
    const result = await gqlApi.gqlMutation(EDIT_REPORT, vars, undefined, undefined, false);
    return result.data.editDivaReport;
  };

  deleteReport = async (vars) => {
    const result = await gqlApi.gqlMutation(DELETE_REPORT, vars, undefined, undefined, false);
    return result.data;
  };

  // queries
  getReport = async (vars) => {
    const result = await gqlApi.gqlQuery(GET_REPORT, vars, undefined, undefined, false);
    return result.data.divaReport;
  };

  getUser = async (vars) => {
    const result = await gqlApi.gqlQuery(GET_USER, vars, undefined, undefined, false);
    return result.data.persistUser;
  };
}

const persistApi = new PersistApi();
export default persistApi;
