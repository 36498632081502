import React, { useCallback, useMemo, useState } from 'react';
import { TableFilters } from '@mq/voltron-table';

import { PROGRAM_TYPES } from '../../../utilities/dictionary/index.js';

import {
  makeToggleClick,
  makeBulkToggleClick,
} from '../../../utilities/make-filter-option-click.js';

const PROGRAM_TYPES_READABLE = new Map();
for (const [api, readable] of Object.entries(PROGRAM_TYPES)) {
  PROGRAM_TYPES_READABLE.set(readable, api);
}
const programTypeToReadable = (programType) => PROGRAM_TYPES[programType] || programType;
const programTypeReadableToType = (readable) => PROGRAM_TYPES_READABLE.get(readable) || readable;

function Filters({
  allBanks,
  allPrograms,
  allProgramTypes,
  initialValues = {},
  refetch,
  testId = 'settled-date-queue',
}) {
  // Compute ID <-> readable option mappings for banks and programs.
  const [bankTokenToName, bankNameToToken, allBankNames] = useMemo(() => {
    const tokenToName = new Map();
    const nameToToken = new Map();
    for (const bank of allBanks) {
      tokenToName.set(bank.token, bank.name);
      nameToToken.set(bank.name, bank.token);
    }
    return [
      (token) => tokenToName.get(token) || token,
      (name) => nameToToken.get(name) || name,
      Array.from(nameToToken.keys()),
    ];
  }, [allBanks]);
  const [programShortCodeToName, programNameToShortCode, allProgramNames] = useMemo(() => {
    const shortCodeToProgram = new Map();
    const programToShortCode = new Map();
    for (const program of allPrograms) {
      shortCodeToProgram.set(program.short_name, program.program);
      programToShortCode.set(program.program, program.short_name);
    }
    return [
      (shortCode) => shortCodeToProgram.get(shortCode) || shortCode,
      (program) => programToShortCode.get(program) || program,
      Array.from(programToShortCode.keys()),
    ];
  }, [allPrograms]);
  const allProgramTypesReadable = useMemo(
    () => allProgramTypes.map((t) => PROGRAM_TYPES[t] || t),
    [allProgramTypes]
  );

  // States.
  const [bankTokens, setBankTokens] = useState(() => new Set(initialValues.dna_bank_tokens || []));
  const [programShortCodes, setProgramShortCodes] = useState(
    () => new Set(initialValues.program_short_codes || [])
  );
  const [programTypes, setProgramTypes] = useState(
    () => new Set(initialValues.program_types || [])
  );

  // Actions.
  const handleClear = useCallback(() => {
    setBankTokens(new Set());
    setProgramShortCodes(new Set());
    setProgramTypes(new Set());
    refetch({
      dna_bank_tokens: undefined,
      program_short_codes: undefined,
      program_types: undefined,
    });
  }, [refetch]);

  const handleSearch = () => {
    refetch({
      dna_bank_tokens: bankTokens.size ? Array.from(bankTokens) : undefined,
      program_short_codes: programShortCodes.size ? Array.from(programShortCodes) : undefined,
      program_types: programTypes.size ? Array.from(programTypes) : undefined,
    });
  };

  // Filter options.
  const programTypeFilterOption = useMemo(
    () => ({
      filterParam: 'programTypes',
      label: 'Program type',
      placeholder: 'Select',
      type: 'multiselect',
      onOptionClick: makeToggleClick(setProgramTypes, programTypeReadableToType),
      onBulkToggleClick: makeBulkToggleClick(
        programTypes,
        setProgramTypes,
        allProgramTypesReadable,
        programTypeReadableToType
      ),
      selected: Array.from(programTypes, programTypeToReadable),
      options: allProgramTypesReadable,
    }),
    [programTypes, allProgramTypesReadable]
  );

  const programFilterOption = useMemo(
    () => ({
      filterParam: 'programs',
      label: 'Program',
      placeholder: 'Select',
      type: 'multiselect',
      onOptionClick: makeToggleClick(setProgramShortCodes, programNameToShortCode),
      onBulkToggleClick: makeBulkToggleClick(
        programShortCodes,
        setProgramShortCodes,
        allProgramNames,
        programNameToShortCode
      ),
      selected: Array.from(programShortCodes, programShortCodeToName),
      options: allProgramNames,
    }),
    [programShortCodes, allProgramNames, programNameToShortCode, programShortCodeToName]
  );

  const bankFilterOption = useMemo(
    () => ({
      filterParam: 'banks',
      label: 'Bank',
      placeholder: 'Select',
      type: 'multiselect',
      onOptionClick: makeToggleClick(setBankTokens, bankNameToToken),
      onBulkToggleClick: makeBulkToggleClick(
        bankTokens,
        setBankTokens,
        allBankNames,
        bankNameToToken
      ),
      selected: Array.from(bankTokens, bankTokenToName),
      options: allBankNames,
    }),
    [bankTokens, allBankNames, bankNameToToken, bankTokenToName]
  );

  const filters = [programTypeFilterOption, programFilterOption, bankFilterOption];

  return (
    <TableFilters
      handleClear={handleClear}
      handleSearch={handleSearch}
      filterOptions={filters}
      testId={testId}
      type="large"
      showActiveFilterCountOnMount
      fetchOnClear={false}
    />
  );
}

export default Filters;
