// @flow
import { action, decorate, observable } from 'mobx';
import ParentStore from './ParentStore.js';
import currentUserStore from 'stores/CurrentUserStore';
import notificationStore from 'stores/NotificationStore';
import janusApi from 'apis/JanusApi';

export default class TokenStore extends ParentStore {
  // values
  dateCreated: ?number = null;
  dateLastUsed: ?number = null;
  dateUpdated: ?number = null;
  durableAccessTokenId: ?string = null;
  durableAccessTokenType: ?string = null;
  email: string = '';
  expireDays: ?number = null;
  id: ?number = null;
  note: string = '';
  status: string = '';
  loading: boolean = false;

  // actions
  deleteToken = async function () {
    const { userStore } = currentUserStore;
    const res = this.tokenActionWrap('revoke', 'Deleted');
    userStore.removeFromLocalStorage('apiTokens');
    return res;
  };

  disableToken = async function () {
    return this.tokenActionWrap('disable', 'Disabled');
  };

  enableToken = async function () {
    return this.tokenActionWrap('enable', 'Active');
  };

  tokenActionWrap = async function (action: string, newStatus: string) {
    try {
      this.loading = true;
      const data = {
        access_token_id: this.durableAccessTokenId,
      };
      const response = await janusApi.postData(`/diva/security/tokenpair/${action}`, data);
      if (response) {
        this.status = newStatus;
      }
    } catch (error) {
      notificationStore.notify('error', 'Error: Could not access token management api');
    } finally {
      this.loading = false;
    }
    return false;
  };

  saveNoteEdit = async function () {
    try {
      this.loading = true;
      const data = {
        access_token_id: this.durableAccessTokenId,
        note: this.noteEdit,
      };
      const response = await janusApi.putData('/diva/security/tokenpair/note', data);
      if (response) {
        this.note = this.noteEdit;
      }
    } catch (error) {
      notificationStore.notify('error', 'Error: Could not access token management api');
    } finally {
      this.loading = false;
    }
  };
}

decorate(TokenStore, {
  // values
  dateCreated: observable,
  dateLastUsed: observable,
  dateUpdated: observable,
  durableAccessTokenId: observable,
  durableAccessTokenType: observable,
  email: observable,
  expireDays: observable,
  id: observable,
  note: observable,
  noteEdit: observable,
  status: observable,
  loading: observable,

  // actions
  tokenActionWrap: action.bound,
  saveNoteEdit: action.bound,
  deleteToken: action.bound,
  disableToken: action.bound,
  enableToken: action.bound,
});
