export const reasonCodes = [
  {
    val: '00',
    render: 'Object activated for the first time',
  },
  {
    val: '01',
    render: 'Requested by you',
  },
  {
    val: '02',
    render: 'Inactivity over time',
  },
  {
    val: '03',
    render: 'This address cannot accept mail or the addressee is unknown',
  },
  {
    val: '04',
    render: 'Negative account balance',
  },
  {
    val: '05',
    render: 'Account under review',
  },
  {
    val: '06',
    render: 'Suspicious activity was identified',
  },
  {
    val: '07',
    render: 'Activity outside the program parameters was identified',
  },
  {
    val: '08',
    render: 'Confirmed fraud was identified',
  },
  {
    val: '09',
    render: 'Matched with an Office of Foreign Assets Control list',
  },
  {
    val: '10',
    render: 'Card was reported lost',
  },
  {
    val: '11',
    render: 'Card information was cloned',
  },
  {
    val: '12',
    render: 'Account or card information was compromised',
  },
  {
    val: '13',
    render: 'Temporary status change while on hold/leave',
  },
  {
    val: '14',
    render: 'Initiated by Marqeta',
  },
  {
    val: '15',
    render: 'Initiated by issuer',
  },
  {
    val: '16',
    render: 'Card expired',
  },
  {
    val: '17',
    render: 'Failed KYC',
  },
  {
    val: '18',
    render: 'Changed to ACTIVE because information was properly validated',
  },
  {
    val: '19',
    render: 'Changed to ACTIVE because account activity was properly validated',
  },
  {
    val: '20',
    render: 'Change occurred prior to the normalization of reason codes',
  },
  {
    val: '21',
    render: 'Initiated by a third-party, often a digital wallet provider',
  },
  {
    val: '22',
    render: 'PIN retry limit reached',
  },
  {
    val: '23',
    render: 'Card was reported stolen',
  },
  {
    val: '24',
    render: 'Address Issue',
  },
  {
    val: '25',
    render: 'Name Issue',
  },
  {
    val: '26',
    render: 'SSN Issue',
  },
  {
    val: '27',
    render: 'DOB Issue',
  },
  {
    val: '28',
    render: 'Email Issue',
  },
  {
    val: '29',
    render: 'Phone Issue',
  },
  {
    val: '30',
    render: 'Account/Fulfillment Mismatch',
  },
  {
    val: '31',
    render: 'Other',
  },
];

export const transitionColorApp = {
  ACTIVE: {
    background: '#EDFAEB',
    color: '#359227',
    border: '#1E5416',
  },
  LIMITED: {
    background: '#E6F3FC',
    color: '#065C9F',
    border: '#065C9F',
  },
  UNVERIFIED: {
    background: '#EFEFF2',
    color: '#5F5F7A',
    border: '#5F5F7A',
  },
  SUSPENDED: {
    background: '#FFFBEA',
    color: '#665413',
    border: '#B29422',
  },
  CLOSED: {
    background: '#FBEAEA',
    color: '#962222',
    border: '#962222',
  },
  TERMINATED: {
    background: '#FBEAEA',
    color: '#962222',
    border: '#962222',
  },
};

export const invalidExpirationDate = 'Invalid expiration date';
export const invalidExpirationDateFormat = 'Invalid date format, use YYYY-MM-DD';

// states
export const UNACTIVATED = 'UNACTIVATED';
export const ACTIVE = 'ACTIVE';
export const SUSPENDED = 'SUSPENDED';
export const TERMINATED = 'TERMINATED';
export const REQUESTED = 'REQUESTED';
export const REQUEST_DECLINED = 'REQUEST_DECLINED';

// actions
export const UNLOCK = 'Unlock';
export const LOCK = 'Lock';
export const TERMINATE = 'Terminate';

// instrument types
export const VIRTUAL_PAN = 'VIRTUAL_PAN';
export const PHYSICAL_MSR = 'PHYSICAL_MSR';

// statuses
export const SUCCESS = 'SUCCESS';
export const CLOSED = 'CLOSED';

// cardholder status modal
export const statusModalHeading = {
  Active: 'Activate',
  Suspended: 'Suspend',
  Closed: 'Terminate',
  Limited: 'Limit',
  Unverified: 'Unverify',
};

export const statusActiveTense = {
  Active: 'Activatation',
  Suspended: 'Suspension',
  Closed: 'Termination',
  Limited: 'Limiting',
  Unverified: 'Unverifying',
};
