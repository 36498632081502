import React from 'react';
import { Icon } from '@mqd/volt-base';

import s from './NotificationList.module.css';

const NotificationBell = ({ notifications = [], setNotificationsShown }) => {
  const unreadNotifications = notifications.filter((un) => !un.isRead);

  const overrideColor = unreadNotifications && unreadNotifications.length && '#1E1672';
  let notificationsCounter = unreadNotifications.length;
  let classnames = s.notifications__bell__counter;

  if (unreadNotifications.length > 9) {
    notificationsCounter = '9+';
    classnames += ` ${s.plus}`;
  }

  return (
    <div className={s.notifications__bell} data-testid="notifications-bell">
      <Icon
        type="alert-24"
        overrideColor={overrideColor}
        noHoverEffects
        onClick={() => setNotificationsShown(true)}
      />
      {!!unreadNotifications.length && (
        <div className={classnames} onClick={() => setNotificationsShown(true)}>
          {notificationsCounter}
        </div>
      )}
    </div>
  );
};

export default NotificationBell;
