import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import s from './EmptyTableState.module.css';
import { Col, Text } from '@mqd/volt-base';

function EmptyTableState({ testId, header, bodyText, style }) {
  const [ref, setRef] = useState(null);
  const [height, setHeight] = useState(null);

  const calcHeight = () => {
    try {
      const winHeight = window.innerHeight;
      const { y } = ref.getBoundingClientRect();
      const newHeight = winHeight - y - 48;
      if (newHeight) {
        setHeight(Math.max(newHeight, 144));
      }
    } catch (e) {
      // do nothing
    }
  };

  useEffect(calcHeight, [ref]);
  useEffect(() => {
    window.addEventListener('resize', calcHeight);
    return () => {
      window.removeEventListener('resize', calcHeight);
    };
  });

  return (
    <span ref={(ref) => setRef(ref)} data-testid={testId}>
      <Col style={{ height, ...style }} className={s.emptyState}>
        <div>
          <Text type="h3">{header}</Text>
          <Text type="body-lg">{bodyText}</Text>
        </div>
      </Col>
    </span>
  );
}

EmptyTableState.propTypes = {
  testId: PropTypes.string,
  header: PropTypes.string,
  bodyText: PropTypes.any,
  style: PropTypes.object,
};

EmptyTableState.defaultProps = {
  testId: 'empty-table-state',
  header: '',
  bodyText: 'No results found',
  style: {},
};

export default observer(EmptyTableState);
