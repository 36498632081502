export const cancelReasons = [
  'Duplicative Transaction',
  'End User changed his/her Mind',
  'Erroneous Transaction made by End User',
  'Erroneous Transaction due to System Issue',
  'Insufficient Funds',
  'Incorrect Destination Account (funds to be sent to)',
  'Incorrect Source Account (funds to be pulled from)',
  'Unauthorized Transaction reported by End User',
  'Unauthorized Transaction reported by MQ Customer',
  'Unauthorized Transaction reported by MQ Partner Bank',
  'Unauthorized Transaction reported by Third Party',
  'Unusual Activity reported by MQ Internal Risk Monitoring',
  'Unusual Activity reported by End User',
  'Unusual Activity reported by MQ Customer',
  'Unusual Activity reported by MQ Partner Bank',
  'Unusual Activity reported by Third Party',
];
