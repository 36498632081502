import { decorate, observable, action, computed } from 'mobx';
import ParentStore from '../ParentStore.js';
import applicationDataHash from './constants.js';

class ApplicationDataStore extends ParentStore {
  applicationData: Object = {};

  mapApplicationDataToRoutes = (applicationData) =>
    Object.entries(applicationDataHash).reduce((acc, [key, value]) => {
      acc[value] = applicationData[key];
      return acc;
    }, {});

  setApplicationData(data) {
    const mappedData = this.mapApplicationDataToRoutes(data);
    this.applicationData = mappedData;
  }

  // computed
  get applicationDataHash() {
    try {
      return this.hashString(JSON.stringify(this.applicationData));
    } catch (error) {
      return '';
    }
  }
}

decorate(ApplicationDataStore, {
  // observables
  applicationData: observable,

  // actions
  setApplicationData: action.bound,

  // computed
  applicationDataHash: computed,
});

const applicationDataStore = new ApplicationDataStore();
export { ApplicationDataStore as ApplicationDataStoreClass };

export default applicationDataStore;
