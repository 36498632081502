import { useQuery } from '@apollo/react-hooks';

import { useProgramSelectorDispatchContext, useProgramSelectorStateContext } from './context.js';
import { saveActiveProgramInfo } from './utils.js';

import { FETCH_CURRENT_DNA_PROGRAM, FETCH_PROGRAM_CONFIGS } from './queries.js';
import {
  SELECT_ACTIVE_PROGRAM_CONFIGS,
  SELECT_ACTIVE_PROGRAM_CONFIGS_KEY,
  SELECT_ACTIVE_PROGRAM_DNA,
  SELECT_ACTIVE_PROGRAM_DNA_KEY,
} from './constants.js';

export default function useLoadActiveProgramInfo() {
  const dispatch = useProgramSelectorDispatchContext();
  const { activeProgram } = useProgramSelectorStateContext();
  const activeProgramShortCode = activeProgram?.short_name;

  const dna = useQuery(FETCH_CURRENT_DNA_PROGRAM, {
    skip: !activeProgramShortCode,
    onCompleted: (data) => {
      const { currentDNAProgram } = data || {};
      if (currentDNAProgram) {
        dispatch({
          type: SELECT_ACTIVE_PROGRAM_DNA,
          payload: currentDNAProgram,
        });
        saveActiveProgramInfo(
          activeProgramShortCode,
          SELECT_ACTIVE_PROGRAM_DNA_KEY,
          currentDNAProgram
        );
      }
    },
  });

  const programConfigs = useQuery(FETCH_PROGRAM_CONFIGS, {
    skip: !activeProgramShortCode,
    onCompleted: (data) => {
      const { programConfigs } = data || {};
      if (programConfigs) {
        dispatch({
          type: SELECT_ACTIVE_PROGRAM_CONFIGS,
          payload: programConfigs,
        });
        saveActiveProgramInfo(
          activeProgramShortCode,
          SELECT_ACTIVE_PROGRAM_CONFIGS_KEY,
          programConfigs
        );
      }
    },
  });

  // for testing purposes
  return { dna, programConfigs };
}
