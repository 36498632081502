import gql from 'graphql-tag';

export const CREATE_ADJUSTMENT = gql`
  mutation createTransactionHashExceptionCommand(
    $tx_hash_internal_id: String!
    $action: TransactionTokenAdjustmentCommandActionEnum!
    $transaction_hash_exception_adjustment: TransactionHashExceptionCommandInput!
    $reference_id: String
  ) {
    createTransactionHashExceptionCommand(
      tx_hash_internal_id: $tx_hash_internal_id
      action: $action
      transaction_hash_exception_adjustment: $transaction_hash_exception_adjustment
      reference_id: $reference_id
    ) {
      status
      statusText
      message
      ok
    }
  }
`;
