// @flow
import { decorate, observable, action, runInAction, computed } from 'mobx';
import gqlUtils from '@mqd/graphql-utils';
import CardStore from './../stores/CardStore';
import { ParentTableStore } from '@mq/voltron-parent';
import { getCardOrCardholderQuery } from '../shared-utils/index';

const { fragments } = gqlUtils;

export default class CardsTableStore extends ParentTableStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
  }
  // values
  items: Array<CardStore> = [];
  count: number = 100;
  token: string = '';

  // actions
  async hydrate() {
    // don't make request unless there is cardholder_token
    if (!this.cardholder_token) {
      console.error('No cardholder token defined in CardsTableStore');
      return;
    }
    this.loading = true;
    const result = await this.gqlQuery(
      `
        query ${this.cardsQuery}($cardholder_token: ID! ${this.paginationParamsOuter}) {
          ${this.cardsQuery}(cardholder_token: $cardholder_token, ${this.paginationParamsInner}) {
            data {
              ...cardBaseInfo
              final_fulfillment { shipping {
                recipient_address { address1 address2 city 
                  state postal_code country zip } } }
            }
            ${this.paginationAttributes}
          }
        }
        ${fragments.cardBaseInfo}
      `,
      Object.assign(this.hydrateParams, {
        cardholder_token: this.cardholder_token,
      })
    );

    runInAction(() => {
      const cards = this.extract(result, this.cardsQuery);
      this.processPaginationResponse(cards, CardStore);
      this.loading = false;
    });
  }

  get cardsQuery() {
    return getCardOrCardholderQuery(
      'cards',
      'cardsByCardProduct',
      localStorage.getItem('userOrgName'),
      JSON.parse(localStorage.getItem('redseaRoles'))
    );
  }
}

decorate(CardsTableStore, {
  // values
  items: observable,
  count: observable,
  sort_by: observable,
  token: observable,

  // actions
  hydrate: action.bound,

  // computed
  cardsQuery: computed,
});
