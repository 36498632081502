import { useContext, useEffect } from 'react';
import { runInAction } from 'mobx';
import { TransactionHistoryStoreContext } from '../../../../../../../context/TransactionHistoryStoreContext.js';
import { fetchTransactionHistory } from '../fetch-utils.js';
import { ITEMS_PER_PAGE } from '../../../constants.js';

export function useFetchHistory() {
  const {
    history,
    setHistory,
    loading,
    setLoading,
    pageIndex,
    isMore,
    setIsMore,
    isLess,
    sortBy,
    setSortBy,
    filterProgram,
    filterType,
    filterAmount,
    filterCollateralAmount,
    filterToken,
    filterStatus,
    filterCurrencyCode,
    filterDateRange,
    error,
    setError,
    goNextPage,
    goPrevPage,
    getInitialFetchWasMade,
    setInitialFetchWasMade,
    setExportNotAvailable,
  } = useContext(TransactionHistoryStoreContext);

  useEffect(() => {
    // Don't fetch records while we're selecting dates
    if (!filterDateRange[0] || !filterDateRange[1]) {
      return;
    }

    setLoading(true);

    fetchTransactionHistory({
      count: ITEMS_PER_PAGE,
      start_index: ITEMS_PER_PAGE * pageIndex,
      sort_by: sortBy,
      program: filterProgram,
      type: filterType,
      amount: filterAmount,
      collateral_amount: filterCollateralAmount,
      token: filterToken,
      status: filterStatus,
      currency_code: filterCurrencyCode,
      dateRange: filterDateRange,
    })
      .then((gqlData) => {
        const {
          data: {
            programReserveTransactions: { data, is_more },
          },
        } = gqlData;

        runInAction(() => {
          setError(null);
          setHistory(data);
          setIsMore(is_more);

          // In this case we want to disable the Export button.
          // To achieve this goal, the "exportNotAvailable" must be set once on the first data fetch,
          // when pagination and filters have not been used yet.
          // In case if there is no data on the first fetch,
          // we conclude that there is no data at and make the button inactive.
          if (!getInitialFetchWasMade()) {
            setExportNotAvailable(data.length === 0);
            setInitialFetchWasMade(true);
          }
        });
      })
      .catch(setError)
      .finally(() => setLoading(false));
  }, [
    pageIndex,
    setError,
    setHistory,
    setIsMore,
    setLoading,
    sortBy,
    filterProgram,
    filterType,
    filterAmount,
    filterCollateralAmount,
    filterToken,
    filterStatus,
    filterCurrencyCode,
    filterDateRange,
    setExportNotAvailable,
    setInitialFetchWasMade,
    getInitialFetchWasMade,
  ]);

  return {
    history,
    loading,
    isMore,
    isLess,
    goNextPage,
    goPrevPage,
    sortBy,
    setSortBy,
    error,
  };
}
