import { decorate, observable } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';

class GpaOrderStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
  }
  // values
  memo: string = '';
  token: string = '';
  amount: string = '';
  tags: string = '';
  currency_code: string = '';
  funding_source_token: string = '';
  cardholder_token: string = '';
}

decorate(GpaOrderStore, {
  memo: observable,
  token: observable,
  amount: observable,
  tags: observable,
  currency_code: observable,
  funding_source_token: observable,
  cardholder_token: observable,
});

export default GpaOrderStore;
