import gql from 'graphql-tag';

export const GET_EXPORTED_DATA_STATUSES = gql`
  query getExportedDataStatuses($user_emails: [String!]) {
    exportedDataStatuses(user_emails: $user_emails) {
      status
      filename
      user_email
      date_requested
      export_type
      request_info {
        subnetwork
        program_types
        program_short_codes
        dna_bank_tokens
        settlement_currencies
        start_settlement_date
        end_settlement_date
        exception_type
      }
    }
  }
`;

export const GET_USER_EMAILS = gql`
  query getUserEmailsWithExports {
    exportedDataStatuses {
      user_email
    }
  }
`;

export const GET_EXPORT_DOWNLOAD_URL = gql`
  query getExportDownloadUrl($filename: String!, $download_name: String) {
    exportDownloadUrl(filename: $filename, download_name: $download_name)
  }
`;
