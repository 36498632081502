import { UAM_GRANULAR_PERMISSIONS } from '@mqd/mqd-constants';
import currentUserStore from 'stores/CurrentUserStore';
import {
  hasDeveloperRole,
  hasNoRoles,
  isJanusUser,
  hasTokenizationManagerPermissions,
} from './functions.js';

const { BUSINESS_DETAILS_VIEW_AND_EDIT, BUSINESS_DETAILS_VIEW } = UAM_GRANULAR_PERMISSIONS;

export const requirements = {
  flipFlopRequirements: ['unify-beta'],
};

export const verifier = () => {
  const { userStore = {} } = currentUserStore;

  const hasBussinessDetailsViewAndEdit = userStore.redseaRoles.some((r) =>
    [BUSINESS_DETAILS_VIEW_AND_EDIT, BUSINESS_DETAILS_VIEW].includes(r)
  );

  const hasPermissions =
    (userStore.unifyActive &&
      ((!hasNoRoles(userStore) && !hasDeveloperRole(userStore)) || isJanusUser(userStore))) ||
    hasTokenizationManagerPermissions(userStore, 'program/businesses');

  return userStore.uamGranularPermissionsActive ? hasBussinessDetailsViewAndEdit : hasPermissions;
};
