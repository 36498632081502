import React from 'react';
import s from './CardProductsHero.module.css';
import { MarqetaCard, Text, Link, Card, HSpacer, Icon } from '@mqd/volt-base';
import { heroBodyText, docsLink } from './constants';
function CardProductsHero({ handleClose }) {
  const cursor = { cursor: 'pointer' };
  const linkIconStyle = { position: 'relative', top: '7.5px' };
  return (
    <div className={s.flexDiv}>
      <MarqetaCard />
      {/* because of padding around MarqetaCard customSpacer is needed to fit grid */}
      <div className={s.customSpacer} />
      <div>
        <div className={s.spaceBetween}>
          <Text type="h4">Welcome to card products</Text>
          <Icon type="close" mod="light" factor={0.5} onClick={handleClose} style={cursor} />
        </div>
        <Text type="body-lg">{heroBodyText}</Text>
        <div
          style={cursor}
          onClick={() => window.open(docsLink, '_blank')}
          data-testid="card-products-hero_docs-link"
        >
          <Link inline>Learn more on docs</Link>
          <Icon type="chevron-right" factor={0.5} overrideColor="#3C33B2" style={linkIconStyle} />
        </div>
      </div>
    </div>
  );
}

export default CardProductsHero;
