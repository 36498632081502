import React from 'react';
import { DismissableBanner } from '../components/index.js';

const LOCAL_STORAGE_KEY = 'development-webhooks-banner-dismissed';

const WebhooksBanner = () => (
  <DismissableBanner
    LOCAL_STORAGE_KEY={LOCAL_STORAGE_KEY}
    icon="single-use-active"
    heading="Keep on top of what's happening"
    description="The Marqeta platform supports webhooks, which are real-time notifications of specific events that occur within your program integration. To learn more about webhooks, read our guide."
    linkURL="https://www.marqeta.com/docs/core-api/webhooks-management"
    linkText="Learn about webhooks"
  />
);

export { WebhooksBanner };
