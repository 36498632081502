import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import TransactionsTable from '../transactions-table/TransactionsTable';
import currentUserStore from 'stores/CurrentUserStore';
class TransactionsTab extends Component {
  render() {
    const {
      storeInitArgs,
      onStoreConstruction,
      localStorageKey,
      loading,
      goToDigitalWalletToken,
      goToNewDispute,
      goToTransaction,
      canAccessDisputes,
    } = this.props;
    const { hasRiskInternalRole, hasProgramAdminRole } = currentUserStore?.disputesAccess || {};
    return (
      <>
        <TransactionsTable
          loading={loading}
          storeInitArgs={storeInitArgs}
          onStoreConstruction={onStoreConstruction}
          localStorageKey={localStorageKey}
          goToDigitalWalletToken={goToDigitalWalletToken}
          goToNewDispute={goToNewDispute}
          goToTransaction={goToTransaction}
          canAccessDisputes={canAccessDisputes}
          hasInternalRole={hasRiskInternalRole}
          hasProgramAdminRole={hasProgramAdminRole}
        />
      </>
    );
  }
}

TransactionsTab.propTypes = {
  storeInitArgs: PropTypes.object,
  onStoreConstruction: PropTypes.func,
  localStorageKey: PropTypes.string,
  goToDigitalWalletToken: PropTypes.func,
  goToNewDispute: PropTypes.func,
  canAccessDisputes: PropTypes.bool,
};

TransactionsTab.defaultProps = {
  storeInitArgs: {},
  onStoreConstruction: () => {},
  localStorageKey: '',
  goToDigitalWalletToken: () => {},
  goToNewDispute: () => {},
  canAccessDisputes: false,
};

export default observer(TransactionsTab);
