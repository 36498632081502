import { decorate, observable, action } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';
import BalanceStore from './BalanceStore';
import LinkStore from './LinkStore';

class GpaBalanceStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
  }
  // values
  gpa: BalanceStore = {};
  links: Array<LinkStore> = {};

  // actions
  loadAndConstruct(args) {
    this.gap = args.gpa ? new BalanceStore(args.gpa) : {};
    this.loadAndConstructList('links', args.links, LinkStore);
  }
}

decorate(GpaBalanceStore, {
  // values
  gpa: observable,
  links: observable,

  // actions
  loadAndConstruct: action.bound,
});

export default GpaBalanceStore;
