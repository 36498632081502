const getEventParents = (evt) => {
  let eventParents = evt.path;
  if (!eventParents) {
    let path = [];
    let currentElem = evt.target;
    while (currentElem) {
      path.push(currentElem);
      currentElem = currentElem.parentElement;
    }
    if (path.indexOf(window) === -1 && path.indexOf(document) === -1) {
      path.push(document);
    }
    if (path.indexOf(window) === -1) {
      path.push(window);
    }
    eventParents = path;
  }
  return eventParents;
};

export default getEventParents;
