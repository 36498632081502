import React from 'react';
import { Button, Flex, HSpacer, Text } from '@mqd/volt-base';
import { goToBulkEntry, goToManualEntry } from '../../../../utils/navigation.js';

import s from './Header.module.css';

function Header() {
  return (
    <Flex flexDirection="row">
      <Text type="h3">Program funding</Text>
      <Flex flexDirection="row" className={s.controls} testId="program-reserve-controls">
        <Button type="tertiary" onClick={goToManualEntry}>
          Manual entry
        </Button>
        <HSpacer factor={2} />
        <Button type="tertiary" onClick={goToBulkEntry}>
          Bulk entry
        </Button>
      </Flex>
    </Flex>
  );
}

export default Header;
