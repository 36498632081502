import React, { useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import s from '../CardCreationDetail.module.css';
import AddAchFundingSourceModal from './AddAchFundingSourceModal';
import InputWithCurrency from '../commons/InputWithCurrency';
import { Text, Link, Select, VSpacer, Icon, HSpacer } from '@mqd/volt-base';

function AddFunds({
  activeFundingSource,
  gpaOrder,
  fundingSourceSelectOptions,
  addFundingSource,
  setAttr,
}) {
  const [showFundingSourceModal, toggleFundingSourceModal] = useState(false);
  const { amount, setAttr: setGpaOrderAttr } = gpaOrder;
  const handleSelect = (fs) => setAttr('activeFundingSource', fs.val);
  const inputWidths = 456;
  const submitFundingSource = (fundingSource) => {
    addFundingSource(fundingSource);
    toggleFundingSourceModal(false);
  };

  return (
    <div data-testid="optional-card-controls_add-funds">
      <Text type="h5">Add funds to card</Text>
      <Select
        label="Transfer funds from"
        placeholder="Select bank account"
        width={inputWidths}
        value={activeFundingSource ? activeFundingSource.name_on_account : null}
        options={fundingSourceSelectOptions}
        onChange={handleSelect}
        testId="volt-select_add-funds"
      />
      <VSpacer factor={1} />
      <Link>
        <span onClick={() => toggleFundingSourceModal(true)}>Add new bank</span>
      </Link>
      {showFundingSourceModal && (
        <AddAchFundingSourceModal
          hideModal={() => toggleFundingSourceModal(false)}
          submitFundingSource={submitFundingSource}
        />
      )}
      <VSpacer factor={2} />
      <InputWithCurrency
        label="Amount"
        placeholder="Add Value"
        width={inputWidths}
        rightAddOn={{
          content: (
            <div className={s.inputRightAddOnCurrencyFlag}>
              <Icon type="usa-flag" noHoverEffects style={{ width: 20 }} />
              <HSpacer factor={0.875} />
              <Text>USD</Text>
            </div>
          ),
        }}
        value={amount}
        onChange={(e) => setGpaOrderAttr('amount', e.target.value)}
        isError={() => (activeFundingSource ? null : 'Please select a bank to add funds')}
        testId="optional-card-controls_funding-amount"
      />
    </div>
  );
}

AddFunds.propTypes = {
  activeFundingSource: PropTypes.object,
  gpaOrder: PropTypes.object,
  fundingSourceSelectOptions: PropTypes.array,
  addFundingSource: PropTypes.func,
  setAttr: PropTypes.func,
};

AddFunds.defaultProps = {
  activeFundingSource: {},
  gpaOrder: {},
  fundingSourceSelectOptions: [],
  addFundingSource: () => {},
  setAttr: () => {},
};

export default observer(AddFunds);
