import React, { Component } from 'react';
import { Container, Row, Col, Text } from '@mqd/volt-base';
import { Status } from '@mq/voltron-parent';
import { convertNumberToCurrency, formatDateAsUTCString } from '../utils';

class TransactionDetails extends Component {
  constructor(props) {
    super(props);
  }

  get cardInfo() {
    const { card } = this.props;
    if (!card) return '';
    const { last_four, formattedExpiration } = card;
    return last_four && formattedExpiration
      ? `**${last_four} 
      Exp:  ${formattedExpiration}`
      : '';
  }

  renderTransactionDetailRow(title, content) {
    return (
      <Row>
        <Col span={4}>
          <Text type="h6">{title}</Text>
        </Col>
        <Col>{content}</Col>
      </Row>
    );
  }

  render() {
    const {
      cardholderName,
      request_amount,
      card,
      token,
      cardAcceptor,
      transactionTime,
      currencyCode,
    } = this.props;

    return (
      <Container gutter={8}>
        {this.renderTransactionDetailRow(
          'Name on card',
          <Text type="body-sm">{cardholderName}</Text>
        )}
        {this.renderTransactionDetailRow('Card', <Text type="body-sm">{this.cardInfo}</Text>)}
        {this.renderTransactionDetailRow(
          'Status',
          <div style={{ lineHeight: '24px' }}>
            <Status status={card ? card.state : null} />
          </div>
        )}
        {this.renderTransactionDetailRow('Transaction', <Text type="body-sm">{cardAcceptor}</Text>)}
        {this.renderTransactionDetailRow(
          'Amount',
          <Text type="body-sm">{convertNumberToCurrency(request_amount, currencyCode)}</Text>
        )}
        {this.renderTransactionDetailRow(
          'Date',
          <Text type="body-sm">
            {transactionTime ? formatDateAsUTCString(transactionTime) : ''}
          </Text>
        )}
        {this.renderTransactionDetailRow('ID', <Text type="body-sm">{token}</Text>)}
      </Container>
    );
  }
}

export default TransactionDetails;
