import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import DigitalWalletTokensTableStore from './DigitalWalletTokensTableStore';
import TerminateTokenModal from './TerminateTokenModal';
import { ParentTable, Status } from '@mq/voltron-parent';
import { Table, ActionsCell, ActionPlaceholder } from '@mq/voltron-table';
import { LoadingOverlay, VSpacer, ToastAlert } from '@mqd/volt-base';
import StyleContext from '../contexts/StyleContext';
import { sentenceCase, titleCase, formatUTCMoment } from './../shared-utils';
import s from './DigitalWalletTokensTable.module.css';

class DigitalWalletTokensTable extends ParentTable {
  constructor(props) {
    super(props);
    this.storeConstructor = DigitalWalletTokensTableStore;
    this.state = {
      activeStore: null,
      ...this.colEditState(props, this.columns()),
    };
    this.handleRowClick = this.handleRowClick.bind(this);
    this.actionsRenderer = this.actionsRenderer.bind(this);
  }

  simpleCell(cellValue = {}, key = '', modifier) {
    const value = cellValue ? cellValue[key] : null;
    const content = modifier instanceof Function ? modifier(value) : value;

    return <span className={content ? '' : s.noneRender}>{content ? content : 'None'}</span>;
  }

  actionsRenderer(row = {}) {
    const { configureAllowedActions } = this.store;
    const configuredAllowedActions = configureAllowedActions(row);
    const viewDetails = [
      {
        text: 'View details',
        onClick: () => this.handleRowClick({ row }),
      },
    ];
    const actions = viewDetails.concat(configuredAllowedActions);
    return <ActionsCell actions={actions} />;
  }

  handleRowClick({ row }) {
    const { goToDigitalWalletToken } = this.props;
    if (goToDigitalWalletToken instanceof Function) {
      const token = row ? row.token : null;
      token && goToDigitalWalletToken(token);
    }
  }

  columns() {
    const columns = [
      {
        header: this.headerRenderer(),
        accessor: 'token',
        key: 'actions',
        renderer: ({ row }) => this.actionsRenderer(row),
        noPadding: true,
        noResize: true,
        noRowClick: true,
        noSort: true,
        noEdit: true,
        deferRenderPlaceholder: () => <ActionPlaceholder width={32} />,
      },
      {
        header: 'Status',
        accessor: 'state',
        key: 'state',
        renderer: ({ cellValue, row }) => (
          <Status status={cellValue} onlyDisplayChildren>
            {row.formattedState}
          </Status>
        ),
      },
      {
        header: 'Token number',
        accessor: 'formattedTokenPan',
        key: 'formattedTokenPan',
      },
      {
        header: 'Token reference ID',
        key: 'token_reference_id',
        accessor: 'token_service_provider',
        renderer: ({ cellValue }) => this.simpleCell(cellValue, 'token_reference_id'),
      },
      {
        header: 'Wallet name',
        key: 'token_requestor_name',
        accessor: 'token_service_provider',
        renderer: ({ cellValue }) => this.simpleCell(cellValue, 'token_requestor_name', titleCase),
      },
      {
        header: 'Device',
        key: 'type',
        accessor: 'device',
        renderer: ({ cellValue }) => this.simpleCell(cellValue, 'type', sentenceCase),
      },
      {
        header: 'Last modified',
        accessor: 'last_modified_time',
        key: 'last_modified_time',
        renderer: ({ cellValue }) => this.simpleCell(cellValue, '', formatUTCMoment),
      },
    ];

    return columns;
  }

  render() {
    const {
      sortedByState = [],
      activeSort,
      activeRow,
      setAttr,
      loading,
      updateKey,
      showTerminateModal,
      hideTerminateModal,
      terminateActiveDWT,
    } = this.store || {};
    const { columns, tableKey } = this.state;
    const { loading: detailLoading } = this.props;

    return (
      <div style={{ width: '100%' }} data-testid="digital-wallet-tokens-table-wrapper">
        {this.renderColEditTableHeader({
          tableFilterTestId: 'digital-wallet-tokens-table-header-buttons',
        })}
        <VSpacer />
        <StyleContext.Consumer>
          {({ height }) => {
            const min = sortedByState.length ? 88 : 200;
            const heightWithMin = Math.max(min, height || this.dynamicHeight);
            return (
              <LoadingOverlay active={loading || detailLoading}>
                <Table
                  key={tableKey}
                  customEmptyState={this.customEmptyState('No digital wallet tokens')}
                  loading={loading}
                  height={heightWithMin}
                  data={sortedByState}
                  columns={columns}
                  sort={activeSort}
                  activeRow={activeRow}
                  fixedColumnCount={1}
                  rightAlignFixedColumns={true}
                  onRowClick={this.handleRowClick}
                  onSort={(newSort) => setAttr('activeSort', newSort)}
                  scrollToTopUpdateKey={updateKey}
                  handleColSizeChange={this.handleColSizeChange.bind(this)}
                  handleResetTable={this.clearFilterFunc}
                />
              </LoadingOverlay>
            );
          }}
        </StyleContext.Consumer>
        {this.renderPagination()}

        <TerminateTokenModal
          showTerminateModal={showTerminateModal}
          hideTerminateModal={hideTerminateModal}
          handleTermination={terminateActiveDWT}
        />
      </div>
    );
  }
}

DigitalWalletTokensTable.propTypes = {
  height: PropTypes.number,
  storeInitArgs: PropTypes.object,
  store: PropTypes.object,
  autoHydrate: PropTypes.bool,
  onStoreConstruction: PropTypes.func,
  gqlClient: PropTypes.any,
  localStorageKey: PropTypes.string,
  goToDigitalWalletToken: PropTypes.func,
};

DigitalWalletTokensTable.defaultProps = {
  height: 800,
  storeInitArgs: { limit: 100 },
  store: null,
  autoHydrate: true,
  onStoreConstruction: null,
  gqlClient: null,
  localStorageKey: 'DigitalWalletTokensTableConfig',
  goToDigitalWalletToken: null,
};

export default observer(DigitalWalletTokensTable);
