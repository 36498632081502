// @flow
import logger from 'utils/logger';
import { action, decorate, observable, computed } from 'mobx';
import userStore, { UserStoreClass } from './UserStore.js';
import { googleAnalytics, secureStorage, flipFlop } from '@mq/volt-amc-container';
import janusApi from './../apis/JanusApi.js';
import persistApi from './../apis/persist-api/PersistApi.js';
import ParentStore from './ParentStore.js';
import notificationStore from './NotificationStore.js';
import routerStore from 'stores/router/RouterStore';
import { UAM_GRANULAR_PERMISSIONS } from '@mqd/mqd-constants';

const {
  PROCESS_DISPUTES_VIEW_AND_EDIT,
  DISPUTES_VIEW_AND_EDIT,
  DISPUTES_MANUAL_TRANSITIONS_VIEW_AND_EDIT,
  DISPUTES_MANUAL_TRANSITIONS,
} = UAM_GRANULAR_PERMISSIONS;

class CurrentUserStore extends ParentStore {
  constructor(userStore: typeof UserStore) {
    super();
    this.baseUserStore = userStore;
    this.loadActingAsUserStore();
  }
  // values
  baseUserStore: typeof UserStore;
  actingAsUserStore: ?typeof UserStore = null;

  // actions
  init(): void {
    this.loadActingAsUserStore();
  }

  loadActingAsUserStore() {
    const prefixFromLocalStorage = secureStorage.getItem('actingAsUserPrefix');
    if (prefixFromLocalStorage) {
      this.actingAsUserStore = new UserStoreClass(prefixFromLocalStorage, flipFlop);
    }
  }

  parseUserData(userData) {
    const { departments, email, programs, supplements } = userData;
    return {
      departments,
      email,
      firstName: userData.first_name,
      lastName: userData.last_name,
      programs,
      supplements,
      userId: userData.id,
      userOrgName: userData.org_name,
      userRole: userData.role,
      webToken: userData.json_web_token,
      webTokenCreatedTime: new Date().toString(),
    };
  }

  async actAs(email: string) {
    try {
      this.loading = true;
      const data = { email: email };
      const userData = await janusApi.postData('/diva/security/users/impersonate', data);

      if (userData) {
        this.hideMainUserLocalStorageDataForActingAsSession();
        const actingAsPrefix = 'actingAs-';
        const actingAsUserStore = new UserStoreClass(actingAsPrefix, flipFlop);
        // wait for local storage to populate
        const parsedUserData = this.parseUserData(userData);
        actingAsUserStore.loadJanusData(parsedUserData, true);

        try {
          const persistApiUserData = await persistApi.findOrCreateUser({
            email: email,
          });
          actingAsUserStore.setupPersistUser(persistApiUserData);
        } catch (error) {
          notificationStore.notify('error', `Persist API Error: ${error.message}`);
        }
        secureStorage.setItem('actingAsUserPrefix', actingAsPrefix);
        this.actingAsUserStore = actingAsUserStore;
        if (googleAnalytics) {
          googleAnalytics.event({
            category: 'Key User Events',
            action: 'User Impersonation',
            label: 'Start User Impersonation',
          });
        }
        routerStore.go('/reports');
      }
    } catch (error) {
      logger.error(error);
      this.actingAsUserStore ? this.clearActingAsUser() : this.revertToMainUserLocalStorage();
      notificationStore.notify('error', `Error: Could not act as ${email}`);
    } finally {
      this.loading = false;
    }
  }

  clearActingAsUser() {
    if (this.actingAsUserStore) {
      this.revertToMainUserLocalStorage();
      // $FlowFixMe
      this.actingAsUserStore.clearJanusData();
      // $FlowFixMe
      this.actingAsUserStore.clearPersistUserData();
      secureStorage.removeItem('actingAsUserPrefix');
      if (googleAnalytics) {
        googleAnalytics.event({
          category: 'Key User Events',
          action: 'User Impersonation',
          label: 'End User Impersonation',
        });
      }
      this.actingAsUserStore = null;
      routerStore.go('/admin');
    }
  }

  get userStore(): typeof UserStore {
    if (this.actingAsUserStore) {
      return this.actingAsUserStore;
    } else {
      return this.baseUserStore;
    }
  }

  get currentWebToken(): string {
    return this.userStore.webToken ? this.userStore.webToken : '';
  }

  get disputesAccess() {
    const INTERNAL = 'risk-internal';
    const EXTERNAL = 'aux-risk-external';
    const PROGRAM_ADMIN = 'program-admin';
    const ISSUER_WRITE_OFF = 'issuer-write-off';

    const {
      uamGranularPermissionsActive,
      hasRoleForActiveProgram,
      hasRoleInArrayForActiveProgram: hasProgramRoles,
    } = this.baseUserStore;

    const hasRoleInArrayForActiveProgram = (roles) => {
      return hasProgramRoles instanceof Function ? hasProgramRoles(roles) : false;
    };

    const hasRiskInternalRole = uamGranularPermissionsActive
      ? hasRoleForActiveProgram(PROCESS_DISPUTES_VIEW_AND_EDIT)
      : hasRoleInArrayForActiveProgram([INTERNAL]);
    const hasAuxRiskExternalRole = uamGranularPermissionsActive
      ? hasRoleForActiveProgram(DISPUTES_VIEW_AND_EDIT)
      : hasRoleInArrayForActiveProgram([EXTERNAL]);
    const hasProgramAdminRole = uamGranularPermissionsActive
      ? hasRoleForActiveProgram(DISPUTES_VIEW_AND_EDIT)
      : hasRoleInArrayForActiveProgram([PROGRAM_ADMIN]);
    const canPerformIssuerWriteOff = hasRoleInArrayForActiveProgram([ISSUER_WRITE_OFF]);
    const canPerformDisputesManualTransitions = uamGranularPermissionsActive
      ? hasRoleForActiveProgram(DISPUTES_MANUAL_TRANSITIONS_VIEW_AND_EDIT)
      : hasRoleInArrayForActiveProgram([DISPUTES_MANUAL_TRANSITIONS]);

    return {
      hasRiskInternalRole,
      hasAuxRiskExternalRole,
      hasProgramAdminRole,
      canPerformIssuerWriteOff,
      canPerformDisputesManualTransitions,
    };
  }

  revertToMainUserLocalStorage() {
    for (const key in localStorage) {
      if (localStorage.hasOwnProperty(key)) {
        if (key.includes('hideForActingAs-')) {
          const originalName = key.replace('hideForActingAs-', '');
          this.renameLocalStorageItem(key, originalName);
        }
      }
    }
  }

  hideMainUserLocalStorageDataForActingAsSession() {
    // this is necessary because there are non-prefixed items that are saved
    // in the course of using the application that we dont want the
    // "acting as" user to see (or the main user thinking that the acting as user
    // has access to)
    // maybe can be refactored to not need prevent hide, but leaving it for now
    // NOTE - dont be concerned about managing localStorage state in the future,
    // its handled here!
    const preventHide = [
      'firstName',
      'lastName',
      'email',
      'webToken',
      'webTokenCreatedTime',
      'apiTokens',
      'userOrgName',
      'validFeatureFlags',
      'userRole',
      'departments',
      'programs',
      'managedUsers',
      'columnDataDict',
      // zendesk set items
      'ZD-store',
      'ZD-buid',
      'ZD-suid',
      'ZD-settings',
    ];
    for (const key in localStorage) {
      if (localStorage.hasOwnProperty(key)) {
        if (!preventHide.includes(key)) {
          this.renameLocalStorageItem(key, `hideForActingAs-${key}`);
        }
      }
    }
  }
}

decorate(CurrentUserStore, {
  // values
  baseUserStore: observable,
  // PS-4093
  // decmanApi: observable,
  actingAsUserStore: observable,
  init: action.bound,

  // actions
  loadActingAsUserStore: action.bound,
  actAs: action.bound,
  clearActingAsUser: action.bound,
  refreshToken: action.bound,
  runTokenRefresh: action.bound,

  // computed
  userStore: computed,
  currentWebToken: computed,
  disputesAccess: computed,
});

const currentUserStore = new CurrentUserStore(userStore);
export default currentUserStore;
