import currentUserStore from 'stores/CurrentUserStore';
import { FlipFlop } from './../../../utils/index.js';
import hasRoleForSelectedProgram from '../../helpers/hasRoleForSelectedProgram.js';
import { hasTokenizationManagerPermissions } from './functions.js';

const validProgramRoles = [
  'marqeta-admin-internal',
  'delivery-internal',
  'production-support-internal',
];

export const requirements = {
  flipFlopRequirements: ['ach-management'],
  requiredUserPermissions: validProgramRoles,
};

const hasPermissionForProgram = () => {
  return (
    FlipFlop.get('ach-management', false) &&
    validProgramRoles.some((role) => hasRoleForSelectedProgram(role))
  );
};

export const verifier = () => {
  const {
    userStore: { uamGranularPermissionsActive },
  } = currentUserStore;

  return (
    (hasPermissionForProgram() ||
      hasTokenizationManagerPermissions(currentUserStore?.userStore, 'program/ach-management')) &&
    !uamGranularPermissionsActive
  );
};

export const programVerifier = () => {
  const {
    userStore: { uamGranularPermissionsActive },
  } = currentUserStore;
  return hasPermissionForProgram() && !uamGranularPermissionsActive;
};
