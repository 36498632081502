import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Col, Container, LoadingOverlay, Row, VSpacer } from '@mqd/volt-base';
import { containerWidthHOC } from '@mq/voltron-parent';

import CardActionsCard from './card-actions-card/CardActionsCard';
import CardAuthAndVelocityControlsCard from './CardAuthAndVelocityControlsCard';
import CardInformationCard from './CardInformationCard';
import CardShippingCard from './CardShippingCard';

class CardInfoTab extends Component {
  render() {
    let { cardStore, userStore } = this.props;
    const {
      containerWidth,
      goToCard,
      canMakeTransition,
      canUpdateCardInfo,
      canSetPin,
      canShowPan,
      canViewShortPan,
      canViewCvv,
    } = this.props;
    if (!cardStore) {
      cardStore = {};
    }
    const {
      isPhysical,
      authFormattedMccs,
      formattedAmountLimits,
      velocityControlUsageLimitsWithTypes,
      hasSingleUseVelocityControl,
    } = cardStore;

    const {
      reportCardLostStolenActive,
      cardSuspendActive,
      cardReplaceActive,
      uamGranularPermissionsActive,
      showActivateCard,
    } = userStore;

    const cardActionsCardProps = {
      canShowPan,
      canMakeTransition,
      goToCard,
      cardStore,
      canViewShortPan,
      canViewCvv,
      reportCardLostStolenActive,
      cardSuspendActive,
      cardReplaceActive,
      showActivateCard,
    };
    const CardAuthAndVelocityControlsCardProps = {
      authFormattedMccs,
      formattedAmountLimits,
      velocityControlUsageLimitsWithTypes,
      hasSingleUseVelocityControl,
    };
    let colSpan = 6;
    if (containerWidth <= 1200) {
      colSpan = 6;
    }
    if (containerWidth <= 800) {
      colSpan = 12;
    }

    return (
      <div data-testid="card-info-card">
        <LoadingOverlay active={cardStore.loading}>
          <CardActionsCard
            {...cardActionsCardProps}
            uamGranularPermissionsActive={uamGranularPermissionsActive}
          />
          <Container>
            <Row>
              <Col span={colSpan}>
                <CardInformationCard
                  cardStore={cardStore}
                  editable={canUpdateCardInfo}
                  canMakeTransition={canMakeTransition}
                  canViewShortPan={canViewShortPan}
                  canSetPin={canSetPin}
                  canShowPan={canShowPan}
                />
                <VSpacer factor={2} />
                {isPhysical && (
                  <CardShippingCard cardStore={cardStore} editable={canUpdateCardInfo} />
                )}
              </Col>
              <Col span={colSpan}>
                <CardAuthAndVelocityControlsCard {...CardAuthAndVelocityControlsCardProps} />
              </Col>
            </Row>
            {/* Removed until we have new design for this
            <Row>
              <Col span={colSpan} style={{ width: '50%' }}>
                <CardPersonalizationCard 
                  cardStore={cardStore} 
                  editable={canUpdateCardInfo}
                />              
              </Col>
            </Row> */}
          </Container>
        </LoadingOverlay>
      </div>
    );
  }
}

CardInfoTab.propTypes = {
  cardStore: PropTypes.any,
  userStore: PropTypes.any,
};

CardInfoTab.defaultProps = {
  cardStore: {
    fulfillment: {
      shipping: {},
    },
  },
  userStore: {},
};

export default containerWidthHOC(observer(CardInfoTab));
