import React from 'react';
import PropTypes from 'prop-types';

// components
import { VSpacer } from '@mqd/volt-base';
import { Pagination } from '@mq/voltron-table';

// utils
import { isFunc } from '../../utils/index.js';

// constants
import { PAGE_SIZE } from '../../constants/index.js';

// styles
import s from './Pagination.module.css';

const next =
  (queryFn, startIndex, count, filters = {}) =>
  () => {
    queryFn({
      variables: {
        start_index: startIndex + count,
        count,
        ...filters,
      },
    });
  };
const previous =
  (queryFn, startIndex, count, filters = {}) =>
  () => {
    queryFn({
      variables: {
        start_index: startIndex - count,
        count,
        ...filters,
      },
    });
  };

const PaginationComponent = ({
  filters,
  queryFn,
  alignRight,
  className,
  nextPage,
  previousPage,
  pagination,
  ...rest
}) => {
  const { startIndex, isMore, isLess, pageSize } = pagination || {};
  if (!(isMore || isLess)) return null;
  if (!(isFunc(previousPage) || !isFunc(nextPage)) && !isFunc(queryFn)) return null;
  const count = pageSize || PAGE_SIZE;

  let classes = '';
  if (alignRight) classes += `${s.alignRight} `;
  if (className) classes += className;

  return (
    <div className={classes}>
      <VSpacer />
      <Pagination
        testId="program-pagination"
        handlePreviousClick={
          isFunc(previousPage) ? previousPage : previous(queryFn, startIndex, count, filters)
        }
        handleNextClick={isFunc(nextPage) ? nextPage : next(queryFn, startIndex, count, filters)}
        {...pagination}
        {...rest}
      />
    </div>
  );
};

PaginationComponent.propTypes = {
  pagination: PropTypes.shape({
    pageSize: PropTypes.number,
    startIndex: PropTypes.number,
    endIndex: PropTypes.number,
    isMore: PropTypes.bool,
    isLess: PropTypes.bool,
  }),
  filters: PropTypes.object,
  queryFn: PropTypes.func,
  alignRight: PropTypes.bool,
  className: PropTypes.string,
  nextPage: PropTypes.func,
  previousPage: PropTypes.func,
};

PaginationComponent.defaultProps = {
  alignRight: true,
  pagination: {
    startIndex: 0,
  },
};

export default PaginationComponent;
