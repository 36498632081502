import { useEffect, useCallback, useState, useRef } from 'react';
import { diff } from 'deep-object-diff';
import { URLS } from '../utilities/config.js';

function equal(a, b) {
  a = JSON.parse(JSON.stringify(a ?? {}));
  b = JSON.parse(JSON.stringify(b ?? {}));
  const d = diff(a, b);
  return d && typeof d === 'object' && Object.keys(d).length === 0;
}

// Compute the difference between current and prev, and apply those changes to
// out.
function threeWayMerge(prev, current, out) {
  const allProps = new Set([...Object.keys(current ?? {}), ...Object.keys(prev ?? {})]);
  const prevOut = out;
  out = out ?? {};
  for (const key of allProps) {
    const inPrev = prev && prev[key] !== undefined && prev[key] !== null;
    const inCurrent = current && current[key] !== undefined && current[key] !== null;

    if (inPrev && !inCurrent) {
      out[key] = undefined;
    } else if (!inPrev && inCurrent) {
      out[key] = current[key];
    } else if (inPrev && inCurrent && JSON.stringify(prev[key]) !== JSON.stringify(current[key])) {
      out[key] = current[key];
    }
  }

  return Object.keys(out).length !== 0 ? out : prevOut;
}

function useForceUpdate() {
  const [, setValue] = useState(0);
  const forceUpdate = useCallback(() => setValue((value) => value + 1), []);
  return forceUpdate;
}

/**
 * Performs a fetch call and provides data, loading, and error values.
 * @param {String} endpoint - Endpoint to fetch
 * @param {Object} options - Additional information for the query
 * @param {String} options.method - HTTP method
 * @param {Object} options.body - Body passed to fetch
 * @param {Object} options.query - Query variables used to form query string
 */
export default function useFetch(endpoint, { method = 'GET', body, query } = {}) {
  const [[data, error], setResult] = useState([undefined, undefined]);
  const [loading, setLoading] = useState(false);
  const forceUpdate = useForceUpdate();

  // Last request sent to server.
  const requestDataRef = useRef({
    firstCall: true,
    /** @type {AbortController | null} */
    abortController: null,
    body: body ?? {},
    query: query ?? {},
  });

  // Last options passed to the useFetch hook.
  const prevHookOptions = useRef({ endpoint, method, body, query });

  const fetchData = useCallback(
    async (newValues) => {
      // Cancel any previous fetch calls.
      if (requestDataRef.current.abortController) requestDataRef.current.abortController.abort();
      requestDataRef.current.abortController = new AbortController();

      const { body, query, abortController } = requestDataRef.current;

      if (newValues?.body) {
        Object.assign(body, newValues?.body);
      }
      if (newValues?.query) {
        Object.assign(query, newValues?.query);
      }

      const options = {
        method,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
        },
        signal: abortController.signal,
      };

      if (method === 'POST' || method === 'PUT') {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(body || {});
      }

      setLoading(true);
      try {
        // Trigger a re-render in the component that calls this hook.
        // We want to ensure that the caller always receives the updated
        // versions of values returned by useFetch hook (i.e., body, query,
        // etc.).
        // See FDS-7754 for historical context.
        forceUpdate();

        if (endpoint.includes('?')) {
          throw new Error("Endpoint cannot contain the '?' symbol");
        }

        const urlSearchParams = new URLSearchParams();
        if (query) {
          for (const key of Object.keys(query)) {
            if (query[key] !== undefined && query[key] !== null) {
              urlSearchParams.append(key, query[key]);
            }
          }
        }
        const queryString = urlSearchParams.toString();

        const res = await fetch(
          `${URLS.nodeGateway}${endpoint}${queryString ? '?' + queryString : ''}`,
          options
        );
        if (!res.ok) {
          throw new Error(`Received non-ok status: ${res.status}`);
        }
        const resJson = await res.json();
        setResult([resJson, undefined]);
        setLoading(false);
      } catch (e) {
        // Prevent setting any state if the component is unmounting, or if a new
        // fetch has started. No need to set loading to false, because either we
        // are unmounting, or the next fetch would set it to true again.
        if (e.name !== 'AbortError') {
          setResult([undefined, e]);
          setLoading(false);
        }
      }
    },
    [forceUpdate, method, endpoint]
  );

  // Abort current request if the component is unmounting.
  useEffect(() => {
    return () => {
      if (requestDataRef.current.abortController) {
        requestDataRef.current.abortController.abort();
      }
    };
  }, []);

  // Check if options passed into the useFetch hook have changed;
  // if so, refetch.
  useEffect(() => {
    const prev = requestDataRef.current;
    requestDataRef.current = {
      firstCall: false,
      abortController: prev.abortController,
    };

    const curHookOptions = { endpoint, method, body, query };

    if (!prev.firstCall) {
      requestDataRef.current.body = prev.body ? { ...prev.body } : prev.body;
      requestDataRef.current.query = prev.query ? { ...prev.query } : prev.query;

      // Compute the difference between the last body passed to useFetch and the
      // current body passed to useFetch.
      // Apply the diff to the body we last called fetch() with.
      //
      // Note: We apply the diff instead of entirely overwriting the body
      // ("requestDataRef.current.body = body") because requestDataRef's body
      // may have changed via a refetch(). We want to maintain any variables
      // passed in through refetch().
      requestDataRef.current.body = threeWayMerge(
        prevHookOptions.current.body,
        curHookOptions.body,
        requestDataRef.current.body
      );
      // Same for query.
      requestDataRef.current.query = threeWayMerge(
        prevHookOptions.current.query,
        curHookOptions.query,
        requestDataRef.current.query
      );
    } else {
      requestDataRef.current.body = prev.body;
      requestDataRef.current.query = prev.query;
    }

    // If any of the options passed to useFetch changed, refetch.
    if (
      prev.firstCall ||
      curHookOptions.endpoint !== prevHookOptions.current.endpoint ||
      curHookOptions.method !== prevHookOptions.current.method ||
      !equal(curHookOptions.body, prevHookOptions.current.body) ||
      !equal(curHookOptions.query, prevHookOptions.current.query)
    ) {
      prev.firstCall = false;
      fetchData();
    }

    // Remember the hook options for the next time useFetch hook is called.
    prevHookOptions.current = curHookOptions;
  }, [fetchData, endpoint, method, query, body]);

  return {
    data,
    loading,
    error,
    refetch: fetchData,
    body: requestDataRef.current.body,
    query: requestDataRef.current.query,
  };
}
