import gql from 'graphql-tag';

export const getNotifications = gql`
  query notifications {
    notifications {
      data {
        token
        message
        title
        link
        createdTime
        to {
          token
          entityType
        }
        initiator {
          token
          entityType
        }
      }
    }
  }
`;

export const deleteNotificationMutation = gql`
  mutation deleteNotification($notificationToken: String!) {
    deleteNotification(notificationToken: $notificationToken) {
      notificationToken
    }
  }
`;

export const createNotificationMutation = gql`
  mutation createNotification($notificationParams: CreateNotificationParams!) {
    createNotification(notificationParams: $notificationParams) {
      notificationToken
    }
  }
`;

export const readNotificationsMutation = gql`
  mutation readNotifications {
    readNotifications {
      timestamp
    }
  }
`;
