import React from 'react';
import { ContentSnippet, Text, VSpacer } from '@mqd/volt-base';
import { ParentDetailCard } from '@mq/voltron-parent';
import DetailCard from '../detail-card/DetailCard';

class CommandoModeVelocityCard extends ParentDetailCard {
  state = {
    editActive: false,
  };

  get commandoModeStore() {
    const { commandoModeStore = {} } = this.props;
    return commandoModeStore;
  }

  renderInfo() {
    const {
      token = '',
      name = '',
      usage_limit = '',
      approvals_only = '',
      include_purchases = '',
      include_withdrawals = '',
      include_transfers = '',
      include_cashback = '',
      currency_code = '',
      amount_limit = '',
      velocity_window = '',
      active = '',
    } = this.props;
    return (
      <DetailCard title="Velocity control">
        {this.renderSnippet({
          title: 'Token',
          content: token,
        })}
        {this.renderSnippet({
          title: 'Name',
          content: name,
        })}
        {this.renderSnippet({
          title: 'Usage Limit',
          content: usage_limit,
        })}
        {this.renderSnippet({
          title: 'Approvals Only',
          content: approvals_only,
        })}
        {this.renderSnippet({
          title: 'Include Purchases',
          content: include_purchases ? 'True' : 'False',
        })}
        {this.renderSnippet({
          title: 'Include Withdrawals',
          content: include_withdrawals ? 'True' : 'False',
        })}
        {this.renderSnippet({
          title: 'Include Transfers',
          content: include_transfers ? 'True' : 'False',
        })}
        {this.renderSnippet({
          title: 'Include Cashback',
          content: include_cashback ? 'True' : 'False',
        })}
        {this.renderSnippet({
          title: 'Currency Code',
          content: currency_code,
        })}
        {this.renderSnippet({
          title: 'Amount Limit',
          content: amount_limit,
        })}
        {this.renderSnippet({
          title: 'Velocity Window',
          content: velocity_window,
        })}
        {this.renderSnippet({
          title: 'Active',
          content: active ? 'True' : 'False',
        })}
      </DetailCard>
    );
  }

  render() {
    return <div>{this.renderInfo()}</div>;
  }
}

export default CommandoModeVelocityCard;
