import React, { PureComponent } from 'react';
import { observer } from 'mobx-react';
import { Card, Container, Row, Col, VSpacer } from '@mqd/volt-base';
import { containerWidthHOC } from '@mq/voltron-parent';
import { sentenceCase } from '../shared-utils';
import DigitalWalletTokensSummaryCard from './DigitalWalletTokensSummaryCard';
import DigitalWalletTokensDetailsCard from './DigitalWalletTokensDetailsCard';
import DigitalWalletTokenDeviceInfo from './DigitalWalletTokenDeviceInfo';

function DigitalWalletTokensInfoTab({
  canShowTokenPan,
  store: {
    activateToken,
    created_time,
    fulfillment_status,
    issuer_eligibility_decision,
    last_modified_time,
    loading,
    lockToken,
    state,
    terminateToken,
    formattedFulfillmentStatus,
    formattedWalletProviderRiskScore,
    device: {
      name: device_name,
      location,
      ip_address,
      type: device_type,
      device_id,
      phone_number,
      language_code,
    },
    token_service_provider: {
      token_pan,
      token_reference_id,
      pan_reference_id,
      token_expiration,
      token_requestor_name,
    },
    wallet_provider_profile: {
      pan_source: wallet_provider_pan_source,
      reason_code: wallet_provider_reason_score,
      risk_assessment: { score: wallet_provider_risk_score } = {},
    },
    hydrate,
    showTerminateModal,
    setTerminateModal,
  },
  containerWidth,
}) {
  const handleShowFullPan = () => {
    hydrate(true);
  };

  let colSpan = 6;
  if (containerWidth <= 1200) {
    colSpan = 6;
  }
  if (containerWidth <= 800) {
    colSpan = 12;
  }

  return (
    <Container>
      <Row>
        <Col span={colSpan}>
          <DigitalWalletTokensSummaryCard
            wallet_name={token_requestor_name}
            token_pan={token_pan}
            token_expiration_date={token_expiration}
            wallet_provider_reason_score={wallet_provider_reason_score}
            wallet_provider_pan_source={sentenceCase(wallet_provider_pan_source)}
            wallet_provider_risk_score={wallet_provider_risk_score}
            formattedWalletProviderRiskScore={formattedWalletProviderRiskScore}
            handleShowFullPan={handleShowFullPan}
            canShowTokenPan={canShowTokenPan}
          />
        </Col>
        <Col span={colSpan}>
          <DigitalWalletTokensDetailsCard
            token_reference_id={token_reference_id}
            pan_reference_id={pan_reference_id}
            token_fulfillment_status={fulfillment_status}
            formattedFulfillmentStatus={formattedFulfillmentStatus}
            issuer_eligibility_decision={issuer_eligibility_decision}
            created_time={created_time}
            last_modified_time={last_modified_time}
            language_code={language_code}
          />
        </Col>
      </Row>
      <Row>
        <Col span={colSpan}>
          <DigitalWalletTokenDeviceInfo
            device_type={device_type}
            device_id={device_id}
            phone_number={phone_number}
            device_name={device_name}
            location={location}
            ip_address={ip_address}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default containerWidthHOC(observer(DigitalWalletTokensInfoTab));
