import ParentStore from './ParentStore.js';
import { googleAnalytics } from '@mq/volt-amc-container';
import { action, decorate, observable } from 'mobx';

class NotificationStore extends ParentStore {
  // values
  notifications: Array<*> = [];

  // actions
  notify(type: string, text: string) {
    const notification = {
      className: `${type}-notification`,
      message: text,
      key: this.makeId(),
      dismissAfter: 5000,
    };
    this.notifications.push(notification);
    if (googleAnalytics && type === 'error') {
      googleAnalytics.event({
        category: 'Application Errors',
        action: 'Error Notification',
        label: text,
      });
    }
  }

  removeNotification(notification: { [string]: any }) {
    this.notifications = this.notifications.filter((noti) => noti !== notification);
  }
}

decorate(NotificationStore, {
  // values
  notifications: observable,

  //action
  notify: action.bound,
  removeNotification: action.bound,
});

const notificationStore = new NotificationStore();
export default notificationStore;
