import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { ActionsHeader, ColumnEditor } from '@mq/voltron-table';
import { useKeyRefresher } from '../../../../hooks/useKeyRefresher.js';

import s from './ColumnEditor.module.css';

function HistoryColumnEditor({ historyContext }) {
  const [dynamicKey, refreshDynamicKey] = useKeyRefresher();
  const { activeColumns, showColumnEditor, setShowColumnEditor, saveColumns, resetColumns } =
    useContext(historyContext);

  return (
    <>
      {showColumnEditor && (
        <ColumnEditor
          title="Customize table columns"
          columns={activeColumns.toJS()}
          handleSave={saveColumns}
          hideModal={() => setShowColumnEditor(false)}
        />
      )}

      <div className={s.actionsHeaderWrapper}>
        <ActionsHeader
          // due to the fact that after resetting the columns, the menu remains open
          // we use the key prop here to force rerender of the ActionsHeader component
          key={dynamicKey}
          testId="program-reserve-columns-actions"
          actions={[
            {
              text: 'Modify columns',
              onClick: () => setShowColumnEditor(true),
            },
            {
              text: 'Reset table to default',
              onClick() {
                resetColumns();
                refreshDynamicKey();
              },
            },
          ]}
        />
      </div>
    </>
  );
}

HistoryColumnEditor.propTypes = {
  historyContext: PropTypes.object.isRequired,
};

export default observer(HistoryColumnEditor);
