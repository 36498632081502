import React from 'react';
import { Flex, HSpacer, Icon } from '@mqd/volt-base';
import { formatMoney } from '../../utils/money.js';
import { currencyToIconCountryNameMap } from 'components/currency-select/components/CurrencyFlagOption/constants.js';

export const columns = [
  {
    header: 'Program',
    accessor: 'program',
    width: 251,
  },
  {
    header: 'Deposit/repayment amount',
    accessor: 'amount',
    width: 251,
    headerStyle: {
      justifyContent: 'flex-end',
    },
    columnStyle: {
      justifyContent: 'flex-end',
    },
    renderer: ({ cellValue: amount }) => (
      <Flex flexDirection="row" justifyContent="flex-end">
        {formatMoney(amount)}
      </Flex>
    ),
  },
  {
    header: 'Collateral amount (optional)',
    accessor: 'collateralAmount',
    width: 251,
    headerStyle: {
      justifyContent: 'flex-end',
    },
    columnStyle: {
      justifyContent: 'flex-end',
    },
    renderer: ({ cellValue: amount }) => (
      <Flex flexDirection="row" justifyContent="flex-end">
        {formatMoney(amount)}
      </Flex>
    ),
  },
  {
    header: 'Credit Amount',
    accessor: 'creditAmount',
    width: 251,
    headerStyle: {
      justifyContent: 'flex-end',
    },
    columnStyle: {
      justifyContent: 'flex-end',
    },
    renderer: ({ cellValue: amount }) => (
      <Flex flexDirection="row" justifyContent="flex-end">
        {formatMoney(amount)}
      </Flex>
    ),
  },
  {
    header: 'Currency',
    accessor: 'currency',
    width: 100,
    renderer: ({ cellValue: currency }) => (
      <Flex flexDirection="row" alignItems="center">
        <Icon
          type={currencyToIconCountryNameMap[currency]}
          factor={1}
          noHoverEffects={true}
          style={{ marginTop: 1 }}
        />
        <HSpacer factor={0.5} />
        {currency}
      </Flex>
    ),
  },
  {
    header: 'Memo (optional)',
    accessor: 'memo',
    width: 251,
    renderer: ({ cellValue }) => cellValue,
  },
];
