import {
  MARQETA_ADMIN_INTERNAL,
  PROGRAM_ADMIN,
  SUPPLIER_PAYMENTS_MANAGER,
  DELIVERY_INTERNAL,
  PRODUCTION_SUPPORT_INTERNAL,
  CARDHOLDER_SUPPORT,
} from './constants/redseaRoles.js';
import currentUserStore from 'stores/CurrentUserStore';
import hasRoleForAtLeastOneProgram from '../../helpers/hasRoleForAtLeastOneProgram.js';
import hasRoleForSelectedProgram from '../../helpers/hasRoleForSelectedProgram.js';
import { hasTokenizationManagerPermissions } from './functions.js';
import { CARD_PRODUCTS_VIEW } from '../../../views/admin/uam-granular-permissions/constants.js';

const requiredUserPermissions = [
  MARQETA_ADMIN_INTERNAL,
  PROGRAM_ADMIN,
  SUPPLIER_PAYMENTS_MANAGER,
  DELIVERY_INTERNAL,
  PRODUCTION_SUPPORT_INTERNAL,
  CARDHOLDER_SUPPORT,
];

const requiredGranularPermissions = [CARD_PRODUCTS_VIEW];

export const requirements = {
  requiredUserPermissions,
  requiredGranularPermissions,
};

export const verifier = () => {
  const {
    userStore: {
      hasRole,
      privateSandboxActive,
      hasRoleInArrayForActiveEnvironment,
      cardProductViewActive,
      uamGranularPermissionsActive,
    },
  } = currentUserStore;

  const hasSufficientRoles = (permissions) =>
    permissions.some((role) => hasRoleForAtLeastOneProgram(hasRole(role)));

  const canViewCardProducts = uamGranularPermissionsActive
    ? cardProductViewActive
    : hasSufficientRoles(requiredUserPermissions) ||
      hasTokenizationManagerPermissions(currentUserStore?.userStore, 'program/card-products');

  if (privateSandboxActive) {
    return hasRoleInArrayForActiveEnvironment(requiredUserPermissions);
  } else return canViewCardProducts;
};

export const programVerifier = () => {
  const {
    userStore: {
      hasRole,
      privateSandboxActive,
      hasRoleInArrayForActiveEnvironment,
      cardProductViewActive,
      uamGranularPermissionsActive,
    },
  } = currentUserStore;

  const hasSufficientRolesForProgram = requiredUserPermissions.some((role) =>
    hasRoleForSelectedProgram(hasRole(role))
  );

  const canViewCardProducts = uamGranularPermissionsActive
    ? cardProductViewActive
    : hasSufficientRolesForProgram;

  if (privateSandboxActive) {
    return hasRoleInArrayForActiveEnvironment(requiredUserPermissions);
  } else return canViewCardProducts;
};
