export const urlParamsToObject = (search = window.location.search) => {
  const urlParams = new URLSearchParams(search);
  const newParams = {};
  for (const [key, value] of urlParams) {
    if (value) newParams[key] = value;
  }
  return newParams;
};

const calculateUrlParamsFromArray = (params, search = window.location.search) => {
  const urlParams = new URLSearchParams(search);
  return params.reduce((acc, param) => {
    if (urlParams.has(param)) {
      acc[param] = urlParams.get(param);
    }
    return acc;
  }, {});
};

/**
 * Appends an array of currently existing URL params to a string.
 *
 * @param {String} url string to which the params will be appended.
 * @param {Array} params array of string params that currently exist in the URL.
 */
export const appendUrlParamsToUrl = (url, params) => {
  const paramsAsObject = calculateUrlParamsFromArray(params);
  const paramsToAppend = Object.keys(paramsAsObject).reduce((acc, key) => {
    const urlParamString = `&${key}=${paramsAsObject[key]}`;
    return acc + urlParamString;
  }, '');

  return url + paramsToAppend;
};

export const pruneUrlParams = (paramsToPrune) => {
  const urlParams = urlParamsToObject();
  return Object.keys(urlParams).reduce((acc, key) => {
    if (!paramsToPrune.find((param) => param === key)) {
      acc[key] = urlParams[key];
    }
    return acc;
  }, {});
};

export const pluckKeysFromObject = (object, keysToPluck) => {
  return keysToPluck.reduce((acc, key) => {
    if (object[key] !== undefined && object[key] !== null) {
      acc[key] = object[key];
    }
    return acc;
  }, {});
};

export const convertObjValuesToJson = (obj) => {
  return Object.keys(obj).reduce((acc, key) => {
    if (typeof obj[key] === 'object') {
      if (obj[key] !== null) {
        acc[key] = JSON.stringify(obj[key]);
      }
    } else if (obj[key] !== undefined) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
};

export const readObjValuesFromJson = (obj) => {
  return Object.keys(obj).reduce((acc, key) => {
    let value;
    try {
      value = JSON.parse(obj[key]);
    } catch {
      value = obj[key];
    }
    acc[key] = value;
    return acc;
  }, {});
};
