/* eslint-env browser */
import { KEYS, URLS } from '../config.js';

const SHORT_CODE_HEADER = 'X-Marqeta-Program-Short-Code';
const TOKEN_HEADER = 'X-Marqeta-Access-Token-Type';

/**
 * Fetch with graphql.
 *
 * @param {Object} [body={}] Passed to fetch's options.body as JSON. It must
 *          have the `query` property and can optionally have a `variables`
 *          property. If it is a string, string will be used as `body.query`.
 * @param {Object} [options={}] Options to pass to fetch.
 * @param {Object} [arg3={}] Data to apply, such as accessToken and
 *          activeProgram.
 * @param {Object} arg3.activeProgram Currently activeProgram object. Will use
 *          localStorage if not passed.
 * @param {String} arg3.accessToken Access token. Will use localStorage if not
 *          passed.
 * @return {Promise} Returns json result (i.e., result.json()).
 */
export default async function fetchGraphql(
  body = {},
  options = {},
  { activeProgram, accessToken } = {}
) {
  // 1) Set defaults for fetch options.
  options = {
    ...options,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // 2) Form body and merge with options
  if (typeof body === 'string') {
    body = { query: body };
  }
  if (typeof body !== 'object' || !body.query) {
    console.error(`Body must be a string or object. Skipping graphql query: \`${body}\`.`);
    return null;
  }
  options.body = JSON.stringify(body);

  // 3) Set required headers.
  if (!activeProgram && !options.headers[SHORT_CODE_HEADER]) {
    activeProgram = localStorage.getItem(KEYS.activeProgram, true);
    if (activeProgram) activeProgram = JSON.parse(activeProgram);
  }
  if (!accessToken && !options.headers.Authorization) {
    accessToken = sessionStorage.getItem(KEYS.accessToken);
  }
  if (accessToken && !options.headers[TOKEN_HEADER]) {
    options.headers[TOKEN_HEADER] = 'redsea';
  }
  if (accessToken && !options.headers.Authorization) {
    options.headers.Authorization = `Bearer ${accessToken}`;
  }
  if (activeProgram) {
    options.headers[SHORT_CODE_HEADER] = activeProgram.short_name;
  }

  // 4) Fetch!
  const response = await fetch(URLS.graphql, options);
  if (!response.ok) {
    return Promise.reject(`${response.status} response from GraphQL server.`);
  }
  const isTextResult = response.headers.get('Content-Type').includes('text');
  return response[isTextResult ? 'text' : 'json']();
}
