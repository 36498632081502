import React, { useEffect, useState } from 'react';
import { Button, Flex, HSpacer, Icon, Text, Tooltip } from '@mqd/volt-base';
import routerStore from 'stores/router/RouterStore';
import { countActiveWebhooks } from '../functions/webhooks';

const WebhooksHeader = ({ webhooks }) => {
  const [numActiveWebhooks, setNumActiveWebhooks] = useState(0);

  useEffect(() => {
    setNumActiveWebhooks(countActiveWebhooks(webhooks));
  }, [webhooks]);

  return (
    <Flex display="flex" justifyContent="space-between" type="div">
      <Text type="h3" role="heading" ariaLevel={1}>
        Webhooks
      </Text>
      <Flex flexDirection="row">
        {numActiveWebhooks > 0 ? (
          <Flex alignItems="center">
            <Text type="h6" id="active-webhook-count">
              {numActiveWebhooks} of 5 &nbsp;
            </Text>
            <Text type="body-sm">maximum active webhooks used</Text>
          </Flex>
        ) : null}
        <HSpacer factor={2} />
        <Tooltip
          content="Environments support a maximum of 5 active  webhooks. To add a new webhook, you need to disable or delete a current active webhook."
          direction="bottomLeft"
          width="320"
          defaultActive={false}
          disabled={numActiveWebhooks < 5}
        >
          <Button
            testId="add-webhook-button"
            type="tertiary"
            disabled={numActiveWebhooks >= 5}
            onClick={() => routerStore.go('developmentWebhooksNew')}
          >
            <Icon type="plus" mod={numActiveWebhooks >= 5 ? 'disabled' : 'default'} />
            <HSpacer factor={1} />
            Add webhook
          </Button>
        </Tooltip>
      </Flex>
    </Flex>
  );
};

export { WebhooksHeader };
