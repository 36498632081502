import { useCallback, useMemo } from 'react';
import logger from 'utils/logger';
import { useApolloClient } from '@apollo/react-hooks';
import { useProgramConfigDispatchContext } from './context.js';
import {
  UPDATE_PROGRAM_CONFIG,
  PROGRAM_CONFIGS,
  PROGRAM_CONFIG_KEY,
  LOADING_PROGRAM_CONFIG,
} from './constants.js';
import { secureStorage } from '@mq/volt-amc-container';
import dig from '../dig.js';
import { useProgramSelectorStateContext } from '../../components/program-selector/context.js';

export const useFetchProgramConfig = () => {
  const dispatch = useProgramConfigDispatchContext();
  const client = useApolloClient();

  return useCallback(() => {
    const handleFetchProgramConfig = async () => {
      try {
        const result = await client.query({ query: PROGRAM_CONFIGS, fetchPolicy: 'no-cache' });
        const configs = dig(result, 'data', 'programConfigs');
        const {
          AUTH_CONTROL_DEFAULT,
          INTERNATIONAL_RESPONSE_FORMAT_VERSION,
          ENABLE_PIN_REVEAL,
          MAX_ACTIVE_CARDS_PER_USER,
          TERMINATE_ACTIVE_CARDS_ON_NEW_CARD_ACTIVATION,
          TRANSACTION_MODEL_VERSION,
          DEFAULT_CURRENCY,
          featureFlags,
        } = configs || {};

        const kycMicroserviceEnabled =
          (featureFlags && featureFlags.KYC_MICROSERVICE_ENABLED) || false;
        const controlPlaneDecisionReportsEnabled =
          (featureFlags && featureFlags.ENABLE_CONTROLPLANE_DECISION_REPORTS) || false;
        const controlPlaneDashboardEnabled =
          (featureFlags && featureFlags.ENABLE_CONTROLPLANE_DASHBOARD) || false;
        const txnAcctMonitoringEnabled =
          (featureFlags && featureFlags.TXN_ACCT_MONITORING_ENABLED) || false;

        // Local state and LocalStorage will both use pascalCase, API uses CAPITAL_SNAKE
        const formattedData = {
          internationalResponseFormatVersion: INTERNATIONAL_RESPONSE_FORMAT_VERSION,
          authDefault: AUTH_CONTROL_DEFAULT,
          enablePinReveal: ENABLE_PIN_REVEAL,
          maxActiveCardsPerUser: MAX_ACTIVE_CARDS_PER_USER,
          terminateActiveCardsOnNewCardActivation: TERMINATE_ACTIVE_CARDS_ON_NEW_CARD_ACTIVATION,
          kycMicroserviceEnabled: kycMicroserviceEnabled,
          controlPlaneDecisionReportsEnabled: controlPlaneDecisionReportsEnabled,
          controlPlaneDashboardEnabled: controlPlaneDashboardEnabled,
          transactionModelVersion: TRANSACTION_MODEL_VERSION,
          defaultCurrency: DEFAULT_CURRENCY,
          txnAcctMonitoringEnabled: txnAcctMonitoringEnabled,
        };
        dispatch({
          type: UPDATE_PROGRAM_CONFIG,
          payload: formattedData,
        });
        secureStorage.setItem(PROGRAM_CONFIG_KEY, JSON.stringify(formattedData));
      } catch (e) {
        logger.error(`Unable to fetch program config: ${e}`);
      }
    };
    dispatch({ type: LOADING_PROGRAM_CONFIG });
    handleFetchProgramConfig();
  }, [dispatch, client]);
};

export default function useHydrateProgramConfig() {
  const dispatch = useProgramConfigDispatchContext();
  const fetchProgramConfig = useFetchProgramConfig();
  const { activeProgram } = useProgramSelectorStateContext();

  const gqlProgramShortCode = useMemo(
    () => activeProgram && activeProgram.short_name,
    [activeProgram]
  );

  return useCallback(
    ({ skipLocalStorage = false } = {}) => {
      if (!gqlProgramShortCode) return;
      // check if programconfig data is already set in localstorage
      const localStorageProgramConfigData = secureStorage.getItem('programConfigData');
      if (localStorageProgramConfigData && !skipLocalStorage) {
        try {
          const data = JSON.parse(localStorageProgramConfigData);
          dispatch({ type: UPDATE_PROGRAM_CONFIG, payload: data });
        } catch (err) {
          // nothing happens, try to refetch program config data from server
          logger.error(err);
        }
      } else {
        fetchProgramConfig();
      }
    },
    [dispatch, fetchProgramConfig, gqlProgramShortCode]
  );
}
