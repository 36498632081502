import { decorate, observable, computed } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';

class MerchantScopeStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
  }
  // values
  mid: string = '';
  mcc: string = '';
  mcc_name: string = '';
  mcc_group: string = '';
  mcc_group_data: Object = {};
}

decorate(MerchantScopeStore, {
  // values
  mid: observable,
  mcc: observable,
  mcc_name: observable,
  mcc_group: observable,
  mcc_group_data: observable,
});

export default MerchantScopeStore;
