import { formatMoneyAndNegative } from '@mqd/volt-currency';

export function formatMoney(value) {
  // since we are only interested in the amount
  // as the second parameter, we pass an empty string as currency
  const formattedValue = formatMoneyAndNegative(value, '') ?? '';

  // get rid of the unnecessary space at the end of the line
  return formattedValue.slice(0, -1);
}

export const isNegativeAmount = (value) => {
  return value.startsWith('(') && value.endsWith(')');
};

export const parseAmount = (value) => {
  const trimmedValue = value.trim();
  const float = parseFloat(trimmedValue.replace(/[(,)]/g, ''));

  if (isNaN(float)) {
    return 0.0;
  }

  return isNegativeAmount(trimmedValue) ? -Math.abs(float) : float;
};
