import React from 'react';

import { ActionCard } from '@mqd/volt-base';
import { CardTemplate } from './components/card-template';
import { CardTemplateContext } from '../../context/CardTemplateContext';
import { useIsFirstRender } from '../../hooks/use-is-first-render';

const { Card, MODE } = ActionCard;

export function ApprovalQueueActionCards({
  data,
  template,
  compareState,
  status,
  cardType,
  actions,
}) {
  const isFirstRenderOfGroup = useIsFirstRender();

  const templateContextValue = {
    template,
    compareState,
    status,
    cardType,
    actions,
    isFirstRenderOfGroup,
  };

  return (
    <CardTemplateContext.Provider value={templateContextValue}>
      {data.map((datum) => (
        <Card
          // we have to include datum.temp into the key to reset any react state down in the tree when we add a new card
          // so any state that was made from datum will not have { temp: true } in it
          key={[datum.id, Boolean(datum.temp)]}
          datum={datum}
          previewTemplate={CardTemplate}
          editTemplate={CardTemplate}
          defaultMode={datum.temp ? MODE.EDIT : MODE.PREVIEW}
        />
      ))}
    </CardTemplateContext.Provider>
  );
}
