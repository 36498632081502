import React, { useMemo } from 'react';
import logger from 'utils/logger';
import { useLazyQuery } from '@apollo/react-hooks';
import { withRedseaUser } from '@mq/volt-amc-container';
import { ToggleVisibility } from '@mqd/volt-base';
import { SHOW_PAN } from '../../queries/getShowPan/query.js';

const Pan = ({ value, tx_hash_internal_id, redseaUser, labelledBy, readOnly }) => {
  const { data: { redseaRoles } = {} } = redseaUser;

  const [showPan, { data, loading, error, called }] = useLazyQuery(SHOW_PAN, {
    onError: (e) => logger.error(`Show PAN error: ${e}`),
    fetchPolicy: 'no-cache',
  });

  const pan = useMemo(() => {
    return (data && data.transactionHashExceptionPan.pan) || value;
  }, [data, value]);

  const hasShowPanRole = useMemo(() => redseaRoles.indexOf('aux-show-pan') !== -1, [redseaRoles]);

  const handleShowPan = (e) => {
    e.stopPropagation();
    if (tx_hash_internal_id && !called) {
      showPan({
        variables: {
          tx_hash_internal_id: tx_hash_internal_id,
        },
      });
    }
  };

  return (
    <div>
      <ToggleVisibility
        abbreviate="max"
        async
        error={error}
        formatting="pan"
        labelledBy={labelledBy}
        loading={loading}
        onClick={handleShowPan}
        readOnly={!hasShowPanRole || readOnly}
        value={pan}
      />
    </div>
  );
};

export default withRedseaUser(Pan);
