import { CHECKBOX_STATES } from '../constants';

function getSelectedCount(webhookEvents) {
  let selectedCount = 0;
  webhookEvents.children.forEach((parentEvent) => {
    parentEvent.children.forEach((child) => {
      if (child.isSelected) selectedCount += 1;
    });
  });
  return selectedCount;
}

function recursivelyCheckChildren(event, sectionIsSelected) {
  if (typeof event.children === 'undefined') {
    event.isSelected = sectionIsSelected;
  } else if (event.children) {
    event.isSelected = sectionIsSelected;
    event.children.forEach((grandChild) => recursivelyCheckChildren(grandChild, sectionIsSelected));
  }
}

function isParentEvent(eventObject) {
  return eventObject.hasOwnProperty('defaultExpanded');
}

function recursivelyUpdateEventsArray(event, eventsArray) {
  // Base cases:
  // 1) If user selects all, just return the array with ['*']
  // 2) If event's parent is checked, return the eventID.*
  // 3) A child is checked, return child eventID
  // 3) If user reaches the end of the tree and there are no more children
  if (event.isSelected === CHECKBOX_STATES.TRUE && event.id === '*') {
    eventsArray.push(event.id);
  } else if (event.isSelected === CHECKBOX_STATES.TRUE && isParentEvent(event)) {
    eventsArray.push(event.id + '.*');
  } else if (event.isSelected === CHECKBOX_STATES.TRUE && !isParentEvent(event)) {
    eventsArray.push(event.id.toLowerCase());
  } else if (event.children) {
    event.children.forEach((child) => recursivelyUpdateEventsArray(child, eventsArray));
  }
  return eventsArray;
}

// expects an input of array
// [{isSelected: boolean}]
// return true, false or 'indeterminate'
function shouldParentBeChecked(arrayOfChildren) {
  let parentCount = 0;
  let selectedCount = 0;
  arrayOfChildren.forEach((childEvent) => {
    if (childEvent.isSelected === CHECKBOX_STATES.TRUE) selectedCount += 1;
    parentCount += 1;
  });
  if (selectedCount === 0) return CHECKBOX_STATES.FALSE;
  if (parentCount === selectedCount) return CHECKBOX_STATES.TRUE;
  return CHECKBOX_STATES.INDETERMINATE;
}

export {
  getSelectedCount,
  isParentEvent,
  recursivelyCheckChildren,
  recursivelyUpdateEventsArray,
  shouldParentBeChecked,
};
