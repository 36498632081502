export const bannerInformation = {
  FAILURE: {
    iconBackgroundColor: 'dangerColor',
    bannerType: 'danger',
    titleText: 'KYB has been rejected',
    subtitleText: 'Business details are not editable. Please visit KYB page to view status ->',
  },
  PENDING: {
    iconBackgroundColor: 'statusWarningColor',
    bannerType: 'warning',
    titleText: 'KYB is pending',
    subtitleText: 'Please go to the KYB section to edit all related business details',
  },
  NOT_PERFORMED: {
    iconBackgroundColor: 'blackLighter4',
    bannerType: 'info',
    titleText: 'KYB has not been performed',
    subtitleText: '',
  },
  VENDOR_PENDING: {
    iconBackgroundColor: 'blackLighter4',
    bannerType: 'info',
    titleText: 'Vendor status is pending',
    subtitleText: '',
  },
};
