import gql from 'graphql-tag';

export const getPaymentsQuery = gql`
  query billPayments(
    $user_token: ID!, 
    $count: Int = 10, 
    $start_index: Int = 0, 
    $sort_by: String, 
    $start_date: String
    $end_date: String
  ) {
    billPayments(
      count: $count, 
      sort_by: $sort_by, 
      start_index: $start_index, 
      user_token: $user_token,
      start_date: $start_date,
      end_date: $end_date,
    ) {
      count
      start_index
      end_index
      is_more
      total  
      data {
        payment_token
        payment_type
        user_token
        payee_token
        payment_account_type
        payment_account_token
        card_token
        amount
        convenience_fee
        biller 
        status
        processing_date
        delivery_date
        created_time
        last_modified_time   
      }
    }
  }
`;