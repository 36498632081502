import { format } from '../../../../../utilities/date.js';
import { parseAmount } from '../../../utils/money.js';

export const parseProgram = (key) => key.trim();

export const equalsToTodaysDate = (dateString) => format(new Date()) === format(dateString);

// First two fields are "Post Date" and "Instance" that are ignored
export const SKIPPED_HEADERS_COUNT = 2;
export const ENTRIES_LIMIT = 100;

export const PARSING_ERRORS = {
  GENERIC: 'Check file and resubmit. For help, contact your FDS representative.',
  DATE: 'Post Date does not match current date. Check file and resubmit. For help, contact your FDS representative.',
  ENTRIES_LIMIT: `Upload failed. File exceeds ${ENTRIES_LIMIT} records. Split file and resubmit. For help, contact your FDS representative.`,
};

export const parseTransactions = (headers, deposits, collateral, credit) => {
  const [postDateHeader, instanceHeader] = headers;
  if (postDateHeader.toLowerCase() !== 'post date' || instanceHeader.toLowerCase() !== 'instance') {
    throw new Error(PARSING_ERRORS.GENERIC);
  }

  // Post date is first value in deposits
  const [postDate] = deposits;
  if (!equalsToTodaysDate(postDate)) {
    throw new Error(PARSING_ERRORS.DATE);
  }

  const filteredEntries = headers
    .slice(SKIPPED_HEADERS_COUNT)
    .reduce((result, rawProgram, index) => {
      const program = parseProgram(rawProgram);
      const amount = parseAmount(deposits[index + SKIPPED_HEADERS_COUNT]);
      const collateralAmount = collateral
        ? parseAmount(collateral[index + SKIPPED_HEADERS_COUNT])
        : null;
      const creditAmount = credit ? parseAmount(credit[index + SKIPPED_HEADERS_COUNT]) : null;

      // Ignore entries in bulk upload when no program or amount is 0 or null
      // except if credit amount is provided
      if ((program && amount !== 0 && amount) || (program && creditAmount && creditAmount !== 0)) {
        result.push({ program, amount, collateralAmount, creditAmount });
      }

      return result;
    }, []);

  if (filteredEntries.length > ENTRIES_LIMIT) {
    throw new Error(PARSING_ERRORS.ENTRIES_LIMIT);
  }

  return filteredEntries;
};
