import React from 'react';
import { CurrencySelect, Form, Input, InputSelectGroup, Select, VSpacer } from '@mqd/volt-base';
import { MaskedInput } from '@mqd/volt-currency';
import PropTypes from 'prop-types';

export default function RemoveFundsForm({ defaultCurrency, formState, gpaOrders, onFormChange }) {
  return (
    <Form onFormChange={onFormChange} initialState={formState}>
      <Select label="Original GPA order" name="originalGpaOrder" options={gpaOrders} required />
      <VSpacer factor={3} />
      <InputSelectGroup label="Amount to unload" testId="unload-amount-currency">
        <MaskedInput name="amount" />
        <CurrencySelect
          withFlags
          primaryCodes={['USD', 'CAD']}
          name="currencyCode"
          placeholder="Select"
          onChange={({ code }) => code}
          value={defaultCurrency}
        />
      </InputSelectGroup>
      <VSpacer factor={3} />
      <Input name="unloadOrderToken" label="Unload order token (optional)" />
    </Form>
  );
}

RemoveFundsForm.propTypes = {
  defaultCurrency: PropTypes.string,
  formState: PropTypes.object,
  gpaOrders: PropTypes.array,
  onFormChange: PropTypes.func,
};

RemoveFundsForm.defaultProps = {
  defaultCurrency: '',
  formState: {},
  gpaOrders: [],
  onFormChange: () => {},
};
