import React from 'react';
import { observer } from 'mobx-react';
import { LoadingOverlay, VSpacer } from '@mqd/volt-base';
import { withTransactionHistoryStore } from '../../../../../../hoc/withTransactionHistoryStore.js';
import { TransactionHistoryStoreContext } from '../../../../../../context/TransactionHistoryStoreContext.js';
import { useFetchHistory } from './hooks/use-fetch-history.js';
import { useScrollToTopIf } from '../../../../hooks/useScrollToTopIf.js';
import Filters from './components/Filters/index.js';
import Table from '../Table/index.js';

const OverviewTab = () => {
  const { loading } = useFetchHistory();

  useScrollToTopIf(loading);

  return (
    <LoadingOverlay active={loading}>
      <VSpacer factor={2} />

      <Filters />

      <VSpacer factor={2} />

      <Table historyContext={TransactionHistoryStoreContext} />

      <VSpacer factor={1} />
    </LoadingOverlay>
  );
};

export default withTransactionHistoryStore(observer(OverviewTab));
