import { useContext, useEffect } from 'react';
import { ProgramTransactionHistoryStoreContext } from '../../../../../../../context/ProgramTransactionHistoryStoreContext.js';
import { fetchTransactionHistory } from '../fetch-utils.js';
import { ITEMS_PER_PAGE } from '../../../constants.js';

export function useFetchHistory() {
  const {
    pageIndex,
    loading,
    sortByForCoreAPI,
    filterProgram,
    setLoading,
    setError,
    setHistory,
    setIsMore,
  } = useContext(ProgramTransactionHistoryStoreContext);

  useEffect(() => {
    // Don't fetch records if no program is selected
    if (!filterProgram) {
      return;
    }

    setLoading(true);

    fetchTransactionHistory({
      count: ITEMS_PER_PAGE,
      start_index: ITEMS_PER_PAGE * pageIndex,
      sort_by: sortByForCoreAPI,
      program: filterProgram,
    })
      .then((gqlData) => {
        const {
          data: {
            programReserveSpecificTransactions: { data, is_more },
          },
        } = gqlData;

        // in order to display the full program name in the Table, each transaction must have a short program name
        data.forEach((transaction) => (transaction.program = filterProgram));

        setError(null);
        setHistory(data);
        setIsMore(is_more);
      })
      .catch(setError)
      .finally(() => setLoading(false));
  }, [filterProgram, pageIndex, setError, setHistory, setIsMore, setLoading, sortByForCoreAPI]);

  return { loading };
}
