export const walletProviderReasonScoreCodes = {
  '01': '01 – Cardholder’s wallet account is too new relative to launch.',
  '02': '02 – Cardholder’s wallet account is too new relative to provisioning request.',
  '03': '03 – Cardholder’s wallet account/card pair is newer than date threshold.',
  '04': '04 – Changes made to account data within the account threshold.',
  '05': '05 – Suspicious transactions linked to this account.',
  '06': '06 – Account has not had activity in the last year.',
  '07': '07 – Suspended cards in the secure element.',
  '08': '08 – Device was put in lost mode in the last seven days for longer than the duration threshold.',
  '09': '09 – The number of provisioning attempts on this device in 24 hours exceeds threshold.',
  '0A': '0A – There have been more than the threshold number of different cards attempted at provisioning to this phone in 24 hours.',
  '0B': '0B – The card provisioning attempt contains a distinct name in excess of the threshold.',
  '0C': '0C – The device score is less than 3.',
  '0D': '0D – The account score is less than 4.',
  '0E': '0E – Device provisioning location outside of the cardholder’s wallet account home country.',
  '0G': '0G – Suspect fraud.',
};
