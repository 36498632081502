import { decorate, observable } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';

class BusinessIdentityResultsStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
  }
  // values
  value: string = '';
  status: string = '';
  note: string = '';
}

decorate(BusinessIdentityResultsStore, {
  // values
  value: observable,
  status: observable,
  note: observable,
});

export default BusinessIdentityResultsStore;
