export const FEES_TOTAL = 'Collective total of all fees amounts in this hash ID.';
export const HASH_ID =
  'Unique ID assigned to transactions that share the same settlement date, network ref. ID, and program on both the Marqeta and network side.';
export const EXCEPTION_TYPE = 'Exception types include fail to post or overpost.';
export const SETTLED_DATE = 'The date the network settled the transaction.';
export const SETTLED_TOTAL =
  'Total amount, including fees and transactions, proposed by the system for this hash ID.';
export const SUBMISSION_DATE = 'The date the exception was submitted for approval.';
export const SUBMISSION_TYPE = 'Submission types include fail to post, overpost, or write-off.';
export const TRANSACTION_TOTAL =
  'Total transaction amount for an individual transaction within a hash ID group. Network sources include ATM fees; Marqeta sources exclude ATM fees.';
export const TRANSACTION_TOKEN =
  'This token is associated to the jcard tran log ID, and maps to the PAN.';
export const ORIGINAL_TOKEN =
  'This token is associated with the original jcard transaction, and will only show for reversal transactions.';
export const CREATED_DATE =
  'The date that Marqeta received the clearing transaction from the network.';
export const SUBMITTED_VARIANCE = 'The difference between the settled and submitted amounts.';
export const READY = 'Completed today’s network files; all exceptions available.';
export const WAITING = 'Waiting for today’s network files; exceptions not available.';
export const PARTIALLY_RECONCILED = 'Parsing today’s network files; some exceptions available.';
