import { useState } from 'react';
import { isEmpty } from 'ramda';
import { approvalReview } from './constants.js';
/**
   Header buttons, highlighting, hiding and disabling fields are all controlled by the currentFormStatus

   Workflow:
   currentFormStatus === inProgress
   on /program-controls/<short-code>
   nothing has changed on the form, just showing current state of API

   currentFormStatus === changedReview
   on /program-controls/<short-code>
   form has changed (been edited), "Review changes" button appears, highlighting changed fields, form can still be modified

   currentFormStatus === approvalReview
   on /program-controls/<short-code> OR /approval-queue/<request-token>
   only changed fields are rendered, form is no longer modifiable
   ready to be "Submit for review" OR ready to "Approve" / "Reject" changes
   **/

/**
 *
 * @param {string} formStatus - the current status of the form
 * @param {*} formState  - the state of the form. if there is no state, teh status defaults to `hasChanged`
 * @returns {useFormChangeSummaryStatus}
 *
 * @typedef {Object} useFormChangeSummaryStatus
 * @property {function} setCurrentFormStatus - callback function to set the form status
 * @property {boolean} hasChanged - if the form is in the `hasChanged` state or not
 * @property {boolean} inReview - if the form is in the `inReview` state or not
 * @property {string} currentFormStatus - current status of the form
 */
export default function useFormChangeSummaryStatus(formStatus, formState) {
  const [currentFormStatus, setCurrentFormStatus] = useState(formStatus);
  const hasChanged = !isEmpty(formState);
  const inReview = currentFormStatus === approvalReview;

  return {
    setCurrentFormStatus,
    hasChanged,
    inReview,
    currentFormStatus,
  };
}
