import React, { useContext, useState } from 'react';
import { pick } from 'ramda';
import { Button, Form, Input, Link, Text, Textarea, VSpacer } from '@mqd/volt-base';
import { WebhookContext } from '../../contexts/WebhookContext.js';
import { webhooksFormValidators, passwordValid } from './webhooksFormValidators.js';
import {
  translateDetailFormValuesToGraphQLWebhookType,
  detailFormAttributes,
} from '../../functions/webhooks.js';
import WebhookFormPasswordInput from './WebhookFormPasswordInput.js';

const WebhookDetailsForm = ({ onFormChange, oldWebhookData }) => {
  const { defaultWebhookValues, onSave } = useContext(WebhookContext);
  const [password, setPassword] = useState(defaultWebhookValues.password);

  return (
    <>
      <Text type="h4">Webhook details</Text>
      <VSpacer factor={1} />
      <Text type="body-sm">
        To learn about webhook details formatting,{' '}
        <Link
          href="https://www.marqeta.com/docs/core-api/webhooks-management"
          external={true}
          newTab={true}
          inline={true}
        >
          read the reference
        </Link>
      </Text>
      <VSpacer factor={3} />
      <Form initialState={defaultWebhookValues} onFormChange={onFormChange}>
        <Input
          name="name"
          label="Name"
          type="text"
          width={540}
          required
          errorValidators={webhooksFormValidators.name}
        />
        <VSpacer factor={3} />
        <Input
          name="url"
          label="URL"
          type="text"
          width={540}
          required
          errorValidators={webhooksFormValidators.url}
        />
        <VSpacer factor={3} />
        <Input
          name="username"
          label="Authentication username"
          type="text"
          width={540}
          required
          errorValidators={webhooksFormValidators.username}
        />
        <VSpacer factor={3} />
        <WebhookFormPasswordInput
          password={password}
          onChangePassword={(newPassword) => {
            setPassword(newPassword);
          }}
        />
        <VSpacer factor={3} />
        <Textarea
          name="headers"
          label="Custom headers"
          labelOptional={true}
          placeholder={`1
2
3
4`}
          noResize={true}
          rows={4}
          width={540}
          errorValidators={webhooksFormValidators.headers}
        />
        <VSpacer factor={3} />
        <Button
          submit
          onClick={(formState) => {
            if (!passwordValid(password)) return;

            const webhookValues = pick(detailFormAttributes, formState);
            const formValues = translateDetailFormValuesToGraphQLWebhookType(webhookValues);
            // Ignores active and events field
            formValues.active = oldWebhookData.active;
            formValues.events = oldWebhookData.events;
            formValues.password = password;
            onSave(formValues);
          }}
          testId="webhook-submit-button"
        >
          Save details
        </Button>
      </Form>
    </>
  );
};

export { WebhookDetailsForm };
