import React from 'react';
import TransactionHistoryStoreProvider from '../components/TransactionHistoryStoreProvider/index.js';

export function withTransactionHistoryStore(Component) {
  return function WithTransactionHistoryStoreComponent(props) {
    return (
      <TransactionHistoryStoreProvider>
        <Component {...props} />
      </TransactionHistoryStoreProvider>
    );
  };
}
