import React from 'react';
import { Modal, Button } from '@mqd/volt-base';

const DisableConfirmationModal = ({ disableWebhook = () => {}, hideModalCallback }) => {
  return (
    <div style={{ whiteSpace: 'break-spaces' }}>
      <Modal
        type="sm"
        heading="Disable webhook"
        description="Disabling this webhook will remove all active event listeners. You can choose to activate this webhook again if needed."
        hideModal={hideModalCallback}
        hideModalButtonText="Cancel"
        showHideModalButton={true}
        maxHeightVh={80}
        disableOverFlow={true}
        footerButtons={[<Button onClick={disableWebhook}>Disable</Button>]}
        testId="volt-library-modal"
      ></Modal>
    </div>
  );
};

export default DisableConfirmationModal;
