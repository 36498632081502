// @flow
import { decorate, observable, action, runInAction } from 'mobx';
import gqlUtils from '@mqd/graphql-utils';
import CommandoModeStore from './../stores/CommandoModeStore';
import { ParentTableStore } from '@mq/voltron-parent';

const { fragments } = gqlUtils;

export default class CommandoModeGroupStore extends ParentTableStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
  }
  // values
  items: Array<BusinessStore> = [];

  // actions
  async hydrate() {
    this.loading = true;
    const result = await this.gqlQuery(
      `
        query commandoModes {
          commandoModes {
            count
            start_index
            data {
              ...commandoModeBaseInfo
              program_gateway_funding_source {
                ...programGatewayFundingSourceBaseInfo
              }
              token
            }
          }
        }
        ${fragments.commandoModeBaseInfo}
        ${fragments.programGatewayFundingSourceBaseInfo}
      `
    );
    runInAction(() => {
      const commandoModes = this.extract(result, 'commandoModes');
      this.processPaginationResponse(commandoModes, CommandoModeStore);
      this.loading = false;
    });
  }
}

decorate(CommandoModeGroupStore, {
  // values
  items: observable,

  // actions
  hydrate: action.bound,

  // computed
});
