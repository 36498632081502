import { FlipFlop } from '../../index';
import { isDdaProgram } from './functions/isDdaProgram.js';

export const requirements = {
  flipFlopRequirements: ['accounts-parity'],
};

export const verifier = () => {
  return FlipFlop.get('accounts-parity', false);
};

export const redirect = () => {
  if (isDdaProgram()) return 'programAccountholders';
  return false;
};
