import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import PageHeader from '../../../PageHeader/index.js';
import { ProgramReserveContext } from '../../../../context/ProgramReserveContext.js';
import TransactionsForm from '../../../TransactionsForm/index.js';
import { usePageLeaveConfirmationModal } from '../../../../hooks/use-page-leave-confirmation-modal.js';

function ManualEntryEdit() {
  const { disableEditMode } = useContext(ProgramReserveContext);

  usePageLeaveConfirmationModal();

  return (
    <>
      <PageHeader
        breadcrumbs={[{ children: 'Manual entry' }]}
        title="Log manual entry"
        description={
          <>
            Log manual entries by selecting the program and amount for each row. Represent
            withdrawal amounts with a
            <br />
            (-) symbol. Currency codes will be auto selected based on the program settings.
          </>
        }
      />

      <TransactionsForm onSubmit={disableEditMode} />
    </>
  );
}

export default observer(ManualEntryEdit);
