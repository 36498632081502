import { ACCOUNTING_GROUPS, NETWORKS } from '../../utilities/dictionary/index.js';

const {
  CROSS_BORDER_ISSUER_FEE,
  INTERCHANGE_PAYABLES,
  PINDEBIT_ASSOC_FEE,
  PURCHASE_PAYABLES,
  SWITCH_FEE,
} = ACCOUNTING_GROUPS;

export const ID_PREFIX = 'exception-queue';

export const ACCOUNTING_GROUPS_ORDER = {
  [NETWORKS.Visa]: [
    PURCHASE_PAYABLES,
    CROSS_BORDER_ISSUER_FEE,
    INTERCHANGE_PAYABLES,
    PINDEBIT_ASSOC_FEE,
  ],
  [NETWORKS.Pulse]: [PURCHASE_PAYABLES, SWITCH_FEE, INTERCHANGE_PAYABLES, PINDEBIT_ASSOC_FEE],
  default: [PURCHASE_PAYABLES, CROSS_BORDER_ISSUER_FEE, INTERCHANGE_PAYABLES, PINDEBIT_ASSOC_FEE],
};

export const API_KEY_TO_ACCOUNT_GROUP_HASH = {
  [CROSS_BORDER_ISSUER_FEE]: 'cross_border_fee',
  [INTERCHANGE_PAYABLES]: 'interchange_fee',
  [PINDEBIT_ASSOC_FEE]: 'surcharge_atm_pin_debit_fee',
  [PURCHASE_PAYABLES]: 'transactions',
  [SWITCH_FEE]: 'switch_fee',
};
