import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Text, VSpacer } from '@mqd/volt-base';

import { useCardTemplateContext } from '../../../../context/CardTemplateContext';

import s from './EditTemplate.module.css';

export function EditTemplate({ datum, switchToPreview, renderControls }) {
  const { editTemplate: Template, actions, cardType } = useCardTemplateContext();

  const [formState, setFormState] = useState(datum);
  const actionsWithReset = {
    ...actions,
    onResetCard: () => {
      formState.temp ? actions.onResetCard(formState) : setFormState(datum);
    },
  };

  return (
    <>
      <div className={s.title}>
        <Text type="h6">{formState.temp ? `New ${cardType}` : datum.name}</Text>
      </div>
      <VSpacer factor={2} />
      <Template formState={formState} handleFormChange={setFormState}>
        <VSpacer factor={3} />
        {renderControls({ formState, actions: actionsWithReset, cardType, switchToPreview })}
      </Template>
    </>
  );
}

EditTemplate.propTypes = {
  datum: PropTypes.any.isRequired,
  switchToPreview: PropTypes.func.isRequired,
  renderControls: PropTypes.func.isRequired,
};
