import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { CopyToClipboard, DetailPane, Flex, HSpacer, ToggleVisibility } from '@mqd/volt-base';
import { IDENTIFICATION_TYPE_NAME_MAP, PRIMARY_IDENTIFICATION_TYPES } from '../constants';

const IdItem = observer(({ id, onReveal, canViewPii, labelledBy }) => {
  /**
   * Note from Jamie:
   * This is the only piece I wasn't able to fully separate from mobX.
   * In the CardholderStore cardholder object, identifications is an observable array
   * of observable IdentificationStore instances. The observability is too deeply
   * nested to untangle in the time I had.
   */

  const [fullValueFetched, setFullValueFetched] = useState(false);
  const { type, value, loading, error } = id;

  useEffect(() => {
    if (value && value.indexOf('_') < 0) {
      setFullValueFetched(true);
    } else {
      setFullValueFetched(false);
    }
  }, [value, setFullValueFetched]);

  const isPii = PRIMARY_IDENTIFICATION_TYPES.includes(type);
  const revealAsync = onReveal && isPii;

  const toggleVisibilityProps = {
    labelledBy,
    formatting: isPii && type ? type.toLowerCase() : null,
    abbreviate: !isPii ? 'lastFour' : null,
    readOnly: !canViewPii && isPii,
    value,
    async: !fullValueFetched && revealAsync,
    error,
    loading,
    onClick: revealAsync
      ? () => {
          if (!fullValueFetched) {
            onReveal(type);
          }
        }
      : undefined,
  };

  return (
    <Flex display="flex">
      <ToggleVisibility {...toggleVisibilityProps} />
      {/* Cannot wrap ToggleVisibility in CopyToClipboard because it needs to be conditionally displayed, which causes issues with a useRef in ToggleVisiblity  */}
      {fullValueFetched && (canViewPii || !isPii) && (
        <>
          <HSpacer factor={0.5} />
          <CopyToClipboard style={{ display: 'inline' }} overrideValue={value}></CopyToClipboard>
        </>
      )}
    </Flex>
  );
});

const ViewIdentification = ({ data = {}, canViewPii, revealId }) => {
  const { identifications } = data;
  return (
    <DetailPane.default layout="row">
      {identifications &&
        identifications.map((identification, idx) => {
          const { type } = identification;
          return (
            <DetailPane.Content
              key={`identificationItem-${idx}`}
              label={IDENTIFICATION_TYPE_NAME_MAP[type] || type}
            >
              <IdItem id={identification} canViewPii={canViewPii} onReveal={revealId} />
            </DetailPane.Content>
          );
        })}
    </DetailPane.default>
  );
};
export default ViewIdentification;
