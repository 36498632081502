export const getMultiFieldStatus = (object) => {
  var status = '';
  Object.keys(object).forEach((key) => {
    if (object[key]) {
      if (!object[key].status) {
        status = 'UNVERIFIED';
      } else if (object[key].status === 'FAILURE' && status !== 'UNVERIFIED') {
        status = 'FAILURE';
      } else if (
        object[key].status === 'PENDING' &&
        status !== 'FAILURE' &&
        status !== 'UNVERIFIED'
      ) {
        status = 'PENDING';
      } else if (
        object[key].status === 'SUCCESS' &&
        status !== 'FAILURE' &&
        status !== 'PENDING' &&
        status !== 'UNVERIFIED'
      ) {
        status = 'VERIFIED';
      }
    }
  });
  return status;
};

const fillerObj = { value: '', status: 'UNVERIFIED' };
export const getNameObject = (entity) => {
  return {
    first_name: entity && entity.first_name ? entity.first_name : fillerObj,
    middle_name: entity && entity.middle_name ? entity.middle_name : fillerObj,
    last_name: entity && entity.last_name ? entity.last_name : fillerObj,
  };
};

export const getAddressObject = (entity) => {
  return {
    address1: entity && entity.address1 ? entity.address1 : fillerObj,
    address2: entity && entity.address2 ? entity.address2 : fillerObj,
    city: entity && entity.city ? entity.city : fillerObj,
    state: entity && entity.state ? entity.state : fillerObj,
    postal_code: entity && entity.postal_code ? entity.postal_code : fillerObj,
    country: entity && entity.country ? entity.country : fillerObj,
  };
};

export const getIssues = (entity) => {
  const issues = [];

  const nameObj = getNameObject(entity);
  if (getMultiFieldStatus(nameObj) !== 'VERIFIED') {
    issues.push('NameIssue');
  }

  const addressObj = getAddressObject(entity);
  if (getMultiFieldStatus(addressObj) !== 'VERIFIED') {
    issues.push('AddressIssue');
  }

  if (entity && entity.birth_date && entity.birth_date.status !== 'SUCCESS') {
    issues.push('DOBIssue');
  }

  if (entity && entity.ssn && entity.ssn.status !== 'SUCCESS') {
    issues.push('SSNIssue');
  }

  if (entity && entity.ofac && entity.ofac.status !== 'SUCCESS') {
    issues.push('OFACIssue');
  }

  return issues.join(', ');
};

export const sentenceCase = (value = '') => {
  value = value && value.replace(/_/g, ' ');
  return value && value[0] && value[0].toUpperCase() + value.substring(1).toLowerCase();
};

export const canEdit = (isInternal, team) => {
  const marqeta = team === 'MARQETA';
  const customer = team === 'CUSTOMER';
  return (isInternal && marqeta) || (!isInternal && customer) || team === '';
};
