import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@mqd/volt-base';
import { Status } from '@mq/voltron-parent';

function DigitalWalletTokensDetailHeader({ status, token_pan }) {
  const tokenDisplay = token_pan ? token_pan : 'Digital wallet token';

  return (
    <>
      <Status restyled uppercase status={status}>
        &nbsp;TOKEN
      </Status>
      <Text type="h3">Token {tokenDisplay}</Text>
    </>
  );
}

DigitalWalletTokensDetailHeader.defaultProps = {
  token_pan: null,
  status: 'UNKNOWN',
};
DigitalWalletTokensDetailHeader.propTypes = {
  token_pan: PropTypes.string,
  status: PropTypes.string,
};

export default DigitalWalletTokensDetailHeader;
