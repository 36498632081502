import { UPDATE_PROGRAM_CONFIG, LOADING_PROGRAM_CONFIG } from './constants.js';
import logger from 'utils/logger';

export const initialState = {
  authDefault: '',
  internationalResponseFormatVersion: 'V2',
  loaded: false,
  loading: false,
  isV2: true,
  enablePinReveal: false,
  maxActiveCardsPerUser: '',
  transactionModelVersion: '',
  terminateActiveCardsOnNewCardActivation: false,
  defaultCurrency: null,
};

export function reducer(state, action) {
  const { type, payload } = action;
  if (process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development') {
    logger.groupCollapsed('ProgramConfig reducer');
    logger.info(`ProgramConfig reducer: ${type}: ${JSON.stringify(payload, null, 4)}`);
    logger.groupEnd();
  }
  switch (type) {
    case UPDATE_PROGRAM_CONFIG:
      return {
        ...state,
        internationalResponseFormatVersion: payload.internationalResponseFormatVersion,
        isV2: payload.internationalResponseFormatVersion === 'V2',
        authDefault: payload.authDefault,
        enablePinReveal: payload.enablePinReveal,
        maxActiveCardsPerUser: +payload.maxActiveCardsPerUser || 0,
        transactionModelVersion: payload.transactionModelVersion,
        terminateActiveCardsOnNewCardActivation:
          payload.terminateActiveCardsOnNewCardActivation === 'true' || false,
        kycMicroserviceEnabled: payload.kycMicroserviceEnabled || false,
        txnAcctMonitoringEnabled: payload.txnAcctMonitoringEnabled || false,
        controlPlaneDashboardEnabled: payload.controlPlaneDashboardEnabled || false,
        controlPlaneDecisionReportsEnabled: payload.controlPlaneDecisionReportsEnabled || false,
        defaultCurrency: payload.defaultCurrency,
        loaded: true,
        loading: false,
      };
    case LOADING_PROGRAM_CONFIG:
      return {
        ...state,
        loading: true,
      };
    default:
      throw new Error(`Action of type: ${type} is not supported by ProgramConfig reducer`);
  }
}
