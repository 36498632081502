import React, { PureComponent } from 'react';
import { Modal, Button, Input, VSpacer } from '@mqd/volt-base';
import PropTypes from 'prop-types';

class AddMetadataModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      key: '',
      value: '',
    };
  }

  resetState() {
    this.setState({
      key: '',
      value: '',
    });
  }

  render() {
    const { handleCancel, handleSave, hideModal } = this.props;
    const { key, value } = this.state;
    const handleHideModal = () => {
      handleCancel && handleCancel();
      this.resetState();
      hideModal();
    };
    return (
      <Modal
        heading="Add Metadata"
        hideModal={handleHideModal}
        footerButtons={[
          <Button
            disabled={!value.length}
            key="save"
            onClick={() => {
              handleSave && handleSave({ key, value });
              this.resetState();
            }}
          >
            Save
          </Button>,
        ]}
      >
        <Input
          label="Key"
          testId="metadata-key"
          placeholder="Enter Key"
          value={key}
          onChange={(e) => this.setState({ key: e.target.value })}
        />
        <VSpacer factor={4} />
        <Input
          isError={(currentValue) => {
            if (!currentValue.length) {
              return 'Value is required';
            }
            return false;
          }}
          label="Value"
          testId="metadata-value"
          placeholder="Enter Value"
          value={value}
          onChange={(e) => this.setState({ value: e.target.value })}
        />
      </Modal>
    );
  }
}

AddMetadataModal.propTypes = {
  handleCancel: PropTypes.func,
  handleSave: PropTypes.func,
  hideModal: PropTypes.func,
};

AddMetadataModal.defaultProps = {
  handleCancel: () => {},
  handleSave: () => {},
  hideModal: () => {},
};

export default AddMetadataModal;
