import React from 'react';
import logger from 'utils/logger';
import retry from './../../utils/helpers/retry.js';
// import * as Sentry from '@sentry/browser';
const GeneralErrorView = React.lazy(() =>
  retry(() => import(/*webpackChunkName: "ErrorComp" */ './GeneralErrorView.js'))
);

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, eventId: null };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    logger.error(error);
    logger.error(info);
    const { googleAnalytics } = this.props;

    // Sentry &&
    //   Sentry.withScope(scope => {
    //     scope.setExtras(info);
    //     const eventId = Sentry.captureException(error);
    //     this.setState({ eventId });
    //   });

    googleAnalytics &&
      googleAnalytics.event({
        category: 'Errors',
        action: 'Error caught on page',
        label: `Error: ${error}, Info: ${info}`,
      });
  }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      return (
        <React.Suspense fallback={<div />}>
          <GeneralErrorView />
        </React.Suspense>
      );
    }
    return this.props.children;
  }
}
