import React, { Component } from 'react';
import {
  Modal,
  Button,
  Input,
  VSpacer,
  ModalAlert,
  Text,
  BannerAlert,
  LoadingOverlay,
  Tooltip,
  ToastAlert,
} from '@mqd/volt-base';
import PropTypes from 'prop-types';

import s from './ChangePasswordModal.module.css';

class ChangePasswordModal extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
  }

  state = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
    error: '',
  };

  componentDidMount() {
    const { error } = this.props;
    this.setState({ error });
  }

  componentDidUpdate(prevProps) {
    const { error } = this.props;
    if (prevProps.error !== error) {
      this.setState({ error });
    }
  }

  async handleSubmit() {
    const { handleSubmit } = this.props;
    const { oldPassword, newPassword, confirmNewPassword } = this.state;
    if (!handleSubmit) {
      console.error('handleSubmit passed to ChangePasswordModal must be a function');
      return;
    }
    handleSubmit({
      oldPassword,
      newPassword,
      confirmNewPassword,
    });
  }

  tooltipContents() {
    return (
      <div className={s.passwordRequirements}>
        <div>Passwords must:</div>
        <ul>
          <li>Have at least one lower case letter</li>
          <li>Have at least one capital letter</li>
          <li>Have at least one special character</li>
          <li>Have at least one number</li>
          <li>Be at least 16 characters</li>
          <li>Not be any of your last four (4) passwords</li>
        </ul>
      </div>
    );
  }

  validatePassword(password) {
    let pattern = new RegExp(
      '((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[-{}|;,.?:/<>@#$%!^&*()_+~`\\[\\]\'"=]).{16,100})'
    );
    return pattern.test(password);
  }

  renderInputs() {
    const { newPassword, confirmNewPassword, oldPassword } = this.state;
    return (
      <>
        <Input
          label="Current password"
          type="password"
          value={oldPassword}
          onChange={(e) => this.setState({ oldPassword: e.target.value, error: '' })}
          testId="user-profile-old-password"
        />
        <VSpacer factor={3} />
        <Input
          label="New password"
          labelTooltip={
            <Tooltip content={this.tooltipContents()} direction="bottomRight" textWrap={true} />
          }
          type="password"
          value={newPassword}
          onChange={(e) => this.setState({ newPassword: e.target.value })}
          isError={(val) => {
            if (val === oldPassword) {
              return 'Password cannot be the same as old password';
            }
            return this.validatePassword(val)
              ? false
              : 'Password must be at least 16 characters and a mix of upper and lower case letters, numbers, and symbols.';
          }}
          testId="user-profile-new-password"
        />
        <VSpacer factor={3} />
        <Input
          name="confirmNewPassword"
          label="Reenter new password"
          labelTooltip={
            <Tooltip content={this.tooltipContents()} direction="topRight" textWrap={true} />
          }
          type="password"
          value={confirmNewPassword}
          onChange={(e) => this.setState({ confirmNewPassword: e.target.value })}
          testId="user-profile-reenter-new-password"
          isError={(val) =>
            newPassword === confirmNewPassword ? false : 'Password does not match'
          }
        />
      </>
    );
  }

  renderError() {
    const { error } = this.state;
    if (!error) return;
    return (
      <>
        <BannerAlert type="danger" size="sm">
          {error}
        </BannerAlert>
        <VSpacer factor={4} />
      </>
    );
  }

  renderEdit() {
    const { oldPassword, newPassword, confirmNewPassword } = this.state;
    const { hideModal, loading } = this.props;
    const validInput =
      oldPassword &&
      newPassword &&
      confirmNewPassword &&
      this.validatePassword(newPassword) &&
      newPassword !== oldPassword &&
      newPassword === confirmNewPassword;
    return (
      <Modal
        heading="Update Password"
        hideModal={hideModal}
        width={460}
        disableOverFlow={true}
        footerButtons={[
          <Button
            disabled={!validInput}
            onClick={this.handleSubmit}
            testId="user-profile-update-password"
          >
            Update password
          </Button>,
        ]}
      >
        <LoadingOverlay active={loading}>
          {this.renderError()}
          {this.renderInputs()}
        </LoadingOverlay>
      </Modal>
    );
  }

  render() {
    const { showSuccessToast } = this.props;
    return showSuccessToast ? null : this.renderEdit();
  }
}

ChangePasswordModal.propTypes = {
  hideModal: PropTypes.func,
  error: PropTypes.string,
};

ChangePasswordModal.defaultProps = {
  hideModal: () => {},
  store: {},
  error: '',
};

export default ChangePasswordModal;
