const filterOptions = [
  {
    label: 'Name',
    filterParam: 'name',
  },
  {
    label: 'BIN prefix',
    filterParam: 'binPrefix',
  },
  {
    label: 'Token',
    filterParam: 'token',
  },
  {
    label: 'Payment instrument',
    type: 'select',
    filterParam: 'paymentInstrument',
    defaultClearOptions: 'all',
    props: {
      dropDownWrap: {
        height: 'auto',
      },
    },
    options: [
      {
        returns: 'all',
        render: 'All payment instruments',
        val: 'all',
      },
      {
        returns: 'physical',
        render: 'Physical',
        val: 'physical',
      },
      {
        returns: 'virtual',
        render: 'Virtual',
        val: 'virtual',
      },
    ],
  },
];

const sortOptions = [
  // Not currently supporting sorting by created_time
  // {label: 'Newest first', sortByAttr: '-created_time'},
  // {label: 'Newest last', sortByAttr: 'created_time'},
  { label: 'Oldest', sortByAttr: 'last_modified_time' },
  { label: 'Most recent', sortByAttr: '-last_modified_time' },
];

export { filterOptions, sortOptions };
