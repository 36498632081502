// @flow
import logger from 'utils/logger';
import { action, decorate, observable, computed, toJS } from 'mobx';
import { redseaRoleMapping, authorization, secureStorage } from '@mq/volt-amc-container';
import { ENVIRONMENT_LOCAL_STORAGE_KEY } from '@mqd/mqd-constants';
import ParentStore from './ParentStore.js';
import currentUserStore from 'stores/CurrentUserStore';
import notificationStore from 'stores/NotificationStore';
import AdminUsersStore from 'stores/AdminUsersStore';
import LoadingStore from 'stores/LoadingStore';
import janusApi from 'apis/JanusApi';
import moment from 'moment';
import gqlApi from 'apis/GqlApi';
const { shouldUseMqidFlow } = authorization;

const {
  departmentsToRedseaRoles,
  rolesToRedseaRoles,
  supplementsToRedseaRoles,
  redseaDepartmentsDisplayMapping,
  redseaSupplementsDisplayMapping,
  upgradeOnlySupplements,
  swissquoteOnlySupplements,
} = redseaRoleMapping;

export default class ManagedUserStore extends ParentStore {
  // values
  dateCreated: ?number = null;
  dateUpdated: ?number = null;
  departments: Array<string> = [];
  email: string = '';
  slackHandle: string = '';
  originalEmail: string = '';
  firstName: string = '';
  lastName: string = '';
  id: ?number = null;
  orgName: string = '';
  orgType: string = '';
  programs: Array<*> = [];
  role: string = '';
  status: string = '';
  supplements: Array<string> = [];
  selectedRedseaUser: Object = {};
  redseaUserRolesRaw: Array<Object> = [];
  token: ?number = null;
  mfaEnabled: boolean = false;
  mfaTotpEnabled: boolean = false;
  phone: string = '';

  // actions
  async fetchRedseaUserInfo(params): Array {
    const userInfo = await currentUserStore.userStore.fetchSelectedRedseaUserData(params);
    if (!userInfo) return;
    this.phone = userInfo.phone;
    this.selectedRedseaUser = userInfo;
    this.redseaUserRolesRaw = userInfo.roles;
    this.token = userInfo.token;
    this.mfaEnabled = userInfo.mfa_enabled;
    this.mfaTotpEnabled = userInfo.mfa_totp_enabled;
    this.mfaTotpRevealed = userInfo.mfa_totp_revealed;
  }

  async initRedseaData(email, rethrowError = false): Array<Object> {
    // load redsea user data
    try {
      await this.fetchRedseaUserInfo({ email });
    } catch (e) {
      logger.error(e);
      if (rethrowError) {
        throw new Error(e);
      }
    }
  }

  async resendInvite(): ?Object {
    const data = { email: this.email };
    try {
      const response = await janusApi.postData(`/diva/security/users/invite/send`, data);
      if (response) {
        notificationStore.notify('success', 'User Invitation Resent');
      }
      return response;
    } catch (error) {
      notificationStore.notify('error', 'Could not resend invite');
    }
  }

  async editJanusUser(): ?Object {
    if (!AdminUsersStore.allProgramsOptionsSelected) {
      this.clearAllProgramsEntryIfExists();
    }
    const params = Object.assign({}, this.janusAsJson);
    delete params.org_name;
    delete params.email;
    delete params.slack_handle;

    return await janusApi.putData(`/diva/security/users/${this.id}`, params);
  }

  async changeJanusUserStatus(status) {
    return await janusApi.putData(`/diva/security/users/${this.id}`, {
      status,
    });
  }

  async editRedseaUser(): ?Object {
    const { updateRedseaUser } = currentUserStore.userStore;
    const params = Object.assign(this.redseaAsJson, {
      token: this.selectedRedseaUser.token,
      phone: this.phone,
      mfa_enabled: this.mfaEnabled,
      mfa_totp_enabled: this.mfaTotpEnabled,
      mfa_totp_revealed: this.mfaTotpRevealed,
    });
    return await updateRedseaUser(params);
  }

  async changeRedseaUserActive(active) {
    const { updateRedseaUser } = currentUserStore.userStore;
    return await updateRedseaUser({
      active,
      token: this.selectedRedseaUser.token,
    });
  }

  async saveEdits(): ?Object {
    try {
      const redseaResponse = await this.editRedseaUser();
      if (!redseaResponse) throw new Error('Could not edit user');
      const janusResponse = await this.editJanusUser();
      if (!janusResponse) throw new Error('Could not edit reporting permissions');
      return true;
    } catch (error) {
      notificationStore.notify('error', `Could not edit user: ${error.message}`);
    }
  }

  async changeStatus(): Object {
    const newJanusStatus = this.casedStringsEqual(this.status, 'Disabled') ? 'Active' : 'Disabled';
    const newRedseaActive = this.casedStringsEqual(newJanusStatus, 'Active') ? true : false;
    try {
      LoadingStore.startLoading();
      const redseaResponse = await this.changeRedseaUserActive(newRedseaActive);
      if (!redseaResponse) throw new Error('Could not change user status');
      const janusResponse = await this.changeJanusUserStatus(newJanusStatus);
      if (!janusResponse) throw new Error('Could not change permissions status');
      if (redseaResponse && janusResponse && janusResponse.code === 200) {
        this.status = newJanusStatus;
      }
    } catch (error) {
      notificationStore.notify('error', `Could not edit user: ${error.message}`);
    } finally {
      LoadingStore.stopLoading();
    }
  }

  async findJanusUser(email) {
    try {
      return await janusApi.getData(
        `/diva/security/users/email/${encodeURIComponent(this.email)}`,
        {},
        // swallow janus error notificiation
        { notify: () => {} }
      );
    } catch (error) {
      return {
        error: true,
        message: error.message,
      };
    }
  }

  async createUser() {
    if (shouldUseMqidFlow) {
      try {
        const { errors } = await this.mqidCreateUser();
        const firstErrorMessage = this.dig(errors, '0', 'message');
        if (firstErrorMessage) throw new Error(firstErrorMessage);
        return { error: false };
      } catch (e) {
        throw new Error(e);
      }
    }

    // check for janus user first
    const existingJanusUser = await this.findJanusUser(this.email);
    if (existingJanusUser && existingJanusUser.email) {
      throw new Error(`User with email: ${existingJanusUser.email} already exists`);
    }

    // if unify active create redsea user
    let redseaResult = null;
    let janusResult = null;
    try {
      // create redsea user
      redseaResult = await this.addRedseaUser();
    } catch (error) {
      const errorMessage =
        typeof error.message == 'string' ? error.message.toLowerCase() : String(error.message);
      if (errorMessage.includes('same email already exist')) {
        // if user already exists, initialize this store with existing user roles and redsea token
        await this.initRedseaData(this.email, true);
        // create janus user
        janusResult = await this.addJanusUser({ sendEmail: false });
        // then edit that redsea user with info added for new user
        await this.editRedseaUser();
      } else {
        throw new Error(error);
      }
    }

    if (!janusResult) {
      // create janus user
      janusResult = await this.addJanusUser({ sendEmail: false });
    }

    return {
      error: false,
      redseaResult,
      janusResult,
    };
  }

  async mqidCreateUser() {
    const webToken = secureStorage.getItem('webToken');
    const { roles, ...redseaData } = this.redseaAsJson;

    const { role, ...janusData } = this.janusAsJson;

    const requestBody = {
      ...redseaData,
      ...janusData,
      redsea_roles: this.redseaAsJson.roles,
      janus_role: this.janusAsJson.role,
      janus_jwt: webToken,
    };

    try {
      const result = await gqlApi.gqlMutation(
        `mutation mqidCreateUsers(
          $email: String!
          $first_name: String!
          $last_name: String!
          $slack_handle: String
          $org_name: String!
          $janus_role: String!
          $programs: [ProgramInput]!
          $departments: [String]!
          $supplements: [String]
          $redsea_roles: [RedseaRolesInput]!
          $janus_jwt: String!
        ) {
          mqidCreateUsers(
            email: $email
            first_name: $first_name
            last_name: $last_name
            slack_handle: $slack_handle
            org_name: $org_name
            janus_role: $janus_role
            programs: $programs
            departments: $departments
            supplements: $supplements
            redsea_roles: $redsea_roles
            janus_jwt: $janus_jwt
          ) {
            redsea_user {
              token
              email
              active
              roles {
                domain_type
                domain_id
                role_token
                environment
              }
            }
            janus_user {
              id
              mqid_org_id
            }
            mqid_invitation {
              id
              name
              email
              requestor
              organization
              provider
              email_verified
              accepted_at
              expires_at
              created_at
              updated_at
            }
          }
        }`,
        requestBody,
        [],
        'no-cache',
        true
      );
      const userData = result && result.data;
      if (!userData) throw new Error('Could not create user');
      return userData;
    } catch (error) {
      const errorMessage = error.message.split('GraphQL error: ')[1];
      if (errorMessage) {
        throw new Error(errorMessage);
      } else {
        throw new Error(error);
      }
    }
  }

  async addRedseaUser() {
    try {
      const result = await gqlApi.gqlMutation(
        `mutation createUser(
          $first_name: String!
          $last_name: String!
          $email: String!
          $roles: [UserRoleInput]
        ) {
          createUser(
            first_name: $first_name
            last_name: $last_name
            email: $email
            roles: $roles
          ) {
            token
            email
          }
        }`,
        this.redseaAsJson,
        [],
        'no-cache',
        true
      );
      const userData = result && result.data;
      if (!userData) throw new Error('Could not create user');
      return userData;
    } catch (error) {
      const errorMessage = error.message.split('GraphQL error: ')[1];
      if (errorMessage) {
        throw new Error(errorMessage);
      } else {
        throw new Error(error);
      }
    }
  }

  async addJanusUser({ sendEmail = false }) {
    return await janusApi.postData(
      `/diva/security/users${sendEmail ? '?send_email_invite=true' : ''}`,
      this.janusAsJson
    );
  }

  async resetUserMfa() {
    const webToken = secureStorage.getItem('webToken');
    this.loading = true;

    const payload = {
      token: this.token,
      mfa_enabled: this.mfaEnabled,
      mfa_totp_enabled: this.mfaTotpEnabled,
      mfa_totp_revealed: false,
      webToken,
      ...(this.mfaType === 'SMS' && { phone: '' }),
    };

    try {
      const result = await gqlApi.gqlMutation(
        `mutation updateUser (
            $token: String
            $mfa_enabled: Boolean
            $mfa_totp_enabled: Boolean
            $mfa_totp_revealed: Boolean
            $webToken: String!
            $phone: String
          ) {
            updateUser(
              token: $token
              mfa_enabled: $mfa_enabled
              mfa_totp_enabled: $mfa_totp_enabled
              mfa_totp_revealed: $mfa_totp_revealed
              webToken: $webToken
              phone: $phone
            ) {
              token
              mfa_enabled    
              mfa_totp_enabled
              mfa_totp_revealed
              phone
            }
          }
          `,
        payload
      );
      const userData = result && result.data;
      if (!userData) throw new Error('Could not reset user totp mfa.');
      return userData;
    } catch (error) {
      throw new Error(error);
    } finally {
      this.loading = false;
    }
  }

  toggleValueInProgramsArray(programEntry: Object): void {
    if (programEntry) {
      const programIndex = this.getProgramIndex(programEntry);
      if (programIndex !== -1) {
        this.programs.splice(programIndex, 1);
      } else {
        this.clearAllProgramsEntryIfExists();
        this.programs.push(programEntry);
      }
    }
  }

  addProgram(programEntry: Object): void {
    if (!programEntry) {
      return null;
    }
    const programIndex = this.getProgramIndex(programEntry);
    if (programIndex === -1) {
      // $FlowFixMe
      this.programs.push(programEntry);
    }
  }

  clearAllProgramsEntryIfExists(): void {
    const allIndex = this.getProgramIndex({
      program: 'All',
      short_name: 'All',
    });
    if (allIndex !== -1) {
      this.programs.splice(allIndex, 1);
    }
  }

  updateOrg(org: string, programsOptions: Array<Object>): void {
    this.orgName = org;
    this.orgType = AdminUsersStore.getOrgTypeByName(org);
    let programs = [];
    const activeOrg = programsOptions.find((prog) => {
      return prog.org_name === this.orgName;
    });
    if (activeOrg && activeOrg.programs && activeOrg.programs.toJS) {
      programs = activeOrg.programs.toJS();
    }
    this.programs = programs;
    //When creating a user, if you switch the org to a non-upgrade org, remove upgrade specific supplements if present
    if (org !== 'Upgrade') {
      this.supplements = this.supplements.filter((supp) => !upgradeOnlySupplements.includes(supp));
    }

    // when creating a user, if you switch the org to a non-Swissquote org, remove all Swissquote specific supplements if present
    if (org !== 'Swissquote Bank') {
      this.supplements = this.supplements.filter(
        (supplement) => !swissquoteOnlySupplements.includes(supplement)
      );
    }
  }

  // computed
  // departments
  toggleExtendedDepartment(department) {
    // look to delete from this.redseaUserRolesRaw first
    const toggledRoleToken =
      redseaRoleMapping.departmentsToRedseaRoles[department && department.toUpperCase()];
    let foundInRedseaRolesRaw = false;
    this.editableRedseaRoles.forEach((editableRole) => {
      const removeEditableToken = editableRole.role_token === toggledRoleToken;
      if (removeEditableToken) {
        foundInRedseaRolesRaw = true;
        this.redseaUserRolesRaw = this.redseaUserRolesRaw.filter((rawRole) => {
          return !(
            rawRole.role_token === editableRole.role_token &&
            rawRole.domain_type === editableRole.domain_type
          );
        });
      }
    });
    // if the department is found in redseaUserRolesRaw and is not found in this.departments
    // then this.redseaUserRolesRaw is "managing" it for the moment
    const redseaRawManagingRole = !foundInRedseaRolesRaw || this.departments.includes(department);
    if (redseaRawManagingRole) {
      this.toggleValueInAttrArray('departments', department);
    }
  }

  get activeDepartmentsExtended(): Array<string> {
    return [].concat(this.departmentsJsArray, this.mappedEditableDepartmentsToRedseaRoles);
  }

  get departmentsString(): string {
    return this.activeDepartmentsExtended.sort().join(', ') || '';
  }

  get mappedEditableDepartmentsToRedseaRoles(): Array<string> {
    return this.uniqueEditableRoleTokens
      .map((role) => {
        return redseaDepartmentsDisplayMapping[role && role.toUpperCase()];
      })
      .filter(Boolean);
  }

  get mappedDisplayDepartmentsToRedseaRoles(): Array<string> {
    return this.activeDepartmentsExtended.map(
      (dept) => departmentsToRedseaRoles[dept && dept.toUpperCase()]
    );
  }

  get departmentsJsArray(): Array<?string> {
    return typeof this.departments.toJS === 'function' ? this.departments.toJS() : [];
  }

  // supplements
  toggleExtendedSupplement(sup) {
    // look to delete from this.redseaUserRolesRaw first
    const toggledRoleToken = supplementsToRedseaRoles[sup && sup.toUpperCase()];
    let foundInRedseaRolesRaw = false;
    this.editableRedseaRoles.forEach((editableRole) => {
      const removeEditableToken = editableRole.role_token === toggledRoleToken;
      if (removeEditableToken) {
        foundInRedseaRolesRaw = true;
        this.redseaUserRolesRaw = this.redseaUserRolesRaw.filter((rawRole) => {
          return !(
            rawRole.role_token === editableRole.role_token &&
            rawRole.domain_type === editableRole.domain_type
          );
        });
      }
    });
    // if the supplement is found in redseaUserRolesRaw and is not found in this.supplements
    // then this.redseaUserRolesRaw is "managing" it for the moment
    const redseaRawManagingRole = !foundInRedseaRolesRaw || this.supplements.includes(sup);
    if (redseaRawManagingRole) {
      this.toggleValueInAttrArray('supplements', sup);
    }
  }

  get activeSupplementsExtended(): Array<string> {
    return [].concat(this.supplementsJsArray, this.mappedEditableSupplementsToRedseaRoles);
  }

  get supplementsString(): string {
    return this.activeSupplementsExtended.sort().join(', ') || '';
  }

  get mappedEditableSupplementsToRedseaRoles(): Array<string> {
    return this.uniqueEditableRoleTokens
      .map((role) => {
        return redseaSupplementsDisplayMapping[role && role.toUpperCase()];
      })
      .filter(Boolean);
  }

  get mappedDisplaySupplementsToRedseaRoles(): Array<string> {
    return this.activeSupplementsExtended.map(
      (sup) => supplementsToRedseaRoles[sup && sup.toUpperCase()]
    );
  }

  get supplementsJsArray(): Array<?string> {
    return typeof this.supplements.toJS === 'function' ? this.supplements.toJS() : [];
  }

  // programs
  get programsNames(): Array<string> {
    return this.programsJSArray.map((program) =>
      program && typeof program.program === 'string' ? program.program : ''
    );
  }

  get programsString(): string {
    return this.programsNames.sort().join(', ') || '';
  }

  get programsJSArray(): Array<?string> {
    return typeof this.programs.toJS === 'function' ? this.programs.toJS() : [];
  }

  // redsea roles operations
  get currentRedseaRoles(): Object[] {
    let role = this.role;
    const marqetaUser = this.casedStringsEqual(this.orgName, 'Marqeta');
    const isAdmin = this.casedStringsEqual(role, 'Admin');
    if (marqetaUser && isAdmin) {
      role = 'MARQETA_ADMIN';
    }
    const mappedRole = rolesToRedseaRoles[role && role.toUpperCase()];
    const roleNames = []
      .concat(
        mappedRole,
        this.mappedDisplayDepartmentsToRedseaRoles,
        this.mappedDisplaySupplementsToRedseaRoles
      )
      .filter(Boolean);
    const uniqueRoleNames = [...new Set(roleNames)];
    let programs = this.programs;
    if (AdminUsersStore.allProgramsOptionsSelected && marqetaUser) {
      programs = [{ program: 'All', short_name: 'All' }];
    }
    const expandedRoles = this.expandRolesToPrograms(uniqueRoleNames, programs);
    return [].concat(
      expandedRoles,
      this.uneditableRedseaRoles.map(({ domain_type, domain_id, role_token, environment }) => ({
        domain_type,
        domain_id,
        role_token,
        environment,
      }))
    );
  }

  get editableRedseaRoles(): Array<Object> {
    const { activeOrgPrograms } = AdminUsersStore;
    const allowedProgramShortcodes =
      activeOrgPrograms && activeOrgPrograms.map((prog) => prog && prog.short_name);
    // this is to support permissions that are based on org, first use case is p:marqeta:permission
    allowedProgramShortcodes.push(String(this.orgName).toLowerCase());
    return this.redseaUserRolesRaw.filter(({ domain_type, domain_id }) => {
      return (
        this.casedStringsEqual(domain_type, 'program') &&
        allowedProgramShortcodes.includes(String(domain_id).toLowerCase())
      );
    });
  }

  get uniqueEditableRoleTokens(): Array<string> {
    const editableRoleTokens = this.editableRedseaRoles.map(({ role_token }) => role_token);
    return [...new Set(editableRoleTokens)].filter(Boolean);
  }

  get uneditableRedseaRoles(): Array<Object> {
    const { activeOrgPrograms } = AdminUsersStore;
    const redseaUserRolesRawJSObject = toJS(this.redseaUserRolesRaw);
    const allowedProgramShortcodes =
      activeOrgPrograms && activeOrgPrograms.map((prog) => prog && prog.short_name);
    // If the role is for a program that is not an activeOrgProgram
    // (like what you would see for sandbox) this role should be uneditable.
    const nonActiveOrgProgramRoles = redseaUserRolesRawJSObject.filter(
      ({ domain_type, domain_id }) => {
        const programNotPartOfActiveOrg = !(
          this.casedStringsEqual(domain_type, 'program') &&
          allowedProgramShortcodes.includes(domain_id)
        );
        // this is to handle the edge case of all programs mapping to the marqeta domain_id,
        // but 'marqeta' is not included in activeOrgPrograms
        const programIsNotMarqeta = !(
          this.casedStringsEqual(domain_type, 'program') &&
          this.casedStringsEqual(domain_id, 'marqeta')
        );
        return programNotPartOfActiveOrg && programIsNotMarqeta;
      }
    );

    // If a non-granular-permission Admin is editing a user who does have granular permissions
    // we want to preserve any granular permission roles that the user currently has. All GP role tokens
    // end with '-view' or '-view-and-edit' so we can filter them by using that substring.
    let granularPermissionRoles = [];

    granularPermissionRoles = redseaUserRolesRawJSObject.filter((role) =>
      role.role_token.includes('-view')
    );

    const uneditableRoles = Array.from(
      new Set([...nonActiveOrgProgramRoles, ...granularPermissionRoles])
    );
    return uneditableRoles;
  }

  // other
  get dateCreatedString(): string {
    return this.dateCreated ? moment(this.dateCreated).utc().format('MM/DD/YYYY h:mm A') : '--';
  }

  get dateUpdatedString(): string {
    return this.dateUpdated ? moment(this.dateUpdated).utc().format('MM/DD/YYYY h:mm A') : '--';
  }

  get emailIsValid(): Boolean {
    if (!this.email) {
      return false;
    }
    const re = /\S+@\S+\.\S+/;
    const validEmail = re.test(this.email);
    return validEmail ? true : false;
  }

  get userIsValid(): Boolean {
    return (
      this.emailIsValid &&
      this.role &&
      this.orgName &&
      this.firstName &&
      this.lastName &&
      this.programsNames.length &&
      this.binDataRolesAreValid
    );
  }

  get actAsAllowed(): Boolean {
    return (
      this.actionsAllowed ||
      this.casedStringsEqual(currentUserStore.userStore.permissionLevel, 'Support')
    );
  }

  get actionsAllowed(): Boolean {
    return currentUserStore.userStore.permissionsGreaterThanOrEqualTo(this.role);
  }

  get roleOptions(): Array<string> {
    // SuperAdmin, Admin, Support or Viewer
    const userRole = currentUserStore.userStore.userRole;
    if (
      this.casedStringsEqual(userRole, 'SuperAdmin') &&
      this.casedStringsEqual(this.orgName, 'Marqeta')
    ) {
      return ['SuperAdmin', 'Admin', 'Support', 'Viewer'];
    } else if (['SuperAdmin', 'Admin'].includes(userRole)) {
      return ['Admin', 'Viewer'];
    }
    return ['Viewer'];
  }

  get isEmailMarqeta(): Boolean {
    const email = typeof this.email === 'string' ? this.email.toUpperCase() : '';
    return email.endsWith('@MARQETA.COM');
  }

  get janusAsJson(): Object {
    const email = this.email.trim ? this.email.trim() : this.email;
    let programs = toJS(this.programs);
    if (
      AdminUsersStore.allProgramsOptionsSelected &&
      ['internal', 'bank'].includes(String(this.orgType).toLowerCase())
    ) {
      programs = [{ program: 'All', short_name: 'All' }];
    }

    const selectedJanusDepartments = this.departments.filter((selectedDepartment) => {
      return AdminUsersStore.janusDepartmentsOptionsJsArray.includes(selectedDepartment);
    });

    const selectedJanusSupplements = this.supplements.filter((selectedSupplement) => {
      return AdminUsersStore.janusSupplementsOptionsJsArray.includes(selectedSupplement);
    });

    const payload = {
      email,
      slack_handle: this.slackHandle,
      first_name: this.firstName,
      last_name: this.lastName,
      role: this.role,
      org_name: this.orgName,
      departments: selectedJanusDepartments,
      supplements: selectedJanusSupplements,
    };

    if (programs.length) payload.programs = programs;

    return payload;
  }

  get redseaAsJson(): Object {
    const email = this.email.trim ? this.email.trim() : this.email;
    return {
      first_name: this.firstName,
      last_name: this.lastName,
      email,
      roles: this.currentRedseaRoles,
    };
  }

  get mfaType(): string {
    let mfaType = '';
    if (this.mfaEnabled || this.mfaTotpEnabled) {
      mfaType = this.mfaTotpEnabled ? 'Google authenticator' : 'SMS';
    }
    return mfaType;
  }

  // helpers
  expandRolesToPrograms(roles, programs) {
    const output = [];
    const env = localStorage.getItem(ENVIRONMENT_LOCAL_STORAGE_KEY);
    programs.forEach((program) => {
      let domain_id = program.short_name;
      if (this.casedStringsEqual(program.short_name, 'All')) {
        // this is to support the use case of both marqeta org and banks being able to select all
        // NOTE! this is something that is supported in Janus but is not yet supported in Redsea
        domain_id = String(this.orgName).toLowerCase();
      }
      roles.forEach((role) => {
        output.push({
          domain_type: 'program',
          domain_id,
          role_token: role,
          ...(env && { environment: env }),
        });
      });
    });
    return output;
  }

  getProgramIndex(activeProgram: Object): number {
    if (activeProgram && activeProgram.program) {
      const programName = activeProgram.program;
      for (let i = 0; i < this.programs.length; i++) {
        const program = this.programs[i];
        if (program.program === programName) {
          return i;
        }
      }
    }
    return -1;
  }

  permissionsGreaterThan(permissionLevel: string): Boolean {
    const permissionsLevels = ['Viewer', 'Admin', 'SuperAdmin'];
    const managedUserPermissionLevel = permissionsLevels.indexOf(this.role);
    const checkPermissionLevel = permissionsLevels.indexOf(permissionLevel);
    if (managedUserPermissionLevel === -1 || checkPermissionLevel === -1) {
      // if not found refturn false to be safe
      return false;
    }
    return managedUserPermissionLevel > checkPermissionLevel;
  }

  get binDataRolesAreValid(): Boolean {
    const hasReviewSupplement = this.activeSupplementsExtended.includes(
      redseaSupplementsDisplayMapping['REVIEW-BIN-DATA-INTERNAL']
    );
    const hasManageSupplement = this.activeSupplementsExtended.includes(
      redseaSupplementsDisplayMapping['MANAGE-BIN-DATA-INTERNAL']
    );
    const hasViewSupplement = this.activeSupplementsExtended.includes(
      redseaSupplementsDisplayMapping['VIEW-BIN-DATA-INTERNAL']
    );

    return hasReviewSupplement
      ? hasManageSupplement && !hasViewSupplement
      : !(hasViewSupplement && hasManageSupplement);
  }
}

decorate(ManagedUserStore, {
  // values
  dateCreated: observable,
  dateUpdated: observable,
  departments: observable,
  email: observable,
  slackHandle: observable,
  originalEmail: observable,
  firstName: observable,
  lastName: observable,
  id: observable,
  orgName: observable,
  orgType: observable,
  programs: observable,
  role: observable,
  status: observable,
  supplements: observable,
  selectedRedseaUser: observable,
  redseaUserRolesRaw: observable,
  token: observable,
  mfaEnabled: observable,
  mfaTotpEnabled: observable,
  phone: observable,

  // actions
  resendInvite: action.bound,
  editRedseaUser: action.bound,
  saveEdits: action.bound,
  saveAlertEdits: action.bound,
  changeStatus: action.bound,
  toggleValueInProgramsArray: action.bound,
  addProgram: action.bound,
  clearAllProgramsEntryIfExists: action.bound,
  updateOrg: action.bound,
  initRedseaData: action.bound,
  fetchRedseaUserInfo: action.bound,
  createUser: action.bound,
  addRedseaUser: action.bound,
  addJanusUser: action.bound,
  toggleExtendedDepartment: action.bound,
  toggleExtendedSupplement: action.bound,
  changeRedseaUserActive: action.bound,
  changeJanusUserStatus: action.bound,
  resetUserMfa: action.bound,

  // computed
  departmentsString: computed,
  departmentsJsArray: computed,
  supplementsJsArray: computed,
  programsJSArray: computed,
  programsNames: computed,
  programsString: computed,
  emailIsValid: computed,
  userIsValid: computed,
  actionsAllowed: computed,
  actAsAllowed: computed,
  roleOptions: computed,
  isEmailMarqeta: computed,
  janusAsJson: computed,
  redseaAsJson: computed,
  currentRedseaRoles: computed,
  editableRedseaRoles: computed,
  uneditableRedseaRoles: computed,
  supplementsString: computed,
  uniqueEditableRoleTokens: computed,
  activeDepartmentsExtended: computed,
  activeSupplementsExtended: computed,
  mappedEditableDepartmentsToRedseaRoles: computed,
  mappedDisplayDepartmentsToRedseaRoles: computed,
  mappedEditableSupplementsToRedseaRoles: computed,
  mappedDisplaySupplementsToRedseaRoles: computed,
  dateCreatedString: computed,
  dateUpdatedString: computed,
  mfaType: computed,
  binDataRolesAreValid: computed,
});
