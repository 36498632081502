export const kycStatuses = {
  SUCCESS: 'Success',
  FAILURE: 'Failure',
  PENDING: 'Pending',
  VENDOR_PENDING: 'Vendor Pending',
};

export const kycStatusIcons = {
  SUCCESS: 'confirmed',
  FAILURE: 'warning',
  PENDING: 'clock',
  VENDOR_PENDING: 'clock',
};

export const kycStatusColors = {
  SUCCESS: 'statusSuccessColor',
  FAILURE: 'statusErrorColor',
  PENDING: 'statusWarningColor',
  VENDOR_PENDING: 'statusWarningColor',
};

export const toastMessages = {
  manualOverrideSuccess: 'Manual override successful',
  manualOverrideError: 'Manual override unsuccessful',
  createReviewError: 'Review creation unsuccessful',
};
