import React, { useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Modal, Button, Text, VSpacer, Select, Textarea } from '@mqd/volt-base';

import { reasonCodes as TERMINATE_REASONS } from '../constants';

function TerminateTokenModal({
  showTerminateModal,
  hideTerminateModal,
  handleTermination,
  setToastProps,
}) {
  const [selectedReasonCode, setSelectedReasonCode] = useState(null);
  const [reasonText, setReasonText] = useState('');

  if (!showTerminateModal) return null;

  const ModalReasonHeader = () => (
    <>
      <Text type="h5">Are you sure?</Text>

      <VSpacer />
      <Text>Your current token will become permanently non-functional upon termination</Text>

      <VSpacer factor={2} />
    </>
  );

  const submitTerminateToken = async () => {
    if (selectedReasonCode) {
      const response = await handleTermination({
        reason_code: selectedReasonCode.val,
        ...(reasonText && { reason: reasonText }),
      });

      if (response) {
        setToastProps({ text: 'Token terminated successfully' });
      }
    }
  };

  return (
    <Modal
      width="588px"
      hideModal={hideTerminateModal}
      disableOverFlow
      footerButtons={[
        <Button
          type="danger"
          onClick={submitTerminateToken}
          testId="digital-wallet-token-table-modal-terminate"
          disabled={!selectedReasonCode}
        >
          Terminate token
        </Button>,
      ]}
    >
      <>
        <ModalReasonHeader />
        <Select
          testId="terminate-token-reason-select"
          label="Select reason"
          placeholder="Select an option"
          options={TERMINATE_REASONS}
          value={selectedReasonCode}
          onChange={(selected) => setSelectedReasonCode(selected)}
        />

        <VSpacer factor={3} />
        <Textarea
          testId="terminate-token-textarea"
          label="Add note to token transition (optional)"
          rows={2}
          value={reasonText}
          onChange={(e) => setReasonText(e.target.value)}
          maxLength={255}
        />
      </>
    </Modal>
  );
}

TerminateTokenModal.propTypes = {
  showTerminateModal: PropTypes.bool,
  hideTerminateModal: PropTypes.func,
  handleTermination: PropTypes.func,
};

TerminateTokenModal.defaultProps = {
  showTerminateModal: false,
  hideTerminateModal: () => {},
  handleTermination: () => {},
};

export default observer(TerminateTokenModal);
