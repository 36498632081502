import React from 'react';
import PropTypes from 'prop-types';
import { ActionsCell } from '@mq/voltron-table';
import { flipFlop } from '@mq/volt-amc-container';

const TransactionsTableActionsCell = (props) => {
  const removeZendeskAction = flipFlop.get(
    'disputes-transactions-table-action-remove-zendesk',
    false
  );

  const {
    canDisputeTransaction,
    canReturnTransaction,
    canCancelBankTransfer,
    isDisputable,
    isReturnable,
    isBankTransferCancellable,
    canAccessDisputes,
    returnAction,
    cancelBankTransfer,
    goToNewDispute,
    showZendeskModal,
    // TODO - DE-3762 Remove Below When Feature Flag Turned ON
    canCreateLegacyDispute,
  } = props;

  const renderDisputeActions = () => {
    if (removeZendeskAction) {
      if (canAccessDisputes) {
        return [
          {
            text: 'Dispute',
            onClick: goToNewDispute,
          },
        ];
      }
      return [];
    } else {
      
      // This is the default code to run until feature flag is turned on
      if (canAccessDisputes) {
        const actions = [
          {
            text: 'Dispute',
            onClick: goToNewDispute,
          },
        ];

        if (canCreateLegacyDispute) {
          actions.push({
            text: 'Disputes (legacy)',
            onClick: showZendeskModal,
          });
        }
        return actions;
      }
      return [{ text: 'Dispute', onClick: props.showZendeskModal }];
    }
  };

  const getActions = () => {
    if (canDisputeTransaction && isDisputable) {
      return renderDisputeActions();
    }

    if (canReturnTransaction && isReturnable) {
      return [
        {
          text: 'Return',
          onClick: returnAction,
        },
      ];
    }

    if (canCancelBankTransfer && isBankTransferCancellable) {
      return [
        {
          text: 'Cancel transfer',
          onClick: cancelBankTransfer,
        },
      ];
    }

    return [
      {
        text: 'Transaction can not be disputed',
        disabled: true,
      },
      {
        text: 'Bank transfer can not be cancelable',
        disabled: true,
      },
    ];
  };

  return <ActionsCell actions={getActions()} />;
};

TransactionsTableActionsCell.propTypes = {
  canDisputeTransaction: PropTypes.bool.isRequired,
  isDisputable: PropTypes.bool.isRequired,
  canReturnTransaction: PropTypes.bool.isRequired,
  isReturnable: PropTypes.bool.isRequired,
  canAccessDisputes: PropTypes.bool.isRequired,
  goToNewDispute: PropTypes.func.isRequired,
  showZendeskModal: PropTypes.func.isRequired,
  returnAction: PropTypes.func.isRequired,
  canCancelBankTransfer: PropTypes.bool.isRequired,
  isBankTransferCancellable: PropTypes.bool.isRequired,
  cancelBankTransfer: PropTypes.func.isRequired,
  // TODO - DE-3762 Remove Below When Feature Flag Turned ON
  canCreateLegacyDispute: PropTypes.bool.isRequired,
};

export default TransactionsTableActionsCell;
