import React, { useEffect, useMemo, useState } from 'react';
import { Button, Flex, HSpacer, LoadingOverlay, Modal, Text } from '@mqd/volt-base';
import { StoreContext } from '@mqd/volt-contexts';
import RemoveFundsForm from './RemoveFundsForm';
import ConfirmRemoveFundsForm from './ConfirmRemoveFundsForm';
import { dig, formatAmount } from './../shared-utils';
import PropTypes from 'prop-types';

function RemoveFundsModal({
  fundingSourceType,
  fundingSourceStore,
  hideModal,
  showSuccessToast,
  showErrorToast,
}) {
  const [gpaOrders, setGpaOrders] = useState([]);
  const [formState, setFormState] = useState({});
  const [showConfirm, setShowConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const {
    fullName = 'Unnamed User',
    business_name_legal = 'Unnamed business',
    getGpaOrders,
    removeFunds,
  } = fundingSourceStore;

  const isCardholder = fundingSourceType === 'cardholder';
  const gpa = dig(fundingSourceStore, 'gpa_balance', 'gpa');

  const gpaOrdersOptions = useMemo(
    () =>
      gpaOrders.map(({ amount, gpa_order }) => {
        const { token } = gpa_order;
        return `${token} - ${formatAmount(amount)}`;
      }),
    [gpaOrders]
  );

  useEffect(() => {
    (async () => {
      if (getGpaOrders) {
        try {
          setLoading(true);
          const gpaOrders = await getGpaOrders();
          gpaOrders && setGpaOrders(gpaOrders);
        } catch (error) {
          setError(error);
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [getGpaOrders]);

  const handleFormChange = ({ amount, currencyCode, originalGpaOrder, unloadOrderToken }) => {
    let originalGpaOrderObject;
    if (originalGpaOrder) {
      originalGpaOrderObject = gpaOrders.find((gpaOrder) => {
        const tempGpaOrderToken = dig(gpaOrder, 'gpa_order', 'token');
        return tempGpaOrderToken === originalGpaOrder.split(' ')[0];
      });
    }
    const gpaOrderToken = dig(originalGpaOrderObject, 'gpa_order', 'token');
    setFormState({
      amount,
      currencyCode,
      originalGpaOrderToken: gpaOrderToken && gpaOrderToken,
      unloadOrderToken,
    });
  };

  const handleSubmit = async ({ amount, originalGpaOrderToken, unloadOrderToken }) => {
    const params = {
      amount,
      original_order_token: originalGpaOrderToken,
      ...(unloadOrderToken && { token: unloadOrderToken }),
    };
    try {
      setError(null);
      setLoading(true);
      await removeFunds({ ...params });
      showSuccessToast();
      hideModal();
    } catch (error) {
      showErrorToast();
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const formButtons = [<Button onClick={() => setShowConfirm(true)}>Continue</Button>];

  const confirmFormButtons = [
    <Button onClick={() => setShowConfirm(false)} type="tertiary">
      Cancel
    </Button>,
    <Button onClick={() => handleSubmit(formState)}>Submit request</Button>,
  ];

  return (
    <StoreContext.Consumer>
      {({ ProgramConfigStore = {} } = {}) => {
        const { defaultCurrency } = ProgramConfigStore;
        return (
          <>
            <Modal
              heading={
                showConfirm
                  ? 'Confirm removing funds request'
                  : `Remove funds from ${isCardholder ? 'user' : 'business'}`
              }
              error={error}
              description={
                <Flex flexDirection="row">
                  <Text>Ledger balance for {isCardholder ? fullName : business_name_legal}:</Text>
                  <HSpacer factor={0.5} />
                  <Text type="h6">
                    {gpa && gpa.currency_code} {gpa && formatAmount(gpa.ledger_balance)}
                  </Text>
                </Flex>
              }
              disableOverFlow
              hideModal={hideModal}
              showHideModalButton={!showConfirm}
              footerButtons={showConfirm ? confirmFormButtons : formButtons}
            >
              <LoadingOverlay active={loading}>
                {showConfirm ? (
                  <ConfirmRemoveFundsForm formState={formState} />
                ) : (
                  <RemoveFundsForm
                    defaultCurrency={defaultCurrency}
                    formState={formState}
                    gpaOrders={gpaOrdersOptions}
                    onFormChange={handleFormChange}
                  />
                )}
              </LoadingOverlay>
            </Modal>
          </>
        );
      }}
    </StoreContext.Consumer>
  );
}

RemoveFundsModal.propTypes = {
  fundingSourceType: PropTypes.string,
  fundingSourceStore: PropTypes.object,
  hideModal: PropTypes.func,
  showSuccessToast: PropTypes.func,
  showErrorToast: PropTypes.func,
};

RemoveFundsModal.defaultProps = {
  fundingSourceType: '',
  fundingSourceStore: {},
  hideModal: () => {},
  showSuccessToast: () => {},
  showErrorToast: () => {},
};

export default RemoveFundsModal;
