import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Text, HSpacer, Tooltip, Icon, Colors } from '@mqd/volt-base';
import { getTotalAmount } from '../utils';

const TotalAmount = ({ transactions }) => {
  const total = getTotalAmount(transactions);
  const textComponentProps = {
    type: 'h6',
  };
  if (total > 0) textComponentProps.color = Colors.aaGreen;

  return <Text {...textComponentProps}>{Math.abs(total)} USD</Text>;
};

const SummaryRow = ({ transactions }) => {
  const tooltipStyle = {
    textTransform: 'none',
    letterSpacing: 'normal',
  };

  return (
    <Flex
      testId="txn-summary-row"
      display="flex"
      justifyContent="space-between"
      flexGrow={1}
      alignItems="center"
    >
      <Flex display="flex" alignItems="flex-end">
        <Text type="h6">Total transaction amount (filter applied)</Text>
        <HSpacer factor={0.5} />
        <Tooltip
          direction="topLeft"
          content="The total transactions amount only applies to the transactions displayed on this page, after a Type or Status filter has been applied."
          style={tooltipStyle}
        >
          <Icon type="info" noHoverEffects />
        </Tooltip>
      </Flex>
      <TotalAmount transactions={transactions} />
    </Flex>
  );
};

SummaryRow.propTypes = {
  transactions: PropTypes.object,
};

SummaryRow.defaultProps = {
  transactions: [],
};

export default SummaryRow;
