import { decorate, observable, computed } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';

class AddressStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
  }
  // values
  first_name: string = '';
  middle_name: string = '';
  last_name: string = '';
  address1: string = '';
  address2: string = '';
  city: string = '';
  state: string = '';
  postal_code: string = '';
  country: string = '';
  phone: string = '';
  zip: string = '';

  // computed
  get displayUS() {
    const lines = [];
    const name = [this.first_name, this.middle_name, this.last_name].filter(Boolean);
    if (name.length) lines.push(name.join(' '));
    if (this.address1) lines.push(this.address1);
    if (this.address2) lines.push(this.address2);

    if (this.city || this.state || this.postal_code || this.zip) {
      let line = [];
      if (this.city) line.push(this.city);
      if (this.state) line.push(this.state);
      if (line.length) {
        line = [line.join(', ')];
      }
      if (this.postal_code || this.zip) line.push(this.postal_code || this.zip);
      lines.push(line.join(' '));
    }
    if (this.country) lines.push(this.country);
    return lines.join('\n');
  }

  get addressCorrect() {
    let addressCorrect = true;
    if (
      this.address1 === '' ||
      this.city === '' ||
      this.state === '' ||
      this.country === '' ||
      (this.zip === '' && this.postal_code === '') ||
      this.address1 === null ||
      this.city === null ||
      this.state === null ||
      this.country === null ||
      (this.zip === null && this.postal_code === null)
    ) {
      addressCorrect = false;
    }

    return addressCorrect;
  }
}

decorate(AddressStore, {
  // values
  first_name: observable,
  middle_name: observable,
  last_name: observable,
  address1: observable,
  address2: observable,
  city: observable,
  state: observable,
  postal_code: observable,
  country: observable,
  phone: observable,

  // computed
  displayUS: computed,
  addressCorrect: computed,
});

export default AddressStore;
