import React, { useMemo } from 'react';
import s from './FlexContainer.module.css';
import { capitalize } from '../../utilities/string/index.js';

const capitalizeString = (val = '') => {
  const lowerCase = val.toLowerCase();
  const splitOnHyphens = lowerCase.split('-');

  return splitOnHyphens.map((str) => capitalize(str)).join('');
};

const generateClassNames = (options) => {
  return Object.entries(options)
    .map(([key, value]) => s[`${key}${capitalizeString(value)}`])
    .join(' ');
};

const FlexContainer = ({
  direction,
  justifyContent,
  alignItems,
  children,
  type = 'div',
  flex,
  ...props
}) => {
  const classNames = useMemo(
    () =>
      generateClassNames({
        flexDirection: direction,
        justifyContent,
        alignItems,
      }),
    [alignItems, direction, justifyContent]
  );

  return React.createElement(
    type,
    {
      className: `${s.flex} ${classNames}`,
      style: {
        flex,
      },
      ...props,
    },
    children
  );
};

export default FlexContainer;
