import React from 'react';
import { Input, Form, VSpacer } from '@mqd/volt-base';

const noSpaceError = 'Invalid Entry. Spaces are not allowed';

const EditTemplate = ({ formState, children, handleFormChange }) => {
  return (
    <Form onFormChange={handleFormChange} initialState={formState}>
      <Input
        name="logo_file"
        label="Logo file name"
        maxLength={50}
        error={formState.logo_file && formState.logo_file.includes(' ') ? noSpaceError : ''}
      />
      <VSpacer factor={3} />
      <Input name="message_line" label="Message line" maxLength={60} testId={'message_line'} />
      <VSpacer factor={3} />
      <Input
        name="carrier_return_window"
        label="Carrier return window"
        maxLength={50}
        testId={'carrier_return_window'}
        labelTooltip="Specifies a PNG image to display in the return-address window of envelopes used for sending cards to cardholders."
        error={
          formState.carrier_return_window && formState.carrier_return_window.includes(' ')
            ? noSpaceError
            : ''
        }
      />
      {children}
    </Form>
  );
};

export default EditTemplate;
