import { secureStorage } from '@mq/volt-amc-container';
import { loadFavoriteProgramsFromLocalStorage } from './utils.js';
import { FAVORITE_PROGRAMS_KEY } from './constants.js';

export default function loadFavoritePrograms(programs) {
  const favoriteProgramsFromLocalStorage = loadFavoriteProgramsFromLocalStorage();
  if (favoriteProgramsFromLocalStorage) {
    const favoritePrograms = reconcileFavoriteProgamsWithRedseaAccess(
      programs,
      favoriteProgramsFromLocalStorage
    );
    secureStorage.setItem(FAVORITE_PROGRAMS_KEY, JSON.stringify(favoritePrograms));
    return programs.map((programObject) => {
      const { program } = programObject;
      const isFavorite = favoriteProgramsFromLocalStorage.find((programFromLocalStorage) => {
        if (typeof programFromLocalStorage === 'string') {
          return programFromLocalStorage === program;
        } else {
          return programFromLocalStorage.program === program;
        }
      });
      return {
        ...programObject,
        favorite: Boolean(isFavorite),
      };
    });
  }
  return programs;
}

function reconcileFavoriteProgamsWithRedseaAccess(programs, favorites) {
  return favorites.filter((favorite) => {
    if (typeof favorite === 'string') {
      return programs.find(({ program }) => program === favorite);
    }
    return programs.find(({ program }) => program === favorite.program);
  });
}
