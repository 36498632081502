import { PROXIES, URLS } from '../config.js';

let cachedAuthHeader;

/**
 * Get authorization header from sessionStorage.
 *
 * @return {string} The authorization header.
 */
function getAuthHeader() {
  return cachedAuthHeader || `Bearer ${sessionStorage.getItem('accessToken')}`;
}

/**
 * Convert params object to a query parameter string.
 *
 * @param {object} params Parameters object to convert.
 * @return {string} Query parameter string.
 */
function convertObjectToQueryParams(params) {
  // If params is a string, return it (ensuring that it begins with `?`).
  if (typeof params === 'string') {
    return `${params[0] === '?' ? '' : '?'}${params}`;
  }
  // Otherwise ensure params is an object.
  if (typeof params !== 'object') return '';

  let queryParams = [];
  Object.keys(params).forEach((key) => {
    let value = params[key];
    if (!value) return;
    if (value instanceof Array) {
      value = value.join(',');
    } else if (typeof value !== 'string') {
      value = JSON.stringify(value);
    }
    queryParams.push(`${key}=${value}`);
  });
  if (queryParams.length) {
    params = `?${queryParams.join('&')}`;
  } else {
    params = '';
  }
  return params;
}

/**
 * Wrapper for native `fetch` which also passes redsea authorization headers and
 * properly formats the fds resource path.
 *
 * @param {string} pathname Path of FDS endpoint to fetch.
 * @param {Object} [options={}] Options which get passed to native fetch.
 * @return {Promise} Result of fetch API call.
 */
async function fetchFds(pathname, options = {}) {
  // Ensure pathname is prefixed with `/`.
  if (pathname[0] !== '/') {
    pathname = `/${pathname}`;
  }

  // Ensure pathname is prefixed with a proxy; or set the default proxy.
  if (!PROXIES.some((PROXY) => pathname.indexOf(PROXY) === 0)) {
    pathname = `${PROXIES[0]}${pathname}`;
  }

  // Convert params to string.
  options.params = convertObjectToQueryParams(options.params);

  // Set URL.
  const resource = `${URLS.nodeGateway}${pathname}${
    typeof options.params === 'string' ? options.params : ''
  }`;

  // Set auth header.
  options.headers = options.headers || {};
  options.headers.Authorization = getAuthHeader();

  // Fetch resource.
  const response = await fetch(resource, options);
  const isTextResult = response.headers.get('Content-Type').includes('text');
  return response[isTextResult ? 'text' : 'json']();
}

export default fetchFds;
