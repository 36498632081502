export const FAILED_TO_POST = 'FAILED_TO_POST';
export const OVERPOSTED = 'OVERPOSTED';
export const WRITE_OFF = 'WRITE_OFF';

export const CHARGEBACK_INITIATED = 'CHARGEBACK_INITIATED';
export const RESEARCH_REQUIRED = 'RESEARCH_REQUIRED';
export const WAITING_TO_OFFSET_TRANSACTION = 'WAITING_TO_OFFSET_TRANSACTION';

export const CUSTOMER_APPEASEMENT = 'CUSTOMER_APPEASEMENT';
export const NON_MARQETA_CARDHOLDER = 'NON_MARQETA_CARDHOLDER';
export const PAST_SLA = 'PAST_SLA';
export const POTENTIAL_FRAUD = 'POTENTIAL_FRAUD';

export const PROGRAM_ENABLED_LATE = 'PROGRAM_ENABLED_LATE';
export const PROD_SUPPORT_ACTION = 'PROD_SUPPORT_ACTION';
export const CHARGEBACK_PRESENT = 'CHARGEBACK_PRESENT';
export const LATE_JCARD_TRANSACTIONS = 'LATE_JCARD_TRANSACTIONS';
export const ROUNDING_ERROR = 'ROUNDING_ERROR';
export const NRID_IN_DIFFERENT_SUBNETWORKS = 'NRID_IN_DIFFERENT_SUBNETWORKS';
export const MISSING_SURCHARGE_AMOUNT_IN_FDS = 'MISSING_SURCHARGE_AMOUNT_IN_FDS';
export const MISSING_TRANSACTION_IN_JCARD = 'MISSING_TRANSACTION_IN_JCARD';
export const SETTLEMENT_DATE_MISMATCH = 'SETTLEMENT_DATE_MISMATCH';
export const OPPOSITE_SURCHARGE_AMOUNT_SIGNAGE_IN_FDS = 'OPPOSITE_SURCHARGE_AMOUNT_SIGNAGE_IN_FDS';

export const TX_NOT_REQUIRED_FOR_RECON = 'TX_NOT_REQUIRED_FOR_RECON';
export const REPRESENTMENT_PRESENT = 'REPRESENTMENT_PRESENT';

export const CHARGEBACK_IN_JCARD = 'CHARGEBACK_IN_JCARD';
export const CHARGEBACK_IN_TXM = 'CHARGEBACK_IN_TXM';
export const CHARGEBACK_IN_TXM_AND_JCARD = 'CHARGEBACK_IN_TXM_AND_JCARD';
export const ATM_ALREADY_PRESENT = 'ATM_ALREADY_PRESENT';

export const OTHER = 'OTHER';
