import userStore from '../../../../stores/UserStore.js';

export const requirements = {
  requiredUserPermissions: ['settlement-internal'],
  flipFlopRequirements: ['program-reserve-beta'],
};

export const verifier = () =>
  userStore.flipFlop.get('program-reserve-beta', false) &&
  Boolean(userStore.hasRole('settlement-internal'));
