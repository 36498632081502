export default function convertJsonToCsv(json) {
  // Find the largest element
  let largestEntry = 0;
  let header;
  for (let i = 0; i < json.length; i++) {
    if (Object.keys(json[i]).length > largestEntry) {
      largestEntry = Object.keys(json[i]).length;
      header = Object.keys(json[i]);
    }
  }
  // Assemble the header
  let convertedjson = '';
  header.forEach((heading) => {
    if (convertedjson !== '') {
      convertedjson += ',';
    }
    convertedjson += '"';
    convertedjson += heading;
    convertedjson += '"';
  });
  convertedjson += '\r\n';
  // Iterate through the header for all elements
  json.forEach((entry) => {
    header.forEach((heading) => {
      convertedjson += '"';
      convertedjson += entry[heading] || '';
      convertedjson += '"';
      convertedjson += ',';
    });
    convertedjson = convertedjson.substring(0, convertedjson.length - 1);
    convertedjson += '\r\n';
  });
  return convertedjson;
}
