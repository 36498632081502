import React from 'react';
import PropTypes from 'prop-types';
import { HSpacer, Flex, Button, VSpacer } from '@mqd/volt-base';

const FormButtons = ({ canContinue, onCancelClick, onContinueClick }) => (
  <>
    <VSpacer factor={4} />

    <Flex flexDirection="row">
      <Button type="tertiary" onClick={onCancelClick}>
        Cancel
      </Button>
      <HSpacer factor={2} />
      <Button type="primary" disabled={!canContinue} onClick={onContinueClick}>
        Continue
      </Button>
    </Flex>
  </>
);

FormButtons.propTypes = {
  canContinue: PropTypes.bool,
  onCancelClick: PropTypes.func,
  onContinueClick: PropTypes.func,
};

FormButtons.defaultProps = {
  canContinue: true,
  onCancelClick: PropTypes.func,
  onContinueClick: PropTypes.func,
};

export default FormButtons;
