import React from 'react';
import PropTypes from 'prop-types';
import { DetailSnippetRow } from '@mq/voltron-parent';
import { observer } from 'mobx-react';
import { Text, Hr, VSpacer } from '@mqd/volt-base';
import VelocityControlUsageLimitsWithTypes from '../velocity-control/VelocityControlUsageLimitsWithTypes';
const emptyVal = <Text type="body-sm">--</Text>;

function CardProductDetailControlsCard({
  authControlStartTimes,
  velocityControlUsageLimitsWithTypes,
  authFormattedMccs,
  authDefault,
  isWhiteList,
  hasSingleUseVelocityControl,
  error,
}) {
  const authMccNames = authFormattedMccs && authFormattedMccs.join(', ');

  return (
    <>
      <Text type="h4">Card product velocity and authorization controls</Text>
      <VSpacer factor={2} />
      <Hr />
      <VSpacer factor={2} />
      <div data-testid="card-product-details_controls">
        <DetailSnippetRow label="Start date">{authControlStartTimes || emptyVal}</DetailSnippetRow>

        <DetailSnippetRow label="Usage type">
          {hasSingleUseVelocityControl ? 'Single use' : 'Multi use'}
        </DetailSnippetRow>

        <DetailSnippetRow label="MCC auth control">
          {authDefault ? [`Default ${authDefault.toLowerCase()}`] : emptyVal}
        </DetailSnippetRow>

        <DetailSnippetRow label={`${isWhiteList ? 'Allow list' : 'Deny list'} MCC`}>
          {authMccNames || emptyVal}
        </DetailSnippetRow>

        <DetailSnippetRow label="Velocity control">
          <VelocityControlUsageLimitsWithTypes>
            {velocityControlUsageLimitsWithTypes}
          </VelocityControlUsageLimitsWithTypes>
        </DetailSnippetRow>
      </div>
    </>
  );
}

CardProductDetailControlsCard.propTypes = {
  authControlStartTimes: PropTypes.array,
  velocityControlUsageLimitsWithTypes: PropTypes.array,
  authFormattedMccs: PropTypes.array,
  authDefault: PropTypes.string,
  hasSingleUseVelocityControl: PropTypes.bool,
};

CardProductDetailControlsCard.defaultProps = {
  authControlStartTimes: [],
  velocityControlUsageLimitsWithTypes: [],
  authFormattedMccs: [],
  authDefault: '',
  hasSingleUseVelocityControl: false,
};

export default observer(CardProductDetailControlsCard);
