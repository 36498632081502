import React from 'react';
import ExceptionQueuePage from '../pages/ExceptionQueue/ExceptionQueue.js';
import ExceptionTypeQueuePage from '../pages/ApprovalQueue/index.js';
import ExceptionSummaryPage from '../pages/ExceptionSummary/ExceptionSummary.js';
import ExceptionDetailPage from '../pages/ExceptionDetail/ExceptionDetailPage.js';
import ApprovalSettlementDateQueuePage from '../pages/ApprovalSettlementDateQueue/ApprovalSettlementDateQueue.js';
import ApprovalDetailPage from '../pages/ApprovalDetails/ApprovalDetails.js';
import ApprovalSummaryPage from '../pages/ApprovalSummary/ApprovalSummary.js';
import ExportQueue from '../pages/ExportQueue/ExportQueue.js';
import ProgramReservePage, {
  BulkEntry as BulkEntryPage,
  ManualEntry as ManualEntryPage,
} from '../pages/ProgramReserveLanding/index.js';
import NodeProxy from '../pages/NodeProxy/index.js';
import { convertFdsRoutesToAmcRoutes } from './utilities.js';
import { ROUTES } from './constants.js';
import {
  requirements as programReserveRequirements,
  verifier as programReserveVerifier,
} from './permissions/program-reserve.js';

/**
 * ROUTES: An abstraction of MQD routes which removes the complexity of creating
 * routes and pages in MQD. See routes-config.test.js to become familiar with
 * the various ways that are supported to configure an FDS Route.
 *
 * A utility function automatically creates one MQD route for each path in an
 * FDS Route object. An FDS Route object can have the following properties:
 *
 * Route = { name: String|Function, names: String[], path: String|Function,
 * paths: String[], id: String|Function, ids: String[], view: Component }
 *
 * Plural properties allow you to explicitly assign values to MQD routes, while
 * assigning a Function to single properties allows you to dynamically assign
 * values. Plural properties take priority over singular properties.
 *
 * IMPORTANT: If an `id` is not given, it will be automatically created as the
 * camelCase value of the `name` property. But take care as this could override
 * previously created routes.
 *
 * @type {Object[]}
 */
const routes = [
  {
    path: '/settlement/node-proxy',
    name: 'Node proxy',
    content: <NodeProxy />,
  },
  // Approval workflow.
  {
    path: '/settlement/approvals',
    name: 'Approval summary',
    content: <ApprovalSummaryPage />,
  },
  {
    path: '/settlement/approvals/:type',
    name: 'Approval queue',
    content: <ExceptionTypeQueuePage />,
  },
  {
    path: '/settlement/approvals/:type/settlement-date',
    name: 'Settlement date queue',
    content: <ApprovalSettlementDateQueuePage />,
  },
  {
    path: '/settlement/approvals/:type/settlement-date/details',
    name: 'Approval details',
    content: <ApprovalDetailPage />,
  },

  // Exception workflow.
  {
    path: '/settlement/exceptions',
    name: 'Exception summary',
    content: <ExceptionSummaryPage />,
  },
  {
    path: '/settlement/exceptions/:type',
    name: 'Exception queue',
    content: <ExceptionQueuePage />,
  },
  {
    path: '/settlement/exceptions/:type/details',
    name: 'Exception details',
    content: <ExceptionDetailPage />,
  },
  // Export Queue.
  {
    path: '/settlement/export-queue',
    name: 'Export queue',
    content: <ExportQueue />,
  },
  // Program reserve.
  {
    path: ROUTES.PROGRAM_RESERVE,
    name: 'Program funding',
    content: <ProgramReservePage />,
    requirements: programReserveRequirements,
    verifier: programReserveVerifier,
  },
  {
    path: ROUTES.MANUAL_ENTRY,
    name: 'Manual entry',
    content: <ManualEntryPage />,
    requirements: programReserveRequirements,
    verifier: programReserveVerifier,
  },
  {
    path: ROUTES.BULK_ENTRY,
    name: 'Bulk entry',
    content: <BulkEntryPage />,
    requirements: programReserveRequirements,
    verifier: programReserveVerifier,
  },
];

const { mapByPath, mapById, mapByName, verifiers, requirements } =
  convertFdsRoutesToAmcRoutes(routes);

export {
  mapByPath as fdsRoutes,
  mapById as fdsRoutesById,
  mapByName as fdsRoutesByName,
  verifiers as fdsVerifiers,
  requirements as fdsRequirements,
};
