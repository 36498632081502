import React, { Component } from 'react';
import { observer } from 'mobx-react';
import AccountsTable from './AccountsTable';
import PropTypes from 'prop-types';

class AccountsTab extends Component {
  render() {
    const { storeInitArgs, onRowClick, onStoreConstruction, localStorageKey } = this.props;
    return (
      <AccountsTable
        storeInitArgs={storeInitArgs}
        onRowClick={onRowClick}
        onStoreConstruction={onStoreConstruction}
        localStorageKey={localStorageKey}
      />
    );
  }
}

AccountsTab.propTypes = {
  storeInitArgs: PropTypes.object,
  onRowClick: PropTypes.func,
  onStoreConstruction: PropTypes.func,
  localStorageKey: PropTypes.string,
};

AccountsTab.defaultProps = {
  storeInitArgs: {},
  onRowClick: () => {},
  onStoreConstruction: () => {},
  localStorageKey: '',
};
export default observer(AccountsTab);
