import { observer } from 'mobx-react';
import React from 'react';
import {
  Button,
  Card,
  ContentSnippet,
  HSpacer,
  LoadingOverlay,
  Text,
  VSpacer,
} from '@mqd/volt-base';
import { DetailSnippetRow, ParentDetailCard } from '@mq/voltron-parent';
import { titleCase } from '../shared-utils';

class CardShippingCard extends ParentDetailCard {
  constructor(props) {
    super(props);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }
  state = {
    loading: false,
    editActive: false,
    expand: false,
    showChangeStateModal: false,
    showAddMetadataModal: false,
  };

  get cardStore() {
    const { cardStore } = this.props;
    return cardStore ? cardStore : {};
  }

  async handleSave() {
    const { updateCard } = this.cardStore;
    this.setState({ loading: true });
    const success = await updateCard();
    if (success) {
      this.setState({ loading: false, editActive: false });
    } else {
      this.setState({ loading: false });
    }
  }

  handleCancel() {
    const { revertUpdates } = this.cardStore;
    revertUpdates && revertUpdates();
    this.setState({ editActive: false });
  }

  renderHeaderButtons() {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button type="outline" onClick={this.handleCancel}>
          Cancel
        </Button>
        <HSpacer />
        <Button onClick={this.handleSave}>Save</Button>
      </div>
    );
  }

  renderAddress({ address, heading, tooltipContent, tooltipProps, type }) {
    const { editActive } = this.state;

    if (!address) {
      address = {};
    }
    const addressDisplay = address && address.displayUS ? address.displayUS.split('\n') : '';

    const {
      first_name,
      middle_name,
      last_name,
      address1,
      address2,
      city,
      state,
      postal_code,
      country,
      updateForSave,
      phone,
    } = address;
    if (editActive) {
      return (
        <div data-testid={`${heading}_container`}>
          <Text type="h5">{heading}</Text>
          <VSpacer factor={2} />
          {this.renderSnippet({
            title: 'First Name',
            heading: heading,
            content: first_name,
            editActive: true,
            onChange: (e) => updateForSave('first_name', e.target.value),
            tooltipContent: 'First Name',
          })}
          {this.renderSnippet({
            title: 'Middle Name',
            heading: heading,
            content: middle_name,
            editActive: true,
            onChange: (e) => updateForSave('middle_name', e.target.value),
            tooltipContent: 'Middle Name',
          })}
          {this.renderSnippet({
            title: 'Last Name',
            heading: heading,
            content: last_name,
            editActive: true,
            onChange: (e) => updateForSave('last_name', e.target.value),
            tooltipContent: 'Last Name',
          })}
          {this.renderSnippet({
            title: 'Address 1',
            heading: heading,
            content: address1,
            editActive: true,
            onChange: (e) => updateForSave('address1', e.target.value),
            tooltipContent: 'Number and street.',
          })}
          {this.renderSnippet({
            title: 'Address 2',
            heading: heading,
            content: address2,
            editActive: true,
            onChange: (e) => updateForSave('address2', e.target.value),
            tooltipContent: 'Additional address information.',
          })}
          {this.renderSnippet({
            title: 'City',
            heading: heading,
            content: city,
            editActive: true,
            onChange: (e) => updateForSave('city', e.target.value),
            tooltipContent: 'City.',
          })}
          {this.renderSnippet({
            title: 'State',
            heading: heading,
            content: state,
            editActive: true,
            onChange: (e) => updateForSave('state', e.target.value),
            tooltipContent: 'State.',
          })}
          {this.renderSnippet({
            title: 'Postal Code',
            heading: heading,
            content: postal_code,
            editActive: true,
            onChange: (e) => updateForSave('postal_code', e.target.value),
            tooltipContent: 'Postal Code.',
          })}
          {this.renderSnippet({
            title: 'Country',
            heading: heading,
            content: country,
            editActive: true,
            onChange: (e) => updateForSave('country', e.target.value),
            tooltipContent: 'Country.',
          })}
          {this.renderSnippet({
            title: 'Phone',
            heading: heading,
            content: phone,
            editActive: true,
            onChange: (e) => updateForSave('phone', e.target.value),
            tooltipContent: 'Phone.',
          })}
        </div>
      );
    } else {
      if (!addressDisplay || addressDisplay.length === 0) {
        return;
      }
      return (
        <div style={{ width: 360 }}>
          <ContentSnippet
            title={heading}
            testId={`volt-content-snippet_heading-${heading}`}
            horizontalLayout={true}
            tooltipContent={tooltipContent}
            tooltipProps={tooltipProps}
          >
            <div style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
              {addressDisplay.map &&
                addressDisplay.map((address, i, arr) => {
                  const showSpacer = i !== arr.length - 1;
                  return (
                    <div key={i}>
                      {address}
                      {showSpacer}
                      <VSpacer />
                    </div>
                  );
                })}
            </div>
          </ContentSnippet>
        </div>
      );
    }
  }

  renderInfo() {
    let { fulfillment, fulfillment_status, expedite } = this.cardStore;
    if (!fulfillment) {
      fulfillment = {};
    }
    let { shipping, card_personalization } = fulfillment;
    if (!shipping) {
      shipping = {};
    }
    if (!card_personalization) {
      card_personalization = {};
    }

    const { return_address, recipient_address, method, care_of_line } = shipping;
    const { name_line_1, name_line_2, name_line_3 } = card_personalization;
    return (
      <Card testId="card_shipping" contentPadding="0">
        <Text type="h5">Card shipping</Text>
        <VSpacer factor={2} />

        {method &&
          this.renderSnippet({
            title: 'Method',
            content: method,
            inputType: 'select',
            inputProps: {
              options: [
                'UPS_REGULAR',
                'UPS_EXPEDITED',
                'USPS_REGULAR',
                'USPS_EXPEDITED',
                'FEDEX_EXPEDITED',
              ],
            },
          })}
        <DetailSnippetRow label="Processing time">
          {expedite ? 'Expedited' : 'Normal'}
        </DetailSnippetRow>
        <DetailSnippetRow label="Card fulfillment status">
          {titleCase(fulfillment_status)}
        </DetailSnippetRow>
        <DetailSnippetRow label="Name line 1">{name_line_1}</DetailSnippetRow>
        <DetailSnippetRow label="Name line 2">{name_line_2}</DetailSnippetRow>
        <DetailSnippetRow label="Name line 3">{name_line_3}</DetailSnippetRow>
        <DetailSnippetRow label="Care of line">{care_of_line}</DetailSnippetRow>
        {this.renderAddress({
          heading: 'Recipient address',
          address: recipient_address,
        })}
        <VSpacer factor={1.5} />
        {this.renderAddress({
          heading: 'Return address',
          address: return_address,
        })}
      </Card>
    );
  }

  render() {
    const { loading } = this.state;
    return <LoadingOverlay active={loading}>{this.renderInfo()}</LoadingOverlay>;
  }
}

export default observer(CardShippingCard);
