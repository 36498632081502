import gql from 'graphql-tag';

export const GET_FILTER_DATA = gql`
  query getFilterData {
    programTypes
    dnaBanks(count: 500) {
      data {
        name
        token
      }
    }
    currencyCodes
  }
`;
