import { FlipFlop } from '../../index.js';
import currentUserStore from 'stores/CurrentUserStore';
import { hasDeveloperRole, isJanusUser } from './functions.js';

export const requirements = {
  flipFlopRequirements: ['webhooks'],
  requiredRoles: ['developer'],
};

export const verifier = () => {
  const { userStore = {} } = currentUserStore;
  return FlipFlop.get('webhooks', false) && !isJanusUser(userStore) && hasDeveloperRole(userStore);
};
