import { decorate, observable } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';

class DirectDepositStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
  }

  // values
  token: string = '';
  state: string = '';
}

decorate(DirectDepositStore, {
  token: observable,
  state: observable,
});

export default DirectDepositStore;
