import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import CommandoModeTransitionsTable from './../commando-mode-transitions-table/CommandoModeTransitionsTable';

class CommandoModeTransitionsTab extends Component {
  render() {
    let { commandoModeStore, onStoreConstruction, localStorageKey } = this.props;
    if (!commandoModeStore) {
      commandoModeStore = {};
    }
    const { gqlClient, token } = commandoModeStore;
    if (!token) return null;
    return (
      <CommandoModeTransitionsTable
        onStoreConstruction={onStoreConstruction}
        storeInitArgs={{
          gqlClient: gqlClient,
          queryParams: {
            token: {
              type: 'ID!',
              val: token,
            },
            count: {
              type: 'Int',
              val: 50,
            },
          },
        }}
        localStorageKey={localStorageKey}
      />
    );
  }
}

CommandoModeTransitionsTab.propTypes = {
  commandoModeStore: PropTypes.any,
  onStoreConstruction: PropTypes.func,
  localStorageKey: PropTypes.string,
};

CommandoModeTransitionsTab.defaultProps = {
  commandoModeStore: null,
  onStoreConstruction: () => {},
  localStorageKey: '',
};

export default observer(CommandoModeTransitionsTab);
