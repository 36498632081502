export const accountTransitionEnabledRoles = [
  'access-manager',
  'cardholder-support',
  'compliance-internal',
  'compliance-processor-only',
  'compliance-program-managed',
  'delivery-internal',
  'marqeta-admin-internal',
  'production-support-internal',
  'program-admin',
  'risk-internal',
];

export const revealAccountNumberEnabledRoles = ['aux-access-pii'];
