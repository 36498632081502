export const getLinkStyles = (link) => {
  if (!link) return false;
  const { width, left } = link.getBoundingClientRect();
  return { width, left };
};

export const getLinkParent = (e) => {
  let element = e.target;
  if (!element || !element.getAttribute) return null;
  while (true) {
    if (element.getAttribute('data-link-wrap')) {
      return element;
    } else {
      if (element.parentElement) {
        element = element.parentElement;
      } else {
        return null;
      }
    }
  }
};
