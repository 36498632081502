import { decorate, observable, action, computed } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';

class BinStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
  }
  // values
  active: boolean = true;
  bin: Int = null;
  network: string = '';
  type: string = '';
  classification: string = '';
  emv: string = '';
}

decorate(BinStore, {
  // values
  active: observable,
  bin: observable,
  network: observable,
  type: observable,
  classification: observable,
  emv: observable,
});

export default BinStore;
