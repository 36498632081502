import currentUserStore from 'stores/CurrentUserStore';
import {
  hasDeveloperRole,
  hasNoRoles,
  isJanusUser,
  hasTokenizationManagerPermissions,
  isDdaProgram,
} from './functions.js';
import {
  USER_DETAILS_VIEW_AND_EDIT,
  USER_DETAILS_VIEW,
} from '../../../views/admin/uam-granular-permissions/constants.js';
import { janusToRedseaRoles } from './constants/redseaRoles.js';

const UAM_ROLES = [USER_DETAILS_VIEW_AND_EDIT, USER_DETAILS_VIEW];

export const requirements = {
  requiredGranularPermissions: UAM_ROLES,
};

export const verifier = () => {
  const { userStore = {} } = currentUserStore;
  const {
    hasRoleInArrayForActiveEnvironment,
    uamGranularPermissionsActive,
    privateSandboxActive,
    hasRequiredUamPermissions,
  } = userStore;
  const hasUamCardholdersPermissions = hasRequiredUamPermissions(UAM_ROLES);

  const hasPermissions =
    (!isDdaProgram() &&
      ((!hasNoRoles(userStore) && !hasDeveloperRole(userStore)) || isJanusUser(userStore))) ||
    hasTokenizationManagerPermissions(userStore, 'program/users');

  const canViewCardholders = uamGranularPermissionsActive
    ? hasUamCardholdersPermissions
    : hasPermissions;

  if (privateSandboxActive) {
    return uamGranularPermissionsActive
      ? hasRoleInArrayForActiveEnvironment(UAM_ROLES) && hasUamCardholdersPermissions
      : hasRoleInArrayForActiveEnvironment(janusToRedseaRoles) && hasPermissions;
  } else return canViewCardholders;
};

export const redirect = () => {
  if (isDdaProgram()) return 'programAccountholders';
  return false;
};
