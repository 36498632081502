import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, is } from 'ramda';
import { VSpacer, Hr, Container, Row, Col, Metrics, DetailPane } from '@mqd/volt-base';
import { Table } from '@mq/voltron-table';
import { getTxnDescription, isAchTransaction, getAmount } from './utils.js';
import GeneralDetails from './components/GeneralDetails.js';
import AchTransactionDetails from './components/AchTransactionDetails.js';
import { RELATED_TRANSACTIONS_TABLE_COLUMNS } from './constants.js';

const TransactionDetails = ({ transaction, relatedTransactions, state }) => {
  if (!is(Object, transaction) || isEmpty(transaction)) {
    return null;
  }

  const { currency_code } = transaction;
  const description = getTxnDescription(transaction);
  const amount = getAmount(transaction);

  const hasRelatedTransactions = Boolean(relatedTransactions.length);

  return (
    <Container>
      <Row>
        <Col span={12}>
          <Metrics>
            <Metrics.MetricItem description={description} content={amount} unit={currency_code} />
          </Metrics>
          <VSpacer />
        </Col>
      </Row>

      <GeneralDetails transaction={transaction} state={state} />

      {isAchTransaction(transaction) && (
        <Row>
          <Col span={6}>
            <AchTransactionDetails transaction={transaction} />
          </Col>
        </Row>
      )}

      {hasRelatedTransactions && (
        <Row>
          <Col span={6}>
            <VSpacer factor={8} />
            <Hr factor={0} />
            <VSpacer factor={8} />
            <DetailPane.Container header="Related transactions">
              <VSpacer factor={3} />
              <Table
                data={relatedTransactions}
                columns={RELATED_TRANSACTIONS_TABLE_COLUMNS}
                height={320}
              />
            </DetailPane.Container>
          </Col>
        </Row>
      )}
    </Container>
  );
};

TransactionDetails.propTypes = {
  transaction: PropTypes.object,
  state: PropTypes.string,
  relatedTransactions: PropTypes.array,
};

TransactionDetails.defaultProps = {
  relatedTransactions: [],
};

export default TransactionDetails;
