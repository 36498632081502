import { Colors } from '@mqd/volt-base';

export const AGENT_TYPES = {
  MARQETA: 'Marqeta',
  CUSTOMER: 'Customer',
  UNASSIGNED: 'Unassigned',
};

export const STATUSES = {
  TO_DO: 'To do',
  IGNORED: 'Ignored',
  OPEN: 'Open',
  IN_PROGRESS: 'In progress',
  CLOSED: 'Closed',
  PENDING: 'Pending',
  SUCCESS: 'Success',
  FAILURE: 'Failure',
  UNVERIFIED: 'Unverified',
};

export const reviewStatusColors = {
  //TODO: add to colors.json, use that
  CLOSED: {
    backgroundColor: Colors.statusDraftColor,
    textColor: Colors.statusSuccessColor,
  },
  OPEN: {
    backgroundColor: Colors.statusDraftWash,
    textColor: Colors.blackLighter4,
  },
  IN_PROGRESS: {
    backgroundColor: Colors.statusPendingColor,
    textColor: Colors.statusPendingColor,
  },
  TO_DO: {
    backgroundColor: Colors.statusPendingWash,
    textColor: Colors.statusPendingColor,
  },
  '': {
    backgroundColor: Colors.statusPendingWash,
    textColor: Colors.statusPendingColor,
  },
};

export const COLUMNS_LOCAL_STORAGE_KEY = 'reviewsTableLocalStorageKey';
