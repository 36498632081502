import React from 'react';
import { observer } from 'mobx-react';
import BulkResponseTableStore from './BulkResponseTableStore';
import PropTypes from 'prop-types';
import { ParentTable, Status } from '@mq/voltron-parent';
import { Table } from '@mq/voltron-table';
import { LoadingOverlay, Tooltip, Icon, HSpacer } from '@mqd/volt-base';
import EmptyTableState from './../shared-components/empty-state/EmptyTableState';
import s from './BulkResponse.module.css';

function HeaderWithTooltip({ header, tooltipContent }) {
  if (!header) return;
  if (!tooltipContent) return header;

  const iconStyle = { marginTop: '2px' };
  const content = <span className={s.allowWrap}>{tooltipContent}</span>;

  return (
    <span className={s.customTableHeader}>
      {header}
      <HSpacer />
      <Tooltip direction="bottomLeft" content={content} width={360} fixOverflow>
        <Icon style={iconStyle} factor={0.5} overrideColor="#8E8E99" type="info" />
      </Tooltip>
    </span>
  );
}

function CSVDownloadRow({ row }) {
  const { Key, signedUrl } = row;
  return (
    <a className={`${s.signedUrl} ${s.inlineFlex}`} href={signedUrl}>
      <Icon type="arrow-down" factor={0.67} overrideColor="#8E8E99" />
      <HSpacer />
      {Key}
    </a>
  );
}

class BulkResponseTable extends ParentTable {
  constructor(props) {
    super(props);
    this.storeConstructor = BulkResponseTableStore;
    this.state = {
      tableKey: 'bulkResponseTable',
      ...this.colEditState(props, this.columns()),
    };
  }

  componentDidMount() {
    const { calcTableHeight } = this.store;
    calcTableHeight();
    window.addEventListener('resize', calcTableHeight);
  }

  componentWillUnmount() {
    const { calcTableHeight } = this.store;
    window.removeEventListener('resize', calcTableHeight);
  }

  columns() {
    return [
      {
        header: 'Last modified',
        accessor: 'formattedLastModified',
        key: 'LastModified',
        width: 200,
      },
      {
        header: 'Response status',
        accessor: 'responseStatus',
        key: 'responseStatus',
        renderer: ({ cellValue }) => <Status status={cellValue.toUpperCase()} />,
        width: 200,
      },
      {
        header: (
          <HeaderWithTooltip
            header="CSV response file"
            tooltipContent="If errors, download the response CSV to review errors and make the respective updates."
          />
        ),
        accessor: 'Key',
        key: 'Key',
        renderer: ({ row }) => <CSVDownloadRow row={row} />,
      },
      {
        header: (
          <HeaderWithTooltip
            header="CSV entries"
            tooltipContent="The primary bulk upload entries are users, cards, and funds. View the entries per upload here."
          />
        ),
        accessor: 'csvEntries',
        key: 'csvEntries',
      },
    ];
  }

  render() {
    const { columns, tableKey } = this.state;
    const { loading, items, setAttr, tableHeight } = this.store;

    return (
      <div style={{ width: '100%' }} data-testid="bulk-responses-container">
        <LoadingOverlay active={loading}>
          <div ref={(ref) => setAttr('tableContainerRef', ref)}>
            <Table
              testId="bulk-response-table"
              key={tableKey}
              height={tableHeight || this.dynamicHeight}
              data={items}
              columns={columns}
              handleColSizeChange={this.handleColSizeChange.bind(this)}
              customEmptyState={
                <EmptyTableState
                  header="No responses yet"
                  testId="bulk-response-table_empty-state"
                  bodyText={
                    <>
                      It appears you have no bulk creation responses yet. If you are
                      <br />
                      waiting for a response, reload this page in 20-30 minutes.
                    </>
                  }
                  style={{ height: '100%' }}
                />
              }
            />
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

BulkResponseTable.propTypes = {
  autoHydrate: PropTypes.bool,
  height: PropTypes.number,
  store: PropTypes.object,
  storeInitArgs: PropTypes.object,
  onStoreConstruction: PropTypes.func,
  userStore: PropTypes.object,
  localStorageKey: PropTypes.string,
};

BulkResponseTable.defaultProps = {
  autoHydrate: true,
  handleRedirect: (store, tab) => {},
  height: 800,
  onRowClick: () => {},
  onStoreConstruction: null,
  store: null,
  storeInitArgs: {},
  userStore: {},
  localStorageKey: 'BulkResponseTable',
};

export default observer(BulkResponseTable);
