import React, { useCallback, useMemo, useState } from 'react';
import { compose, includes, not } from 'ramda';
import { useApolloClient } from '@apollo/react-hooks';
import { NotificationList, NotificationBell } from '@mq/parity-plus';
import {
  ActiveIssueLink,
  DocsLink,
  FeedbackLink,
  NewFeatureLink,
  UserProfileDropdown,
  flipFlop,
  withJanusUser,
} from '@mq/volt-amc-container';
import { Drawer } from '@mqd/volt-base';
import { Text } from '@mqd/volt-core/pre';
import { ContentSwitcher } from '@mqd/volt-patterns/pre';
import { classes } from '@mqd/volt-toolbox/pre';
import routerStore from 'stores/router/RouterStore';
import GlobalSearch from '../../../components/global-search/GlobalSearch.js';
import EnvironmentSelector from '../../../components/environment-selector/EnvironmentSelector.js';
import { useNotifications } from '../../../components/index.js';
import withRouterGo from '../../../components/router-go/withRouterGo.js';
import currentUserStore from '../../../stores/CurrentUserStore.js';
import useHasRoleInArrayForActiveProgram from '../../../utils/helpers/useHasRoleInArrayForActiveProgram.js';
import { getBreadcrumbRootRoute } from '../../../utils/Breadcrumb.js';
import { isJanusUser } from '../../../utils/route-authorization/routes/functions.js';
import { cfnLeftNav } from '../left-nav/cfn-left-nav.js';
import { handleSearchView } from './utils.js';
import css from './CFNTopBar.module.css';

const ROUTES_WITH_NO_GLOBAL_SEARCH = ['Development', 'TransactionTimeline'];

function CFNTopBar({ activeContent, go, janusUser, onContentChange }) {
  const [isNotificationsShown, setNotificationsShown] = useState(false);

  const {
    notifications,
    saveNotifications,
    removeNotificationHandler,
    removeNotificationSectionHandler,
    markAllNotificationsAsRead,
  } = useNotifications();

  const closeDrawer = () => {
    setNotificationsShown(false);
    markAllNotificationsAsRead();
  };

  const userStore = currentUserStore?.userStore;
  const { privateSandboxActive, loggedInToJanus } = userStore;

  const gqlClient = useApolloClient();

  const showNotificationsPanel = flipFlop.get('notifications-panel', false);
  const newFeatureLink = flipFlop.get('new-feature-link', false);
  const isGlobalIssueActive = flipFlop.get('active-issue-link', false);

  const hasRoleInArrayForActiveProgram = useHasRoleInArrayForActiveProgram();
  const routeHidesSearch = includes(routerStore.currentRouteName, ROUTES_WITH_NO_GLOBAL_SEARCH);
  const userHidesSearch = compose(not, isJanusUser)(userStore);
  const globalSearchIsHidden = routeHidesSearch || userHidesSearch;

  const handleSearchClick = useCallback((args) => handleSearchView({ go })(args), [go]);

  const currentProgramName = userStore?.selectedActiveProgram?.program;

  const globalSearchInitArgs = useMemo(() => {
    return { gqlClient };
  }, [gqlClient]);

  const janusUserEmail = useMemo(() => {
    const { data: { email } = {} } = janusUser;
    return email;
  }, [janusUser]);

  return (
    <>
      <div className={css.left}>
        {loggedInToJanus ? (
          <ContentSwitcher
            config={cfnLeftNav.workspaces}
            activeContent={activeContent}
            onChange={onContentChange}
          />
        ) : (
          <Text mod="h5">
            {userStore.redsea_first_name} {userStore.redsea_last_name} (Sandbox)
          </Text>
        )}
      </div>

      <div className={classes(css.main, loggedInToJanus && css.mainFull)}>
        <div className={css.center}>
          {!globalSearchIsHidden && (
            <GlobalSearch
              currentProgramName={currentProgramName}
              inputProps={{ width: '100%' }}
              onClickResult={handleSearchClick}
              storeInitArgs={globalSearchInitArgs}
              janusUserEmail={janusUserEmail}
              hasRoleInArrayForActiveProgram={hasRoleInArrayForActiveProgram}
            />
          )}
        </div>

        <div className={css.right}>
          {privateSandboxActive && (
            <div className={css.rightItem}>
              <EnvironmentSelector getBreadcrumbRootRoute={getBreadcrumbRootRoute} />
            </div>
          )}

          {!globalSearchIsHidden && (
            <div className={css.rightItem}>
              <NewFeatureLink active={newFeatureLink} />
            </div>
          )}

          {isGlobalIssueActive && (
            <div className={css.rightItem}>
              <ActiveIssueLink active={isGlobalIssueActive} />
            </div>
          )}

          <div className={css.rightIcon}>
            <FeedbackLink />
          </div>

          <div className={css.rightIcon}>
            <DocsLink />
          </div>

          {showNotificationsPanel && (
            <div className={css.rightIcon}>
              <NotificationBell
                notifications={notifications}
                setNotificationsShown={setNotificationsShown}
              />
            </div>
          )}

          <div className={css.rightIcon}>
            <UserProfileDropdown />
          </div>
        </div>
      </div>

      <Drawer isOpened={isNotificationsShown} closeDrawer={closeDrawer}>
        <NotificationList
          notifications={notifications}
          saveNotifications={saveNotifications}
          removeNotificationHandler={removeNotificationHandler}
          removeNotificationSectionHandler={removeNotificationSectionHandler}
        />
      </Drawer>
    </>
  );
}

CFNTopBar.propTypes = {};
CFNTopBar.defaultProps = {};

export default withRouterGo(withJanusUser(CFNTopBar));
