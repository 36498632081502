import React from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { Modal, Button } from '@mqd/volt-base';

import { useBodyNode } from '../../../../../../hooks/use-body-node';

export function ConfirmDeletionModal({ cardType, name, closeModal, handleDelete }) {
  const bodyNode = useBodyNode();

  // portal is needed because
  // Modal positioning is wrong
  // when rendered inside the Drawer
  return createPortal(
    <Modal
      type="sm"
      heading={`Delete ${cardType}`}
      description={`Are you sure you want to delete “${name}”? This action cannot be undone.`}
      hideModal={closeModal}
      hideModalButtonText="Cancel"
      footerButtons={[<Button onClick={handleDelete}>Delete {cardType}</Button>]}
    />,
    bodyNode
  );
}

ConfirmDeletionModal.propTypes = {
  cardType: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  handleDelete: PropTypes.func.isRequired,

  closeModal: Modal.propTypes.hideModal,
};

ConfirmDeletionModal.defaultProps = {
  closeModal: Modal.defaultProps.closeModal,
};
