import { anyPass, includes, pipe, prop } from 'ramda';
import { FlipFlop } from 'utils';
import currentUserStore from 'stores/CurrentUserStore';

const userStoreHasNecessaryRole = pipe(
  prop('redseaRoles'),
  anyPass([includes('marqeta-admin-internal'), includes('delivery-internal')])
);

export const requirements = {
  flipFlopRequirements: ['sandboxes-management'],
  requiredRoles: ['Admin (Internal)', 'Delivery (Internal)'],
};

export const verifier = () => {
  const {
    userStore: { uamGranularPermissionsActive },
  } = currentUserStore;
  const hasFlag = FlipFlop.get('sandboxes-management', false);
  const hasRole = userStoreHasNecessaryRole(currentUserStore.userStore);
  return hasFlag && hasRole && !uamGranularPermissionsActive;
};
