import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Alert, VSpacer, Modal, Button, LoadingOverlay, Select, Textarea } from '@mqd/volt-base';
import { reasonCodes, REQUIRED_ERROR_MESSAGE } from './constants';
import TransactionDetails from './TransactionDetails';

const transformedReasonCodes = reasonCodes.map(({ code, message }) => ({
  code,
  message,
  render: `${code}: ${message}`,
}));

class ReturnTransactionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reasonCode: null,
      reasonNote: null,
      attemptedSave: false,
      modalError: null,
    };
  }

  get reasonNoteError() {
    const { attemptedSave, reasonNote } = this.state;
    return !reasonNote && attemptedSave ? REQUIRED_ERROR_MESSAGE : false;
  }

  get reasonCodeError() {
    const { attemptedSave, reasonCode } = this.state;
    return !reasonCode && attemptedSave ? REQUIRED_ERROR_MESSAGE : false;
  }

  handleSave = async () => {
    const { reasonCode, reasonNote } = this.state;
    const { store, hideModal, refreshTable, onSuccess } = this.props;

    this.setState({ attemptedSave: true }, async () => {
      if (!this.isFormValid()) return;

      const params = {
        reason_code: reasonCode.code,
        reason: reasonNote,
      };

      try {
        await store.returnTransaction(params);
        refreshTable(); // refresh table because a new record should appear
        onSuccess();
        hideModal();
      } catch (e) {
        console.error(e);
        this.setState({ modalError: e });
      }
    });
  };

  isFormValid = () => {
    const { reasonCodeError, reasonNoteError } = this;
    return Boolean(!reasonCodeError && !reasonNoteError);
  };

  handlereasonCodeChange = (value) => {
    this.setState({ reasonCode: value });
  };

  handlereasonNoteChange = (event) => {
    this.setState({ reasonNote: event.target.value });
  };

  render() {
    const { hideModal, store } = this.props;
    const { loading, amount, currency_code, cardholder_transaction_time } = store;
    const { reasonCode, reasonNote, modalError } = this.state;

    return (
      <Modal
        heading={`Return transaction`}
        hideModal={hideModal}
        disableOverFlow
        width={600}
        footerButtons={[<Button onClick={() => this.handleSave()}>Save</Button>]}
      >
        <LoadingOverlay active={loading}>
          {!modalError ? null : (
            <>
              <Alert type="error">{modalError}</Alert>
              <VSpacer factor={3} />
            </>
          )}
          <TransactionDetails
            amount={amount}
            currency_code={currency_code}
            cardholder_transaction_time={cardholder_transaction_time}
          />
          <VSpacer factor={3} />
          <Select
            label="Return reason code"
            placeholder="Select an option"
            options={transformedReasonCodes}
            onChange={(value) => this.setState({ reasonCode: value })}
            value={reasonCode}
            error={this.reasonCodeError}
            numOptions={10}
            testId="return-transaction-reason-code-select"
          />
          <VSpacer factor={3} />
          <Textarea
            label="Status change reason"
            type="text"
            value={reasonNote}
            onChange={(event) => this.setState({ reasonNote: event.target.value })}
            error={this.reasonNoteError}
            maxLength={255}
            testId="return-transaction-reason-textarea"
          />
        </LoadingOverlay>
      </Modal>
    );
  }
}

ReturnTransactionModal.propTypes = {
  hideModal: PropTypes.func,
  onSuccess: PropTypes.func,
  refreshTable: PropTypes.func,
  store: PropTypes.object,
};

ReturnTransactionModal.defaultProps = {
  hideModal: () => {},
  onSuccess: () => {},
  refreshTable: () => {},
  store: {},
};

export default observer(ReturnTransactionModal);
