import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { getHref, handleClick } from './linkHelpers.js';

class Link extends PureComponent {
  constructor(props) {
    super(props);
    this.handleClick = handleClick.bind(this);
    this.routerStore = props.routerStore;
  }

  render() {
    const { children } = this.props;
    return (
      <a
        href={getHref(this.routerStore, this.props)}
        onClick={(e) => handleClick(e, this.routerStore, this.props)}
        data-testid="link"
      >
        {children}
      </a>
    );
  }
}

Link.propTypes = {
  to: PropTypes.string,
  params: PropTypes.object,
  active: PropTypes.bool,
  skipGaPageview: PropTypes.bool,
  before: PropTypes.func,
};

Link.defaultProps = {
  params: {},
  active: true,
  skipGaPageview: false,
};

export default observer(Link);
