import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Multiselect } from '@mqd/volt-base';
import { ISOCurrencyList } from './constants.js';
import { objectHelpers } from 'utils';
const { convertArrayToObject } = objectHelpers;

// This component will be moved to @mqd/volt-base for use in the future
// Jira: https://marqeta.atlassian.net/browse/PS-25231

/**
  Props:
    initialValue: 
      - Expects an array of currency codes or number. Ex. initialValue={['USD', 'JPY']} || initialValue={['840', 'JPY']} || initialValue={['840', '392']}
      - Acceptable code values in ./constants
    onChange: 
      - will receive the full currency object with code and name. Ex. { code: 'USD', number: '840' }
 */
function CurrencyMultiSelect({
  label,
  width,
  onOptionClick,
  initialValue,
  testId,
  name,
  disabled,
  hasChangedType,
}) {
  const convertToCodeIfNumber = (codeOrNumber) => {
    if (!isNaN(Number(codeOrNumber))) {
      const currencyObject = ISOCurrencyList.find(({ number }) => number === codeOrNumber);
      return currencyObject && currencyObject.code;
    }

    return codeOrNumber;
  };

  const [selected, setSelected] = useState(initialValue.map(convertToCodeIfNumber));
  const ISOCurrencyMap = convertArrayToObject(ISOCurrencyList, 'code');

  const options = ISOCurrencyList.map(({ code }) => code);

  const componentOnOptionClick = (code) => {
    let selectedItems = [];

    // already selected, remove it
    if (selected.includes(code)) {
      const filteredSelected = selected.filter((currency) => currency !== code);
      selectedItems = filteredSelected;
      setSelected(selectedItems);
    } else {
      selectedItems = [...selected, code];
      setSelected(selectedItems);
    }

    const selectedCurrencyFullObjectArray = selectedItems.map((code) => {
      return ISOCurrencyMap[code];
    });

    onOptionClick(selectedCurrencyFullObjectArray);
  };

  return (
    <Multiselect
      label={label}
      testId={testId}
      showSearch={true}
      selected={selected}
      width={width}
      onOptionClick={componentOnOptionClick}
      options={options}
      collapseSelectedLimit={2}
      numOptions={5}
      name={name}
      disabled={disabled}
      hasChangedType={hasChangedType}
    />
  );
}

CurrencyMultiSelect.propTypes = {
  label: PropTypes.string,
  width: PropTypes.number,
  initialValue: PropTypes.array,
  onChange: PropTypes.func,
  testId: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  hasChangedType: PropTypes.string,
  kind: PropTypes.string,
};

CurrencyMultiSelect.defaultProps = {
  label: 'Select multiple currencies',
  width: 100,
  initialValue: [],
  onChange: () => {},
  testId: 'currency-multiselect',
  name: '',
  disabled: false,
  kind: 'multiselect',
};

export default CurrencyMultiSelect;
