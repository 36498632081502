import React from 'react';
import {
  Flex,
  CopyToClipboard,
  HSpacer,
  Icon,
  Text,
  Tooltip,
  BoxContainer,
} from '@mqd/volt-base';
import Pan from '../Pan/Pan.js';
import StatusIndicator from '../StatusIndicator/StatusIndicator.js';
import s from './TextColumn.module.css';
import { generateId } from './utilities.js';

const Label = ({ label, content, type, idPrefix }) => {
  const textType = type === 'header' ? 'h5' : 'h6';
  return (
    <Text type={textType} id={generateId(label, idPrefix)}>
      {label}
    </Text>
  );
};

const CopyToClipboardWrapper = ({ text, children }) =>
  text === 'N/A' ? (
    children
  ) : (
    <CopyToClipboard
      overrideValue={text}
      tooltipDirection="top"
      tooltipUncopiedText="Copy to the clipboard"
      tooltipCopiedText="Copied!"
      copyCallback={(e) => {
        e.stopPropagation();
      }}
    >
      {children}
      <HSpacer factor={0.5} />
    </CopyToClipboard>
  );

const ContentWrapper = ({ content, addCopyButton, label, idPrefix, addStatusIndicator }) => (
  <div className={s.contentWrapper}>
    {addCopyButton ? (
      <CopyToClipboardWrapper text={content}>
        <Text labelledBy={generateId(label, idPrefix)}>{content}</Text>
      </CopyToClipboardWrapper>
    ) : addStatusIndicator ? (
      <StatusIndicator status={content} labelledBy={generateId(label, idPrefix)} />
    ) : (
      <Text labelledBy={generateId(label, idPrefix)}>{content}</Text>
    )}
  </div>
);

const TextColumn = ({ data, header, idPrefix }) => {
  return (
    <section aria-labelledby={generateId(header, idPrefix)}>
      <Text type="h4" id={generateId(header, idPrefix)}>
        {header}
      </Text>
      {data.map(
        ({
          addCopyButton,
          addStatusIndicator,
          content,
          header,
          label,
          pan,
          hasContent = true,
          toggleVisibility,
          tooltip,
          tx_hash_internal_id,
          type,
        }) => (
          <BoxContainer key={generateId(label, idPrefix)} marginTop={2}>
            {header ? (
              <>
                <Text type="h5">{header}</Text>
              </>
            ) : (
              <Flex alignItems="flex-start">
                <div className={type === 'header' ? s.headerColumn : s.labelColumn}>
                  <div className={s.labelWrapper}>
                    <Label label={label} content={content} type={type} idPrefix={idPrefix} />
                    {tooltip && (
                      <div className={s.tooltipWrapper}>
                        <Tooltip content={tooltip} direction="topRight" textWrap>
                          <Icon type="info" hasTooltip />
                        </Tooltip>
                      </div>
                    )}
                  </div>
                </div>
                {hasContent && (
                  <ContentWrapper
                    addCopyButton={addCopyButton}
                    addStatusIndicator={addStatusIndicator}
                    content={content}
                    idPrefix={idPrefix}
                    label={label}
                  />
                )}
                {toggleVisibility && (
                  <Pan
                    tx_hash_internal_id={tx_hash_internal_id}
                    value={pan}
                    labelledBy={generateId(label, idPrefix)}
                  />
                )}
              </Flex>
            )}
          </BoxContainer>
        )
      )}
    </section>
  );
};

export default TextColumn;
