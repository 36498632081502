import React from 'react';
import { Modal, Button, CopyToClipboard, DetailPane } from '@mqd/volt-base';

function GlobalAlertBankInformationModal({ data, closeModal }) {
  return (
    <React.Fragment>
      {data && (
        <Modal
          type="lg"
          maxHeightVh={90}
          heading="Updated bank account information"
          description="Following is the bank account information that customers should use for payments of outstanding invoices."
          disableOverFlow={true}
          showHideModalButton
          hideModalButtonText="Dismiss"
          footerButtons={[<Button onClick={closeModal}>Dismiss</Button>]}
        >
          <DetailPane.Container>
            <DetailPane.Content label="Account Owner">{data.accountOwner}</DetailPane.Content>
            <DetailPane.Content label="Account Number">
              <CopyToClipboard type="body-sm">{data.accountNumber}</CopyToClipboard>
            </DetailPane.Content>
            <DetailPane.Content label="Account Name">{data.accountName}</DetailPane.Content>
            <DetailPane.Content label="Financial Institution Name">
              {data.financialInstitution}
            </DetailPane.Content>
            <DetailPane.Content label="Financial Institution City and State">
              {data.financialInstitutionCityState}
            </DetailPane.Content>
            <DetailPane.Content label="Wire Transfer Bank Routing and Transit Number">
              <CopyToClipboard type="body-sm">
                {data.wireTransferRoutingTransitNumber}
              </CopyToClipboard>
            </DetailPane.Content>
            <DetailPane.Content label="Wire Transfer SWIFT Code">
              <CopyToClipboard type="body-sm">{data.wireTransferSwiftCode}</CopyToClipboard>
            </DetailPane.Content>
            <DetailPane.Content label="ACH Transaction Bank Routing Number">
              <CopyToClipboard type="body-sm">
                {data.achTransacctionBankRoutingNumber}
              </CopyToClipboard>
            </DetailPane.Content>
          </DetailPane.Container>
        </Modal>
      )}
    </React.Fragment>
  );
}

export default GlobalAlertBankInformationModal;
