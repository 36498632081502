import moment from 'moment';
import { DATE_TIME_FORMAT_STRING } from 'views/fds/utilities/date.js';
import {
  COUNTRY_BY_CURRENCY_CODE,
  SUBNETWORKS,
  NETWORKS,
  TOOLTIP_DESCRIPTIONS,
} from '../../utilities/dictionary';
import { formatApiEnum } from 'views/fds/utilities/string';
import calculateAndFormatScaledDecimal from '../../utilities/number/calculate-and-format-scaled-decimal.js';

const TX_INFO_FIELDS = {
  source: ({ source, network }) => ({
    label: 'Source',
    content: source === 'MQ' ? 'Marqeta' : `${NETWORKS[network]}`,
  }),

  masked_pan: ({ masked_pan, tx_hash_internal_id }) => ({
    label: 'PAN',
    pan: masked_pan,
    toggleVisibility: true,
    tx_hash_internal_id,
    hasContent: false,
  }),

  transaction_token: ({ transaction_token, source }) => ({
    label: 'Token',
    content: source === 'MQ' ? transaction_token || 'N/A' : 'N/A',
    addCopyButton: true,
    tooltip: TOOLTIP_DESCRIPTIONS.TRANSACTION_TOKEN,
  }),

  original_id: ({ original_id, source }) => ({
    label: 'Original token',
    content: source === 'MQ' ? original_id || 'N/A' : 'N/A',
    tooltip: TOOLTIP_DESCRIPTIONS.ORIGINAL_TOKEN,
  }),

  retrieval_reference_number: ({ retrieval_reference_number, source, subnetwork }) => ({
    label: 'Retrieval reference number (RRN)',
    content:
      source === 'MQ' && SUBNETWORKS[subnetwork] === 'Net'
        ? 'N/A'
        : retrieval_reference_number || 'N/A',
  }),

  system_trace_audit_number: ({ system_trace_audit_number }) => ({
    label: 'System trace audit number (STAN)',
    content: system_trace_audit_number || 'N/A',
  }),

  transaction_type: ({ transaction_type }) => ({
    label: 'Transaction type',
    content: formatApiEnum(transaction_type) || 'N/A',
  }),

  transaction_code: ({ transaction_code }) => ({
    label: 'Transaction code',
    content: formatApiEnum(transaction_code) || 'N/A',
  }),

  created_date: ({ created_date, source }) => ({
    label: 'Created date',
    content:
      source === 'MQ'
        ? created_date
          ? moment(created_date).format(DATE_TIME_FORMAT_STRING)
          : 'N/A'
        : 'N/A',
    tooltip: TOOLTIP_DESCRIPTIONS.CREATED_DATE,
  }),

  local_transaction_date_time: ({ local_transaction_date_time }) => ({
    label: 'Local transaction date time',
    content: local_transaction_date_time,
  }),

  itc: ({ itc, source }) => ({
    label: 'Internal transaction code (ITC)',
    content: source === 'MQ' ? itc || 'N/A' : 'N/A',
  }),

  fee_program_indicator: ({ fee_program_indicator, source }) => ({
    label: 'Fee program indicator (FPI)',
    content: source === 'MQ' ? 'N/A' : fee_program_indicator || 'N/A',
  }),

  batch_number: ({ batch_number }) => ({
    label: 'Batch number',
    content: batch_number,
  }),

  reason_code: ({ reason_code }) => ({
    label: 'Reason code',
    content: reason_code,
  }),

  message_reason_code: ({ message_reason_code, source }) => ({
    label: 'Message reason code',
    content: source === 'MQ' ? 'N/A' : message_reason_code || 'N/A',
  }),

  merchant_name: ({ merchant_name }) => ({
    label: 'Merchant name',
    content: merchant_name,
  }),

  merchant_id: ({ merchant_id }) => ({
    label: 'Merchant ID',
    content: merchant_id,
  }),

  merchant_country: ({ merchant_country }) => ({
    label: 'Merchant country',
    content: COUNTRY_BY_CURRENCY_CODE[merchant_country],
  }),

  merchant_category_code: ({ merchant_category_code }) => ({
    label: 'Merchant category code',
    content: merchant_category_code,
  }),

  acquirer_reference_id: ({ acquirer_reference_id }) => ({
    label: 'Acquirer reference ID',
    content: acquirer_reference_id,
  }),

  conversion_fee_amount: ({ conversion_fee_amount, conversion_fee_currency }) => ({
    label: 'CCA and currency',
    content:
      calculateAndFormatScaledDecimal(conversion_fee_amount, conversion_fee_currency) || 'N/A',
  }),

  message_type_indicator: ({ message_type_indicator }) => ({
    label: 'Message type indicator',
    content: message_type_indicator || 'N/A',
  }),

  transaction_function: ({ transaction_function }) => ({
    label: 'Transaction function',
    content: transaction_function || 'N/A',
  }),

  processing_code: ({ processing_code }) => ({
    label: 'Processing code',
    content: processing_code || 'N/A',
  }),

  function_code: ({ function_code }) => ({
    label: 'Function code',
    content: function_code || 'N/A',
  }),

  billing_amount: ({ billing_amount }) => ({
    label: 'Billing amount',
    content: calculateAndFormatScaledDecimal(billing_amount) || 'N/A',
  }),

  business_activity: ({ business_activity }) => ({
    label: 'Business activity',
    content: business_activity || 'N/A',
  }),

  cycle: ({ cycle }) => ({
    label: 'Cycle',
    content: cycle || 'N/A',
  }),

  originator: ({ originator }) => ({
    label: 'Originator',
    content: originator || 'N/A',
  }),

  additional_information: ({ additional_information }) => ({
    label: 'Additional information',
    content: additional_information || 'N/A',
  }),
};

export default TX_INFO_FIELDS;
