import React from 'react';
import { useRouterParamsContext } from './context.js';

export default function withRouterGo(Component) {
  return function WrapperComponent(props) {
    const routerParams = useRouterParamsContext();

    return <Component {...props} routerParams={routerParams} />;
  };
}
