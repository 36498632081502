import currentUserStore from 'stores/CurrentUserStore';
import {
  CARD_DETAILS_VIEW,
  CARD_DETAILS_VIEW_AND_EDIT,
} from '../../../views/admin/uam-granular-permissions/constants.js';
import { isDdaProgram } from './functions.js';
import { janusToRedseaRoles } from './constants/redseaRoles.js';

export const requirements = {
  requiredGranularPermissions: [CARD_DETAILS_VIEW, CARD_DETAILS_VIEW_AND_EDIT],
};

export const verifier = () => {
  const { userStore = {} } = currentUserStore;
  const { hasRoleInArrayForActiveEnvironment, privateSandboxActive, uamGranularPermissionsActive } =
    userStore;
  const cardRoles = [CARD_DETAILS_VIEW, CARD_DETAILS_VIEW_AND_EDIT];
  const canViewCard = uamGranularPermissionsActive
    ? cardRoles.some((role_token) => userStore.redseaRoles?.includes(role_token))
    : userStore.unifyActive && !isDdaProgram();

  const canViewCardPrivateSandbox = uamGranularPermissionsActive
    ? hasRoleInArrayForActiveEnvironment(cardRoles)
    : hasRoleInArrayForActiveEnvironment(janusToRedseaRoles);

  if (privateSandboxActive) {
    return canViewCardPrivateSandbox;
  } else {
    return canViewCard;
  }
};

export const redirect = () => {
  if (isDdaProgram()) return 'programAccountholders';
  return false;
};
