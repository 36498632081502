import React, { useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import s from '../CardCreationDetail.module.css';
import InputWithCurrency from '../commons/InputWithCurrency';
import {
  ActionDropdown,
  Icon,
  Input,
  Select,
  HSpacer,
  VSpacer,
  Text,
  Multiselect,
  Checkbox,
} from '@mqd/volt-base';

function VelocityControl({
  velocityControl,
  isSingleUse,
  isAmountOnly,
  removeVelocityControl,
  duplicateVelocityControl,
  validateVelocityControlMaximums,
  testId,
}) {
  const inputWidths = 360;
  const {
    approvals_only,
    hasError,
    setAttr,
    amount_limit,
    usage_limit,
    velocity_window,
    velocityWindowOptions = [],
    validationWithRequiredUsage,
    selectedIncludeOptions,
    includeOnlyTypes,
    invalidIncludeTypeSelection,
  } = velocityControl;
  const [amountError, setAmountError] = useState(false);
  const [usageError, setUsageError] = useState(false);
  const [windowError, setWindowError] = useState(false);
  const showError = Boolean(hasError || amountError || usageError || windowError);
  const handleError = (field = '', toggleError = () => {}) => {
    const errorMessage = validationWithRequiredUsage[field];
    toggleError(Boolean(errorMessage));
    return errorMessage;
  };
  const windowOptions = isAmountOnly
    ? velocityWindowOptions
    : velocityWindowOptions.filter((window) => window !== 'TRANSACTION');
  const formattedWindowOptions = windowOptions.map((window) => ({
    val: window,
    render: window.toLowerCase(),
  }));

  return (
    <div data-testid={testId}>
      {includeOnlyTypes && (
        <>
          <Multiselect
            label="Included transaction types"
            sublabel="Default options are preselected."
            testId="velocity-control-form_include_types"
            showSearch={false}
            numOptions={5}
            options={Object.keys(includeOnlyTypes)}
            selected={selectedIncludeOptions}
            selectedToTop={true}
            onOptionClick={(option) => {
              const typeOption = includeOnlyTypes[option];
              const { key, value } = typeOption;
              setAttr(key, !value);
            }}
            width="258px"
            isError={() => invalidIncludeTypeSelection}
          />
          <VSpacer />
        </>
      )}
      <Checkbox
        active={approvals_only}
        testId="velocity-control-form_approval_only"
        onClick={() => setAttr('approvals_only', !approvals_only)}
        label="Apply control to approved transactions only (optional)"
      />
      <VSpacer />
      <div className={s.flexDiv}>
        <InputWithCurrency
          label="Amount"
          placeholder="Add amount"
          width={inputWidths}
          rightAddOn={{
            content: (
              <div className={s.inputRightAddOnCurrencyFlag}>
                <Icon type="usa-flag" noHoverEffects style={{ width: 20 }} />
                <HSpacer factor={0.875} />
                <Text>USD</Text>
              </div>
            ),
          }}
          value={amount_limit}
          onChange={(e) => setAttr('amount_limit', e.target.value)}
          isError={() => handleError('amount_limit', setAmountError)}
          error={hasError ? validationWithRequiredUsage['amount_limit'] : ''}
          testId="velocity-control-form_amount_limit"
          preventErrorPushdown
        />

        {!isSingleUse && !isAmountOnly && (
          <>
            <HSpacer />
            <Text className={s.velocityControlInBetweenFields}>and</Text>
            <HSpacer />
            <Input
              label="Usage"
              placeholder="Add usage"
              width={inputWidths}
              rightAddOn={{
                content: <div className={s.inputRightAddOnCurrency}>Times</div>,
              }}
              value={usage_limit}
              onChange={(e) => setAttr('usage_limit', e.target.value)}
              isError={() => handleError('usage_limit', setUsageError)}
              preventErrorPushdown
              error={hasError ? validationWithRequiredUsage['usage_limit'] : ''}
              testId="velocity-control-form_usage_limit"
            />
          </>
        )}

        {!isSingleUse && (
          <>
            <HSpacer />
            <Text className={s.velocityControlInBetweenFields}>per</Text>
            <HSpacer />
            <Select
              label="Time period"
              placeholder="Select "
              options={formattedWindowOptions}
              value={velocity_window ? velocity_window.toLowerCase() : ''}
              onChange={({ val }) => {
                setAttr('velocity_window', val);
                validateVelocityControlMaximums();
              }}
              isError={() => handleError('velocity_window', setWindowError)}
              error={hasError ? validationWithRequiredUsage['velocity_window'] : ''}
              testId="velocity-control-form_velocity_window"
              preventErrorPushdown
            />
          </>
        )}
        <HSpacer factor={1} />
        <div className={s.velocityControlInBetweenFields}>
          <ActionDropdown
            control={<Icon factor={0.5} type="view-more-vertical" />}
            content={[
              {
                element: 'Remove',
                onClick: removeVelocityControl,
              },
              {
                element: 'Duplicate',
                onClick: duplicateVelocityControl,
              },
            ]}
          />
        </div>
      </div>

      {showError && <VSpacer factor={2} />}
    </div>
  );
}

VelocityControl.propTypes = {
  velocityControl: PropTypes.object,
  isSingleUse: PropTypes.bool,
  isAmountOnly: PropTypes.bool,
  removeVelocityControl: PropTypes.func,
  duplicateVelocityControl: PropTypes.func,
  testId: PropTypes.string,
};

VelocityControl.defaultProps = {
  velocityControl: {},
  isSingleUse: false,
  isAmountOnly: false,
  removeVelocityControl: () => {},
  duplicateVelocityControl: () => {},
  testId: 'velocity-control_input-row',
};

export default observer(VelocityControl);
