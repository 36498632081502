import { googleAnalytics } from '@mq/volt-amc-container';
import logger from 'utils/logger';

// Implementation based on: https://github.com/GoogleChrome/web-vitals#using-analyticsjs
const sendWebVitalToGoogleAnalytics = ({ name, delta, id }, currentPath) => {
  try {
    googleAnalytics.event({
      category: 'Web Vitals',
      action: `${currentPath} - ${name}`,
      // Google Analytics metrics must be integers, so the value is rounded.
      // For CLS the value is first multiplied by 1000 for greater precision
      // (note: increase the multiplier for greater precision if needed).
      value: Math.round(name === 'CLS' ? delta * 1000 : delta),
      label: id,
      // Use a non-interaction event to avoid affecting bounce rate.
      nonInteraction: true,
    });
  } catch (error) {
    logger.error(error);
  }
};

export default sendWebVitalToGoogleAnalytics;
