import React, { useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Button } from '@mqd/volt-base';

import routerStore from '../../../../../../stores/router/RouterStore.js';
import { APPROVE_TRANSACTION_ADJUSTMENT } from '../../../../queries/approveTransactionHashAdjustment/query.js';
import { createNetRefIdForToast } from '../../../../utilities/string/index.js';

import { createRejectOrApproveFailureMessage } from '../../../ApprovalSettlementDateQueue/utils.js';

const ApproveButton = ({
  disabled,
  onLoading,
  onResponse,
  txHashInternalId,
  adjustmentIdx,
  netRefId,
  setApproveOrRejectResponse,
}) => {
  const [approveSelectedTransactionAdjustments, { data, error, loading }] = useMutation(
    APPROVE_TRANSACTION_ADJUSTMENT,
    {
      variables: {
        transactionApprovalIds: [
          {
            tx_hash_internal_id: txHashInternalId,
            adjustment_idx: adjustmentIdx,
          },
        ],
      },
    }
  );

  useEffect(() => {
    if (data && !loading) {
      const { message, unsuccessful_tx_hash_internal_ids: unsuccessful_ids } =
        data.approveTransactionAdjustment ?? {};

      if ((unsuccessful_ids && unsuccessful_ids.length) || error) {
        setApproveOrRejectResponse({
          message: message || createRejectOrApproveFailureMessage('approved', { multiple: false }),
          heading: 'Approval failed',
          error: true,
        });
      } else {
        let toastMessage = `${createNetRefIdForToast(netRefId)} approved`;

        const urlParams = new URLSearchParams(window.location.search);
        urlParams.delete('tx_hash_internal_id');
        routerStore.go(`/settlement/approvals/${routerStore.pathParams.type}/settlement-date`, {
          params: urlParams,
          state: {
            toastMessage,
          },
        });
      }
    }
  }, [data, error, loading, netRefId, setApproveOrRejectResponse]);

  useEffect(() => {
    if (loading) {
      setApproveOrRejectResponse({
        error: false,
      });
    }
  }, [loading, setApproveOrRejectResponse]);

  useEffect(() => {
    const cb = loading ? onLoading : onResponse;
    if (typeof cb === 'function') {
      cb();
    }
  }, [loading, onLoading, onResponse]);

  return (
    <Button
      loading={loading}
      disabled={disabled}
      type="tertiary"
      onClick={approveSelectedTransactionAdjustments}
    >
      Approve
    </Button>
  );
};

export default ApproveButton;
