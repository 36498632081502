import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import {
  Alert,
  Tooltip,
  Icon,
  HSpacer,
  VSpacer,
  Modal,
  Button,
  LoadingOverlay,
  SegmentedButtons,
  Textarea,
  Text,
} from '@mqd/volt-base';
import { convertStringToSentenceCase } from './utils';

class ChangeAccountStatusModal extends Component {
  constructor(props) {
    super(props);

    const { defaultStatus } = this.props;
    this.state = {
      status: defaultStatus,
      reason: null,
      modalError: null,
      pristine: true,
    };
  }

  async handleSave() {
    const { status, reason } = this.state;
    if (!reason) {
      return this.setState({ ...this.state, pristine: false });
    }
    const { store, hideModal, onError, onComplete } = this.props;
    const params = {
      state: status,
      reason,
    };

    try {
      await store.changeStatus(params);
      onComplete && onComplete(status);
      hideModal();
    } catch (e) {
      console.error(e);
      onError && onError();
      this.setState({ modalError: e });
    }
  }

  getStatusOptions = () => {
    const { status } = this.state;
    const { store } = this.props;

    return store.statusTransitionOptions.map((availableStatus) => {
      return {
        text: convertStringToSentenceCase(availableStatus),
        active: status === availableStatus,
        onClick: () => this.setState({ status: availableStatus }),
      };
    });
  };

  render() {
    const { hideModal, store } = this.props;
    const { lastFour, loading } = store;
    const { reason, modalError, status, pristine } = this.state;
    return (
      <Modal
        heading={`Change account status ${String.fromCharCode(8212)} **${lastFour}`}
        hideModal={hideModal}
        disableOverFlow
        width={600}
        footerButtons={[<Button onClick={() => this.handleSave()}>Save</Button>]}
      >
        <LoadingOverlay active={loading}>
          {!modalError ? null : (
            <>
              <Alert type="error">{modalError}</Alert>
              <VSpacer factor={3} />
            </>
          )}
          {!store.statusTransitionOptions ? null : (
            <SegmentedButtons
              options={this.getStatusOptions()}
              label={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Text type="h6">Status</Text>
                  <HSpacer factor={0.5} />
                  <div style={{ height: 20 }}>
                    <Tooltip
                      direction="topRight"
                      content={`
                          Suspended accounts can be reactivated;
                          terminated accounts are permanently inactive.
                        `}
                    >
                      <Icon type="info" factor={0.5} />
                    </Tooltip>
                  </div>
                </div>
              }
            />
          )}
          <VSpacer factor={3} />
          <Textarea
            label={
              <>
                <Text type="h6">Status change reason</Text>
              </>
            }
            rows={2}
            value={reason}
            noResize
            onChange={(e) => this.setState({ reason: e.target.value })}
            maxLength={255}
            error={!reason && !pristine ? 'This field is required.' : ''}
            testId="change-account-status-reason"
          />
          {status === 'TERMINATED' && (
            <>
              <VSpacer factor={3} />
              <Alert type="error">Terminating an account is a permanent action.</Alert>
            </>
          )}
        </LoadingOverlay>
      </Modal>
    );
  }
}

ChangeAccountStatusModal.propTypes = {
  hideModal: PropTypes.func,
  store: PropTypes.object,
  defaultStatus: PropTypes.string,
};

ChangeAccountStatusModal.defaultProps = {
  hideModal: () => {},
  store: {},
  defaultStatus: null,
};

export default observer(ChangeAccountStatusModal);
