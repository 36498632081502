import { decorate, observable } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';

class LinkStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
  }
  // values
  rel: string = '';
  method: string = '';
  href: string = '';
}

decorate(LinkStore, {
  rel: observable,
  method: observable,
  href: observable,
});

export default LinkStore;
