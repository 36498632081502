import { useHashExceptionDetailsState } from '../../utilities/use-hash-exception-details/context.js';

import { createDetailsColumn } from './utils.js';

export default function useApprovalDetailsPageSettledDetails() {
  const { data, loading } = useHashExceptionDetailsState();

  if (loading) {
    return [];
  }

  const { settled_details = {}, common_data: { currency_code } = {} } = data;

  return createDetailsColumn({
    currencyCode: currency_code,
    details: settled_details,
    feesTotal: settled_details.fees,
    header: 'Settled transaction summary',
    total: settled_details.settled_total,
    transactionsAmounts: settled_details.transactions,
    type: 'settled',
    interchangeFee: settled_details.interchange_fee,
  });
}
