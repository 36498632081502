import { useQuery } from '@apollo/react-hooks';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Text, VSpacer } from '@mqd/volt-base';
import { Page } from '@mqd/volt-layout/pre';
import { equals } from 'ramda';
import s from './Layout.module.css';
import Header from './../header/Header.js';
import CFNTopBar from './../header/CFNTopBar.js';
import routerStore from 'stores/router/RouterStore';
import userStore from 'stores/UserStore';
import { getBreadcrumbRootRoute } from '../../../utils/Breadcrumb.js';
import ProgramSelector from '../../../components/program-selector/ProgramSelector.js';
import withProgramSelector from '../../../components/program-selector/components/withProgramSelector.js';
import withRouterUrl from '../../../components/router-url/with-router-url.js';
import { verifier } from '../../../utils/route-authorization/index.js';
import NewLeftNav from '../left-nav/LeftNav.js';
import { CFN_CONFIG } from '../left-nav/cfn-config.js';
import { cfnLeftNav } from '../left-nav/cfn-left-nav.js';
import { FlipFlop } from 'utils';
import Footer from '../footer/index.js';
import { RealTimeDecisioningModal } from 'components';
import GlobalAlertBankInformation from '../bankInformation/GlobalAlertBankInformation';
import GlobalAlertBankInformationModal from '../bankInformation/GlobalAlertBankInformationModal';
import { BANK_TRANSFER_INFORMATION } from './queries';
import { secureStorage } from '@mq/volt-amc-container';

function CFNProgramSelectorHeader() {
  return (
    <>
      <Text type="label">Program</Text>
      <VSpacer factor={1} />
      <ProgramSelector
        width="100%"
        marginLeft="-4px"
        getBreadcrumbRootRoute={getBreadcrumbRootRoute}
      />
    </>
  );
}

function Layout(props) {
  const cfnBetaActive = FlipFlop.get('cfn-beta', false);

  return cfnBetaActive ? <CFNLayout {...props} /> : <OldLayout {...props} />;
}

function CFNLayout({ children, footerPostfix, programSelector, url }) {
  const [activeContent, setActiveContent] = useState({});
  const [activeNavPath, setActiveNavPath] = useState('');
  const [showRealTimeDecisioning, setShowRealTimeDecisioning] = useState(false);
  const [showBankTransferModal, setShowBankTransferModal] = useState(false);
  const webToken = secureStorage.getItem('webToken');
  const { data: bankTransferData, bankTransferError } = useQuery(BANK_TRANSFER_INFORMATION, {
    variables: { webToken },
  });
  const bankTransferDataFlagActive = FlipFlop.get('bank-transfer-information', false);

  const updateContent = useCallback(
    (url) => {
      const activeNavLink = cfnLeftNav.leftNav[url?.pathname];
      const newContentConfig = cfnLeftNav.workspaces[activeNavLink?.workspaceId || 'dashboard'];
      // With our current component architecture, navigating to links
      // causes a re-render of all layout components. The cfnVerified
      // flag was added in order to only parse CFN/leftnav data
      // on initial load. There is opportunity to refactor our
      // page/layout components structure to only re-render
      // changing content when routes change.
      setActiveNavPath(activeNavLink?.path || url?.pathname);
      setActiveContent(newContentConfig);
    },
    [setActiveNavPath, setActiveContent]
  );

  // Initialize config for left nav.
  useEffect(() => {
    cfnLeftNav.configure(CFN_CONFIG, verifier);
    cfnLeftNav.authorize();
  }, []);

  // Reauthorize leftnav when active program gets bootstrapped.
  const { activeProgramBootstrapCompleted } = programSelector || {};
  useEffect(() => {
    if (activeProgramBootstrapCompleted) {
      cfnLeftNav.authorize();
    }
  }, [activeProgramBootstrapCompleted]);

  useEffect(() => {
    const { programsMetadata, activeProgram } = programSelector;
    const prevProgramsMetadata = JSON.parse(JSON.stringify(userStore.programsMetadata));
    if (programsMetadata && !equals(prevProgramsMetadata, programsMetadata)) {
      userStore.programsMetadata = programsMetadata;
    }
    if (activeProgram) {
      userStore.setActiveProgram(activeProgram);
      updateContent(window.location);
    }
  }, [programSelector, updateContent]);

  useEffect(() => {
    updateContent(url);
    setShowRealTimeDecisioning(routerStore.currentParams.has('real-time-decisioning'));
  }, [url, updateContent]);

  const showGlobalAlertBanner =
    bankTransferDataFlagActive && !bankTransferError && bankTransferData;
  return (
    <>
      {showGlobalAlertBanner && showBankTransferModal && (
        <GlobalAlertBankInformationModal
          data={bankTransferData.bankTransferInformation}
          closeModal={() => setShowBankTransferModal(false)}
        />
      )}
      {showGlobalAlertBanner && (
        <GlobalAlertBankInformation showModal={() => setShowBankTransferModal(true)} />
      )}
      <Page
        // loading={true}
        topBar={<CFNTopBar activeContent={activeContent} />}
        leftNav={activeContent?.leftNav}
        leftNavHeader={activeContent?.programSelector && <CFNProgramSelectorHeader />}
        go={routerStore.go}
        activePath={activeNavPath}
        persist={true}
        testId="layout-cfn"
      >
        {showRealTimeDecisioning && (
          <RealTimeDecisioningModal
            hideModal={() => setShowRealTimeDecisioning(false)}
            version={url?.searchParams?.get('real-time-decisioning')}
          />
        )}
        <div>{children}</div>
        <Footer postfix={footerPostfix} />
      </Page>
    </>
  );
}

function OldLayout({ children, footerPostfix, programSelector, type, url, wrapStyle }) {
  const [showRealTimeDecisioning, setShowRealTimeDecisioning] = useState(false);
  const [showBankTransferModal, setShowBankTransferModal] = useState(false);
  const webToken = secureStorage.getItem('webToken');
  const { data: bankTransferData, bankTransferError } = useQuery(BANK_TRANSFER_INFORMATION, {
    variables: { webToken },
  });
  const bankTransferDataFlagActive = FlipFlop.get('bank-transfer-information', false);

  const { activeProgramBootstrapCompleted } = programSelector;
  const leftNavRefreshKey = useMemo(
    () => 'activeProgramBootstrapCompleted:' + !!activeProgramBootstrapCompleted,
    [activeProgramBootstrapCompleted]
  );

  useEffect(() => {
    const { programsMetadata, activeProgram } = programSelector;
    const prevProgramsMetadata = JSON.parse(JSON.stringify(userStore.programsMetadata));
    if (programsMetadata && !equals(prevProgramsMetadata, programsMetadata)) {
      userStore.programsMetadata = programsMetadata;
    }
    if (activeProgram) {
      userStore.setActiveProgram(activeProgram);
    }
  }, [programSelector]);

  useEffect(() => {
    setShowRealTimeDecisioning(url?.searchParams.has('real-time-decisioning'));
  }, [url]);

  /*
    Temporary class:
    After GA complete, the .reskinLite styles can be moved to .content.
  */
  const showGlobalAlertBanner =
    bankTransferDataFlagActive && !bankTransferError && bankTransferData;
  return (
    <>
      {showGlobalAlertBanner && showBankTransferModal && (
        <GlobalAlertBankInformationModal
          data={bankTransferData.bankTransferInformation}
          closeModal={() => setShowBankTransferModal(false)}
        />
      )}
      {showGlobalAlertBanner && (
        <GlobalAlertBankInformation showModal={() => setShowBankTransferModal(true)} />
      )}
      <div className={s.wrapperForMargin}>
        {showRealTimeDecisioning && (
          <RealTimeDecisioningModal
            hideModal={() => setShowRealTimeDecisioning(false)}
            version={url?.searchParams?.get('real-time-decisioning')}
          />
        )}
        <div
          className={[s.layoutContainer, s.reskinLite].join(' ')}
          data-testid="layout-container_new-view"
        >
          <div>
            <NewLeftNav key={leftNavRefreshKey} />
            <div className={`${s.wrap} ${s[type] ? s[type] : ''} ${s.reskinLite}`}>
              <Header routerStore={routerStore} />
              <div className={s.page}>
                <div className={s.content}>
                  <div className={s.container}>{children}</div>
                  <Footer postfix={footerPostfix} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

Layout.propTypes = {
  type: PropTypes.string,
  children: PropTypes.any,
};

Layout.defaultProps = {
  type: 'default',
  children: null,
};

export default withRouterUrl(withProgramSelector(observer(Layout)));
