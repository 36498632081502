import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Button,
  Select,
  Textarea,
  VSpacer,
  HSpacer,
  LoadingOverlay,
  Flex,
  Form,
} from '@mqd/volt-base';
import { bankTransferTypeMapping } from './constants.js';

const CancelBankTransferModal = ({ loading, hideModal, onSubmit, error, type }) => {
  const { cancelReasons, descriptionProps } = bankTransferTypeMapping[type];

  return (
    <Modal
      type="lg"
      maxHeightVh={90}
      heading="Cancel transfer"
      description="Please specify a reason for canceling this transfer."
      disableOverFlow={true}
      showHideModalButton={false}
      error={error && 'Something went wrong with your request'}
    >
      <Form>
        <LoadingOverlay active={loading}>
          <Select
            required
            label="Reason"
            labelFor="transaction_cancel_reason"
            name="reason"
            id="transaction_cancel_reason"
            options={cancelReasons}
          />
          <VSpacer factor={3} />
          <Textarea
            labelFor="transaction_cancel_description"
            name="description"
            id="transaction_cancel_description"
            noResize={true}
            maxLength={150}
            {...descriptionProps}
          />
          <VSpacer factor={3} />
          <Flex display="flex" justifyContent="flex-end">
            <Button type="tertiary" onClick={hideModal} disabled={loading}>
              Cancel
            </Button>
            <HSpacer />
            <Button
              submit
              testId="return_transition_modal_submit"
              type="primary"
              disabled={loading}
              onClick={({ reason = '', description = '' }) =>
                onSubmit({
                  reason: `${reason}${description ? ` - ${description}` : ''}`,
                })
              }
            >
              Confirm
            </Button>
          </Flex>
        </LoadingOverlay>
      </Form>
    </Modal>
  );
};

CancelBankTransferModal.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  hideModal: PropTypes.func,
  error: PropTypes.object,
  type: PropTypes.oneOf(['user', 'program']),
};

CancelBankTransferModal.defaultProps = {
  type: 'user',
};

export default CancelBankTransferModal;
