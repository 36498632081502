import React, { useRef, useEffect } from 'react';
import { Button, Flex } from '@mqd/volt-base';

import { Button as TempButton } from '../../../../../../../../components/button/Button';
import { useCardTemplateContext } from '../../../../../../../../context/CardTemplateContext';
import { disabledStatuses } from '../../../../../../../../constants';

/**
 * An object that contains actions we can apply to a card
 * @typedef {Object} Actions
 * @property {Function} onCreateNewCard - The callback to be called when we want to create a new card
 * @property {Function} onUpdateCard - The callback to be called when we want to update a card
 * @property {Function} onDeleteCard - The callback to be called when we want to remove a card
 * @property {Function} onResetCard - The callback to be called when we want to reset (undo any changes) a card
 */

/**
 * Returns rendered react elements to be placed inside the Form component
 * @param {Object} formState
 * @param {Actions} actions
 * @param {string} cardType
 * @param {Function} switchToPreview
 * @returns {JSX.Element}
 */
export function renderControls({ formState, actions, cardType, switchToPreview }) {
  const { status } = useCardTemplateContext();

  const cancelButtonRef = useRef();
  useEffect(() => {
    if (formState.submitted && !formState.allFieldsAreValid) {
      cancelButtonRef.current.focus();
    }
  }, [cancelButtonRef, formState.submitted, formState.allFieldsAreValid]);

  const createActionHandler = (callback) => () => {
    callback(formState);
    switchToPreview();
  };

  return (
    !disabledStatuses.has(status) && (
      <>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex display="flex">
            {formState.temp ? (
              <Button
                type="primary"
                submit
                onClick={createActionHandler(actions.onCreateNewCard)}
                disabled={formState.submitted && !formState.allFieldsAreValid}
              >
                {`Add ${cardType}`}
              </Button>
            ) : (
              <Button
                type="primary"
                submit
                onClick={createActionHandler(actions.onUpdateCard)}
                disabled={formState.submitted && !formState.allFieldsAreValid}
              >
                {`Update ${cardType}`}
              </Button>
            )}
          </Flex>

          {/* TODO: replace after this ticket is done https://marqeta.atlassian.net/browse/VOLTENG-45 */}
          <TempButton
            ref={cancelButtonRef}
            mod="secondary"
            link
            onClick={createActionHandler(actions.onResetCard)}
          >
            Cancel
          </TempButton>
        </Flex>
      </>
    )
  );
}
