import currentUserStore from 'stores/CurrentUserStore';
import { FlipFlop } from '../../index.js';

export const requirements = {
  validJanusAccount: true,
};

export const verifier = () => {
  const hasUpgradeFlag = FlipFlop.get('upgrade-permissions', false);

  const upgradeUser = currentUserStore.userStore.userOrgName === 'Upgrade';
  const upgradeUserWithViewDivaApiAccess =
    upgradeUser &&
    currentUserStore.userStore.redseaRoles &&
    currentUserStore.userStore.redseaRoles.includes('aux-view-diva-api-access');

  const userHasNoReportsSupplement = currentUserStore.userStore.hasNoReportsSupplement;

  return hasUpgradeFlag
    ? currentUserStore.userStore.loggedInToJanus &&
        !(upgradeUser && !upgradeUserWithViewDivaApiAccess)
    : currentUserStore.userStore.loggedInToJanus && !userHasNoReportsSupplement;
};
