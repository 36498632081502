import React from 'react';
import { observer } from 'mobx-react';
import routerStore from 'stores/router/RouterStore';
import currentUserStore from 'stores/CurrentUserStore';
import { verifier } from '../../../utils/route-authorization/index.js';
import { NewLeftNav } from '@mq/volt-amc-container';
import { HSpacer } from '@mqd/volt-base';
import { getBreadcrumbRootRoute } from '../../../utils/Breadcrumb.js';
import ProgramSelector from '../../../components/program-selector/ProgramSelector.js';
import SandboxName from '../../../components/sandbox-name/SandboxName.js';
import {
  hideProgramSelectorForRouteNamed,
  hideProgramSelectorForUser,
} from './leftNavFunctions.js';

function LeftNav() {
  const { currentPath, currentRouteName, go } = routerStore;
  const { userStore } = currentUserStore;
  const hideProgramSelector =
    hideProgramSelectorForRouteNamed(currentRouteName) || hideProgramSelectorForUser(userStore);

  return (
    <div>
      <div style={{ height: '72px', display: 'flex', alignItems: 'center' }}>
        <HSpacer factor={2} />
        {hideProgramSelector ? (
          <SandboxName />
        ) : (
          <ProgramSelector getBreadcrumbRootRoute={getBreadcrumbRootRoute} />
        )}
      </div>
      <NewLeftNav currentPath={currentPath} go={go} verifier={verifier} />
    </div>
  );
}

export default observer(LeftNav);
