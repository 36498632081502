import React from 'react';
import { Colors, HSpacer, Icon, RawDropdown, Text, VSpacer } from '@mqd/volt-base';
import PropTypes from 'prop-types';
import s from './KYBCard.module.css';
import { approvalStatusColors, iconInputOptions } from '../../constants';

// helper function
function DropDownContent({ status, onChangeStatus, field }) {
  const dropdownOptions = status
    ? iconInputOptions[status].dropdownOptions
    : ['Verified', 'Rejected'];
  return (
    <div className={s.dropdownContent}>
      {dropdownOptions.map((statusType, i) => {
        const colorName = approvalStatusColors[statusType];
        const color = Colors[colorName] || 'gray';
        const needsSpacer = i !== dropdownOptions.length - 1;
        return (
          <div key={`approval-status-option-${i}`}>
            <Status
              color={color}
              onChangeStatus={onChangeStatus}
              field={field}
              statusType={statusType}
            />
            {needsSpacer && <VSpacer />}
          </div>
        );
      })}
    </div>
  );
}

// helper function
function Status({ color, onChangeStatus, field, statusType }) {
  return (
    <div
      data-testid={`kyb-test-status-${statusType}`}
      onClick={(e) => onChangeStatus(field, statusType)}
      className={s.statusContainer}
    >
      <div className={s.statusIcon} style={{ backgroundColor: color }} />
      <HSpacer />
      <Text>{statusType}</Text>
    </div>
  );
}

function IconInput({ status, field, onChangeStatus }) {
  const statusIcon = status ? iconInputOptions[status].icon : 'pending';
  const statusMod = status === 'FAILURE' ? 'error' : '';
  const overrideColor = status === 'SUCCESS' ? Colors.statusSuccessColor : '';
  return (
    <div className={s.iconContainer} data-testid={`KYB-icon-input-${status}`}>
      <RawDropdown
        control={<Icon type={statusIcon} mod={statusMod} overrideColor={overrideColor} />}
        anchor="right"
        testId="KYB-icon-input-dropdown"
      >
        <DropDownContent
          status={status}
          onChangeStatus={onChangeStatus}
          field={field}
          status={status}
        />
      </RawDropdown>
    </div>
  );
}

IconInput.propTypes = {
  status: PropTypes.oneOf(['SUCCESS', 'FAILURE', 'PENDING', '']),
  onChangeStatus: PropTypes.func,
};
IconInput.defaultProps = {
  status: 'PENDING',
  onChangeStatus: () => {},
};

export default IconInput;
