import {
  EXPORT_TYPES,
  SUBNETWORK_TO_FULL_NAME,
  EXCEPTION_TYPES,
} from '../../utilities/dictionary/index.js';
import { format } from '../../utilities/date.js';

/**
 * filename from backend has the following format:
 *   <date>-<request info>-<nonce>.csv
 * this method extracts the nonce
 */
const getNonceFromFilename = (filename) => {
  const name = filename.split('.')[0];
  const fields = name.split('-');
  return fields[fields.length - 1];
};

const getDetailsFromRow = (row) => {
  switch (row.export_type) {
    case 'EXCEPTION_QUEUE':
      return (
        (SUBNETWORK_TO_FULL_NAME[row.request_info.subnetwork] || row.request_info.subnetwork)
          .split(' ')
          .join('-') +
        '_' +
        (EXCEPTION_TYPES[row.request_info.exception_type] || row.request_info.exception_type)
          .split(' ')
          .join('-')
      );
    default:
      return '';
  }
};

/**
 * Returns a filename formatted as follows:
 *   <export type>_<details>_<date>_<nonce>
 */
export const rowToFilename = (row) => {
  const export_type = EXPORT_TYPES[row.export_type].split(' ').join('_');
  const details = getDetailsFromRow(row);
  const date = format(row.date_requested, false);
  const nonce = getNonceFromFilename(row.filename);

  return `${export_type}_${details}_${date}_${nonce}.csv`;
};
