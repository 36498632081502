import { decorate, observable } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';

class AssociationStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
  }
  // values
  cardholder_token: string = '';
  card_product_token: string = '';
  business_token: string = '';
}

decorate(AssociationStore, {
  // values
  cardholder_token: observable,
  card_product_token: observable,
  business_token: observable,
});

export default AssociationStore;
