// @flow
import logger from 'utils/logger';
import { action, decorate, observable } from 'mobx';
import { MODAL_ERRORS } from '../../constants.js';

class ErrorModalStore {
  // values
  state = {};

  // actions
  showRejectConfirmationModal(btnClickCallback: func, closeCallback?: func) {
    logger.log('showError');
    this.state = {
      ...MODAL_ERRORS.REJECT_CONFIRMATION,
      btnClickCallback,
      closeCallback,
    };
  }

  showArchiveConfirmationModal(btnClickCallback: func, closeCallback?: func) {
    logger.log('showError');
    this.state = {
      ...MODAL_ERRORS.ARCHIVE_CONFIRMATION,
      btnClickCallback,
      closeCallback,
    };
  }

  showFormValidationModal(btnClickCallback?: func, closeCallback?: func) {
    this.state = {
      ...MODAL_ERRORS.FORM_VALIDATION,
      btnClickCallback,
      closeCallback,
    };
  }

  showAPIErrorModal(btnClickCallback: func, closeCallback: func, customHeader) {
    this.state = {
      ...MODAL_ERRORS.API_ERROR,
      btnClickCallback,
      closeCallback,
      ...(customHeader && { heading: customHeader }),
    };
  }

  showCustomErrorModal(error: {
    showError: boolean,
    heading: string,
    description?: string,
    btnText: string,
    btnClickCallback: func,
    closeCallback: func,
  }) {
    this.state = error;
  }

  clearError() {
    this.state = {};
  }
}

decorate(ErrorModalStore, {
  // values
  state: observable,

  // actions
  showRejectConfirmationModal: action.bound,
  showFormValidationModal: action.bound,
  showAPIErrorModal: action.bound,
  showCustomErrorModal: action.bound,
  clearError: action.bound,
});

const errorModal = new ErrorModalStore();

export default errorModal;
