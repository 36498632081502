import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { DetailSnippetRow } from '@mq/voltron-parent';
import { Card, VSpacer, Text } from '@mqd/volt-base';
import { StoreContext } from '@mqd/volt-contexts';
import VelocityControlUsageLimitsWithTypes from '../velocity-control/VelocityControlUsageLimitsWithTypes';

function CardAuthAndVelocityControlsCard({
  authFormattedMccs,
  formattedAmountLimits,
  velocityControlUsageLimitsWithTypes,
  hasSingleUseVelocityControl,
}) {
  return (
    <StoreContext.Consumer>
      {({ ProgramConfigStore = {} } = {}) => {
        const { authDefault, isWhiteList } = ProgramConfigStore;

        return (
          <Card testId="card-auth-velocity-control-card" contentPadding="0">
            <Text type="h4">Card velocity and auth controls</Text>
            <VSpacer factor={2} />

            <DetailSnippetRow label="MCC auth control">
              {authDefault ? [`Default ${authDefault.toLowerCase()}`] : null}
            </DetailSnippetRow>
            <DetailSnippetRow label={`${isWhiteList ? 'Allow list' : 'Deny list'} MCC(s)`}>
              {authFormattedMccs}
            </DetailSnippetRow>
            <DetailSnippetRow label="Usage type">
              {hasSingleUseVelocityControl ? 'Single use' : 'Multi use'}
            </DetailSnippetRow>
            <DetailSnippetRow label="Velocity control(s)">
              <VelocityControlUsageLimitsWithTypes>
                {velocityControlUsageLimitsWithTypes}
              </VelocityControlUsageLimitsWithTypes>
            </DetailSnippetRow>
          </Card>
        );
      }}
    </StoreContext.Consumer>
  );
}

CardAuthAndVelocityControlsCard.propTypes = {
  authFormattedMccs: PropTypes.array,
  formattedAmountLimits: PropTypes.array,
  velocityControlUsageLimits: PropTypes.array,
  hasSingleUseVelocityControl: PropTypes.bool,
};

CardAuthAndVelocityControlsCard.defaultProps = {
  authFormattedMccs: null,
  formattedAmountLimits: null,
  velocityControlUsageLimits: null,
  hasSingleUseVelocityControl: false,
};

export default observer(CardAuthAndVelocityControlsCard);
