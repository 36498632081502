import { decorate, observable } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';

class ResponseStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
  }
  // values
  code: string = '';
  memeo: string = '';
}

decorate(ResponseStore, {
  code: observable,
  memo: observable,
});

export default ResponseStore;
