import {
  MARQETA_ADMIN_INTERNAL,
  PROGRAM_ADMIN,
  SUPPLIER_PAYMENTS_MANAGER,
  DELIVERY_INTERNAL,
  PRODUCTION_SUPPORT_INTERNAL,
  CARDHOLDER_SUPPORT,
  CREDIT_PRODUCT_CREATOR,
  CREDIT_PRODUCT_APPROVER,
  CREDIT_OFFER_CREATOR,
  CREDIT_OFFER_APPROVER,
  CREDIT_SUPPORT_AGENT,
  CREDIT_PRODUCT_VIEW_ONLY,
} from './constants/redseaRoles.js';
import currentUserStore from 'stores/CurrentUserStore';
import hasRoleForAtLeastOneProgram from '../../helpers/hasRoleForAtLeastOneProgram.js';
import hasRoleForSelectedProgram from '../../helpers/hasRoleForSelectedProgram.js';
import { CARD_PRODUCTS_VIEW } from '../../../views/admin/uam-granular-permissions/constants.js';

const requiredUserPermissions = [
  MARQETA_ADMIN_INTERNAL,
  PROGRAM_ADMIN,
  SUPPLIER_PAYMENTS_MANAGER,
  DELIVERY_INTERNAL,
  PRODUCTION_SUPPORT_INTERNAL,
  CARDHOLDER_SUPPORT,
  CREDIT_PRODUCT_CREATOR,
  CREDIT_PRODUCT_APPROVER,
  CREDIT_OFFER_CREATOR,
  CREDIT_OFFER_APPROVER,
  CREDIT_SUPPORT_AGENT,
  CREDIT_PRODUCT_VIEW_ONLY,
];

const requiredGranularPermissions = [CARD_PRODUCTS_VIEW];

export const requirements = {
  requiredUserPermissions,
  requiredGranularPermissions,
};

export const verifier = () => {
  const {
    userStore: {
      hasRole,
      privateSandboxActive,
      hasRoleInArrayForActiveEnvironment,
      cardProductViewActive,
      uamGranularPermissionsActive,
    },
  } = currentUserStore;

  const hasSufficientRoles = requiredUserPermissions.some((role) =>
    hasRoleForAtLeastOneProgram(hasRole(role))
  );

  const canViewCardProduct = uamGranularPermissionsActive
    ? cardProductViewActive
    : hasSufficientRoles;

  if (privateSandboxActive) {
    return hasRoleInArrayForActiveEnvironment(requiredUserPermissions);
  } else return canViewCardProduct;
};

export const programVerifier = () => {
  const {
    userStore: {
      hasRole,
      privateSandboxActive,
      hasRoleInArrayForActiveEnvironment,
      cardProductViewActive,
      uamGranularPermissionsActive,
    },
  } = currentUserStore;

  const hasSufficientRolesForProgram = requiredUserPermissions.some((role) =>
    hasRoleForSelectedProgram(hasRole(role))
  );

  const canViewCardProduct = uamGranularPermissionsActive
    ? cardProductViewActive
    : hasSufficientRolesForProgram;

  if (privateSandboxActive) {
    return hasRoleInArrayForActiveEnvironment(requiredUserPermissions);
  } else return canViewCardProduct;
};
