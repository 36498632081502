// @flow
import { observable, decorate } from 'mobx';
import axios from 'axios';
import ParentStore from '../stores/ParentStore.js';
import ResponseError from './../diva-api/ResponseError.js';
import notificationStore from 'stores/NotificationStore';
import { secureStorage } from '@mq/volt-amc-container';

class ParentApi extends ParentStore {
  // computed
  get defaultHeaders(): Object {
    if (process.env.REACT_APP_ENV === 'test') {
      return this.testHeaders;
    } else {
      return this.liveHeaders;
    }
  }

  get liveHeaders(): Object {
    return {
      headers: {
        Authorization: `Basic ${btoa(this.authKey)}`,
        actor: 'DivaApp',
      },
    };
  }

  get testHeaders(): Object {
    return {
      headers: {
        Authorization: `Basic ${btoa(this.authKey)}`,
        actor: 'IntegrationTest',
        'actor-email': window.env.TEST_EMAIL,
      },
    };
  }

  get authKey(): string {
    const actualUserWebToken = secureStorage.getItem('webToken');
    const actingAsWebToken = secureStorage.getItem('actingAs-webToken');
    const webToken = actingAsWebToken || actualUserWebToken;
    return `DIVA_APP:${webToken || ''}`;
  }

  get validateProgressiveMfa(): Boolean {
    const progressiveMfaFlagActive = JSON.parse(secureStorage.getItem('progressiveMfaActive'));
    const hasAdditionalAuthFactor = JSON.parse(secureStorage.getItem('has_additional_auth_factor'));

    if (progressiveMfaFlagActive) {
      if (!hasAdditionalAuthFactor) {
        if (window.env.ENVIRONMENT === 'production') {
          notificationStore.notify('error', 'Unauthorized: User does not have MFA enabled');
          return false;
        }
      }
    }
    return true;
  }

  // REQUEST WRAPPERS

  get(path: string, params: ?Object, headers: ?Object, cancelToken: ?string): Promise<Object> {
    const request = {
      headers: Object.assign(this.defaultHeaders.headers, headers),
      method: 'GET',
      params: params,
      url: path,
      cancelToken,
    };
    return axios(request);
  }

  put(path: string, data: ?Object, headers: ?Object, cancelToken: ?string): Promise<Object> {
    const request = {
      headers: Object.assign(this.defaultHeaders.headers, headers),
      method: 'PUT',
      data: data,
      url: path,
      cancelToken,
    };
    return axios(request);
  }

  post(path: string, data: ?Object, headers: ?Object, cancelToken: ?string): Promise<Object> {
    const request = {
      headers: Object.assign(this.defaultHeaders.headers, headers),
      method: 'POST',
      data: data,
      url: path,
      cancelToken,
    };
    return axios(request);
  }

  delete(path: string, data: ?Object, headers: ?Object, cancelToken: ?string): Promise<Object> {
    const request = {
      headers: Object.assign(this.defaultHeaders.headers, headers),
      method: 'DELETE',
      data: data,
      url: path,
      cancelToken,
    };
    return axios(request);
  }

  /**
   * Generate a cancellation token,
   * you will need to pass `CancelTokenSource.token` inside get, post, put ...
   *
   * @url https://github.com/axios/axios#cancellation
   * @returns CancelTokenSource
   */
  generateCancelTokenSource(): Object {
    return axios.CancelToken.source();
  }

  /**
   * Use a cancellation token to cancel request
   *
   * @url https://github.com/axios/axios#cancellation
   * @param cancelTokenSource
   * @returns {*}
   */
  cancelRequest(cancelTokenSource): void {
    return cancelTokenSource.cancel();
  }

  wrapCall = async function ({
    path = '',
    params = null,
    body = null,
    method = '',
    successMessage = null,
    headers = {},
    cancelToken = null,
  }): ?Object {
    // Add the pathPrefix to the path, if defined
    if (this.pathPrefix) {
      const pathEnd = path && path.length ? `/${path}` : '';
      path = `${this.pathPrefix}${pathEnd}`;
    }
    let response = null;

    try {
      switch (method) {
        case 'get':
          response = await this.get(path, params, headers, cancelToken);
          break;
        case 'post':
          response = await this.post(path, body, headers, cancelToken);
          break;
        case 'put':
          response = await this.put(path, body, headers, cancelToken);
          break;
        case 'delete':
          response = await this.delete(path, body, headers, cancelToken);
          break;
        default:
          response = await this.get(path, params, headers, cancelToken);
      }
    } catch (error) {
      if (axios.isCancel(error)) return;
      const fatalErrorMessage = 'Error: The API is either unavailable or encountered a fatal error';
      notificationStore.notify('error', fatalErrorMessage);
      return false;
    }

    if (response) {
      if (this.responseIncludesError(response)) {
        notificationStore.notify('error', this.extractResponseDataError(response));
        return false;
      } else {
        if (successMessage) notificationStore.notify('success', successMessage);
        return this.extractResponseData(response);
      }
    } else {
      notificationStore.notify('error', 'Error: Could not access the API');
      return false;
    }
  };

  // HELPERS

  extractResponseData(response: Object): Object {
    return response.data;
  }

  extractResponseDataError(response: Object): string {
    const responseHasData = response.data;
    const responseDataError = response.data.code && response.data.code !== 200;
    const responseDataMessage = response.data.message;
    if (responseHasData && responseDataError && responseDataMessage) {
      return responseDataMessage;
    } else {
      return 'Error in user permissions API';
    }
  }

  responseIncludesError(response): boolean {
    if (!response) return true;

    const systemCallResponseSuccess = response.status && response.status < 400;
    const responseHasData = response.data;
    const responseDataError = response.data.code && response.data.code !== 200;

    if (systemCallResponseSuccess && responseHasData && !responseDataError) {
      return false;
    } else {
      return true;
    }
  }

  responseErrorString(error: Object) {
    return `${this.getResponseErrorName(error)} ${this.getResponseErrorMessage(error)}`;
  }

  responseError(error: Object) {
    return new ResponseError(this.getResponseErrorName(error), this.getResponseErrorMessage(error));
  }

  getResponseErrorName(error: Object) {
    return error.name;
  }

  getResponseErrorMessage(error: Object) {
    if (error.response) {
      const response = error.response;
      if (response.data) {
        const data = response.data;
        const message = data.message;
        const statusText = response.statusText ? `${response.statusText} - ` : '';
        return `${statusText}${message}`;
      }
    }
    return error.message;
  }
}

decorate(ParentApi, {
  // VALUES
  envApiPrefix: observable,
  pathPrefix: observable,
});

export default ParentApi;
