import React from 'react';

import { DefaultCardTemplate } from '../../../../components/default-card-template';
import { withAdditionalProps } from '../../../../hoc/with-additional-props';
import { optionallyRendered } from '../../../../hoc/optionally-rendered';

import { EditTemplate } from './components/edit-template';
import { PreviewTemplate } from './components/preview-template';
import {
  CardTemplateContext,
  useCardTemplateContext,
} from '../../../../context/CardTemplateContext';

// Pass "cardCompareState" prop from optionallyRendered hoc
// directly to the templates bypassing the DefaultCardTemplate
// which doesn't know about this property
function DefaultCardTemplateWithCompareState({ cardCompareState, ...props }) {
  const extendedProps = {
    ...props,
    editTemplate: withAdditionalProps(props.editTemplate, { cardCompareState }),
    previewTemplate: withAdditionalProps(props.previewTemplate, { cardCompareState }),
  };

  return <DefaultCardTemplate {...extendedProps} />;
}

const CardTemplateWithTemplates = withAdditionalProps(DefaultCardTemplateWithCompareState, {
  editTemplate: EditTemplate,
  previewTemplate: PreviewTemplate,
});

const OptionallyRenderedCardTemplate = optionallyRendered(CardTemplateWithTemplates);

// Pass "editTemplate" prop as "template" to the card template context
// for optionallyRendered hoc to work correctly
function CardTemplateWithTemplateInContext(props) {
  const cardTemplateContext = useCardTemplateContext();

  const extendedCardTemplateContext = {
    ...cardTemplateContext,
    template: cardTemplateContext.editTemplate,
  };

  return (
    <CardTemplateContext.Provider value={extendedCardTemplateContext}>
      <OptionallyRenderedCardTemplate {...props} />
    </CardTemplateContext.Provider>
  );
}

export { CardTemplateWithTemplateInContext as CardTemplate };
