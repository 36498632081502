import {
  status,
  createdAt,
  program,
  type,
  amount,
  collateral,
  currency,
  token,
  memo,
  bankFile,
  failureReason,
} from '../../available-columns.js';

export const columns = [
  status,
  createdAt,
  program,
  type,
  amount,
  collateral,
  currency,
  token,
  memo,
  bankFile,
  failureReason,
];
