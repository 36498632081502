/* eslint-disable camelcase */
import React, { Component } from 'react';
import {
  Modal,
  Textarea,
  Button,
  Select,
  VSpacer,
  ModalAlert,
  LoadingOverlay,
  SegmentedButtons,
} from '@mqd/volt-base';
import { SegmentTrackHOC } from '@mq/voltron-parent';
import PropTypes from 'prop-types';

class ChangeStatusModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: '',
      status: '',
      reason_code: '',
      successMessage: '',
      errorMessage: '',
      invalidTransition: false,
      loading: false,
    };
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
  }

  componentDidMount() {
    const {
      store: { token, availableStatuses },
    } = this.props;

    if (availableStatuses && availableStatuses.length === 0) {
      this.setState({
        invalidTransition: true,
        errorMessage: 'No transition can be made',
      });
    }
    if (!token) {
      this.setState({
        invalidTransition: true,
        errorMessage: 'Invalid transition form',
      });
    }
  }

  async handleChangeStatus() {
    const { store, overrideNativeOnSubmit } = this.props;
    const { successMessage, errorMessage, ...params } = this.state;
    if (!store || !store.changeStatus) {
      console.error('Store passed to ChangeStatusModal must expose a changeStatus function');
      return;
    }
    // Throw error for required params
    const { status, reason_code } = params;
    if ((!status && !overrideNativeOnSubmit) || !reason_code) {
      const errors = [];
      let errorMessage = 'The following parameters are required: ';
      if (!status && !overrideNativeOnSubmit) errors.push('Status');
      if (!reason_code) errors.push('Reason');
      errorMessage += errors.join(', ');

      console.error(errorMessage);
      this.setState({ errorMessage: errorMessage });
      return;
    }

    this.setState({ loading: true });
    if (overrideNativeOnSubmit) {
      try {
        const result = await overrideNativeOnSubmit(params);
        this.setState({
          successMessage: result,
          errorMessage: '',
          loading: false,
        });
      } catch (e) {
        this.setState({
          errorMessage: e,
          successMessage: '',
          loading: false,
        });
      }

      return;
    }

    try {
      const result = await store.changeStatus(params);
      this.setState({
        successMessage: result,
        errorMessage: '',
        loading: false,
      });
    } catch (e) {
      this.setState({
        errorMessage: e,
        successMessage: '',
        loading: false,
      });
    }
  }

  renderReasonCode() {
    const { reason_code } = this.state;
    const { store, testId } = this.props;
    const { reasonCodes } = store;
    if (!reasonCodes) {
      console.error('store needs to define a reasonCodes array');
      return null;
    }

    const optionBaseStyle = {
      margin: '0',
      width: '99.9%',
      overflow: 'hidden',
      cursor: 'pointer',
    };

    const styledOptions = reasonCodes.map((option) => ({
      ...option,
      render: (
        <span
          data-testid={`${testId}__select-render-option`}
          style={{
            ...optionBaseStyle,
            wordBreak: 'break-word',
            whiteSpace: 'normal',
          }}
        >
          {option.render}
        </span>
      ),
      renderSelected: (
        <span
          data-testid={`${testId}__select-render-selected-option`}
          style={{
            ...optionBaseStyle,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {option.render}
        </span>
      ),
    }));

    const dropDownWrap = {
      width: '99.9%',
      height: '200px',
    };

    const currentOption = styledOptions.find((option) => option.val === reason_code);

    return (
      <Select
        testId={`${testId}__select`}
        label="Select reason"
        placeholder="Select an option"
        width="99.9%"
        dropDownWrap={dropDownWrap}
        options={styledOptions}
        value={currentOption}
        onChange={(selected) => {
          this.setState({ reason_code: selected.val });
        }}
      />
    );
  }

  renderStatusToggle() {
    const { store, testId, showStatusSelection } = this.props;
    const { status } = this.state;
    if (!showStatusSelection) return null;

    if (!store.availableStatuses) {
      console.error('store needs to define an availableStatuses array');
      return null;
    }

    const options = store.availableStatuses.map((availableStatus) => {
      const active = status === availableStatus.toUpperCase();

      return {
        active,
        text: availableStatus,
        onClick: () => this.setState({ status: availableStatus.toUpperCase() }),
      };
    });

    return (
      <SegmentedButtons
        testId={`${testId}__button-toggle`}
        type="default"
        label="Status*"
        options={options}
      />
    );
  }

  renderNote() {
    const { testId } = this.props;
    const { reason } = this.state;
    return (
      <Textarea
        label="Add note to card transition (optional)"
        value={reason}
        rows={2}
        onChange={(e) => this.setState({ reason: e.target.value })}
        placeholder="Enter Note..."
        testId={`${testId}__note-text-area`}
      />
    );
  }

  renderSuccess() {
    const { successMessage } = this.state;
    const { hideModal, testId } = this.props;
    if (!successMessage) return;
    return (
      <ModalAlert
        type="success"
        hideModal={() => {
          this.setState({ successMessage: '' });
          return hideModal();
        }}
        testId={`${testId}__success-modal-alert`}
      >
        {successMessage}
      </ModalAlert>
    );
  }

  failureHideModal() {
    const { hideModal, callHideInFailure } = this.props;
    this.setState({ errorMessage: '' });
    return callHideInFailure && hideModal();
  }

  renderFailure() {
    const { testId } = this.props;
    const { errorMessage } = this.state;
    if (!errorMessage) return;
    return (
      <ModalAlert
        type="danger"
        hideModal={() => {
          this.failureHideModal();
        }}
        testId={`${testId}__failed-modal-alert`}
      >
        {errorMessage}
      </ModalAlert>
    );
  }

  renderInvalid() {
    const { errorMessage } = this.state;
    const { hideModal, testId } = this.props;
    return (
      <ModalAlert
        type="danger"
        hideModal={() => {
          hideModal();
        }}
        testId={`${testId}__invalid-modal-alert`}
      >
        {errorMessage}
      </ModalAlert>
    );
  }

  get renderCustomFooterButtons() {
    const { footerButtons, overrideNativeOnSubmit } = this.props;
    const footerButton = footerButtons && footerButtons[0];
    if (!footerButtons || !footerButton) return null;

    return [
      React.cloneElement(footerButton, {
        onClick: this.handleChangeStatus,
      }),
    ];
  }

  render() {
    const { heading, hideModal, testId, footerButtons, children, segmentEventName } = this.props;
    const { invalidTransition, loading } = this.state;

    if (invalidTransition) return this.renderInvalid();

    return (
      <Modal
        testId={testId}
        heading={heading}
        disableOverFlow
        width="655px"
        maxHeight="570px"
        hideModal={hideModal}
        footerButtons={
          this.renderCustomFooterButtons || [
            <SegmentTrackHOC eventName={segmentEventName}>
              <Button
                key="save"
                onClick={this.handleChangeStatus}
                testId={`${testId}__save-button`}
              >
                Save
              </Button>
            </SegmentTrackHOC>,
          ]
        }
      >
        <LoadingOverlay active={loading}>
          {this.renderSuccess()}
          {this.renderFailure()}
          {children}
          {this.renderStatusToggle()}
          <VSpacer factor={3} />
          {this.renderReasonCode()}
          <VSpacer factor={3} />
          {this.renderNote()}
        </LoadingOverlay>
      </Modal>
    );
  }
}

ChangeStatusModal.propTypes = {
  hideModal: PropTypes.func,
  heading: PropTypes.string,
  store: PropTypes.object,
  testId: PropTypes.string,
  footerButtons: PropTypes.array,
  showStatusSelection: PropTypes.bool,
  callHideInFailure: PropTypes.bool,
  overrideNativeOnSubmit: PropTypes.func,
};

ChangeStatusModal.defaultProps = {
  hideModal: () => {},
  heading: 'Change Status',
  store: {},
  testId: 'change-status-modal',
  footerButtons: null,
  showStatusSelection: true,
  overrideNativeOnSubmit: null,
  callHideInFailure: true,
};

export default ChangeStatusModal;
