import { useMutation } from '@apollo/react-hooks';
import { useSandboxCredentials } from './useSandboxCredentials.js';
import { CREATE_WEBHOOK_MUTATION, WEBHOOKS_QUERY } from '../operations/index.js';
import { segmentTrackEvent } from '@mq/voltron-parent';
import { setDatadogError } from '../utils';

const noop = () => {};

const useCreateWebhook = ({ onCompleted = () => {} }) => {
  let customError = null;

  const {
    data: { accessToken, applicationToken, shortCode },
    error: sandboxError,
    loading: sandboxLoading,
  } = useSandboxCredentials();

  const sandboxCredentialsVariables = {
    accessToken,
    applicationToken,
    'x-marqeta-program-short-code': shortCode,
  };

  const refetchQueries = [
    {
      query: WEBHOOKS_QUERY,
      variables: sandboxCredentialsVariables,
    },
  ];

  const [mutate, { loading, error, called }] = useMutation(CREATE_WEBHOOK_MUTATION, {
    onCompleted: (data) => onCompleted(data, error),
    refetchQueries,
    awaitRefetchQueries: true,
  });

  const createWebhook = async (variables) => {
    try {
      const { data } = await mutate({
        variables: {
          ...variables,
          ...sandboxCredentialsVariables,
          active: true,
        },
      });
      if (data.errors?.length > 0) {
        customError = data.errors;
        setDatadogError(customError, { source: 'create-webhook' });
        onCompleted(data, customError);
      } else {
        segmentTrackEvent('Webhook Created', {
          environment: window.env.SANDBOX_BASE_URL,
          userID: applicationToken,
        });
        return data;
      }
    } catch (_e) {
      customError = _e;
      setDatadogError(customError, { source: 'create-webhook' });
      onCompleted({}, customError);
    }
  };

  return {
    called,
    createWebhook: createWebhook || noop,
    error: sandboxError || error || customError,
    loading: sandboxLoading || loading,
  };
};

export { useCreateWebhook };
