import React from 'react';
import { ErrorModal } from '@mqd/volt-base';

const RequestFailedModal = ({ hideModalCallback }) => {
  return (
    <ErrorModal
      heading="Unable to process your request"
      hideModal={hideModalCallback}
      buttonText="Dismiss"
      testId="webhook-request-failed-modal"
    >
      Please try again later.
    </ErrorModal>
  );
};

export default RequestFailedModal;
