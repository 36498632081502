import React, { useState } from 'react';
import { Flex, VSpacer, Button } from '@mqd/volt-base';
import { Button as TempButton } from '../../../../components/button/Button';
import { ConfirmDeletionModal } from '../../../../components/default-card-template/components/edit-template/components/confirm-deletion-modal/ConfirmDeletionModal';

export function renderControls({ formState, switchToPreview, actions, cardType }) {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const handleAction = (callback) => {
    callback(formState);
    switchToPreview();
  };

  const createActionHandler = (callback) => () => handleAction(callback);

  const handleDelete = () => {
    setIsDeleteModalVisible(false);
    handleAction(actions.onDeleteCard);
  };

  return (
    <>
      <VSpacer factor={3} />
      <Flex justifyContent="space-between" alignItems="center">
        {formState.temp ? (
          <>
            <Button submit onClick={createActionHandler(actions.onCreateNewCard)}>
              Add {cardType}
            </Button>
            {/* TODO: replace after this ticket is done https://marqeta.atlassian.net/browse/VOLTENG-45 */}
            <TempButton link mod="secondary" onClick={createActionHandler(actions.onResetCard)}>
              Cancel
            </TempButton>
          </>
        ) : (
          !formState.deleted && (
            <Button type="tertiary" onClick={() => setIsDeleteModalVisible(true)}>
              Delete
            </Button>
          )
        )}
      </Flex>

      {isDeleteModalVisible && (
        <ConfirmDeletionModal
          cardType={cardType}
          closeModal={() => setIsDeleteModalVisible(false)}
          handleDelete={handleDelete}
          name={formState.name}
        />
      )}
    </>
  );
}
