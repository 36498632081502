import React, { useEffect, useState, useRef } from 'react';
import NavLinkUnderline from './NavLinkUnderline.js';
import NavLink from './NavLink.js';
import { getLinkStyles, getLinkParent } from './utils/index.js';
import { NAV_LINKS } from './../constants.js';
import s from './NavBar.module.css';

let resetTimeout;

const useUnderlineReset = () => {
  const [underlineReset, setUnderlineReset] = useState(false);

  const handleMouseOut = (e) => {
    resetTimeout = setTimeout(() => {
      setUnderlineReset(true);
      setTimeout(() => setUnderlineReset(false), 300);
    }, 500);
  };

  return {
    handleMouseOut,
    underlineReset,
  };
};

const useUnderlineStyles = () => {
  const { underlineReset, handleMouseOut } = useUnderlineReset();
  const [underlineStyle, setUnderlineStyle] = useState({});
  const activeLink = useRef();
  const assignActiveLinkRef = (link) => (activeLink.current = link);

  const underlineActive = () => {
    const link = activeLink.current;
    if (link) {
      const linkStyle = getLinkStyles(link);
      setUnderlineStyle(linkStyle);
    } else {
      setUnderlineStyle({});
    }
  };

  useEffect(underlineActive, []);

  useEffect(() => {
    if (underlineReset) {
      setTimeout(underlineActive, 100);
    }
  }, [underlineReset]);

  const handleMouseEnter = (e) => {
    clearTimeout(resetTimeout);
    const link = getLinkParent(e);
    const linkStyle = getLinkStyles(link);
    linkStyle && setUnderlineStyle(linkStyle);
  };
  return {
    underlineStyle,
    underlineReset,
    handleMouseEnter,
    handleMouseOut,
    assignActiveLinkRef,
  };
};

function NavBar({ verifier, isActiveLink }) {
  const [hovered, setHovered] = useState('');
  const { underlineStyle, underlineReset, handleMouseEnter, handleMouseOut, assignActiveLinkRef } =
    useUnderlineStyles();
  return (
    <div data-testid="navbar">
      <div className={s.headerSub}>
        {NAV_LINKS.map((link) => (
          <NavLink
            {...link}
            key={link.text}
            hovered={hovered}
            setHovered={setHovered}
            handleMouseEnter={handleMouseEnter}
            handleMouseOut={handleMouseOut}
            assignActiveLinkRef={assignActiveLinkRef}
            verifier={verifier}
            isActiveLink={isActiveLink}
          />
        ))}
      </div>
      <NavLinkUnderline underlineStyle={underlineStyle} underlineReset={underlineReset} />
    </div>
  );
}

NavBar.propTypes = {};
NavBar.defaultProps = {};

export default NavBar;
