import React from 'react';
import { observer } from 'mobx-react';
import {
  Card,
  Text,
  HSpacer,
  Button,
  VSpacer,
  Icon,
  LoadingOverlay,
  Tooltip,
} from '@mqd/volt-base';
import { ParentDetailCard } from '@mq/voltron-parent';
import s from './SpendControlsTab.module.css';

class CardholderAuthAndVelocityControlsCard extends ParentDetailCard {
  constructor(props) {
    super(props);
    this.handeSave = this.handeSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }
  state = {
    loading: false,
    editActive: false,
  };

  get cardholderStore() {
    const { cardholderStore = {} } = this.props;
    return cardholderStore;
  }

  async handeSave() {
    // TODO
    this.setState({ loading: true });
    const success = true;
    if (success) {
      this.setState({ loading: false, editActive: false });
    } else {
      this.setState({ loading: false });
    }
  }

  handleCancel() {
    const { revertUpdates } = this.cardholderStore;
    revertUpdates && revertUpdates();
    this.setState({ editActive: false });
  }

  renderHeaderButtons() {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button type="outline" onClick={this.handleCancel}>
          Cancel
        </Button>
        <HSpacer />
        <Button onClick={this.handeSave}>Save</Button>
      </div>
    );
  }

  renderVelocityControl(velocityControlStore) {
    const {
      active,
      association,
      token,
      name,
      usage_limit,
      amount_limit,
      merchant_scope,
      velocity_window,
      approvals_only,
      include_purchases,
      include_withdrawals,
      include_transfers,
      include_cashback,
      currency_code,
    } = velocityControlStore;
    return (
      <div className={s.controlBlock} data-testid={`velocity-control-card_${token}`}>
        {this.renderSnippet({
          title: 'Active',
          content: active ? 'True' : 'False',
          tooltipContent: 'Indicates whether the velocity control is active.',
        })}
        {this.renderSnippet({
          title: 'Token',
          content: token,
          tooltipContent: 'The unique identifier of the velocity control.',
        })}
        {this.renderSnippet({
          title: 'Name',
          content: name,
          tooltipContent: 'The name of the velocity control.',
        })}
        {this.renderSnippet({
          title: 'Amount Limit',
          content: amount_limit,
          tooltipContent: `
              The maximum monetary sum that can 
              be cleared within the time period
              defined by the velocity_window field.
            `,
        })}
        {this.renderSnippet({
          title: 'Usage Limit',
          content: usage_limit,
          tooltipContent: `
              Maximum number of times a card can
              be used within the time period 
              defined by the velocity_window field.
              If velocity_window is set to TRANSACTION,
              do not include a usage_limit in your request.
            `,
        })}
        {this.renderSnippet({
          title: 'Velocity Window',
          content: velocity_window,
          tooltipProps: { width: 470 },
          tooltipContent: (
            <div>
              Defines the time period to which the amount_limit and usage_limit fields apply: <br />
              DAY – one day; days begin at 00:00:00. <br />
              WEEK – one week; weeks begin Mondays at 00:00:00. <br />
              MONTH – one month; months begin on the first day of month at 00:00:00. <br />
              LIFETIME – forever; time period never expires. <br />
              TRANSACTION – a single transaction.
            </div>
          ),
        })}
        {this.renderSnippet({
          title: 'Approvals Only',
          content: approvals_only ? 'True' : 'False',
          tooltipContent: `
              If set to true, only approved
              transactions are subject to control.
            `,
        })}
        {this.renderSnippet({
          title: 'Include Purchases',
          content: include_purchases ? 'True' : 'False',
          tooltipContent: `
              If set to true, purchases 
              are subject to control.
            `,
        })}
        {this.renderSnippet({
          title: 'Include Withdrawals',
          content: include_withdrawals ? 'True' : 'False',
          tooltipContent: `
              If set to true, ATM withdrawals
              are subject to control.
            `,
        })}
        {this.renderSnippet({
          title: 'Include Transfers',
          content: include_transfers ? 'True' : 'False',
          tooltipContent: `
              If set to true, transfers
              are subject to control.
            `,
        })}
        {this.renderSnippet({
          title: 'Include Cashback',
          content: include_cashback ? 'True' : 'False',
          tooltipContent: `
              If set to true, cash backs
              are subject to control.
            `,
        })}
        {this.renderSnippet({
          title: 'Currency Code',
          content: currency_code,
          tooltipContent: 'The currency code.',
        })}
        {this.renderMerchantScope(merchant_scope)}
        {this.renderAssociation(association)}
      </div>
    );
  }

  renderMerchantScope(merchantScopeStore) {
    if (!merchantScopeStore) {
      merchantScopeStore = {};
    }
    const { mid, mcc, mcc_group } = merchantScopeStore;
    return (
      <>
        <div className={s.flexDiv}>
          <Text type="h5">Merchant Scope</Text>
          <HSpacer />
          <div style={{ height: 16 }}>
            <Tooltip
              zIndex={9999}
              width={280}
              content={`
                  Defines the group of merchants to which the
                  authorization control applies. You must
                  populate one or more fields of this object.
                  If no fields are populated, the authorization
                  control applies to all merchants.
                `}
            >
              <Icon type="info" mod="default" hasTooltip />
            </Tooltip>
          </div>
        </div>
        <VSpacer factor={2} />
        {this.renderSnippet({
          title: 'Merchant ID',
          content: mid,
          tooltipContent: `
              MID (Merchant ID). The unique
              identification number of a merchant.
              Enter a value to control access
              to a particular merchant.
            `,
        })}
        {this.renderSnippet({
          title: 'Merchant Category Code',
          content: mcc,
          tooltipContent: `
              A single MCC (Merchant Category Code).
              Identifies the type of goods or services
              provided by the merchant. Enter a value
              to control access to a particular type
              of product or service.
            `,
        })}
        {this.renderSnippet({
          title: 'Merchant Category Code Group',
          content: mcc_group,
          tooltipContent: `
              Token identifying a group of MCCs.
              Enter a value to control access to
              a group of product or service types.
            `,
        })}
      </>
    );
  }

  renderAssociation(associationStore) {
    if (!associationStore) {
      associationStore = {};
    }
    const { card_product_token, cardholder_token } = associationStore;
    return (
      <>
        <div className={s.flexDiv}>
          <Text type="h5">Association</Text>
          <HSpacer />
          <div style={{ height: 16 }}>
            <Tooltip
              zIndex={9999}
              width={340}
              content={`
                  Token identifying either a card product or user. 
                  Specify a card product token in the card_product_token 
                  field to apply the authorization control to all users 
                  holding active cards associated with the card product. 
                  Specify a user_token in the user_token field to apply 
                  the authorization control to a single user. Set 
                  either card_product_token or user_token, not both.
                `}
            >
              <Icon type="info" mod="default" hasTooltip />
            </Tooltip>
          </div>
        </div>
        <VSpacer factor={2} />
        {this.renderSnippet({
          title: 'Card product token',
          content: card_product_token,
        })}
        {this.renderSnippet({
          title: 'User Token',
          content: cardholder_token,
        })}
      </>
    );
  }

  renderAuthControl(authControlStore) {
    const { active, token, name, start_time, end_time, merchant_scope, association } =
      authControlStore;

    return (
      <div className={s.controlBlock} data-testid={`auth-control-card_${token}`}>
        {this.renderSnippet({
          title: 'Active',
          content: active ? 'True' : 'False',
          tooltipContent: 'Indicates whether the authorization control is active.',
        })}
        {this.renderSnippet({
          title: 'Token',
          content: token,
          tooltipContent: 'The unique identifier of the authorization control.',
        })}
        {this.renderSnippet({
          title: 'Name',
          content: name,
          tooltipContent: 'The name of the authorization control.',
        })}
        {this.renderSnippet({
          title: 'Start Time',
          content: start_time,
          tooltipContent: 'The date and time the exception goes into effect.',
        })}
        {this.renderSnippet({
          title: 'End Time',
          content: end_time,
          tooltipContent: 'The date and time the exception ends.',
        })}
        {this.renderMerchantScope(merchant_scope)}
        {this.renderAssociation(association)}
      </div>
    );
  }

  renderInfo() {
    const { velocity_controls, auth_controls } = this.cardholderStore;
    return (
      <Card title="Velocity Controls & Auth Controls">
        {/* Velocity Controls */}
        <div className={s.flexDiv}>
          <Text type="h5">Velocity Controls</Text>
          <HSpacer />
          <div style={{ height: 16 }}>
            <Tooltip
              zIndex={9999}
              width={440}
              content={`
                  A velocity control is a type of spend control that limits how much
                  cardholders can spend. You can configure velocity controls to limit
                  how much cardholders can spend and/or the number of transactions they
                  can make within a given window of time. Velocity controls can apply to
                  a single user, all cardholders associated with a particular card product,
                  or all cardholders in your program.
                `}
            >
              <Icon type="info" mod="default" hasTooltip />
            </Tooltip>
          </div>
        </div>
        <VSpacer factor={2} />
        {velocity_controls &&
          velocity_controls.map((velocityControl, i) => {
            return (
              <React.Fragment key={i}>
                {this.renderVelocityControl(velocityControl)}
                {i + 1 === velocity_controls.length ? null : <VSpacer factor={2} />}
              </React.Fragment>
            );
          })}

        {/* Auth Controls */}
        <VSpacer factor={3} />
        <div className={s.flexDiv}>
          <Text type="h5">Authorization Controls</Text>
          <HSpacer />
          <div style={{ height: 16 }}>
            <Tooltip
              zIndex={9999}
              width={440}
              content={`
                  An authorization control is a type of spend control that limits where
                  users can transact. You can configure authorization controls to limit
                  spending at a single merchant or a group of merchants. Authorization
                  controls can apply to a single user, all users associated with a
                  particular card product, or all users in your program
                `}
            >
              <Icon type="info" mod="default" hasTooltip />
            </Tooltip>
          </div>
        </div>
        <VSpacer factor={2} />
        {auth_controls &&
          auth_controls.map((authControl, i) => {
            return (
              <React.Fragment key={i}>
                {this.renderAuthControl(authControl)}
                {i + 1 === auth_controls.length ? null : <VSpacer factor={2} />}
              </React.Fragment>
            );
          })}
      </Card>
    );
  }

  render() {
    const { loading } = this.state;
    return <LoadingOverlay active={loading}>{this.renderInfo()}</LoadingOverlay>;
  }
}

export default observer(CardholderAuthAndVelocityControlsCard);
