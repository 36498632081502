export const inventoryRoutes = [
  {
    path: '/program/card-management-inventory',
    name: 'Card management inventory details',
    importer: () =>
      import(
        /*webpackChunkName: "Program Config Card Inventory Management" */ '../InventoryDetails/InventoryDetailsPage'
      ),
  },
];
