import React from 'react';
import PropTypes from 'prop-types';

import NoAction from './NoAction';

const ActionsPlate = ({ children }) => {
  const childrenCount = React.Children.toArray(children).length;

  return childrenCount > 0 ? children : <NoAction />;
};

ActionsPlate.propTypes = {
  children: PropTypes.node,
};

export default ActionsPlate;
