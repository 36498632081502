import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import s from '../CardCreationDetail.module.css';
import { Text, Link, Input, HSpacer, VSpacer, Hr } from '@mqd/volt-base';

function CardExpiration({
  cardStartDate,
  cardExpirationDate,
  cardExpirationDateError,
  setManualCardCreateAttrWithLimit,
  setThreeYearTimeFrame,
  hasMinOffSet,
}) {
  if (!hasMinOffSet) return null;

  return (
    <>
      <Text type="h6">Card issuance and expiration date</Text>
      <div className={s.flexDiv}>
        <Input
          testId="issuance-date-input"
          placeholder="Issuance Date"
          disabled={true}
          value={cardStartDate}
          preventErrorPushdown={true}
        />
        <HSpacer factor={1} />
        to
        <HSpacer factor={1} />
        <Input
          testId="expiration-date-input"
          placeholder="Expiration Date"
          onChange={(e) =>
            setManualCardCreateAttrWithLimit('cardExpirationDate', e.target.value, 10)
          }
          value={cardExpirationDate}
        />
      </div>
      {cardExpirationDateError && (
        <>
          <Text type="footnote" mod="error" inline={false}>
            {cardExpirationDateError}
          </Text>
          <VSpacer factor={1} />
        </>
      )}
      <Link testId="set-three-year-time-frame-button" onClick={setThreeYearTimeFrame}>
        Set three year timeframe
      </Link>
      <VSpacer factor={2} />
      <Hr />
      <VSpacer factor={2} />
    </>
  );
}

CardExpiration.propTypes = {
  cardStartDate: PropTypes.string,
  cardExpirationDate: PropTypes.string,
  cardExpirationDateError: PropTypes.string,
  setManualCardCreateAttrWithLimit: PropTypes.func,
  setThreeYearTimeFrame: PropTypes.func,
  hasMinOffSet: PropTypes.bool,
};

CardExpiration.defaultProps = {
  cardStartDate: '',
  cardExpirationDate: '',
  cardExpirationDateError: '',
  setManualCardCreateAttrWithLimit: () => {},
  setThreeYearTimeFrame: () => {},
  hasMinOffSet: false,
};

export default observer(CardExpiration);
