import currentUserStore from 'stores/CurrentUserStore';

export const requirements = {
  flipFlopRequirements: ['unify-beta'],
};

export const verifier = () => {
  const { userStore = {} } = currentUserStore;
  return userStore.unifyActive;
};
