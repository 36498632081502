const exception_queue = [
  'hash_id',
  'settlement_date',
  'state',
  'variance_amount',
  'source',
  'masked_pan',
  'network_ref_id',
  'program',
  'program_type',
  'bank',
  'message_reason_code',
  'tx_hash_internal_id',
];

const approval_queue = [
  'settlement_date',
  'exception_count',
  'settled_total',
  'adjustment_fees',
  'adjustment_total',
];

const settlement_date_queue = [
  'hash_id',
  'submission_date',
  'state',
  'network_ref_id',
  'settled_total',
  'submitted_total',
  'submitted_variance',
  'program',
  'reason',
  'latest_jcard_error_response',
  'program_type',
  'bank',
  'tx_hash_internal_id',
  'message_reason_code',
];

const hash_group = ({ source }) => {
  return [
    'hashId',
    'tx_hash_internal_id',
    'net_ref_id',
    'subnetwork',
    'bank',
    'settlementDate',
    'state',
    'exceptionType',
    'submissionDate',
    'submissionType',
    'program_header',
    'program_type',
    'program',
    'reference_id',
    source !== 'MQ' ? 'sre' : null,
    source !== 'MQ' ? 'ft_sre' : null,
  ];
};

const transaction_info = [
  'source',
  'masked_pan',
  'transaction_token',
  'original_id',
  'retrieval_reference_number',
  'transaction_type',
  'transaction_code',
  'created_date',
  'itc',
  'fee_program_indicator',
  'batch_number',
  'reason_code',
  'message_reason_code',
  'merchant_name',
  'merchant_id',
  'merchant_country',
  'originator',
  'additional_information',
];
const related_transactions = [
  'settlement_date',
  'network_reference_id',
  'source',
  'settled_total',
  'transaction_total',
  'fee_total',
  'cross_border_fee',
  'interchange_fee',
  'surcharge_atm_pin_debit_fee',
  'masked_pan',
  'transaction_token',
  'original_id',
  'retrieval_reference_number',
  'transaction_type',
  'transaction_code',
  'created_date',
  'itc',
  'fee_program_indicator',
  'batch_number',
  'reason_code',
  'message_reason_code',
  'merchant_name',
  'merchant_id',
  'merchant_country',
  'originator',
  'additional_information',
];

export {
  exception_queue,
  approval_queue,
  settlement_date_queue,
  hash_group,
  transaction_info,
  related_transactions,
};
