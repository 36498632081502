import React, { useState } from 'react';
import { Checkbox, FormError, Text, Link, VSpacer } from '@mqd/volt-base';

import s from './CheckboxGroup.module.css';

const CheckboxGroup = ({
  groupItems,
  onChange,
  preventErrorPushdown,
  error,
  customErrorMessage,
}) => {
  const [values, setValues] = useState(groupItems);

  if (!groupItems || !groupItems.length) return;

  const renderError = () => {
    if (!error) return null;
    return <FormError preventErrorPushdown={preventErrorPushdown}>{customErrorMessage}</FormError>;
  };

  const handleClick = (name) => {
    const groupItemsCopy = [...values];
    const index = groupItemsCopy.findIndex((item) => item.name === name);
    if (index >= 0 && groupItemsCopy[index]) {
      groupItemsCopy[index].active = !groupItemsCopy[index].active;
    }

    const selectedGroupItems = groupItemsCopy.filter((gic) => gic.active);

    setValues(groupItemsCopy);
    onChange && onChange(selectedGroupItems);
  };

  return (
    <>
      {values.map((value) => (
        <>
          <CheckboxGroupItem
            active={!!value.active}
            onClick={handleClick.bind(this, value.name)}
            applyErrorStyles={error}
            {...value}
          />
          <VSpacer />
        </>
      ))}
      {renderError()}
    </>
  );
};

const CheckboxGroupItem = ({
  active,
  onClick,
  applyErrorStyles,
  name,
  token,
  label,
  network,
  binPrefix,
  goToCardProductDetail,
}) => {
  const cardProductLabel = (
    <Link className={s.cardProductLabel} onClick={() => goToCardProductDetail(token)}>
      {label}
    </Link>
  );

  const errorClasses = `
    ${applyErrorStyles && s.error}
  `;

  const bullet = '\u2022';

  return (
    <div className={`${s.cardProductItem} ${errorClasses}`}>
      <Checkbox
        active={active}
        name={name}
        label={cardProductLabel}
        onClick={onClick}
        testId={name}
      >
        <Text type="footnote">{`Network: ${network} ${bullet} BIN prefix: ${binPrefix}`}</Text>
      </Checkbox>
    </div>
  );
};

CheckboxGroup.defaultProps = {
  kind: 'checkboxGroup',
  groupItems: [],
};

export default CheckboxGroup;
