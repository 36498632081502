import { NETWORKS } from '../../utilities/dictionary';
import * as VISA_CONFIG from './visa.js';
import * as PULSE_CONFIG from './pulse.js';
import * as MASTERCARD_CONFIG from './mastercard.js';

const networkConfigs = {
  [NETWORKS.Visa]: VISA_CONFIG,
  [NETWORKS.Pulse]: PULSE_CONFIG,
  [NETWORKS.Mastercard]: MASTERCARD_CONFIG,
  default: VISA_CONFIG,
};

export default networkConfigs;
