import React from 'react';
import PropTypes from 'prop-types';
import { DetailPane, Row, Col, CopyToClipboard } from '@mqd/volt-base';
import { CardHoverCard, AccountholderHoverCard } from '@components/index.js';
import capitalize from '../../../utils/helpers/capitalize.js';
import { parseDateTimeString } from '../../../utils/helpers/parseDateString.js';
import { getMethod, getTxnDisplayType } from '../utils.js';

const AccountholderDisplay = ({ accountholder }) => {
  return <AccountholderHoverCard accountholder={accountholder} />;
};

const GeneralDetails = ({ transaction, state }) => {
  const {
    token = '',
    created_time = '',
    direct_deposit,
    cardholder,
    card_acceptor,
    card,
    type: transactionType,
  } = transaction;
  const isDirectDeposit = transactionType && transactionType.includes('directdeposit');
  const { company_name, standard_entry_class_code, type } = direct_deposit || {};
  const { mcc } = card_acceptor || {};

  let txnTo, txnFrom;
  switch (type) {
    case 'CREDIT':
      txnTo = <AccountholderDisplay accountholder={cardholder} />;
      txnFrom = company_name;
      break;
    case 'DEBIT':
      txnTo = company_name;
      txnFrom = <AccountholderDisplay accountholder={cardholder} />;
      break;
    default:
      break;
  }

  const directDeposit = (
    <>
      {txnFrom && <DetailPane.Content label="From">{txnFrom}</DetailPane.Content>}
      {txnTo && <DetailPane.Content label="To">{txnTo}</DetailPane.Content>}
      {standard_entry_class_code && (
        <DetailPane.Content label="SEC code">{standard_entry_class_code}</DetailPane.Content>
      )}
    </>
  );

  const cardAndMcc = (
    <>
      <DetailPane.Content label="Account holder">
        <AccountholderDisplay accountholder={cardholder} />
      </DetailPane.Content>
      {card && (
        <DetailPane.Content label="Last 4 of PAN">
          <CardHoverCard card={card} />
        </DetailPane.Content>
      )}
      {mcc && <DetailPane.Content label="MCC code">{mcc}</DetailPane.Content>}
    </>
  );

  const date = parseDateTimeString(created_time);
  const displayType = getTxnDisplayType(transaction, true);
  const status = capitalize((state || transaction.state).toLocaleLowerCase());
  const method = getMethod(transaction);

  return (
    <Row testId="transaction-details-section">
      <Col span={6}>
        <DetailPane.Container>
          <DetailPane.Content label="Date">{date}</DetailPane.Content>
          <DetailPane.Content label="Transaction type">{displayType}</DetailPane.Content>
          <DetailPane.Content label="Transaction status">{status}</DetailPane.Content>
          <DetailPane.Content label="Method">{method}</DetailPane.Content>

          {isDirectDeposit ? directDeposit : cardAndMcc}

          <DetailPane.Content label="Token">
            <CopyToClipboard>{token}</CopyToClipboard>
          </DetailPane.Content>
        </DetailPane.Container>
      </Col>
    </Row>
  );
};

GeneralDetails.propTypes = {
  transaction: PropTypes.object,
  state: PropTypes.string,
};

export default GeneralDetails;
