import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Table } from '@mq/voltron-table';
import { ParentTable, Status } from '@mq/voltron-parent';
import { LoadingOverlay, VSpacer, Icon, Text } from '@mqd/volt-base';

import TransitionsTableStore from './TransitionsTableStore';

import StyleContext from '../contexts/StyleContext';
import { reasonCodes } from '../constants';
import { titleCase } from '../shared-utils';

import s from './TransitionsTable.module.css';

class TransitionsTable extends ParentTable {
  constructor(props) {
    super(props);
    this.storeConstructor = TransitionsTableStore;
    this.state = this.colEditState(props, this.columnController);
  }

  get snapToRowHeight() {
    let { currentList = [] } = this.store;

    const header = 40;
    const spacing = 0;
    const height = currentList.length * 40 + header + spacing;

    return height;
  }

  get columnHeaderMenu() {
    return {
      header: this.headerRenderer(),
      accessor: 'token',
      key: 'actions',
      renderer: () => <div />,
      noPadding: true,
      noResize: true,
      noRowClick: true,
      noSort: true,
      noEdit: true,
    };
  }

  get columnController(): Array {
    const { transitionType } = this.store;
    switch (transitionType) {
      case 'digitalWalletToken':
        return [
          {
            header: 'Token transition date & time',
            renderer: ({ cellValue }) => moment(cellValue).format('YYYY-MM-DD hh:mm'),
            accessor: 'created_time',
            key: 'created_time',
            width: 280,
          },
          {
            header: 'State',
            renderer: ({ cellValue }) => <Status status={cellValue} />,
            accessor: 'state',
            key: 'state',
            width: 200,
          },
          {
            header: 'Fulfillment Status',
            renderer: ({ cellValue }) => titleCase(cellValue),
            accessor: 'fulfillment_status',
            key: 'fulfillment_status',
            width: 200,
          },
          {
            header: 'Details',
            accessor: 'reason',
            key: 'reason',
          },
        ];
      case 'business':
        return [
          this.columnHeaderMenu,
          {
            header: 'Created Time',
            accessor: 'created_time',
            key: 'created_time',
          },
          {
            header: 'Status',
            accessor: 'status',
            key: 'status',
          },
          {
            header: 'Reason',
            accessor: 'reason',
            key: 'reason',
          },
          {
            header: 'Reason Code',
            accessor: 'reason_code',
            key: 'reason_code',
          },
          {
            header: 'Channel',
            accessor: 'channel',
            key: 'channel',
          },
          {
            header: 'Token',
            accessor: 'token',
            key: 'token',
          },
          {
            header: 'Last Modified Time',
            accessor: 'last_modified_time',
            key: 'last_modified_time',
            hide: true,
          },
          {
            header: 'Business Token',
            accessor: 'business_token',
            key: 'business_token',
            hide: true,
          },
        ];
      case 'cardholder':
        return [
          this.columnHeaderMenu,
          {
            header: 'Created Time',
            accessor: 'created_time',
            key: 'created_time',
          },
          {
            header: 'Status',
            accessor: 'status',
            key: 'status',
          },
          {
            header: 'Reason Code',
            accessor: 'reason_code',
            key: 'reason_code',
            hide: true,
          },
          {
            header: 'Reason',
            accessor: 'reason',
            key: 'reason',
          },
          {
            header: 'Channel',
            accessor: 'channel',
            key: 'channel',
          },
          {
            header: 'Token',
            accessor: 'token',
            key: 'token',
          },
          {
            header: 'Last Modified Time',
            accessor: 'last_modified_time',
            key: 'last_modified_time',
            hide: true,
          },
        ];
      case 'card':
        return [
          this.columnHeaderMenu,
          {
            header: 'Date',
            accessor: 'created_time',
            key: 'created_time',
            renderer: ({ cellValue }) => {
              return moment(cellValue).format('YYYY-MM-DD hh:mm');
            },
          },
          {
            header: 'Status',
            renderer: ({ cellValue }) => <Status status={cellValue} />,
            accessor: 'state',
            key: 'state',
          },
          {
            header: 'Reason',
            accessor: 'reason_code',
            key: 'reason_code',
            renderer: ({ row }) => {
              let reasonByCode =
                reasonCodes.filter((reason) => reason.val === row.reason_code)[0] || {};

              return <div>{reasonByCode.render}</div>;
            },
          },
          {
            header: 'Note',
            accessor: 'reason',
            key: 'reason',
            renderer: ({ row }) => {
              let reasonByCode =
                reasonCodes.filter((reason) => reason.val === row.reason_code)[0] || {};

              return <div>{reasonByCode.render !== row.reason ? row.reason : null}</div>;
            },
          },
        ];
      // TODO: for other transition types
      // case 'cardholder':
      default:
        return this.baseTransitionColumns;
    }
  }

  get baseTransitionColumns(): Array {
    return [
      this.columnHeaderMenu,
      {
        header: 'token',
        accessor: 'token',
        key: 'token',
      },
      {
        header: 'reason_code',
        accessor: 'reason_code',
        key: 'reason_code',
      },
      {
        header: 'reason',
        accessor: 'reason',
        key: 'reason',
      },
      {
        header: 'channel',
        accessor: 'channel',
        key: 'channel',
      },
      {
        header: 'created_time',
        accessor: 'created_time',
        key: 'created_time',
      },
      {
        header: 'last_modified_time',
        accessor: 'last_modified_time',
        key: 'last_modified_time',
      },
    ];
  }

  renderNoTransitionsContent() {
    return (
      <div className={s.emptyBlock_wrapper}>
        <div className={s.iconWrapper}>
          <Icon type="single-use-inactive" />
        </div>
        <VSpacer factor={2} />
        <Text type="h3">No transitions yet</Text>
      </div>
    );
  }

  render() {
    const { currentList, activeSort, activeRow, setAttr, loading, transitionType } = this.store;
    const { columns, tableKey } = this.state;
    const { loading: detailLoading, calculateTableHeight } = this.props;

    const borderLessTables = ['digitalWalletToken', 'card'];
    const noActionTables = ['digitalWalletToken'];
    const pageSize = this.store.count;
    const tablePaddingBottom = calculateTableHeight(this.store);

    return (
      <div style={{ width: '100%' }}>
        {this.renderColEditTableHeader({ tableFilterTestId: 'transitions-table-header-buttons' })}
        <VSpacer />
        <StyleContext.Consumer>
          {({ height }) => {
            return (
              <LoadingOverlay active={loading || detailLoading}>
                <Table
                  key={tableKey}
                  loading={loading}
                  customEmptyState={this.renderNoTransitionsContent()}
                  height={height + tablePaddingBottom || this.snapToRowHeight}
                  data={currentList}
                  columns={columns}
                  borderLessWrapper={borderLessTables.includes(transitionType)}
                  fixedColumnCount={!noActionTables.includes(transitionType) ? 1 : 0}
                  rightAlignFixedColumns={!noActionTables.includes(transitionType)}
                  sort={activeSort}
                  activeRow={activeRow}
                  flatTop={true}
                  onSort={(newSort) => setAttr('activeSort', newSort)}
                  handleColSizeChange={this.handleColSizeChange.bind(this)}
                />
              </LoadingOverlay>
            );
          }}
        </StyleContext.Consumer>
        {this.renderPagination()}
      </div>
    );
  }
}

TransitionsTable.propTypes = {
  height: PropTypes.number,
  storeInitArgs: PropTypes.object,
  store: PropTypes.object,
  userStore: PropTypes.object,
  autoHydrate: PropTypes.bool,
  onStoreConstruction: PropTypes.func,
  gqlClient: PropTypes.any,
  localStorageKey: PropTypes.string,
  calculateTableHeight: PropTypes.func,
};

TransitionsTable.defaultProps = {
  height: 800,
  storeInitArgs: { limit: 100 },
  store: null,
  userStore: {},
  autoHydrate: true,
  onStoreConstruction: null,
  gqlClient: null,
  localStorageKey: 'TransitionsTableConfig',
  calculateTableHeight: () => 0,
};

export default observer(TransitionsTable);
