import { RESOURCE_TYPE_TO_BREADCRUMB_NAME } from '../header/constants.js';
import logger from 'utils/logger';
import { routeMap } from '@mq/volt-amc-container';
import { isProgramDdaProgram } from '../../../utils/route-authorization/routes/functions/isDdaProgram.js';

const getFirstSegmentOfPathname = (path) => path.split('/').slice(1, 2)[0];

export const isActive = (to) => {
  try {
    const urlPath = getFirstSegmentOfPathname(window.location.pathname);
    const routeMapPath = getFirstSegmentOfPathname(routeMap[to].path);
    return urlPath === routeMapPath;
  } catch (e) {
    logger.error(`Could not match path for: ${to}.  \n${e}`);
    return false;
  }
};

const getRoutesMap = (programShortCode) => {
  return {
    cardholder: isProgramDdaProgram(programShortCode)
      ? 'programAccountholder'
      : 'programCardholder',
    business: 'programBusiness',
    card: isProgramDdaProgram(programShortCode) ? 'programAccountholderCard' : 'programCard',
    dispute: 'riskDispute',
    transaction: isProgramDdaProgram(programShortCode)
      ? '/program/accountholder/transaction'
      : '/program/user/transaction',
  };
};

export const getCardholderName = (cardholder) => {
  const { first_name, middle_name, last_name } = cardholder || {};
  const names = [first_name, middle_name, last_name].filter(Boolean);

  if (!names.length) {
    return '';
  }

  return names.join(' ');
};

export const getRouteParamsToHandleSearchView = (resourceType, resourceStore) => {
  const { token = '', programShortCode = '', cardholder, userToken } = resourceStore;
  const breadcrumb = RESOURCE_TYPE_TO_BREADCRUMB_NAME[resourceType];
  const returnVal = {
    program: programShortCode,
    ...(breadcrumb ? { breadcrumb } : {}),
  };

  if (resourceType === 'transaction' && !isProgramDdaProgram(programShortCode)) {
    const cardholderName = getCardholderName(cardholder);

    return {
      token: userToken,
      transactionToken: token,
      ...(cardholderName ? { cardholderName } : {}),
      ...returnVal,
    };
  }

  return {
    token,
    ...returnVal,
  };
};

export const handleSearchView =
  ({ go }) =>
  ({ resourceType, resourceStore }) => {
    const { programShortCode = '' } = resourceStore; // programCode enables switching programs via the URL
    const routesMap = getRoutesMap(programShortCode);
    const routeName = routesMap[resourceType];

    routeName && go && go(routeName, getRouteParamsToHandleSearchView(resourceType, resourceStore));
  };
