export const filterOptions = [
  {
    label: 'Business Name DBA',
    placeholder: '40 char max',
    filterParam: 'business_name_dba',
  },
  {
    label: 'Business Name Legal',
    placeholder: '40 char max',
    filterParam: 'business_name_legal',
  },
];
