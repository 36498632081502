import { pathOr } from 'ramda';
import { datadogRum } from '@datadog/browser-rum';
import { ERROR_STATES } from './constants';

/**
 * Accepts an error and source for logging to datadog
 * @param  {String, Object} error Error string or graphQL error object
 * @param  {String} source Where the error is coming from
 */
export const setDatadogError = (error = ERROR_STATES.Default, { source }) => {
  const parsedError = pathOr(error, ['graphQLErrors', '0', 'message'], error);
  datadogRum.addError(parsedError, { source });

  return parsedError;
};
