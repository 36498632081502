import React from 'react';
import { Text, Tooltip, Icon, HSpacer } from '@mqd/volt-base';
import s from './CardholdersTable.module.css';

export const filterOptions = [
  {
    label: 'First name',
    placeholder: '40 char max',
    filterParam: 'first_name',
  },
  {
    label: 'Last name',
    placeholder: '40 char max',
    filterParam: 'last_name',
  },
  {
    label: 'Phone',
    placeholder: 'e.g. 5108393876',
    filterParam: 'phone',
  },
  {
    label: 'Email',
    placeholder: 'e.g. example@marqeta.com',
    filterParam: 'email',
  },
  {
    label: 'SSN',
    placeholder: 'Enter last four digits or full SSN',
    filterParam: 'ssn',
  },
  {
    label: (
      <div className={s.labelWrap}>
        <Text type="h6">Card number</Text>
        <HSpacer />
        <div style={{ height: 16 }}>
          <Tooltip
            direction="right"
            content="You cannot filter by this field in combination with other filters."
            width={230}
          >
            <Icon type="info" mod="default" hasTooltip />
          </Tooltip>
        </div>
      </div>
    ),
    placeholder: 'Enter last four digits or full PAN',
    filterParam: 'last_four',
  },
  {
    label: (
      <div className={s.labelWrap}>
        <Text type="h6">UAI</Text>
        <HSpacer />
        <div style={{ height: 16 }}>
          <Tooltip
            direction="right"
            content="Enter the account holder’s UAI (i.e., a unique 17-digit customer identifier) to filter on this value."
            width={230}
          >
            <Icon type="info" mod="default" hasTooltip />
          </Tooltip>
        </div>
      </div>
    ),
    placeholder: 'Enter unique account identifier',
    filterParam: 'dda',
  },
];
