import gql from 'graphql-tag';

export const BULK_REJECT_ADJUSTMENTS = gql`
  mutation bulkRejectAdjustments(
    $adjustment_ids: [TransactionAdjustmentInfoInput!]
    $reason: String!
    $notes: String
  ) {
    bulkRejectAdjustments(adjustment_ids: $adjustment_ids, reason: $reason, notes: $notes) {
      status
      statusText
      message
      unsuccessful_tx_hash_internal_ids
    }
  }
`;
