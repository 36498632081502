import currentUserStore from 'stores/CurrentUserStore';
import { hasTokenizationManagerPermissions } from './functions.js';
import { AUDIT_LOGS_VIEW } from '../../../views/admin/uam-granular-permissions/constants.js';

export const requirements = {
  requiredUserPermissions: [
    'production-support-internal',
    'program-admin',
    'developer',
    'marqeta-admin-internal',
  ],
  requiredGranularPermissions: [AUDIT_LOGS_VIEW],
};

export const verifier = () => {
  const { userStore = {} } = currentUserStore;

  return (
    userStore.auditLogTableActive || hasTokenizationManagerPermissions(userStore, 'audit-logs')
  );
};
