import React from 'react';
import { observer } from 'mobx-react';

import routerStore from 'stores/router/RouterStore';
import leavingModalStore from '../../stores/LeavingModalStore.js';

import { Modal, Text, VSpacer, Button } from '@mqd/volt-base';

const LeavingModal = () => {
  const { go } = routerStore;
  const {
    showLeavingModal,
    setShowLeavingModal,
    routeParamsToLeave,
    leavingModalTexts: { headerText, bodyText } = { headerText: '', bodyText: '' },
  } = leavingModalStore;

  if (!showLeavingModal) return null;

  const onLeaveHandler = () => {
    const { routeName, options, params } = routeParamsToLeave;
    go(routeName, params, options);
  };

  return (
    <Modal
      width={400}
      type="sm"
      hideModal={setShowLeavingModal.bind(this, false)}
      hideModalButtonText="Stay on this page"
      footerButtons={[<Button onClick={onLeaveHandler}>Leave this page</Button>]}
    >
      <>
        <Text type="h5">{headerText}</Text>
        <VSpacer />
        <Text>{bodyText}</Text>
      </>
    </Modal>
  );
};

export default observer(LeavingModal);
