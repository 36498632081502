import { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { propOr } from 'ramda';
import { useSandboxCredentials } from './useSandboxCredentials.js';
import { WEBHOOK_QUERY } from '../operations/index.js';

const useWebhook = ({ token }) => {
  const client = useApolloClient();
  const [webhookData, setWebhookData] = useState(null);
  const [webhookLoading, setWebhookLoading] = useState(true);
  const [webhookError, setWebhookError] = useState(null);
  const {
    data: { accessToken, applicationToken, shortCode },
    error: sandboxError,
    loading: sandboxLoading,
  } = useSandboxCredentials();

  // TODO: https://marqeta.atlassian.net/browse/PS-30549
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sandboxCredentialsVariables = {
    accessToken,
    applicationToken,
    'x-marqeta-program-short-code': shortCode,
  };

  useEffect(() => {
    const fetchWebhooks = async () => {
      if (!token || !accessToken || !applicationToken || !shortCode) return;
      const variables = {
        token,
        ...sandboxCredentialsVariables,
      };
      const {
        data: webhookData,
        loading: webhookLoading,
        error: webhookError,
      } = await client.query({ query: WEBHOOK_QUERY, variables });
      setWebhookData(webhookData);
      setWebhookLoading(webhookLoading);
      setWebhookError(webhookError);
    };
    fetchWebhooks();
  }, [client, accessToken, applicationToken, shortCode, sandboxCredentialsVariables, token]);
  return !token
    ? {
        webhook: null,
        loading: true,
        error: null,
      }
    : {
        webhook: propOr(null, 'publicSandboxWebhook', webhookData),
        loading: sandboxLoading || webhookLoading,
        error: sandboxError || webhookError,
      };
};

export { useWebhook };
