import React from 'react';
import PropTypes from 'prop-types';
import { ActionCard } from '@mqd/volt-base';

import { CardTemplateContext } from '../../context/CardTemplateContext';
import { useIsFirstRender } from '../../hooks/use-is-first-render';

import { CardTemplate } from './components/card-template';
import { CardErrorHandler } from './components/card-error-handler/CardErrorHandler';

const { Card, MODE } = ActionCard;

export function ReportActionCards({ data, previewTemplate, editTemplate, cardType, actions }) {
  const isFirstRenderOfGroup = useIsFirstRender();

  const templateContextValue = {
    previewTemplate,
    editTemplate,
    cardType,
    actions,
    isFirstRenderOfGroup,
  };

  return (
    <CardTemplateContext.Provider value={templateContextValue}>
      {data.map((datum) => (
        <CardErrorHandler key={datum.id} id={datum.id}>
          <Card
            datum={datum}
            previewTemplate={CardTemplate}
            editTemplate={CardTemplate}
            defaultMode={datum.temp ? MODE.EDIT : MODE.PREVIEW}
          />
        </CardErrorHandler>
      ))}
    </CardTemplateContext.Provider>
  );
}

ReportActionCards.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  previewTemplate: Card.propTypes.previewTemplate,
  editTemplate: Card.propTypes.editTemplate,
  cardType: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    onCreateNewCard: PropTypes.func.isRequired,
    onUpdateCard: PropTypes.func.isRequired,
    onResetCard: PropTypes.func.isRequired,
    onDeleteCard: PropTypes.func.isRequired,
    retryLastAction: PropTypes.func.isRequired,
  }),
};
