import React, { useMemo, useState } from 'react';
import { HSpacer, Logo, Drawer } from '@mqd/volt-base';
import { NotificationList, NotificationBell } from '@mq/parity-plus';
import { compose, includes, not } from 'ramda';
import GlobalSearch from '../../../../components/global-search/GlobalSearch.js';
import { FlipFlop } from '../../../../utils/index.js';
import {
  ActiveIssueLink,
  DocsLink,
  FeedbackLink,
  NewFeatureLink,
  UserProfileDropdown,
} from '@mq/volt-amc-container';
import EnvironmentSelector from '../../../../components/environment-selector/EnvironmentSelector.js';
import ProgramSelector from '../../../../components/program-selector/ProgramSelector.js';
import { useNotifications } from '../../../../components/index.js';
import { isJanusUser } from '../../../../utils/route-authorization/routes/functions.js';

import s from './../Header.module.css';

const ROUTES_WITH_NO_GLOBAL_SEARCH = ['Development', 'TransactionTimeline'];
const ROUTES_WITH_NO_PROGRAM_SELECTOR = ['Development', 'TransactionTimeline'];

function TitleBar({
  getBreadcrumbRootRoute,
  gqlClient,
  handleSearchView,
  hasRoleInArrayForActiveProgram,
  isFeatureUnifyBetaActive,
  isGlobalIssueActive,
  janusUserEmail,
  newFeatureLink,
  routerStore,
  userStore,
}) {
  const [isNotificationsShown, setNotificationsShown] = useState(false);
  const {
    notifications,
    saveNotifications,
    removeNotificationHandler,
    removeNotificationSectionHandler,
    markAllNotificationsAsRead,
  } = useNotifications();

  const closeDrawer = () => {
    setNotificationsShown(false);
    markAllNotificationsAsRead();
  };

  const globalSearchInitArgs = useMemo(() => {
    return { gqlClient };
  }, [gqlClient]);

  const routeHidesSearch = includes(routerStore.currentRouteName, ROUTES_WITH_NO_GLOBAL_SEARCH);
  const userHidesSearch = compose(not, isJanusUser)(userStore);
  const globalSearchIsHidden = routeHidesSearch || userHidesSearch;
  const programSelectorIsHidden = includes(
    routerStore.currentRouteName,
    ROUTES_WITH_NO_PROGRAM_SELECTOR
  );

  const currentProgramName = userStore?.selectedActiveProgram?.program;
  const { privateSandboxActive } = userStore;

  return (
    <div className={s.headerMain}>
      <div className={s.headerLeft}>
        {!isFeatureUnifyBetaActive && (
          <>
            <Logo type={'full dark'} width={177} />
            <HSpacer factor={1.5} />
            {!programSelectorIsHidden && (
              <>
                <ProgramSelector
                  width="256px"
                  marginLeft="-8px"
                  getBreadcrumbRootRoute={getBreadcrumbRootRoute}
                />
                <HSpacer factor={3} />
              </>
            )}
          </>
        )}
        {!globalSearchIsHidden && isFeatureUnifyBetaActive && (
          <GlobalSearch
            currentProgramName={currentProgramName}
            inputProps={{ width: '100%' }}
            onClickResult={handleSearchView}
            storeInitArgs={globalSearchInitArgs}
            janusUserEmail={janusUserEmail}
            hasRoleInArrayForActiveProgram={hasRoleInArrayForActiveProgram}
          />
        )}
      </div>
      <div className={s.headerRight}>
        {!globalSearchIsHidden && !isFeatureUnifyBetaActive && (
          <GlobalSearch
            currentProgramName={currentProgramName}
            inputProps={{ width: '320px' }}
            onClickResult={handleSearchView}
            storeInitArgs={globalSearchInitArgs}
            janusUserEmail={janusUserEmail}
            hasRoleInArrayForActiveProgram={hasRoleInArrayForActiveProgram}
          />
        )}
        {privateSandboxActive && (
          <EnvironmentSelector getBreadcrumbRootRoute={getBreadcrumbRootRoute} />
        )}
        <div className={s.hideSmall}>
          <HSpacer factor={2} />
          {!globalSearchIsHidden && (
            <>
              <NewFeatureLink active={newFeatureLink} />
              {newFeatureLink && <HSpacer factor={2} />}
            </>
          )}
          <ActiveIssueLink active={isGlobalIssueActive} />
          {isGlobalIssueActive && <HSpacer factor={2} />}
          <FeedbackLink />
          <HSpacer factor={2} />
          <DocsLink />
        </div>
        <HSpacer factor={2} />
        <div className={s.seperator} />
        <HSpacer factor={2} />

        {FlipFlop.get('notifications-panel', false) && (
          <>
            <HSpacer factor={0.5} />
            <NotificationBell
              notifications={notifications}
              setNotificationsShown={setNotificationsShown}
            />
            <HSpacer factor={3} />
          </>
        )}

        <Drawer isOpened={isNotificationsShown} closeDrawer={closeDrawer}>
          <NotificationList
            notifications={notifications}
            saveNotifications={saveNotifications}
            removeNotificationHandler={removeNotificationHandler}
            removeNotificationSectionHandler={removeNotificationSectionHandler}
          />
        </Drawer>
        <UserProfileDropdown />
      </div>
    </div>
  );
}

TitleBar.propTypes = {};
TitleBar.defaultProps = {};

export default TitleBar;
