import { ISOCurrencyList } from './constants.js';

export const convertToCode = (value) => {
  if (!isNaN(Number(value))) {
    const currencyObject = ISOCurrencyList.find(({ number }) => String(number) === String(value));

    return currencyObject?.code;
  }

  return value?.code ?? value;
};

export const getOptionsSorter = (primaryCodes) => (left, right) => {
  const isLeftPrimary = primaryCodes.includes(left);
  const isRightPrimary = primaryCodes.includes(right);

  if (isLeftPrimary && isRightPrimary) {
    return primaryCodes.indexOf(left) - primaryCodes.indexOf(right);
  } else if (isLeftPrimary) {
    return -1;
  } else if (isRightPrimary) {
    return 1;
  }

  return left.localeCompare(right);
};
