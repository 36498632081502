import currentUserStore from 'stores/CurrentUserStore';

export const requirements = {
  requiredPermissions: ['Support', 'Admin', 'SuperAdmin'],
};

export const verifier = () => {
  const allowed = ['Support', 'Admin', 'SuperAdmin'];
  const {
    userStore: { uamGranularPermissionsActive, permissionLevel },
  } = currentUserStore;

  return !uamGranularPermissionsActive && allowed.includes(permissionLevel);
};
