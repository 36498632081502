import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Flex, VSpacer } from '@mqd/volt-base';
import { Pagination, Table } from '@mq/voltron-table';
import Placeholder from '../Placeholder/index.js';
import ColumnEditor from '../ColumnEditor/index.js';
import { useHistoryDataWithProgramFullNames } from './hooks/use-history-data-with-program-full-names.js';

import s from './Table.module.css';

const HEADER_ROW_HEIGHT = 40;
const BODY_ROW_HEIGHT = 41;
const MIN_VISIBLE_ROWS = 10;
const HORIZONTAL_SCROLL_HEIGHT = 12;

const HistoryTable = ({ historyContext }) => {
  const {
    activeColumns,
    loading,
    history,
    sortBy,
    setSortBy,
    error,
    isMore,
    isLess,
    goNextPage,
    goPrevPage,
  } = useContext(historyContext);
  const { historyDataWithProgramFullNames } = useHistoryDataWithProgramFullNames(history);

  const TABLE_HEIGHT =
    Math.max(history.length, MIN_VISIBLE_ROWS) * BODY_ROW_HEIGHT +
    (HEADER_ROW_HEIGHT + HORIZONTAL_SCROLL_HEIGHT);

  const noDataAvailable = !loading && history.length === 0;
  if (noDataAvailable || error) {
    return <Placeholder hasError={Boolean(error)} />;
  }

  return (
    <div className={s.tableWrap}>
      <ColumnEditor historyContext={historyContext} />

      <Table
        loading={loading}
        columns={activeColumns.toJS()}
        height={TABLE_HEIGHT}
        bodyRowHeight={BODY_ROW_HEIGHT}
        scrollToTopUpdateKey={loading}
        data={historyDataWithProgramFullNames}
        inlineStyle={{
          borderTop: 'none',
          borderLeft: 'none',
        }}
        sort={sortBy}
        onSort={setSortBy}
      />
      <VSpacer factor={1} />

      {(isMore || isLess) && (
        <Flex justifyContent="flex-end">
          <Pagination
            startIndex={0}
            isMore={isMore}
            isLess={isLess}
            handleNextClick={goNextPage}
            handlePreviousClick={goPrevPage}
          />
        </Flex>
      )}
    </div>
  );
};

HistoryTable.propTypes = {
  historyContext: PropTypes.object.isRequired,
};

export default observer(HistoryTable);
