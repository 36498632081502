import calculateAndFormatScaledDecimal from '../../utilities/number/calculate-and-format-scaled-decimal.js';

export function createDetailsColumn({
  currencyCode,
  details = {},
  feesTotal,
  header,
  total,
  transactionsAmounts,
  type,
  interchangeFee,
}) {
  const crossBorderFee = calculateAndFormatScaledDecimal(details.cross_border_fee, currencyCode);
  const calculatedInterchangeFee = calculateAndFormatScaledDecimal(interchangeFee, currencyCode);
  const surchargeAtmPinDebit = calculateAndFormatScaledDecimal(
    details.surcharge_atm_pin_debit_fee,
    currencyCode
  );

  const transactionTotal = calculateAndFormatScaledDecimal(transactionsAmounts, currencyCode);

  const formattedFeesTotal = calculateAndFormatScaledDecimal(feesTotal, currencyCode);

  const formattedTotal = calculateAndFormatScaledDecimal(total, currencyCode);

  return {
    crossBorderFee,
    feesTotal: formattedFeesTotal,
    interchangeFee: calculatedInterchangeFee,
    surchargeAtmPinDebit,
    total: formattedTotal,
    transactionTotal,
  };
}
