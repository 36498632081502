import { isProgramConfigFlagEnabled } from './functions.js';
import currentUserStore from 'stores/CurrentUserStore';
import { UAM_GRANULAR_PERMISSIONS } from '@mqd/mqd-constants';

const UAMRoles = [UAM_GRANULAR_PERMISSIONS.TRANSACTION_REVIEW_REPORT_VIEW];

export const requirements = {
  programConfigRequirements: [],
  requiredGranularPermissions: UAMRoles,
};

export const verifier = () => {
  const { userStore = {} } = currentUserStore;
  const { uamGranularPermissionsActive, hasRequiredUamPermissions } = userStore;
  const isGrantedAccessFromUamRoles = hasRequiredUamPermissions(UAMRoles);
  const isEnabledForProgram =
    isProgramConfigFlagEnabled('ENABLE_CONTROLPLANE_DECISION_REPORTS') ||
    isProgramConfigFlagEnabled('ENABLE_CONTROLPLANE_DASHBOARD');

  if (uamGranularPermissionsActive && isGrantedAccessFromUamRoles && isEnabledForProgram) {
    return true;
  }

  if (!uamGranularPermissionsActive && isEnabledForProgram) {
    return true;
  }

  return false;
};
