import { useHashExceptionDetailsState } from '../../utilities/use-hash-exception-details/context.js';

import { createDetailsColumn } from './utils.js';

export default function useApprovalDetailsPageSubmittedDetails() {
  const { data, loading } = useHashExceptionDetailsState();

  if (loading) {
    return [];
  }

  const { adjustment = {}, common_data: { currency_code } = {} } = data;

  return createDetailsColumn({
    currencyCode: currency_code,
    total: adjustment.submitted_total,
    transactionsAmounts: adjustment.transactions_total,
    details: adjustment,
    header: 'Submitted transaction summary',
    type: 'submitted',
    feesTotal: adjustment.fees_total,
    interchangeFee: adjustment.interchange_payable,
  });
}
