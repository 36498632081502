import React from 'react';
import { ActionPlaceholder } from '@mq/voltron-table';
import { flipFlop } from '@mq/volt-amc-container';
import { formatMoneyAndNegative } from '@mqd/volt-currency';
import { Flex, Link } from '@mqd/volt-base';
import { Status } from '@mq/voltron-parent';

const cardholderAuthDataRenderer = () => {
  return ({ cellValue }) => {
    if (!cellValue || (typeof cellValue === 'object' && !Object.keys(cellValue).length)) return;

    const cleanValue = { ...cellValue };
    delete cleanValue.__typename;
    return JSON.stringify(cleanValue);
  };
};

const locationRenderer = () => {
  return ({ cellValue }) => {
    if (!cellValue) return;
    return <div>{`${cellValue.city}, ${cellValue.state}`}</div>;
  };
};

// remove this when pos will be used with parsing to individual keys
const posRenderer = () => {
  return ({ cellValue }) => {
    if (!cellValue || (typeof cellValue === 'object' && !Object.keys(cellValue).length)) return;

    const cleanValue = { ...cellValue };
    delete cleanValue.__typename;
    return JSON.stringify(cleanValue);
  };
};

const statusRenderer = () => {
  return ({ cellValue }) => {
    return (
      <div>
        <Status status={cellValue} />
      </div>
    );
  };
};

export const transactionsTableColumns = ({
  actionsRenderer,
  goToDigitalWalletToken,
  headerRenderer,
  parentIsBusiness,
}) => {
  const availableBalanceFlagActive = flipFlop.get('impacted-amount', false);
  const impactedAmountFlagActive = flipFlop.get('available-balance-in-transactions', false);
  return [
    {
      header: headerRenderer,
      accessor: 'token',
      key: 'actions',
      renderer: actionsRenderer,
      noPadding: true,
      noResize: true,
      noRowClick: true,
      noSort: true,
      noEdit: true,
      deferRenderPlaceholder: () => <ActionPlaceholder width={32} />,
    },
    {
      header: 'Amount',
      accessor: 'amount',
      key: 'amount',
      renderer: ({ cellValue, row }) => {
        const { currency_code } = row || {};
        if (!cellValue && typeof cellValue !== 'number') return null;
        return (
          <Flex justifyContent="flex-end">{formatMoneyAndNegative(cellValue, currency_code)}</Flex>
        );
      },
    },
    ...(impactedAmountFlagActive
      ? [
          {
            header: 'Impacted amount',
            accessor: 'impactedAmount',
            key: 'impactedAmount',
            renderer: ({ cellValue, row }) => {
              const { currency_code } = row || {};
              if (!cellValue && typeof cellValue !== 'number') return null;
              return (
                <Flex justifyContent="flex-end">
                  {formatMoneyAndNegative(cellValue, currency_code)}
                </Flex>
              );
            },
          },
        ]
      : []),
    {
      header: 'State',
      accessor: 'state',
      key: 'state',
      renderer: statusRenderer(),
      filterType: 'starts-with',
    },
    {
      header: 'Digital wallet',
      accessor: 'token_requestor_name',
      key: 'token_requestor_name',
      renderer: ({ cellValue, row }) => {
        const { digitalWalletToken } = row;
        const onClick = () => goToDigitalWalletToken(digitalWalletToken);

        return digitalWalletToken && <Link onClick={onClick}>{cellValue}</Link>;
      },
    },
    {
      header: 'Location',
      accessor: 'card_acceptor',
      key: 'location',
      renderer: locationRenderer(),
    },
    {
      header: 'Date',
      accessor: 'created_time',
      key: 'created_time',
    },
    ...(availableBalanceFlagActive
      ? [
          {
            header: 'Available balance',
            accessor: 'available_balance',
            key: 'available_balance',
            renderer: ({ cellValue, row }) => {
              const { currency_code } = row || {};
              if (!cellValue && typeof cellValue !== 'number') return null;
              return (
                <Flex justifyContent="flex-end">
                  {formatMoneyAndNegative(cellValue, currency_code)}
                </Flex>
              );
            },
          },
        ]
      : []),
    {
      header: 'Token',
      accessor: 'token',
      key: 'token',
    },
    {
      header: 'Settlement date',
      accessor: 'settlement_date',
      key: 'settlement_date',
      hide: !parentIsBusiness,
    },
    // hide below
    {
      header: 'Merchant',
      accessor: 'cardAcceptor',
      key: 'cardAcceptor',
      hide: true,
    },
    {
      header: 'Response code',
      accessor: 'responseCode',
      key: 'responseCode',
      hide: true,
    },
    {
      header: 'Response memo',
      accessor: 'responseMemo',
      key: 'responseMemo',
      hide: true,
    },
    {
      header: 'Response additional information',
      accessor: 'responseAdditionalInformation',
      key: 'responseAdditionalInformation',
      hide: true,
    },
    {
      header: 'Type',
      accessor: 'type',
      key: 'type',
      hide: true,
    },
    {
      header: 'Acquirer reference data',
      accessor: 'acquirer_reference_data',
      key: 'acquirer_reference_data',
      hide: true,
    },
    {
      header: 'Approval code',
      accessor: 'approval_code',
      key: 'approval_code',
      hide: true,
    },
    {
      header: 'Network',
      accessor: 'network',
      key: 'network',
      hide: true,
    },
    {
      header: 'Subnetwork',
      accessor: 'subnetwork',
      key: 'subnetwork',
      hide: true,
    },
    {
      header: 'ATM subnetwork',
      accessor: 'atm_subnetwork',
      key: 'atm_subnetwork',
      hide: true,
    },
    {
      header: 'Request amount',
      accessor: 'request_amount',
      key: 'request_amount',
      hide: true,
    },
    {
      header: 'Duration',
      accessor: 'duration',
      key: 'duration',
      hide: true,
    },
    {
      header: 'AVS',
      accessor: 'avs',
      key: 'avs',
      hide: true,
    },
    {
      header: 'Verification result',
      accessor: 'verification_result',
      key: 'verification_result',
      hide: true,
    },
    {
      header: 'Currency code',
      accessor: 'currency_code',
      key: 'currency_code',
      hide: true,
    },
    {
      header: 'pos',
      accessor: 'pos',
      key: 'pos',
      renderer: posRenderer(),
      hide: true,
    },
    {
      header: 'Card security code verification',
      accessor: 'card_security_code_verification',
      key: 'card_security_code_verification',
      hide: true,
    },
    {
      header: 'Cardholder authentication data',
      accessor: 'cardholder_authentication_data',
      key: 'cardholder_authentication_data',
      hide: true,
      renderer: cardholderAuthDataRenderer(),
    },
    {
      header: 'Standin Approved By',
      accessor: 'standin_approved_by',
      key: 'standin_approved_by',
      hide: true,
    },
    {
      header: 'Transaction Metadata',
      accessor: 'transaction_metadata',
      key: 'transaction_metadata',
      hide: true,
    },
    {
      header: 'Acting User Token',
      accessor: 'acting_cardholder_token',
      key: 'acting_cardholder_token',
      hide: true,
    },
    {
      header: 'User token',
      accessor: 'cardholder_token',
      key: 'cardholder_token',
      hide: true,
    },
    {
      header: 'Card token',
      accessor: 'card_token',
      key: 'card_token',
      hide: true,
    },
    {
      header: 'Preceding related transaction token',
      accessor: 'preceding_related_transaction_token',
      key: 'preceding_related_transaction_token',
      hide: true,
    },
    {
      header: 'Incremental authorization transaction tokens',
      accessor: 'incremental_authorization_transaction_tokens',
      key: 'incremental_authorization_transaction_tokens',
      hide: true,
    },
    {
      header: 'Program transfer',
      accessor: 'program_transfer',
      key: 'program_transfer',
      hide: true,
    },
    {
      header: 'Fee transfer',
      accessor: 'fee_transfer',
      key: 'fee_transfer',
      hide: true,
    },
    {
      header: 'Peer transfer',
      accessor: 'peer_transfer',
      key: 'peer_transfer',
      hide: true,
    },
    {
      header: 'Offer orders',
      accessor: 'offer_orders',
      key: 'offer_orders',
      hide: true,
    },
    {
      header: 'Rewards',
      accessor: 'rewards',
      key: 'rewards',
      hide: true,
    },
    {
      header: 'Polarity',
      accessor: 'polarity',
      key: 'polarity',
      hide: true,
    },
    {
      header: 'Real time fee group',
      accessor: 'real_time_fee_group',
      key: 'real_time_fee_group',
      hide: true,
    },

    {
      header: 'Electronic commerce indicator',
      accessor: 'electronic_commerce_indicator',
      key: 'electronic_commerce_indicator',
      hide: true,
    },

    {
      header: 'Verification value created by',
      accessor: 'verification_value_created_by',
      key: 'verification_value_created_by',
      hide: true,
    },
    {
      header: 'Acquirer fee amount',
      accessor: 'acquirer_fee_amount',
      key: 'acquirer_fee_amount',
      hide: true,
    },
    {
      header: 'Issuer interchange amount',
      accessor: 'issuer_interchange_amount',
      key: 'issuer_interchange_amount',
      hide: true,
    },
    {
      header: 'Network Original Amount',
      accessor: 'currencyConversionNetworkOriginalAmount',
      key: 'currencyConversionNetworkOriginalAmount',
      hide: true,
    },
    {
      header: 'Network Original Currency Code',
      accessor: 'currencyConversionNetworkOriginalCurrencyCode',
      key: 'currencyConversionNetworkOriginalCurrencyCode',
      hide: true,
    },
    {
      header: 'Channel',
      accessor: 'channel',
      key: 'channel',
      hide: true,
    },
    {
      header: 'Cardholder presence',
      accessor: 'cardholder_presence',
      key: 'cardholder_presence',
      hide: true,
    },
    {
      header: 'Card presence',
      accessor: 'card_presence',
      key: 'card_presence',
      hide: true,
    },
    {
      header: 'Processing type',
      accessor: 'processing_type',
      key: 'processing_type',
      hide: true,
    },
    {
      header: 'Bank transfer token',
      accessor: 'bank_transfer_token',
      key: 'bank_transfer_token',
      hide: true,
      noSort: true,
    },
  ];
};
