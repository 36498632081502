import gql from 'graphql-tag';

export const BULK_SUBMIT_EXCEPTIONS = gql`
  mutation bulkSubmitExceptions(
    $subnetwork: String!
    $tx_hash_internal_ids: [String!]
    $adjustment_action: ExceptionTypeEnum!
    $reason: String!
    $note: String
  ) {
    bulkSubmitExceptions(
      subnetwork: $subnetwork
      tx_hash_internal_ids: $tx_hash_internal_ids
      adjustment_action: $adjustment_action
      reason: $reason
      note: $note
    ) {
      status
      statusText
      message
      unsuccessful_tx_hash_internal_ids
    }
  }
`;
