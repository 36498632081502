import gql from 'graphql-tag';

const UPDATE_WEBHOOK_MUTATION = gql`
  mutation publicSandboxUpdateWebhook(
    $token: ID!
    $active: Boolean!
    $accessToken: String!
    $applicationToken: String!
    $name: String!
    $url: String!
    $username: String!
    $password: String!
    $headers: String!
    $events: [String!]
  ) {
    publicSandboxUpdateWebhook(
      token: $token
      active: $active
      name: $name
      config: {
        url: $url
        basic_auth_username: $username
        basic_auth_password: $password
        custom_header: $headers
      }
      events: $events
      sandbox_creds: { access_token: $accessToken, application_token: $applicationToken }
    ) {
      active
      token
      name
      last_modified_time
      created_time
      events
      config {
        url
        basic_auth_username
        basic_auth_password
        custom_header
      }
    }
  }
`;

const CREATE_WEBHOOK_MUTATION = gql`
  mutation publicSandboxCreateWebhook(
    $name: String!
    $active: Boolean!
    $url: String!
    $username: String!
    $password: String!
    $headers: String!
    $accessToken: String!
    $applicationToken: String!
    $events: [String!]
  ) {
    publicSandboxCreateWebhook(
      name: $name
      active: $active
      events: $events
      config: {
        url: $url
        basic_auth_username: $username
        basic_auth_password: $password
        custom_header: $headers
      }
      sandbox_creds: { access_token: $accessToken, application_token: $applicationToken }
    ) {
      token
    }
  }
`;

const WEBHOOKS_QUERY = gql`
  query publicSandboxWebhooks($accessToken: String!, $applicationToken: String!) {
    publicSandboxWebhooks(
      sandbox_creds: { access_token: $accessToken, application_token: $applicationToken }
    ) {
      count
      data {
        token
        active
        name
        last_modified_time
        created_time
        events
        config {
          url
          basic_auth_username
          basic_auth_password
        }
      }
    }
  }
`;

const WEBHOOK_QUERY = gql`
  query publicSandboxWebhook($token: ID!, $accessToken: String!, $applicationToken: String!) {
    publicSandboxWebhook(
      token: $token
      sandbox_creds: { access_token: $accessToken, application_token: $applicationToken }
    ) {
      token
      active
      name
      last_modified_time
      created_time
      events
      config {
        url
        basic_auth_username
        basic_auth_password
        custom_header
      }
    }
  }
`;

const WEBHOOK_PING = gql`
  query publicSandboxPingWebhook($token: ID!, $accessToken: String!, $applicationToken: String!) {
    publicSandboxPingWebhook(
      token: $token
      sandbox_creds: { access_token: $accessToken, application_token: $applicationToken }
    ) {
      __typename
    }
  }
`;

export {
  CREATE_WEBHOOK_MUTATION,
  WEBHOOK_PING,
  WEBHOOK_QUERY,
  WEBHOOKS_QUERY,
  UPDATE_WEBHOOK_MUTATION,
};
