import React from 'react';
import routerStore from 'stores/router/RouterStore';
import currentUserStore from 'stores/CurrentUserStore';
import { observer } from 'mobx-react';
import retry from './../../../utils/helpers/retry.js';
import { mergeCustomPrograms } from './../../../utils/program-config/customPrograms.js';
import { requirements, verifier, redirects } from 'utils/route-authorization/';
import { PRIVATE_SANDBOX } from '../../../views/admin/uam-granular-permissions/constants.js';
import { SANDBOX } from '../../environment-selector/constants';

const NotAllowedView = React.lazy(() =>
  retry(() => import('../../../views/not-allowed/NotAllowedView'))
);

const CurrentRoute = () => {
  const {
    currentRouteName,
    currentProgramShortCodeInUrl,
    currentEnvironmentInUrl,
    routeMap,
    route,
  } = routerStore;
  const { id: currentRouteId } = route || {};
  const { userStore = {} } = currentUserStore ? currentUserStore : {};
  const { programsList = [], activeProgram } = userStore;
  const hasPrivateSandboxRole = userStore.redseaRoles?.includes(PRIVATE_SANDBOX);

  // Merge custom qa programs data with programs for non-prod for development & testing.
  // these programs are not available in pp_table must be manually added (UPS-16614).
  const mergedProgramsList = mergeCustomPrograms(programsList);

  const userHasAccessToProgram = mergedProgramsList.find(
    ({ short_name }) => short_name === currentProgramShortCodeInUrl
  );

  if (currentProgramShortCodeInUrl && !userHasAccessToProgram && activeProgram) {
    return (
      <React.Suspense fallback={<div />}>
        <NotAllowedView
          desiredProgram={currentProgramShortCodeInUrl}
          defaultProgram={activeProgram}
        />
      </React.Suspense>
    );
  }

  if (currentEnvironmentInUrl && currentEnvironmentInUrl === SANDBOX && !hasPrivateSandboxRole) {
    return (
      <React.Suspense fallback={<div />}>
        <NotAllowedView protectionRules={{ requiredRoles: [PRIVATE_SANDBOX] }} />
      </React.Suspense>
    );
  }

  const redirectTo = redirects(currentRouteId);

  if (redirectTo) {
    const validatedRedirectPath = routeMap && routeMap[redirectTo]?.path;
    if (validatedRedirectPath) {
      routerStore.go(validatedRedirectPath);
    } else {
      routerStore.go('/home');
    }
  }

  const viewVerified = verifier(currentRouteName);
  const viewIsProtected = requirements(currentRouteName);

  if (!viewVerified && viewIsProtected) {
    return (
      <React.Suspense fallback={<div />}>
        <NotAllowedView protectionRules={requirements(currentRouteName)} />
      </React.Suspense>
    );
  } else {
    const { currentView: View } = routerStore;
    return View && <View />;
  }
};

export default observer(CurrentRoute);
