import React, { useReducer } from 'react';
import { reducer, initialState } from './reducer.js';
import { ProgramConfigStateContext, ProgramConfigDispatchContext } from './context.js';

export default function ProgramConfigSetup({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ProgramConfigStateContext.Provider value={state}>
      <ProgramConfigDispatchContext.Provider value={dispatch}>
        {children}
      </ProgramConfigDispatchContext.Provider>
    </ProgramConfigStateContext.Provider>
  );
}
