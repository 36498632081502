import React, { useContext, useRef } from 'react';
import { observer } from 'mobx-react';
import { ErrorModal, VSpacer } from '@mqd/volt-base';
import { ProgramReserveContext } from '../../../../context/ProgramReserveContext.js';
import { goToProgramReserve } from '../../../../utils/navigation.js';
import { usePageLeaveConfirmationModal } from '../../../../hooks/use-page-leave-confirmation-modal.js';
import PageHeader from '../../../PageHeader/index.js';
import TransactionsForm from '../../../TransactionsForm/TransactionsForm.js';
import FormButtons from '../../../FormButtons/FormButtons.js';
import FileInput from '../FileInput/FileInput.js';
import { BulkEntryContext } from '../../BulkEntryContext.js';

function BulkEntryEdit() {
  const { areChangesMade } = useContext(ProgramReserveContext);
  const { file, setFile, parsingError, setParsingError } = useContext(BulkEntryContext);
  const fileInputRef = useRef();

  usePageLeaveConfirmationModal();

  const handleModalDismissClick = () => {
    setParsingError(null);
    setFile(null);
    fileInputRef.current.resetFile();
  };

  return (
    <>
      <PageHeader
        breadcrumbs={[{ children: 'Bulk entry' }]}
        title="Log bulk entry"
        description={
          <>
            Log bulk entries by uploading a formatted bank file with programs and amounts. Represent
            withdrawal
            <br /> amounts with a (-) symbol. Currency codes will be auto selected based on the
            program settings.
          </>
        }
      />

      <FileInput
        ref={fileInputRef}
        handleFiles={(file) => file && setFile(file)}
        placeholder={file?.name}
      />

      {areChangesMade && !parsingError && (
        <>
          <VSpacer factor={3} />
          <TransactionsForm />
        </>
      )}

      {(!areChangesMade || parsingError) && (
        <FormButtons onCancelClick={goToProgramReserve} canContinue={false} />
      )}

      {parsingError && (
        <ErrorModal heading="Upload failed" hideModal={handleModalDismissClick}>
          {parsingError}
        </ErrorModal>
      )}
    </>
  );
}

export default observer(BulkEntryEdit);
