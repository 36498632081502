// @flow
import { decorate, observable, action, computed } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';
import { BusinessStore, CardholderStore, CardStore } from '@mq/parity-plus';
import currentUserStore from 'stores/CurrentUserStore';
import { getCardOrCardholderQuery } from '../../packages/parity-plus/src/shared-utils/index';

export const MAX_NUM_PROGRAMS_TO_SEARCH_ACROSS = 60;
class GlobalSearchStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
  }
  // values
  searchVal: string = '';
  results: Array = [];
  noResultFound: boolean = false;

  // actions
  async search() {
    this.loading = true;
    this.noResultFound = false;
    this.clearResults();

    await this.searchByTokenOrAccount({ value: this.trimmedSearchVal });
    await this.fetchDigitalWalletTokens(this.trimmedSearchVal);
    await this.fetchTransactions(this.trimmedSearchVal);

    this.loading = false;
    this.noResultFound = this.results.length < 1;
  }

  async fetchDigitalWalletTokens(value) {
    const query = `
    query tokenizationDigitalWalletTokens(
        $pan_reference_id: String,
        $token_reference_id: String, 
        $device_id: String 
      ) {
        tokenizationDigitalWalletTokens(
          filter_params: {
            pan_reference_id: $pan_reference_id,
            token_reference_id: $token_reference_id,
            device_id: $device_id
          }
        ) {
          data {
            token
          }
      }
    }
    `;

    const response = await this.gqlQuery(query, {
      pan_reference_id: value,
      token_reference_id: value,
      device_id: value,
    });

    if (!response?.data?.tokenizationDigitalWalletTokens?.data?.length) {
      return;
    }

    response.data.tokenizationDigitalWalletTokens.data.forEach((item) => {
      this.results.push({
        type: 'Tokens',
        store: { token: item.token },
      });
    });
  }

  async fetchSingleProgramSearchResults(programShortCode, value) {
    const searchVariables = {
      ...value,
      'x-marqeta-program-short-code': programShortCode,
    };
    const paramsInfo = { value: { type: 'String' } };

    const result = await this.gqlQuery(
      `
        query ${this.globalTokenAndAccountSearchQuery}(${this.configureOuterQueryParams(
        paramsInfo
      )}) {
          ${this.globalTokenAndAccountSearchQuery}(${this.configureInnerQueryParams(paramsInfo)}) {
            business {
              token
              business_name_legal
              status
              active
            }
            cardholder {
              token
              first_name
              last_name
              status
              active
            }
            card {
              token
              last_four
              state
            }
            dispute {
              token
              type
              state
            }
          }
        }
      `,
      searchVariables
    );

    return { ...result, programShortCode };
  }

  async fetchTransactions(value) {
    const { userStore = {} } = currentUserStore || {};
    const { activeProgram = {} } = userStore;
    const query = `
    query transaction(
        $token: ID!,
      ) {
        transaction(
          token: $token
        ) {
          token
          currency_code
          amount
          card_acceptor {
            name
          }
          cardholder {
            first_name
            middle_name
            last_name
          }
          cardholder_token
          user_token
      }
    }
    `;

    try {
      const { data } = await this.gqlQuery(query, {
        token: value,
      });

      if (data?.transaction) {
        const { token, currency_code, card_acceptor, amount, user_token, cardholder_token, cardholder } = data.transaction;
        this.results.push({
          type: 'Transaction',
          store: {
            token,
            amount,
            cardholder,
            programShortCode: activeProgram.short_name,
            currencyCode: currency_code,
            cardAcceptorName: card_acceptor?.name,
            userToken: user_token || cardholder_token,
          },
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  processSearchResult(result) {
    try {
      const tokenSearch = this.extract(result, this.globalTokenAndAccountSearchQuery);

      const { business, cardholder, card, dispute } = tokenSearch;

      const hasBusinessResult = business && business.token;
      const hasCardholderResult = cardholder && cardholder.token;
      const hasCardResult = card && card.token;
      const hasDisputeResult = dispute && dispute.token;

      if (hasBusinessResult) {
        const businessStore = {
          type: 'Business',
          store: new BusinessStore({
            ...business,
            programShortCode: result.programShortCode,
          }),
        };

        this.results.push({ ...businessStore, programShortCode: result.programShortCode });
      }

      if (hasCardholderResult) {
        // removed else if just in case you have more than one result for a program
        const cardholderStore = {
          type: 'User',
          store: new CardholderStore({
            ...cardholder,
            programShortCode: result.programShortCode,
          }),
        };

        this.results.push({ ...cardholderStore, programShortCode: result.programShortCode });
      }

      if (hasCardResult) {
        // removed else if just in case you have more than one result for a program
        const cardStore = {
          type: 'Card',
          store: new CardStore({ ...card, programShortCode: result.programShortCode }),
        };

        this.results.push({ ...cardStore, programShortCode: result.programShortCode });
      }

      if (hasDisputeResult) {
        const disputeStore = {
          type: 'Dispute',
          store: { ...dispute, programShortCode: result.programShortCode },
        };

        this.results.push({ ...disputeStore, programShortCode: result.programShortCode });
      }
    } catch (e) {
      console.log(e);
    }
  }

  // Iterates all programs and returns results.
  async fetchSearchResultsAcrossPrograms(programShortCodes, value) {
    try {
      const requests = [];

      programShortCodes.map((programShortCode) => {
        const currentRequest = this.fetchSingleProgramSearchResults(programShortCode, value) // Async function that fetches the user info.
          .then((result) => {
            this.processSearchResult(result);
          });
        requests.push(currentRequest);
        return currentRequest;
      });
      return Promise.all(requests);
    } catch (e) {
      console.log(e);
      this.loading = false;
    }
  }

  async searchByTokenOrAccount(value) {
    let programsArray = currentUserStore.userStore.redseaPrograms;
    if (programsArray.length === 0) {
      programsArray = JSON.parse(localStorage.redseaPrograms);
    }
    if (
      programsArray.includes('marqeta') ||
      programsArray.length > MAX_NUM_PROGRAMS_TO_SEARCH_ACROSS
    ) {
      // Only search existing program if user is a Marqeta user or belongs to more than 20 programs
      const { userStore = {} } = currentUserStore || {};
      const { activeProgram = {} } = userStore;

      await this.fetchSingleProgramSearchResults(activeProgram.short_name, value).then((result) => {
        this.processSearchResult(result);
      });
    } else {
      // Search across all programs if user is not a Marqeta user and belongs to 20 programs or less
      await this.fetchSearchResultsAcrossPrograms(programsArray, value);
    }
  }

  clearResults() {
    this.results = [];
    this.noResultFound = false;
  }

  // computed
  get showNoResult(): boolean {
    return this.noResultFound;
  }

  get trimmedSearchVal() {
    return this.searchVal && String(this.searchVal).trim();
  }

  get globalTokenAndAccountSearchQuery() {
    return getCardOrCardholderQuery(
      'globalTokenAndAccountSearch',
      'globalTokenAndAccountSearchByCardProduct',
      localStorage.getItem('userOrgName'),
      JSON.parse(localStorage.getItem('redseaRoles'))
    );
  }
}

decorate(GlobalSearchStore, {
  // values
  searchVal: observable,
  results: observable,
  noResultFound: observable,

  // actions
  search: action.bound,
  clearResults: action.bound,

  // computed
  showNoResult: computed,
  trimmedSearchVal: computed,
  globalTokenAndAccountSearchQuery: computed,
});

export default GlobalSearchStore;
