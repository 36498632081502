import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import s from './GlobalSearch.module.css';
import { HSpacer } from '@mqd/volt-base';
import currentUserStore from 'stores/CurrentUserStore';
import { Icon } from '@mqd/volt-base';

class GlobalSearchResults extends Component {
  onResultClick(onViewClick, programShortCode) {
    const { userStore = {} } = currentUserStore || {};
    const { activeProgram = {} } = userStore;
    onViewClick();
    // Reload only needed if switching programs
    if (activeProgram && activeProgram.short_name !== programShortCode) {
      window.location.reload();
      return false;
    }
  }

  renderItems(searchItemProp) {
    const { userStore = {} } = currentUserStore || {};
    const { activeProgram = {} } = userStore;

    return (
      <div tabIndex="0" className={s.result} data-testid="global-search-result">
        {searchItemProp.map((currentProps) => {
          const {
            type,
            mainText,
            onViewClick,
            programName,
            programShortCode,
            store: { token },
          } = currentProps;

          return (
            <div
              className={s.resultBody}
              data-testid={`global_search_result_type-${type}-${token}-${programShortCode}`}
              key={`${type}-${token}-${programShortCode}`}
            >
              <div
                className={`${s.resultText} ${onViewClick ? '' : s.noHover}`}
                onClick={() => this.onResultClick(onViewClick, programShortCode)}
              >
                <div data-testid={`global_search_result_text`}>{mainText}</div>
                {type && <HSpacer factor={2} />}
                <div className={s.programDetails}>
                  {`${type} • ${programName} `}
                  {activeProgram &&
                  activeProgram !== null &&
                  activeProgram.short_name !== programShortCode ? (
                    <Icon
                      type="new-tab"
                      factor={0.25}
                      style={{ 'margin-left': '4px', width: '12px' }}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    const { allSearchItemProps } = this.props;
    if (allSearchItemProps) {
      return this.renderItems(allSearchItemProps);
    }
    return null;
  }
}

GlobalSearchResults.propTypes = {
  type: PropTypes.string,
  store: PropTypes.object,
  mainText: PropTypes.any,
  onViewClick: PropTypes.func,
  testId: PropTypes.string,
};

GlobalSearchResults.defaultProps = {
  type: '',
  store: {},
  mainText: '',
  onViewClick: null,
  testId: 'global-search-result',
};

export default observer(GlobalSearchResults);
