import React from 'react';
import { getHref, handleClick } from './linkHelpers.js';
import { Breadcrumbs } from '@mqd/volt-base';
import routerStore from 'stores/router/RouterStore';

const routeIndexOf = (idx) => {
  const { currentParams = new Map() } = routerStore;
  const breadcrumb = currentParams.get('breadcrumb');
  try {
    if (breadcrumb) {
      const decodedBreadCrumb = decodeURI(breadcrumb);
      const route = decodedBreadCrumb.split('>')[idx].split(':')[0];
      if (route) return route;
    }
  } catch (e) {
    // no routes
  }
};

const addToBreadcrumb = (resource, name, token) => {
  const { currentParams = new Map() } = routerStore;
  let breadcrumb = currentParams.get('breadcrumb') || '';
  // format 'Cardholders>Cardholder:name:abcd-1234>Card:last:efgh-4567'
  const prefix = breadcrumb ? '>' : '';
  const nameParam = token || name ? `:${name}` : '';
  const tokenParam = token ? `:${token}` : '';
  let addition = `${prefix}${resource}${nameParam}${tokenParam}`;
  return (breadcrumb += addition);
};

const buildCrumbs = () => {
  const { currentParams = new Map() } = routerStore;
  let breadcrumb = currentParams.get('breadcrumb');
  if (!breadcrumb) {
    return [];
  } else {
    breadcrumb = decodeURI(breadcrumb);
  }
  const elements = breadcrumb.split('>');
  return elements.map((element, i) => {
    const params = {};
    const splitElement = element.split(':');
    const resource = splitElement[0];
    let name = splitElement[1];
    const token = splitElement[2];
    if (token) {
      params.token = token;
    }
    if (name) {
      name = decodeURI(name);
    }
    const previousBreadcrumbs = elements.slice(0, i);
    if (previousBreadcrumbs.length) {
      params.breadcrumb = previousBreadcrumbs.join('>');
    }

    const linkProps = { to: resource, params };
    return {
      children: name ? name : resource,
      href: getHref(routerStore, linkProps),
      onClick: (e) => handleClick(e, routerStore, linkProps),
    };
  });
};

const buildBreadcrumb = (...crumbs) => {
  const existingCrumbs = buildCrumbs() || [];
  const allCrumbs = existingCrumbs ? existingCrumbs.concat(crumbs) : crumbs;
  return <Breadcrumbs crumbs={allCrumbs} />;
};

const breadCrumbRoot = () => routeIndexOf(0);

const getBreadcrumbRootRoute = () => {
  const { routeMap } = routerStore;
  const breadcrumRootRoute = routeIndexOf(0);
  const routeKey = Object.keys(routeMap).find((key) => routeMap[key].name === breadcrumRootRoute);
  if (breadcrumRootRoute && routeKey) {
    return routeMap[routeKey].path;
  }
};

export { addToBreadcrumb, buildBreadcrumb, getBreadcrumbRootRoute, breadCrumbRoot };
