import React, { useRef, useEffect } from 'react';
import moment from 'moment';

import { Icon, Text, VSpacer } from '@mqd/volt-base';

import s from './NotificationList.module.css';

const NotificationListItemCircleNew = ({ isRead }) => {
  return !isRead && <div className={s.notificationList__item__new}></div>;
};

const NotificationListItemHeader = ({
  token,
  headLine,
  createdDate,
  isRead,
  removeNotification,
}) => {
  const headerContentRef = useRef(null);
  const formattedDate = moment(createdDate).format('YYYY-MM-DD hh:mm');
  let showAdditionalVSpacer = false;

  useEffect(() => {
    if (
      headerContentRef &&
      headerContentRef.current &&
      headerContentRef.current.offsetHeight > 20
    ) {
      showAdditionalVSpacer = true;
    }
  }, [headerContentRef]);

  return (
    <span className={s.notificationList__item__header}>
      <div className={s.notificationList__item__header__content} ref={headerContentRef}>
        <Text className={s.notificationList__item__header__headLine} type="footnote">
          <span>
            <NotificationListItemCircleNew isRead={isRead} />
          </span>

          <span>
            {headLine}
            <div className={s.notificationList__item__header__bullet}></div>
            <Text inline type="footnote">
              {formattedDate}
            </Text>
          </span>
        </Text>
        {showAdditionalVSpacer && <VSpacer factor={0.5} />}
      </div>

      <div
        className={s.notificationList__item__header__removeBlock}
        onClick={(e) => removeNotification(e, token)}
        data-testid="remove_notification"
      >
        <Icon type="clear" />
      </div>
    </span>
  );
};

const NotificationDescription = ({ description }) => {
  return <Text type="body-sm">{description}</Text>;
};

const NotificationListItem = (props) => {
  const { token, headLine, description, createdDate, markNotificationAsRead } = props;
  if (!headLine || !description) return null;

  const id = `notification-${props.token}`;

  return (
    <div
      className={s.notificationList__item}
      id={id}
      data-testid={id}
      role="notification-item"
      onClick={(e) => markNotificationAsRead(e, token)}
    >
      <NotificationListItemHeader id={id} {...props} />
      <VSpacer factor={0.5} />
      <NotificationDescription description={description} createdDate={createdDate} />
    </div>
  );
};

export default NotificationListItem;
