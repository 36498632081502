import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import {
  Button,
  Card,
  CopyToClipboard,
  Flex,
  HSpacer,
  Icon,
  Link,
  LoadingOverlay,
  Text,
  VSpacer,
} from '@mqd/volt-base';
import { DetailSnippetRow, ParentDetailCard } from '@mq/voltron-parent';
import ChangeStatusModal from './../change-status-modal/ChangeStatusModal';
import { AddMetadataModal, SetPinModal } from './../shared-components';
import s from './CardInfoTab.module.css';

class CardInformationCard extends ParentDetailCard {
  constructor(props) {
    super(props);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  state = {
    loading: false,
    editActive: false,
    expand: false,
    showChangeStatusModal: false,
    showAddMetadataModal: false,
    showSetPinModal: false,
  };

  get cardStore() {
    const { cardStore } = this.props;
    return cardStore ? cardStore : {};
  }

  async handleSave() {
    const { updateCard } = this.cardStore;
    this.setState({ loading: true });
    const success = await updateCard();
    if (success) {
      this.setState({ loading: false, editActive: false });
    } else {
      this.setState({ loading: false });
    }
  }

  handleCancel() {
    const { revertUpdates } = this.cardStore;
    revertUpdates && revertUpdates();
    this.setState({ editActive: false });
  }

  renderHeaderButtons() {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button type="outline" onClick={this.handleCancel}>
          Cancel
        </Button>
        <HSpacer />
        <Button onClick={this.handleSave}>Save</Button>
      </div>
    );
  }

  getFooterButtons() {
    const { expand } = this.state;
    return [
      <Button
        type="outline"
        testId="expand-button"
        onClick={() => this.setState({ expand: !expand })}
      >
        <div className={s.flexDiv}>
          <div>{expand ? 'Minimize' : 'View All'}</div>
          <HSpacer factor={0.5} />
          <Icon type={expand ? 'arrow-up' : 'arrow-down'} factor={0.8333} />
        </div>
      </Button>,
    ];
  }

  parsedPan(formattedPan, pan) {
    let newPan = formattedPan;
    // if pan is not a valid number, we only want to display the last 4 digits
    if (isNaN(pan)) {
      newPan = '____________' + newPan.substring(12, 16);
    }

    const panNumbers = newPan
      ? newPan.replace(/ /g, '').replace(/\D/g, '\u2022').substring(0, 16)
      : '';
    const spacedNumbers = panNumbers.match(/.{1,4}/g);

    if (!spacedNumbers) {
      return '';
    }
    const returnVal = spacedNumbers.join(' ');

    return returnVal;
  }

  renderExpansion() {
    const { created_time, last_modified_time, bulk_issuance_token, state_reason, barcode } =
      this.cardStore;
    return (
      <>
        {this.renderSnippet({
          title: 'State Reason',
          content: state_reason,
          tooltipContent: `
              Metadata explaining the reason
              for the current card state.
              Set in state transition
            `,
        })}
        {this.renderSnippet({
          title: 'Barcode',
          content: barcode,
          tooltipContent: 'A barcode associated with the card',
        })}
        {this.renderSnippet({
          title: 'Created',
          content: created_time,
          tooltipContent: `
              A timestamp of when the card was
              created in the Marqeta API
            `,
        })}
        {this.renderSnippet({
          title: 'Last Modified',
          content: last_modified_time,
          tooltipContent: `
              A timestamp of when the card was
              last updated in the Marqea API
            `,
        })}
        {this.renderMetadata()}
      </>
    );
  }

  renderMetadata() {
    const { editActive } = this.state;
    let { metadataObject = {}, updateMetadata } = this.cardStore;
    const metadata = [];
    for (const key in metadataObject) {
      const value = metadataObject[key];
      metadata.push(
        <React.Fragment key={key}>
          {this.renderSnippet({
            title: key,
            content: value,
            editActive,
            tooltipContent: `
                To delete a metadata key pair
                delete the value and save
              `,
            onChange: (e) => updateMetadata(key, e.target.value),
          })}
        </React.Fragment>
      );
    }
    return (
      <div>
        <VSpacer />
        <div className={s.flexDiv}>
          <Text type="h5">Metadata</Text>
          <HSpacer factor={2} />
          <Button
            size="xs"
            type="outline"
            onClick={() => this.setState({ showAddMetadataModal: true })}
          >
            Add new
          </Button>
        </div>
        <VSpacer factor={2} />
        {metadata}
      </div>
    );
  }

  renderChangeStatusModal() {
    const { showChangeStatusModal } = this.state;
    const { last_four = '' } = this.cardStore;
    if (!showChangeStatusModal) return null;
    return (
      <ChangeStatusModal
        store={this.cardStore}
        heading={`Change State - ****${last_four}`}
        hideModal={() => this.setState({ showChangeStatusModal: false })}
      />
    );
  }

  renderAddMetadataModal() {
    const { showAddMetadataModal } = this.state;
    const { addMetadata } = this.cardStore;
    if (!showAddMetadataModal) return null;
    return (
      <AddMetadataModal
        handleCancel={() => {
          this.setState({ showAddMetadataModal: false });
        }}
        handleSave={async ({ key, value }) => {
          this.setState({ showAddMetadataModal: false, loading: true });
          addMetadata && (await addMetadata({ key, value }));
          this.setState({ loading: false });
        }}
        hideModal={() => {
          this.setState({ showAddMetadataModal: false });
        }}
      />
    );
  }

  renderSetPinModal() {
    const { canSetPin } = this.props;
    const { showSetPinModal } = this.state;
    const { createOrUpdatePin } = this.cardStore;

    if (!canSetPin) return null;

    return (
      <SetPinModal
        hideModal={() => this.setState({ showSetPinModal: false })}
        onSave={createOrUpdatePin}
        show={showSetPinModal}
      />
    );
  }

  renderInfo() {
    const { canViewShortPan, canSetPin, canShowPan } = this.props;
    const {
      cardholder,
      bulk_issuance_token,
      pin_is_set,
      token,
      pan,
      instrumentType,
      cardholder_token,
      created_time,
      last_modified_time,
      showPan,
    } = this.cardStore;

    const fundingSourcesNames = cardholder && cardholder.fundingSourcesNames;
    const formattedPan = canViewShortPan ? pan : '****************';
    const revealPan = canShowPan ? showPan : null;

    return (
      <Card testId="card_information" contentPadding="0">
        <Text type="h4">Card Information</Text>
        <VSpacer factor={2} />
        <DetailSnippetRow label="PAN">
          <div className={s.flexContainer}>
            <div className={s.pan}>{this.parsedPan(formattedPan, pan)}</div>
            {revealPan && (
              <div className={s.reveal} onClick={revealPan}>
                <Icon type="show" factor={0.5} testId="reveal-pan" />
              </div>
            )}
          </div>
        </DetailSnippetRow>
        <DetailSnippetRow label="Instrument type">{instrumentType}</DetailSnippetRow>
        <DetailSnippetRow label="PIN is set">
          <Flex alignItems="center" flexDirection="row">
            <Text>{pin_is_set ? 'Yes' : 'No'}</Text>
            {canSetPin && (
              <>
                <HSpacer factor={0.5} />
                <Text type="footnote">
                  <VSpacer factor={0.375} />
                  <Link
                    inline
                    inheritParentFont
                    onClick={() => this.setState({ showSetPinModal: true })}
                    testId="set-pin"
                  >
                    Set PIN
                  </Link>
                </Text>
              </>
            )}
          </Flex>
        </DetailSnippetRow>
        <DetailSnippetRow label="Funding">{fundingSourcesNames}</DetailSnippetRow>
        <DetailSnippetRow label="Created time">
          {moment(created_time).format('YYYY-MM-DD hh:mm')}
        </DetailSnippetRow>
        <DetailSnippetRow label="Last modified time">
          {moment(last_modified_time).format('YYYY-MM-DD hh:mm')}
        </DetailSnippetRow>
        <DetailSnippetRow label="Card token">
          <CopyToClipboard overrideValue={token}>
            <span>{token}</span>
          </CopyToClipboard>
        </DetailSnippetRow>
        <DetailSnippetRow label="User token">
          <CopyToClipboard overrideValue={cardholder_token}>
            <span>{cardholder_token}</span>
          </CopyToClipboard>
        </DetailSnippetRow>
        <DetailSnippetRow label="Bulk issue token">
          {bulk_issuance_token ? (
            <CopyToClipboard testId="bulk-issuance-token" overrideValue={bulk_issuance_token}>
              <span>{bulk_issuance_token}</span>
            </CopyToClipboard>
          ) : null}
        </DetailSnippetRow>
        {/* Removed until we have new design for this */}
        {/* {this.renderMetadata()}   */}
      </Card>
    );
  }

  render() {
    const { loading } = this.state;
    return (
      <LoadingOverlay active={loading}>
        <div>
          {this.renderChangeStatusModal()}
          {this.renderAddMetadataModal()}
          {this.renderSetPinModal()}
          {this.renderInfo()}
        </div>
      </LoadingOverlay>
    );
  }
}

export default observer(CardInformationCard);
