import React from 'react';
import { Button, Flex, HSpacer } from '@mqd/volt-base';

/**
 * @description ButtonsGroup is used on each form in the Cardholder Info component.
 * Need to rewrite on component instead of function after refactoring of general form component in VOLT library.
 * see https://marqeta.slack.com/archives/CNUBJQ929/p1604589488451600?thread_ts=1604589488451600&cid=CNUBJQ929
 * @param disabled
 * @param onCancel
 * @param onClick
 * @param buttonType
 * @param transitionText
 * @param testId
 * @returns {JSX}
 */
const buttonsGroup = (
  disabled,
  onCancel,
  onClick,
  buttonType = 'primary',
  transitionText = 'Save',
  testId = 'cardholder-info-submit-button'
) => (
  <>
    <Flex alignItems="baseline">
      <Button type="tertiary" onClick={onCancel} disabled={disabled}>
        Cancel
      </Button>
      <HSpacer />
      <Button submit type={buttonType} disabled={disabled} onClick={onClick} testId={testId}>
        {transitionText}
      </Button>
    </Flex>
  </>
);

export default buttonsGroup;
