import { decorate, observable, action } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';
import KYCResultStore from './KYCResultStore';

class KYCStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
    this.loadFullInfoResult(args);
  }
  // values
  kyc_status: string = 'FAILURE';
  first_name: KYCResultStore = {};
  middle_name: KYCResultStore = {};
  last_name: KYCResultStore = {};
  address1: KYCResultStore = {};
  address2: KYCResultStore = {};
  city: KYCResultStore = {};
  state: KYCResultStore = {};
  zip: KYCResultStore = {};
  country: KYCResultStore = {};
  dob: KYCResultStore = {};
  ssn: KYCResultStore = {};
  ofac: KYCResultStore = {};
  loading: boolean = false;

  loadFullInfoResult(data) {
    this.load(data);
  }
}

decorate(KYCStore, {
  // values
  kyc_status: observable,
  first_name: observable,
  middle_name: observable,
  last_name: observable,
  address1: observable,
  address2: observable,
  city: observable,
  state: observable,
  zip: observable,
  country: observable,
  dob: observable,
  ssn: observable,
  ofac: observable,
  loading: observable,

  // actions
  hydrate: action.bound,
});

export default KYCStore;
