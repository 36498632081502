import React from 'react';
import { Colors, StatusIndicator } from '@mqd/volt-base';

import { sentenceCase } from '../../../shared-utils';

import { TRANSACTION_TYPE_RENAME_MAP } from '../constants';
import { TransactionAmount } from '../components';

export const transformType = ({ type = '' } = {}) =>
  sentenceCase(
    type
      .split('.')
      .map((word) => TRANSACTION_TYPE_RENAME_MAP[word] || word)
      .join(' ')
  );

export const getAmount = (item = {}) => {
  const { type, currency_code, amount } = item;
  const impacted_amount = item?.gpa?.impacted_amount;

  const amountAsFloat = parseFloat(amount);
  const gpaDirection = Math.sign(impacted_amount);
  const isDirectDepositTransaction = /directdeposit./i.test(type);

  // non-dd transaction without a valid impacted_amount
  // https://marqeta.atlassian.net/browse/PS-27918
  if (!gpaDirection && !isDirectDepositTransaction) {
    return (
      <TransactionAmount
        amount={amountAsFloat}
        currencyCode={currency_code}
        color={Colors.blackLighter5}
        lineThrough
      />
    );
  }

  // $0 transactions, non-numeric amounts, or non-dd transactions without a valid impacted_amount
  if (!amountAsFloat || (!gpaDirection && !isDirectDepositTransaction)) {
    return <TransactionAmount amount={amountAsFloat} currencyCode={currency_code} />;
  }

  let signedAmount = amountAsFloat;
  if (isDirectDepositTransaction) {
    if (/directdeposit.debit/i.test(type)) {
      signedAmount = signedAmount * -1;
    }
  } else {
    signedAmount = signedAmount * gpaDirection;
  }

  if (signedAmount > 0) {
    return (
      <TransactionAmount
        amount={signedAmount}
        currencyCode={currency_code}
        color={Colors.aaGreen}
      />
    );
  } else {
    return <TransactionAmount amount={signedAmount} currencyCode={currency_code} />;
  }
};

export const getTotalAmount = (transactions = []) => {
  const total = transactions.reduce((acc, transaction) => {
    const { type, gpa = {} } = transaction;
    const { impacted_amount } = gpa;
    const amount = isNaN(impacted_amount) ? 0 : Number(impacted_amount);
    if (/directdeposit.debit/i.test(type)) return acc - amount;
    return acc + amount;
  }, 0);
  return total;
};

export const isAchTransaction = (transaction = {}) =>
  transaction.type && transaction.type.includes('ach.');

const getDescription = (item = {}) => {
  const { direct_deposit, card_acceptor = {} } = item;
  if (isAchTransaction(item)) {
    const { bankTransfer } = item;
    return bankTransfer.statement_descriptor;
  }

  if (direct_deposit) {
    return [direct_deposit.company_name, direct_deposit.company_entry_description].join(' ').trim();
  }

  return card_acceptor.name;
};

const buildLocation = (city, state) => (city && state ? `${city}, ${state}` : city || state);
export const getLocation = (item = {}, stateCodes = []) => {
  const { city, state } = item.card_acceptor || {};

  let location = buildLocation(city, state);
  if (state && Number.isInteger(Number(state)) && Array.isArray(stateCodes)) {
    const { state_name, stusab } = stateCodes.find((stateCode) => stateCode.state === state) || {};
    location = buildLocation(state_name, stusab);
  }

  return {
    location: {
      val: 'location',
      renderer: () => location || 'None',
    },
  };
};

export const getRow = (item = {}, stateCodes) => {
  const {
    amount,
    state,
    created_time,
    gpa,
    currency_code,
    token,
    direct_deposit,
    card_acceptor,
    response,
  } = item;
  const { mcc } = card_acceptor || {};
  const { standard_entry_class_code, token: direct_deposit_token } = direct_deposit || {};
  const { impacted_amount } = gpa || {};
  const type = sentenceCase(
    (item.type || '')
      .split('.')
      .map((word) => TRANSACTION_TYPE_RENAME_MAP[word] || word)
      .join(' ')
  );

  return {
    token,
    created_time,
    description: getDescription(item),
    state: {
      val: state,
      renderer: () => <StatusIndicator withIcon status={state} type="pill-footnote" />,
    },
    ...getLocation(item, stateCodes),
    code: mcc || standard_entry_class_code,
    type,
    amount: {
      val: amount,
      renderer: () => getAmount(item),
    },
    response,
    gpa,
    currency_code,
    impacted_amount: Number(impacted_amount),
    direct_deposit_token,
  };
};

export const getRows = (transactions = [], stateCodes) =>
  transactions.map((transaction) => getRow(transaction, stateCodes));
