import { replace, toUpper, type } from 'ramda';
import omitDeep from '../../../utils/omitDeep';

export const capitalizeFirst = (str) => {
  return type(str) === 'String' ? replace(/^./, toUpper, str) : str;
};

export const prepareJsonAsText = (data) => {
  return data ? JSON.stringify(omitDeep(['__typename'], data), null, 2) : null;
};

export const transactionTypeData = (type) => {
  const nonAchTransaction = !(type && type.includes('ach.'));
  if (nonAchTransaction) {
    return [type, nonAchTransaction];
  }
  const [ach, ...rest] = type.split('.');
  return [`${ach.toUpperCase()} ${rest.join(' ')}`, nonAchTransaction];
};
