import React, { useMemo, useState } from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { Link, LoadingOverlay, Text, VSpacer } from '@mqd/volt-base';
import { Pagination, Table, TableFilters, TableHeader } from '@mq/voltron-table';

import { EXPORT_TYPES } from '../../utilities/dictionary/index.js';
import ErrorLoading from '../../components/ErrorLoading/index.js';
import useQueryUrlParams from '../../../../utils/use-query-params-url/useQueryParamsToUrl.js';
import userStore from '../../../../stores/UserStore.js';

import ExportStatusIndicator from './ExportStatusIndicator.js';
import { GET_EXPORTED_DATA_STATUSES, GET_USER_EMAILS, GET_EXPORT_DOWNLOAD_URL } from './query.js';
import { rowToFilename } from './utils.js';
import css from './ExportQueue.module.css';

const PAGE_SIZE = 25;
const urlKeys = ['user_emails'];
const EXPORT_TIMEOUT = 15 * 60 * 1000; // 15 minutes in ms

const Filters = ({ variables, refetch, userEmailsResponse }) => {
  const emailSuggestions = useMemo(() => {
    const emailSet = new Set();
    for (const status of userEmailsResponse.data?.exportedDataStatuses || []) {
      emailSet.add(status.user_email);
    }
    const sortedEmails = [...emailSet].sort();
    const suggestions = [];
    if (userStore.email) {
      suggestions.push(userStore.email);
    }
    for (const email of sortedEmails) {
      if (!userStore.email || email !== userStore.email) {
        suggestions.push(email);
      }
    }
    return suggestions;
  }, [userEmailsResponse.data]);

  const [userEmails, setUserEmails] = useState(variables.user_emails ?? []);
  const filterOptions = useMemo(
    () => [
      {
        type: 'multiselect',
        selected: userEmails,
        label: 'Requester',
        placeholder: 'Select',
        onOptionClick: (option) => {
          setUserEmails((emails) => {
            if (emails.includes(option)) {
              return emails.filter((email) => email !== option);
            } else {
              return [...emails, option];
            }
          });
        },
        onBulkToggleClick: (checked) => {
          if (checked) {
            setUserEmails(emailSuggestions);
          } else {
            setUserEmails([]);
          }
        },
        options: emailSuggestions,
      },
    ],
    [emailSuggestions, userEmails]
  );

  const handleSearch = () => {
    refetch({
      user_emails: userEmails.length === 0 ? null : userEmails,
    });
  };
  const handleClear = () => {
    setUserEmails([]);
    refetch({
      user_emails: null,
    });
  };

  return (
    <TableFilters
      filterOptions={filterOptions}
      showActiveFilterCountOnMount
      handleSearch={handleSearch}
      handleClear={handleClear}
      // we specify this to be false because otherwise, handleSearch
      // gets called before the filters can be properly cleared,
      // thus using the old value of the filters to refetch
      fetchOnClear={false}
    />
  );
};

const usePaginate = (data) => {
  const [startIndex, setStartIndex] = useState(0);
  const endIndex = startIndex + PAGE_SIZE;
  const items = useMemo(() => data.slice(startIndex, startIndex + PAGE_SIZE), [data, startIndex]);
  return {
    items,
    startIndex,
    endIndex,
    hasMore: endIndex < data.length,
    hasLess: startIndex > 0,
    goNext: () => setStartIndex((idx) => (idx + PAGE_SIZE < data.length ? idx + PAGE_SIZE : idx)),
    goPrevious: () => setStartIndex((idx) => Math.max(0, idx - PAGE_SIZE)),
  };
};

const ExportedData = ({ dynamicHeight }) => {
  const gqlClient = useApolloClient();
  const { data, loading, error, refetch, variables } = useQueryUrlParams(
    GET_EXPORTED_DATA_STATUSES,
    {
      notifyOnNetworkStatusChange: true, // show loading screen on refresh
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    urlKeys
  );
  const hasData = Boolean(data?.exportedDataStatuses?.length);

  const userEmailsResponse = useQuery(GET_USER_EMAILS, { fetchPolicy: 'no-cache' });

  const refresh = () => {
    refetch();
    userEmailsResponse.refetch();
  };

  const sortedItems = useMemo(
    () =>
      data?.exportedDataStatuses
        ? data.exportedDataStatuses.sort(
            (a, b) => new Date(b.date_requested).getTime() - new Date(a.date_requested).getTime()
          )
        : [],
    [data]
  );

  const { items, startIndex, hasMore, hasLess, goNext, goPrevious } = usePaginate(sortedItems);

  const columns = useMemo(() => {
    const now = new Date();
    return [
      {
        header: 'File status',
        accessor: 'status',
        key: 'status',
        renderer: ({ cellValue, row }) => {
          // if the export hasn't finished after 15 minutes, kill it
          if (cellValue === 'IN_PROGRESS') {
            const date_requested = new Date(row.date_requested);
            if (now - date_requested >= EXPORT_TIMEOUT) {
              return <ExportStatusIndicator status={'ERROR'} />;
            }
          }
          return <ExportStatusIndicator status={cellValue} />;
        },
      },
      {
        header: 'File name',
        accessor: 'filename',
        key: 'filename',
        renderer: ({ row }) => rowToFilename(row),
      },
      {
        header: 'Export type',
        accessor: 'export_type',
        key: 'export_type',
        renderer: ({ cellValue }) => EXPORT_TYPES[cellValue],
      },
      {
        header: 'File download',
        accessor: 'filename',
        key: 'download',
        renderer: ({ row }) => {
          return (
            row.status === 'COMPLETED' && (
              <Link
                href="#"
                type="primary"
                iconType="download"
                iconDirection="right"
                onClick={async () => {
                  const response = await gqlClient.query({
                    query: GET_EXPORT_DOWNLOAD_URL,
                    variables: {
                      filename: row.filename,
                      download_name: rowToFilename(row),
                    },
                    fetchPolicy: 'no-cache',
                  });
                  downloadURL(response.data.exportDownloadUrl);
                }}
              >
                Download
              </Link>
            )
          );
        },
      },
      {
        header: 'Requester',
        accessor: 'user_email',
        key: 'user_email',
      },
    ];
  }, [gqlClient]);

  return (
    <main>
      <Text type="h3">Export Queue</Text>
      <VSpacer />
      <div className={css.descriptionText}>
        <Text>
          Table of exported settlement data from the last 24 hours. Large data sets run the risk of
          timing out after 15 minutes. Apply filters and export smaller data sets to avoid long
          waits and timeout failure.{' '}
          <Link inline inheritParentFont iconType="refresh12" onClick={refresh}>
            Refresh
          </Link>
        </Text>
      </div>
      <VSpacer factor={3} />
      <TableHeader>
        <Filters variables={variables} refetch={refetch} userEmailsResponse={userEmailsResponse} />
      </TableHeader>
      <VSpacer factor={2} />
      <LoadingOverlay active={loading}>
        {error && !hasData ? (
          <ErrorLoading />
        ) : (
          <>
            <Table loading={loading} data={items} columns={columns} height={dynamicHeight} />
            <VSpacer factor={1} />
            <Pagination
              className={css.pagination}
              startIndex={startIndex}
              isMore={hasMore}
              isLess={hasLess}
              handleNextClick={goNext}
              handlePreviousClick={goPrevious}
            />
          </>
        )}
      </LoadingOverlay>
    </main>
  );
};

function downloadURL(href, name = '') {
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = href;
  tempLink.setAttribute('download', name);

  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
    tempLink.setAttribute('rel', 'noopener noreferrer');
  }

  if (document && document.body) {
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
  }
}

export default ExportedData;
