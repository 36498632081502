// @flow
import { decorate, observable, action, reaction, computed } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';
import CardProductStore from './CardProductStore';
import CardholderStore from './CardholderStore';
import VelocityControlStore from './VelocityControlStore';

class VelocityControlsFormStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);

    reaction(
      () => this.presetSingleUse || this.singleUseSelected,
      this.autoSetSingleUsageVelocityWindow
    );
  }

  // values
  cardholder: CardholderStore = new CardholderStore({ gqlClient: this.gqlClient });
  cardProduct: CardProductStore = new CardProductStore({ gqlClient: this.gqlClient });
  singleUseSelected: Boolean = false;
  velocityControls: Array<VelocityControlStore> = [];
  amountOnlyVelocityControls: Array<VelocityControlStore> = [];

  // actions
  addNewVelocityControl(amountOnly = false) {
    const newControl = this.newVelocityControl({
      usage_limit: amountOnly ? '1' : '',
      velocity_window: amountOnly ? 'LIFETIME' : 'MONTH',
    });
    const listType = amountOnly ? 'amountOnlyVelocityControls' : 'velocityControls';

    if (this[listType].length) {
      this[listType].push(newControl);
    } else {
      this[listType] = [newControl];
    }

    this.validateVelocityControlMaximums();
  }

  removeVelocityControl(index) {
    this.targetValocityControls.splice(index, 1);
  }

  duplicateVelocityControl(velocityControl = {}) {
    const { token, ...remainParams } = velocityControl;
    this.targetValocityControls.push(this.newVelocityControl(remainParams));
  }

  validateVelocityControlMaximums() {
    const allNewControls = [...this.velocityControls, ...this.amountOnlyVelocityControls];
    allNewControls.map((control = {}) => {
      const { velocity_window, number_of_days, setAttr } = control;
      const maxLimits = this.maxVelocityControls[velocity_window || number_of_days];
      const { amount_limit, usage_limit } = maxLimits || {};
      if (amount_limit) setAttr('maxAmountLimit', amount_limit);
      if (usage_limit) setAttr('maxUsageLimit', usage_limit);
    });
  }

  autoSetSingleUsageVelocityWindow() {
    if (this.presetSingleUse || this.singleUseSelected) {
      this.velocityControls.forEach((control = {}) => {
        const { setAttr } = control;
        setAttr && setAttr('velocity_window', 'LIFETIME');
        setAttr && setAttr('usage_limit', '1');
      });
      this.validateVelocityControlMaximums();
    }
  }

  setControlValidities() {
    this.velocityControls.map((control) => {
      const { isValidWithRequiredUsage, amount_limit, usage_limit, velocity_window, setAttr } =
        control;

      if (amount_limit || usage_limit || velocity_window !== 'MONTH') {
        setAttr('hasError', !isValidWithRequiredUsage);
      }
    });

    this.amountOnlyVelocityControls.map((control) => {
      const { setAttr, isValid, amount_limit, velocity_window } = control;
      if (amount_limit || velocity_window !== 'MONTH') {
        setAttr('hasError', !isValid);
      }
    });
  }

  // computed
  get velocityControlsAreValid() {
    const velocityControlValidity = this.velocityControls.every((control) => {
      const { isValidWithRequiredUsage, amount_limit, usage_limit, velocity_window } = control;
      return !amount_limit && !usage_limit ? false : isValidWithRequiredUsage;
    });

    const velocityControlTypeValidity = this.targetValocityControls.every((control) => {
      const { invalidIncludeTypeSelection } = control;
      return !Boolean(invalidIncludeTypeSelection);
    });

    const amountOnlyControlValidity = this.amountOnlyVelocityControls.every((control) => {
      const { isValid, amount_limit, velocity_window } = control;
      return amount_limit ? true : isValid;
    });

    return (
      (this.singleUseSelected ? amountOnlyControlValidity : velocityControlValidity) &&
      velocityControlTypeValidity
    );
  }

  get velocityControlPayload() {
    // we are using different array of velocity controls to determine what type of velocity we are trying to create cards with
    const selectedVelocityControls = this.singleUseSelected
      ? this.amountOnlyVelocityControls
      : this.velocityControls;

    const payload = selectedVelocityControls.map((control) => {
      const {
        amount_limit,
        usage_limit,
        velocity_window,
        currency_code,
        approvals_only,
        include_purchases,
        include_withdrawals,
        include_transfers,
        include_cashback,
        include_credits,
      } = control;

      // we are only returning velcity controls that are valid
      if (amount_limit && usage_limit && velocity_window && currency_code) {
        return {
          amount_limit: parseFloat(amount_limit),
          usage_limit: parseInt(usage_limit),
          velocity_window,
          currency_code,
          approvals_only,
          include_purchases,
          include_withdrawals,
          include_transfers,
          include_cashback,
          include_credits,
        };
      }
    });

    return payload.filter((control) => control);
  }

  get velocityControlPayloads() {
    return this.velocityControlPayload.filter((control) => control);
  }

  get allExistingVelocityControls() {
    const { velocity_controls: cardholderControls = [] } = this.cardholder || {};
    const { velocity_controls: cardProductControls = [] } = this.cardProduct || {};
    return [...cardholderControls, ...cardProductControls];
  }

  get maxVelocityControls() {
    const max = {};
    this.allExistingVelocityControls.forEach((control) => {
      const { amount_limit, usage_limit, velocity_window, number_of_days } = control;
      const window = velocity_window || number_of_days;

      if (!max[window]) {
        max[window] = { amount_limit, usage_limit };
        return;
      }

      const maxAmount = max[window].amount_limit;
      const maxUsage = max[window].usage_limit;
      if (maxAmount > amount_limit) {
        max[window].amount_limit = amount_limit;
      }
      if (maxUsage > usage_limit) {
        max[window].usage_limit = usage_limit;
      }
    });

    return max;
  }

  get sortedMaxVelocityControlKeys() {
    const windows = ['LIFETIME', 'MONTH', 'WEEK', 'DAY', 'TRANSACTION'];
    return Object.keys(this.filtereddMaxVelocityControls).sort((a, b) => {
      if (windows.includes(a) && windows.includes(b)) {
        return windows.indexOf(a) - windows.indexOf(b);
      } else if (windows.includes(a)) {
        return -1;
      } else if (windows.includes(b)) {
        return 1;
      } else {
        return b - a;
      }
    });
  }

  get filtereddMaxVelocityControls() {
    if (this.singleUseSelected || this.presetSingleUse) {
      const { LIFETIME } = this.maxVelocityControls;
      return LIFETIME ? { LIFETIME } : {};
    } else {
      return this.maxVelocityControls;
    }
  }

  get formattedAmountAndUsageLimit() {
    if (!this.sortedMaxVelocityControlKeys.length) return;

    const formattedMaxes = this.sortedMaxVelocityControlKeys.map((maxKey) => {
      const control = this.maxVelocityControlPerWindow(maxKey);
      return control.formattedUsageLimit || control.formattedVelocityControl;
    });

    return `Pre-configured: ${formattedMaxes.filter((m) => m).join(', ')}`;
  }

  get formattedAmountLimit() {
    if (!this.sortedMaxVelocityControlKeys.length) return;
    const formattedMaxes = this.sortedMaxVelocityControlKeys.map((maxKey) => {
      const control = this.maxVelocityControlPerWindow(maxKey);
      return control.formattedVelocityControl;
    });

    return `Pre-configured: ${formattedMaxes.filter((m) => m).join(', ')}`;
  }

  get presetSingleUse() {
    const { hasSingleUseVelocityControl: singleUseCardholder } = this.cardholder;
    const { hasSingleUseVelocityControl: singleUseCardProduct } = this.cardProduct;
    return singleUseCardholder || singleUseCardProduct;
  }

  get targetValocityControls() {
    // this is returned based on if singleUseSelected is true/false
    // the user has to choose either type of velocity control to submit not both
    return this.singleUseSelected ? this.amountOnlyVelocityControls : this.velocityControls;
  }

  // helpers
  maxVelocityControlPerWindow(maxWindow) {
    const window =
      parseInt(maxWindow) >= 0 ? { number_of_days: maxWindow } : { velocity_window: maxWindow };
    return this.newVelocityControl({
      ...this.maxVelocityControls[maxWindow],
      ...window,
    });
  }

  newVelocityControl(args = {}) {
    return new VelocityControlStore({
      currency_code: 'USD',
      velocity_window: 'MONTH',
      ...args,
    });
  }
}

decorate(VelocityControlsFormStore, {
  // observables
  cardholder: observable,
  cardProduct: observable,
  velocityControls: observable,
  amountOnlyVelocityControls: observable,
  singleUseSelected: observable,

  // actions
  addNewVelocityControl: action.bound,
  removeVelocityControl: action.bound,
  duplicateVelocityControl: action.bound,
  validateVelocityControlMaximums: action.bound,
  autoSetSingleUsageVelocityWindow: action.bound,
  setControlValidities: action.bound,

  // computed
  velocityControlPayloads: computed,
  velocityControlsAreValid: computed,
  allExistingVelocityControls: computed,
  maxVelocityControls: computed,
  filtereddMaxVelocityControls: computed,
  sortedMaxVelocityControlKeys: computed,
  formattedAmountAndUsageLimit: computed,
  formattedAmountLimit: computed,
  presetSingleUse: computed,
  velocityControlPayload: computed,
  targetValocityControls: computed,

  // helpers
  maxVelocityControlPerWindow: action.bound,
  newVelocityControl: action.bound,
});

export default VelocityControlsFormStore;
