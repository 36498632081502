import React from 'react';
import { path } from 'ramda';
import { useQuery } from '@apollo/react-hooks';
import { Activity, LoadingOverlay, Breadcrumbs, VSpacer } from '@mqd/volt-base';

import { getActivityLogsQuery } from '../../queries.js';
import { goToProducts } from 'views/credit/redirect-helpers';
import CREDIT_ROUTES from 'views/credit/routes.js';

import errorModalStore from '../ErrorModal/ErrorModalStore.js';

export default function ActivityPage({ token }) {
  const { data, loading, refetch } = useQuery(getActivityLogsQuery, {
    variables: { token, entityType: 'CreditProduct' },
    onError: () => errorModalStore.showAPIErrorModal(refetch, goToProducts),
    fetchPolicy: 'no-cache',
  });

  const activityLogs = path(['activityLogs', 'data'], data);

  return (
    <LoadingOverlay active={loading} transparentBackground>
      <Breadcrumbs
        crumbs={[
          {
            children: 'Credit products',
            href: CREDIT_ROUTES.PRODUCTS,
          },
          {
            children: 'Review credit product',
          },
        ]}
      />
      <VSpacer factor={2} />

      <Activity activities={activityLogs} hideInput showAllActivities />
    </LoadingOverlay>
  );
}
