import React from 'react';
import {
  Form,
  FormLabel,
  Input,
  InputSelectGroup,
  CheckboxGroup,
  Checkbox,
  VSpacer,
  Toggle,
  RadioGroup,
  FormChangeSummary,
} from '@mqd/volt-base';
import CurrencySelect from '../currency-select/CurrencySelect.js';
import {
  ATM_WITHDRAWAL,
  AUTHORIZATION,
  PINDEBIT,
  DOMESTIC_ENABLED,
  INTERNATIONAL_ENABLED,
} from './constants.js';

export default function FeesActionGroupTemplate({
  formState,
  children,
  handleFormChange,
  compareState,
  status,
}) {
  return (
    <Form
      initialState={formState.new ? { ...formState, enabled: true } : formState}
      onFormChange={handleFormChange}
    >
      <FormChangeSummary compareState={compareState} status={status} formType={'actionCard'}>
        <Input name="name" label="Name" testId="fee-action-card-name-input" />
        <VSpacer factor={3} />
        <Toggle name="enabled">Enabled</Toggle>
        <VSpacer factor={3} />
        <Input name="token" label="Token" testId="fee-action-card-token-input" />
        <VSpacer factor={3} />
        <Input name="tags" label="Tags" testId="fee-action-card-tags-input" />
        <VSpacer factor={3} />
        <section aria-labelledby={`${formState?.token}-transaction_type`}>
          <RadioGroup
            name="transaction_type"
            label="Transaction type"
            id={`${formState?.token}-transaction_type`}
            groupItems={transactionTypesRadioGroupItems}
            value={formState?.transaction_type}
          />
        </section>
        <VSpacer factor={3} />
        <section aria-labelledby={`${formState?.token}-real-time-assessment`}>
          <CheckboxGroup version="v2" name="feesActionGroupPreKycControls">
            <FormLabel>Pre KYC controls</FormLabel>
            <Checkbox label="Assess on domestic" name={DOMESTIC_ENABLED} />
            <VSpacer />
            <Checkbox label="Assess on international" name={INTERNATIONAL_ENABLED} />
            <VSpacer />
          </CheckboxGroup>
        </section>
        <VSpacer factor={3} />
        <InputSelectGroup label="Amount" id={`${formState?.token}-amount`}>
          <Input
            name="amount"
            id={`${formState?.token}-amount`}
            testId="fee-action-card-amount-input"
          />
          <CurrencySelect
            name="currency_code"
            placeholder="Currency"
            testId="general-information-currency-code"
            onChange={({ code }) => code}
          />
        </InputSelectGroup>
        {children}
      </FormChangeSummary>
    </Form>
  );
}

FeesActionGroupTemplate.properties = [
  'amount',
  'enabled',
  'name',
  'domestic_enabled',
  'international_enabled',
  'tags',
  'token',
  'transaction_type',
  'currency_code',
];

const transactionTypesRadioGroupItems = [
  {
    label: 'ATM withdrawal',
    value: ATM_WITHDRAWAL,
  },
  {
    label: 'Authorization',
    value: AUTHORIZATION,
  },
  {
    label: 'PIN debit',
    value: PINDEBIT,
  },
];
