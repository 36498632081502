export const checkForChanges = (originalObject, state) => {
  var valueChanged = false;
  var statusChanged = false;
  var validatedFieldChanged = false;
  var allFieldsSuccess = true;
  var popModal = false;
  Object.keys(originalObject).forEach((key) => {
    if (!originalObject[key]) {
      originalObject[key] = { value: '', status: 'PENDING' };
    }
    const keyValueChanged = originalObject[key].value !== state[key].value;
    const keyStatusChanged = originalObject[key].status !== state[key].status;
    const statusAlwaysSuccess =
      originalObject[key].status === 'SUCCESS' && state[key].status === 'SUCCESS';
    const fieldIsFailed = state[key].status !== 'SUCCESS';
    if (keyValueChanged) {
      valueChanged = true;
    }
    if (keyStatusChanged) {
      statusChanged = true;
    }
    if (
      keyValueChanged &&
      statusAlwaysSuccess &&
      key !== 'attester_title' &&
      key !== 'business_name_dba'
    ) {
      validatedFieldChanged = true;
    }
    if (fieldIsFailed) {
      allFieldsSuccess = false;
    }
  });
  if (statusChanged || validatedFieldChanged || allFieldsSuccess) {
    popModal = true;
  }
  return {
    popModal: popModal,
    valueChanged: valueChanged,
    statusChanged: statusChanged,
    validatedFieldChanged: validatedFieldChanged,
    allFieldsSuccess: allFieldsSuccess,
  };
};

export const transformEntityString = (title) => {
  if (typeof title !== 'string') return '';
  try {
    return title
      .toLowerCase()
      .replace('business_name_dba', 'Doing Business As')
      .replace('business', 'business details')
      .replace('proprietor', 'controlling officer')
      .replace('owner', 'owner ')
      .replace(/_/g, ' ')
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
      .replace('Kyc', 'KYB')
      .replace('Dob', 'Date of Birth')
      .replace('Ssn', 'SSN')
      .replace('Ofac', 'OFAC')
      .replace('Ein', 'Federal Tax ID/EIN')
      .replace('Sanctions', 'Sanctions List');
  } catch (error) {
    console.error(error);
    return '';
  }
};

export const getMultiFieldStatus = (object) => {
  var status = '';
  Object.keys(object).forEach((key) => {
    if (object[key]) {
      if (object[key].status === 'FAILURE') {
        status = 'FAILURE';
      } else if (object[key].status === 'PENDING' && status !== 'FAILURE') {
        status = 'PENDING';
      } else if (object[key].status === 'SUCCESS' && status !== 'FAILURE' && status !== 'PENDING') {
        status = 'SUCCESS';
      }
    }
  });
  return status === '' ? 'PENDING' : status;
};

export const getRelevantFields = (fields) => {
  if (fields && typeof fields === 'object') {
    if (fields.address) {
      fields.address.status = getMultiFieldStatus(getAddressObject(fields));
    }
    if (fields.first_name) {
      fields.first_name.status = getMultiFieldStatus(getNameObject(fields));
    }
    const relevantFields = [
      'name',
      'address',
      'ein',
      'ofac',
      'sanctions',
      'first_name',
      'ssn',
      'dob',
      'ofac',
      'attester_name',
      'attestation_date',
    ];
    return Object.keys(fields).filter((key) => relevantFields.includes(key));
  } else {
    return [];
  }
};

const fillerObj = { value: '', status: 'PENDING' };
export const getAddressObject = (entity) => {
  return {
    address: entity && entity.address ? entity.address : fillerObj,
    address2: entity && entity.address2 ? entity.address2 : fillerObj,
    city: entity && entity.city ? entity.city : fillerObj,
    state: entity && entity.state ? entity.state : fillerObj,
    zip: entity && entity.zip ? entity.zip : fillerObj,
    country: entity && entity.country ? entity.country : fillerObj,
  };
};

export const getNameObject = (entity) => {
  return {
    first_name: entity && entity.first_name ? entity.first_name : fillerObj,
    middle_name: entity && entity.middle_name ? entity.middle_name : fillerObj,
    last_name: entity && entity.last_name ? entity.last_name : fillerObj,
  };
};
