import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Table, TableHeader } from '@mq/voltron-table';
import { containerHeightHOC } from '@mq/voltron-parent';
import {
  Alert,
  Button,
  Drawer,
  ErrorModal,
  Flex,
  FormError,
  FormLabel,
  HSpacer,
  Icon,
  LoadingOverlay,
  Modal,
  Select,
  Text,
  Textarea,
  ToastAlert,
  VSpacer,
} from '@mqd/volt-base';
import moment from 'moment';

import { useProgramSelectorStateContext } from '../../../../components/program-selector/context.js';
import { useRouterGoContext } from '../../../../components/router-go/context.js';
import routerStore from '../../../../stores/router/RouterStore.js';
import useQueryUrlParams from '../../../../utils/use-query-params-url/useQueryParamsToUrl.js';
import number from 'views/fds/utilities/number/index.js';
import getDefaultFormattingOptions from 'views/fds/utilities/number/get-default-formatting-options.js';

import {
  SELECT_ACTION,
  SELECT_REASON,
  ADJUSTMENT_REASONS_LABELS,
} from 'views/fds/components/AdjustmentAction/constants.js';
import * as adjustmentActionReducer from 'views/fds/components/AdjustmentAction/reducer.js';

import FlexContainer from '../../components/FlexContainer/FlexContainer.js';
import DatePicker from '../../components/DatePicker/DatePicker.js';
import ExceptionFlowBreadcrumbs from '../../components/ExceptionFlowBreadcrumbs/ExceptionFlowBreadcrumbs.js';
import ErrorLoading from '../../components/ErrorLoading/index.js';
import { constantToDashCase, dashCaseToConstant } from '../../utilities/string/index.js';
import {
  EXCEPTION_TYPES,
  NETWORKS,
  PROGRAM_TYPES,
  SUBNETWORKS,
} from '../../utilities/dictionary/index.js';
import transformMaskedPan from '../../utilities/transform-masked-pan/index.js';
import calculateAndFormatScaledDecimal from '../../utilities/number/calculate-and-format-scaled-decimal.js';
import { PAGE_SIZE } from '../../utilities/config.js';
import { GET_UNMATCHED_QUEUE } from '../../queries/getExceptionQueue/query.js';
import useRowSelector from '../../hooks/useRowSelector.js';
import useTableColumns from '../../hooks/useTableColumns.js';

import Pagination from './components/Pagination.js';
import TableFilters from './components/Filters.js';
import { EXPORT_EXCEPTION_QUEUE } from '../../queries/exportExceptionQueue/query.js';
import { BULK_SUBMIT_EXCEPTIONS } from '../../queries/bulkSubmitExceptions/query.js';
import { COLUMNS_LOCAL_STORAGE_KEY } from './constants.js';
import s from './ExceptionQueue.module.css';
import {
  ADJUST_TRANSACTION,
  MANUAL_TRANSACTION,
  WRITE_OFF_TRANSACTION,
  INVALID_TRANSACTION,
  EXCLUDE_TRANSACTION,
} from 'views/fds/utilities/dictionary/adjustment-actions.js';
import { OTHER } from 'views/fds/utilities/dictionary/adjustment-reasons.js';

const NO_MODAL = 0;
const EXPORT_SUCCESS_MODAL = 1;
const EXPORT_ERROR_MODAL = 2;

const onError = () => {};
const urlKeys = [
  'end_settlement_date',
  'start_settlement_date',
  'program_short_codes',
  'page_token',
  'program_types',
  'settlement_currencies',
  'dna_bank_tokens',
  'ica_values',
  'originator',
];

const BULK_EDITABLE_TYPES = new Set(['FAILED_TO_POST', 'OVERPOSTED', 'OTHER']);

function TableActions({ exceptionType, numSelected, submitTransactions, getTotalVariance }) {
  const [drawerActive, setDrawerActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [note, setNote] = useState('');

  const [adjustmentActionState, adjustmentActionDispatch] = useReducer(
    adjustmentActionReducer.reducer,
    {
      ...adjustmentActionReducer.initialState,
      selectedAction: ADJUST_TRANSACTION,
      reasons: ADJUSTMENT_REASONS_LABELS[ADJUST_TRANSACTION],
      selectedReason: exceptionType === OTHER ? '' : exceptionType,
    }
  );

  const permittedActions = useMemo(
    () =>
      adjustmentActionState.actions.filter(({ type }) =>
        [
          ADJUST_TRANSACTION,
          WRITE_OFF_TRANSACTION,
          MANUAL_TRANSACTION,
          INVALID_TRANSACTION,
          EXCLUDE_TRANSACTION,
        ].includes(type)
      ),
    [adjustmentActionState.actions]
  );
  const permittedReasons = useMemo(() => {
    let reasons = adjustmentActionState.reasons;
    if (adjustmentActionState.selectedAction === ADJUST_TRANSACTION && exceptionType !== OTHER) {
      reasons = reasons.filter((option) => option.type === exceptionType);
    }
    return reasons;
  }, [exceptionType, adjustmentActionState.reasons, adjustmentActionState.selectedAction]);

  const validateForm = useCallback(() => {
    if (adjustmentActionState.selectedAction === '') {
      setError('Please select an action.');
      return false;
    }
    if (adjustmentActionState.selectedReason === '') {
      setError('Please select a reason.');
      return false;
    }
    if (note.length > 1000) {
      setError('Maximum note length is 1000 characters.');
      return false;
    }

    setError('');
    return true;
  }, [adjustmentActionState.selectedAction, adjustmentActionState.selectedReason, note]);

  const handleSubmit = useCallback(
    async (params) => {
      setLoading(true);
      if (validateForm() === true) {
        try {
          await submitTransactions(params);
          setDrawerActive(false);
        } catch {
          // Empty catch block here to prevent unhandled exception.
          // This could occur if there was a GQL server error.
          // We want to keep the drawer active in that case.
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    },
    [submitTransactions, validateForm]
  );

  return (
    <>
      <Flex flexDirection="row" alignItems="center">
        {numSelected > 0 && (
          <>
            <Text>{numSelected} selected</Text>
            <HSpacer factor={2} />
          </>
        )}
        <Button type="tertiary" onClick={() => setDrawerActive(true)} disabled={numSelected === 0}>
          Take action
        </Button>
      </Flex>
      {numSelected > 0 && (
        <Drawer
          isOpened={drawerActive}
          orientation="right"
          backdrop={false}
          closeDrawer={() => setDrawerActive(false)}
          header={
            <div className={s.drawerHeader}>
              <Text type="h4">Bulk action</Text>
              <Icon type="close" onClick={() => setDrawerActive(false)} />
            </div>
          }
          footer={
            <div className={s.drawerFooter}>
              <Button type="tertiary" onClick={() => setDrawerActive(!drawerActive)}>
                Cancel
              </Button>
              <Button
                type="primary"
                loading={loading}
                onClick={() =>
                  handleSubmit({
                    action: adjustmentActionState.selectedAction,
                    reason: adjustmentActionState.selectedReason,
                    note,
                  })
                }
              >
                Take action ({numSelected})
              </Button>
            </div>
          }
        >
          <Alert type="info">
            <Text>Hash IDs selected: {numSelected}</Text>
            <Text>Total submitted variance: {getTotalVariance()}</Text>
          </Alert>
          <VSpacer factor={2} />
          <Text>Submitting hash IDs will send them to the approval queue for further review.</Text>
          <VSpacer factor={2} />
          <FormLabel size="small">Type of action</FormLabel>
          <Select
            onChange={(option) =>
              adjustmentActionDispatch({ type: SELECT_ACTION, payload: option.id })
            }
            options={permittedActions.map((option) => ({
              id: option.type,
              val: option.type,
              render: option.label,
            }))}
            value={adjustmentActionState.selectedAction}
            placeholder={'Select action'}
          />
          <VSpacer factor={3} />
          <FormLabel size="small">Reason</FormLabel>
          <Select
            onChange={(option) =>
              adjustmentActionDispatch({ type: SELECT_REASON, payload: option.id })
            }
            options={permittedReasons.map((option) => ({
              id: option.type,
              val: option.type,
              render: option.label,
            }))}
            value={adjustmentActionState.selectedReason}
            placeholder={'Select reason'}
          />
          <VSpacer factor={3} />
          <Textarea
            label="Note"
            value={note}
            onChange={(e) => setNote(e.target.value)}
            maxLength={1000}
            noResize
            labelOptional
            rows={3}
            testId="exception-bulk-action-note"
          />
          {error && <FormError>{error}</FormError>}
        </Drawer>
      )}
    </>
  );
}

function ExceptionQueue({ dynamicHeight }) {
  // Determine inputs.
  const { location } = window;
  const urlParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const subnetwork = urlParams.get('subnetwork');
  const network = urlParams.get('network');
  const originator = urlParams.get('originator');
  const { pathParams } = routerStore;
  const go = useRouterGoContext();
  const exceptionType = useMemo(() => dashCaseToConstant(pathParams.type), [pathParams.type]);

  // Get toast message.
  const [toastMessage, setToastMessage] = useState(window.history.state?.toastMessage || null);

  useEffect(() => {
    if (window.history.state?.toastMessage) {
      window.history.replaceState(
        {
          ...window.history.state,
          toastMessage: null,
        },
        ''
      );
    }
  }, []);

  // Fetch GraphQL data.
  const {
    data,
    loading: loadingQuery,
    error: loadingError,
    refetch: refetchOrig,
    variables: queryVariables,
  } = useQueryUrlParams(
    GET_UNMATCHED_QUEUE,
    {
      notifyOnNetworkStatusChange: true, // show loading screen on refresh
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: {
        originator: originator,
        subnetwork,
        exception_type: exceptionType,
        page_size: PAGE_SIZE,
      },
      onError,
    },
    urlKeys
  );
  const refetch = useCallback(
    (variables) => {
      if (!variables) return refetchOrig();

      // Fill missing variables with their current values.
      const fullVariables = {};
      for (const key of urlKeys) {
        if (key in variables) {
          fullVariables[key] = variables[key];
        } else {
          fullVariables[key] = queryVariables[key];
        }
      }
      return refetchOrig(fullVariables);
    },
    [refetchOrig, queryVariables]
  );

  // Initial variables.
  const initialDateFrom = () =>
    queryVariables.start_settlement_date
      ? moment(queryVariables.start_settlement_date).toDate()
      : null;
  const initialDateTo = () =>
    queryVariables.end_settlement_date ? moment(queryVariables.end_settlement_date).toDate() : null;

  // Available programs/banks/currencies/etc. for filter autocomplete.
  const { programs = [] } = useProgramSelectorStateContext();
  const [programShortCodeToProgram, setProgramShortCodeToProgram] = useState(new Map());
  const allPrograms = useMemo(
    () => Array.from(programShortCodeToProgram.values()),
    [programShortCodeToProgram]
  );

  useEffect(() => {
    if (programs.length) {
      const newProgramMap = new Map();

      // This "unknown" program is added to enable filtering by unknown programs.
      // It is added here because our programs list comes from Janus/Diva, which
      // does not contain "unknown".
      //
      // Add UNKNOWN to newProgramMap before everything else to ensure it shows
      // up first in the filter.
      const hasUnknown = programs.find((p) => p.short_name.toLowerCase() === 'unknown');
      if (!hasUnknown) {
        newProgramMap.set('UNKNOWN', {
          favorite: false,
          program: 'Unknown',
          short_name: 'UNKNOWN',
        });
      }

      for (const program of programs) {
        newProgramMap.set(program.short_name, program);
      }

      setProgramShortCodeToProgram(newProgramMap);
    }
  }, [programs]);

  const [allProgramTypes, setAllProgramTypes] = useState([]);
  const [allBanks, setAllBanks] = useState([]);
  const [allCurrencyCodes, setAllCurrencyCodes] = useState([]);
  useEffect(() => {
    if (data?.programTypes?.length) setAllProgramTypes(data.programTypes);
    if (data?.dnaBanks?.data?.length) setAllBanks(data.dnaBanks.data);
    if (data?.currencyCodes?.length) setAllCurrencyCodes(data.currencyCodes);
  }, [data]);

  // Transform exceptions into the queue fit for <Table> display.
  const [queue, setQueue] = useState([]);
  useEffect(() => {
    const exceptions = data?.exceptionQueueBySubnetwork?.exceptions ?? [];

    if (exceptions.length) {
      setQueue(
        exceptions.map((item) => {
          const programObj = programShortCodeToProgram.get(item.program_short_code);

          return {
            ...item,
            masked_pan: item.masked_pan ? transformMaskedPan(item.masked_pan) : '',
            network_reference_id: item.network_reference_id,
            variance: item.variance_amount
              ? number(
                  item.variance_amount,
                  getDefaultFormattingOptions(
                    item.settlement_currency ? { symbol: item.settlement_currency } : {}
                  ),
                  false
                ).value
              : 0,
            variance_amount: item.variance_amount
              ? calculateAndFormatScaledDecimal(item.variance_amount, item.settlement_currency)
              : '',
            settlement_date: item.settlement_date ? item.settlement_date : '',
            source:
              item.source === 'MQ'
                ? 'Marqeta'
                : item.source === 'BOTH'
                ? 'Both'
                : `${NETWORKS[network] ?? network} - ${SUBNETWORKS[subnetwork] ?? subnetwork}`,
            program: programObj?.program || item.program_short_code,
            program_type: PROGRAM_TYPES[item.program_type] || item.program_type,
            bank: item.bank?.name || '',
            local_transaction_date_time: item.local_transaction_date_time || '',
          };
        })
      );
    } else {
      setQueue([]);
    }
  }, [data, programShortCodeToProgram, network, subnetwork]);

  // Bulk transaction selection.
  const { SelectRow, selectAllRows, selectedRows, clearSelections } = useRowSelector({
    rows: queue,
    idProp: 'tx_hash_internal_id',
  });

  // Table columns.
  const isBulkEditable = BULK_EDITABLE_TYPES.has(exceptionType);
  const { columns, columnEditor } = useTableColumns({
    network,
    subnetwork,
    fieldSetId: 'exception_queue',
    isBulkEditable,
    SelectRow,
    selectAllRows,
    columnsLocalStorageKey: COLUMNS_LOCAL_STORAGE_KEY,
  });

  // Queue CSV export.
  const [exportModal, setExportModal] = useState(NO_MODAL);
  const [exportExceptionQueue, exportResult] = useMutation(EXPORT_EXCEPTION_QUEUE);
  const exportTable = async () => {
    const filters = {
      subnetwork,
      exception_type: exceptionType,
      program_types: queryVariables.program_types,
      program_short_codes: queryVariables.program_short_codes,
      dna_bank_tokens: queryVariables.dna_bank_tokens,
      settlement_currencies: queryVariables.settlement_currencies,
      start_settlement_date: queryVariables.start_settlement_date,
      end_settlement_date: queryVariables.end_settlement_date,
      ica_values: queryVariables.ica_values,
    };
    try {
      await exportExceptionQueue({
        variables: filters,
      });

      setExportModal(EXPORT_SUCCESS_MODAL);
    } catch (err) {
      console.error('Failed to start export process', err);
      setExportModal(EXPORT_ERROR_MODAL);
    }
  };

  // Bulk submit.
  const [numTxsSubmittedForToast, setNumTxsSubmittedForToast] = useState(0);
  const [bulkSubmitError, setBulkSubmitError] = useState('');

  const loading = loadingQuery;

  const getTotalVariance = () => {
    let sum = 0;
    for (const item of queue) {
      if (selectedRows.ids.has(item.tx_hash_internal_id)) {
        sum += item.variance;
      }
    }
    return number(sum).format();
  };

  const handleRowClick = useCallback(
    (rowValue) => {
      const { tx_hash_internal_id } = rowValue;
      go(`/settlement/exceptions/${constantToDashCase(exceptionType)}/details`, {
        params: `${urlParams}&tx_hash_internal_id=${tx_hash_internal_id}`,
      });
    },
    [go, exceptionType, urlParams]
  );

  const adjustmentActionToApi = useCallback((action, reason) => {
    switch (action) {
      case ADJUST_TRANSACTION:
        return reason;
      case WRITE_OFF_TRANSACTION:
        return 'WRITE_OFF';
      case MANUAL_TRANSACTION:
        return 'MANUALLY_RECONCILE';
      case INVALID_TRANSACTION:
        return 'INVALID_EXCEPTION';
      case EXCLUDE_TRANSACTION:
        return 'EXCLUDE_EXCEPTION';
      default:
        throw new Error('Invalid adjustment action');
    }
  }, []);

  const [bulkSubmitExceptions] = useMutation(BULK_SUBMIT_EXCEPTIONS);

  const handleBulkSubmit = useCallback(
    (params) => {
      const submittedHashInternalIDs = Array.from(selectedRows.ids);
      return bulkSubmitExceptions({
        variables: {
          subnetwork,
          tx_hash_internal_ids: submittedHashInternalIDs,
          adjustment_action: adjustmentActionToApi(params.action, params.reason),
          reason: params.reason,
          note: params.note,
        },
      })
        .then((response) => {
          const { message, unsuccessful_tx_hash_internal_ids } =
            response?.data?.bulkSubmitExceptions ?? {};
          if (unsuccessful_tx_hash_internal_ids && unsuccessful_tx_hash_internal_ids.length) {
            // Indicate to the user that not all of the ids got submitted
            setBulkSubmitError(message);
            refetch();
          } else {
            // Indicate a total success
            setNumTxsSubmittedForToast(submittedHashInternalIDs.length);
            clearSelections();
            refetch();
          }
        })
        .catch((error) => {
          setBulkSubmitError(error);
          throw error;
        });
    },
    [
      refetch,
      subnetwork,
      clearSelections,
      selectedRows.ids,
      adjustmentActionToApi,
      bulkSubmitExceptions,
    ]
  );

  return (
    <div>
      <div className={s.header}>
        <div className={s.title}>
          <ExceptionFlowBreadcrumbs />
          <Text type="h3">
            {SUBNETWORKS[subnetwork] || subnetwork} exceptions:{' '}
            {EXCEPTION_TYPES[exceptionType] || exceptionType}
          </Text>
        </div>
        <div>
          <Button type="tertiary" disabled={exportResult.loading} onClick={exportTable}>
            Export data
          </Button>
        </div>
      </div>
      <VSpacer factor={3} />
      <TableHeader>
        <FlexContainer style={{ width: '100%' }}>
          <TableFilters
            refetch={refetch}
            allBanks={allBanks}
            allCurrencyCodes={allCurrencyCodes}
            allPrograms={allPrograms}
            allProgramTypes={allProgramTypes}
            initialValues={queryVariables}
            network={network}
            testId="unmatched-queue"
          />
          <HSpacer factor={2} />
          <DatePicker
            dateFromParam="start_settlement_date"
            dateToParam="end_settlement_date"
            initialDateFrom={initialDateFrom}
            initialDateTo={initialDateTo}
            refetch={refetch}
          />
          <HSpacer factor={2} />
          <div style={{ flex: '1' }} />
          <TableActions
            exceptionType={exceptionType}
            numSelected={selectedRows.ids.size}
            getTotalVariance={getTotalVariance}
            submitTransactions={handleBulkSubmit}
          />
          {columnEditor}
        </FlexContainer>
      </TableHeader>
      <VSpacer factor={1} />
      {!queue?.length && loadingError ? (
        <ErrorLoading />
      ) : (
        <LoadingOverlay active={loading}>
          <Table
            loading={loading}
            data={queue}
            columns={columns}
            fixedColumnCount={1}
            rightAlignFixedColumns={true}
            height={dynamicHeight}
            onRowClick={handleRowClick}
          />
          <VSpacer factor={1} />
          <Pagination
            className={s.pagination}
            refetch={refetch}
            prevPageToken={data?.exceptionQueueBySubnetwork?.prev_page_token ?? ''}
            nextPageToken={data?.exceptionQueueBySubnetwork?.next_page_token ?? ''}
          />
        </LoadingOverlay>
      )}
      {numTxsSubmittedForToast > 0 && (
        <ToastAlert remove={() => setNumTxsSubmittedForToast(0)} dismissTime={3}>
          {`${numTxsSubmittedForToast} network ref. ID${
            numTxsSubmittedForToast > 1 ? 's' : ''
          } submitted`}
        </ToastAlert>
      )}
      {toastMessage && (
        <ToastAlert remove={() => setToastMessage(null)} icon="success">
          {toastMessage}
        </ToastAlert>
      )}
      {bulkSubmitError && (
        <ErrorModal
          heading="Bulk submission failed"
          hideModal={() => setBulkSubmitError('')}
          buttonText="Dismiss"
        >
          {bulkSubmitError + '    '}
        </ErrorModal>
      )}
      {exportModal === EXPORT_SUCCESS_MODAL && (
        <Modal
          heading="Export request submitted"
          type="sm"
          hideModal={() => setExportModal(NO_MODAL)}
          hideModalButtonText="Dismiss"
          description="To check your export's status, go to the Export queue.
            Large data sets run the risk of timing out after 15 minutes. Apply
            filters and export smaller data sets to avoid long waits and time
            outs."
          footerButtons={[
            <Button onClick={() => go('/settlement/export-queue')}>View status</Button>,
          ]}
        />
      )}
      {exportModal === EXPORT_ERROR_MODAL && (
        <ErrorModal
          heading="Unable to process your request"
          hideModal={() => setExportModal(NO_MODAL)}
          buttonText="Dismiss"
        >
          An unknown error occurred while starting your data export.
        </ErrorModal>
      )}
    </div>
  );
}

export default containerHeightHOC(ExceptionQueue, 300);
