class CFNLeftNav {
  constructor(config, defaultVerifier) {
    this.initialized = false;
    this.authorizeLinks = CFNLeftNav.authorizeLinks;
    this.isAuthorized = CFNLeftNav.isAuthorized;
    this.cloneWorkspace = CFNLeftNav.cloneWorkspace;

    if (config || defaultVerifier) this.configure(config, defaultVerifier);

    // this.workspaces is set/reset any time this.authorize() is called. It is a
    // deep clone of this.CONFIG, except it only includes authorized workspaces
    // and links.
    this.workspaces = {};

    // this.leftNav is set/reset any time this.authorize() is called. It is a
    // map of leftnav paths/links { [path]: [config] }, grouped by path and
    // contains all links in this.CONFIG plus associated paths, filtered down to
    // only links user is authorized for.
    this.leftNav = {};
  }

  /**
   * Configures the instance.
   *
   * IMPORTANT: Do not mutate this.CONFIG!
   *
   * @param {object} config CFN configuration.
   * @param {function} defaultVerifier Default verifier function.
   */
  configure(config, defaultVerifier) {
    this.CONFIG = config;
    this.verifier = defaultVerifier;
  }

  /**
   * Deep clones this.CONFIG and outputs authorized workspaces + links to
   * this.workspaces and this.leftNav.
   *
   * @return {CFNLeftNav} The current instance.
   */
  authorize() {
    if (!this.CONFIG) return;

    // Reset workspaces and leftNav links.
    this.workspaces = {};
    this.leftNav = {};

    // Iterate through workspaces...
    Object.keys(this.CONFIG).forEach((workspaceId) => {
      // Add workspaceId as id.
      const workspace = this.cloneWorkspace(workspaceId);
      workspace.id = workspaceId;

      // Iterate over leftNav items (L1 and L2)...
      workspace.leftNav = this.authorizeLinks(workspace.leftNav, workspaceId);

      // If workspace.leftNav has authorized links, then workspace is authorized.
      const workspaceIsAuthorized = Boolean(workspace.leftNav.length);
      if (workspaceIsAuthorized) {
        this.workspaces[workspaceId] = workspace;
      }
    });

    this.initialized = true;
    return this;
  }

  /**
   * Return only authorized links from an array of links.
   *
   * @param {Link[]} [links=[]] Array of Link objects.
   * @return {Link[]} Filtered links user is authorized to see.
   */
  static authorizeLinks(links = [], workspaceId) {
    if (!links?.length) return [];

    const authorizedLinks = [];

    links.forEach((link) => {
      let authorized = false;
      link.workspaceId = workspaceId;
      // L1 nav links with children do not link anywhere; so they should only return title and
      // children properties. If there are zero authorized children, the L1 link
      // should not be displayed.
      if (link.children) {
        const children = this.authorizeLinks(link.children, workspaceId);
        authorized = Boolean(children.length);
        if (authorized) {
          authorizedLinks.push({
            title: link.title,
            workspaceId,
            children,
          });
        }
        return authorizedLinks;
      }

      authorized = this.isAuthorized(link);

      // Only return links that are both authorized and not hidden.
      if (authorized && !link.hidden) {
        authorizedLinks.push(link);
        this.leftNav[link.path] = link;

        // Add associatedPaths to this.leftNav.
        if (link.associatedPaths) {
          if (typeof link.associatedPaths === 'string') {
            link.associatedPaths = [link.associatedPaths];
          }
          link.associatedPaths.forEach((associatedPath) => {
            if (this.leftNav[associatedPath]) return;
            this.leftNav[associatedPath] = link;
          });
        }
      }
    });

    return authorizedLinks;
  }

  /**
   * Deep clones a specified workspace from this.CONFIG.
   *
   * @param {object} [config={}] Single workspace config object.
   * @return {object} Cloned workspace config.
   */
  static cloneWorkspace(workspace) {
    if (typeof workspace === 'string') workspace = this.CONFIG[workspace];
    const workspaceClone = { ...workspace };
    if (workspace.leftNav && workspace.leftNav.length) {
      workspaceClone.leftNav = workspace.leftNav.map((obj) => ({ ...obj }));
    }
    return workspaceClone;
  }

  /**
   * Determines whether user is authorized to view specified link.
   *
   * L2 nav links must link somewhere. We do not support nav links deeper than L2.
   *
   * @param {Object} [link={}] Link configuration.
   * @param {string} [link.title] Link title displayed in UI.
   * @param {string} [link.path] Path to navigate to when clicked.
   * @param {Link[]} [link.children] Array of Link objects. A link with children and
   *          no path will act as a show/hide toggle.
   * @param {function} [link.verifier] - Callback which determines whether user is
   *          authorized to view link.
   * @param {boolean} [link.authorized] Whether user is authorized. Takes precedence
   *          over Link.verifier().
   * @return {boolean} True if user is authorized, False otherwise.
   */
  static isAuthorized(link) {
    if (typeof link === 'string') link = this.leftNav[link];
    if (!link) return false;
    // If link.authorized is set, use it.
    if (typeof link.authorized === 'boolean') return link.authorized;
    // Otherwise return the result of verifier(), where verifier can be
    // link.verifier() or the default verifier from route-authorization.
    return typeof link.verifier === 'function'
      ? link.verifier(link.path)
      : this.verifier && this.verifier(link.path);
  }
}

const cfnLeftNav = new CFNLeftNav();
export { CFNLeftNav, cfnLeftNav };
