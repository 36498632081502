import { useCallback } from 'react';
import { secureStorage } from '@mq/volt-amc-container';
import { useProgramSelectorDispatchContext, useProgramSelectorStateContext } from './context.js';
import { FAVORITE_PROGRAMS_KEY, PROGRAMS } from './constants.js';
import { stripFavoriteKeyFromProgram } from './utils.js';

export default function useToggleFavoriteProgram() {
  const dispatch = useProgramSelectorDispatchContext();
  const { programs } = useProgramSelectorStateContext();

  return useCallback(
    (toggledProgram) => {
      const programsWithToggledFavorite = programs.map((program) => {
        if (program.program === toggledProgram.program) {
          return {
            ...program,
            favorite: !program.favorite,
          };
        }
        return program;
      });
      const newFavoritePrograms = programsWithToggledFavorite
        .filter(({ favorite }) => favorite)
        .map(stripFavoriteKeyFromProgram);

      secureStorage.setItem(FAVORITE_PROGRAMS_KEY, JSON.stringify(newFavoritePrograms));
      dispatch({ type: PROGRAMS, payload: programsWithToggledFavorite });
    },
    [dispatch, programs]
  );
}
