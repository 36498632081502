import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { MarqetaCard, VSpacer, Card, HSpacer, ToastAlert } from '@mqd/volt-base';

import ReportCardLost from './actions/ReportCardLost';
import LockCard from './actions/LockCard';
import ReplaceCard from './actions/ReplaceCard';
import ActivateCard from './actions/ActivateCard';
import NoAction from './actions/NoAction';
import CardIsTerminated from './actions/CardIsTerminated';
import ActionsPlate from './actions/ActionsPlate';

import s from './CardActionsCard.module.css';

function CardActionsCard({
  canMakeTransition,
  canShowPan,
  canViewCvv,
  canViewShortPan,
  cardStore,
  goToCard,
  reportCardLostStolenActive,
  cardSuspendActive,
  cardReplaceActive,
  uamGranularPermissionsActive,
  showActivateCard,
}) {
  const [toastAlert, setToastAlert] = useState({
    message: '',
    icon: '',
  });
  const {
    token,
    pan,
    expiration,
    cvv_number,
    activateCard,
    unlockCard,
    reportCardLostStolen,
    reportCardLostStolenUam,
    suspendCard,
    suspendCardUam,
    isLocked,
    isUnactivated,
    isTerminated,
    replaceCardWithSamePan,
    replaceCardWithSamePanUam,
    loading,
    showPan,
    state: cardState,
    first_name,
    middle_name,
    last_name,
    fulfillment: {
      shipping: {
        recipient_address: { address1, address2, city, state, postal_code, country, zip } = {},
      } = {},
    },
  } = cardStore;

  const acceptedCardStates = ['ACTIVE', 'SUSPENDED', 'LIMITED'];

  const AddressRow = ({ children }) =>
    children ? (
      <>
        {children}
        <br />
      </>
    ) : null;

  const postalCodeOrZip = postal_code || zip;

  const recipientAddress = (
    <>
      <AddressRow>{address1}</AddressRow>
      <AddressRow>{address2}</AddressRow>
      <AddressRow>{`${city}, ${state} ${postalCodeOrZip}`}</AddressRow>
      <AddressRow>{country}</AddressRow>
    </>
  );

  const renderSuccessAlert = () => setToastAlert({ message: 'New card issued', icon: 'success' });
  const renderErrorAlert = () =>
    setToastAlert({ message: 'New card failed to issue', icon: 'error' });

  const renderActions = () => {
    if (isTerminated) {
      return <CardIsTerminated />;
    }

    if ((!uamGranularPermissionsActive && !canMakeTransition) || !token) {
      return <NoAction />;
    }

    if (isUnactivated && showActivateCard) {
      return <ActivateCard onClick={activateCard} />;
    }

    const delayedGoToCard = async (token) => {
      // give it some time to display success message before routing to new card
      renderSuccessAlert();
      setTimeout(() => {
        goToCard(token);
      }, 1500);
    };

    return (
      <ActionsPlate>
        {reportCardLostStolenActive && acceptedCardStates.indexOf(cardState) >= 0 && (
          <ReportCardLost
            cardStore={cardStore}
            recipientAddress={recipientAddress}
            onClick={async (params) => {
              try {
                await (uamGranularPermissionsActive
                  ? reportCardLostStolenUam(params, delayedGoToCard)
                  : reportCardLostStolen(params, delayedGoToCard));
              } catch (e) {
                renderErrorAlert();
              }
            }}
          />
        )}
        {cardSuspendActive && (
          <LockCard
            active={isLocked}
            cardStore={cardStore}
            unlockCard={unlockCard}
            lockCard={async (params) => {
              try {
                await (uamGranularPermissionsActive ? suspendCardUam(params) : suspendCard(params));
              } catch (e) {
                renderErrorAlert();
              }
            }}
          />
        )}
        {cardReplaceActive && (
          <ReplaceCard
            cardStore={cardStore}
            recipientAddress={recipientAddress}
            onClick={async (params) => {
              try {
                await (uamGranularPermissionsActive
                  ? replaceCardWithSamePanUam(params, delayedGoToCard)
                  : replaceCardWithSamePan(params, delayedGoToCard));
              } catch (e) {
                renderErrorAlert();
              }
            }}
          />
        )}
      </ActionsPlate>
    );
  };

  const renderToastAlert = () => {
    if (!toastAlert || !toastAlert.message) return null;

    return (
      <ToastAlert
        testId="issue-card-alert"
        remove={() => setToastAlert({ toastAlert: null })}
        {...toastAlert}
      >
        {toastAlert.message}
      </ToastAlert>
    );
  };

  const formattedPan = canViewShortPan ? pan : '****************';

  return (
    <div data-testid="card_actions">
      {renderToastAlert()}
      <Card style={{ padding: 0 }} contentPadding="0">
        <div className={s.container}>
          <MarqetaCard
            firstName={first_name}
            middleName={middle_name}
            lastName={last_name}
            pan={formattedPan}
            revealPan={canShowPan ? showPan : null}
            locked={isLocked}
            exp={expiration}
            cvv={canViewCvv ? cvv_number : ''}
          />
          <HSpacer factor={4} />
          <div className={s.actionContainer}>{renderActions()}</div>
        </div>
      </Card>
      <VSpacer factor={4} />
    </div>
  );
}

CardActionsCard.propTypes = {
  cardStore: PropTypes.any,
  reportCardLostStolenActive: PropTypes.bool,
  cardSuspendActive: PropTypes.bool,
  cardReplaceActive: PropTypes.bool,
  canShowPan: PropTypes.bool,
  canMakeTransition: PropTypes.bool,
  goToCard: PropTypes.func,
  canViewShortPan: PropTypes.bool,
  canViewCvv: PropTypes.bool,
  showActivateCard: PropTypes.bool,
};

CardActionsCard.defaultProps = {
  reportCardLostStolenActive: false,
  cardSuspendActive: false,
  cardReplaceActive: false,
  cardStore: {
    fulfillment: {
      shipping: {},
    },
  },
  canShowPan: false,
  canMakeTransition: false,
  goToCard: () => {},
  canViewShortPan: false,
  canViewCvv: false,
  showActivateCard: false,
};

export default observer(CardActionsCard);
