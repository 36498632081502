export default function sortData({ data, sort, configuration }) {
  if (!sort || !configuration) {
    return data;
  }

  const { column, direction } = deconstructSort(sort);

  return sortByConfiguration({ configuration, data, direction, column });
}

const deconstructSort = (sort) => {
  const direction = sort.substr(sort.length - 1) === '-' ? 'descend' : 'ascend';
  const column = sort.substr(0, sort.length - 1);

  return { column, direction };
};

const sortByConfiguration = ({ configuration, data, direction, column }) => {
  const columnConfiguration = configuration[column];

  if (!columnConfiguration) {
    console.info(`No sort configuration for: ${column}`);
    return data;
  }

  const { type, transform } = columnConfiguration;

  const args = {
    data,
    direction,
    column,
    transform,
  };

  if (type === 'string') {
    return sortByString(args);
  } else if (type === 'number') {
    return sortByNumber(args);
  } else if (type === 'date') {
    return sortByDate(args);
  } else {
    if (typeof type === 'function') {
      return type(args);
    }
    return data;
  }
};

const sortByDate = ({ data, direction, column, transform }) => {
  return data.slice().sort((a, b) => {
    const dataA = transform ? transform(a[column]) : a[column];
    const dataB = transform ? transform(b[column]) : b[column];
    if (direction === 'descend') {
      return new Date(dataA) - new Date(dataB);
    }
    return new Date(dataB) - new Date(dataA);
  });
};

const sortByString = ({ data, direction, column, transform }) => {
  return data.slice().sort((a, b) => {
    const dataA = transform ? transform(a[column]) : a[column];
    const dataB = transform ? transform(b[column]) : b[column];
    if (direction === 'descend') {
      return dataA.localeCompare(dataB);
    }
    return dataB.localeCompare(dataA);
  });
};

const sortByNumber = ({ data, direction, column, transform }) => {
  return data.slice().sort((a, b) => {
    const dataA = transform ? transform(a[column]) : a[column];
    const dataB = transform ? transform(b[column]) : b[column];
    if (direction === 'descend') {
      return dataA - dataB;
    } else {
      return dataB - dataA;
    }
  });
};
