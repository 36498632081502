export const reasonCodes = [
  {
    code: 'R01',
    message: 'Insufficient funds',
  },
  {
    code: 'R02',
    message: 'Account closed',
  },
  {
    code: 'R03',
    message: 'No Account/Unable to locate account',
  },
  {
    code: 'R06',
    message: 'Returned per ODFI request',
  },
  {
    code: 'R08',
    message: 'Payment stopped',
  },
  {
    code: 'R10',
    message: 'User advises unauthorized transaction',
  },
  {
    code: 'R11',
    message: 'Customer advises entry breaches the terms of the authorization',
  },
  {
    code: 'R16',
    message: 'Account frozen/returned per OFAC instruction',
  },
  {
    code: 'R17',
    message: 'Suspicious or questionable transaction',
  },
  {
    code: 'R23',
    message: 'Credit entry refused by receiver',
  },
];

export const REQUIRED_ERROR_MESSAGE = 'This field is required.';
