// Source: https://github.com/lukes/ISO-3166-Countries-with-Regional-Codes/blob/master/all/all.csv

const countryCodeList = [
  {
    name: 'Afghanistan',
    alpha2: 'AF',
    alpha3: 'AFG',
    countryCode: '004',
    iso3166Dash2: 'ISO 3166-2:AF',
    region: 'Asia',
    subRegion: 'Southern Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: '034',
    intermediateregionCode: '',
  },
  {
    name: 'Åland Islands',
    alpha2: 'AX',
    alpha3: 'ALA',
    countryCode: 248,
    iso3166Dash2: 'ISO 3166-2:AX',
    region: 'Europe',
    subRegion: 'Northern Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: 154,
    intermediateregionCode: '',
  },
  {
    name: 'Albania',
    alpha2: 'AL',
    alpha3: 'ALB',
    countryCode: '008',
    iso3166Dash2: 'ISO 3166-2:AL',
    region: 'Europe',
    subRegion: 'Southern Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: '039',
    intermediateregionCode: '',
  },
  {
    name: 'Algeria',
    alpha2: 'DZ',
    alpha3: 'DZA',
    countryCode: '012',
    iso3166Dash2: 'ISO 3166-2:DZ',
    region: 'Africa',
    subRegion: 'Northern Africa',
    intermediateRegion: '',
    regionCode: '002',
    subregionCode: '015',
    intermediateregionCode: '',
  },
  {
    name: 'American Samoa',
    alpha2: 'AS',
    alpha3: 'ASM',
    countryCode: '016',
    iso3166Dash2: 'ISO 3166-2:AS',
    region: 'Oceania',
    subRegion: 'Polynesia',
    intermediateRegion: '',
    regionCode: '009',
    subregionCode: '061',
    intermediateregionCode: '',
  },
  {
    name: 'Andorra',
    alpha2: 'AD',
    alpha3: 'AND',
    countryCode: '020',
    iso3166Dash2: 'ISO 3166-2:AD',
    region: 'Europe',
    subRegion: 'Southern Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: '039',
    intermediateregionCode: '',
  },
  {
    name: 'Angola',
    alpha2: 'AO',
    alpha3: 'AGO',
    countryCode: '024',
    iso3166Dash2: 'ISO 3166-2:AO',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Middle Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '017',
  },
  {
    name: 'Anguilla',
    alpha2: 'AI',
    alpha3: 'AIA',
    countryCode: 660,
    iso3166Dash2: 'ISO 3166-2:AI',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '029',
  },
  {
    name: 'Antarctica',
    alpha2: 'AQ',
    alpha3: 'ATA',
    countryCode: '010',
    iso3166Dash2: 'ISO 3166-2:AQ',
    region: '',
    subRegion: '',
    intermediateRegion: '',
    regionCode: '',
    subregionCode: '',
    intermediateregionCode: '',
  },
  {
    name: 'Antigua and Barbuda',
    alpha2: 'AG',
    alpha3: 'ATG',
    countryCode: '028',
    iso3166Dash2: 'ISO 3166-2:AG',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '029',
  },
  {
    name: 'Argentina',
    alpha2: 'AR',
    alpha3: 'ARG',
    countryCode: '032',
    iso3166Dash2: 'ISO 3166-2:AR',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'South America',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '005',
  },
  {
    name: 'Armenia',
    alpha2: 'AM',
    alpha3: 'ARM',
    countryCode: '051',
    iso3166Dash2: 'ISO 3166-2:AM',
    region: 'Asia',
    subRegion: 'Western Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: 145,
    intermediateregionCode: '',
  },
  {
    name: 'Aruba',
    alpha2: 'AW',
    alpha3: 'ABW',
    countryCode: 533,
    iso3166Dash2: 'ISO 3166-2:AW',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '029',
  },
  {
    name: 'Australia',
    alpha2: 'AU',
    alpha3: 'AUS',
    countryCode: '036',
    iso3166Dash2: 'ISO 3166-2:AU',
    region: 'Oceania',
    subRegion: 'Australia and New Zealand',
    intermediateRegion: '',
    regionCode: '009',
    subregionCode: '053',
    intermediateregionCode: '',
  },
  {
    name: 'Austria',
    alpha2: 'AT',
    alpha3: 'AUT',
    countryCode: '040',
    iso3166Dash2: 'ISO 3166-2:AT',
    region: 'Europe',
    subRegion: 'Western Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: 155,
    intermediateregionCode: '',
  },
  {
    name: 'Azerbaijan',
    alpha2: 'AZ',
    alpha3: 'AZE',
    countryCode: '031',
    iso3166Dash2: 'ISO 3166-2:AZ',
    region: 'Asia',
    subRegion: 'Western Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: 145,
    intermediateregionCode: '',
  },
  {
    name: 'Bahamas',
    alpha2: 'BS',
    alpha3: 'BHS',
    countryCode: '044',
    iso3166Dash2: 'ISO 3166-2:BS',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '029',
  },
  {
    name: 'Bahrain',
    alpha2: 'BH',
    alpha3: 'BHR',
    countryCode: '048',
    iso3166Dash2: 'ISO 3166-2:BH',
    region: 'Asia',
    subRegion: 'Western Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: 145,
    intermediateregionCode: '',
  },
  {
    name: 'Bangladesh',
    alpha2: 'BD',
    alpha3: 'BGD',
    countryCode: '050',
    iso3166Dash2: 'ISO 3166-2:BD',
    region: 'Asia',
    subRegion: 'Southern Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: '034',
    intermediateregionCode: '',
  },
  {
    name: 'Barbados',
    alpha2: 'BB',
    alpha3: 'BRB',
    countryCode: '052',
    iso3166Dash2: 'ISO 3166-2:BB',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '029',
  },
  {
    name: 'Belarus',
    alpha2: 'BY',
    alpha3: 'BLR',
    countryCode: 112,
    iso3166Dash2: 'ISO 3166-2:BY',
    region: 'Europe',
    subRegion: 'Eastern Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: 151,
    intermediateregionCode: '',
  },
  {
    name: 'Belgium',
    alpha2: 'BE',
    alpha3: 'BEL',
    countryCode: '056',
    iso3166Dash2: 'ISO 3166-2:BE',
    region: 'Europe',
    subRegion: 'Western Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: 155,
    intermediateregionCode: '',
  },
  {
    name: 'Belize',
    alpha2: 'BZ',
    alpha3: 'BLZ',
    countryCode: '084',
    iso3166Dash2: 'ISO 3166-2:BZ',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Central America',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '013',
  },
  {
    name: 'Benin',
    alpha2: 'BJ',
    alpha3: 'BEN',
    countryCode: 204,
    iso3166Dash2: 'ISO 3166-2:BJ',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Western Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '011',
  },
  {
    name: 'Bermuda',
    alpha2: 'BM',
    alpha3: 'BMU',
    countryCode: '060',
    iso3166Dash2: 'ISO 3166-2:BM',
    region: 'Americas',
    subRegion: 'Northern America',
    intermediateRegion: '',
    regionCode: '019',
    subregionCode: '021',
    intermediateregionCode: '',
  },
  {
    name: 'Bhutan',
    alpha2: 'BT',
    alpha3: 'BTN',
    countryCode: '064',
    iso3166Dash2: 'ISO 3166-2:BT',
    region: 'Asia',
    subRegion: 'Southern Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: '034',
    intermediateregionCode: '',
  },
  {
    name: 'Bolivia (Plurinational State of)',
    alpha2: 'BO',
    alpha3: 'BOL',
    countryCode: '068',
    iso3166Dash2: 'ISO 3166-2:BO',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'South America',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '005',
  },
  {
    name: 'Bonaire, Sint Eustatius and Saba',
    alpha2: 'BQ',
    alpha3: 'BES',
    countryCode: 535,
    iso3166Dash2: 'ISO 3166-2:BQ',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '029',
  },
  {
    name: 'Bosnia and Herzegovina',
    alpha2: 'BA',
    alpha3: 'BIH',
    countryCode: '070',
    iso3166Dash2: 'ISO 3166-2:BA',
    region: 'Europe',
    subRegion: 'Southern Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: '039',
    intermediateregionCode: '',
  },
  {
    name: 'Botswana',
    alpha2: 'BW',
    alpha3: 'BWA',
    countryCode: '072',
    iso3166Dash2: 'ISO 3166-2:BW',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Southern Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '018',
  },
  {
    name: 'Bouvet Island',
    alpha2: 'BV',
    alpha3: 'BVT',
    countryCode: '074',
    iso3166Dash2: 'ISO 3166-2:BV',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'South America',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '005',
  },
  {
    name: 'Brazil',
    alpha2: 'BR',
    alpha3: 'BRA',
    countryCode: '076',
    iso3166Dash2: 'ISO 3166-2:BR',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'South America',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '005',
  },
  {
    name: 'British Indian Ocean Territory',
    alpha2: 'IO',
    alpha3: 'IOT',
    countryCode: '086',
    iso3166Dash2: 'ISO 3166-2:IO',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Eastern Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '014',
  },
  {
    name: 'Brunei Darussalam',
    alpha2: 'BN',
    alpha3: 'BRN',
    countryCode: '096',
    iso3166Dash2: 'ISO 3166-2:BN',
    region: 'Asia',
    subRegion: 'South-eastern Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: '035',
    intermediateregionCode: '',
  },
  {
    name: 'Bulgaria',
    alpha2: 'BG',
    alpha3: 'BGR',
    countryCode: 100,
    iso3166Dash2: 'ISO 3166-2:BG',
    region: 'Europe',
    subRegion: 'Eastern Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: 151,
    intermediateregionCode: '',
  },
  {
    name: 'Burkina Faso',
    alpha2: 'BF',
    alpha3: 'BFA',
    countryCode: 854,
    iso3166Dash2: 'ISO 3166-2:BF',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Western Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '011',
  },
  {
    name: 'Burundi',
    alpha2: 'BI',
    alpha3: 'BDI',
    countryCode: 108,
    iso3166Dash2: 'ISO 3166-2:BI',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Eastern Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '014',
  },
  {
    name: 'Cabo Verde',
    alpha2: 'CV',
    alpha3: 'CPV',
    countryCode: 132,
    iso3166Dash2: 'ISO 3166-2:CV',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Western Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '011',
  },
  {
    name: 'Cambodia',
    alpha2: 'KH',
    alpha3: 'KHM',
    countryCode: 116,
    iso3166Dash2: 'ISO 3166-2:KH',
    region: 'Asia',
    subRegion: 'South-eastern Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: '035',
    intermediateregionCode: '',
  },
  {
    name: 'Cameroon',
    alpha2: 'CM',
    alpha3: 'CMR',
    countryCode: 120,
    iso3166Dash2: 'ISO 3166-2:CM',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Middle Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '017',
  },
  {
    name: 'Canada',
    alpha2: 'CA',
    alpha3: 'CAN',
    countryCode: 124,
    iso3166Dash2: 'ISO 3166-2:CA',
    region: 'Americas',
    subRegion: 'Northern America',
    intermediateRegion: '',
    regionCode: '019',
    subregionCode: '021',
    intermediateregionCode: '',
  },
  {
    name: 'Cayman Islands',
    alpha2: 'KY',
    alpha3: 'CYM',
    countryCode: 136,
    iso3166Dash2: 'ISO 3166-2:KY',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '029',
  },
  {
    name: 'Central African Republic',
    alpha2: 'CF',
    alpha3: 'CAF',
    countryCode: 140,
    iso3166Dash2: 'ISO 3166-2:CF',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Middle Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '017',
  },
  {
    name: 'Chad',
    alpha2: 'TD',
    alpha3: 'TCD',
    countryCode: 148,
    iso3166Dash2: 'ISO 3166-2:TD',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Middle Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '017',
  },
  {
    name: 'Chile',
    alpha2: 'CL',
    alpha3: 'CHL',
    countryCode: 152,
    iso3166Dash2: 'ISO 3166-2:CL',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'South America',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '005',
  },
  {
    name: 'China',
    alpha2: 'CN',
    alpha3: 'CHN',
    countryCode: 156,
    iso3166Dash2: 'ISO 3166-2:CN',
    region: 'Asia',
    subRegion: 'Eastern Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: '030',
    intermediateregionCode: '',
  },
  {
    name: 'Christmas Island',
    alpha2: 'CX',
    alpha3: 'CXR',
    countryCode: 162,
    iso3166Dash2: 'ISO 3166-2:CX',
    region: 'Oceania',
    subRegion: 'Australia and New Zealand',
    intermediateRegion: '',
    regionCode: '009',
    subregionCode: '053',
    intermediateregionCode: '',
  },
  {
    name: 'Cocos (Keeling) Islands',
    alpha2: 'CC',
    alpha3: 'CCK',
    countryCode: 166,
    iso3166Dash2: 'ISO 3166-2:CC',
    region: 'Oceania',
    subRegion: 'Australia and New Zealand',
    intermediateRegion: '',
    regionCode: '009',
    subregionCode: '053',
    intermediateregionCode: '',
  },
  {
    name: 'Colombia',
    alpha2: 'CO',
    alpha3: 'COL',
    countryCode: 170,
    iso3166Dash2: 'ISO 3166-2:CO',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'South America',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '005',
  },
  {
    name: 'Comoros',
    alpha2: 'KM',
    alpha3: 'COM',
    countryCode: 174,
    iso3166Dash2: 'ISO 3166-2:KM',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Eastern Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '014',
  },
  {
    name: 'Congo',
    alpha2: 'CG',
    alpha3: 'COG',
    countryCode: 178,
    iso3166Dash2: 'ISO 3166-2:CG',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Middle Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '017',
  },
  {
    name: 'Congo, Democratic Republic of the',
    alpha2: 'CD',
    alpha3: 'COD',
    countryCode: 180,
    iso3166Dash2: 'ISO 3166-2:CD',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Middle Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '017',
  },
  {
    name: 'Cook Islands',
    alpha2: 'CK',
    alpha3: 'COK',
    countryCode: 184,
    iso3166Dash2: 'ISO 3166-2:CK',
    region: 'Oceania',
    subRegion: 'Polynesia',
    intermediateRegion: '',
    regionCode: '009',
    subregionCode: '061',
    intermediateregionCode: '',
  },
  {
    name: 'Costa Rica',
    alpha2: 'CR',
    alpha3: 'CRI',
    countryCode: 188,
    iso3166Dash2: 'ISO 3166-2:CR',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Central America',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '013',
  },
  {
    name: "Côte d'Ivoire",
    alpha2: 'CI',
    alpha3: 'CIV',
    countryCode: 384,
    iso3166Dash2: 'ISO 3166-2:CI',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Western Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '011',
  },
  {
    name: 'Croatia',
    alpha2: 'HR',
    alpha3: 'HRV',
    countryCode: 191,
    iso3166Dash2: 'ISO 3166-2:HR',
    region: 'Europe',
    subRegion: 'Southern Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: '039',
    intermediateregionCode: '',
  },
  {
    name: 'Cuba',
    alpha2: 'CU',
    alpha3: 'CUB',
    countryCode: 192,
    iso3166Dash2: 'ISO 3166-2:CU',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '029',
  },
  {
    name: 'Curaçao',
    alpha2: 'CW',
    alpha3: 'CUW',
    countryCode: 531,
    iso3166Dash2: 'ISO 3166-2:CW',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '029',
  },
  {
    name: 'Cyprus',
    alpha2: 'CY',
    alpha3: 'CYP',
    countryCode: 196,
    iso3166Dash2: 'ISO 3166-2:CY',
    region: 'Asia',
    subRegion: 'Western Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: 145,
    intermediateregionCode: '',
  },
  {
    name: 'Czechia',
    alpha2: 'CZ',
    alpha3: 'CZE',
    countryCode: 203,
    iso3166Dash2: 'ISO 3166-2:CZ',
    region: 'Europe',
    subRegion: 'Eastern Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: 151,
    intermediateregionCode: '',
  },
  {
    name: 'Denmark',
    alpha2: 'DK',
    alpha3: 'DNK',
    countryCode: 208,
    iso3166Dash2: 'ISO 3166-2:DK',
    region: 'Europe',
    subRegion: 'Northern Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: 154,
    intermediateregionCode: '',
  },
  {
    name: 'Djibouti',
    alpha2: 'DJ',
    alpha3: 'DJI',
    countryCode: 262,
    iso3166Dash2: 'ISO 3166-2:DJ',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Eastern Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '014',
  },
  {
    name: 'Dominica',
    alpha2: 'DM',
    alpha3: 'DMA',
    countryCode: 212,
    iso3166Dash2: 'ISO 3166-2:DM',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '029',
  },
  {
    name: 'Dominican Republic',
    alpha2: 'DO',
    alpha3: 'DOM',
    countryCode: 214,
    iso3166Dash2: 'ISO 3166-2:DO',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '029',
  },
  {
    name: 'Ecuador',
    alpha2: 'EC',
    alpha3: 'ECU',
    countryCode: 218,
    iso3166Dash2: 'ISO 3166-2:EC',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'South America',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '005',
  },
  {
    name: 'Egypt',
    alpha2: 'EG',
    alpha3: 'EGY',
    countryCode: 818,
    iso3166Dash2: 'ISO 3166-2:EG',
    region: 'Africa',
    subRegion: 'Northern Africa',
    intermediateRegion: '',
    regionCode: '002',
    subregionCode: '015',
    intermediateregionCode: '',
  },
  {
    name: 'El Salvador',
    alpha2: 'SV',
    alpha3: 'SLV',
    countryCode: 222,
    iso3166Dash2: 'ISO 3166-2:SV',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Central America',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '013',
  },
  {
    name: 'Equatorial Guinea',
    alpha2: 'GQ',
    alpha3: 'GNQ',
    countryCode: 226,
    iso3166Dash2: 'ISO 3166-2:GQ',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Middle Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '017',
  },
  {
    name: 'Eritrea',
    alpha2: 'ER',
    alpha3: 'ERI',
    countryCode: 232,
    iso3166Dash2: 'ISO 3166-2:ER',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Eastern Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '014',
  },
  {
    name: 'Estonia',
    alpha2: 'EE',
    alpha3: 'EST',
    countryCode: 233,
    iso3166Dash2: 'ISO 3166-2:EE',
    region: 'Europe',
    subRegion: 'Northern Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: 154,
    intermediateregionCode: '',
  },
  {
    name: 'Eswatini',
    alpha2: 'SZ',
    alpha3: 'SWZ',
    countryCode: 748,
    iso3166Dash2: 'ISO 3166-2:SZ',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Southern Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '018',
  },
  {
    name: 'Ethiopia',
    alpha2: 'ET',
    alpha3: 'ETH',
    countryCode: 231,
    iso3166Dash2: 'ISO 3166-2:ET',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Eastern Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '014',
  },
  {
    name: 'Falkland Islands (Malvinas)',
    alpha2: 'FK',
    alpha3: 'FLK',
    countryCode: 238,
    iso3166Dash2: 'ISO 3166-2:FK',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'South America',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '005',
  },
  {
    name: 'Faroe Islands',
    alpha2: 'FO',
    alpha3: 'FRO',
    countryCode: 234,
    iso3166Dash2: 'ISO 3166-2:FO',
    region: 'Europe',
    subRegion: 'Northern Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: 154,
    intermediateregionCode: '',
  },
  {
    name: 'Fiji',
    alpha2: 'FJ',
    alpha3: 'FJI',
    countryCode: 242,
    iso3166Dash2: 'ISO 3166-2:FJ',
    region: 'Oceania',
    subRegion: 'Melanesia',
    intermediateRegion: '',
    regionCode: '009',
    subregionCode: '054',
    intermediateregionCode: '',
  },
  {
    name: 'Finland',
    alpha2: 'FI',
    alpha3: 'FIN',
    countryCode: 246,
    iso3166Dash2: 'ISO 3166-2:FI',
    region: 'Europe',
    subRegion: 'Northern Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: 154,
    intermediateregionCode: '',
  },
  {
    name: 'France',
    alpha2: 'FR',
    alpha3: 'FRA',
    countryCode: 250,
    iso3166Dash2: 'ISO 3166-2:FR',
    region: 'Europe',
    subRegion: 'Western Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: 155,
    intermediateregionCode: '',
  },
  {
    name: 'French Guiana',
    alpha2: 'GF',
    alpha3: 'GUF',
    countryCode: 254,
    iso3166Dash2: 'ISO 3166-2:GF',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'South America',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '005',
  },
  {
    name: 'French Polynesia',
    alpha2: 'PF',
    alpha3: 'PYF',
    countryCode: 258,
    iso3166Dash2: 'ISO 3166-2:PF',
    region: 'Oceania',
    subRegion: 'Polynesia',
    intermediateRegion: '',
    regionCode: '009',
    subregionCode: '061',
    intermediateregionCode: '',
  },
  {
    name: 'French Southern Territories',
    alpha2: 'TF',
    alpha3: 'ATF',
    countryCode: 260,
    iso3166Dash2: 'ISO 3166-2:TF',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Eastern Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '014',
  },
  {
    name: 'Gabon',
    alpha2: 'GA',
    alpha3: 'GAB',
    countryCode: 266,
    iso3166Dash2: 'ISO 3166-2:GA',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Middle Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '017',
  },
  {
    name: 'Gambia',
    alpha2: 'GM',
    alpha3: 'GMB',
    countryCode: 270,
    iso3166Dash2: 'ISO 3166-2:GM',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Western Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '011',
  },
  {
    name: 'Georgia',
    alpha2: 'GE',
    alpha3: 'GEO',
    countryCode: 268,
    iso3166Dash2: 'ISO 3166-2:GE',
    region: 'Asia',
    subRegion: 'Western Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: 145,
    intermediateregionCode: '',
  },
  {
    name: 'Germany',
    alpha2: 'DE',
    alpha3: 'DEU',
    countryCode: 276,
    iso3166Dash2: 'ISO 3166-2:DE',
    region: 'Europe',
    subRegion: 'Western Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: 155,
    intermediateregionCode: '',
  },
  {
    name: 'Ghana',
    alpha2: 'GH',
    alpha3: 'GHA',
    countryCode: 288,
    iso3166Dash2: 'ISO 3166-2:GH',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Western Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '011',
  },
  {
    name: 'Gibraltar',
    alpha2: 'GI',
    alpha3: 'GIB',
    countryCode: 292,
    iso3166Dash2: 'ISO 3166-2:GI',
    region: 'Europe',
    subRegion: 'Southern Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: '039',
    intermediateregionCode: '',
  },
  {
    name: 'Greece',
    alpha2: 'GR',
    alpha3: 'GRC',
    countryCode: 300,
    iso3166Dash2: 'ISO 3166-2:GR',
    region: 'Europe',
    subRegion: 'Southern Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: '039',
    intermediateregionCode: '',
  },
  {
    name: 'Greenland',
    alpha2: 'GL',
    alpha3: 'GRL',
    countryCode: 304,
    iso3166Dash2: 'ISO 3166-2:GL',
    region: 'Americas',
    subRegion: 'Northern America',
    intermediateRegion: '',
    regionCode: '019',
    subregionCode: '021',
    intermediateregionCode: '',
  },
  {
    name: 'Grenada',
    alpha2: 'GD',
    alpha3: 'GRD',
    countryCode: 308,
    iso3166Dash2: 'ISO 3166-2:GD',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '029',
  },
  {
    name: 'Guadeloupe',
    alpha2: 'GP',
    alpha3: 'GLP',
    countryCode: 312,
    iso3166Dash2: 'ISO 3166-2:GP',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '029',
  },
  {
    name: 'Guam',
    alpha2: 'GU',
    alpha3: 'GUM',
    countryCode: 316,
    iso3166Dash2: 'ISO 3166-2:GU',
    region: 'Oceania',
    subRegion: 'Micronesia',
    intermediateRegion: '',
    regionCode: '009',
    subregionCode: '057',
    intermediateregionCode: '',
  },
  {
    name: 'Guatemala',
    alpha2: 'GT',
    alpha3: 'GTM',
    countryCode: 320,
    iso3166Dash2: 'ISO 3166-2:GT',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Central America',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '013',
  },
  {
    name: 'Guernsey',
    alpha2: 'GG',
    alpha3: 'GGY',
    countryCode: 831,
    iso3166Dash2: 'ISO 3166-2:GG',
    region: 'Europe',
    subRegion: 'Northern Europe',
    intermediateRegion: 'Channel Islands',
    regionCode: 150,
    subregionCode: 154,
    intermediateregionCode: 830,
  },
  {
    name: 'Guinea',
    alpha2: 'GN',
    alpha3: 'GIN',
    countryCode: 324,
    iso3166Dash2: 'ISO 3166-2:GN',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Western Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '011',
  },
  {
    name: 'Guinea-Bissau',
    alpha2: 'GW',
    alpha3: 'GNB',
    countryCode: 624,
    iso3166Dash2: 'ISO 3166-2:GW',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Western Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '011',
  },
  {
    name: 'Guyana',
    alpha2: 'GY',
    alpha3: 'GUY',
    countryCode: 328,
    iso3166Dash2: 'ISO 3166-2:GY',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'South America',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '005',
  },
  {
    name: 'Haiti',
    alpha2: 'HT',
    alpha3: 'HTI',
    countryCode: 332,
    iso3166Dash2: 'ISO 3166-2:HT',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '029',
  },
  {
    name: 'Heard Island and McDonald Islands',
    alpha2: 'HM',
    alpha3: 'HMD',
    countryCode: 334,
    iso3166Dash2: 'ISO 3166-2:HM',
    region: 'Oceania',
    subRegion: 'Australia and New Zealand',
    intermediateRegion: '',
    regionCode: '009',
    subregionCode: '053',
    intermediateregionCode: '',
  },
  {
    name: 'Holy See',
    alpha2: 'VA',
    alpha3: 'VAT',
    countryCode: 336,
    iso3166Dash2: 'ISO 3166-2:VA',
    region: 'Europe',
    subRegion: 'Southern Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: '039',
    intermediateregionCode: '',
  },
  {
    name: 'Honduras',
    alpha2: 'HN',
    alpha3: 'HND',
    countryCode: 340,
    iso3166Dash2: 'ISO 3166-2:HN',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Central America',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '013',
  },
  {
    name: 'Hong Kong',
    alpha2: 'HK',
    alpha3: 'HKG',
    countryCode: 344,
    iso3166Dash2: 'ISO 3166-2:HK',
    region: 'Asia',
    subRegion: 'Eastern Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: '030',
    intermediateregionCode: '',
  },
  {
    name: 'Hungary',
    alpha2: 'HU',
    alpha3: 'HUN',
    countryCode: 348,
    iso3166Dash2: 'ISO 3166-2:HU',
    region: 'Europe',
    subRegion: 'Eastern Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: 151,
    intermediateregionCode: '',
  },
  {
    name: 'Iceland',
    alpha2: 'IS',
    alpha3: 'ISL',
    countryCode: 352,
    iso3166Dash2: 'ISO 3166-2:IS',
    region: 'Europe',
    subRegion: 'Northern Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: 154,
    intermediateregionCode: '',
  },
  {
    name: 'India',
    alpha2: 'IN',
    alpha3: 'IND',
    countryCode: 356,
    iso3166Dash2: 'ISO 3166-2:IN',
    region: 'Asia',
    subRegion: 'Southern Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: '034',
    intermediateregionCode: '',
  },
  {
    name: 'Indonesia',
    alpha2: 'ID',
    alpha3: 'IDN',
    countryCode: 360,
    iso3166Dash2: 'ISO 3166-2:ID',
    region: 'Asia',
    subRegion: 'South-eastern Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: '035',
    intermediateregionCode: '',
  },
  {
    name: 'Iran (Islamic Republic of)',
    alpha2: 'IR',
    alpha3: 'IRN',
    countryCode: 364,
    iso3166Dash2: 'ISO 3166-2:IR',
    region: 'Asia',
    subRegion: 'Southern Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: '034',
    intermediateregionCode: '',
  },
  {
    name: 'Iraq',
    alpha2: 'IQ',
    alpha3: 'IRQ',
    countryCode: 368,
    iso3166Dash2: 'ISO 3166-2:IQ',
    region: 'Asia',
    subRegion: 'Western Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: 145,
    intermediateregionCode: '',
  },
  {
    name: 'Ireland',
    alpha2: 'IE',
    alpha3: 'IRL',
    countryCode: 372,
    iso3166Dash2: 'ISO 3166-2:IE',
    region: 'Europe',
    subRegion: 'Northern Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: 154,
    intermediateregionCode: '',
  },
  {
    name: 'Isle of Man',
    alpha2: 'IM',
    alpha3: 'IMN',
    countryCode: 833,
    iso3166Dash2: 'ISO 3166-2:IM',
    region: 'Europe',
    subRegion: 'Northern Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: 154,
    intermediateregionCode: '',
  },
  {
    name: 'Israel',
    alpha2: 'IL',
    alpha3: 'ISR',
    countryCode: 376,
    iso3166Dash2: 'ISO 3166-2:IL',
    region: 'Asia',
    subRegion: 'Western Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: 145,
    intermediateregionCode: '',
  },
  {
    name: 'Italy',
    alpha2: 'IT',
    alpha3: 'ITA',
    countryCode: 380,
    iso3166Dash2: 'ISO 3166-2:IT',
    region: 'Europe',
    subRegion: 'Southern Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: '039',
    intermediateregionCode: '',
  },
  {
    name: 'Jamaica',
    alpha2: 'JM',
    alpha3: 'JAM',
    countryCode: 388,
    iso3166Dash2: 'ISO 3166-2:JM',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '029',
  },
  {
    name: 'Japan',
    alpha2: 'JP',
    alpha3: 'JPN',
    countryCode: 392,
    iso3166Dash2: 'ISO 3166-2:JP',
    region: 'Asia',
    subRegion: 'Eastern Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: '030',
    intermediateregionCode: '',
  },
  {
    name: 'Jersey',
    alpha2: 'JE',
    alpha3: 'JEY',
    countryCode: 832,
    iso3166Dash2: 'ISO 3166-2:JE',
    region: 'Europe',
    subRegion: 'Northern Europe',
    intermediateRegion: 'Channel Islands',
    regionCode: 150,
    subregionCode: 154,
    intermediateregionCode: 830,
  },
  {
    name: 'Jordan',
    alpha2: 'JO',
    alpha3: 'JOR',
    countryCode: 400,
    iso3166Dash2: 'ISO 3166-2:JO',
    region: 'Asia',
    subRegion: 'Western Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: 145,
    intermediateregionCode: '',
  },
  {
    name: 'Kazakhstan',
    alpha2: 'KZ',
    alpha3: 'KAZ',
    countryCode: 398,
    iso3166Dash2: 'ISO 3166-2:KZ',
    region: 'Asia',
    subRegion: 'Central Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: 143,
    intermediateregionCode: '',
  },
  {
    name: 'Kenya',
    alpha2: 'KE',
    alpha3: 'KEN',
    countryCode: 404,
    iso3166Dash2: 'ISO 3166-2:KE',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Eastern Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '014',
  },
  {
    name: 'Kiribati',
    alpha2: 'KI',
    alpha3: 'KIR',
    countryCode: 296,
    iso3166Dash2: 'ISO 3166-2:KI',
    region: 'Oceania',
    subRegion: 'Micronesia',
    intermediateRegion: '',
    regionCode: '009',
    subregionCode: '057',
    intermediateregionCode: '',
  },
  {
    name: "Korea (Democratic People's Republic of)",
    alpha2: 'KP',
    alpha3: 'PRK',
    countryCode: 408,
    iso3166Dash2: 'ISO 3166-2:KP',
    region: 'Asia',
    subRegion: 'Eastern Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: '030',
    intermediateregionCode: '',
  },
  {
    name: 'Korea, Republic of',
    alpha2: 'KR',
    alpha3: 'KOR',
    countryCode: 410,
    iso3166Dash2: 'ISO 3166-2:KR',
    region: 'Asia',
    subRegion: 'Eastern Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: '030',
    intermediateregionCode: '',
  },
  {
    name: 'Kuwait',
    alpha2: 'KW',
    alpha3: 'KWT',
    countryCode: 414,
    iso3166Dash2: 'ISO 3166-2:KW',
    region: 'Asia',
    subRegion: 'Western Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: 145,
    intermediateregionCode: '',
  },
  {
    name: 'Kyrgyzstan',
    alpha2: 'KG',
    alpha3: 'KGZ',
    countryCode: 417,
    iso3166Dash2: 'ISO 3166-2:KG',
    region: 'Asia',
    subRegion: 'Central Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: 143,
    intermediateregionCode: '',
  },
  {
    name: "Lao People's Democratic Republic",
    alpha2: 'LA',
    alpha3: 'LAO',
    countryCode: 418,
    iso3166Dash2: 'ISO 3166-2:LA',
    region: 'Asia',
    subRegion: 'South-eastern Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: '035',
    intermediateregionCode: '',
  },
  {
    name: 'Latvia',
    alpha2: 'LV',
    alpha3: 'LVA',
    countryCode: 428,
    iso3166Dash2: 'ISO 3166-2:LV',
    region: 'Europe',
    subRegion: 'Northern Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: 154,
    intermediateregionCode: '',
  },
  {
    name: 'Lebanon',
    alpha2: 'LB',
    alpha3: 'LBN',
    countryCode: 422,
    iso3166Dash2: 'ISO 3166-2:LB',
    region: 'Asia',
    subRegion: 'Western Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: 145,
    intermediateregionCode: '',
  },
  {
    name: 'Lesotho',
    alpha2: 'LS',
    alpha3: 'LSO',
    countryCode: 426,
    iso3166Dash2: 'ISO 3166-2:LS',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Southern Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '018',
  },
  {
    name: 'Liberia',
    alpha2: 'LR',
    alpha3: 'LBR',
    countryCode: 430,
    iso3166Dash2: 'ISO 3166-2:LR',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Western Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '011',
  },
  {
    name: 'Libya',
    alpha2: 'LY',
    alpha3: 'LBY',
    countryCode: 434,
    iso3166Dash2: 'ISO 3166-2:LY',
    region: 'Africa',
    subRegion: 'Northern Africa',
    intermediateRegion: '',
    regionCode: '002',
    subregionCode: '015',
    intermediateregionCode: '',
  },
  {
    name: 'Liechtenstein',
    alpha2: 'LI',
    alpha3: 'LIE',
    countryCode: 438,
    iso3166Dash2: 'ISO 3166-2:LI',
    region: 'Europe',
    subRegion: 'Western Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: 155,
    intermediateregionCode: '',
  },
  {
    name: 'Lithuania',
    alpha2: 'LT',
    alpha3: 'LTU',
    countryCode: 440,
    iso3166Dash2: 'ISO 3166-2:LT',
    region: 'Europe',
    subRegion: 'Northern Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: 154,
    intermediateregionCode: '',
  },
  {
    name: 'Luxembourg',
    alpha2: 'LU',
    alpha3: 'LUX',
    countryCode: 442,
    iso3166Dash2: 'ISO 3166-2:LU',
    region: 'Europe',
    subRegion: 'Western Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: 155,
    intermediateregionCode: '',
  },
  {
    name: 'Macao',
    alpha2: 'MO',
    alpha3: 'MAC',
    countryCode: 446,
    iso3166Dash2: 'ISO 3166-2:MO',
    region: 'Asia',
    subRegion: 'Eastern Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: '030',
    intermediateregionCode: '',
  },
  {
    name: 'Madagascar',
    alpha2: 'MG',
    alpha3: 'MDG',
    countryCode: 450,
    iso3166Dash2: 'ISO 3166-2:MG',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Eastern Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '014',
  },
  {
    name: 'Malawi',
    alpha2: 'MW',
    alpha3: 'MWI',
    countryCode: 454,
    iso3166Dash2: 'ISO 3166-2:MW',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Eastern Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '014',
  },
  {
    name: 'Malaysia',
    alpha2: 'MY',
    alpha3: 'MYS',
    countryCode: 458,
    iso3166Dash2: 'ISO 3166-2:MY',
    region: 'Asia',
    subRegion: 'South-eastern Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: '035',
    intermediateregionCode: '',
  },
  {
    name: 'Maldives',
    alpha2: 'MV',
    alpha3: 'MDV',
    countryCode: 462,
    iso3166Dash2: 'ISO 3166-2:MV',
    region: 'Asia',
    subRegion: 'Southern Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: '034',
    intermediateregionCode: '',
  },
  {
    name: 'Mali',
    alpha2: 'ML',
    alpha3: 'MLI',
    countryCode: 466,
    iso3166Dash2: 'ISO 3166-2:ML',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Western Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '011',
  },
  {
    name: 'Malta',
    alpha2: 'MT',
    alpha3: 'MLT',
    countryCode: 470,
    iso3166Dash2: 'ISO 3166-2:MT',
    region: 'Europe',
    subRegion: 'Southern Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: '039',
    intermediateregionCode: '',
  },
  {
    name: 'Marshall Islands',
    alpha2: 'MH',
    alpha3: 'MHL',
    countryCode: 584,
    iso3166Dash2: 'ISO 3166-2:MH',
    region: 'Oceania',
    subRegion: 'Micronesia',
    intermediateRegion: '',
    regionCode: '009',
    subregionCode: '057',
    intermediateregionCode: '',
  },
  {
    name: 'Martinique',
    alpha2: 'MQ',
    alpha3: 'MTQ',
    countryCode: 474,
    iso3166Dash2: 'ISO 3166-2:MQ',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '029',
  },
  {
    name: 'Mauritania',
    alpha2: 'MR',
    alpha3: 'MRT',
    countryCode: 478,
    iso3166Dash2: 'ISO 3166-2:MR',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Western Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '011',
  },
  {
    name: 'Mauritius',
    alpha2: 'MU',
    alpha3: 'MUS',
    countryCode: 480,
    iso3166Dash2: 'ISO 3166-2:MU',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Eastern Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '014',
  },
  {
    name: 'Mayotte',
    alpha2: 'YT',
    alpha3: 'MYT',
    countryCode: 175,
    iso3166Dash2: 'ISO 3166-2:YT',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Eastern Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '014',
  },
  {
    name: 'Mexico',
    alpha2: 'MX',
    alpha3: 'MEX',
    countryCode: 484,
    iso3166Dash2: 'ISO 3166-2:MX',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Central America',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '013',
  },
  {
    name: 'Micronesia (Federated States of)',
    alpha2: 'FM',
    alpha3: 'FSM',
    countryCode: 583,
    iso3166Dash2: 'ISO 3166-2:FM',
    region: 'Oceania',
    subRegion: 'Micronesia',
    intermediateRegion: '',
    regionCode: '009',
    subregionCode: '057',
    intermediateregionCode: '',
  },
  {
    name: 'Moldova, Republic of',
    alpha2: 'MD',
    alpha3: 'MDA',
    countryCode: 498,
    iso3166Dash2: 'ISO 3166-2:MD',
    region: 'Europe',
    subRegion: 'Eastern Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: 151,
    intermediateregionCode: '',
  },
  {
    name: 'Monaco',
    alpha2: 'MC',
    alpha3: 'MCO',
    countryCode: 492,
    iso3166Dash2: 'ISO 3166-2:MC',
    region: 'Europe',
    subRegion: 'Western Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: 155,
    intermediateregionCode: '',
  },
  {
    name: 'Mongolia',
    alpha2: 'MN',
    alpha3: 'MNG',
    countryCode: 496,
    iso3166Dash2: 'ISO 3166-2:MN',
    region: 'Asia',
    subRegion: 'Eastern Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: '030',
    intermediateregionCode: '',
  },
  {
    name: 'Montenegro',
    alpha2: 'ME',
    alpha3: 'MNE',
    countryCode: 499,
    iso3166Dash2: 'ISO 3166-2:ME',
    region: 'Europe',
    subRegion: 'Southern Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: '039',
    intermediateregionCode: '',
  },
  {
    name: 'Montserrat',
    alpha2: 'MS',
    alpha3: 'MSR',
    countryCode: 500,
    iso3166Dash2: 'ISO 3166-2:MS',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '029',
  },
  {
    name: 'Morocco',
    alpha2: 'MA',
    alpha3: 'MAR',
    countryCode: 504,
    iso3166Dash2: 'ISO 3166-2:MA',
    region: 'Africa',
    subRegion: 'Northern Africa',
    intermediateRegion: '',
    regionCode: '002',
    subregionCode: '015',
    intermediateregionCode: '',
  },
  {
    name: 'Mozambique',
    alpha2: 'MZ',
    alpha3: 'MOZ',
    countryCode: 508,
    iso3166Dash2: 'ISO 3166-2:MZ',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Eastern Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '014',
  },
  {
    name: 'Myanmar',
    alpha2: 'MM',
    alpha3: 'MMR',
    countryCode: 104,
    iso3166Dash2: 'ISO 3166-2:MM',
    region: 'Asia',
    subRegion: 'South-eastern Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: '035',
    intermediateregionCode: '',
  },
  {
    name: 'Namibia',
    alpha2: 'NA',
    alpha3: 'NAM',
    countryCode: 516,
    iso3166Dash2: 'ISO 3166-2:NA',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Southern Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '018',
  },
  {
    name: 'Nauru',
    alpha2: 'NR',
    alpha3: 'NRU',
    countryCode: 520,
    iso3166Dash2: 'ISO 3166-2:NR',
    region: 'Oceania',
    subRegion: 'Micronesia',
    intermediateRegion: '',
    regionCode: '009',
    subregionCode: '057',
    intermediateregionCode: '',
  },
  {
    name: 'Nepal',
    alpha2: 'NP',
    alpha3: 'NPL',
    countryCode: 524,
    iso3166Dash2: 'ISO 3166-2:NP',
    region: 'Asia',
    subRegion: 'Southern Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: '034',
    intermediateregionCode: '',
  },
  {
    name: 'Netherlands',
    alpha2: 'NL',
    alpha3: 'NLD',
    countryCode: 528,
    iso3166Dash2: 'ISO 3166-2:NL',
    region: 'Europe',
    subRegion: 'Western Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: 155,
    intermediateregionCode: '',
  },
  {
    name: 'New Caledonia',
    alpha2: 'NC',
    alpha3: 'NCL',
    countryCode: 540,
    iso3166Dash2: 'ISO 3166-2:NC',
    region: 'Oceania',
    subRegion: 'Melanesia',
    intermediateRegion: '',
    regionCode: '009',
    subregionCode: '054',
    intermediateregionCode: '',
  },
  {
    name: 'New Zealand',
    alpha2: 'NZ',
    alpha3: 'NZL',
    countryCode: 554,
    iso3166Dash2: 'ISO 3166-2:NZ',
    region: 'Oceania',
    subRegion: 'Australia and New Zealand',
    intermediateRegion: '',
    regionCode: '009',
    subregionCode: '053',
    intermediateregionCode: '',
  },
  {
    name: 'Nicaragua',
    alpha2: 'NI',
    alpha3: 'NIC',
    countryCode: 558,
    iso3166Dash2: 'ISO 3166-2:NI',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Central America',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '013',
  },
  {
    name: 'Niger',
    alpha2: 'NE',
    alpha3: 'NER',
    countryCode: 562,
    iso3166Dash2: 'ISO 3166-2:NE',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Western Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '011',
  },
  {
    name: 'Nigeria',
    alpha2: 'NG',
    alpha3: 'NGA',
    countryCode: 566,
    iso3166Dash2: 'ISO 3166-2:NG',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Western Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '011',
  },
  {
    name: 'Niue',
    alpha2: 'NU',
    alpha3: 'NIU',
    countryCode: 570,
    iso3166Dash2: 'ISO 3166-2:NU',
    region: 'Oceania',
    subRegion: 'Polynesia',
    intermediateRegion: '',
    regionCode: '009',
    subregionCode: '061',
    intermediateregionCode: '',
  },
  {
    name: 'Norfolk Island',
    alpha2: 'NF',
    alpha3: 'NFK',
    countryCode: 574,
    iso3166Dash2: 'ISO 3166-2:NF',
    region: 'Oceania',
    subRegion: 'Australia and New Zealand',
    intermediateRegion: '',
    regionCode: '009',
    subregionCode: '053',
    intermediateregionCode: '',
  },
  {
    name: 'North Macedonia',
    alpha2: 'MK',
    alpha3: 'MKD',
    countryCode: 807,
    iso3166Dash2: 'ISO 3166-2:MK',
    region: 'Europe',
    subRegion: 'Southern Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: '039',
    intermediateregionCode: '',
  },
  {
    name: 'Northern Mariana Islands',
    alpha2: 'MP',
    alpha3: 'MNP',
    countryCode: 580,
    iso3166Dash2: 'ISO 3166-2:MP',
    region: 'Oceania',
    subRegion: 'Micronesia',
    intermediateRegion: '',
    regionCode: '009',
    subregionCode: '057',
    intermediateregionCode: '',
  },
  {
    name: 'Norway',
    alpha2: 'NO',
    alpha3: 'NOR',
    countryCode: 578,
    iso3166Dash2: 'ISO 3166-2:NO',
    region: 'Europe',
    subRegion: 'Northern Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: 154,
    intermediateregionCode: '',
  },
  {
    name: 'Oman',
    alpha2: 'OM',
    alpha3: 'OMN',
    countryCode: 512,
    iso3166Dash2: 'ISO 3166-2:OM',
    region: 'Asia',
    subRegion: 'Western Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: 145,
    intermediateregionCode: '',
  },
  {
    name: 'Pakistan',
    alpha2: 'PK',
    alpha3: 'PAK',
    countryCode: 586,
    iso3166Dash2: 'ISO 3166-2:PK',
    region: 'Asia',
    subRegion: 'Southern Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: '034',
    intermediateregionCode: '',
  },
  {
    name: 'Palau',
    alpha2: 'PW',
    alpha3: 'PLW',
    countryCode: 585,
    iso3166Dash2: 'ISO 3166-2:PW',
    region: 'Oceania',
    subRegion: 'Micronesia',
    intermediateRegion: '',
    regionCode: '009',
    subregionCode: '057',
    intermediateregionCode: '',
  },
  {
    name: 'Palestine, State of',
    alpha2: 'PS',
    alpha3: 'PSE',
    countryCode: 275,
    iso3166Dash2: 'ISO 3166-2:PS',
    region: 'Asia',
    subRegion: 'Western Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: 145,
    intermediateregionCode: '',
  },
  {
    name: 'Panama',
    alpha2: 'PA',
    alpha3: 'PAN',
    countryCode: 591,
    iso3166Dash2: 'ISO 3166-2:PA',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Central America',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '013',
  },
  {
    name: 'Papua New Guinea',
    alpha2: 'PG',
    alpha3: 'PNG',
    countryCode: 598,
    iso3166Dash2: 'ISO 3166-2:PG',
    region: 'Oceania',
    subRegion: 'Melanesia',
    intermediateRegion: '',
    regionCode: '009',
    subregionCode: '054',
    intermediateregionCode: '',
  },
  {
    name: 'Paraguay',
    alpha2: 'PY',
    alpha3: 'PRY',
    countryCode: 600,
    iso3166Dash2: 'ISO 3166-2:PY',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'South America',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '005',
  },
  {
    name: 'Peru',
    alpha2: 'PE',
    alpha3: 'PER',
    countryCode: 604,
    iso3166Dash2: 'ISO 3166-2:PE',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'South America',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '005',
  },
  {
    name: 'Philippines',
    alpha2: 'PH',
    alpha3: 'PHL',
    countryCode: 608,
    iso3166Dash2: 'ISO 3166-2:PH',
    region: 'Asia',
    subRegion: 'South-eastern Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: '035',
    intermediateregionCode: '',
  },
  {
    name: 'Pitcairn',
    alpha2: 'PN',
    alpha3: 'PCN',
    countryCode: 612,
    iso3166Dash2: 'ISO 3166-2:PN',
    region: 'Oceania',
    subRegion: 'Polynesia',
    intermediateRegion: '',
    regionCode: '009',
    subregionCode: '061',
    intermediateregionCode: '',
  },
  {
    name: 'Poland',
    alpha2: 'PL',
    alpha3: 'POL',
    countryCode: 616,
    iso3166Dash2: 'ISO 3166-2:PL',
    region: 'Europe',
    subRegion: 'Eastern Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: 151,
    intermediateregionCode: '',
  },
  {
    name: 'Portugal',
    alpha2: 'PT',
    alpha3: 'PRT',
    countryCode: 620,
    iso3166Dash2: 'ISO 3166-2:PT',
    region: 'Europe',
    subRegion: 'Southern Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: '039',
    intermediateregionCode: '',
  },
  {
    name: 'Puerto Rico',
    alpha2: 'PR',
    alpha3: 'PRI',
    countryCode: 630,
    iso3166Dash2: 'ISO 3166-2:PR',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '029',
  },
  {
    name: 'Qatar',
    alpha2: 'QA',
    alpha3: 'QAT',
    countryCode: 634,
    iso3166Dash2: 'ISO 3166-2:QA',
    region: 'Asia',
    subRegion: 'Western Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: 145,
    intermediateregionCode: '',
  },
  {
    name: 'Réunion',
    alpha2: 'RE',
    alpha3: 'REU',
    countryCode: 638,
    iso3166Dash2: 'ISO 3166-2:RE',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Eastern Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '014',
  },
  {
    name: 'Romania',
    alpha2: 'RO',
    alpha3: 'ROU',
    countryCode: 642,
    iso3166Dash2: 'ISO 3166-2:RO',
    region: 'Europe',
    subRegion: 'Eastern Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: 151,
    intermediateregionCode: '',
  },
  {
    name: 'Russian Federation',
    alpha2: 'RU',
    alpha3: 'RUS',
    countryCode: 643,
    iso3166Dash2: 'ISO 3166-2:RU',
    region: 'Europe',
    subRegion: 'Eastern Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: 151,
    intermediateregionCode: '',
  },
  {
    name: 'Rwanda',
    alpha2: 'RW',
    alpha3: 'RWA',
    countryCode: 646,
    iso3166Dash2: 'ISO 3166-2:RW',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Eastern Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '014',
  },
  {
    name: 'Saint Barthélemy',
    alpha2: 'BL',
    alpha3: 'BLM',
    countryCode: 652,
    iso3166Dash2: 'ISO 3166-2:BL',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '029',
  },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    alpha2: 'SH',
    alpha3: 'SHN',
    countryCode: 654,
    iso3166Dash2: 'ISO 3166-2:SH',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Western Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '011',
  },
  {
    name: 'Saint Kitts and Nevis',
    alpha2: 'KN',
    alpha3: 'KNA',
    countryCode: 659,
    iso3166Dash2: 'ISO 3166-2:KN',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '029',
  },
  {
    name: 'Saint Lucia',
    alpha2: 'LC',
    alpha3: 'LCA',
    countryCode: 662,
    iso3166Dash2: 'ISO 3166-2:LC',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '029',
  },
  {
    name: 'Saint Martin (French part)',
    alpha2: 'MF',
    alpha3: 'MAF',
    countryCode: 663,
    iso3166Dash2: 'ISO 3166-2:MF',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '029',
  },
  {
    name: 'Saint Pierre and Miquelon',
    alpha2: 'PM',
    alpha3: 'SPM',
    countryCode: 666,
    iso3166Dash2: 'ISO 3166-2:PM',
    region: 'Americas',
    subRegion: 'Northern America',
    intermediateRegion: '',
    regionCode: '019',
    subregionCode: '021',
    intermediateregionCode: '',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    alpha2: 'VC',
    alpha3: 'VCT',
    countryCode: 670,
    iso3166Dash2: 'ISO 3166-2:VC',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '029',
  },
  {
    name: 'Samoa',
    alpha2: 'WS',
    alpha3: 'WSM',
    countryCode: 882,
    iso3166Dash2: 'ISO 3166-2:WS',
    region: 'Oceania',
    subRegion: 'Polynesia',
    intermediateRegion: '',
    regionCode: '009',
    subregionCode: '061',
    intermediateregionCode: '',
  },
  {
    name: 'San Marino',
    alpha2: 'SM',
    alpha3: 'SMR',
    countryCode: 674,
    iso3166Dash2: 'ISO 3166-2:SM',
    region: 'Europe',
    subRegion: 'Southern Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: '039',
    intermediateregionCode: '',
  },
  {
    name: 'Sao Tome and Principe',
    alpha2: 'ST',
    alpha3: 'STP',
    countryCode: 678,
    iso3166Dash2: 'ISO 3166-2:ST',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Middle Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '017',
  },
  {
    name: 'Saudi Arabia',
    alpha2: 'SA',
    alpha3: 'SAU',
    countryCode: 682,
    iso3166Dash2: 'ISO 3166-2:SA',
    region: 'Asia',
    subRegion: 'Western Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: 145,
    intermediateregionCode: '',
  },
  {
    name: 'Senegal',
    alpha2: 'SN',
    alpha3: 'SEN',
    countryCode: 686,
    iso3166Dash2: 'ISO 3166-2:SN',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Western Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '011',
  },
  {
    name: 'Serbia',
    alpha2: 'RS',
    alpha3: 'SRB',
    countryCode: 688,
    iso3166Dash2: 'ISO 3166-2:RS',
    region: 'Europe',
    subRegion: 'Southern Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: '039',
    intermediateregionCode: '',
  },
  {
    name: 'Seychelles',
    alpha2: 'SC',
    alpha3: 'SYC',
    countryCode: 690,
    iso3166Dash2: 'ISO 3166-2:SC',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Eastern Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '014',
  },
  {
    name: 'Sierra Leone',
    alpha2: 'SL',
    alpha3: 'SLE',
    countryCode: 694,
    iso3166Dash2: 'ISO 3166-2:SL',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Western Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '011',
  },
  {
    name: 'Singapore',
    alpha2: 'SG',
    alpha3: 'SGP',
    countryCode: 702,
    iso3166Dash2: 'ISO 3166-2:SG',
    region: 'Asia',
    subRegion: 'South-eastern Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: '035',
    intermediateregionCode: '',
  },
  {
    name: 'Sint Maarten (Dutch part)',
    alpha2: 'SX',
    alpha3: 'SXM',
    countryCode: 534,
    iso3166Dash2: 'ISO 3166-2:SX',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '029',
  },
  {
    name: 'Slovakia',
    alpha2: 'SK',
    alpha3: 'SVK',
    countryCode: 703,
    iso3166Dash2: 'ISO 3166-2:SK',
    region: 'Europe',
    subRegion: 'Eastern Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: 151,
    intermediateregionCode: '',
  },
  {
    name: 'Slovenia',
    alpha2: 'SI',
    alpha3: 'SVN',
    countryCode: 705,
    iso3166Dash2: 'ISO 3166-2:SI',
    region: 'Europe',
    subRegion: 'Southern Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: '039',
    intermediateregionCode: '',
  },
  {
    name: 'Solomon Islands',
    alpha2: 'SB',
    alpha3: 'SLB',
    countryCode: '090',
    iso3166Dash2: 'ISO 3166-2:SB',
    region: 'Oceania',
    subRegion: 'Melanesia',
    intermediateRegion: '',
    regionCode: '009',
    subregionCode: '054',
    intermediateregionCode: '',
  },
  {
    name: 'Somalia',
    alpha2: 'SO',
    alpha3: 'SOM',
    countryCode: 706,
    iso3166Dash2: 'ISO 3166-2:SO',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Eastern Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '014',
  },
  {
    name: 'South Africa',
    alpha2: 'ZA',
    alpha3: 'ZAF',
    countryCode: 710,
    iso3166Dash2: 'ISO 3166-2:ZA',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Southern Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '018',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    alpha2: 'GS',
    alpha3: 'SGS',
    countryCode: 239,
    iso3166Dash2: 'ISO 3166-2:GS',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'South America',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '005',
  },
  {
    name: 'South Sudan',
    alpha2: 'SS',
    alpha3: 'SSD',
    countryCode: 728,
    iso3166Dash2: 'ISO 3166-2:SS',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Eastern Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '014',
  },
  {
    name: 'Spain',
    alpha2: 'ES',
    alpha3: 'ESP',
    countryCode: 724,
    iso3166Dash2: 'ISO 3166-2:ES',
    region: 'Europe',
    subRegion: 'Southern Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: '039',
    intermediateregionCode: '',
  },
  {
    name: 'Sri Lanka',
    alpha2: 'LK',
    alpha3: 'LKA',
    countryCode: 144,
    iso3166Dash2: 'ISO 3166-2:LK',
    region: 'Asia',
    subRegion: 'Southern Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: '034',
    intermediateregionCode: '',
  },
  {
    name: 'Sudan',
    alpha2: 'SD',
    alpha3: 'SDN',
    countryCode: 729,
    iso3166Dash2: 'ISO 3166-2:SD',
    region: 'Africa',
    subRegion: 'Northern Africa',
    intermediateRegion: '',
    regionCode: '002',
    subregionCode: '015',
    intermediateregionCode: '',
  },
  {
    name: 'Suriname',
    alpha2: 'SR',
    alpha3: 'SUR',
    countryCode: 740,
    iso3166Dash2: 'ISO 3166-2:SR',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'South America',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '005',
  },
  {
    name: 'Svalbard and Jan Mayen',
    alpha2: 'SJ',
    alpha3: 'SJM',
    countryCode: 744,
    iso3166Dash2: 'ISO 3166-2:SJ',
    region: 'Europe',
    subRegion: 'Northern Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: 154,
    intermediateregionCode: '',
  },
  {
    name: 'Sweden',
    alpha2: 'SE',
    alpha3: 'SWE',
    countryCode: 752,
    iso3166Dash2: 'ISO 3166-2:SE',
    region: 'Europe',
    subRegion: 'Northern Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: 154,
    intermediateregionCode: '',
  },
  {
    name: 'Switzerland',
    alpha2: 'CH',
    alpha3: 'CHE',
    countryCode: 756,
    iso3166Dash2: 'ISO 3166-2:CH',
    region: 'Europe',
    subRegion: 'Western Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: 155,
    intermediateregionCode: '',
  },
  {
    name: 'Syrian Arab Republic',
    alpha2: 'SY',
    alpha3: 'SYR',
    countryCode: 760,
    iso3166Dash2: 'ISO 3166-2:SY',
    region: 'Asia',
    subRegion: 'Western Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: 145,
    intermediateregionCode: '',
  },
  {
    name: 'Taiwan, Province of China',
    alpha2: 'TW',
    alpha3: 'TWN',
    countryCode: 158,
    iso3166Dash2: 'ISO 3166-2:TW',
    region: 'Asia',
    subRegion: 'Eastern Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: '030',
    intermediateregionCode: '',
  },
  {
    name: 'Tajikistan',
    alpha2: 'TJ',
    alpha3: 'TJK',
    countryCode: 762,
    iso3166Dash2: 'ISO 3166-2:TJ',
    region: 'Asia',
    subRegion: 'Central Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: 143,
    intermediateregionCode: '',
  },
  {
    name: 'Tanzania, United Republic of',
    alpha2: 'TZ',
    alpha3: 'TZA',
    countryCode: 834,
    iso3166Dash2: 'ISO 3166-2:TZ',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Eastern Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '014',
  },
  {
    name: 'Thailand',
    alpha2: 'TH',
    alpha3: 'THA',
    countryCode: 764,
    iso3166Dash2: 'ISO 3166-2:TH',
    region: 'Asia',
    subRegion: 'South-eastern Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: '035',
    intermediateregionCode: '',
  },
  {
    name: 'Timor-Leste',
    alpha2: 'TL',
    alpha3: 'TLS',
    countryCode: 626,
    iso3166Dash2: 'ISO 3166-2:TL',
    region: 'Asia',
    subRegion: 'South-eastern Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: '035',
    intermediateregionCode: '',
  },
  {
    name: 'Togo',
    alpha2: 'TG',
    alpha3: 'TGO',
    countryCode: 768,
    iso3166Dash2: 'ISO 3166-2:TG',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Western Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '011',
  },
  {
    name: 'Tokelau',
    alpha2: 'TK',
    alpha3: 'TKL',
    countryCode: 772,
    iso3166Dash2: 'ISO 3166-2:TK',
    region: 'Oceania',
    subRegion: 'Polynesia',
    intermediateRegion: '',
    regionCode: '009',
    subregionCode: '061',
    intermediateregionCode: '',
  },
  {
    name: 'Tonga',
    alpha2: 'TO',
    alpha3: 'TON',
    countryCode: 776,
    iso3166Dash2: 'ISO 3166-2:TO',
    region: 'Oceania',
    subRegion: 'Polynesia',
    intermediateRegion: '',
    regionCode: '009',
    subregionCode: '061',
    intermediateregionCode: '',
  },
  {
    name: 'Trinidad and Tobago',
    alpha2: 'TT',
    alpha3: 'TTO',
    countryCode: 780,
    iso3166Dash2: 'ISO 3166-2:TT',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '029',
  },
  {
    name: 'Tunisia',
    alpha2: 'TN',
    alpha3: 'TUN',
    countryCode: 788,
    iso3166Dash2: 'ISO 3166-2:TN',
    region: 'Africa',
    subRegion: 'Northern Africa',
    intermediateRegion: '',
    regionCode: '002',
    subregionCode: '015',
    intermediateregionCode: '',
  },
  {
    name: 'Turkey',
    alpha2: 'TR',
    alpha3: 'TUR',
    countryCode: 792,
    iso3166Dash2: 'ISO 3166-2:TR',
    region: 'Asia',
    subRegion: 'Western Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: 145,
    intermediateregionCode: '',
  },
  {
    name: 'Turkmenistan',
    alpha2: 'TM',
    alpha3: 'TKM',
    countryCode: 795,
    iso3166Dash2: 'ISO 3166-2:TM',
    region: 'Asia',
    subRegion: 'Central Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: 143,
    intermediateregionCode: '',
  },
  {
    name: 'Turks and Caicos Islands',
    alpha2: 'TC',
    alpha3: 'TCA',
    countryCode: 796,
    iso3166Dash2: 'ISO 3166-2:TC',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '029',
  },
  {
    name: 'Tuvalu',
    alpha2: 'TV',
    alpha3: 'TUV',
    countryCode: 798,
    iso3166Dash2: 'ISO 3166-2:TV',
    region: 'Oceania',
    subRegion: 'Polynesia',
    intermediateRegion: '',
    regionCode: '009',
    subregionCode: '061',
    intermediateregionCode: '',
  },
  {
    name: 'Uganda',
    alpha2: 'UG',
    alpha3: 'UGA',
    countryCode: 800,
    iso3166Dash2: 'ISO 3166-2:UG',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Eastern Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '014',
  },
  {
    name: 'Ukraine',
    alpha2: 'UA',
    alpha3: 'UKR',
    countryCode: 804,
    iso3166Dash2: 'ISO 3166-2:UA',
    region: 'Europe',
    subRegion: 'Eastern Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: 151,
    intermediateregionCode: '',
  },
  {
    name: 'United Arab Emirates',
    alpha2: 'AE',
    alpha3: 'ARE',
    countryCode: 784,
    iso3166Dash2: 'ISO 3166-2:AE',
    region: 'Asia',
    subRegion: 'Western Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: 145,
    intermediateregionCode: '',
  },
  {
    name: 'United Kingdom of Great Britain and Northern Ireland',
    alpha2: 'GB',
    alpha3: 'GBR',
    countryCode: 826,
    iso3166Dash2: 'ISO 3166-2:GB',
    region: 'Europe',
    subRegion: 'Northern Europe',
    intermediateRegion: '',
    regionCode: 150,
    subregionCode: 154,
    intermediateregionCode: '',
  },
  {
    name: 'United States of America',
    alpha2: 'US',
    alpha3: 'USA',
    countryCode: 840,
    iso3166Dash2: 'ISO 3166-2:US',
    region: 'Americas',
    subRegion: 'Northern America',
    intermediateRegion: '',
    regionCode: '019',
    subregionCode: '021',
    intermediateregionCode: '',
  },
  {
    name: 'United States Minor Outlying Islands',
    alpha2: 'UM',
    alpha3: 'UMI',
    countryCode: 581,
    iso3166Dash2: 'ISO 3166-2:UM',
    region: 'Oceania',
    subRegion: 'Micronesia',
    intermediateRegion: '',
    regionCode: '009',
    subregionCode: '057',
    intermediateregionCode: '',
  },
  {
    name: 'Uruguay',
    alpha2: 'UY',
    alpha3: 'URY',
    countryCode: 858,
    iso3166Dash2: 'ISO 3166-2:UY',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'South America',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '005',
  },
  {
    name: 'Uzbekistan',
    alpha2: 'UZ',
    alpha3: 'UZB',
    countryCode: 860,
    iso3166Dash2: 'ISO 3166-2:UZ',
    region: 'Asia',
    subRegion: 'Central Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: 143,
    intermediateregionCode: '',
  },
  {
    name: 'Vanuatu',
    alpha2: 'VU',
    alpha3: 'VUT',
    countryCode: 548,
    iso3166Dash2: 'ISO 3166-2:VU',
    region: 'Oceania',
    subRegion: 'Melanesia',
    intermediateRegion: '',
    regionCode: '009',
    subregionCode: '054',
    intermediateregionCode: '',
  },
  {
    name: 'Venezuela (Bolivarian Republic of)',
    alpha2: 'VE',
    alpha3: 'VEN',
    countryCode: 862,
    iso3166Dash2: 'ISO 3166-2:VE',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'South America',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '005',
  },
  {
    name: 'Viet Nam',
    alpha2: 'VN',
    alpha3: 'VNM',
    countryCode: 704,
    iso3166Dash2: 'ISO 3166-2:VN',
    region: 'Asia',
    subRegion: 'South-eastern Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: '035',
    intermediateregionCode: '',
  },
  {
    name: 'Virgin Islands (British)',
    alpha2: 'VG',
    alpha3: 'VGB',
    countryCode: '092',
    iso3166Dash2: 'ISO 3166-2:VG',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '029',
  },
  {
    name: 'Virgin Islands (U.S.)',
    alpha2: 'VI',
    alpha3: 'VIR',
    countryCode: 850,
    iso3166Dash2: 'ISO 3166-2:VI',
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    intermediateRegion: 'Caribbean',
    regionCode: '019',
    subregionCode: 419,
    intermediateregionCode: '029',
  },
  {
    name: 'Wallis and Futuna',
    alpha2: 'WF',
    alpha3: 'WLF',
    countryCode: 876,
    iso3166Dash2: 'ISO 3166-2:WF',
    region: 'Oceania',
    subRegion: 'Polynesia',
    intermediateRegion: '',
    regionCode: '009',
    subregionCode: '061',
    intermediateregionCode: '',
  },
  {
    name: 'Western Sahara',
    alpha2: 'EH',
    alpha3: 'ESH',
    countryCode: 732,
    iso3166Dash2: 'ISO 3166-2:EH',
    region: 'Africa',
    subRegion: 'Northern Africa',
    intermediateRegion: '',
    regionCode: '002',
    subregionCode: '015',
    intermediateregionCode: '',
  },
  {
    name: 'Yemen',
    alpha2: 'YE',
    alpha3: 'YEM',
    countryCode: 887,
    iso3166Dash2: 'ISO 3166-2:YE',
    region: 'Asia',
    subRegion: 'Western Asia',
    intermediateRegion: '',
    regionCode: 142,
    subregionCode: 145,
    intermediateregionCode: '',
  },
  {
    name: 'Zambia',
    alpha2: 'ZM',
    alpha3: 'ZMB',
    countryCode: 894,
    iso3166Dash2: 'ISO 3166-2:ZM',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Eastern Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '014',
  },
  {
    name: 'Zimbabwe',
    alpha2: 'ZW',
    alpha3: 'ZWE',
    countryCode: 716,
    iso3166Dash2: 'ISO 3166-2:ZW',
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    intermediateRegion: 'Eastern Africa',
    regionCode: '002',
    subregionCode: 202,
    intermediateregionCode: '014',
  },
];

export { countryCodeList };
