import logger from 'utils/logger';
import {
  SELECT_ACTION,
  SELECT_ACTION_ERROR,
  SELECT_REASON,
  SELECT_REASON_ERROR,
} from './constants.js';

import { ADJUSTMENT_ACTIONS_LABELS, ADJUSTMENT_REASONS_LABELS } from './constants.js';

export const initialState = {
  actions: ADJUSTMENT_ACTIONS_LABELS,
  reasons: [],
  selectedAction: '',
  selectedReason: '',
  selectedReasonError: '',
  selectedActionError: '',
};

export function reducer(state, action) {
  const { type, payload } = action;
  if (process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development') {
    logger.info(`Adjustment action reducer: ${type}: ${JSON.stringify(payload, null, 4)}`);
  }
  switch (type) {
    case SELECT_ACTION:
      return {
        ...state,
        reasons: ADJUSTMENT_REASONS_LABELS[payload],
        selectedAction: payload,
        selectedReason: payload === state.selectedAction ? state.selectedReason : '',
        selectedActionError: payload === state.selectedAction ? state.selectedActionError : '',
        selectedReasonError: payload === state.selectedAction ? state.selectedReasonError : '',
      };

    case SELECT_REASON:
      return {
        ...state,
        selectedReason: payload,
        selectedReasonError: payload === state.selectedReason ? state.selectedReasonError : '',
      };

    case SELECT_REASON_ERROR:
      return {
        ...state,
        selectedReasonError: payload,
      };

    case SELECT_ACTION_ERROR:
      return {
        ...state,
        selectedActionError: payload,
      };

    default:
      throw new Error(`Action of type: ${type} is not supported by Adjustment action reducer`);
  }
}
