import useTransactionListStateContext from './useTransactionListStateContext';

export default function useHasAuxToReturnTransaction() {
  const { hasRoleInArrayForActiveProgram } = useTransactionListStateContext();

  return !!hasRoleInArrayForActiveProgram([
    'cardholder-support',
    'compliance-internal',
    'compliance-processor-only',
    'marqeta-admin-internal',
    'production-support-internal',
    'program-admin',
    'risk-internal',
  ]);
}
