const networks = {
  NETWORK_DISCOVER: 'Discover',
  NETWORK_MASTERCARD: 'Mastercard',
  NETWORK_PULSE: 'Pulse',
  NETWORK_VISA: 'Visa',
  NETWORK_MARQETA: 'Marqeta',
  UNRECOGNIZED: 'Unrecognized',
  Discover: 'NETWORK_DISCOVER',
  Mastercard: 'NETWORK_MASTERCARD',
  Pulse: 'NETWORK_PULSE',
  Visa: 'NETWORK_VISA',
  Unrecognized: 'UNRECOGNIZED',
  Marqeta: 'NETWORK_MARQETA',
};

export default networks;
