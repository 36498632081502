export default function orderProgramsByFavoritesAlphabetically(programs) {
  const newPrograms = [...programs];
  return newPrograms.sort((a, b) => {
    const { program: programA, favorite: favoriteA } = a;
    const { program: programB, favorite: favoriteB } = b;

    if (favoriteA && favoriteB) {
      return sortAlphabetically(programA, programB);
    } else if (favoriteA) {
      return -1;
    } else if (favoriteB) {
      return 1;
    } else {
      return sortAlphabetically(programA, programB);
    }
  });
}

function sortAlphabetically(strA, strB) {
  const upperA = strA.toUpperCase();
  const upperB = strB.toUpperCase();
  if (upperA === upperB) {
    return 0;
  } else if (upperA > upperB) {
    return 1;
  }
  return -1;
}
