import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  DetailPane,
  Modal,
  Button,
  Select,
  Textarea,
  VSpacer,
  HSpacer,
  LoadingOverlay,
  Flex,
  Form,
  CopyToClipboard,
} from '@mqd/volt-base';
import { cancelReasons } from './cancelReasons.js';
import { transactionTypeData } from '../../../../../views/program/TransactionDetails/utils';
import titleize from '../../../../../utils/helpers/titleize';
import { parseDateTimeString } from '../../../../../utils/helpers/parseDateString';

const CancelTransferModal = (props) => {
  const { store, refreshTable, onSuccess, hideModal } = props;
  const [cancelModalError, setCancelModalError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { type, token, bankTransfer, bank_transfer_token } = store;
  const { status, created_time, achFundingSource } = bankTransfer || {};
  const { account_suffix } = achFundingSource || {};
  const [transformedType] = transactionTypeData(type);

  const onSubmit = async (values) => {
    setLoading(true);

    try {
      await store.cancelBankTransfer(values);
      refreshTable(); // refresh table because a new record should appear
      onSuccess();
      hideModal();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setCancelModalError(e);
    }
  };

  return (
    <Modal
      type="lg"
      maxHeightVh={90}
      heading="Cancel transfer"
      description="Please specify a reason for canceling this transfer."
      disableOverFlow={true}
      showHideModalButton={false}
      error={cancelModalError && 'Something went wrong with your request'}
    >
      <DetailPane.Container>
        <DetailPane.Content label="Date">{parseDateTimeString(created_time)}</DetailPane.Content>
        <DetailPane.Content label="Transaction type">{transformedType}</DetailPane.Content>
        <DetailPane.Content label="Token">
          <CopyToClipboard>{token}</CopyToClipboard>
        </DetailPane.Content>
        <DetailPane.Content label="Transfer status">{titleize(status)}</DetailPane.Content>
        <DetailPane.Content label="Last 4 of Acc No.">{account_suffix}</DetailPane.Content>
        <DetailPane.Content label="Transfer token">
          <CopyToClipboard>{bank_transfer_token}</CopyToClipboard>
        </DetailPane.Content>
      </DetailPane.Container>
      <VSpacer factor={3} />
      <Form>
        <LoadingOverlay active={loading}>
          <Select
            required
            label="Reason"
            labelFor="transaction_cancel_reason"
            name="reason"
            id="transaction_cancel_reason"
            options={cancelReasons}
          />
          <VSpacer factor={3} />
          <Textarea
            labelFor="transaction_cancel_description"
            name="description"
            id="transaction_cancel_description"
            label="Description (optional)"
            noResize={true}
            maxLength={150}
          />
          <VSpacer factor={3} />
          <Flex display="flex" justifyContent="flex-end">
            <Button type="tertiary" onClick={hideModal} disabled={loading}>
              Cancel
            </Button>
            <HSpacer />
            <Button
              submit
              testId="return_transition_modal_submit"
              type="primary"
              disabled={loading}
              onClick={({ reason = '', description = '' }) =>
                onSubmit({
                  reason: `${reason} ${description ? `- ${description}` : ''}`.trim(),
                })
              }
            >
              Confirm
            </Button>
          </Flex>
        </LoadingOverlay>
      </Form>
    </Modal>
  );
};

CancelTransferModal.propTypes = {
  hideModal: PropTypes.func,
  onSuccess: PropTypes.func,
  refreshTable: PropTypes.func,
  store: PropTypes.object,
};

CancelTransferModal.defaultProps = {
  hideModal: () => {},
  onSuccess: () => {},
  refreshTable: () => {},
  store: {},
};

export default CancelTransferModal;
