export const RESOURCE_TYPE_TO_BREADCRUMB_NAME = {
  business: 'Businesses',
  cardholder: 'Users',
  transaction: 'Users',
};

export const NAV_LINKS = [
  {
    text: 'HOME',
    icon: 'old-home',
    destinations: [
      {
        to: 'home',
        routeName: 'Home',
      },
    ],
    width: 82,
  },
  {
    text: 'REPORTS',
    icon: 'note',
    destinations: [
      {
        to: 'reports',
        routeName: 'Reports',
      },
    ],
    // set explicit width to deal with issue where on initial visit
    // underline was measured before fonts loaded, putting underline
    // in the wrong location
    width: 109,
  },
  // hide for now, not being developed
  // {
  //   text: 'STORIES',
  //   icon: 'stories',
  //   destinations: [
  //     {
  //       to: 'stories',
  //       routeName: 'Stories',
  //     },
  //   ],
  //   width: 103,
  // },
  {
    text: 'PROGRAM',
    icon: 'program',
    destinations: [
      {
        to: 'program',
        routeName: 'Program',
      },
    ],
    width: 114,
  },
  {
    text: 'AUDIT LOGS',
    icon: 'audit-logs',
    destinations: [
      {
        to: 'auditLogs',
        routeName: 'Audit logs',
      },
    ],
    width: 133,
  },
  {
    text: 'ADMIN',
    icon: 'old-user',
    destinations: [
      {
        to: 'admin',
        routeName: 'Admin',
      },
      {
        to: 'adminRoles',
        routeName: 'Roles',
      },
      {
        to: 'adminDepartments',
        routeName: 'Departments',
      },
      {
        to: 'adminSupplements',
        routeName: 'Supplements',
      },
      {
        to: 'adminConfigManagement',
        routeName: 'Config management',
      },
      {
        to: 'adminSandboxesManagement',
        routeName: 'Sandbox management',
      },
    ],
    width: 87,
  },
  {
    text: 'DEVELOPMENT',
    icon: 'bracket-ellipses',
    destinations: [
      {
        to: 'development',
        routeName: 'Development',
      },
    ],
    width: 155,
  },
  {
    text: 'RISK',
    icon: 'shield',
    destinations: [
      {
        to: 'risk',
        routeName: 'Risk',
      },
    ],
    width: 71,
  },
  // hide for now, moved to airflow
  // {
  //   text: 'SCHEDULER',
  //   icon: 'filter',
  //   destinations: [
  //     {
  //       to: 'scheduler',
  //       routeName: 'Scheduler',
  //     },
  //   ],
  //   width: 132,
  // },
  {
    text: 'BINS',
    icon: 'database',
    destinations: [
      {
        to: 'binManagement',
        routeName: 'Bin Management',
      },
    ],
    width: 70,
  },
  {
    text: 'CREDIT',
    icon: 'cardstock',
    destinations: [
      {
        to: 'credit',
        routeName: 'Credit',
      },
    ],
    width: 92,
  },
];

export const JANUS_NAV_LINKS = NAV_LINKS.filter((link) => !['DEVELOPMENT'].includes(link.text));

export const RESTRICTED_LINKS = [
  'HOME',
  'ADMIN',
  'STORIES',
  'DEVELOPMENT',
  'RISK',
  'FRAUDSTREAM',
  'PROGRAM',
  'AUDIT LOGS',
  'DECISION MANAGER',
  'SCHEDULER',
  'RISK',
  'BINS',
  'CREDIT',
];

export const ACTIVE_MAP = {
  home: ['home'],
  auditLogs: ['Audit logs'],
  admin: ['Admin', 'Roles', 'Departments', 'Config management'],
  program: [
    'Program',
    'Commando modes',
    'Commando mode',
    'Businesses',
    'Business',
    'Cardholders',
    'Cardholder',
    'Card',
    'Card creation',
    'Card products',
    'Card product',
    'Bulk card products',
    'Reviews',
  ],
  credit: [
    'Credit',
    'Work Queue',
    'Credit Product',
    'Credit Product Create',
    'Credit Products',
    'Credit Products History',
    'Credit Products Rejected',
    'Offer',
    'Offer Create',
    'Offers',
    'Offers History',
  ],
  risk: [
    'risk',
    'fraudstream',
    'Decision manager',
    'FraudStream transactions',
    'FraudStream policy',
    'FraudStream health',
    'FraudStream history',
    'FraudStream home',
    'Case management',
  ],
  reports: ['reports'],
  stories: ['stories'],
  development: ['development', 'TransactionTimeline'],
  fraudstream: ['fraudstream'],
  scheduler: ['scheduler'],
  binManagement: [
    'Bin Management',
    'Bin Management Bin View',
    'Bin Management Range View',
    'Bin Management Program View',
  ],
};
