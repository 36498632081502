/* eslint-disable camelcase */
import React from 'react';
import { observer } from 'mobx-react';
import s from './BusinessInfoTab.module.css';
import {
  Card,
  Text,
  ContentSnippet,
  VSpacer,
  LoadingOverlay,
  Button,
  HSpacer,
} from '@mqd/volt-base';
import PropTypes from 'prop-types';
import { ParentDetailCard } from '@mq/voltron-parent';

class BusinessIncorporationStatusCard extends ParentDetailCard {
  constructor(props) {
    super(props);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.state = {
      editActive: false,
      loading: false,
    };
  }

  get incStore() {
    const { businessStore = {} } = this.props;
    const { incorporation = {} } = businessStore;
    return incorporation || {};
  }

  get businessStore() {
    const { businessStore } = this.props;
    return businessStore || {};
  }

  async handleSave() {
    const { updateBusiness } = this.businessStore;
    this.setState({ loading: true });
    const success = await updateBusiness();
    if (success) {
      this.setState({ loading: false, editActive: false });
    } else {
      this.setState({ loading: false });
    }
  }

  handleCancel() {
    const { revertUpdates } = this.businessStore;
    revertUpdates && revertUpdates();
    this.setState({ editActive: false });
  }

  renderHeaderButtons() {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button type="outline" onClick={this.handleCancel}>
          Cancel
        </Button>
        <HSpacer />
        <Button onClick={this.handleSave}>Save</Button>
      </div>
    );
  }

  renderRegisteredAddress() {
    const { editActive } = this.state;
    let { address_registered_under = {} } = this.incStore;
    if (!address_registered_under) {
      address_registered_under = {};
    }
    const addressDisplay =
      address_registered_under && address_registered_under.displayUS
        ? address_registered_under.displayUS
        : '';
    const { address1, address2, city, state, postal_code, country, updateForSave } =
      address_registered_under;
    if (editActive) {
      return (
        <>
          <Text type="h5">Registered Address</Text>
          <VSpacer factor={2} />
          {this.renderSnippet({
            title: 'Address 1',
            content: address1,
            editActive: true,
            onChange: (e) => updateForSave('address1', e.target.value),
            tooltipContent: "Business's registered street address.",
          })}
          {this.renderSnippet({
            title: 'Address 2',
            content: address2,
            editActive: true,
            onChange: (e) => updateForSave('address2', e.target.value),
            tooltipContent: 'Additional address information.',
          })}
          {this.renderSnippet({
            title: 'City',
            content: city,
            editActive: true,
            onChange: (e) => updateForSave('city', e.target.value),
            tooltipContent: "Business's registered city.",
          })}
          {this.renderSnippet({
            title: 'State',
            content: state,
            editActive: true,
            onChange: (e) => updateForSave('state', e.target.value),
            tooltipContent: "Business's registered state.",
          })}
          {this.renderSnippet({
            title: 'Postal Code',
            content: postal_code,
            editActive: true,
            onChange: (e) => updateForSave('postal_code', e.target.value),
            tooltipContent: "Business's registered postal code.",
          })}
          {this.renderSnippet({
            title: 'Country',
            content: country,
            editActive: true,
            onChange: (e) => updateForSave('country', e.target.value),
            tooltipContent: "Business's registered country.",
          })}
        </>
      );
    } else {
      return (
        <ContentSnippet
          title="Registered Address"
          testId="volt-content-snippet_title-Registered Address"
          horizontalLayout
          tooltipContent="The address under which the business is registered."
          titleInline={!editActive ? { width: 180 } : null}
          contentInline={!editActive ? { flexGrow: 1 } : null}
        >
          <div className={s.address}>{addressDisplay}</div>
        </ContentSnippet>
      );
    }
  }

  renderInfo() {
    const { editable } = this.props;
    const { editActive } = this.state;
    const {
      is_public,
      incorporation_type,
      stock_symbol,
      state_of_incorporation,
      name_registered_under,
      updateForSave,
    } = this.incStore;
    return (
      <Card
        title="Incorporation Status"
        testId="incorporation-card"
        controls={editable && (editActive ? this.renderHeaderButtons() : this.renderControl())}
      >
        {this.renderSnippet({
          title: 'Is Public',
          content: is_public ? 'True' : 'False',
          inputValue: is_public,
          inputType: 'toggle',
          onChange: (value) => updateForSave('is_public', value),
          editActive: editActive,
          tooltipContent: `
              Indicates whether the business is 
              privately or publicly held.
            `,
        })}
        {this.renderSnippet({
          title: 'Incorporation Type',
          content: incorporation_type,
          inputType: 'select',
          inputProps: {
            options: [
              'LLC',
              'CORPORATION',
              'SOLE_PROPRIETORSHIP',
              'PARTNERSHIP',
              'COOPERATIVE',
              'OTHER',
            ],
          },
          onChange: (value) => updateForSave('incorporation_type', value),
          editActive: editActive,
          tooltipContent: (
            <div>
              The business's type of incorporation. Required for verification (KYC) checks.
              <br />
              <br />
              Note: Only one of the following identification types can be associated with a
              business:
              <ul>
                <li>LLC</li>
                <li>CORPORATION</li>
                <li>SOLE_PROPRIETORSHIP</li>
                <li>PARTNERSHIP</li>
                <li>COOPERATIVE</li>
                <li>OTHER</li>
              </ul>
            </div>
          ),
        })}
        {this.renderSnippet({
          title: 'Name (Registered)',
          content: name_registered_under,
          onChange: (e) => updateForSave('name_registered_under', e.target.value),
          editActive: editActive,
          tooltipContent: 'The name under which the business is registered.',
        })}
        <div id={s.stateInc}>
          {this.renderSnippet({
            title: 'State of Incorporation',
            content: state_of_incorporation,
            onChange: (e) => updateForSave('state_of_incorporation', e.target.value),
            editActive: editActive,
            tooltipContent: `
              The state in which the business is incorporated.
              Required for verification (KYC) checks.
            `,
          })}
        </div>
        {this.renderSnippet({
          title: 'Stock Symbol',
          content: stock_symbol,
          editActive: editActive,
          onChange: (e) => updateForSave('stock_symbol', e.target.value),
          tooltipContent: "The business's stock symbol.",
        })}
        {this.renderRegisteredAddress()}
      </Card>
    );
  }

  render() {
    const { loading } = this.state;
    return <LoadingOverlay active={loading}>{this.renderInfo()}</LoadingOverlay>;
  }
}

BusinessIncorporationStatusCard.propTypes = {
  businessStore: PropTypes.any,
  editable: PropTypes.bool,
};

BusinessIncorporationStatusCard.defaultProps = {
  businessStore: {},
  editable: false,
};

export default observer(BusinessIncorporationStatusCard);
