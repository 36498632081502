import React from 'react';
import { Icon, Flex, HSpacer, Text } from '@mqd/volt-base';
import { currencyToIconCountryNameMap } from './constants.js';

function CurrencyFlagOption({ code, ...rest }) {
  return (
    <Flex alignItems="center" {...rest} testId="currency-option">
      <Icon type={currencyToIconCountryNameMap[code]} factor={1} noHoverEffects={true} />
      <HSpacer factor={0.5} />
      <Text type="body-sm">{code}</Text>
    </Flex>
  );
}

export default CurrencyFlagOption;
