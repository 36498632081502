import moment from 'moment';
import generateUserInitials from '../../utilities/string/generate-user-initials.js';

export default function generateNotes(notes) {
  return notes.map((note, i) => generateNote(note, i));
}

export function generateNote({ action, createdTime, email, note, user_name }, initialIndex) {
  const timestamp = moment(createdTime).format('YYYY[-]MM[-]DD HH:mm');
  return {
    userInitials: generateUserInitials(user_name),
    email,
    description: action,
    note,
    createdTime: timestamp,
    token: `${timestamp} ${note} ${initialIndex}`,
  };
}
