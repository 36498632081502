// @flow
import { decorate, observable, action, runInAction, computed } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';

class OffsetStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
  }
  // values
  unit: string = '';
  value: string = '';
}

class MinOffsetStore extends OffsetStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
  }
}

class ExpirationOffSetStore extends OffsetStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
    this.loadAndConstructItem('min_offset', args.min_offset, MinOffsetStore);
  }
  // values
  min_offset: MinOffsetStore = null;
}

decorate(OffsetStore, {
  unit: observable,
  value: observable,
});

decorate(ExpirationOffSetStore, {
  min_offset: observable,
});

export default ExpirationOffSetStore;
