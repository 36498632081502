import React from 'react';
import { useRouterUrlContext } from './context.js';

export default function withRouterUrl(Component) {
  return function WrapperComponent(props) {
    const url = useRouterUrlContext() || new URL(window.location);

    return <Component {...props} url={url} />;
  };
}
