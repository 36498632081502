const loadAttributes = [
  { localStorageKey: 'userId' },
  { localStorageKey: 'firstName' },
  { localStorageKey: 'lastName' },
  { localStorageKey: 'email' },
  { localStorageKey: 'webToken' },
  { localStorageKey: 'webTokenCreatedTime' },
  { localStorageKey: 'userOrgName' },
  { localStorageKey: 'userRole' },
  { localStorageKey: 'persistApiUserId' },
  { localStorageKey: 'redsea_first_name' },
  { localStorageKey: 'redsea_last_name' },
  { localStorageKey: 'redsea_phone' },
  { localStorageKey: 'redsea_email' },
  { localStorageKey: 'redsea_token' },
  { localStorageKey: 'selectedActiveProgram', parse: true },
  { localStorageKey: 'departments', defaultVal: [], parse: true },
  { localStorageKey: 'programs', defaultVal: [], parse: true },
  {
    localStorageKey: 'userMetadata-programs',
    attr: 'programsMetadata',
    defaultVal: [],
    parse: true,
  },
  { localStorageKey: 'savedReports', defaultVal: [], parse: true },
  { localStorageKey: 'defaultReport', defaultVal: {}, parse: true },
  { localStorageKey: 'dataJournals', defaultVal: [], parse: true },
  { localStorageKey: 'supplements', defaultVal: [], parse: true },
  { localStorageKey: 'favoritePrograms', defaultVal: [], parse: true },
  {
    localStorageKey: 'redsea_mfa_totp_enabled',
    defaultVal: false,
    parse: true,
  },
  { localStorageKey: 'redseaRoles', defaultVal: [], parse: true },
  { localStorageKey: 'redseaRolesToProgramsMap', defaultVal: [], parse: true },
];

export default loadAttributes;
