import { isEmpty, is, isNil } from 'ramda';

/**
 *
 * @param { object }
 * @param { array } overrideKeys These keys are skipped during the removal of empty objects and strings
 * @returns { object }
 */
export default function removeEmptyObjectsAndStringsFromObject(object, overrideKeys = []) {
  return Object.keys(object).reduce((acc, key) => {
    const value = object[key];
    if (Array.isArray(value)) {
      const cleanedArray = value.reduce((acc, item) => {
        if (is(Object, item)) {
          const cleanedObject = removeEmptyObjectsAndStringsFromObject(item);
          if (isEmpty(cleanedObject)) {
            return acc;
          }
          return acc.concat(cleanedObject);
        }
        if (!isEmpty(item) && !isNil(item)) {
          return acc.concat(item);
        }
        return acc;
      }, []);
      if (!isEmpty(cleanedArray)) {
        acc[key] = cleanedArray;
      }
    } else if (is(Object, value)) {
      const cleanedObject = removeEmptyObjectsAndStringsFromObject(value);
      if (!isEmpty(cleanedObject) || overrideKeys.includes(key)) {
        acc[key] = cleanedObject;
      }
    } else {
      if ((!isEmpty(value) && !isNil(value)) || overrideKeys.includes(key)) {
        acc[key] = value;
      }
    }

    return acc;
  }, {});
}
