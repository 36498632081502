import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { ParentTable } from '@mq/voltron-parent';
import s from './CardProductGrid.module.css';
import { LoadingOverlay, Container, Row, Col, Button, Text, VSpacer } from '@mqd/volt-base';
import CardProductsStore from '../stores/CardProductsStore';
import CardProductTile from './CardProductTile';
import CardProductGridFilter from './CardProductGridFilter';
import EmptyTableState from './../shared-components/empty-state/EmptyTableState';
import userStore from 'stores/UserStore.js';

class CardProductsGrid extends ParentTable {
  constructor(props) {
    super(props);
    this.storeConstructor = CardProductsStore;
  }

  renderCardProductTiles() {
    const { sortedItems } = this.store;
    const { goToCardProductDetail } = this.props;
    const tiles = sortedItems.map((item) => {
      return (
        <Fragment key={item.token}>
          <Col span={6} testId="card-product-tile__col">
            <CardProductTile
              handleClick={() => goToCardProductDetail({ token: item.token })}
              store={item}
            />
          </Col>
        </Fragment>
      );
    });

    if (sortedItems && sortedItems.length) {
      return (
        <Fragment>
          <Row>{tiles}</Row>
          {this.renderPagination()}
        </Fragment>
      );
    } else {
      return null;
    }
  }

  render() {
    const { sortedItems, loading } = this.store;
    const { goToCardProductsBulkResponsePage, cardProductsHero } = this.props;
    const { cardProductViewActive, uamGranularPermissionsActive } = userStore;

    const showBulkResponseButton = uamGranularPermissionsActive ? cardProductViewActive : true;

    return (
      <div className={s.containerDiv} data-testid="card-products-grid__container">
        <div className={s.flexDiv}>
          <Text type="h3" testId="card-products-index-page-header">
            Card products
          </Text>
          {showBulkResponseButton && (
            <Button
              onClick={goToCardProductsBulkResponsePage}
              type="tertiary"
              testId="bulk-response-table-button"
            >
              Bulk card response
            </Button>
          )}
        </div>
        <VSpacer factor={3} />
        {cardProductsHero}
        <Container>
          <Row>
            <Col>
              <CardProductGridFilter store={this.store} />

              {!loading && !sortedItems.length && (
                <EmptyTableState
                  header="No card products"
                  testId="card-products-grid_empty-state"
                  bodyText={
                    <>
                      It appears none of your card products have been configured yet.
                      <br />
                      Work with your Marqeta representative to get set up!
                    </>
                  }
                />
              )}
            </Col>
          </Row>

          {loading ? (
            <>
              <VSpacer factor={5} />
              <LoadingOverlay active={loading} borderRadius="large" transparentBackground>
                <VSpacer factor={8} />
              </LoadingOverlay>
            </>
          ) : (
            this.renderCardProductTiles()
          )}
        </Container>
      </div>
    );
  }
}

CardProductsGrid.propTypes = {
  activeUserInfo: PropTypes.object,
  autoHydrate: PropTypes.bool,
  handleRedirect: PropTypes.func,
  height: PropTypes.number,
  store: PropTypes.object,
  storeInitArgs: PropTypes.object,
  cardProductsHero: PropTypes.any,
  onRowClick: PropTypes.func,
  onStoreConstruction: PropTypes.func,
  userStore: PropTypes.object,
  localStorageKey: PropTypes.string,
};

CardProductsGrid.defaultProps = {
  activeUserInfo: {},
  handleRedirect: (store, tab) => {},
  height: 800,
  onRowClick: () => {},
  cardProductsHero: null,
  onStoreConstruction: null,
  store: null,
  storeInitArgs: { limit: 100 },
  userStore: {},
  localStorageKey: 'CardProductsGrid',
};

export default observer(CardProductsGrid);
