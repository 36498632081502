import React from 'react';
import { bannerInformation } from './constants';
import { Colors, Icon, VSpacer, BannerAlert } from '@mqd/volt-base';
import s from './KYBBanner.module.css';
import PropTypes from 'prop-types';

class KYBBanner extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const type = this.props.vendorPending ? 'VENDOR_PENDING' : this.props.type || 'NOT_PERFORMED';
    const { titleText, subtitleText, iconBackgroundColor, bannerType } =
      bannerInformation[type] || '';
    const bannerIcon = (
      <div className={s.cautionIcon} style={{ backgroundColor: Colors[iconBackgroundColor] }}>
        <Icon type="danger" mod="reversed" noHoverEffects />
      </div>
    );
    return (
      <div data-testid="KYB-Banner">
        <BannerAlert
          type={bannerType}
          heading={titleText}
          iconComponent={bannerIcon}
          size="lg"
          disposable={true}
        >
          {subtitleText}
        </BannerAlert>
        <VSpacer factor={3} />
      </div>
    );
  }
}

KYBBanner.propTypes = {
  type: PropTypes.string,
  vendorPending: PropTypes.bool,
};
KYBBanner.defaultProps = {
  type: '',
  vendorPending: false,
};

export default KYBBanner;
