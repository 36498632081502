import { action, computed, decorate, observable } from 'mobx';
import moment from 'moment';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';

class DownloadStore {
  static FILE_ID_LENGTH = 8;

  isDownloadAvailable = true;
  isInProgress = false;
  isError = false;

  filename = null;
  dataLoader = null;

  constructor(filename, dataLoader) {
    this.filename = filename;
    this.dataLoader = dataLoader;
  }

  get fileId() {
    // The file id represents the current timestamp, converted to a lowercase Base64-encoded string,
    // which has a predefined length and is devoid of the "=" character
    return btoa(Date.now()).replaceAll('=', '').slice(-DownloadStore.FILE_ID_LENGTH).toLowerCase();
  }

  get currentDate() {
    return moment().format('YYYY-MM-DD');
  }

  get fullFilename() {
    return `${this.filename}_${this.currentDate}_${this.fileId}`;
  }

  setIsDownloadAvailable(value) {
    this.isDownloadAvailable = value;
  }

  setIsInProgress(value) {
    this.isInProgress = value;
  }

  setIsError(value) {
    this.isError = value;
  }

  async download() {
    this.setIsInProgress(true);
    this.setIsError(false);

    try {
      const { data, config } = await this.dataLoader();

      if (this.isDownloadAvailable) {
        const csvData = Papa.unparse(data, config);
        const file = new File([csvData], this.fullFilename, { type: 'text/csv' });

        saveAs(file);
      }
    } catch (error) {
      this.setIsError(true);
    } finally {
      this.setIsInProgress(false);
    }
  }
}

decorate(DownloadStore, {
  isInProgress: observable,
  isError: observable,

  fullFilename: computed,

  setIsDownloadAvailable: action.bound,
  setIsInProgress: action.bound,
  setIsError: action.bound,
  download: action.bound,
});

export { DownloadStore };
