import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import DigitalWalletTokensTableStore from '../digital-wallet-tokens-table/DigitalWalletTokensTableStore';
import { ParentComponent } from '@mq/voltron-parent';
import DigitalWalletTokenList from './DigitalWalletTokensList';
import { LoadingOverlay, VSpacer, EmptyState } from '@mqd/volt-base';
import StyleContext from '../contexts/StyleContext';

class DigitalWalletTokensTab extends ParentComponent {
  constructor(props) {
    super(props);

    this.storeConstructor = DigitalWalletTokensTableStore;
    this.state = {
      activeStore: null,
    };
  }

  render() {
    const { goToDigitalWalletToken } = this.props;
    const { activeItems, inactiveItems, loading } = this.store;

    const hasActiveTokens = activeItems.length > 0;
    const hasInactiveTokens = inactiveItems.length > 0;

    if (!loading && !hasActiveTokens && !hasInactiveTokens) {
      return (
        <>
          <VSpacer factor={11} />
          <EmptyState type="large" text="No digital wallet tokens yet" />
          <VSpacer factor={12} />
        </>
      );
    }

    return (
      <LoadingOverlay active={loading}>
        <StyleContext.Consumer>
          {({ height }) => (
            <div style={{ minHeight: height + 80 }}>
              <DigitalWalletTokenList
                heading="Active tokens"
                items={activeItems}
                goToDigitalWalletToken={goToDigitalWalletToken}
              />
              {hasActiveTokens && hasInactiveTokens && <VSpacer factor={3} />}
              <DigitalWalletTokenList
                heading="Inactive tokens"
                items={inactiveItems}
                goToDigitalWalletToken={goToDigitalWalletToken}
              />
            </div>
          )}
        </StyleContext.Consumer>
      </LoadingOverlay>
    );
  }
}

DigitalWalletTokensTab.propTypes = {
  storeInitArgs: PropTypes.object,
  onStoreConstruction: PropTypes.func,
  localStorageKey: PropTypes.string,
  goToDigitalWalletToken: PropTypes.func,
};

DigitalWalletTokensTab.defaultProps = {
  storeInitArgs: {},
  onStoreConstruction: () => {},
  localStorageKey: '',
  goToDigitalWalletToken: null,
};

export default observer(DigitalWalletTokensTab);
