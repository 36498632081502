import { FlipFlop } from './../../../utils/index.js';
import hasRoleForAtLeastOneProgram from '../../helpers/hasRoleForAtLeastOneProgram.js';
import hasRoleForSelectedProgram from '../../helpers/hasRoleForSelectedProgram.js';

export const requirements = {
  flipFlopRequirements: ['card-inventory-manager-beta'],
  requiredUserPermissions: ['delivery-internal'],
};

export const verifier = () => {
  const hasAccessForAtLeastOneProgram = hasRoleForAtLeastOneProgram('delivery-internal');
  return FlipFlop.get('card-inventory-manager-beta', false) && hasAccessForAtLeastOneProgram;
};

export const programVerifier = () => {
  const hasAccessForSelectedProgram = hasRoleForSelectedProgram('delivery-internal');
  return FlipFlop.get('card-inventory-manager-beta', false) && hasAccessForSelectedProgram;
};
