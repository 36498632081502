import React, { useEffect, useState, useMemo } from 'react';

// components
import { VSpacer, OnPageError, LoadingOverlay, Flex } from '@mqd/volt-base';
import { Table, Pagination } from '@mq/voltron-table';
import { DatePicker } from '@mq/voltron-date-picker';

// hooks
import { useLazyQuery } from '@apollo/react-hooks';

// queries
import { getPaymentsQuery } from './queries';

// utils
import {
  getBillPaymentFilters,
  getDefaultDateRangeFilter,
  getDefaultDates,
  onChangeDateRange,
} from './utils';

// constants
import {
  PAYMENTS_TABLE_COLUMNS,
  PAGE_SIZE,
  ERROR_MESSAGE_PAYMENTS_FETCH,
} from './constants.js';
import routerStore from '../../../../stores/router/RouterStore';

const PaymentsTab = () => {
  const { currentParams = new Map() } = routerStore;
  const token = currentParams.get('token');
  const [getPayments, { data, loading, error }] = useLazyQuery(getPaymentsQuery);
  const [count, setCount] = useState(PAGE_SIZE);
  const [start_index, setStartIndex] = useState(0);

  const [queryFilters, setQueryFilters] = useState(
    getBillPaymentFilters()
      ? JSON.parse(getBillPaymentFilters())
      : getDefaultDateRangeFilter()
  );
  const [[from, to], setLocalDateFilter] = useState(getDefaultDates);

  const { data: items, end_index, is_more, total } = data?.billPayments || {};

  const perPage = useMemo(() => {
    return {
      perPage: count,
      total,
      current: Math.ceil(start_index / count + 1),
      perPageOptions: [25, 50, 100],
      onChange: (val) => setCount(+val),
    };
  }, [count, total, start_index]);

  useEffect(
    () => {
      getPayments({
        variables: {
          start_index,
          count,
          user_token: token,
          ...queryFilters
        },
      })
    },
    [getPayments, token, start_index, count, queryFilters]
  );

  const renderTable = () => {
    if (error) return <OnPageError type="lg">{ERROR_MESSAGE_PAYMENTS_FETCH}</OnPageError>;

    return (
      <div data-testid="bill-payments-table">
        <DatePicker
          from={from}
          to={to}
          onChange={(startDate, endDate) => {
            onChangeDateRange({
              startDate,
              endDate,
              setQueryFilters,
              setLocalDateFilter
            });
          }}
          closeAfterButtonClick
        />
        <VSpacer factor={3} />
        <Table
          localStorageKey="billPaymentsTableConfig"
          isLoading={loading}
          data={items}
          columns={PAYMENTS_TABLE_COLUMNS}
          height={600}
          minWidth={100}
          maxColumnWidth={1000}
          fixedColumnCount={0}
        />
        <VSpacer factor={2} />
        <Flex justifyContent="flex-end" alignItems="center">
          <Pagination
            endIndex={end_index}
            handleNextClick={() => setStartIndex(start_index + count)}
            handlePreviousClick={() => setStartIndex(Math.max(0, start_index - count))}
            isLess={start_index > 0}
            isMore={is_more}
            startIndex={start_index}
            perPage={perPage}
          />
        </Flex>
      </div>
    );
  };

  return (
    <LoadingOverlay active={loading}>
      <VSpacer factor={1} />
      {renderTable()}
    </LoadingOverlay>
  );
};

export default PaymentsTab;
