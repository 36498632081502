import { DefaultCardTemplate } from '../../../../components/default-card-template';
import { withAdditionalProps } from '../../../../hoc/with-additional-props';

import { EditTemplate } from './components/edit-template';
import { PreviewTemplate } from './components/preview-template';

const CardTemplateWithEditAndPreviewTemplates = withAdditionalProps(DefaultCardTemplate, {
  editTemplate: EditTemplate,
  previewTemplate: PreviewTemplate,
});

export { CardTemplateWithEditAndPreviewTemplates as CardTemplate };
