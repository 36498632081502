import { formatDate } from '../../../../../../utils/date.js';
import fetchGraphql from '../../../../../../../../utilities/graphql/fetch-graphql.js';

const query = `
  query programReserveSpecificTransactionsQuery(
    $program: String!
    $count: Int
    $start_index: Int
    $sort_by: String
  ) {
    programReserveSpecificTransactions(
      program: $program
      count: $count
      start_index: $start_index
      sort_by: $sort_by
    ) {
      data {
        transaction_token
        amount
        is_collateral
        currency_code
        type
        created_time
        memo
      }
      is_more
    }
  }
`;

export const fetchTransactionHistory = (params) => {
  return fetchGraphql({
    query,
    variables: {
      program: params.program,
      count: params.count,
      start_index: params.start_index,
      sort_by: params.sort_by,
    },
  });
};

export const fetchTransactionHistoryForExport = async (dataProcessor, columns, program) => {
  const {
    data: {
      programReserveSpecificTransactions: { data },
    },
    // TODO: define what value the count parameter should have
  } = await fetchTransactionHistory({ program, count: 100000, start_index: 0 });
  const processedData = dataProcessor(prettifyTransactionsForExport(data, program), columns);

  return processedData;
};

function prettifyTransactionsForExport(transactions, program) {
  transactions.forEach((transaction) => {
    transaction.programFullName = program;
    transaction.created_time = formatDate(transaction.created_time);
  });

  return transactions;
}
