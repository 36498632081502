import gql from 'graphql-tag';

export const FIND_OR_CREATE_USER = gql`
  mutation findOrCreatePersistUser($email: String!) {
    findOrCreatePersistUser(email: $email) {
      id
      email
      default_report
      saved_reports {
        id
        uid
        name
        hydrator
        created_at
        updated_at
      }
    }
  }
`;

export const UPDATE_DEFAULT_REPORT = gql`
  mutation updateDefaultDivaReport($user_id: Int!, $default_report: String) {
    updateDefaultDivaReport(user_id: $user_id, default_report: $default_report) {
      id
      email
      default_report
    }
  }
`;

export const SAVE_REPORT = gql`
  mutation addDivaReport(
    $uid: String!
    $created_by_user_id: Int!
    $name: String!
    $hydrator: String!
  ) {
    addDivaReport(
      uid: $uid
      created_by_user_id: $created_by_user_id
      name: $name
      hydrator: $hydrator
    ) {
      id
    }
  }
`;

export const EDIT_REPORT = gql`
  mutation editDivaReport($uid: String!, $name: String, $hydrator: String!) {
    editDivaReport(uid: $uid, name: $name, hydrator: $hydrator) {
      id
      uid
      name
      hydrator
    }
  }
`;

export const DELETE_REPORT = gql`
  mutation deleteDivaReport($uid: String!) {
    deleteDivaReport(uid: $uid) {
      uid
    }
  }
`;

export const GET_REPORT = gql`
  query divaReport($uid: String!) {
    divaReport(uid: $uid) {
      id
      uid
      name
      hydrator
      created_at
      updated_at
    }
  }
`;

export const GET_USER = gql`
  query persistUser($id: Int!) {
    persistUser(id: $id) {
      id
      email
      default_report
      saved_reports {
        id
        uid
        name
        hydrator
        created_at
        updated_at
      }
    }
  }
`;
