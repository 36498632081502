import gql from 'graphql-tag';

export const EXPORT_EXCEPTION_QUEUE = gql`
  mutation exportExceptionQueueBySubnetwork(
    $subnetwork: Subnetwork!
    $exception_type: ExceptionTypeEnum!
    $program_types: [ProgramType!]
    $program_short_codes: [String!]
    $dna_bank_tokens: [ID!]
    $settlement_currencies: [CurrencyCode!]
    $start_settlement_date: String
    $end_settlement_date: String
  ) {
    exportStatus: exportExceptionQueueBySubnetwork(
      subnetwork: $subnetwork
      exception_type: $exception_type
      program_types: $program_types
      program_short_codes: $program_short_codes
      dna_bank_tokens: $dna_bank_tokens
      settlement_currencies: $settlement_currencies
      start_settlement_date: $start_settlement_date
      end_settlement_date: $end_settlement_date
    ) {
      status_code
    }
  }
`;
