import gql from 'graphql-tag';

export const BANK_TRANSFER_INFORMATION = gql`
  query bankTransferInformation($webToken: String!) {
    bankTransferInformation(webToken: $webToken) {
      accountOwner
      accountNumber
      accountName
      financialInstitution
      financialInstitutionCityState
      wireTransferRoutingTransitNumber
      wireTransferSwiftCode
      achTransacctionBankRoutingNumber
    }
  }
`;
