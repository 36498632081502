import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { ParentTable, Status } from '@mq/voltron-parent';
import { Table, ActionsCell, ActionPlaceholder } from '@mq/voltron-table';
import { LoadingOverlay, ModalAlert, VSpacer, ErrorModal } from '@mqd/volt-base';

import AddNoteModal from '../add-note-modal/AddNoteModal';
import ChangeStatusModal from '../change-status-modal/ChangeStatusModal';
import StyleContext from '../contexts/StyleContext';
import CardholdersTableStore from './CardholdersTableStore';
import { filterOptions } from './constants';

import s from './CardholdersTable.module.css';

class CardholdersTable extends ParentTable {
  constructor(props) {
    super(props);
    this.storeConstructor = CardholdersTableStore;
    this.actionsRenderer = this.actionsRenderer.bind(this);
    this.state = {
      activeStore: null,
      showAddNoteModal: false,
      showChangeStatusModal: false,
      showNoteNotAddedAlert: false,
      showNoteSuccessfullyAddedAlert: false,
      tableKey: 'unset',
      ...this.colEditState(props, this.columns()),
    };
  }

  addNote(store) {
    this.setState({ showAddNoteModal: true, activeStore: store });
  }

  changeStatus(store) {
    this.setState({ showChangeStatusModal: true, activeStore: store });
  }

  redirectToDetailsTab(store, tab) {
    const { handleRedirect } = this.props;
    this.setState({ activeStore: store });
    handleRedirect(store, tab);
  }

  actionsRenderer({ row }) {
    if (this.props.canAccessChildrenUsers) {
      return (
        <ActionsCell
          actions={[
            {
              text: 'Add Note',
              onClick: () => this.addNote(row),
            },
            {
              text: 'Change Status',
              onClick: () => this.changeStatus(row),
            },
          ]}
        />
      );
    }

    return null;
  }

  children(row) {
    const children = ['User Details', 'Cards', 'Transactions', 'History', 'Notes', 'Transitions'];
    return children.map((child) => {
      return {
        element: <div className={s.action}>{child}</div>,
        onClick: () => this.redirectToDetailsTab(row, child),
      };
    });
  }

  get parentIsBusiness() {
    return this.tableParentType === 'business';
  }

  columns() {
    return [
      // main info
      {
        header: this.headerRenderer(),
        accessor: 'token',
        key: 'actions',
        renderer: this.actionsRenderer,
        noPadding: true,
        noResize: true,
        noRowClick: true,
        noSort: true,
        noEdit: true,
        deferRenderPlaceholder: () => <ActionPlaceholder width={32} />,
      },
      {
        header: 'Status',
        accessor: 'status',
        key: 'status',
        renderer: ({ cellValue }) => <Status status={cellValue} />,
        filterType: 'starts-with',
      },
      { header: 'First Name', accessor: 'first_name', key: 'first_name' },
      { header: 'Last Name', accessor: 'last_name', key: 'last_name' },
      { header: 'Phone', accessor: 'phone', key: 'phone', hide: this.parentIsBusiness },
      { header: 'Email', accessor: 'email', key: 'email' },
      { header: 'City', accessor: 'city', key: 'city', hide: this.parentIsBusiness },
      { header: 'State', accessor: 'state', key: 'state', hide: this.parentIsBusiness },
      {
        header: 'Postal Code',
        accessor: 'postal_code',
        key: 'postal_code',
        hide: this.parentIsBusiness,
      },
      {
        header: 'Last Modified',
        accessor: 'last_modified_time',
        key: 'last_modified_time',
        hide: this.parentIsBusiness,
      },
      {
        header: 'Group Token',
        accessor: 'account_holder_group_token',
        key: 'account_holder_group_token',
        hide: !this.parentIsBusiness,
      },
      { header: 'Token', accessor: 'token', key: 'token', primaryKey: true },

      // extended info
      { header: 'Created Time', accessor: 'created_time', key: 'created_time', hide: true },
      { header: 'Honorific', accessor: 'honorific', key: 'honorific', hide: true },
      { header: 'Gender', accessor: 'gender', key: 'gender', hide: true },
      { header: 'Middle Name', accessor: 'middle_name', key: 'middle_name', hide: true },
      { header: 'Nationality', accessor: 'nationality', key: 'nationality', hide: true },
      {
        header: 'Corporate Card Holder',
        accessor: 'corporate_card_holder',
        key: 'corporate_card_holder',
        hide: true,
      },
      { header: 'Company', accessor: 'company', key: 'company', hide: true },
      // not shown
      {
        header: 'Uses Parent Account',
        accessor: 'uses_parent_account',
        key: 'uses_parent_account',
        hide: true,
      },
      { header: 'SSN', accessor: 'ssn', key: 'ssn', hide: true },
      { header: 'Note Metadata', accessor: 'notes_string', key: 'notes_string', hide: true },
      { header: 'IP Address', accessor: 'ip_address', key: 'ip_address', hide: true },
      { header: 'Address 1', accessor: 'address1', key: 'address1', hide: true },
      { header: 'Address 2', accessor: 'address2', key: 'address2', hide: true },
      { header: 'Country', accessor: 'country', key: 'country', hide: true },
      { header: 'Birth Date', accessor: 'birth_date', key: 'birth_date', hide: true },
    ];
  }

  renderAddNoteModal() {
    const { showAddNoteModal, activeStore } = this.state;
    const { userStore } = this.props;

    //TODO: Pull out userRole from userStore (current userRole is from Janus).
    //Map redseaUserRole to USER | ADMIN | BANK_USER | BANK_ADMIN | MARQETA_PD | MARQETA_ADMIN.
    //Pass in the correct created_by_user_role.
    //Temporarily, all notes will have created_by_user_role='ADMIN'.
    const { email } = userStore;
    const activeUserInfo = email
      ? {
          email,
          userRole: 'ADMIN',
        }
      : {};
    if (!showAddNoteModal) return null;
    return (
      <AddNoteModal
        hideModal={() => this.setState({ showAddNoteModal: false })}
        heading={`Add Note - ${activeStore.fullName}`}
        store={activeStore}
        activeUserInfo={activeUserInfo}
        renderSuccessAlert={() => this.setState({ showNoteSuccessfullyAddedAlert: true })}
        renderFailedAlert={() => this.setState({ showNoteNotAddedAlert: true })}
      />
    );
  }

  renderChangeStatusModal() {
    const { showChangeStatusModal, activeStore } = this.state;
    if (!showChangeStatusModal) return null;
    return (
      <ChangeStatusModal
        hideModal={() => this.setState({ showChangeStatusModal: false })}
        heading={`Change Status - ${activeStore.fullName}`}
        store={activeStore}
      />
    );
  }

  renderNoteSuccessfullyAddedAlert() {
    const { showNoteSuccessfullyAddedAlert } = this.state;
    if (!showNoteSuccessfullyAddedAlert) return null;
    return (
      <ModalAlert
        type="success"
        title="Note successfully added"
        hideModal={() => this.setState({ showNoteSuccessfullyAddedAlert: false })}
      />
    );
  }

  renderNoteNotAddedAlert() {
    const { showNoteNotAddedAlert } = this.state;
    if (!showNoteNotAddedAlert) return null;
    return (
      <ModalAlert
        type="danger"
        title="Note could not be added. Please try again."
        hideModal={() => this.setState({ showNoteNotAddedAlert: false })}
      />
    );
  }

  renderBackendErrorModal(heading) {
    return (
      <ErrorModal
        heading={heading}
        hideModal={() => (this.store.cardHoldersBackendError = '')}
        buttonText="Dismiss"
      >
        If you believe this is an error, please contact support.
      </ErrorModal>
    );
  }

  render() {
    const { onRowClick, setRowLink, calculateTableHeight, canAccessChildrenUsers } = this.props;
    const {
      currentList,
      activeSort,
      activeRow,
      setAttr,
      loading,
      updateKey,
      cardHoldersBackendError,
    } = this.store;
    const { columns, tableKey } = this.state;
    return (
      <div style={{ width: '100%' }} data-testid="cardholder-table-wrapper">
        {cardHoldersBackendError && this.renderBackendErrorModal(cardHoldersBackendError)}
        {this.renderAddNoteModal()}
        {this.renderChangeStatusModal()}
        {this.renderNoteSuccessfullyAddedAlert()}
        {this.renderNoteNotAddedAlert()}
        {this.renderColEditTableHeader({
          filterOptions: !this.parentIsBusiness && filterOptions,
          tableFilterTestId: 'cardholders-table-header-buttons',
        })}
        <VSpacer />
        <StyleContext.Consumer>
          {({ height }) => {
            return (
              <LoadingOverlay active={loading}>
                <Table
                  loading={loading}
                  key={tableKey}
                  height={height + calculateTableHeight(this.store) || this.dynamicHeight}
                  data={currentList}
                  columns={columns}
                  fixedColumnCount={1}
                  rightAlignFixedColumns={true}
                  sort={activeSort}
                  activeRow={activeRow}
                  setRowLink={setRowLink}
                  onRowClick={({ token }) => canAccessChildrenUsers && onRowClick({ token })}
                  onSort={(newSort) => setAttr('activeSort', newSort)}
                  scrollToTopUpdateKey={updateKey}
                  deferRenderTimeout={500}
                  handleColSizeChange={this.handleColSizeChange.bind(this)}
                  handleResetTable={this.clearFilterFunc}
                />
              </LoadingOverlay>
            );
          }}
        </StyleContext.Consumer>
        {this.renderPagination()}
      </div>
    );
  }
}

CardholdersTable.propTypes = {
  activeUserInfo: PropTypes.object,
  autoHydrate: PropTypes.bool,
  handleRedirect: PropTypes.func,
  height: PropTypes.number,
  store: PropTypes.object,
  storeInitArgs: PropTypes.object,
  onRowClick: PropTypes.func,
  setRowLink: PropTypes.func,
  onStoreConstruction: PropTypes.func,
  userStore: PropTypes.object,
  localStorageKey: PropTypes.string,
  calculateTableHeight: PropTypes.func,
  canAccessChildrenUsers: PropTypes.bool,
};

CardholdersTable.defaultProps = {
  canAccessChildrenUsers: false,
  activeUserInfo: {},
  autoHydrate: true,
  handleRedirect: (store, tab) => {},
  height: 800,
  onRowClick: () => {},
  setRowLink: null,
  onStoreConstruction: null,
  store: null,
  storeInitArgs: { limit: 100 },
  userStore: {},
  localStorageKey: 'CardholdersTableConfig',
  calculateTableHeight: () => 0,
};

export default observer(CardholdersTable);
