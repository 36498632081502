// @flow
import { decorate, observable, action } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';

class CardSwapStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
  }

  previous_card_token: string = '';
  new_card_token: string = '';
}

decorate(CardSwapStore, {
  // values
  previous_card_token: observable,
  new_card_token: observable,
});

class DigitalWalletTokenTransitionStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.loadFullInfoResult(args);
  }
  // values
  token: string = '';
  card_swap: CardSwapStore = {};
  type: string = '';
  channel: string = '';
  state: string = '';
  fulfillment_status: string = '';
  reason: string = '';
  reason_code: string = '';
  created_time: string = '';
  skipAutoLoad: boolean = true;

  loadFullInfoResult(data) {
    const { card_swap } = data;
    this.load(data);
    this.loadAndConstructItem('card_swap', card_swap, CardSwapStore);
  }
}

decorate(DigitalWalletTokenTransitionStore, {
  // values
  token: observable,
  card_swap: observable,
  type: observable,
  channel: observable,
  state: observable,
  fulfillment_status: observable,
  reason: observable,
  reason_code: observable,
  created_time: observable,
  skipAutoLoad: observable,

  // actions
  loadFullInfoResult: action.bound,
});

export default DigitalWalletTokenTransitionStore;
