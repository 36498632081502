// Default state is the API default values
const defaultState = {
  token: null,
  name: null,
  is_reloadable: true,
  kyc_required: 'NEVER',
  balance_max: 1000,
  cash_access_enabled: false,
  enable_non_program_loads: false,
  international_enabled: false,
  is_reloadable_pre_kyc: false,
  real_time_fee_group_token: null,
};

const CASH_ACCESS_ENABLED = 'cash_access_enabled';
const INTERNATIONAL_ENABLED = 'international_enabled';
const IS_RELOADABLE_PRE_KYC = 'is_reloadable_pre_kyc';
const ENABLE_NON_PROGRAM_LOADS = 'enable_non_program_loads';
const IS_RELOADABLE = 'is_reloadable';
const REAL_TIME_FEE_GROUP_TOKEN = 'real_time_fee_group_token';
const KYC_REQUIRED = 'kyc_required';
const BALANCE_MAX = 'balance_max';

export {
  defaultState,
  CASH_ACCESS_ENABLED,
  INTERNATIONAL_ENABLED,
  IS_RELOADABLE_PRE_KYC,
  ENABLE_NON_PROGRAM_LOADS,
  IS_RELOADABLE,
  REAL_TIME_FEE_GROUP_TOKEN,
  KYC_REQUIRED,
  BALANCE_MAX,
};
