import React, { Component } from 'react';
import { Button, Modal, Textarea } from '@mqd/volt-base';
import { SegmentTrackHOC } from '@mq/voltron-parent';
import PropTypes from 'prop-types';

class AddNoteModal extends Component {
  constructor(props) {
    super(props);
    this.handleAdd = this.handleAdd.bind(this);
  }

  state = {
    description: '',
    isPrivate: false,
  };

  async handleAdd() {
    const {
      store,
      hideModal,
      activeUserInfo = {},
      renderFailedAlert,
      renderSuccessAlert,
    } = this.props;
    const { token } = store;
    const { description, isPrivate } = this.state;

    if (!store || !store.addNote) {
      console.error('Store passed to AddNoteModal must expose an addNote function');
      return;
    }

    const result = await store.addNote(token, description, isPrivate, activeUserInfo);
    hideModal && hideModal();
    if (result && result.data) {
      renderSuccessAlert && renderSuccessAlert();
    } else {
      renderFailedAlert && renderFailedAlert();
    }
  }

  renderContent() {
    const { description } = this.state;
    const { activeUserInfo = {}, testId } = this.props;
    const { email, userRole } = activeUserInfo;
    const isDisabled = !email || !userRole;
    if (isDisabled) return <div>User must be signed in to create note.</div>;
    return (
      <>
        <Textarea
          testId={`${testId}__text-area`}
          noResize={true}
          value={description}
          onChange={(e) => this.setState({ description: e.target.value })}
          placeholder="Enter Note"
        />
      </>
    );
  }

  render() {
    const { heading, hideModal, activeUserInfo = {}, testId, segmentEventName } = this.props;
    const { email, userRole } = activeUserInfo;
    const isDisabled = !email || !userRole;
    return (
      <Modal
        testId={testId}
        heading={heading}
        hideModal={hideModal}
        footerButtons={[
          <SegmentTrackHOC eventName={segmentEventName}>
            <Button onClick={this.handleAdd} disabled={isDisabled} testId="add-note">
              Add
            </Button>
          </SegmentTrackHOC>,
        ]}
      >
        {this.renderContent()}
      </Modal>
    );
  }
}

AddNoteModal.propTypes = {
  activeUserInfo: PropTypes.object,
  hideModal: PropTypes.func,
  heading: PropTypes.string,
  renderFailedAlert: PropTypes.func,
  renderSuccessAlert: PropTypes.func,
  store: PropTypes.object,
  testId: PropTypes.string,
};

AddNoteModal.defaultProps = {
  activeUserInfo: {},
  hideModal: () => {},
  heading: 'Add Note',
  renderFailedAlert: () => {},
  renderSuccessAlert: () => {},
  store: {},
  testId: 'parity-plus-add-note-modal',
};

export default AddNoteModal;
