export const PRODUCTION = 'prod';
export const SANDBOX = 'sbx';
export const VALID_ENVIRONMENTS = [PRODUCTION, SANDBOX];

export const SET_ENVIRONMENT = 'SET_ENVIRONMENT';

export const PRODUCTION_DISPLAY_NAME = 'Production';
export const SANDBOX_DISPLAY_NAME = 'Sandbox';
export const ENVIRONMENT_OPTIONS = [PRODUCTION_DISPLAY_NAME, SANDBOX_DISPLAY_NAME];

export const ENVIRONMENT_LOCAL_STORAGE_KEY = 'selectedEnvironment';
