import { readDnaConfigByActiveProgram, readProgramConfigByActiveProgram } from './index.js';

const stringToBoolean = (str = '') => str.toLocaleLowerCase() === 'true';

export const isAchOriginationEnabled = () => {
  const dnaConfig = readDnaConfigByActiveProgram();

  return dnaConfig?.ach?.origination_enabled;
};

export const isAchProgramBankTransfersEnabled = () => {
  const programConfig = readProgramConfigByActiveProgram();

  return isAchOriginationEnabled() && stringToBoolean(programConfig?.PROGRAM_MT_FUND_SOURCE_SWITCH);
};
