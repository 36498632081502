import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import s from './Highlight.module.css';
import { Text, VSpacer } from '@mqd/volt-base';

class Highlight extends PureComponent {
  render() {
    const { title, children } = this.props;
    const content = typeof children === 'string' ? <Text type="lg">{children}</Text> : children;
    return (
      <div className={s.wrap}>
        <div className={s.title}>
          <Text mod="light bold">{title}</Text>
        </div>
        <VSpacer />
        <div className={s.content}>{content}</div>
      </div>
    );
  }
}

Highlight.propTypes = {
  title: PropTypes.string,
};

Highlight.defaultProps = {
  title: '',
};

export default Highlight;
