import { action, computed, decorate, observable } from 'mobx';
import { BaseHistoryStore } from './base-history-store.js';

class ProgramTransactionHistoryStore extends BaseHistoryStore {
  get sortByForCoreAPI() {
    const { sortBy = '' } = this;
    const sortKey = sortBy.slice(0, -1);

    if (sortBy.endsWith('-')) {
      return `-${sortKey}`;
    } else if (sortBy.endsWith('+')) {
      return sortKey;
    }

    return undefined;
  }

  setFilterProgram(value) {
    this.filterProgram = value;
  }
}

decorate(ProgramTransactionHistoryStore, {
  filterProgram: observable,

  sortByForCoreAPI: computed,

  setFilterProgram: action.bound,
});

export { ProgramTransactionHistoryStore };
