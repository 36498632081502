import React, { useMemo } from 'react';
import logger from 'utils/logger';
import { useMutation } from '@apollo/react-hooks';
import { Button } from '@mqd/volt-base';
import routerStore from '../../../../stores/router/RouterStore.js';
import {
  useAdjustmentActionStateContext,
  useAdjustmentActionDispatchContext,
} from '../AdjustmentAction/context.js';
import {
  useAdjustTransactionSettlementAndFeesStateContext,
  useAdjustTransactionSettlementAndFeesDispatchContext,
} from '../TransactionDetailContent/context.js';
import { SELECT_ACTION_ERROR, SELECT_REASON_ERROR } from '../AdjustmentAction/constants.js';
import { SUBMIT_ATTEMPTED } from '../TransactionDetailContent/constants.js';
import { CREATE_ADJUSTMENT } from './constants.js';
import { validateReferenceId } from '../TransactionDetailContent/utils.js';
import { createNetRefIdForToast } from '../../utilities/string/index.js';

const stripParentheses = (str) => str.replace('(', '').replace(')', '');
const stripCommas = (str) => str.replace(',', '');
const stripPeriods = (str) => str.replace('.', '');

const createScaledDecimal = (str) => {
  const [amount] = str.split(' ');

  const sign = amount.includes('(') ? '-' : '+';
  const amountWithoutParensOrCommas = stripCommas(stripParentheses(amount));
  const decimalIndex = amountWithoutParensOrCommas.indexOf('.');
  const scale = amountWithoutParensOrCommas.length - 1 - decimalIndex;

  const units = `${sign}${stripPeriods(amountWithoutParensOrCommas)}`;

  return {
    scale,
    units,
  };
};

const SubmitAdjustmentAction = ({
  detailPageLoading,
  internalHashId,
  netRefId,
  setLoadingCreateAdjustmentAction,
  setErrorModalData,
}) => {
  const dispatch = useAdjustmentActionDispatchContext();
  const { selectedAction, selectedReason } = useAdjustmentActionStateContext();
  const { CROSS_BORDER_ISSUER_FEE, PINDEBIT_ASSOC_FEE, PURCHASE_PAYABLES, errors, referenceId } =
    useAdjustTransactionSettlementAndFeesStateContext();
  const settlementsAndFeesDispatch = useAdjustTransactionSettlementAndFeesDispatchContext();

  const lines = useMemo(() => {
    const linesObject = Object.entries({
      CROSS_BORDER_ISSUER_FEE,
      PINDEBIT_ASSOC_FEE,
      PURCHASE_PAYABLES,
    });

    return linesObject
      .map(([key, value]) => ({
        accounting_group: key,
        amount: createScaledDecimal(value),
      }))
      .filter(({ amount: { units } }) => units);
  }, [CROSS_BORDER_ISSUER_FEE, PINDEBIT_ASSOC_FEE, PURCHASE_PAYABLES]);

  const [createTransactionCommand, { loading }] = useMutation(CREATE_ADJUSTMENT, {
    variables: {
      transaction_hash_exception_adjustment: {
        reason: selectedReason,
        lines,
      },
      action: selectedAction,
      tx_hash_internal_id: internalHashId,
      reference_id: referenceId,
    },
    onError: (err) => logger.error(err),
  });

  const redirectToExceptionQueue = () => {
    const urlParams = routerStore.url.searchParams;
    urlParams.delete('tx_hash_internal_id');
    routerStore.go(`/settlement/exceptions/${routerStore.pathParams.type}`, {
      params: urlParams,
      state: {
        toastMessage: `${createNetRefIdForToast(netRefId)} submitted`,
      },
    });
  };

  const handleSubmit = async () => {
    settlementsAndFeesDispatch({ type: SUBMIT_ATTEMPTED });
    const validatedReferenceId = validateReferenceId(referenceId, selectedAction);
    if (selectedAction && selectedReason && !errors.length && validatedReferenceId.valid) {
      setLoadingCreateAdjustmentAction(true);
      const { data } = await createTransactionCommand();
      setLoadingCreateAdjustmentAction(false);

      if (!data?.createTransactionHashExceptionCommand?.ok) {
        setErrorModalData({
          message: data?.createTransactionHashExceptionCommand?.message ?? '',
          error: true,
        });
      } else {
        redirectToExceptionQueue();
      }
    } else {
      if (!selectedReason) {
        dispatch({
          type: SELECT_REASON_ERROR,
          payload: 'A reason is required to submit this transaction',
        });
      } else if (!selectedAction) {
        dispatch({
          type: SELECT_ACTION_ERROR,
          payload: 'An action is required to submit this transaction',
        });
      }
      if (!validatedReferenceId.valid) {
        settlementsAndFeesDispatch(validatedReferenceId);
      }
    }
  };

  return (
    <Button loading={loading} onClick={handleSubmit} disabled={detailPageLoading}>
      Submit
    </Button>
  );
};

export default SubmitAdjustmentAction;
