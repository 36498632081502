import { formatDate } from '../../../../../../utils/date.js';
import fetchGraphql from '../../../../../../../../utilities/graphql/fetch-graphql.js';

const programReserveTransactionsQuery = `
  query programReserveTransactions(
    $count: Int
    $start_index: Int
    $sort_by: String
    $program: String
    $type: ProgramReserveTransactionType
    $amount: Float
    $is_collateral: Boolean
    $token: String
    $status: TemporaryTransactionStatus
    $currency_code: CurrencyCode
    $dateRange: [String!]
  ) {
    programReserveTransactions(
      count: $count
      start_index: $start_index
      sort_by: $sort_by
      program: $program
      type: $type
      amount: $amount
      is_collateral: $is_collateral
      token: $token
      status: $status
      currency_code: $currency_code
      dateRange: $dateRange
    ) {
      data {
        program
        type
        amount
        is_collateral
        currency_code
        token
        memo
        bank_file
        created_at
        status
        failure_reason
      }
      is_more
    }
  }
`;

const programReserveAllTransactionsQuery = `
  query programReserveAllTransactions {
    programReserveAllTransactions {
      data {
        program
        type
        amount
        is_collateral
        currency_code
        token
        memo
        bank_file
        created_at
        status
        failure_reason
      }
      is_more
    }
  }
`;

export const fetchTransactionHistory = (params) => {
  return fetchGraphql({
    query: programReserveTransactionsQuery,
    variables: {
      count: params.count,
      start_index: params.start_index,
      sort_by: params.sort_by,
      program: params.program,
      type: params.type,
      amount: params.amount,
      is_collateral: params.is_collateral,
      token: params.token,
      status: params.status,
      currency_code: params.currency_code,
      dateRange: params.dateRange,
    },
  });
};

export const fetchTransactionHistoryForExport = async (dataProcessor, columns) => {
  const {
    data: {
      programReserveAllTransactions: { data },
    },
  } = await fetchGraphql({ query: programReserveAllTransactionsQuery });
  const processedData = dataProcessor(formatTransactionsDate(data), columns);

  return processedData;
};

function formatTransactionsDate(transactions) {
  transactions.forEach((transaction) => {
    transaction.created_at = formatDate(Number(transaction.created_at));
  });

  return transactions;
}
