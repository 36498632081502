import { stateOptions } from './states.js';
import { readableCodes } from '../components/Transitions/constants.js';
import { FlipFlop } from '../../../../utils/index.js';
import { isBillPaymentEnabled } from '../../../../utils/bill-pay';

export const ACCOUNT_HOLDER_TABS = {
  Overview: true,
  Accounts: true,
  Transactions: true,
  'Bill payments': isBillPaymentEnabled() && FlipFlop?.get('bill-pay', false),
  Cards: true,
  'Transactions list': FlipFlop?.get('dda-txn-list', false),
  // toggle this back to true when spend controls will be ready for implementation
  'Spend control': false,
};

export const CARD_DETAILS_TAB = 'Overview';
export const CARD_SHIPPING_HISTORY_TAB = 'Shipping history';
export const CARD_DIGITAL_WALLET_TOKEN_TAB = 'Digital wallet tokens';

export const CARD_TABS = {
  [CARD_DETAILS_TAB]: true,
  [CARD_SHIPPING_HISTORY_TAB]: true,
  [CARD_DIGITAL_WALLET_TOKEN_TAB]: true,
};

export const PAGE_SIZE = 25;
export const CARDS_FOR_TRANSITION_SIZE = 50;

export const LAST_FOUR = 4;

export const DEFAULT_MASK = '\u2022\u2022';

// errors messages
export const ERROR_MESSAGE_ACCOUNT_HOLDER_TRANSITION_FETCH =
  'Unable to load account holder details data.';
export const ERROR_MESSAGE_TRANSACTIONS_FETCH = 'Unable to load transactions.';
export const ERROR_MESSAGE_API = 'An error occurred. Try again.';

export const ERROR_MESSAGE_ACCOUNT_TRANSITION_FETCH = 'Unable to load account data.';
export const ERROR_MESSAGE_CARDS_TRANSITION_FETCH = 'Unable to load card data.';

export const ACTIVITY_ERROR_MESSAGES = {
  accountholder: 'Unable to load account holder activity.',
  account: 'Unable to load account activity.',
  card: 'Unable to load card activity.',
  accountholderNote: 'Unable to add note.',
};

export const ERROR_MESSAGE_ACCOUNTHOLDERS_FETCH =
  'Unable to load account holders data. Please refresh the page.';

export { stateOptions, readableCodes };
