import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import NotesTable from '../notes-table/NotesTable';

class NotesTab extends Component {
  render() {
    const { storeInitArgs, onStoreConstruction, localStorageKey, hasMarqetaEmail } = this.props;
    return (
      <NotesTable
        hasMarqetaEmail={hasMarqetaEmail}
        storeInitArgs={storeInitArgs}
        onStoreConstruction={onStoreConstruction}
        localStorageKey={localStorageKey}
      />
    );
  }
}

NotesTab.propTypes = {
  storeInitArgs: PropTypes.object,
  onStoreConstruction: PropTypes.func,
  localStorageKey: PropTypes.string,
};

NotesTab.defaultProps = {
  storeInitArgs: {},
  onStoreConstruction: () => {},
  localStorageKey: '',
};

export default observer(NotesTab);
