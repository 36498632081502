import React from 'react';
import PropTypes from 'prop-types';
import { Link, Text, HSpacer } from '@mqd/volt-base';
import s from '../CardholderInfo.module.css';

const EditableTitle = (props) => {
  const { title, canEdit, isEditMode, onClick, testId } = props;

  const editLink = () => {
    if (!canEdit || isEditMode) return null;
    return (
      <Text type="sm">
        <Link testId={testId} onClick={onClick} inheritParentFont inline>
          Edit
        </Link>
      </Text>
    );
  };

  return (
    <div className={s.editableTitle}>
      <Text type="h5">{title}</Text>
      <HSpacer factor={1} />
      {editLink()}
    </div>
  );
};

EditableTitle.propTypes = {
  title: PropTypes.string,
  testId: PropTypes.string,
  canEdit: PropTypes.bool,
  isEditMode: PropTypes.bool,
  onClick: PropTypes.func,
};

export default EditableTitle;
