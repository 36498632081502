// @flow
import { decorate, observable, action, runInAction, computed } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';

class DeviceStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
  }
  token_pan: string = null;
  token_expiration: string = '';
  token_reference_id: string = '';
  pan_reference_id: string = '';
  token_requestor_name: string = '';
  location: string = '';
  ip_address: string = '';
  phone_number: string = '';
  language_code: string = '';
  type: string = '';
}

decorate(DeviceStore, {
  token_pan: observable,
  token_expiration: observable,
  token_reference_id: observable,
  pan_reference_id: observable,
  token_requestor_name: observable,
  location: observable,
  ip_address: observable,
  phone_number: observable,
  language_code: observable,
  type: observable,
});

export default DeviceStore;
