import { action, computed, decorate, observable } from 'mobx';
import { clone } from 'ramda';

class BaseHistoryStore {
  pageIndex = 0;
  loading = false;
  isMore = false;
  sortBy = this.DEFAULT_SORT_BY;
  history = [];
  error = null;
  showColumnEditor = false;

  constructor(initialColumns = []) {
    this.initialColumns = initialColumns;
    this.activeColumns = clone(initialColumns);
  }

  get DEFAULT_SORT_BY() {
    return '';
  }

  get isLess() {
    return this.pageIndex > 0;
  }

  get visibleColumns() {
    return this.activeColumns.filter(({ hide }) => !hide);
  }

  setLoading(loading) {
    this.loading = loading;
  }

  setIsMore(isMore) {
    this.isMore = isMore;
  }

  setSortBy(sortBy) {
    this.sortBy = sortBy || this.DEFAULT_SORT_BY;
    this.pageIndex = 0;
  }

  setHistory(transactions) {
    this.history = transactions;
  }

  setError(error) {
    this.error = error;
  }

  goNextPage() {
    this.pageIndex += 1;
  }

  goPrevPage() {
    this.pageIndex -= 1;
  }

  setShowColumnEditor(showColumnEditor) {
    this.showColumnEditor = showColumnEditor;
  }

  saveColumns(newColumns) {
    this.activeColumns = newColumns;
  }

  resetColumns() {
    this.activeColumns = clone(this.initialColumns);
  }

  resetPageIndex() {
    this.pageIndex = 0;
  }
}

decorate(BaseHistoryStore, {
  pageIndex: observable,
  loading: observable,
  isMore: observable,
  sortBy: observable,
  history: observable,
  error: observable,
  showColumnEditor: observable,
  activeColumns: observable.shallow,

  isLess: computed,
  visibleColumns: computed,

  setLoading: action.bound,
  setIsMore: action.bound,
  setSortBy: action.bound,
  setHistory: action.bound,
  setError: action.bound,
  goNextPage: action.bound,
  goPrevPage: action.bound,
  setShowColumnEditor: action.bound,
  saveColumns: action.bound,
  resetColumns: action.bound,
  resetPageIndex: action.bound,
});

export { BaseHistoryStore };
