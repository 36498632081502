import React from 'react';
import { withRedseaUser } from '@mq/volt-amc-container';
import { Text } from '@mqd/volt-base';
import s from './SandboxName.module.css';

const SandboxName = ({ redseaUser }) => {
  const {
    data: { redsea_first_name: firstName, redsea_last_name: lastName },
  } = redseaUser;
  return (
    <Text type="h5" className={s.main}>
      {firstName} {lastName} (Sandbox)
    </Text>
  );
};

export default withRedseaUser(SandboxName);
