export const reasonCodes = [
  {
    code: '00',
    message: 'Object activated for the first time.',
  },
  {
    code: '01',
    message: 'Requested by you.',
  },
  {
    code: '02',
    message: 'Inactivity over time.',
  },
  {
    code: '03',
    message: 'This address cannot accept mail or the addressee is unknown.',
  },
  {
    code: '04',
    message: 'Negative account balance.',
  },
  {
    code: '05',
    message: 'Account under review.',
  },
  {
    code: '06',
    message: 'Suspicious activity was identified.',
  },
  {
    code: '07',
    message: 'Activity outside the program parameters was identified.',
  },
  {
    code: '08',
    message: 'Confirmed fraud was identified.',
  },
  {
    code: '09',
    message: 'Matched with an Office of Foreign Assets Control list.',
  },
  {
    code: '10',
    message: 'Card was reported lost.',
  },
  {
    code: '11',
    message: 'Card information was cloned.',
  },
  {
    code: '12',
    message: 'Account or card information was compromised.',
  },
  {
    code: '13',
    message: 'Temporary status change while on hold/leave.',
  },
  {
    code: '14',
    message: 'Initiated by Marqeta.',
  },
  {
    code: '15',
    message: 'Initiated by issuer.',
  },
  {
    code: '16',
    message: 'Card expired.',
  },
  {
    code: '17',
    message: 'Failed KYC.',
  },
  {
    code: '18',
    message: 'Changed to ACTIVE because information was properly validated.',
  },
  {
    code: '19',
    message: 'Changed to ACTIVE because account activity was properly validated.',
  },
  {
    code: '20',
    message: 'Change occurred prior to the normalization of reason codes.',
  },
  {
    code: '21',
    message: 'Initiated by a third party, often a digital wallet provider.',
  },
  {
    code: '22',
    message: 'PIN retry limit reached.',
  },
  {
    code: '23',
    message: 'Card was reported stolen.',
  },
];

export const readableCodes = reasonCodes.map(({ code, message }) => `${code}: ${message}`);
