import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import AuditLogsTable from '../audit-logs-table/AuditLogsTable';

class AuditLogsTab extends Component {
  render() {
    const {
      storeInitArgs,
      onStoreConstruction,
      localStorageKey,
      excludedSearchFilters,
      loading,
      calculateTableHeight,
    } = this.props;
    return (
      <AuditLogsTable
        loading={loading}
        storeInitArgs={storeInitArgs}
        onStoreConstruction={onStoreConstruction}
        localStorageKey={localStorageKey}
        excludedFilters={excludedSearchFilters}
        calculateTableHeight={calculateTableHeight}
      />
    );
  }
}

AuditLogsTab.propTypes = {
  storeInitArgs: PropTypes.object,
  onStoreConstruction: PropTypes.func,
  localStorageKey: PropTypes.string,
  calculateTableHeight: PropTypes.func,
};

AuditLogsTab.defaultProps = {
  storeInitArgs: {},
  onStoreConstruction: () => {},
  localStorageKey: '',
  calculateTableHeight: () => 0,
};

export default observer(AuditLogsTab);
