import React, { useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { ErrorModal, ToastAlert, List, OnPageError, VSpacer } from '@mqd/volt-base';
import { Header, ExpandedContent } from './components';
import { countActiveFilters, filtersIsEqual, getRows } from './utils';
import { useDynamicHeight, useFilters } from './hooks';

import { ContextTransactionList, reducer, initialState } from './reducers';
import { TRANSACTIONS_LIST_COLUMNS } from './constants';

const TransactionList = (props) => {
  const {
    accountHolderName,
    hasRoleInArrayForActiveProgram,
    onViewMoreClick,
    onReturnClick,
    onDisputeClick,
    onAccountholderClick,
    onCardClick,
    onDigitalWalletClick,
  } = props;
  const storedProgramConfig = window.localStorage.getItem('programConfigData');
  const programConfigData = storedProgramConfig ? JSON.parse(storedProgramConfig) : {};
  const { transactionModelVersion = '1.0' } = programConfigData;

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    hasRoleInArrayForActiveProgram,
    transactionModelVersion,
    onViewMoreClick,
    onReturnClick,
    onDisputeClick,
    onAccountholderClick,
    onCardClick,
    onDigitalWalletClick,
  });

  return (
    <ContextTransactionList.Provider value={{ dispatch, state }}>
      <TransactionListContent {...props} />
    </ContextTransactionList.Provider>
  );
};

TransactionList.defaultProps = {
  hasRoleInArrayForActiveProgram: () => {},
  onViewMoreClick: () => {},
  onReturnClick: () => {},
  onDisputeClick: () => {},
  onAccountholderClick: () => {},
  onCardClick: () => {},
  onDigitalWalletClick: () => {},
};

const TransactionListContent = ({
  transactions,
  stateCodes,
  loading,
  error,
  errorExport,
  handleExport,
  cancelExportRequest,
  loadingExport,
  toastMessage,
  noTransactions,
  defaultFilters,
  pagination,
  transactionsQueryFn,
  accountHolderName,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [height, setHeight] = useState('auto');
  const [localToastMessage, setLocalToastMessage] = useState();
  const hideModal = () => setShowModal(false);
  const removeToast = () => setLocalToastMessage();

  useEffect(() => {
    if (errorExport) setShowModal(true);
  }, [errorExport]);

  useEffect(() => {
    if (toastMessage) {
      setLocalToastMessage(toastMessage);
    }
  }, [toastMessage, setLocalToastMessage]);

  useEffect(() => {
    if (localToastMessage && toastMessage !== localToastMessage) {
      setLocalToastMessage(toastMessage);
    }
  }, [toastMessage, localToastMessage, setLocalToastMessage]);

  const measureListHeight = useDynamicHeight(transactions, setHeight);

  const filterProps = useFilters({
    defaultFilters,
    pagination,
    transactionsQueryFn,
  });

  if (error) {
    return <OnPageError type="lg">Unable to load transactions</OnPageError>;
  }

  const zeroResults = transactions.length === 0;
  const { handleClear, appliedFilters, filterOptions } = filterProps;
  const nonDateFilterCount = countActiveFilters(filterOptions, appliedFilters, defaultFilters);
  const defaultFiltersApplied = filtersIsEqual(defaultFilters, appliedFilters);
  const showFilteredEmptyState = zeroResults && !defaultFiltersApplied;

  const rows = getRows(transactions, stateCodes);
  return (
    <>
      <Header
        pagination={pagination}
        transactionsQueryFn={transactionsQueryFn}
        handleExport={handleExport}
        disableExport={zeroResults}
        loadingExport={loadingExport}
        cancelExportRequest={cancelExportRequest}
        filterCount={nonDateFilterCount}
        {...filterProps}
      />

      <VSpacer />
      <div ref={measureListHeight}>
        <List
          height={height}
          loading={loading}
          columns={TRANSACTIONS_LIST_COLUMNS}
          rows={rows}
          expandedContent={(row) => (
            <ExpandedContent
              accountHolderName={accountHolderName}
              transaction={transactions.find((txn) => txn.token === row.token)}
            />
          )}
          // Hiding SummaryRow as per https://marqeta.atlassian.net/browse/PS-27938
          // summaryRow={
          //   !defaultFiltersApplied && !zeroResults
          //     ? () => <SummaryRow transactions={transactions} />
          //     : null
          // }
          emptyStateProps={{
            isFiltered: showFilteredEmptyState,
            text: noTransactions
              ? 'No transactions for this account holder yet'
              : 'No results found',
            onResetList: () => handleClear(),
          }}
        />
      </div>

      {localToastMessage && (
        <ToastAlert dismissTime={3} remove={removeToast}>
          {localToastMessage}
        </ToastAlert>
      )}

      {showModal && (
        <ErrorModal heading="Unable to complete download" hideModal={hideModal}>
          Try again
        </ErrorModal>
      )}
    </>
  );
};

TransactionList.propTypes = {
  /**
   * Raw transaction objects returned from API.
   */
  transactions: PropTypes.arrayOf(PropTypes.object),

  /**
   * This function should be based on relevant Apollo query that is able to fetch transactions.
   * It is used during filtering and pagination.
   * See useFilters, <Header /> for details.
   * @param { variables: {} }
   * @example transactionsQueryFn({
   *   variables: {
   *     start_index: 0,
   *     count: 25,
   *   }
   * })
   */
  transactionsQueryFn: PropTypes.func,

  /**
   * <List /> supports two empty states: there are no items on server yet or current filter params yield no matches.
   * noTransactions is related to former. Should be true if transaction fetching with default filters returned count === 0.
   */
  noTransactions: PropTypes.bool,

  /**
   * This should indicate if transactions fetching is in process.
   */
  loading: PropTypes.bool,
  /**
   * Transaction fetching error.
   */
  error: PropTypes.object,

  /**
   * Default values for transaction filters.
   * @example {
   *   type: [],
   *   state: [],
   *   start_date: <some date>,
   *   end_date: <some date>,
   * }
   */
  defaultFilters: PropTypes.object,

  /**
   * Export (download) transactions.
   * Handles export process: transactions fetching and further download.
   * See <Header /> for details.
   */
  handleExport: PropTypes.func,
  /**
   * Provide this function if your use-case supports canceling export process.
   * The intent here to prevent a possible lengthy operation that blocks other user actions.
   * See <Header /> for details.
   */
  cancelExportRequest: PropTypes.func,
  loadingExport: PropTypes.bool,
  errorExport: PropTypes.object,

  pagination: PropTypes.shape({
    startIndex: PropTypes.number,
    endIndex: PropTypes.number,
    isMore: PropTypes.bool,
    isLess: PropTypes.bool,
    pageSize: PropTypes.number,
  }),
  toastMessage: PropTypes.string,

  /**
   * state: FIPS State Code,
   * stusab: Official United States Postal Service (USPS) Code,
   * statens: Geographic Names Information System Identifier (GNISID),
   * state_name: State name.
   * @example { state: '06', stusab: 'CA', statens: '01779778', state_name: 'California' }
   */
  stateCodes: PropTypes.arrayOf(
    PropTypes.shape({
      state: PropTypes.string,
      stusab: PropTypes.string,
      statens: PropTypes.string,
      state_name: PropTypes.string,
    })
  ),

  /**
   * Should be able to accept array of valid red sea roles and determine if user has any such role in active program.
   * Used to initiate transaction dispute.
   */
  hasRoleInArrayForActiveProgram: PropTypes.func,

  /**
   * Below props are used within expanded area of list row for different transaction types.
   * See <ExpandedContent /> for details.
   */
  accountHolderName: PropTypes.string,
  onDisputeClick: PropTypes.func,
  onReturnClick: PropTypes.func,
  onViewMoreClick: PropTypes.func,
  onAccountholderClick: PropTypes.func,
  onCardClick: PropTypes.func,
  onDigitalWalletClick: PropTypes.func,
};

TransactionListContent.defaultProps = {
  transactions: [],
  error: null,
  exportProps: {},
  pagination: {},
};

export default TransactionList;
