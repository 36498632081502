import React from 'react';
import { ContentSnippet, Text, VSpacer } from '@mqd/volt-base';
import { ParentDetailCard } from '@mq/voltron-parent';
import DetailCard from '../detail-card/DetailCard';

class CommandoModeAuthCard extends ParentDetailCard {
  state = {
    editActive: false,
  };

  renderInfo() {
    const { token = '', name = '', start_time = '', end_time = '', active = '' } = this.props;
    return (
      <DetailCard title="Auth control">
        {this.renderSnippet({
          title: 'Token',
          content: token,
        })}
        {this.renderSnippet({
          title: 'Name',
          content: name,
        })}
        {this.renderSnippet({
          title: 'Start Time',
          content: start_time,
        })}
        {this.renderSnippet({
          title: 'End Time',
          content: end_time,
        })}
        {this.renderSnippet({
          title: 'Active',
          content: active,
        })}
      </DetailCard>
    );
  }

  render() {
    return <div>{this.renderInfo()}</div>;
  }
}

export default CommandoModeAuthCard;
