import { weakPins } from './constants';

export const pinValidators = {
  length: {
    validator: (value) => !/^\d{4}$/.test(value),
    message: 'PIN must be a 4-digit number.',
  },
  weak: {
    validator: (value) => weakPins.includes(value),
    message: 'Weak PIN. Enter a new PIN.',
  },
};

export const confirmPinValidators = {
  match: {
    validator: (value, formState) => value !== formState.pin,
    message: 'PINs do not match.',
  },
};
