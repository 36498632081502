import React, { useEffect, useMemo } from 'react';
import { Flex, HSpacer, VSpacer } from '@mqd/volt-base';

import TextColumn from '../../../components/TextColumn/index.js';
import TransactionDetailContent from '../../../components/TransactionDetailContent/TransactionDetailContent.js';
import { useAdjustTransactionSettlementAndFeesDispatchContext } from '../../../components/TransactionDetailContent/context.js';
import { SET_INITIAL_STATE } from '../../../components/TransactionDetailContent/constants.js';
import getSource from '../../../utilities/get-transaction-source.js';
import { ADJUSTMENT_ACTIONS } from '../../../utilities/dictionary/index.js';
import { useAdjustmentActionDispatchContext } from '../../../components/AdjustmentAction/context.js';
import { SELECT_ACTION, SELECT_REASON } from '../../../components/AdjustmentAction/constants.js';
import { EXCEPTION_TYPES } from 'views/fds/utilities/dictionary/index.js';
import { dashCaseToConstant } from 'views/fds/utilities/string/index.js';

import { calculateSettlementAmountAndFees } from './utils.js';
import getFields from '../../../network-configs/fields/index.js';
import Activity from './Activity.js';

const ExceptionDetails = ({
  business_activity,
  commonData = {},
  cycle,
  exceptionType,
  settledDetails,
  handleAddNote,
  handleViewAllActivity,
  hashId,
  loading,
  notes,
  program,
  settlementDate,
  tx_hash_internal_id,
}) => {
  const {
    acquirer_reference_id,
    bank,
    currency_code,
    ica,
    masked_pan,
    network,
    net_ref_id,
    program_type,
    reference_id,
    settlement_service_number,
    source,
    state,
    subnetwork,
  } = commonData;
  const hashIdInformation = useMemo(
    () =>
      getFields('hash_group', {
        acquirer_reference_id,
        bank,
        exceptionType,
        hashId,
        ica,
        net_ref_id,
        network,
        program,
        program_type,
        reference_id,
        settlement_service_number,
        showSubmissionFields: false,
        showReportingEntities: false,
        settlementDate,
        source,
        state,
        subnetwork,
        tx_hash_internal_id,
      }),
    [
      acquirer_reference_id,
      bank,
      exceptionType,
      hashId,
      ica,
      net_ref_id,
      network,
      program,
      program_type,
      reference_id,
      settlementDate,
      settlement_service_number,
      source,
      state,
      subnetwork,
      tx_hash_internal_id,
    ]
  );

  const transactionInformation = useMemo(
    () => [
      {
        label: 'Source',
        content: getSource({ source, network, subnetwork }),
      },
      {
        label: 'PAN',
        pan: masked_pan,
        toggleVisibility: true,
        tx_hash_internal_id,
        hasContent: false,
      },
    ],
    [masked_pan, network, source, subnetwork, tx_hash_internal_id]
  );

  const settlementAmountAndFees = useMemo(
    () => calculateSettlementAmountAndFees(settledDetails ?? {}, network),
    [network, settledDetails]
  );

  const settlementAndFeesDispatch = useAdjustTransactionSettlementAndFeesDispatchContext();
  const adjustmentActionDispatch = useAdjustmentActionDispatchContext();

  useEffect(() => {
    settlementAndFeesDispatch({
      type: SET_INITIAL_STATE,
      payload: calculateInitialState(settlementAmountAndFees),
    });
  }, [settlementAmountAndFees, settlementAndFeesDispatch]);

  useEffect(() => {
    if (exceptionType) {
      adjustmentActionDispatch({
        type: SELECT_ACTION,
        payload: ADJUSTMENT_ACTIONS.ADJUST_TRANSACTION,
      });
      // The user can only submit an adjustment with a "Failed to post" or
      // "Overposted" reason. So, if the exception type is "Other", we should
      // not automatically set the reason. Instead, allow the user to pick it
      // themselves.
      if (exceptionType !== dashCaseToConstant(EXCEPTION_TYPES.OTHER))
        adjustmentActionDispatch({ type: SELECT_REASON, payload: exceptionType });
    }
  }, [adjustmentActionDispatch, exceptionType]);

  return (
    <Flex flexDirection="row">
      <Flex flex="0 0 50%">
        <TransactionDetailContent
          settlementAmountAndFees={settlementAmountAndFees}
          currencyCode={currency_code}
        />
        <VSpacer factor={2} />
      </Flex>
      <HSpacer factor={3} />
      {!loading && (
        <Flex flexDirection="column">
          <TextColumn
            data={hashIdInformation}
            header="Hash ID group information"
            idPrefix="exceptionDetailsHashIdGroupInformation"
          />
          <VSpacer factor={3} />
          <TextColumn
            data={transactionInformation}
            header="Transaction information"
            idPrefix="exceptionDetailsHashIdGroupInformation"
          />
          <VSpacer factor={3} />
          <Activity
            notes={notes}
            addActivityHandler={handleAddNote}
            handleViewAllActivity={handleViewAllActivity}
            tx_hash_internal_id={tx_hash_internal_id}
          />
        </Flex>
      )}
    </Flex>
  );
};

function calculateInitialState(settlementAmountAndFees, initialState) {
  return settlementAmountAndFees.reduce((acc, { id, content }) => {
    return {
      ...acc,
      [id]: content,
    };
  }, initialState);
}

export default ExceptionDetails;
