import janusApi from 'apis/JanusApi';
import LoadingStore from 'stores/LoadingStore';
import logger from 'utils/logger';
import {
  applicationDataUtils,
  authorization,
  googleAnalytics,
  janusUtils,
  redseaUtils,
} from '@mq/volt-amc-container';

const { removeJanusUserKeysFromLocalStorage, clearActingAsFromLocalStorage } = janusUtils;
const { clearApplicationDataLocalStorage } = applicationDataUtils;
const { redseaAuthHeaders, removeRedseaUserKeysFromLocalStorage } = redseaUtils;

/*
  This file is for UserStore logOut functionality, used by JanusApi.
  The main logOut functionality is located in the volt-amc-container package.
  Any changes to logOut functionality should also be made there.
*/

export default async function logOut() {
  LoadingStore.startLoading('Signing Out');
  try {
    await janusApi.postData(
      '/diva/security/users/redsea/logout',
      null, // params
      null, // viewStore
      redseaAuthHeaders()
    );
  } catch (error) {
    // do nothing
    logger.error(error);
  }
  if (googleAnalytics) {
    googleAnalytics.event({
      category: 'Key User Events',
      action: 'User Logged Out',
      label: 'User Logged Out',
    });
  }
  removeJanusUserKeysFromLocalStorage();
  removeRedseaUserKeysFromLocalStorage();
  clearApplicationDataLocalStorage();
  clearActingAsFromLocalStorage();
  authorization.logout(undefined, 'utils/logOut.js');
  // dont LoadingStore.stopLoading();
  // because the user will be redirected
  // away from the site at this poin
}
