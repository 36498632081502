import { FlipFlop } from '../..';
import currentUserStore from '../../../stores/CurrentUserStore';

const HSM_ROLE = 'manage-hsm-key';
const HSM_FEATURE_FLAG = 'hsm-keys';

export const requirements = {
  requiredUserPermissions: [HSM_ROLE],
  flipFlopRequirements: [HSM_FEATURE_FLAG],
};

export const verifier = () => {
  const {
    userStore: { hasRole },
  } = currentUserStore;
  return hasRole(HSM_ROLE) || FlipFlop.get(HSM_FEATURE_FLAG, false);
};
