import { decorate, observable, action, computed } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';
import CardProductFulfillmentStore from './CardProductFulfillmentStore';
import CardLifeCycleStore from './CardLifeCycleStore';
import CardProductJitFundingStore from './CardProductJitFundingStore';

class CardProductConfigStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.loadAndConstruct(args);
  }
  // store conifg
  skipAutoLoad: boolean = true;

  // values
  fulfillment: CardProductFulfillmentStore = {};
  card_life_cycle: CardLifeCycleStore = {};
  jit_funding: CardProductJitFundingStore = {};

  loadAndConstruct(cardProductConfigData = {}) {
    const { fulfillment, card_life_cycle, jit_funding } = cardProductConfigData;
    this.loadAndConstructItem('fulfillment', fulfillment, CardProductFulfillmentStore);
    this.loadAndConstructItem('card_life_cycle', card_life_cycle, CardLifeCycleStore);
    this.loadAndConstructItem('jit_funding', jit_funding, CardProductJitFundingStore);
  }

  // computed
}

decorate(CardProductConfigStore, {
  skipAutoLoad: observable,
  // values
  fulfillment: observable,
  card_life_cycle: observable,
  jit_funding: observable,

  // actions
  loadAndConstruct: action.bound,

  // computed
});

export default CardProductConfigStore;
