import { decorate, observable, action, computed } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';
import AssociationStore from './AssociationStore';
import MerchantScopeStore from './MerchantScopeStore';
import moment from 'moment';

class AuthControlStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.loadAndConstruct(args);
  }
  // store conifg
  skipAutoLoad: boolean = true;

  // values
  token: string = '';
  name: string = '';
  start_time: string = '';
  end_time: string = '';
  active: boolean = false;
  // objects
  association: AssociationStore = {};
  merchant_scope: MerchantScopeStore = {};

  // actions
  loadAndConstruct(authControlData = {}) {
    this.load(authControlData);
    const { association, merchant_scope } = authControlData;
    this.loadAndConstructItem('association', association, AssociationStore);
    this.loadAndConstructItem('merchant_scope', merchant_scope, MerchantScopeStore);
  }

  // computed
  get formattedStartTime() {
    return this.start_time && new moment(this.start_time).utc().format('YYYY-MM-DD');
  }
}

decorate(AuthControlStore, {
  skipAutoLoad: observable,
  // values
  token: observable,
  name: observable,
  start_time: observable,
  end_time: observable,
  active: observable,
  // objects
  association: observable,
  merchant_scope: observable,

  // actions
  loadAndConstruct: action.bound,

  // actions
  formattedStartTime: computed,
});

export default AuthControlStore;
