import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ActionCard } from '@mqd/volt-base';
import { T } from 'ramda';

import { useCardTemplateContext } from '../../context/CardTemplateContext';
import { createButtonKeyDownHandler } from '../../helpers';

const { MODE, TemplateWrapper } = ActionCard;

export function DefaultCardTemplate({
  datum,
  mode,
  editTemplate,
  previewTemplate,
  switchToPreview,
  switchToEdit,
}) {
  const EditTemplate = editTemplate;
  const PreviewTemplate = previewTemplate;

  const { isFirstRenderOfGroup } = useCardTemplateContext();

  useEffect(() => {
    if (mode === MODE.PREVIEW && !isFirstRenderOfGroup) {
      templateRef.current?.focus();
    }
  }, []);

  const templateRef = useRef();

  const handleSwitchToPreview = () => {
    switchToPreview();
    templateRef.current?.focus();
  };

  if (mode === MODE.EDIT) {
    const switchToPreviewMaybe = datum.temp ? T : switchToPreview;

    return (
      <TemplateWrapper
        ref={templateRef}
        mode={mode}
        onKeyDown={createButtonKeyDownHandler(switchToPreviewMaybe)}
        dynamicWidth
      >
        <EditTemplate datum={datum} switchToPreview={handleSwitchToPreview} />
      </TemplateWrapper>
    );
  }

  if (mode === MODE.PREVIEW) {
    const switchToEditMaybe = datum.error || datum.loading ? T : switchToEdit;

    return (
      <TemplateWrapper
        ref={templateRef}
        mode={mode}
        onKeyDown={createButtonKeyDownHandler(switchToEditMaybe)}
        onClick={switchToEditMaybe}
        dynamicWidth
      >
        <PreviewTemplate datum={datum} switchToEdit={switchToEdit} />
      </TemplateWrapper>
    );
  }

  return null;
}

DefaultCardTemplate.propTypes = {
  datum: PropTypes.shape({
    temp: PropTypes.bool,
    loading: PropTypes.bool,
    error: PropTypes.bool,
  }).isRequired,
  mode: TemplateWrapper.propTypes.mode,
  editTemplate: PropTypes.element,
  previewTemplate: PropTypes.element,
  switchToPreview: PropTypes.func, // is passed when mode === MODE.EDIT
  switchToEdit: PropTypes.func, // is passed when mode === MODE.PREVIEW
};

const warning = (mode) => {
  console.warn(`Tried switching to "${mode}" mode when it's already set.`);
};

DefaultCardTemplate.defaultProps = {
  switchToPreview: () => {
    warning(MODE.PREVIEW);
  },
  switchToEdit: () => {
    warning(MODE.EDIT);
  },
};
