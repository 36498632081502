import React from 'react';
import PropTypes from 'prop-types';
import { VSpacer, DetailPane, CopyToClipboard, Text, Hr } from '@mqd/volt-base';
import { DetailPaneContentWithNoneValue } from '@components/index.js';
import { parseDateTimeString } from '../../../utils/helpers/parseDateString.js';
import {
  getTransitionDate,
  getTransitionReason,
  getVerificationStatus,
  isAchTransaction,
} from '../utils.js';
import { mapTransferStatus } from '@mq/parity-plus';

const AchTransactionDetails = ({ transaction, tooltipDirection, fundingSourceSectionHeader }) => {
  const { bank_transfer_token, bankTransfer } = transaction;
  if (!bank_transfer_token || !isAchTransaction(transaction)) return null;
  const {
    status,
    type,
    transitions,
    return_code,
    return_reason,
    funding_source_token,
    achFundingSource,
    standard_entry_class_code,
    statement_descriptor,
    transfer_speed
  } = bankTransfer || {};
  const { token, account_suffix, created_time, bank_name } = achFundingSource || {};

  const achCompletedCreatedTime = getTransitionDate(transitions, 'COMPLETED');
  const achReturnedCreatedTime = getTransitionDate(transitions, 'RETURNED');
  const achCancelledCreatedTime = getTransitionDate(transitions, 'CANCELLED');
  const achCancelReason = getTransitionReason(transitions, 'CANCELLED');

  const renderTransactionCompletionDate = () => {
    const showDate =
      Array.isArray(transitions) && achCompletedCreatedTime && (type === 'PUSH' || type === 'PULL');

    if (showDate) {
      const label = type === 'PUSH' ? 'Debit completion date' : 'Credit completion date';
      return (
        <DetailPaneContentWithNoneValue label={label}>
          {parseDateTimeString(achCompletedCreatedTime)}
        </DetailPaneContentWithNoneValue>
      );
    }
  };

  const renderCancelFields = () => {
    const [reason, ...rest] = achCancelReason.split(' - ');
    const description = rest.length > 1 ? rest.join(' - ') : rest[0];
    return (
      status === 'CANCELLED' && (
        <>
          <DetailPane.Content label="Cancelled date">
            {parseDateTimeString(achCancelledCreatedTime)}
          </DetailPane.Content>
          <DetailPaneContentWithNoneValue label="Cancel reason">
            {reason}
          </DetailPaneContentWithNoneValue>
          <DetailPaneContentWithNoneValue label="Cancel description">
            {description}
          </DetailPaneContentWithNoneValue>
        </>
      )
    );
  };

  return (
    <div data-testid="ach-transaction-bank-transfer">
      <VSpacer factor={8} />
      <Hr factor={0} />
      <VSpacer factor={8} />
      <Text type="h4">Bank transfer details</Text>
      <VSpacer factor={3} />
      <DetailPane.Container>
        <DetailPaneContentWithNoneValue label="Transfer status">
          {mapTransferStatus(status)}
        </DetailPaneContentWithNoneValue>
        {renderCancelFields()}
        {renderTransactionCompletionDate()}
        <DetailPaneContentWithNoneValue label="Transfer token">
          <CopyToClipboard tooltipDirection={tooltipDirection}>
            {bank_transfer_token}
          </CopyToClipboard>
        </DetailPaneContentWithNoneValue>
        <DetailPaneContentWithNoneValue label="Transfer speed">
          {transfer_speed}
        </DetailPaneContentWithNoneValue>
        <DetailPaneContentWithNoneValue label="SEC code">
          {standard_entry_class_code}
        </DetailPaneContentWithNoneValue>
        <DetailPaneContentWithNoneValue label="Statement descriptor">
          {statement_descriptor}
        </DetailPaneContentWithNoneValue>
      </DetailPane.Container>

      {Array.isArray(transitions) && achReturnedCreatedTime && (
        <div data-testid="ach-transaction-return-details">
          <VSpacer factor={5} />
          <Text type="h5">Return details</Text>
          <VSpacer factor={2} />
          <DetailPane.Container>
            <DetailPaneContentWithNoneValue label="Return date">
              {parseDateTimeString(achReturnedCreatedTime)}
            </DetailPaneContentWithNoneValue>
            <DetailPaneContentWithNoneValue label="Return code">
              {return_code}
            </DetailPaneContentWithNoneValue>
            <DetailPaneContentWithNoneValue label="Return description">
              {return_reason}
            </DetailPaneContentWithNoneValue>
          </DetailPane.Container>
        </div>
      )}
      <VSpacer factor={8} />

      {funding_source_token && achFundingSource && (
        <div data-testid="ach-transaction-funding-source-details">
          <Hr factor={0} />
          <VSpacer factor={8} />
          <Text type="h4">{fundingSourceSectionHeader}</Text>
          <VSpacer factor={3} />
          <DetailPane.Container>
            <DetailPaneContentWithNoneValue label="Bank">
              {bank_name}
            </DetailPaneContentWithNoneValue>
            <DetailPaneContentWithNoneValue label="Verification status">
              {getVerificationStatus(bankTransfer)}
            </DetailPaneContentWithNoneValue>
            <DetailPaneContentWithNoneValue label="Created date">
              {parseDateTimeString(created_time)}
            </DetailPaneContentWithNoneValue>
            <DetailPaneContentWithNoneValue label="Last 4 of Acc No.">
              {account_suffix}
            </DetailPaneContentWithNoneValue>
            <DetailPaneContentWithNoneValue label="Funding source token">
              {token ? (
                <CopyToClipboard tooltipDirection={tooltipDirection}>{token}</CopyToClipboard>
              ) : (
                token
              )}
            </DetailPaneContentWithNoneValue>
          </DetailPane.Container>
        </div>
      )}
    </div>
  );
};

AchTransactionDetails.defaultProps = {
  transaction: {},
  tooltipDirection: 'top',
};

AchTransactionDetails.propTypes = {
  transaction: PropTypes.object,
  tooltipDirection: PropTypes.string,
  fundingSourceSectionHeader: PropTypes.string,
};

AchTransactionDetails.defaultProps = {
  fundingSourceSectionHeader: 'External account',
};

export default AchTransactionDetails;
