import React from 'react';
import ToastStoreProvider from '../components/ToastStoreProvider/index.js';

export function withToastStore(Component) {
  return function WithToastStoreComponent(props) {
    return (
      <ToastStoreProvider>
        <Component {...props} />
      </ToastStoreProvider>
    );
  };
}
