import React from 'react';
import { TransactionHistoryStore } from '../../../../stores/program-reserve/transaction-history-store.js';
import { TransactionHistoryStoreContext } from '../../context/TransactionHistoryStoreContext.js';
import { columns } from '../ProgramReserve/components/TransactionHistory/components/OverviewTab/columns.js';
import { filterColumns } from '../../utils/filterColumns.js';

const TransactionHistoryStoreProvider = ({ children }) => {
  const initialColumns = filterColumns(columns);
  const transactionHistoryStore = new TransactionHistoryStore(initialColumns);

  return (
    <TransactionHistoryStoreContext.Provider value={transactionHistoryStore}>
      {children}
    </TransactionHistoryStoreContext.Provider>
  );
};

export default TransactionHistoryStoreProvider;
