import { is, isNil } from 'ramda';

/**
 * Deeply compares the key / value pairs of initObject with the comparisonObject
 * and returns the key / value pairs of the initObject that differ from the
 * comparison object
 * @param {object} initObject
 * @param {object} comparisonObject
 * @returns {object}
 *
 * @example
 * const initObject = { name: { firstName: 'hello', lastName: 'goodbye': } }
 * const comparisonObject = { name: { firstName: 'sup', lastName: 'goodbye', middleName: 'pie' } }
 *
 * returns { name: { firstName: 'hello' } }
 */
export default function deepObjectDiff(obj1, obj2, keyPairs = {}) {
  return Object.keys(obj1).reduce((acc, key) => {
    const obj1Value = obj1[key];
    const obj2Value = obj2[key];
    if (is(Object, obj1Value)) {
      if (is(Array, obj1Value)) {
        acc[key] = arrayDiff(obj1Value, obj2Value);
      } else {
        acc[key] = deepObjectDiff(obj1Value, obj2Value, keyPairs[key]);
      }
    } else if (obj1Value !== obj2Value) {
      acc[key] = obj1Value;

      const dependency = keyPairs[key];
      if (dependency && isNil(acc[dependency])) {
        acc[dependency] = obj2[dependency];
      }
    }
    return acc;
  }, {});
}

function arrayDiff(arr1, arr2) {
  return arr1.reduce((arrAcc, arrItem, index) => {
    const arr2Item = arr2[index];
    if (is(Object, arrItem)) {
      if (is(Array, arrItem)) {
        return arrayDiff(arrItem, arr2Item);
      }
      return deepObjectDiff(arrItem, arr2Item);
    }

    if (arrItem !== arr2Item) {
      arrAcc.push(arrItem);
    }

    return arrAcc;
  }, []);
}
