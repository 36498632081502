import React, { useState } from 'react';
import { LoadingOverlay } from '@mqd/volt-base';
import { Layout } from '../../layouts';
import { renderIf } from '../../utils/renderIf.js';
import { WebhookDetailsHeader, WebhookDetailsMain } from './webhooks';
import { WebhookProvider } from './contexts';
import { useCreateWebhook } from './hooks';
import { WebhookUpdatedToast, RequestFailedModal } from './webhooks/components';
import routerStore from 'stores/router/RouterStore';

const goBackToOverview = () => routerStore.go('developmentWebhooksOverview');

const WebhooksNew = () => {
  const initialWebhookValues = {
    name: '',
    url: '',
    username: '',
    password: '',
    headers: '',
    active: true,
    events: ['*'],
  };
  const [showUpdateToast, setShowUpdateToast] = useState(false);
  const [updateSuccessful, setUpdateSuccessful] = useState(true);
  const [webhookValues, setWebhookValues] = useState(initialWebhookValues);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const onCompletedHandler = ({ updateWebhook }, _error) => {
    if (_error) {
      setShowErrorModal(true);
    } else {
      setUpdateSuccessful(true);
      setShowUpdateToast(true);
      goBackToOverview();
    }
  };

  // When form change, update initial value of webhook context
  const onUpdateWebhookValues = (data) => {
    setWebhookValues(data);
  };

  const { createWebhook, error, loading } = useCreateWebhook({ onCompleted: onCompletedHandler });

  const dismissToast = () => {
    setShowUpdateToast(false);
  };

  return (
    <Layout>
      <LoadingOverlay active={loading}>
        <WebhookProvider
          defaultWebhookValues={webhookValues}
          error={error}
          onSave={createWebhook}
          saving={loading}
          onUpdateWebhook={onUpdateWebhookValues}
        >
          <WebhookDetailsHeader />
          <WebhookDetailsMain />
        </WebhookProvider>
        {renderIf(
          showUpdateToast,
          <WebhookUpdatedToast dismissToast={dismissToast} isSuccessful={updateSuccessful} />
        )}
        {renderIf(
          showErrorModal,
          <RequestFailedModal hideModalCallback={() => setShowErrorModal(false)} />
        )}
      </LoadingOverlay>
    </Layout>
  );
};

export default WebhooksNew;
