import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { Colors, Flex, Text } from '@mqd/volt-base';

import s from './Title.module.css';

export function Title({ name, label, inactive, collapsed }) {
  const color = inactive ? Colors.blackLighter4 : null;
  const className = cn({ [s.collapsed]: collapsed });

  return (
    <Flex flexDirection="row" alignItems="center">
      <Text type="h6" color={color} className={className}>
        {name}
      </Text>
      {label}
    </Flex>
  );
}

Title.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  inactive: PropTypes.bool,
  collapsed: PropTypes.bool,
};

Title.defaultProps = {
  label: null,
  inactive: false,
  collapsed: false,
};
