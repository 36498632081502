import moment from 'moment';
import { flipFlop } from '@mq/volt-amc-container';
import { isNotPresent } from '../../../../packages/parity-plus/src/shared-utils/index.js';

import {
  DIGITAL_WALLET_TOKEN_CONDITIONAL_FIELDS,
  TRANSACTION_TYPES,
  JIT_FUNDING_FIELDS,
  JIT_FUNDING_SUBSECTIONS,
  POS_FIELDS,
  POS_SUBSECTIONS,
  TRANSACTION_SECTIONS,
  TRANSACTION_CONDITIONAL_BLOCKS,
  AMOUNT_FIELDS,
  AMOUNT_SUBSECTIONS,
} from './constants';

export const formatDate = (date) => {
  return moment.utc(date).format('YYYY-MM-DD, HH:mm:ss');
};

export const formatDisplayValue = (str) => {
  if (typeof str === 'string') {
    const toSentenceCase = str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    return toSentenceCase.replace(/_/g, ' ');
  } else {
    return str;
  }
};

export const getColSpans = (containerWidth) => {
  let colSpans = [6, 6];
  if (containerWidth <= 1200) colSpans = [9, 3];
  if (containerWidth <= 1085) colSpans = [12, 12];
  return colSpans;
};

export const getColSpansForRestColumns = (containerWidth) => {
  let colSpans = [6, 6];
  if (containerWidth <= 1085) colSpans = [12, 12];
  return colSpans;
};

export const formatMccDisplay = (mccString) => {
  if (!mccString) return;
  //formatting the data from gql: 123 (Description) => [123] Description
  let mccName = mccString?.replace(/[()]/g, '')?.split(' ');
  if (mccName) {
    mccName[0] = `[${mccName[0]}]`;
  }
  return mccName?.join(' ');
};

export const parseMetadata = (metadata) => {
  if (metadata) {
    return JSON.parse(metadata);
  } else {
    return {};
  }
};

export const formatFundingTypeDisplay = (fundingTypeString) => {
  if (!fundingTypeString) return;
  return fundingTypeString === 'programgateway'
    ? 'Program gateway'
    : formatDisplayValue(fundingTypeString);
};

export const formatAcquirerExemption = (acquirerExemptionArray) => {
  if (!acquirerExemptionArray) return;
  return Array.isArray(acquirerExemptionArray)
    ? acquirerExemptionArray.map((exemption) => formatDisplayValue(exemption)).join(', ')
    : formatDisplayValue(acquirerExemptionArray);
};

export const getTransactionTypes = (transaction) => {
  const {
    digital_wallet_token,
    transaction_metadata,
    type,
    state,
    currency_conversion,
    address_verification,
    gpa_order,
  } = transaction;
  const parsedMetadata = parseMetadata(transaction_metadata);
  const conversionRate = currency_conversion?.network?.conversion_rate;
  const sourceType = gpa_order?.funding?.source?.type;
  const transactionTypes = [];

  if (
    digital_wallet_token &&
    parsedMetadata?.payment_channel?.toUpperCase() === 'ECOMMERCE' &&
    type?.toUpperCase() === 'AUTHORIZATION'
  ) {
    transactionTypes.push(TRANSACTION_TYPES.TOKEN_ECOMMERCE_AUTHORIZATION);
  }

  if (
    digital_wallet_token &&
    parsedMetadata?.payment_channel?.toUpperCase() !== 'ECOMMERCE' &&
    type?.toUpperCase() === 'AUTHORIZATION'
  ) {
    transactionTypes.push(TRANSACTION_TYPES.TOKEN_AUTHORIZATION);
  }

  if (state === 'DECLINED' && type === 'authorization') {
    transactionTypes.push(TRANSACTION_TYPES.DECLINED_AUTHORIZATION);
  }

  if (
    parsedMetadata?.payment_channel?.toUpperCase() === 'ECOMMERCE' &&
    type?.toUpperCase() === 'AUTHORIZATION'
  ) {
    transactionTypes.push(TRANSACTION_TYPES.ECOMMERCE_AUTHORIZATION);
  }

  if (type === 'authorization' && address_verification) {
    transactionTypes.push(TRANSACTION_TYPES.ADDRESS_VERIFICATION_AUTHORIZATION);
  }

  if (type === 'authorization' && sourceType === 'programgateway') {
    transactionTypes.push(TRANSACTION_TYPES.JIT_AUTHORIZATION);
  }

  if (conversionRate !== 1) {
    transactionTypes.push(TRANSACTION_TYPES.MULTICURRENCY);
  }

  if (transactionTypes.length === 0) {
    transactionTypes.push(TRANSACTION_TYPES.GENERIC_TRANSACTION);
  }

  return transactionTypes;
};

export const getFieldsAndSubSections = (section, transactionTypes) => {
  let fieldsSet = new Set();
  let subSectionsSet = new Set();
  transactionTypes.forEach((transactionType) => {
    if (section === TRANSACTION_SECTIONS.DIGITAL_WALLET_TOKEN) {
      if (DIGITAL_WALLET_TOKEN_CONDITIONAL_FIELDS[transactionType]) {
        DIGITAL_WALLET_TOKEN_CONDITIONAL_FIELDS[transactionType].forEach(fieldsSet.add, fieldsSet);
      }
    }
    if (section === TRANSACTION_SECTIONS.JIT_FUNDING) {
      if (JIT_FUNDING_FIELDS[transactionType]) {
        JIT_FUNDING_FIELDS[transactionType].forEach(fieldsSet.add, fieldsSet);
      }
      if (JIT_FUNDING_SUBSECTIONS[transactionType]) {
        JIT_FUNDING_SUBSECTIONS[transactionType].forEach(subSectionsSet.add, subSectionsSet);
      }
    }
    if (section === TRANSACTION_SECTIONS.POS) {
      if (POS_FIELDS[transactionType]) {
        POS_FIELDS[transactionType].forEach(fieldsSet.add, fieldsSet);
      }
      if (POS_SUBSECTIONS[transactionType]) {
        POS_SUBSECTIONS[transactionType].forEach(subSectionsSet.add, subSectionsSet);
      }
    }
    if (section === TRANSACTION_SECTIONS.AMOUNT) {
      if (AMOUNT_FIELDS[transactionType]) {
        AMOUNT_FIELDS[transactionType].forEach(fieldsSet.add, fieldsSet);
      }
      if (AMOUNT_SUBSECTIONS[transactionType]) {
        AMOUNT_SUBSECTIONS[transactionType].forEach(subSectionsSet.add, subSectionsSet);
      }
    }
  });
  return {
    fields: [...fieldsSet],
    subsections: [...subSectionsSet],
  };
};

export const getConditionalBlocks = (transactionTypes) => {
  let conditionalBlocksSet = new Set();
  transactionTypes.forEach((transactionType) => {
    if (TRANSACTION_CONDITIONAL_BLOCKS[transactionType]) {
      TRANSACTION_CONDITIONAL_BLOCKS[transactionType].forEach(
        conditionalBlocksSet.add,
        conditionalBlocksSet
      );
    }
  });
  return conditionalBlocksSet;
};

export const extractDigitalWalletToken = (transaction) => {
  const { digital_wallet_token } = transaction || {};

  const {
    card_token,
    created_time,
    fulfillment_status,
    issuer_eligibility_decision,
    last_modified_time,
    state,
    state_reason,
    token,
    token_service_provider,
    wallet_provider_profile,
  } = digital_wallet_token || {};

  const {
    pan_reference_id,
    token_eligibility_decision,
    token_expiration,
    token_pan,
    token_reference_id,
    token_requestor_id,
    token_requestor_name,
    token_type,
  } = token_service_provider || {};

  const { risk_assessment } = wallet_provider_profile || {};
  const { score, version } = risk_assessment || {};

  return {
    state,
    state_reason,
    fulfillment_status,
    issuer_eligibility_decision,
    token,
    card_token,
    token_reference_id,
    pan_reference_id,
    token_requestor_id,
    token_requestor_name,
    token_type,
    token_pan,
    token_expiration,
    token_eligibility_decision,
    score,
    version,
    created_time,
    last_modified_time,
  };
};

export const extractDevice = (transaction) => {
  const { digital_wallet_token } = transaction || {};

  const { device, wallet_provider_profile, address_verification } = digital_wallet_token || {};

  const { type, phone_number, name, location, ip_address, language_code, device_id } = device || {};
  const { pan_source, device_score, reason_code, account } = wallet_provider_profile || {};
  const { street_address, zip } = address_verification || {};

  const { id, email_address, score } = account || {};

  return {
    type,
    pan_source,
    phone_number,
    name,
    location,
    ip_address,
    language_code,
    device_score,
    reason_code,
    device_id,
    id,
    email_address,
    score,
    street_address,
    zip,
  };
};

export const extractBalances = (transaction) => {
  const balances =
    transaction?.gpa_order?.funding?.gateway_log?.response?.data?.jit_funding?.balances;
  if (!balances) {
    return [];
  }
  return Object.entries(balances)
    .map(([, value]) => value)
    .filter((el) => el?.currency_code);
};

export const extractCardholderAuthenticationData = (transaction) => {
  const { acquirer_exemption, cardholder_authentication_data_v2, pos } = transaction;

  const {
    authentication_method,
    authentication_status,
    electronic_commerce_indicator,
    three_ds_message_version,
    verification_result,
    verification_value_created_by,
  } = cardholder_authentication_data_v2 || {};

  const { cardholder_authentication_method } = pos || {};

  return {
    authentication_method,
    authentication_status,
    acquirer_exemption,
    cardholder_authentication_method,
    electronic_commerce_indicator,
    verification_result,
    verification_value_created_by,
    three_ds_message_version,
  };
};

export const extractAddressVerification = (transaction) => {
  const { address_verification } = transaction || {};
  const { response, request, on_file } = address_verification || {};
  const { memo, code } = response || {};
  const { street_address: request_street_address, postal_code: request_postal_code } =
    request || {};

  const { street_address: on_file_street_address, postal_code: on_file_postal_code } =
    on_file || {};

  return {
    code,
    memo,
    request_street_address,
    request_postal_code,
    on_file_street_address,
    on_file_postal_code,
  };
};

export const isDirectDeposit = (type) => {
  return type?.startsWith('directdeposit');
};

export const isPullFromCard = (data) => {
  return !!data?.transaction?.pullFromCard;
};

export const getTxnType = (data, type) => {
  if (isPullFromCard(data)) {
    return 'pullFromCard';
  } else if (isDirectDeposit(type)) {
    return 'directDeposit';
  }
  return 'default';
};

export const ignoreImpactedAmount = (impactedAmount, state, type) => {
  const isImpactedAmountFlagActive = flipFlop.get('impacted-amount', false);
  const isAuthorizationType = type?.toUpperCase() === 'AUTHORIZATION';
  const hasOneOfTheAuthTxnStates = isAuthorizationType && ['DECLINED'].includes(state);

  if (isImpactedAmountFlagActive) {
    if (hasOneOfTheAuthTxnStates && isNotPresent(impactedAmount)) return true;
    else return false;
  } else return true;
};

export const transformRelatedTransactions = (data) => {
  const relatedTransactions = data?.relatedTransactions?.data || [];
  const { preceding_related_transaction_token } = data?.transaction || {};

  return relatedTransactions.map((rt) => ({
    ...rt,
    relation : preceding_related_transaction_token === rt.token
      ? 'Preceding'
      : 'Other'
  }));
};
