const processLog = function (funcName, ...args) {
  if (process.env.SKIP_LOGS) return;

  console[funcName](...args);
};

const logger = {
  groupCollapsed: function (...args) {
    processLog('groupCollapsed', ...args);
  },
  groupEnd: function (...args) {
    processLog('groupEnd', ...args);
  },
  log: function (...args) {
    processLog('log', ...args);
  },
  info: function (...args) {
    processLog('info', ...args);
  },
  warn: function (...args) {
    processLog('warn', ...args);
  },
  error: function (...args) {
    processLog('error', ...args);
  },
};

export default logger;
