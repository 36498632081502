import React from 'react';
import { Text, HSpacer, VSpacer, Input } from '@mqd/volt-base';
import { transformEntityString } from '../../helpers';
import s from './KYBCard.module.css';
import IconInput from './IconInput';
import StatusIcon from './StatusIcon';
import PropTypes from 'prop-types';

class Row extends React.PureComponent {
  showStatusIcon() {
    const { onChangeStatus, name, userStore = {}, status } = this.props;
    const hasStatusEditRights = userStore.kybCanWrite;
    if (hasStatusEditRights) {
      return <IconInput onChangeStatus={onChangeStatus} field={name} status={status} />;
    } else {
      return <StatusIcon status={status} />;
    }
  }

  render() {
    const {
      editActive,
      field,
      name,
      onChange,
      status,
      userStore = {},
      kybCustomerCanWrite,
    } = this.props;
    const displayName = transformEntityString(name);
    const disabled = (name === 'ssn' || name === 'ein') && !userStore.canRevealIdentification;
    const showInput = editActive && (userStore.kybCanWrite || kybCustomerCanWrite);
    return (
      <div data-testid="KYB-row">
        <Text type="h6">{displayName}</Text>
        <VSpacer />
        {showInput ? (
          <div className={s.editActive} data-testid="KYB-row-edit-active">
            <Input
              testId="KYB-row-input"
              value={field}
              disabled={disabled}
              onChange={onChange}
              width="100%"
            />
            {this.showStatusIcon()}
          </div>
        ) : (
          <div className={s.notEditActive} data-testid="KYB-row-edit-not-active">
            <Text testId={`KYB-row-regular-${name}`}>{field}</Text>
            <HSpacer />
            <StatusIcon status={status} />
          </div>
        )}
      </div>
    );
  }
}

Row.propTypes = {
  editActive: PropTypes.bool,
  field: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  status: PropTypes.string,
  kybCustomerCanWrite: PropTypes.bool,
};

Row.defaultProps = {
  editActive: false,
  field: '',
  name: '',
  onChange: () => {},
  status: '',
  kybCustomerCanWrite: false,
};

export default Row;
