import { FlipFlop } from './../../../utils/index.js';
import logger from 'utils/logger';
import currentUserStore from 'stores/CurrentUserStore';
import routerStore from 'stores/router/RouterStore';
import { secureStorage } from '@mq/volt-amc-container';
import { ENVIRONMENT_LOCAL_STORAGE_KEY, VALID_ENVIRONMENTS } from '@mqd/mqd-constants';

export const pageIsProtected = () => {
  const currentRouteName = routerStore ? routerStore.currentRouteName : null;
  const unprotectedPages = ['Demo', 'Contact Customer Success', 'Auth Callback', 'Auth Error'];
  return !unprotectedPages.includes(currentRouteName);
};

export const ensureValidFeatureFlagsIsSet = async () => {
  const { redsea_email, redseaRoles, userOrgName } = currentUserStore
    ? currentUserStore?.userStore || {}
    : {};
  const hasValidFeatureFlagsInSecureStorage = secureStorage.getItem('validFeatureFlags');

  if (!hasValidFeatureFlagsInSecureStorage && redsea_email && redseaRoles) {
    const sessionInfo = {
      application: 'amc',
      email: redsea_email || null,
      environment: null,
      // send org as program short code to turn on unify beta based on org
      programShortCode: userOrgName || null,
      roles: redseaRoles || null,
    };
    try {
      await FlipFlop.populate(sessionInfo);
    } catch (e) {
      logger.error(`FlipFlop error. ${e}`);
    }
  }
};

/**
 * Adds active program to URL params if currentParams.program is undefined
 */
export const addActiveProgramToUrl = () => {
  const { updateParams, currentProgramShortCodeInUrl } = routerStore || {};
  const { userStore = {} } = currentUserStore || {};
  const { activeProgram = {}, programsList } = userStore;

  const urlProgramEqualsActiveProgram =
    currentProgramShortCodeInUrl &&
    activeProgram &&
    currentProgramShortCodeInUrl === activeProgram.short_name;

  if (!currentProgramShortCodeInUrl && activeProgram) {
    updateParams && updateParams({ program: activeProgram.short_name }, true);
  } else if (!urlProgramEqualsActiveProgram) {
    const programObjectInUrl = programsList.find(
      ({ short_name }) => short_name === currentProgramShortCodeInUrl
    );
    const userHasAccessToProgram = Boolean(programObjectInUrl);
    if (userHasAccessToProgram) {
      userStore.setActiveProgram(programObjectInUrl);
    }
  }
};

/**
 * Adds active environment to URL params if currentParams.env is undefined
 */
export const addActiveEnvironmentToUrl = () => {
  const activeEnvironment = localStorage.getItem(ENVIRONMENT_LOCAL_STORAGE_KEY);
  const { updateParams, currentEnvironmentInUrl } = routerStore || {};
  const { privateSandboxActive } = currentUserStore.userStore;

  const urlEnvironmentEqualsActiveEnvironment =
    currentEnvironmentInUrl && activeEnvironment && currentEnvironmentInUrl === activeEnvironment;

  if (!privateSandboxActive) return;

  if (!currentEnvironmentInUrl && activeEnvironment) {
    if (VALID_ENVIRONMENTS.includes(activeEnvironment)) {
      updateParams && updateParams({ env: activeEnvironment }, true);
    }
  } else if (!urlEnvironmentEqualsActiveEnvironment) {
    if (VALID_ENVIRONMENTS.includes(currentEnvironmentInUrl)) {
      localStorage.setItem(ENVIRONMENT_LOCAL_STORAGE_KEY, currentEnvironmentInUrl);
    } else {
      updateParams && updateParams({ env: activeEnvironment }, true);
    }
  }
};
