import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from '@mqd/volt-base';
import { containerWidthHOC } from '@mq/voltron-parent';
import CommandoModeRealTimeStandInInfoCard from './CommandoModeRealTimeStandInInfoCard';
import CommandoModeProtectedFundingSourceCard from './CommandoModeProtectedFundingSourceCard';

class CommandoModeInfoTab extends Component {
  render() {
    const { commandoModeStore } = this.props;
    const colSpan = 6;
    return (
      <div>
        <Container>
          <Row>
            <Col span={colSpan}>
              <CommandoModeProtectedFundingSourceCard commandoModeStore={commandoModeStore} />
            </Col>
            <Col span={colSpan}>
              <CommandoModeRealTimeStandInInfoCard commandoModeStore={commandoModeStore} />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

CommandoModeInfoTab.propTypes = {
  businessStore: PropTypes.any,
};

CommandoModeInfoTab.defaultProps = {
  businessStore: null,
};

export default containerWidthHOC(CommandoModeInfoTab);
