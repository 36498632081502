import { useCallback } from 'react';
import logger from 'utils/logger';
import { secureStorage, useJanusApi } from '@mq/volt-amc-container';
import { PROGRAMS_META_DATA_KEY } from './constants.js';

export default function useLoadProgramsMetadata() {
  const fetchJanusApi = useJanusApi();
  return useCallback(() => {
    const loadProgramsMetadata = async function () {
      try {
        const res = await fetchJanusApi({
          url: '/diva/security/orgs/programs',
        });
        const programs = res.data;
        if (programs) {
          secureStorage.setItem(PROGRAMS_META_DATA_KEY, JSON.stringify(programs));
          return programs;
        }
        return [];
      } catch (error) {
        logger.error(error);
        return [];
      }
    };
    return loadProgramsMetadata();
  }, [fetchJanusApi]);
}
