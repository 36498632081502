// looks at the key value pair of two objects, returns a new object in with
// all changed key value pairs (newObject values take precedence over originalObject values)
const findParamChanges = (newObject, originalObject) => {
  const allParams = Object.keys({ ...originalObject, ...newObject });
  return allParams.reduce((acc, key) => {
    if (newObject[key] !== originalObject[key]) {
      acc[key] = newObject[key];
    }
    return acc;
  }, {});
};

export default findParamChanges;
