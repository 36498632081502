import React, { Component } from 'react';
import logger from 'utils/logger';
import { observer } from 'mobx-react';
import { BannerAlert } from '@mqd/volt-base';
import PropTypes from 'prop-types';

class NotificationWrap extends Component {
  constructor(props) {
    super(props);
    this.handleDismiss = this.handleDismiss.bind(this);
  }

  convertType(type) {
    const mapping = {
      error: 'danger',
    };
    return mapping[type] ? mapping[type] : type;
  }

  handleDismiss() {
    const { dismissable, store } = this.props;
    if (!dismissable) return null;
    try {
      store.clearNotification();
    } catch (error) {
      logger.error(error);
    }
  }

  render() {
    const { store, style } = this.props;
    const { notificationType, notificationText, notificationActive } = store;
    if (!notificationActive) return null;
    return (
      <div onClick={this.handleDismiss} style={style}>
        <BannerAlert type={this.convertType(notificationType)} size="sm">
          {notificationText}
        </BannerAlert>
      </div>
    );
  }
}

NotificationWrap.propTypes = {
  store: PropTypes.any,
  dismissable: PropTypes.bool,
  style: PropTypes.object,
};

NotificationWrap.defaultProps = {
  store: {},
  dismissable: true,
  style: {},
};

export default observer(NotificationWrap);
