import React from 'react';
import { Text, Icon } from '@mqd/volt-base';

import NotificationListItem from './NotificationListItem';

import s from './NotificationList.module.css';

const SectionHeader = ({ label, removeNotificationSection }) => {
  return (
    <div className={s.notification__section__header}>
      <Text type="h5">{label}</Text>
      <div data-testid="remove-notification_section">
        <Icon type="clear" onClick={() => removeNotificationSection(label)} />
      </div>
    </div>
  );
};

const SectionContent = ({ notifications, removeNotification, markNotificationAsRead }) => {
  return (
    <ul
      className={s.notification__section__content}
      data-testid="notification-section-content-list"
    >
      {notifications.map((notification) => {
        return (
          <NotificationListItem
            {...notification}
            removeNotification={removeNotification}
            markNotificationAsRead={markNotificationAsRead}
            key={notification.token}
          />
        );
      })}
    </ul>
  );
};

const NotificationSection = ({
  id,
  notifications,
  label,
  removeNotification,
  removeNotificationSection,
  markNotificationAsRead,
}) => {
  if (!notifications || notifications.length === 0 || !label) return null;

  return (
    <section id={id}>
      <SectionHeader label={label} removeNotificationSection={removeNotificationSection} />
      <div className={s.notification__section__hr}></div>
      <SectionContent
        notifications={notifications}
        removeNotification={removeNotification}
        markNotificationAsRead={markNotificationAsRead}
      />
    </section>
  );
};

export default NotificationSection;
