export const EXCEPTION_TOTAL_SUMMARY_ID = 'settlements-exception-total-summary';
export const ADDITIONAL_INFORMATION_ID = 'settlements-exceptions';

export const UPDATE_SETTLEMENT_AMOUNTS_AND_FEES = 'UPDATE_SETTLEMENT_AMOUNTS_AND_FEES';
export const SET_INITIAL_STATE = 'SET_INITIAL_STATE';
export const ERROR_SETTLEMENT_AMOUNTS_AND_FEES = 'ERROR_SETTLEMENT_AMOUNTS_AND_FEES';
export const SUBMIT_ATTEMPTED = 'SUBMIT_ATTEMPTED';
export const CLEAR_ERROR_SETTLEMENT_AMOUNTS_AND_FEES = 'CLEAR_ERROR_SETTLEMENT_AMOUNTS_AND_FEES';

export const UPDATE_REFERENCE_ID = 'UPDATE_REFERENCE_ID';
export const REFERENCE_ID_ERROR = 'REFERENCE_ID_ERROR';

export const REFERENCE_ID_MAX_LENGTH = 100;
export const MANUAL_RECONCILIATION_NO_REFERENCE_ID_ERROR = 'This is required to submit manually.';
export const REFERENCE_ID_LENGTH_ERROR = `This cannot be more than ${REFERENCE_ID_MAX_LENGTH} characters.`;
