import {
  SUBNETWORKS,
  NETWORKS,
  EXCEPTION_TYPES,
  TOOLTIP_DESCRIPTIONS,
  PROGRAM_TYPES,
} from '../../utilities/dictionary';

const HASH_GROUP_FIELDS = {
  hashId: ({ hashId }) => ({
    label: 'Hash ID',
    content: hashId,
    addCopyButton: true,
    tooltip: TOOLTIP_DESCRIPTIONS.HASH_ID,
  }),

  tx_hash_internal_id: ({ tx_hash_internal_id }) => ({
    label: 'FDS tx hash internal ID',
    content: tx_hash_internal_id,
    addCopyButton: true,
  }),

  net_ref_id: ({ net_ref_id, network }) => ({
    label: 'Network reference ID',
    content: net_ref_id,
    addCopyButton: true,
    tooltip: network === NETWORKS.Pulse ? 'NRID is a combination of RRN+STAN.' : '',
  }),

  acquirer_reference_id: ({ acquirer_reference_id }) => ({
    label: 'Acquirer reference ID',
    content: acquirer_reference_id,
  }),

  subnetwork: ({ subnetwork }) => ({
    label: 'Subnetwork',
    content: SUBNETWORKS[subnetwork],
  }),

  bank: ({ bank }) => ({
    label: 'Bank',
    content: bank?.name ? bank.name : 'N/A',
  }),

  ica: ({ ica }) => ({
    label: 'ICA',
    content: ica || 'N/A',
  }),

  settlement_service_number: ({ settlement_service_number }) => ({
    label: 'Settlement service number',
    content: settlement_service_number || 'N/A',
  }),

  settlementDate: ({ settlementDate }) => ({
    label: 'Settlement date',
    content: settlementDate,
    tooltip: TOOLTIP_DESCRIPTIONS.SETTLED_DATE,
  }),

  state: ({ state }) => ({
    label: 'Status',
    content: state,
    addStatusIndicator: true,
  }),

  exceptionType: ({ exceptionType }) => ({
    label: 'Exception type',
    content: EXCEPTION_TYPES[exceptionType],
    tooltip: TOOLTIP_DESCRIPTIONS.EXCEPTION_TYPE,
  }),

  submissionDate: ({ submissionDate, showSubmissionFields }) =>
    showSubmissionFields
      ? {
          label: 'Submission date',
          content: submissionDate,
          tooltip: TOOLTIP_DESCRIPTIONS.SUBMISSION_DATE,
        }
      : null,

  submissionType: ({ submissionType, showSubmissionFields }) =>
    showSubmissionFields
      ? {
          label: 'Submission type',
          content: submissionType,
          tooltip: TOOLTIP_DESCRIPTIONS.SUBMISSION_TYPE,
        }
      : null,

  reference_id: ({ reference_id, showSubmissionFields }) =>
    showSubmissionFields
      ? {
          label: 'Reference ID',
          content: reference_id || 'N/A',
        }
      : null,

  program_header: () => ({
    header: 'Program',
    hasContent: false,
  }),

  program_type: ({ program_type }) => ({
    label: 'Program type',
    content: PROGRAM_TYPES[program_type],
  }),

  program: ({ program }) => ({
    label: 'Program',
    content: program || 'N/A',
  }),

  sre: ({ sre }) => ({
    label: 'Settlement reporting entity (SRE)',
    content: sre,
  }),

  ft_sre: ({ ft_sre }) => ({
    label: 'Funding settlement reporting entity (FTSRE)',
    content: ft_sre,
  }),

  institution_id: ({ institution_id }) => ({
    label: 'Institution ID',
    content: institution_id,
  }),
};

export default HASH_GROUP_FIELDS;
