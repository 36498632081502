import React from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  LabeledContent,
  CopyToClipboard,
  HSpacer,
  Link,
  Colors,
  Icon,
  VSpacer,
} from '@mqd/volt-base';
import { CardLink } from '../../index';
import ActionButtons from './ActionButtons';
import { useTransactionListStateContext } from '../hooks';
import { ICON_TYPE_MAPPING_BY_NETWORK } from '../constants';
import { sentenceCase } from '../../../shared-utils';

const ExpandedContentItem = (props) => (
  <Flex flex="1 1 0%">
    <LabeledContent flexDirection="column" {...props}>
      {props.children || 'None'}
    </LabeledContent>
    {props.withSpacer && <HSpacer />}
  </Flex>
);

const NetworkIcon = ({ network }) => {
  if (!ICON_TYPE_MAPPING_BY_NETWORK[network]) {
    return network;
  }

  return (
    <Flex display="flex" testId={`network-icon-${network}`}>
      <Icon type={ICON_TYPE_MAPPING_BY_NETWORK[network]} noHoverEffects />
      <HSpacer factor={0.5} />
      {sentenceCase(network)}
    </Flex>
  );
};

const DirectDepositContent = ({ transaction, onAccountholderClick, accountHolderName }) => {
  const { direct_deposit, cardholder_token, token } = transaction;
  const { individual_name, company_name } = direct_deposit;

  return (
    <Flex testId="direct-deposit-expanded-content" display="flex">
      <ExpandedContentItem label="Receiver" withSpacer>
        {individual_name}
      </ExpandedContentItem>

      <ExpandedContentItem label="Account holder" withSpacer>
        {accountHolderName && (
          <Link inheritParentFont onClick={() => onAccountholderClick(cardholder_token)}>
            <Icon overrideColor={Colors.actionColor} type="user" factor={1} noHoverEffects />
            <HSpacer factor={0.5} />
            {accountHolderName}
          </Link>
        )}
      </ExpandedContentItem>

      <ExpandedContentItem label="Originator" withSpacer>
        {company_name}
      </ExpandedContentItem>

      <ExpandedContentItem label="Token">
        <CopyToClipboard>{token}</CopyToClipboard>
      </ExpandedContentItem>
    </Flex>
  );
};

const DwtContent = ({ transaction, onDigitalWalletClick }) => {
  const { network, digital_wallet_token = {}, token } = transaction;
  const { pan_source } = digital_wallet_token.vallet_provider_profile || {};
  const { token_requestor_name } = digital_wallet_token.token_service_provider || {};

  return (
    <Flex testId="digital-wallet-expanded-content" display="flex">
      <ExpandedContentItem
        label="PAN entry mode"
        tooltip="This field indicates the method used for capturing the card PAN during the transaction."
        withSpacer
      >
        {pan_source}
      </ExpandedContentItem>

      <ExpandedContentItem label="Digital wallet" withSpacer>
        {token_requestor_name && (
          <Link inheritParentFont onClick={() => onDigitalWalletClick(digital_wallet_token)}>
            {token_requestor_name}
          </Link>
        )}
      </ExpandedContentItem>

      <ExpandedContentItem label="Network" withSpacer>
        <NetworkIcon network={network} />
      </ExpandedContentItem>

      <ExpandedContentItem label="Token">
        <CopyToClipboard>{token}</CopyToClipboard>
      </ExpandedContentItem>
    </Flex>
  );
};

const CardContent = ({ transaction, transactionModelVersion, onCardClick }) => {
  const { pos, card, card_acceptor = {}, network, token, card_token } = transaction;
  const { processing_type } = card_acceptor.poi || {};
  const { pan_entry_mode } = pos || {};
  const { last_four } = card || {};

  return (
    <Flex testId="card-expanded-content" display="flex">
      <ExpandedContentItem
        label="PAN entry mode"
        tooltip="This field indicates the method used for capturing the card PAN during the transaction."
        withSpacer
      >
        {transactionModelVersion === '1.0' ? processing_type : pan_entry_mode}
      </ExpandedContentItem>

      <ExpandedContentItem label="Last 4 of PAN" withSpacer>
        {last_four ? <CardLink text={last_four} onClick={() => onCardClick(card_token)} /> : null}
      </ExpandedContentItem>

      <ExpandedContentItem label="Network" withSpacer>
        <NetworkIcon network={network} />
      </ExpandedContentItem>

      <ExpandedContentItem label="Token">
        <CopyToClipboard>{token}</CopyToClipboard>
      </ExpandedContentItem>
    </Flex>
  );
};

const expandedContentComponentMap = {
  direct_deposit: DirectDepositContent,
  digital_wallet_token: DwtContent,
  card: CardContent,
};

const ExpandedContent = ({ accountHolderName, transaction }) => {
  const {
    transactionModelVersion,
    onViewMoreClick,
    onReturnClick,
    onDisputeClick,
    onAccountholderClick,
    onCardClick,
    onDigitalWalletClick,
  } = useTransactionListStateContext();

  const expandedContentType = transaction.direct_deposit
    ? 'direct_deposit'
    : transaction.digital_wallet_token
    ? 'digital_wallet_token'
    : 'card';

  const ExpandedContentComponent = expandedContentComponentMap[expandedContentType];
  return (
    <>
      <ExpandedContentComponent
        transaction={transaction}
        accountHolderName={accountHolderName}
        transactionModelVersion={transactionModelVersion}
        onAccountholderClick={onAccountholderClick}
        onDigitalWalletClick={onDigitalWalletClick}
        onCardClick={onCardClick}
      />
      <VSpacer factor={2} />
      <ActionButtons
        transaction={transaction}
        onViewMoreClick={onViewMoreClick}
        onReturnClick={onReturnClick}
        onDisputeClick={onDisputeClick}
      />
    </>
  );
};

ExpandedContent.propTypes = {
  transaction: PropTypes.object,
  accountHolderName: PropTypes.string,
  onAccountholderClick: PropTypes.func,
  onCardClick: PropTypes.func,
  onDigitalWalletClick: PropTypes.func,
};

export default ExpandedContent;
