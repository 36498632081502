import React from 'react';
import PropTypes from 'prop-types';
import { is } from 'ramda';
import { Text } from '@mqd/volt-base';
import { Helpers } from '@utils';

function Timestamps({ timestamps, testId }) {
  if (!is(Array, timestamps) || timestamps.length < 1) return null;

  const formatTimestamp = (timestamp) => {
    const { description, dateTime } = timestamp;
    if (!dateTime) return '';
    const formattedDateTime = Helpers.parseDateTimeString(dateTime);

    return description ? description + ' ' + formattedDateTime : formattedDateTime;
  };

  const string = timestamps.reduce((acc, timestamp) => {
    let updatedString = acc;
    const newTimestamp = formatTimestamp(timestamp);
    if (updatedString && newTimestamp) updatedString += '  \u2022  ';
    updatedString += newTimestamp;
    return updatedString;
  }, '');

  return (
    <Text testId={testId} type="footnote">
      {string}
    </Text>
  );
}

Timestamps.propTypes = {
  timestamps: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      dateTime: PropTypes.string.isRequired,
    })
  ),
};

Timestamps.defaultProps = {
  testId: 'timestamps',
  timestamps: [],
};

export default Timestamps;
