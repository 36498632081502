export const kycStatuses = {
  //TODO: add to colors.json, use that
  SUCCESS: {
    color: 'statusSuccessColor',
    icon: 'confirmed',
    label: 'Success',
  },
  FAILURE: {
    color: 'statusErrorColor',
    icon: 'warning',
    label: 'Failure',
  },
  PENDING: {
    color: 'statusPendingColor',
    icon: 'clock',
    label: 'Pending',
  },
  '': {
    color: 'statusPendingColor',
    icon: 'clock',
    label: 'Pending',
  },
  UNVERIFIED: {
    color: 'statusDraftColor',
    icon: 'help',
    label: 'Unverified',
  },
  null: {
    color: 'statusDraftColor',
    icon: 'help',
    label: 'Unverified',
  },
  VERIFIED: {
    color: 'statusSuccessColor',
    icon: 'confirmed',
    label: 'Success',
  },
  APPROVED: {
    color: 'statusSuccessColor',
    icon: 'confirmed',
    label: 'Success',
  },
  DECLINED: {
    color: 'statusErrorColor',
    icon: 'warning',
    label: 'Failure',
  },
  OPEN: {
    color: 'statusDraftColor',
  },
  CLOSED: {
    color: 'statusSuccessColor',
  },
  IN_PROGRESS: {
    color: 'statusPendingColor',
  },
};
