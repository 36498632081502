// @flow
import logger from 'utils/logger';
import { observable, action, decorate } from 'mobx';
import notificationStore from 'stores/NotificationStore';
import downloadBlob from 'utils/downloadBlob';

class BackgroundJobStore {
  // values
  activeDownloads: Array<*> = [];
  activePolls: Object = {};

  // actions

  addDownload(downloadCallBack: func = () => {}, downloadToken: string, name: string = 'Download') {
    this.activeDownloads.push({
      downloadToken: downloadToken,
      name: name,
    });

    const pollFunction = async () => {
      try {
        const response = await downloadCallBack(downloadToken);
        if ((response && response.name === 'Error') || window[downloadToken]) {
          this.removeDownload(downloadToken);
        } else if (response && response.data && response.status === 200) {
          this.handleSuccesfulDownload(response, downloadToken, name);
        } else {
          setTimeout(pollFunction.bind(this), 1000);
        }
      } catch (error) {
        logger.error(error);
        notificationStore.notify('error', `Download Error - ${error.message}`);
        this.removeDownload(downloadToken);
      }
    };
    pollFunction();
  }

  removeDownload(downloadToken: string) {
    let newActiveDownloads = this.activeDownloads.filter((download) => {
      return download.downloadToken !== downloadToken;
    });
    this.activeDownloads = newActiveDownloads;
  }

  handleSuccesfulDownload(response: any, downloadToken: string, name: string) {
    window[downloadToken] = true;
    this.removeDownload(downloadToken);
    const data = response.data;
    downloadBlob(data, name);
  }
}

decorate(BackgroundJobStore, {
  // values
  activeDownloads: observable,
  activePolls: observable,

  // actions
  removeDownload: action.bound,
  addDownload: action.bound,
  handleSuccesfulDownload: action.bound,

  // computed
});

const backgroundJobStore = new BackgroundJobStore();
export default backgroundJobStore;
