import React, { useReducer } from 'react';
import { EnvironmentSelectorDispatchContext, EnvironmentSelectorStateContext } from './context';
import environmentSelectorReducer, { defaultState } from './reducer';

export default function EnvironmentSelectorSetup({ children }) {
  const [state, dispatch] = useReducer(environmentSelectorReducer, defaultState);

  return (
    <EnvironmentSelectorDispatchContext.Provider value={dispatch}>
      <EnvironmentSelectorStateContext.Provider value={state}>
        {children}
      </EnvironmentSelectorStateContext.Provider>
    </EnvironmentSelectorDispatchContext.Provider>
  );
}
