import React from 'react';
import number from '../../utilities/number/index.js';
import { SUMMARY_STATUSES } from '../../utilities/dictionary/index.js';
import { SORTED_TYPES } from '../../utilities/dictionary/exception-types.js';
import { format as formatDate } from '../../utilities/date.js';

import pageConfig from './config.js';
import { DATE_FORMAT_OPTIONS, DEFAULT_FILTER_LABEL } from './constants.js';

function getFilterLabel(dateFilters = {}) {
  if (!dateFilters.from && !dateFilters.to) {
    return DEFAULT_FILTER_LABEL;
  }

  let result = '';
  if (dateFilters.from) {
    result += new Intl.DateTimeFormat('en-US', DATE_FORMAT_OPTIONS).format(dateFilters.from);
  }
  if (dateFilters.to && dateFilters.to.toDateString() !== dateFilters.from.toDateString()) {
    result += ' - ' + new Intl.DateTimeFormat('en-US', DATE_FORMAT_OPTIONS).format(dateFilters.to);
  }
  return result;
}

// TODO: https://marqeta.atlassian.net/browse/PS-30549
// eslint-disable-next-line react-hooks/exhaustive-deps
const useOnMount = (fn) => React.useEffect(fn, []);

function parseSummaries(data, type) {
  const { amountProps, getCountsAndAmounts, integerProps, linesProp } = pageConfig[type];

  const summaries = [];
  data.forEach((summary) => {
    // If status is not valid, skip this summary.
    if (summary.status && !SUMMARY_STATUSES[summary.status]) return;
    // Sort summary lines.
    const lines = [];
    SORTED_TYPES.forEach((type) => {
      const line = summary[linesProp].find((item) => item.type === type);
      if (line) lines.push(line);
    });
    // Format number values.
    if (amountProps?.length || integerProps?.length) {
      summary[linesProp] = lines.map((line) => {
        const countsAndAmounts =
          typeof getCountsAndAmounts === 'function' ? getCountsAndAmounts(line) : line;
        if (amountProps?.length) {
          amountProps.forEach((prop) => {
            line[prop] = number(countsAndAmounts[prop], true);
          });
        }
        if (integerProps?.length) {
          integerProps.forEach((prop) => {
            line[prop] = number(countsAndAmounts[prop], { precision: 0 }, true);
          });
        }
        return line;
      });
    }
    // Only push certain statuses.
    summaries.push(summary);
  });
  // Sort summaries by subnetwork.
  summaries.sort((a, b) => {
    if (a.subnetwork < b.subnetwork) return -1;
    if (a.subnetwork > b.subnetwork) return 1;
    return 0;
  });

  return summaries;
}

function fetchData({ network, dateFilters = {}, type, setLoading, setSummaries, setError }) {
  setLoading(true);

  const from = dateFilters.from ? formatDate(dateFilters.from) : null;
  const to = dateFilters.to ? formatDate(dateFilters.to) : from ? from : null;
  const params = {
    start_settlement_date: from,
    end_settlement_date: to,
  };
  const config = pageConfig[type];

  return config
    .fetch(network, { params })
    .then((response) => {
      if (!response.ok && response.error) {
        throw Error(response);
      }
      const summaries = [];
      Object.keys(config.summaryProps).forEach((prop) => {
        if (!response?.data[prop]) return;
        const summaryGroup = response.data[prop];
        summaries.push({
          label: config.summaryProps[prop],
          data: parseSummaries(summaryGroup, type),
        });
      });
      setSummaries(summaries);
      setError(false);
      setLoading(false);
    })
    .catch((error) => {
      setError(true);
      setLoading(false);
    });
}

export { fetchData, getFilterLabel, useOnMount, parseSummaries };
