import currentUserStore from 'stores/CurrentUserStore';

// This is hardcoded for now.
// In the future we will determine based on DNA configuration with PS-27776
const ddaPrograms = ['anda', 'daydda', 'ddaqa', 'evodem', 'meta', 'rsdm'];

export const isProgramDdaProgram = (shortCode) => {
  return Boolean(shortCode) && ddaPrograms.includes(shortCode);
};

export const isDdaProgram = () => {
  const shortCode = currentUserStore?.userStore?.activeProgramShortCode;
  return isProgramDdaProgram(shortCode);
};
