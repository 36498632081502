import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { Alert, Colors, Flex, HSpacer, Icon, Link, VSpacer } from '@mqd/volt-base';
import { ProgramReserveContext } from '../../context/ProgramReserveContext.js';
import { goToProgramReserve } from '../../utils/navigation.js';
import TransactionRow from './components/TransactionRow/index.js';
import FormButtons from '../FormButtons/FormButtons.js';

import s from './TransactionsForm.module.css';

const TransactionsForm = () => {
  const {
    transactions,
    hasTransactionsWithErrors,
    addOneMoreTransaction,
    validateAndDisableEditMode,
    areChangesMade,
  } = useContext(ProgramReserveContext);

  return (
    <>
      {hasTransactionsWithErrors && (
        <>
          <div className={s.errorContainer}>
            <Alert type="error">Unable to submit. See alerts below.</Alert>
          </div>
          <VSpacer factor={2} />
        </>
      )}

      {transactions.map((transaction, index, array) => (
        <TransactionRow
          key={transaction.id}
          leading={!index}
          removable={array.length > 1}
          transaction={transaction}
        />
      ))}

      <VSpacer factor={0.5} />

      <Link href={null} onClick={addOneMoreTransaction} inline>
        <Flex display="inline-flex">
          <Icon
            type="plus"
            mod="default"
            factor={1}
            noHoverEffects
            overrideColor={Colors.actionColor}
          />
          <HSpacer factor={0.5} />
          Add new entry
        </Flex>
      </Link>

      <FormButtons
        canContinue={areChangesMade}
        onCancelClick={goToProgramReserve}
        onContinueClick={validateAndDisableEditMode}
      />
    </>
  );
};

export default observer(TransactionsForm);
