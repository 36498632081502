import React, { useState } from 'react';
import PropTypes from 'prop-types';
import s from './actions.module.css';
import { Text, Button, VSpacer } from '@mqd/volt-base';
import ChangeStatusModal from '../../../change-status-modal/ChangeStatusModal';

function ReplaceCard({ cardStore, disabled, recipientAddress, onClick }) {
  const [showConfirmationmodal, setShowConfirmationmodal] = useState(false);
  const { isPhysical, last_four } = cardStore;

  const physicalContent = (
    <>
      <Text>
        Replace a damaged or expiring card and immediately reissue a new card with the same card
        number, sent to the recipient address on file. The current card remains active until the new
        card is activated, at which point the current card is terminated.
      </Text>
      <VSpacer factor={3} />
      <Text type="h6">Recipient address</Text>
      <VSpacer factor={1} />
      <Text>{recipientAddress}</Text>
    </>
  );

  const virtualContent = (
    <Text>
      Replace an expiring card and immediately reissue a new card with the same card number, sent to
      the recipient address on file. The current card remains active until the new card is
      activated, at which point the current card is terminated.
    </Text>
  );

  const modalContent = isPhysical ? physicalContent : virtualContent;

  return (
    <div className={s.container} data-testid="replace-card">
      {showConfirmationmodal && (
        <ChangeStatusModal
          heading={`Replace card**${last_four}`}
          showStatusSelection={false}
          hideModal={() => setShowConfirmationmodal(false)}
          store={cardStore}
          overrideNativeOnSubmit={onClick}
          footerButtons={[<Button testId="replace-card-modal-button">Replace and reissue</Button>]}
        >
          {modalContent}
        </ChangeStatusModal>
      )}
      <Text type="h6">Replace card</Text>
      <VSpacer factor={1} />
      <Text type="body-sm">Replace a damaged or expiring card with the same card number.</Text>
      <VSpacer factor={3} />
      <Button
        testId="replace-card-button"
        disabled={disabled}
        type="secondary"
        onClick={() => setShowConfirmationmodal(true)}
      >
        Replace card
      </Button>
    </div>
  );
}

ReplaceCard.propTypes = {
  cardStore: PropTypes.any,
};

ReplaceCard.defaultProps = {
  cardStore: {},
};

export default ReplaceCard;
