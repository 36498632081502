// @flow
import { decorate, observable } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';

class WalletProviderRiskAssessmentStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
  }
  score: string = '';
  version: string = '';
}

decorate(WalletProviderRiskAssessmentStore, {
  score: observable,
  version: observable,
});

export default WalletProviderRiskAssessmentStore;
