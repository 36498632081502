import React from 'react';
import s from './actions.module.css';
import { Text, Button, VSpacer } from '@mqd/volt-base';

function ActivateCard({ onClick }) {
  return (
    <div className={s.container} style={{ width: '375px' }} data-testid="activate-card">
      <Text type="h4">Activate card</Text>
      <VSpacer factor={1} />
      <Text type="body-sm">This card will be available for use after activation.</Text>
      <VSpacer factor={3} />
      <Button type="secondary" testId="active-card-button" onClick={onClick}>
        Activate card
      </Button>
    </div>
  );
}

export default ActivateCard;
