import React, { useState, useEffect } from 'react';
import { pathOr } from 'ramda';
import { LoadingOverlay, VSpacer } from '@mqd/volt-base';
import { Layout } from '../../layouts';
import withRouterParams from '../../components/router-params/withRouterParams.js';
import NotFoundView from '../error-views/NotFoundView.js';
import { useUpdateWebhook, useWebhook } from './hooks';
import { WebhookDetailsHeader, WebhookDetailsMain } from './webhooks';
import { WebhookProvider } from './contexts';
import { arraysEqual, translateGraphQLWebhookTypeToFormValues } from './functions/webhooks.js';
import { renderIf } from 'utils';
import { RequestFailedModal, WebhookUpdatedToast } from './webhooks/components';
import { WEBHOOKS_TOAST_MESSAGE } from './constants';

import { observer } from 'mobx-react';
import routerStore from 'stores/router/RouterStore';

const WebhooksShow = ({ routerParams }) => {
  /**
   * Had to deal with routerStore in this component because the `routerParams`
   * updates instantly and was displaying the NotFoundView when navigating away
   * from this page. Once that's improved, this page can go back to not having
   * to worry about routerStore's currentPath and if we're "on the right path"
   * for this view.
   */
  const { currentPath } = routerStore;
  const onTheRightPath = currentPath.toString() === '/development/webhooks/show';

  const token = pathOr(false, ['currentParamsAsObject', 'token'])(routerParams);

  const [successMessage, setSuccessMessage] = useState(WEBHOOKS_TOAST_MESSAGE.DEFAULT.SUCCESS);

  const onCompleted = ({ publicSandboxUpdateWebhook = {} }, _error) => {
    if (_error) {
      setShowErrorModal(true);
    } else {
      // Checks to see what has changed
      if (publicSandboxUpdateWebhook.active !== oldWebhookData.active) {
        publicSandboxUpdateWebhook.active
          ? setSuccessMessage(WEBHOOKS_TOAST_MESSAGE.ACTIVATED.SUCCESS)
          : setSuccessMessage(WEBHOOKS_TOAST_MESSAGE.DISABLED.SUCCESS);
      } else if (!arraysEqual(oldWebhookData.events, publicSandboxUpdateWebhook.events)) {
        setSuccessMessage(WEBHOOKS_TOAST_MESSAGE.EVENTS_SAVED.SUCCESS);
      } else {
        setSuccessMessage(WEBHOOKS_TOAST_MESSAGE.DEFAULT.SUCCESS);
      }
      setIsActive(publicSandboxUpdateWebhook.active);
      setUpdateSuccessful(true);
      setShowUpdateToast(true);
      setWebhook(translateGraphQLWebhookTypeToFormValues(publicSandboxUpdateWebhook));
      setOldWebhookData(translateGraphQLWebhookTypeToFormValues(publicSandboxUpdateWebhook));
    }
  };

  const dismissToast = () => {
    setShowUpdateToast(false);
  };

  const { webhook: initialWebhook, loading } = useWebhook({ token });
  const { updateWebhook, error, loading: updating } = useUpdateWebhook(onCompleted);
  const [isActive, setIsActive] = useState(false);
  const [updateSuccessful, setUpdateSuccessful] = useState(true);
  const [showUpdateToast, setShowUpdateToast] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  // Holds a reference to the data that's being changed by the user
  const [webhook, setWebhook] = useState(initialWebhook);
  // Holds a reference to the data that's in the database
  const [oldWebhookData, setOldWebhookData] = useState(initialWebhook);

  useEffect(() => {
    if (initialWebhook) {
      setWebhook(translateGraphQLWebhookTypeToFormValues(initialWebhook));
      setOldWebhookData(translateGraphQLWebhookTypeToFormValues(initialWebhook));
      setIsActive(initialWebhook.active);
    }
  }, [initialWebhook]);

  // When form change, update initial value of webhook context
  const onUpdateForm = (data) => {
    setWebhook(data);
  };

  return (
    <LoadingOverlay active={loading || updating}>
      <Layout>
        {!onTheRightPath ? (
          <VSpacer factor={42} />
        ) : token ? (
          <div data-testId="webhooks-detail">
            {renderIf(!webhook, <VSpacer factor={42} />)}
            {renderIf(
              webhook,
              <WebhookProvider
                defaultWebhookValues={webhook}
                error={error}
                onSave={updateWebhook}
                saving={updating}
                onUpdateForm={onUpdateForm}
              >
                <WebhookDetailsHeader active={isActive} />
                <WebhookDetailsMain />
              </WebhookProvider>
            )}
            {renderIf(
              showUpdateToast,
              <WebhookUpdatedToast
                dismissToast={dismissToast}
                isSuccessful={updateSuccessful}
                successMessage={successMessage}
                // Technically, we'll never reach this because we have a failed modal as a fallback
                errorMessage={WEBHOOKS_TOAST_MESSAGE.DEFAULT.FAILED}
              />
            )}
            {renderIf(
              showErrorModal,
              <RequestFailedModal hideModalCallback={() => setShowErrorModal(false)} />
            )}
          </div>
        ) : (
          <NotFoundView />
        )}
      </Layout>
    </LoadingOverlay>
  );
};

export default observer(withRouterParams(WebhooksShow));
