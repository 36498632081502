// Can't use "fds/utilities/date.js" due to the need to use 12 hour time
const dateFormatter = new Intl.DateTimeFormat('en-CA');
const timeFormatter = new Intl.DateTimeFormat('en-US', {
  hour: 'numeric',
  minute: 'numeric',
});

export function formatDate(dateToFormat) {
  try {
    const date = new Date(dateToFormat);

    return `${dateFormatter.format(date)} ${timeFormatter.format(date)}`;
  } catch (error) {
    console.log(error);
  }

  return '';
}
