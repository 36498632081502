import {
  createdTime,
  programFullName,
  type,
  amount,
  collateral,
  currency,
  transactionToken,
  memo,
} from '../../available-columns.js';

/**
 * TODO: we may need to disable sorting for such columns as: created_time, currency_code, transaction_token
 * At the moment, the server returns an error:
 * "could not resolve property: currency_code of: org.jpos.gl.ProgramReserveTransaction"
 */
export const columns = [
  createdTime,
  programFullName,
  type,
  amount,
  collateral,
  currency,
  transactionToken,
  memo,
];

export const creditColumns = [
  {
    header: 'Date Processed',
    accessor: 'postTime',
  },
  {
    header: 'Program',
    accessor: 'shortCode',
  },
  {
    header: 'Type',
    accessor: 'type',
    width: 150,
  },
  {
    header: 'Credit Amount',
    accessor: 'amount',
  },
  {
    header: 'Currency',
    accessor: 'currencyCode',
  },
  {
    header: 'Transaction Token',
    accessor: 'token',
  },
  {
    header: 'Memo',
    accessor: 'memo',
  },
];
