export function prepareDataForCSVExport(data, columns) {
  const csvColumns = columns.map(({ header }) => header);
  const accessorToHeaderMap = new Map(columns.map(({ accessor, header }) => [accessor, header]));

  data.forEach((dataItem) => {
    for (const [accessor, header] of accessorToHeaderMap) {
      dataItem[header] = dataItem[accessor];
    }
  });

  return { data, config: { columns: csvColumns } };
}
