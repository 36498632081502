import { v4 as uuidv4 } from 'uuid';
import { computed, decorate, observable } from 'mobx';
import { VALIDATION_ERROR, ValidationError, VIOLATION_LEVEL } from './validation-error.js';

export const TRANSACTION_STATE = {
  UNVALIDATED: 0,
  VALIDATED: 1,
};

const DEFAULT_CURRENCY = 'USD';

export class Transaction {
  id = uuidv4();
  validationState = TRANSACTION_STATE.UNVALIDATED;
  validationErrors = [];
  validationWarnings = [];
  currency = DEFAULT_CURRENCY;

  constructor(
    program = null,
    amount = 0.0,
    collateralAmount = 0.0,
    memo = null,
    creditAmount = 0.0
  ) {
    this.program = program;
    this.amount = amount;
    this.collateralAmount = collateralAmount;
    this.creditAmount = creditAmount;
    this.memo = memo;
  }

  validate(availablePrograms) {
    this.validationState = TRANSACTION_STATE.VALIDATED;
    this.validationErrors = [];

    if (!this.program) {
      const error = new ValidationError(VIOLATION_LEVEL.ERROR, VALIDATION_ERROR.PROGRAM_REQUIRED);
      this.validationErrors.push(error);
    } else if (!availablePrograms.includes(this.program)) {
      const error = new ValidationError(VIOLATION_LEVEL.ERROR, VALIDATION_ERROR.PROGRAM_NOT_FOUND);
      this.validationErrors.push(error);
    }

    if (!this.amount && !this.collateralAmount && !this.creditAmount) {
      const error = new ValidationError(VIOLATION_LEVEL.ERROR, VALIDATION_ERROR.AMOUNT_REQUIRED);
      this.validationErrors.push(error);
    } else if (typeof this.amount !== 'number' || !isFinite(this.amount)) {
      const error = new ValidationError(VIOLATION_LEVEL.ERROR, VALIDATION_ERROR.AMOUNT_INVALID);
      this.validationErrors.push(error);
    }
  }

  validateProgramDuplicates(duplicatedPrograms) {
    if (duplicatedPrograms.includes(this.program)) {
      const hasDuplicateProgramWarning = this.validationWarnings.some(
        (warning) => warning.code === VALIDATION_ERROR.PROGRAM_DUPLICATE
      );

      if (!hasDuplicateProgramWarning) {
        const warning = new ValidationError(
          VIOLATION_LEVEL.WARN,
          VALIDATION_ERROR.PROGRAM_DUPLICATE
        );
        this.validationWarnings.push(warning);
      }
    } else {
      // we can't just assign clear the validationWarnings because
      // validation and ensuring the program is unique may happen independently
      this.validationWarnings = this.validationWarnings.filter(
        (warning) => warning.code !== VALIDATION_ERROR.PROGRAM_DUPLICATE
      );
    }
  }

  autocorrectCurrency(currenciesMap) {
    const hasMissingCurrencyWarning = this.hasMissingCurrencyWarning;

    if (currenciesMap.has(this.program)) {
      if (hasMissingCurrencyWarning) {
        this.validationWarnings = this.validationWarnings.filter(
          (warning) => warning.code !== VALIDATION_ERROR.MISSING_CURRENCY
        );
      }

      this.currency = currenciesMap.get(this.program);
    } else {
      const hasProgramNotFoundError = this.validationErrors.some(
        (warning) => warning.code === VALIDATION_ERROR.PROGRAM_NOT_FOUND
      );

      if (!hasProgramNotFoundError && !hasMissingCurrencyWarning) {
        const warning = new ValidationError(
          VIOLATION_LEVEL.WARN,
          VALIDATION_ERROR.MISSING_CURRENCY
        );
        this.validationWarnings.push(warning);
      }
    }
  }

  get hasErrors() {
    return this.validationErrors.length > 0;
  }

  get hasWarnings() {
    return this.validationWarnings.length > 0;
  }

  get hasProgramErrors() {
    const programErrors = [VALIDATION_ERROR.PROGRAM_REQUIRED, VALIDATION_ERROR.PROGRAM_NOT_FOUND];

    return this.validationErrors.some((error) => programErrors.includes(error.code));
  }

  get hasAmountErrors() {
    const programErrors = [VALIDATION_ERROR.AMOUNT_REQUIRED, VALIDATION_ERROR.AMOUNT_INVALID];

    return this.validationErrors.some((error) => programErrors.includes(error.code));
  }

  get hasMissingCurrencyWarning() {
    return this.validationWarnings.some(
      (warning) => warning.code === VALIDATION_ERROR.MISSING_CURRENCY
    );
  }

  get isValidated() {
    return this.validationState === TRANSACTION_STATE.VALIDATED;
  }

  get validationErrorsAndWarnings() {
    return this.validationErrors.concat(this.validationWarnings);
  }

  get currencySelectionDisabled() {
    return this.program && !this.hasMissingCurrencyWarning;
  }
}

decorate(Transaction, {
  program: observable,
  amount: observable,
  collateralAmount: observable,
  memo: observable,
  currency: observable,
  validationState: observable,
  validationErrors: observable,
  validationWarnings: observable,

  hasErrors: computed,
  hasProgramErrors: computed,
  hasAmountErrors: computed,
  hasMissingCurrencyWarning: computed,
  isValidated: computed,
  validationErrorsAndWarnings: computed,
});
