import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import CardholdersTable from '../cardholders-table/CardholdersTable';

class CardholdersTab extends Component {
  render() {
    const {
      storeInitArgs,
      onRowClick,
      localStorageKey,
      userStore,
      calculateTableHeight,
      canAccessChildrenUsers,
    } = this.props;
    return (
      <CardholdersTable
        canAccessChildrenUsers={canAccessChildrenUsers}
        storeInitArgs={storeInitArgs}
        onRowClick={onRowClick}
        localStorageKey={localStorageKey}
        userStore={userStore}
        calculateTableHeight={calculateTableHeight}
      />
    );
  }
}

CardholdersTab.propTypes = {
  storeInitArgs: PropTypes.object,
  onRowClick: PropTypes.func,
  localStorageKey: PropTypes.string,
  calculateTableHeight: PropTypes.func,
  canAccessChildrenUsers: PropTypes.bool,
};

CardholdersTab.defaultProps = {
  storeInitArgs: {},
  canAccessChildrenUsers: false,
  onRowClick: () => {},
  localStorageKey: '',
  calculateTableHeight: () => 0,
};

export default observer(CardholdersTab);
