import React from 'react';
import s from './commons.module.css';
import { Input } from '@mqd/volt-base';

function InputWithCurrency(props) {
  return (
    <Input
      rightAddOn={{
        content: <div className={s.inputRightAddOnText}>USD</div>,
      }}
      {...props}
    />
  );
}

export default InputWithCurrency;
