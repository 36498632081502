import React, { Component } from 'react';
import { Textarea, Select, VSpacer, Input, FileUpload } from '@mqd/volt-base';
import { disputeReasonOptions, cardCompromisedOptions } from '../constants';
import PropTypes from 'prop-types';

class DisputeTransactionForm extends Component {
  constructor(props) {
    super(props);
  }

  get filesDisplayValue() {
    const { files } = this.props;
    let displayValue;
    switch (files.length) {
      case 0:
        displayValue = '';
        break;
      case 1:
        displayValue = files[0].filename;
        if (displayValue.length > 40) {
          displayValue =
            displayValue.substr(0, 25) +
            '...' +
            displayValue.substr(displayValue.length - 10, displayValue.length);
        }
        break;
      default:
        displayValue = files.length + ' files attached';
        break;
    }
    return displayValue;
  }

  render() {
    const {
      transactionAmount,
      currencyCode,
      disputeAmount,
      onDisputeAmountChange,
      reason,
      onReasonChange,
      cardCompromised,
      onCardCompromisedChange,
      details,
      onDetailsChange,
      onFilesChange,
      formErrors,
      onErrorChanges,
    } = this.props;
    const amountError =
      'Please enter a valid number greater than 0 and up to the transaction amount.';
    const reasonError = 'Choose a reason for this dispute.';
    const cardCompromisedError = 'Select whether the card had been compromised.';

    return (
      <>
        <Input
          label="Dispute amount"
          type="number"
          placeholder="Amount"
          value={disputeAmount}
          onChange={(e) => {
            const value = e.target.value;
            const decimals = value % 1 ? value.toString().split('.')[1].length : 0;
            if (decimals > 2) {
              return;
            } else {
              onDisputeAmountChange(value);
            }
          }}
          isError={(currentValue) => {
            if (
              !currentValue ||
              currentValue > Number.parseFloat(transactionAmount) ||
              currentValue <= 0
            ) {
              onErrorChanges('disputeAmount', true);
              return amountError;
            } else {
              onErrorChanges('disputeAmount', false);
            }
            return false;
          }}
          error={formErrors.disputeAmount ? amountError : false}
          rightAddOn={{ content: currencyCode }}
        />
        <VSpacer factor={2} />
        <Select
          label="Reason"
          placeholder="Select a reason"
          options={disputeReasonOptions}
          value={reason}
          onChange={(selected) => {
            onReasonChange(selected);
            onErrorChanges('reason', selected ? false : true);
          }}
          error={formErrors.reason ? reasonError : false}
        />
        <VSpacer factor={2} />
        <Select
          label="Has the card been compromised?"
          placeholder="Select status of the customer's card"
          options={cardCompromisedOptions}
          value={cardCompromised}
          onChange={(selected) => {
            onCardCompromisedChange(selected);
            onErrorChanges('cardCompromised', selected ? false : true);
          }}
          error={formErrors.cardCompromised ? cardCompromisedError : false}
        />
        <VSpacer factor={2} />
        <Textarea
          label="Additional information"
          placeholder="Add additional information about why this transaction is being disputed"
          rows={2}
          value={details}
          onChange={(e) => onDetailsChange(e.target.value)}
        />
        <VSpacer factor={2} />
        <FileUpload
          acceptedFileTypes="image/jpg, image/jpeg, image/bmp, image/tif, image/tiff, image/gif, image/png, image/eps, image/raw, image/cr2, image/nef, image/orf, image/sr2, .pdf"
          handleFiles={(files) => onFilesChange(files)}
          label="Upload additional file"
          placeholder="Click to upload additional file"
          displayValue={this.filesDisplayValue}
        ></FileUpload>
      </>
    );
  }
}

DisputeTransactionForm.propTypes = {
  transactionAmount: PropTypes.string,
  currencyCode: PropTypes.string,
  disputeAmount: PropTypes.number,
  onDisputeAmountChange: PropTypes.func,
  reason: PropTypes.string,
  onReasonChange: PropTypes.func,
  cardCompromised: PropTypes.string,
  onCardCompromisedChange: PropTypes.func,
  details: PropTypes.string,
  onDetailsChange: PropTypes.func,
  files: PropTypes.array,
  onFilesChange: PropTypes.func,
  formErrors: PropTypes.object,
  onErrorChanges: PropTypes.func,
};

DisputeTransactionForm.defaultProps = {
  transactionAmount: 0,
  currencyCode: '',
  disputeAmount: 0,
  onDisputeAmountChange: () => {},
  reason: '',
  onReasonChange: () => {},
  cardCompromised: '',
  onCardCompromisedChange: () => {},
  details: '',
  onDetailsChange: () => {},
  files: [],
  onFilesChange: () => {},
  formErrors: {},
  onErrorChanges: () => {},
};

export default DisputeTransactionForm;
