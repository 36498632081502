import routerStore from 'stores/router/RouterStore.js';
import { ROUTES } from '../../../routing/index.js';

export function goToProgramReserve() {
  routerStore.go(ROUTES.PROGRAM_RESERVE);
}

export function goToManualEntry() {
  routerStore.go(ROUTES.MANUAL_ENTRY);
}

export function goToBulkEntry() {
  routerStore.go(ROUTES.BULK_ENTRY);
}
