import React from 'react';
import { Text } from '@mqd/volt-base';

function CardIsTerminated() {
  return (
    <div data-testid="card-terminated">
      <Text type="h4">Card is terminated</Text>
      <Text type="body-sm">Terminated cards cannot be used or reactivated.</Text>
    </div>
  );
}

export default CardIsTerminated;
