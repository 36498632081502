import React from 'react';
import { StatusIndicator } from '@mqd/volt-base';

export const RELATED_TRANSACTIONS_TABLE_COLUMNS = [
  {
    header: 'Created time',
    accessor: 'created_time',
    key: 'created_time',
  },
  {
    header: 'Token',
    accessor: 'token',
    key: 'token',
    renderer: ({ cellValue }) => `\u2022\u2022${cellValue.slice(-4)}`,
  },
  {
    header: 'Type',
    accessor: 'type',
    key: 'type',
  },
  {
    header: 'State',
    accessor: 'state',
    key: 'state',
    renderer: ({ cellValue }) => <StatusIndicator status={cellValue} />,
  },
  {
    header: 'Amount',
    accessor: 'amount',
    key: 'amount',
    renderer: ({ cellValue, row }) => `${row.currency_code} ${cellValue?.toFixed(2)}`,
  },
  {
    header: 'Memo',
    accessor: 'memo',
    key: 'memo',
  },
];
