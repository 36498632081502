import { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { pathOr } from 'ramda';
import { useSandboxCredentials } from './useSandboxCredentials.js';
import { WEBHOOKS_QUERY } from '../operations/index.js';

const useWebhooks = () => {
  const client = useApolloClient();
  const [webhooksData, setWebhooksData] = useState(null);
  const [webhooksLoading, setWebhooksLoading] = useState(true);
  const [webhooksError, setWebhooksError] = useState(null);
  const {
    data: { accessToken, applicationToken, shortCode },
    error: sandboxError,
    loading: sandboxLoading,
  } = useSandboxCredentials();

  // TODO: https://marqeta.atlassian.net/browse/PS-30549
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sandboxCredentialsVariables = {
    accessToken,
    applicationToken,
    'x-marqeta-program-short-code': shortCode,
  };

  useEffect(() => {
    const fetchWebhooks = async () => {
      if (!accessToken || !applicationToken || !shortCode) return;
      const {
        data: webhooksData,
        loading: webhooksLoading,
        error: webhooksError,
      } = await client.query({
        query: WEBHOOKS_QUERY,
        variables: sandboxCredentialsVariables,
      });
      const cleanedWebhookData = pathOr([], ['publicSandboxWebhooks', 'data'], webhooksData);
      setWebhooksData(cleanedWebhookData);
      setWebhooksLoading(webhooksLoading);
      setWebhooksError(webhooksError);
    };
    fetchWebhooks();
  }, [accessToken, applicationToken, client, sandboxCredentialsVariables, shortCode]);

  return {
    webhooks: webhooksData || [],
    loading: sandboxLoading || webhooksLoading,
    error: sandboxError || webhooksError,
    setWebhooksData: setWebhooksData,
  };
};

export { useWebhooks };
