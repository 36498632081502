// This is to support old implementations of routerStore.go where
// the 3rd argument was a boolean (skipGaPageView)
export const resolveOptions = (optionsOrSkipGaPageView) => {
  let options;
  if (typeof optionsOrSkipGaPageView === 'boolean') {
    const skipGaPageview = optionsOrSkipGaPageView;
    options = {
      skipGaPageview,
    };
  } else if (typeof optionsOrSkipGaPageView === 'undefined') {
    options = {};
  } else {
    options = optionsOrSkipGaPageView;
  }
  return options;
};

/**
 * Convert string of words to camelCase.
 *
 * @param {string} words Words.
 * @return {string} camelCased words.
 */
export function camelCase(words) {
  words = words.split(' ').reduce((result, word, i) => {
    if (i === 0) result += word.toLowerCase();
    else {
      result += word[0].toUpperCase();
      result += word.slice(1).toLowerCase();
    }
    return result;
  }, '');
  return words;
}

/**
 * Find the route in routeMap or pathMap from a given pathOrRoute.
 *
 * @param {string|Object} pathOrRoute Path (string) or route (object).
 * @param {Object} arg2 Options.
 * @param {Object} arg2.pathMap Path map.
 * @param {Object} arg2.routeMap Route map.
 * @return {Object|null} Matching route or null.
 */
export function urlMatcher(pathOrRoute, { pathMap, routeMap }) {
  // If pathOrRoute is already a route...
  if (typeof pathOrRoute === 'object') {
    // ...return it if it's a valid route.
    if (
      (pathOrRoute.path && pathMap[pathOrRoute.path]) ||
      (pathOrRoute.id && routeMap[pathOrRoute.id])
    ) {
      return pathOrRoute;
    }
    // ...or if it has a path property, search for its dynamic route.
    if (pathOrRoute.path) {
      pathOrRoute = pathOrRoute.path;
      // ...otherwise return null.
    } else {
      return null;
    }
  }
  // Low hanging fruit: Try to look up in pathMap and routeMap.
  if (pathMap[pathOrRoute] || (routeMap && routeMap[pathOrRoute])) {
    return pathMap[pathOrRoute] || routeMap[pathOrRoute];
  }
  // Otherwise search pathMap for a match.
  for (const key in pathMap) {
    const route = pathMap[key];
    // First check to see if name or path match.
    if (route.name === pathOrRoute || route.path === pathOrRoute) {
      return route;
    }
  }
  return null;
}

/**
 * Merge path parameters into a path with syntax: `/hello/:world`
 *
 * @param {string} newPath Path to merge.
 * @param {Array} pathParams Path parameters from Director router.
 * @return {Object} { newPath, pathParams } where newPath is a string with the
 *          path parameters merged, and pathParams is an Object key/value pair
 *          of path parameters.
 */
export function pathParamsToObject(pathParams, route) {
  const pathSegments = route.path.split('/');
  const result = {};
  let activeIndex = 0;
  pathSegments.forEach((segment, i) => {
    if (segment[0] === ':') {
      const key = pathSegments[i].slice(1);
      const value = pathParams[activeIndex];
      result[key] = value;
      activeIndex += 1;
    }
  });
  return result;
}
