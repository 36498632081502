const MARQETA_ADMIN_INTERNAL = 'marqeta-admin-internal';
const PROGRAM_ADMIN = 'program-admin';
const VIEWER = 'read-only';
const SUPPLIER_PAYMENTS_MANAGER = 'supplier-payments-manager';
const DELIVERY_INTERNAL = 'delivery-internal';
const PRODUCTION_SUPPORT_INTERNAL = 'production-support-internal';
const CARDHOLDER_SUPPORT = 'cardholder-support';
const CREDIT_PRODUCT_CREATOR = 'aux-credit-product-creator-external';
const CREDIT_PRODUCT_APPROVER = 'aux-credit-product-approver-external';
const CREDIT_OFFER_CREATOR = 'aux-credit-offer-creator-external';
const CREDIT_OFFER_APPROVER = 'aux-credit-offer-approver-external';
const CREDIT_SUPPORT_AGENT = 'aux-credit-support-agent-external';
const CREDIT_PRODUCT_VIEW_ONLY = 'aux-credit-product-view-only-external';
const CREDIT_BRAND_CONTRIBUTOR = 'aux-credit-brand-contributor';
const CREDIT_BANK_PROGRAM_MANAGER_CREATOR = 'aux-credit-bank-program-manager-creator';
const CREDIT_BANK_PROGRAM_MANAGER_APPROVER = 'aux-credit-bank-program-manager-approver';

export const janusToRedseaRoles = [MARQETA_ADMIN_INTERNAL, PROGRAM_ADMIN, VIEWER];

export {
  MARQETA_ADMIN_INTERNAL,
  PROGRAM_ADMIN,
  VIEWER,
  SUPPLIER_PAYMENTS_MANAGER,
  DELIVERY_INTERNAL,
  PRODUCTION_SUPPORT_INTERNAL,
  CARDHOLDER_SUPPORT,
  CREDIT_PRODUCT_CREATOR,
  CREDIT_PRODUCT_APPROVER,
  CREDIT_OFFER_CREATOR,
  CREDIT_OFFER_APPROVER,
  CREDIT_SUPPORT_AGENT,
  CREDIT_PRODUCT_VIEW_ONLY,
  CREDIT_BRAND_CONTRIBUTOR,
  CREDIT_BANK_PROGRAM_MANAGER_CREATOR,
  CREDIT_BANK_PROGRAM_MANAGER_APPROVER,
};
