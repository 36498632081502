const dateSorter = (a: string, b: string) => {
  const aVal = new Date(a);
  const bVal = new Date(b);
  // push all non-date objects to the back
  if (aVal.toString() === 'Invalid Date') {
    return [-1, 1];
  } else if (bVal.toString() === 'Invalid Date') {
    return [1, -1];
  }

  if (aVal > bVal) {
    return [1, -1];
  }
  return [-1, 1];
};
export default dateSorter;
