import React from 'react';

import { disabledStatuses } from '../../../../../../constants';
import { PreviewTemplate } from '../../../../../../components/default-card-template/components/preview-template';
import { useCardTemplateContext } from '../../../../../../context/CardTemplateContext';

function PreviewTemplateOptionallyEditable(props) {
  const { status } = useCardTemplateContext();

  return <PreviewTemplate {...props} editable={!disabledStatuses.has(status)} />;
}

export { PreviewTemplateOptionallyEditable as PreviewTemplate };
