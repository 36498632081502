import React from 'react';
import { prop } from 'ramda';
import { Link, List, VSpacer } from '@mqd/volt-base';
import {
  renderName,
  renderState,
  renderUrl,
  sortActiveWebhooksFirst,
} from '../functions/webhooks.js';
import { WebhooksTableStateColumn } from './WebhooksTableStateColumn.js';

const WebhooksTable = ({ webhooks, setWebhooksData }) => {
  return (
    <>
      <VSpacer factor={3} />
      <List
        rows={sortActiveWebhooksFirst(webhooks)}
        columns={[
          {
            id: 'name',
            label: 'Name',
            renderer: renderName(({ name, token }) => (
              <Link href={`/development/webhooks/show?token=${token}`}>{name}</Link>
            )),
          },
          {
            id: 'url',
            label: 'URL',
            renderer: renderUrl(prop('url')),
          },
          {
            id: 'state',
            label: 'State',
            renderer: renderState((webhook) => (
              <WebhooksTableStateColumn
                key={webhook.token}
                webhook={webhook}
                webhooks={webhooks}
                setWebhooksData={setWebhooksData}
              />
            )),
          },
        ]}
      />
    </>
  );
};

export { WebhooksTable };
