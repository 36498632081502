// @flow
import { decorate, observable, action, computed } from 'mobx';
import React from 'react';
import { Text } from '@mqd/volt-base';
import moment from 'moment';
import CardProductStore from './CardProductStore';
import CardholderStore from './CardholderStore';
import AuthControlStore from './AuthControlStore';
import CardStore from './CardStore';
import { ParentStore } from '@mq/voltron-parent';
import FundingSourceStore from './FundingSourceStore';
import GpaOrderStore from './GpaOrderStore';
import VelocityControlsFormStore from './VelocityControlsFormStore';
import gqlUtils from '@mqd/graphql-utils';
import { invalidExpirationDate, invalidExpirationDateFormat } from '../constants';
import getSignature from '../shared-utils/get-signature';
import * as qualtrics from 'utils/qualtrics';

const { templates } = gqlUtils;

const logErrorHeader = 'Unable to create card';

class ManualCardCreateStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    const { queryParams } = args;
    this.queryParams = queryParams;
  }

  // values
  queryParams: object = {};
  loading: boolean = false;
  cardholder: CardholderStore = new CardholderStore({ gqlClient: this.gqlClient });
  cardProduct: CardProductStore = new CardProductStore();
  authControls: Array<AuthControlStore> = [new AuthControlStore()];
  card: CardStore = new CardStore();
  cardExpirationDate: string = '';
  activeFundingSource: FundingSourceStore = null;
  fundingSources: Array<FundingSourceStore> = [];
  gpaOrder: GpaOrderStore = new GpaOrderStore({ currency_code: 'USD' });
  velocityControlsFormStore: VelocityControlsFormStore = new VelocityControlsFormStore();
  errors: Array = [];
  requestSuccess: Boolean = null;

  // address
  first_name: String = '';
  middle_name: String = '';
  last_name: String = '';
  address1: String = '';
  address2: String = '';
  city: String = '';
  state: String = '';
  postal_code: String = '';
  country: String = '';
  zip: String = '';
  method: String = 'LOCAL_MAIL';
  expedite: Boolean = false;

  // Card personalization
  name_line_1: String = null;
  name_line_2: String = null;
  name_line_3: String = null;
  cardholderSignature: String = '';

  // Card carrier
  cardCarrier: Object = null;

  // Return address
  returnAddress: Object = null;

  consolidatedAddresses: Object = {};
  consolidatedCardPersonalization: Object = {};
  consolidatedCardCarrier: Object = null;

  // actions
  async createCardResources() {
    if (!this.formIsValid) {
      const { setControlValidities } = this.velocityControlsFormStore;
      setControlValidities();
      return;
    }

    try {
      this.loading = true;
      const composeMutation = templates.composeCardResourceMutation;
      const mutation = composeMutation(this.cardResourcesPayload);
      const result = await this.gqlMutation(
        mutation,
        this.cardResourcesPayload,
        undefined,
        undefined,
        'all'
      );

      if (result?.data) {
        qualtrics.track(qualtrics.EVENTS.USER_CARD_CREATED);
      }

      const cardholderToken = this.dig(
        result,
        'data',
        'createCardResources',
        'cardholder',
        'token'
      );
      const errors = this.dig(result, 'errors');
      if (cardholderToken) {
        if (!errors) {
          await this.createCard(cardholderToken);
        } else {
          this.errors = errors;
          this.logResponseErrorWithCardholderToken({ cardholderToken, errors });
        }
        await this.initializeCardholder(cardholderToken);
      }
    } catch (err) {
      console.error(`${logErrorHeader}:`, err);
      this.errors = err instanceof Array ? err : [err];
    } finally {
      this.loading = false;
      if (this.errors.length > 0) this.requestSuccess = false;
    }
  }

  async createCardResourcesUam() {
    if (!this.formIsValid) {
      const { setControlValidities } = this.velocityControlsFormStore;
      setControlValidities();
      return;
    }

    try {
      this.loading = true;
      const composeMutation = templates.composeCardResourceMutation;
      const mutation = composeMutation(this.cardResourcesPayload);
      const result = await this.gqlMutation(
        mutation,
        this.cardResourcesPayload,
        undefined,
        undefined,
        'all'
      );

      if (result?.data) {
        qualtrics.track(qualtrics.EVENTS.USER_CARD_CREATED);
      }

      const cardholderToken = this.dig(
        result,
        'data',
        'createCardResources',
        'cardholder',
        'token'
      );
      const errors = this.dig(result, 'errors');
      if (cardholderToken) {
        if (!errors) {
          await this.manualCreateCard(cardholderToken);
        } else {
          this.errors = errors;
          this.logResponseErrorWithCardholderToken({ cardholderToken, errors });
        }
        await this.initializeCardholder(cardholderToken);
      }
    } catch (err) {
      console.error(`${logErrorHeader}:`, err);
      this.errors = err instanceof Array ? err : [err];
    } finally {
      this.loading = false;
      if (this.errors.length > 0) this.requestSuccess = false;
    }
  }

  async createCard(token) {
    try {
      this.loading = true;
      const fulfillmentPayload = {
        fulfillment: {
          shipping: {
            recipient_address: this.cardCardholderCardproductFullfillmentShippingAddress,
            return_address: this.mapReturnAddressToApi,
            method: this.method,
          },
          card_personalization: {
            text: this.cardPersonalizationTextPayload,
            images: {
              signature: {
                name: getSignature(this.cardholderSignature),
              },
              carrier_return_window: {
                name: this.cardCarrier ? getSignature(this.cardCarrier.carrier_return_window) : '',
              },
            },
            carrier: {
              message_line: this.cardCarrier ? this.cardCarrier.message_line : '',
              logo_file: this.cardCarrier ? this.cardCarrier.logo_file : '',
            },
          },
        },
      };

      const cardProductIsPhysical = this.cardProduct.isPhysical;
      const shouldUpdateAddress = this.inputDataHasChanged && cardProductIsPhysical;

      const payload = {
        cardholder_token: token,
        card_product_token: this.cardProduct.token,
        ...(cardProductIsPhysical && { expedite: this.expedite }),
        ...(shouldUpdateAddress && fulfillmentPayload),
      };

      if (this.expirationOffSet) {
        payload['expiration_offset'] = this.expirationOffSet;
      }

      const result = await this.gqlMutation(
        `
          mutation createCard(
            $cardholder_token: ID!
            $card_product_token: ID!
            $expiration_offset: ExpirationOffsetInput
            $fulfillment: CardFulfillmentInput
            $expedite: Boolean
          ) {
            createCard(
              cardholder_token: $cardholder_token
              card_product_token: $card_product_token
              expiration_offset: $expiration_offset
              fulfillment: $fulfillment
              expedite: $expedite
            ){
              token
            }
          }
        `,
        payload
      );

      const cardData = this.dig(result, 'data', 'createCard');
      this.card = new CardStore(cardData);
      this.requestSuccess = Boolean(this.card.token);
    } catch (e) {
      throw e;
    } finally {
      this.loading = false;
    }
  }

  async manualCreateCard(token) {
    try {
      this.loading = true;
      const fulfillmentPayload = {
        fulfillment: {
          shipping: {
            recipient_address: this.cardCardholderCardproductFullfillmentShippingAddress,
            return_address: this.mapReturnAddressToApi,
            method: this.method,
          },
          card_personalization: {
            text: this.cardPersonalizationTextPayload,
            images: {
              signature: {
                name: getSignature(this.cardholderSignature),
              },
              carrier_return_window: {
                name: this.cardCarrier ? getSignature(this.cardCarrier.carrier_return_window) : '',
              },
            },
            carrier: {
              message_line: this.cardCarrier ? this.cardCarrier.message_line : '',
              logo_file: this.cardCarrier ? this.cardCarrier.logo_file : '',
            },
          },
        },
      };

      const cardProductIsPhysical = this.cardProduct.isPhysical;
      const shouldUpdateAddress = this.inputDataHasChanged && cardProductIsPhysical;

      const payload = {
        cardholder_token: token,
        card_product_token: this.cardProduct.token,
        ...(cardProductIsPhysical && { expedite: this.expedite }),
        ...(shouldUpdateAddress && fulfillmentPayload),
      };

      if (this.expirationOffSet) {
        payload['expiration_offset'] = this.expirationOffSet;
      }

      const result = await this.gqlMutation(
        `
          mutation manualCreateCard(
            $cardholder_token: ID!
            $card_product_token: ID!
            $expiration_offset: ExpirationOffsetInput
            $fulfillment: CardFulfillmentInput
            $expedite: Boolean
          ) {
            manualCreateCard(
              cardholder_token: $cardholder_token
              card_product_token: $card_product_token
              expiration_offset: $expiration_offset
              fulfillment: $fulfillment
              expedite: $expedite
            ){
              token
            }
          }
        `,
        payload
      );

      const cardData = this.dig(result, 'data', 'manualCreateCard');
      this.card = new CardStore(cardData);
      this.requestSuccess = Boolean(this.card.token);
    } catch (e) {
      throw e;
    } finally {
      this.loading = false;
    }
  }

  async hydrate() {
    const { cardproduct_token, cardholder_token } = this.hydrateParams;
    if (cardproduct_token) {
      await this.initializeCardProductStore(cardproduct_token);
    }
    if (cardholder_token) {
      await this.initializeCardholder(cardholder_token);
    }

    this.consolidatedAddresses = this.loadExistingAddresses();
    this.consolidatedCardPersonalization = this.loadExistingCardPersonalization();
  }

  async initializeCardProductStore(token) {
    try {
      this.loading = true;
      const cardProduct = new CardProductStore({ token, gqlClient: this.gqlClient });
      await cardProduct.hydrate();
      this.cardProduct = cardProduct;
      this.velocityControlsFormStore.setAttr('cardProduct', cardProduct);
      this.velocityControlsFormStore.validateVelocityControlMaximums();

      // dynamically set shipping method options
      const { isBulkShip } = this.cardProduct;
      isBulkShip ? (this.method = 'GROUND') : 'LOCAL_MAIL';
    } catch (e) {
      // figure out what the error state is
      // https://marqeta.atlassian.net/browse/PS-10502
    } finally {
      this.loading = false;
    }
  }

  async initializeCardholder(token) {
    try {
      this.loading = true;
      const cardholder = new CardholderStore({ gqlClient: this.gqlClient });
      await cardholder.hydrate(token);
      this.cardholder = cardholder;
      this.velocityControlsFormStore.setAttr('cardholder', cardholder);
      this.velocityControlsFormStore.validateVelocityControlMaximums();
    } catch (err) {
      console.error(err);
      // figure out what the error state is
      // https://marqeta.atlassian.net/browse/PS-10502
    } finally {
      this.loading = false;
    }
  }

  logResponseErrorWithCardholderToken({ cardholderToken, errors }) {
    const error_messages = [];
    errors.forEach((error) => {
      const error_message = this.dig(error, 'errorResponse', 'error_message');
      error_messages.push(error_message);
    });

    console.error(`${logErrorHeader} for user ${cardholderToken}:`, error_messages);
  }

  addFundingSource(fundingSource) {
    if (fundingSource instanceof FundingSourceStore) {
      this.activeFundingSource = fundingSource;
      if (this.fundingSources.length === 0) {
        this.fundingSources = [fundingSource];
      } else {
        this.fundingSources = [...this.fundingSources, fundingSource];
      }
    }
  }

  setThreeYearTimeFrame() {
    this.cardExpirationDate = moment().add(3, 'years').utc().format('YYYY-MM-DD');
  }

  addNewAuthControl() {
    this.authControls.push(new AuthControlStore());
  }

  removeAuthControl(idx) {
    if (this.authControls.length === 1) {
      this.authControls[0].merchant_scope.mcc = '';
      return;
    }
    this.authControls.splice(idx, 1);
  }

  duplicateAuthControl(idx) {
    const targetAuthControl = this.authControls[idx];
    const {
      merchant_scope: { mcc },
    } = targetAuthControl;
    const newAuthControl = new AuthControlStore({
      merchant_scope: {
        mcc,
      },
    });
    this.authControls.splice(idx, 0, newAuthControl);
  }

  loadExistingAddresses() {
    const cardholderShippingAddress = this.dig(this.cardholder, 'shippingAddress');
    const cardProductShippingAddress = this.dig(this.cardProduct, 'shippingAddress');

    if (cardholderShippingAddress) {
      for (let key in cardholderShippingAddress) {
        const value = cardholderShippingAddress[key];
        this.setAttr(key, value);
      }
      return cardholderShippingAddress;
    } else if (cardProductShippingAddress) {
      for (let key in cardProductShippingAddress) {
        const value = cardProductShippingAddress[key];
        this.setAttr(key, value);
      }
      return cardProductShippingAddress;
    }

    return {};
  }

  loadExistingCardPersonalization() {
    return {
      cardholderSignature: this.cardholderSignature,
    };
  }

  // computed
  get shippingAddressHasChanged() {
    const shippingAddressFields = [
      'first_name',
      'middle_name',
      'last_name',
      'address1',
      'address2',
      'city',
      'state',
      'postal_code',
      'country',
      'zip',
    ];

    return shippingAddressFields.some((key) => {
      return this[key] !== this.consolidatedAddresses[key];
    });
  }

  // computed
  get cardPersonalizationHasChanged() {
    const cardPersonalizationFields = ['cardholderSignature'];

    return cardPersonalizationFields.some((key) => {
      return this[key] !== this.consolidatedCardPersonalization[key];
    });
  }

  // computed
  get cardCarrierHasChanged() {
    return this.cardCarrier === this.consolidatedCardCarrier;
  }

  // computed
  get inputDataHasChanged() {
    return (
      this.cardPersonalizationHasChanged ||
      this.shippingAddressHasChanged ||
      this.cardCarrierHasChanged
    );
  }

  get formIsValid() {
    const { velocityControlsAreValid } = this.velocityControlsFormStore;
    if (this.cardProduct.isPhysical) {
      return Boolean(
        velocityControlsAreValid &&
          this.gpaOrderIsValid &&
          !this.cardExpirationDateError &&
          this.name_line_1 &&
          this.method &&
          !this.nameLine1Validator &&
          !this.cardholderSignatureValidator &&
          !this.cardCarrierValidator
      );
    }
    return Boolean(
      velocityControlsAreValid &&
        this.gpaOrderIsValid &&
        !this.cardExpirationDateError &&
        this.method
    );
  }

  get cardResourcesPayload() {
    const { velocityControlPayloads } = this.velocityControlsFormStore;
    const { business_token: { val: parent_token } = {} } = this.queryParams;
    const { first_name, last_name, token: existing_cardholder_token } = this.cardholder || {};
    const { account_number, account_type, name_on_account, routing_number, verification_override } =
      this.activeFundingSource || {};
    const { amount, currency_code } = this.gpaOrder || {};
    const { cardholderSignature } = this;

    return {
      first_name,
      last_name,
      existing_cardholder_token,
      parent_token,
      account_number,
      account_type,
      name_on_account,
      routing_number,
      verification_override,
      amount: parseFloat(amount),
      currency_code,
      fundingSources: this.fundingSourcePayloads,
      authControls: this.authControlPayloads,
      velocityControls: velocityControlPayloads,
      cardholderSignature,
    };
  }

  get fundingSourcePayloads() {
    return this.fundingSources.map((fs) => {
      const { name_on_account, account_type, routing_number, account_number } = fs;
      return { name_on_account, account_type, routing_number, account_number };
    });
  }

  get authControlPayloads() {
    const payloads = this.authControls.map((control) => {
      const {
        merchant_scope: { mcc },
      } = ({} = control);
      if (mcc) {
        return { merchant_scope: { mcc }, name: mcc };
      }
    });
    return payloads.filter((control) => control);
  }

  get gpaOrderIsValid() {
    const { amount } = this.gpaOrder;
    return amount ? Boolean(this.activeFundingSource) : true;
  }

  get cardExpirationDateError() {
    const { end_date } = this.cardProduct;
    if (!this.cardExpirationDate) return '';
    const regEx = /^\d{4}-\d{2}-\d{2}$/;
    const expDate = new Date(this.cardExpirationDate);

    if (this.cardExpirationDate.match(regEx)) {
      const isInvalidDate = isNaN(expDate.getTime());
      if (isInvalidDate) {
        return invalidExpirationDate;
      }

      if (new Date() > expDate || (end_date && expDate > new Date(end_date))) {
        return invalidExpirationDate;
      }
    } else {
      return invalidExpirationDateFormat;
    }
  }

  get cardStartDate() {
    const { start_date } = this.cardProduct;
    const currentDate = new Date();
    const startDate = new Date(start_date);
    const currentDateString = new moment().utc().format('YYYY-MM-DD');
    if (!start_date) return currentDateString;

    if (currentDate < startDate) {
      return moment(start_date, 'YYYY-MM-DD').format('YYYY-MM-DD');
    }

    return currentDateString;
  }

  get expirationOffSet() {
    if (!this.cardExpirationDate) return null;
    const startDate = new moment().utc().startOf('day');
    const endDate = moment(this.cardExpirationDate, 'YYYY-MM-DD').utc();
    const value = endDate.diff(startDate, 'days');

    return {
      unit: 'DAYS',
      value,
    };
  }

  get allFundingSources() {
    const { funding_sources: existingFundingSources } = this.cardholder;
    const allFundingSources = [...this.fundingSources];
    if (existingFundingSources.length) {
      allFundingSources.push(...existingFundingSources);
    }
    return allFundingSources;
  }

  get fundingSourceSelectOptions() {
    return this.allFundingSources.map((fundingSource) => {
      return {
        val: fundingSource,
        render: fundingSource.name_on_account,
      };
    });
  }

  get responseModalProps() {
    const handleClose = () => (this.requestSuccess = null);
    const cardholderToken = this.cardholder.token;
    return {
      handleClose,
      hide: ![true, false].includes(this.requestSuccess),
      true: {
        heading: 'Creation successful',
        text: (
          <Text>
            Your card has been created. You can copy your card details and start making purchases
            now.
          </Text>
        ),
        actionText: 'View card',
        testId: 'card-creation-success-modal',
      },
      false: {
        heading: 'Unable to create card',
        text: (
          <>
            <Text>Please revisit the values and try again.</Text>
            {cardholderToken && (
              <div style={{ width: '360px' }}>
                <Text>
                  <b>User token: </b>&nbsp;{cardholderToken}
                </Text>
              </div>
            )}
          </>
        ),
        actionText: 'Return to card creation',
        testId: 'card-creation-failure-modal',
      },
    };
  }

  get cardCardholderCardproductFullfillmentShippingAddress() {
    const {
      first_name,
      middle_name,
      last_name,
      address1,
      address2,
      city,
      state,
      postal_code,
      zip,
      country,
    } = this || {};

    return {
      first_name,
      middle_name,
      last_name,
      address1,
      address2,
      city,
      state,
      postal_code,
      zip,
      country,
    };
  }

  get mapReturnAddressToApi() {
    const { returnAddress } = this;
    if (!returnAddress) {
      return null;
    }

    const {
      firstName,
      middleName,
      lastName,
      address1,
      address2,
      city,
      state,
      postal,
      country,
      phone,
    } = returnAddress;
    return {
      first_name: firstName,
      middle_name: middleName,
      last_name: lastName,
      address1,
      address2,
      city,
      state,
      postal_code: postal,
      country,
      phone,
    };
  }

  get cardPersonalizationData() {
    const { name_line_1, name_line_2, name_line_3, cardholderSignature, method, expedite } =
      this || {};

    return {
      name_line_1,
      name_line_2,
      name_line_3,
      cardholderSignature,
      method,
      expedite,
    };
  }

  get shippingMethodOptions() {
    const { isBulkShip } = this.cardProduct;
    const shippingMethodOptions = [
      {
        id: 'two-day',
        val: 'TWO_DAY',
        render: 'TWO DAY',
        renderSelected: 'TWO DAY',
      },
      {
        id: 'over-night',
        val: 'OVERNIGHT',
        render: 'OVERNIGHT',
        renderSelected: 'OVERNIGHT',
      },
      {
        id: 'international',
        val: 'INTERNATIONAL',
        render: 'INTERNATIONAL',
        renderSelected: 'INTERNATIONAL',
      },
      {
        id: 'international-priority',
        val: 'INTERNATIONAL_PRIORITY',
        render: 'INTERNATIONAL PRIORITY',
        renderSelected: 'INTERNATIONAL PRIORITY',
      },
      {
        id: 'local-mail-package',
        val: 'LOCAL_MAIL_PACKAGE',
        render: 'LOCAL MAIL PACKAGE',
        renderSelected: 'LOCAL MAIL PACKAGE',
      },
    ];
    const groundOptions = [
      {
        id: 'ground',
        val: 'GROUND',
        render: 'GROUND',
        renderSelected: 'GROUND',
      },
    ];
    const localMailOptions = [
      {
        id: 'local-mail',
        val: 'LOCAL_MAIL',
        render: 'LOCAL MAIL',
        renderSelected: 'LOCAL MAIL',
      },
      {
        id: 'local-priority',
        val: 'LOCAL_PRIORITY',
        render: 'LOCAL PRIORITY',
        renderSelected: 'LOCAL PRIORITY',
      },
    ];

    // these options changes based on card product's bulk ship status
    // doc: https://www.marqeta.com/docs/core-api/cards#_create_card
    isBulkShip
      ? shippingMethodOptions.unshift(...groundOptions)
      : shippingMethodOptions.unshift(...localMailOptions);

    return shippingMethodOptions;
  }

  get nameLine1Validator() {
    const { name_line_1 } = this || {};

    if (name_line_1 !== null && !name_line_1) {
      return 'This field is required';
    }
  }

  get cardholderSignatureValidator() {
    const { cardholderSignature } = this || {};

    if (cardholderSignature && cardholderSignature.includes(' ')) {
      return 'Invalid Entry. Spaces are not allowed';
    }
  }

  get cardCarrierValidator() {
    const { cardCarrier } = this;
    if (!cardCarrier) return false;
    const error = 'Invalid Entry. Spaces are not allowed';
    if (cardCarrier.logo_file && cardCarrier.logo_file.includes(' ')) {
      return error;
    }
    if (cardCarrier.carrier_return_window && cardCarrier.carrier_return_window.includes(' ')) {
      return error;
    }
  }

  get cardPersonalizationValidators() {
    const { nameLine1Validator, cardholderSignatureValidator } = this;
    return {
      nameLine1Validator,
      cardholderSignatureValidator,
    };
  }

  get cardPersonalizationTextPayload() {
    const { name_line_1, name_line_2, name_line_3 } = this || {};

    const constructNameLinePayload = (key, nameLine) => {
      if (!nameLine) return null;
      return {
        [key]: {
          value: nameLine,
        },
      };
    };

    const nameLine1 = constructNameLinePayload('name_line_1', name_line_1);
    const nameLine2 = constructNameLinePayload('name_line_2', name_line_2);
    const nameLine3 = constructNameLinePayload('name_line_3', name_line_3);

    return {
      ...nameLine1,
      ...(nameLine2 && nameLine2),
      ...(nameLine3 && nameLine3),
    };
  }
}

decorate(ManualCardCreateStore, {
  // observables
  authControls: observable,
  activeFundingSource: observable,
  fundingSources: observable,
  gpaOrder: observable,
  queryParams: observable,
  cardholder: observable,
  cardProduct: observable,
  card: observable,
  velocityControlsFormStore: observable,
  loading: observable,
  cardExpirationDate: observable,
  requestSuccess: observable,
  errors: observable,
  consolidatedAddresses: observable,

  first_name: observable,
  middle_name: observable,
  last_name: observable,
  address1: observable,
  address2: observable,
  city: observable,
  state: observable,
  postal_code: observable,
  zip: observable,
  country: observable,
  method: observable,
  expedite: observable,

  // card fulfillment card_personalization
  name_line_1: observable,
  name_line_2: observable,
  name_line_3: observable,
  cardholderSignature: observable,
  cardCarrier: observable,
  consolidatedCardPersonalization: observable,
  consolidatedCardCarrier: observable,
  returnAddress: observable,

  // actions
  createCardResources: action.bound,
  createCardResourcesUam: action.bound,
  addNewAuthControl: action.bound,
  hydrate: action.bound,
  initializeCardProductStore: action.bound,
  removeAuthControl: action.bound,
  duplicateAuthControl: action.bound,
  setThreeYearTimeFrame: action.bound,
  initializeCardholder: action.bound,
  addFundingSource: action.bound,
  createCard: action.bound,
  manualCreateCard: action.bound,
  loadExistingAddresses: action.bound,
  logResponseErrorWithCardholderToken: action.bound,

  // computed
  formIsValid: computed,
  cardResourcesPayload: computed,
  fundingSourcePayloads: computed,
  authControlPayloads: computed,
  gpaOrderIsValid: computed,
  cardStartDate: computed,
  cardExpirationDateError: computed,
  expirationOffSet: computed,
  fundingSourceSelectOptions: computed,
  allFundingSources: computed,
  responseModalProps: computed,
  cardCardholderCardproductFullfillmentShippingAddress: computed,
  shippingAddressHasChanged: computed,
  cardPersonalizationHasChanged: computed,
  inputDataHasChanged: computed,
  cardCarrierHasChanged: computed,
  cardPersonalizationData: computed,
  shippingMethodOptions: computed,
  nameLine1Validator: computed,
  cardholderSignatureValidator: computed,
  cardCarrierValidator: computed,
  cardPersonalizationValidators: computed,
  cardPersonalizationTextPayload: computed,
  mapReturnAddressToApi: computed,

  // helpers
  gotToCard: action.bound,
});

export default ManualCardCreateStore;
