import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Icon, Text, VSpacer, Link } from '@mqd/volt-base';
import {
  CP_WELCOME_CART,
  AO_WELCOME_CART,
  PL_WELCOME_CART,
  CREDIT_TYPES,
} from '../../constants.js';
import { createStorageKey } from '../../utils.js';
import CreditCard from '../CreditCard/index.js';
import CreditCapabilities from '../../CreditCapabilities/index.js';

import s from './WelcomeCard.module.css';

export default function WelcomeCard({ productType }) {
  const viewType = CreditCapabilities.getViewType(productType);
  const cookieKey = createStorageKey(productType);
  const cookieValue =
    localStorage.getItem(cookieKey) && JSON.parse(localStorage.getItem(cookieKey));
  const [cardIsHidden, setCardIsHidden] = useState(cookieValue && cookieValue[viewType]);

  const hideCard = () => {
    setCardIsHidden(true);
    localStorage.setItem(cookieKey, JSON.stringify({ [viewType]: true }));
  };

  const cardDataMap = {
    [CREDIT_TYPES.CREDIT]: CP_WELCOME_CART,
    [CREDIT_TYPES.ACQUISITION]: AO_WELCOME_CART,
    [CREDIT_TYPES.POLICY_LIBRARY]: PL_WELCOME_CART,
  };

  const cardData = cardDataMap[productType];

  return (
    <div className={`${s.welcomeCardContainer} ${cardIsHidden ? s.hidden : ''}`}>
      <div className={s.contentContainer}>
        <div className={s.leftAccentLine} />
        <div className={s.iconContainer}>
          <CreditCard />
        </div>
        <div style={{ width: '100%' }}>
          <Text type="h4" inline={true} testId="welcome-card-title">
            {cardData[viewType].TITLE}
          </Text>
          <VSpacer factor={0.85} />
          <Text type="body-sm" testId="welcome-card-text" className={s.cardTextWidth}>
            {cardData[viewType].TEXT}
          </Text>
          <VSpacer />
          {cardData[viewType].LINK && (
            <Link href={cardData[viewType].LINK} external newTab iconType="new-tab">
              {cardData[viewType].LINK_TEXT}
            </Link>
          )}
        </div>
        <Icon
          type="close"
          mod="default"
          factor={0.3}
          style={{ float: 'right', cursor: 'pointer', alignSelf: 'baseline' }}
          onClick={hideCard}
          noHoverEffects
        />
      </div>

      <VSpacer factor={3} />
    </div>
  );
}

WelcomeCard.propTypes = {
  productType: PropTypes.string,
};
