import React, { useState } from 'react';
import ManualCreateFlow from './ManualCreateFlow';
import BulkCreate from './BulkCreate';

function CardCreationDetail({
  type,
  shouldRenderCreationType,
  goToBulkResponse,
  goToCard,
  goToHome,
  goBack,
  bulkStoreInitArgs,
  manualStoreInitArgs,
  userStore,
}) {
  const [creationType] = useState(type);
  const isBulk = creationType !== 'manual';

  if (isBulk) {
    return (
      <BulkCreate
        storeInitArgs={bulkStoreInitArgs}
        goToBulkResponse={goToBulkResponse}
        goBack={goBack}
        goToHome={goToHome}
      />
    );
  }

  return (
    <ManualCreateFlow
      goBack={goBack}
      goToCard={goToCard}
      storeInitArgs={manualStoreInitArgs}
      userStore={userStore}
    />
  );
}

export default CardCreationDetail;
