import React from 'react';

import { getDisplayName } from '../helpers';
import { useCardTemplateContext } from '../context/CardTemplateContext';
import { useIsCardEdited } from '../components/accordion-card-template/hooks';
import { changedReview, disabledStatuses } from '../constants';

export function optionallyRendered(CardTemplate) {
  function OptionallyRendered({ datum, mode, switchToPreview, switchToEdit }) {
    const { template, compareState, status } = useCardTemplateContext();

    const cardCompareState = compareState.find(
      (compareStateItem) => compareStateItem.id === datum.id
    );

    const isCardEdited = useIsCardEdited(template.properties, datum, cardCompareState);
    const shouldShowCard = Boolean(
      datum.temp || status === changedReview || (disabledStatuses.has(status) && isCardEdited)
    );

    return (
      shouldShowCard && (
        <CardTemplate
          datum={datum}
          mode={mode}
          switchToEdit={switchToEdit}
          switchToPreview={switchToPreview}
          isCardEdited={isCardEdited}
          cardCompareState={cardCompareState}
        />
      )
    );
  }

  OptionallyRendered.displayName = `OptionallyRendered(${getDisplayName(CardTemplate)})`;

  return OptionallyRendered;
}
