/* eslint-disable camelcase */
import React from 'react';
import { observer } from 'mobx-react';
import {
  Card,
  Text,
  ContentSnippet,
  HSpacer,
  Button,
  VSpacer,
  Icon,
  LoadingOverlay,
} from '@mqd/volt-base';
import { AddMetadataModal } from './../shared-components';
import { ParentDetailCard, Status } from '@mq/voltron-parent';
import ChangeStatusModal from './../change-status-modal/ChangeStatusModal';
import s from './CardholderInfoTab.module.css';
import AddressStore from '../stores/AddressStore';

class CardholderInformationCard extends ParentDetailCard {
  constructor(props) {
    super(props);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.state = {
      loading: false,
      expand: false,
      editActive: false,
      showChangeStatusModal: false,
      showAddMetadataModal: false,
    };
  }

  get cardholderStore() {
    const { cardholderStore = {} } = this.props;
    return cardholderStore;
  }

  async handleSave() {
    const { updateCardholder } = this.cardholderStore;
    this.setState({ loading: true });
    const success = await updateCardholder();
    if (success) {
      this.setState({ loading: false, editActive: false });
    } else {
      this.setState({ loading: false });
    }
  }

  handleCancel() {
    const { revertUpdates } = this.cardholderStore;
    revertUpdates && revertUpdates();
    this.setState({ editActive: false });
  }

  renderHeaderButtons() {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button type="outline" onClick={this.handleCancel}>
          Cancel
        </Button>
        <HSpacer />
        <Button onClick={this.handleSave}>Save</Button>
      </div>
    );
  }

  getFooterButtons() {
    const { expand } = this.state;
    return [
      <Button
        key="expand"
        type="outline"
        testId="expand-button"
        onClick={() => this.setState({ expand: !expand })}
      >
        <div className={s.flexDiv}>
          <div>{expand ? 'Minimize' : 'View All'}</div>
          <HSpacer factor={0.5} />
          <Icon type={expand ? 'arrow-up' : 'arrow-down'} factor={0.8333} />
        </div>
      </Button>,
    ];
  }

  renderStatus() {
    const { status } = this.cardholderStore;
    const { editActive } = this.state;
    const { hasUserDetailsEdit, hasUamRoleToken } = this.props;
    const canEditStatus = hasUamRoleToken ? hasUserDetailsEdit : true;

    return (
      <>
        <ContentSnippet
          title="Status"
          testId="volt-content-snippet_title-Status"
          horizontalLayout
          tooltipProps={{ zIndex: 9999 }}
          tooltipContent={`
              Status controls the user's capabilities and the 
              setting of the user.active field.
            `}
          titleInline={!editActive ? { width: 180 } : null}
          contentInline={!editActive ? { flexGrow: 1 } : null}
        >
          <div className={s.flexDiv}>
            <Status status={status} />
            {canEditStatus
              ? this.renderChangeStatusButton('Cardholder Status Changed', {
                  Flow: 'Cardholder details',
                })
              : null}
          </div>
        </ContentSnippet>
        <VSpacer factor={2} />
      </>
    );
  }

  renderAddress() {
    const { editActive } = this.state;
    const { address1, address2, city, state, postal_code, country, updateForSave } =
      this.cardholderStore;
    const addressStore = new AddressStore({
      address1,
      address2,
      city,
      state,
      postal_code,
      country,
    });
    const addressDisplay = addressStore && addressStore.displayUS ? addressStore.displayUS : '';
    if (editActive) {
      return (
        <>
          <Text type="h5">Address</Text>
          <VSpacer factor={2} />
          {this.renderSnippet({
            title: 'Address 1',
            content: address1,
            editActive: true,
            onChange: (e) => updateForSave('address1', e.target.value),
            tooltipContent: `
                The address of the user.
                Required for verification (KYC) checks. 
                Cannot perform KYC if set to a PO Box.
              `,
          })}
          {this.renderSnippet({
            title: 'Address 2',
            content: address2,
            editActive: true,
            onChange: (e) => updateForSave('address2', e.target.value),
            tooltipContent: `
                Additional address information.
                Cannot perform KYC if set to a PO Box.
              `,
          })}
          {this.renderSnippet({
            title: 'City',
            content: city,
            editActive: true,
            onChange: (e) => updateForSave('city', e.target.value),
            tooltipContent: `
                The city that corresponds to the address.
                Required for verification (KYC) checks.
              `,
          })}
          {this.renderSnippet({
            title: 'State',
            content: state,
            editActive: true,
            onChange: (e) => updateForSave('state', e.target.value),
            tooltipContent: `
                The state that corresponds to the address.
                Required for verification (KYC) checks.
              `,
          })}
          {this.renderSnippet({
            title: 'Postal Code',
            content: postal_code,
            editActive: true,
            onChange: (e) => updateForSave('postal_code', e.target.value),
            tooltipContent: `
                The postal code that corresponds to the address.
                Required for verification (KYC) checks.
              `,
          })}
          {this.renderSnippet({
            title: 'Country',
            content: country,
            editActive: true,
            onChange: (e) => updateForSave('country', e.target.value),
            tooltipContent: `
                Country in which user resides.
                Required for verification (KYC) checks.
              `,
          })}
        </>
      );
    } else {
      return (
        <ContentSnippet
          title="Address"
          horizontalLayout
          tooltipProps={{ zIndex: 9999 }}
          tooltipContent={`
              Address of the user.
              Required for verification (KYC) checks.
            `}
          titleInline={!editActive ? { width: 180 } : null}
          contentInline={!editActive ? { flexGrow: 1 } : null}
        >
          <div className={s.address}>{addressDisplay}</div>
        </ContentSnippet>
      );
    }
  }

  renderExpansion() {
    const { editActive } = this.state;
    const {
      honorific,
      uses_parent_account,
      parent_token,
      corporate_card_holder,
      company,
      nationality,
      ip_address,
      notes_string,
      business_token,
      account_holder_group_token,
      created_time,
      last_modified_time,
      updateForSave,
    } = this.cardholderStore;
    return (
      <>
        <VSpacer factor={2} />
        {/*        {this.renderSnippet({
          title: 'Gender',
          content: gender,
          editActive,
          onChange: (e) => updateForSave('gender', e.target.value),
          tooltipContent: `
              The gender of the user. M | F
            `,
        })}*/}
        {this.renderSnippet({
          title: 'Honorific',
          content: honorific,
          editActive,
          onChange: (e) => updateForSave('honorific', e.target.value),
          tooltipContent: `
              The user's title/prefix: 
              Ms., Mr., Miss, Mrs. etc.
            `,
        })}
        {this.renderTextRow({
          title: 'Uses Parent Account',
          content: uses_parent_account ? 'True' : 'False',
          tooltipContent: `
              Indicates whether the child shares
              balances with the parent (true),
              or whether the child balances are
              independent of the parent (false).
            `,
        })}
        <div id={s.corpCardHolder}>
          {this.renderSnippet({
            title: 'Corporate Card Holder',
            content: corporate_card_holder ? 'True' : 'False',
            inputValue: corporate_card_holder,
            editActive,
            inputType: 'toggle',
            onChange: (value) => updateForSave('corporate_card_holder', value),
            tooltipContent: 'Set to true if user holds a corporate card.',
          })}
        </div>
        {this.renderSnippet({
          title: 'Company',
          content: company,
          editActive,
          onChange: (e) => updateForSave('company', e.target.value),
          tooltipContent: 'Company name.',
        })}
        {this.renderSnippet({
          title: 'Nationality',
          content: nationality,
          editActive,
          onChange: (e) => updateForSave('nationality', e.target.value),
          tooltipContent: 'Nationality of the user.',
        })}
        {this.renderSnippet({
          title: 'IP Address',
          content: ip_address,
          editActive,
          onChange: (e) => updateForSave('ip_address', e.target.value),
          tooltipContent: 'The IP address of the user.',
        })}
        {this.renderSnippet({
          title: 'API Note',
          content: notes_string,
          editActive,
          onChange: (e) => updateForSave('notes_string', e.target.value),
          tooltipContent: `
              A note string defined on the business object.
              Note: This is different than the notes in
              the note tab.
            `,
        })}
        {this.renderSnippet({
          title: 'Parent Token',
          content: parent_token,
          editActive,
          onChange: (e) => updateForSave('parent_token', e.target.value),
          tooltipContent: `
              The unique identifier of a user/business 
              already in the system.
            `,
        })}
        {this.renderTextRow({
          title: 'Business Token',
          content: business_token,
          tooltipProps: { width: 620 },
          tooltipContent: (
            <div>
              The business_token field is conditionally returned in the response (it cannot be set
              through the API). You can use this field in conjunction with the parent_token field to
              determine whether the user has a parent or grandparent that is a business:
              <br />
              <VSpacer />
              <table className={s.table}>
                <thead>
                  <tr>
                    <th className={s.th}>parent_token</th>
                    <th className={s.th}>business_token</th>
                    <th className={s.th}>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className={s.td}>Not populated</td>
                    <td className={s.td}>Not populated</td>
                    <td className={s.td}>User does not have a parent.</td>
                  </tr>
                  <tr>
                    <td className={s.td}>Populated</td>
                    <td className={s.td}>Not populated</td>
                    <td className={s.td}>User's parent is a user.</td>
                  </tr>
                  <tr>
                    <td className={s.td}>
                      Populated; <br />
                      matches business_token
                    </td>
                    <td className={s.td}>
                      Populated; <br />
                      matches parent_token
                    </td>
                    <td className={s.td}>User's parent is a business.</td>
                  </tr>
                  <tr>
                    <td className={s.td}>
                      Populated; <br />
                      does not match business_token
                    </td>
                    <td className={s.td}>
                      Populated; <br />
                      does not match parent_token
                    </td>
                    <td className={s.td}>
                      User's parent is a user <br />
                      and the parent's parent is a business.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ),
        })}
        <div id={s.accHoldGroupToken}>
          {this.renderSnippet({
            title: 'Account Holder Group Token',
            content: account_holder_group_token,
            editActive,
            onChange: (e) => updateForSave('account_holder_group_token', e.target.value),
            tooltipContent: `
              Associates the specified account holder
              group with the user.
            `,
          })}
        </div>
        {this.renderTextRow({
          title: 'Created',
          content: created_time,
          tooltipContent: `
              A timestamp of when the user was
              created in the Marqeta API
            `,
        })}
        {this.renderTextRow({
          title: 'Last Modified',
          content: last_modified_time,
          tooltipContent: `
              A timestamp of when the user was
              last updated in the Marqea API
            `,
        })}
        {this.renderMetadata()}
      </>
    );
  }

  renderMetadata() {
    const { editActive } = this.state;
    const { hasUamRoleToken, hasUserDetailsEdit } = this.props;
    const canAddMetadata = hasUamRoleToken ? hasUserDetailsEdit : true;
    let { metadataObject, updateMetadata } = this.cardholderStore;
    if (!metadataObject) {
      metadataObject = {};
    }
    const metadata = [];
    for (const key in metadataObject) {
      const value = metadataObject[key];
      metadata.push(
        <React.Fragment key={key}>
          {this.renderSnippet({
            title: key,
            content: value,
            editActive,
            tooltipContent: `
                To delete a metadata key pair
                delete the value and save
              `,
            onChange: (e) => updateMetadata(key, e.target.value),
          })}
        </React.Fragment>
      );
    }
    return (
      <div>
        <VSpacer />
        <div className={s.flexDiv}>
          <Text type="h5">Metadata</Text>
          <HSpacer factor={2} />
          {canAddMetadata && (
            <Button
              size="xs"
              type="outline"
              onClick={() => this.setState({ showAddMetadataModal: true })}
            >
              Add New
            </Button>
          )}
        </div>
        <VSpacer factor={2} />
        {metadata}
      </div>
    );
  }

  renderTextRow({ content, title, ...props }) {
    const { editActive } = this.state;

    return (
      <>
        <ContentSnippet
          horizontalLayout
          title={title}
          titleInline={!editActive ? { width: 180 } : null}
          contentInline={!editActive ? { flexGrow: 1 } : null}
          testId={`volt-content-snippet_title-${title}`}
          {...props}
        >
          <Text>{content}</Text>
        </ContentSnippet>
        <VSpacer factor={2} />
      </>
    );
  }

  renderInfo() {
    const { editable } = this.props;
    const { editActive, expand } = this.state;
    const {
      active,
      token,
      first_name,
      middle_name,
      last_name,
      email,
      phone,
      updateForSave,
      birth_date,
    } = this.cardholderStore;

    return (
      <Card
        title="User Info"
        testId="cardholder-information-card"
        controls={editable && (editActive ? this.renderHeaderButtons() : this.renderControl())}
        footerButtons={this.getFooterButtons()}
      >
        <div style={{ width: '90%' }}>
          {this.renderTextRow({
            title: 'Active',
            content: <Status status={active ? 'ACTIVE' : 'INACTIVE'} />,
            tooltipContent: (
              <div>
                Active is a boolean determined by current status
                <br />
                <VSpacer />
                <table className={s.table}>
                  <thead>
                    <tr>
                      <th className={s.th}>Status</th>
                      <th className={s.th}>Active</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className={s.td}>Active, Limited</td>
                      <td className={s.td}>True</td>
                    </tr>
                    <tr>
                      <td className={s.td}>Unverified, Suspended, Closed</td>
                      <td className={s.td}>False</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ),
          })}
          {this.renderStatus()}
          {this.renderTextRow({
            title: 'Token',
            content: token,
            tooltipContent: `
                The unique identifier of the user.
                This value cannot be updated.
              `,
          })}
          {this.renderSnippet({
            title: 'First Name',
            content: first_name,
            editActive: editActive,
            onChange: (e) => updateForSave('first_name', e.target.value),
            tooltipContent: `
                Users's first name.
                Required for verification (KYC) checks.
              `,
          })}
          {this.renderSnippet({
            title: 'Middle Name',
            content: middle_name,
            editActive: editActive,
            onChange: (e) => updateForSave('middle_name', e.target.value),
            tooltipContent: "Users's middle name.",
          })}
          {this.renderSnippet({
            title: 'Last Name',
            content: last_name,
            editActive: editActive,
            onChange: (e) => updateForSave('last_name', e.target.value),
            tooltipContent: `
                Users's last name.
                Required for verification (KYC) checks.
              `,
          })}
          {this.renderSnippet({
            title: 'Birth Date',
            content: birth_date,
            inputProps: { placeholder: 'yyyy-mm-dd' },
            editActive,
            onChange: (e) => updateForSave('birth_date', e.target.value),
            tooltipContent: `
                The date of birth of the user.
                Required for verification (KYC) checks.
              `,
          })}
          {this.renderSnippet({
            title: 'Email',
            content: email,
            editActive: editActive,
            onChange: (e) => updateForSave('email', e.target.value),
            tooltipContent: `
                A valid email address for the user.
                This value must be unique among users.
              `,
          })}
          {this.renderSnippet({
            title: 'Phone',
            content: phone,
            editActive: editActive,
            onChange: (e) => updateForSave('phone', e.target.value),
            tooltipContent: '10 digit telephone number of primary contact.',
          })}
          {this.renderAddress()}
          {expand && this.renderExpansion()}
        </div>
      </Card>
    );
  }

  renderChangeStatusModal() {
    const { showChangeStatusModal } = this.state;
    const { fullName = '' } = this.cardholderStore;
    if (!showChangeStatusModal) return null;
    return (
      <ChangeStatusModal
        store={this.cardholderStore}
        heading={`Change Status - ${fullName}`}
        hideModal={() => this.setState({ showChangeStatusModal: false })}
      />
    );
  }

  renderAddMetadataModal() {
    const { showAddMetadataModal } = this.state;
    const { addMetadata } = this.cardholderStore;
    if (!showAddMetadataModal) return null;
    return (
      <AddMetadataModal
        handleCancel={() => {
          this.setState({ showAddMetadataModal: false });
        }}
        handleSave={async ({ key, value }) => {
          this.setState({ showAddMetadataModal: false, loading: true });
          addMetadata && (await addMetadata({ key, value }));
          this.setState({ loading: false });
        }}
        hideModal={() => {
          this.setState({ showAddMetadataModal: false });
        }}
      />
    );
  }

  render() {
    const { loading } = this.state;
    return (
      <LoadingOverlay active={loading}>
        {this.renderChangeStatusModal()}
        {this.renderAddMetadataModal()}
        {this.renderInfo()}
      </LoadingOverlay>
    );
  }
}

export default observer(CardholderInformationCard);
