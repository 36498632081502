import React from 'react';
import moment from 'moment';

import { List, StatusIndicator, Text, VSpacer } from '@mqd/volt-base';

const columns = [
  {
    id: 'state',
    label: 'Status',
    renderer: ({ val }) => <StatusIndicator status={val} />,
  },
  {
    id: 'token',
    label: 'Token Number',
    renderer: ({ rowData }) => rowData.row.formattedTokenPan,
  },
  {
    id: 'token_service_provider.token_reference_id',
    label: 'Token Reference ID',
    renderer: ({ rowData }) => rowData.row.token_service_provider.token_reference_id,
    width: 210,
  },
  {
    id: 'token_service_provider.pan_reference_id',
    label: 'pan reference id',
    renderer: ({ rowData }) => rowData.row.token_service_provider.pan_reference_id,
  },
  {
    id: 'device.wallet_name',
    label: 'wallet name',
    renderer: ({ rowData }) => rowData.row.token_service_provider.token_requestor_name,
  },
  {
    id: 'device.device_type',
    label: 'Device',
    renderer: ({ rowData }) => rowData.row.device.type,
  },
  {
    id: 'created_time',
    label: 'Creation date',
    renderer: ({ val }) => moment(val).format('YYYY-MM-DD hh:mm'),
  },
];

export default function DigitalWalletTokenList({ heading, items, goToDigitalWalletToken }) {
  if (!Array.isArray(items) || items.length <= 0) return null;
  return (
    <>
      <Text type="h4">{heading}</Text>
      <VSpacer factor={2} />
      <List
        columns={columns}
        rows={items}
        onRowClick={(e) => goToDigitalWalletToken(items[e.rowIndex].token)}
      />
    </>
  );
}
