import React from 'react';
import { VSpacer, Icon, Text } from '@mqd/volt-base';

import s from './EmptyState.module.css';

export const EmptyState = ({
  iconName = 'search-inactive',
  content,
  subContent,
  topMargin = 8,
  midMargin = 2,
  baseMargin = 8,
  contentType = 'h3',
}) => (
  <>
    <VSpacer factor={topMargin} />
    <div className={s.emptyState__wrapper}>
      <div className={s.emptyState__icon}>
        <Icon type={iconName} />
      </div>
      <VSpacer factor={midMargin} />
      <Text type={contentType}>{content}</Text>
      <VSpacer />
      <Text className={s.emptyState__subContent}>{subContent}</Text>
      <VSpacer factor={baseMargin} />
    </div>
  </>
);
