import { decorate, observable } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';
import BusinessIdentityResultsStore from './BusinessIdentityResultsStore';

class BusinessIdentityIndividualStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
  }
  // values
  first_name: BusinessIdentityResultsStore = {};
  middle_name: BusinessIdentityResultsStore = {};
  last_name: BusinessIdentityResultsStore = {};
  address: BusinessIdentityResultsStore = {};
  city: BusinessIdentityResultsStore = {};
  state: BusinessIdentityResultsStore = {};
  zip: BusinessIdentityResultsStore = {};
  country: BusinessIdentityResultsStore = {};
  ssn: BusinessIdentityResultsStore = {};
  dob: BusinessIdentityResultsStore = {};
  ofac: BusinessIdentityResultsStore = {};
}

decorate(BusinessIdentityIndividualStore, {
  // values
  first_name: observable,
  middle_name: observable,
  last_name: observable,
  address: observable,
  city: observable,
  state: observable,
  zip: observable,
  country: observable,
  ssn: observable,
  dob: observable,
  ofac: observable,
});

export default BusinessIdentityIndividualStore;
