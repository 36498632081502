import { decorate, observable, computed } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';
import { KycBusinessResultStore } from './KycBusinessResultStore';

class KycBusinessStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
  }

  // values
  token: string = '';
  created_time: string = '';
  last_modified_time: string = '';
  business_token: string = '';
  manual_override: boolean = false;
  result: KycBusinessResultStore = {};
}

decorate(KycBusinessStore, {
  // values
  token: observable,
  created_time: observable,
  last_modified_time: observable,
  business_token: observable,
  manual_override: observable,
  result: observable,
});

export default KycBusinessStore;
