import React from 'react';
import { observer } from 'mobx-react';
import { Colors, HSpacer, Icon, RawDropdown, Text, VSpacer, Modal } from '@mqd/volt-base';
import s from './Header.module.css';
import { approvalStatusColors, statusToString } from '../constants';
import ApprovalModal from '../ApprovalModal';

// helper function
function DropDownContent({ otherStatusTypes, updateStatus }) {
  const colorName = approvalStatusColors[statusType];
  return (
    <div className={s.dropdownContent}>
      {otherStatusTypes.map((statusType, i) => {
        const color = Colors[colorName] || 'grey';
        return (
          <div key={`approval-status-option-${i}`}>
            <Status updateStatus={updateStatus} text={statusType} color={color} />
            {i !== otherStatusTypes.length - 1 && <VSpacer />}
          </div>
        );
      })}
    </div>
  );
}

// helper function
function Status({ text, color, updateStatus }) {
  return (
    <div
      data-testid={`KYB-approvalstatus-${text}`}
      onClick={updateStatus}
      className={s.statusContainer}
    >
      <div className={s.statusIcon} style={{ backgroundColor: color }} />
      <HSpacer />
      <Text>{text}</Text>
    </div>
  );
}

class ApprovalStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      showModal: false,
    };
    this.store = {
      statusTypes: ['Verified', 'Pending', 'Rejected', 'Not Performed'],
    };

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.updateStatus = this.updateStatus.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
    this.showModal = this.showModal.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ active: false });
    }
  }

  getStatusOptions(status, canVerify) {
    const { statusTypes } = this.store;
    var newStatusTypes = statusTypes.filter((statusType) => statusType !== status);
    if (!canVerify) {
      newStatusTypes = newStatusTypes.filter((statusType) => statusType !== 'Verified');
    }
    return newStatusTypes;
  }

  updateStatus(ev) {
    this.setState({
      showModal: !this.state.showModal,
    });
  }

  saveChanges(note) {
    this.updateStatus();
    kybStore.saveChanges();
  }

  showModal(businessName) {
    if (this.state.showModal) {
      return (
        <ApprovalModal
          businessName={businessName}
          hideModal={this.updateStatus}
          noteChangeHandler={this.saveChanges}
          validatedFieldChanged={false}
          allFieldsSuccess={false}
        />
      );
    }
  }

  render() {
    const { statusTypes } = this.store;
    const { businessName, userStore, businessEntities } = this.props;
    const { active } = this.state;
    //const hasStatusEditRights = userStore.kybCanWrite;
    const hasStatusEditRights = false; //until backend functionality available only show icon
    const status = statusToString[businessEntities.status] || 'Not Performed';
    const otherStatusTypes = this.getStatusOptions(status, businessEntities.canVerify);
    const colorName = approvalStatusColors[status];
    const color = Colors[colorName] || 'transparent';
    const control = (
      <div
        className={`${s.approvalStatus} ${active ? s.active : ''}`}
        onClick={() => this.setState({ active: !active })}
        ref={this.setWrapperRef}
      >
        <Status text={status} color={color} />
        <HSpacer />
        <Icon type="chevron-down" />
      </div>
    );
    if (hasStatusEditRights) {
      return (
        <>
          <RawDropdown control={control} anchor="right">
            <DropDownContent updateStatus={this.updateStatus} otherStatusTypes={otherStatusTypes} />
          </RawDropdown>
          {this.showModal(businessName)}
        </>
      );
    } else {
      return <Status text={status} color={color} />;
    }
  }
}

export default observer(ApprovalStatus);
