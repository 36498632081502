import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { ErrorBannerAlert, ParentDetail } from '@mq/voltron-parent';
import DigitalWalletTokenStore from './../stores/DigitalWalletTokenStore';
import { VSpacer, Tabs, LoadingOverlay, ToastAlert } from '@mqd/volt-base';
import DigitalWalletTokensInfoTab from '../digital-wallet-tokens-info-tab/DigitalWalletTokensInfoTab';
import DigitalWalletTokensActions from '../digital-wallet-tokens-info-tab/DigitalWalletTokensActions';
import DigitalWalletTokensDetailHeader from './DigitalWalletTokensDetailHeader';
import TransitionsTab from '../transitions-tab/TransitionsTab';
import StyleContext from '../contexts/StyleContext';

import { TERMINATED } from '../constants';

const TABS = {
  DETAILS: 'Token details',
  TRANSITIONS: 'Token transitions',
};

const TerminateToastAlert = ({ hideToast, toastProps }) => {
  if (!toastProps) return null;

  return <ToastAlert remove={hideToast}>{toastProps.text}</ToastAlert>;
};

class DigitalWalletTokensDetail extends ParentDetail {
  constructor(props) {
    super(props);
    this.storeConstructor = DigitalWalletTokenStore;
    this.state = {
      activeTab: this.props.currentTab || TABS.DETAILS,
      historyTabRefreshToken: 'initial',
      loadedStores: {},
      toastProps: null,
    };
    this.canShowTokenPan = this.canShowTokenPan.bind(this);
  }

  get activeTabs() {
    return {
      [TABS.DETAILS]: true,
      [TABS.TRANSITIONS]: true,
    };
  }

  canShowTokenPan() {
    const { userStore } = this.props;
    const { auxShowDWTPanActive } = userStore;

    return auxShowDWTPanActive;
  }

  renderBreadcrumb() {
    const { buildBreadcrumb } = this.props;
    if (!(buildBreadcrumb instanceof Function)) return null;
    const { formattedTokenPan } = this.store;
    const currentCrumb = {
      children: formattedTokenPan || '· · ·',
    };
    return (
      <>
        {buildBreadcrumb(currentCrumb)}
        <VSpacer factor={2} />
      </>
    );
  }

  renderContent() {
    const { activeTab, loadedStores } = this.state;
    const { gqlClient, token } = this.store;
    const { transitionsTableLocalStorageKey } = this.props;

    const defaultComponent = (
      <DigitalWalletTokensInfoTab store={this.store} canShowTokenPan={this.canShowTokenPan()} />
    );

    if (this.inactiveTab) return defaultComponent;
    switch (activeTab) {
      case TABS.DETAILS:
        return defaultComponent;
      case TABS.TRANSITIONS:
        return (
          <TransitionsTab
            onStoreConstruction={(store) => {
              const newLoadedStores = Object.assign({}, loadedStores, {
                Transitions: store,
              });
              this.setState({ loadedStores: newLoadedStores });
            }}
            storeInitArgs={{
              gqlClient,
              queryParams: {
                digital_wallet_token: {
                  val: token,
                  type: 'ID!',
                },
              },
            }}
            localStorageKey={transitionsTableLocalStorageKey}
            calculateTableHeight={(store) =>
              store.currentList.length >= store.pageSize ? 0 : -165
            }
          />
        );
      default:
        return defaultComponent;
    }
  }

  renderErrors() {
    const { loadedStores, activeTab } = this.state;
    if ([TABS.DETAILS, TABS.TRANSITIONS].includes(activeTab)) {
      return <ErrorBannerAlert store={this.store} />;
    } else {
      return <ErrorBannerAlert store={loadedStores[activeTab]} />;
    }
  }

  get canMakeTransition() {
    const { createTransitionAllowedRoles } = this.store;
    const { userStore } = this.props;
    const { hasRoleInArrayForActiveProgram = () => {}, uamGranularPermissionsActive } = userStore;
    return uamGranularPermissionsActive
      ? this.props.canPerformActions
      : hasRoleInArrayForActiveProgram(createTransitionAllowedRoles);
  }

  render() {
    const { activeTab, toastProps } = this.state;
    const { handleTabClick } = this.props;
    const {
      formattedTokenPan,
      state: status,
      terminateToken,
      lockToken,
      activateToken,
      showTerminateModal,
      setTerminateModal,
      loading,
    } = this.store;

    return (
      <div style={{ width: '100%' }}>
        {this.renderErrors()}
        <VSpacer factor={2} />
        {this.renderBreadcrumb() || <span />}
        <LoadingOverlay active={loading}>
          <DigitalWalletTokensDetailHeader token_pan={formattedTokenPan} status={status} />

          {status !== TERMINATED && this.canMakeTransition && (
            <>
              <VSpacer factor={3.5} />
              <DigitalWalletTokensActions
                state={status}
                terminateToken={terminateToken}
                lockToken={lockToken}
                activateToken={activateToken}
                showTerminateModal={showTerminateModal}
                displayTerminateModal={() => setTerminateModal(true)}
                hideTerminateModal={() => setTerminateModal(false)}
                setToastProps={(toastProps) => this.setState({ toastProps })}
              />
            </>
          )}
          <VSpacer factor={3} />
          <Tabs
            testId="digital-wallet-tokens-tab-selector"
            tabs={Object.keys(this.activeTabs)}
            activeTab={activeTab}
            onTabChange={(activeTab) => {
              handleTabClick({ tab: activeTab });
              this.setState({ activeTab });
            }}
          />
          <VSpacer factor={2.75} />
          <StyleContext.Provider value={{ height: this.dynamicHeight }}>
            {this.renderContent()}
          </StyleContext.Provider>

          <TerminateToastAlert
            hideToast={() => this.setState({ toastProps: null })}
            toastProps={toastProps}
          />
        </LoadingOverlay>
      </div>
    );
  }
}

DigitalWalletTokensDetail.propTypes = {
  storeInitArgs: PropTypes.object,
  store: PropTypes.object,
  autoHydrate: PropTypes.bool,
  onStoreConstruction: PropTypes.func,
  buildBreadcrumb: PropTypes.array,
  handleTabClick: PropTypes.func,
  currentTab: PropTypes.string,
  transitionsTableLocalStorageKey: PropTypes.string,
  canPerformActions: PropTypes.bool,
};

DigitalWalletTokensDetail.defaultProps = {
  storeInitArgs: {},
  store: null,
  autoHydrate: true,
  onStoreConstruction: null,
  buildBreadcrumb: [],
  handleTabClick: () => {},
  currentTab: TABS.DETAILS,
  transitionsTableLocalStorageKey: 'digitalWalletTokensTransitionsTableConfig',
  canPerformActions: false,
};

export default observer(DigitalWalletTokensDetail);
