// @flow
import { decorate, observable, action, runInAction, computed } from 'mobx';
import gqlUtils from '@mqd/graphql-utils';
import CardholderStore from '../stores/CardholderStore';
import { ParentTableStore } from '@mq/voltron-parent';
import { getCardOrCardholderQuery } from '../shared-utils/index';

const { fragments } = gqlUtils;

export default class CardholdersTableStore extends ParentTableStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
  }
  // values
  items: Array<CardholderStore> = [];
  count: number = 50;
  first_name: string = '';
  last_name: string = '';
  phone: string = '';
  email: string = '';
  ssn: string = '';
  dda: string = '';
  last_four: string = '';
  cardHoldersBackendError: string = '';

  // actions
  handleSearch() {
    const { hydrate, setAttr } = this;
    setAttr && setAttr('start_index', 0);
    setAttr('count', 50);
    hydrate && hydrate();
  }

  async hydrate() {
    try {
      this.loading = true;
      let result;
      // fetch data

      if (this.last_four) {
        return this.last_four.length > 4
          ? await this.searchAndLoadCardsByFullPan()
          : await this.searchAndLoadCardsByLastFour();
      }

      switch (this.tableParentType) {
        case 'business':
          result = await this.fetchBusinessChildernCardholder();
          break;
        default:
          result = await this.fetchCardholders();
          break;
      }

      runInAction(() => {
        let cardholders;
        this.resetError(result);
        switch (this.tableParentType) {
          case 'business':
            cardholders = this.dig(result, 'data', 'business', 'children');
            break;
          default:
            cardholders = this.extract(result, this.cardholdersQuery);
            break;
        }

        this.processPaginationResponse(cardholders, CardholderStore);
      });
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  async fetchBusinessChildernCardholder() {
    const result = await this.gqlQuery(
      `
        query businessCarholders${this.outerQueryParams} {
          business(token: $token) {
            children(${this.paginationParamsInner}) {
              data {
                ...cardholderBaseInfo
              }
              ${this.paginationAttributes}
            }
          }
        }
        ${fragments.cardholderBaseInfo}
      `,
      this.hydrateParams,
      [],
      'no-cache',
      'all'
    );
    return result;
  }

  async fetchCardholders() {
    const paramsInfo = {
      first_name: {
        type: 'String',
        val: this.first_name,
      },
      last_name: {
        type: 'String',
        val: this.last_name,
      },
      phone: {
        type: 'String',
        val: this.phone,
      },
      email: {
        type: 'String',
        val: this.email,
      },
      ssn: {
        type: 'String',
        val: this.ssn,
      },
      dda: {
        type: 'String',
        val: this.dda,
      },
    };
    const queryParams = this.configureQueryParams(paramsInfo);
    const queryAndPaginationParams = Object.assign({}, this.paginationParams, queryParams);
    return await this.gqlQuery(
      this.queryStatement(paramsInfo),
      queryAndPaginationParams,
      [],
      'no-cache',
      'all'
    );
  }

  queryStatement(paramsInfo = {}): String {
    switch (this.tableParentType) {
      default:
        if (!this.enableNoParamQuery && !this.validParams) return;
        const defaultOuterParams = `(${this.configureOuterQueryParams(
          paramsInfo
        )} $count: Int $start_index: Int)`;
        const defaultInnerParams = `(${this.configureInnerQueryParams(
          paramsInfo
        )} count: $count start_index: $start_index)`;
        return `
          query ${this.cardholdersQuery}${defaultOuterParams} {
            ${this.cardholdersQuery}${defaultInnerParams} {
              data {
                ...cardholderBaseInfo
              }
              ${this.paginationAttributes}
            }
          }
          ${fragments.cardholderBaseInfo}
        `;
    }
  }

  async searchAndLoadCardsByLastFour() {
    const result = await this.gqlQuery(
      `
        query ${this.searchCardsByLastFourQuery}(
          $last_four: String
          $count: Int 
          $start_index: Int       
        ){
          ${this.searchCardsByLastFourQuery}(
            last_four: $last_four
            count: $count 
            start_index: $start_index
          ){
            data {
              cardholder {
                ...cardholderBaseInfo
              }
            }
            ${this.paginationAttributes}
          }
        }

        ${fragments.cardholderBaseInfo}
      `,
      {
        last_four: this.last_four,
        ...this.paginationParams,
      },
      [],
      'no-cache',
      'all'
    );

    const searchCardsByLastFour = this.dig(result, 'data', this.searchCardsByLastFourQuery);
    this.resetError(result);
    const data = this.dig(result, 'data', this.searchCardsByLastFourQuery, 'data');
    const spreadCardholder = (data) => ({ ...data.cardholder });
    const parsedCardholderData = {
      ...searchCardsByLastFour,
      data: data.map(spreadCardholder),
    };
    this.processPaginationResponse(parsedCardholderData, CardholderStore);
  }

  async searchAndLoadCardsByFullPan() {
    const result = await this.gqlQuery(
      `
        query ${this.searchCardsByFullPanQuery}(
          $pan: String     
        ){
          ${this.searchCardsByFullPanQuery}(
            pan: $pan
          ){
              cardholder {
                ...cardholderBaseInfo
              }
          }
        }

        ${fragments.cardholderBaseInfo}
      `,
      {
        pan: this.last_four,
      },
      [],
      'no-cache',
      'all'
    );
    const cardholder = this.dig(result, 'data', this.searchCardsByFullPanQuery, 'cardholder');
    this.resetError(result);
    const parsedCardholderData = cardholder ? this.wrapInPage([cardholder]) : this.wrapInPage([]);
    this.processPaginationResponse(parsedCardholderData, CardholderStore);
  }

  resetError(result) {
    const error_message = this.dig(result, 'errors', '0', 'message');
    if (
      error_message &&
      error_message.includes("You don't have access to VIEW this part of the app")
    ) {
      this.cardHoldersBackendError = "You don't have permission to view this page";
    } else {
      this.cardHoldersBackendError = '';
    }
  }

  wrapInPage = (data) => {
    return {
      count: data.length,
      start_index: 0,
      end_index: 0,
      is_more: false,
      data,
    };
  };

  get cardholdersQuery() {
    return getCardOrCardholderQuery(
      'cardholdersLookup',
      'cardholdersLookupByCardProduct',
      localStorage.getItem('userOrgName'),
      JSON.parse(localStorage.getItem('redseaRoles'))
    );
  }

  get searchCardsByLastFourQuery() {
    return getCardOrCardholderQuery(
      'searchCardsByLastFour',
      'searchCardsByLastFourAndByCardProduct',
      localStorage.getItem('userOrgName'),
      JSON.parse(localStorage.getItem('redseaRoles'))
    );
  }

  get searchCardsByFullPanQuery() {
    return getCardOrCardholderQuery(
      'searchCardsByFullPan',
      'searchCardsByFullPanAndByCardProduct',
      localStorage.getItem('userOrgName'),
      JSON.parse(localStorage.getItem('redseaRoles'))
    );
  }
}

decorate(CardholdersTableStore, {
  // values
  items: observable,
  first_name: observable,
  last_name: observable,
  phone: observable,
  email: observable,
  ssn: observable,
  dda: observable,
  last_four: observable,
  cardHoldersBackendError: observable,

  // actions
  handleSearch: action.bound,
  hydrate: action.bound,
  queryStatement: action.bound,
  searchAndLoadCardsByLastFour: action.bound,
  searchAndLoadCardsByFullPan: action.bound,

  // computed
  cardholdersQuery: computed,
  searchCardsByLastFourQuery: computed,
  searchCardsByFullPanQuery: computed,
});
