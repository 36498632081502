// @flow
import { computed, decorate, observable, action, runInAction, toJS } from 'mobx';
import { ParentTableStore } from '@mq/voltron-parent';
import ReviewStore from './../stores/ReviewStore';
import { AGENT_TYPES, STATUSES } from './constants';
import moment from 'moment';
import {
  compareByEmail,
  formatDateRange,
  getMultiSelectParams,
  getProgramShortCodes,
  prepareForTable,
} from './utils';

export default class ReviewsTableStore extends ParentTableStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
    const { isInternalUser } = args;
    this.isInternalUser = isInternalUser;
  }

  // values
  items: Array<ReviewStore> = [];
  count: number = 50;
  loading: boolean = false;
  status: Array<String> = [STATUSES.OPEN, STATUSES.IN_PROGRESS];
  kycStatus: Array<String> = [];
  agent: Array<String> = [];
  disableBulkSelect: boolean = true;
  caseReviewer: Array<String> = [];
  lastUpdated = {
    from: moment().subtract(7, 'd').toDate(),
    to: moment().toDate(),
    changed: false,
  };
  dateCreated = {
    from: moment().subtract(7, 'd').toDate(),
    to: moment().toDate(),
    changed: false,
  };
  customerName: string = '';
  reviewId: string = '';
  userToken: string = '';
  initialLoad: boolean = true;
  agentsList: Array<String> = [];
  programsList = [];
  selectedPrograms: Array<String> = [];
  selectedReviews: Array<String> = [];
  tokens: Array<String> = [];
  firstLoad: Boolean = true;

  // actions
  handleSearch() {
    const { hydrate, setAttr } = this;
    setAttr && setAttr('start_index', 0);
    setAttr('count', 50);
    hydrate && hydrate();
  }

  handleDateChange(from, to, field) {
    this[field] = {
      from: from,
      to: to,
      changed: true,
    };
  }

  obtainParamsInfo() {
    if (this.firstLoad) {
      this.caseReviewer = [this.isInternalUser ? AGENT_TYPES.MARQETA : AGENT_TYPES.CUSTOMER];
      this.firstLoad = false;
    }

    const paramsInfo = {
      unit: {
        type: 'String',
        val: 'ONBOARDING_KYC',
      },
      status: {
        type: 'String',
        val: getMultiSelectParams(this.status),
      },
      team: {
        type: 'String',
        val: getMultiSelectParams(this.caseReviewer),
      },
      token: {
        type: 'String',
        val: this.reviewId,
      },
      user_token: {
        type: 'String',
        val: this.userToken,
      },
    };

    if (this.lastUpdated.changed) {
      return {
        ...paramsInfo,
        last_modified_time: {
          type: 'String',
          val: formatDateRange(this.lastUpdated),
        },
      };
    }

    if (this.dateCreated.changed) {
      return {
        ...paramsInfo,
        created_time: {
          type: 'String',
          val: formatDateRange(this.dateCreated),
        },
      };
    }

    if (this.agent.length > 0) {
      const agentsArr = toJS(this.agent).map((agentEmail) => {
        const agentDetails = this.agentsList.find((agent) => agent.email === agentEmail);
        return agentDetails ? agentDetails.token : AGENT_TYPES.UNASSIGNED;
      });
      const agentsStr = agentsArr.join(',');
      const paramsKey = this.isInternalUser ? 'marqeta_agent_token' : 'customer_agent_token';

      return {
        ...paramsInfo,
        [paramsKey]: {
          type: 'String',
          val: agentsStr,
        },
      };
    }

    if (this.selectedPrograms.length !== this.programsList.length) {
      return {
        ...paramsInfo,
        program_short_code: {
          type: 'String',
          val: getProgramShortCodes(this.programsList, toJS(this.selectedPrograms)),
        },
      };
    }

    return paramsInfo;
  }

  getAllAssigees = async () => {
    this.agentsList = [];
    const result = await this.gqlQuery(
      `
      query allAgentsQuery {
        allAgents {
          agents {
            token
            email
          }
        }
      }
      `
    );
    const agentsResult = this.dig(result, 'data', 'allAgents', 'agents') || [];
    this.initialLoad = false;
    runInAction(() => {
      this.agentsList = agentsResult.sort(compareByEmail);
    });
  };

  async hydrate() {
    this.loading = true;

    if (!this.enableNoParamQuery && !this.validParams) return;

    if (this.initialLoad) {
      await this.getAllAssigees();
    }

    runInAction(() => {
      const reviews = this.extract(result, 'reviews');
      this.processPaginationResponse(prepareForTable(reviews), ReviewStore);
      this.tokens =
        reviews &&
        reviews.reviews &&
        reviews.reviews.map((review) => {
          return review.token;
        });
      this.loading = false;
    });
  }

  assignSelectedReview = async (email, reviewTokens) => {
    const agent = this.agentsList.find((agent) => agent.email === email);

    const payload = {
      agent_token: agent.token,
      transition_note: '',
      reviewTokens: reviewTokens,
    };
    try {
      const result = await this.gqlMutation(
        `
          mutation assignReviewToAgent ($agent_token: String, $transition_note: String, $reviewTokens: [ID]) {
            assignReviewToAgent (agent_token: $agent_token, transition_note: $transition_note, reviewTokens: $reviewTokens) {
              transition_note
            }
          }
        `,
        payload
      );
      if (!result) throw 'Update failed';
      await this.hydrate(this.token);
    } catch (error) {
      console.error(error);
    }
  };

  get programsListOptions(): Array {
    return this.programsList.map((item) => item.program);
  }

  get agentsListOptions(): Array {
    return this.agentsList.slice(0, 50).map((item) => item.email);
  }

  get agentsListSearchableOptions(): Array {
    return this.agentsList.map((item) => item.email);
  }
}

decorate(ReviewsTableStore, {
  // values
  items: observable,
  loading: observable,
  status: observable,
  kycStatus: observable,
  agent: observable,
  caseReviewer: observable,
  lastUpdated: observable,
  dateCreated: observable,
  customerName: observable,
  reviewId: observable,
  userToken: observable,
  agentsList: observable,
  selectedPrograms: observable,
  selectedReviews: observable,
  disableBulkSelect: observable,
  tokens: observable,

  // actions
  handleSearch: action.bound,
  handleDateChange: action.bound,
  hydrate: action.bound,
  hydrateFilterQueryParams: action.bound,
  assignSelectedReview: action.bound,

  //computed
  programsListOptions: computed,
  agentsListOptions: computed,
  agentsListSearchableOptions: computed,
});
