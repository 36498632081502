import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import AuthControls from './AuthControls';
import VelocityControls from './VelocityControls';
import CardExpiration from './CardExpiration';
import { StoreContext } from '@mqd/volt-contexts';
import { Text, VSpacer, Hr } from '@mqd/volt-base';

function OptionalCardControls({ store, testId }) {
  const {
    velocityControlsFormStore,
    cardStartDate,
    cardExpirationDate,
    cardExpirationDateError,
    setManualCardCreateAttrWithLimit,
    setThreeYearTimeFrame,
    hasMinOffSet,
  } = store;

  return (
    <div data-testid={testId}>
      <Text type="h5">Add optional controls for user</Text>
      <Text>These inputs will influence the user’s on-going controls.</Text>
      <VSpacer factor={3} />
      <CardExpiration
        cardStartDate={cardStartDate}
        cardExpirationDate={cardExpirationDate}
        cardExpirationDateError={cardExpirationDateError}
        setManualCardCreateAttrWithLimit={setManualCardCreateAttrWithLimit}
        setThreeYearTimeFrame={setThreeYearTimeFrame}
        hasMinOffSet={hasMinOffSet}
      />
      <StoreContext.Consumer>
        {({ ProgramConfigStore = {} } = {}) => {
          const { isWhiteList } = ProgramConfigStore;
          const type = isWhiteList ? 'Allow list' : 'Deny list';

          return <AuthControls store={store} title={`${type} MCCs`} />;
        }}
      </StoreContext.Consumer>
      <Hr />
      <VSpacer factor={2} />
      <VelocityControls store={velocityControlsFormStore} />
    </div>
  );
}

OptionalCardControls.propTypes = {
  store: PropTypes.object,
  testId: PropTypes.string,
};

OptionalCardControls.defaultProps = {
  store: {},
  testId: 'optional-card-controls',
};

export default observer(OptionalCardControls);
